import { Popover } from '@material-ui/core';
import React from 'react';
import { businessPartner } from '../../../../../../models/entities/businessPartner/businessPartner';
import { contact } from '../../../../../../models/entities/businessPartner/contact';
import CZTooltip from '../../../../../shared/CZTooltip/CZTooltip';
import { FormDropDown } from '../../../../../shared/inputs/form';
import MaterialPopover from '../../../../../shared/Popover/MaterialPopover';
import { styled } from '../../../../../shared/Theme/theme';
import { partiesToDropdownOptions } from '../../../../businessPartners/components/BusinessPartnerForm/BusinessPartnerFormConnected';
import { AddContainer, Flex, renderFieldLabel } from '../../../../singleShipment/BidForm/ShipmentBidForm';
import CreateBusinessPartner from '../BusinessPartner/CreateBusinessPartner';
import CreateBusinessPartnerContact from '../BusinessPartner/CreateBusinessPartnerContact';

type Props = {
    values: any;
    setFieldValue: any;
    businessPartners?: Array<businessPartner>;
    reSendEmail?: (partyId: string) => void;
    disabled: boolean;
    localization: any;
};
export type partyObj = {
    id: string;
    name?: string;
    contactId?: number;
    contactName?: string;
    email?: string;
};
export const emptyPartyObj = {
    id: '',
    contactsIds: undefined
};
const Parties = ({ values, setFieldValue, businessPartners, reSendEmail, disabled, localization }: Props) => {
    const renderInfoPopover = (name: string, email: string) => (
        <MaterialPopover anchor={<ActionButton className="material-icons">info_outline</ActionButton>} anchorStyle={{ display: 'flex' }}>
            <div>
                <div>
                    <b>{localization.name}:</b> {name}
                </div>
                <div>
                    <b>{localization.email}:</b> {email}
                </div>
            </div>
        </MaterialPopover>
    );

    return (
        <>
            {values.parties &&
                values.parties.map((item: partyObj, index: number) => {
                    const isItemEditable = item.id !== '' && !businessPartners?.find((bp) => bp.id === item.id);
                    const contacts = businessPartners?.find((p) => p.id === item.id)?.contacts;
                    const contact = item.contactId ? contacts?.find((c) => c.id === item.contactId) : { name: item.contactName, email: item.email };
                    return (
                        <Flex key={index} style={{ alignItems: 'center', marginBottom: '20px' }}>
                            <StyledFieldContainer>
                                {!isItemEditable && (
                                    <Flex>
                                        <FormDropDown
                                            name={`parties[${index}][id]`}
                                            style={{ width: '200px' }}
                                            placeHolder={localization.pick_forwarder}
                                            options={partiesToDropdownOptions(businessPartners)}
                                            onChange={(_: any) => {
                                                setFieldValue(`parties[${index}][contactsIds]`, []);
                                            }}
                                            showSearch
                                        />
                                        <CreateBusinessPartner
                                            modalTitle={localization.add_forwarder}
                                            type="FORWARDER_BROKER"
                                            submitCallback={(businessPartner: businessPartner) =>
                                                setFieldValue(`parties[${index}][id]`, businessPartner.id)
                                            }
                                        />
                                        {values.parties[index].id && (
                                            <Flex>
                                                <FormDropDown
                                                    name={`parties[${index}][contactsIds]`}
                                                    style={{ width: '150px', marginLeft: '10px' }}
                                                    mode="multiple"
                                                    placeHolder={localization.pick_contact}
                                                    options={
                                                        contacts?.map((item) => {
                                                            return { value: '' + item.id, text: item.name };
                                                        }) || []
                                                    }
                                                    showSearch
                                                />

                                                <CreateBusinessPartnerContact
                                                    modalTitle={localization.add_contact}
                                                    submitCallback={(contact: contact) => {
                                                        const parties: { id: string; contactsIds: string[] }[] = [...(values.parties || [])];
                                                        const currentParty = parties[index];

                                                        if (currentParty) {
                                                            const newContacts = [...(currentParty.contactsIds || []), contact.id];
                                                            setFieldValue(`parties[${index}][contactsIds]`, newContacts);
                                                        }
                                                    }}
                                                    businessPartnerId={values.parties[index].id}
                                                />
                                            </Flex>
                                        )}
                                    </Flex>
                                )}
                                {isItemEditable && (
                                    <Flex>
                                        <div>{item.name}</div>
                                        {item.contactName && (
                                            <>
                                                <div style={{ whiteSpace: 'pre' }}> - </div>
                                                <div>{item.contactName}</div>
                                            </>
                                        )}
                                    </Flex>
                                )}
                            </StyledFieldContainer>
                            {!disabled && (
                                <DeleteContainer
                                    onClick={() => {
                                        const parties: Array<any> = [...values.parties];
                                        parties.splice(index, 1);
                                        setFieldValue('parties', parties);
                                    }}
                                >
                                    <VerticalDivider />
                                    <span className="material-icons">delete</span>
                                </DeleteContainer>
                            )}
                            {contact && contact.name && contact.email && renderInfoPopover(contact.name, contact.email)}
                            {item.id && reSendEmail && (
                                <CZTooltip text={localization.send_email_again}>
                                    <ActionButton
                                        style={{ cursor: 'pointer', marginLeft: '5px' }}
                                        onClick={() => reSendEmail(item.id)}
                                        className="material-icons"
                                    >
                                        send
                                    </ActionButton>
                                </CZTooltip>
                            )}
                        </Flex>
                    );
                })}
            {!disabled && (
                <AddContainer
                    onClick={() => {
                        let parties: Array<any> = [];
                        if (values.parties) parties = [...values.parties];
                        parties.push(emptyPartyObj);
                        setFieldValue('parties', parties);
                    }}
                >
                    <span className="material-icons">add_circle</span>
                    <span>{localization.add_forwarder}</span>
                </AddContainer>
            )}
        </>
    );
};

const DeleteContainer = styled.div`
    display: flex;
    color: ${(props) => props.theme.colors.primaryBlue};
    span.material-icons {
        cursor: pointer;
    }
`;
const VerticalDivider = styled.div`
    height: auto;
    width: 1px;
    background: rgb(0 0 0 / 14%);
    margin-right: 20px;
`;
const ActionButton = styled.span`
    font-size: 20px;
    color: #3d4676;
`;
const StyledFieldContainer = styled.div`
    padding-right: 30px;
`;
export default Parties;
