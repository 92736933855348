import { ModelConfig } from '@rematch/core';
import { loadShipmentSvgIcons } from '../../../components/pages/singleShipment/ShipmentPdfDocument';
import { shipment } from '../../../models/entities/shipment/shipment';

export type shipmentPdfSvgIconsType = { typeCodeSvg: string; originCountrySvg: string; arrowIconSvg: string; destinationCountrySvg: string };

export type singleShipmentStateType = {
    shipmentId: string | null;
    shipmentPdfSvgIcons: shipmentPdfSvgIconsType;
};

export const singleShipment: ModelConfig<singleShipmentStateType> = {
    state: {
        shipmentId: null,
        shipmentPdfSvgIcons: { typeCodeSvg: '', originCountrySvg: '', arrowIconSvg: '', destinationCountrySvg: '' }
    },
    reducers: {
        setCurrentShipmentId(state: singleShipmentStateType, shipmentId: string): singleShipmentStateType {
            return { ...state, shipmentId };
        },
        setShipmentPdfSvgIcons(state: singleShipmentStateType, shipmentPdfSvgIcons: shipmentPdfSvgIconsType): singleShipmentStateType {
            return { ...state, shipmentPdfSvgIcons };
        }
    }
};
