import React from 'react';
import { settings, purchaseOrder } from '../../../../models/entities/purchaseOrder/purchaseOrder';
import { localizationSelectors } from '../../../../state/ducks/localization/selectors';
import { purchaseOrdersSelectors } from '../../../../state/ducks/purchaseOrders/selectors';
import { RootState } from '../../../../state/store/store';
import { connect } from 'react-redux';
import { singlePurchaseOrderSelectors } from '../../../../state/ducks/singlePurchaseOrder/selectors';
import { document } from '../../../../models/entities/common_subentities/document';
import { userSelectors } from '../../../../state/ducks/user/selectors';
import MultipleDocumentFormConnected, { formMode } from '../../commonSubEntities/documents/DocumentForm/MultipleDocumentFormConnected';

type Props = {
    purchaseOrder?: purchaseOrder;
    mode: formMode;
    localizations?: any;
    settings?: settings;
    openedFromModal?: {
        setVisible: React.Dispatch<React.SetStateAction<boolean>>;
    };
    confirmation?: { setConfirm: React.Dispatch<React.SetStateAction<boolean>> };
    createMultiplePurchaseOrderDocument: (purchaseOrderNumber: string, documents: Array<document>) => Promise<void>;
    createMultiplePurchaseOrderDocumentSupplierUpdates: (purchaseOrderId: string, supplierName: string, documents: Array<document>) => Promise<void>;
    showLoader?: boolean;
    additionalData?: { [key: string]: any };
};

const PurchaseOrderMultipleDocumentFormConnected = ({
    purchaseOrder,
    localizations,
    mode,
    settings,
    openedFromModal,
    confirmation,
    createMultiplePurchaseOrderDocument,
    createMultiplePurchaseOrderDocumentSupplierUpdates,
    showLoader,
    additionalData
}: Props) => {
    return (
        <MultipleDocumentFormConnected<purchaseOrder, settings>
            entity={purchaseOrder}
            mode={mode}
            localizations={localizations}
            settings={settings}
            openedFromModal={openedFromModal}
            confirmation={confirmation}
            createMultipleDocument={async (entityId: string, documents: document[]) => {
                if (typeof additionalData?.supplierName === 'string') {
                    await createMultiplePurchaseOrderDocumentSupplierUpdates(entityId, additionalData.supplierName, documents);
                    return;
                }

                await createMultiplePurchaseOrderDocument(entityId, documents);
            }}
            showLoader={showLoader}
        />
    );
};

const mapStateToProps = (state: RootState) => ({
    localizations: localizationSelectors.singlePurchaseOrderPage(state),
    settings: purchaseOrdersSelectors.settingsByLanguage(state),
    purchaseOrder: singlePurchaseOrderSelectors.purchaseOrder(state),
    showLoader:
        !!state.loading.effects.purchaseOrders.createMultiplePurchaseOrderDocument ||
        !!state.loading.effects.purchaseOrders.createMultiplePurchaseOrderDocumentSupplierUpdates,
    userInfo: userSelectors.userInfo(state)
});

const mapDispatchToProps = (dispatch: any) => ({
    createMultiplePurchaseOrderDocument: (purchaseOrderNumber: string, documents: Array<document>) =>
        dispatch.purchaseOrders.createMultiplePurchaseOrderDocument({ purchaseOrderNumber, documents }),
    createMultiplePurchaseOrderDocumentSupplierUpdates: (purchaseOrderId: string, supplierName: string, documents: Array<document>) =>
        dispatch.purchaseOrders.createMultiplePurchaseOrderDocumentSupplierUpdates({ purchaseOrderId, supplierName, documents })
});

export default connect(mapStateToProps, mapDispatchToProps)(PurchaseOrderMultipleDocumentFormConnected);
