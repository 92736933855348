import React, { useEffect, useState } from 'react';
import { event } from '../../../../../models/entities/common_subentities/event';
import { styled } from '../../../../shared/Theme/theme';
import { Form, Formik } from 'formik';
import Button from '../../../../shared/SmallComponents/Button';
import { ModalFooter, ModalFormContainer } from '../../../../shared/Modal/Modal';
import Loader from '../../../../shared/SmallComponents/Loader';
import { MaterialUiPickersDate } from '@material-ui/pickers/typings/date';
import StaticDateTimePicker from '../../../../shared/StaticDateTimePicker/StaticDateTimePicker';
import { FormCheckbox, FormDropDown, FormTextArea } from '../../../../shared/inputs/form';
import FieldLabel from '../../../../shared/SmallComponents/FieldLabel';

export enum formMode {
    NEW,
    EDIT
}

interface IHaveId {
    id: string;
}

interface IEventSettings {
    eventTypes?: { code: string; name: string; description: string }[];
}

interface formFields {
    code?: string;
    comment?: string;
    date?: Date;
    isConfidential?: boolean;
    sendByMail?: boolean;
}
type Props<entityType, settingsType = any> = {
    entity?: entityType;
    event?: event;
    mode: formMode;
    localizations?: any;
    settings?: settingsType;
    openedFromModal?: {
        setVisible: React.Dispatch<React.SetStateAction<boolean>>;
    };
    confirmation?: { setConfirm: React.Dispatch<React.SetStateAction<boolean>> };
    createEvent: (entityId: string, event: event) => void;
    updateEvent: (entityId: string, event: event) => void;
    showLoader?: boolean;
    UserCompanyID?: string;
};

let AFTER_LOADING: boolean = false;
function EventFormConnected<entityType extends IHaveId, settingsType extends IEventSettings = any>({
    entity,
    event,
    localizations,
    mode,
    settings,
    openedFromModal,
    confirmation,
    createEvent,
    updateEvent,
    showLoader,
    UserCompanyID
}: Props<entityType, settingsType>) {
    const [submitLoading, setLoading] = useState(false);

    const [eventDate, setEventDate] = useState((mode === formMode.EDIT && event?.eventDate) || new Date());
    const onChangeEventDate = (date: MaterialUiPickersDate) => {
        if (date) {
            setEventDate(date.toDate());
        }
    };

    const eventTypesOptions = settings?.eventTypes?.map((item) => ({
        text: item.name,
        value: item.code
    }));

    const initFormValues: formFields = {
        code: '',
        comment: '',
        date: undefined,
        isConfidential: false,
        sendByMail: false
    };
    const setInitialValues = () => {
        if (!event || mode === formMode.NEW) {
            return initFormValues;
        } else {
            initFormValues.code = event.eventCode;
            initFormValues.comment = event.comment;
            initFormValues.date = event.eventDate;
            initFormValues.isConfidential = false;
            initFormValues.sendByMail = false;
            return initFormValues;
        }
    };
    const validate = (values: any) => {
        if (confirmation) {
            if (JSON.stringify(values) === JSON.stringify(initFormValues)) {
                confirmation.setConfirm(false);
            } else {
                confirmation.setConfirm(true);
            }
        }

        const errors: formFields = {};
        if (!values.code) errors.code = localizations.events.form.required_error;
        if (values.comment.length > 1000) errors.comment = localizations.events.form.comment_max_length_error;
        return errors;
    };
    const submit = (values: any) => {
        if (entity) {
            let payload: event;
            if (values.isConfidential == true) {
                payload = {
                    eventCode: values.code,
                    eventDate: eventDate,
                    comment: values.comment,
                    displayOnlyForCompanyId: UserCompanyID,
                    sendByMail: values.sendByMail
                } as event;
            } else {
                payload = {
                    eventCode: values.code,
                    eventDate: eventDate,
                    comment: values.comment,
                    sendByMail: values.sendByMail
                } as event;
            }
            if (mode === formMode.NEW) createEvent(entity.id, payload);
            else if (event)
                if (values.isConfidential == true) {
                    updateEvent(entity.id, {
                        id: event.id,
                        eventCode: values.code,
                        eventDate: eventDate,
                        comment: values.comment,
                        sendByMail: values.sendByMail,
                        userId: event.userId,
                        displayOnlyForCompanyId: UserCompanyID
                    } as event);
                } else {
                    updateEvent(entity.id, {
                        id: event.id,
                        eventCode: values.code,
                        eventDate: eventDate,
                        comment: values.comment,
                        sendByMail: values.sendByMail,
                        userId: event.userId
                    } as event);
                }
        }
    };
    useEffect(() => {
        if (showLoader) {
            setLoading(true);
            AFTER_LOADING = true;
        } else {
            setLoading(false);
            if (AFTER_LOADING && openedFromModal) {
                openedFromModal.setVisible(false);
                AFTER_LOADING = false;
            }
        }
    }, [showLoader, openedFromModal]);
    return (
        <Formik initialValues={setInitialValues()} validate={validate} onSubmit={submit}>
            <Form>
                <Container>
                    <FieldLabel>{localizations.events.form.eventtype}</FieldLabel>
                    <FieldContainer>
                        <FormDropDown name="code" placeHolder={localizations.events.form.eventtype_placeholder} options={eventTypesOptions || []} />
                    </FieldContainer>
                    <FieldLabel>{localizations.events.form.comment}</FieldLabel>
                    <FieldContainer>
                        <FormTextArea name="comment" placeHolder={localizations.events.form.comments_placeholder} />
                    </FieldContainer>
                    <FieldContainer>
                        <div style={{ display: 'flex', alignItems: 'center', marginLeft: '3px' }}>
                            <FormCheckbox style={{ color: 'red' }} name="isConfidential">
                                {localizations.events.form.isConfidential}
                            </FormCheckbox>
                            <FormCheckbox style={{ color: '#008ac9', marginLeft: '40px' }} name="sendByMail">
                                {localizations.events.form.sendByMail}
                            </FormCheckbox>
                        </div>
                    </FieldContainer>
                    <FieldContainer>
                        <StaticDateTimePicker value={eventDate} onChange={onChangeEventDate} />
                    </FieldContainer>
                </Container>
                {openedFromModal && (
                    <FormFooter>
                        <Button minWidth="55px" onClick={() => openedFromModal.setVisible(false)}>
                            <span>{localizations.events.modal.cancel_btn}</span>
                        </Button>
                        <Button minWidth="55px" buttonType="filled" type="submit">
                            {submitLoading ? (
                                <Loader width="20px" marginTop="0px" showText={false} />
                            ) : (
                                <span>{mode === formMode.NEW ? localizations.events.modal.add_btn : localizations.events.modal.edit_btn}</span>
                            )}
                        </Button>
                    </FormFooter>
                )}
            </Form>
        </Formik>
    );
}
const FieldContainer = styled.div`
    margin-bottom: 12px;
`;

const Container = styled(ModalFormContainer)``;

const FormFooter = styled(ModalFooter)`
    position: absolute;
    bottom: 0;
    width: 100%;
    left: 0;
    right: 0;
    background: white;
`;

export default EventFormConnected;
