import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { internalFlowInventoryLogs, internalFlowInventoryLogsGridDataType } from '../../../models/entities/product/inventoryLog';
import { drawers } from '../../../state/ducks/header/header';
import { gridSortState } from '../../../state/ducks/internalFlowInventoryLogs/internalFlowInventoryLogs';
import { RootState } from '../../../state/store/store';
import InternalFlowInventoryLogsPage from './InternalFlowInventoryLogsPage';
import { internalFlowInventoryLogsSelectors } from '../../../state/ducks/internalFlowInventoryLogs/selectors';
import { ColumnType } from '../../shared/Grid/types/Column';
import { localizationSelectors } from '../../../state/ducks/localization/selectors';

type Props = {
    internalFlowInventoryLogsGridData: internalFlowInventoryLogsGridDataType[];
    gridColumns: Array<ColumnType<internalFlowInventoryLogsGridDataType>>;
    currentGridSort: gridSortState | null;
    showLoader: boolean;
    localization: any;
    setGridSort: (sortData: gridSortState) => void;
    fetchGridColumns: () => void;
    fetchInternalFlowInventoryLogs: () => void;
    setDrawerOpen: (open: drawers, params?: any) => void;
};

const InternalFlowInventoryLogsPageConnected = ({
    internalFlowInventoryLogsGridData,
    gridColumns,
    currentGridSort,
    showLoader,
    localization,
    setGridSort,
    fetchGridColumns,
    fetchInternalFlowInventoryLogs,
    setDrawerOpen
}: Props) => {
    useEffect(() => {
        fetchInternalFlowInventoryLogs();
        fetchGridColumns();
    }, []);

    const DEFAULT_SORT_DIRECTION = 'asc';
    const SECOND_SORT_DIRECTION = 'desc';

    const onGridColumnClick = (newColumn: keyof internalFlowInventoryLogsGridDataType) => {
        if (currentGridSort) {
            const { direction, column } = currentGridSort;
            if (column === newColumn)
                if (direction === DEFAULT_SORT_DIRECTION)
                    setGridSort({
                        column: newColumn,
                        direction: SECOND_SORT_DIRECTION
                    });
                else
                    setGridSort({
                        column: newColumn,
                        direction: DEFAULT_SORT_DIRECTION
                    });
            else {
                setGridSort({
                    column: newColumn,
                    direction: DEFAULT_SORT_DIRECTION
                });
            }
        } else {
            setGridSort({
                column: newColumn,
                direction: DEFAULT_SORT_DIRECTION
            });
        }
    };

    return (
        <InternalFlowInventoryLogsPage
            internalFlowInventoryLogsGridData={internalFlowInventoryLogsGridData}
            gridColumns={gridColumns}
            currentGridSort={currentGridSort}
            gridSortDirection={currentGridSort?.direction}
            gridSortedBy={currentGridSort?.column}
            showLoader={showLoader}
            localization={localization}
            setDrawerOpen={setDrawerOpen}
            onGridColumnClick={onGridColumnClick}
        />
    );
};

const mapStateToProps = (state: RootState) => ({
    internalFlowInventoryLogsGridData: internalFlowInventoryLogsSelectors.currentGridDataPage(state),
    gridColumns: internalFlowInventoryLogsSelectors.gridColumns(state),
    currentGridSort: internalFlowInventoryLogsSelectors.gridSort(state),
    localization: localizationSelectors.internalFlowInventory(state),
    showLoader:
        state.loading.effects.internalFlowInventoryLogs.fetchGridColumns ||
        state.loading.effects.internalFlowInventoryLogs.fetchInternalFlowInventoryLogs
});

const mapDispatchToProps = (dispatch: any) => ({
    fetchInternalFlowInventoryLogs: () => dispatch.internalFlowInventoryLogs.fetchInternalFlowInventoryLogs(),
    fetchGridColumns: () => dispatch.internalFlowInventoryLogs.fetchGridColumns(),
    setGridSort: (sortData: gridSortState) => dispatch.internalFlowInventoryLogs.setGridSort(sortData),
    setDrawerOpen: (open: drawers, params?: any) => dispatch.header.setDrawerOpenParams({ open, params })
});

export default connect(mapStateToProps, mapDispatchToProps)(InternalFlowInventoryLogsPageConnected);
