import { Button } from 'antd';
import React from 'react';
import { Link } from 'react-router-dom';
import styled from 'styled-components';
import { featureFlagsModel } from '../../../../../models/entities/businessPartner/settings';
import { company } from '../../../../../models/entities/company/company';
import { ColumnType } from '../../../../shared/Grid/types/Column';

const companiesGridColumns: (
    localization: any,
    getLwaState: (companyId: string) => Promise<string>,
    featureFlags?: featureFlagsModel
) => Array<ColumnType<company>> = (localization: any, getLwaState: (companyId: string) => Promise<string>, featureFlags?: featureFlagsModel) => [
    {
        field: 'name',
        width: '200px',
        sortable: true,
        isDisplayed: true,
        textAlign: 'center'
    },
    {
        field: 'vatNumber',
        width: '100%',
        sortable: true,
        textAlign: 'center',
        minWidth: '150px'
    },
    {
        field: 'type',
        width: '100%',
        sortable: true,
        minWidth: '150px'
    },
    {
        field: 'address',
        width: '100%',
        minWidth: '150px',
        sortable: true
    },
    {
        field: 'phoneNumber',
        width: '100%',
        sortable: true
    },
    {
        field: 'createdAt',
        width: '100%',
        columnType: 'date',
        sortable: false
    },
    {
        field: 'color',
        width: '100%',
        sortable: false,
        renderer: (cellValue: any, bindedData: any) => <div style={{ color: cellValue }}>{cellValue || '-'}</div>
    },
    {
        field: 'sellingPartnerId',
        width: '100%',
        bindedFields: ['id'],
        sortable: false,
        hideTitleAttribute: true,
        textAlign: 'center',
        isDisplayed: !!featureFlags?.DEV && !!featureFlags?.AMAZON,
        renderer: (cellValue: any, bindedData: any) =>
            cellValue ? (
                <GreenText>{localization.api_connection.connected}</GreenText>
            ) : (
                <Button
                    type="primary"
                    onClick={async (e) => {
                        e.stopPropagation();
                        if (bindedData?.[0]) {
                            const state = await getLwaState(bindedData?.[0]);
                            window.location.href = `${process.env.REACT_APP_SP_API_AUTHORIZE_CARGOZONE}&state=${encodeURIComponent(state)}`;
                        }
                    }}
                >
                    {localization.api_connection.connect}
                </Button>
            )
    },
    {
        field: 'adsApiRefreshToken',
        width: '100%',
        bindedFields: ['id'],
        sortable: false,
        hideTitleAttribute: true,
        textAlign: 'center',
        isDisplayed: !!featureFlags?.DEV && !!featureFlags?.AMAZON,
        renderer: (cellValue: any, bindedData: any) =>
            cellValue ? (
                <GreenText>{localization.api_connection.connected}</GreenText>
            ) : (
                <Button
                    type="primary"
                    onClick={async (e) => {
                        e.stopPropagation();
                        if (bindedData?.[0]) {
                            const state = await getLwaState(bindedData?.[0]);
                            window.location.href = `${process.env.REACT_APP_ADS_API_AUTHORIZATION_URL}&state=${encodeURIComponent(state)}`;
                        }
                    }}
                >
                    {localization.api_connection.connect}
                </Button>
            )
    }
];

const GreenText = styled.div`
    color: #629e31;
`;

export default companiesGridColumns;
