import React, { useEffect, useState } from 'react';
import { settings, businessPartner } from '../../../../../models/entities/businessPartner/businessPartner';
import { styled } from '../../../../shared/Theme/theme';
import { Form, Formik } from 'formik';
import { localizationSelectors } from '../../../../../state/ducks/localization/selectors';
import { RootState } from '../../../../../state/store/store';
import { connect } from 'react-redux';
import Button from '../../../../shared/SmallComponents/Button';
import { ModalFooter, ModalFormContainer } from '../../../../shared/Modal/Modal';
import Loader from '../../../../shared/SmallComponents/Loader';
import { document } from '../../../../../models/entities/businessPartner/document';
import { FormDropDown, FormTextArea, FormUploadInput } from '../../../../shared/inputs/form';
import FieldLabel from '../../../../shared/SmallComponents/FieldLabel';
import { businessPartnersSelectors } from '../../../../../state/ducks/businessPartner/selectors';
import { singleBusinessPartnersSelectors } from '../../../../../state/ducks/singleBusinessPartner/selectors';

export enum formMode {
    NEW,
    EDIT
}

interface formFields {
    type?: string;
    comment?: string;
    file?: File;
}
interface formFieldsErrors {
    type?: string;
    comment?: string;
    file?: string;
}
type Props = {
    businessPartner?: businessPartner;
    document?: document;
    mode: formMode;
    localizations?: any;
    settings?: settings;
    openedFromModal?: {
        setVisible: React.Dispatch<React.SetStateAction<boolean>>;
    };
    confirmation?: { setConfirm: React.Dispatch<React.SetStateAction<boolean>> };
    createBusinessPartnerDocument: (businessPartnerId: string, document: document) => void;
    updateBusinessPartnerDocument: (businessPartnerId: string, document: document) => void;
    showLoader?: boolean;
    defaultValues?: document;
};

let AFTER_LOADING: boolean = false;
const DocumentFormConnected = ({
    businessPartner,
    document,
    localizations,
    mode,
    settings,
    openedFromModal,
    confirmation,
    createBusinessPartnerDocument,
    updateBusinessPartnerDocument,
    showLoader,
    defaultValues
}: Props) => {
    const [submitLoading, setLoading] = useState(false);
    const documentTypesOptions = settings?.documentTypes?.map((item) => ({
        text: item.name,
        value: item.code
    }));
    let initFormValues: formFields = {
        type: '',
        comment: '',
        file: undefined
    };
    const setInitialValues = () => {
        if (!document || mode === formMode.NEW) {
            if (defaultValues) initFormValues = defaultValues;
            return initFormValues;
        } else {
            initFormValues.type = document.type;
            initFormValues.comment = document.comment;
            initFormValues.file = { name: document.fileName, type: document.fileType } as File;
            return initFormValues;
        }
    };
    const validate = (values: any) => {
        if (confirmation) {
            if (JSON.stringify(values) === JSON.stringify(initFormValues)) {
                confirmation.setConfirm(false);
            } else {
                confirmation.setConfirm(true);
            }
        }
        const errors: formFieldsErrors = {};
        if (!values.type) errors.type = localizations.documents.form.required_error;
        if (!values.file) errors.file = localizations.documents.form.required_error;
        return errors;
    };
    const submit = (values: any) => {
        if (businessPartner) {
            const payload: document = {
                type: values.type,
                comment: values.comment,
                fileName: values.file.name,
                fileType: values.file.type,
                file: values.file
            } as document;
            if (mode === formMode.NEW) createBusinessPartnerDocument(businessPartner.id, payload);
            else if (document) {
                updateBusinessPartnerDocument(businessPartner.id, {
                    ...document,
                    ...payload,
                    id: document.id
                });
            }
        }
    };
    useEffect(() => {
        if (showLoader) {
            setLoading(true);
            AFTER_LOADING = true;
        } else {
            setLoading(false);
            if (AFTER_LOADING && openedFromModal) {
                openedFromModal.setVisible(false);
                AFTER_LOADING = false;
            }
        }
    }, [showLoader, openedFromModal]);
    return (
        <Formik initialValues={setInitialValues()} validate={validate} onSubmit={submit}>
            <Form>
                <Container>
                    <FieldLabel>{localizations.documents.form.documenttype}</FieldLabel>
                    <FieldContainer>
                        <FormDropDown
                            minWidth="160px"
                            name="type"
                            placeHolder={localizations.documents.form.documenttype_placeholder}
                            options={documentTypesOptions || []}
                        />
                    </FieldContainer>
                    <FieldLabel>{localizations.documents.form.comment}</FieldLabel>
                    <FieldContainer>
                        <FormTextArea name="comment" placeHolder={localizations.documents.form.comment_placeholder} />
                    </FieldContainer>
                    <FieldContainer>
                        <FormUploadInput name="file" placeHolder={localizations.documents.form.addfile_placeholder} id="file-upload" />
                    </FieldContainer>
                </Container>
                {openedFromModal && (
                    <FormFooter>
                        <Button minWidth="55px" buttonType="filled" type="submit">
                            {submitLoading ? (
                                <Loader width="20px" marginTop="0px" showText={false} />
                            ) : (
                                <span>{localizations.documents.modal.submit_btn}</span>
                            )}
                        </Button>
                    </FormFooter>
                )}
            </Form>
        </Formik>
    );
};
const FieldContainer = styled.div`
    margin-bottom: 12px;
`;

const Container = styled(ModalFormContainer)``;

const FormFooter = styled(ModalFooter)`
    position: absolute;
    bottom: 0;
    width: 100%;
    left: 0;
    right: 0;
    background: white;
    justify-content: flex-end;
`;
const mapStateToProps = (state: RootState) => ({
    localizations: localizationSelectors.SingleBusinessPartnerPage(state),
    settings: businessPartnersSelectors.settingsByLanguage(state),
    businessPartner: singleBusinessPartnersSelectors.businessPartner(state),
    showLoader:
        state.loading.effects.businessPartners.createBusinessPartnerDocument || state.loading.effects.businessPartners.updateBusinessPartnerDocument
});

const mapDispatchToProps = (dispatch: any) => ({
    createBusinessPartnerDocument: (businessPartnerId: string, document: document) =>
        dispatch.businessPartners.createBusinessPartnerDocument({ businessPartnerId, document }),
    updateBusinessPartnerDocument: (businessPartnerId: string, document: document) =>
        dispatch.businessPartners.updateBusinessPartnerDocument({ businessPartnerId, document })
});

export default connect(mapStateToProps, mapDispatchToProps)(DocumentFormConnected);
