import React from 'react';
import styled from 'styled-components';
import { marketplaceAsin } from '../../../../../models/entities/product/product';
import CountryOption from '../../../../shared/SmallComponents/CountryOption';
import { flatMarketplaces } from '../../../Admin/Companies/SingleCompanyPage/utils';

type Props = {
    asin: marketplaceAsin[];
    singleAsinPaddingBottom?: number;
};

const getAmazonLink = (asin: string, website: string) => `https://www.${website}/dp/${asin}`;

const AsinView = ({ asin, singleAsinPaddingBottom }: Props) => {
    return (
        <div style={{ maxHeight: 105, paddingTop: 5, overflowY: 'auto' }}>
            {asin?.map((a: marketplaceAsin) => {
                const marketplace = flatMarketplaces.find((m) => m.marketplaceId === a.marketplaceId);
                return (
                    <AsinWrapper
                        onClick={() => window.open(getAmazonLink(a.asin, marketplace?.website || 'amazon.com'), '_blank', 'noopener,noreferrer')}
                    >
                        <CountryOption
                            country={a.asin}
                            code={marketplace?.countryCode || ''}
                            removeBorder
                            paddingBottom={singleAsinPaddingBottom !== undefined ? singleAsinPaddingBottom : 10}
                            flagTooltip={marketplace?.country}
                            color="#008ac9"
                        />
                    </AsinWrapper>
                );
            }) || '-'}
        </div>
    );
};

const AsinWrapper = styled.div`
    cursor: pointer;
`;
export default AsinView;
