import BaseController from '../..';
import IPurchaseOrderHistory from './IPurchaseOrderHistory';

export default class PurchaseOrderHistory extends BaseController implements IPurchaseOrderHistory {
    constructor(baseUrl: string) {
        super(baseUrl);
    }
    async fetchPurchaseOrderHistoryById(purchaseOrderId: string) {
        const response = await this.client.get(`/purchaseOrder/history/${purchaseOrderId}`);
        return response;
    }
}
