import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { DropDown } from '.';
import { userDropDown, userInfo } from '../../../../models/entities/user';
import { userSelectors } from '../../../../state/ducks/user/selectors';
import { usersSelectors } from '../../../../state/ducks/users/selectors';
import { userDetails } from '../../../../state/ducks/users/users';
import { RootState } from '../../../../state/store/store';
import { UserImageAvatar } from '../../Layout/Header/AvatarHeader';
import HexagonAvatar from '../../SmallComponents/HexagonAvatar';
import Loader from '../../SmallComponents/Loader';
import { styled } from '../../Theme/theme';
import { Option } from './DropDown';

type Props = {
    usersInSameNetworkIncludeSelf?: Array<userDetails>;
    getUserPicture: (userId: string) => string;
    isLoadingUserPicture: boolean;
    onChange?: any;
    onBlur?: any;
    value: string | string[] | undefined;
    addUser: (userId: string) => Promise<userDetails>;
    hasError?: boolean;
    placeHolder?: string;
    showSearch?: boolean;
    mode?: 'multiple' | 'tags';
    users?: Array<userDetails>;
    customUsers?: boolean;
    usersToExclude?: string[];
};

const UserDropDownBase = ({
    getUserPicture,
    isLoadingUserPicture,
    users,
    addUser,
    usersInSameNetworkIncludeSelf,
    customUsers,
    usersToExclude,
    ...restProps
}: Props) => {
    const ddlUsers = users || usersInSameNetworkIncludeSelf;
    const [filteredDllUsers, setFilteredDllUsers] = useState(ddlUsers);
    // useEffect(() => {
    //     if (ddlUsers) {
    //         ddlUsers.forEach((item) => {
    //             if (!getUserPicture(item.id)) addUser(item.id);
    //         });
    //         setFilteredDllUsers(ddlUsers);
    //     }
    // }, [ddlUsers, addUser]);

    useEffect(() => {
        setFilteredDllUsers(ddlUsers?.filter((u) => !usersToExclude?.includes(u.id)));
    }, [usersToExclude]);

    const renderUserOption = (option: Option) => {
        const splittedFullName = option.text?.split(' ');
        const avatarSize = 16;
        return (
            <OptionContainer>
                <HexagonAvatar
                    width={avatarSize}
                    img={
                        getUserPicture(option.value) ||
                        UserImageAvatar(
                            {
                                firstName: splittedFullName && splittedFullName[0],
                                lastName: splittedFullName && splittedFullName[1],
                                avatarColor: option.color
                            } as userDetails,
                            avatarSize
                        )
                    }
                    borderColor={'#e0e0e0'}
                    borderThickness={1}
                />
                <div style={{ marginLeft: '5px', marginRight: '6px', color: '#394372', fontWeight: 500 }}>{option.text}</div>
            </OptionContainer>
        );
    };

    return (
        <Container>
            <DropDown
                {...restProps}
                options={
                    filteredDllUsers?.map((item) => {
                        return { value: item.id, text: item.firstName + ' ' + item.lastName, color: item.avatarColor };
                    }) || []
                }
                optionRenderer={renderUserOption}
            />
            {!filteredDllUsers && (
                <LoaderWrapper>
                    <Loader marginTop="0" showText={false} width="20px" />
                </LoaderWrapper>
            )}
        </Container>
    );
};
const Container = styled.div`
    display: flex;
    align-items: center;
`;
const LoaderWrapper = styled.div`
    margin-left: 10px;
`;
const OptionContainer = styled.div`
    display: flex;
    align-items: center;
`;
const mapStateToProps = (state: RootState) => ({
    usersInSameNetworkIncludeSelf: userSelectors.usersInSameNetworkIncludeSelf(state),
    isLoadingUserPicture: state.loading.effects.users.getUserPicture,
    getUserPicture: (id: string) => usersSelectors.userPicture(state, id)
});

const mapDispatchToProps = (dispatch: any) => ({
    addUser: (userId: string) => dispatch.users.addUser(userId)
});

export default connect(mapStateToProps, mapDispatchToProps)(UserDropDownBase);
