import React from 'react';
import styled from 'styled-components';
import { chat } from '../../../../models/entities/chat/chat';
import DotsWaveAnimation from '../../../shared/Animations/DotsWaveAnimation';
import UserBadge from '../../../shared/UserBadge/UserBadge';
import GroupBadge from '../../../shared/GroupBadge/GroupBadge';
import { userDetails } from '../../../../state/ducks/users/users';
import { userInfo } from '../../../../models/entities/user';
import { getUserStatusTheme } from '../../../shared/Theme/util';
import moment from 'moment';
import unread from '../../../../static/icons/unread.svg';
import read from '../../../../static/icons/read.svg';
import parse from 'html-react-parser';

type Props = {
    chat: chat;
    userInfo: userInfo;
    isOpened: boolean;
    notReadMessagesCount: number | undefined;
    onClick: () => void;
    typingUsers: string[] | undefined;
    getUserById: (userId: string) => userDetails | undefined;
};
const ChatItem = ({ chat, userInfo, isOpened, notReadMessagesCount, onClick, typingUsers, getUserById }: Props) => {
    const { participants, id, messages, type } = chat;
    const participantsIds = participants.map((p) => p.userId);
    const userId = participantsIds.find((id) => id !== userInfo.id);
    const user = getUserById(userId || '');

    const itemContent = () => {
        if (type === 'user') {
            if (userId && user) {
                return (
                    <UserBadge
                        width={40}
                        userId={userId}
                        userName={user?.name || ''}
                        hideBadgeName={true}
                        borderColor={getUserStatusTheme(user?.status || 'OOTO').color}
                        popoverLeft={true}
                    />
                );
            }
        } else {
            return <GroupBadge chat={chat} popoverLeft={true} />;
        }
    };

    const lastChatMessage = messages[messages.length - 1];
    const typing = typingUsers;
    const isContactTyping = typing && typing.length > 0;
    const isCurrentUserMessage = userInfo.id === lastChatMessage.userId;

    return (
        <ItemContainer onClick={onClick} isOpenedChat={isOpened}>
            {itemContent()}

            <ItemDetails>
                <div className="username">{type === 'user' ? user?.name : chat.name}</div>
                <div className="last-message">
                    {isCurrentUserMessage && !isContactTyping && (
                        <img src={lastChatMessage.isRead ? read : unread} alt="" width={18} style={{ marginRight: '3px' }} />
                    )}
                    {isContactTyping ? (
                        <span className="typing">
                            Typing <DotsWaveAnimation dotSize={2} dotColor="#008ac9" />
                        </span>
                    ) : (
                        parse(lastChatMessage.content || '')
                    )}
                </div>
            </ItemDetails>
            <ItemMetadata>
                <div className="last-message-time">{moment(lastChatMessage.sendDate).format('HH:mm')}</div>
                {!isOpened && !!Number(notReadMessagesCount) && <NotReadBadge>{notReadMessagesCount}</NotReadBadge>}
            </ItemMetadata>
        </ItemContainer>
    );
};

interface ItemContainerProps {
    isOpenedChat?: boolean;
}

const NotReadBadge = styled.div`
    font-weight: bold;
    background-color: #d3445b;
    height: 0px;
    overflow: hidden;
    width: 18px;
    height: 18px;
    line-height: 18px;
    font-size: 10px;
    border-radius: 50%;
    color: white;
    text-align: center;
    margin-top: 3px;
`;
const ItemContainer = styled.div<ItemContainerProps>`
    height: 72px;
    padding: 11px 15px;
    display: flex;
    align-items: center;
    cursor: pointer;
    position: relative;
    border-bottom: 1px solid #e1e1e1;
    &:hover {
        background-color: #f1f1f1;
    }
    background-color: ${(props) => (props.isOpenedChat ? '#f1f1f1' : '#fff')};
`;
const ItemDetails = styled.div`
    display: flex;
    flex-direction: column;
    height: 100%;
    flex: 1;
    padding: 0 10px;
    .username {
        color: #252b4a;
        font-size: 16px;
    }
    .last-message {
        color: rgba(0, 0, 0, 0.5);
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        margin-top: 3px;
        display: flex;
        p {
            text-overflow: ellipsis;
            overflow: hidden;
            white-space: nowrap;
            margin-bottom: 0;
        }
        .typing {
            color: #008ac9;
            font-style: italic;
        }
    }
    overflow: hidden;
`;
const ItemMetadata = styled.div`
    justify-content: spa;
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    height: 100%;
    .last-message-time {
        color: #394372;
    }
`;

export default ChatItem;
