import React from 'react';
import { FormikProps } from 'formik';
import FormInputWrapper from '../../../../shared/Form/FormInputWrapper';
import { FormDatePicker } from '../../../../shared/inputs/form';
import { createTariffPayload } from '../../../../../models/entities/tariff';

type Props = {
    formikProps: FormikProps<createTariffPayload>;
    localization: any;
};

const Dates = ({ formikProps, localization }: Props) => {
    const fieldsLocalization = localization.fields;
    return (
        <>
            <FormInputWrapper fieldName="activationDate" localization={fieldsLocalization} isMandatory>
                <FormDatePicker
                    name="activationDate"
                    style={{ width: '200px' }}
                    placeHolder={fieldsLocalization.activationDate.placeholder}
                    showTime
                />
            </FormInputWrapper>
            <FormInputWrapper fieldName="expirationDate" localization={fieldsLocalization} isMandatory>
                <FormDatePicker
                    name="expirationDate"
                    style={{ width: '200px' }}
                    placeHolder={fieldsLocalization.expirationDate.placeholder}
                    showTime
                />
            </FormInputWrapper>
        </>
    );
};

export default Dates;
