import { gridModeType } from '../../../state/ducks/internalFlowInventoryLogs/internalFlowInventoryLogs';
import { marketplaceAsin, product } from './product';
import { amazonInventory } from './amazonInventory';

export type inventoryLogEntityType = 'PD' | 'PO' | 'SHIPMENT';
export type inventoryActionType = 'ON_HAND' | 'RESERVED' | 'INBOUND' | 'OUTBOUND' | 'ADD_STOCK' | 'SET_STOCK';

export type inventoryLog = {
    id: string;
    productId: string;
    cost: string;
    costCurrency: string;
    userId: string;
    warehouseId: string;
    location: string;
    actionType: inventoryActionType;
    quantity: number;
    reason: string;
    createdAt: Date;
    updatedAt: Date;
    entityId?: string;
    entityItemId?: number;
    entityInactive?: boolean;
    entityType?: inventoryLogEntityType;
    entityCzNumber?: string;
    length?: string;
    width?: string;
    height?: string;
    dimensionUnit?: string;
    weight?: string;
    weightUnit?: string;
};

export type internalFlowInventoryLogs = {
    [productId: string]: {
        product: product;
        internalFlow: {
            [actionType in inventoryActionType]?: { [key in inventoryLogEntityType]: inventoryLog[] };
        };
        amazon?: amazonInventory[];
        manual: {
            [actionType in inventoryActionType]?: inventoryLog[];
        };
    };
};

export type amountByEntityType = {
    id: string;
    entityId: string;
    entityCzNumber: string;
    entityType: inventoryLogEntityType;
    amount: number;
    currency?: string;
    volumeUnit?: string;
};

export type amountByMarketplace = {
    amount: number;
    marketplaceId: string;
    currency?: string;
    volumeUnit?: string;
};

export type internalFlowInventoryLogsGridDataType = {
    productId: string;
    name: string;
    sku: string;
    fnsku: string;
    productImgUrl?: string;
    asin: marketplaceAsin[];
    pdReservedTotal: number;
    poInboundTotal: number;
    shipmentInboundTotal: number;
    pdReservedTotalUnit?: string;
    poInboundTotalUnit?: string;
    shipmentInboundTotalUnit?: string;
    reservedAmountByPDs: amountByEntityType[];
    inboundAmountByPOs: amountByEntityType[];
    inboundAmountByShipments: amountByEntityType[];
    gridMode: gridModeType;
    amazonOnHand: amountByMarketplace[];
    amazonOnHandTotal: number;
    amazonOnHandTotalUnit?: string;
    amazonInbound: amountByMarketplace[];
    amazonInboundTotal: number;
    amazonInboundTotalUnit?: string;
    amazonReserved: amountByMarketplace[];
    amazonReservedTotal: number;
    amazonReservedTotalUnit?: string;
    manualOnHand: number;
    manualOnHandUnit?: string;
    manualInbound: number;
    manualInboundUnit?: string;
    manualReserved: number;
    manualReservedUnit?: string;
};

export type inventoryTotalView = {
    id: string;
    productId: string;
    productName: string;
    productAsin?: marketplaceAsin[];
    productFnsku?: string;
    productImgId?: string;
    productImgUrl?: string;
    sku: string;
    status: string;
    reserved: number;
    onHand: number;
    inbound: number;
    outbound: number;
    available: number;
    unavailable: number;
    updatedAt: Date;
};
export type inventoryTotal = {
    id: string;
    productId: string;
    warehouseId: string;
    warehouseName: string;
    reserved: number;
    onHand: number;
    inbound: number;
    outbound: number;
    available: number;
    updatedAt: Date;
    createdAt: Date;
};

export const eInventoryActionType: { [key: string]: string } = {
    ON_HAND: 'On Hand',
    INBOUND: 'Inbound',
    RESERVED: 'Reserved'
    // OUTBOUND: 'Outbound',
    // ADD_STOCK: 'Add stock',
    // SET_STOCK: 'Set stock'
};
