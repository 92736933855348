import React, { useState } from 'react';
import moment from 'moment';
import { orderEvent } from '../../../../../models/entities/order/orderEvent/orderEvent';
import CustomModal, { ModalTriggerProps } from '../../../../shared/Modal/Modal';
import clock from '../../../../../static/icons/clock.svg';
import TimeLine, { TimeLineItem } from '../../../singleShipment/sections/Events/express/TimeLine';
import EventFormConnected from './EventFormConnected';
import { formMode } from '../../../commonSubEntities/events/EventForm/EventFormConnected';
import styled from 'styled-components';
import Button from '../../../../shared/SmallComponents/Button';
import { event } from '../../../../../models/entities/common_subentities/event';
import { Modal } from 'antd';
import { deleteOrderEventPayload } from '../../../../../models/entities/order/orderEvent/payloads';

type Props = {
    eventsTabData: TimeLineItem<orderEvent>[];
    localization: any;
    deleteOrderEvent: (payload: deleteOrderEventPayload) => Promise<void>;
};

const Events = ({ eventsTabData, localization, deleteOrderEvent }: Props) => {
    const [editModalEvent, setEditModalEvent] = useState<orderEvent | undefined>(undefined);
    return (
        <>
            <CustomModal
                title={{ icon: clock, text: localization.events.add_event }}
                isOpen={!!editModalEvent}
                Trigger={(props: ModalTriggerProps) => (
                    <ButtonContainer>
                        <Button onClick={props.onClick} width="auto" buttonType="hollow" padding="4px 12px">
                            {localization.events.add_event}
                        </Button>
                    </ButtonContainer>
                )}
                onCancelCallback={() => setEditModalEvent(undefined)}
                Content={(setVisible?: React.Dispatch<React.SetStateAction<boolean>>, setConfirm?: React.Dispatch<React.SetStateAction<boolean>>) => {
                    if (editModalEvent !== undefined) {
                        const eventToEdit: event = {
                            id: editModalEvent.id as any,
                            entityId: editModalEvent.orderId,
                            userId: editModalEvent.userId,
                            eventCode: editModalEvent.code,
                            description: editModalEvent.description,
                            userName: editModalEvent.userName,
                            comment: editModalEvent.comment,
                            displayOnlyForCompanyId: '',
                            createdAt: editModalEvent.createdAt,
                            eventDate: editModalEvent.eventDate
                        };
                        return (
                            <EventFormConnected
                                mode={formMode.EDIT}
                                event={eventToEdit}
                                openedFromModal={setVisible ? { setVisible } : undefined}
                                confirmation={setConfirm ? { setConfirm } : undefined}
                                onSubmit={() => setEditModalEvent(undefined)}
                            />
                        );
                    }
                    return (
                        <EventFormConnected
                            mode={formMode.NEW}
                            openedFromModal={setVisible ? { setVisible } : undefined}
                            confirmation={setConfirm ? { setConfirm } : undefined}
                        />
                    );
                }}
                width="393px"
            />

            {!!eventsTabData?.length ? (
                <TimeLine<orderEvent>
                    data={eventsTabData}
                    onEdit={(event?: orderEvent) => {
                        if (event) {
                            setEditModalEvent(event);
                        }
                    }}
                    onDelete={(event?: orderEvent) => {
                        if (event) {
                            Modal.confirm({
                                zIndex: 1100,
                                content: <div>{localization.are_you_sure}</div>,
                                async onOk() {
                                    await deleteOrderEvent({ orderId: event.orderId, eventId: event.id });
                                },
                                onCancel() {}
                            });
                        }
                    }}
                />
            ) : (
                <Empty>{localization.events.empty_state}</Empty>
            )}
        </>
    );
};

const ButtonContainer = styled.div`
    display: flex;
    justify-content: flex-end;
    padding-top: 8px;
`;

const Empty = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
`;

export default Events;
