import React, { useEffect } from 'react';
import SingleBidPage from './SingleBidPage';
import { RouteComponentProps, withRouter } from 'react-router-dom';
import { Dispatch, RootState } from '../../../../state/store/store';
import Loader from '../../../shared/SmallComponents/Loader';
import { singleBidSelectors } from '../../../../state/ducks/singleBid/selectors';
import { connect } from 'react-redux';
import { bidShipment, settings } from '../../../../models/entities/bid/bid';
import { localizationSelectors } from '../../../../state/ducks/localization/selectors';
import { bidsSelectors } from '../../../../state/ducks/bids/selectors';

type PathParams = {
    bidId: string;
};

type Props = RouteComponentProps<PathParams> & {
    bid: bidShipment | undefined;
    setCurrentBidId: (currentBidId: string) => void;
    fetchSingleBid: (bidId: string) => void;
    fetchShipments: () => void;
    localizations?: any;
    startBid: (bidId: string) => void;
    startBidLoader: boolean;
    settings?: settings;
};

const SingleBidPageConnected = ({ bid, setCurrentBidId, fetchSingleBid, localizations, match, startBid, startBidLoader, settings }: Props) => {
    const urlBidId = match.params.bidId;
    useEffect(() => {
        setCurrentBidId(urlBidId);
        fetchSingleBid(urlBidId);
    }, [urlBidId, setCurrentBidId, fetchSingleBid]);
    return (
        <>
            {bid ? (
                <SingleBidPage
                    bid={bid}
                    localization={localizations}
                    shipment={bid.shipment}
                    startBid={startBid}
                    startBidLoader={startBidLoader}
                    settings={settings}
                />
            ) : (
                <Loader />
            )}
        </>
    );
};

const mapStateToProps = (state: RootState) => ({
    bid: singleBidSelectors.bid(state),
    quotations: singleBidSelectors.quotations(state),
    localizations: localizationSelectors.bid(state),
    settings: bidsSelectors.settingsByLanguage(state),
    startBidLoader: state.loading.effects.bids.startBid
});

const mapDispatchToProps = (dispatch: Dispatch) => ({
    setCurrentBidId: (currentBidId: string) => dispatch.singleBid.setCurrentBidId(currentBidId),
    fetchSingleBid: (bidId: string) => dispatch.bids.fetchBidById(bidId),
    startBid: (bidId: string) => dispatch.bids.startBid(bidId)
});

export default withRouter(connect(mapStateToProps, mapDispatchToProps as any)(SingleBidPageConnected));
