import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { RouteComponentProps } from 'react-router-dom';
import { tariff, createTariffPayload } from '../../../models/entities/tariff';
import { businessPartnersSelectors } from '../../../state/ducks/businessPartner/selectors';
import { localizationSelectors } from '../../../state/ducks/localization/selectors';
import { singleTariffSelectors } from '../../../state/ducks/singleTariff/selectors';
import { userSelectors } from '../../../state/ducks/user/selectors';
import { RootState } from '../../../state/store/store';
import { Option } from '../../shared/inputs/base/DropDown';
import SingleTariffPage from './SingleTariffPage';

type PathParams = {
    tariffId: string;
};

type Props = RouteComponentProps<PathParams> & {
    tariff?: tariff;
    allowedCompaniesDropDownOptions: Option[];
    businessPartnersDropDownOptions: Option[];
    allowedShipmentTypes: Array<string>;
    localization: any;
    setCurrentTariffId: (currentTariffId: string | null) => void;
    fetchSingleTariff: (tariffId: string) => Promise<tariff>;
    updateTariff: (tariffId: string, tariff: createTariffPayload) => Promise<void>;
};

const SingleTatiffPageConnected = ({
    localization,
    tariff,
    match,
    allowedCompaniesDropDownOptions,
    businessPartnersDropDownOptions,
    allowedShipmentTypes,
    setCurrentTariffId,
    fetchSingleTariff,
    updateTariff
}: Props) => {
    const tariffId = match.params.tariffId;

    useEffect(() => {
        setCurrentTariffId(tariffId);
        fetchSingleTariff(tariffId);
    }, [tariffId, setCurrentTariffId, fetchSingleTariff]);

    if (!tariff) return <div>...</div>;

    return (
        <SingleTariffPage
            tariff={tariff}
            allowedCompaniesDropDownOptions={allowedCompaniesDropDownOptions}
            businessPartnersDropDownOptions={businessPartnersDropDownOptions}
            allowedShipmentTypes={allowedShipmentTypes}
            localization={localization}
            updateTariff={updateTariff}
        />
    );
};
const mapStateToProps = (state: RootState) => ({
    localization: localizationSelectors.singleTariff(state),
    tariff: singleTariffSelectors.tariff(state),
    allowedCompaniesDropDownOptions: userSelectors.allowedCompaniesDropDownOptions(state),
    businessPartnersDropDownOptions: businessPartnersSelectors.businessPartnersDropDownOptions(state),
    allowedShipmentTypes: userSelectors.allowedTypes(state)
});

const mapDispatchToProps = (dispatch: any) => ({
    setCurrentTariffId: (currentTariffId: string | null) => dispatch.singleTariff.setCurrentTariffId(currentTariffId),
    fetchSingleTariff: (tariffId: string) => dispatch.tariffs.fetchTariffById(tariffId),
    updateTariff: (tariffId: string, tariff: createTariffPayload) => dispatch.tariffs.updateTariff({ tariffId, tariff })
});

export default connect(mapStateToProps, mapDispatchToProps)(SingleTatiffPageConnected);
