import React from 'react';
import { GridCellParams, GridColDef } from '@material-ui/data-grid';
import { PagesRoutes } from '../../../../../../routing/PagesRoutes';
import { Link } from 'react-router-dom';
import moment from 'moment';
import { purchaseOrderItem } from '../../../../../../models/entities/purchaseOrder/purchaseOrderItem';

export const POsGridColumns = (localization: any): GridColDef[] => [
    {
        field: 'CargoZoneNumber',
        headerName: '#',
        flex: 1,
        renderCell: (params: GridCellParams) => (
            <Link to={{ pathname: PagesRoutes.PurchaseOrders + '/' + params.getValue(params.id, 'id') }} target="_blank">
                {params.value}
            </Link>
        )
    },
    {
        field: 'expectedReadyDate',
        headerName: localization.expected_ready_date,
        flex: 1,
        renderCell: (params: GridCellParams) => {
            const value: string = params.value as string;

            return moment(value).isValid() ? moment(value).format('DD/MM/YYYY') : '-';
        }
    },
    {
        field: 'items',
        headerName: localization.items_title,
        flex: 1,
        renderCell: (params: GridCellParams) => {
            const value = params.value as purchaseOrderItem[];

            if (!Array.isArray(value) || !value?.length) return '-';

            return value.map((item) => item.name).join(', ');
        }
    }
];
