import { createSelector } from 'reselect';
import { userInfo } from '../../../models/entities/user';
import { RootState } from '../../store/store';
import { byDate } from '../../../utils/sortArray';
import { deletedRecord } from '../../../models/entities/admin/deletedRecord';
import { orderBy } from 'lodash';
import { orderGridDataFn } from '../../../components/shared/Grid/Grid';

const admin = (state: RootState) => state.admin;
const users = (state: RootState) => state.admin.users;
const usersGridSort = (state: RootState) => state.admin.usersGridSort;
const deletedRecords = (state: RootState) => state.admin.deletedRecords;
const gridFilter = (state: RootState) => state.admin.gridFilter;

const getUserById = createSelector(users, (users: userInfo[]) => (userId: string) => {
    return users.find((u) => u.id === userId);
});

const usersData = createSelector(gridFilter, users, usersGridSort, (gridFilter, users, gridSort) => {
    if (gridFilter && gridFilter !== '' && gridFilter.length > 2) {
        users = users?.filter((s) => JSON.stringify(s).toLocaleLowerCase().includes(gridFilter.toLocaleLowerCase()));
    }

    if (gridSort) {
        users = orderGridDataFn(users, gridSort);
    }
    return users;
});
const gridData = createSelector(gridFilter, deletedRecords, (gridFilter, deletedRecords) => {
    if (gridFilter && gridFilter !== '' && gridFilter.length > 2) {
        deletedRecords = deletedRecords?.filter((s) => JSON.stringify(s).toLocaleLowerCase().includes(gridFilter.toLocaleLowerCase()));
    }
    return deletedRecords.sort(byDate(false, (dr: deletedRecord) => dr.deletedAt));
});
export { default as adminSelectors } from './selectors';

export default {
    getUserById,
    admin,
    users,
    usersData,
    gridData,
    gridFilter,
    usersGridSort
};
