import React from 'react';
import { styled } from '../../../../shared/Theme/theme';
import Loader from '../../../../shared/SmallComponents/Loader';
import { shipmentPackage } from '../../../../../models/entities/shipment/shipmentPackage';
import GridRender from './gridColumns';
import { shipmentSubType, shipmentType } from '../../../../../models/entities/shipment/shipment';
import { shipmentContainer } from '../../../../../models/entities/shipment/shipmentContainer';

type Props = {
    packages?: Array<shipmentPackage>;
    containers?: Array<shipmentContainer>;
    localization: any;
    type: shipmentType;
    sub_type?: shipmentSubType;
};

const packagesTable = ({ packages, containers, localization, type, sub_type }: Props) => {
    return (
        <Wrapper>
            {packages || containers ? (
                packages?.length || containers?.length ? (
                    <GridWrapper>{GridRender(localization, type, packages, containers, sub_type)}</GridWrapper>
                ) : (
                    <EmaptyStateText>{localization.packages.empty_state}</EmaptyStateText>
                )
            ) : (
                <Loader marginTop="0px" />
            )}
        </Wrapper>
    );
};

const GridWrapper = styled.div`
    flex: 0 0 100%;
    max-width: 100%;
    width: 100%;
    box-sizing: border-box;
    overflow-y: overlay;
    height: 100%;
    width: 100%;
`;

const Wrapper = styled.div`
    display: flex;
    flex-direction: column;
    width: 100%;
`;

const EmaptyStateText = styled.span`
    color: ${(props) => props.theme.colors.emptyStateText};
`;

export default packagesTable;
