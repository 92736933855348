import React from 'react';
import { connect } from 'react-redux';
import styled from 'styled-components';
import { filterFieldsType } from '../../../../../state/ducks/allBusinessPartnersPage/allBusinessPartnersPage';
import { RootState } from '../../../../../state/store/store';
import { allShipmentsPageSelectors } from '../../../../../state/ducks/allShipmentsPage/selectors';
import { parseFilterFields, removeFilterItem, parsedFilterFieldType } from '../../utils';
import { allowedCompany } from '../../../../../models/entities/user';
import { userSelectors } from '../../../../../state/ducks/user/selectors';
import { settings, shipment } from '../../../../../models/entities/shipment/shipment';
import { shipmentsSelectors } from '../../../../../state/ducks/shipments/selectors';

type Props = {
    filterFields: filterFieldsType | null;
    setShipmentFilters: (filter: filterFieldsType, disableDefaultActiveFilter?: boolean) => void;
    fetchShipmentsByFilter: (filter: filterFieldsType) => Array<shipment>;
    settings: settings;
    allowedCompanies: allowedCompany[];
    localization: any;
};

const FiltersStripe = ({ filterFields, allowedCompanies, settings, setShipmentFilters, fetchShipmentsByFilter, localization }: Props) => {
    const renderFiltersStrip = () => {
        return parseFilterFields(filterFields || [], allowedCompanies, settings, localization)?.map((filter: parsedFilterFieldType, i) => {
            const isInactiveFilter = filter.field === 'isActive' && filter.originalValue === 'false';
            return (
                <FilterBadge key={i} isInactiveFilter={isInactiveFilter}>
                    {filter.parsedValue}
                    <FilterRemove
                        isInactiveFilter={isInactiveFilter}
                        className="material-icons"
                        onClick={() => {
                            if (filter.field === 'isActive') fetchShipmentsByFilter([]);
                            setShipmentFilters(removeFilterItem(filter, filterFields || []), true);
                        }}
                    >
                        close
                    </FilterRemove>
                </FilterBadge>
            );
        });
    };

    return <FiltersStripeContainer>{renderFiltersStrip()}</FiltersStripeContainer>;
};

interface FilterProps {
    isInactiveFilter: boolean;
}
const FiltersStripeContainer = styled.div`
    flex: 1;
    font-size: 12px;
    display: flex;
    overflow-x: hidden;

    @media screen and (max-width: 768px) {
        display: none;
    }

    &:hover {
        overflow-x: overlay;
    }

    &::-webkit-scrollbar {
        height: 2px;
    }

    &::-webkit-scrollbar-track {
        background-color: white;
        border-radius: 10px;
    }

    &::-webkit-scrollbar-thumb {
        border-radius: 10px;
    }
`;

const FilterBadge = styled.span<FilterProps>`
    border-radius: 2vh;
    border: ${(props) => `1px solid ${props.isInactiveFilter ? '#ff7676' : '#008ac9'}`};
    color: ${(props) => (props.isInactiveFilter ? '#ff7676' : 'rgba(0, 0, 0, 0.65)')};
    font-size: 14px;
    height: 24px;
    padding: 3px 8px;
    margin-left: 10px;
    display: inline-flex;
    align-items: center;
    line-height: 1px;
    min-width: max-content;
`;

const FilterRemove = styled.span<FilterProps>`
    font-size: 12px;
    color: ${(props) => (props.isInactiveFilter ? '#ff7676' : '#008ac9')};
    margin-left: 5px;
    cursor: pointer;
`;

const mapStateToProps = (state: RootState) => ({
    filterFields: allShipmentsPageSelectors.filterFields(state),
    allowedCompanies: userSelectors.allowedCompanies(state),
    settings: shipmentsSelectors.settingsByLanguage(state)()
});

const mapDispatchToProps = (dispatch: any) => ({
    setShipmentFilters: (filter: filterFieldsType, disableDefaultActiveFilter?: boolean) =>
        dispatch.allShipmentsPage.setShipmentFilters({ filter, disableDefaultActiveFilter }),
    fetchShipmentsByFilter: (filter: filterFieldsType) => dispatch.allShipmentsPage.fetchShipmentsByFilter(filter)
});

export default connect(mapStateToProps, mapDispatchToProps)(FiltersStripe);
