import React from 'react';
import styled from 'styled-components';
import { supplier } from '../../../../../models/entities/product/supplier';
import { Option } from '../../../../shared/inputs/base/DropDown';
import { CurrencyInput, FormDropDown, FormNumberInput, FormTextInput } from '../../../../shared/inputs/form';

type Props = {
    name: string;
    values: any;
    localizations: any;
    codePlaceHolder: string;
    suppliersOptions: Option[];
    setFieldValue?: (field: string, value: any) => void;
    onChange?: (value: any) => void;
    onDelete?: (supplierId: string) => void;
};

export const emptySupplierObj: Partial<supplier> = {
    sku: '',
    supplierId: '',
    cost: 0,
    costCurrency: 'USD',
    minOrderQuantity: 1,
    productionDays: 1,
    isDefault: false
};

const SuppliersInput = ({ name, values, localizations, suppliersOptions, setFieldValue, onChange, onDelete }: Props) => {
    return (
        <>
            {values[name] &&
                values[name].map((item: supplier, index: number) => {
                    return (
                        <Flex key={index} style={{ alignItems: 'center', marginBottom: '20px' }}>
                            <FormDropDown
                                name={`${name}[${index}][supplierId]`}
                                style={{ width: 200, marginRight: 5 }}
                                placeHolder={localizations.supplier}
                                options={suppliersOptions}
                                showSearch={true}
                                onChange={(supplierId: string) => {
                                    setFieldValue?.(
                                        `${name}[${index}][supplierName]`,
                                        suppliersOptions?.find((opt) => opt.value === supplierId)?.text || null
                                    );
                                }}
                            />

                            <FieldContainer>
                                <FormTextInput name={`${name}[${index}][sku]`} placeHolder={localizations.sku} style={{ marginRight: 5 }} />
                            </FieldContainer>

                            <FieldContainer>
                                <CurrencyInput
                                    currencyValue={values[`${name}[${index}][costCurrency]`] ?? 'USD'}
                                    numberInputName={`${name}[${index}][cost]`}
                                    numberPlaceholder={localizations.cost}
                                    unitInputName={`${name}[${index}][costCurrency]`}
                                    numberInputWidth="100%"
                                />
                            </FieldContainer>
                            <FieldContainer>
                                <FormNumberInput
                                    name={`${name}[${index}][minOrderQuantity]`}
                                    placeHolder={localizations.minOrderQuantity}
                                    min={1}
                                    step={1}
                                    width={'60px'}
                                    style={{ width: '100%', marginRight: 5 }}
                                />
                            </FieldContainer>

                            <FormNumberInput
                                name={`${name}[${index}][productionDays]`}
                                placeHolder={localizations.productionDays}
                                min={1}
                                step={1}
                                width={'60px'}
                                style={{ width: '100%' }}
                            />

                            <DeleteContainer
                                onClick={() => {
                                    const hsCodes: Array<supplier> = [...values[name]];
                                    hsCodes.splice(index, 1);
                                    if (item.id) onDelete?.(item.id);
                                    onChange && onChange(hsCodes);
                                }}
                            >
                                <VerticalDivider style={{ marginLeft: '20px' }} />
                                <span className="material-icons">delete</span>
                            </DeleteContainer>
                        </Flex>
                    );
                })}
            <AddContainer
                onClick={() => {
                    const suppliers: Array<Partial<supplier>> = [...(values[name] || []), emptySupplierObj];
                    onChange && onChange(suppliers);
                }}
            >
                <span className="material-icons">add_circle</span>
                <span>{'Add supplier'}</span>
            </AddContainer>
        </>
    );
};

const DeleteContainer = styled.div`
    display: flex;
    color: ${(props) => props.theme.colors.primaryBlue};
    span.material-icons {
        cursor: pointer;
    }
`;
const VerticalDivider = styled.div`
    height: auto;
    width: 1px;
    background: rgb(0 0 0 / 14%);
    margin-right: 20px;
`;
const StyledFieldContainer = styled.div`
    padding-right: 30px;
`;

const Flex = styled.div`
    display: flex;
`;

const FieldContainer = styled.div`
    margin-right: 5px;
`;

const AddContainer = styled.div`
    cursor: pointer;
    display: inline-flex;
    align-items: center;
    font-size: 13px;
    line-height: 0;
    color: ${(props) => props.theme.colors.pageTitle};
    span.material-icons {
        font-size: inherit;
        margin-right: 5px;
        user-select: none;
    }
`;

export default SuppliersInput;
