import React, { useEffect, useState } from 'react';
import { product, settings } from '../../../../../models/entities/product/product';
import TabsContainer from '../../../../shared/Tabs/TabsContainer';
import Tab from '../../../../shared/Tabs/Tab';
import { useLocation } from 'react-router-dom';
import History from '../../../commonSubEntities/history/HistoryTab';
import Documents from './Documents/DocumentsTab';
import Attributes from './Attributes/AttributesTab';
import Links from './Links/LinksTab';
import RelatedProductsTab from './RelatedProducts/RelatedProductsTab';
import InventoryTab from './Inventory/InventoryTab';

type BaseProps = {
    product: product;
    localization: any;
    settings: settings;
    defaultTab?: string;
};
type Props = BaseProps & {
    isMobile?: boolean;
};

const ProductTabs = ({ product, localization, isMobile, settings, defaultTab }: Props) => {
    const location = useLocation();

    const tabParam = defaultTab || new URLSearchParams(location.search).get('tab');

    const compareCaseInsensitive = (first: string, second: string) => {
        return first.toUpperCase() === second.toUpperCase();
    };

    const getActiveTab = () => {
        if (tabParam) {
            const tabs = ['Documents', 'Attributes', 'Links', 'RelatedProducts', 'Inventory', 'History'];
            const tabsMobile = tabs.concat('Overview');

            return (isMobile ? tabsMobile : tabs).some((tab) => compareCaseInsensitive(tab, tabParam))
                ? tabParam
                : isMobile
                ? 'Overview'
                : 'Documents';
        }
        return isMobile ? 'Overview' : 'Documents';
    };

    const [activeTab, setActiveTab] = useState(getActiveTab());

    useEffect(() => {
        setActiveTab(getActiveTab());
    }, [location]);

    const mobileToUpper = (title: string) => {
        return isMobile ? title.toUpperCase() : title;
    };

    const compareActiveTab = (tab: string) => {
        return compareCaseInsensitive(activeTab, tab);
    };
    return (
        <TabsContainer>
            <Tab title={mobileToUpper('Documents')} onClick={() => setActiveTab('Documents')} isActive={compareActiveTab('Documents')}>
                <Documents documents={product.documents} localization={localization} settings={settings} productId={product.id} />
            </Tab>
            <Tab title={mobileToUpper('Attributes')} onClick={() => setActiveTab('Attributes')} isActive={compareActiveTab('Attributes')}>
                <Attributes attributes={product.attributes} productId={product.id} localization={localization} />
            </Tab>
            <Tab
                title={mobileToUpper('Related Products')}
                onClick={() => setActiveTab('RelatedProducts')}
                isActive={compareActiveTab('RelatedProducts')}
            >
                <RelatedProductsTab products={product.relatedProducts} />
            </Tab>
            <Tab title={mobileToUpper('Inventory')} onClick={() => setActiveTab('Inventory')} isActive={compareActiveTab('Inventory')}>
                <InventoryTab product={product} />
            </Tab>
            <Tab title={mobileToUpper('Links')} onClick={() => setActiveTab('Links')} isActive={compareActiveTab('Links')}>
                <Links links={product.links} productId={product.id} localization={localization} />
            </Tab>
            <Tab title={mobileToUpper('History')} onClick={() => setActiveTab('History')} isActive={compareActiveTab('History')}>
                <History history={product.history} localization={localization} />
            </Tab>
        </TabsContainer>
    );
};
export default ProductTabs;
