import React from 'react';
import { discussion } from '../../../../../models/entities/common_subentities/discussion';
import { userSelectors } from '../../../../../state/ducks/user/selectors';
import { RootState } from '../../../../../state/store/store';
import { connect } from 'react-redux';
import { singleShipmentSelectors } from '../../../../../state/ducks/singleShipment/selectors';
import { userInfo } from '../../../../../models/entities/user';
import DiscussionsSection from '../../../commonSubEntities/discussions/DiscussionsSection';
import { userDetails } from '../../../../../state/ducks/users/users';
import { usersSelectors } from '../../../../../state/ducks/users/selectors';
import { shipment } from '../../../../../models/entities/shipment/shipment';
import { shipmentsSelectors } from '../../../../../state/ducks/shipments/selectors';
import { ColumnType } from '../../../../shared/Grid/types/Column';
import { allShipmentsPageSelectors } from '../../../../../state/ducks/allShipmentsPage/selectors';

type Props = {
    shipmentId?: string;
    shipmentNumber?: string;
    discussions?: Array<discussion>;
    localization: any;
    currentUser: userInfo;
    createShipmentDiscussion: (shipmentId: string, discussion: discussion, shipmentNumber?: string) => void;
    updateShipmentDiscussion: (shipmentId: string, discussion: discussion, shipmentNumber?: string) => void;
    deleteShipmentDiscussion: (shipmentNumber: string, discussionId: string) => void;
    getUserById: (userId: string) => userDetails | undefined;
    getShipmentById: (id: string) => shipment | undefined;
    showLoader: boolean;
    gridColumns: Array<ColumnType<shipment>>;
};

const ShipmentDiscussionsSection = ({
    shipmentId,
    discussions,
    localization,
    currentUser,
    createShipmentDiscussion,
    updateShipmentDiscussion,
    deleteShipmentDiscussion,
    getUserById,
    getShipmentById,
    showLoader,
    gridColumns,
    shipmentNumber
}: Props) => {
    return (
        <DiscussionsSection
            entityId={shipmentId}
            entityName={shipmentNumber}
            discussions={discussions}
            localization={localization}
            currentUser={currentUser}
            createDiscussion={createShipmentDiscussion}
            updateDiscussion={updateShipmentDiscussion}
            deleteDiscussion={deleteShipmentDiscussion}
            showLoader={showLoader}
            getUserById={getUserById}
            getShipmentById={getShipmentById}
            gridColumns={gridColumns}
        />
    );
};
const mapStateToProps = (state: RootState) => ({
    currentUser: userSelectors.userInfo(state),
    shipmentId: singleShipmentSelectors.shipment(state)?.id,
    shipmentNumber: singleShipmentSelectors.shipment(state)?.CargoZoneNumber,
    discussions: singleShipmentSelectors.shipment(state)?.discussions,
    showLoader:
        state.loading.effects.shipments.createShipmentDiscussion ||
        state.loading.effects.shipments.updateShipmentDiscussion ||
        state.loading.effects.shipments.deleteShipmentDiscussion,
    getUserById: (id: string) => usersSelectors.getUserById(state)(id),
    getShipmentById: (id: string) => shipmentsSelectors.getShipmentById(state, id),
    gridColumns: allShipmentsPageSelectors.gridColumns(state)
});

const mapDispatchToProps = (dispatch: any) => ({
    createShipmentDiscussion: (shipmentId: string, discussion: discussion, shipmentNumber?: string) =>
        dispatch.shipments.createShipmentDiscussion({ shipmentId, discussion, shipmentNumber }),
    updateShipmentDiscussion: (shipmentId: string, discussion: discussion, shipmentNumber?: string) =>
        dispatch.shipments.updateShipmentDiscussion({ shipmentId, discussion, shipmentNumber }),
    deleteShipmentDiscussion: (shipmentNumber: string, discussionId: string) =>
        dispatch.shipments.deleteShipmentDiscussion({ shipmentNumber, discussionId })
});

export default connect(mapStateToProps, mapDispatchToProps)(ShipmentDiscussionsSection);
