import { ContentState, EditorState } from 'draft-js';
import React, { useEffect } from 'react';
import { chat, chatType, createChatPayload, editMessagePayload, message, messageAttachment } from '../../../models/entities/chat/chat';
import { chatRequest } from '../../../models/entities/chat/chatRequest';
import { shipment } from '../../../models/entities/shipment/shipment';
import { userInfo } from '../../../models/entities/user';
import { userDetails, usersObject } from '../../../state/ducks/users/users';
import { ColumnType } from '../../shared/Grid/types/Column';
import ChatView from './ChatView/ChatView';

type Props = {
    participantsIds: string[];
    chatType: chatType;
    userInfo: userInfo;
    chat?: chat;
    messages?: { [key: string]: message[] };
    sendMessage: (chatId: string, message: message) => void;
    createChat: (payload: createChatPayload) => void;
    editMessage: (payload: editMessagePayload) => void;
    deleteMessage: (id: string, chatId: string) => void;
    getUserById: (userId: string) => userDetails | undefined;
    readMessages: (chatId: string, messagesIds: string[], userId: string) => void;
    typingUsers: string[] | undefined;
    deleteGroupParticipant: (chatId: string, userId: string, userName: string) => Promise<void>;
    addGroupParticipants: (chatId: string, users: { id: string; name: string }[]) => Promise<void>;
    fetchNextMessages: (chatId: string, messagesToId: string) => Promise<chat | undefined>;
    getMessageById: (chatId: string, messageId: string) => message | undefined;
    gridColumns: Array<ColumnType<shipment>>;
    myNetwork: usersObject;
    shipments: Array<shipment>;
    getShipmentById: (id: string) => shipment | undefined;
    inviteUser: (email: string) => void;
    saveChatEditorState: (chatId: string, editorContentState: ContentState) => void;
    saveChatAttachmentsEditorState: (chatId: string, attachments: messageAttachment[]) => void;
    sentRequests?: Array<chatRequest>;
    isFetchingNextMessages: boolean;
    localization: any;
};
const ChatPane = (props: Props) => {
    const messagesLength = props.messages?.length;
    /** chat debug
    console.log('ChatPane');
*/
    return <ChatView {...props} typingUsers={props.typingUsers} localization={props.localization.chat_view} />;
};

export default React.memo(ChatPane);
