import { Form, Formik } from 'formik';
import React, { useState } from 'react';
import { connect } from 'react-redux';
import { allowedCompany } from '../../../../../models/entities/user';
import { userSelectors } from '../../../../../state/ducks/user/selectors';
import { RootState } from '../../../../../state/store/store';
import { FormDropDown, FormUploadInput } from '../../../../shared/inputs/form';
import CustomModal, { ModalFooter, ModalFormContainer, ModalTriggerProps } from '../../../../shared/Modal/Modal';
import Button from '../../../../shared/SmallComponents/Button';
import Loader from '../../../../shared/SmallComponents/Loader';
import { styled } from '../../../../shared/Theme/theme';
import { Link } from 'react-router-dom';
import Tooltip from '@material-ui/core/Tooltip';

type Props = {
    openedFromModal?: {
        setVisible: React.Dispatch<React.SetStateAction<boolean>>;
    };
    showLoader?: boolean;
    allowedCompanies: allowedCompany[];
    uploadShipmentsByExcel: (file: File, companyId?: string, companyName?: string) => Promise<boolean>;
    localization: any;
};

const UploadForm = ({ allowedCompanies, uploadShipmentsByExcel, openedFromModal, showLoader, localization }: Props) => {
    const [submitLoading, setLoading] = useState(false);
    const setInitialValues = () => {
        return {
            companyId: null,
            companyName: null,
            file: null
        };
    };
    const validate = (values: any) => {
        const errors: any = {};
        if (!values.file) errors.file = localization.required;
        return errors;
    };
    const submit = (values: any) => {
        setLoading(true);
        uploadShipmentsByExcel(values.file, values.companyId, values.companyName)
            .then((success: boolean) => {
                if (openedFromModal && success) {
                    openedFromModal.setVisible(false);
                } else {
                    setLoading(false);
                }
            })
            .catch(() => {
                setLoading(false);
            });
    };

    return (
        <Formik initialValues={setInitialValues()} validate={validate} onSubmit={submit}>
            {({ values, setFieldValue }) => {
                return (
                    <Form>
                        <Container>
                            {allowedCompanies.length > 1 && (
                                <FieldContainer>
                                    <FormDropDown
                                        name="companyId"
                                        placeHolder={localization.company_placeholder}
                                        options={allowedCompanies.map((item) => {
                                            return { value: item.companyId, text: item.companyName };
                                        })}
                                        onChange={(value: string) =>
                                            setFieldValue('companyName', allowedCompanies.find((item) => item.companyId === value)?.companyName)
                                        }
                                    />
                                </FieldContainer>
                            )}
                            <FieldContainer style={{ marginTop: '15px' }}>
                                <FormUploadInput name="file" placeHolder={localization.select_excel_file} id="file-upload" />
                            </FieldContainer>
                        </Container>
                        <FormFooter>
                            <Link
                                to={{
                                    pathname: `https://${process.env.REACT_APP_AWS_CARGOZONE_PUBLIC_DOCUMENTS}.s3.eu-central-1.amazonaws.com/${process.env.REACT_APP_SHIPMENT_TEMPLATE_NAME}`
                                }}
                                target="_blank"
                            >
                                {localization.download_template}
                            </Link>
                            <Button minWidth="55px" buttonType="filled" type="submit">
                                {submitLoading ? <Loader width="20px" marginTop="0px" showText={false} /> : <span>{localization.upload_btn}</span>}
                            </Button>
                        </FormFooter>
                    </Form>
                );
            }}
        </Formik>
    );
};
const FieldContainer = styled.div`
    margin-bottom: 12px;
`;

const Container = styled(ModalFormContainer)``;

const FormFooter = styled(ModalFooter)`
    position: absolute;
    bottom: 0;
    width: 100%;
    left: 0;
    right: 0;
    background: white;
    justify-content: space-between;
    align-items: center;
`;
const mapStateToProps = (state: RootState) => ({
    allowedCompanies: userSelectors.allowedCompanies(state),
    showLoader: state.loading.effects.shipments.uploadShipmentsByExcel
});

const mapDispatchToProps = (dispatch: any) => ({
    uploadShipmentsByExcel: async (file: File, companyId?: string, companyName?: string) =>
        dispatch.shipments.uploadShipmentsByExcel({ file, companyId, companyName })
});

const UploadShipments = connect(mapStateToProps, mapDispatchToProps)(UploadForm);

export default UploadShipments;
type ModalProps = {
    localization: any;
};
export const UploadShipmentsModal = ({ localization }: ModalProps) => {
    return (
        <CustomModal
            title={{ text: localization.modal_title }}
            Trigger={(props: ModalTriggerProps) => (
                <Button onClick={props.onClick} width="32px" height="32px" borderRadius="50%" margin="7px" buttonType="hollow">
                    <Tooltip title={localization.upload_btn} aria-label="upload">
                        <span className="material-icons-outlined">file_upload</span>
                    </Tooltip>
                </Button>
            )}
            Content={(setVisible?: React.Dispatch<React.SetStateAction<boolean>>, setConfirm?: React.Dispatch<React.SetStateAction<boolean>>) => {
                return <UploadShipments openedFromModal={setVisible ? { setVisible } : undefined} localization={localization} />;
            }}
            width="393px"
        />
    );
};
