import baseStyled, { ThemedStyledInterface } from 'styled-components';

const basicColors = {
    white: '#fff'
};

const theme = {
    basic: {
        white: '#fff'
    },
    colors: {
        primary: '#00B5B9',
        secondary: '#252b4a',
        primaryBlue: '#008ac9',
        darkBlue: '#757575',
        grey: '#bdbdbd',
        pageTitle: '#394372',
        gridCell: '#394372',
        gridResultsCounter: '#757575',
        toolTipBackground: '#252b4a',
        toolTipText: basicColors.white,
        grayDefaultBorder: '#e0e0e0',
        gridRowBorder: '#e0e0e0',
        link: '#008ac9',
        tabNormal: '#394372',
        tabActive: '#008ac9',
        sideBarItem: basicColors.white,
        sectionTitle: '#3d4e7e',
        summaryBoxTitle: '#3d4e7e',
        emptyStateText: '#00aede',
        overlayBG: 'rgba(0, 0, 0, 0.75)',
        hoverBG: '#efefef',
        userStatuses: {
            DEFAULT: {
                color: '#00b5b9',
                dot_fill: true
            },
            AVAILABLE: {
                color: '#00b5b9',
                dot_fill: true
            },
            BUSY: {
                color: '#f0a333',
                dot_fill: true
            },
            DO_NOT_DISTURB: {
                color: '#f03333',
                dot_fill: true
            },
            OOTO: {
                color: '#e0e0e0',
                dot_fill: true
            }
        },
        menu: {
            boxBorder: '#e0e0e0',
            itemBorder: '#f9f9f9',
            itemHoverBG: '#f3f3f3',
            triggers: {
                cellTriggerBG: '#008ac9'
            }
        },
        userMenu: {
            containerBG: '#f3f3f3',
            dividerColor: '#bdbdbd',
            itemHoverBG: '#e0e0e0',
            statusDropDown: {
                color: '#252b4a',
                background: '#f3f3f3',
                borderColor: '#e0e0e0'
            }
        },
        header: {
            avatar: {
                borderColor: '#efefef8c',
                hoverBG: '#efefef8c'
            },
            actions: {
                color: '#008ac9',
                bgColor: '#394372'
            }
        },
        modal: {
            headerColor: '#0097d1'
        },
        notifications: {
            page: {
                headerBorderColor: '#f1f1f1',
                headerColor: '#394372',
                markAllReadColor: '#bdbdbd',
                removeIcon: '#bdbdbd',
                item: {
                    hexagonBorderColor: '#aedee0',
                    unReadButtonColor: '#303654',
                    borderColor: '#e4e4e4',
                    isUnreadBG: '#e5f4fa',
                    hoverReadBG: '#f0f0f0',
                    hoverUnreadBG: '#cce8f5',
                    dateColor: '#dbdbdb',
                    removeIcon: '#394372'
                }
            },
            menu: {
                headerBorderColor: '#f1f1f1',
                headerColor: '#394372',
                badgeColor: '#008ac9'
            }
        }
    },
    fontSizes: {
        menuItem: '9px',
        gridResultsCounter: '14px',
        gridCell: '14px',
        subTitlte: '14px',
        pageTitle: '16px',
        toolTip: '12px',
        tabText: '18px',
        tabTextMobile: '14px',
        languageSelector: '12px',
        summaryBoxTitle: '14px',
        summaryBoxData: '30px',
        sectionTitle: '16px'
    }
};

export type themeType = typeof theme;
// Export styled with strongly typed ts - https://github.com/styled-components/styled-components/issues/1589
export const styled = baseStyled as ThemedStyledInterface<themeType>;
export default theme;
