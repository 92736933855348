import { RootState } from '../../../state/store/store';
import { createSelector } from 'reselect';
import ordersSelectors from '../orders/selectors';
import { order as orderType } from '../../../models/entities/order';
import moment from 'moment';

const currentOrderId = (state: RootState) => state.singleOrder.orderId;
const fetchOrderError = (state: RootState) => state.error.effects.orders.fetchOrderById;
const isLoadingOrder = (state: RootState) => state.loading.effects.orders.fetchOrderById;
const isUpdateOrder = (state: RootState) => state.loading.effects.orders.updateOrder;
const products = (state: RootState) => state.products.products;
const userInfo = (state: RootState) => state.user.userInfo;

const order = createSelector(currentOrderId, ordersSelectors.ordersData, (orderId, orders) => {
    return orders?.find((order) => order.id === orderId);
});

const lastEvent = createSelector(order, ordersSelectors.ordersData, (order: orderType | undefined) => {
    if (!order?.events?.length) return null;

    const descEventsByDate = order.events.sort((a, b) => moment(b.createdDate).toDate().getTime() - moment(a.createdDate).toDate().getTime());

    return descEventsByDate[0];
});

const orderItemsRelatedProducts = createSelector(order, products, (order, products) => {
    return products.filter((product) => order?.items?.some((item) => item.productId === product.id));
});

const orderDiscussions = createSelector(order, (order) => {
    return (order?.discussions || []).sort((a, b) => moment(b.createdAt).toDate().getTime() - moment(a.createdAt).toDate().getTime());
});

const isFollowingCurrentOrder = createSelector(order, userInfo, (order, userInfo) => {
    return !!order?.followers?.some((follower) => follower.userId === userInfo.id);
});

const eventsTabData = createSelector(order, ordersSelectors.orderSettings, (order, settings) => {
    return (
        order?.events?.map((event) => {
            const eventSettings = settings?.eventTypes?.find((e) => e.code === event.code);
            return {
                title: eventSettings?.name || event.code,
                date: event.eventDate,
                isCompleted: true,
                description: event.description || event.comment || eventSettings?.description || '',
                entity: event
            };
        }) || []
    ).sort((a, b) => moment(b.date).toDate().getTime() - moment(a.date).toDate().getTime());
});

export { default as singleOrderSelectors } from './selectors';

const selectors = {
    order,
    fetchOrderError,
    isLoadingOrder,
    currentOrderId,
    isUpdateOrder,
    lastEvent,
    orderItemsRelatedProducts,
    orderDiscussions,
    eventsTabData,
    isFollowingCurrentOrder
};

export default selectors;
