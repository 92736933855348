import React, { useEffect } from 'react';
import { currenciesType, purchaseOrder } from '../../../../models/entities/purchaseOrder/purchaseOrder';
import { RouteComponentProps, useLocation } from 'react-router-dom';
import { connect } from 'react-redux';
import { Dispatch, RootState } from '../../../../state/store/store';
import { localizationSelectors } from '../../../../state/ducks/localization/selectors';
import { businessPartnersSelectors } from '../../../../state/ducks/businessPartner/selectors';
import { businessPartner } from '../../../../models/entities/businessPartner/businessPartner';
import { singlePurchaseOrderSelectors } from '../../../../state/ducks/singlePurchaseOrder/selectors';
import PurchaseOrderForm from './PurchaseOrderForm';
import Loader from '../../../shared/SmallComponents/Loader';
import { userSelectors } from '../../../../state/ducks/user/selectors';
import { allowedCompany, userDropDown } from '../../../../models/entities/user';
import { PagesRoutes } from '../../../../routing/PagesRoutes';
import { filterFieldsType } from '../../../../state/ducks/allShipmentsPage/allShipmentsPage';
import { purchaseOrdersSelectors } from '../../../../state/ducks/purchaseOrders/selectors';
import { shipment } from '../../../../models/entities/shipment/shipment';
import { productsSelectors } from '../../../../state/ducks/products/selectors';
import { product } from '../../../../models/entities/product/product';
import { usersSelectors } from '../../../../state/ducks/users/selectors';
import { userDetails } from '../../../../state/ducks/users/users';
import { Option } from '../../../shared/inputs/base/DropDown';
import { Redirect } from 'react-router-dom';
import ExceptionPage from '../../ExceptionPage/ExceptionPage';
import claimsType from '../../../../models/entities/claimsType';
import { claimsDomainsType } from '../../../../userClaims';

type PathParams = {
    purchaseOrderId: string;
};

export type BaseProps = {
    purchaseOrder: purchaseOrder | undefined;
    localizations?: any;
    businessPartnersOptions?: any;
    businessPartnerVendors?: Array<businessPartner>;
    businessPartnerConsignees?: Array<businessPartner>;
    businessPartnerForwarders?: Array<businessPartner>;
    businessPartnerSuppliers?: Array<businessPartner>;
    businessPartnerSuppliersManufacturers?: Array<businessPartner>;
    businessPartnerManufacturers?: Array<businessPartner>;
    businessPartnerWarehouses?: Array<businessPartner>;
    businessPartners?: Array<businessPartner>;
    usersInSameCompany?: Array<userDropDown>;
    createPurchaseOrder: (purchaseOrder: purchaseOrder) => Promise<string>;
    updatePurchaseOrder: (id: string, purchaseOrder: purchaseOrder) => Promise<boolean>;
    getUsersByCompaniesIds: (companyId: string[]) => userDetails[];
    allowedTypes: Array<string>;
    allowedCompanies: allowedCompany[];
    allowedCompaniesDropDownOptions: Option[];
    purchaseOrderFormError?: string;
    fetchShipmentsByFilter: (filter: filterFieldsType) => void;
    getUserClaims: (domain: claimsDomainsType) => claimsType;
    filteredShipments: Array<shipment>;
};
type Props = RouteComponentProps<PathParams> &
    BaseProps & {
        setCurrentPurchaseOrderId: (currentPurchaseOrderId: string) => void;
        fetchSinglePurchaseOrder: (purchaseOrderId: string) => void;
    };
export const ePurchaseOrderFormMode = {
    NEW: 'new',
    EDIT: 'edit',
    DUPLICATE: 'duplicate'
};
const SinglePurchaseOrderFormConnected = ({
    localizations,
    businessPartnerVendors,
    businessPartnerConsignees,
    businessPartnerForwarders,
    businessPartnerSuppliers,
    businessPartnerManufacturers,
    businessPartnerSuppliersManufacturers,
    businessPartnerWarehouses,
    businessPartners,
    match,
    createPurchaseOrder,
    updatePurchaseOrder,
    purchaseOrder,
    setCurrentPurchaseOrderId,
    fetchSinglePurchaseOrder,
    allowedTypes,
    allowedCompanies,
    allowedCompaniesDropDownOptions,
    purchaseOrderFormError,
    fetchShipmentsByFilter,
    filteredShipments,
    usersInSameCompany,
    getUsersByCompaniesIds,
    getUserClaims
}: Props) => {
    const urlPurchaseOrderId = match.params.purchaseOrderId;
    const location = useLocation<{ relatedPdId?: string; initialValues?: purchaseOrder } | undefined>();
    useEffect(() => {
        if (urlPurchaseOrderId) {
            setCurrentPurchaseOrderId(urlPurchaseOrderId);
            fetchSinglePurchaseOrder(urlPurchaseOrderId);
        }
    }, [urlPurchaseOrderId, setCurrentPurchaseOrderId, fetchSinglePurchaseOrder]);
    let mode: string = ePurchaseOrderFormMode.NEW;
    if (urlPurchaseOrderId) mode = ePurchaseOrderFormMode.EDIT;
    if (match.path.indexOf(PagesRoutes.DuplicatePurchaseOrder) !== -1) mode = ePurchaseOrderFormMode.DUPLICATE;

    if (mode === ePurchaseOrderFormMode.NEW && !getUserClaims('po').create) {
        return <ExceptionPage status={401} title={localizations.no_permission} goBackPath={PagesRoutes.PurchaseOrders} />;
    }

    return urlPurchaseOrderId && !purchaseOrder ? (
        <Loader />
    ) : (
        <PurchaseOrderForm
            mode={mode}
            purchaseOrder={purchaseOrder}
            initialValues={location?.state?.initialValues}
            relatedPdId={location?.state?.relatedPdId}
            localizations={localizations}
            businessPartnerVendors={businessPartnerVendors}
            businessPartnerConsignees={businessPartnerConsignees}
            businessPartnerForwarders={businessPartnerForwarders}
            businessPartnerSuppliers={businessPartnerSuppliers}
            businessPartnerManufacturers={businessPartnerManufacturers}
            businessPartnerSuppliersManufacturers={businessPartnerSuppliersManufacturers}
            businessPartnerWarehouses={businessPartnerWarehouses}
            businessPartners={businessPartners}
            createPurchaseOrder={createPurchaseOrder}
            updatePurchaseOrder={updatePurchaseOrder}
            allowedTypes={allowedTypes}
            allowedCompanies={allowedCompanies}
            allowedCompaniesDropDownOptions={allowedCompaniesDropDownOptions}
            purchaseOrderFormError={purchaseOrderFormError}
            fetchShipmentsByFilter={fetchShipmentsByFilter}
            filteredShipments={filteredShipments}
            getUsersByCompaniesIds={getUsersByCompaniesIds}
            usersInSameCompany={usersInSameCompany}
            getUserClaims={getUserClaims}
        />
    );
};

const mapStateToProps = (state: RootState) => ({
    localizations: localizationSelectors.allPurchaseOrdersPage(state),
    businessPartnerVendors: businessPartnersSelectors.businessPartnerVendors(state),
    businessPartnerConsignees: businessPartnersSelectors.businessPartnerConsignees(state),
    businessPartnerForwarders: businessPartnersSelectors.businessPartnerForwarders(state),
    businessPartnerSuppliers: businessPartnersSelectors.businessPartnerSuppliers(state),
    businessPartnerSuppliersManufacturers: businessPartnersSelectors.businessPartnerSuppliersManufacturers(state),
    businessPartnerManufacturers: businessPartnersSelectors.businessPartnerManufacturers(state),
    businessPartnerWarehouses: businessPartnersSelectors.businessPartnerWarehouses(state),
    businessPartners: businessPartnersSelectors.businessPartners(state),
    purchaseOrder: singlePurchaseOrderSelectors.purchaseOrder(state),
    filteredShipments: purchaseOrdersSelectors.filteredShipments(state),
    allowedTypes: userSelectors.allowedTypes(state),
    allowedCompanies: userSelectors.allowedCompanies(state),
    allowedCompaniesDropDownOptions: userSelectors.allowedCompaniesDropDownOptions(state),
    getUsersByCompaniesIds: (companiesIds: string[]) => usersSelectors.getUsersByCompaniesIds(state)(companiesIds),
    usersInSameCompany: userSelectors.usersInSameCompany(state),
    purchaseOrderFormError: state.error.effects.purchaseOrders.updatePurchaseOrder || state.error.effects.purchaseOrders.createPurchaseOrder,
    getUserClaims: (domain: claimsDomainsType) => userSelectors.getUserClaims(state)(domain)
});

const mapDispatchToProps = (dispatch: Dispatch) => ({
    createPurchaseOrder: (purchaseOrder: purchaseOrder) => dispatch.purchaseOrders.createPurchaseOrder({ purchaseOrder }),
    updatePurchaseOrder: (id: string, purchaseOrder: string) => dispatch.purchaseOrders.updatePurchaseOrder({ id, purchaseOrder }),
    setCurrentPurchaseOrderId: (currentPurchaseOrderId: string) => dispatch.singlePurchaseOrder.setCurrentPurchaseOrderId(currentPurchaseOrderId),
    fetchSinglePurchaseOrder: (purchaseOrderId: string) => dispatch.purchaseOrders.fetchPurchaseOrderById(purchaseOrderId),
    fetchShipmentsByFilter: (filter: filterFieldsType) => dispatch.purchaseOrders.fetchShipmentsByFilter(filter)
});

export default connect(mapStateToProps, mapDispatchToProps as any)(SinglePurchaseOrderFormConnected);
