import BaseController from '../..';
import { purchaseDemandItem } from '../../../../models/entities/purchaseDemand/purchaseDemandItem';
import IPurchaseDemandItems from './IPurchaseDemandItems';

export default class PurchaseDemandItems extends BaseController implements IPurchaseDemandItems {
    constructor(baseUrl: string) {
        super(baseUrl);
    }
    async createPurchaseDemandItem(purchaseDemandId: string, item: purchaseDemandItem) {
        const response = await this.client.post(`/purchaseDemand/items`, JSON.stringify({ ...item, purchaseDemandId }));
        return response;
    }
    async createMultiplePurchaseDemandItems(purchaseDemandId: string, items: Array<purchaseDemandItem>) {
        const response = await this.client.post(
            `/purchaseDemand/items/multiple`,
            JSON.stringify({ purchaseDemandId, items: items.map((i) => ({ ...i, purchaseDemandId })) })
        );
        return response;
    }
    async updatePurchaseDemandItem(item: purchaseDemandItem) {
        const response = await this.client.put(`/purchaseDemand/items`, JSON.stringify(item));
        return response;
    }
    async deletePurchaseDemandItem(purchaseDemandId: string, itemId: string) {
        const response = await this.client.delete(`/purchaseDemand/items`, JSON.stringify({ purchaseDemandId, id: itemId }));
        return response;
    }
}
