import React, { FunctionComponent } from 'react';
import 'react-virtualized/styles.css';
import ReactDOM from 'react-dom';
import { AutoSizer, List, CellMeasurerCache, CellMeasurer } from 'react-virtualized';
import styled from '@emotion/styled';

import {
    Droppable,
    Draggable,
    DraggableProvided,
    DraggableStateSnapshot,
    DroppableProvided,
    DroppableStateSnapshot,
    DraggableRubric
} from 'react-beautiful-dnd';

import BoardItem from './BoardItem';
import { IBoardItem, Column } from '../types/BoardColumn';
import { ItemContentProps } from './VirtualizedBoard';

type RowData<T extends IBoardItem> = {
    items: T[];
    ItemContent: FunctionComponent<ItemContentProps<T>>;
};

type RowProps = {
    index: number;
    style: any;
    key: string;
    parent: any;
};

const cellMeasurerCache = new CellMeasurerCache({
    fixedWidth: true,
    defaultHeight: 100
});

const getRowRender =
    <T extends IBoardItem>(data: RowData<T>) =>
    ({ index, style, key, parent }: RowProps) => {
        const { items, ItemContent } = data;
        const item: T = items[index];

        if (!item) {
            return null;
        }

        const patchedStyle = {
            ...style,
            top: style.top + 8,
            height: style.height - 8
        };

        return (
            <CellMeasurer key={key} cache={cellMeasurerCache} parent={parent} columnIndex={0} rowIndex={index}>
                {({ measure, registerChild }) => (
                    <Draggable draggableId={item.id} index={index} key={item.id}>
                        {(provided: DraggableProvided, snapshot: DraggableStateSnapshot) => (
                            <BoardItem<T>
                                provided={provided}
                                item={item}
                                isDragging={snapshot.isDragging}
                                style={patchedStyle}
                                ItemContent={ItemContent as any}
                                measure={measure}
                                registerChild={registerChild}
                            />
                        )}
                    </Draggable>
                )}
            </CellMeasurer>
        );
    };

type ColumnProps<T extends IBoardItem> = {
    columnId: string;
    columnData: Column<T>;
    ItemContent: FunctionComponent<ItemContentProps<T>>;
};

function BoardColumn<T extends IBoardItem>(props: ColumnProps<T>) {
    const { columnId, columnData, ItemContent } = props;
    const { items, title } = columnData;

    return (
        <ColumnContainer>
            <Title>{(title || columnId) + ` (${items.length})`}</Title>
            <Droppable
                droppableId={columnId}
                mode="virtual"
                renderClone={(provided: DraggableProvided, snapshot: DraggableStateSnapshot, rubric: DraggableRubric) => (
                    <BoardItem
                        provided={provided}
                        isDragging={snapshot.isDragging}
                        item={items[rubric.source.index]}
                        style={{ margin: 0 }}
                        ItemContent={ItemContent}
                    />
                )}
            >
                {(droppableProvided: DroppableProvided, snapshot: DroppableStateSnapshot) => {
                    const itemCount: number = snapshot.isUsingPlaceholder ? items.length + 1 : items.length;

                    return (
                        <div style={{ height: '100%' }}>
                            <AutoSizer>
                                {({ height, width }) => (
                                    <List
                                        {...droppableProvided.droppableProps}
                                        height={height}
                                        rowCount={itemCount}
                                        rowHeight={cellMeasurerCache.rowHeight}
                                        width={width}
                                        // overscanRowCount={20}
                                        ref={(ref) => {
                                            // react-virtualized has no way to get the list's ref that I can so
                                            // So we use the `ReactDOM.findDOMNode(ref)` escape hatch to get the ref
                                            if (ref) {
                                                const whatHasMyLifeComeTo = ReactDOM.findDOMNode(ref);

                                                if (whatHasMyLifeComeTo instanceof HTMLElement) {
                                                    droppableProvided.innerRef(whatHasMyLifeComeTo);
                                                }
                                            }
                                        }}
                                        style={{
                                            borderRight: '1px solid #e4e4e4',
                                            backgroundColor: getBackgroundColor(snapshot.isDraggingOver, Boolean(snapshot.draggingFromThisWith)),
                                            transition: 'background-color 0.2s ease',
                                            width: 280,
                                            padding: '4px 12px 12px 12px'
                                        }}
                                        className="items-list"
                                        rowRenderer={getRowRender({ items, ItemContent: ItemContent as any })}
                                        deferredMeasurementCache={cellMeasurerCache}
                                    />
                                )}
                            </AutoSizer>
                        </div>
                    );
                }}
            </Droppable>
        </ColumnContainer>
    );
}

const ColumnContainer = styled.div`
    border-top-left-radius: 2px;
    border-top-right-radius: 2px;
    flex-shrink: 0;
    display: flex;
    flex-direction: column;
    height: 100%;
`;

const Title = styled.h4`
    width: 280px;
    margin: 0;
    padding: 0 10px 4px 12px;
    border-bottom: 3px solid #008ac9;
    color: #394372;
    background-color: #fff;
    font-weight: 500;
    padding-top: 20px;
    left: 0;
    display: flex;
    align-items: center;
    justify-content: space-between;
    .material-icons {
        font-size: 20px;
        cursor: pointer;
        color: #008ac9;
    }
    height: 52px;
`;

export const getBackgroundColor = (isDraggingOver: boolean, isDraggingFrom: boolean): string => {
    if (isDraggingOver) {
        return 'lightblue';
    }
    if (isDraggingFrom) {
        return 'white';
    }
    return 'white';
};

export default BoardColumn;
