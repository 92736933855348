import BaseController from '../..';
import shipmentItem from '../../../../models/entities/shipment/shipmentItem';
import IShipmentItems from './IShipmentItems';

export default class ShipmentItems extends BaseController implements IShipmentItems {
    constructor(baseUrl: string) {
        super(baseUrl);
    }

    async createShipmentItem(shipmentId: string, item: shipmentItem) {
        const response = await this.client.post(`/shipment/items`, JSON.stringify({ ...item, shipmentId }));
        return response;
    }
    async createMultipleShipmentItems(shipmentId: string, items: Array<shipmentItem>) {
        const response = await this.client.post(
            `/shipment/items/multiple`,
            JSON.stringify({ shipmentId, items: items.map((i) => ({ ...i, shipmentId })) })
        );
        return response;
    }
    async updateShipmentItem(item: shipmentItem) {
        const response = await this.client.put(`/shipment/items/`, JSON.stringify(item));
        return response;
    }
    async deleteShipmentItem(shipmentId: string, itemId: string) {
        const response = await this.client.delete(`/shipment/items`, JSON.stringify({ shipmentId, id: itemId }));
        return response;
    }
}
