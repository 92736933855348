import { createSelector } from 'reselect';
import { purchaseOrder, settings as settingsModel } from '../../../models/entities/purchaseOrder/purchaseOrder';
import mockData from '../../../sideEffects/purchaseOrder/settings/mockData';
import { RootState } from '../../store/store';

const purchaseOrdersData = (state: RootState) => state.purchaseOrders.purchaseOrders;
const fetchPurchaseOrdersError = (state: RootState) => state.error.effects.purchaseOrders.fetchPurchaseOrders;
const settings = (state: RootState) => state.user.userInfo.companySettings;
const filteredShipments = (state: RootState) => state.purchaseOrders.filteredShipments;

const error = (state: RootState) => state.purchaseOrders.error;

const getPurchaseOrderById = (state: RootState, id: string): purchaseOrder | undefined => {
    return state.purchaseOrders.purchaseOrders?.find((purchaseOrder: purchaseOrder) => purchaseOrder.id === id);
};
const dashboardGridData = createSelector(purchaseOrdersData, (purchaseOrdersData: Array<purchaseOrder>) => {
    return purchaseOrdersData.filter((po) => po.state !== 'CLOSED');
});

const currentLanguage = (state: RootState) => state.localization.currentLanguage;
const settingsByLanguage = createSelector(settings, currentLanguage, (settings, currentLanguage) => {
    const mockDataIndex = Object.keys(mockData).findIndex((item) => item === currentLanguage.symbol);
    const settingsObj: settingsModel = Object.values(mockData)[mockDataIndex] as settingsModel;
    if (settings) {
        settings
            .filter((s) => (s.entityType === 'PURCHASE_ORDER' || !s.entityType) && s.language === currentLanguage.symbol)
            .forEach((s) => {
                settingsObj[s.key as keyof settingsModel] = s.value;
            });
        return settingsObj;
    }
    return settingsObj;
});

const purchaseOrderStateOptions = createSelector(settingsByLanguage, (settingsByLanguage) => {
    const settings = settingsByLanguage;

    return (
        settings?.state
            ?.sort((a, b) => a.displayOrder - b.displayOrder)
            .map((item) => ({
                text: item.name,
                value: item.code,
                color: item.color
            })) || []
    );
});

const isPoStateAfterOrEqual = createSelector(
    purchaseOrdersData,
    settingsByLanguage,
    (purchaseOrders, settings) => (purchaseOrderId: string, state: string) => {
        if (!settings) return false;

        const purchaseOrder = purchaseOrders?.find((po) => po.id === purchaseOrderId);

        if (!purchaseOrder) return false;

        const settingsCheckState = settings.state?.find((s) => s.code === state);

        if (!settingsCheckState) return false;

        const settingsPoState = settings.state?.find((s) => s.code === purchaseOrder.state);

        if (!settingsPoState) return false;

        return settingsPoState.displayOrder >= settingsCheckState.displayOrder;
    }
);

export { default as purchaseOrdersSelectors } from './selectors';

export default {
    purchaseOrdersData,
    fetchPurchaseOrdersError,
    error,
    filteredShipments,
    settingsByLanguage,
    dashboardGridData,
    purchaseOrderStateOptions,
    getPurchaseOrderById,
    isPoStateAfterOrEqual
};
