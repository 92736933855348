import { notification } from 'antd';
import React from 'react';
import { connect } from 'react-redux';
import { localizationSelectors } from '../../../state/ducks/localization/selectors';
import { RootState } from '../../../state/store/store';

type Props = {
    value: string;
    localization: any;
};

const CopyToClipboard = ({ value, localization }: Props) => {
    return (
        <>
            <span
                className="material-icons"
                style={{ fontSize: '17px', cursor: 'pointer' }}
                onClick={() => {
                    const tempInput = document.createElement('input');
                    tempInput.value = value;
                    document.body.appendChild(tempInput);
                    tempInput.select();
                    tempInput.setSelectionRange(0, 99999);
                    document.execCommand('copy');
                    document.body.removeChild(tempInput);
                    notification.success({
                        message: localization.copied_message,
                        duration: 1.5
                    });
                }}
            >
                content_copy
            </span>
        </>
    );
};

const mapStateToProps = (state: RootState) => ({
    localization: localizationSelectors.common(state)
});

const mapDispatchToProps = (dispatch: any) => ({});

export default connect(mapStateToProps, mapDispatchToProps)(CopyToClipboard);
