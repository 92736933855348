import { createSelector } from 'reselect';
import { containersGridView as containersModel } from '../../../models/entities/containers/containersGridView';
import { containersStatsType } from '../../../models/entities/containers/containersStats';
import { RootState } from '../../../state/store/store';

const containersGridView = (state: RootState) => state.containers.containersGridView;
const textFilterValue = (state: RootState) => state.containers.textFilterValue;

const filteredContainers = createSelector(containersGridView, textFilterValue, (containers: containersModel, term: string) => {
    const filtered = containers.filter((c) => JSON.stringify(c).toLowerCase().includes(term.toLowerCase()));

    return filtered;
});

const containersStats = createSelector(containersGridView, (containers: containersModel) => {
    const stats: containersStatsType = {
        totalContainers: containers.length,
        storageOverdue: containers.filter((c) => c.storageDays >= 4).length,
        returnOverdue: containers.filter((c) => c.returnDays >= 8).length
    };

    return stats;
});

export { default as containersSelectors } from './selectors';

export default {
    textFilterValue,
    containersGridView,
    filteredContainers,
    containersStats
};
