import React from 'react';
import { FormDropDown, FormTextArea, FormTextInput, LocationSearchInput } from '../../../../../shared/inputs/form';
import { styled } from '../../../../../shared/Theme/theme';
import { FieldContainer, renderFieldLabel } from '../../PurchaseOrderForm';
import { renderCountryOption } from '../../../../../shared/SmallComponents/CountryOption';
import countries from '../../../../../../static/data/countries.json';
import airports from '../../../../../../static/data/airports.json';
import { businessPartner } from '../../../../../../models/entities/businessPartner/businessPartner';
import { address, baseAddress } from '../../../../../../models/entities/businessPartner/address';
import { updateContactDetails, updatePickupAddress } from '../utils';
import { filterFieldsType } from '../../../../../../state/ducks/allPurchaseOrdersPage/allPurchaseOrdersPage';
import Vendor from './Vendor';
import { contact } from '../../../../../../models/entities/businessPartner/contact';

const countriesDropDownOptions = countries.map((country) => ({
    text: country.name,
    value: country.code
}));

type Props = {
    values: any;
    setFieldValue: any;
    consigneeAddresses: baseAddress[];
    consigneeContacts: contact[];
    fetchShipmentsByFilter: (filter: filterFieldsType) => void;
    localizations: any;
};
const Consignee = ({ values, setFieldValue, fetchShipmentsByFilter, consigneeAddresses, consigneeContacts, localizations }: Props) => {
    return (
        <div style={{ borderRight: '1px solid #e0e0e0', paddingRight: '30px' }}>
            <div style={{ marginBottom: '10px' }}>Deliver to</div>
            {consigneeContacts.length > 1 && (
                <FieldContainer>
                    <div>{renderFieldLabel(localizations.pick_contact, false)}</div>
                    <FormDropDown
                        name="consignee_contacts"
                        options={consigneeContacts.map((item) => {
                            return { text: item.name, value: item.id?.toString() };
                        })}
                        onChange={(value: string) => {
                            const contact = consigneeContacts.find((item) => '' + item.id === value);
                            if (contact) updateContactDetails('consignee', setFieldValue, contact, values);
                        }}
                        placeHolder={localizations.pick_contact}
                    />
                </FieldContainer>
            )}
            <FieldContainer>
                <div>{renderFieldLabel(localizations.contact_name, false, localizations.contact_name)}</div>
                <div>
                    <FormTextInput name="consignee_contact_name" style={{ width: '200px' }} placeHolder={localizations.contact_name} />
                </div>
            </FieldContainer>
            <FieldContainer>
                <div>{renderFieldLabel(localizations.contact_phone, false, localizations.contact_phone)}</div>
                <div>
                    <FormTextInput name="consignee_contact_phoneNumber" style={{ width: '200px' }} placeHolder={localizations.contact_phone} />
                </div>
            </FieldContainer>
            <FieldContainer>
                <div>{renderFieldLabel(localizations.contact_email, false, localizations.contact_email)}</div>
                <div>
                    <FormTextInput name="consignee_contact_email" style={{ width: '200px' }} placeHolder={localizations.contact_email} />
                </div>
            </FieldContainer>
            {consigneeAddresses.length > 1 && (
                <FieldContainer>
                    <div>{renderFieldLabel(localizations.pick_address, false)}</div>
                    <FormDropDown
                        name="consignee_addresses"
                        options={consigneeAddresses.map((item) => {
                            return { text: item.name || item.city + ',' + item.country, value: item.id.toString() };
                        })}
                        onChange={(value: string) => {
                            const address = consigneeAddresses.find((item) => '' + item.id === value);
                            if (address) updatePickupAddress('destination', setFieldValue, address, values);
                        }}
                        placeHolder={localizations.pick_address}
                    />
                </FieldContainer>
            )}
            <FieldContainer>
                <div>{renderFieldLabel(localizations.destination_country, false, localizations.destination_country)}</div>
                <div>
                    <FormDropDown
                        name="destination_country"
                        style={{ width: '200px' }}
                        placeHolder={localizations.pick_country}
                        options={countriesDropDownOptions}
                        optionRenderer={renderCountryOption}
                        onChange={(value: any) => {
                            fetchShipmentsByFilter([
                                { field: 'destinationCountry', value },
                                { field: 'originCountry', value: values.origin_country }
                            ]);
                            setFieldValue('destination', '');
                            setFieldValue('destination_zipcode', '');
                        }}
                        showSearch
                    />
                </div>
            </FieldContainer>
            <FieldContainer>
                <div>{renderFieldLabel(localizations.destination_port, false, localizations.destination_port)}</div>
                <div>
                    <FormDropDown
                        disabled={!values.destination_country || !(values.destination_country.length > 0)}
                        name="destination"
                        style={{ width: '200px' }}
                        placeHolder={localizations.pick_port}
                        options={airports
                            .filter((airport) => airport.iso === values.destination_country)
                            .map((airport) => ({
                                text: airport.name ? airport.name : airport.iata,
                                value: airport.iata
                            }))}
                        showSearch
                    />
                </div>
            </FieldContainer>

            <FieldContainer>
                <div>{renderFieldLabel(localizations.destination_city, false, localizations.destination_city)}</div>
                <div>
                    <LocationSearchInput
                        name="destination_zipcode"
                        placeHolder={localizations.pick_zipcode}
                        countryCode={values.destination_country}
                        width={'200px'}
                    />
                </div>
            </FieldContainer>
            <FieldContainer>
                <div>{renderFieldLabel(localizations.destination_pick_up_address, false, localizations.destination_pick_up_address)}</div>
                <div>
                    <FormTextArea
                        placeHolder={localizations.destination_pick_up_address}
                        name="destination_pickup_address"
                        width={'200px'}
                        height={'100px'}
                    />
                </div>
            </FieldContainer>
        </div>
    );
};

export default Consignee;
