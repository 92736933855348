import BaseController from '../..';
import { party } from '../../../../models/entities/common_subentities/party';
import IPurchaseOrderParties from './IPurchaseOrderParties';

export default class PurchaseOrderParties extends BaseController implements IPurchaseOrderParties {
    constructor(baseUrl: string) {
        super(baseUrl);
    }
    async fetchPurchaseOrderParties(purchaseOrderNumber: string) {
        const response = await this.client.get(`/purchaseOrder/parties/${purchaseOrderNumber}`);
        return response;
    }
    async createPurchaseOrderParty(purchaseOrderId: string, data: party) {
        const response = await this.client.post(`/purchaseOrder/parties`, JSON.stringify({ purchaseOrderId, data }));
        return response;
    }
    async updatePurchaseOrderParty(purchaseOrderId: string, data: party) {
        const response = await this.client.put(`/purchaseOrder/parties`, JSON.stringify({ purchaseOrderId, data }));
        return response;
    }
    async deletePurchaseOrderParty(purchaseOrderId: string, partyId: number) {
        const response = await this.client.delete(`/purchaseOrder/parties`, JSON.stringify({ purchaseOrderId, id: partyId }));
        return response;
    }
}
