import React, { useEffect, useState } from 'react';
import { Form, Formik } from 'formik';
import { RootState } from '../../../../../state/store/store';
import { connect } from 'react-redux';
import { styled } from '../../../../shared/Theme/theme';
import Button from '../../../../shared/SmallComponents/Button';
import { localizationSelectors } from '../../../../../state/ducks/localization/selectors';
import { filterFieldsType } from '../../../../../state/ducks/allBusinessPartnersPage/allBusinessPartnersPage';
import { Flex } from '../../../singleShipment/ShipmentForm/ShipmentForm';
import FieldLabel from '../../../../shared/SmallComponents/FieldLabel';
import { FormDropDown } from '../../../../shared/inputs/form';
import { allBusinessPartnersPageSelectors } from '../../../../../state/ducks/allBusinessPartnersPage/selectors';
import { settings } from '../../../../../models/entities/businessPartner/businessPartner';

type Props = {
    localizations: any;
    filterFields: filterFieldsType | null;
    onCancel: () => void;
    setBusinessPartnerFilters: (filter: filterFieldsType) => void;
    settings?: settings;
};
type formFilterFields = {
    type?: string[];
};

const FilterContent = ({ onCancel, setBusinessPartnerFilters, filterFields, settings, localizations }: Props) => {
    const [formFieldsValues, setFormFieldsValues] = useState<formFilterFields>({});

    useEffect(() => {
        const filterFieldsValues: formFilterFields = {};
        filterFields?.forEach(({ field, value }: { field: string; value: any }) => {
            (filterFieldsValues as any)[field] = value;
        });
        setFormFieldsValues(filterFieldsValues);
    }, [filterFields]);

    const partyTypesOptions = settings?.partyTypes?.map((item) => ({
        text: item.name,
        value: item.code
    }));

    const setInitialValues = () => {
        return {};
    };
    const validate = () => {
        const errors: any = {};
        return errors;
    };
    const submit = (values: any) => {
        const filter: filterFieldsType = [];
        Object.keys(formFieldsValues).forEach((item) => {
            if ((formFieldsValues as any)[item]) filter.push({ field: item, value: (formFieldsValues as any)[item] });
        });

        setBusinessPartnerFilters(filter);
        onCancel();
    };

    const resetForm = () => {
        setFormFieldsValues({});
        setBusinessPartnerFilters([]);
    };
    return (
        <Formik initialValues={setInitialValues()} validate={validate} onSubmit={submit}>
            {({}: any) => {
                return (
                    <FormContainer>
                        <Container>
                            <FieldContainer data-popover-bug>
                                <FieldLabel>{localizations.type}</FieldLabel>
                                <FormDropDown
                                    preventPopoverBug={true}
                                    name="type"
                                    placeHolder={localizations.type}
                                    options={partyTypesOptions || []}
                                    allowClear
                                    mode="multiple"
                                    onChange={(value: string[]) => setFormFieldsValues({ ...formFieldsValues, type: value })}
                                    value={formFieldsValues.type}
                                />
                            </FieldContainer>
                        </Container>
                        <FormFooter>
                            <Button minWidth="55px" type="button" onClick={onCancel}>
                                <span>{localizations.cancel_btn}</span>
                            </Button>
                            <Flex>
                                <Button minWidth="55px" type="button" onClick={resetForm}>
                                    <span>{localizations.cancel_btn}</span>
                                </Button>
                                <Button minWidth="55px" buttonType="filled" type="submit">
                                    <span>{localizations.filter_btn}</span>
                                </Button>
                            </Flex>
                        </FormFooter>
                    </FormContainer>
                );
            }}
        </Formik>
    );
};
const FormContainer = styled(Form)`
    min-width: 500px;
    @media screen and (max-width: 768px) {
        min-width: 100%;
    }
`;

const FieldContainer = styled.div`
    margin-bottom: 12px;
    flex-basis: 45%;
    width: 0;
    padding: 0 10px;
    @media screen and (max-width: 768px) {
        flex-basis: 100%;
    }
`;
const Container = styled.div`
    width: 100%;
    max-height: 420px;
    overflow: auto;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-around;
    @media screen and (max-width: 768px) {
        height: calc(100vh - 70px);
        padding: 4px 12px;
        max-height: 100%;
    }
`;

const FormFooter = styled.div`
    position: absolute;
    width: 100%;
    display: flex;
    justify-content: space-between;
    height: 70px;
    padding: 17px 30px;
    border-top: 1px solid #eaeaea;
    left: 0;
    right: 0;
    bottom: -70px;
    background: white;
    box-shadow: 0 9px 12px 1px rgb(0 0 0 / 9%);
    button {
        padding: 15px;
    }
    @media screen and (max-width: 768px) {
        bottom: 0;
        button {
            span {
                display: -webkit-box;
            }
        }
    }
`;

const mapStateToProps = (state: RootState) => ({
    localizations: localizationSelectors.allBusinessPartnersPage(state).filter,
    filterFields: allBusinessPartnersPageSelectors.filterFields(state)
});

const mapDispatchToProps = (dispatch: any) => ({
    setBusinessPartnerFilters: (filter: filterFieldsType) => dispatch.allBusinessPartnersPage.setBusinessPartnerFilters(filter)
});

export default connect(mapStateToProps, mapDispatchToProps)(FilterContent);
