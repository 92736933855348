import React, { useEffect, useState } from 'react';
import { settings, product } from '../../../../../models/entities/product/product';
import { localizationSelectors } from '../../../../../state/ducks/localization/selectors';
import { productsSelectors } from '../../../../../state/ducks/products/selectors';
import { RootState } from '../../../../../state/store/store';
import { connect } from 'react-redux';
import { document } from '../../../../../models/entities/common_subentities/document';
import DocumentFormConnected, { formMode } from '../../../commonSubEntities/documents/DocumentForm/DocumentFormConnected';

type Props = {
    entityId?: string;
    document?: document;
    mode: formMode;
    localizations?: any;
    settings?: settings;
    openedFromModal?: {
        setVisible: React.Dispatch<React.SetStateAction<boolean>>;
    };
    confirmation?: { setConfirm: React.Dispatch<React.SetStateAction<boolean>> };
    createProductDocument: (productId: string, document: document) => void;
    updateProductDocument: (productId: string, document: document) => void;
    showLoader?: boolean;
    defaultValues?: document;
};

const ProductDocumentFormConnected = ({
    entityId,
    document,
    localizations,
    mode,
    settings,
    openedFromModal,
    confirmation,
    createProductDocument,
    updateProductDocument,
    showLoader,
    defaultValues
}: Props) => {
    return (
        <DocumentFormConnected<product, settings>
            entity={{ id: entityId } as product}
            document={document}
            localizations={localizations}
            mode={mode}
            settings={settings}
            openedFromModal={openedFromModal}
            confirmation={confirmation}
            createDocument={createProductDocument}
            updateDocument={updateProductDocument}
            showLoader={showLoader}
            defaultValues={defaultValues}
            typeMode="tags"
        />
    );
};
const mapStateToProps = (state: RootState) => ({
    localizations: localizationSelectors.products(state),
    settings: productsSelectors.settingsByLanguage(state),
    showLoader: state.loading.effects.products.createProductDocument || state.loading.effects.products.updateProductDocument
});

const mapDispatchToProps = (dispatch: any) => ({
    createProductDocument: (productId: string, document: document) => dispatch.products.createProductDocument({ productId, document }),
    updateProductDocument: (productId: string, document: document) => dispatch.products.updateProductDocument({ productId, document })
});

export default connect(mapStateToProps, mapDispatchToProps)(ProductDocumentFormConnected);
