import { businessPartner } from '../../../models/entities/businessPartner/businessPartner';
import { ModelConfig } from '@rematch/core';
import { RootState } from '../../store/store';
import { localSDK as client } from '../../../sdk';

export type filterFieldsType = Array<{ field: string; value: any }>;

export type paginationType = { currentPage: number; rowsPerPage: number };

export type businessPartnersPageStateType = {
    gridSort: gridSortState | null;
    gridFilter: string | null;
    error: string | null;
    filterFields: filterFieldsType | null;
    pagination: paginationType;
};

export type gridSortState = {
    direction: 'asc' | 'desc';
    column: keyof businessPartner;
};

export type exportTypes = 'PDF' | 'EXCEL';

const defaultGridSort: gridSortState = {
    direction: 'desc',
    column: 'name'
};

export const allBusinessPartnersPage: ModelConfig<businessPartnersPageStateType> = {
    state: {
        gridSort: defaultGridSort,
        gridFilter: null,
        error: null,
        filterFields: null,
        pagination: {
            currentPage: 0,
            rowsPerPage: 30
        }
    },
    reducers: {
        setGridSort(state: businessPartnersPageStateType, sortData: gridSortState): businessPartnersPageStateType {
            return { ...state, gridSort: sortData };
        },
        setGridFilter(state: businessPartnersPageStateType, filterText: string): businessPartnersPageStateType {
            return { ...state, gridFilter: filterText };
        },
        setFilterFields(state: businessPartnersPageStateType, filterFields: filterFieldsType): businessPartnersPageStateType {
            return { ...state, filterFields };
        },
        setCurrentPage(state: businessPartnersPageStateType, currentPage: number): businessPartnersPageStateType {
            return { ...state, pagination: { ...state.pagination, currentPage } };
        },
        setRowsPerPage(state: businessPartnersPageStateType, rowsPerPage: number): businessPartnersPageStateType {
            return { ...state, pagination: { ...state.pagination, rowsPerPage } };
        },
        setError(state: businessPartnersPageStateType, error: string): businessPartnersPageStateType {
            return { ...state, error: error };
        }
    },
    effects: (dispatch: any) => ({
        async exportDataTable(type: exportTypes, state: RootState) {},
        async exportDataRow({ companyId, type }: { companyId: string; type: exportTypes }) {},
        async setBusinessPartnerFilters(filter: filterFieldsType) {
            dispatch.allBusinessPartnersPage.setFilterFields(filter);
            dispatch.allBusinessPartnersPage.setCurrentPage(0);
        },
        async fetchCompaniesByFilter(filter: filterFieldsType) {
            try {
                const businessPartners = await client.businessPartners().fetchCompaniesByFilter(filter);
                dispatch.allBusinessPartnersPage.setFilterFields(filter);
                dispatch.businessPartners.setBusinessPartners(businessPartners);
                dispatch.allBusinessPartnersPage.setCurrentPage(0);
            } catch (e) {
                dispatch.allBusinessPartnersPage.setError(e);
            }
        },
        async deleteBusinessPartner(businessPartnerId: string) {
            await client.businessPartners().deleteBusinessPartner(businessPartnerId);
            dispatch.businessPartners.removeBusinessPartner(businessPartnerId);
        }
    })
};
