import moment from 'moment';
import { filterFieldsType } from '../../../state/ducks/allPurchaseDemandsPage/allPurchaseDemandsPage';

export type parsedFilterFieldType = {
    field: string;
    parsedValue: string;
    originalValue: any;
};

export const parseFilterFields = (filterFields: filterFieldsType, localization: any) => {
    let parsedFilterFields: parsedFilterFieldType[] = [];
    filterFields.forEach(({ field, value }: { field: string; value: any }) => {
        switch (field) {
            case 'fromDate':
            case 'toDate':
                let title = (() => {
                    switch (field) {
                        case 'fromDate':
                            return localization.date_from;
                        case 'toDate':
                            return localization.date_to;
                        default:
                            return '';
                    }
                })();
                parsedFilterFields.push({ field, parsedValue: title + moment(value).format('DD/MM/YYYY'), originalValue: value });

                break;

            default:
                parsedFilterFields.push({ field, parsedValue: value, originalValue: value });

                break;
        }
    });

    return parsedFilterFields;
};

export const removeFilterItem = (removedFilter: parsedFilterFieldType, filterFields: filterFieldsType) => {
    let newFilterFields: filterFieldsType = [...filterFields];
    const { field, originalValue } = removedFilter;

    switch (field) {
        default:
            let filterIndex = newFilterFields.findIndex((filter) => filter.field === field && filter.value === originalValue);
            if (filterIndex > -1) {
                newFilterFields.splice(filterIndex, 1);
            }
            break;
    }

    return newFilterFields;
};
