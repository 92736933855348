import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { RouteComponentProps, withRouter } from 'react-router-dom';
import { supplierUpdatesUpdatePoItemsReadyDatePayload, supplierUpdatesUpdatePoPayload } from '../../../../models/entities/purchaseOrder/payloads';
import { purchaseOrder, settings } from '../../../../models/entities/purchaseOrder/purchaseOrder';
import { localizationSelectors } from '../../../../state/ducks/localization/selectors';
import { purchaseOrdersSelectors } from '../../../../state/ducks/purchaseOrders/selectors';
import { singlePurchaseOrderSelectors } from '../../../../state/ducks/singlePurchaseOrder/selectors';
import { RootState } from '../../../../state/store/store';
import { getUrlFuncType } from '../../../shared/FilesPreview/Preview';
import Loader from '../../../shared/SmallComponents/Loader';
import ExceptionPage from '../../ExceptionPage/ExceptionPage';
import PoSupplierUpdates from './PoSupplierUpdates';

type PathParams = {
    poSupplierUpdatesToken: string;
};

type Props = RouteComponentProps<PathParams> & {
    purchaseOrder?: purchaseOrder;
    localization: any;
    allPosPageLocalization: any;
    isGetPoSupplierUpdates: boolean;
    getPoSupplierUpdates: (token: string) => Promise<purchaseOrder>;
    supplierUpdatesUpdatePoItemsReadyDate: (payload: supplierUpdatesUpdatePoItemsReadyDatePayload) => Promise<void>;
    supplierUpdatesUpdatePo: (payload: supplierUpdatesUpdatePoPayload) => Promise<void>;
    getPoSupplierUpdatesFileUrl?: getUrlFuncType;
    getPoSupplierUpdatesError?: string;
    settings?: settings;
};
const PoSupplierUpdatesConnected = ({
    localization,
    allPosPageLocalization,
    isGetPoSupplierUpdates,
    getPoSupplierUpdates,
    supplierUpdatesUpdatePoItemsReadyDate,
    supplierUpdatesUpdatePo,
    getPoSupplierUpdatesFileUrl,
    getPoSupplierUpdatesError,
    match,
    purchaseOrder,
    settings
}: Props) => {
    const poSupplierUpdatesToken = match.params.poSupplierUpdatesToken;

    useEffect(() => {
        if (poSupplierUpdatesToken) {
            (async () => await getPoSupplierUpdates(poSupplierUpdatesToken))();
        }
    }, [poSupplierUpdatesToken]);

    if (getPoSupplierUpdatesError) {
        let error = { message: undefined, status: 500 };
        try {
            error = JSON.parse(getPoSupplierUpdatesError);
        } catch (e) {}
        return <ExceptionPage status={error.status} title={error.message} />;
    }
    if (!purchaseOrder || isGetPoSupplierUpdates) return <Loader />;

    return (
        <PoSupplierUpdates
            purchaseOrder={purchaseOrder}
            localization={localization}
            allPosPageLocalization={allPosPageLocalization}
            supplierUpdatesUpdatePoItemsReadyDate={supplierUpdatesUpdatePoItemsReadyDate}
            supplierUpdatesUpdatePo={supplierUpdatesUpdatePo}
            settings={settings}
            getPoSupplierUpdatesFileUrl={getPoSupplierUpdatesFileUrl}
        />
    );
};

const mapStateToProps = (state: RootState) => ({
    allPosPageLocalization: localizationSelectors.allPurchaseOrdersPage(state),
    localization: localizationSelectors.singlePurchaseOrderPage(state),
    getPoSupplierUpdatesError: state.error.effects.purchaseOrders.getPoSupplierUpdates,
    isGetPoSupplierUpdates: !!state.loading.effects.purchaseOrders.getPoSupplierUpdates,
    purchaseOrder: singlePurchaseOrderSelectors.purchaseOrder(state),
    settings: purchaseOrdersSelectors.settingsByLanguage(state)
});

const mapDispatchToProps = (dispatch: any) => ({
    getPoSupplierUpdates: (token: string) => dispatch.purchaseOrders.getPoSupplierUpdates(token),
    supplierUpdatesUpdatePoItemsReadyDate: (payload: supplierUpdatesUpdatePoItemsReadyDatePayload) =>
        dispatch.purchaseOrders.supplierUpdatesUpdatePoItemsReadyDate(payload),
    supplierUpdatesUpdatePo: (payload: supplierUpdatesUpdatePoPayload) => dispatch.purchaseOrders.supplierUpdatesUpdatePo(payload),
    getPoSupplierUpdatesFileUrl: (fileId: string, ...args: any[]) => dispatch.purchaseOrders.getPoSupplierUpdatesFileUrl(fileId)
});

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(PoSupplierUpdatesConnected));
