import React, { useEffect, useState } from 'react';
import { Form, Formik } from 'formik';
import { RootState } from '../../../../../state/store/store';
import { connect } from 'react-redux';
import { styled } from '../../../../shared/Theme/theme';
import { eShipmentTypes } from '../../../../../models/entities/shipment/shipmentTypes';
import { eIncoterms } from '../../../../../models/entities/shipment/incoterms';
import { eOpenDates } from '../../../../../models/entities/shipment/open_dates';
import countries from '../../../../../static/data/countries.json';
import stations from '../../../../../static/data/stations.json';
import Button from '../../../../shared/SmallComponents/Button';
import { localizationSelectors } from '../../../../../state/ducks/localization/selectors';
import { getDatesRange } from '../../../../../utils/date';
import { filterFieldsType } from '../../../../../state/ducks/allShipmentsPage/allShipmentsPage';
import { FormDatePicker, FormDropDown, FormTextInput } from '../../../../shared/inputs/form';
import { enumToDropdownOptions, Option } from '../../../../shared/inputs/base/DropDown';
import { renderCountryOption } from '../../../../shared/SmallComponents/CountryOption';
import FieldLabel from '../../../../shared/SmallComponents/FieldLabel';
import { Flex } from '../../../singleShipment/ShipmentForm/ShipmentForm';
import { allShipmentsPageSelectors } from '../../../../../state/ducks/allShipmentsPage/selectors';
import { businessPartnersSelectors } from '../../../../../state/ducks/businessPartner/selectors';
import { businessPartner } from '../../../../../models/entities/businessPartner/businessPartner';
import { userSelectors } from '../../../../../state/ducks/user/selectors';
import { allowedCompany } from '../../../../../models/entities/user';
import { shipmentsSelectors } from '../../../../../state/ducks/shipments/selectors';
import { userDetails } from '../../../../../state/ducks/users/users';
import { UserDropDown } from '../../../../shared/inputs/base';
type Props = {
    localizations: any;
    filterFields: filterFieldsType | null;
    onCancel: () => void;
    setShipmentFilters: (filter: filterFieldsType, disableDefaultActiveFilter?: boolean) => void;
    shipmentStateOptions: { text: string; value: string; color: string }[];
    businessPartnerForwarders: Array<businessPartner>;
    allowedCompanies: allowedCompany[];
};

type formFilterFields = {
    alertStatus?: string[];
    state?: string[];
    typeCode?: string[];
    companyId?: string[];
    incoterms?: string[];
    forwarderName?: string[];
    fromDate?: Date;
    toDate?: Date;
    fromETA?: Date;
    toETA?: Date;
    originCountry?: string;
    originStation?: string;
    destinationCountry?: string;
    destinationStation?: string;
    shipperName?: string;
    carrierCode?: string;
    commodity?: string;
    isActive?: string;
    salesman?: string;
    customsResponsible?: string;
};

const FilterContent = ({
    localizations,
    allowedCompanies,
    filterFields,
    onCancel,
    setShipmentFilters,
    businessPartnerForwarders,
    shipmentStateOptions
}: Props) => {
    const [formFieldsValues, setFormFieldsValues] = useState<formFilterFields>({});
    useEffect(() => {
        const filterFieldsValues: formFilterFields = {};
        filterFields?.forEach(({ field, value }: { field: string; value: any }) => {
            (filterFieldsValues as any)[field] = value;
        });
        setFormFieldsValues(filterFieldsValues);
    }, [filterFields]);
    const shipmentTypesDropDownOptions = enumToDropdownOptions(eShipmentTypes);
    const incotermsDropDownOptions = enumToDropdownOptions(eIncoterms);
    const openDatesDropDownOptions = enumToDropdownOptions(eOpenDates);
    const countriesDropDownOptions = countries.map((country) => ({
        text: country.name,
        value: country.code
    }));
    const setInitialValues = () => {
        return formFieldsValues;
    };
    const validate = (values: any) => {
        const errors: any = {};
        return errors;
    };
    const submit = (values: any) => {
        const filter: filterFieldsType = [];
        Object.keys(formFieldsValues).forEach((item) => {
            if (item !== 'calcDate' && (formFieldsValues as any)[item] && (formFieldsValues as any)[item].length !== 0)
                filter.push({ field: item, value: (formFieldsValues as any)[item] });
        });
        setShipmentFilters(filter, true);
        onCancel();
    };
    const resetForm = () => {
        setFormFieldsValues({});
        setShipmentFilters([], false);
    };
    const [users] = useState<Array<userDetails>>();
    return (
        <Formik initialValues={setInitialValues()} validate={validate} onSubmit={submit}>
            {({ values, setFieldValue }: any) => {
                let airportsDestOptions: Array<Option> = [];
                let airportsOrigOptions: Array<Option> = [];
                if (values.destinationCountry)
                    airportsDestOptions = stations
                        .filter((station) => station.country === values.destinationCountry)
                        .map((station) => ({
                            text: station.name !== '' ? station.name : station.location,
                            value: station.code
                        }));
                if (values.originCountry)
                    airportsOrigOptions = stations
                        .filter((station) => station.country === values.originCountry)
                        .map((station) => ({
                            text: station.name !== '' ? station.name : station.location,
                            value: station.code
                        }));
                return (
                    <FormContainer>
                        <Container>
                            <FieldContainer data-popover-bug>
                                <FieldLabel>{localizations.active_shipments}</FieldLabel>
                                <FormDropDown
                                    preventPopoverBug={true}
                                    name="isActive"
                                    placeHolder={localizations.active_shipments}
                                    options={[
                                        { text: localizations.active, value: 'true' },
                                        { text: localizations.inactive, value: 'false' }
                                    ]}
                                    allowClear
                                    onChange={(value: string) => setFormFieldsValues({ ...formFieldsValues, isActive: value })}
                                    value={formFieldsValues.isActive}
                                />
                            </FieldContainer>
                            <FieldContainer data-popover-bug>
                                <FieldLabel>{localizations.state}</FieldLabel>
                                <FormDropDown
                                    preventPopoverBug={true}
                                    name="state"
                                    placeHolder={localizations.state}
                                    options={shipmentStateOptions}
                                    allowClear
                                    mode="multiple"
                                    onChange={(value: string[]) => setFormFieldsValues({ ...formFieldsValues, state: value })}
                                    value={formFieldsValues.state}
                                />
                            </FieldContainer>
                            {allowedCompanies.length > 1 && (
                                <>
                                    <FieldContainer style={{ display: 'flex', flexDirection: 'column' }}>
                                        <FieldLabel>{localizations.company}</FieldLabel>
                                        <FormDropDown
                                            name="companyId"
                                            style={{ width: '200px', marginRight: '15px' }}
                                            placeHolder={localizations.pick_company}
                                            options={allowedCompanies.map((item) => {
                                                return { value: item.companyId, text: item.companyName };
                                            })}
                                            allowClear
                                            mode="multiple"
                                            onChange={(value: string[]) => setFormFieldsValues({ ...formFieldsValues, companyId: value })}
                                            value={formFieldsValues.companyId}
                                        />
                                    </FieldContainer>
                                </>
                            )}
                            <FieldContainer data-popover-bug>
                                <FieldLabel>{localizations.alert_status}</FieldLabel>
                                <FormDropDown
                                    preventPopoverBug={true}
                                    name="alertStatus"
                                    placeHolder={localizations.alert_status}
                                    options={[
                                        { text: localizations.all_good, value: '3' },
                                        { text: localizations.warning, value: '2' },
                                        { text: localizations.critical, value: '1' }
                                    ]}
                                    allowClear
                                    mode="multiple"
                                    onChange={(value: string[]) => setFormFieldsValues({ ...formFieldsValues, alertStatus: value })}
                                    value={formFieldsValues.alertStatus}
                                />
                            </FieldContainer>
                            <FieldContainer data-popover-bug>
                                <FieldLabel>{localizations.shipment_type}</FieldLabel>
                                <FormDropDown
                                    preventPopoverBug={true}
                                    name="typeCode"
                                    placeHolder={localizations.shipment_type}
                                    options={shipmentTypesDropDownOptions}
                                    allowClear
                                    mode="multiple"
                                    onChange={(value: string[]) => setFormFieldsValues({ ...formFieldsValues, typeCode: value })}
                                    value={formFieldsValues.typeCode}
                                />
                            </FieldContainer>
                            <FieldContainer data-popover-bug>
                                <FieldLabel>{localizations.incoterms}</FieldLabel>
                                <FormDropDown
                                    preventPopoverBug={true}
                                    name="incoterms"
                                    placeHolder={localizations.incoterms}
                                    options={incotermsDropDownOptions}
                                    allowClear
                                    mode="multiple"
                                    onChange={(value: string[]) => setFormFieldsValues({ ...formFieldsValues, incoterms: value })}
                                    value={formFieldsValues.incoterms}
                                />
                            </FieldContainer>
                            <FieldContainer>
                                <FieldLabel>{localizations.forwarder}</FieldLabel>
                                <FormDropDown
                                    preventPopoverBug={true}
                                    name="forwarderName"
                                    placeHolder={localizations.forwarder}
                                    options={businessPartnerForwarders.map((party) => ({
                                        text: party.name,
                                        value: party.name
                                    }))}
                                    allowClear
                                    mode="multiple"
                                    onChange={(value: string[]) => setFormFieldsValues({ ...formFieldsValues, forwarderName: value })}
                                    value={formFieldsValues.forwarderName}
                                />
                            </FieldContainer>
                            <FieldContainer data-popover-bug>
                                <FieldLabel>{localizations.open_date}</FieldLabel>
                                <FormDropDown
                                    preventPopoverBug={true}
                                    name="calcDate"
                                    placeHolder={localizations.open_date}
                                    options={openDatesDropDownOptions}
                                    onChange={(value: string) => {
                                        const range = getDatesRange(value);
                                        setFieldValue('fromDate', range.from);
                                        setFieldValue('toDate', range.to);
                                        setFormFieldsValues({ ...formFieldsValues, fromDate: range.from, toDate: range.to });
                                    }}
                                    allowClear
                                />
                            </FieldContainer>
                            {values.calcDate === 'CUSTOM' && (
                                <>
                                    <FieldContainer data-popover-bug>
                                        <FieldLabel>{localizations.from_date}</FieldLabel>
                                        <FormDatePicker
                                            name="fromDate"
                                            placeHolder={localizations.from_date}
                                            onChange={(value: Date) => setFormFieldsValues({ ...formFieldsValues, fromDate: value })}
                                            value={formFieldsValues.fromDate}
                                            preventPopoverBug={true}
                                        />
                                    </FieldContainer>
                                    <FieldContainer data-popover-bug>
                                        <FieldLabel>{localizations.to_date}</FieldLabel>
                                        <FormDatePicker
                                            name="toDate"
                                            placeHolder={localizations.to_date}
                                            onChange={(value: Date) => setFormFieldsValues({ ...formFieldsValues, toDate: value })}
                                            value={formFieldsValues.toDate}
                                            preventPopoverBug={true}
                                        />
                                    </FieldContainer>
                                </>
                            )}
                            <FieldContainer data-popover-bug>
                                <FieldLabel>{localizations.eta_from_date}</FieldLabel>
                                <FormDatePicker
                                    name="fromETA"
                                    placeHolder={localizations.eta_from_date}
                                    onChange={(value: Date) => setFormFieldsValues({ ...formFieldsValues, fromETA: value })}
                                    value={formFieldsValues.fromETA}
                                    preventPopoverBug={true}
                                />
                            </FieldContainer>
                            <FieldContainer data-popover-bug>
                                <FieldLabel>{localizations.eta_to_date}</FieldLabel>
                                <FormDatePicker
                                    name="toETA"
                                    placeHolder={localizations.eta_to_date}
                                    onChange={(value: Date) => setFormFieldsValues({ ...formFieldsValues, toETA: value })}
                                    value={formFieldsValues.toETA}
                                    preventPopoverBug={true}
                                />
                            </FieldContainer>
                            <FieldContainer data-popover-bug>
                                <FieldLabel>{localizations.origin_country}</FieldLabel>
                                <FormDropDown
                                    preventPopoverBug={true}
                                    name="originCountry"
                                    placeHolder={localizations.origin_country}
                                    options={countriesDropDownOptions}
                                    optionRenderer={renderCountryOption}
                                    showSearch={true}
                                    onChange={(value: string) => setFormFieldsValues({ ...formFieldsValues, originCountry: value })}
                                    value={formFieldsValues.originCountry}
                                    allowClear
                                />
                            </FieldContainer>
                            <FieldContainer data-popover-bug>
                                <FieldLabel>{localizations.origin_station}</FieldLabel>
                                <FormDropDown
                                    showSearch
                                    preventPopoverBug={true}
                                    name="originStation"
                                    placeHolder={localizations.origin_station}
                                    options={airportsOrigOptions}
                                    onChange={(value: string) => setFormFieldsValues({ ...formFieldsValues, originStation: value })}
                                    value={formFieldsValues.originStation}
                                    allowClear
                                />
                            </FieldContainer>
                            <FieldContainer>
                                <FieldLabel>{'Salesman'}</FieldLabel>
                                <UserDropDown
                                    value={formFieldsValues.salesman}
                                    users={users}
                                    placeHolder="Salesman"
                                    mode="multiple"
                                    showSearch
                                    customUsers
                                    onChange={(value: string) => setFormFieldsValues({ ...formFieldsValues, salesman: value })}
                                />
                            </FieldContainer>
                            <Divider />
                            <FieldContainer data-popover-bug>
                                <FieldLabel>{localizations.destination_country}</FieldLabel>
                                <FormDropDown
                                    preventPopoverBug={true}
                                    name="destinationCountry"
                                    placeHolder={localizations.destination_country}
                                    options={countriesDropDownOptions}
                                    optionRenderer={renderCountryOption}
                                    showSearch={true}
                                    onChange={(value: string) => setFormFieldsValues({ ...formFieldsValues, destinationCountry: value })}
                                    value={formFieldsValues.destinationCountry}
                                    allowClear
                                />
                            </FieldContainer>
                            <FieldContainer data-popover-bug>
                                <FieldLabel>{localizations.destination_station}</FieldLabel>
                                <FormDropDown
                                    showSearch
                                    preventPopoverBug={true}
                                    name="destinationStation"
                                    placeHolder={localizations.destination_station}
                                    options={airportsDestOptions}
                                    onChange={(value: string) => setFormFieldsValues({ ...formFieldsValues, destinationStation: value })}
                                    value={formFieldsValues.destinationStation}
                                    allowClear
                                />
                            </FieldContainer>
                            <FieldContainer>
                                <FieldLabel>{localizations.vendor}</FieldLabel>
                                <FormTextInput
                                    name="shipperName"
                                    placeHolder={localizations.vendor}
                                    onChange={(value: string) => setFormFieldsValues({ ...formFieldsValues, shipperName: value })}
                                    value={formFieldsValues.shipperName}
                                    allowClear
                                />
                            </FieldContainer>
                            <FieldContainer>
                                <FieldLabel>{localizations.carrier}</FieldLabel>
                                <FormTextInput
                                    name="carrierCode"
                                    placeHolder={localizations.carrier}
                                    onChange={(value: string) => setFormFieldsValues({ ...formFieldsValues, carrierCode: value })}
                                    value={formFieldsValues.carrierCode}
                                    allowClear
                                />
                            </FieldContainer>
                            <FieldContainer>
                                <FieldLabel>Commodity</FieldLabel>
                                <FormTextInput
                                    name="commodity"
                                    placeHolder={'Commodity'}
                                    onChange={(value: string) => setFormFieldsValues({ ...formFieldsValues, commodity: value })}
                                    value={formFieldsValues.commodity}
                                    allowClear
                                />
                            </FieldContainer>
                            <FieldContainer>
                                <FieldLabel>Customs Responsible?</FieldLabel>
                                <FormDropDown
                                    name="customsResponsible"
                                    placeHolder={localizations.customs_responsible}
                                    options={[
                                        { text: 'Yes', value: 'true' },
                                        { text: 'No', value: 'false' }
                                    ]}
                                    allowClear
                                    onChange={(value: string) => setFormFieldsValues({ ...formFieldsValues, customsResponsible: value })}
                                    value={formFieldsValues.customsResponsible}
                                />
                            </FieldContainer>
                        </Container>
                        <FormFooter>
                            <Button minWidth="55px" type="button" onClick={onCancel}>
                                <span>{localizations.cancel_btn}</span>
                            </Button>
                            <Flex>
                                <Button minWidth="55px" type="button" onClick={resetForm}>
                                    <span>{localizations.reset_btn}</span>
                                </Button>
                                <Button minWidth="55px" buttonType="filled" type="submit">
                                    <span>{localizations.filter_btn}</span>
                                </Button>
                            </Flex>
                        </FormFooter>
                    </FormContainer>
                );
            }}
        </Formik>
    );
};
const FormContainer = styled(Form)`
    min-width: 500px;
    @media screen and (max-width: 768px) {
        min-width: 100%;
    }
`;
const FieldContainer = styled.div`
    margin-bottom: 12px;
    flex-basis: 45%;
    width: 0;
    padding: 0 10px;
    @media screen and (max-width: 768px) {
        flex-basis: 100%;
    }
`;
const Divider = styled.div`
    width: 100%;
    margin-bottom: 12px;
    border-top: 1px solid rgba(0, 0, 0, 0.06);
    @media screen and (max-width: 768px) {
        display: none;
    }
`;
const Container = styled.div`
    width: 100%;
    max-height: 420px;
    overflow: auto;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-around;
    @media screen and (max-width: 768px) {
        height: calc(100vh - 70px);
        padding: 4px 12px;
        max-height: 100%;
    }
`;
const FormFooter = styled.div`
    position: absolute;
    width: 100%;
    display: flex;
    justify-content: space-between;
    height: 70px;
    padding: 17px 30px;
    border-top: 1px solid #eaeaea;
    left: 0;
    right: 0;
    bottom: -70px;
    background: white;
    box-shadow: 0 9px 12px 1px rgb(0 0 0 / 9%);
    button {
        padding: 15px;
    }
    @media screen and (max-width: 768px) {
        bottom: 0;
        button {
            span {
                display: -webkit-box;
            }
        }
    }
`;
const mapStateToProps = (state: RootState) => ({
    localizations: localizationSelectors.allShipmentsPage(state).filter,
    filterFields: allShipmentsPageSelectors.filterFields(state),
    businessPartnerForwarders: businessPartnersSelectors.businessPartnerForwarders(state),
    allowedCompanies: userSelectors.allowedCompanies(state),
    shipmentStateOptions: shipmentsSelectors.shipmentStateOptions(state)
});
const mapDispatchToProps = (dispatch: any) => ({
    setShipmentFilters: (filter: filterFieldsType, disableDefaultActiveFilter?: boolean) =>
        dispatch.allShipmentsPage.setShipmentFilters({ filter, disableDefaultActiveFilter })
});
export default connect(mapStateToProps, mapDispatchToProps)(FilterContent);
