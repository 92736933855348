import { history } from './../../../models/entities/common_subentities/history';
import { createOrderFollowerPayload } from './../../../models/entities/order/orderFollower/payloads/createOrderFollowerPayload';
import { orderFollower } from './../../../models/entities/order/orderFollower/orderFollower';
import { gridSortType } from './../../../components/shared/Grid/Grid';
import { orderFilterFields } from './gridFilters/types/orderFilterFields';
import { document } from './../../../models/entities/common_subentities/document';
import { orderAlert } from './../../../models/entities/order/orderAlert/orderAlert';
import { orderEvent } from './../../../models/entities/order/orderEvent/orderEvent';
import { orderDiscussion } from './../../../models/entities/order/orderDiscussion/orderDiscussion';
import { createOrderItemsPayload, updateOrderItemPayload, deleteOrderItemPayload } from './../../../models/entities/order/orderItem/payloads';
import {
    createMultipleOrderEventsPayload,
    createOrderEventPayload,
    updateOrderEventPayload,
    deleteOrderEventPayload
} from './../../../models/entities/order/orderEvent/payloads';
import {
    createOrderDiscussionPayload,
    updateOrderDiscussionPayload,
    deleteOrderDiscussionPayload
} from './../../../models/entities/order/orderDiscussion/payloads';
import { ModelConfig } from '@rematch/core';
import { paginationType } from '../../../components/shared/PageTemplates/GridPage/components/GridPageHeader/models';
import { order } from '../../../models/entities/order/order';
import { orderItem } from '../../../models/entities/order/orderItem/orderItem';
import {
    createOrderPayload,
    updateOrderPayload,
    updateOrderStatePayload,
    bulkUpdateOrdersStatePayload,
    bulkDeleteOrdersPayload
} from '../../../models/entities/order/payloads';
import { localSDK as client } from '../../../sdk';
import { deleteOrderFollowerPayload, bulkFollowPayload, bulkUnfollowPayload } from '../../../models/entities/order/orderFollower/payloads';

export type orderPdfSvgIconsType = { typeCodeSvg: string; originCountrySvg: string; arrowIconSvg: string; destinationCountrySvg: string };

export type ordersStateType = {
    orders: order[];
    gridFilter: string | null;
    filterFields: orderFilterFields | null;
    pagination: paginationType;
    ordersGridSort?: gridSortType<order>;
};

export const orders: ModelConfig<ordersStateType> = {
    state: {
        orders: [],
        gridFilter: null,
        filterFields: [],
        pagination: {
            currentPage: 0,
            rowsPerPage: 30
        },
        ordersGridSort: {
            updatedAt: 'desc'
        }
    },
    reducers: {
        setOrders(state: ordersStateType, orders: order[]): ordersStateType {
            return { ...state, orders };
        },
        addOrder(state: ordersStateType, order: order): ordersStateType {
            const newOrders = [...state.orders, order];
            return { ...state, orders: newOrders };
        },
        editOrder(state: ordersStateType, updatedOrder: Partial<order>, orderId?: string): ordersStateType {
            const newOrders = [...state.orders];

            if (!updatedOrder.id && !orderId) return state;

            const orderIndex = newOrders.findIndex((order) => order.id === (updatedOrder.id || orderId));
            if (orderIndex !== -1) {
                newOrders[orderIndex] = { ...newOrders[orderIndex], ...updatedOrder };
            }

            return { ...state, orders: newOrders };
        },
        deleteMultipleOrders(state: ordersStateType, ordersIds: string[]): ordersStateType {
            const newOrders = [...state.orders].filter((order) => !ordersIds.includes(order.id));
            return { ...state, orders: newOrders };
        },
        editMultipleOrders(state: ordersStateType, updatedOrders: Partial<order>[], ordersIds?: string): ordersStateType {
            const newOrders = [...state.orders];

            if (!ordersIds?.length || !updatedOrders.length) return state;

            for (const orderId of ordersIds) {
                const updatedFields = updatedOrders.find((updatedOrder) => updatedOrder.id === orderId) || updatedOrders[0];
                if (!updatedFields) continue;
                const orderIndex = newOrders.findIndex((order) => order.id === orderId);
                if (orderIndex !== -1) {
                    newOrders[orderIndex] = { ...newOrders[orderIndex], ...updatedFields };
                }
            }

            return { ...state, orders: newOrders };
        },
        setSingleOrder(state: ordersStateType, newOrder: order): ordersStateType {
            const newOrders = [...state.orders];
            const orderIndex = newOrders.findIndex((order) => order.id === newOrder.id);
            if (orderIndex !== -1) {
                newOrders[orderIndex] = newOrder;
            } else {
                newOrders.push(newOrder);
            }
            return { ...state, orders: newOrders };
        },
        addMultipleOrderItems(state: ordersStateType, orderId: string, items: Array<orderItem>): ordersStateType {
            const newOrders = [...state.orders];
            const orderIndex = newOrders.findIndex((p) => p.id === orderId);

            if (orderIndex !== -1) {
                const currentOrder = { ...newOrders[orderIndex] };
                const currentOrderItems = currentOrder.items || [];
                const newOrderItems = [...currentOrderItems, ...items];
                currentOrder.items = newOrderItems;
                newOrders[orderIndex] = currentOrder;
            }
            return { ...state, orders: [...newOrders] };
        },
        editOrderItem(state: ordersStateType, { orderId, itemId, item }: updateOrderItemPayload): ordersStateType {
            const newOrders = [...state.orders];
            const orderIndex = newOrders.findIndex((p) => p.id === orderId);

            if (orderIndex === -1) return state;

            const currentOrder = { ...newOrders[orderIndex] };
            const currentOrderItems = [...(currentOrder.items || [])];
            const itemIndex = currentOrderItems.findIndex((i) => i.id == itemId);

            if (itemIndex === -1) return state;

            const newItem = { ...currentOrderItems[itemIndex], ...item };
            currentOrderItems[itemIndex] = newItem;

            currentOrder.items = currentOrderItems;
            newOrders[orderIndex] = currentOrder;

            return { ...state, orders: [...newOrders] };
        },
        removeOrderItem(state: ordersStateType, { orderId, itemId }: deleteOrderItemPayload): ordersStateType {
            const newOrders = [...state.orders];
            const orderIndex = newOrders.findIndex((p) => p.id === orderId);

            if (orderIndex === -1) return state;

            const currentOrder = { ...newOrders[orderIndex] };
            const currentOrderItems = [...(currentOrder.items || [])];
            const itemIndex = currentOrderItems.findIndex((i) => i.id == itemId);

            if (itemIndex <= -1) return state;

            currentOrderItems.splice(itemIndex, 1);

            currentOrder.items = currentOrderItems;
            newOrders[orderIndex] = currentOrder;

            return { ...state, orders: [...newOrders] };
        },

        addMultipleOrderDocuments(state: ordersStateType, orderId: string, documents: Array<document>): ordersStateType {
            const newOrders = [...state.orders];
            const orderIndex = newOrders.findIndex((p) => p.id === orderId);

            if (orderIndex !== -1) {
                const currentOrder = { ...newOrders[orderIndex] };
                const currentOrderEvents = currentOrder.documents || [];
                const newOrderEvents = [...currentOrderEvents, ...documents];
                currentOrder.documents = newOrderEvents;
                newOrders[orderIndex] = currentOrder;
            }
            return { ...state, orders: [...newOrders] };
        },
        editOrderDocument(state: ordersStateType, orderId: string, document: document): ordersStateType {
            const newOrders = [...state.orders];
            const orderIndex = newOrders.findIndex((p) => p.id === orderId);

            if (orderIndex === -1) return state;

            const currentOrder = { ...newOrders[orderIndex] };
            const currentOrderDocuments = [...(currentOrder.documents || [])];
            const itemIndex = currentOrderDocuments.findIndex((i) => i.id === document.id);

            if (itemIndex === -1) return state;

            const newItem = { ...currentOrderDocuments[itemIndex], ...document };
            currentOrderDocuments[itemIndex] = newItem;

            currentOrder.documents = currentOrderDocuments;
            newOrders[orderIndex] = currentOrder;

            return { ...state, orders: [...newOrders] };
        },
        removeOrderDocument(state: ordersStateType, orderId: string, documentId: number): ordersStateType {
            const newOrders = [...state.orders];
            const orderIndex = newOrders.findIndex((p) => p.id === orderId);

            if (orderIndex === -1) return state;

            const currentOrder = { ...newOrders[orderIndex] };
            const currentOrderDocuments = [...(currentOrder.documents || [])];
            const documentIndex = currentOrderDocuments.findIndex((i) => i.id === documentId);

            if (documentIndex <= -1) return state;

            currentOrderDocuments.splice(documentIndex, 1);

            currentOrder.documents = currentOrderDocuments;
            newOrders[orderIndex] = currentOrder;

            return { ...state, orders: [...newOrders] };
        },
        addMultipleOrderEvents(state: ordersStateType, orderId: string, events: Array<orderEvent>): ordersStateType {
            const newOrders = [...state.orders];
            const orderIndex = newOrders.findIndex((p) => p.id === orderId);

            if (orderIndex !== -1) {
                const currentOrder = { ...newOrders[orderIndex] };
                const currentOrderEvents = currentOrder.events || [];
                const newOrderEvents = [...currentOrderEvents, ...events];
                currentOrder.events = newOrderEvents;
                newOrders[orderIndex] = currentOrder;
            }
            return { ...state, orders: [...newOrders] };
        },
        editOrderEvent(state: ordersStateType, { orderId, eventId, event }: updateOrderEventPayload): ordersStateType {
            const newOrders = [...state.orders];
            const orderIndex = newOrders.findIndex((p) => p.id === orderId);

            if (orderIndex === -1) return state;

            const currentOrder = { ...newOrders[orderIndex] };
            const currentOrderEvents = [...(currentOrder.events || [])];
            const eventIndex = currentOrderEvents.findIndex((i) => i.id === eventId);

            if (eventIndex === -1) return state;

            const newEvent = { ...currentOrderEvents[eventIndex], ...event };
            currentOrderEvents[eventIndex] = newEvent;

            currentOrder.events = currentOrderEvents;
            newOrders[orderIndex] = currentOrder;

            return { ...state, orders: [...newOrders] };
        },
        removeOrderEvent(state: ordersStateType, { orderId, eventId }: deleteOrderEventPayload): ordersStateType {
            const newOrders = [...state.orders];
            const orderIndex = newOrders.findIndex((p) => p.id === orderId);

            if (orderIndex === -1) return state;

            const currentOrder = { ...newOrders[orderIndex] };
            const currentOrderEvents = [...(currentOrder.events || [])];
            const eventIndex = currentOrderEvents.findIndex((i) => i.id === eventId);

            if (eventIndex <= -1) return state;

            currentOrderEvents.splice(eventIndex, 1);

            currentOrder.events = currentOrderEvents;
            newOrders[orderIndex] = currentOrder;

            return { ...state, orders: [...newOrders] };
        },
        addOrderDiscussion(state: ordersStateType, orderId: string, discussion: orderDiscussion): ordersStateType {
            const newOrders = [...state.orders];
            const orderIndex = newOrders.findIndex((p) => p.id === orderId);

            if (orderIndex !== -1) {
                const currentOrder = { ...newOrders[orderIndex] };
                const currentOrderDiscussions = currentOrder.discussions || [];
                const newOrderDiscussions = [...currentOrderDiscussions, discussion];
                currentOrder.discussions = newOrderDiscussions;
                newOrders[orderIndex] = currentOrder;
            }
            return { ...state, orders: [...newOrders] };
        },
        editOrderDiscussion(state: ordersStateType, { orderId, discussionId, discussion }: updateOrderDiscussionPayload): ordersStateType {
            const newOrders = [...state.orders];
            const orderIndex = newOrders.findIndex((p) => p.id === orderId);

            if (orderIndex === -1) return state;

            const currentOrder = { ...newOrders[orderIndex] };
            const currentOrderDiscussions = [...(currentOrder.discussions || [])];
            const discussionIndex = currentOrderDiscussions.findIndex((i) => i.id === discussionId);

            if (discussionIndex === -1) return state;

            const newDiscussion = { ...currentOrderDiscussions[discussionIndex], ...discussion };
            currentOrderDiscussions[discussionIndex] = newDiscussion;

            currentOrder.discussions = currentOrderDiscussions;
            newOrders[orderIndex] = currentOrder;

            return { ...state, orders: [...newOrders] };
        },
        removeOrderDiscussion(state: ordersStateType, { orderId, discussionId }: deleteOrderDiscussionPayload): ordersStateType {
            const newOrders = [...state.orders];
            const orderIndex = newOrders.findIndex((p) => p.id === orderId);

            if (orderIndex === -1) return state;

            const currentOrder = { ...newOrders[orderIndex] };
            const currentOrderDiscussions = [...(currentOrder.discussions || [])];
            const discussionIndex = currentOrderDiscussions.findIndex((i) => i.id === discussionId);

            if (discussionIndex <= -1) return state;

            currentOrderDiscussions.splice(discussionIndex, 1);

            currentOrder.discussions = currentOrderDiscussions;
            newOrders[orderIndex] = currentOrder;

            return { ...state, orders: [...newOrders] };
        },
        addOrderFollower(state: ordersStateType, orderId: string, follower: orderFollower): ordersStateType {
            const newOrders = [...state.orders];
            const orderIndex = newOrders.findIndex((p) => p.id === orderId);

            if (orderIndex !== -1) {
                const currentOrder = { ...newOrders[orderIndex] };
                const currentOrderFollowers = currentOrder.followers || [];
                const newOrderFollowers = [...currentOrderFollowers, follower];
                currentOrder.followers = newOrderFollowers;
                newOrders[orderIndex] = currentOrder;
            }
            return { ...state, orders: [...newOrders] };
        },
        setOrderHistory(state: ordersStateType, orderId: string, history: history[]): ordersStateType {
            const newOrders = [...state.orders];
            const orderIndex = newOrders.findIndex((p) => p.id === orderId);

            if (orderIndex !== -1) {
                const currentOrder = { ...newOrders[orderIndex] };
                const newOrderHistory = history;
                currentOrder.history = newOrderHistory;
                newOrders[orderIndex] = currentOrder;
            }
            return { ...state, orders: [...newOrders] };
        },
        addMultipleOrderFollowers(state: ordersStateType, followers: orderFollower[]): ordersStateType {
            const newOrders = [...state.orders];
            for (const follower of followers) {
                const orderIndex = newOrders.findIndex((p) => p.id === follower.orderId);

                if (orderIndex !== -1) {
                    const currentOrder = { ...newOrders[orderIndex] };
                    const currentOrderFollowers = currentOrder.followers || [];
                    const newOrderFollowers = [...currentOrderFollowers, follower];
                    currentOrder.followers = newOrderFollowers;
                    newOrders[orderIndex] = currentOrder;
                }
            }
            return { ...state, orders: [...newOrders] };
        },
        removeOrderFollower(state: ordersStateType, { orderId, userId }: deleteOrderFollowerPayload): ordersStateType {
            const newOrders = [...state.orders];
            const orderIndex = newOrders.findIndex((p) => p.id === orderId);

            if (orderIndex === -1) return state;

            const currentOrder = { ...newOrders[orderIndex] };
            const currentOrderFollowers = [...(currentOrder.followers || [])];
            const followerIndex = currentOrderFollowers.findIndex((i) => i.userId === userId);

            if (followerIndex <= -1) return state;

            currentOrderFollowers.splice(followerIndex, 1);

            currentOrder.followers = currentOrderFollowers;
            newOrders[orderIndex] = currentOrder;

            return { ...state, orders: [...newOrders] };
        },
        removeMultipleFollowers(state: ordersStateType, { ordersIds, userId }: bulkUnfollowPayload): ordersStateType {
            const newOrders = [...state.orders];
            const orders = newOrders.filter((p) => ordersIds.includes(p.id));

            if (!orders.length) return state;

            for (const order of orders) {
                const orderIndex = newOrders.findIndex((p) => p.id === order.id);

                const currentOrder = { ...order };
                const currentOrderFollowers = [...(currentOrder.followers || [])];
                const followerIndex = currentOrderFollowers.findIndex((i) => i.userId === userId);

                if (followerIndex <= -1) return state;

                currentOrderFollowers.splice(followerIndex, 1);

                currentOrder.followers = currentOrderFollowers;
                newOrders[orderIndex] = currentOrder;
            }

            return { ...state, orders: [...newOrders] };
        },
        setOrderAlerts(state: ordersStateType, orderId: string, orderAlerts: Array<orderAlert>): ordersStateType {
            const currentOrders = [...state.orders];
            const orderIndex = currentOrders.findIndex((item) => item.id === orderId);
            if (orderIndex !== -1) {
                currentOrders[orderIndex].alerts = orderAlerts;
            }

            return { ...state, orders: currentOrders };
        },
        dismissAlert(state: ordersStateType, orderId: string, alertId: string): ordersStateType {
            const currentOrders = [...state.orders];
            const orderIndex = currentOrders.findIndex((item) => item.id === orderId);

            if (orderIndex !== -1) {
                const currentAlerts = currentOrders[orderIndex].alerts;

                if (currentAlerts) {
                    const alertIndex = currentAlerts?.findIndex((a) => a.id === alertId);
                    if (alertIndex !== -1) {
                        currentAlerts[alertIndex].isDismissed = true;
                    }
                }
            }

            return { ...state, orders: currentOrders };
        },
        setGridFilter(state: ordersStateType, filterText: string): ordersStateType {
            return { ...state, gridFilter: filterText };
        },
        setCurrentPage(state: ordersStateType, currentPage: number): ordersStateType {
            return { ...state, pagination: { ...state.pagination, currentPage } };
        },
        setRowsPerPage(state: ordersStateType, rowsPerPage: number): ordersStateType {
            return { ...state, pagination: { ...state.pagination, rowsPerPage } };
        },
        setFilterFields(state: ordersStateType, filterFields: orderFilterFields): ordersStateType {
            return { ...state, filterFields };
        },
        setOrdersGridSort(state: ordersStateType, ordersGridSort?: gridSortType<order>): ordersStateType {
            return { ...state, ordersGridSort };
        }
    },
    effects: (dispatch: any) => ({
        async fetchOrders() {
            const orders = await client.orders().fetchOrders();
            dispatch.orders.setOrders(orders);
            dispatch.orders.setCurrentPage(0);
        },
        async fetchOrderById(orderId: string) {
            const order = await client.orders().fetchOrderById(orderId);
            dispatch.orders.setSingleOrder(order);

            return order;
        },
        async createOrder(payload: createOrderPayload) {
            const order = await client.orders().createOrder(payload);
            dispatch.orders.addOrder(order);
        },
        async updateOrder(payload: updateOrderPayload) {
            dispatch.orders.editOrder(payload, payload.orderId);
            const updatedOrder = await client.orders().updateOrder(payload);
            dispatch.orders.fetchOrderHistory(payload.orderId);
        },
        async bulkDeleteOrders(payload: bulkDeleteOrdersPayload) {
            await client.orders().bulkDeleteOrders(payload);
            dispatch.orders.deleteMultipleOrders(payload.ordersIds);
        },
        async updateOrderState(payload: updateOrderStatePayload) {
            dispatch.loadingNotify.setLoadingState({ visible: true, text: 'Updating State...' });
            dispatch.orders.editOrder(payload, payload.orderId);
            const updatedOrder = await client.orders().updateOrderState(payload);
            dispatch.orders.editOrder(updatedOrder);
            dispatch.orders.fetchOrderHistory(payload.orderId);
            dispatch.loadingNotify.setLoadingState({ visible: false });
        },
        async bulkUpdateOrdersState(payload: bulkUpdateOrdersStatePayload) {
            dispatch.orders.editMultipleOrders([payload], payload.ordersIds);
            const updatedOrders = await client.orders().bulkUpdateOrdersState(payload);
            dispatch.orders.editMultipleOrders(updatedOrders, payload.ordersIds);
        },
        async setOrdersFilters(filter: orderFilterFields) {
            dispatch.orders.setFilterFields(filter);
            dispatch.orders.setCurrentPage(0);
        },
        async fetchOrdersByFilter(filter: orderFilterFields) {
            try {
                dispatch.orders.setFilterFields(filter);
                // const orders = await client.orders().fetchOrdersByFilter(filter);
                // dispatch.orders.setOrders(orders);
                dispatch.orders.setCurrentPage(0);
            } catch (e) {
                dispatch.orders.setError(e);
            }
        },

        //#region items
        async createOrderItems(payload: createOrderItemsPayload) {
            const createdItems = await client.orders().items().createMultipleOrderItems(payload);
            dispatch.orders.addMultipleOrderItems(payload.orderId, createdItems);
            dispatch.orders.fetchOrderHistory(payload.orderId);
        },
        async updateOrderItem(payload: updateOrderItemPayload) {
            dispatch.orders.editOrderItem(payload);
            await client.orders().items().updateOrderItem(payload);
            dispatch.orders.fetchOrderHistory(payload.orderId);
        },
        async deleteOrderItem(payload: deleteOrderItemPayload) {
            await client.orders().items().deleteOrderItem(payload);
            dispatch.orders.removeOrderItem(payload);
            dispatch.orders.fetchOrderHistory(payload.orderId);
        },
        //#endregion

        //#region events
        async createOrderEvents(payload: createMultipleOrderEventsPayload) {
            const createdEvents = await client.orders().events().createMultipleOrderEvents(payload);
            dispatch.orders.addMultipleOrderEvents(payload.orderId, createdEvents);
            const order = await client.orders().fetchOrderById(payload.orderId);
            dispatch.orders.setSingleOrder(order);
            dispatch.orders.fetchOrderHistory(payload.orderId);
        },
        async updateOrderEvent(payload: updateOrderEventPayload) {
            dispatch.orders.editOrderEvent(payload);
            await client.orders().events().updateOrderEvent(payload);
            dispatch.orders.fetchOrderHistory(payload.orderId);
        },
        async deleteOrderEvent(payload: deleteOrderEventPayload) {
            await client.orders().events().deleteOrderEvent(payload);
            dispatch.orders.removeOrderEvent(payload);
            dispatch.orders.fetchOrderHistory(payload.orderId);
        },
        //#endregion

        //#region discussions
        async createOrderDiscussion(payload: createOrderDiscussionPayload) {
            const createdDiscussion = await client.orders().discussions().createOrderDiscussion(payload);
            dispatch.orders.addOrderDiscussion(payload.orderId, createdDiscussion);
        },
        async updateOrderDiscussion(payload: updateOrderDiscussionPayload) {
            dispatch.orders.editOrderDiscussion(payload);
            await client.orders().discussions().updateOrderDiscussion(payload);
        },
        async deleteOrderDiscussion(payload: deleteOrderDiscussionPayload) {
            await client.orders().discussions().deleteOrderDiscussion(payload);
            dispatch.orders.removeOrderDiscussion(payload);
        },
        //#endregion

        //#region followers
        async createOrderFollower(payload: createOrderFollowerPayload) {
            const createdFollower = await client.orders().followers().createOrderFollower(payload);
            dispatch.orders.addOrderFollower(payload.orderId, createdFollower);
        },
        async bulkFollow(payload: bulkFollowPayload) {
            const createdFollowers = await client.orders().followers().bulkFollow(payload);
            dispatch.orders.addMultipleOrderFollowers(createdFollowers);
        },
        async bulkUnfollow(payload: bulkUnfollowPayload) {
            await client.orders().followers().bulkUnfollow(payload);
            dispatch.orders.removeMultipleFollowers(payload);
        },
        async deleteOrderFollower(payload: deleteOrderFollowerPayload) {
            await client.orders().followers().deleteOrderFollower(payload);
            dispatch.orders.removeOrderFollower(payload);
        },
        //#endregion

        async fetchOrderHistory(orderId: string) {
            const orderHistoryData = await client.orders().history().fetchOrderHistory(orderId);
            dispatch.orders.setOrderHistory(orderId, orderHistoryData);
        },

        //#region alerts
        async fetchAlertsByOrderId(orderId: string) {
            const orderAlertsData = await client.orders().alerts().fetchAlertsByOrderId(orderId);
            dispatch.orders.setOrderAlerts(orderId, orderAlertsData);
        },
        async dismissOrderAlert({ orderId, alert }: { orderId: string; alert: orderAlert }) {
            dispatch.orders.dismissAlert(orderId, alert.id);
            await client.orders().alerts().dismissOrderAlert(orderId, alert);
        },
        //#end region

        async createMultipleOrderDocument({ orderId, documents }: { orderId: string; documents: Array<document> }) {
            dispatch.loadingNotify.setLoadingState({ visible: true, text: 'Uploading files...' });
            const createdDocuments = await client.orders().documents().createMultipleOrderDocument(orderId, documents);
            dispatch.orders.addMultipleOrderDocuments(orderId, createdDocuments);
            dispatch.orders.fetchOrderHistory(orderId);
            dispatch.loadingNotify.setLoadingState({ visible: false });
        },
        async updateOrderDocument({ orderId, document }: { orderId: string; document: document }) {
            await client.orders().documents().updateOrderDocument(orderId, document);
            dispatch.orders.editOrderDocument(orderId, document);
            dispatch.orders.fetchOrderHistory(orderId);
        },
        async deleteOrderDocument({ orderId, documentId }: { orderId: string; documentId: number }) {
            await client.orders().documents().deleteOrderDocument(orderId, documentId);
            dispatch.orders.removeOrderDocument(orderId, documentId);
            dispatch.orders.fetchOrderHistory(orderId);
        }
    })
};
