import React, { useEffect, useState } from 'react';
import { businessPartner } from '../../../../../models/entities/businessPartner/businessPartner';
import { styled } from '../../../../shared/Theme/theme';
import { Form, Formik } from 'formik';
import { localizationSelectors } from '../../../../../state/ducks/localization/selectors';
import { RootState } from '../../../../../state/store/store';
import { connect } from 'react-redux';
import Button from '../../../../shared/SmallComponents/Button';
import { ModalFooter, ModalFormContainer } from '../../../../shared/Modal/Modal';
import Loader from '../../../../shared/SmallComponents/Loader';
import { contact } from '../../../../../models/entities/businessPartner/contact';
import { eBpContactDepartments } from '../../../../../models/entities/businessPartner/eBpContactDepartments';
import { FormDropDown, FormTextArea, FormTextInput } from '../../../../shared/inputs/form';
import FieldLabel from '../../../../shared/SmallComponents/FieldLabel';
import { singleBusinessPartnersSelectors } from '../../../../../state/ducks/singleBusinessPartner/selectors';
import { emailPattern, phonePattern } from '../../../../../utils/patterns';
import { enumToDropdownOptions } from '../../../../shared/inputs/base/DropDown';

export enum formMode {
    NEW,
    EDIT
}

interface formFields {
    name?: string;
    email?: string;
    phoneNumber?: string;
    departments?: Array<string>;
    role?: string;
    comment?: string;
    backupContact?: number;
}
type Props = {
    businessPartner?: businessPartner;
    businessPartnerId?: string;
    contact?: contact;
    mode: formMode;
    localizations?: any;
    openedFromModal?: {
        setVisible: React.Dispatch<React.SetStateAction<boolean>>;
    };
    confirmation?: { setConfirm: React.Dispatch<React.SetStateAction<boolean>> };
    createBusinessPartnerContact: (businessPartnerId: string, contact: contact) => Promise<contact>;
    updateBusinessPartnerContact: (businessPartnerId: string, contact: contact) => Promise<number>;
    submitCallback?: (contact: contact) => void;
    showLoader?: boolean;
};

let AFTER_LOADING: boolean = false;

const departmentsDropDownOptions = enumToDropdownOptions(eBpContactDepartments);
const ContactFormConnected = ({
    businessPartner,
    contact,
    localizations,
    mode,
    openedFromModal,
    confirmation,
    createBusinessPartnerContact,
    updateBusinessPartnerContact,
    showLoader,
    submitCallback,
    businessPartnerId
}: Props) => {
    const [submitLoading, setLoading] = useState(false);
    let initFormValues: formFields = {
        name: '',
        email: '',
        phoneNumber: '',
        departments: [],
        role: '',
        comment: '',
        backupContact: undefined
    };
    const setInitialValues = () => {
        if (!contact || mode === formMode.NEW) {
            return initFormValues;
        } else {
            initFormValues.name = contact.name;
            initFormValues.email = contact.email;
            initFormValues.phoneNumber = contact.phoneNumber;
            initFormValues.departments = contact.departments;
            initFormValues.role = contact.role;
            initFormValues.comment = contact.comment;
            initFormValues.backupContact = contact.backupContact;
            return initFormValues;
        }
    };
    const validate = (values: any) => {
        if (confirmation) {
            if (JSON.stringify(values) === JSON.stringify(initFormValues)) {
                confirmation.setConfirm(false);
            } else {
                confirmation.setConfirm(true);
            }
        }

        const errors: any = {};
        if (!values.name) errors.name = localizations.contacts.form.required_error;
        if (!values.email) errors.email = localizations.contacts.form.required_error;
        //if (values.phoneNumber && !values.phoneNumber.match(phonePattern)) errors.phoneNumber = localizations.contacts.form.phone_error;
        if (values.email && !values.email.match(emailPattern)) errors.email = localizations.contacts.form.email_error;

        const maxLengthValidation = (field: string, maxLength: number, message: string) => {
            if (values[field].length > maxLength) {
                errors[field] = message;
            }
        };

        maxLengthValidation('role', 50, localizations.contacts.form.role_max_length_error);
        maxLengthValidation('phoneNumber', 40, localizations.contacts.form.phone_max_length_error);
        maxLengthValidation('comment', 250, localizations.contacts.form.comment_max_length_error);
        return errors;
    };
    const submit = (values: any) => {
        const bp = businessPartner ? businessPartner : businessPartnerId ? { id: businessPartnerId } : undefined;
        if (bp) {
            const payload: contact = {
                name: values.name,
                email: values.email,
                phoneNumber: values.phoneNumber,
                departments: values.departments,
                role: values.role,
                comment: values.comment,
                backupContact: values.backupContact || null
            } as contact;
            if (mode === formMode.NEW)
                createBusinessPartnerContact(bp.id, payload).then((value: contact) => {
                    if (submitCallback) submitCallback(value);
                });
            else if (contact) {
                updateBusinessPartnerContact(bp.id, {
                    ...contact,
                    ...payload,
                    id: contact.id
                }).then((success: number) => {
                    if (submitCallback)
                        submitCallback({
                            ...contact,
                            ...payload,
                            id: contact.id
                        });
                });
            }
        }
    };
    useEffect(() => {
        if (showLoader) {
            setLoading(true);
            AFTER_LOADING = true;
        } else {
            setLoading(false);
            if (AFTER_LOADING && openedFromModal) {
                openedFromModal.setVisible(false);
                AFTER_LOADING = false;
            }
        }
    }, [showLoader, openedFromModal]);
    return (
        <Formik initialValues={setInitialValues()} validate={validate} onSubmit={submit}>
            <Form>
                <Container>
                    <FieldLabel>{localizations.contacts.form.name}</FieldLabel>
                    <FieldContainer>
                        <FormTextInput name="name" placeHolder={localizations.contacts.form.name} />
                    </FieldContainer>
                    <FieldLabel>{localizations.contacts.form.email}</FieldLabel>
                    <FieldContainer>
                        <FormTextInput name="email" placeHolder={localizations.contacts.form.email} />
                    </FieldContainer>
                    <FieldLabel>{localizations.contacts.form.phoneNumber}</FieldLabel>
                    <FieldContainer>
                        <FormTextInput name="phoneNumber" placeHolder={localizations.contacts.form.phoneNumber} />
                    </FieldContainer>
                    <FieldLabel>{localizations.contacts.form.departments}</FieldLabel>
                    <FieldContainer>
                        <FormDropDown
                            name="departments"
                            placeHolder={localizations.contacts.form.departments}
                            options={departmentsDropDownOptions}
                            allowClear
                            mode="tags"
                        />
                    </FieldContainer>
                    <FieldLabel>{localizations.contacts.form.role}</FieldLabel>
                    <FieldContainer>
                        <FormTextInput name="role" placeHolder={localizations.contacts.form.role} />
                    </FieldContainer>
                    <FieldLabel>{localizations.contacts.form.comment}</FieldLabel>
                    <FieldContainer>
                        <FormTextArea name="comment" placeHolder={localizations.contacts.form.comment} />
                    </FieldContainer>
                    <FieldLabel>{localizations.contacts.form.backupContact}</FieldLabel>
                    <FieldContainer>
                        <FormDropDown
                            name="backupContact"
                            placeHolder={localizations.contacts.form.backupContact}
                            options={
                                businessPartner?.contacts?.map((item) => {
                                    return { value: '' + item.id, text: item.name };
                                }) || []
                            }
                            allowClear
                        />
                    </FieldContainer>
                </Container>
                {openedFromModal && (
                    <FormFooter>
                        <Button minWidth="55px" buttonType="filled" type="submit">
                            {submitLoading ? (
                                <Loader width="20px" marginTop="0px" showText={false} />
                            ) : (
                                <span>{mode === formMode.NEW ? localizations.contacts.modal.add_btn : localizations.contacts.modal.edit_btn}</span>
                            )}
                        </Button>
                    </FormFooter>
                )}
            </Form>
        </Formik>
    );
};
const FieldContainer = styled.div`
    margin-bottom: 12px;
`;

const Container = styled(ModalFormContainer)``;

const FormFooter = styled(ModalFooter)`
    position: absolute;
    bottom: 0;
    width: 100%;
    left: 0;
    right: 0;
    background: white;
    justify-content: flex-end;
`;
const mapStateToProps = (state: RootState) => ({
    localizations: localizationSelectors.SingleBusinessPartnerPage(state),
    businessPartner: singleBusinessPartnersSelectors.businessPartner(state),
    showLoader:
        state.loading.effects.businessPartners.createBusinessPartnerContact || state.loading.effects.businessPartners.updateBusinessPartnerContact
});

const mapDispatchToProps = (dispatch: any) => ({
    createBusinessPartnerContact: (businessPartnerId: string, contact: contact) =>
        dispatch.businessPartners.createBusinessPartnerContact({ businessPartnerId, contact }),
    updateBusinessPartnerContact: (businessPartnerId: string, contact: contact) =>
        dispatch.businessPartners.updateBusinessPartnerContact({ businessPartnerId, contact })
});

export default connect(mapStateToProps, mapDispatchToProps)(ContactFormConnected);
