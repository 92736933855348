import { ModelConfig } from '@rematch/core';
import { getStartedCreateCompanyPayload } from '../../../models/entities/company/getStartedCreateCompanyPayload';
import { localSDK as client } from '../../../sdk';

export type userRegistrationStateType = {
    email: string;
    underForwarderInvalidTokenError: boolean;
};

export const userRegistration: ModelConfig<userRegistrationStateType> = {
    state: {
        email: '',
        underForwarderInvalidTokenError: false
    },
    reducers: {
        setEmail(state: userRegistrationStateType, email: string): userRegistrationStateType {
            return { ...state, email };
        },
        setUnderForwarderInvalidTokenError(state: userRegistrationStateType, underForwarderInvalidTokenError: boolean): userRegistrationStateType {
            return { ...state, underForwarderInvalidTokenError };
        }
    },
    effects: (dispatch: any) => ({
        async sendEmailValidation(email: string) {
            const response = await client.userRegistration().sendEmailValidation(email);
            return response;
        },
        async validateEmail({
            email,
            pinCode,
            pricingPlanId,
            underForwarderToken
        }: {
            email: string;
            pinCode: string;
            pricingPlanId?: string;
            underForwarderToken?: string;
        }) {
            const response = await client.userRegistration().validateEmail(email, pinCode, pricingPlanId, underForwarderToken);
            return response;
        },
        async validateCompanyName(name: string) {
            const response = await client.userRegistration().validateCompanyName(name);
            return response;
        },
        async validateUnderForwarderToken(token: string) {
            const response = await client.userRegistration().validateUnderForwarderToken(token);
            dispatch.userRegistration.setUnderForwarderInvalidTokenError(!response);
            return response;
        },
        async createCompany(payload: getStartedCreateCompanyPayload) {
            const response = await client.userRegistration().createCompany(payload);
            return response;
        }
    })
};
