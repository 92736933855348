import React, { useState } from 'react';
import { Checkbox } from '../../../../shared/inputs/base';
import CustomModal, { ModalTriggerProps } from '../../../../shared/Modal/Modal';
import Button from '../../../../shared/SmallComponents/Button';

export type useStateType = { open: boolean; func: (includeDocuments: boolean, includeAttributes: boolean) => void };

type Props = {
    localizations: any;
    onCallbackFn: useStateType;
    setDuplicateCallback: React.Dispatch<React.SetStateAction<useStateType | undefined>>;
};

const DuplicateIncludeModal = ({ localizations, onCallbackFn, setDuplicateCallback }: Props) => {
    const [includeDocuments, setIncludeDocuments] = useState(false);
    const [includeAttributes, setIncludeAttributes] = useState(false);
    return (
        <CustomModal
            title={{ text: localizations.before_duplicate_msg }}
            Content={() => {
                return (
                    <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                        <div>
                            <Checkbox checked={includeDocuments} onChange={(e) => setIncludeDocuments(e.target.checked)}>
                                {localizations.include_documents}
                            </Checkbox>
                            <Checkbox checked={includeAttributes} onChange={(e) => setIncludeAttributes(e.target.checked)}>
                                {localizations.include_attributes}
                            </Checkbox>
                        </div>
                        <Button
                            buttonType="filled"
                            width="100px"
                            height="30px"
                            margin="20px auto"
                            onClick={() => {
                                onCallbackFn.func(includeDocuments, includeAttributes);
                                setDuplicateCallback(undefined);
                            }}
                        >
                            <span>{localizations.save_btn}</span>
                        </Button>
                    </div>
                );
            }}
            isOpen={true}
        />
    );
};

export default DuplicateIncludeModal;
