import { Location } from 'history';
import React, { useEffect, useState } from 'react';
import { Prompt } from 'react-router-dom';
import { Modal } from 'antd';

interface Props {
    title: string;
    when?: boolean | undefined;
    navigate: (path: string) => void;
    shouldBlockNavigation: (location: Location) => boolean;
}
const RouteLeavingGuard = ({ title, when, navigate, shouldBlockNavigation }: Props) => {
    const [modalVisible, setModalVisible] = useState(false);
    const [lastLocation, setLastLocation] = useState<Location | null>(null);
    const [confirmedNavigation, setConfirmedNavigation] = useState(false);
    const closeModal = () => {
        setModalVisible(false);
    };
    const handleBlockedNavigation = (nextLocation: Location): boolean => {
        if (!confirmedNavigation && shouldBlockNavigation(nextLocation)) {
            setModalVisible(true);
            setLastLocation(nextLocation);
            return false;
        }
        return true;
    };
    const handleConfirmNavigationClick = () => {
        setModalVisible(false);
        setConfirmedNavigation(true);
    };

    useEffect(() => {
        if (confirmedNavigation && lastLocation) {
            navigate(lastLocation.pathname);
        }
        if (modalVisible) {
            showConfirm(title, handleConfirmNavigationClick, closeModal);
        }
    }, [confirmedNavigation, lastLocation]);

    const showConfirm = (content: string, onOkFunc: () => void, onCancelFunc: () => void) => {
        Modal.confirm({
            title,
            onOk() {
                onOkFunc();
            },
            onCancel() {
                onCancelFunc();
            }
        });
    };
    return (
        <>
            <Prompt when={when} message={handleBlockedNavigation} />
        </>
    );
};
export default RouteLeavingGuard;
