import React from 'react';
import { connect } from 'react-redux';
import { purchaseOrder } from '../../../../../../models/entities/purchaseOrder/purchaseOrder';
import { purchaseOrderItem } from '../../../../../../models/entities/purchaseOrder/purchaseOrderItem';
import { shipment } from '../../../../../../models/entities/shipment/shipment';
import shipmentItem from '../../../../../../models/entities/shipment/shipmentItem';
import { filterFieldsType } from '../../../../../../state/ducks/allShipmentsPage/allShipmentsPage';
import { allShipmentsPageSelectors } from '../../../../../../state/ducks/allShipmentsPage/selectors';
import { purchaseOrdersSelectors } from '../../../../../../state/ducks/purchaseOrders/selectors';
import { RootState } from '../../../../../../state/store/store';
import CustomModal, { ModalProps } from '../../../../../shared/Modal/Modal';
import MergePoItemsToShipmentModalContent from './MergePoItemsToShipmentModalContent';

type Props = Partial<ModalProps> & {
    localization: any;
    purchaseOrder: purchaseOrder;
    selectedItems: purchaseOrderItem[];
    fetchShipmentsByFilter: (filter: filterFieldsType) => void;
    shipments?: shipment[];
    isLoading: boolean;
    createShipmentItems: (shipmentId: string, items: shipmentItem[]) => void;
    fetchSinglePurchaseOrder: (purchaseOrderId: string) => Promise<purchaseOrder>;
};

const MergePoItemsToShipmentModal = ({
    localization,
    createShipmentItems,
    selectedItems,
    purchaseOrder,
    fetchShipmentsByFilter,
    shipments,
    isLoading,
    fetchSinglePurchaseOrder,
    ...restProps
}: Props) => {
    return (
        <CustomModal
            {...restProps}
            title={{ text: localization.title }}
            Content={(setVisible?: React.Dispatch<React.SetStateAction<boolean>>, setConfirm?: React.Dispatch<React.SetStateAction<boolean>>) => {
                return (
                    <MergePoItemsToShipmentModalContent
                        localization={localization}
                        fetchSinglePurchaseOrder={fetchSinglePurchaseOrder}
                        createShipmentItems={createShipmentItems}
                        selectedItems={selectedItems}
                        purchaseOrder={purchaseOrder}
                        fetchShipmentsByFilter={fetchShipmentsByFilter}
                        shipments={shipments || []}
                        isLoading={isLoading}
                        setModalVisible={setVisible}
                    />
                );
            }}
            width="900px"
        />
    );
};

const mapStateToProps = (state: RootState) => ({
    shipments: purchaseOrdersSelectors.filteredShipments(state),
    isLoading: !!state.loading.effects.purchaseOrders.fetchShipmentsByFilter
});

const mapDispatchToProps = (dispatch: any) => ({
    fetchShipmentsByFilter: (filter: filterFieldsType) => dispatch.purchaseOrders.fetchShipmentsByFilter(filter),
    createShipmentItems: (shipmentId: string, items: shipmentItem[]) => dispatch.shipments.createShipmentItems({ shipmentId, items }),
    fetchSinglePurchaseOrder: (purchaseOrderId: string) => dispatch.purchaseOrders.fetchPurchaseOrderById(purchaseOrderId),
});

export default connect(mapStateToProps, mapDispatchToProps)(MergePoItemsToShipmentModal);
