import React, { useState } from 'react';
import { styled } from '../../shared/Theme/theme';
import { randDarkColor } from '../Theme/util';

const DEFAULT_BORDER_THICKNESS = 2;
const DEFAULT_WIDTH = 70;

type Props = {
    width?: number;
    borderThickness?: number;
    borderColor: string;
    img?: JSX.Element | string;
    bgColor?: string;
    hasShadow?: boolean;
};

const HexagonAvatar = (props: Props) => {
    // const [randomColor, setRandomColor] = useState(randDarkColor());
    const hexagonIOProps = new HexagonIOProps(
        props.width || DEFAULT_WIDTH,
        props.borderThickness || DEFAULT_BORDER_THICKNESS,
        props.borderColor,
        typeof props.img === 'string' ? props.img : undefined,
        props.bgColor
    );
    return (
        <HexagonWrapper hasShadow={props.hasShadow}>
            <HexagonOuter {...hexagonIOProps}>
                <HexagonInner {...hexagonIOProps}>{typeof props.img !== 'string' && props.img}</HexagonInner>
            </HexagonOuter>
        </HexagonWrapper>
    );
};

interface HexagonWrapperProps {
    hasShadow?: boolean;
}
const HexagonWrapper = styled.div<HexagonWrapperProps>`
    filter: ${(props) => (props.hasShadow ? 'drop-shadow(0px 5px 3px #d2d2d2)' : '')};
    display: flex;
    align-items: center;
    justify-content: center;
`;
class HexagonIOProps {
    constructor(width: number, borderThickness: number, borderColor: string, img?: string, bgColor?: string) {
        this.width = width;
        this.borderThickness = borderThickness;
        this.borderColor = borderColor;
        this.img = img;
        this.bgColor = bgColor;
        this.diff = width / 10;
        if (!this.img && !this.bgColor) {
            this.bgColor = 'white';
        }
    }
    width: number;
    borderThickness: number;
    borderColor: string;
    diff: number;
    img?: string;
    bgColor?: string;
}
const HexagonOuter = styled.div<HexagonIOProps>`
    display: inline-block;
    position: relative;
    width: ${(props) => props.width}px;
    height: ${(props) => props.width + props.diff}px;
    background: ${(props) => props.borderColor};
    box-sizing: border-box;
    clip-path: polygon(50% 0, 100% 25%, 100% 75%, 50% 100%, 0 75%, 0 25%);
`;
const HexagonInner = styled.div<HexagonIOProps>`
    position: absolute;
    top: ${(props) => props.borderThickness}px; /* equal to border thickness */
    left: ${(props) => props.borderThickness}px; /* equal to border thickness */
    width: ${(props) => props.width - props.borderThickness * 2}px; /* container width - (border thickness * 2) */
    height: ${(props) => props.width + props.diff - props.borderThickness * 2}px; /* container height - (border thickness * 2) */
    ${(props) => props.img && `background-image: url(${props.img});`}
    ${(props) => `background-color: ${props.bgColor}`};
    background-position: center;
    background-size: cover;
    clip-path: polygon(50% 0, 100% 25%, 100% 75%, 50% 100%, 0 75%, 0 25%);
`;
interface ImageInnerDivProps {
    color?: string;
    fontSize?: number;
}
export const AvatarNameDiv = styled.div<ImageInnerDivProps>`
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    font-weight: bold;
    color: white;
    font-size: smaller;
    background-color: ${(props) => props.color || 'black'};
`;

export default HexagonAvatar;
