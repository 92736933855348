import React from 'react';
import { shipmentSubType, shipmentType } from '../../../../../../models/entities/shipment/shipment';
import LCLColumns from './LCL';
import FCLColumns from './FCL';
import TOTALSColumns from './TOTALS';
import Grid from '../../../../../shared/Grid/Grid';
import { shipmentPackage } from '../../../../../../models/entities/shipment/shipmentPackage';
import { shipmentContainer } from '../../../../../../models/entities/shipment/shipmentContainer';

const GridRender = (
    localization: any,
    type: shipmentType,
    packages?: Array<shipmentPackage>,
    containers?: Array<shipmentContainer>,
    sub_type?: shipmentSubType
) => {
    switch (type) {
        case 'OI':
        case 'OEX':
        case 'OE':
        case 'DROP_OCEAN':
        case 'AI':
        case 'AE':
        case 'INLAND':
        case 'RAIL':
        case 'CUSTOMS':
        case 'DROP_AIR':
        case 'EXPRESS':
        default: {
            if (containers?.length) {
                return (
                    <Grid<shipmentContainer>
                        sortedByColumn="type"
                        sortDirection="asc"
                        columns={FCLColumns(localization)}
                        data={containers}
                        localization={localization.packages.grid}
                    />
                );
            } else if (packages) {
                return <Grid<shipmentPackage> columns={LCLColumns(localization)} data={packages} localization={localization.packages.grid} />;
            }
        }
    }
};

function convertPackageData<T>(packages: Array<any>) {
    const convertPackages: Array<T> = packages.map(
        (item): T => {
            let packageItem = { ...item };
            let data;
            if (packageItem.data) {
                data = { ...packageItem.data };
                delete packageItem.data;
            }
            packageItem = { ...packageItem, ...data };
            return packageItem as T;
        }
    );
    return convertPackages;
}
export default GridRender;
