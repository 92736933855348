import { shipmentPackage } from '../../../../../../models/entities/shipment/shipmentPackage';
import { ColumnType } from '../../../../../shared/Grid/types/Column';

const packageGridColumns = (localization: any): Array<ColumnType<shipmentPackage>> => {
    const { quantity, dimensions, weight, description, containerNumber, hsCode } = localization.packages.grid.columns;
    return [
        {
            title: quantity,
            field: 'quantity',
            sortable: false,
            isDisplayed: true,
            width: '100px'
        },
        {
            title: dimensions,
            field: 'length',
            bindedFields: ['length', 'width', 'height', 'dimensionsUnit'],
            sortable: false,
            isDisplayed: true,
            columnType: 'dimensions'
        },
        {
            title: weight,
            field: 'weight',
            bindedFields: ['weight', 'weightUnit'],
            sortable: false,
            isDisplayed: true,
            columnType: 'weight'
        },
        {
            title: description,
            field: 'description',
            sortable: false,
            isDisplayed: true
        },
        {
            title: containerNumber,
            field: 'containerNumber',
            sortable: false,
            isDisplayed: true
        },
        {
            title: hsCode,
            field: 'hsCode',
            sortable: false,
            isDisplayed: true
        }
    ];
};

export default packageGridColumns;
