import React from 'react';
import styled from 'styled-components';
import CZTooltip, { QuestionToolTip } from '../CZTooltip/CZTooltip';

type Props = {
    text: string;
    isMandatory?: boolean;
    tooltipText?: string;
};
export const FormFieldLabel = ({ text, isMandatory, tooltipText }: Props) => {
    return (
        <LabelContainer>
            <Label>
                {isMandatory && <MandatoryIndication>*</MandatoryIndication>}
                {text}
            </Label>
            {tooltipText && <CZTooltip text={tooltipText}>{QuestionToolTip}</CZTooltip>}
        </LabelContainer>
    );
};

const Label = styled.label`
    white-space: nowrap;
    color: ${(props) => props.theme.colors.primaryBlue} !important;
`;

const MandatoryIndication = styled.span`
    color: red;
    margin-right: 7px;
`;

const LabelContainer = styled.div`
    display: flex;
    margin-bottom: 5px;

    & img {
        margin-top: -2px;
        margin-left: 5px;
    }
`;

export default FormFieldLabel;
