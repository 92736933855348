import React from 'react';
import { document } from '../../../../../../models/entities/common_subentities/document';
import CustomModal, { ModalTriggerProps } from '../../../../../shared/Modal/Modal';
import { styled } from '../../../../../shared/Theme/theme';
import { formMode } from '../../../../commonSubEntities/documents/DocumentForm/MultipleDocumentFormConnected';
import { AddContainer, Flex, renderFieldLabel } from '../../../../singleShipment/BidForm/ShipmentBidForm';
import MultipleDocumentFormConnected from '../../../DocumentForm/MultipleDocumentFormConnected';

type Props = {
    values: any;
    setFieldValue: any;
    disabled?: boolean;
    localization: any;
};
const Documents = ({ values, setFieldValue, disabled, localization }: Props) => {
    return (
        <>
            <div>{renderFieldLabel(localization.upload_documents, false)}</div>
            {values.documents &&
                values.documents.map((item: document, index: number) => {
                    return (
                        <Flex key={index} style={{ alignItems: 'center', marginBottom: '20px' }}>
                            <StyledFieldContainer>
                                <b>{item.fileName}</b>
                            </StyledFieldContainer>
                            {!disabled && (
                                <DeleteContainer
                                    onClick={() => {
                                        const documents: Array<document> = [...values.documents];
                                        documents.splice(index, 1);
                                        setFieldValue('documents', documents);
                                    }}
                                >
                                    <VerticalDivider />
                                    <span className="material-icons">delete</span>
                                </DeleteContainer>
                            )}
                        </Flex>
                    );
                })}
            {!disabled && (
                <CustomModal
                    title={{ text: localization.add_documents }}
                    Trigger={(props: ModalTriggerProps) => (
                        <AddContainer onClick={props.onClick}>
                            <span className="material-icons">add_circle</span>
                            <span>{localization.add_documents}</span>
                        </AddContainer>
                    )}
                    Content={(
                        setVisible?: React.Dispatch<React.SetStateAction<boolean>>,
                        setConfirm?: React.Dispatch<React.SetStateAction<boolean>>
                    ) => {
                        return (
                            <MultipleDocumentFormConnected
                                mode={formMode.NEW}
                                openedFromModal={setVisible ? { setVisible } : undefined}
                                confirmation={setConfirm ? { setConfirm } : undefined}
                                createMultipleBidDocument={(bidNumber: string, documents: Array<document>) => {
                                    const newDocuments = [...values.documents];
                                    newDocuments.push(...documents);
                                    setFieldValue('documents', newDocuments);
                                }}
                            />
                        );
                    }}
                    width="500px"
                />
            )}
        </>
    );
};
const DeleteContainer = styled.div`
    display: flex;
    color: ${(props) => props.theme.colors.primaryBlue};
    span.material-icons {
        cursor: pointer;
    }
`;
const VerticalDivider = styled.div`
    height: auto;
    width: 1px;
    background: rgb(0 0 0 / 14%);
    margin-right: 20px;
`;
const StyledFieldContainer = styled.div`
    padding-right: 30px;
`;
export default Documents;
