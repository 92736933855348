import React, { useEffect, useRef, useState, useCallback } from 'react';
import styled from 'styled-components';
import { chat, message, chatType, editMessagePayload, createChatPayload, messageAttachment } from '../../../../models/entities/chat/chat';
import { userInfo } from '../../../../models/entities/user';
import ChatBackground from '../../../../static/chat_background.svg';
import { userDetails, usersObject } from '../../../../state/ducks/users/users';
import { shipment } from '../../../../models/entities/shipment/shipment';
import { ColumnType } from '../../../shared/Grid/types/Column';
import { chatRequest } from '../../../../models/entities/chat/chatRequest';
import { animateScroll } from 'react-scroll';
import Header from './components/Header';
import Content from './components/Content';
import Footer from './components/Footer';
import { ContentState } from 'draft-js';

type Props = {
    participantsIds: string[];
    chatType: chatType;
    userInfo: userInfo;
    chat?: chat;
    messages?: { [key: string]: message[] };
    sendMessage: (chatId: string, message: message) => void;
    createChat: (payload: createChatPayload) => void;
    editMessage: (payload: editMessagePayload) => void;
    deleteMessage: (id: string, chatId: string) => void;
    getUserById: (userId: string) => userDetails | undefined;
    readMessages: (chatId: string, messagesIds: string[], userId: string) => void;
    fetchNextMessages: (chatId: string, messagesToId: string) => Promise<chat | undefined>;
    getMessageById: (chatId: string, messageId: string) => message | undefined;
    isFetchingNextMessages: boolean;
    gridColumns: Array<ColumnType<shipment>>;
    typingUsers?: string[];
    myNetwork: usersObject;
    shipments: Array<shipment>;
    getShipmentById: (id: string) => shipment | undefined;
    inviteUser: (email: string) => void;
    sentRequests?: Array<chatRequest>;
    deleteGroupParticipant: (chatId: string, userId: string, userName: string) => Promise<void>;
    addGroupParticipants: (chatId: string, users: { id: string; name: string }[]) => Promise<void>;
    saveChatEditorState: (chatId: string, editorContentState: ContentState) => void;
    saveChatAttachmentsEditorState: (chatId: string, attachments: messageAttachment[]) => void;
    localization: any;
};
const ChatView = ({
    participantsIds,
    chatType,
    chat,
    messages,
    createChat,
    editMessage,
    deleteMessage,
    sendMessage,
    userInfo,
    getUserById,
    readMessages,
    typingUsers,
    myNetwork,
    gridColumns,
    getShipmentById,
    inviteUser,
    deleteGroupParticipant,
    addGroupParticipants,
    fetchNextMessages,
    getMessageById,
    saveChatEditorState,
    saveChatAttachmentsEditorState,
    isFetchingNextMessages,
    sentRequests,
    localization
}: Props) => {
    const [contactUser, setContactUser] = useState<userDetails | undefined>(undefined);
    const [isEditMessage, setIsEditMessage] = useState<message | null>(null);
    const [replyMessage, setReplyMessage] = useState<message | undefined>(undefined);
    const [showParticipants, setShowParticipants] = useState<boolean>(false);
    const loader = useRef<any>(null);

    const [canReadMessages, setCanReadMessages] = useState(true);
    const [readMessagesTimout, setReadMessagesTimout] = useState(0);

    const chatContentRef = useRef<HTMLDivElement>(null);
    const topMessageRef = useRef<HTMLDivElement>(null);
    const messagesEndRef = useRef<HTMLDivElement>(null);

    const scrollToBottom = (smooth?: boolean) => {
        if (messagesEndRef && messagesEndRef.current) {
            messagesEndRef.current.scrollIntoView({ behavior: smooth ? 'smooth' : 'auto', block: 'nearest', inline: 'start' });
        }
    };

    const [currentTopMessageId, setCurrentTopMessageId] = useState('');

    useEffect(() => {
        if (messages) {
            const messagesValues = Object.values(messages);
            if (messagesValues) {
                const lastMessagesGroup = messagesValues[messagesValues.length - 1];
                if (lastMessagesGroup) {
                    const lastMessage = lastMessagesGroup[lastMessagesGroup.length - 1];
                    if (lastMessage && !lastMessage.readBy?.includes(userInfo.id) && lastMessage?.userId !== userInfo.id) {
                        clearTimeout(readMessagesTimout);
                        setCanReadMessages(false);
                        setReadMessagesTimout(
                            setTimeout(() => {
                                setCanReadMessages(true);
                            }, 1000)
                        );
                    }
                }
            }
        }
        return () => clearTimeout(readMessagesTimout);
    }, [messages]);

    const loadMoreMessages = useCallback(
        async (entries) => {
            const target = entries[0];
            if (target.isIntersecting && chat && !isFetchingNextMessages) {
                const lastMessageId = chat.messages[0].id;
                setCurrentTopMessageId(lastMessageId);
                const nextChat = await fetchNextMessages(chat.id, lastMessageId);

                if (nextChat && topMessageRef?.current && chatContentRef?.current) {
                    chatContentRef.current.scrollTop = topMessageRef.current.offsetTop - 40;
                }
            }
        },
        [chat]
    );
    const isAdmin = !!chat?.participants
        .filter((p) => !!p.isAdmin)
        .map((u) => u.userId)
        ?.includes(userInfo.id);

    const messagesLength = chat?.messages.length;
    /** chat
    useEffect(() => {
        console.log('render chat view');
    });
     */
    useEffect(() => {
        const options = {
            root: null,
            rootMargin: '0px',
            threshold: 0.1
        };

        const observer = new IntersectionObserver(loadMoreMessages, options);

        if (loader?.current) {
            observer.observe(loader.current);
        }

        return () => observer.unobserve(loader?.current);
    }, [loadMoreMessages]);

    useEffect(() => {
        if (chatType === 'user') {
            const userId = participantsIds.filter((id) => id !== userInfo.id)[0];
            const user = getUserById(userId);
            setContactUser(user);
        } else {
            setContactUser(undefined);
        }
    }, [getUserById, participantsIds, userInfo.id, chatType]);

    useEffect(() => {
        if (chat && chat.messages.length && canReadMessages) {
            const unreadMessages = chat.messages
                .filter((message) => message.userId !== userInfo.id && !message.readBy?.includes(userInfo.id))
                .map((m) => m.id);

            if (unreadMessages.length) {
                readMessages(chat.id, unreadMessages, userInfo.id);
            }
        }
    }, [messagesLength, chat, userInfo.id, readMessages, canReadMessages]);

    const chatDisabled = contactUser ? !myNetwork[contactUser.id].isActive : false;

    return (
        <ChatPaneContainer style={{ backgroundImage: `url(${ChatBackground})` }}>
            <Header
                chat={chat}
                chatType={chatType}
                contactUser={contactUser}
                isAdmin={isAdmin}
                setShowParticipants={setShowParticipants}
                showParticipants={showParticipants}
                deleteGroupParticipant={deleteGroupParticipant}
                userInfo={userInfo}
                getUserById={getUserById}
                addGroupParticipants={addGroupParticipants}
                localization={localization}
            />
            <Content
                chat={chat}
                messages={messages}
                chatContentRef={chatContentRef}
                topMessageRef={topMessageRef}
                messagesEndRef={messagesEndRef}
                scrollToBottom={scrollToBottom}
                currentTopMessageId={currentTopMessageId}
                isEditMessage={isEditMessage}
                getShipmentById={getShipmentById}
                getUserById={getUserById}
                gridColumns={gridColumns}
                userInfo={userInfo}
                setIsEditMessage={setIsEditMessage}
                deleteMessage={deleteMessage}
                setReplyMessage={setReplyMessage}
                getMessageById={getMessageById}
                isFetchingNextMessages={isFetchingNextMessages}
                loader={loader}
                localization={localization.content}
            />

            <Footer
                typingUsers={typingUsers}
                scrollToBottom={scrollToBottom}
                getUserById={getUserById}
                getShipmentById={getShipmentById}
                isEditMessage={isEditMessage}
                setIsEditMessage={setIsEditMessage}
                replyMessage={replyMessage}
                setReplyMessage={setReplyMessage}
                chatDisabled={chatDisabled}
                sentRequests={sentRequests}
                contactUser={contactUser}
                inviteUser={inviteUser}
                userInfo={userInfo}
                chat={chat}
                createChat={createChat}
                editMessage={editMessage}
                setShowParticipants={setShowParticipants}
                sendMessage={sendMessage}
                saveChatEditorState={saveChatEditorState}
                saveChatAttachmentsEditorState={saveChatAttachmentsEditorState}
                participantsIds={participantsIds}
                localization={localization.footer}
            />
        </ChatPaneContainer>
    );
};

const ChatPaneContainer = styled.div`
    display: flex;
    flex-direction: column;
    height: 100%;
    background-color: #f6f7f9;

    .group-header {
        justify-content: space-between;
        padding-right: 20px;
    }
`;

export default React.memo(ChatView);
