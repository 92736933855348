import React, { useEffect, useState } from 'react';
import { Form, Formik } from 'formik';
import { RootState } from '../../../../../state/store/store';
import { connect } from 'react-redux';
import { styled } from '../../../../shared/Theme/theme';
import Button from '../../../../shared/SmallComponents/Button';
import { localizationSelectors } from '../../../../../state/ducks/localization/selectors';
import { filterFieldsType } from '../../../../../state/ducks/allShipmentsPage/allShipmentsPage';
import { FormDatePicker, FormDropDown, FormTextInput } from '../../../../shared/inputs/form';
import FieldLabel from '../../../../shared/SmallComponents/FieldLabel';
import { Flex } from '../../../singleShipment/ShipmentForm/ShipmentForm';
import { userSelectors } from '../../../../../state/ducks/user/selectors';
import { allowedCompany } from '../../../../../models/entities/user';
import { ordersSelectors } from '../../../../../state/ducks/orders/selectors';
import { orderFilterFields, orderFilters } from '../../../../../state/ducks/orders/gridFilters/types';
import { entries } from '../../../../../utils/typedEntries';
import { filterField } from '../../../../shared/PageTemplates/GridPage/components/GridPageHeader/models/filterField';
import { orderStates, OrderStateType } from '../../../../../models/entities/order';
import renderStateOption from '../../../../shared/SmallComponents/StateOption';
import { getDatesRange } from '../../../../../utils/date';
import { enumToDropdownOptions } from '../../../../shared/inputs/base/AutoComplete';
import { eOpenDates } from '../../../../../models/entities/shipment/open_dates';
import countries from '../../../../../static/data/countries.json';
import { renderCountryOption } from '../../../../shared/SmallComponents/CountryOption';

type Props = {
    localization: any;
    filterFields: filterFieldsType | null;
    allowedCompanies: allowedCompany[];
    onCancel: () => void;
    setOrdersFilters: (filters: orderFilterFields) => void;
};

type formFilterFields = Partial<orderFilters>;

const FilterContent = ({ localization, allowedCompanies, filterFields, onCancel, setOrdersFilters }: Props) => {
    const [formFieldsValues, setFormFieldsValues] = useState<formFilterFields>({});
    const [isCustomOrderDate, setIsCustomOrderDate] = useState<boolean>(false);

    const fieldsLocalization = localization.fields;
    const openDatesDropDownOptions = enumToDropdownOptions(eOpenDates);

    const countriesDropDownOptions = countries.map((country) => ({
        text: country.name,
        value: country.code
    }));

    useEffect(() => {
        const filterFieldsValues: formFilterFields = {};
        filterFields?.forEach(({ field, value }: { field: string; value: any }) => {
            (filterFieldsValues as any)[field] = value;
        });
        setFormFieldsValues(filterFieldsValues);
    }, [filterFields]);

    const setInitialValues = () => {
        return formFieldsValues;
    };

    const validate = (values: any) => {
        const errors: any = {};
        return errors;
    };

    const submit = (values: any) => {
        const filter: orderFilterFields = [];

        for (const entry of entries<formFilterFields>(formFieldsValues)) {
            const [field, value] = entry;
            if (value) filter.push({ field, value } as filterField<orderFilters>);
        }

        setOrdersFilters(filter);

        onCancel();
    };

    const resetForm = () => {
        setFormFieldsValues({});
        setOrdersFilters([]);
    };

    return (
        <Formik initialValues={setInitialValues()} validate={validate} onSubmit={submit}>
            {({ values, setFieldValue }: any) => {
                return (
                    <FormContainer>
                        <Container>
                            <FieldContainer data-popover-bug>
                                <FieldLabel>{fieldsLocalization.state.title}</FieldLabel>
                                <FormDropDown
                                    preventPopoverBug={true}
                                    name="state"
                                    placeHolder={fieldsLocalization.state.placeholder}
                                    options={Object.entries(orderStates)
                                        .sort((a, b) => a[1].displayOrder - b[1].displayOrder)
                                        .map(([state, data]) => ({
                                            text: localization.state?.[state] || state,
                                            value: state,
                                            color: data.color
                                        }))}
                                    optionRenderer={renderStateOption}
                                    allowClear
                                    mode="multiple"
                                    onChange={(value: OrderStateType[]) => setFormFieldsValues({ ...formFieldsValues, state: value })}
                                    value={formFieldsValues.state}
                                />
                            </FieldContainer>
                            {allowedCompanies.length > 1 && (
                                <>
                                    <FieldContainer style={{ display: 'flex', flexDirection: 'column' }}>
                                        <FieldLabel>{fieldsLocalization.companyId.placeholder}</FieldLabel>
                                        <FormDropDown
                                            name="companyId"
                                            style={{ width: '200px', marginRight: '15px' }}
                                            placeHolder={fieldsLocalization.companyId.placeholder}
                                            options={allowedCompanies.map((item) => {
                                                return { value: item.companyId, text: item.companyName };
                                            })}
                                            allowClear
                                            mode="multiple"
                                            onChange={(value: string[]) => setFormFieldsValues({ ...formFieldsValues, companyId: value })}
                                            value={formFieldsValues.companyId}
                                        />
                                    </FieldContainer>
                                </>
                            )}
                            <FieldContainer data-popover-bug>
                                <FieldLabel>{fieldsLocalization.alertStatus.title}</FieldLabel>
                                <FormDropDown
                                    preventPopoverBug={true}
                                    name="alertStatus"
                                    placeHolder={fieldsLocalization.alertStatus.placeholder}
                                    options={[
                                        { text: localization.alerts.status.success, value: '3' },
                                        { text: localization.alerts.status.warning, value: '2' },
                                        { text: localization.alerts.status.critical, value: '1' }
                                    ]}
                                    allowClear
                                    mode="multiple"
                                    onChange={(value: string[]) => setFormFieldsValues({ ...formFieldsValues, alertStatus: value })}
                                    value={formFieldsValues.alertStatus}
                                />
                            </FieldContainer>
                            <FieldContainer data-popover-bug>
                                <FieldLabel>{'Order Date'}</FieldLabel>
                                <FormDropDown
                                    preventPopoverBug={true}
                                    name="calcDate"
                                    placeHolder={'Order Date'}
                                    options={openDatesDropDownOptions}
                                    onChange={(value: string) => {
                                        const isCustom = value === 'CUSTOM';
                                        setIsCustomOrderDate(isCustom);

                                        if (isCustom || !value) {
                                            setFieldValue('fromDate', undefined);
                                            setFieldValue('toDate', undefined);
                                            setFormFieldsValues({ ...formFieldsValues, orderDateFrom: undefined, orderDateTo: undefined });
                                            return;
                                        }
                                        const range = getDatesRange(value);
                                        setFieldValue('fromDate', range.from);
                                        setFieldValue('toDate', range.to);
                                        setFormFieldsValues({ ...formFieldsValues, orderDateFrom: range.from, orderDateTo: range.to });
                                    }}
                                />
                            </FieldContainer>

                            {isCustomOrderDate && (
                                <>
                                    <FieldContainer data-popover-bug>
                                        <FieldLabel>{fieldsLocalization.orderDateFrom.title}</FieldLabel>
                                        <FormDatePicker
                                            name="orderDateFrom"
                                            placeHolder={fieldsLocalization.orderDateFrom.placeholder}
                                            onChange={(value: Date) => setFormFieldsValues({ ...formFieldsValues, orderDateFrom: value })}
                                            value={formFieldsValues.orderDateFrom}
                                            preventPopoverBug={true}
                                        />
                                    </FieldContainer>
                                    <FieldContainer data-popover-bug>
                                        <FieldLabel>{fieldsLocalization.orderDateTo.title}</FieldLabel>
                                        <FormDatePicker
                                            name="orderDateTo"
                                            placeHolder={fieldsLocalization.orderDateTo.placeholder}
                                            onChange={(value: Date) => setFormFieldsValues({ ...formFieldsValues, orderDateTo: value })}
                                            value={formFieldsValues.orderDateTo}
                                            preventPopoverBug={true}
                                        />
                                    </FieldContainer>
                                </>
                            )}
                            <FieldContainer data-popover-bug>
                                <FieldLabel>{fieldsLocalization.customerCountry.title}</FieldLabel>
                                <FormDropDown
                                    preventPopoverBug={true}
                                    name="originCountry"
                                    placeHolder={fieldsLocalization.customerCountry.placeholder}
                                    options={countriesDropDownOptions}
                                    optionRenderer={renderCountryOption}
                                    showSearch={true}
                                    onChange={(value: string) => setFormFieldsValues({ ...formFieldsValues, customerCountry: value })}
                                    value={formFieldsValues.customerCountry}
                                    allowClear
                                />
                            </FieldContainer>
                            <FieldContainer>
                                <FieldLabel>{fieldsLocalization.carrier.title}</FieldLabel>
                                <FormTextInput
                                    name="carrier"
                                    placeHolder={fieldsLocalization.carrier.placeholder}
                                    onChange={(value: string) => setFormFieldsValues({ ...formFieldsValues, carrier: value })}
                                    value={formFieldsValues.carrier}
                                    allowClear
                                />
                            </FieldContainer>
                        </Container>
                        <FormFooter>
                            <Button minWidth="55px" type="button" onClick={onCancel}>
                                <span>{localization.filtersForm.cancel_btn}</span>
                            </Button>
                            <Flex>
                                <Button minWidth="55px" type="button" onClick={resetForm}>
                                    <span>{localization.filtersForm.reset_btn}</span>
                                </Button>
                                <Button minWidth="55px" buttonType="filled" type="submit">
                                    <span>{localization.filtersForm.filter_btn}</span>
                                </Button>
                            </Flex>
                        </FormFooter>
                    </FormContainer>
                );
            }}
        </Formik>
    );
};
const FormContainer = styled(Form)`
    min-width: 500px;
    @media screen and (max-width: 768px) {
        min-width: 100%;
    }
`;
const FieldContainer = styled.div`
    margin-bottom: 12px;
    flex-basis: 45%;
    width: 0;
    padding: 0 10px;
    @media screen and (max-width: 768px) {
        flex-basis: 100%;
    }
`;
const Divider = styled.div`
    width: 100%;
    margin-bottom: 12px;
    border-top: 1px solid rgba(0, 0, 0, 0.06);
    @media screen and (max-width: 768px) {
        display: none;
    }
`;
const Container = styled.div`
    width: 100%;
    max-height: 420px;
    overflow: auto;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-around;
    @media screen and (max-width: 768px) {
        height: calc(100vh - 70px);
        padding: 4px 12px;
        max-height: 100%;
    }
`;

const FormFooter = styled.div`
    position: absolute;
    width: 100%;
    display: flex;
    justify-content: space-between;
    height: 70px;
    padding: 17px 30px;
    border-top: 1px solid #eaeaea;
    left: 0;
    right: 0;
    bottom: -70px;
    background: white;
    box-shadow: 0 9px 12px 1px rgb(0 0 0 / 9%);
    button {
        padding: 15px;
    }
    @media screen and (max-width: 768px) {
        bottom: 0;
        button {
            span {
                display: -webkit-box;
            }
        }
    }
`;

const mapStateToProps = (state: RootState) => ({
    localization: localizationSelectors.ordersPage(state),
    filterFields: ordersSelectors.filterFields(state),
    allowedCompanies: userSelectors.allowedCompanies(state)
});

const mapDispatchToProps = (dispatch: any) => ({
    setOrdersFilters: (filters: orderFilterFields) => dispatch.orders.setOrdersFilters(filters)
});

export default connect(mapStateToProps, mapDispatchToProps)(FilterContent);
