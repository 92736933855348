import React, { useRef, useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Popover } from '@material-ui/core';
import { orderAlert, orderAlertImg, orderAlertLevelEnum } from '../../../models/entities/order/orderAlert/orderAlert';
import { AlertItem, AlertImage, AlertMessage } from '../../pages/singleShipment/SingleShipmentPage';
import { connect } from 'react-redux';
import { ordersSelectors } from '../../../state/ducks/orders/selectors';
import { RootState } from '../../../state/store/store';
import { localizationSelectors } from '../../../state/ducks/localization/selectors';
import Loader from '../../shared/SmallComponents/Loader';
import styled from 'styled-components';

type Props = {
    alertStatus: number;
    localization: any;
    orderId: string;
    isLoadingAlerts: boolean;
    iconWidth?: number;
    getAlertsByOrderId: (id: string) => Array<orderAlert> | undefined;
    fetchAlertsByOrderId: (orderId: string) => Promise<void>;
};
const useStyles = makeStyles((theme) => ({
    popover: {
        pointerEvents: 'none'
    },
    popoverContent: {
        pointerEvents: 'auto',
        minWidth: '90px',
        minHeight: '90px',
        display: 'flex',
        alignItems: 'center'
    }
}));
const OrderAlertPopover = ({ alertStatus, localization, orderId, getAlertsByOrderId, fetchAlertsByOrderId, isLoadingAlerts, iconWidth }: Props) => {
    const [alerts, setAlerts] = useState<Array<orderAlert> | undefined>(undefined);
    const [openedPopover, setOpenedPopover] = useState(false);
    const popoverAnchor = useRef(null);

    const classes = useStyles();

    const popoverEnter = async ({ currentTarget }: any) => {
        setOpenedPopover(true);
        setAlerts(getAlertsByOrderId(orderId));
        if (!alerts?.length && !isLoadingAlerts && [1, 2].includes(alertStatus || 3)) {
            await fetchAlertsByOrderId(orderId);
            setAlerts(getAlertsByOrderId(orderId));
        }
    };
    const popoverLeave = ({ currentTarget }: any) => {
        setOpenedPopover(false);
    };
    return (
        <>
            <Container
                ref={popoverAnchor}
                aria-owns="mouse-over-popover"
                aria-haspopup="true"
                onMouseEnter={popoverEnter}
                onMouseLeave={popoverLeave}
            >
                <Image
                    src={orderAlertImg[alertStatus || 3]}
                    alt={localization.alerts.status[orderAlertLevelEnum[alertStatus || 3]]}
                    width={iconWidth}
                />
            </Container>
            <Popover
                id="mouse-over-popover"
                className={classes.popover}
                classes={{
                    paper: classes.popoverContent
                }}
                PaperProps={{
                    onMouseEnter: popoverEnter,
                    onMouseLeave: popoverLeave
                }}
                open={openedPopover}
                anchorEl={popoverAnchor.current}
                anchorOrigin={{
                    vertical: 'center',
                    horizontal: 'right'
                }}
                transformOrigin={{
                    vertical: 'center',
                    horizontal: -14
                }}
            >
                <PopoverContent>
                    {![1, 2].includes(alertStatus || 3) ? (
                        'All good'
                    ) : alerts && !isLoadingAlerts ? (
                        alerts.length > 0 &&
                        alerts
                            .sort((orderAlert: orderAlert) => -orderAlert.level)
                            .map((orderAlert: orderAlert, i: number) => {
                                return (
                                    <AlertItem key={i}>
                                        <AlertImage
                                            src={orderAlertImg[orderAlert.level]}
                                            alt={localization.alerts.status[orderAlertLevelEnum[orderAlert.level]]}
                                        />
                                        <AlertMessage>{orderAlert.message}</AlertMessage>
                                    </AlertItem>
                                );
                            })
                    ) : (
                        <Loader width="30px" marginTop="0" showText={false} />
                    )}
                </PopoverContent>
            </Popover>
        </>
    );
};

interface ImageProps {
    width?: number;
}
const Container = styled.div``;
const PopoverContent = styled.div`
    flex: 1;
    padding: 0 25px;
    display: flex;
    flex-direction: column;

    & :last-child {
        margin-bottom: 0;
    }
`;
const Image = styled.img<ImageProps>`
    ${(props) => (props.width ? 'width:' + props.width + 'px' : 'width:20px')};
`;

const mapStateToProps = (state: RootState) => ({
    getAlertsByOrderId: (id: string) => ordersSelectors.getAlertsByOrderId(state)(id),
    localization: localizationSelectors.ordersPage(state),
    isLoadingAlerts: state.loading.effects.orders.fetchAlertsByOrderId
});

const mapDispatchToProps = (dispatch: any) => ({
    fetchAlertsByOrderId: (orderId: string) => dispatch.orders.fetchAlertsByOrderId(orderId)
});

export default connect(mapStateToProps, mapDispatchToProps)(OrderAlertPopover);
