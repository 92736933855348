import React, { useState } from 'react';
import { purchaseDemandItem } from '../../../../../models/entities/purchaseDemand/purchaseDemandItem';
import { purchaseOrder } from '../../../../../models/entities/purchaseOrder/purchaseOrder';
import MuiDataGrid from '../../../../shared/MuiDataGrid/MuiDataGrid';
import { POsGridColumns } from './gridColumns/MergePOsGridCols';
import Button from '../../../../shared/SmallComponents/Button';
import styled from 'styled-components';
import { purchaseOrderItem } from '../../../../../models/entities/purchaseOrder/purchaseOrderItem';
import { uuidv4 } from '../../../../../utils/patterns';
import CustomModal, { ModalTriggerProps } from '../../../../shared/Modal/Modal';
import { useHistory } from 'react-router';
import { PagesRoutes } from '../../../../../routing/PagesRoutes';
import { purchaseDemand } from '../../../../../models/entities/purchaseDemand/purchaseDemand';

type Props = {
    purchaseOrders: purchaseOrder[];
    pdItems: purchaseDemandItem[];
    purchaseDemand: purchaseDemand;
    supplierId: string;
    localization: any;
    createPurchaseOrderItems: (purchaseOrderId: string, items: Array<purchaseOrderItem>) => Promise<void>;
};

const MergePDtoPOGrid = ({ purchaseOrders, pdItems, supplierId, createPurchaseOrderItems, localization, purchaseDemand }: Props) => {
    const [selectedPoId, setSelectedPoId] = useState<string | undefined>(undefined);
    const [showGoBackModal, setShowGoBackModal] = useState<boolean>(false);
    const [isMerging, setIsMerging] = useState<boolean>(false);

    const history = useHistory();

    const renderGoBackModal = () => {
        return (
            <CustomModal
                title={{ text: 'Where do you want to go?' }}
                isOpen={true}
                Content={(setVisible?: React.Dispatch<React.SetStateAction<boolean>>) => {
                    return (
                        <div style={{ display: 'flex', justifyContent: 'space-around' }}>
                            <Button
                                onClick={() => {
                                    history.push(PagesRoutes.PurchaseDemands + '/' + purchaseDemand.id);
                                }}
                            >
                                {localization.purchaseDemand}
                            </Button>
                            <Button
                                onClick={() => {
                                    history.push(PagesRoutes.PurchaseOrders + '/' + selectedPoId);
                                }}
                            >
                                {localization.purchaseOrder}
                            </Button>
                        </div>
                    );
                }}
                onCancelCallback={() => {
                    setShowGoBackModal(false);
                }}
                width="393px"
            />
        );
    };

    return (
        <>
            <div>
                <MuiDataGrid
                    columns={POsGridColumns(localization)}
                    rows={purchaseOrders}
                    autoHeight
                    disableColumnFilter
                    disableColumnMenu
                    disableColumnReorder
                    onSelectionModelChange={(newSelection) => {
                        if (newSelection.selectionModel?.[0]) {
                            setSelectedPoId(newSelection.selectionModel[0] as string);
                        }
                    }}
                    pageSize={10}
                    pagination
                    showFooter
                    disableMultipleSelection
                />
                <ButtonsContainer>
                    <Button
                        width="auto"
                        type="button"
                        buttonType="filled"
                        disabled={isMerging || !selectedPoId}
                        onClick={async () => {
                            if (selectedPoId) {
                                setIsMerging(true);
                                await createPurchaseOrderItems(
                                    selectedPoId,
                                    pdItems.map((item) => ({
                                        id: uuidv4(),
                                        productId: item.productId,
                                        quantity: item.leftQuantity || 0,
                                        name: item.name,
                                        code: item.code,
                                        price: item.price,
                                        currency: item.currency,
                                        length: item.length,
                                        width: item.width,
                                        height: item.height,
                                        dimensionsUnit: item.dimensionsUnit,
                                        weight: item.weight,
                                        weightUnit: item.weightUnit,
                                        productSku: item.productSku,
                                        attributes: item.attributes,
                                        purchaseDemandId: item.purchaseDemandId,
                                        purchaseDemandItemId: item.id,
                                        minOrderQuantity: item.minOrderQuantity,
                                        productionDays: item.productionDays,
                                        unitsPerMasterCarton: item.unitsPerMasterCarton,
                                        masterCartonWeight: item.masterCartonWeight,
                                        masterCartonWeightUnit: item.masterCartonWeightUnit,
                                        masterCartonHeight: item.masterCartonHeight,
                                        masterCartonWidth: item.masterCartonWidth,
                                        masterCartonLength: item.masterCartonLength,
                                        masterCartonDimensionUnit: item.masterCartonDimensionUnit,
                                        purchaseOrderId: selectedPoId
                                    }))
                                );
                                setIsMerging(false);
                                setShowGoBackModal(true);
                            }
                        }}
                    >
                        Merge
                    </Button>
                </ButtonsContainer>
            </div>
            {showGoBackModal && renderGoBackModal()}
        </>
    );
};

const ButtonsContainer = styled.div`
    display: flex;
    justify-content: flex-end;
    margin-top: 20px;
`;

export default MergePDtoPOGrid;
