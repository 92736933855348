import { GridColDef, GridValueFormatterParams } from '@material-ui/data-grid';
import React from 'react';
import styled from 'styled-components';
import { supplier } from '../../../../../models/entities/product/supplier';
import { uuidv4 } from '../../../../../utils/patterns';
import { currencyFormatter } from '../../../../shared/inputs/form/CurrencyInput/CurrencyInput';
import MuiDataGrid from '../../../../shared/MuiDataGrid/MuiDataGrid';

type Props = {
    suppliers: supplier[];
    localization: any;
};

const SuppliersGrid = ({ suppliers, localization }: Props) => {
    const columns: GridColDef[] = [
        {
            field: 'supplierName',
            headerName: localization?.supplier,
            flex: 1
        },
        {
            field: 'sku',
            headerName: localization?.sku,
            flex: 1
        },
        {
            field: 'cost',
            headerName: localization?.cost,
            flex: 1,
            valueFormatter: (params: GridValueFormatterParams) => (params.value ? currencyFormatter(params.row.costCurrency)(params.value) : '-')
        },
        {
            field: 'minOrderQuantity',
            headerName: localization?.minOrderQuantity,
            flex: 1
        },
        {
            field: 'productionDays',
            headerName: localization?.productionDays,
            flex: 1
        }
    ];

    return (
        <Wrapper>
            <MuiDataGrid
                columns={columns}
                rows={suppliers.map((supplier) => ({ ...supplier, id: uuidv4() }))}
                disableSelectionOnClick
                disableColumnFilter
                disableColumnSelector
                disableColumnMenu
                autoHeight
            />
        </Wrapper>
    );
};

const Wrapper = styled.div``;

export default SuppliersGrid;
