import React, { useState } from 'react';
import { settings, purchaseOrder } from '../../../../models/entities/purchaseOrder/purchaseOrder';
import Parties from './Parties';
import Events from './EventsTab';
import Documents from './Documents/DocumentsTab';
import TabsContainer from '../../../shared/Tabs/TabsContainer';
import Tab from '../../../shared/Tabs/Tab';
import History from '../../commonSubEntities/history/HistoryTab';
import ItemsTab from './Items/ItemsTab';
import { settings as companySettings } from '../../../../models/entities/businessPartner/businessPartner';

type Props = {
    purchaseOrder: purchaseOrder;
    localization?: any;
    settings?: settings;
    companySettings?: companySettings;
};

const PurchaseOrderTabs = ({ purchaseOrder, localization, settings, companySettings }: Props) => {
    const [activeTab, setActiveTab] = useState('Items');

    return (
        <TabsContainer>
            <Tab title="Items" onClick={() => setActiveTab('Items')} isActive={activeTab === 'Items'}>
                <ItemsTab purchaseOrder={purchaseOrder} items={purchaseOrder.items} localization={localization} settings={settings} />
            </Tab>
            <Tab title="Events" onClick={() => setActiveTab('Events')} isActive={activeTab === 'Events'}>
                <Events events={purchaseOrder.events} localization={localization} settings={settings} />
            </Tab>
            <Tab title="Documents" onClick={() => setActiveTab('Documents')} isActive={activeTab === 'Documents'}>
                <Documents
                    documents={purchaseOrder.documents?.documents}
                    requiredDocs={purchaseOrder.documents?.requiredDocs}
                    localization={localization}
                    settings={settings}
                />
            </Tab>
            {/* <Tab title="References" onClick={() => setActiveTab('References')} isActive={activeTab === 'References'}>
                <div>References content</div>
            </Tab> */}

            <Tab title="History" onClick={() => setActiveTab('History')} isActive={activeTab === 'History'}>
                <History history={purchaseOrder.history} localization={localization} />
            </Tab>
        </TabsContainer>
    );
};

export default PurchaseOrderTabs;
