import { task } from '../../../models/entities/task';
import { ModelConfig } from '@rematch/core';
import { localSDK as client } from '../../../sdk';

export type applicationStateType = {
    isMobile: boolean;
};

export const application: ModelConfig<applicationStateType> = {
    state: {
        isMobile: false
    },
    reducers: {
        setIsMobile(state: applicationStateType, isMobile: boolean): applicationStateType {
            return { ...state, isMobile };
        }
    },
    effects: (dispatch: any) => ({})
};
