import { businessPartner } from '../../../../../models/entities/businessPartner/businessPartner';
import { Option } from '../../../../shared/inputs/base/DropDown';
import { party } from '../../../../../models/entities/common_subentities/party';
import { address, baseAddress } from '../../../../../models/entities/businessPartner/address';
import { contact } from '../../../../../models/entities/businessPartner/contact';
import { allowedCompany } from '../../../../../models/entities/user';

export const partiesPrepareSubmit = (
    values: any,
    initialValues: any,
    purchaseOrderId?: string,
    businessPartners?: Array<businessPartner>,
    allowedCompanies?: allowedCompany[]
) => {
    let parties: Array<party> = [];

    if (values.vendorId) {
        const vendor = businessPartners?.find((p) => p.id === values.vendorId);
        if (vendor) {
            parties.push({
                entityId: purchaseOrderId,
                businessPartnerId: vendor.id,
                type: 'SHIPPER',
                subType: 'SHIPPER',
                name: vendor.name,
                contactName: values.vendor_contact_name,
                country: values.origin_country,
                city: values.origin_zipcode,
                address: values.origin_pickup_address + ',' + values.origin_zipcode + ',' + values.origin_country,
                email: values.vendor_contact_email,
                phoneNumber: values.vendor_contact_phoneNumber,
                logoFileId: vendor.logoFileId
            } as party);
        }
    }

    if (values.consignee_same_as_company) {
        const company = allowedCompanies?.find((c) => c.companyId === values.companyId);
        if (company) {
            parties.push({
                entityId: purchaseOrderId,
                type: 'CONSIGNEE',
                subType: 'CONSIGNEE',
                name: company?.companyName,
                companyId: values.companyId,
                contactName: values.consignee_contact_name,
                country: values.destination_country,
                city: values.destination_zipcode,
                address: values.destination_pickup_address + ',' + values.destination_zipcode + ',' + values.destination_country,
                email: values.consignee_contact_email,
                phoneNumber: values.consignee_contact_phoneNumber
            } as party);
        }
    } else if (values.consigneeId) {
        const consignee = businessPartners?.find((p) => p.id === values.consigneeId);
        if (consignee) {
            parties.push({
                entityId: purchaseOrderId,
                businessPartnerId: consignee.id,
                type: 'CONSIGNEE',
                subType: 'CONSIGNEE',
                name: consignee.name,
                contactName: values.consignee_contact_name,
                country: values.destination_country,
                city: values.destination_zipcode,
                address: values.destination_pickup_address + ',' + values.destination_zipcode + ',' + values.destination_country,
                email: values.consignee_contact_email,
                phoneNumber: values.consignee_contact_phoneNumber,
                logoFileId: consignee.logoFileId
            } as party);
        }
    }
    return parties.sort((a, b) => a.id - b.id);
};

export const updateOriginPickupAddress = (values: any, setFieldValue: any, value?: string) => {
    const field = 'origin_pickup_address';
    if (!values[field]) setFieldValue(field, value);
};
export const updateDestinationPickupAddress = (values: any, setFieldValue: any, value?: string) => {
    const field = 'destination_pickup_address';
    if (!values[field]) setFieldValue(field, value);
};

const isNumeric = (str: string) => {
    return !isNaN(+str) && !isNaN(parseFloat(str));
};

export const updateMaster = (values: any, setFieldValue: any, value: string) => {
    const field = 'master';
    if (!values[field]) {
        if (isNumeric(value)) {
            setFieldValue(field, value + '-');
        } else {
            setFieldValue(field, value);
        }
    }
};

export const handlePickupAddress = (
    purchaseOrder_type: string,
    addresses: { vendorAddress?: string; consigneeAddress?: string },
    setFieldValue: any,
    values: any
) => {
    if (['AI', 'OI'].includes(purchaseOrder_type)) {
        //take vendor address and set consignee null
        updateOriginPickupAddress(values, setFieldValue, addresses.vendorAddress);
        updateDestinationPickupAddress(values, setFieldValue, '');
    } else if (['AE', 'OE'].includes(purchaseOrder_type)) {
        //take consignee address and set vendor null
        updateOriginPickupAddress(values, setFieldValue, '');
        updateDestinationPickupAddress(values, setFieldValue, addresses.consigneeAddress);
    } else {
        // rest are common for both , so set both addresses
        updateOriginPickupAddress(values, setFieldValue, addresses.vendorAddress);
        updateDestinationPickupAddress(values, setFieldValue, addresses.consigneeAddress);
    }
};

export const updatePickupAddress = (field: string, setFieldValue: any, address: baseAddress, values: any) => {
    const country = field + '_country';
    // if (!values[country])
    setFieldValue(country, address.country); // country

    const port = field;
    // if (!values[port])
    setFieldValue(port, address.airPort); // port

    const city = field + '_zipcode';
    // if (!values[city])
    setFieldValue(city, address.city); // city

    const pickup_address = field + '_pickup_address';
    // if (!values[pickup_address])
    setFieldValue(pickup_address, address.address); // address
};

export const updateContactDetails = (field: string, setFieldValue: any, contact: contact, values: any) => {
    const name = field + '_contact_name';
    setFieldValue(name, contact.name);

    const phone = field + '_contact_phoneNumber';
    setFieldValue(phone, contact.phoneNumber);

    const email = field + '_contact_email';
    setFieldValue(email, contact.email);
};

export const getBusinessPartnerDefaultAddressAndContact = (
    businessPartners: businessPartner[] | undefined,
    businessPartnerId: string
): { businessPartner: businessPartner | undefined; defaultAddress: false | address; defaultContact: false | contact } => {
    const businessPartner = businessPartners?.find((item) => item.id === businessPartnerId);
    const businessPartnerAddresses = businessPartner?.addresses || [];
    const defaultAddress =
        businessPartnerAddresses.find((item) => item.isDefault) || (businessPartnerAddresses.length === 1 && businessPartnerAddresses[0]);
    const vendorContacts = businessPartner?.contacts || [];
    const defaultContact = vendorContacts.length === 1 && vendorContacts[0];

    return { businessPartner, defaultAddress, defaultContact };
};
export const getAllowedTypes = (purchaseOrderTypesDropDownOptions: Option[], allowedTypes?: Array<string>) => {
    const allowedPurchaseOrderTypes: Option[] = [];
    allowedTypes?.forEach((type) => {
        const obj = purchaseOrderTypesDropDownOptions.find((item) => item.value === type);
        if (obj) allowedPurchaseOrderTypes.push(obj);
    });
    return allowedPurchaseOrderTypes;
};
