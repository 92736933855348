import { isEmpty } from 'lodash';
import { party } from '../../../../../../models/entities/bid/party';
import { businessPartner } from '../../../../../../models/entities/businessPartner/businessPartner';
import { partyObj } from './index';

export const validateParties = (parties: { id: string; contactsIds: string[]; bidId?: string }[], errors: any, localizations: any) => {
    if (!errors.parties) errors.parties = [];
    parties.forEach(() => {
        errors.parties.push({});
    });
    parties.forEach((item, index) => {
        if (!item.id) errors.parties[index].id = localizations.forwarder_is_required;
        if (!item.bidId && !item.contactsIds?.length) errors.parties[index].contactsIds = localizations.contact_is_required;
    });
    if (isErrorsEmpty(errors.parties)) delete errors.parties;
    function isErrorsEmpty(parties: party[]) {
        for (const item of parties) {
            if (!isEmpty(item)) {
                return false;
            }
        }
        return true;
    }
};

export const partiesPrepareSubmit = (values: any, businessPartners: Array<businessPartner>, bidId?: string) => {
    let parties: Array<party> = [];
    values.parties.forEach((item: { id: string; contactsIds: string[] }) => {
        const cp = businessPartners.find((p) => p.id === '' + item.id);
        item.contactsIds?.forEach((id) => {
            const contact = cp?.contacts?.find((c) => c.id.toString() === id);
            const party = {
                bidId,
                businessPartnerId: cp?.id,
                type: cp?.type,
                name: cp?.name,
                country: cp?.country,
                city: cp?.city,
                email: contact?.email || cp?.email,
                phoneNumber: contact?.phoneNumber || cp?.phoneNumber,
                contactName: contact?.name
            } as party;
            if (party.email) parties.push(party);
        });
    });

    return parties;
};
