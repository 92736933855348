export default {
    en: {
        documentTypes: [
            { code: 'SPC', name: 'Specifications', description: 'Specifications' },
            { code: 'FDA', name: 'FDA Approval', description: 'FDA Approval' },
            { code: 'CRT', name: 'Certificate', description: 'Certificate' },
            { code: 'PRM', name: 'Import/Export permit', description: 'Import/Export permit' },
            { code: 'MSDS', name: 'MSDS', description: 'MSDS' },
            { code: 'CBT', name: 'CB test', description: 'CB test' },
            { code: 'PRODUCT_IMG', name: 'Product Image', description: 'Product Image' },
            { code: 'OTHER', name: 'Other', description: 'Other file description' }
        ]
    }
};
