import BaseController from '../..';
import { discussion } from '../../../../models/entities/common_subentities/discussion';
import IBusinessPartnersDiscussions from './IBusinessPartnersDiscussions';

export default class BusinessPartnersDiscussions extends BaseController implements IBusinessPartnersDiscussions {
    constructor(baseUrl: string) {
        super(baseUrl);
    }
    async createBusinessPartnerDiscussion(businessPartnerId: string, data: discussion, businessPartnerNumber?: string) {
        const response = await this.client.post(`/businessPertner/discussions`, JSON.stringify({ data, businessPartnerId, businessPartnerNumber }));
        return response;
    }
    async updateBusinessPartnerDiscussion(businessPartnerId: string, data: discussion, businessPartnerNumber?: string) {
        const response = await this.client.put(`/businessPertner/discussions`, JSON.stringify({ data, businessPartnerId, businessPartnerNumber }));
        return response;
    }
    async deleteBusinessPartnerDiscussion(businessPartnerId: string, discussionId: number) {
        const response = await this.client.delete(`/businessPertner/discussions`, JSON.stringify({ businessPartnerId, id: discussionId }));
        return response;
    }
}
