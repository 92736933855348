import React from 'react';
import { event } from '../../../../../../models/entities/common_subentities/event';
import { settings } from '../../../../../../models/entities/shipment/shipment';
import TimeLine from './TimeLine';
import EventFormConnected from '../../../EventForm/EventFormConnected';
import Button from '../../../../../shared/SmallComponents/Button';
import { styled } from '../../../../../shared/Theme/theme';
import CustomModal, { ModalTriggerProps } from '../../../../../shared/Modal/Modal';
import { formMode } from '../../../../commonSubEntities/events/EventForm/EventFormConnected';
import claimsType from '../../../../../../models/entities/claimsType';

type Props = {
    events?: Array<event>;
    settings?: settings;
    localization: any;
    userShipmentEventsClaims?: claimsType;
};

function ShipmentExpressEvents({ events, settings, localization, userShipmentEventsClaims }: Props) {
    return (
        <EventsWrapper>
            {!userShipmentEventsClaims || userShipmentEventsClaims?.add_event ? (
                <CustomModal
                    title={{ text: localization.modal.add_title }}
                    Trigger={(props: ModalTriggerProps) => (
                        <ButtonContainer>
                            <Button onClick={props.onClick} width="auto" buttonType="hollow" padding="4px 12px">
                                {localization.add_event}
                            </Button>
                        </ButtonContainer>
                    )}
                    Content={(
                        setVisible?: React.Dispatch<React.SetStateAction<boolean>>,
                        setConfirm?: React.Dispatch<React.SetStateAction<boolean>>
                    ) => {
                        return (
                            <EventFormConnected
                                mode={formMode.NEW}
                                openedFromModal={setVisible ? { setVisible } : undefined}
                                confirmation={setConfirm ? { setConfirm } : undefined}
                            />
                        );
                    }}
                    width="393px"
                />
            ) : (
                <h2>Events</h2>
            )}
            {
                <TimeLine
                    data={
                        events?.map((item) => {
                            const eventType = `${settings?.eventTypes?.find((e: any) => e.code === item.eventCode)?.description || item.eventCode}, ${
                                localization.updated_by
                            } ${item.userName}, ${item.comment ? 'Comment: ' + item.comment : item.comment}`;
                            return { title: item.description || eventType, date: item.eventDate, isCompleted: true };
                        }) || []
                    }
                />
            }
        </EventsWrapper>
    );
}

const EventsWrapper = styled.div`
    display: flex;
    flex-direction: column;
    width: 100%;
`;

const ButtonContainer = styled.div`
    display: flex;
    justify-content: flex-end;
    padding-top: 8px;
`;
export default ShipmentExpressEvents;
