import React from 'react';
import { DatePicker as AntDatePicker } from 'antd';
import moment, { Moment } from 'moment';
import styled, { CSSProperties } from 'styled-components';
import { getPopupContainerPopoverBug } from '.';

export type BaseProps = {
    value?: Date;
    style?: CSSProperties;
    placeHolder?: string;
    onChange?: any;
    hasError?: boolean;
    width?: string;
    height?: string;
    preventPopoverBug?: boolean;
    showTime?: boolean;
    disabledDate?: (current: moment.Moment) => boolean;
    disabled?: boolean;
};

const DatePicker = ({
    value,
    style,
    placeHolder,
    onChange,
    hasError,
    width,
    height,
    preventPopoverBug,
    showTime,
    disabledDate,
    disabled
}: BaseProps) => {
    return (
        <Conatiner hasError={hasError}>
            <AntDatePicker
                getPopupContainer={(trigger: any) => getPopupContainerPopoverBug(trigger, preventPopoverBug)}
                placeholder={placeHolder}
                onChange={onChange}
                style={{ width, height, ...style }}
                value={value ? moment(value) : undefined}
                showTime={showTime}
                onSelect={(value: Moment) => {
                    if (value && value.isValid() && onChange) {
                        onChange(value, value);
                    }
                }}
                disabledDate={disabledDate}
                disabled={disabled}
            />
        </Conatiner>
    );
};

DatePicker.defaultProps = {
    width: '100%',
    height: 'auto'
};
type ContainerProps = {
    hasError?: boolean;
};
const Conatiner = styled.div<ContainerProps>`
    position: relative;
    & .ant-picker {
        border-radius: 70px !important;
        border-color: ${(props) => (props.hasError ? 'red' : props.theme.colors.primaryBlue)} !important;
    }
`;

export default DatePicker;
