import { stateProps } from '.';
import { settings, shipment } from '../../../models/entities/shipment/shipment';

export const chooseForwarderHandler = async (
    shipment: shipment,
    value: string,
    setChooseForwarder: (value: React.SetStateAction<stateProps>) => void,
    updateShipmentState: (shipmentId: string, shipmentState: string, forwarderId?: string | undefined) => Promise<void>,
    isFreelancerOrForwarder: boolean | undefined,
    settings?: settings,
    file?: File
) => {
    const orderIndex = settings?.state?.findIndex((item) => item.code === 'ORDER') || -1;
    const currentIndex = settings?.state?.findIndex((item) => item.code === value) || -1;
    if (orderIndex !== -1 && currentIndex >= orderIndex && !shipment.forwarderId && !shipment.forwarderName && !isFreelancerOrForwarder) {
        const args = [shipment.id, value];
        setChooseForwarder({
            companyId: shipment.companyId,
            args,
            func: updateShipmentState
        });
    } else {
        await updateShipmentState(shipment.id, value, '');
    }
};
