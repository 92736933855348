import React, { useState } from 'react';
import Menu, { TriggerProps } from '../../../shared/Menu/Menu';
import MenuItem from '../../../shared/Menu/MenuItem';
import excelIcon from '../../../../static/icons/documentsType/excel.svg';
import Button from '../../../shared/SmallComponents/Button';
import { exportTypes } from '../../../../state/ducks/allShipmentsPage/allShipmentsPage';
import { styled } from '../../../shared/Theme/theme';
import ArrayToExcelModal from '../../../shared/ArrayToExcel/ArrayToExcelModal';
import { ModalTriggerProps } from '../../../shared/Modal/Modal';
import { shipment } from '../../../../models/entities/shipment/shipment';
import { ColumnType } from '../../../shared/Grid/types/Column';
import { pickBy } from 'lodash';
import Tooltip from '@material-ui/core/Tooltip';
import { eShipmentTypes } from '../../../../models/entities/shipment/shipmentTypes';

type Props = {
    shipments: Array<shipment>;
    localization: any;
    gridColumns: Array<ColumnType<shipment>>;
    exportDataTable: (type: exportTypes) => void;
};
const ExportMenu = ({ shipments, localization, gridColumns, exportDataTable }: Props) => {
    const [isModalOpened, setIsModalOpened] = useState(false);

    const menuItems = [
        <ArrayToExcelModal<shipment>
            localization={{ ...localization.grid.columns, ...localization.exportOptions.export_excel_modal, ...eShipmentTypes }}
            data={
                shipments.map((s) =>
                    pickBy(s, (value, key) =>
                        [
                            ...gridColumns.map((c) => c.field),
                            'originCountry',
                            'destinationCountry',
                            'subTypeCode',
                            'goodsValue',
                            'transportationPrice'
                        ].includes(key as keyof shipment)
                    )
                ) as Array<shipment>
            }
            title={localization.exportOptions.export_excel_modal.title}
            fileName={localization.exportOptions.export_file_name}
            onCancelCallback={() => {
                setIsModalOpened(false);
            }}
            Trigger={(props: ModalTriggerProps) => (
                <MenuItem
                    onClick={(e) => {
                        e.stopPropagation();
                        setIsModalOpened(true);
                        props.onClick();
                    }}
                    icon={excelIcon}
                    text={localization.exportOptions.excel}
                />
            )}
        />
    ];

    return (
        <Menu
            position="bottom-center"
            arrowOnTrigger={false}
            options={menuItems}
            isModalOpened={isModalOpened}
            MenuTrigger={(props: TriggerProps) => (
                <Button width="32px" height="32px" borderRadius="50%" margin="7px" buttonType="hollow" onClick={props.onClick}>
                    <Tooltip title={localization.export_btn} aria-label="export">
                        <span className="material-icons-outlined">file_download</span>
                    </Tooltip>
                </Button>
            )}
        />
    );
};

const ExportLabel = styled.span`
    @media screen and (max-width: 768px) {
        display: none;
    }
`;
export default ExportMenu;
