import SideEffectJS from 'side-effect-js';
import mockColumns from './mockColumns';

const delay = (ms: number) => new Promise((res) => setTimeout(res, ms));

const fetchProductsGridColumnsMock = async () => {
    await delay(1500);
    return Promise.resolve(mockColumns);
};
const fetchProductsGridColumnsReal = () => Promise.reject('Implement API');

const fetchProductsGridColumnsSideEffect = SideEffectJS.CreateEffect(
    'gridColumnsProductsPage',
    fetchProductsGridColumnsReal,
    fetchProductsGridColumnsMock
);

export default fetchProductsGridColumnsSideEffect;
