import { task } from './../../../models/entities/task';
import BaseController from '..';
import ITask from './ITask';

export default class Task extends BaseController implements ITask {
    constructor(baseUrl: string) {
        super(baseUrl);
    }
    async fetchTasks() {
        const response = await this.client.get('/task');
        return response;
    }

    async createTask(task: task) {
        const response = await this.client.post('/task', JSON.stringify({ ...task }));
        return response;
    }

    async updateTask(task: task) {
        const response = await this.client.put(`/task/${task.id}`, JSON.stringify({ ...task }));
        return response;
    }

    async deleteTask(taskId: string) {
        const response = await this.client.delete(`/task/${taskId}`);
        return response;
    }
}
