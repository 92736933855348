import BaseController from '../..';
import {
    createMultipleOrderEventsPayload,
    createOrderEventPayload,
    updateOrderEventPayload,
    deleteOrderEventPayload
} from '../../../../models/entities/order/orderEvent/payloads';
import IOrderEvent from './IOrderEvents';

export default class OrderEvents extends BaseController implements IOrderEvent {
    async createOrderEvent(payload: createOrderEventPayload) {
        const response = await this.client.post(`/order/events`, JSON.stringify(payload));
        return response;
    }
    async createMultipleOrderEvents(payload: createMultipleOrderEventsPayload) {
        const response = await this.client.post(`/order/events/multiple`, JSON.stringify(payload));
        return response;
    }
    async updateOrderEvent(payload: updateOrderEventPayload) {
        const response = await this.client.put(`/order/events`, JSON.stringify(payload));
        return response;
    }
    async deleteOrderEvent(payload: deleteOrderEventPayload) {
        const response = await this.client.delete(`/order/events`, JSON.stringify(payload));
        return response;
    }
}
