import React, { useState } from 'react';
import styled from 'styled-components';
import { useOutsideClickHandler } from '../../../../hooks/handleClickOutside';
import { userInfo } from '../../../../models/entities/user';
import theme from '../../Theme/theme';
import { Item } from './MenuItem';
import mapper from './dropdowns/mapper';

export type MenuItemDropDownProps = {
    name: string;
    items: [{}];
    localizations?: any;
    outSideRef: any;
    userInfo: userInfo;
    ddlKey: string;
};
function MenuItem({ name, items, localizations, outSideRef, userInfo, ddlKey }: MenuItemDropDownProps) {
    const [isOpen, onToggle] = useState(false);
    useOutsideClickHandler(outSideRef, () => onToggle(false));

    const DDLComp = mapper[ddlKey];
    return (
        <>
            <Item onClick={() => onToggle(!isOpen)}>
                <div>{localizations[name]}</div>
                <StyledArrow className="material-icons">keyboard_arrow_right</StyledArrow>
            </Item>
            {DDLComp && <DDLComp items={items} localizations={localizations} userInfo={userInfo} isOpen={isOpen} />}
        </>
    );
}
const StyledArrow = styled.span`
    font-size: 17px;
    color: ${theme.colors.primary};
`;
export default MenuItem;
