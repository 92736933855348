import { shipmentPackage } from '../../../../../../models/entities/shipment/shipmentPackage';
import { shipmentContainer } from '../../../../../../models/entities/shipment/shipmentContainer';

export const lclPackageEmptyObj: shipmentPackage = {
    id: '',
    shipmentId: '',
    description: '',
    hsCode: '',
    quantity: 1,
    length: 0,
    width: 0,
    height: 0,
    dimensionsUnit: 'CM',
    weight: 0,
    weightUnit: 'KG'
};

export const fclPackageEmptyObj: shipmentContainer = {
    id: '',
    shipmentId: '',
    description: '',
    type: '',
    number: '',
    state: '',
    isOverweight: false
};
