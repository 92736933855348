import React from 'react';
import { FormDatePicker, FormDropDown, FormTextInput, CurrencyInput } from '../../../../shared/inputs/form';
import { styled } from '../../../../shared/Theme/theme';
import { shipmentFormLogics } from '../../util';
import { FieldContainer, renderFieldLabelByName } from '../ShipmentForm';
import { updateMaster, carriersDropDownOptionsByShipmentType } from './utils';

type Props = {
    values: any;
    localizations: any;
};
const Amazon = ({ values, localizations }: Props) => {
    const amazonSectionLocalizations = localizations.form.sections.amazon;
    const fieldsLocalizations = amazonSectionLocalizations.fields;
    const renderFieldTitle = (name: string, isMandatory: boolean) => renderFieldLabelByName(name, isMandatory, fieldsLocalizations);

    return (
        <>
            <AmazonFormSection>
                <div style={{ paddingRight: '30px' }}>
                    <FieldContainer>
                        <div>{renderFieldTitle('fba_id', false)}</div>
                        <div>
                            <FormTextInput name="fba_id" placeHolder={fieldsLocalizations.fba_id.title} style={{ width: '200px' }} />
                        </div>
                    </FieldContainer>
                </div>

                <div style={{ paddingRight: '30px' }}>
                    <FieldContainer>
                        <div>{renderFieldTitle('amazon_id', false)}</div>
                        <div>
                            <FormTextInput name="amazon_id" placeHolder={fieldsLocalizations.amazon_id.title} style={{ width: '200px' }} />
                        </div>
                    </FieldContainer>
                </div>

                <div style={{ paddingRight: '30px' }}>
                    <FieldContainer>
                        <div>{renderFieldTitle('amazon_appointment_date', false)}</div>
                        <div>
                            <FormDatePicker
                                name="amazon_appointment_date"
                                style={{ width: '200px' }}
                                placeHolder={fieldsLocalizations.amazon_appointment_date.title}
                                showTime
                            />
                        </div>
                    </FieldContainer>
                </div>
            </AmazonFormSection>
        </>
    );
};

const AmazonFormSection = styled.div`
    display: flex;
    flex-direction: row;
`;

const Flex = styled.div`
    display: flex;
`;

export default Amazon;
