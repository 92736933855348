import React from 'react';
import styled from 'styled-components';
import EditIcon from '../../../../static/icons/edit.svg';
import { order } from '../../../../models/entities/order/order';
import { orderEvent } from '../../../../models/entities/order/orderEvent/orderEvent';
import { Option } from '../../../shared/inputs/base/DropDown';
import UserBadge from '../../../shared/UserBadge/UserBadge';
import SingleValueFormModal from '../../../shared/SingleValueFormModal/SingleValueFormModal';
import { ModalTriggerProps } from '../../../shared/Modal/Modal';
import { updateOrderPayload } from '../../../../models/entities/order';
import TrackingNumberLink from '../components/TrackingNumberLink';

type Props = {
    order: order;
    localization: any;
    allowedCompaniesDropDownOptions: Option[];
    orderLastEvent: orderEvent | null;
    updateOrder: (payload: updateOrderPayload) => Promise<void>;
};

const OrderOverviewHeader = ({ order, localization, allowedCompaniesDropDownOptions, orderLastEvent, updateOrder }: Props) => {
    const tarrifCompany = allowedCompaniesDropDownOptions.find((opt) => opt.value === order.companyId)?.text;

    return (
        <OverviewHeaderWrapper>
            <SectionWrapper>
                <OrderNumber id="order-number">
                    <span>{localization.overview.order}</span> #{order.cargoZoneNumber}
                </OrderNumber>
                <ReferencesSection>
                    <SingleRefWrapper>
                        <RefText>{localization.fields.trackingNumber.title}:</RefText>
                        <Editable trackingNumberExist={!!order.trackingNumber}>
                            {!!order.trackingNumber ? <TrackingNumberLink trackingNumber={order.trackingNumber} /> : '-'}
                            <SingleValueFormModal
                                title={localization.fields.trackingNumber.title}
                                initialValue={order.trackingNumber}
                                valueType="text"
                                onSubmit={async (value: string) => {
                                    await updateOrder({ orderId: order.id, trackingNumber: value });
                                }}
                                Trigger={(props: ModalTriggerProps) => {
                                    return (
                                        <span>
                                            <img
                                                src={EditIcon}
                                                onMouseDown={(e) => {
                                                    props.onClick();
                                                }}
                                                className="edit-icon"
                                                alt={'edit'}
                                            />
                                        </span>
                                    );
                                }}
                            />
                        </Editable>
                    </SingleRefWrapper>
                    <LastEventWrapper>
                        <RefText>{localization.fields.lastEvent.title}:</RefText>
                        <LastEvent>{order.lastEvent || '-'}</LastEvent>
                    </LastEventWrapper>
                </ReferencesSection>
            </SectionWrapper>

            <SectionsSeparator />

            <SectionWrapper>
                <DetailWrapper>
                    <div>{localization.overview.company}:</div>
                    <div style={{ fontWeight: 'bolder', fontSize: '20px' }}>{tarrifCompany}</div>
                </DetailWrapper>
            </SectionWrapper>

            <SectionsSeparator />

            <SectionWrapper>
                <RowWrapper>
                    <UserBadge width={40} userId={order.userId} />
                </RowWrapper>
            </SectionWrapper>
        </OverviewHeaderWrapper>
    );
};

const OverviewHeaderWrapper = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    width: 100%;
    border-bottom: solid 1px #e0e0e0;
    padding: 24px 18px 18px 48px;
`;

const SectionWrapper = styled.div`
    max-width: 300px;
`;

const DetailWrapper = styled.div`
    color: #394372;

    margin: 4px 0;
    &:first-child {
        margin-top: 0;
    }
`;

const SectionsSeparator = styled.div`
    width: 1px;
    border-right: solid 1px #e0e0e0;
    margin: 0 18px;
`;

const OrderNumber = styled.div`
    color: ${(props) => props.theme.colors.pageTitle};
    font-size: ${(props) => props.theme.fontSizes.pageTitle};
    font-weight: bold;
    height: 24px;
    white-space: nowrap;

    & span {
        @media screen and (max-width: 768px) {
            display: none;
        }
    }
`;
const ReferencesSection = styled.div`
    color: ${(props) => props.theme.colors.pageTitle};
    font-size: ${(props) => props.theme.fontSizes.subTitlte};
    margin-top: 6px;
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;

    & ::-webkit-scrollbar-thumb {
        background-color: rgba(0, 0, 0, 0.3) !important;
        border-radius: 5.5px;
    }
`;

const SingleRefWrapper = styled.div`
    display: flex;
    align-items: center;
    margin-right: 12px;
    flex-wrap: wrap;
    padding: 3px 0;
    min-height: 30px;
`;

const LastEventWrapper = styled.div`
    display: flex;
    margin-right: 12px;
    flex-wrap: wrap;
    padding: 3px 0;
`;

const LastEvent = styled.div`
    flex: 1;
`;

const RefText = styled.span`
    font-weight: bold;
    margin-right: 4px;
`;

const RowWrapper = styled.div`
    display: flex;
    white-space: nowrap;
    flex-direction: row;
    padding: 6px 0;
    color: #394372;
`;

const Editable = styled.span<{ trackingNumberExist: boolean }>`
    .edit-icon {
        display: ${(props) => (props.trackingNumberExist ? 'none' : 'initial')};
        cursor: pointer;
    }

    &:hover {
        .edit-icon {
            display: initial;
        }
    }
`;

export default OrderOverviewHeader;
