import React from 'react';
import { connect } from 'react-redux';
import { attribute, attributeOption } from '../../../../../models/entities/product/attribute';
import { product } from '../../../../../models/entities/product/product';
import { localizationSelectors } from '../../../../../state/ducks/localization/selectors';
import { RootState } from '../../../../../state/store/store';
import CustomModal, { ModalTriggerProps } from '../../../../shared/Modal/Modal';
import ProductAttributesModalContent from './ProductAttributesModalContent';

type Props = {
    title: string;
    attributes: Array<attribute>;
    isOpen?: boolean;
    onSubmit?: (attributes: { [key: string]: string }, firstAttributeOption?: attributeOption) => void;
    onCancelCallback?: () => void;
    Trigger?: (props: ModalTriggerProps) => JSX.Element;
    localization: any;
};
const ProductAttributesModal = ({ title, attributes, onCancelCallback, Trigger, onSubmit, localization, isOpen }: Props) => {
    return (
        <CustomModal
            title={{ text: title }}
            isOpen={isOpen}
            Trigger={Trigger}
            Content={(setVisible?: React.Dispatch<React.SetStateAction<boolean>>, setConfirm?: React.Dispatch<React.SetStateAction<boolean>>) => {
                return (
                    <ProductAttributesModalContent
                        attributes={attributes}
                        openedFromModal={setVisible ? { setVisible } : undefined}
                        onSubmit={onSubmit}
                        localization={localization}
                        onCancelCallback={onCancelCallback}
                    />
                );
            }}
            width="700px"
            onCancelCallback={onCancelCallback}
        />
    );
};

const mapStateToProps = (state: RootState) => ({
    localization: localizationSelectors.products(state)
});

const mapDispatchToProps = (dispatch: any) => ({});

export default connect(mapStateToProps, mapDispatchToProps)(ProductAttributesModal);
