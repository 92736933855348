import React from 'react';
import moment from 'moment';
import styled from 'styled-components';
import hexagonOpened from '../../../../../../static/icons/hexagonCheck/opened.svg';
import hexagonClosed from '../../../../../../static/icons/hexagonCheck/closed.svg';
import editIcon from '../../../../../../static/icons/edit.svg';
import trashIcon from '../../../../../../static/icons/trash.svg';

export type TimeLineItem<T = any> = {
    title: string;
    isCompleted: boolean;
    date: Date;
    description?: string;
    entity?: T;
};

type Props<T = any> = {
    data: Array<TimeLineItem<T>>;
    onEdit?: (entity?: T) => void;
    onDelete?: (entity?: T) => void;
};

function TimeLine<T = any>({ data, onEdit, onDelete }: Props<T>) {
    const renderItems = data.map((item: TimeLineItem<T>, index) => {
        const { title, isCompleted, date, description, entity } = item;
        const mDate = moment(date);
        return (
            <ItemContainer key={index}>
                <LineContainer isCompleted={isCompleted}>
                    <img src={isCompleted ? hexagonClosed : hexagonOpened} alt="hexagon" className="completed-mark" />
                    <div className="vertical-line"></div>
                </LineContainer>
                <ContentContainer>
                    <HeaderContainer>
                        <div className="date">
                            <div>{mDate.format('DD/MM/YYYY').toString()}</div>
                            <div>{mDate.format('HH:mm').toString()}</div>
                        </div>
                        <div>
                            <Title isCompleted={isCompleted}>{title}</Title>
                            <Description>{description}</Description>
                        </div>
                    </HeaderContainer>
                    {(onEdit || onDelete) && (
                        <ActionsContainer className="actions-container">
                            {onEdit && <img alt="editIcon" className="action-btn editz-btn" src={editIcon} onClick={() => onEdit(entity)} />}
                            {onDelete && <img alt="trashIcon" className="action-btn delete-btn" src={trashIcon} onClick={() => onDelete?.(entity)} />}
                        </ActionsContainer>
                    )}
                </ContentContainer>
            </ItemContainer>
        );
    });
    return <TimeLineContainer>{renderItems}</TimeLineContainer>;
}

interface timelineItemProps {
    isCompleted: boolean;
}

const TimeLineContainer = styled.div`
    display: flex;
    flex-direction: column;
    width: 100%;
    padding: 20px 0;
    max-height: 300px;
    overflow: auto;
`;
const LineContainer = styled.div<timelineItemProps>`
    display: flex;
    flex-direction: column;
    align-items: center;

    .vertical-line {
        background-image: ${(props) =>
            props.isCompleted
                ? 'linear-gradient(#008ac9 33%, rgba(255, 255, 255, 0) 0%)'
                : 'linear-gradient(#e0e0e0 33%, rgba(255, 255, 255, 0) 0%)'};
        background-position: right;
        background-size: 2px 5px;
        width: 2px;
        flex: 1;
        background-repeat: repeat-y;
    }
`;
const ContentContainer = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
    flex: 1;
    padding-left: 5px;
`;

const HeaderContainer = styled.div`
    display: flex;
    padding-bottom: 6px;
    margin-bottom: 10px;
    align-items: baseline;
    color: #757575;

    .date {
        text-align: right;
        color: #008ac9;
        margin-right: 20px;
    }
`;

const ActionsContainer = styled.div`
    display: flex;
    align-items: center;
    justify-content: flex-end;
    flex: 1;
    height: 100%;

    .action-btn {
        margin-right: 5px;
        cursor: pointer;
    }

    .delete-btn {
        filter: invert(16%) sepia(62%) saturate(4949%) hue-rotate(354deg) brightness(82%) contrast(124%);
    }
`;

const Title = styled.div<timelineItemProps>`
    display: flex;
    align-items: center;
    font-weight: bold;
    color: ${(props) => props.theme.colors.pageTitle};
`;

const Description = styled.div`
    display: flex;
    align-items: center;
`;

const ItemContainer = styled.div`
    display: flex;
    width: 100%;

    .actions-container {
        display: none;
    }
    &:hover {
        background-color: #f1f1f1;
        .actions-container {
            display: flex;
        }
    }

    .material-icons {
        width: 24px;
        height: 24px;
        font-size: 18px;
        color: #008ac9;
        display: flex;
        align-items: center;
        justify-content: center;
    }
`;

export default TimeLine;
