import React, { useEffect } from 'react';
import { RootState } from '../../../state/store/store';
import { connect } from 'react-redux';
import DashboardPage from './DashboardPage';
import { summaryData } from '../../../models/entities/dashboard/summaryData';
import { localizationSelectors } from '../../../state/ducks/localization/selectors';
import { shipment } from '../../../models/entities/shipment/shipment';
import { shipmentsSelectors } from '../../../state/ducks/shipments/selectors';
import { userSelectors } from '../../../state/ducks/user/selectors';
import { drawers } from '../../../state/ducks/header/header';
import { headerSelectors } from '../../../state/ducks/header/selectors';
import { purchaseOrdersSelectors } from '../../../state/ducks/purchaseOrders/selectors';
import { purchaseOrder } from '../../../models/entities/purchaseOrder/purchaseOrder';
import { ColumnType } from '../../shared/Grid/types/Column';
import { allPurchaseOrdersPageSelectors } from '../../../state/ducks/allPurchaseOrdersPage/selectors';
import { filterFieldsType } from '../../../state/ducks/allBusinessPartnersPage/allBusinessPartnersPage';
import { shipmentsStatistics } from '../../../models/entities/dashboard/shipmentsStatistics';
import { featureFlagsModel } from '../../../models/entities/businessPartner/settings';
import { allPurchaseDemandsPageSelectors } from '../../../state/ducks/allPurchaseDemandsPage/selectors';
import { purchaseDemand } from '../../../models/entities/purchaseDemand/purchaseDemand';
import { bidsSelectors } from '../../../state/ducks/bids/selectors';
import { bid } from '../../../models/entities/bid/bid';
import { quotationView } from '../../../models/entities/bid/quotation';
import { claimsDomainsType } from '../../../userClaims';
import claimsType from '../../../models/entities/claimsType';
import OrdersOrientedDashboardPage from './OrdersOrientedDashboardPage';
import { ordersStatistics } from '../../../models/entities/order/ordersStatistics';
import { dashboardSelectors } from '../../../state/ducks/dashboard/selectors';
import { orderFilterFields } from '../../../state/ducks/orders/gridFilters/types';

type Props = {
    myRFQ: Array<bid>;
    sentQuotations: Array<quotationView>;
    purchaseDemandsGridColumns: Array<ColumnType<purchaseDemand>>;
    purchaseDemands: Array<purchaseDemand>;
    shipmentsStatistics: shipmentsStatistics | null;
    ordersStatistics: ordersStatistics | null;
    purchaseOrderGridColumns: Array<ColumnType<purchaseOrder>>;
    summaryData: summaryData | null;
    upcomingArrivals?: Array<shipment>;
    readySoonShipments?: Array<shipment>;
    purchaseOrders?: Array<purchaseOrder>;
    localizations?: any;
    showLoader: boolean;
    userName: string;
    drawerOpen: drawers;
    isFreeLancer?: boolean;
    isForwarder?: boolean;
    featureFlags?: featureFlagsModel;
    orderShipments: shipment[];
    isOrdersOriented: boolean;
    ordersLocalization: any;
    fetchShipments: () => void;
    fetchMyRFQ: () => Promise<void>;
    fetchShipmentsStatistics: () => void;
    fetchOrdersStatistics: () => Promise<void>;
    setDrawerOpen: (open: drawers, params?: any) => void;
    fetchPurchaseOrders: () => void;
    fetchPurchaseOrderGridColumns: () => void;
    fetchPurchaseDemands: () => void;
    fetchPurchaseDemandsGridColumns: () => void;
    fetchGridColumns: () => void;
    setShipmentFilters: (filter: filterFieldsType) => void;
    setOrdersFilters: (filter: orderFilterFields) => void;
    setGridFilter: (text: string) => void;
    getUserClaims: (domain: claimsDomainsType) => claimsType;
};

const DashboardPageConnected = ({
    sentQuotations,
    purchaseDemandsGridColumns,
    purchaseDemands,
    shipmentsStatistics,
    ordersStatistics,
    purchaseOrderGridColumns,
    summaryData,
    upcomingArrivals,
    readySoonShipments,
    localizations,
    showLoader,
    purchaseOrders,
    userName,
    drawerOpen,
    myRFQ,
    isForwarder,
    isFreeLancer,
    featureFlags,
    orderShipments,
    isOrdersOriented,
    ordersLocalization,
    setGridFilter,
    fetchShipments,
    fetchShipmentsStatistics,
    fetchOrdersStatistics,
    setDrawerOpen,
    fetchPurchaseOrders,
    fetchPurchaseOrderGridColumns,
    fetchPurchaseDemands,
    fetchPurchaseDemandsGridColumns,
    setShipmentFilters,
    fetchGridColumns,
    fetchMyRFQ,
    getUserClaims,
    setOrdersFilters
}: Props) => {
    useEffect(() => {
        // if (drawerOpen == null) setDrawerOpen('TASK');
        fetchShipments();
        fetchGridColumns();
        fetchPurchaseOrders();
        if (getUserClaims('pd').view_pd) {
            fetchPurchaseDemands();
            fetchPurchaseDemandsGridColumns();
        }
        fetchPurchaseOrderGridColumns();
        if (featureFlags?.DEV) {
            fetchShipmentsStatistics();
        }
        if (isForwarder) {
            fetchMyRFQ();
        }

        if (isOrdersOriented) {
            fetchOrdersStatistics();
        }
    }, []);

    if (isOrdersOriented && ordersStatistics) {
        return (
            <OrdersOrientedDashboardPage
                localization={localizations}
                ordersLocalization={ordersLocalization}
                userName={userName}
                ordersStatistics={ordersStatistics}
                setOrdersFilters={setOrdersFilters}
            />
        );
    }

    return (
        <DashboardPage
            localization={localizations}
            summaryData={summaryData}
            shipmentsStatistics={shipmentsStatistics}
            upcomingArrivals={upcomingArrivals}
            readySoonShipments={readySoonShipments}
            purchaseOrders={purchaseOrders}
            purchaseOrderGridColumns={purchaseOrderGridColumns}
            showLoader={showLoader}
            userName={userName}
            purchaseDemands={purchaseDemands}
            purchaseDemandsGridColumns={purchaseDemandsGridColumns}
            myRFQ={myRFQ}
            isFreeLancer={isFreeLancer}
            isForwarder={isForwarder}
            featureFlags={featureFlags}
            sentQuotations={sentQuotations}
            orderShipments={orderShipments}
            setShipmentFilters={setShipmentFilters}
            setDrawerOpen={setDrawerOpen}
            setGridFilter={setGridFilter}
        />
    );
};

const mapStateToProps = (state: RootState) => ({
    myRFQ: bidsSelectors.myRFQ(state),
    sentQuotations: bidsSelectors.sentQuotations(state),
    purchaseDemands: allPurchaseDemandsPageSelectors.dashboardGridData(state),
    purchaseDemandsGridColumns: allPurchaseDemandsPageSelectors.gridColumns(state),
    purchaseOrderGridColumns: allPurchaseOrdersPageSelectors.gridColumns(state),
    summaryData: shipmentsSelectors.getSummaryData(state),
    shipmentsStatistics: state.dashboard.shipmentsStatistics,
    ordersStatistics: dashboardSelectors.ordersStatistics(state),
    upcomingArrivals: shipmentsSelectors.upcomingArrivalsShipments(state),
    readySoonShipments: shipmentsSelectors.readySoonShipments(state),
    orderShipments: shipmentsSelectors.orderShipments(state),
    purchaseOrders: purchaseOrdersSelectors.dashboardGridData(state),
    localizations: localizationSelectors.dashboardPage(state),
    ordersLocalization: localizationSelectors.ordersPage(state),
    showLoader:
        state.loading.effects.shipments.fetchShipments ||
        state.loading.effects.user.fetchUserInfo ||
        state.loading.effects.purchaseOrders.fetchPurchaseOrders ||
        state.loading.effects.allPurchaseOrdersPage.fetchGridColumns ||
        state.loading.effects.bids.fetchMyRFQ ||
        state.loading.effects.dashboard.fetchShipmentsStatistics ||
        state.loading.effects.dashboard.fetchOrdersStatistics,
    userName: userSelectors.userName(state),
    drawerOpen: headerSelectors.drawerOpen(state),
    isFreeLancer: userSelectors.userInfo(state).companyType === 'FREELANCER',
    isForwarder: userSelectors.userInfo(state).companyType === 'FORWARDER',
    isOrdersOriented: userSelectors.isOrdersOrientedCompany(state),
    featureFlags: userSelectors.featureFlags(state),
    getUserClaims: (domain: claimsDomainsType) => userSelectors.getUserClaims(state)(domain)
});

const mapDispatchToProps = (dispatch: any) => ({
    fetchShipments: () => dispatch.shipments.fetchShipmentsDashboard(),
    fetchMyRFQ: () => dispatch.bids.fetchMyRFQ(),
    fetchShipmentsStatistics: () => dispatch.dashboard.fetchShipmentsStatistics(),
    fetchOrdersStatistics: () => dispatch.dashboard.fetchOrdersStatistics(),
    fetchPurchaseOrders: () => dispatch.purchaseOrders.fetchPurchaseOrders(),
    fetchPurchaseOrderGridColumns: () => dispatch.allPurchaseOrdersPage.fetchGridColumns(),
    fetchPurchaseDemands: () => dispatch.purchaseDemands.fetchPurchaseDemands(),
    fetchPurchaseDemandsGridColumns: () => dispatch.allPurchaseDemandsPage.fetchGridColumns(),
    setDrawerOpen: (open: drawers, params?: any) => dispatch.header.setDrawerOpenParams({ open, params }),
    fetchGridColumns: () => dispatch.allShipmentsPage.fetchGridColumns(),
    setShipmentFilters: (filter: filterFieldsType) => dispatch.allShipmentsPage.setShipmentFilters({ filter }),
    setGridFilter: (text: string) => dispatch.allShipmentsPage.setGridFilter(text),
    setOrdersFilters: (filters: orderFilterFields) => dispatch.orders.setOrdersFilters(filters)
});

export default connect(mapStateToProps, mapDispatchToProps)(DashboardPageConnected);
