import React from 'react';
import styled from 'styled-components';
import { ColumnType } from '../../components/shared/Grid/types/Column';
import { Image } from 'antd';
import { inventoryTotalView } from '../../models/entities/product/inventoryLog';
import ProductMarketplaceAsin from '../../components/shared/Grid/specialColumns/ProductMarketplaceAsin';

const mockColumns: Array<ColumnType<inventoryTotalView>> = [
    {
        field: 'productName',
        sortable: true,
        isDisplayed: true,
        width: '200px',
        columnType: 'fakeLink'
    },
    {
        field: 'sku',
        sortable: true,
        isDisplayed: true
    },
    {
        field: 'productImgUrl',
        sortable: false,
        isDisplayed: true,
        textAlign: 'center',
        renderer: (cellValue: any, bindedData?: Array<any> | null) => {
            if (!cellValue || typeof cellValue !== 'string' || cellValue === '-') return <div>-</div>;
            return (
                <ImgContainer
                    onClick={(e) => {
                        e.stopPropagation();
                    }}
                >
                    <Image height={35} src={cellValue as string} />
                </ImgContainer>
            );
        }
    },
    {
        field: 'productFnsku',
        sortable: true,
        isDisplayed: true
    },
    {
        field: 'productAsin',
        sortable: true,
        isDisplayed: true,
        width: '160px',
        hideTitleAttribute: true,
        renderer: (cellValue: any) => {
            return <ProductMarketplaceAsin marketplaceAsin={cellValue} />;
        }
    },
    {
        field: 'status',
        sortable: true,
        isDisplayed: true
    },
    {
        field: 'reserved',
        sortable: true,
        isDisplayed: true
    },
    {
        field: 'onHand',
        sortable: true,
        isDisplayed: true
    },
    {
        field: 'inbound',
        sortable: true,
        isDisplayed: true
    },
    {
        field: 'outbound',
        sortable: true,
        isDisplayed: true
    },
    {
        field: 'available',
        sortable: true,
        isDisplayed: true
    },
    {
        field: 'updatedAt',
        sortable: true,
        isDisplayed: true,
        columnType: 'date-time'
    }
];

const ImgContainer = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;

    .ant-image-mask-info {
        font-size: 0;
        display: flex;
        justify-content: center;
        align-items: center;
    }

    .ant-image-mask-info .anticon {
        font-size: 14px;
        margin-inline-end: 0;
    }
`;

export default mockColumns;
