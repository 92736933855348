import * as Yup from 'yup';

export const validationSchema = (localization: any) => {
    const requiredFieldMessage = localization.required_field_message;
    return Yup.object().shape({
        name: Yup.string().required(requiredFieldMessage).nullable().test('len', localization.max_length_message(250), maxLengthValidation(250)),
        sku: Yup.string().required(requiredFieldMessage).nullable().test('len', localization.max_length_message(150), maxLengthValidation(150)),
        // description: Yup.string().nullable().test('len', localization.max_length_message(512), maxLengthValidation(512)),
        supplierId: Yup.string().required(requiredFieldMessage).nullable(),
        supplierName: Yup.string()
            .required(requiredFieldMessage)
            .nullable()
            .test('len', localization.max_length_message(150), maxLengthValidation(150)),
        categoryId: Yup.string().nullable(),
        brand: Yup.string().nullable().test('len', localization.max_length_message(150), maxLengthValidation(150)),
        cost: Yup.number().nullable(),
        costCurrency: Yup.string().nullable(),
        retailPrice: Yup.number().nullable(),
        retailPriceCurrency: Yup.string().nullable(),
        salePrice: Yup.number().nullable(),
        salePriceCurrency: Yup.string().nullable(),
        weight: Yup.number().nullable(),
        weightUnit: Yup.string().nullable(),
        height: Yup.number().nullable(),
        width: Yup.number().nullable(),
        length: Yup.number().nullable(),
        dimensionUnit: Yup.string().nullable().test('len', localization.max_length_message(20), maxLengthValidation(20)),
        note: Yup.string().nullable().test('len', localization.max_length_message(512), maxLengthValidation(512)),
        userId: Yup.string().nullable(),
        fnsku: Yup.string().nullable().test('len', localization.max_length_message(20), maxLengthValidation(20)),
        rank: Yup.number().nullable(),
        numberOfRatings: Yup.number().nullable(),
        fbaFee: Yup.number().nullable(),
        fbaFeeCurrency: Yup.string().nullable(),
        amazonPercentFee: Yup.string().nullable()
    });
};

const maxLengthValidation = (maxLength: number) => {
    return (val: any) => (val ? val.toString().length <= maxLength : true);
};
