import { orderAlert } from './../../../../models/entities/order/orderAlert/orderAlert';
import BaseController from '../..';
import IOrderAlerts from './IOrderAlerts';

export default class OrderAlerts extends BaseController implements IOrderAlerts {
    async fetchAlertsByOrderId(orderId: string): Promise<Array<orderAlert>> {
        const response = await this.client.get(`/order/alerts/${orderId}`);
        return response;
    }

    async dismissOrderAlert(orderId: string, alert: orderAlert): Promise<void> {
        const response = await this.client.post(
            `/order/alerts/${alert.id}/dismiss`,
            JSON.stringify({ orderId, data: { level: alert.level, message: alert.message } })
        );
        return response;
    }
}
