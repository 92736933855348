import React from 'react';
import { styled } from '../../Theme/theme';
import { FormTextInput, FormNumberInput } from '.';

type Props = {
    codeInputName: string;
    percentInputName: string;
    codePlaceHolder: string;
};

const HsCodeInput = ({ codeInputName, percentInputName, codePlaceHolder }: Props) => {
    return (
        <Flex>
            <FormTextInput name={codeInputName} placeHolder={codePlaceHolder} />
            <div style={{ marginLeft: '10px', flex: 1 }}>
                <FormNumberInput
                    name={percentInputName}
                    formatter={(value) => `${value || 0}%`}
                    parser={(value) => Number(value.replace('%', '')) || 0}
                    min={0}
                    max={100}
                />
            </div>
        </Flex>
    );
};
const Flex = styled.div`
    display: flex;
`;
HsCodeInput.defaultProps = {
    codeInputName: 'code',
    percentInputName: 'percent'
};
export default HsCodeInput;
