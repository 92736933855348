import React from 'react';
import { styled } from '../../Theme/theme';
import { FormTextInput, FormDropDown } from '.';
import { GroupedOptions, Option } from '../base/DropDown';

type Props = {
    lengthInputName: string;
    heightInputName: string;
    widthInputName: string;
    unitInputName: string;
    unitPlaceHolder: string;
    onUnitChange?: (value: any) => void;
    options: Option[] | GroupedOptions;
};

const DimensionInput = ({ lengthInputName, heightInputName, widthInputName, unitInputName, unitPlaceHolder, options, onUnitChange }: Props) => {
    return (
        <Flex>
            <FormTextInput
                name={lengthInputName}
                placeHolder="L"
                type="number"
                style={{
                    width: '60px',
                    borderTopRightRadius: 0,
                    borderBottomRightRadius: 0,
                    textAlign: 'center'
                }}
                min="0"
                step="0.001"
            />
            <FormTextInput
                name={widthInputName}
                placeHolder="W"
                type="number"
                style={{ width: '60px', borderRadius: 0, borderRight: 0, borderLeft: 0, textAlign: 'center' }}
                min="0"
                step="0.001"
            />
            <FormTextInput
                name={heightInputName}
                placeHolder="H"
                type="number"
                style={{
                    width: '60px',
                    borderTopLeftRadius: 0,
                    borderBottomLeftRadius: 0,
                    textAlign: 'center'
                }}
                min="0"
                step="0.001"
            />
            <div style={{ marginLeft: '10px', flex: 1 }}>
                <FormDropDown name={unitInputName} placeHolder={unitPlaceHolder} options={options} onChange={onUnitChange} />
            </div>
        </Flex>
    );
};
const Flex = styled.div`
    display: flex;
`;
export default DimensionInput;
