import React from 'react';
import styled from 'styled-components';
import theme from '../Theme/theme';

const menuTheme = theme.colors.menu;

type Props = {
    onClick?: (e: React.MouseEvent<HTMLElement, MouseEvent>) => void;
    icon?: string;
    text: string;
    children?: React.ReactChild;
    requiredConfirm?: boolean;
    suffixIcon?: string;
};
function MenuItem({ onClick, icon, text, requiredConfirm, suffixIcon, children }: Props) {
    return (
        <StyledMenuItem onClick={requiredConfirm ? () => {} : onClick}>
            {icon && <img alt="icon" style={{ marginRight: '5px' }} src={icon} />}
            {children || <span>{text}</span>}
            {suffixIcon && <SuffixIcon className="material-icons">{suffixIcon}</SuffixIcon>}
        </StyledMenuItem>
    );
}

const StyledMenuItem = styled.div`
    width: 100%;
    display: flex;
    align-items: center;
    cursor: pointer;
    padding: 6px;
    border-bottom: 1px solid ${menuTheme.itemBorder};
    user-select: none;
    :hover {
        background: ${menuTheme.itemHoverBG};
    }
    @media screen and (max-width: 768px) {
        padding: 8px 25px;
    }
`;
const SuffixIcon = styled.div`
    position: absolute;
    right: 0;
    color: ${(props) => props.theme.colors.primaryBlue};
    width: 25px;
    font-size: 18px;
`;
export default MenuItem;
