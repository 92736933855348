import { ModelConfig } from '@rematch/core';
import { shipment } from '../../../models/entities/shipment/shipment';
import { containersGridView } from '../../../models/entities/containers/containersGridView';
import { localSDK as client } from '../../../sdk';
import { shipmentsStatistics } from '../../../models/entities/dashboard/shipmentsStatistics';

export type containersStateType = {
    containersGridView: containersGridView;
    textFilterValue: string;
};

export const containers: ModelConfig<containersStateType> = {
    state: {
        containersGridView: [],
        textFilterValue: ''
    },
    reducers: {
        setContainersGridView(state: containersStateType, containers: containersGridView): containersStateType {
            return { ...state, containersGridView: containers };
        },
        setContainerState(
            state: containersStateType,
            { containerId, containerState }: { containerId: string; containerState: string }
        ): containersStateType {
            const newContainers = [...state.containersGridView];
            const containerIndex = newContainers.findIndex((c) => c.id === containerId);
            if (containerIndex !== -1) {
                newContainers[containerIndex].state = containerState;
            }
            return { ...state, containersGridView: newContainers };
        },
        setTextFilterValue(state: containersStateType, text: string) {
            return { ...state, textFilterValue: text };
        }
    },
    effects: (dispatch: any) => ({
        async fetchContainersGridView() {
            const data = await client.containers().getContainers();
            dispatch.containers.setContainersGridView(data);
        },
        async updateContainerState({ shipmentId, containerId, state }: { shipmentId: string; containerId: string; state: string }) {
            dispatch.containers.setContainerState({ containerId, containerState: state });
            await client.shipments().containers().updateShipmentContainerState(shipmentId, containerId, state);
        },
        async filterContainersByTerm(term: string) {
            dispatch.containers.setTextFilterValue(term);
        },
        async fetchUpcomingArrivals() {}
    })
};
