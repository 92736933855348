import BaseController from '../..';
import { attribute, attributeOption } from '../../../../models/entities/product/attribute';
import IProductsAttributes from './IProductAttributes';

export default class ProductsAttributes extends BaseController implements IProductsAttributes {
    constructor(baseUrl: string) {
        super(baseUrl);
    }
    async createProductAttribute(productId: string, data: attribute) {
        const response = await this.client.post(`/product/attributes`, JSON.stringify({ productId, data }));
        return response;
    }
    async updateProductAttribute(productId: string, data: attribute) {
        const response = await this.client.put(`/product/attributes`, JSON.stringify({ productId, data }));
        return response;
    }
    async deleteProductAttribute(productId: string, attributeId: number) {
        const response = await this.client.delete(`/product/attributes`, JSON.stringify({ id: attributeId, productId }));
        return response;
    }

    async createProductAttributeOption(attributeId: string, data: attributeOption) {
        const response = await this.client.post(`/product/attributes/option`, JSON.stringify({ productAttributeId: attributeId, data }));
        return response;
    }
    async updateProductAttributeOption(attributeId: string, data: attributeOption) {
        const response = await this.client.put(`/product/attributes/option`, JSON.stringify({ productAttributeId: attributeId, data }));
        return response;
    }
    async deleteProductAttributeOption(productId: string, attributeId: number) {
        const response = await this.client.delete(`/product/attributes/option`, JSON.stringify({ id: attributeId, productId }));
        return response;
    }
}
