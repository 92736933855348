import { useState, useEffect, useCallback } from 'react';
import { useDebouncedCallback } from 'use-debounce/lib';

export default function useDebounceOnChange<T = any>(
    value: T,
    onChange: (value: T) => void,
    delay: number = 500,
    defaultValue?: T
): [T, (newValue: T) => void] {
    const [innerValue, setInnerValue] = useState<T>(value);

    useEffect(() => {
        if (value) {
            setInnerValue(value);
        } else if (defaultValue) {
            setInnerValue(defaultValue);
        }
    }, [defaultValue, value]);

    const debouncedHandleOnChange = useDebouncedCallback((newValue: T) => {
        if (onChange) {
            onChange(newValue);
        }
    }, delay);

    const handleDebounceOnChange = useCallback((newValue: T) => {
        setInnerValue(newValue);
        debouncedHandleOnChange(newValue);
    }, []);

    return [innerValue, handleDebounceOnChange];
}
