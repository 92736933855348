import BaseController from '../..';
import { document } from '../../../../models/entities/common_subentities/document';
import IOrderDocuments from './IOrderDocuments';

export default class OrderDocuments extends BaseController implements IOrderDocuments {
    async createMultipleOrderDocument(orderId: string, data: Array<document>) {
        this.client.setBodyType('FORM-DATA');
        const response = await this.client.post(`/order/documents/upload-multiple`, this.convertToArrayFormData(data, orderId));
        return response;
    }

    async updateOrderDocument(orderId: string, data: document) {
        this.client.setBodyType('FORM-DATA');
        const response = await this.client.put(`/order/documents`, this.convertToFormData(data, orderId));
        return response;
    }
    async deleteOrderDocument(orderId: string, documentId: number) {
        const response = await this.client.delete(`/order/documents`, JSON.stringify({ orderId, id: documentId }));
        return response;
    }

    private convertToArrayFormData(documents: Array<document>, orderId: string): FormData {
        const formData = new FormData();
        formData.append('orderId', orderId);
        documents.forEach((document) => {
            let type = document.type;
            if (Array.isArray(type)) type = JSON.stringify(type);
            formData.append('type[]', type);
            formData.append('comment[]', document.comment);
            formData.append('fileName[]', document.fileName);
            formData.append('fileType[]', document.fileType);
            if (document.expDate) formData.append('expDate[]', JSON.stringify(document.expDate));
            if (document.file) formData.append('file[]', document.file);
            if (document.id) formData.append('id[]', '' + document.id);
        });
        return formData;
    }

    private convertToFormData(document: document, orderId: string): FormData {
        const formData = new FormData();
        let type = document.type;
        if (Array.isArray(type)) type = JSON.stringify(type);
        formData.append('orderId', orderId);
        formData.append('type', type);
        formData.append('comment', document.comment);
        formData.append('fileName', document.fileName);
        formData.append('fileType', document.fileType);
        if (document.expDate) formData.append('expDate', JSON.stringify(document.expDate));
        if (document.file) formData.append('file', document.file);
        if (document.id) formData.append('id', '' + document.id);
        return formData;
    }
}
