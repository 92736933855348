import { GridCellParams, GridColDef, GridValueFormatterParams } from '@material-ui/data-grid';
import React from 'react';
import { currencyFormatter } from '../../components/shared/inputs/form/CurrencyInput/CurrencyInput';
import CustomModal, { ModalTriggerProps } from '../../components/shared/Modal/Modal';
import MuiDataGrid from '../../components/shared/MuiDataGrid/MuiDataGrid';
import CountryOption from '../../components/shared/SmallComponents/CountryOption';
import { amountByMarketplace } from '../../models/entities/product/inventoryLog';
import { uuidv4 } from '../../utils/patterns';
import marketplaces from '../../static/data/marketplaces.json';

type Props = {
    amountsByMarketplaces: Array<amountByMarketplace>;
    onCancelCallback?: () => void;
    Trigger: (props: ModalTriggerProps) => JSX.Element;
    localization?: any;
};

type marketplace = {
    country: string;
    countryCode: string;
    marketplaceId: string;
};
const AmazonMarketplacesAmountsModal = ({ amountsByMarketplaces, onCancelCallback, Trigger, localization }: Props) => {
    const flatMarketplaces = Object.entries(marketplaces)
        .reduce((acc: marketplace[], [region, regionMarketplaces]: [string, marketplace[]]) => [...acc, ...regionMarketplaces], [])
        .flat();

    const columns: GridColDef[] = [
        {
            field: 'marketplaceId',
            headerName: 'marketplace',
            flex: 1,
            renderCell: (params: GridCellParams) => {
                const marketplace = flatMarketplaces.find((m) => m.marketplaceId === params.value);
                return <CountryOption country={marketplace?.country || ''} code={marketplace?.countryCode || ''} removeBorder />;
            }
        },
        {
            field: 'amount',
            headerName: 'amount',
            valueFormatter: (params: GridValueFormatterParams) => {
                const { currency, amount, volumeUnit } = params.row;
                return `${params.row.currency ? currencyFormatter(currency)(amount) : volumeUnit ? `${amount} ${volumeUnit}` : amount}`;
            },
            flex: 1
        }
    ];
    return (
        <CustomModal
            Trigger={Trigger}
            Content={(setVisible?: React.Dispatch<React.SetStateAction<boolean>>, setConfirm?: React.Dispatch<React.SetStateAction<boolean>>) => {
                return (
                    <MuiDataGrid
                        columns={columns}
                        rows={amountsByMarketplaces.map((amounts) => ({ ...amounts, id: uuidv4() }))}
                        disableSelectionOnClick
                        disableColumnFilter
                        disableColumnSelector
                        disableColumnMenu
                        autoHeight
                    />
                );
            }}
            width="500px"
            onCancelCallback={onCancelCallback}
        />
    );
};

export default AmazonMarketplacesAmountsModal;
