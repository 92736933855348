import { GridCellParams, GridColDef, GridValueFormatterParams } from '@material-ui/data-grid';
import React from 'react';
import { Link } from 'react-router-dom';
import { currencyFormatter } from '../../components/shared/inputs/form/CurrencyInput/CurrencyInput';
import CustomModal, { ModalTriggerProps } from '../../components/shared/Modal/Modal';
import MuiDataGrid from '../../components/shared/MuiDataGrid/MuiDataGrid';
import { amountByEntityType } from '../../models/entities/product/inventoryLog';
import { PagesRoutes } from '../../routing/PagesRoutes';

type Props = {
    amountsByEntities: Array<amountByEntityType>;
    onCancelCallback?: () => void;
    Trigger: (props: ModalTriggerProps) => JSX.Element;
    localization?: any;
};

const EntitiesAmountsModal = ({ amountsByEntities, onCancelCallback, Trigger, localization }: Props) => {
    const columns: GridColDef[] = [
        {
            field: 'entityCzNumber',
            headerName: '#',
            flex: 1,
            renderCell: (params: GridCellParams) => (
                <Link
                    to={{
                        pathname:
                            (params.row.entityType === 'PO'
                                ? PagesRoutes.PurchaseOrders
                                : params.row.entityType === 'PD'
                                ? PagesRoutes.PurchaseDemands
                                : PagesRoutes.Shipments) +
                            '/' +
                            params.row.entityId
                    }}
                >
                    {params.value}
                </Link>
            )
        },
        {
            field: 'amount',
            headerName: 'amount',
            valueFormatter: (params: GridValueFormatterParams) => {
                const { currency, amount, volumeUnit } = params.row;
                return `${params.row.currency ? currencyFormatter(currency)(amount) : volumeUnit ? `${amount} ${volumeUnit}` : amount}`;
            },
            flex: 1
        }
    ];
    return (
        <CustomModal
            Trigger={Trigger}
            Content={(setVisible?: React.Dispatch<React.SetStateAction<boolean>>, setConfirm?: React.Dispatch<React.SetStateAction<boolean>>) => {
                return (
                    <MuiDataGrid
                        columns={columns}
                        rows={amountsByEntities}
                        disableSelectionOnClick
                        disableColumnFilter
                        disableColumnSelector
                        disableColumnMenu
                        autoHeight
                    />
                );
            }}
            width="500px"
            onCancelCallback={onCancelCallback}
        />
    );
};

export default EntitiesAmountsModal;
