import React from 'react';
import { Link } from 'react-router-dom';
import { settings, shipment } from '../../../../../models/entities/shipment/shipment';
import ShipmentStation from '../../../../shared/Grid/specialColumns/ShipmentStation';
import ShipmentTypeWithTooltip from '../../../../shared/SmallComponents/ShipmentTypeWithTooltip';
import { styled } from '../../../../shared/Theme/theme';
import ReferencesItems from '../../../singleShipment/sections/References/ShipmentReferencesItems';
import { PagesRoutes } from '../../../../../routing/PagesRoutes';
import { bid } from '../../../../../models/entities/bid/bid';

type Props = {
    localization?: any;
    shipment?: shipment;
    bid?: bid;
    padding?: string;
};

const OverviewHeader = ({ shipment, localization, bid, padding }: Props) => {
    if (!shipment) return null;
    return (
        <SectionWrapper padding={padding}>
            <Wrapper>
                <GeneralSection>
                    {bid && (
                        <BidNumber>
                            <span>{localization.bid}</span> #{bid.CargoZoneNumber}
                        </BidNumber>
                    )}
                    <ShipmentNumber>
                        <ShipmentTypeWithTooltip typeCode={shipment.typeCode} subTypeCode={shipment.subTypeCode} iconWidth={30} />
                        <span style={{ marginLeft: '10px', marginRight: '5px' }}>Shipment</span>
                        <Link
                            to={{
                                pathname: `${PagesRoutes.Shipments}/${shipment.id}`
                            }}
                        >
                            #{shipment.CargoZoneNumber}
                        </Link>
                    </ShipmentNumber>
                </GeneralSection>
                <SectionsSeparator />
                <ReferencesSection>
                    <SingleRefWrapper>
                        <RefText>{localization.client_ref}</RefText> #
                        <ReferencesItems shipmentId={shipment.id} references={shipment.references || []} type="CLIENT" printMode={true} />
                    </SingleRefWrapper>
                    <ShipmentDetail>
                        <Text>Incoterms:</Text>
                        <Value>{shipment.incoterms}</Value>
                    </ShipmentDetail>
                </ReferencesSection>
                <SectionsSeparator />
                <GeneralSection>
                    <RouteRowWrapper>
                        <ShipmentStation country={shipment.originCountry} port={shipment.originStation} flagSize={'24px'} />
                        <RightArrowIcon />
                        <ShipmentStation country={shipment.destinationCountry} port={shipment.destinationStation} flagSize={'24px'} />
                    </RouteRowWrapper>
                    <Flex>
                        <ShipmentDetail>
                            <Text>City from:</Text>
                            <Value>{shipment.originZipCode || '-'}</Value>
                        </ShipmentDetail>
                        <ShipmentDetail>
                            <Text>City to:</Text>
                            <Value>{shipment.destinationZipCode || '-'}</Value>
                        </ShipmentDetail>
                    </Flex>
                    <Flex>
                        <ShipmentDetail>
                            <Text>Pickup address:</Text>
                            <Value>{shipment.originPickupAddress || '-'}</Value>
                        </ShipmentDetail>
                        <ShipmentDetail>
                            <Text>Delivery address:</Text>
                            <Value>{shipment.destinationPickupAddress || '-'}</Value>
                        </ShipmentDetail>
                    </Flex>
                </GeneralSection>
                <SectionsSeparator />
                <GeneralSection>
                    <DetailWrapper>
                        <div>{localization.company}:</div>
                        <div style={{ fontWeight: 'bolder', fontSize: '12px' }}>{shipment.companyName}</div>
                    </DetailWrapper>
                </GeneralSection>
            </Wrapper>
        </SectionWrapper>
    );
};

export const SectionWrapper = styled.div<{ padding?: string }>`
    display: flex;
    flex-direction: row;
    padding: ${(props) => props.padding || '24px 18px 18px 48px'};
    border-bottom: solid 1px ${(props) => props.theme.colors.grayDefaultBorder};
    justify-content: space-between;
`;

const Wrapper = styled.div`
    display: flex;
    flex-direction: row;
    width: 100%;
    color: #394372;
`;
const ShipmentNumber = styled.div`
    display: flex;
    align-items: center;
    font-size: ${(props) => props.theme.fontSizes.pageTitle};
    font-weight: bold;
`;
const BidNumber = styled.div`
    color: ${(props) => props.theme.colors.pageTitle};
    font-size: ${(props) => props.theme.fontSizes.pageTitle};
    font-weight: bold;
    height: 24px;
    margin-left: 10px;
    white-space: nowrap;
    margin-bottom: 10px;

    & span {
        @media screen and (max-width: 768px) {
            display: none;
        }
    }
`;
const SectionsSeparator = styled.div`
    width: 1px;
    border-right: solid 1px #e0e0e0;
    margin: 0 18px;
`;
const SingleRefWrapper = styled.div`
    display: flex;
    align-items: center;
    margin-right: 12px;
    flex-wrap: wrap;
    padding: 6px 0;
`;
const Flex = styled.div`
    display: flex;
    justify-content: space-between;
`;
const GeneralSection = styled.div`
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
`;
const ReferencesSection = styled.div`
    color: ${(props) => props.theme.colors.pageTitle};
    font-size: ${(props) => props.theme.fontSizes.subTitlte};
    margin-top: 6px;
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;

    & ::-webkit-scrollbar-thumb {
        background-color: rgba(0, 0, 0, 0.3) !important;
        border-radius: 5.5px;
    }
`;
const RefText = styled.span`
    font-weight: bold;
    margin-right: 4px;
`;
const ShipmentDetail = styled.div`
    max-width: 140px;
    min-width: 140px;
    word-break: break-all;
    margin-bottom: 10px;
    margin-right: 10px;
`;
const Text = styled.span`
    margin-right: 5px;
`;
const Value = styled.span`
    font-weight: bold;
`;
const DetailWrapper = styled.div`
    max-width: 200px;
    &:first-child {
        margin-bottom: 10px;
    }
`;

const RowWrapper = styled.div`
    display: flex;
    white-space: nowrap;
    flex-direction: row;
    padding: 6px 0;
    color: #394372;
`;
const RouteRowWrapper = styled(RowWrapper)`
    padding: 8px;
    cursor: pointer;
    :hover {
        background: #e0e0e0;
    }
`;

const RightArrowIcon = styled.div`
    width: 20px;
    height: 20px;
    margin: 10px 15px;

    background: #d1d1d1;
    height: 1px;
    width: 15px;
    position: relative;

    &:before,
    &:after {
        content: '';
        background: #d1d1d1;
        position: absolute;
        height: 1px;
        width: 8px;
    }

    &:before {
        right: -4px;
        bottom: -2px;
        transform: rotate(-30deg);
    }

    &:after {
        right: -4px;
        top: -2px;
        transform: rotate(30deg);
    }
`;

export default OverviewHeader;
