import React, { useState } from 'react';
import { styled } from '../../../../../../shared/Theme/theme';
import { address } from '../../../../../../../models/entities/company/address';
import Loader from '../../../../../../shared/SmallComponents/Loader';
import { ColumnType } from '../../../../../../shared/Grid/types/Column';
import Grid from '../../../../../../shared/Grid/Grid';
import CustomModal, { ModalTriggerProps } from '../../../../../../shared/Modal/Modal';
import AddressFormConnected, { formMode } from './AddressForm/AddressFormConnected';
import Button from '../../../../../../shared/SmallComponents/Button';
import attachment from '../../../../../../../static/icons/attachment.svg';
import { settings } from '../../../../../../../models/entities/businessPartner/businessPartner';

type Props = {
    addresses?: Array<address>;
    localization: any;
    settings?: settings;
};

const CompanyAddresses = ({ addresses, localization, settings }: Props) => {
    const { name, country, airPort, seaPort, city, address, description, phoneNumber, isDefault } = localization.addresses.grid.columns;
    const gridColumns: Array<ColumnType<address>> = [
        {
            title: name,
            field: 'name',
            sortable: false,
            isDisplayed: true
        },
        {
            title: country,
            field: 'country',
            sortable: false,
            isDisplayed: true,
            columnType: 'country'
        },
        {
            title: airPort,
            field: 'airPort',
            sortable: false,
            isDisplayed: true,
            columnType: 'port'
        },
        {
            title: seaPort,
            field: 'seaPort',
            sortable: false,
            isDisplayed: true,
            columnType: 'port'
        },
        {
            title: city,
            field: 'city',
            sortable: false,
            isDisplayed: true
        },
        {
            title: address,
            field: 'address',
            sortable: false,
            isDisplayed: true
        },
        {
            title: phoneNumber,
            field: 'phoneNumber',
            sortable: false,
            isDisplayed: true
        },
        {
            title: description,
            field: 'description',
            sortable: false,
            isDisplayed: true
        },
        {
            title: isDefault,
            field: 'isDefault',
            sortable: false,
            isDisplayed: true,
            columnType: 'bool'
        }
    ];

    const [modalVisible, setVisible] = useState(false);
    const [addressEntity, setAddress] = useState({} as address);
    return (
        <AddressesWrapper>
            {addresses ? (
                <>
                    <CustomModal
                        title={{ icon: attachment, text: localization.addresses.modal.add_title }}
                        Trigger={(props: ModalTriggerProps) => (
                            <ButtonContainer>
                                <Button onClick={props.onClick} width="auto" buttonType="hollow" padding="4px 12px">
                                    {localization.addresses.add_address}
                                </Button>
                            </ButtonContainer>
                        )}
                        Content={(
                            setVisible?: React.Dispatch<React.SetStateAction<boolean>>,
                            setConfirm?: React.Dispatch<React.SetStateAction<boolean>>
                        ) => {
                            return (
                                <AddressFormConnected
                                    mode={formMode.NEW}
                                    openedFromModal={setVisible ? { setVisible } : undefined}
                                    confirmation={setConfirm ? { setConfirm } : undefined}
                                />
                            );
                        }}
                        width="500px"
                    />
                    {addresses.length > 0 ? (
                        <GridWrapper>
                            <Grid<address>
                                columns={gridColumns}
                                data={addresses}
                                localization={localization.addresses.grid}
                                settings={settings}
                                onSettingsClick={() => console.log('settings')}
                                onRowMoreOptionsClick={(row) => {
                                    setAddress(row);
                                    setVisible(true);
                                }}
                                menuType="companyAddressMenu"
                            />
                            {modalVisible && (
                                <CustomModal
                                    title={{ icon: attachment, text: localization.addresses.modal.edit_title }}
                                    isOpen={true}
                                    Content={(
                                        setModalVisible?: React.Dispatch<React.SetStateAction<boolean>>,
                                        setConfirm?: React.Dispatch<React.SetStateAction<boolean>>
                                    ) => {
                                        return (
                                            <AddressFormConnected
                                                mode={formMode.EDIT}
                                                address={addressEntity}
                                                openedFromModal={{ setVisible }}
                                                confirmation={setConfirm ? { setConfirm } : undefined}
                                            />
                                        );
                                    }}
                                    onCancelCallback={() => {
                                        setVisible(false);
                                        setAddress({} as address);
                                    }}
                                    width="393px"
                                />
                            )}
                        </GridWrapper>
                    ) : (
                        <EmaptyStateText>{localization.addresses.empty_state}</EmaptyStateText>
                    )}
                </>
            ) : (
                <Loader />
            )}
        </AddressesWrapper>
    );
};

const GridWrapper = styled.div`
    flex: 0 0 100%;
    max-width: 100%;
    width: 100%;
    box-sizing: border-box;
    overflow-y: overlay;
    height: 100%;
    width: 100%;
`;

const AddressesWrapper = styled.div`
    display: flex;
    flex-direction: column;
    width: 100%;
`;

const EmaptyStateText = styled.span`
    color: ${(props) => props.theme.colors.emptyStateText};
`;

const ButtonContainer = styled.div`
    display: flex;
    justify-content: flex-end;
    padding-top: 8px;
`;

export default CompanyAddresses;
