import React from 'react';
import { PagesRoutes } from '../../../../routing/PagesRoutes';
import { businessPartner } from '../../../../models/entities/businessPartner/businessPartner';
import Overview from './sections/Overview';
import { styled } from '../../../shared/Theme/theme';
import Button from '../../../shared/SmallComponents/Button';
import EditIcon from '../../../../static/icons/edit.svg';
import BackButton from '../../../shared/SmallComponents/BackButton';
import CompanyTabs from './sections/Tabs';
import { useHistory } from 'react-router-dom';
import CustomModal, { ModalTriggerProps } from '../../../shared/Modal/Modal';
import BusinessPartnerFormConnected, { formMode } from '../components/BusinessPartnerForm/BusinessPartnerFormConnected';
import SendGetStartedInventationModal from '../components/SendGetStartedInventation/SendGetStartedInventationModal';
import { settings } from '../../../../models/entities/businessPartner/businessPartner';
import DiscussionsSection from './sections/Discussions/DiscussionsSection';
import { allowedCompany, userInfo } from '../../../../models/entities/user';
import { product } from '../../../../models/entities/product/product';
import { drawers } from '../../../../state/ducks/header/header';
import { Switch } from 'antd';
import { RcFile } from 'antd/lib/upload';

type Props = {
    businessPartner: businessPartner;
    localization?: any;
    settings?: settings;
    allowedCompanies: allowedCompany[];
    products: product[];
    setDrawerOpen: (open: drawers, params?: any) => void;
    updateBusinessPartnerIsActive: (businessPartnerId: string, isActive: boolean) => Promise<void>;
    updateBusinessPartnerLogo: (businessPartnerId: string, file: RcFile) => Promise<void>;
    showLogoLoader: boolean;
    loadingIsActive: boolean;
    isForwarder: boolean;
    users: userInfo[];
};

const SingleBusinessPartnerPage = ({
    businessPartner,
    products,
    localization,
    settings,
    allowedCompanies,
    updateBusinessPartnerLogo,
    showLogoLoader,
    setDrawerOpen,
    updateBusinessPartnerIsActive,
    loadingIsActive,
    isForwarder,
    users
}: Props) => {
    const history = useHistory();
    return (
        <PageWrapper>
            <LeftSide>
                <SectionWrapper>
                    <div>
                        <BackButton style={{ marginLeft: '-30px' }} toPath={PagesRoutes.BusinessPartners} text={localization.backLink} />
                    </div>
                    {businessPartner.deletedAt && <InactiveBadge>{localization.deleted}</InactiveBadge>}
                    <HeaderRightSide>
                        {isForwarder && (
                            <SendGetStartedInventationModal
                                businessPartner={businessPartner}
                                Trigger={(props: ModalTriggerProps) => (
                                    <Button onClick={props.onClick} buttonType="filled" margin="0 12px 0 0">
                                        {localization.invite_to_join_cargozone}
                                    </Button>
                                )}
                            />
                        )}
                        <Switch
                            style={{ marginRight: 5 }}
                            loading={loadingIsActive}
                            checked={businessPartner.isActive}
                            onChange={async (checked) => await updateBusinessPartnerIsActive(businessPartner.id, checked)}
                            checkedChildren={localization.active}
                            unCheckedChildren={localization.inactive}
                        />
                        {allowedCompanies.map((item) => item.companyId).includes(businessPartner.companyId) && (
                            <CustomModal
                                title={{ text: localization.modal.edit_title }}
                                Trigger={(props: ModalTriggerProps) => (
                                    <Button onClick={props.onClick} margin="0 12px 0 0">
                                        <img src={EditIcon} alt={localization.edit} />
                                        <span>{localization.edit}</span>
                                    </Button>
                                )}
                                Content={(
                                    setVisible?: React.Dispatch<React.SetStateAction<boolean>>,
                                    setConfirm?: React.Dispatch<React.SetStateAction<boolean>>
                                ) => {
                                    return (
                                        <BusinessPartnerFormConnected
                                            mode={formMode.EDIT}
                                            party={businessPartner}
                                            openedFromModal={setVisible ? { setVisible } : undefined}
                                            confirmation={setConfirm ? { setConfirm } : undefined}
                                        />
                                    );
                                }}
                                width="393px"
                            />
                        )}
                    </HeaderRightSide>
                </SectionWrapper>
                <OverviewSectionWrapper>
                    <Overview
                        updateBusinessPartnerLogo={updateBusinessPartnerLogo}
                        showLogoLoader={showLogoLoader}
                        businessPartner={businessPartner}
                        localization={localization}
                        settings={settings}
                        users={users}
                    />
                </OverviewSectionWrapper>
                <SectionWrapper>
                    <CompanyTabs
                        businessPartner={businessPartner}
                        products={products}
                        localization={localization}
                        settings={settings}
                        setDrawerOpen={setDrawerOpen}
                    />
                </SectionWrapper>
                <SectionWrapper>
                    <DiscussionsSection localization={localization} />
                </SectionWrapper>
            </LeftSide>
        </PageWrapper>
    );
};

const PageWrapper = styled.div`
    display: flex;
    flex-direction: row;

    @media screen and (max-width: 768px) {
        height: calc(100vh - 65px - 40px);
    }
`;

const LeftSide = styled.div`
    width: 100%;
    border-right: 1px solid ${(props) => props.theme.colors.grayDefaultBorder};

    @media screen and (max-width: 1200px) {
        width: 100%;
        max-width: 100%;
    }
`;

const OverviewSectionWrapper = styled.div`
    display: flex;
    flex-direction: row;
    border-bottom: solid 1px ${(props) => props.theme.colors.grayDefaultBorder};
    justify-content: space-between;
`;

export const SectionWrapper = styled.div`
    display: flex;
    flex-direction: row;
    padding: 24px 18px 18px 48px;
    border-bottom: solid 1px ${(props) => props.theme.colors.grayDefaultBorder};
    justify-content: space-between;
`;

const HeaderRightSide = styled.div`
    display: flex;
    align-items: center;
    height: 30px;
    margin: 30px 0 0 0;
`;

const InactiveBadge = styled.span`
    border-radius: 2vh;
    border: 1px solid #ff7676;
    color: #ff7676;
    font-size: 14px;
    height: 24px;
    padding: 3px 8px;
    margin-left: 10px;
    display: inline-flex;
    align-items: center;
    line-height: 1px;
    align-self: flex-end;
    min-width: max-content;
`;

export default SingleBusinessPartnerPage;
