import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { RootState } from '../../../state/store/store';
import { localizationSelectors } from '../../../state/ducks/localization/selectors';
import { ordersSelectors } from '../../../state/ducks/orders/selectors';
import { order } from '../../../models/entities/order/order';
import Loader from '../../shared/SmallComponents/Loader';
import ordersGridColumns from './ordersGridColumns';
import GridPage from '../../shared/PageTemplates/GridPage/GridPage';
import { paginationType } from '../../shared/PageTemplates/GridPage/components/GridPageHeader/models';
import { PagesRoutes } from '../../../routing/PagesRoutes';
import { orderFilters, orderFilterFields } from '../../../state/ducks/orders/gridFilters/types';
import FilterContent from './components/Filter/FilterContent';
import { parseFilterFields, removeFilterItem } from './components/Filter/utils';
import { userSelectors } from '../../../state/ducks/user/selectors';
import { allowedCompany, userInfo, userSettings } from '../../../models/entities/user';
import { gridSortType } from '../../shared/Grid/Grid';
import { filterColsByUserSettings } from '../../shared/Grid/util';
import { ContextMenuItemType } from '../../shared/ContextMenu/types';
import { bulkDeleteOrdersPayload, bulkUpdateOrdersStatePayload, orderStates } from '../../../models/entities/order';
import styled from 'styled-components';
import { entries } from '../../../utils/typedEntries';
import { bulkFollowPayload, bulkUnfollowPayload } from '../../../models/entities/order/orderFollower/payloads';
import trashIcon from '../../../static/icons/trash.svg';

type Props = {
    orders: order[];
    allOrders: order[];
    isFetchingOrders: boolean;
    localization: any;
    activeOrdersCount: number;
    filteredOrdersCount: number;
    pagination: paginationType;
    filterFields: orderFilterFields | null;
    activeFiltersCount: number;
    allowedCompanies: allowedCompany[];
    ordersGridSort?: gridSortType<order>;
    ordersGridSettings?: userSettings;
    userInfo: userInfo;
    bulkUpdateOrdersState: (payload: bulkUpdateOrdersStatePayload) => Promise<void>;
    setOrdersGridSort: (ordersGridSort?: gridSortType<order>) => void;
    fetchOrders: () => Promise<void>;
    setGridFilter: (text: string) => void;
    setCurrentPage: (page: number) => void;
    setRowsPerPage: (rowsPerPage: number) => void;
    setOrdersFilters: (filter: orderFilterFields) => void;
    bulkFollow: (payload: bulkFollowPayload) => Promise<void>;
    bulkUnfollow: (payload: bulkUnfollowPayload) => Promise<void>;
    bulkDeleteOrders: (payload: bulkDeleteOrdersPayload) => Promise<void>;
};

const OrdersPageConnected = ({
    orders,
    allOrders,
    isFetchingOrders,
    localization,
    activeOrdersCount,
    filteredOrdersCount,
    pagination,
    filterFields,
    activeFiltersCount,
    allowedCompanies,
    ordersGridSort,
    ordersGridSettings,
    userInfo,
    bulkUpdateOrdersState,
    setOrdersGridSort,
    fetchOrders,
    setGridFilter,
    setCurrentPage,
    setRowsPerPage,
    setOrdersFilters,
    bulkFollow,
    bulkUnfollow,
    bulkDeleteOrders
}: Props) => {
    const [selectedOrders, setSelectedOrders] = useState<string[]>([]);

    const contextMenuData = (order: order): ContextMenuItemType[] => [
        {
            label: 'Follow',
            callback: async () => await bulkFollow({ ordersIds: selectedOrders.length ? selectedOrders : [order.id] }),
            showConfirm: true
        },
        {
            label: 'Unfollow',
            callback: async () => await bulkUnfollow({ ordersIds: selectedOrders.length ? selectedOrders : [order.id], userId: userInfo.id }),
            showConfirm: true
        },
        {
            label: 'Update State',
            items: entries(orderStates)
                .sort((a, b) => a[1].displayOrder - b[1].displayOrder)
                .map(([state, data]) => ({
                    label: localization.state[state],
                    leftIcon: <Circle color={data.color} />,
                    callback: async () => await bulkUpdateOrdersState({ ordersIds: selectedOrders.length ? selectedOrders : [order.id], state }),
                    showConfirm: true
                }))
        },
        {
            label: 'Delete',
            callback: async () => {
                await bulkDeleteOrders({ ordersIds: selectedOrders.length ? selectedOrders : [order.id] });
                setSelectedOrders([]);
            },
            leftIcon: <img alt="Delete" src={trashIcon} />,
            showConfirm: true
        }
    ];

    useEffect(() => {
        fetchOrders();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    if (isFetchingOrders && orders.length <= 1) return <Loader />;
    return (
        <GridPage<order, orderFilters>
            showLoadingOverlay={isFetchingOrders && activeOrdersCount > 1}
            gridProps={{
                data: orders,
                columns: filterColsByUserSettings(ordersGridColumns(localization), ordersGridSettings),
                emptyStateText: localization.emptyState,

                gridSort: ordersGridSort,
                setGridSort: setOrdersGridSort,

                contextMenuData,

                allData: allOrders,
                showCheckboxSelection: true,
                selectedRows: selectedOrders,
                onSelectedRowsChange: (selectedOrders: string[]) => setSelectedOrders(selectedOrders),

                onSettingsClick: () => console.log('settings'),
                onRowMoreOptionsClick: (row) => console.log(row),
                userSettingKey: 'OrdersGrid'
            }}
            headerProps={{
                data: orders,
                activeDataCount: activeOrdersCount,
                filteredDataCount: filteredOrdersCount,
                pagination,
                gridColumns: ordersGridColumns(localization),
                currentGridFilter: null,
                localization,
                isMobile: false,
                setGridFilter,
                fetchData: fetchOrders,
                setCurrentPage,
                setRowsPerPage,
                addBtn: {
                    title: localization.newOrder,
                    pathname: PagesRoutes.NewOrder
                },
                filterStripeProps: {
                    parsedFilterFields: filterFields ? parseFilterFields(filterFields, allowedCompanies, localization) : undefined,
                    filterFields,
                    removeFilterItem: removeFilterItem,
                    setFilters: setOrdersFilters
                },
                filterPopoverProps: {
                    activeFiltersCount,
                    FilterContent: FilterContent,
                    localization
                }
            }}
        />
    );
};

const mapStateToProps = (state: RootState) => ({
    localization: localizationSelectors.ordersPage(state),
    orders: ordersSelectors.currentGridDataPage(state),
    allOrders: ordersSelectors.gridData(state),
    isFetchingOrders: ordersSelectors.isFetchingOrders(state),
    activeOrdersCount: ordersSelectors.activeOrdersCount(state),
    filteredOrdersCount: ordersSelectors.filteredOrdersCount(state),
    filterFields: ordersSelectors.filterFields(state),
    activeFiltersCount: ordersSelectors.activeFiltersCount(state),
    pagination: ordersSelectors.pagination(state),
    allowedCompanies: userSelectors.allowedCompanies(state),
    ordersGridSort: ordersSelectors.ordersGridSort(state),
    ordersGridSettings: userSelectors.ordersGridSettings(state),
    userInfo: userSelectors.userInfo(state)
});

const mapDispatchToProps = (dispatch: any) => ({
    fetchOrders: () => dispatch.orders.fetchOrders(),
    setGridFilter: (text: string) => dispatch.orders.setGridFilter(text),
    setCurrentPage: (page: number) => dispatch.orders.setCurrentPage(page),
    setRowsPerPage: (rowsPerPage: number) => dispatch.orders.setRowsPerPage(rowsPerPage),
    setOrdersFilters: (filters: orderFilterFields) => dispatch.orders.setOrdersFilters(filters),
    setOrdersGridSort: (ordersGridSort?: gridSortType<order>) => dispatch.orders.setOrdersGridSort(ordersGridSort),
    bulkUpdateOrdersState: (payload: bulkUpdateOrdersStatePayload) => dispatch.orders.bulkUpdateOrdersState(payload),
    bulkFollow: (payload: bulkFollowPayload) => dispatch.orders.bulkFollow(payload),
    bulkUnfollow: (payload: bulkUnfollowPayload) => dispatch.orders.bulkUnfollow(payload),
    bulkDeleteOrders: (payload: bulkDeleteOrdersPayload) => dispatch.orders.bulkDeleteOrders(payload)
});

export default connect(mapStateToProps, mapDispatchToProps)(OrdersPageConnected);

type CircleProps = {
    color: string;
};

const Circle = styled.div<CircleProps>`
    width: 11px;
    height: 11px;
    border-radius: 50%;
    background-color: ${(props) => props.color};
    border: 1px solid ${(props) => props.color};
    margin: 0 3px;
`;
