import React, { PropsWithChildren, ReactNode, useEffect } from 'react';
import Header from './Header/Header';
import SideBar from './SideBar/SideBar';
import ContactsSideBar from './ContactsSideBar/ContactsSideBar';
import { styled } from '../Theme/theme';
import UserMenuConnected from '../UserMenu/UserMenuConnected';
import LayoutLoadingNotify from './LayoutLoadingNotify';
import ResponsiveDrawerConnected from '../ResponsiveDrawer/ResponsiveDrawerConnected';
import { featureFlagsModel } from '../../../models/entities/businessPartner/settings';
import useWindowSize from '../../../hooks/windowSize';
import { useHistory } from 'react-router';
import { PagesRoutes } from '../../../routing/PagesRoutes';
import { connect } from 'react-redux';
import { RootState } from '../../../state/store/store';
import { applicationSelectors } from '../../../state/ducks/application/selectors';

type Props = {
    printMode?: boolean;
    featureFlags?: featureFlagsModel;
    setIsMobile: (isMobile: boolean) => any;
    fetchUserInfo: () => void;
    fetchUserMenu: () => void;
    getMyNetwork: () => void;
    getChatRequests: () => void;
    fetchNotifications: () => void;
    fetchBusinessPartners: () => void;
    isMobile: boolean;
    children?: ReactNode;
};

const Layout = ({
    printMode,
    children,
    featureFlags,
    fetchUserInfo,
    fetchUserMenu,
    fetchNotifications,
    fetchBusinessPartners,
    getMyNetwork,
    getChatRequests,
    setIsMobile,
    isMobile
}: Props) => {
    const [windowWidth] = useWindowSize();

    useEffect(() => {
        fetchUserInfo();
        fetchUserMenu();
        fetchNotifications();
        fetchBusinessPartners();
        getMyNetwork();
        getChatRequests();
    }, [fetchUserInfo, fetchUserMenu, fetchNotifications, fetchBusinessPartners, getMyNetwork, getChatRequests]);

    useEffect(() => {
        if (windowWidth <= 768) {
            setIsMobile(true);
        } else {
            setIsMobile(false);
        }
    }, [windowWidth]);

    const history = useHistory();

    return (
        <LayoutContainer>
            <HeaderWrapper printMode={printMode}>
                <Header />
            </HeaderWrapper>
            <UserMenuConnected />
            {featureFlags?.CHAT && !history.location.pathname.includes(PagesRoutes.Messages) && !printMode && windowWidth >= 768 && (
                <ContactsSideBar></ContactsSideBar>
            )}
            <ResponsiveDrawerConnected>
                <SideBar printMode={printMode}>
                    <ContentWrapper printMode={printMode}>
                        {children}
                        <LayoutLoadingNotify />
                    </ContentWrapper>
                </SideBar>
            </ResponsiveDrawerConnected>
        </LayoutContainer>
    );
};
const HeaderWrapper = styled.div<{ printMode?: boolean }>`
    ${(props) => (props.printMode ? 'visibility:hidden;' : '')}
    height: 64px;
    background-color: #fff;
    display: block;
    width: 100%;
    text-align: left;
    box-sizing: border-box;
    -webkit-backface-visibility: hidden;

    position: fixed;

    box-shadow: 0 0 9px 0 rgba(0, 0, 0, 0.2);
    z-index: 1004;

    @media screen and (max-width: 768px) {
        left: 0;
        width: 100%;
    }
`;

const LayoutContainer = styled.div`
    height: 100%;
`;
const ContentWrapper = styled.div<{ printMode?: boolean }>`
    width: 100%;
    overflow-y: ${(props) => (props.printMode ? 'initial' : 'overlay')};
    z-index: 50;
    height: calc(100% - 64px);
    margin-left: auto;
    @media screen and (max-width: 768px) {
        position: fixed;
        width: 100%;
        height: calc(100% - 40px - 64px);
    }
`;

const mapStateToProps = (state: RootState) => ({
    isMobile: applicationSelectors.isMobile(state)
});

const mapDispatchToProps = (dispatch: any) => ({
    fetchUserInfo: () => dispatch.user.fetchUserInfo(),
    fetchUserMenu: () => dispatch.user.fetchUserMenu(),
    getMyNetwork: () => dispatch.chats.getMyNetwork(),
    getChatRequests: () => dispatch.chats.getChatRequests(),
    fetchNotifications: () => dispatch.notifications.fetchNotifications(),
    fetchBusinessPartners: () => dispatch.businessPartners.fetchBusinessPartners(),
    setIsMobile: (isMobile: boolean) => dispatch.application.setIsMobile(isMobile)
});

export default connect(mapStateToProps, mapDispatchToProps)(Layout);
