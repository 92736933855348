import React from 'react';
import styled from 'styled-components';
import { userInfo } from '../../../../../models/entities/user';
import Grid, { gridSortType } from '../../../../shared/Grid/Grid';
import usersGridColumns from './usersGridColumns';
import { drawers } from '../../../../../state/ducks/header/header';
import Loader from '../../../../shared/SmallComponents/Loader';
type Props = {
    usersGridData: Array<userInfo>;
    showLoader: boolean;
    localization?: any;
    setDrawerOpen: (open: drawers, params?: any) => void;
    setUsersGridSort: (sort?: gridSortType<userInfo>) => void;
    usersGridSort?: gridSortType<userInfo>;
};

const UsersMgmtGrid = ({ usersGridData, showLoader, localization, setDrawerOpen, setUsersGridSort, usersGridSort }: Props) => {
    return showLoader ? (
        <Loader />
    ) : (
        <Wrapper>
            <Grid<userInfo>
                columns={usersGridColumns(setDrawerOpen)}
                data={usersGridData}
                localization={localization.grid}
                emptyStateText={'localization.grid.emptyState'}
                onRowClick={(row) => setDrawerOpen(`ADD_EDIT_USER${row.id || ''}`, { userId: row.id })}
                colorRowsBy={(row) => (!!row.deletedAt ? '#ffeeed' : undefined)}
                setGridSort={setUsersGridSort}
                gridSort={usersGridSort}
            />
        </Wrapper>
    );
};

const Wrapper = styled.div`
    width: 100%;
    height: 100%;

    .MuiDataGrid-root {
        border: none;
    }
    .MuiDataGrid-root .MuiDataGrid-window {
        overflow-y: overlay !important;
    }
`;

export default UsersMgmtGrid;
