import { RootState } from '../../store/store';

const companiesRelations = (state: RootState) => state.companiesRelations.relations;
const companiesRelationRequests = (state: RootState) => state.companiesRelations.relationRequests;

export { default as companiesRelationsSelectors } from './selectors';

export default {
    companiesRelations,
    companiesRelationRequests
};
