import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { RouteComponentProps } from 'react-router-dom';
import { businessPartner } from '../../../../models/entities/businessPartner/businessPartner';
import { createOrderPayload, updateOrderPayload } from '../../../../models/entities/order/payloads';
import { businessPartnersSelectors } from '../../../../state/ducks/businessPartner/selectors';
import { localizationSelectors } from '../../../../state/ducks/localization/selectors';
import { userSelectors } from '../../../../state/ducks/user/selectors';
import { Option } from '../../../shared/inputs/base/DropDown';
import { RootState } from '../../../../state/store/store';
import OrderForm from './OrderForm';
import { order } from '../../../../models/entities/order';
import { singleOrderSelectors } from '../../../../state/ducks/singleOrder/selectors';
import Loader from '../../../shared/SmallComponents/Loader';

type PathParams = {
    orderId: string;
};

type Props = RouteComponentProps<PathParams> & {
    order?: order;
    isLoadingOrder: boolean;
    allowedCompaniesDropDownOptions: Option[];
    getBusinessPartnerById: (id: string) => businessPartner | undefined;
    getBusinessPartnersDropDownOptionsByTypes: (types: string[]) => Option[];
    allowedShipmentTypes: Array<string>;
    localization: any;
    setCurrentOrderId: (currentOrderId: string | null) => void;
    fetchSingleOrder: (orderId: string) => Promise<order>;
    updateOrder: (order: updateOrderPayload) => Promise<void>;
    createOrder: (order: createOrderPayload) => Promise<void>;
};

const OrderFormConnected = ({
    order,
    isLoadingOrder,
    allowedCompaniesDropDownOptions,
    getBusinessPartnerById,
    getBusinessPartnersDropDownOptionsByTypes,
    allowedShipmentTypes,
    localization,
    match,
    setCurrentOrderId,
    fetchSingleOrder,
    updateOrder,
    createOrder
}: Props) => {
    const urlOrderId = match.params.orderId;

    useEffect(() => {
        if (urlOrderId) {
            setCurrentOrderId(urlOrderId);
            fetchSingleOrder(urlOrderId);
        }
    }, [urlOrderId, setCurrentOrderId, fetchSingleOrder]);

    if (urlOrderId && (!order || isLoadingOrder)) return <Loader />;

    return (
        <OrderForm
            order={order}
            allowedCompaniesDropDownOptions={allowedCompaniesDropDownOptions}
            getBusinessPartnerById={getBusinessPartnerById}
            getBusinessPartnersDropDownOptionsByTypes={getBusinessPartnersDropDownOptionsByTypes}
            allowedShipmentTypes={allowedShipmentTypes}
            localization={localization}
            isEdit={!!urlOrderId}
            updateOrder={updateOrder}
            createOrder={createOrder}
        />
    );
};

const mapStateToProps = (state: RootState) => ({
    localization: localizationSelectors.ordersPage(state),
    order: singleOrderSelectors.order(state),
    isLoadingOrder: singleOrderSelectors.isLoadingOrder(state),
    allowedCompaniesDropDownOptions: userSelectors.allowedCompaniesDropDownOptions(state),
    getBusinessPartnerById: (id: string) => businessPartnersSelectors.getById(state, id),
    getBusinessPartnersDropDownOptionsByTypes: (types: string[]) => businessPartnersSelectors.getBusinessPartnersDropDownOptionsByTypes(state)(types),
    allowedShipmentTypes: userSelectors.allowedTypes(state)
});

const mapDispatchToProps = (dispatch: any) => ({
    setCurrentOrderId: (currentOrderId: string | null) => dispatch.singleOrder.setCurrentOrderId(currentOrderId),
    fetchSingleOrder: (orderId: string) => dispatch.orders.fetchOrderById(orderId),
    createOrder: (order: createOrderPayload) => dispatch.orders.createOrder(order),
    updateOrder: (payload: updateOrderPayload) => dispatch.orders.updateOrder(payload)
});

export default connect(mapStateToProps, mapDispatchToProps)(OrderFormConnected);
