import React, { useState } from 'react';
import { Popover } from 'antd';
import Button from '../../../../shared/SmallComponents/Button';
import filterIcon from '../../../../../static/icons/filter.svg';
import FilterContent from './FilterContent';
import { connect } from 'react-redux';
import { allShipmentsPageSelectors } from '../../../../../state/ducks/allShipmentsPage/selectors';
import { RootState } from '../../../../../state/store/store';
import { styled } from '../../../../shared/Theme/theme';
import { settings } from '../../../../../models/entities/businessPartner/businessPartner';
import Tooltip from '@material-ui/core/Tooltip';

type Props = {
    activeFiltersCount: number;
    settings?: settings;
    localization: any;
};
const FilterPopover = ({ activeFiltersCount, settings, localization }: Props) => {
    const [visible, setVisible] = useState(false);
    return (
        <Popover
            overlayClassName="custom-popover"
            placement="bottomRight"
            content={<FilterContent onCancel={() => setVisible(false)} settings={settings} />}
            trigger="click"
            visible={visible}
            onVisibleChange={setVisible}
        >
            <ButtonContainer>
                <Button width="32px" height="32px" borderRadius="50%" margin="7px" buttonType="hollow">
                    <Tooltip title={localization.filter_btn} aria-label="filter">
                        <span className="material-icons-outlined">filter_alt</span>
                    </Tooltip>
                </Button>
                <Badge>{activeFiltersCount}</Badge>
            </ButtonContainer>
        </Popover>
    );
};
const ButtonContainer = styled.div`
    position: relative;
    .filter-label {
        @media screen and (max-width: 768px) {
            display: none;
        }
    }
`;
const Badge = styled.span`
    position: absolute;
    top: 3px;
    right: 0;
    background: ${(props) => props.theme.colors.primaryBlue};
    padding: 3px;
    height: 15px;
    width: 15px;
    border-radius: 50%;
    font-size: 10px;
    line-height: 9px;
    color: white;
    text-align: center;
`;
const mapStateToProps = (state: RootState) => ({
    activeFiltersCount: allShipmentsPageSelectors.activeFiltersCount(state)
});

const mapDispatchToProps = (dispatch: any) => ({});

export default connect(mapStateToProps, mapDispatchToProps)(FilterPopover);
