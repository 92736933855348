import React, { Component } from 'react';
import { Draggable, DraggableProvided } from 'react-beautiful-dnd';
import { Column, IBoardItem } from './types/BoardColumn';

type Props<T extends IBoardItem> = {
    column: Column<T>;
    renderItem: (item: T, isDragging: boolean, provided: DraggableProvided) => JSX.Element;
};

class BoardColumnList<T extends IBoardItem> extends Component<Props<T>> {
    render() {
        const { column, renderItem } = this.props;
        return column.items.map((item: T, index: number) => {
            return (
                <Draggable key={item.id} draggableId={item.id} index={index}>
                    {(provided: DraggableProvided, snapshot) => {
                        return renderItem(item, snapshot.isDragging, provided);
                    }}
                </Draggable>
            );
        });
    }
}

export default BoardColumnList;
