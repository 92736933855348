import React from 'react';
import { connect } from 'react-redux';
import { RootState } from '../../state/store/store';
import { localizationSelectors } from '../../state/ducks/localization/selectors';

type Props = {
    localizations: any;
};
const Calendar = ({ localizations }: Props) => {
    return <div>{localizations.title}</div>;
};

const mapStateToProps = (state: RootState) => ({
    localizations: localizationSelectors.calenderPage(state)
});

const mapDispatchToProps = (dispatch: any) => ({});

export default connect(mapStateToProps, mapDispatchToProps)(Calendar);
