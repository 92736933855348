import React, { useState } from 'react';
import { userSettingDefinition } from '../../../../models/entities/user';
import { styled } from '../../../shared/Theme/theme';
import { Checkbox, TextArea } from '../../../shared/inputs/base';
import Button from '../../../shared/SmallComponents/Button';
import Loader from '../../../shared/SmallComponents/Loader';

type Props = {
    setUserSettings: (key: string, value: any, id?: number) => Promise<boolean>;
    item: userSettingDefinition;
    localization: any;
};

const UserSettingsItem = ({ item, setUserSettings, localization }: Props) => {
    const [value, setValue] = useState(item.value);
    const [changed, setChanged] = useState(false);
    const [loader, setLoader] = useState(false);
    const handleChange = (value: any) => {
        setValue(value);
        setChanged(true);
    };
    const handleSubmit = (key: string, value: any, id: number) => {
        setLoader(true);
        setUserSettings(key, value, id)
            .then(() => {
                setChanged(false);
            })
            .finally(() => {
                setLoader(false);
            });
    };
    const renderField = () => {
        const WrapComp = (children: JSX.Element | JSX.Element[]) => <div style={{ flex: 0.5 }}>{children}</div>;
        switch (item.type) {
            case 'boolean':
                return <Checkbox checked={value} onChange={(e) => handleChange(e.target.checked)}></Checkbox>;
            default:
                return WrapComp(<TextArea value={value} onChange={(value: string) => handleChange(value)} />);
        }
    };
    return (
        <Item>
            <div>{item.key}</div>
            {renderField()}
            <Button disabled={!changed} buttonType="filled" onClick={() => handleSubmit(item.key, value, item.id)}>
                {loader ? (
                    <Loader width="20px" marginTop="0px" showText={false} />
                ) : (
                    <span>{item.id ? localization.user_settings.update_btn : localization.user_settings.create_btn}</span>
                )}
            </Button>
        </Item>
    );
};

const Item = styled.div`
    background: #f7f7f7;
    border-bottom: 1px solid #ececec;
    display: flex;
    align-items: center;
    padding: 20px;
    justify-content: space-between;
    border-radius: 5px;
`;
export default UserSettingsItem;
