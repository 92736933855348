import React, { useState } from 'react';
import { bid } from '../../../../../models/entities/bid/bid';
import { styled } from '../../../../shared/Theme/theme';
import Button from '../../../../shared/SmallComponents/Button';
import { BidFormModal } from '../../../singleShipment/BidForm/ShipmentBidFormConnected';
import { GridColDef, GridValueGetterParams } from '@material-ui/data-grid';
import { ModalPreviewFileAsync } from '../../../../shared/FilesPreview/ModalPreview';
import FileExtIcon from '../../../../shared/Grid/specialColumns/FileExtIcon';
import moment from 'moment';
import { currencyFormatter } from '../../../../shared/inputs/form/CurrencyInput/CurrencyInput';
import { shipment } from '../../../../../models/entities/shipment/shipment';
import Loader from '../../../../shared/SmallComponents/Loader';
import { eBidShipmentTypes } from '../../../singleShipment/BidForm/ShipmentBidForm';
import { drawers } from '../../../../../state/ducks/header/header';
import { eQuotationState } from '../../../../../models/entities/bid/quotation';
import MuiDataGrid from '../../../../shared/MuiDataGrid/MuiDataGrid';
import _ from 'lodash';
import { shipmentCalcWeightVolume } from '../../../singleShipment/ShipmentForm/sections/Packages/util';
import TariffQuotationsGrid from '../../../singleShipment/sections/TariffQuotations/TariffQuotationsGrid';
import { featureFlagsModel } from '../../../../../models/entities/businessPartner/settings';

type Props = {
    localization: any;
    shipment: shipment;
    featureFlags?: featureFlagsModel;
    updateBid?: (id: string, bid: bid, shipment: shipment, publish: boolean) => Promise<boolean>;
    createBid?: (shipment: shipment, bid: bid, publish: boolean) => Promise<string>;
    startBid?: (id: string, shipmentId: string, shipment: shipment) => Promise<void>;
    setDrawerOpen: (open: drawers, params?: any) => void;
};

const approveTableStyles = {
    '&.MuiDataGrid-root .MuiDataGrid-columnsContainer': {
        borderBottom: 'none',
        minHeight: '30px!important',
        maxHeight: '30px!important',
        lineHeight: '30px!important'
    },
    '&.MuiDataGrid-root .MuiDataGrid-colCell': {
        fontSize: '12px',
        color: '#b2b2b2'
    },
    '&.MuiDataGrid-root .MuiDataGrid-cell': {
        borderBottom: 'none'
    },
    '&.MuiDataGrid-root .MuiDataGrid-windowContainer': {
        height: '80px!important'
    },
    '&.MuiDataGrid-root .MuiDataGrid-window': {
        top: '30px!important'
    }
};

function Quotations({ localization, updateBid, startBid, createBid, shipment, setDrawerOpen, featureFlags }: Props) {
    const [publishLoader, setPublishLoader] = useState(false);
    const { bid } = shipment;

    if (!bid && shipment.state === 'DRAFT') {
        return (
            <EmptyStateText>
                {localization.empty_state}
                {!shipment.forwarderId ? (
                    <BidFormModal
                        shipment={shipment}
                        onPublishCallBack={async (createdBid, publish) => {
                            if (createBid) await createBid(shipment, { ...createdBid, shipmentId: shipment.id }, publish);
                        }}
                        Trigger={(props) => (
                            <Button onClick={props.onClick} width="auto" height="30px">
                                {localization.create_btn}
                            </Button>
                        )}
                    />
                ) : (
                    <span>{localization.forwarder_exist}</span>
                )}
            </EmptyStateText>
        );
    } else if (!bid) return <EmptyStateText>{localization.state_is_not_draft}</EmptyStateText>;
    const { quotations } = bid;

    let fastestQuotationId: number, cheapestQuotationId: number;
    if (quotations && quotations.length > 0) {
        fastestQuotationId =
            _.minBy(quotations, function (q) {
                return q.transitTime;
            })?.id || 0;
        cheapestQuotationId =
            _.minBy(quotations, function (q) {
                return Number(q.freightPrice);
            })?.id || 0;
    }
    const mockColumns: GridColDef[] = [
        {
            field: 'forwarderName',
            headerName: localization.quotations.grid.columns.forwarder,
            flex: 1,
            renderCell: (params) => <div style={{ color: '#008AC9' }}>{params.getValue(params.id, 'forwarderName')}</div>
        },
        { field: 'contactName', headerName: 'Representative', flex: 1 },
        {
            field: 'shipmentTypeFormat',
            headerName: localization.quotations.grid.columns.shipment,
            flex: 1,
            valueGetter: (params) => {
                const shipmentType = params.row?.shipmentType;
                return shipmentType ? eBidShipmentTypes[shipmentType] : '-';
            }
        },
        {
            field: 'transitTime',
            headerName: localization.quotations.grid.columns.transit_time,
            flex: 1,
            renderCell: (params) => {
                const transitTime = params.getValue(params.id, 'transitTime')?.toString();
                if (!transitTime) return <span>-</span>;
                return (
                    <FlexCenter>
                        <span>{transitTime}d</span>
                        {fastestQuotationId === params.row.id && <IconStyled className="material-icons">bolt</IconStyled>}
                    </FlexCenter>
                );
            }
        },
        {
            field: 'totalPrice',
            headerName: localization.quotations.grid.columns.total_price,
            flex: 1,
            sortComparator: (_v1, _v2, param1, param2) =>
                param1.api.getCellValue(param1.id, 'freightPrice') - param2.api.getCellValue(param2.id, 'freightPrice'),
            renderCell: (params) => {
                if (!params.row.freightPrice) return <span>-</span>;
                const totalPrice = `${currencyFormatter(params.row?.freightPriceCurrency)(params.row.freightPrice)}`;
                return (
                    <FlexCenter>
                        <span>{totalPrice}</span>
                        {cheapestQuotationId === params.row.id && <IconStyled className="material-icons">paid</IconStyled>}
                    </FlexCenter>
                );
            }
        },
        {
            field: 'validTillFormat',
            headerName: localization.quotations.grid.columns.valid_till,
            flex: 1,
            valueGetter: (params) => `${moment(params.row.validTill).isValid() ? moment(params.row.validTill).format('DD/MM/YY') : '-'}`
        },
        {
            field: 'pricePerUnit',
            headerName: localization.quotations.grid.columns.price_per_unit,
            flex: 1,
            valueFormatter: (params) => {
                const freightPrice = params.getValue(params.id, 'freightPrice');

                const { chargeableWeight } = shipmentCalcWeightVolume(shipment);

                if (!Number(freightPrice) || !Number(chargeableWeight)) return '-';

                const pricePerCBM = `${currencyFormatter(params.row?.freightPriceCurrency)(
                    (Number(freightPrice) / Number(chargeableWeight)).toFixed(3)
                )}`;

                return `${pricePerCBM}`;
            }
        },
        {
            field: 'state',
            headerName: localization.quotations.grid.columns.status,
            flex: 1,
            valueFormatter: (params) => {
                const state = params.getValue(params.id, 'state')?.toString();
                if (!state) return '-';
                return `${eQuotationState[state]}`;
            }
        },
        {
            field: 'fileExtIcon',
            headerName: localization.quotations.grid.columns.notes,
            flex: 2,
            renderCell: (params: GridValueGetterParams) => {
                const fileName = params.row.fileName;
                const fileId = params.row.fileId;
                if (!fileName || !fileId) return <span>-</span>;
                return <ModalPreviewFileAsync triggerChildren={<FileExtIcon filename={fileName} />} fileId={fileId} fileName={fileName} />;
            }
        }
    ];

    return (
        <BidsWrapper>
            <>
                {bid.state !== 'CLOSED' ? (
                    <HeaderContainer>
                        <LeftSide>
                            <b>
                                {localization.bid} #{bid.CargoZoneNumber}
                            </b>
                            {bid.state === 'NEW' && <StateDraft>{localization.draft}</StateDraft>}
                        </LeftSide>
                        <RightSide>
                            <Buttons>
                                {bid.state === 'NEW' ? (
                                    <>
                                        <BidFormModal
                                            shipment={shipment}
                                            onPublishCallBack={async (updatedBid, publish) => {
                                                if (updateBid) await updateBid(bid.id, updatedBid, shipment, publish);
                                            }}
                                            bid={bid}
                                            Trigger={(props) => (
                                                <Button onClick={props.onClick} width="auto" height="30px">
                                                    {localization.edit_btn}
                                                </Button>
                                            )}
                                        />

                                        <Button
                                            margin="0 0 0 5px"
                                            buttonType="filled"
                                            width="auto"
                                            height="30px"
                                            onClick={async () => {
                                                if (startBid) {
                                                    setPublishLoader(true);
                                                    startBid(bid.id, bid.shipmentId, shipment).finally(() => {
                                                        setPublishLoader(false);
                                                    });
                                                }
                                            }}
                                        >
                                            {publishLoader ? (
                                                <Loader width="20px" marginTop="0px" showText={false} />
                                            ) : (
                                                <span>{localization.publish_btn}</span>
                                            )}
                                        </Button>
                                    </>
                                ) : (
                                    <BidFormModal
                                        shipment={shipment}
                                        bid={bid}
                                        Trigger={(props) => (
                                            <Button onClick={props.onClick} width="auto" height="30px">
                                                {localization.view_btn}
                                            </Button>
                                        )}
                                        onPublishCallBack={async (updatedBid, publish) => {
                                            if (updateBid) await updateBid(bid.id, updatedBid, shipment, publish);
                                        }}
                                        hideSaveAsDraft
                                        disabled
                                    />
                                )}
                            </Buttons>
                        </RightSide>
                    </HeaderContainer>
                ) : (
                    <ApprovedQuotation>
                        <ApprovedHeader>{localization.selected_offer}</ApprovedHeader>
                        <MuiDataGrid
                            rootStyles={approveTableStyles}
                            rows={quotations?.filter((item) => item.state === 'APPROVED') || []}
                            columns={mockColumns}
                            disableSelectionOnClick
                            disableColumnSelector
                            autoPageSize
                            hideFooter
                            autoHeight
                            disableColumnMenu
                            onRowClick={(params) => setDrawerOpen('QUOTATION', { quotation: params.row, shipmentId: shipment.id, bid: bid })}
                        />
                    </ApprovedQuotation>
                )}
                {quotations && quotations.length > 0 ? (
                    <GridWrapper>
                        <MuiDataGrid
                            rows={quotations}
                            columns={mockColumns}
                            disableSelectionOnClick
                            disableColumnSelector
                            autoPageSize
                            hideFooter
                            autoHeight
                            onRowClick={(params) => setDrawerOpen('QUOTATION', { quotation: params.row, shipmentId: shipment.id, bid: bid })}
                        />
                    </GridWrapper>
                ) : (
                    <EmptyStateText>{localization.quotations.empty_state}</EmptyStateText>
                )}
                {featureFlags?.TARIFF && (
                    <GridWrapper>
                        <TariffQuotationsGrid setDrawerOpen={setDrawerOpen} shipment={shipment} />
                    </GridWrapper>
                )}
            </>
        </BidsWrapper>
    );
}

const HeaderContainer = styled.div`
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding-top: 10px;
    padding-bottom: 10px;
`;

const LeftSide = styled.div`
    color: ${(props) => props.theme.colors.pageTitle};
`;
const StateDraft = styled.span`
    background: ${(props) => props.theme.colors.grayDefaultBorder};
    border-radius: 20px;
    height: 24px;
    font-size: 12px;
    margin-left: 7px;
    padding: 4px;
`;
const RightSide = styled.div``;
const Buttons = styled.div`
    display: flex;
`;

const GridWrapper = styled.div`
    flex: 0 0 100%;
    max-width: 100%;
    width: 100%;
    box-sizing: border-box;
    overflow-y: auto;
    height: 300px;
    width: 100%;
    max-height: 300px;
`;

const BidsWrapper = styled.div`
    display: flex;
    flex-direction: column;
    width: 100%;
    padding: 10px;
`;

const EmptyStateText = styled.div`
    height: 200px;
    color: ${(props) => props.theme.colors.pageTitle};
    background-color: #f9f9f9;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 10px;
    flex-direction: column;
`;
const ApprovedQuotation = styled.div`
    border: 1px solid #158cc6;
    box-sizing: border-box;
    border-radius: 8px;
    min-height: 100px;
    margin: 20px 0;
    box-shadow: 0px 2px 4px rgb(0 0 0 / 15%);
`;
const ApprovedHeader = styled.div`
    border-top-left-radius: 8px;
    border-top-right-radius: 8px;
    font-size: 16px;
    font-weight: bold;
    background: rgba(0, 138, 201, 0.1);
    color: #158cc6;
    padding: 8px 19px;
`;
const FlexCenter = styled.div`
    display: flex;
    align-items: center;
`;
const IconStyled = styled.span`
    color: #158cc6;
    margin-left: 5px;
    font-size: 15px;
`;
export default Quotations;
