import React from 'react';
import PricingTable from '../../../shared/PricingTable/PricingTable';
import pricingTableData from './pricingTableData.json';
type Props = {
    onSelectPlan: (pricingPlanId: string) => void;
};
const Pricing = ({ onSelectPlan }: Props) => {
    return <PricingTable onSelectPlan={onSelectPlan} data={pricingTableData} />;
};

export default Pricing;
