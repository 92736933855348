import BaseController from '../..';
import { document } from '../../../../models/entities/businessPartner/document';
import IBusinessPartnersDocuments from './IBusinessPartnersDocuments';

export default class BusinessPartnersDocuments extends BaseController implements IBusinessPartnersDocuments {
    constructor(baseUrl: string) {
        super(baseUrl);
    }
    async createMultipleBusinessPartnerDocument(businessPartnerId: string, data: Array<document>) {
        this.client.setBodyType('FORM-DATA');
        const response = await this.client.post(`/businessPertner/documents/upload-multiple`, this.convertToArrayFormData(data, businessPartnerId));
        return response;
    }
    async createBusinessPartnerDocument(businessPartnerId: string, data: document) {
        this.client.setBodyType('FORM-DATA');
        const response = await this.client.post(`/businessPertner/documents/upload`, this.convertToFormData(data, businessPartnerId));
        return response;
    }
    async updateBusinessPartnerDocument(businessPartnerId: string, data: document) {
        this.client.setBodyType('FORM-DATA');
        const response = await this.client.put(`/businessPertner/documents`, this.convertToFormData(data, businessPartnerId));
        return response;
    }
    async deleteBusinessPartnerDocument(businessPartnerId: string, documentId: number) {
        const response = await this.client.delete(`/businessPertner/documents`, JSON.stringify({ businessPartnerId, id: documentId }));
        return response;
    }
    private convertToFormData(document: document, businessPartnerId: string): FormData {
        const formData = new FormData();
        formData.append('businessPartnerId', businessPartnerId.toString());
        formData.append('type', document.type);
        formData.append('comment', document.comment);
        formData.append('fileName', document.fileName);
        formData.append('fileType', document.fileType);
        if (document.expDate) formData.append('expDate', JSON.stringify(document.expDate));
        if (document.file) formData.append('file', document.file);
        if (document.id) formData.append('id', '' + document.id);
        return formData;
    }
    private convertToArrayFormData(documents: Array<document>, businessPartnerId: string): FormData {
        const formData = new FormData();
        formData.append('businessPartnerId', businessPartnerId.toString());
        documents.forEach((document) => {
            formData.append('type[]', document.type);
            formData.append('comment[]', document.comment);
            formData.append('fileName[]', document.fileName);
            formData.append('fileType[]', document.fileType);
            if (document.expDate) formData.append('expDate[]', JSON.stringify(document.expDate));
            if (document.file) formData.append('file[]', document.file);
            if (document.id) formData.append('id[]', '' + document.id);
        });
        return formData;
    }
}
