import React, { useState } from 'react';
import { styled } from '../../../../shared/Theme/theme';
import { Form, Formik } from 'formik';
import { localizationSelectors } from '../../../../../state/ducks/localization/selectors';
import { RootState } from '../../../../../state/store/store';
import { connect } from 'react-redux';
import Button from '../../../../shared/SmallComponents/Button';
import { ModalFooter, ModalFormContainer } from '../../../../shared/Modal/Modal';
import Loader from '../../../../shared/SmallComponents/Loader';
import { attribute } from '../../../../../models/entities/product/attribute';
import { FormTextInput } from '../../../../shared/inputs/form';
import FieldLabel from '../../../../shared/SmallComponents/FieldLabel';

export enum formMode {
    NEW,
    EDIT
}

interface formFields {
    name?: string;
}
type Props = {
    attribute?: attribute;
    mode: formMode;
    localizations?: any;
    openedFromModal?: {
        setVisible: React.Dispatch<React.SetStateAction<boolean>>;
    };
    createProductAttribute: (productId: string, attribute: attribute) => Promise<boolean>;
    updateProductAttribute: (productId: string, attribute: attribute) => Promise<boolean>;
    productId: string;
};

const AttributeFormConnected = ({
    productId,
    attribute,
    localizations,
    mode,
    openedFromModal,
    createProductAttribute,
    updateProductAttribute
}: Props) => {
    const [submitLoading, setLoading] = useState(false);
    let initFormValues: formFields = {
        name: ''
    };
    const setInitialValues = () => {
        if (!attribute || mode === formMode.NEW) {
            return initFormValues;
        } else {
            initFormValues.name = attribute.name;
            return initFormValues;
        }
    };
    const validate = (values: any) => {
        const errors: any = {};
        if (!values.name) errors.name = localizations.attributes.form.required_error;

        const maxLengthValidation = (field: string, maxLength: number, message: string) => {
            if (values[field] && values[field].length > maxLength) {
                errors[field] = message;
            }
        };

        maxLengthValidation('name', 100, localizations.attributes.form.name_max_length_error);
        return errors;
    };
    const submit = (values: any) => {
        const payload: attribute = {
            name: values.name
        } as attribute;
        setLoading(true);
        if (mode === formMode.NEW)
            createProductAttribute(productId, payload)
                .then((value) => {
                    if (value) {
                        // api success
                        if (openedFromModal) openedFromModal.setVisible(false);
                    }
                })
                .finally(() => {
                    setLoading(false);
                });
        else if (attribute) {
            updateProductAttribute(productId, {
                ...payload,
                id: attribute.id
            })
                .then((value) => {
                    if (value) {
                        // api success
                        if (openedFromModal) openedFromModal.setVisible(false);
                    }
                })
                .finally(() => {
                    setLoading(false);
                });
        }
    };
    return (
        <Formik initialValues={setInitialValues()} validate={validate} onSubmit={submit}>
            {({ values, errors, handleSubmit }) => {
                return (
                    <Form>
                        <Container>
                            <FieldLabel>{localizations.attributes.form.name}</FieldLabel>
                            <FieldContainer>
                                <FormTextInput name="name" placeHolder={localizations.attributes.form.name} />
                            </FieldContainer>
                        </Container>
                        {openedFromModal && (
                            <FormFooter>
                                <Button
                                    minWidth="55px"
                                    buttonType="filled"
                                    type="button"
                                    onClick={() => {
                                        handleSubmit();
                                    }}
                                >
                                    {submitLoading ? (
                                        <Loader width="20px" marginTop="0px" showText={false} />
                                    ) : (
                                        <span>
                                            {mode === formMode.NEW ? localizations.attributes.modal.add_btn : localizations.attributes.modal.edit_btn}
                                        </span>
                                    )}
                                </Button>
                            </FormFooter>
                        )}
                    </Form>
                );
            }}
        </Formik>
    );
};
const FieldContainer = styled.div`
    margin-bottom: 12px;
`;

const Container = styled(ModalFormContainer)``;

const FormFooter = styled(ModalFooter)`
    position: absolute;
    bottom: 0;
    width: 100%;
    left: 0;
    right: 0;
    background: white;
    justify-content: flex-end;
`;
const mapStateToProps = (state: RootState) => ({
    localizations: localizationSelectors.products(state)
});

const mapDispatchToProps = (dispatch: any) => ({
    createProductAttribute: (productId: string, attribute: attribute) => dispatch.products.createProductAttribute({ productId, attribute }),
    updateProductAttribute: (productId: string, attribute: attribute) => dispatch.products.updateProductAttribute({ productId, attribute })
});

export default connect(mapStateToProps, mapDispatchToProps)(AttributeFormConnected);
