import React from 'react';
import { Option } from '../../base/DropDown';
import { NumberUnitInput } from '..';
import currencyList from './currencyList';
import { NumberProps, UnitProps } from '../NumberUnitInput';
import getSymbolFromCurrency from 'currency-symbol-map';

type Props = { currencyValue: string } & NumberProps & Omit<UnitProps, 'unitOptions'>;

const locale = 'en-us';

export const currencyFormatter = (selectedCurrOpt: any) => (value: any) => {
    let newVal = value ? value.toString() : '0';
    if (newVal[newVal.length - 1] === '.' && newVal.slice(0, -1).includes('.')) newVal = newVal.slice(0, -1);
    const decinmalDigits = newVal.split('.')[1] ? `.${newVal.split('.')[1]}` : newVal[newVal.length - 1] === '.' ? '.' : '';
    const formattedNum = new Intl.NumberFormat(locale, {
        style: 'currency',
        currency: selectedCurrOpt || 'USD',
        minimumFractionDigits: 0,
        maximumFractionDigits: 10
    }).format(value);
    return Number(newVal) ? (formattedNum.split('.')[1] ? formattedNum : formattedNum + decinmalDigits) : newVal;
};

export const currencyOptions: Array<Option> = currencyList.data.map((c) => ({
    text: getSymbolFromCurrency(c.Ccy) || c.Ccy || c.CcyNm,
    value: c.Ccy || c.CcyNm
}));

const CurrencyInput = ({ currencyValue, ...props }: Props) => {
    return (
        <NumberUnitInput
            min={0}
            step={1}
            unitPlaceholder="Currency"
            unitInputWidth="55px"
            {...props}
            numberFormatter={currencyFormatter(currencyValue || 'USD')}
            unitOptions={currencyOptions}
        />
    );
};

export default CurrencyInput;
