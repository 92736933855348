import React from 'react';
import styled from 'styled-components';
import { shipment } from '../../../models/entities/shipment/shipment';
import countries from '../../../utils/countries-flags/countries';
import CZTooltip from '../CZTooltip/CZTooltip';
import { RightArrowIcon } from '../Grid/CellRenderer/CellRenderer';
import ShipmentStation from '../Grid/specialColumns/ShipmentStation';
import stations from '../../../static/data/stations.json';
import { shipment_po } from '../../../models/entities/shipment_po';

interface IHaveRoute {
    originCountry: string;
    originStation: string;
    destinationCountry: string;
    destinationStation: string;
    originStationName?: string;
    destinationStationName?: string;
}
type Props = {
    entity: IHaveRoute;
    flagSize: string;
    fontWeight?: string;
    fontSize?: string;
    arrowStyle?: React.CSSProperties;
};
const ShipmentRoute = ({ entity, flagSize, fontWeight, fontSize, arrowStyle }: Props) => {
    const originCountryName = countries.find((item) => item.code === entity.originCountry)?.name;
    const destinationCountryName = countries.find((item) => item.code === entity.destinationCountry)?.name;

    const originStationName = entity.originStationName
        ? entity.originStationName
        : stations.find((s) => s.code === entity.originStation && s.country === entity.originCountry)?.location;

    const destinationStationName = entity.destinationStationName
        ? entity.destinationStationName
        : stations.find((s) => s.code === entity.destinationStation && s.country === entity.destinationCountry)?.location;

    return (
        <Container>
            <CZTooltip text={(originStationName ? originStationName + ', ' : '') + originCountryName}>
                <span>
                    <ShipmentStation
                        country={entity.originCountry}
                        port={entity.originStation}
                        flagSize={flagSize}
                        portFontWeight={fontWeight}
                        portFontSize={fontSize}
                    ></ShipmentStation>
                </span>
            </CZTooltip>
            <RightArrowIcon style={arrowStyle || { margin: '10px 15px' }} />
            <CZTooltip text={(destinationStationName ? destinationStationName + ', ' : '') + destinationCountryName}>
                <span>
                    <ShipmentStation
                        country={entity.destinationCountry}
                        port={entity.destinationStation}
                        flagSize={flagSize}
                        portFontWeight={fontWeight}
                        portFontSize={fontSize}
                    />
                </span>
            </CZTooltip>
        </Container>
    );
};

const Container = styled.div`
    display: flex;
    flex-direction: row;
`;

export default ShipmentRoute;
