import { ModelConfig } from '@rematch/core';
import { shipment } from '../../../models/entities/shipment/shipment';
import { localSDK as client } from '../../../sdk';
import { shipmentsStatistics } from '../../../models/entities/dashboard/shipmentsStatistics';
import { ordersStatistics } from '../../../models/entities/order/ordersStatistics';

export type dashboardStateType = {
    shipmentsStatistics: shipmentsStatistics | null;
    ordersStatistics: ordersStatistics | null;
    error?: string;
    errorFetchSummary?: string;
    upcomingArrivals?: Array<shipment>;
};

export const dashboard: ModelConfig<dashboardStateType> = {
    state: {
        shipmentsStatistics: null,
        ordersStatistics: null,
        upcomingArrivals: []
    },
    reducers: {
        setShipmentsStatistics(state: dashboardStateType, shipmentsStatistics: shipmentsStatistics): dashboardStateType {
            return { ...state, shipmentsStatistics };
        },
        setOrdersStatistics(state: dashboardStateType, ordersStatistics: ordersStatistics): dashboardStateType {
            return { ...state, ordersStatistics };
        },
        setUpcomingArrivals(state: dashboardStateType, upcomingArrivals: Array<shipment>): dashboardStateType {
            return { ...state, upcomingArrivals };
        },
        setFetchSummaryError(state: dashboardStateType, error: string) {
            return { ...state, errorFetchSummary: error };
        },
        setFetchError(state: dashboardStateType, error: string) {
            return { ...state, error };
        }
    },
    effects: (dispatch: any) => ({
        async fetchShipmentsStatistics() {
            const shipmentsStatistics: shipmentsStatistics = await client.dashboard().fetchShipmentsStatistics();
            dispatch.dashboard.setShipmentsStatistics(shipmentsStatistics);
        },
        async fetchOrdersStatistics() {
            const ordersStatistics: ordersStatistics = await client.dashboard().fetchOrdersStatistics();
            dispatch.dashboard.setOrdersStatistics(ordersStatistics);
        },
        async fetchUpcomingArrivals() {}
    })
};
