import { RootState } from '../../../state/store/store';
import { createSelector } from 'reselect';
import companiesSelectors from '../companies/selectors';

const currentCompanyId = (state: RootState) => state.singleCompany.companyId;
const fetchCompanyError = (state: RootState) => state.error.effects.companies.fetchCompanyById;
const isLoadingCompany = (state: RootState) => state.loading.effects.companies.fetchCompanyById;

const company = createSelector(currentCompanyId, companiesSelectors.companies, (companyId, companies) => {
    return companies?.find((company) => company.id === companyId);
});

export { default as singleCompanySelectors } from './selectors';

const selectors = {
    company,
    fetchCompanyError,
    isLoadingCompany,
    currentCompanyId
};

export default selectors;
