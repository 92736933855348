import React from 'react';
import { FormDatePicker, FormDropDown, FormTextInput, CurrencyInput, FormCheckbox } from '../../../../shared/inputs/form';
import { styled } from '../../../../shared/Theme/theme';
import { shipmentFormLogics } from '../../util';
import { FieldContainer, renderFieldLabelByName } from '../ShipmentForm';
import { updateMaster, carriersDropDownOptionsByShipmentType, operatorDropDownOptions } from './utils';
import { partiesToDropdownOptions } from '../../../businessPartners/components/BusinessPartnerForm/BusinessPartnerFormConnected';
import { businessPartnersSelectors } from '../../../../../state/ducks/businessPartner/selectors';
import { businessPartner } from '../../../../../models/entities/businessPartner/businessPartner';

type Props = {
    values: any;
    setFieldValue: any;
    localizations: any;
    isFreeLancerOrForwarder: boolean;
    businessPartnerTruckers?: Array<businessPartner>;
};
const Billing = ({ values, setFieldValue, isFreeLancerOrForwarder, localizations, businessPartnerTruckers }: Props) => {
    const billingSectionLocalizations = localizations.form.sections.billing;
    const fieldsLocalizations = billingSectionLocalizations.fields;
    const renderFieldTitle = (name: string, isMandatory: boolean) => renderFieldLabelByName(name, isMandatory, fieldsLocalizations);

    return (
        <>
            <BillingFormSection>
                <div style={{ paddingRight: '30px' }}>
                    {shipmentFormLogics.goods_ready(values.shipment_type) && (
                        <FieldContainer>
                            <div>{renderFieldTitle('goods_ready', values.sub_type === 'FCL' && values.shipment_type === 'OI' ? true : false)}</div>
                            <div>
                                <FormDatePicker
                                    name="goods_ready"
                                    style={{ width: '200px' }}
                                    placeHolder={fieldsLocalizations.goods_ready.title}
                                    showTime
                                />
                            </div>
                        </FieldContainer>
                    )}
                    {shipmentFormLogics.master(values.shipment_type) && (
                        <FieldContainer>
                            <div>{renderFieldTitle(values.shipment_type === 'EXPRESS' ? 'awb' : 'master', false)}</div>
                            <div>
                                <FormTextInput name="master" placeHolder={billingSectionLocalizations.enter_name} style={{ width: '200px' }} />
                            </div>
                        </FieldContainer>
                    )}
                    {shipmentFormLogics.pick_up(values.shipment_type) && (
                        <FieldContainer>
                            <div>{renderFieldTitle('pick_up', false)}</div>
                            <div>
                                <FormDatePicker name="pick_up" style={{ width: '200px' }} placeHolder={fieldsLocalizations.pick_up.title} showTime />
                            </div>
                        </FieldContainer>
                    )}
                    {shipmentFormLogics.operator(values.shipment_type) && (
                        <FieldContainer>
                            <div>{renderFieldTitle('operator', false)}</div>
                            <div>
                                <FormDropDown
                                    style={{ width: '200px' }}
                                    placeHolder={fieldsLocalizations.operator.placeholder}
                                    options={operatorDropDownOptions()}
                                    name="operator"
                                    showSearch
                                />
                            </div>
                        </FieldContainer>
                    )}
                </div>
                <div style={{ paddingRight: '30px' }}>
                    {shipmentFormLogics.etd(values.shipment_type) && (
                        <FieldContainer>
                            <div>{renderFieldTitle('etd', false)}</div>
                            <div>
                                <FormDatePicker name="etd" style={{ width: '200px' }} placeHolder={fieldsLocalizations.etd.title} showTime />
                            </div>
                        </FieldContainer>
                    )}
                    {shipmentFormLogics.house(values.shipment_type) && (
                        <FieldContainer>
                            <div>{renderFieldTitle('house', false)}</div>
                            <div>
                                <FormTextInput name="house" placeHolder={billingSectionLocalizations.enter_name} style={{ width: '200px' }} />
                            </div>
                        </FieldContainer>
                    )}
                    {shipmentFormLogics.carrier(values.shipment_type) && (
                        <FieldContainer>
                            <div>{renderFieldTitle('carrier', false)}</div>
                            <div>
                                <FormDropDown
                                    style={{ width: '200px' }}
                                    placeHolder={fieldsLocalizations.carrier.placeholder}
                                    options={carriersDropDownOptionsByShipmentType(values.shipment_type)}
                                    name="carrier"
                                    showSearch
                                    onChange={(value: string) => {
                                        updateMaster(values, setFieldValue, value);
                                    }}
                                />
                            </div>
                        </FieldContainer>
                    )}
                    {shipmentFormLogics.delivery_note(values.shipment_type) && (
                        <FieldContainer>
                            <div>{renderFieldTitle('delivery_note', false)}</div>
                            <div>
                                <FormTextInput name="delivery_note" placeHolder={billingSectionLocalizations.enter_name} style={{ width: '200px' }} />
                            </div>
                        </FieldContainer>
                    )}
                    {shipmentFormLogics.vessel_name(values.shipment_type) && (
                        <FieldContainer>
                            <div>{renderFieldTitle('vessel_name', false)}</div>
                            <div>
                                <FormTextInput name="vessel_name" placeHolder={billingSectionLocalizations.enter_name} style={{ width: '200px' }} />
                            </div>
                        </FieldContainer>
                    )}
                </div>
                <div style={{ paddingRight: '30px' }}>
                    {shipmentFormLogics.eta(values.shipment_type) && (
                        <FieldContainer>
                            <div>{renderFieldTitle('eta', false)}</div>
                            <div>
                                <FormDatePicker name="eta" style={{ width: '200px' }} placeHolder={fieldsLocalizations.eta.title} showTime />
                            </div>
                        </FieldContainer>
                    )}
                    <FieldContainer>
                        <div>{renderFieldTitle('customs_file', true)}</div>
                        <FormCheckbox name={'customs_responsible'}>
                            <span> {fieldsLocalizations.customs_file.check_box}</span>
                        </FormCheckbox>
                    </FieldContainer>

                    {shipmentFormLogics.customs_file(values.shipment_type) && values.customs_responsible && (
                        <FieldContainer>
                            <div>
                                <FormTextInput name="customs_file" placeHolder={fieldsLocalizations.customs_file.title} style={{ width: '200px' }} />
                            </div>
                        </FieldContainer>
                    )}
                    {shipmentFormLogics.customs_file(values.shipment_type) && !values.customs_responsible && (
                        <FieldContainer>
                            <div>
                                <FormTextInput
                                    name="customs_file"
                                    placeHolder={fieldsLocalizations.customs_file.enter_name}
                                    style={{ width: '200px' }}
                                />
                            </div>
                        </FieldContainer>
                    )}
                    <FieldContainer>
                        <div>{renderFieldTitle('inland_transportation', true)}</div>
                        <FormCheckbox name={'inland_transportation'}>
                            <span> {fieldsLocalizations.inland_transportation.check_box}</span>
                        </FormCheckbox>
                    </FieldContainer>

                    {!values.inland_transportation && (
                        <FieldContainer>
                            <div>
                                <FormTextInput
                                    name="inland_operator"
                                    placeHolder={fieldsLocalizations.inland_transportation.enter_name}
                                    style={{ width: '200px' }}
                                />
                            </div>
                        </FieldContainer>
                    )}
                    {values.inland_transportation && (
                        <FieldContainer>
                            <div>
                                <FormDropDown
                                    name="inland_operator"
                                    placeHolder={fieldsLocalizations.inland_transportation.choose_trucker}
                                    style={{ width: '200px' }}
                                    options={partiesToDropdownOptions(businessPartnerTruckers)}
                                />
                            </div>
                        </FieldContainer>
                    )}
                    {shipmentFormLogics.voyage_number(values.shipment_type) && (
                        <FieldContainer>
                            <div>{renderFieldTitle('voyage_number', false)}</div>
                            <div>
                                <FormTextInput
                                    name="voyage_number"
                                    placeHolder={fieldsLocalizations.voyage_number.placeholder}
                                    style={{ width: '200px' }}
                                />
                            </div>
                        </FieldContainer>
                    )}
                </div>
            </BillingFormSection>
            <BillingFormSection>
                <div style={{ paddingRight: '30px' }}>
                    <FieldContainer>
                        <div>{renderFieldTitle('goods_value', false)}</div>
                        <CurrencyInput
                            currencyValue={values.goods_value_currency}
                            numberInputName="goods_value"
                            numberPlaceholder={fieldsLocalizations.goods_value.title}
                            unitInputName="goods_value_currency"
                        />
                    </FieldContainer>
                </div>
                <div style={{ paddingRight: '30px' }}>
                    <FieldContainer>
                        <div>{renderFieldTitle('trans_price', false)}</div>
                        <CurrencyInput
                            currencyValue={values.trans_price_currency}
                            numberInputName="trans_price"
                            numberPlaceholder={fieldsLocalizations.trans_price.title}
                            unitInputName="trans_price_currency"
                        />
                    </FieldContainer>
                </div>
            </BillingFormSection>
            {isFreeLancerOrForwarder && (
                <BillingFormSection>
                    <div style={{ paddingRight: '30px' }}>
                        <FieldContainer>
                            <div>{renderFieldTitle('buying_rate', false)}</div>
                            <CurrencyInput
                                currencyValue={values.buying_rate_currency}
                                numberInputName="buying_rate"
                                numberPlaceholder={fieldsLocalizations.buying_rate.title}
                                unitInputName="buying_rate_currency"
                            />
                        </FieldContainer>
                    </div>
                    <div style={{ paddingRight: '30px' }}>
                        <FieldContainer>
                            <div>{renderFieldTitle('selling_rate', false)}</div>
                            <CurrencyInput
                                currencyValue={values.selling_rate_currency}
                                numberInputName="selling_rate"
                                numberPlaceholder={fieldsLocalizations.selling_rate.title}
                                unitInputName="selling_rate_currency"
                            />
                        </FieldContainer>
                    </div>
                </BillingFormSection>
            )}
        </>
    );
};

const BillingFormSection = styled.div`
    display: flex;
    flex-direction: row;
`;

const Flex = styled.div`
    display: flex;
`;

export default Billing;
