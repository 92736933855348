import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import GetStartedPage from './GetStartedPage';
import { withRouter, RouteComponentProps } from 'react-router-dom';
import { RootState } from '../../../state/store/store';
import { localizationSelectors } from '../../../state/ducks/localization/selectors';
import { getStartedCreateCompanyPayload } from '../../../models/entities/company/getStartedCreateCompanyPayload';
import * as qs from 'querystring';
import { Location } from 'history';
import Loader from '../../shared/SmallComponents/Loader';
import ExceptionPage from '../ExceptionPage/ExceptionPage';

export type LocationStateType = {
    email: string;
    pricingPlanId?: string;
    companyDetails: { name: string; type: string };
    userHasRegistered?: boolean;
};
export type GetStartedPageConnectedProps = RouteComponentProps & {
    localization: any;
    sendEmailValidation: (email: string) => Promise<boolean>;
    validateEmail: (email: string, pinCode: string, pricingPlanId?: string, underForwarderToken?: string) => Promise<string>;
    validateCompanyName: (name: string) => Promise<boolean>;
    validateUnderForwarderToken: (token: string) => Promise<boolean>;
    createCompany: (payload: getStartedCreateCompanyPayload) => Promise<void>;
    isSendingValidationEmail: boolean;
    isValidateEmail: boolean;
    isCreateCompany: boolean;
    isValidateCompanyName: boolean;
    underForwarderInvalidTokenError: string;
    createCompanyError?: string;
    location: Location<LocationStateType>;
};

const GetStartedPageConnected = ({
    localization,
    sendEmailValidation,
    validateEmail,
    validateCompanyName,
    validateUnderForwarderToken,
    createCompany,
    isSendingValidationEmail,
    isValidateEmail,
    isCreateCompany,
    isValidateCompanyName,
    underForwarderInvalidTokenError,
    createCompanyError,
    match,
    location
}: GetStartedPageConnectedProps) => {
    const underForwarderToken = qs.parse(location.search.replace('?', '')).token as string;
    const [isValidateToken, setIsValidateToken] = useState<boolean>(!!underForwarderToken);
    useEffect(() => {
        if (underForwarderToken) {
            (async () => {
                setIsValidateToken(true);
                await validateUnderForwarderToken(underForwarderToken);
                setIsValidateToken(false);
            })();
        }
    }, []);

    if (isValidateToken) return <Loader />;

    if (underForwarderInvalidTokenError) {
        const message = underForwarderInvalidTokenError === '"RELATION_EXIST"' ? localization.used_link : localization.invalid_token;
        return <ExceptionPage status={401} title={message} />;
    }

    return (
        <GetStartedPage
            localization={localization}
            sendEmailValidation={sendEmailValidation}
            validateEmail={validateEmail}
            validateCompanyName={validateCompanyName}
            createCompany={createCompany}
            isSendingValidationEmail={isSendingValidationEmail}
            isValidateEmail={isValidateEmail}
            isCreateCompany={isCreateCompany}
            isValidateCompanyName={isValidateCompanyName}
            match={match}
            location={location}
            underForwarderToken={underForwarderToken}
        />
    );
};

const mapStateToProps = (state: RootState) => ({
    localization: localizationSelectors.getStartedPage(state),
    isSendingValidationEmail: !!state.loading.effects.userRegistration.sendEmailValidation,
    isValidateEmail: !!state.loading.effects.userRegistration.validateEmail,
    isValidateCompanyName: !!state.loading.effects.userRegistration.validateCompanyName,
    isCreateCompany: !!state.loading.effects.userRegistration.createCompany,
    underForwarderInvalidTokenError: state.error.effects.userRegistration.validateUnderForwarderToken,
    createCompanyError: state.error.effects.userRegistration.createCompany
});

const mapDispatchToProps = (dispatch: any) => ({
    sendEmailValidation: (email: string) => dispatch.userRegistration.sendEmailValidation(email),
    validateEmail: (email: string, pinCode: string, pricingPlanId?: string, underForwarderToken?: string) =>
        dispatch.userRegistration.validateEmail({ email, pinCode, pricingPlanId, underForwarderToken }),
    validateCompanyName: (name: string) => dispatch.userRegistration.validateCompanyName(name),
    validateUnderForwarderToken: (token: string) => dispatch.userRegistration.validateUnderForwarderToken(token),
    createCompany: (payload: getStartedCreateCompanyPayload) => dispatch.userRegistration.createCompany(payload)
});

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(GetStartedPageConnected));
