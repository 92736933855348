import React from 'react';
import { purchaseOrderReference } from '../../../../../../models/entities/purchaseOrder/purchaseOrderReference';
import { FormTextInput } from '../../../../../shared/inputs/form';
import { styled } from '../../../../../shared/Theme/theme';
import { Flex, renderFieldLabel } from '../../PurchaseOrderForm';

type Props = {
    values: any;
    setFieldValue: any;
    localization: any;
};
export const emptyRefObj: purchaseOrderReference = {
    id: '',
    entityId: '',
    value: '',
    companyType: 'CLIENT'
};
const References = ({ values, setFieldValue, localization }: Props) => {
    return (
        <>
            <div>{renderFieldLabel(localization.reference_no, false, localization.reference_no_tooltip)}</div>
            {values.references &&
                values.references.map((item: purchaseOrderReference, index: number) => {
                    if (item.companyType !== 'CLIENT') return null;
                    return (
                        <Flex key={index} style={{ alignItems: 'center', marginBottom: '20px' }}>
                            <StyledFieldContainer>
                                <div>
                                    <FormTextInput
                                        name={`references[${index}][value]`}
                                        placeHolder={localization.reference_no}
                                        style={{ width: '200px' }}
                                    />
                                </div>
                            </StyledFieldContainer>
                            <DeleteContainer
                                onClick={() => {
                                    const references: Array<any> = [...values.references];
                                    references.splice(index, 1);
                                    setFieldValue('references', references);
                                }}
                            >
                                <VerticalDivider />
                                <span className="material-icons">delete</span>
                            </DeleteContainer>
                        </Flex>
                    );
                })}
            <AddContainer
                onClick={() => {
                    let references: Array<any> = [];
                    if (values.references) references = [...values.references];
                    references.push(emptyRefObj);
                    setFieldValue('references', references);
                }}
            >
                <span className="material-icons">add_circle</span>
                <span>{localization.add_ref_no}</span>
            </AddContainer>
        </>
    );
};

const AddContainer = styled.div`
    cursor:pointer;
    display: inline-flex;
    align-items: center;
    font-size: 13px;
    line-height:0; 
    color: ${(props) => props.theme.colors.primaryBlue};
    span.material-icons{
        font-size:inherit;
        margin-right:5px;
        user-select:none;
    }
}
`;
const DeleteContainer = styled.div`
    display: flex;
    color: ${(props) => props.theme.colors.primaryBlue};
    span.material-icons {
        cursor: pointer;
    }
`;
const VerticalDivider = styled.div`
    height: auto;
    width: 1px;
    background: rgb(0 0 0 / 14%);
    margin-right: 20px;
`;
const StyledFieldContainer = styled.div`
    padding-right: 30px;
`;
export default References;
