import React, { useState } from 'react';
import settingsIcon from '../../../../static/icons/settings-light-blue.svg';
import { styled } from '../../Theme/theme';
import CustomizeColumnsModal from '../CustomizeColumns/CustomizeColumnsModal';
import { Column } from '../Grid';
import { ColumnType } from '../types/Column';

type Props = {
    colWidth: string;
    columns: Array<ColumnType<any>>;
    userSettingKey?: string;
    onSettingsClick: () => void;
    setColumns: React.Dispatch<React.SetStateAction<ColumnType<any>[]>>;
};

const SettingsColumns = ({ colWidth, columns, userSettingKey, onSettingsClick, setColumns }: Props) => {
    const [customizeColumnsModal, setCustomizeColumnsModal] = useState(false);

    return (
        <>
            <Container>
                <Column
                    key="settings"
                    width={colWidth}
                    pointer={true}
                    sortable={false}
                    onClick={() => {
                        onSettingsClick();
                        setCustomizeColumnsModal(true);
                    }}
                >
                    <img style={{ paddingTop: '22px' }} src={settingsIcon} alt="Settings" />
                </Column>

                {customizeColumnsModal && (
                    <CustomizeColumnsModal
                        gridColumns={columns}
                        setGridColumns={setColumns}
                        setVisible={setCustomizeColumnsModal}
                        userSettingKey={userSettingKey}
                    />
                )}
            </Container>
            <MobileWrapper>
                <Column key="settings" width={colWidth} pointer={true} sortable={false}></Column>
            </MobileWrapper>
        </>
    );
};
const Container = styled.div`
    @media screen and (max-width: 768px) {
        display: none;
    }
`;
const MobileWrapper = styled.div`
    @media screen and (min-width: 768px) {
        display: none;
    }
`;
export default SettingsColumns;
