import BaseController from '../..';
import { document } from '../../../../models/entities/common_subentities/document';
import IPurchaseOrderSupplierUpdatesDocuments from './IPurchaseOrderSupplierUpdatesDocuments';

export default class PurchaseOrderSupplierUpdatesDocuments extends BaseController implements IPurchaseOrderSupplierUpdatesDocuments {
    constructor(baseUrl: string) {
        super(baseUrl);
    }
    async fetchPurchaseOrderDocuments(purchaseOrderNumber: string) {
        const response = await this.client.get(`/public/poSupplierUpdates/documents/${purchaseOrderNumber}`);
        return response;
    }
    async createMultiplePurchaseOrderDocument(supplierName: string, data: Array<document>) {
        this.client.setBodyType('FORM-DATA');
        const response = await this.client.post(
            `/public/poSupplierUpdates/documents/upload-multiple`,
            this.convertToArrayFormData(supplierName, data)
        );
        return response;
    }
    async createPurchaseOrderDocument(supplierName: string, data: document) {
        this.client.setBodyType('FORM-DATA');
        const response = await this.client.post(`/public/poSupplierUpdates/documents/upload`, this.convertToFormData(supplierName, data));
        return response;
    }
    async updatePurchaseOrderDocument(supplierName: string, data: document) {
        this.client.setBodyType('FORM-DATA');
        const response = await this.client.put(`/public/poSupplierUpdates/documents`, this.convertToFormData(supplierName, data));
        return response;
    }
    async deletePurchaseOrderDocument(purchaseOrderId: string, documentId: number) {
        const response = await this.client.delete(`/public/poSupplierUpdates/documents`, JSON.stringify({ purchaseOrderId, id: documentId }));
        return response;
    }
    private convertToFormData(supplierName: string, document: document): FormData {
        const formData = new FormData();
        let type = document.type;
        if (Array.isArray(type)) type = JSON.stringify(type);
        formData.append('userName', supplierName);
        formData.append('type', type);
        formData.append('comment', document.comment);
        formData.append('fileName', document.fileName);
        formData.append('fileType', document.fileType);
        if (document.expDate) formData.append('expDate', JSON.stringify(document.expDate));
        if (document.file) formData.append('file', document.file);
        if (document.id) formData.append('id', '' + document.id);
        return formData;
    }
    private convertToArrayFormData(supplierName: string, documents: Array<document>): FormData {
        const formData = new FormData();
        formData.append('userName', supplierName);
        documents.forEach((document) => {
            let type = document.type;
            if (Array.isArray(type)) type = JSON.stringify(type);
            formData.append('type[]', type);
            formData.append('comment[]', document.comment);
            formData.append('fileName[]', document.fileName);
            formData.append('fileType[]', document.fileType);
            if (document.expDate) formData.append('expDate[]', JSON.stringify(document.expDate));
            if (document.file) formData.append('file[]', document.file);
            if (document.id) formData.append('id[]', '' + document.id);
        });
        return formData;
    }
}
