import React from 'react';
import { Badge } from '../Layout/Header/Header';
import { styled } from '../Theme/theme';

type TabProps = {
    title: string;
    isActive?: boolean;
    onClick?: () => void;
    children?: Array<JSX.Element> | JSX.Element;
    icon?: string | JSX.Element;
    badgeNumber?: number;
    fontSize?: string;
};

const Tab = ({ title, isActive, onClick, icon, badgeNumber, fontSize }: TabProps) => {
    return (
        <StyledTab onClick={onClick} isActive={isActive} fontSize={fontSize}>
            {icon && (
                <IconContainer>
                    {typeof icon === 'string' && <StyledIcon className="material-icons">{icon}</StyledIcon>}
                    {typeof icon !== 'string' && icon}
                </IconContainer>
            )}
            <span>{title}</span>
            {badgeNumber ? <StyledBadge>{badgeNumber}</StyledBadge> : null}
        </StyledTab>
    );
};

type StyledTabProps = {
    isActive: boolean | undefined;
    fontSize?: string;
};
const StyledTab = styled.div<StyledTabProps>`
    ${({ isActive, theme }) =>
        isActive === true &&
        `
        color: ${theme.colors.tabActive};
        font-weight: bold;
        border-bottom: 4px solid ${theme.colors.tabActive};        
    `}

    ${({ isActive, theme }) =>
        !isActive &&
        `
        color: ${theme.colors.tabNormal};
        border-bottom: 4px solid transparent;
    `}
    font-size: ${(props) => props.fontSize || props.theme.fontSizes.tabText};
    cursor: pointer;
    height: 30px;
    display: flex;
    flex: 1;
    justify-content: center;
    transition: all 0.3s;
    align-items: center;
    min-width: fit-content;

    padding: 5px 15px 0 15px;
    @media screen and (max-width: 768px) {
        height: 52px;
        font-size: ${(props) => props.theme.fontSizes.tabTextMobile};
        font-weight: bold;
    }
`;
const StyledIcon = styled.span`
    margin-right: 5px;
    font-size: 22px;
`;
const StyledBadge = styled(Badge)`
    position: inherit;
    margin-left: 5px;
`;
const IconContainer = styled.div`
    margin-right: 5px;
`;
export default Tab;
