import { GridColDef } from '@material-ui/data-grid';
import React, { useState } from 'react';
import shipmentItem from '../../../../../models/entities/shipment/shipmentItem';
import MuiDataGrid from '../../../../shared/MuiDataGrid/MuiDataGrid';
import { connect } from 'react-redux';
import { RootState } from '../../../../../state/store/store';
import { Formik } from 'formik';
import { Form } from 'formik-antd';
import { Modal } from 'antd';
import { shipment } from '../../../../../models/entities/shipment/shipment';
import { drawers } from '../../../../../state/ducks/header/header';
import { product } from '../../../../../models/entities/product/product';
import { singleShipmentSelectors } from '../../../../../state/ducks/singleShipment/selectors';
import { ShipmentItemsGridColDef } from './ShipmentItemsGridColDef';
import { useEffect } from 'react';
import { featureFlagsModel } from '../../../../../models/entities/businessPartner/settings';

type Props = {
    shipment: shipment;
    localization: any;
    isAllowedShipment?: boolean;
    updateShipmentItem: (item: shipmentItem) => Promise<void>;
    deleteShipmentItem: (shipmentId: string, itemId: string) => void;
    setDrawerOpen: (open: drawers, params?: any) => void;
    fetchProducts: (ids: string[]) => Promise<Array<product>>;
    shipmentItemsRelatedProducts: Array<product>;
    featureFlags?: featureFlagsModel;
    isFreelancerOrForwarder?: boolean;
};
const ShipmentItemsTab = ({
    shipment,
    localization,
    updateShipmentItem,
    deleteShipmentItem,
    setDrawerOpen,
    isAllowedShipment,
    shipmentItemsRelatedProducts,
    fetchProducts,
    featureFlags,
    isFreelancerOrForwarder
}: Props) => {
    const [updateItemQuantityTimeout, setUpdateItemQuantityTimeout] = useState(0);

    useEffect(() => {
        if (shipment?.items?.length) {
            const productsIds: string[] = shipment.items.map((item: shipmentItem) => item.productId);
            fetchProducts(productsIds);
        }
    }, []);

    const onUpdateItemQuantity = !isAllowedShipment
        ? undefined
        : async (updatedItem: shipmentItem) => {
              clearTimeout(updateItemQuantityTimeout);
              const timeout = setTimeout(async () => {
                  await updateShipmentItem({ ...updatedItem });
              }, 500);
              setUpdateItemQuantityTimeout(timeout);
          };

    const ItemsGridColumns = (setFieldValue: any, values: any): GridColDef[] => {
        const onDeleteItem = !isAllowedShipment
            ? undefined
            : async (itemIndex: number) => {
                  const items: Array<shipmentItem> = [...values.items];
                  const removedItems = items.splice(itemIndex, 1);
                  const itemId = removedItems[0]?.id;

                  if (removedItems && itemId) {
                      Modal.confirm({
                          zIndex: 1100,
                          content: <div>{localization.are_you_sure}</div>,
                          async onOk() {
                              await deleteShipmentItem(shipment.id, itemId);
                              const newItems = items?.filter((item) => item.id !== itemId);
                              setFieldValue('items', newItems);
                          },
                          onCancel() {}
                      });
                      return;
                  }
              };

        return ShipmentItemsGridColDef(
            shipment,
            values.items,
            localization,
            featureFlags,
            onUpdateItemQuantity,
            onDeleteItem,
            setDrawerOpen,
            setFieldValue,
            shipmentItemsRelatedProducts,
            isAllowedShipment,
            updateShipmentItem,
            isFreelancerOrForwarder
        );
    };

    return (
        <Formik
            initialValues={{
                items: shipment.items
            }}
            onSubmit={() => {}}
            enableReinitialize
        >
            {({ values, errors, setFieldValue, isSubmitting, isValidating }) => {
                const gridColumns = ItemsGridColumns(setFieldValue, values);

                return (
                    <Form>
                        {!!values.items?.length && (
                            <MuiDataGrid
                                columns={gridColumns}
                                rows={values.items}
                                autoHeight
                                disableColumnFilter
                                disableColumnMenu
                                disableColumnReorder
                            />
                        )}
                    </Form>
                );
            }}
        </Formik>
    );
};

const mapStateToProps = (state: RootState) => ({
    shipmentItemsRelatedProducts: singleShipmentSelectors.shipmentItemsRelatedProducts(state)
});

const mapDispatchToProps = (dispatch: any) => ({
    updateShipmentItem: (item: shipmentItem) => dispatch.shipments.updateShipmentItem(item),
    deleteShipmentItem: (shipmentId: string, itemId: string) => dispatch.shipments.deleteShipmentItem({ shipmentId, itemId }),
    setDrawerOpen: (open: drawers, params?: any) => dispatch.header.setDrawerOpenParams({ open, params }),
    fetchProducts: (ids: string[]) => dispatch.products.fetchProductsByIds(ids)
});

export default connect(mapStateToProps, mapDispatchToProps)(ShipmentItemsTab);
