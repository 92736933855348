import React, { useEffect } from 'react';
import moment from 'moment';
import { groupBy } from 'lodash';
import styled, { css } from 'styled-components';
import Message from './Message';
import { chat, message, chatType, messageAttachment, editMessagePayload, createChatPayload } from '../../../../models/entities/chat/chat';
import { userInfo } from '../../../../models/entities/user';
import { userDetails } from '../../../../state/ducks/users/users';
import { shipment } from '../../../../models/entities/shipment/shipment';
import { ColumnType } from '../../../shared/Grid/types/Column';
import { isEqual } from 'lodash';
import { uuidv4 } from '../../../../utils/patterns';
type Props = {
    chatMessages: { [key: string]: message[] };
    chatType: chatType;
    topMessageRef: React.MutableRefObject<any>;
    currentTopMessageId: string;
    chatId: string;
    userInfo: userInfo;
    highlightedMessage?: string;
    isEditMessage: message | null;
    highlightedMessageId?: string;
    getShipmentById: (id: string) => shipment | undefined;
    getUserById: (userId: string) => userDetails | undefined;
    gridColumns: Array<ColumnType<shipment>>;
    onMenuEditClick: (e: any, m: message) => void;
    onMenuDeleteClick: (e: any, m: message) => void;
    setReplyMessage: (message: message) => void;
    highlightMessage: (id: string) => void;
    getMessageById: (chatId: string, messageId: string) => message | undefined;
    localization: any;
};

const MessagesList = ({ chatMessages, topMessageRef, currentTopMessageId, ...restProps }: Props) => {
    return (
        <>
            {Object.entries(chatMessages).map(([date, messages], index) => {
                return (
                    <div style={{ width: '100%', display: 'flex', flexDirection: 'column' }} key={`messages-group-${messages[0].id}`}>
                        <DateStamp>{date}</DateStamp>
                        {messages.map((m, i) => {
                            const key = m.id ? m.id : uuidv4();
                            const isFirstInGroup =
                                messages[i - 1] === undefined || messages[i - 1]?.userId !== m.userId || messages[i - 1]?.isActivity === true;
                            const isLastInGroup = messages[i + 1] === undefined || messages[i + 1]?.userId !== m.userId;
                            const isHighlighted = m.id === restProps.highlightedMessage;
                            const isActivity = m.isActivity;
                            const isTopMessage = currentTopMessageId === m.id;
                            return !isActivity ? (
                                <Message
                                    key={key}
                                    {...restProps}
                                    message={{ ...m }}
                                    topMessageRef={isTopMessage ? topMessageRef : undefined}
                                    isFirstInGroup={isFirstInGroup}
                                    isLastInGroup={isLastInGroup}
                                    isEditMessage={m.id === restProps.isEditMessage?.id ? restProps.isEditMessage : null}
                                    highlightedMessageId={isHighlighted ? restProps.highlightedMessage : undefined}
                                    localization={restProps.localization}
                                />
                            ) : (
                                <ActivityMessageContainer id={`message-container-${m.id}`} ref={isTopMessage ? topMessageRef : undefined}>
                                    {m.content}
                                </ActivityMessageContainer>
                            );
                        })}
                    </div>
                );
            })}
        </>
    );
};

const DateStamp = styled.span`
    width: fit-content;
    margin: 0 auto;
    padding: 1px 5px;
    border-radius: 8px;
    text-align: center;
    position: sticky;
    top: 0;
    left: 0;
    right: 0;
    display: block;
    background-color: #d0d0d0;
    z-index: 1;
`;

const ActivityMessageContainer = styled.div`
    margin: 8px auto;
    background-color: #dedede;
    padding: 1px 5px;
    background-color: #d0d0d0;
    box-shadow: 0 1px 3px 0 rgb(0 0 0 / 10%), 0 1px 2px 0 rgb(0 0 0 / 6%);
    border-radius: 5px;
    font-size: 12px;
`;
export default React.memo(MessagesList);
