import React from 'react';
import styled from 'styled-components';
import { Option } from '../../../../shared/inputs/base/DropDown';
import { FormDropDown, FormTextInput } from '../../../../shared/inputs/form';
import { flatMarketplaces, getFlatMarketplaces, getMarketplacesDropDownOptions } from '../../../Admin/Companies/SingleCompanyPage/utils';
import CountryOption from '../../../../shared/SmallComponents/CountryOption';
import { marketplaceAsin } from '../../../../../models/entities/product/product';
import { userSelectors } from '../../../../../state/ducks/user/selectors';
import { allowedCompany } from '../../../../../models/entities/user';
import { connect } from 'react-redux';
import { RootState } from '../../../../../state/store/store';

type Props = {
    allowedCompanies: allowedCompany[];
    name: string;
    values: any;
    localizations: any;
    placeholder: string;
    setFieldValue?: (field: string, value: any) => void;
    onChange?: (value: any) => void;
};

export const emptyAsinObj = (marketplaceId?: string): marketplaceAsin => ({
    marketplaceId: marketplaceId || '',
    asin: ''
});

const AsinInput = ({ name, values, localizations, setFieldValue, onChange, allowedCompanies }: Props) => {
    const usMarketplace = flatMarketplaces?.find((mp) => mp.countryCode === 'US')?.marketplaceId;
    const selectedCompanyMarketplaces = allowedCompanies.find((c) => c.companyId === values.companyId)?.marketplaceIds || [usMarketplace ?? ''];
    const addDisabled = !values[name] || !selectedCompanyMarketplaces?.length || values[name]?.length === selectedCompanyMarketplaces?.length;
    return (
        <>
            {values[name] &&
                values[name].map((item: marketplaceAsin, index: number) => {
                    const excludedMarketplaces: string[] = values[name]?.reduce((marketplaces: string[], asin: marketplaceAsin) => {
                        return values[name]?.[index]?.marketplaceId === asin.marketplaceId ? marketplaces : [...marketplaces, asin.marketplaceId];
                    }, []);

                    const marketplacesOptions = getMarketplacesDropDownOptions(selectedCompanyMarketplaces, excludedMarketplaces);
                    const flatMarketplacesOptions = getFlatMarketplaces(marketplacesOptions);
                    return (
                        <Flex key={index} style={{ alignItems: 'center', marginBottom: '20px' }}>
                            <FormDropDown
                                disabled={
                                    values[name]?.[index]?.marketplaceId ? flatMarketplacesOptions.length === 1 : flatMarketplacesOptions.length < 1
                                }
                                name={`${name}[${index}][marketplaceId]`}
                                style={{ width: 150, marginRight: 5 }}
                                placeHolder={localizations.marketplace}
                                options={marketplacesOptions}
                                showSearch={true}
                                optionRenderer={(option: Option) => {
                                    const marketplace = flatMarketplaces.find((m) => m.marketplaceId === option.value);
                                    return <CountryOption country={marketplace?.country || ''} code={marketplace?.countryCode || ''} />;
                                }}
                                onChange={(marketplaceId: string) => {
                                    setFieldValue?.(`${name}[${index}][marketplaceId]`, marketplaceId);
                                }}
                            />

                            <FieldContainer>
                                <FormTextInput name={`${name}[${index}][asin]`} placeHolder={localizations.asin} style={{ marginRight: 5 }} />
                            </FieldContainer>

                            <DeleteContainer
                                onClick={() => {
                                    const asin: Array<marketplaceAsin> = [...values[name]];
                                    asin.splice(index, 1);
                                    onChange && onChange(asin);
                                }}
                            >
                                <VerticalDivider style={{ marginLeft: '5px' }} />
                                <span className="material-icons">delete</span>
                            </DeleteContainer>
                        </Flex>
                    );
                })}
            <AddContainer
                disabled={addDisabled}
                onClick={() => {
                    if (addDisabled) return;
                    const asin: Array<marketplaceAsin> = [
                        ...(values[name] || []),
                        emptyAsinObj(
                            flatMarketplaces.filter(
                                (mp) =>
                                    selectedCompanyMarketplaces?.includes(mp.marketplaceId) &&
                                    !values.asin?.map((a: marketplaceAsin) => a.marketplaceId)?.includes(mp.marketplaceId)
                            )?.[0]?.marketplaceId
                        )
                    ];
                    onChange && onChange(asin);
                }}
            >
                <span className="material-icons">add_circle</span>
                <span>{'Add Asin'}</span>
            </AddContainer>
        </>
    );
};

const DeleteContainer = styled.div`
    display: flex;
    color: ${(props) => props.theme.colors.primaryBlue};
    span.material-icons {
        cursor: pointer;
    }
`;
const VerticalDivider = styled.div`
    height: auto;
    width: 1px;
    background: rgb(0 0 0 / 14%);
    margin-right: 5px;
`;
const StyledFieldContainer = styled.div`
    padding-right: 30px;
`;

const Flex = styled.div`
    display: flex;
`;

const FieldContainer = styled.div`
    margin-right: 5px;
`;

const AddContainer = styled.div<{ disabled?: boolean }>`
    cursor: ${(props) => (props.disabled ? 'not-allowed' : 'pointer')};
    display: inline-flex;
    align-items: center;
    font-size: 13px;
    line-height: 0;
    color: ${(props) => (props.disabled ? '#3943729c' : props.theme.colors.pageTitle)};
    span.material-icons {
        font-size: inherit;
        margin-right: 5px;
        user-select: none;
    }
`;

const mapStateToProps = (state: RootState) => ({
    allowedCompanies: userSelectors.allowedCompanies(state)
});

const mapDispatchToProps = (dispatch: any) => ({});

export default connect(mapStateToProps, mapDispatchToProps)(AsinInput);
