import React from 'react';
import { product } from '../../../../../../models/entities/product/product';
import { purchaseOrderItem } from '../../../../../../models/entities/purchaseOrder/purchaseOrderItem';
import { Option } from '../../../../../shared/inputs/base/DropDown';
import { currencyFormatter } from '../../../../../shared/inputs/form/CurrencyInput/CurrencyInput';
import { styled } from '../../../../../shared/Theme/theme';
import { calcCosts } from './util';
import ProductsModal, { productGridRow } from '../../../../products/components/ProductsModal/ProductsModal';
import { ModalTriggerProps } from '../../../../../shared/Modal/Modal';
import MuiDataGrid from '../../../../../shared/MuiDataGrid/MuiDataGrid';
import { GridColDef, GridRowId, GridRowParams } from '@material-ui/data-grid';
import { uuidv4 } from '../../../../../../utils/patterns';
import Button from '../../../../../shared/SmallComponents/Button';
import SingleValueFormModal from '../../../../../shared/SingleValueFormModal/SingleValueFormModal';
import { PoItemsGridColDef } from './PoItemsGridColDef';
import { drawers } from '../../../../../../state/ducks/header/header';
import { calcTotalVolume, calcQuantity, calcTotalWeight } from '../../../../../../utils/measurementUtils';
import MergePoItemsToShipmentModal from '../../../sections/Items/MergePoItemsToShipmentModal/MergePoItemsToShipmentModal';
import { purchaseOrder } from '../../../../../../models/entities/purchaseOrder/purchaseOrder';

type Props = {
    purchaseOrder?: purchaseOrder;
    values: any;
    setFieldValue?: any;
    shipmentsOptions?: Option[];
    hideBottomDetails?: boolean;
    isProductModalOpened?: boolean;
    localization: any;
    printMode?: boolean;
    hideItemsPrices?: boolean;
    onDeleteItem?: (itemId: string) => Promise<void>;
    onAddItemsCallback?: (items: purchaseOrderItem[]) => void;
    onUpdateItemsReadyDate?: (ids: string[], readyDate?: Date) => Promise<void>;
    onUpdateItemQuantity?: (updatedItem: purchaseOrderItem) => Promise<void>;
    updatePurchaseOrderItem?: (updatedItem: purchaseOrderItem) => Promise<void>;
    onUpdateItemCost?: (updatedItem: purchaseOrderItem) => Promise<void>;
    onCreateShipment?: (itemsIds: string[]) => void;
    setDrawerOpen?: (open: drawers, params?: any) => void;
    updateProduct?: (id: string, product: product, deletedSuppliers?: string[]) => Promise<boolean>;
    poItemsRelatedProducts?: Array<product>;
    isSupplierUpdates?: boolean;
};
const PoItemsGrid = ({
    purchaseOrder,
    values,
    setFieldValue,
    shipmentsOptions,
    hideBottomDetails,
    localization,
    isProductModalOpened,
    printMode,
    hideItemsPrices,
    onDeleteItem,
    onAddItemsCallback,
    onUpdateItemsReadyDate,
    onUpdateItemQuantity,
    updatePurchaseOrderItem,
    onUpdateItemCost,
    onCreateShipment,
    setDrawerOpen,
    updateProduct,
    poItemsRelatedProducts,
    isSupplierUpdates
}: Props) => {
    const [selectedItems, setSelectedItems] = React.useState<GridRowId[]>([]);

    const volumeCalc = calcTotalVolume(values.items);
    const weightCalc = calcTotalWeight(values.items);
    const amounts = calcCosts(values.items);

    const ItemsGridColumns: GridColDef[] = PoItemsGridColDef(
        values.items as Array<purchaseOrderItem>,
        localization,
        printMode || isSupplierUpdates
            ? undefined
            : async (updatedItem: purchaseOrderItem) => {
                  if (onUpdateItemQuantity) await onUpdateItemQuantity(updatedItem);
              },
        updatePurchaseOrderItem,
        printMode || isSupplierUpdates ? undefined : onUpdateItemCost,
        printMode || isSupplierUpdates
            ? undefined
            : async (itemIndex) => {
                  const items: Array<purchaseOrderItem> = [...values.items];
                  const removedItems = items.splice(itemIndex, 1);
                  if (onDeleteItem && removedItems && removedItems[0]?.id) {
                      onDeleteItem(removedItems[0].id);
                      return;
                  }
                  setFieldValue?.('items', items);
              },
        setDrawerOpen,
        setFieldValue,
        printMode,
        updateProduct,
        poItemsRelatedProducts,
        hideItemsPrices,
        isSupplierUpdates
    );
    return (
        <div>
            <ButtonsContainer>
                {!printMode && !isSupplierUpdates && (
                    <ProductsModal
                        title={'Products'}
                        isOpened={isProductModalOpened}
                        showQuantities
                        onSubmitProductsCallBack={(products: productGridRow[]) => {
                            const itemsToAdd: purchaseOrderItem[] = [];

                            products.forEach((product) => {
                                const itemToAdd = {
                                    productId: product.id,
                                    price: product.cost,
                                    currency: product.costCurrency,
                                    name: product.name,
                                    length: product.length,
                                    width: product.width,
                                    height: product.height,
                                    dimensionsUnit: product.dimensionUnit,
                                    weight: product.weight,
                                    weightUnit: product.weightUnit,
                                    productSku: product.sku,
                                    attributes: product.selectedAttributes,
                                    quantity: product.quantity || 1,
                                    minOrderQuantity: product.minOrderQuantity,
                                    productionDays: product.productionDays,
                                    unitsPerMasterCarton: product.unitsPerMasterCarton,
                                    masterCartonWeight: product.masterCartonWeight,
                                    masterCartonWeightUnit: product.masterCartonWeightUnit,
                                    masterCartonHeight: product.masterCartonHeight,
                                    masterCartonWidth: product.masterCartonWidth,
                                    masterCartonLength: product.masterCartonLength,
                                    masterCartonDimensionUnit: product.masterCartonDimensionUnit
                                } as purchaseOrderItem;

                                if (!itemToAdd.id) itemToAdd.id = uuidv4();

                                itemsToAdd.push(itemToAdd);
                            });

                            if (onAddItemsCallback) {
                                onAddItemsCallback(
                                    itemsToAdd.map((i) => {
                                        const item = { ...i, id: undefined } as any;

                                        return item;
                                    })
                                );
                                return;
                            }
                            setFieldValue?.('items', [...(values.items || []), ...itemsToAdd]);
                        }}
                        supplierId={values.vendorId}
                        showAttributesModal
                        Trigger={(props: ModalTriggerProps) => {
                            return (
                                <AddContainer>
                                    <Button onClick={props.onClick} width="auto" buttonType="hollow" padding="4px 12px" type="button">
                                        <span className="material-icons">add</span>
                                        <span>{localization.add_item}</span>
                                    </Button>
                                </AddContainer>
                            );
                        }}
                    />
                )}
                <div style={{ display: 'flex' }}>
                    {!!purchaseOrder && (
                        <MergePoItemsToShipmentModal
                            localization={localization.merge_shipment_modal}
                            selectedItems={(values.items as purchaseOrderItem[]).filter((item) => selectedItems.includes(item.id))}
                            purchaseOrder={purchaseOrder}
                            Trigger={(props: ModalTriggerProps) => (
                                <Button disabled={!selectedItems?.length} onClick={props.onClick} buttonType="hollow" margin="0 10px">
                                    {localization.merge_shipment}
                                </Button>
                            )}
                        />
                    )}
                    {!!onCreateShipment && !printMode && (
                        <AddContainer>
                            <Button
                                width="auto"
                                buttonType="hollow"
                                padding="4px 12px"
                                type="button"
                                disabled={!selectedItems?.length}
                                onClick={() => {
                                    if (selectedItems?.length) onCreateShipment?.(selectedItems as string[]);
                                }}
                            >
                                <span>{localization.create_shipment}</span>
                            </Button>
                        </AddContainer>
                    )}
                    {!!onUpdateItemsReadyDate && !printMode && (
                        <SingleValueFormModal
                            title={localization.ready_date}
                            valueType="date"
                            onSubmit={async (value: any) => {
                                if (onUpdateItemsReadyDate) {
                                    await onUpdateItemsReadyDate(selectedItems as string[], value as Date);
                                    return;
                                }
                                const items: Array<purchaseOrderItem> = [...values.items];
                                const newItems = items.map((item) => (selectedItems.includes(item.id) ? { ...item, readyDate: value } : item));
                                setFieldValue?.('items', newItems);
                            }}
                            Trigger={(props: ModalTriggerProps) => {
                                return (
                                    <Button
                                        width="auto"
                                        onClick={props.onClick}
                                        disabled={!selectedItems?.length}
                                        buttonType="hollow"
                                        padding="4px 12px"
                                        type="button"
                                    >
                                        <span>{localization.update_ready_date}</span>
                                    </Button>
                                );
                            }}
                        />
                    )}
                </div>
            </ButtonsContainer>

            {!!values.items?.length && (
                <MuiDataGrid
                    columns={ItemsGridColumns}
                    rows={values.items}
                    autoHeight
                    disableColumnFilter
                    disableColumnMenu
                    disableColumnReorder
                    checkboxSelection={!printMode}
                    onSelectionModelChange={(newSelection) => {
                        setSelectedItems(newSelection.selectionModel);
                    }}
                    selectionModel={selectedItems}
                    isRowSelectable={(params: GridRowParams) => {
                        return Number(params.row.quantity || 0) - Number(params.row.openedQuantity || 0) > 0;
                    }}
                    rootStyles={
                        !printMode
                            ? undefined
                            : {
                                  '&.MuiDataGrid-root': {
                                      width: '100% !important'
                                  },
                                  '&.MuiDataGrid-root .MuiDataGrid-viewport': {
                                      minWidth: '100% !important',
                                      maxWidth: '100% !important'
                                  }
                              }
                    }
                />
            )}
            {values.items && !hideBottomDetails && (
                <BottomDetails>
                    <div>PO total: {Number(calcQuantity(values.items).toFixed(3))} items</div>
                    <div>
                        {Object.keys(amounts).map((key) => {
                            return amounts[key] > 0 && <div key={key}>{currencyFormatter(key)(Number(amounts[key].toFixed(3)))}</div>;
                        })}
                    </div>
                    <div>
                        {Number(volumeCalc.volume?.toFixed(3)) + ' ' + volumeCalc.unit} ,{' '}
                        {Number(weightCalc.weight?.toFixed(3)) + ' ' + weightCalc.unit}
                    </div>
                </BottomDetails>
            )}
        </div>
    );
};

const ButtonsContainer = styled.div`
    display: flex;
    justify-content: space-between;
`;

const AddContainer = styled.div`
    cursor: pointer;
    display: inline-flex;
    align-items: center;
    margin-right: 10px;
    font-size: 13px;
    line-height: 0;
    color: ${(props) => props.theme.colors.primaryBlue};
    span.material-icons {
        font-size: inherit;
        margin-right: 5px;
        user-select: none;
    }
`;

const BottomDetails = styled.div`
    margin-top: 10px;
    width: 100%;
    border-top: 1px solid rgb(0 0 0 / 14%);
    text-align: right;
    color: ${(props) => props.theme.colors.primaryBlue};
`;

export default PoItemsGrid;
