import React from 'react';
import { RootState } from '../../../../state/store/store';
import { connect } from 'react-redux';
import { userSelectors } from '../../../../state/ducks/user/selectors';
import styled, { css } from 'styled-components';

type Props = {
    companyId: string;
    companyName: string;
    getColorByConpanyId: (id: string) => string | undefined;
};

const CompanyColor = ({ companyId, companyName, getColorByConpanyId }: Props) => {
    return <ColoredLabel color={getColorByConpanyId(companyId)}>{companyName}</ColoredLabel>;
};

interface ColoredLabelProps {
    color?: string;
}
const ColoredLabel = styled.div<ColoredLabelProps>`
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: normal;
    -webkit-line-clamp: 2;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    ${(props) =>
        props.color
            ? css`
                  color: ${props.color};
              `
            : ``}
`;

const mapStateToProps = (state: RootState) => ({
    getColorByConpanyId: (id: string) => userSelectors.getColorByConpanyId(state)(id)
});

const mapDispatchToProps = (dispatch: any) => ({});

export default connect(mapStateToProps, mapDispatchToProps)(CompanyColor);
