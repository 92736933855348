import React from 'react';
import { QuotationPublicDetails } from '../../../../sdk/controllers/bids/IBids';
import ShipmentStation from '../../../shared/Grid/specialColumns/ShipmentStation';
import { styled } from '../../../shared/Theme/theme';
import Packages from './Packages';
import Items from './Items';
import Documents from './Documents';
import QuotationForm, { formMode } from './Form';
import { eShipmentTypes } from '../../../../models/entities/shipment/shipmentTypes';
import countries from '../../../../static/data/countries.json';
import stations from '../../../../static/data/stations.json';
import moment from 'moment';
import { RightArrowIcon } from '../../../shared/Grid/CellRenderer/CellRenderer';
import { getUrlFuncType } from '../../../shared/FilesPreview/Preview';
import Parties from '../../commonSubEntities/parties/Parties';
import { shipment } from '../../../../models/entities/shipment/shipment';
import { settings } from '../../../../models/entities/businessPartner/businessPartner';
import { calcShipmentItemsTotalVolumeWeight } from '../../singleShipment/sections/ShipmentItems/utils';
import { shipmentPackage } from '../../../../models/entities/shipment/shipmentPackage';

type Props = {
    data: QuotationPublicDetails;
    createQuotation: (formData: FormData, isPublic?: boolean) => Promise<boolean>;
    localization: any;
    isPublic: boolean;
    quotationGetFile: getUrlFuncType;
    companySettings: settings;
};

const QuotationReplyPage = ({ data, localization, createQuotation, quotationGetFile, isPublic, companySettings }: Props) => {
    const { shipment, bid, party, shipmentItemsRelatedProducts } = data;

    const originCountryName = countries.find((item) => item.code === shipment.originCountry)?.name;
    const originStationName = stations.find((item) => item.code === shipment.originStation)?.name;
    const destinationCountryName = countries.find((item) => item.code === shipment.destinationCountry)?.name;
    const destinationStationName = stations.find((item) => item.code === shipment.destinationStation)?.name;
    const totalCartons =
        shipment.totalCartons ||
        shipment.items?.reduce(
            (acc, item) => acc + (item.quantity && item.unitsPerMasterCarton ? Math.ceil(item.quantity / item.unitsPerMasterCarton) : 0),
            0
        );

    const packagesTotalQuantity = shipment.packages?.reduce((totalQuantity: number, item: shipmentPackage) => {
        return totalQuantity + (item.quantity || 0);
    }, 0);

    const containersQuantity = shipment.containers?.length;

    const {
        volume: itemsTotalVolume,
        weight: itemsTotalWeight,
        quantity: itemsTotalQuantity
    } = calcShipmentItemsTotalVolumeWeight(shipment.items || []);

    return (
        <Container>
            <LeftSide>
                <Header>
                    <Titles>
                        <HeaderTitle>{party.contactName || party.name}</HeaderTitle>
                        <Divider />
                        <HeaderTitle>{localization.quotation_request}</HeaderTitle>
                        <Divider />
                        <HeaderTitle>#{shipment?.CargoZoneNumber}</HeaderTitle>
                    </Titles>
                    <Expire>
                        {localization.expire} {moment(bid.expirationDate).fromNow()}
                    </Expire>
                </Header>
                <RouteSection>
                    <FlexWrap>
                        <div>
                            <Flex>
                                <Origin>
                                    <ShipmentStation country={shipment.originCountry} port={shipment.originStation} flagSize={'24px'} />
                                    <AddressSection>
                                        <div>{originCountryName}</div>
                                        <div>{originStationName}</div>
                                        <div>{shipment.originZipCode}</div>
                                        <div>{shipment.originPickupAddress}</div>
                                    </AddressSection>
                                </Origin>
                                <RightArrowIcon style={{ marginTop: '10px' }} />
                                <Destination>
                                    <ShipmentStation country={shipment.destinationCountry} port={shipment.destinationStation} flagSize={'24px'} />
                                    <AddressSection>
                                        <div>{destinationCountryName}</div>
                                        <div>{destinationStationName}</div>
                                        <div>{shipment.destinationZipCode}</div>
                                        <div>{shipment.destinationPickupAddress}</div>
                                    </AddressSection>
                                </Destination>
                            </Flex>
                            <Flex>
                                <DetailBox>
                                    <DetailTitle>{localization.incoterms}</DetailTitle>
                                    <DetailValue>{shipment.incoterms || '-'}</DetailValue>
                                </DetailBox>
                                <DetailBox>
                                    <DetailTitle>{localization.shipment_type}</DetailTitle>
                                    <DetailValue>{eShipmentTypes[shipment.typeCode] || '-'}</DetailValue>
                                </DetailBox>
                            </Flex>
                            <Flex>
                                <DetailBox>
                                    <DetailTitle>{localization.quantity}</DetailTitle>
                                    <DetailValue>
                                        {Number(shipment.quantity) || itemsTotalQuantity || packagesTotalQuantity || containersQuantity || '-'}
                                    </DetailValue>
                                </DetailBox>
                                {!!totalCartons && (
                                    <DetailBox>
                                        <DetailTitle>{localization.cartons}</DetailTitle>
                                        <DetailValue>{totalCartons}</DetailValue>
                                    </DetailBox>
                                )}
                                <DetailBox>
                                    <DetailTitle>{localization.weight}</DetailTitle>
                                    <DetailValue>
                                        {Number(shipment.weight)
                                            ? `${Number(shipment.weight).toFixed(3)} ${shipment.weightUnit?.toUpperCase()}`
                                            : shipment.items?.length
                                            ? `${Number(itemsTotalWeight.weight.toFixed(3))} ${itemsTotalWeight.unit}`
                                            : '-'}
                                    </DetailValue>
                                </DetailBox>
                                <DetailBox>
                                    <DetailTitle>{localization.volume}</DetailTitle>
                                    <DetailValue>
                                        {Number(shipment.volume)
                                            ? `${Number(shipment.volume).toFixed(3)} ${shipment.volumeUnit?.toUpperCase()}`
                                            : shipment.items?.length
                                            ? `${Number(itemsTotalVolume.volume.toFixed(3))} ${itemsTotalVolume.unit}`
                                            : '-'}
                                    </DetailValue>
                                </DetailBox>
                            </Flex>
                            <Flex>
                                <DetailBox>
                                    <DetailTitle>{localization.note}</DetailTitle>
                                    <DetailValue>{bid.note || '-'}</DetailValue>
                                </DetailBox>
                                <DetailBox>
                                    <DetailTitle>{localization.include_insurance}</DetailTitle>
                                    <DetailValue>{<span className="material-icons">{bid.includeInsurance ? 'check' : 'close'} </span>}</DetailValue>
                                </DetailBox>
                            </Flex>
                        </div>
                        {!!shipment?.parties?.length && (
                            <PartiesContainer>
                                <h3>{localization.parties_title}</h3>

                                <Parties<shipment, settings> entity={shipment} localization={localization} settings={companySettings} />
                            </PartiesContainer>
                        )}
                    </FlexWrap>
                </RouteSection>
                {!!shipment?.items?.length && (
                    <ItemsSection>
                        <h3>{localization.items_title}</h3>
                        {shipment.items && (
                            <Items items={shipment.items} localization={localization} itemsRelatedProducts={shipmentItemsRelatedProducts} />
                        )}
                    </ItemsSection>
                )}
                {(!!shipment?.packages?.length || !!shipment.containers?.length) && (
                    <PackagesSection>
                        <h3>{localization.packages_title}</h3>
                        {shipment.packages && (
                            <Packages
                                packages={shipment.packages}
                                containers={shipment.containers}
                                type={shipment.typeCode}
                                sub_type={shipment.subTypeCode}
                                localization={localization}
                            />
                        )}
                    </PackagesSection>
                )}
                <DocumentsSection>
                    <h3>
                        {localization.attached_files}({bid?.documents?.length || 0})
                    </h3>
                    {bid.documents && (
                        <Documents
                            documents={bid.documents}
                            quotationGetFile={(fileId: string) => quotationGetFile(fileId, bid.id, party.id, isPublic)}
                        />
                    )}
                </DocumentsSection>
            </LeftSide>
            <RightSide>
                <QuotationForm
                    mode={formMode.NEW}
                    createQuotation={createQuotation}
                    shipmentTypes={bid?.shipmentTypes}
                    localization={localization.form}
                    isPublic={isPublic}
                    data={data}
                />
            </RightSide>
        </Container>
    );
};

const Container = styled.div`
    height: 100%;
    display: flex;

    @media screen and (max-width: 768px) {
        flex-direction: column;
    }
`;
const SectionWrapper = styled.div`
    padding: 23px;
    border-bottom: 1px solid ${(props) => props.theme.colors.grayDefaultBorder};
`;
const Header = styled(SectionWrapper)`
    display: flex;
    align-items: center;
    color: ${(props) => props.theme.colors.pageTitle};
    justify-content: space-between;
`;
const Titles = styled.div`
    display: flex;
    align-items: center;
`;
const Expire = styled.div`
    font-size: 14px;
`;
const HeaderTitle = styled.div`
    font-size: 22px;
    font-weight: 500;

    @media screen and (max-width: 768px) {
        font-size: 16px;
    }
`;
const Divider = styled.div`
    width: 2px;
    background: #c4c4c4;
    margin: 0 12px;
    height: 23px;
`;
const RouteSection = styled(SectionWrapper)`
    display: flex;
    flex-direction: column;
`;
const Flex = styled.div`
    display: flex;
    margin-bottom: 20px;
`;
const FlexWrap = styled.div`
    display: flex;
    flex-wrap: wrap;
    margin-bottom: 20px;
`;
const Origin = styled.div`
    min-width: 150px;
    margin-right: 20px;
`;
const Destination = styled.div`
    min-width: 150px;
    margin-left: 20px;
    margin-right: 40px;
`;
const AddressSection = styled.div`
    color: ${(props) => props.theme.colors.pageTitle};
    padding-left: 24px;
    margin-top: 5px;
`;
const DetailBox = styled.div`
    margin-right: 40px;
`;

const DetailTitle = styled.div`
    color: #95939b;
`;

const DetailValue = styled.div`
    font-size: 20px;
    color: #413e48;
    font-weight: 500;

    @media screen and (max-width: 768px) {
        font-size: 14px;
    }
`;
const ItemsSection = styled(SectionWrapper)`
    color: ${(props) => props.theme.colors.pageTitle};
    h3 {
        color: inherit;
        font-weight: 500;
    }
`;

const PackagesSection = styled(SectionWrapper)`
    color: ${(props) => props.theme.colors.pageTitle};
    h3 {
        color: inherit;
        font-weight: 500;
    }
`;

const DocumentsSection = styled(SectionWrapper)`
    color: ${(props) => props.theme.colors.pageTitle};
    h3 {
        color: inherit;
        font-weight: 500;
    }
`;

const LeftSide = styled.div`
    flex: 1 1 75%;
    overflow-y: overlay;
`;
const RightSide = styled.div`
    flex: 1 1 25%;
    display: flex;
    flex-direction: column;
    background: #252c49;
`;

const PartiesContainer = styled.div`
    flex: 1;
    h3 {
        padding-left: 10px;
        color: ${(props) => props.theme.colors.pageTitle};
        font-weight: 500;
    }
`;

export default QuotationReplyPage;
