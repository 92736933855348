import React from 'react';
import { orderFollower } from '../../../../../models/entities/order/orderFollower/orderFollower';
import { styled } from '../../../../shared/Theme/theme';
import OrderFollower from './OrderFollower';

type Props = {
    followers?: Array<orderFollower>;
};

const OrderFollowers = ({ followers }: Props) => {
    if (!followers) return null;
    return (
        <FollowersContainer>
            {followers.map((follower) => (
                <OrderFollower key={follower.id} follower={follower} />
            ))}
        </FollowersContainer>
    );
};

const FollowersContainer = styled.div`
    display: flex;
    align-items: center;
    margin-right: 20px;
`;

export default OrderFollowers;
