import React from 'react';
import { event } from '../../../../../models/entities/common_subentities/event';
import { order } from '../../../../../models/entities/order/order';
import { localizationSelectors } from '../../../../../state/ducks/localization/selectors';
import { ordersSelectors } from '../../../../../state/ducks/orders/selectors';
import { RootState } from '../../../../../state/store/store';
import { connect } from 'react-redux';
import { singleOrderSelectors } from '../../../../../state/ducks/singleOrder/selectors';
import { userSelectors } from '../../../../../state/ducks/user/selectors';
import EventFormConnected, { formMode } from '../../../commonSubEntities/events/EventForm/EventFormConnected';
import { createMultipleOrderEventsPayload, updateOrderEventPayload } from '../../../../../models/entities/order/orderEvent/payloads';
import { orderEventInput } from '../../../../../models/entities/order/orderEvent/payloads/orderEventInput';
import { orderSettingsType } from '../../../../../models/entities/order/orderSettings/orderSettings';

type Props = {
    order?: order;
    event?: event;
    mode: formMode;
    localization?: any;
    orderSettings?: orderSettingsType;
    openedFromModal?: {
        setVisible: React.Dispatch<React.SetStateAction<boolean>>;
    };
    confirmation?: { setConfirm: React.Dispatch<React.SetStateAction<boolean>> };
    showLoader?: boolean;
    createOrderEvents: (payload: createMultipleOrderEventsPayload) => Promise<void>;
    updateOrderEvent: (payload: updateOrderEventPayload) => Promise<void>;
    onSubmit?: () => void;
    UserCompanyID: string;
};

const OrderEventFormConnected = ({
    order,
    event,
    localization,
    mode,
    openedFromModal,
    confirmation,
    orderSettings,
    showLoader,
    createOrderEvents,
    updateOrderEvent,
    onSubmit,
    UserCompanyID
}: Props) => {
    return (
        <EventFormConnected<order, orderSettingsType>
            entity={order}
            event={event}
            localizations={localization}
            mode={mode}
            openedFromModal={openedFromModal}
            confirmation={confirmation}
            settings={orderSettings}
            createEvent={async (orderId: string, event: event) => {
                const newOrderEvent: orderEventInput = {
                    orderId,
                    code: event.eventCode,
                    description: event.description,
                    eventDate: event.eventDate,
                    createdDate: event.eventDate,
                    comment: event.comment
                };

                const payload: createMultipleOrderEventsPayload = {
                    orderId,
                    events: [newOrderEvent]
                };

                await createOrderEvents(payload);
                onSubmit?.();
            }}
            updateEvent={async (orderId: string, event: event) => {
                const newOrderEvent: orderEventInput = {
                    orderId,
                    code: event.eventCode,
                    description: event.description,
                    eventDate: event.eventDate,
                    createdDate: event.eventDate,
                    comment: event.comment
                };

                const payload: updateOrderEventPayload = {
                    orderId,
                    eventId: `${event.id}`,
                    event: newOrderEvent
                };

                await updateOrderEvent(payload);
                onSubmit?.();
            }}
            showLoader={showLoader}
            UserCompanyID={UserCompanyID}
        />
    );
};

const mapStateToProps = (state: RootState) => ({
    localization: localizationSelectors.ordersPage(state),
    orderSettings: ordersSelectors.orderSettings(state),
    order: singleOrderSelectors.order(state),
    showLoader: state.loading.effects.orders.createOrderEvents || state.loading.effects.orders.updateOrderEvent,
    userInfo: userSelectors.userInfo(state),
    UserCompanyID:userSelectors.usersCompanyId(state),
});

const mapDispatchToProps = (dispatch: any) => ({
    createOrderEvents: (payload: createMultipleOrderEventsPayload) => dispatch.orders.createOrderEvents(payload),
    updateOrderEvent: (payload: updateOrderEventPayload) => dispatch.orders.updateOrderEvent(payload)
});

export default connect(mapStateToProps, mapDispatchToProps)(OrderEventFormConnected);
