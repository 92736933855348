import { shipmentContainer } from '../../../../../../models/entities/shipment/shipmentContainer';
import { ColumnType } from '../../../../../shared/Grid/types/Column';

const containerGridColumns = (localization: any): Array<ColumnType<shipmentContainer>> => {
    const { containerType, containerNumber, isOverweight, description } = localization.packages.grid.columns;
    return [
        {
            title: containerType,
            width: '100%',
            field: 'type',
            sortable: false,
            isDisplayed: true
        },
        {
            title: containerNumber,
            field: 'number',
            width: '100%',
            sortable: false,
            isDisplayed: true
        },
        {
            title: description,
            field: 'description',
            width: '100%',
            sortable: false,
            isDisplayed: true
        },
        {
            title: isOverweight,
            field: 'isOverweight',
            width: '100%',
            sortable: false,
            isDisplayed: true,
            columnType: 'bool'
        }
    ];
};

export default containerGridColumns;
