import { createSelector } from 'reselect';
import { RootState } from '../../store/store';

const historyUrls = (state: RootState) => state.pagesHistory.history;

export { default as pagesHistorySelectors } from './selectors';

export default {
    historyUrls
};
