import React, { useState } from 'react';
import styled from 'styled-components';
import moment from 'moment';
import { Formik, Form } from 'formik';
import EditIcon from '../../../../static/icons/edit.svg';
import Button from '../../../shared/SmallComponents/Button';
import EntityDetails, { detail } from '../../../shared/EntityDetails/EntityDetails';
import { tariff, createTariffPayload } from '../../../../models/entities/tariff';
import TariffOverviewHeader from './TariffOverviewHeader';
import Loader from '../../../shared/SmallComponents/Loader';
import { enumToDropdownOptions, Option } from '../../../shared/inputs/base/DropDown';
import { carriersDropDownOptionsByShipmentType, getAllowedTypes } from '../../singleShipment/ShipmentForm/sections/utils';
import { eShipmentTypes } from '../../../../models/entities/shipment/shipmentTypes';
import { eIncoterms } from '../../../../models/entities/shipment/incoterms';
import ShipmentTypeOption, { renderShipmentTypeOption } from '../../../shared/SmallComponents/ShipmentTypeOption';
import { shipmentType } from '../../../../models/entities/shipment/shipment';
import TariffChargesFormModal from '../TariffCharges/Form/Modal/TariffChargesFormModal';
import TariffChargesGrid from '../TariffCharges/Grid/TariffChargesGrid';
import { ModalTriggerProps } from '../../../shared/Modal/Modal';

type Props = {
    tariff: tariff;
    allowedCompaniesDropDownOptions: Option[];
    businessPartnersDropDownOptions: Option[];
    allowedShipmentTypes: Array<string>;
    localization: any;
    updateTariff: (tariffId: string, tariff: createTariffPayload) => Promise<void>;
};

const shipmentTypesDropDownOptions = enumToDropdownOptions(eShipmentTypes);
const incotermsDropDownOptions = enumToDropdownOptions(eIncoterms);

const TariffOverview = ({
    tariff,
    allowedCompaniesDropDownOptions,
    businessPartnersDropDownOptions,
    allowedShipmentTypes,
    localization,
    updateTariff
}: Props) => {
    const [detailsEdit, setDetailsEdit] = useState(false);

    const fieldsLocalization = localization?.form?.fields;
    const allowedShipmentTypesOptions: Option[] = getAllowedTypes(shipmentTypesDropDownOptions, allowedShipmentTypes);

    const initialValues: createTariffPayload = {
        companyId: tariff.companyId,
        businessPartnerId: tariff.businessPartnerId,
        shipmentTypeCode: tariff.shipmentTypeCode,
        shipmentSubTypeCode: tariff.shipmentSubTypeCode,
        incoterms: tariff.incoterms,
        carrier: tariff.carrier,
        originCountry: tariff.originCountry,
        originStation: tariff.originStation,
        destinationCountry: tariff.destinationCountry,
        destinationStation: tariff.destinationStation,
        notes: tariff.notes,
        activationDate: tariff.activationDate,
        expirationDate: tariff.expirationDate,
        transitTime: tariff.transitTime,
        forwarderTariffNumber: tariff.forwarderTariffNumber
    };

    const submit = async (values: createTariffPayload) => {
        await updateTariff(tariff.id, values);
        setDetailsEdit(false);
    };

    const tariffShipmentTypeOption = allowedShipmentTypesOptions.find((opt) => opt.value === tariff.shipmentTypeCode);
    const detailsData = (values: createTariffPayload, setFieldValue: any): Array<Array<detail<createTariffPayload>>> => [
        [
            {
                name: 'companyId',
                showCondition: true,
                editable: {
                    type: 'dropdown',
                    options: allowedCompaniesDropDownOptions
                },
                title: fieldsLocalization?.company?.title || 'Company',
                value: allowedCompaniesDropDownOptions.find((opt) => opt.value === tariff.companyId)?.text || '-'
            },
            {
                name: 'businessPartnerId',
                showCondition: true,
                editable: {
                    type: 'dropdown',
                    options: businessPartnersDropDownOptions
                },
                title: fieldsLocalization?.businessPartnerId?.title || 'Customer',
                value: businessPartnersDropDownOptions.find((opt) => opt.value === tariff.businessPartnerId)?.text || '-'
            },
            {
                name: 'shipmentTypeCode',
                showCondition: true,
                editable: {
                    type: 'dropdown',
                    options: allowedShipmentTypesOptions,
                    dropDownOptionRenderer: renderShipmentTypeOption
                },
                title: fieldsLocalization?.shipmentTypeCode?.title || 'Shipment Type',
                value: tariffShipmentTypeOption ? (
                    <ShipmentTypeOption value={tariffShipmentTypeOption.value} text={tariffShipmentTypeOption.text} hideOptionBorder />
                ) : (
                    '-'
                )
            }
        ],
        [
            {
                name: 'incoterms',
                showCondition: true,
                editable: {
                    type: 'dropdown',
                    dropDownMode: 'multiple',
                    options: incotermsDropDownOptions
                },
                title: fieldsLocalization?.incoterms?.title || 'Incoterms',
                value: Array.isArray(tariff.incoterms) ? tariff.incoterms?.join(', ') : tariff.incoterms
            },
            {
                name: 'carrier',
                showCondition: true,
                editable: {
                    type: 'dropdown',
                    options: carriersDropDownOptionsByShipmentType(values.shipmentTypeCode as shipmentType)
                },
                title: fieldsLocalization?.carrier?.title || 'Carrier',
                value: tariff.carrier.join(', ')
            },
            {
                name: 'transitTime',
                showCondition: true,
                editable: {
                    type: 'number'
                },
                title: fieldsLocalization?.transitTime?.title || 'Transit Time',
                value: `${tariff.transitTime}`
            }
        ],
        [
            {
                name: 'activationDate',
                showCondition: true,
                editable: {
                    type: 'date'
                },
                title: fieldsLocalization?.activationDate?.title || 'Activation Date',
                value: tariff.activationDate ? moment(tariff.activationDate).format('DD/MM/YYYY HH:mm') : undefined
            },
            {
                name: 'expirationDate',
                showCondition: true,
                editable: {
                    type: 'date'
                },
                title: fieldsLocalization?.expirationDate?.title || 'Expiration Date',
                value: tariff.expirationDate ? moment(tariff.expirationDate).format('DD/MM/YYYY HH:mm') : undefined
            }
        ],
        [
            {
                name: 'forwarderTariffNumber',
                showCondition: true,
                editable: {},
                title: fieldsLocalization?.forwarderTariffNumber?.title || 'Forwarder Tariff Number',
                value: tariff.forwarderTariffNumber
            },
            {
                name: 'notes',
                showCondition: true,
                editable: {
                    type: 'textarea'
                },
                title: fieldsLocalization?.note?.title || 'Note',
                value: tariff.notes
            }
        ]
    ];

    return (
        <OverviewWrapper>
            <TariffOverviewHeader tariff={tariff} localization={localization} allowedCompaniesDropDownOptions={allowedCompaniesDropDownOptions} />
            <OverviewSectionWrapper>
                <DetailsEditButton onClick={() => setDetailsEdit(!detailsEdit)}>
                    <Button margin="5px" buttonType={detailsEdit ? 'filled' : 'hollow'}>
                        <img src={EditIcon} alt={'Edit'} />
                    </Button>
                </DetailsEditButton>
                <Formik<createTariffPayload> initialValues={initialValues} onSubmit={submit} enableReinitialize>
                    {({ values, setFieldValue }) => {
                        return (
                            <Form>
                                <EntityDetails<createTariffPayload>
                                    detailsData={detailsData(values, setFieldValue)}
                                    values={initialValues}
                                    detailsEdit={detailsEdit}
                                />
                                {detailsEdit && (
                                    <div>
                                        <Button buttonType="filled" type="submit">
                                            {false ? <Loader width="20px" marginTop="0px" showText={false} /> : <span>{localization.saveBtn}</span>}
                                        </Button>
                                    </div>
                                )}
                            </Form>
                        );
                    }}
                </Formik>
            </OverviewSectionWrapper>
            <OverviewSectionWrapper>
                <TariffChargesFormModal
                    tariff={tariff}
                    localization={localization}
                    Trigger={(props: ModalTriggerProps) => (
                        <Button buttonType="filled" onClick={props.onClick}>
                            {localization.charges.addCharge}
                        </Button>
                    )}
                />
                <TariffChargesGrid tariff={tariff} localization={localization.charges} />
            </OverviewSectionWrapper>
        </OverviewWrapper>
    );
};

const OverviewWrapper = styled.div`
    display: flex;
    flex-direction: column;
    width: 100%;
`;

const DetailsEditButton = styled.div`
    display: inline-block;
    position: absolute;
    right: 0;
`;

const OverviewSectionWrapper = styled.div`
    position: relative;
    padding: 24px 18px 18px 48px;
    border-bottom: solid 1px ${(props) => props.theme.colors.grayDefaultBorder};
`;

export default TariffOverview;
