import React, { useEffect, useState } from 'react';
import Loader from '../../shared/SmallComponents/Loader';
import { styled } from '../../shared/Theme/theme';
import { shipment } from '../../../models/entities/shipment/shipment';
import DropDown from '../../shared/inputs/base/DropDown';
import { eShipmentTypes } from '../../../models/entities/shipment/shipmentTypes';
import { enumToDropdownOptions } from '../../shared/inputs/base/DropDown';
import { renderShipmentTypeOption } from '../../shared/SmallComponents/ShipmentTypeOption';
import { drawers } from '../../../state/ducks/header/header';
import useWindowSize from '../../../hooks/windowSize';
import { ColumnType } from '../../shared/Grid/types/Column';
import ShipmentItem from '../../shared/SmallComponents/ShipmentItem';

type Props = {
    localization: any;
    setDrawerEmptyState: (drawer: drawers, isEmpty: boolean) => void;
    setDrawerOpen: (open: drawers) => void;
    followingShipmentsData?: Array<shipment>;
    isLoadingShipments: boolean;
    isEmptyStateDrawer: boolean;
    gridColumns: Array<ColumnType<shipment>>;
};

const FollowingShipments = ({ localization, setDrawerEmptyState, isLoadingShipments, followingShipmentsData, setDrawerOpen, gridColumns }: Props) => {
    const [filteredShipmentData, setFilteredShipmentData] = useState(followingShipmentsData);
    const [filterTypeValue, setFilterTypeValue] = useState<any>(null);
    const [windowWidth] = useWindowSize();

    const shipmentTypesDropDownOptions = enumToDropdownOptions(eShipmentTypes);

    useEffect(() => {
        const filteredData = followingShipmentsData?.filter((shipment) => (filterTypeValue ? shipment.typeCode === filterTypeValue : shipment));
        setFilteredShipmentData(filteredData);

        if ((!followingShipmentsData?.length || !filteredData?.length) && !isLoadingShipments) {
            setDrawerEmptyState('FOLLOW', true);
        } else {
            setDrawerEmptyState('FOLLOW', false);
        }
    }, [followingShipmentsData, isLoadingShipments, filterTypeValue, setDrawerEmptyState]);

    const renderShipmentItems = () => {
        return filteredShipmentData?.map((shipment: shipment) => (
            <ShipmentItemContainer key={shipment.id}>
                <ShipmentItem
                    shipment={shipment}
                    gridColumns={gridColumns}
                    onClickRef={() => {
                        if (windowWidth < 1280) {
                            setDrawerOpen(null);
                        }
                    }}
                    hasMenu={true}
                />
            </ShipmentItemContainer>
        ));
    };
    return (
        <Container>
            <HeaderContainer>
                <FilterContainer>
                    <DropDown
                        placeHolder={localization.shipment_type}
                        style={{ width: '100%', margin: '10px 0' }}
                        options={shipmentTypesDropDownOptions}
                        optionRenderer={renderShipmentTypeOption}
                        value={filterTypeValue}
                        onChange={(value: any) => {
                            setFilterTypeValue(value);
                            const filteredData = followingShipmentsData?.filter((shipment) => shipment.typeCode === value);
                            setFilteredShipmentData(filteredData);
                        }}
                    />
                    <span
                        className="clear"
                        onClick={() => {
                            setFilterTypeValue(null);
                        }}
                    >
                        {localization.clear}
                    </span>
                </FilterContainer>
            </HeaderContainer>
            {!filteredShipmentData?.length && <EmptyState>{localization.empty_state}</EmptyState>}
            {isLoadingShipments ? <Loader /> : <ContentContainer>{followingShipmentsData && renderShipmentItems()}</ContentContainer>}
        </Container>
    );
};

const Container = styled.div`
    height: 100%;
    & :last-child {
        border: none;
    }
`;
const HeaderContainer = styled.div`
    width: 100%;
    padding: 10px 32px;
`;
const ContentContainer = styled.div`
    width: 100%;
    overflow-y: overlay;
    height: calc(100% - 84px);
    padding: 10px 32px 0 32px;
`;
const EmptyState = styled.div`
    color: #008ac9;
    margin: 20px;
    padding: 0 32px;
    font-size: 20px;
`;
const FilterContainer = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;

    & :first-child {
        margin-right: 5px;
    }

    .clear {
        color: #008ac9;
        cursor: pointer;
    }
`;

const Header = styled.div`
    font-size: 16px;
    font-weight: bold;
    color: #008ac9;
    padding: 4px;
`;

const ShipmentItemContainer = styled.div`
    padding: 8px 0;
`;

export default FollowingShipments;
