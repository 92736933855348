import React, { useState } from 'react';
import styled from 'styled-components';
import { Formik } from 'formik';
import { Form } from 'formik-antd';
import { Checkbox } from 'formik-antd';
import { Divider } from 'antd';
import Button from '../SmallComponents/Button';
import { arrayToExcel } from './utils';
import { cloneDeep } from 'lodash';

type Props<T> = {
    data: Array<T>;
    fileName: string;
    localization: any;
    setModalVisible?: (visible: boolean) => void;
    confirmation?: { setConfirm: React.Dispatch<React.SetStateAction<boolean>> };
};

type formValuesType<T> = {
    columns: Array<keyof T>;
    checkAll: boolean;
};
const ArrayToExcel = function <T>({ data, setModalVisible, confirmation, fileName, localization }: Props<T>) {
    const initialValues: formValuesType<T> = {
        columns: [],
        checkAll: false
    };

    return (
        <Formik
            initialValues={initialValues}
            onSubmit={({ columns }) => {
                const filteredData = cloneDeep(data);
                filteredData.map((obj) =>
                    (Object.keys(obj) as Array<keyof T>).forEach((key: keyof T) => {
                        if (!columns.includes(key)) {
                            delete obj[key];
                        }
                    })
                );
                arrayToExcel.convertArrayToTable<T>(filteredData, `${fileName}-${new Date().getTime()}`, localization);
                setModalVisible && setModalVisible(false);
            }}
        >
            {({ values, setFieldValue }) => {
                return (
                    <FormContainer>
                        <Form>
                            <Checkbox
                                name="checkAll"
                                onChange={(value) => {
                                    setFieldValue('columns', !values.checkAll ? (Object.keys(data[0]) as Array<keyof T>) : []);
                                }}
                            >
                                {localization.all_columns}
                            </Checkbox>
                            <Divider />
                            <Checkbox.Group
                                name="columns"
                                options={Object.keys(data[0]).map((key) => ({ label: localization[key] || key, value: key }))}
                                onChange={(value) => {
                                    setFieldValue('checkAll', value.length === Object.keys(data[0]).length);
                                }}
                            />
                            <Buttons>
                                <Button
                                    width="auto"
                                    height="30px"
                                    type="submit"
                                    buttonType="clean"
                                    textColor="#394372"
                                    onClick={() => setModalVisible && setModalVisible(false)}
                                >
                                    {localization.cancel}
                                </Button>
                                <Button buttonType="filled" width="100px" height="30px" type="submit" disabled={!values.columns.length}>
                                    {localization.export}
                                </Button>
                            </Buttons>
                        </Form>
                    </FormContainer>
                );
            }}
        </Formik>
    );
};

const FormContainer = styled.div`
    width: 100%;

    .ant-checkbox-group-item {
        display: inline-block;
        margin-right: 0;
        flex: 0 0 50%;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
        display: flex;
    }

    .ant-checkbox-group {
        display: flex;
        flex-wrap: wrap;
    }
`;

const Buttons = styled.div`
    width: 100%;
    padding-top: 25px;
    display: flex;
    justify-content: flex-end;

    button {
        margin-right: 20px;
    }
`;
export default ArrayToExcel;
