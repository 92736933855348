import { createSelector } from 'reselect';
import { settings as settingsModel } from '../../../models/entities/purchaseDemand/purchaseDemand';
import mockData from '../../../sideEffects/purchaseDemand/settings/mockData';
import { RootState } from '../../store/store';

const purchaseDemandsData = (state: RootState) => state.purchaseDemands.purchaseDemands;
const settings = (state: RootState) => state.user.userInfo.companySettings;

const currentLanguage = (state: RootState) => state.localization.currentLanguage;
const settingsByLanguage = createSelector(settings, currentLanguage, (settings, currentLanguage) => {
    const mockDataIndex = Object.keys(mockData).findIndex((item) => item === currentLanguage.symbol);
    const settingsObj: settingsModel = Object.values(mockData)[mockDataIndex] as settingsModel;

    if (settings) {
        settings
            .filter((s) => (s.entityType === 'PURCHASE_DEMAND' || !s.entityType) && s.language === currentLanguage.symbol)
            .forEach((s) => {
                settingsObj[s.key as keyof settingsModel] = s.value;
            });
        return settingsObj;
    }
});

export { default as purchaseDemandsSelectors } from './selectors';

export default {
    purchaseDemandsData,
    settingsByLanguage
};
