import React, { useState } from 'react';
import { settings, purchaseDemand } from '../../../../models/entities/purchaseDemand/purchaseDemand';
import TabsContainer from '../../../shared/Tabs/TabsContainer';
import Tab from '../../../shared/Tabs/Tab';
import History from '../../commonSubEntities/history/HistoryTab';
import ItemsTab from './ItemsTab';
import { settings as companySettings } from '../../../../models/entities/businessPartner/businessPartner';
import Documents from './Documents/DocumentsTab';

type Props = {
    purchaseDemand: purchaseDemand;
    localization?: any;
    settings?: settings;
    companySettings?: companySettings;
};

const PurchaseDemandTabs = ({ purchaseDemand, localization, settings, companySettings }: Props) => {
    const [activeTab, setActiveTab] = useState('Items');

    return (
        <TabsContainer>
            <Tab title="Items" onClick={() => setActiveTab('Items')} isActive={activeTab === 'Items'}>
                <ItemsTab purchaseDemand={purchaseDemand} items={purchaseDemand.items} localization={localization} settings={settings} />
            </Tab>
            <Tab title="Documents" onClick={() => setActiveTab('Documents')} isActive={activeTab === 'Documents'}>
                <Documents
                    documents={purchaseDemand.documents?.documents}
                    requiredDocs={purchaseDemand.documents?.requiredDocs}
                    localization={localization}
                    settings={settings}
                />
            </Tab>
            <Tab title="History" onClick={() => setActiveTab('History')} isActive={activeTab === 'History'}>
                <History history={purchaseDemand.history} localization={localization} />
            </Tab>
        </TabsContainer>
    );
};

export default PurchaseDemandTabs;
