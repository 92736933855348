import BaseController from '..';
import IUserRegistration from './IUserRegistration';
import { getStartedCreateCompanyPayload } from './../../../models/entities/company/getStartedCreateCompanyPayload';

export default class UserRegistration extends BaseController implements IUserRegistration {
    constructor(baseUrl: string) {
        super(baseUrl);
    }

    async sendEmailValidation(email: string) {
        const response = await this.client.post('/userRegistration/sendEmailValidation', JSON.stringify({ email }));
        return response;
    }

    async validateEmail(email: string, pinCode: string, pricingPlanId?: string, underForwarderToken?: string) {
        const response = await this.client.post(
            '/userRegistration/validateEmail',
            JSON.stringify({ email, pinCode, pricingPlanId, underForwarderToken })
        );
        if (response) {
            this.client.headers.Authorization = 'Bearer ' + response;
        }
        return response;
    }

    async validateCompanyName(name: string): Promise<boolean> {
        const response = await this.client.post('/userRegistration/validateCompanyName', JSON.stringify({ name }));
        return response;
    }

    async validateUnderForwarderToken(token: string): Promise<boolean> {
        const response = await this.client.post('/userRegistration/validateUnderForwarderToken', JSON.stringify({ underForwarderToken: token }));
        return response;
    }

    async createCompany(payload: getStartedCreateCompanyPayload) {
        const response = await this.client.post('/userRegistration/createCompany', JSON.stringify(payload));
        return response;
    }
}
