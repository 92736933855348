import React from 'react';
import { event } from '../../../../models/entities/common_subentities/event';
import { settings } from '../../../../models/entities/purchaseOrder/purchaseOrder';
import Events from '../../commonSubEntities/events/Events';
import EventFormConnected from '../EventForm/EventFormConnected';

type Props = {
    events?: Array<event>;
    localization: any;
    settings?: settings;
};

function PurchaseOrderEvents({ events, localization, settings }: Props) {
    return (
        <Events<settings>
            events={events}
            localization={localization}
            settings={settings}
            menuType="purchaseOrderEventMenu"
            EventFormConnected={EventFormConnected}
        />
    );
}

export default PurchaseOrderEvents;
