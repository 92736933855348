import React from 'react';
import { discussion } from '../../../../../models/entities/common_subentities/discussion';
import { userSelectors } from '../../../../../state/ducks/user/selectors';
import { RootState } from '../../../../../state/store/store';
import { connect } from 'react-redux';
import { singlePurchaseDemandSelectors } from '../../../../../state/ducks/singlePurchaseDemand/selectors';
import { userInfo } from '../../../../../models/entities/user';
import DiscussionsSection from '../../../commonSubEntities/discussions/DiscussionsSection';
import { userDetails } from '../../../../../state/ducks/users/users';
import { usersSelectors } from '../../../../../state/ducks/users/selectors';
import { shipment } from '../../../../../models/entities/shipment/shipment';
import { shipmentsSelectors } from '../../../../../state/ducks/shipments/selectors';
import { ColumnType } from '../../../../shared/Grid/types/Column';
import { allShipmentsPageSelectors } from '../../../../../state/ducks/allShipmentsPage/selectors';

type Props = {
    purchaseDemandId?: string;
    pdCzNumber?: string;
    discussions?: Array<discussion>;
    localization: any;
    currentUser: userInfo;
    createPurchaseDemandDiscussion: (purchaseDemandNumber: string, discussion: discussion, pdCzNumber?: string) => void;
    updatePurchaseDemandDiscussion: (purchaseDemandNumber: string, discussion: discussion, pdCzNumber?: string) => void;
    deletePurchaseDemandDiscussion: (purchaseDemandNumber: string, discussionId: string) => void;
    getUserById: (userId: string) => userDetails | undefined;
    getShipmentById: (id: string) => shipment | undefined;
    showLoader: boolean;
    gridColumns: Array<ColumnType<shipment>>;
};

const PurchaseDemandDiscussionsSection = ({
    purchaseDemandId,
    pdCzNumber,
    discussions,
    localization,
    currentUser,
    createPurchaseDemandDiscussion,
    updatePurchaseDemandDiscussion,
    deletePurchaseDemandDiscussion,
    showLoader,
    getUserById,
    getShipmentById,
    gridColumns
}: Props) => {
    return (
        <DiscussionsSection
            entityId={purchaseDemandId}
            entityName={pdCzNumber}
            discussions={discussions}
            localization={localization}
            currentUser={currentUser}
            createDiscussion={createPurchaseDemandDiscussion}
            updateDiscussion={updatePurchaseDemandDiscussion}
            deleteDiscussion={deletePurchaseDemandDiscussion}
            showLoader={showLoader}
            getUserById={getUserById}
            getShipmentById={getShipmentById}
            gridColumns={gridColumns}
        />
    );
};
const mapStateToProps = (state: RootState) => ({
    currentUser: userSelectors.userInfo(state),
    purchaseDemandId: singlePurchaseDemandSelectors.purchaseDemand(state)?.id,
    pdCzNumber: singlePurchaseDemandSelectors.purchaseDemand(state)?.CargoZoneNumber,
    discussions: singlePurchaseDemandSelectors.purchaseDemand(state)?.discussions,
    showLoader:
        state.loading.effects.purchaseDemands.createPurchaseDemandDiscussion ||
        state.loading.effects.purchaseDemands.updatePurchaseDemandDiscussion ||
        state.loading.effects.purchaseDemands.deletePurchaseDemandDiscussion,
    getUserById: (id: string) => usersSelectors.getUserById(state)(id),
    getShipmentById: (id: string) => shipmentsSelectors.getShipmentById(state, id),
    gridColumns: allShipmentsPageSelectors.gridColumns(state)
});

const mapDispatchToProps = (dispatch: any) => ({
    createPurchaseDemandDiscussion: (purchaseDemandNumber: string, discussion: discussion, pdCzNumber?: string) =>
        dispatch.purchaseDemands.createPurchaseDemandDiscussion({ purchaseDemandNumber, discussion, pdCzNumber }),
    updatePurchaseDemandDiscussion: (purchaseDemandNumber: string, discussion: discussion, pdCzNumber?: string) =>
        dispatch.purchaseDemands.updatePurchaseDemandDiscussion({ purchaseDemandNumber, discussion, pdCzNumber }),
    deletePurchaseDemandDiscussion: (purchaseDemandNumber: string, discussionId: string) =>
        dispatch.purchaseDemands.deletePurchaseDemandDiscussion({ purchaseDemandNumber, discussionId })
});

export default connect(mapStateToProps, mapDispatchToProps)(PurchaseDemandDiscussionsSection);
