import React from 'react';
import { hsCode } from '../../../models/entities/common_subentities/hsCode';
import HsCodeInput from '../inputs/form/HsCodeInput';
import { styled } from '../Theme/theme';

type Props = {
    name: string;
    values: any;
    localizations: any;
    codePlaceHolder: string;
    onChange?: (value: any) => void;
};
export const emptyHsCodeObj: hsCode = {
    code: '',
    percent: 0
};
const HsCodes = ({ name, values, onChange, localizations, codePlaceHolder }: Props) => {
    return (
        <>
            {values[name] &&
                values[name].map((item: hsCode, index: number) => {
                    return (
                        <Flex key={index} style={{ alignItems: 'center', marginBottom: '20px' }}>
                            <HsCodeInput
                                codeInputName={`${name}[${index}][code]`}
                                percentInputName={`${name}[${index}][percent]`}
                                codePlaceHolder={codePlaceHolder}
                            />
                            <DeleteContainer
                                onClick={() => {
                                    const hsCodes: Array<hsCode> = [...values[name]];
                                    hsCodes.splice(index, 1);
                                    onChange && onChange(hsCodes);
                                }}
                            >
                                <VerticalDivider style={{ marginLeft: '20px' }} />
                                <span className="material-icons">delete</span>
                            </DeleteContainer>
                        </Flex>
                    );
                })}
            <AddContainer
                onClick={() => {
                    let hsCodes: Array<hsCode> = [];
                    if (values[name]) hsCodes = [...values[name]];
                    hsCodes.push(emptyHsCodeObj);
                    onChange && onChange(hsCodes);
                }}
            >
                <span className="material-icons">add_circle</span>
                <span>{localizations.add_hscode}</span>
            </AddContainer>
        </>
    );
};

const DeleteContainer = styled.div`
    display: flex;
    color: ${(props) => props.theme.colors.primaryBlue};
    span.material-icons {
        cursor: pointer;
    }
`;
const VerticalDivider = styled.div`
    height: auto;
    width: 1px;
    background: rgb(0 0 0 / 14%);
    margin-right: 20px;
`;
const StyledFieldContainer = styled.div`
    padding-right: 30px;
`;

const Flex = styled.div`
    display: flex;
`;

const AddContainer = styled.div`
    cursor: pointer;
    display: inline-flex;
    align-items: center;
    font-size: 13px;
    line-height: 0;
    color: ${(props) => props.theme.colors.pageTitle};
    span.material-icons {
        font-size: inherit;
        margin-right: 5px;
        user-select: none;
    }
`;

export default HsCodes;
