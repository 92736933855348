import BaseController from '../..';
import { contact } from '../../../../models/entities/businessPartner/contact';
import IBusinessPartnersContacts from './IBusinessPartnersContacts';

export default class BusinessPartnersContacts extends BaseController implements IBusinessPartnersContacts {
    constructor(baseUrl: string) {
        super(baseUrl);
    }
    async createBusinessPartnerContact(businessPartnerId: string, data: contact) {
        const response = await this.client.post(`/businessPertner/contacts`, JSON.stringify({ ...data, businessPartnerId }));
        return response;
    }
    async updateBusinessPartnerContact(businessPartnerId: string, data: contact) {
        const response = await this.client.put(`/businessPertner/contacts`, JSON.stringify(data));
        return response;
    }
    async deleteBusinessPartnerContact(businessPartnerId: string, contactId: number) {
        const response = await this.client.delete(`/businessPertner/contacts`, JSON.stringify({ businessPartnerId, id: contactId }));
        return response;
    }
}
