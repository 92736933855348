import React from 'react';
import { Page, Text, View, Document, StyleSheet, Image, Font } from '@react-pdf/renderer';
import { purchaseOrder } from '../../../models/entities/purchaseOrder/purchaseOrder';
import PdfEntityDetails from '../../shared/PDF/PdfEntityDetails';
import { detailsData } from './sections/overviewUtils';
import PdfTable from '../../shared/PDF/PdfTable';
import { PoItemsGridColDef } from './PurchaseOrderForm/sections/Items/PoItemsGridColDef';
import { currencyFormatter } from '../../shared/inputs/form/CurrencyInput/CurrencyInput';
import { localizationSelectors } from '../../../state/ducks/localization/selectors';
import { RootState } from '../../../state/store/store';
import { connect } from 'react-redux';
import moment from 'moment';
import countries from '../../../utils/countries-flags/countries';
import SvgComponent from '../../shared/PDF/SvgPdf';

type PurchaseOrderPdfDocumentProps = {
    purchaseOrder: purchaseOrder;
    localization: any;
    allPurchaseOrdersLocalization: any;
    hidePrices?: boolean;
};

// Register Font
Font.register({ family: 'Rubik', src: 'https://fonts.gstatic.com/s/rubik/v3/4sMyW_teKWHB3K8Hm-Il6A.ttf' });

Font.register({
    family: 'Roboto',
    fonts: [
        {
            src: `https://fonts.gstatic.com/s/heebo/v7/NGS6v5_NC0k9P-HxR7BDsbMB.ttf`
        },
        {
            src: `https://fonts.gstatic.com/s/heebo/v7/NGS3v5_NC0k9P9kFbpRLmq8I0LVF.ttf`,
            fontWeight: 'bold'
        }
    ]
});

const PurchaseOrderPdfDocument = ({ purchaseOrder, localization, allPurchaseOrdersLocalization, hidePrices }: PurchaseOrderPdfDocumentProps) => {
    const vendor = purchaseOrder.parties?.find((p) => p.businessPartnerId === purchaseOrder.vendorId);
    const consignee = purchaseOrder.parties?.find(
        (p) => p.businessPartnerId === purchaseOrder.consigneeId || (purchaseOrder.consigneeSameAsCompany && p.companyId === purchaseOrder.companyId)
    );

    const originCountryName = countries.find((item) => item.code === purchaseOrder.originCountry)?.name;
    const destinationCountryName = countries.find((item) => item.code === purchaseOrder.destinationCountry)?.name;

    return (
        <Document title={`#${purchaseOrder.CargoZoneNumber}`}>
            <Page style={styles.body}>
                <View style={styles.headerWrapper}>
                    <View style={styles.headerSection}>
                        <View style={styles.poNumberWrapper}>
                            <View style={styles.poNumber}>
                                <Text>{localization.purchaseOrder}</Text>
                                <Text> </Text>
                                <Text>{` #${purchaseOrder.CargoZoneNumber}`}</Text>
                            </View>
                        </View>
                        <View style={styles.refsWrapper}>
                            <Text style={{ marginRight: 0, fontWeight: 'bold' }}>{'Open Date: '}</Text>
                            <Text style={styles.ref}>{moment(purchaseOrder.createdAt).format('DD/MM/YYYY')}</Text>
                        </View>
                        <View style={styles.refsWrapper}>
                            <Text style={{ marginRight: 0, fontWeight: 'bold' }}>{localization.client_ref}</Text>
                            {purchaseOrder.references
                                ?.filter((r) => r.companyType === 'CLIENT')
                                ?.map((ref, index) => {
                                    return <Text key={`client_ref_${index}`} style={styles.ref}>{`${index !== 0 ? '| ' : ''}${ref.value}`}</Text>;
                                })}
                        </View>
                    </View>
                    <View style={styles.headerSectionSeperator} />
                    <View style={styles.headerSection}>
                        <View style={styles.wrapper}>
                            <Text>{localization.company}:</Text>
                            <Text style={{ fontWeight: 'bold' }}>{purchaseOrder.companyName}</Text>
                        </View>
                        {purchaseOrder.companyLogoUrl && <Image src={purchaseOrder.companyLogoUrl} style={{ width: 80 }} />}
                    </View>
                    <View style={styles.headerSectionSeperator} />
                    <View style={styles.headerSection}>
                        <Text style={styles.userName}>{purchaseOrder.userName}</Text>
                    </View>
                </View>
                <View style={styles.seperator} />
                <View style={styles.headerWrapper}>
                    <View style={styles.companySectionWrapper}>
                        <Text style={{ ...styles.sectionHeader, width: '80px' }}>Supplier Details</Text>
                        {!!vendor && renderDetails(vendor, localization)}
                        <Text style={styles.detailWrapper}>
                            {[purchaseOrder.originPickupAddress, purchaseOrder.originZipCode, originCountryName].filter((c) => !!c).join(', ')}
                        </Text>
                    </View>
                    <View style={styles.headerSectionSeperator} />
                    <View style={styles.companySectionWrapper}>
                        <Text style={{ ...styles.sectionHeader, width: '45px' }}>Ship to</Text>
                        {!!consignee && renderDetails(consignee, localization)}
                        <Text style={styles.detailWrapper}>
                            {[purchaseOrder.destinationPickupAddress, purchaseOrder.destinationZipCode, destinationCountryName]
                                .filter((c) => !!c)
                                .join(', ')}
                        </Text>
                    </View>
                </View>
                <View style={styles.seperator} />
                <PdfEntityDetails
                    detailsData={detailsData(purchaseOrder, {}, undefined, localization, false, undefined, undefined, undefined, undefined, true)}
                    detailsEdit={false}
                    excludedFields={hidePrices ? ['paymentDates', 'paymentTerms', 'taxesRate', 'totalCost'] : []}
                />
                {!!purchaseOrder.items?.length && (
                    <>
                        <View style={styles.seperator} />
                        <PdfTable
                            columns={PoItemsGridColDef(purchaseOrder.items, allPurchaseOrdersLocalization.form)}
                            rows={purchaseOrder.items || []}
                            excludeFields={['Img', 'id', 'purchaseDemandId', 'shipmentsRefs', 'pricePerUnit'].concat(
                                hidePrices ? ['price', 'currency', 'pricePerUnit'] : []
                            )}
                            valueFormatter={(field: string, value: any, row: any) => {
                                switch (field) {
                                    case 'price':
                                        return value && row['currency'] ? currencyFormatter(row['currency'])(value) : '-';
                                    case 'currency':
                                        return value && row['price'] && row['quantity']
                                            ? currencyFormatter(value)((Number(row['price']) || 0) * (Number(row['quantity']) || 1) || 0)
                                            : '-';
                                    case 'qcApproved':
                                        return !!value;
                                    case 'unitsPerMasterCarton':
                                        const unitsPerMasterCarton = Number(row['unitsPerMasterCarton']);
                                        const quantity = Number(row['quantity']);

                                        if (!unitsPerMasterCarton) return '-';

                                        const cartonsNumber = Math.ceil(quantity / unitsPerMasterCarton);
                                        return cartonsNumber;
                                    case 'openedQuantity':
                                        const openedQuantity = Number(row['openedQuantity'] || 0);
                                        const quantityForOpenedQuantity = Number(row['quantity'] || 0);

                                        return quantityForOpenedQuantity - openedQuantity;
                                    default:
                                        return value;
                                }
                            }}
                            title="Items"
                        />
                    </>
                )}
            </Page>
        </Document>
    );
};

const renderDetails = (obj: object, localization: any) =>
    Object.entries(obj)
        ?.filter(([key, value]) => !!value && !['id', 'type', 'address'].some((p) => key.toLowerCase().includes(p)))
        .map(([key, value], index) => {
            if (key === 'logoUrl' && value)
                return (
                    <View key={`company_detail_${index}`} style={styles.detailWrapper}>
                        <Image src={value} style={{ width: 40 }} />
                    </View>
                );
            return (
                <View style={styles.detailWrapper} key={`company_detail_${index}`}>
                    {key !== 'name' && <Text style={{ fontWeight: 'bold' }}>{localization[key] || key}: </Text>}
                    <Text> </Text>
                    <Text>{value}</Text>
                </View>
            );
        });
const styles = StyleSheet.create({
    body: {
        padding: 20,
        fontFamily: 'Roboto',
        fontSize: '8px'
    },
    headerWrapper: {
        display: 'flex',
        flexDirection: 'row',
        marginTop: 10
    },
    headerSectionSeperator: {
        width: '0.5px',
        backgroundColor: '#bfbfbf',
        margin: '0 10px'
    },
    headerSection: {
        display: 'flex',
        flexDirection: 'column',
        color: '#394372',
        maxWidth: '250px',
        overflow: 'hidden'
    },
    poNumberWrapper: {
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        fontWeight: 'bold'
    },
    poNumber: {
        fontSize: '10px',
        fontWeight: 'bold',
        height: '24px',
        display: 'flex',
        flexDirection: 'row',
        flexWrap: 'wrap'
    },
    refsWrapper: {
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        flexWrap: 'wrap',
        padding: '3px 0'
    },
    ref: {
        marginLeft: '3px'
    },
    wrapper: {
        flexDirection: 'column',
        flexWrap: 'wrap',
        color: '#394372'
    },
    userName: {
        color: '#00b5b9',
        fontSize: '11px'
    },
    seperator: {
        margin: '20px 0',
        width: 'auto',
        backgroundColor: '#bfbfbf',
        height: '.5px'
    },
    detailWrapper: {
        display: 'flex',
        flexDirection: 'row',
        color: '#394372',
        margin: '2px 0'
    },
    companySectionWrapper: {
        fontSize: 10,
        flex: 1,
        maxWidth: '200px'
    },
    sectionHeader: {
        color: '#394372',
        fontWeight: 'bold',
        borderBottom: '0.5px solid #394372',
        padding: '0 5px',
        marginBottom: 4
    }
});

export default PurchaseOrderPdfDocument;
