import React from 'react';
import { Option } from '../../shared/inputs/base/DropDown';
import { FormTextArea, FormTextInput, FormDatePicker, FormDropDown, CurrencyInput, FormNumberInput } from '../../shared/inputs/form';
import { currencyFormatter } from '../../shared/inputs/form/CurrencyInput/CurrencyInput';
import { styled } from '../../shared/Theme/theme';
import { featureFlagsModel } from '../../../models/entities/businessPartner/settings';
import { camalize } from '../../../utils/utils-functions';
import ViewHsCodes from '../../shared/HsCodes/view';
import { Link } from 'react-router-dom';

export type editableDetail = {
    name: string;
    label?: string;
    type?: editTypes;
    options?: Option[];
    placeholder?: string;
    onChange?: any;
    suffix?: JSX.Element;
    referenceName?: string;
    min?: number;
    step?: number;
    max?: number;
    localizations?: any;
};
export type editTypes = 'dropdown' | 'date' | 'currency' | 'unit' | 'checkbox' | 'textarea' | 'dimension' | 'number' | 'users' | 'link' | 'hsCodes';
export const renderEditable = (editable: editableDetail, values: any, renderFieldLabel: (...params: any) => JSX.Element, viewMode?: boolean) => {
    const { type, name, options, placeholder, label, onChange, referenceName, min, step, max, suffix, localizations } = editable;
    const _placeholder = placeholder || label || name;
    let comp = <FormTextInput name={name} placeHolder={_placeholder} style={{ width: '100%' }} />;
    if (!viewMode)
        switch (type) {
            case 'dropdown':
                comp = (
                    <FormDropDown
                        name={name}
                        style={{ width: '100%' }}
                        placeHolder={_placeholder}
                        options={options || []}
                        showSearch={true}
                        onChange={onChange}
                    />
                );
                break;
            case 'date':
                comp = <FormDatePicker name={name} style={{ width: '100%' }} placeHolder={_placeholder} showTime />;
                break;
            case 'currency':
                comp = (
                    <CurrencyInput
                        currencyValue={values[`${name}Currency`] ?? 'USD'}
                        numberInputName={name}
                        numberPlaceholder={_placeholder}
                        unitInputName={`${name}Currency`}
                        numberInputWidth="100%"
                    />
                );
                break;
            case 'textarea':
                comp = <FormTextArea name={name} placeHolder={_placeholder} style={{ width: '100%' }} />;
                break;
            case 'number':
                comp = (
                    <FormNumberInput name={name} placeHolder={_placeholder} min={min} step={step} max={max} width="100%" style={{ width: '100%' }} />
                );
                break;
        }
    return (
        <>
            <div>{renderFieldLabel(label, false)}</div>
            {suffix ? (
                <div style={{ display: 'flex' }}>
                    <span style={{ flex: 1 }}>{comp}</span>
                    {suffix}
                </div>
            ) : (
                comp
            )}
        </>
    );
};
const Value = styled.div`
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
`;

type onChangeType = { [key: string]: (value: any) => void };
type suffixType = { [key: string]: JSX.Element };
type fieldsArrProps = {
    localizations: any;
    options?: {
        product: Option[];
        warehouse: Option[];
        actionType: Option[];
    };
    onChange?: onChangeType;
    suffix?: suffixType;
    featureFlags?: featureFlagsModel;
};
export type sectionType = { title?: string; items: Array<editableDetail>; hide?: boolean };
type fieldsArrType = Array<sectionType>;
export const formFieldsArr: (props: fieldsArrProps) => fieldsArrType = ({ localizations, options, onChange, featureFlags, suffix }) => [
    {
        items: [
            {
                name: 'productId',
                label: localizations.product,
                type: 'dropdown',
                options: options?.product
            },
            {
                name: 'actionType',
                label: localizations.actionType,
                type: 'dropdown',
                options: options?.actionType
            }
        ]
    },
    {
        items: [
            {
                name: 'cost',
                label: localizations.cost,
                type: 'currency'
            },
            {
                name: 'quantity',
                label: localizations.quantity
            }
        ]
    },
    {
        items: [
            {
                name: 'warehouseId',
                label: localizations.warehouse,
                type: 'dropdown',
                options: options?.warehouse,
                suffix: suffix?.warehouseId
            },
            {
                name: 'location',
                label: localizations.location
            }
        ]
    },
    {
        items: [
            {
                name: 'reason',
                viewMode: true,
                label: localizations.reason,
                type: 'textarea'
            }
        ]
    }
];
