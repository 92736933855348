import { createSelector } from 'reselect';
import { RootState } from '../../store/store';
import { Option } from '../../../components/shared/inputs/base/DropDown';
import { category } from '../../../models/entities/category/category';

const categories = (state: RootState) => state.categories.categories;

const getCategoriesOptionsByCompanyId = createSelector(categories, (categories) => (companyId: string): Option[] => {
    return categories
        .filter((c) => c.companyId === companyId)
        .map((category: category) => {
            return { value: category.id, text: category.name };
        });
});

export { default as categoriesSelectors } from './selectors';

export default {
    getCategoriesOptionsByCompanyId,
    categories
};
