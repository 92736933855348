import React from 'react';
import styled, { css } from 'styled-components';
import { mapCodeIcon } from '../../../utils/countries-flags';
import CZTooltip from '../CZTooltip/CZTooltip';
import { Option } from '../inputs/base/DropDown';

type Props = {
    country: string;
    code: string;
    flagSize?: string;
    removeBorder?: boolean;
    paddingBottom?: number;
    flagTooltip?: string;
    color?: string;
};

const CountryOption = ({ country, code, flagSize, removeBorder, paddingBottom, flagTooltip, color }: Props) => {
    const flagURL = mapCodeIcon(code.toUpperCase());
    return (
        <Wrapper removeBorder={removeBorder} paddingBottom={paddingBottom}>
            {flagTooltip ? (
                <>
                    <CZTooltip text={flagTooltip} zIndex={1500}>
                        <FlagIcon alt={country} src={flagURL} width={flagSize} />
                    </CZTooltip>
                    <CountryName color={color}> {country} </CountryName>
                </>
            ) : (
                <>
                    <FlagIcon alt={country} src={flagURL} width={flagSize} /> <CountryName> {country} </CountryName>
                </>
            )}
        </Wrapper>
    );
};

export const renderCountryOption = (option: Option) => {
    return <CountryOption country={option.text} code={option.value} />;
};

type StyledWrapper = {
    removeBorder?: boolean;
    paddingBottom?: number;
};

const Wrapper = styled.div<StyledWrapper>`
    display: flex;
    justify-content: left;
    align-items: center;
    border-bottom: 1px solid #dadada;
    padding-bottom: 3px;

    ${(props) =>
        props.removeBorder &&
        css`
            border: none;
        `}
    ${(props) =>
        props.paddingBottom &&
        css`
            padding-bottom: ${props.paddingBottom + 'px'};
        `}
`;

type StyledFlag = {
    width?: string;
};

const FlagIcon = styled.img<StyledFlag>`
    width: ${(props) => props.width ?? '20px'};
    margin-right: 6px;
`;

const CountryName = styled.div<{ color?: string }>`
    color: ${(props) => props.color || props.theme.colors.gridCell};
    font-size: ${(props) => props.theme.fontSizes.gridCell};
    font-weight: 400;
`;

export default CountryOption;
