import React, { useState } from 'react';
import { styled } from '../../../../../shared/Theme/theme';
import { Form, Formik } from 'formik';
import { localizationSelectors } from '../../../../../../state/ducks/localization/selectors';
import { RootState } from '../../../../../../state/store/store';
import { connect } from 'react-redux';
import Button from '../../../../../shared/SmallComponents/Button';
import { ModalFooter, ModalFormContainer } from '../../../../../shared/Modal/Modal';
import Loader from '../../../../../shared/SmallComponents/Loader';
import { FormTextInput, CurrencyInput, NumberUnitInput, DimensionInput, FormNumberInput, FormCheckbox } from '../../../../../shared/inputs/form';
import FieldLabel from '../../../../../shared/SmallComponents/FieldLabel';
import { attributeOption } from '../../../../../../models/entities/product/attribute';
import { validationSchema } from '../../../../../../validationSchemas/attributeOptionForm';

export enum formMode {
    NEW,
    EDIT
}

type Props = {
    attributeOption?: attributeOption;
    mode: formMode;
    localizations?: any;
    openedFromModal?: {
        setVisible: React.Dispatch<React.SetStateAction<boolean>>;
    };
    createProductAttributeOption: (productId: string, attributeId: string, attributeOption: attributeOption) => Promise<boolean>;
    updateProductAttributeOption: (productId: string, attributeId: string, attributeOption: attributeOption) => Promise<boolean>;
    productId: string;
    attributeId: string;
};

const AttributeOptionFormConnected = ({
    productId,
    attributeId,
    attributeOption,
    localizations,
    mode,
    openedFromModal,
    createProductAttributeOption,
    updateProductAttributeOption
}: Props) => {
    const [submitLoading, setLoading] = useState(false);
    let initFormValues = {
        name: null,
        skuAdjustment: null,
        costAdjustment: null,
        costAdjustmentCurrency: 'USD',
        priceAdjustment: null,
        priceAdjustmentCurrency: 'USD',
        weightAdjustment: null,
        weightAdjustmentUnit: 'KG',
        heightAdjustment: null,
        widthAdjustment: null,
        lengthAdjustment: null,
        dimensionAdjustmentUnit: 'CM',
        displayOrder: null,
        isDefault: false
    };
    const setInitialValues = () => {
        if (!attributeOption || mode === formMode.NEW) {
            return initFormValues;
        } else {
            return {
                name: attributeOption.name,
                skuAdjustment: attributeOption.skuAdjustment,
                costAdjustment: attributeOption.costAdjustment,
                costAdjustmentCurrency: attributeOption.costAdjustmentCurrency || 'USD',
                priceAdjustment: attributeOption.priceAdjustment,
                priceAdjustmentCurrency: attributeOption.priceAdjustmentCurrency || 'USD',
                weightAdjustment: attributeOption.weightAdjustment,
                weightAdjustmentUnit: attributeOption.weightAdjustmentUnit || 'KG',
                heightAdjustment: attributeOption.heightAdjustment,
                widthAdjustment: attributeOption.widthAdjustment,
                lengthAdjustment: attributeOption.lengthAdjustment,
                dimensionAdjustmentUnit: attributeOption.dimensionAdjustmentUnit || 'CM',
                displayOrder: attributeOption.displayOrder,
                isDefault: attributeOption.isDefault
            };
        }
    };
    const validate = (values: any) => {
        const errors: any = {};
        return errors;
    };
    const formValidationSchema = validationSchema(localizations.attributeOptions.form);
    const submit = (values: any) => {
        const payload: attributeOption = {
            ...values,
            costAdjustment: values.costAdjustment ? Number(values.costAdjustment) : null,
            costAdjustmentCurrency: values.costAdjustment ? values.costAdjustmentCurrency : null,
            priceAdjustment: values.priceAdjustment ? Number(values.priceAdjustment) : null,
            priceAdjustmentCurrency: values.priceAdjustment ? values.priceAdjustmentCurrency : null,
            weightAdjustment: values.weightAdjustment ? Number(values.weightAdjustment) : null,
            weightAdjustmentUnit: values.weightAdjustment ? values.weightAdjustmentUnit : null,
            heightAdjustment: values.heightAdjustment ? Number(values.heightAdjustment) : null,
            widthAdjustment: values.widthAdjustment ? Number(values.widthAdjustment) : null,
            lengthAdjustment: values.lengthAdjustment ? Number(values.lengthAdjustment) : null,
            dimensionAdjustmentUnit:
                values.heightAdjustment && values.widthAdjustment && values.lengthAdjustment ? values.dimensionAdjustmentUnit : null
        } as attributeOption;
        setLoading(true);
        if (mode === formMode.NEW)
            createProductAttributeOption(productId, attributeId, payload)
                .then((value) => {
                    if (value) {
                        // api success
                        if (openedFromModal) openedFromModal.setVisible(false);
                    }
                })
                .finally(() => {
                    setLoading(false);
                });
        else if (attributeOption) {
            updateProductAttributeOption(productId, attributeId, {
                ...payload,
                id: attributeOption.id
            })
                .then((value) => {
                    if (value) {
                        // api success
                        if (openedFromModal) openedFromModal.setVisible(false);
                    }
                })
                .finally(() => {
                    setLoading(false);
                });
        }
    };
    return (
        <Formik initialValues={setInitialValues()} validationSchema={formValidationSchema} validate={validate} onSubmit={submit}>
            {({ values, errors, handleSubmit }) => {
                return (
                    <Form>
                        <Container>
                            <FieldLabel>{localizations.attributeOptions.form.name}</FieldLabel>
                            <FieldContainer>
                                <FormTextInput name="name" placeHolder={localizations.attributeOptions.form.name} />
                            </FieldContainer>

                            <FieldLabel>{localizations.attributeOptions.form.sku}</FieldLabel>
                            <FieldContainer>
                                <FormTextInput name="skuAdjustment" placeHolder={localizations.attributeOptions.form.sku} />
                            </FieldContainer>

                            <FieldLabel>{localizations.attributeOptions.form.cost}</FieldLabel>
                            <FieldContainer>
                                <CurrencyInput
                                    currencyValue={values['costAdjustmentCurrency']}
                                    numberInputName={'costAdjustment'}
                                    numberPlaceholder={localizations.attributeOptions.form.cost}
                                    unitInputName={'costAdjustmentCurrency'}
                                    numberInputWidth="100%"
                                />
                            </FieldContainer>

                            <FieldLabel>{localizations.attributeOptions.form.price}</FieldLabel>
                            <FieldContainer>
                                <CurrencyInput
                                    currencyValue={values['priceAdjustmentCurrency']}
                                    numberInputName={'priceAdjustment'}
                                    numberPlaceholder={localizations.attributeOptions.form.price}
                                    unitInputName={'priceAdjustmentCurrency'}
                                    numberInputWidth="100%"
                                />
                            </FieldContainer>

                            <FieldLabel>{localizations.attributeOptions.form.weight}</FieldLabel>
                            <FieldContainer>
                                <NumberUnitInput
                                    numberInputName={'weightAdjustment'}
                                    numberInputWidth="100%"
                                    min={0}
                                    step={0.001}
                                    unitInputName={'weightAdjustmentUnit'}
                                    numberPlaceholder={localizations.attributeOptions.form.weight}
                                    unitOptions={[
                                        { value: 'KG', text: localizations.attributeOptions.form.KG },
                                        { value: 'LB', text: localizations.attributeOptions.form.LB }
                                    ]}
                                />
                            </FieldContainer>

                            <FieldLabel>{localizations.attributeOptions.form.dimension}</FieldLabel>
                            <FieldContainer>
                                <DimensionInput
                                    lengthInputName={'lengthAdjustment'}
                                    widthInputName={'widthAdjustment'}
                                    heightInputName={'heightAdjustment'}
                                    unitInputName={'dimensionAdjustmentUnit'}
                                    unitPlaceHolder={localizations.attributeOptions.form.dimension}
                                    options={[
                                        { value: 'CM', text: localizations.attributeOptions.form.CM },
                                        { value: 'IN', text: localizations.attributeOptions.form.IN }
                                    ]}
                                />
                            </FieldContainer>

                            <FieldLabel>{localizations.attributeOptions.form.displayOrder}</FieldLabel>
                            <FieldContainer>
                                <FormNumberInput
                                    name="displayOrder"
                                    placeHolder={localizations.attributeOptions.form.displayOrder}
                                    style={{ width: '100%' }}
                                />
                            </FieldContainer>

                            <FormCheckbox name="isDefault">{localizations.attributeOptions.form.isDefault}</FormCheckbox>
                        </Container>
                        {openedFromModal && (
                            <FormFooter>
                                <Button
                                    minWidth="55px"
                                    buttonType="filled"
                                    type="button"
                                    onClick={() => {
                                        handleSubmit();
                                    }}
                                >
                                    {submitLoading ? (
                                        <Loader width="20px" marginTop="0px" showText={false} />
                                    ) : (
                                        <span>
                                            {mode === formMode.NEW
                                                ? localizations.attributeOptions.modal.add_btn
                                                : localizations.attributeOptions.modal.edit_btn}
                                        </span>
                                    )}
                                </Button>
                            </FormFooter>
                        )}
                    </Form>
                );
            }}
        </Formik>
    );
};
const FieldContainer = styled.div`
    margin-bottom: 12px;
`;

const Container = styled(ModalFormContainer)``;

const FormFooter = styled(ModalFooter)`
    position: absolute;
    bottom: 0;
    width: 100%;
    left: 0;
    right: 0;
    background: white;
    justify-content: flex-end;
`;
const mapStateToProps = (state: RootState) => ({
    localizations: localizationSelectors.products(state).attributes
});

const mapDispatchToProps = (dispatch: any) => ({
    createProductAttributeOption: (productId: string, attributeId: string, attributeOption: attributeOption) =>
        dispatch.products.createProductAttributeOption({ productId, attributeId, attributeOption }),
    updateProductAttributeOption: (productId: string, attributeId: string, attributeOption: attributeOption) =>
        dispatch.products.updateProductAttributeOption({ productId, attributeId, attributeOption })
});

export default connect(mapStateToProps, mapDispatchToProps)(AttributeOptionFormConnected);
