import { RootState } from '../../store/store';
import { createSelector } from 'reselect';
import { orderBy } from 'lodash';
import filterProducts from './inventoryGridFilter';
import { inventoryTotalView } from '../../../models/entities/product/inventoryLog';
import { gridSortCallback } from '../../../utils/sortArray';

const inventoryData = (state: RootState) => state.inventory.inventoryLogs;
const gridColumns = (state: RootState) => state.inventory.gridColumns;
const gridSort = (state: RootState) => state.inventory.gridSort;
const gridFilter = (state: RootState) => state.inventory.gridFilter;
const filterFields = (state: RootState) => state.inventory.filterFields;
const pagination = (state: RootState) => state.inventory.pagination;

const activeInventoryCount = createSelector(inventoryData, (productsData: Array<inventoryTotalView>) => {
    return productsData.length;
});
const gridData = createSelector(
    inventoryData,
    gridSort,
    gridFilter,
    filterFields,
    (inventoryData: Array<inventoryTotalView>, gridSort, gridFilter, filterFields) => {
        // const settings: settings = settingsByLanguage();

        if (gridFilter && gridFilter !== '' && gridFilter.length > 2) {
            inventoryData = inventoryData.filter((s) => JSON.stringify(s).toLocaleLowerCase().includes(gridFilter.toLocaleLowerCase()));
        }

        if (filterFields) {
            inventoryData = filterProducts(inventoryData, filterFields);
        }

        if (gridSort) {
            switch (gridSort.column) {
                default:
                    inventoryData = orderBy(inventoryData, gridSortCallback(gridSort.column), [gridSort.direction]);
            }
        }

        return inventoryData;
    }
);

const filteredInventoryCount = createSelector(gridData, (gridData) => {
    return gridData?.length || 0;
});
export { default as inventorySelectors } from './selectors';

export default {
    inventoryData,
    gridColumns,
    gridData,
    filterFields,
    gridSort,
    gridFilter,
    activeInventoryCount,
    filteredInventoryCount,
    pagination
};
