import * as Yup from 'yup';
import { phonePattern } from '../../utils/patterns';

export const createCompanySchema = (localization: any) => {
    const maxCharsMessage = (num: number) => `${localization.errors.max} ${num} ${localization.errors.chars}`;
    return Yup.object().shape({
        name: Yup.string().required(localization.errors.required).test('len', maxCharsMessage(150), maxLengthValidation(150)),
        type: Yup.string().required(localization.errors.required).test('len', maxCharsMessage(10), maxLengthValidation(10)),
        vat_number: Yup.string().test('len', maxCharsMessage(20), maxLengthValidation(20)),
        address: Yup.string().test('len', maxCharsMessage(250), maxLengthValidation(250)),
        phoneNumber: Yup.string()
            .matches(new RegExp(phonePattern), localization.errors.invalid_phone_num)
            .test('len', maxCharsMessage(20), maxLengthValidation(20))
    });
};

const maxLengthValidation = (maxLength: number) => {
    return (val: any) => (val ? val.toString().length <= maxLength : true);
};
