import { billingLine } from '../../../../models/entities/common_subentities/billingLine';
import { event } from '../../../../models/entities/common_subentities/event';
type stateType = { [key: string]: any };
function setBillingLines(state: stateType, entityId: string, entityType: keyof stateType, billingLines: Array<billingLine>) {
    const newArr = [...state[entityType]];
    const index = newArr.findIndex((item) => item.id === entityId);
    if (index !== -1) {
        newArr[index].billingLines = billingLines;
    }
    return newArr;
}
function addBillingLine(state: stateType, entityId: string, entityType: keyof stateType, billingLine: billingLine) {
    const newArr = [...state[entityType]];
    const index = newArr.findIndex((item) => item.id === entityId);
    if (index !== -1) {
        let billingLines = [billingLine];
        const oldBillingLines = newArr[index].billingLines;
        if (oldBillingLines && oldBillingLines.length > 0) billingLines = [...oldBillingLines, ...billingLines];
        newArr[index].billingLines = billingLines;
    }
    return newArr;
}
function editBillingLine(state: stateType, entityId: string, entityType: keyof stateType, billingLine: billingLine) {
    const newArr = [...state[entityType]];
    const index = newArr.findIndex((item) => item.id === entityId);

    if (index !== -1) {
        const billingLines = newArr[index].billingLines?.map((item: billingLine) => {
            if (item.id === billingLine.id) {
                item.remarks = billingLine.remarks;
                item.serviceType = billingLine.serviceType;
                item.currency = billingLine.currency;
                item.income = billingLine.income;
                item.expense = billingLine.expense;
                item.totalCost = billingLine.totalCost;
                item.relativeCost = billingLine.relativeCost;
                item.profit = billingLine.profit;
                item.date = billingLine.date;
            }
            return { ...item };
        });
        newArr[index].billingLines = billingLines;
    }
    return newArr;
}
function removeBillingLine(state: stateType, entityId: string, entityType: keyof stateType, billingLineId: number) {
    const newArr = [...state[entityType]];
    const index = newArr.findIndex((item) => item.id === entityId);
    if (index !== -1) {
        const oldBillingLines = newArr[index].billingLines?.filter((item: event) => item.id !== billingLineId);
        newArr[index].billingLines = oldBillingLines;
    }
    return newArr;
}

export { setBillingLines, addBillingLine, editBillingLine, removeBillingLine };
