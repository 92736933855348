import React from 'react';
import * as ReactDOM from 'react-dom/index';
import { ColumnType } from '../types/Column';
import { styled } from '../../Theme/theme';
import hexagonOpened from '../../../../static/icons/hexagonCheck/opened.svg';
import hexagonClosed from '../../../../static/icons/hexagonCheck/closed.svg';
import dragIcon from '../../../../static/icons/menu.svg';
import { DraggableProvided, DraggableStateSnapshot, DraggingStyle, NotDraggingStyle } from 'react-beautiful-dnd';
import { CSSProperties } from '@material-ui/core/styles/withStyles';

type PortalAwareItemProps = {
    provided: DraggableProvided;
    snapshot: DraggableStateSnapshot;
    item: ColumnType<any>;
    currGridColumns: ColumnType<any>[];
    index: number;
    setCurrGridColumns: React.Dispatch<React.SetStateAction<ColumnType<any>[]>>;
};

//Create Portal
const portal = document.createElement('div');
document.body.appendChild(portal);

const getItemStyle = (isDragging: boolean, draggableStyle?: DraggingStyle | NotDraggingStyle): CSSProperties => {
    return {
        userSelect: 'none',
        boxShadow: isDragging ? '0 6px 9px 0 rgba(0, 0, 0, 0.1)' : undefined,
        backgroundColor: isDragging ? ' #f8f8f8' : undefined,
        ...draggableStyle
    };
};

export const PortalAwareDraggableColumnItem = ({ provided, snapshot, item, currGridColumns, index, setCurrGridColumns }: PortalAwareItemProps) => {
    const usePortal = snapshot.isDragging;

    const child = (
        <ColumnItem
            ref={provided.innerRef}
            {...provided.draggableProps}
            {...provided.dragHandleProps}
            style={getItemStyle(snapshot.isDragging, provided.draggableProps.style)}
        >
            <Flex>
                <Checkmark
                    src={item.isDisplayed === false ? hexagonOpened : hexagonClosed}
                    onClick={() => {
                        item.isDisplayed = item.isDisplayed === false ? !item.isDisplayed : false;
                        currGridColumns[index] = item;
                        setCurrGridColumns([...currGridColumns]);
                    }}
                    alt="hexagon"
                />
                <div>{item.title || item.field}</div>
            </Flex>
            <DragIcon className="drag-icon" src={dragIcon} alt="drag" />
        </ColumnItem>
    );

    if (!usePortal) {
        return child;
    }

    // if dragging - put the item in a portal
    return ReactDOM.createPortal(child, portal);
};

const ColumnItem = styled.div`
    display: flex;
    align-items: center;
    justify-content: space-between;
    height: 35px;
    margin-bottom: 5px;
    &:hover {
        box-shadow: 0 6px 9px 0 rgba(0, 0, 0, 0.1);
        background-color: #f8f8f8;
        .drag-icon {
            visibility: visible;
        }
    }
`;

const Flex = styled.div`
    display: flex;
`;
const Checkmark = styled.img`
    margin-right: 15px;
    cursor: pointer;
`;
const DragIcon = styled.img`
    visibility: hidden;
    margin-right: 10px;
`;

export default PortalAwareDraggableColumnItem;
