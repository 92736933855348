import React from 'react';
import { contact } from '../../../../../../models/entities/businessPartner/contact';
import CustomModal, { ModalTriggerProps } from '../../../../../shared/Modal/Modal';
import { styled } from '../../../../../shared/Theme/theme';
import ContactFormConnected, { formMode } from '../../../../businessPartners/singleBusinessPartner/ContactForm/ContactFormConnected';

type Props = {
    submitCallback?: (contact: contact) => void;
    modalTitle: string;
    businessPartnerId: string;
};
const CreateBusinessPartnerContact = ({ submitCallback, modalTitle, businessPartnerId }: Props) => {
    return (
        <CustomModal
            title={{ text: modalTitle }}
            Trigger={(props: ModalTriggerProps) => (
                <AddButton className="material-icons" onClick={props.onClick}>
                    add
                </AddButton>
            )}
            Content={(setVisible?: React.Dispatch<React.SetStateAction<boolean>>, setConfirm?: React.Dispatch<React.SetStateAction<boolean>>) => {
                return (
                    <ContactFormConnected
                        mode={formMode.NEW}
                        openedFromModal={setVisible ? { setVisible } : undefined}
                        confirmation={setConfirm ? { setConfirm } : undefined}
                        submitCallback={submitCallback}
                        businessPartnerId={businessPartnerId}
                    />
                );
            }}
            width="393px"
        />
    );
};
const AddButton = styled.span`
    color: ${(props) => props.theme.colors.primaryBlue};
    cursor: pointer;
    display: flex;
    align-items: center;
`;
export default CreateBusinessPartnerContact;
