import { ModelConfig } from '@rematch/core';
import { companiesRelation, companiesRelationsResponseType, companiesRelationType } from '../../../models/entities/company/companiesRelation';
import { companiesRelationRequest, companiesRelationRequestsResponseType } from '../../../models/entities/company/companiesRelationRequest';
import { localSDK as client } from '../../../sdk';

export type CompaniesRelationsStateType = {
    relations: companiesRelationsResponseType;
    relationRequests: companiesRelationRequestsResponseType;
};

export const companiesRelations: ModelConfig<CompaniesRelationsStateType> = {
    state: {
        relations: {
            requested: [],
            approved: []
        },
        relationRequests: {
            sent: [],
            recieved: []
        }
    },
    reducers: {
        setCompaniesRelations(state: CompaniesRelationsStateType, relations: companiesRelationsResponseType): CompaniesRelationsStateType {
            return { ...state, relations };
        },
        setCompaniesRelationRequests(
            state: CompaniesRelationsStateType,
            relationRequests: companiesRelationRequestsResponseType
        ): CompaniesRelationsStateType {
            return { ...state, relationRequests };
        },
        addCompaniesRelation(state: CompaniesRelationsStateType, relation: companiesRelation, isApprovedByMe: boolean): CompaniesRelationsStateType {
            const relationGroup = isApprovedByMe ? 'approved' : 'requested';
            const newRelations = { ...state.relations, [relationGroup]: [...state.relations[relationGroup], relation] };
            return { ...state, relations: newRelations };
        },
        removeCompaniesRelation(state: CompaniesRelationsStateType, relationId: string): CompaniesRelationsStateType {
            const filterRelations = (relations: companiesRelation[]) => relations.filter((relation) => relation.id !== relationId);
            const { requested, approved } = state.relations;
            const newRelations = { requested: filterRelations(requested), approved: filterRelations(approved) };
            return { ...state, relations: { ...newRelations } };
        },
        addCompaniesRelationRequest(
            state: CompaniesRelationsStateType,
            relationRequest: companiesRelationRequest,
            isSentByMe: boolean
        ): CompaniesRelationsStateType {
            const requestType = isSentByMe ? 'sent' : 'recieved';
            const newRelationRequests = {
                ...state.relationRequests,
                [requestType]: [...state.relationRequests[requestType], relationRequest]
            };
            return { ...state, relationRequests: newRelationRequests };
        },
        removeCompaniesRelationRequest(
            state: CompaniesRelationsStateType,
            relationRequestId: string,
            isSentByMe: boolean
        ): CompaniesRelationsStateType {
            const requestType = isSentByMe ? 'sent' : 'recieved';

            const newRequests = [...state.relationRequests[requestType]];
            const requestIntex = newRequests.findIndex((req) => req.id === relationRequestId);

            if (requestIntex > -1) {
                newRequests.splice(requestIntex, 1);
            }

            const newRelationRequests = {
                ...state.relationRequests,
                [requestType]: newRequests
            };

            return { ...state, relationRequests: newRelationRequests };
        }
    },
    effects: (dispatch: any) => ({
        async fetchCompaniesRelations() {
            const relations = await client.companies().relations().getCompaniesRelations();
            dispatch.companiesRelations.setCompaniesRelations(relations);
        },
        async fetchCompaniesRelationRequests() {
            const relationRequests = await client.companies().relations().getCompaniesRelationRequests();
            dispatch.companiesRelations.setCompaniesRelationRequests(relationRequests);
        },
        async approveCompaniesRelationRequest({
            requestId,
            businessPartnerId,
            recievedBusinessPartnerId,
            recievedRelationType
        }: {
            requestId: string;
            businessPartnerId: string;
            recievedBusinessPartnerId?: string;
            recievedRelationType?: companiesRelationType;
        }) {
            const createdRelation = await client
                .companies()
                .relations()
                .approveCompaniesRelation(requestId, businessPartnerId, recievedBusinessPartnerId, recievedRelationType);
            dispatch.companiesRelations.addCompaniesRelation(createdRelation, true);
            dispatch.companiesRelations.removeCompaniesRelationRequest(requestId, false);
        },
        async sendCompaniesRelationRequest({
            relationToken,
            relationType,
            recieverBusinessPartnerId,
            recieverRelationType
        }: {
            relationToken: string;
            relationType: companiesRelationType;
            recieverBusinessPartnerId?: string;
            recieverRelationType?: companiesRelationType;
        }) {
            const relationRequests = await client
                .companies()
                .relations()
                .requestCompaniesRelation(relationToken, relationType, recieverBusinessPartnerId, recieverRelationType);
            dispatch.companiesRelations.addCompaniesRelationRequest(relationRequests, true);
        },
        async denyCompaniesRelation(requestId: string) {
            await client.companies().relations().denyCompaniesRelation(requestId);
            dispatch.companiesRelations.removeCompaniesRelationRequest(requestId, false);
        },
        async deleteCompaniesRelation(relationId: string) {
            await client.companies().relations().deleteCompaniesRelation(relationId);
            dispatch.companiesRelations.removeCompaniesRelation(relationId);
        }
    })
};
