import React, { useCallback, useState } from 'react';
import { styled } from '../../../shared/Theme/theme';
import { document } from '../../../../models/entities/common_subentities/document';
import Loader from '../../../shared/SmallComponents/Loader';
import { ColumnType, specialColumnType } from '../../../shared/Grid/types/Column';
import Grid from '../../../shared/Grid/Grid';
import CustomModal, { ModalTriggerProps } from '../../../shared/Modal/Modal';
import Button from '../../../shared/SmallComponents/Button';
import attachment from '../../../../static/icons/attachment.svg';
import { settings } from '../../../../models/entities/shipment/shipment';
import claimsType from '../../../../models/entities/claimsType';
import MissingDocs from './MissingDocs';
import { formMode } from './DocumentForm/MultipleDocumentFormConnected';
import CZTooltip from '../../../shared/CZTooltip/CZTooltip';
import moment from 'moment';
import { CellWrapper } from '../../../shared/Grid/CellRenderer/CellRenderer';
import { ModalPreviewFileAsync } from '../../../shared/FilesPreview/ModalPreview';
import FileExtIcon from '../../../shared/Grid/specialColumns/FileExtIcon';
import { getUrlFuncType } from '../../../shared/FilesPreview/Preview';

type Props<settingsType> = {
    documents?: Array<document>;
    requiredDocs?: Array<string>;
    localization: any;
    settings?: settingsType;
    menuType?: specialColumnType;
    DocumentFormConnected?: any;
    userDocumentsClaims?: claimsType;
    MultipleDocumentFormConnected?: any;
    dismissRequiredDoc?: (entityId: string, docType: string) => string;
    printMode?: boolean;
    entityId?: string;
    additionalData?: { [key: string]: any };
    getFileUrl?: getUrlFuncType;
};

function Documents<settingsType extends settings = any>({
    documents,
    localization,
    menuType,
    DocumentFormConnected,
    MultipleDocumentFormConnected,
    settings,
    requiredDocs,
    printMode,
    userDocumentsClaims,
    dismissRequiredDoc,
    entityId,
    additionalData,
    getFileUrl
}: Props<settingsType>) {
    const { type, name, date, added_by, comment, displayOnlyForCompanyIdTooltip, expirationDate } = localization.documents.grid.columns;

    const fileNameRenderer = (cellValue: any, bindedData?: any[] | null) => {
        const fileId = bindedData && bindedData[0];
        if (!fileId) return <CellWrapper width={'100%'}>-</CellWrapper>;
        return (
            <CellWrapper width={'100%'}>
                <ModalPreviewFileAsync
                    triggerChildren={<FileExtIcon filename={cellValue} />}
                    fileId={fileId}
                    fileName={bindedData && bindedData[1]}
                    getFileUrl={getFileUrl}
                />
            </CellWrapper>
        );
    };
    const gridColumns: Array<ColumnType<document>> = [
        {
            title: type,
            field: 'type',
            sortable: false,
            isDisplayed: true,
            columnType: 'document_type',
            bindedFields: ['type']
        },
        {
            title: name,
            field: 'fileName',
            sortable: false,
            isDisplayed: true,
            columnType: getFileUrl ? undefined : 'fileExtIcon',
            bindedFields: ['fileId', 'fileName'],
            renderer: getFileUrl ? fileNameRenderer : undefined
        },
        {
            title: date,
            field: 'createdAt',
            sortable: false,
            isDisplayed: true,
            columnType: 'date'
        },
        {
            title: added_by,
            field: 'userName',
            sortable: false,
            isDisplayed: true
        },
        {
            title: comment,
            field: 'comment',
            sortable: false,
            isDisplayed: true
        },
        {
            title: expirationDate,
            field: 'expDate',
            sortable: true,
            isDisplayed: true,
            renderer: (cellValue: any, bindedData?: Array<any> | null) => {
                const date = moment(cellValue);
                if (!cellValue || cellValue === '-' || !date.isValid()) return <div>-</div>;
                const isDatedPassed = moment(date).isBefore(moment());
                const isDatedInThreeDays = moment(cellValue).diff(moment(), 'days') <= 3;
                return <div style={{ color: isDatedPassed ? '#c1292e' : isDatedInThreeDays ? '#fda119' : undefined }}>{date.format('DD/MM/YY')}</div>;
            }
        },
        {
            field: 'displayOnlyForCompanyId',
            width: '40px',
            sortable: false,
            isDisplayed: true,
            renderer: (cellValue: any, bindedData?: Array<any> | null) => {
                if (!cellValue || typeof cellValue !== 'string' || cellValue === '-') return <div></div>;
                return (
                    <CZTooltip text={displayOnlyForCompanyIdTooltip}>
                        <span className="material-icons-sharp">security</span>
                    </CZTooltip>
                );
            }
        }
    ];

    const [modalVisible, setVisible] = useState(false);
    const [document, setDocument] = useState({} as document);
    return (
        <DocumentsWrapper>
            <MissingDocs
                documents={documents}
                requiredDocs={requiredDocs}
                localization={localization}
                settings={settings}
                userDocumentsClaims={userDocumentsClaims}
                DocumentFormConnected={DocumentFormConnected}
                dismissRequiredDoc={dismissRequiredDoc}
                entityId={entityId}
                additionalData={additionalData}
            />
            {documents ? (
                <>
                    {!printMode ? (
                        MultipleDocumentFormConnected &&
                        (!userDocumentsClaims || userDocumentsClaims?.add_document) && (
                            <CustomModal
                                title={{ icon: attachment, text: localization.documents.modal.add_title }}
                                Trigger={(props: ModalTriggerProps) => (
                                    <ButtonContainer>
                                        <Button onClick={props.onClick} width="auto" buttonType="hollow" padding="4px 12px">
                                            {localization.documents.add_document}
                                        </Button>
                                    </ButtonContainer>
                                )}
                                Content={(
                                    setVisible?: React.Dispatch<React.SetStateAction<boolean>>,
                                    setConfirm?: React.Dispatch<React.SetStateAction<boolean>>
                                ) => {
                                    return (
                                        <MultipleDocumentFormConnected
                                            mode={formMode.NEW}
                                            openedFromModal={setVisible ? { setVisible } : undefined}
                                            confirmation={setConfirm ? { setConfirm } : undefined}
                                            entityId={entityId}
                                            additionalData={additionalData}
                                        />
                                    );
                                }}
                                width="500px"
                            />
                        )
                    ) : (
                        <h2>Documents</h2>
                    )}
                    {documents.length > 0 ? (
                        <GridWrapper>
                            <Grid<document>
                                columns={gridColumns}
                                data={documents}
                                localization={localization.documents.grid}
                                settings={settings}
                                onSettingsClick={() => console.log('settings')}
                                onRowMoreOptionsClick={(row) => {
                                    setDocument(row);
                                    setVisible(true);
                                }}
                                userIdKey="userId"
                                menuType={menuType}
                                parentEntityId={entityId}
                            />
                            {modalVisible && DocumentFormConnected && (
                                <CustomModal
                                    title={{ icon: attachment, text: localization.documents.modal.edit_title }}
                                    isOpen={true}
                                    Content={(
                                        setModalVisible?: React.Dispatch<React.SetStateAction<boolean>>,
                                        setConfirm?: React.Dispatch<React.SetStateAction<boolean>>
                                    ) => {
                                        return (
                                            <DocumentFormConnected
                                                mode={formMode.EDIT}
                                                document={document}
                                                openedFromModal={{ setVisible }}
                                                confirmation={setConfirm ? { setConfirm } : undefined}
                                                entityId={entityId}
                                                additionalData={additionalData}
                                            />
                                        );
                                    }}
                                    onCancelCallback={() => {
                                        setVisible(false);
                                        setDocument({} as document);
                                    }}
                                    width="393px"
                                />
                            )}
                        </GridWrapper>
                    ) : (
                        <EmaptyStateText>{localization.documents.empty_state}</EmaptyStateText>
                    )}
                </>
            ) : (
                <Loader />
            )}
        </DocumentsWrapper>
    );
}

const GridWrapper = styled.div`
    flex: 0 0 100%;
    max-width: 100%;
    width: 100%;
    box-sizing: border-box;
    overflow-y: overlay;
    height: 100%;
    width: 100%;
`;

const DocumentsWrapper = styled.div`
    display: flex;
    flex-direction: column;
    width: 100%;
`;

const EmaptyStateText = styled.span`
    color: ${(props) => props.theme.colors.emptyStateText};
`;

const ButtonContainer = styled.div`
    display: flex;
    justify-content: flex-end;
    padding-top: 8px;
`;

export default Documents;
