import React from 'react';
import { discussion } from '../../../../../models/entities/common_subentities/discussion';
import { userSelectors } from '../../../../../state/ducks/user/selectors';
import { RootState } from '../../../../../state/store/store';
import { connect } from 'react-redux';
import { singleOrderSelectors } from '../../../../../state/ducks/singleOrder/selectors';
import { userInfo } from '../../../../../models/entities/user';
import DiscussionsSection from '../../../commonSubEntities/discussions/DiscussionsSection';
import { userDetails } from '../../../../../state/ducks/users/users';
import { usersSelectors } from '../../../../../state/ducks/users/selectors';
import { shipment } from '../../../../../models/entities/shipment/shipment';
import { shipmentsSelectors } from '../../../../../state/ducks/shipments/selectors';
import { ColumnType } from '../../../../shared/Grid/types/Column';
import { allShipmentsPageSelectors } from '../../../../../state/ducks/allShipmentsPage/selectors';
import {
    createOrderDiscussionPayload,
    deleteOrderDiscussionPayload,
    updateOrderDiscussionPayload
} from '../../../../../models/entities/order/orderDiscussion/payloads';
import { orderDiscussion } from '../../../../../models/entities/order/orderDiscussion/orderDiscussion';

type Props = {
    orderId?: string;
    orderCzNumber?: string;
    discussions?: Array<orderDiscussion>;
    localization: any;
    currentUser: userInfo;
    showLoader: boolean;
    gridColumns: Array<ColumnType<shipment>>;
    createOrderDiscussion: (payload: createOrderDiscussionPayload) => Promise<void>;
    updateOrderDiscussion: (payload: updateOrderDiscussionPayload) => Promise<void>;
    deleteOrderDiscussion: (payload: deleteOrderDiscussionPayload) => Promise<void>;
    getUserById: (userId: string) => userDetails | undefined;
    getShipmentById: (id: string) => shipment | undefined;
};

const OrderDiscussionsSection = ({
    orderId,
    orderCzNumber,
    discussions,
    localization,
    currentUser,
    showLoader,
    gridColumns,
    createOrderDiscussion,
    updateOrderDiscussion,
    deleteOrderDiscussion,
    getUserById,
    getShipmentById
}: Props) => {
    return (
        <DiscussionsSection
            entityId={orderId}
            entityName={orderCzNumber}
            discussions={discussions?.map(
                (orderDiscussion) =>
                    ({
                        id: orderDiscussion.id,
                        entityId: orderDiscussion.orderId,
                        userId: orderDiscussion.userId,
                        userName: orderDiscussion.userName,
                        userPic: orderDiscussion.userPicture,
                        message: orderDiscussion.message,
                        createdDate: orderDiscussion.createdAt
                    } as discussion)
            )}
            localization={localization}
            currentUser={currentUser}
            createDiscussion={async (entityId: string, discussion: discussion) => {
                await createOrderDiscussion({ orderId: entityId, discussion: { message: discussion.message } });
            }}
            updateDiscussion={async (entityId: string, discussion: discussion) => {
                await updateOrderDiscussion({ orderId: entityId, discussionId: discussion.id, discussion: { message: discussion.message } });
            }}
            deleteDiscussion={async (entityId: string, discussionId: string) => {
                await deleteOrderDiscussion({ orderId: entityId, discussionId });
            }}
            showLoader={showLoader}
            getUserById={getUserById}
            getShipmentById={getShipmentById}
            gridColumns={gridColumns}
        />
    );
};
const mapStateToProps = (state: RootState) => ({
    currentUser: userSelectors.userInfo(state),
    orderId: singleOrderSelectors.order(state)?.id,
    orderCzNumber: singleOrderSelectors.order(state)?.cargoZoneNumber,
    discussions: singleOrderSelectors.orderDiscussions(state),
    showLoader:
        state.loading.effects.orders.createOrderDiscussion ||
        state.loading.effects.orders.updateOrderDiscussion ||
        state.loading.effects.orders.deleteOrderDiscussion,
    getUserById: (id: string) => usersSelectors.getUserById(state)(id),
    getShipmentById: (id: string) => shipmentsSelectors.getShipmentById(state, id),
    gridColumns: allShipmentsPageSelectors.gridColumns(state)
});

const mapDispatchToProps = (dispatch: any) => ({
    createOrderDiscussion: (payload: createOrderDiscussionPayload) => dispatch.orders.createOrderDiscussion(payload),
    updateOrderDiscussion: (payload: updateOrderDiscussionPayload) => dispatch.orders.updateOrderDiscussion(payload),
    deleteOrderDiscussion: (payload: deleteOrderDiscussionPayload) => dispatch.orders.deleteOrderDiscussion(payload)
});

export default connect(mapStateToProps, mapDispatchToProps)(OrderDiscussionsSection);
