import * as Yup from 'yup';
import moment from 'moment';

export const shipmentOverviewSchema = () => {
    const requiredFieldMessage = 'Required';
    const dateAfterMessage = (date: string) => `Date needs to be after ${date}`;
    return Yup.object().shape({
        goodsValueCurrency: Yup.string()
            .nullable()
            .when(['goodsValue'], (goodsValue: string, schema: any) => {
                return schema.test({
                    test: (goodsValueCurrency: string) => (!!goodsValue ? !!goodsValueCurrency : true),
                    message: requiredFieldMessage
                });
            }),
        transportationPriceCurrency: Yup.string()
            .nullable()
            .when(['transportationPrice'], (transportationPrice: string, schema: any) => {
                return schema.test({
                    test: (transportationPriceCurrency: string) => (!!transportationPrice ? !!transportationPriceCurrency : true),
                    message: requiredFieldMessage
                });
            }),
        quantity: Yup.string()
            .nullable()
            .when(['volume', 'weight'], {
                is: (volume: string, weight: string) => !!Number(volume) || !!Number(weight),
                then: Yup.string().required(requiredFieldMessage)
            }),
        eta: Yup.date()
            .when('etd', (st: string, schema: any) => {
                if (st) {
                    return schema.min(new Date(st), dateAfterMessage(moment(st).format('DD/MM/YYYY')));
                }

                return;
            })
            .nullable()
    });
};
