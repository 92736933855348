import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { product } from '../../../../../../models/entities/product/product';
import { purchaseOrder } from '../../../../../../models/entities/purchaseOrder/purchaseOrder';
import { purchaseOrderItem } from '../../../../../../models/entities/purchaseOrder/purchaseOrderItem';
import { shipment } from '../../../../../../models/entities/shipment/shipment';
import { drawers } from '../../../../../../state/ducks/header/header';
import { headerSelectors } from '../../../../../../state/ducks/header/selectors';
import { localizationSelectors } from '../../../../../../state/ducks/localization/selectors';
import { productsSelectors } from '../../../../../../state/ducks/products/selectors';
import { singlePurchaseOrderSelectors } from '../../../../../../state/ducks/singlePurchaseOrder/selectors';
import { RootState } from '../../../../../../state/store/store';
import { Option } from '../../../../../shared/inputs/base/DropDown';
import PoItemsGrid from './PoItemsGrid';

type Props = {
    values: any;
    setFieldValue: any;
    filteredShipments?: Array<shipment>;
    hideBottomDetails?: boolean;
    isProductModalOpened?: boolean;
    localization: any;
    printMode?: boolean;
    hideItemsPrices?: boolean;
    onDeleteItem?: (itemId: string) => Promise<void>;
    onAddItemsCallback?: (items: purchaseOrderItem[]) => void;
    onUpdateItemsReadyDate?: (ids: string[], readyDate?: Date) => Promise<void>;
    onUpdateItemQuantity?: (updatedItem: purchaseOrderItem) => Promise<void>;
    updatePurchaseOrderItem?: (updatedItem: purchaseOrderItem) => Promise<void>;
    onUpdateItemCost?: (updatedItem: purchaseOrderItem) => Promise<void>;
    onCreateShipment?: (itemsIds: string[]) => void;
    updateProduct: (id: string, product: product, deletedSuppliers?: string[]) => Promise<boolean>;
    setDrawerOpen: (open: drawers, params?: any) => void;
    fetchProducts: (ids: string[]) => Promise<Array<product>>;
    purchaseOrder?: purchaseOrder;
    poItemsRelatedProducts: Array<product>;
};

const Items = ({
    values,
    setFieldValue,
    hideBottomDetails,
    filteredShipments,
    localization,
    isProductModalOpened,
    printMode,
    onDeleteItem,
    onAddItemsCallback,
    onUpdateItemsReadyDate,
    updatePurchaseOrderItem,
    onUpdateItemQuantity,
    onUpdateItemCost,
    onCreateShipment,
    updateProduct,
    setDrawerOpen,
    fetchProducts,
    purchaseOrder,
    poItemsRelatedProducts,
    hideItemsPrices
}: Props) => {
    const shipmentsOptions: Option[] =
        filteredShipments?.map((item) => {
            return { value: item.id, text: item.CargoZoneNumber };
        }) || [];

    useEffect(() => {
        if (values.items?.length && purchaseOrder?.id) {
            const productsIds: string[] = values.items.map((item: purchaseOrderItem) => item.productId);
            fetchProducts(productsIds);
        }
    }, [values.items?.length, purchaseOrder?.id]);
    return (
        <div>
            <PoItemsGrid
                purchaseOrder={purchaseOrder}
                isProductModalOpened={isProductModalOpened}
                values={values}
                hideItemsPrices={hideItemsPrices}
                setFieldValue={setFieldValue}
                shipmentsOptions={shipmentsOptions}
                localization={localization.form}
                hideBottomDetails={hideBottomDetails}
                printMode={printMode}
                onDeleteItem={onDeleteItem}
                onAddItemsCallback={onAddItemsCallback}
                onUpdateItemsReadyDate={onUpdateItemsReadyDate}
                updatePurchaseOrderItem={updatePurchaseOrderItem}
                onUpdateItemQuantity={onUpdateItemQuantity}
                onCreateShipment={onCreateShipment}
                setDrawerOpen={setDrawerOpen}
                onUpdateItemCost={onUpdateItemCost}
                updateProduct={updateProduct}
                poItemsRelatedProducts={poItemsRelatedProducts}
            />
        </div>
    );
};
const mapStateToProps = (state: RootState) => ({
    productsBySupplier: (supplierId: string) => productsSelectors.productsBySupplier(state)(supplierId),
    localization: localizationSelectors.allPurchaseOrdersPage(state),
    printMode: headerSelectors.printMode(state),
    poItemsRelatedProducts: singlePurchaseOrderSelectors.poItemsRelatedProducts(state)
});

const mapDispatchToProps = (dispatch: any) => ({
    setDrawerOpen: (open: drawers, params?: any) => dispatch.header.setDrawerOpenParams({ open, params }),
    updateProduct: (id: string, product: product, deletedSuppliers?: string[]) => dispatch.products.updateProduct({ id, product, deletedSuppliers }),
    fetchProducts: (ids: string[]) => dispatch.products.fetchProductsByIds(ids)
});

export default connect(mapStateToProps, mapDispatchToProps)(Items);
