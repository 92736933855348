import SideEffectJs from 'side-effect-js';
import { ModelConfig } from '@rematch/core';
import { notification } from '../../../models/entities/notification/notification';
import { localSDK as client } from '../../../sdk';

export type notificationsStateType = {
    notifications: Array<notification> | null;
    error: string;
};

export const notifications: ModelConfig<notificationsStateType> = {
    state: {
        notifications: null,
        error: ''
    },
    reducers: {
        setNotifications(state: notificationsStateType, notifications: Array<notification>): notificationsStateType {
            return { ...state, notifications };
        },
        addNewNotification(state: notificationsStateType, notification: notification): notificationsStateType {
            const currentNotifications = state.notifications;
            const newNotifications = [...(currentNotifications || []), notification];
            return { ...state, notifications: newNotifications };
        },
        setError(state: notificationsStateType, error: string): notificationsStateType {
            return { ...state, error };
        },
        updateNotificationRead(state: notificationsStateType, notificationId: string, isRead: boolean): notificationsStateType {
            const currentNotifications = state.notifications;
            if (currentNotifications === null) return state;
            const currentNotification = currentNotifications.find((x) => x.id === notificationId);
            if (!currentNotification) return state;
            const currentNotificationIndex = currentNotifications.indexOf(currentNotification);
            const newNotifications = [...currentNotifications];
            newNotifications[currentNotificationIndex] = { ...newNotifications[currentNotificationIndex], isRead };
            return { ...state, notifications: newNotifications };
        },
        updateMultipleNotificationsRead(state: notificationsStateType, notificationIds: string[], isRead: boolean): notificationsStateType {
            const currentNotifications = state.notifications;
            if (currentNotifications === null) return state;
            const selectedNotifications = currentNotifications.filter((x) => notificationIds.includes(x.id));
            if (!selectedNotifications.length) return state;
            const newNotifications = [...currentNotifications];

            selectedNotifications.forEach((notification) => {
                const currentNotificationIndex = currentNotifications.indexOf(notification);
                if (currentNotificationIndex !== -1)
                    newNotifications[currentNotificationIndex] = { ...newNotifications[currentNotificationIndex], isRead };
            });
            return { ...state, notifications: newNotifications };
        },
        updateAllNotificationRead(state: notificationsStateType, isRead: boolean): notificationsStateType {
            if (state.notifications) {
                const notifications = [
                    ...state.notifications.map((item) => {
                        item.isRead = isRead;
                        return item;
                    })
                ];
                return { ...state, notifications };
            }
            return state;
        },
        removeNotification(state: notificationsStateType, notificationId: string): notificationsStateType {
            if (state.notifications) {
                const newNotifications = state.notifications.filter((item) => item.id !== notificationId);
                return { ...state, notifications: newNotifications };
            }
            return state;
        },
        removeMultipleNotifications(state: notificationsStateType, notificationIds: string[]): notificationsStateType {
            if (state.notifications) {
                const newNotifications = state.notifications.filter((item) => !notificationIds.includes(item.id));
                return { ...state, notifications: newNotifications };
            }
            return state;
        },
        removeAllNotifications(state: notificationsStateType): notificationsStateType {
            if (state.notifications) {
                return { ...state, notifications: null };
            }
            return state;
        },
        updateNotificationFollow(state: notificationsStateType, notificationId: string, isFollow: boolean): notificationsStateType {
            const currentNotifications = state.notifications;
            if (currentNotifications === null) return state;
            const currentNotification = currentNotifications.find((x) => x.id === notificationId);
            if (!currentNotification) return state;
            const currentNotificationIndex = currentNotifications.indexOf(currentNotification);
            const newNotifications = [...currentNotifications];
            newNotifications[currentNotificationIndex] = { ...newNotifications[currentNotificationIndex], isFollow };
            return { ...state, notifications: newNotifications };
        }
    },
    effects: (dispatch: any) => ({
        async fetchNotifications() {
            const notifications = await client.notification().fetchNotifications();
            dispatch.notifications.setNotifications(notifications);
        },
        async setNotificationRead({ notificationId, isRead }: { notificationId: string; isRead: boolean }) {
            dispatch.notifications.updateNotificationRead(notificationId, isRead);
            await client.notification().setNotificationRead(notificationId, isRead);
        },
        async setMultipleNotificationsRead({ notificationIds, isRead }: { notificationIds: string[]; isRead: boolean }) {
            dispatch.notifications.updateMultipleNotificationsRead(notificationIds, isRead);
            await client.notification().setMultipleNotificationsRead(notificationIds, isRead);
        },
        async setAllNotificationsRead(isRead: boolean) {
            dispatch.notifications.updateAllNotificationRead(isRead);
            await client.notification().setAllNotificationRead(isRead);
        },
        async deleteNotification(notificationId: string) {
            dispatch.notifications.removeNotification(notificationId);
            await client.notification().setNotificationDeleted(notificationId);
        },
        async deleteMultipleNotifications(notificationIds: string[]) {
            dispatch.notifications.removeMultipleNotifications(notificationIds);
            await client.notification().setMultipleNotificationDeleted(notificationIds);
        },
        async deleteAllNotifications() {
            dispatch.notifications.removeAllNotifications();
            await client.notification().setAllNotificationDeleted();
        },
        async setNotificationFollow({ notificationId, isFollow }: { notificationId: string; isFollow: boolean }) {
            try {
                const setNotificationFollowSideEffect = SideEffectJs.Get('setNotificationFollow');
                await setNotificationFollowSideEffect();
                dispatch.notifications.updateNotificationFollow(notificationId, isFollow);
            } catch (e) {
                dispatch.notifications.setError(e);
            }
        }
    })
};
