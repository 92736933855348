import BaseController from '..';
import Users from './users/users';
import Shipments from '../shipments/shipments';
import PurchaseOrders from '../purchaseOrders/purchaseOrders';
import PurchaseDemands from '../purchaseDemands/purchaseDemands';
import BusinessPartners from '../businessPartners/businessPartners';

export default class Admin extends BaseController {
    private adminUsers: Users;
    private shipments: Shipments;
    private purchaseOrders: PurchaseOrders;
    private purchaseDemands: PurchaseDemands;
    private businessPartners: BusinessPartners;
    constructor(baseUrl: string) {
        super(baseUrl);
        this.adminUsers = new Users(baseUrl);
        this.shipments = new Shipments(baseUrl);
        this.purchaseOrders = new PurchaseOrders(baseUrl);
        this.purchaseDemands = new PurchaseDemands(baseUrl);
        this.businessPartners = new BusinessPartners(baseUrl);
    }
    async fetchDeletedRecords() {
        const response = await this.client.get('/companyAdmin/company/deletedRecords');
        return response;
    }
    async restoreEntity(entityId: string, entityType: string) {
        switch (entityType) {
            case 'SHIPMENT':
                return this.shipments.restoreShipment(entityId);
            case 'PO':
                return this.purchaseOrders.restorePurchaseOrder(entityId);
            case 'PD':
                return this.purchaseDemands.restorePurchaseDemand(entityId);
            case 'BUSINESS_PARTNER':
                return this.businessPartners.restoreBusinessPartner(entityId);
            default:
                return;
        }
    }
    async deleteEntity(entityId: string, entityType: string) {
        switch (entityType) {
            case 'SHIPMENT':
                return this.shipments.deleteShipment(entityId);
            case 'PO':
                return this.purchaseOrders.deletePurchaseOrder(entityId);
            case 'PD':
                return this.purchaseDemands.deletePurchaseDemand(entityId);
            case 'BUSINESS_PARTNER':
                return this.businessPartners.deleteBusinessPartner(entityId);
            default:
                return;
        }
    }
    users() {
        return this.adminUsers;
    }
}
