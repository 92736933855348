import { FormikProps } from 'formik';
import React from 'react';
import countries from '../../../../../static/data/countries.json';
import { orderPartyType } from '../../../../../models/entities/order/orderParty';
import FormInputWrapper from '../../../../shared/Form/FormInputWrapper';
import { FormDropDown, FormTextArea, FormTextInput, LocationSearchInput } from '../../../../shared/inputs/form';
import { renderCountryOption } from '../../../../shared/SmallComponents/CountryOption';
import { OrderFormValues } from '../OrderForm';
import styled from 'styled-components';

type Props = {
    formikProps: FormikProps<OrderFormValues>;
    partyType: orderPartyType;
    localization: any;
};

const countriesDropDownOptions = countries.map((country) => ({
    text: country.name,
    value: country.code
}));

const OrderPartyForm = ({ formikProps, partyType, localization }: Props) => {
    const fieldsLocalization = localization.fields.party;
    const partyTitleLocalization = localization.fields[`${partyType.toLowerCase()}Id`]?.title;
    const { values, setValues, setFieldValue } = formikProps;

    const addresses = values?.parties?.[partyType]?.addresses;
    const contacts = values?.parties?.[partyType]?.contacts;
    return (
        <>
            {partyTitleLocalization && <PartyTitle>{partyTitleLocalization}</PartyTitle>}
            {addresses && addresses.length > 0 && (
                <FormInputWrapper fieldName="addresses" localization={fieldsLocalization} isMandatory>
                    <FormDropDown
                        disabled={addresses.length === 1}
                        name={`parties.${partyType}.addressId`}
                        options={addresses.map((item) => {
                            return { text: item.name || item.city + ',' + item.country, value: item.id.toString() };
                        })}
                        onChange={(addressId: string) => {
                            const address = addresses.find((add) => add.id.toString() === addressId);

                            const newValues = { ...values };
                            const currentParty = newValues.parties?.[partyType];
                            if (address && currentParty) {
                                currentParty.addressId = address.id.toString();
                                currentParty.country = address.country;
                                currentParty.city = address.city;
                                currentParty.address = address.address || '';

                                if (partyType === 'CUSTOMER') {
                                    newValues.customerCountry = address.country;
                                    newValues.customerCity = address.city;
                                    newValues.customerAddress = address.address || '';
                                } else if (partyType === 'PICKUP') {
                                    newValues.pickupCountry = address.country;
                                    newValues.pickupCity = address.city;
                                    newValues.pickupAddress = address.address;
                                }
                            }
                            setValues(newValues);
                        }}
                        style={{ width: '200px' }}
                        placeHolder={'Address'}
                    />
                </FormInputWrapper>
            )}
            {contacts && contacts.length > 0 && (
                <FormInputWrapper fieldName="contacts" localization={fieldsLocalization} isMandatory>
                    <FormDropDown
                        disabled={contacts.length === 1}
                        name={`parties.${partyType}.contactId`}
                        options={contacts.map((item) => {
                            return { text: item.name, value: item.id?.toString() };
                        })}
                        onChange={(contactId: string) => {
                            const contact = contacts.find((con) => con.id.toString() === contactId);

                            const newValues = { ...values };
                            const currentParty = newValues.parties?.[partyType];
                            if (contact && currentParty) {
                                currentParty.contactId = contact.id;
                                currentParty.contactName = contact.name;
                                currentParty.phoneNumber = contact.phoneNumber;
                                currentParty.email = contact.email;
                            }
                            setValues(newValues);
                        }}
                        style={{ width: '200px' }}
                        placeHolder={'Contact'}
                    />
                </FormInputWrapper>
            )}
            <FormInputWrapper fieldName="contactName" localization={fieldsLocalization} isMandatory>
                <FormTextInput
                    name={`parties.${partyType}.contactName`}
                    style={{ width: '200px' }}
                    placeHolder={fieldsLocalization.contactName.placeholder}
                />
            </FormInputWrapper>
            <FormInputWrapper fieldName="phoneNumber" localization={fieldsLocalization} isMandatory>
                <FormTextInput
                    name={`parties.${partyType}.phoneNumber`}
                    style={{ width: '200px' }}
                    placeHolder={fieldsLocalization.phoneNumber.placeholder}
                />
            </FormInputWrapper>
            <FormInputWrapper fieldName="email" localization={fieldsLocalization} isMandatory>
                <FormTextInput name={`parties.${partyType}.email`} style={{ width: '200px' }} placeHolder={fieldsLocalization.email.placeholder} />
            </FormInputWrapper>
            <FormInputWrapper fieldName="vatNumber" localization={fieldsLocalization}>
                <FormTextInput
                    name={`parties.${partyType}.vatNumber`}
                    style={{ width: '200px' }}
                    placeHolder={fieldsLocalization.vatNumber.placeholder}
                />
            </FormInputWrapper>
            <FormInputWrapper fieldName="country" localization={fieldsLocalization} isMandatory>
                <FormDropDown
                    name={`parties.${partyType}.country`}
                    style={{ width: '200px' }}
                    onChange={(country: string) => {
                        setFieldValue('customerCountry', country);
                    }}
                    placeHolder={fieldsLocalization.country.placeholder}
                    options={countriesDropDownOptions}
                    optionRenderer={renderCountryOption}
                    showSearch
                />
            </FormInputWrapper>
            <FormInputWrapper fieldName="city" localization={fieldsLocalization}>
                <LocationSearchInput
                    name={`parties.${partyType}.city`}
                    onChange={(city: string) => {
                        setFieldValue('customerCity', city);
                    }}
                    placeHolder={fieldsLocalization.city.placeholder}
                    countryCode={values.parties?.[partyType]?.country}
                    width={'200px'}
                />
            </FormInputWrapper>
            <FormInputWrapper fieldName="address" localization={fieldsLocalization}>
                <FormTextArea
                    placeHolder={fieldsLocalization.address.placeholder}
                    name={`parties.${partyType}.address`}
                    onChange={(address: string) => {
                        setFieldValue('customerAddress', address);
                    }}
                    width={'200px'}
                    height={'100px'}
                />
            </FormInputWrapper>
        </>
    );
};

const PartyTitle = styled.div`
    margin-bottom: 15px;
`;
export default OrderPartyForm;
