import * as Yup from 'yup';
import { phonePattern } from '../../utils/patterns';

export const createUserSchema = (localization: any) => {
    return Yup.object().shape({
        firstName: Yup.string().required(localization.errors.required).test('len', localization.errors.max_50, maxLengthValidation(50)),
        lastName: Yup.string().required(localization.errors.required).test('len', localization.errors.max_50, maxLengthValidation(50)),
        username: Yup.string().test('len', localization.errors.max_50, maxLengthValidation(50)),
        role: Yup.string().test('len', localization.errors.max_50, maxLengthValidation(50)),
        phoneNumber: Yup.string()
            .matches(new RegExp(phonePattern), localization.errors.invalid_phone_num)
            .test('len', localization.errors.max_40, maxLengthValidation(40))
    });
};

const maxLengthValidation = (maxLength: number) => {
    return (val: any) => (val ? val.toString().length <= maxLength : true);
};
