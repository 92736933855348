import { GridCellParams, GridColDef } from '@material-ui/data-grid';
import React from 'react';
import styled from 'styled-components';
import MuiDataGrid from '../../../../shared/MuiDataGrid/MuiDataGrid';
import { attribute, attributeOption } from '../../../../../models/entities/product/attribute';
import { Formik } from 'formik';
import { FormDropDown } from '../../../../shared/inputs/form';
import { Form } from 'formik-antd';
import Button from '../../../../shared/SmallComponents/Button';

type Props = {
    attributes: Array<attribute>;
    onSubmit?: (attributes: { [key: string]: string }, firstAttributeOption?: attributeOption) => void;
    openedFromModal?: {
        setVisible: React.Dispatch<React.SetStateAction<boolean>>;
    };
    localization: any;
    onCancelCallback?: () => void;
};
const ProductAttributesModalContent = ({ attributes, onSubmit, openedFromModal, localization, onCancelCallback }: Props) => {
    const columns: GridColDef[] = [
        { field: 'name', headerName: localization.grid.columns.name, flex: 1 },
        {
            field: 'options',
            flex: 1,
            renderCell: (params: GridCellParams) => {
                return (
                    <div onClick={(e) => e.stopPropagation()} style={{ width: '100%' }}>
                        <FormDropDown
                            name={params.getValue(params.id, 'name') as string}
                            width={'80%'}
                            options={
                                ([...((params.value as Array<attributeOption>) || [])] as Array<attributeOption>)
                                    .sort(({ displayOrder: a }, { displayOrder: b }) => {
                                        if (a == null) return 1;
                                        if (b == null) return -1;
                                        return a - b;
                                    })
                                    .map((option) => ({
                                        text: option?.name || '',
                                        value: option?.id?.toString() || ''
                                    })) || []
                            }
                            allowClear
                        />
                    </div>
                );
            }
        }
    ];

    const initialValues: { [key: string]: string } = attributes.reduce((values: { [key: string]: string }, attribute: attribute) => {
        const defaultOption = attribute.options?.find((opt) => opt.isDefault);
        const currentValues = { ...values };
        if (!!defaultOption) {
            currentValues[attribute.name] = defaultOption.id.toString();
        }
        return currentValues;
    }, {});

    return (
        <Formik
            initialValues={initialValues}
            onSubmit={(values) => {
                if (onSubmit) {
                    const selectedOptions = attributes
                        .map((att) => ({
                            name: att.name,
                            selectedOption: att.options?.find((option) => !!option && Object.values(values).includes(option?.id?.toString()))
                        }))
                        .filter((v) => !!v?.selectedOption)
                        ?.sort(({ selectedOption: a }, { selectedOption: b }) => {
                            if (a?.costAdjustment == null) return 1;
                            if (b?.costAdjustment == null) return -1;
                            return b?.costAdjustment - a?.costAdjustment;
                        });

                    const newAttributes = selectedOptions.reduce(
                        (
                            newValues: { [key: string]: string },
                            value: {
                                name: string;
                                selectedOption: attributeOption | undefined;
                            }
                        ) => {
                            if (value?.selectedOption) {
                                newValues[value.name] = value.selectedOption.name;
                            }
                            return newValues;
                        },
                        {}
                    );

                    onSubmit(newAttributes, selectedOptions[0]?.selectedOption);
                }
                if (openedFromModal) openedFromModal?.setVisible(false);
            }}
        >
            {({ values, setFieldValue, handleSubmit }) => {
                return (
                    <Form>
                        <MuiDataGrid rows={attributes} columns={columns} autoHeight />
                        <Buttons>
                            <Button
                                width="100px"
                                height="30px"
                                type="button"
                                onClick={() => {
                                    onCancelCallback && onCancelCallback();
                                    if (openedFromModal) openedFromModal?.setVisible(false);
                                }}
                            >
                                Cancel
                            </Button>
                            <Button buttonType="filled" width="100px" height="30px" type="button" onClick={() => handleSubmit()}>
                                Add
                            </Button>
                        </Buttons>
                    </Form>
                );
            }}
        </Formik>
    );
};

const Buttons = styled.div`
    width: 100%;
    padding-top: 25px;
    display: flex;
    justify-content: flex-end;

    button {
        margin-right: 20px;
    }
`;

export default ProductAttributesModalContent;
