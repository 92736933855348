// eslint-disable-next-line import/no-anonymous-default-export
export default {
    en: {
        partyTypes: [
            {
                code: 'FORWARDER_BROKER',
                name: 'Forwarder/Broker',
                description: 'Forwarder/Broker',
                subTypes: [
                    {
                        code: 'FORWARDER',
                        name: 'Forwarder',
                        description: 'Forwarder description'
                    },
                    {
                        code: 'BROKER',
                        name: 'Broker',
                        description: 'Broker description'
                    }
                ]
            },
            {
                code: 'SUPPLIER',
                name: 'Supplier',
                description: 'Supplier description'
            },
            {
                code: 'MANUFACTURER',
                name: 'Manufacturer',
                description: 'Manufacturer description'
            },
            {
                code: 'CARGO_OWNER',
                name: 'Cargo Owner',
                description: 'Shipper/Consignee',
                subTypes: [
                    {
                        code: 'SHIPPER',
                        name: 'Shipper',
                        description: 'Shipper description'
                    },
                    {
                        code: 'CONSIGNEE',
                        name: 'Consignee',
                        description: 'Consignee description'
                    },
                    {
                        code: 'BILL_TO',
                        name: 'Bill to',
                        description: 'Bill to description'
                    }
                ]
            },
            {
                code: 'NOTIFY',
                name: 'Notify',
                description: 'Notify description'
            },
            {
                code: 'AGENT',
                name: 'Agent',
                description: 'Agent description'
            },
            {
                code: 'CUSTOMER',
                name: 'Customer',
                description: 'Customer description'
            },
            {
                code: 'WAREHOUSE',
                name: 'Warehouse',
                description: 'Warehouse description'
            },
            {
                code: 'TRUCKER',
                name: 'Trucker',
                description: 'Trucker description'
            },
            {
                code: 'INSURER',
                name: 'Insurer',
                description: 'Insurer description'
            },
            {
                code: 'CUSTOM_BROKER',
                name: 'Custom broker',
                description: 'Custom broker description'
            },
            {
                code: 'BANK',
                name: 'Bank',
                description: 'Bank description'
            },
            {
                code: 'CHARATER_AGENT',
                name: 'Charter agent',
                description: 'Charter agent description'
            }
        ],
        documentTypes: [
            { code: 'BL', name: 'Original Bill of landing', description: 'Original Bill of landing description' },
            { code: 'WTS', name: 'Waybill/Telex/Surrendered', description: 'Original Bill of landing description' },
            { code: 'INV', name: 'Invoice', description: 'Invoice description' },
            { code: 'CE', name: 'Customs entry', description: 'Customs entry description' },
            { code: 'MO', name: 'Money Order', description: 'Money Order description' },
            { code: 'PROINV', name: 'Pro-forma invoice', description: 'Pro-forma invoice description' },
            { code: 'PL', name: 'Packing list', description: 'Packing list description' },
            { code: 'QUOTE', name: 'Quotation', description: 'Quotation description' },
            { code: 'COF', name: 'Certificate of origin', description: 'Certificate of origin description' },
            { code: 'EURO', name: 'Euro1/Euro-Med', description: 'Euro1/Euro-Med description' },
            { code: 'MSDS', name: 'MSDS', description: 'MSDS description' },
            { code: 'CBTEST', name: 'CB Test', description: 'CB Test description' },
            { code: 'IEPERMIT', name: 'Import/Export permit', description: 'Import/Export permit description' },
            { code: 'CATALOG', name: 'Catalog', description: 'Catalog description' },
            { code: 'IELINSE', name: 'Import/Export License', description: 'Import/Export License description' },
            { code: 'PO', name: 'PO License', description: 'PO License description' },
            { code: 'LOC', name: 'Letter of credit License', description: 'Letter of credit License description' },
            { code: 'COA', name: 'Certificate of Analysis', description: 'Certificate of Analysis description' },
            { code: 'OTHER', name: 'Other', description: 'Other file description' }
        ],
        state: [
            {
                code: 'DRAFT',
                name: 'Draft',
                description: 'Ready to ship',
                displayOrder: 0,
                color: '#b2b2b2',
                toolTipText: `Ready Purchase order/shipment that wasn't assigned to a forwarder yet`
            },
            {
                code: 'ORC',
                name: 'Order Confirmation',
                description: 'Forwarder recived & handle shipment',
                displayOrder: 3,
                color: '#b2b2b2',
                toolTipText: 'Order confirmation & handling received by the forwarder   '
            },
            {
                code: 'ORDER',
                name: 'Order',
                description: 'Assign to Forwarder',
                displayOrder: 2,
                color: '#b2b2b2',
                toolTipText: `Shipments that was assigned to a forwarder but handling approval didn't receive yet`
            },
            {
                code: 'BID',
                name: 'Bid',
                description: 'Under shipping qoutes',
                displayOrder: 1,
                color: '#b2b2b2',
                toolTipText: 'Shipments you asked to get a quotation for'
            },
            {
                code: 'BOOKING',
                name: 'Booking',
                description: ' Shipment is booked',
                displayOrder: 4,
                color: '#007acc',
                toolTipText: 'Shipments that got booking details and/or pick up have been scheduled for'
            },
            {
                code: 'ON_BOARD',
                name: 'On Board',
                description: 'Shipment is on board',
                displayOrder: 5,
                color: '#007acc',
                toolTipText: 'Shipments indicated to be on the Flight/Voyage/Truck'
            },
            {
                code: 'ARRIVAL',
                name: 'Arrival',
                description: 'Shipment arrived to port of destination',
                displayOrder: 6,
                color: '#007acc',
                toolTipText: 'Shipments reported as Arrived at destination Port/Terminal'
            },
            {
                code: 'CUSTOMS',
                name: 'Customs',
                description: 'Shipment is under import customs procces',
                displayOrder: 7,
                color: '#007acc',
                toolTipText: 'Shipments under import customs process'
            },
            {
                code: 'DELIVERY',
                name: 'Customs released', // hard Code Change for visual - originally called 'Delivery' - TO DO - fit the logic accordingly
                // name: 'Delivery',
                description: 'Goods are intended for delivery',
                displayOrder: 8,
                color: '#339933',
                toolTipText: 'Shipments that was cleared from import customs & advanced to inland transporter for scheduling'
            },
            {
                code: 'DELIVERED',
                name: 'Delivered',
                description: 'Goods arrived to final destination',
                displayOrder: 9,
                color: '#339933',
                toolTipText: 'Shipments that was already delivered to importer warehouse  (POD - proof of delivery)'
            }
        ]
    }
};
