import React, { useEffect } from 'react';
import { RootState } from '../../../../../state/store/store';
import { connect } from 'react-redux';
import { companyAdminCreateUserPayload, companyAdminUpdateUserPayload, userInfo } from '../../../../../models/entities/user';
import { localizationSelectors } from '../../../../../state/ducks/localization/selectors';
import { adminSelectors } from '../../../../../state/ducks/admin/selectors';
import AddEditUser from './AddEditUser';
import { companiesSelectors } from '../../../../../state/ducks/companies/selectors';
import { company } from '../../../../../models/entities/company/company';
import { companiesRelationsSelectors } from '../../../../../state/ducks/companiesRelations/selectors';
import { companiesRelationsResponseType } from '../../../../../models/entities/company/companiesRelation';

type Props = {
    params: { userId?: string };
    getUserById: (userId: string) => userInfo | undefined;
    updateUserDetails: (details: companyAdminUpdateUserPayload, userId: string) => Promise<void>;
    createUser: (payload: companyAdminCreateUserPayload) => Promise<void>;
    resetUserPassword: (userId: string, email: string) => Promise<void>;
    deleteUser: (userId: string) => Promise<void>;
    restoreUser: (userId: string) => Promise<void>;
    fetchMyCompanies: () => Promise<void>;
    fetchCompaniesRelations: () => void;
    checkEmailExist: (email: string) => Promise<boolean>;
    relations: companiesRelationsResponseType;
    companies: Array<company>;
    localization: any;
};

const AddEditUserPaneConnected = ({
    params,
    getUserById,
    localization,
    createUser,
    companies,
    fetchMyCompanies,
    fetchCompaniesRelations,
    updateUserDetails,
    resetUserPassword,
    deleteUser,
    restoreUser,
    checkEmailExist,
    relations
}: Props) => {
    useEffect(() => {
        fetchMyCompanies();
        fetchCompaniesRelations();
    }, []);
    return (
        <AddEditUser
            updateUserDetails={updateUserDetails}
            resetUserPassword={resetUserPassword}
            user={params?.userId ? getUserById(params.userId) : undefined}
            createUser={createUser}
            deleteUser={deleteUser}
            restoreUser={restoreUser}
            checkEmailExist={checkEmailExist}
            localization={localization}
            companies={companies}
            relations={relations}
        />
    );
};

const mapStateToProps = (state: RootState) => ({
    localization: localizationSelectors.usersMgmtPage(state),
    relations: companiesRelationsSelectors.companiesRelations(state),
    companies: companiesSelectors.companies(state),
    getUserById: (userId: string) => adminSelectors.getUserById(state)(userId)
});

const mapDispatchToProps = (dispatch: any) => ({
    fetchMyCompanies: () => dispatch.companies.fetchMyCompanies(),
    fetchCompaniesRelations: () => dispatch.companiesRelations.fetchCompaniesRelations(),
    updateUserDetails: (details: companyAdminUpdateUserPayload, userId: string) => dispatch.admin.updateUserDetails({ details, userId }),
    resetUserPassword: (userId: string, email: string) => dispatch.admin.resetUserPassword({ userId, email }),
    deleteUser: (userId: string) => dispatch.admin.deleteUser(userId),
    restoreUser: (userId: string) => dispatch.admin.restoreUser(userId),
    createUser: (payload: companyAdminCreateUserPayload) => dispatch.admin.createUser(payload),
    checkEmailExist: (email: string) => dispatch.admin.checkEmailExist(email)
});

export default connect(mapStateToProps, mapDispatchToProps)(AddEditUserPaneConnected);
