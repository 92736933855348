import React from 'react';
import { MenuItem, Typography, MenuItemProps } from '@mui/material';
import { Box, styled } from '@mui/system';

const StyledMenuItem = styled(MenuItem)({
    paddingRight: 0,
    paddingLeft: '4px',
    display: 'flex',
    justifyContent: 'space-between'
});

const StyledTypography = styled(Typography)({
    paddingLeft: '8px',
    paddingRight: '8px',
    textAlign: 'left'
});

const FlexBox = styled(Box)({
    display: 'flex'
});

type Props = {
    label: string;
    leftIcon?: JSX.Element;
    rightIcon?: JSX.Element;
    menuItemProps?: Omit<MenuItemProps, 'sx'>;
    className?: string;
    onClick?: React.MouseEventHandler<HTMLDivElement>;
};

const IconMenuItem = ({ leftIcon, rightIcon, label, menuItemProps, className, onClick }: Props) => {
    return (
        <div onClick={onClick}>
            <StyledMenuItem {...menuItemProps} className={className}>
                <FlexBox>
                    {leftIcon}
                    <StyledTypography fontSize={14}>{label}</StyledTypography>
                </FlexBox>
                {rightIcon}
            </StyledMenuItem>
        </div>
    );
};

export default IconMenuItem;
