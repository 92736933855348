import React, { useCallback, useEffect, useRef, useState } from 'react';
import { Field } from 'formik-antd';
import { FieldProps } from 'formik';
import TextEditor from '../base/TextEditor';
import { ErrorText } from '.';
import { BaseProps } from '../base/TextEditor';
import { createEditorStateWithText } from '@draft-js-plugins/editor';
import { EditorState } from 'draft-js';
import { useDebouncedCallback } from 'use-debounce/lib';

type Props = BaseProps & {
    name: string;
};

const INPUT_DELAY = 500;

const FormTextEditor = ({ name, editorState, onChange, ...restProps }: Props) => {
    const emptyEditorState = createEditorStateWithText('');

    const [innerValue, setInnerValue] = useState<EditorState>(editorState || emptyEditorState);

    useEffect(() => {
        if (editorState) {
            setInnerValue(editorState);
        } else {
            setInnerValue(emptyEditorState);
        }
    }, [editorState]);

    const debouncedHandleOnChange = useDebouncedCallback((editorState: EditorState) => {
        if (onChange) {
            onChange(editorState);
        }
    }, INPUT_DELAY);

    const handleOnChange = useCallback((editorState: EditorState) => {
        setInnerValue(editorState);
        debouncedHandleOnChange(editorState);
    }, []);

    return (
        <Field name={name}>
            {({ field, meta }: FieldProps) => {
                const { touched, error } = meta;
                const hasError = touched && error !== undefined;
                return (
                    <div>
                        <TextEditor hasError={hasError} {...restProps} onChange={handleOnChange} editorState={innerValue} />
                        <ErrorText>{hasError && error}</ErrorText>
                    </div>
                );
            }}
        </Field>
    );
};

export default FormTextEditor;
