import React from 'react';
import styled from 'styled-components';
import { userInfo } from '../../../../models/entities/user';
import UsersMgmtPageHeader from './components/UsersMgmtPageHeader';
import UsersMgmtGrid from './components/UsersMgmtGrid';
import { drawers } from '../../../../state/ducks/header/header';
import { gridSortType } from '../../../shared/Grid/Grid';

type Props = {
    fetchUsers: () => Promise<void>;
    users: userInfo[];
    localization?: any;
    showLoader: boolean;
    setDrawerOpen: (open: drawers, params?: any) => void;
    setGridFilter: (text: string) => void;
    setUsersGridSort: (sort?: gridSortType<userInfo>) => void;
    usersGridSort?: gridSortType<userInfo>;
    gridFilter?: string;
};
const UsersMgmtPage = ({
    fetchUsers,
    users,
    localization,
    showLoader,
    setDrawerOpen,
    setGridFilter,
    gridFilter,
    setUsersGridSort,
    usersGridSort
}: Props) => {
    return (
        <PageWrapper>
            <HeaderWrapper>
                <UsersMgmtPageHeader
                    fetchUsers={fetchUsers}
                    localization={localization}
                    setDrawerOpen={setDrawerOpen}
                    setGridFilter={setGridFilter}
                    gridFilter={gridFilter}
                />
            </HeaderWrapper>
            <ContainersGridWrapper>
                <UsersMgmtGrid
                    usersGridData={users}
                    showLoader={showLoader}
                    localization={localization}
                    setDrawerOpen={setDrawerOpen}
                    setUsersGridSort={setUsersGridSort}
                    usersGridSort={usersGridSort}
                />
            </ContainersGridWrapper>
        </PageWrapper>
    );
};

const PageWrapper = styled.div`
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
`;
const HeaderWrapper = styled.div`
    width: 100%;
    padding: 20px 30px;
`;
const ContainersGridWrapper = styled.div`
    flex: 1;
`;

export default UsersMgmtPage;
