import React, { useState } from 'react';
import { shipment, shipmentSettingsFunc } from '../../../models/entities/shipment/shipment';
import { styled } from '../../shared/Theme/theme';
import moment from 'moment';
import { drawers } from '../../../state/ducks/header/header';
import ShipmentRoute from './ShipmentRoute';
import { connect } from 'react-redux';
import { RootState } from '../../../state/store/store';
import { ColumnType } from '../Grid/types/Column';
import ShipmentMenu from '../Grid/specialColumns/ShipmentMenu';
import { TriggerProps } from '../Menu/Menu';
import ShipmentWarning from '../Grid/specialColumns/ShipmentWarning';
import ShipmentTypeWithTooltip from './ShipmentTypeWithTooltip';
import carriers from '../../../static/data/carriers.json';
import { shipmentsSelectors } from '../../../state/ducks/shipments/selectors';
import { DraggableProvided } from 'react-beautiful-dnd';
import { useHistory } from 'react-router-dom';
import { PagesRoutes } from '../../../routing/PagesRoutes';
import AnimateHeight from 'react-animate-height';

type Props = {
    shipment: shipment;
    gridColumns: Array<ColumnType<shipment>>;
    hasMenu?: boolean;
    isDragging?: boolean;
    provided?: DraggableProvided;
    refOpenShipmentPane?: boolean;
    setDrawerOpen?: (open: drawers, params?: any) => void;
    settings?: shipmentSettingsFunc;
    onClickRef?: () => void;
    isLastVisited?: boolean;
    opened?: boolean;
};
const ShipmentItem = ({
    shipment,
    setDrawerOpen,
    gridColumns,
    settings,
    isDragging,
    refOpenShipmentPane,
    hasMenu,
    provided,
    onClickRef,
    isLastVisited,
    opened
}: Props) => {
    const [isDetailsHidden, setIsDetailsHidden] = useState(!opened);
    const history = useHistory();

    const renderShipmentDetails = (skip?: number, take?: number) => {
        const fieldsToExclude = ['CargoZoneNumber', 'typeCode', 'originStation', 'destinationStation', 'state', 'alertStatus', 'ata'];
        if (!shipment['ata']) fieldsToExclude.push('eta');

        return gridColumns
            .filter((column) => {
                const key = column.field;
                const value = shipment[key];
                return column.isDisplayed !== false && value != null && value.toString().trim() != '' && !fieldsToExclude.includes(key);
            })
            .slice(skip, take)
            .map((column, i) => {
                let detailValue = shipment[column.field];

                switch (column?.columnType) {
                    case 'date':
                        detailValue = moment(detailValue?.toString()).format('DD/MM/YY');
                        break;
                    case 'date-time':
                        detailValue = moment(detailValue?.toString()).format('DD/MM/YYYY HH:mm');
                        break;
                    case 'event_name':
                        detailValue =
                            (settings && settings(shipment)?.eventTypes?.find((item: any) => item.code === detailValue)?.name) || detailValue;
                        break;
                    case 'carrier':
                        detailValue = carriers.find((item) => item.carrierCode === detailValue)?.carrierName || detailValue;

                        break;
                }
                const bold = ['consigneeName', 'shipperName'].includes(column.field);
                return (
                    <div className="detail" key={i}>
                        <span className="detail-header">{column?.title}: </span>
                        <span className={`detail-value ${bold && 'bold'}`}>{detailValue}</span>
                    </div>
                );
            });
    };

    const renderAtaOrEta = () => (
        <div className="detail">
            <span className="detail-header">{shipment['ata'] ? 'ATA' : 'ETA'}: </span>
            <span className="detail-value">
                {shipment['ata'] || shipment['eta'] ? moment((shipment['ata'] || shipment['eta'])?.toString()).format('DD/MM/YYYY HH:mm') : '-'}
            </span>
        </div>
    );

    const itemColor = (() => {
        if (shipment.state === 'DRAFT') return '#b2b2b2';
        switch (shipment.alertStatus) {
            case null:
            case 3:
                return '#639e31';
            case 2:
                return '#f4c915';
            case 1:
                return '#c1292e';
            default:
                return '#639e31';
        }
    })();
    return (
        <ItemContainer
            ref={provided?.innerRef}
            isDragging={isDragging}
            {...provided?.draggableProps}
            {...provided?.dragHandleProps}
            style={{
                ...provided?.draggableProps.style
            }}
            isLastVisited={isLastVisited}
        >
            <StateColor color={itemColor} />
            <ItemContent>
                <ShipmentItemTitle>
                    <div className="shipment-icon">
                        <ShipmentTypeWithTooltip iconWidth={28} typeCode={shipment.typeCode} subTypeCode={shipment.subTypeCode} />
                    </div>
                    <span
                        className="shipment-number"
                        onClick={() => {
                            if (refOpenShipmentPane) setDrawerOpen && setDrawerOpen(`SHIPMENT${shipment.id}`, { shipmentId: shipment.id });
                            else history.push(PagesRoutes.Shipments + '/' + shipment.id);

                            onClickRef && onClickRef();
                        }}
                    >
                        #{shipment.CargoZoneNumber}
                    </span>
                    <div className="shipment-alert-icon">
                        <ShipmentWarning alertStatus={shipment.alertStatus} shipmentId={shipment.id} iconWidth={15} />
                    </div>
                </ShipmentItemTitle>
                <ShipmentRouteContainer>
                    <ShipmentRoute entity={shipment} flagSize="20px" fontSize="14px" fontWeight="300px" />
                </ShipmentRouteContainer>
                <Details>
                    {renderAtaOrEta()}
                    {renderShipmentDetails(0, 2)}
                    {opened ? (
                        renderShipmentDetails(2)
                    ) : (
                        <AnimateHeight id="example-panel" duration={500} height={!isDetailsHidden ? 'auto' : 0} animateOpacity={true}>
                            {renderShipmentDetails(2)}
                        </AnimateHeight>
                    )}
                    {/* <HiddenDetails isHidden={isDetailsHidden}>{renderShipmentDetails(2)}</HiddenDetails> */}
                </Details>
            </ItemContent>
            <div className="top-hidden-container" onClick={() => setIsDetailsHidden(!isDetailsHidden)}>
                {!opened && <span className="material-icons details-toggle">{isDetailsHidden ? 'keyboard_arrow_down' : 'keyboard_arrow_up'}</span>}
            </div>

            {hasMenu && (
                <div className="top-hidden-container menu-container">
                    <span className="menu-toggle">
                        <ShipmentMenu
                            shipment={shipment}
                            entityId={shipment.id}
                            entityData={{ CargoZoneNumber: shipment.CargoZoneNumber, companyId: shipment.companyId }}
                            trigger={(props: TriggerProps) => (
                                <span className="material-icons" style={{ cursor: 'pointer' }} onClick={(e) => props.onClick(e)}>
                                    more_vert
                                </span>
                            )}
                        />
                    </span>
                </div>
            )}
        </ItemContainer>
    );
};

interface StateColorProps {
    color: string;
}

const StateColor = styled.div<StateColorProps>`
    width: 3px;
    background-color: ${(props) => props.color};
`;

const ItemContent = styled.div`
    flex: 1;
    height: 100%;
    padding: 12px;
`;

const Details = styled.div`
    .detail {
        margin-top: 4px;
        .detail-header {
            color: #394372;
        }
        .detail-value {
            color: #757575;
        }
        .bold {
            font-weight: bold;
        }
    }
`;
interface HiddenDetailsProps {
    isHidden: boolean;
}
const HiddenDetails = styled.div<HiddenDetailsProps>`
    height: ${(props) => (props.isHidden ? '0px' : 'auto')};
    overflow: hidden;
`;

interface ItemContainerProps {
    isDragging?: boolean;
    isLastVisited?: boolean;
}
const ItemContainer = styled.div<ItemContainerProps>`
    user-select: none;
    margin: 0 0 8px 0;
    background-color: ${(props) => (props.isDragging ? '#f1f1f1' : '#fff')};
    display: flex;
    box-shadow: 0 0 10px rgb(228, 228, 228);
    border: 1px solid #e4e4e4;
    width: 100%;
    z-index: ${(props) => (props.isDragging ? '999 !important' : 'auto')};
    ${(props) => props.isLastVisited && 'box-shadow:rgb(0, 114, 255) 0px 0px 5px 1px;'}
    position: relative;

    :hover {
        .top-hidden-container {
            display: flex;
            align-items: center;
            justify-content: center;
        }
    }
    .menu-container {
        top: 0 !important;
        right: 0;
        width: auto !important;
        justify-content: flex-end !important;
        bottom: auto !important;
    }
    .top-hidden-container {
        display: none;
        position: absolute;
        bottom: 0;
        width: 100%;

        .details-toggle {
            border: 1px solid #d4d4d4;
            width: 30px;
            height: 15px;
            text-align: center;
            font-size: 14px;
            border-bottom: none;
            cursor: pointer;
            background-color: #f0f0f0;
        }
        .menu-toggle {
            margin: 5px;
            border: 1px solid #d4d4d4;
            text-align: center;
            cursor: pointer;
            background-color: #f0f0f0;

            .material-icons {
                font-size: 18px;
            }
        }
    }
`;

const ShipmentItemTitle = styled.div`
    display: flex;
    align-items: center;
    color: #394372;
    font-size: 14px;
    white-space: nowrap;

    .shipment-icon {
        margin-right: 10px;
    }
    .shipment-number {
        cursor: pointer;
        transition: color 0.1s;

        &:hover {
            color: #008ac9;
        }
    }
    .shipment-alert-icon {
        margin-left: 10px;
    }
`;

const ShipmentRouteContainer = styled.div`
    margin-top: 10px;
`;

const mapStateToProps = (state: RootState) => ({
    // showLoader: state.loading.effects.shipments.fetchShipments || state.loading.effects.allShipmentsPage.fetchGridColumns,
    settings: shipmentsSelectors.settingsByLanguage(state)
});

const mapDispatchToProps = (dispatch: any) => ({
    setDrawerOpen: (open: drawers, params?: any) => dispatch.header.setDrawerOpenParams({ open, params })
});

export default connect(mapStateToProps, mapDispatchToProps)(React.memo(ShipmentItem));
