import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { chatRequest } from '../../../models/entities/chat/chatRequest';
import { chatsSelectors } from '../../../state/ducks/chats/selectors';
import { localizationSelectors } from '../../../state/ducks/localization/selectors';
import { RootState } from '../../../state/store/store';
import ChatRequests from './ChatRequests';

type Props = {
    getChatRequests: () => void;
    inviteUser: (email: string[]) => Promise<boolean>;
    approveRequest: (requestId: string) => void;
    denyRequest: (requestId: string) => void;
    sentRequests?: Array<chatRequest>;
    recievedRequests?: Array<chatRequest>;
    setEmailAutoCompleteSettings: (emails: string[]) => void;
    localization: any;
};
const ChatRequestsConnected = ({
    getChatRequests,
    inviteUser,
    sentRequests,
    recievedRequests,
    approveRequest,
    denyRequest,
    setEmailAutoCompleteSettings,
    localization
}: Props) => {
    useEffect(() => {
        getChatRequests();
    }, [getChatRequests]);
    return (
        <ChatRequests
            sent={sentRequests}
            recieved={recievedRequests}
            inviteUser={inviteUser}
            approveRequest={approveRequest}
            denyRequest={denyRequest}
            setEmailAutoCompleteSettings={setEmailAutoCompleteSettings}
            localization={localization}
        />
    );
};

const mapStateToProps = (state: RootState) => ({
    sentRequests: chatsSelectors.sentRequests(state),
    recievedRequests: chatsSelectors.recievedRequests(state),
    localization: localizationSelectors.chat(state)
});

const mapDispatchToProps = (dispatch: any) => ({
    getChatRequests: () => dispatch.chats.getChatRequests(),
    inviteUser: (email: string[]) => dispatch.chats.inviteUser(email),
    approveRequest: (requestId: string) => dispatch.chats.approveRequest(requestId),
    denyRequest: (requestId: string) => dispatch.chats.denyRequest(requestId),
    setEmailAutoCompleteSettings: (emails: string[]) => dispatch.user.setEmailAutoCompleteSettings(emails)
});

export default connect(mapStateToProps, mapDispatchToProps)(ChatRequestsConnected);
