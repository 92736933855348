import React from 'react';
import { businessPartner } from '../../../../../models/entities/businessPartner/businessPartner';
import { contact } from '../../../../../models/entities/businessPartner/contact';
import { AutoComplete } from '../../../../shared/inputs/base';
import { Option } from '../../../../shared/inputs/base/AutoComplete';

type Props = {
    businessPartner: businessPartner;
    setEmail: (email: string) => void;
    email?: string;
    openedFromModal?: {
        setVisible: React.Dispatch<React.SetStateAction<boolean>>;
    };
};
const opt = (value: string) => ({ text: value, value });
const trimLower = (value: string) => value.split(' ').join().toLowerCase();

const SendGetStartedInventationModalContent = ({ setEmail, email, businessPartner }: Props) => {
    const businessPartnerContactsEmailsOptions =
        businessPartner.contacts?.reduce(
            (options: Option[], contact: contact) => {
                if (!contact.email) return options;

                if (options.some((opt) => trimLower(opt.value) === trimLower(contact.email))) return options;
                return [...options, opt(contact.email)];
            },
            businessPartner.email ? [opt(businessPartner.email)] : []
        ) || [];

    return (
        <AutoComplete
            style={{ width: '100%', marginBottom: 0 }}
            placeHolder={'Email'}
            options={businessPartnerContactsEmailsOptions}
            showSearch={true}
            onChange={setEmail}
            value={email}
        />
    );
};

export default SendGetStartedInventationModalContent;
