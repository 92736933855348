import SideEffectJS from 'side-effect-js';
import mockColumns from './mockColumns';

const delay = (ms: number) => new Promise((res) => setTimeout(res, ms));

const fetchInventoryGridColumnsMock = async () => {
    await delay(1500);
    return Promise.resolve(mockColumns);
};
const fetchInventoryGridColumnsReal = () => Promise.reject('Implement API');

const fetchInventoryGridColumnsSideEffect = SideEffectJS.CreateEffect(
    'gridColumnsInventoryLog',
    fetchInventoryGridColumnsReal,
    fetchInventoryGridColumnsMock
);

export default fetchInventoryGridColumnsSideEffect;
