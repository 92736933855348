import React from 'react';
import { PagesRoutes } from '../../../../routing/PagesRoutes';
import StyledLink from '../../SmallComponents/StyledLink';

type Props = {
    purchaseDemandId: string;
    purchaseDemandNumber: string;
};

const PurchaseDemandLink = ({ purchaseDemandId, purchaseDemandNumber }: Props) => {
    return <StyledLink to={`${PagesRoutes.PurchaseDemands}/${purchaseDemandId}`}>{purchaseDemandNumber}</StyledLink>;
};

export default PurchaseDemandLink;
