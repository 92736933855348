import React from 'react';
import { styled } from '../Theme/theme';
import CancelSharpIcon from '@material-ui/icons/CancelSharp';

type Props = {
    text: string;
    onClose?: (tag: string) => void;
};
const Tag = ({ text, onClose }: Props) => {
    const onCloseClick = () => {
        if (onClose) {
            onClose(text);
        }
    };
    return (
        <TagWrapper>
            {text}
            <CancelSharpIcon
                onClick={onCloseClick}
                style={{
                    fontSize: '12px',
                    marginLeft: '8px',
                    color: '#fff',
                    backgroundColor: '#757575',
                    borderRadius: '20px',
                    cursor: 'pointer'
                }}
            />
        </TagWrapper>
    );
};

const TagWrapper = styled.div`
    display: flex;
    height: 24px;
    border-radius: 100px;
    border: 1px solid #ededed;
    background-color: #ededed;
    color: #757575;
    padding: 4px 6px;
    box-sizing: border-box;
    font-size: 14px;
    justify-content: center;
    align-items: center;
`;

export default Tag;
