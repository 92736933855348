import React from 'react';
import { styled } from '../../../shared/Theme/theme';
import { discussion } from '../../../../models/entities/common_subentities/discussion';
import { userInfo } from '../../../../models/entities/user';
import DiscussionItem from './DiscussionItem';
import Loader from '../../../shared/SmallComponents/Loader';
import { userDetails } from '../../../../state/ducks/users/users';
import { shipment } from '../../../../models/entities/shipment/shipment';
import { ColumnType } from '../../../shared/Grid/types/Column';

type Props = {
    entityId?: string;
    entityName?: string;
    discussions?: Array<discussion>;
    localization: any;
    currentUser: userInfo;
    createDiscussion: (entityId: string, discussion: discussion, entityName?: string) => void;
    updateDiscussion: (entityId: string, discussion: discussion, entityName?: string) => void;
    deleteDiscussion: (entityId: string, discussionId: string) => void;
    getUserById: (userId: string) => userDetails | undefined;
    getShipmentById: (id: string) => shipment | undefined;
    gridColumns: Array<ColumnType<shipment>>;
    showLoader: boolean;
};

const DiscussionsSection = ({
    entityId,
    entityName,
    discussions,
    localization,
    currentUser,
    createDiscussion,
    updateDiscussion,
    deleteDiscussion,
    showLoader,
    getUserById,
    getShipmentById,
    gridColumns
}: Props) => {
    return (
        <Wrapper id="discussions">
            <Header>
                <span>{localization.discussions.header}</span>
                <LoaderContainer>{showLoader ? <Loader width="17px" marginTop="0px" showText={false} /> : null}</LoaderContainer>
            </Header>

            <DiscussionItem
                currentUser={currentUser}
                discussion={
                    {
                        userId: currentUser.id,
                        userName: currentUser.firstName + ' ' + currentUser.lastName,
                        userPic: currentUser.profileImg
                    } as discussion
                }
                localization={localization}
                isNew={true}
                createDiscussion={createDiscussion}
                entityId={entityId}
                entityName={entityName}
                isSubmit={showLoader}
                getUserById={getUserById}
                getShipmentById={getShipmentById}
                gridColumns={gridColumns}
            />
            {discussions ? (
                discussions?.map((discussion) => (
                    <DiscussionItem
                        key={discussion.id}
                        currentUser={currentUser}
                        discussion={discussion}
                        localization={localization}
                        updateDiscussion={updateDiscussion}
                        deleteDiscussion={deleteDiscussion}
                        entityId={entityId}
                        entityName={entityName}
                        isSubmit={showLoader}
                        getUserById={getUserById}
                        getShipmentById={getShipmentById}
                        gridColumns={gridColumns}
                    />
                ))
            ) : (
                <Loader />
            )}
        </Wrapper>
    );
};
const Wrapper = styled.div`
    width: 100%;
    position: relative;
`;
const Header = styled.div`
    color: #394372;
    font-size: 18px;
    font-weight: bold;
    margin-bottom: 10px;
    display: flex;
    align-items: center;
    position: relative;
`;
const LoaderContainer = styled.div`
    position: absolute;
    right: 0;
`;

export default DiscussionsSection;
