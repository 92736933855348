import React, { useEffect, useState } from 'react';
import { settings, purchaseOrder } from '../../../../models/entities/purchaseOrder/purchaseOrder';
import { localizationSelectors } from '../../../../state/ducks/localization/selectors';
import { purchaseOrdersSelectors } from '../../../../state/ducks/purchaseOrders/selectors';
import { RootState } from '../../../../state/store/store';
import { connect } from 'react-redux';
import { singlePurchaseOrderSelectors } from '../../../../state/ducks/singlePurchaseOrder/selectors';
import { document } from '../../../../models/entities/common_subentities/document';
import DocumentFormConnected, { formMode } from '../../commonSubEntities/documents/DocumentForm/DocumentFormConnected';

type Props = {
    purchaseOrder?: purchaseOrder;
    document?: document;
    mode: formMode;
    localizations?: any;
    settings?: settings;
    openedFromModal?: {
        setVisible: React.Dispatch<React.SetStateAction<boolean>>;
    };
    confirmation?: { setConfirm: React.Dispatch<React.SetStateAction<boolean>> };
    createPurchaseOrderDocument: (purchaseOrderNumber: string, document: document) => Promise<void>;
    createPurchaseOrderDocumentSupplierUpdates: (purchaseOrderId: string, supplierName: string, document: document) => Promise<void>;
    updatePurchaseOrderDocument: (purchaseOrderNumber: string, document: document) => Promise<void>;
    updatePurchaseOrderDocumentSupplierUpdates: (purchaseOrderId: string, supplierName: string, document: document) => Promise<void>;
    showLoader?: boolean;
    defaultValues?: document;
    additionalData?: { [key: string]: any };
};

const PurchaseOrderDocumentFormConnected = ({
    purchaseOrder,
    document,
    localizations,
    mode,
    settings,
    openedFromModal,
    confirmation,
    createPurchaseOrderDocument,
    createPurchaseOrderDocumentSupplierUpdates,
    updatePurchaseOrderDocument,
    updatePurchaseOrderDocumentSupplierUpdates,
    showLoader,
    defaultValues,
    additionalData
}: Props) => {
    return (
        <DocumentFormConnected<purchaseOrder, settings>
            entity={purchaseOrder}
            document={document}
            localizations={localizations}
            mode={mode}
            settings={settings}
            openedFromModal={openedFromModal}
            confirmation={confirmation}
            createDocument={async (entityId: string, documents: document) => {
                if (typeof additionalData?.supplierName === 'string') {
                    await createPurchaseOrderDocumentSupplierUpdates(entityId, additionalData.supplierName, documents);
                    return;
                }

                await createPurchaseOrderDocument(entityId, documents);
            }}
            updateDocument={async (entityId: string, documents: document) => {
                if (typeof additionalData?.supplierName === 'string') {
                    await updatePurchaseOrderDocumentSupplierUpdates(entityId, additionalData.supplierName, documents);
                    return;
                }

                await updatePurchaseOrderDocument(entityId, documents);
            }}
            showLoader={showLoader}
            defaultValues={defaultValues}
        />
    );
};
const mapStateToProps = (state: RootState) => ({
    localizations: localizationSelectors.singlePurchaseOrderPage(state),
    settings: purchaseOrdersSelectors.settingsByLanguage(state),
    purchaseOrder: singlePurchaseOrderSelectors.purchaseOrder(state),
    showLoader:
        !!state.loading.effects.purchaseOrders.createPurchaseOrderDocument ||
        !!state.loading.effects.purchaseOrders.createPurchaseOrderDocumentSupplierUpdates ||
        !!state.loading.effects.purchaseOrders.updatePurchaseOrderDocument ||
        !!state.loading.effects.purchaseOrders.updatePurchaseOrderDocumentSupplierUpdates
});

const mapDispatchToProps = (dispatch: any) => ({
    createPurchaseOrderDocument: (purchaseOrderNumber: string, document: document) =>
        dispatch.purchaseOrders.createPurchaseOrderDocument({ purchaseOrderNumber, document }),
    createPurchaseOrderDocumentSupplierUpdates: (purchaseOrderId: string, supplierName: string, document: document) =>
        dispatch.purchaseOrders.createPurchaseOrderDocumentSupplierUpdates({ purchaseOrderId, supplierName, document }),
    updatePurchaseOrderDocument: (purchaseOrderNumber: string, document: document) =>
        dispatch.purchaseOrders.updatePurchaseOrderDocument({ purchaseOrderNumber, document }),
    updatePurchaseOrderDocumentSupplierUpdates: (purchaseOrderId: string, supplierName: string, document: document) =>
        dispatch.purchaseOrders.updatePurchaseOrderDocumentSupplierUpdates({ purchaseOrderId, supplierName, document })
});

export default connect(mapStateToProps, mapDispatchToProps)(PurchaseOrderDocumentFormConnected);
