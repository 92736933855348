import React, { useEffect } from 'react';
import { match, useHistory } from 'react-router-dom';
import styled from 'styled-components';
import SuccessIcon from '../../../../static/icons/success.svg';
import { Location } from 'history';
import { LocationStateType } from '../GetStartedPageConnected';

type Props = {
    localization: any;
    match: match;
    location: Location<LocationStateType>;
};
const RegistrationSuccess = ({ localization, match, location }: Props) => {
    const history = useHistory();

    useEffect(() => {
        if (!location.state?.userHasRegistered) history.replace(`${match.url}`);
    }, [Object.values(location?.state || {})]);

    return (
        <Container>
            <img src={SuccessIcon} width={120} alt={localization.edit} style={{ marginBottom: 20 }} />
            <Title>{localization.title}</Title>
            <SubTitle>{localization.sub_title}</SubTitle>
        </Container>
    );
};

const Container = styled.div`
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top: 10%;
    height: 100%;
`;

const Title = styled.div`
    font-weight: 700;
    font-size: 48px;
    line-height: 46px;
    max-width: 700px;
    text-align: center;
    letter-spacing: -0.75px;
    margin-bottom: 10px;
`;

const SubTitle = styled.div`
    font-size: 18px;
    line-height: 27px;
    margin-bottom: 32px;
    color: #454245;
    max-width: 700px;
    text-align: center;
`;

export default RegistrationSuccess;
