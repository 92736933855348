import BaseController from '../..';
import { event } from '../../../../models/entities/common_subentities/event';
import IPurchaseOrderEvents from './IPurchaseOrderEvents';

export default class PurchaseOrderEvents extends BaseController implements IPurchaseOrderEvents {
    constructor(baseUrl: string) {
        super(baseUrl);
    }
    async fetchPurchaseOrderEvents(purchaseOrderNumber: string) {
        const response = await this.client.get(`/purchaseOrder/events/${purchaseOrderNumber}`);
        return response;
    }
    async createPurchaseOrderEvent(purchaseOrderId: string, data: event) {
        const response = await this.client.post(`/purchaseOrder/events`, JSON.stringify({ purchaseOrderId, data }));
        return response;
    }
    async updatePurchaseOrderEvent(purchaseOrderId: string, data: event) {
        const response = await this.client.put(`/purchaseOrder/events`, JSON.stringify({ purchaseOrderId, data }));
        return response;
    }
    async deletePurchaseOrderEvent(purchaseOrderId: string, eventId: number) {
        const response = await this.client.delete(`/purchaseOrder/events`, JSON.stringify({ purchaseOrderId, id: eventId }));
        return response;
    }
}
