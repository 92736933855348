import { RootState } from '../../../state/store/store';
import { shipmentsSelectors } from '../shipments/selectors';

const shipmentsSettings = (state: RootState) => shipmentsSelectors.settingsByLanguage(state)();
const ordersStatistics = (state: RootState) => state.dashboard.ordersStatistics;
const upcomingArrivals = (state: RootState) => state.dashboard.upcomingArrivals;
const error = (state: RootState) => state.dashboard.error;

export { default as dashboardSelectors } from './selectors';

export default {
    error,
    upcomingArrivals,
    ordersStatistics
};
