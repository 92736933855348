import React from 'react';
import styled from 'styled-components';
import { tariff } from '../../../../models/entities/tariff/tariff';
import { Option } from '../../../shared/inputs/base/DropDown';
import ShipmentRoute from '../../../shared/SmallComponents/ShipmentRoute';
import UserBadge from '../../../shared/UserBadge/UserBadge';

type Props = {
    tariff: tariff;
    localization: any;
    allowedCompaniesDropDownOptions: Option[];
};

const TariffOverviewHeader = ({ tariff, localization, allowedCompaniesDropDownOptions }: Props) => {
    const tarrifCompany = allowedCompaniesDropDownOptions.find((opt) => opt.value === tariff.companyId)?.text;

    return (
        <OverviewHeaderWrapper>
            <SectionWrapper>
                <TariffNumber id="tariff-number">
                    <span>{localization.overview.tariff}</span> #{tariff.cargoZoneNumber}
                </TariffNumber>
                <ReferencesSection>
                    <SingleRefWrapper>
                        <RefText>{localization.form.fields.forwarderTariffNumber.title}:</RefText>
                        <div>{tariff.forwarderTariffNumber}</div>
                    </SingleRefWrapper>
                </ReferencesSection>
            </SectionWrapper>

            <SectionsSeparator />

            <SectionWrapper>
                <ShipmentRoute entity={tariff} flagSize="20px" fontSize="14px" fontWeight="300px" />
            </SectionWrapper>

            <SectionsSeparator />

            <SectionWrapper>
                <DetailWrapper>
                    <div>{localization.form.fields.companyId.title}:</div>
                    <div style={{ fontWeight: 'bolder', fontSize: '20px' }}>{tarrifCompany}</div>
                </DetailWrapper>
            </SectionWrapper>

            <SectionsSeparator />

            <SectionWrapper>
                <RowWrapper>
                    <UserBadge width={40} userId={tariff.userId} />
                </RowWrapper>
            </SectionWrapper>
        </OverviewHeaderWrapper>
    );
};

const OverviewHeaderWrapper = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    width: 100%;
    border-bottom: solid 1px #e0e0e0;
    padding: 24px 18px 18px 48px;
`;

const SectionWrapper = styled.div``;

const DetailWrapper = styled.div`
    color: #394372;

    margin: 4px 0;
    &:first-child {
        margin-top: 0;
    }
`;

const SectionsSeparator = styled.div`
    width: 1px;
    border-right: solid 1px #e0e0e0;
    margin: 0 18px;
`;

const TariffNumber = styled.div`
    color: ${(props) => props.theme.colors.pageTitle};
    font-size: ${(props) => props.theme.fontSizes.pageTitle};
    font-weight: bold;
    height: 24px;
    white-space: nowrap;

    & span {
        @media screen and (max-width: 768px) {
            display: none;
        }
    }
`;
const ReferencesSection = styled.div`
    color: ${(props) => props.theme.colors.pageTitle};
    font-size: ${(props) => props.theme.fontSizes.subTitlte};
    margin-top: 6px;
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;

    & ::-webkit-scrollbar-thumb {
        background-color: rgba(0, 0, 0, 0.3) !important;
        border-radius: 5.5px;
    }
`;

const SingleRefWrapper = styled.div`
    display: flex;
    align-items: center;
    margin-right: 12px;
    flex-wrap: wrap;
    padding: 3px 0;
`;

const RefText = styled.span`
    font-weight: bold;
    margin-right: 4px;
`;

const RowWrapper = styled.div`
    display: flex;
    white-space: nowrap;
    flex-direction: row;
    padding: 6px 0;
    color: #394372;
`;

export default TariffOverviewHeader;
