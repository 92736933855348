import React, { useRef, useState } from 'react';
import warningRed from '../../../../static/icons/warning-red-18dp.svg';
import warningOrange from '../../../../static/icons/warning-orange-18dp.svg';
import warningGreen from '../../../../static/icons/warning-green-18dp.svg';
import { styled } from '../../Theme/theme';
import { makeStyles } from '@material-ui/core/styles';
import { Popover } from '@material-ui/core';
import { alertImgs, shipmentAlert, shipmentAlertsEnum } from '../../../../models/entities/shipment/shipmentAlert';
import { AlertItem, AlertImage, AlertMessage } from '../../../pages/singleShipment/SingleShipmentPage';
import { connect } from 'react-redux';
import { shipmentsSelectors } from '../../../../state/ducks/shipments/selectors';
import { RootState } from '../../../../state/store/store';
import Loader from '../../SmallComponents/Loader';
import { localizationSelectors } from '../../../../state/ducks/localization/selectors';

type Props = {
    alertStatus?: number;
    localization: any;
    shipmentId: string;
    getAlertsByShipmentId: (id: string) => Array<shipmentAlert> | undefined;
    fetchShipmentAlertsById: (shipmentId: string) => Promise<void>;
    isLoadingAlerts: boolean;
    iconWidth?: number;
};
const useStyles = makeStyles((theme) => ({
    popover: {
        pointerEvents: 'none'
    },
    popoverContent: {
        pointerEvents: 'auto',
        minWidth: '90px',
        minHeight: '90px',
        display: 'flex',
        alignItems: 'center'
    }
}));
const ShipmentWarning = ({
    alertStatus,
    localization,
    shipmentId,
    getAlertsByShipmentId,
    fetchShipmentAlertsById,
    isLoadingAlerts,
    iconWidth
}: Props) => {
    let text = localization.grid.shipmentWarning.success;
    let src = warningGreen;
    const [alerts, setAlerts] = useState<Array<shipmentAlert> | undefined>(undefined);
    const [openedPopover, setOpenedPopover] = useState(false);
    const popoverAnchor = useRef(null);

    const classes = useStyles();

    const popoverEnter = async ({ currentTarget }: any) => {
        setOpenedPopover(true);
        setAlerts(getAlertsByShipmentId(shipmentId));
        if (!alerts && !isLoadingAlerts && [1, 2].includes(alertStatus || 0)) {
            await fetchShipmentAlertsById(shipmentId);
            setAlerts(getAlertsByShipmentId(shipmentId));
        }
    };
    const popoverLeave = ({ currentTarget }: any) => {
        setOpenedPopover(false);
    };
    switch (alertStatus) {
        case 1:
            text = localization.grid.shipmentWarning.critical;
            src = warningRed;
            break;
        case 2:
            text = localization.grid.shipmentWarning.warning;
            src = warningOrange;
            break;
    }
    return (
        <>
            <Container
                ref={popoverAnchor}
                aria-owns="mouse-over-popover"
                aria-haspopup="true"
                onMouseEnter={popoverEnter}
                onMouseLeave={popoverLeave}
            >
                <Image src={src} alt={text} width={iconWidth} />
            </Container>
            <Popover
                id="mouse-over-popover"
                className={classes.popover}
                classes={{
                    paper: classes.popoverContent
                }}
                PaperProps={{
                    onMouseEnter: popoverEnter,
                    onMouseLeave: popoverLeave
                }}
                open={openedPopover}
                anchorEl={popoverAnchor.current}
                anchorOrigin={{
                    vertical: 'center',
                    horizontal: 'right'
                }}
                transformOrigin={{
                    vertical: 'center',
                    horizontal: -14
                }}
            >
                <PopoverContent>
                    {![1, 2].includes(alertStatus || 0) ? (
                        'All good'
                    ) : alerts ? (
                        alerts.length > 0 &&
                        alerts
                            .sort((shipmentAlert: shipmentAlert) => -shipmentAlert.level)
                            .map((shipmentAlert: shipmentAlert, i: number) => {
                                return (
                                    <AlertItem key={i}>
                                        <AlertImage
                                            src={alertImgs[shipmentAlert.level]}
                                            alt={localization.grid.alert_status[shipmentAlertsEnum[shipmentAlert.level]]}
                                        />
                                        <AlertMessage>{shipmentAlert.message}</AlertMessage>
                                    </AlertItem>
                                );
                            })
                    ) : (
                        <Loader width="30px" marginTop="0" showText={false}></Loader>
                    )}
                </PopoverContent>
            </Popover>
        </>
    );
};

interface ImageProps {
    width?: number;
}
const Container = styled.div``;
const PopoverContent = styled.div`
    flex: 1;
    padding: 0 25px;
    display: flex;
    flex-direction: column;

    & :last-child {
        margin-bottom: 0;
    }
`;
const Image = styled.img<ImageProps>`
    ${(props) => (props.width ? 'width:' + props.width + 'px' : 'width:20px')};
`;

const mapStateToProps = (state: RootState) => ({
    getAlertsByShipmentId: (id: string) => shipmentsSelectors.getAlertsByShipmentId(state, id),
    localization: localizationSelectors.allShipmentsPage(state),
    isLoadingAlerts: state.loading.effects.shipments.fetchShipmentAlertsById
});

const mapDispatchToProps = (dispatch: any) => ({
    fetchShipmentAlertsById: (shipmentId: string) => dispatch.shipments.fetchShipmentAlertsById(shipmentId)
});

export default connect(mapStateToProps, mapDispatchToProps)(ShipmentWarning);
