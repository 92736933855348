import { chat, chatType, message, messageAttachment, createChatPayload, editMessagePayload } from './../../../models/entities/chat/chat';
import BaseController from '..';
import IChat from './IChat';
import ChatNetwork from './network/chatNetwork';

export default class Chat extends BaseController implements IChat {
    private chatNetwork: ChatNetwork;
    constructor(baseUrl: string) {
        super(baseUrl);
        this.chatNetwork = new ChatNetwork(baseUrl);
    }
    async fetchActiveChats(): Promise<Array<chat>> {
        const response = await this.client.get('/chat');
        return response;
    }
    async getChatById(chatId: string, messagesToId?: string): Promise<chat> {
        const response = await this.client.post(`/chat/getChatById`, JSON.stringify({ chatId, messagesToId }));
        return response;
    }

    async getContactChat(contactId: string): Promise<chat | null> {
        const response = await this.client.get(`/chat/getContactChat/${contactId}`);
        return response;
    }

    async createChat(payload: createChatPayload): Promise<chat> {
        this.client.setBodyType('FORM-DATA');
        const response = await this.client.post('/chat', this.convertToArrayFormData(payload));
        return response;
    }

    async sendMessage(
        chatId: string,
        message: string,
        sendDate: Date,
        attachments?: messageAttachment[],
        replyMessageId?: string
    ): Promise<{ id: string; attachments: messageAttachment[] }> {
        this.client.setBodyType('FORM-DATA');
        const response = await this.client.post(
            '/chat/message',
            this.convertToArrayFormData({ chatId, message, attachments, replyMessageId, sendDate })
        );
        return response;
    }

    async editMessage(payload: editMessagePayload): Promise<messageAttachment[]> {
        this.client.setBodyType('FORM-DATA');
        const response = await this.client.put('/chat/message', this.convertToArrayFormData(payload));
        return response;
    }
    async deleteMessage(id: string, chatId: string): Promise<string> {
        const response = await this.client.delete(
            '/chat/message',
            JSON.stringify({
                id,
                chatId
            })
        );
        return response;
    }
    async deleteGroupParticipant(chatId: string, userId: string, userName: string): Promise<string> {
        const response = await this.client.put(
            '/chat/group/removeUser',
            JSON.stringify({
                chatId,
                userId,
                userName
            })
        );
        return response;
    }
    async addGroupUser(chatId: string, users: { id: string; name: string }[]): Promise<string> {
        const response = await this.client.put(
            '/chat/group/addUsers',
            JSON.stringify({
                chatId,
                users
            })
        );
        return response;
    }
    async readMessages(chatId: string, messagesIds: string[]): Promise<message> {
        const response = await this.client.post(
            '/chat/message/read',
            JSON.stringify({
                chatId,
                messagesIds
            })
        );
        return response;
    }

    async editGroupName(chatId: string, name: string): Promise<any> {
        const response = await this.client.put(
            '/chat/group/editName',
            JSON.stringify({
                chatId,
                name
            })
        );

        return response;
    }
    network() {
        return this.chatNetwork;
    }

    private convertToArrayFormData({
        chatId,
        message,
        attachments,
        id,
        participantsIds,
        chatType,
        replyMessageId,
        name,
        admins,
        isActivityMessage,
        sendDate
    }: {
        chatId?: string;
        message: string;
        attachments?: messageAttachment[];
        id?: string;
        participantsIds?: string[];
        chatType?: chatType;
        replyMessageId?: string;
        name?: string;
        admins?: string[];
        isActivityMessage?: boolean;
        sendDate?: Date;
    }): FormData {
        const formData = new FormData();
        formData.append('message', message);
        if (chatId) formData.append('chatId', chatId);
        if (id) formData.append('id', id);
        if (chatType) formData.append('chatType', chatType);
        if (replyMessageId) formData.append('replyMessageId', replyMessageId);
        if (name) formData.append('name', name);
        if (sendDate) formData.append('sendDate', sendDate.toString());
        if (isActivityMessage) formData.append('isActivityMessage', isActivityMessage.toString());
        if (participantsIds) participantsIds.forEach((p) => formData.append('participantsIds[]', p));
        if (admins) admins.forEach((a) => formData.append('admins[]', a));
        attachments?.forEach((attachment) => {
            if (attachment.file || attachment.fileId)
                formData.append(
                    'file[]',
                    attachment.file ||
                        new File([] as BlobPart[], attachment.fileId || '', {
                            type: 'fileId'
                        })
                );
            if (attachment.name) formData.append('fileName[]', attachment.name);
        });
        return formData;
    }
}
