import React from 'react';
import styled from 'styled-components';
import { filterFieldsType } from '../../models';
import { parsedFilterFieldType } from './models/parsedFilterFieldType';

export type FilterStripeProps<TEntity, TFilterFields = any> = {
    parsedFilterFields?: parsedFilterFieldType<TEntity>[];
    filterFields: filterFieldsType<TFilterFields> | null;
    removeFilterItem: (
        removedFilter: parsedFilterFieldType<TEntity>,
        filterFields: filterFieldsType<TFilterFields>
    ) => filterFieldsType<TFilterFields>;
    setFilters: (filter: filterFieldsType<TFilterFields>) => void;
    fetchDataByFilter?: (filter: filterFieldsType<TFilterFields>) => Array<TEntity>;
};

function FiltersStripe<TEntity, TFilterFields = any>({
    parsedFilterFields,
    filterFields,
    removeFilterItem,
    setFilters,
    fetchDataByFilter
}: FilterStripeProps<TEntity, TFilterFields>) {
    return (
        <>
            {parsedFilterFields?.map((filter: parsedFilterFieldType<TEntity>, i: number) => {
                return (
                    <FilterBadge key={i}>
                        {filter.parsedValue}
                        <FilterRemove
                            className="material-icons"
                            onClick={() => {
                                if (fetchDataByFilter && filter.field === 'isActive') fetchDataByFilter([]);
                                setFilters(removeFilterItem(filter, filterFields || []));
                            }}
                        >
                            close
                        </FilterRemove>
                    </FilterBadge>
                );
            })}
        </>
    );
}

const FilterBadge = styled.span`
    border-radius: 2vh;
    border: 1px solid #008ac9;
    color: rgba(0, 0, 0, 0.65);
    font-size: 14px;
    height: 24px;
    padding: 3px 8px;
    margin-left: 10px;
    display: inline-flex;
    align-items: center;
    line-height: 1px;
    min-width: max-content;
`;

const FilterRemove = styled.span`
    font-size: 12px;
    color: #008ac9;
    margin-left: 5px;
    cursor: pointer;
`;

export default FiltersStripe;
