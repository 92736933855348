import { GridColumns, GridValueFormatterParams } from '@material-ui/data-grid';
import React from 'react';
import { tariff } from '../../../../../models/entities/tariff';
import { CalcType, fixCalcData, flatCalcData, percentCalcData, breakCalcData } from '../../../../../models/entities/tariff/charge';
import MuiDataGrid from '../../../../shared/MuiDataGrid/MuiDataGrid';
import { GridCellParams } from '@material-ui/data-grid';
import styled from 'styled-components';
import ObjectDetailsPopover from '../../../../shared/ObjectDetails/ObjectDetailsPopover';
import { currencyFormatter } from '../../../../shared/inputs/form/CurrencyInput/CurrencyInput';

type Props = {
    localization: any;
    tariff: tariff;
};
const TariffChargesGrid = ({ tariff, localization }: Props) => {
    const tariffChargesGridColumns: GridColumns = [
        {
            field: 'chargeCode',
            headerName: localization.fields.chargeCode.title,
            flex: 1
        },
        {
            hide: false,
            field: 'chargeType',
            headerName: localization.fields.chargeType.title,
            flex: 1
        },
        {
            field: 'unitType',
            headerName: localization.fields.unitType.title,
            flex: 1
        },
        {
            hide: !tariff.charges?.some((c) => !!c.description),
            field: 'description',
            headerName: localization.fields.description.title,
            flex: 1,
            valueFormatter: (params: GridValueFormatterParams) => params.value || '-'
        },
        {
            hide: !tariff.charges?.some((c) => !!c.minimum),
            field: 'minimum',
            headerName: localization.fields.minimum.title,
            flex: 1,
            valueFormatter: (params: GridValueFormatterParams) => (params.value ? currencyFormatter(params.row.currency)(params.value) : '-')
        },
        {
            field: 'calcType',
            headerName: localization.fields.calcType.title,
            flex: 1
        },
        {
            field: 'chargeCalcData',
            headerName: localization.fields.chargeCalcData.title,
            flex: 1,
            renderCell: (params: GridCellParams) => {
                const calcType: CalcType = params.row.calcType;
                const value = params.value;
                const currency = params.row.currency;

                switch (calcType) {
                    case 'FIX': {
                        const { price } = value as fixCalcData;
                        return <GridCell>{currencyFormatter(currency)(price)}</GridCell>;
                    }
                    case 'FLAT': {
                        const { price } = value as flatCalcData;
                        return <GridCell>{currencyFormatter(currency)(price)}</GridCell>;
                    }
                    case 'PERCENT': {
                        const { chargeCode, percent } = value as percentCalcData;
                        return (
                            <GridCell>
                                {percent}% - {chargeCode}
                            </GridCell>
                        );
                    }

                    case 'BREAK': {
                        const breakValue = value as breakCalcData[];
                        const obj = breakValue.reduce((acc: object, { value, price }: breakCalcData) => {
                            return { ...acc, [value]: currencyFormatter(currency)(price) };
                        }, {});
                        return <ObjectDetailsPopover anchor={<GridCell>click</GridCell>} object={obj} />;
                    }
                }
            }
        },
        {
            hide: true,
            field: 'createdAt',
            headerName: localization.fields.createdAt.title,
            flex: 1
        }
    ];
    return (
        <MuiDataGrid
            sortModel={[{ field: 'createdAt', sort: 'desc' }]}
            columns={tariffChargesGridColumns}
            rows={tariff.charges || []}
            autoHeight
            disableColumnFilter
            disableColumnMenu
            disableColumnReorder
        />
    );
};

const GridCell = styled.div`
    width: 100%;
    display: block;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
`;
export default TariffChargesGrid;
