import React from 'react';
import styled from 'styled-components';
import { companiesRelationsResponseType } from '../../../../../models/entities/company/companiesRelation';
import { company } from '../../../../../models/entities/company/company';
import { companyAdminCreateUserPayload, companyAdminUpdateUserPayload, userInfo } from '../../../../../models/entities/user';
import AddUserForm from './AddUserForm';
import EditUserForm from './EditUserForm';

type Props = {
    user?: userInfo;
    createUser: (payload: companyAdminCreateUserPayload) => Promise<void>;
    updateUserDetails: (details: companyAdminUpdateUserPayload, userId: string) => Promise<void>;
    deleteUser: (userId: string) => Promise<void>;
    restoreUser: (userId: string) => Promise<void>;
    resetUserPassword: (userId: string, email: string) => Promise<void>;
    checkEmailExist: (email: string) => Promise<boolean>;
    relations: companiesRelationsResponseType;
    companies: Array<company>;
    localization: any;
};
const AddEditUser = ({
    user,
    createUser,
    localization,
    companies,
    updateUserDetails,
    resetUserPassword,
    deleteUser,
    restoreUser,
    checkEmailExist,
    relations
}: Props) => {
    return (
        <Wrapper>
            {user ? (
                <EditUserForm
                    user={user}
                    companies={companies}
                    updateUserDetails={updateUserDetails}
                    localization={{ ...localization.add_edit_user.edit_user_form, are_you_sure: localization.are_you_sure }}
                    resetUserPassword={resetUserPassword}
                    deleteUser={deleteUser}
                    restoreUser={restoreUser}
                    relations={relations}
                />
            ) : (
                <AddUserForm
                    checkEmailExist={checkEmailExist}
                    createUser={createUser}
                    localization={localization.add_edit_user.add_user_form}
                    companies={companies}
                />
            )}
        </Wrapper>
    );
};

const Wrapper = styled.div`
    width: 100%;
    height: 100%;
    padding: 48px 11px;
`;

export default AddEditUser;
