import React from 'react';
import { Formik } from 'formik';
import { Form } from 'formik-antd';
import Packages from '../../../ShipmentForm/sections/Packages';
import { shipment, shipmentType } from '../../../../../../models/entities/shipment/shipment';
import { connect } from 'react-redux';
import { RootState } from '../../../../../../state/store/store';
import { localizationSelectors } from '../../../../../../state/ducks/localization/selectors';
import CustomModal, { ModalTriggerProps } from '../../../../../shared/Modal/Modal';
import Button from '../../../../../shared/SmallComponents/Button';
import styled from 'styled-components';
import Loader from '../../../../../shared/SmallComponents/Loader';
import {
    calcWeightVolume,
    containersPrepareSubmit,
    packagesPrepareSubmit,
    validateContainers,
    validatePackages
} from '../../../ShipmentForm/sections/Packages/util';

type Props = {
    shipment: shipment;
    Trigger: (props: ModalTriggerProps) => JSX.Element;
    updateShipment: (id: string, shipment: shipment) => Promise<void>;
    deleteShipmentContainer: (shipmentId: string, containerId: string) => Promise<void>;
    deleteShipmentPackage: (shipmentId: string, packageId: string) => Promise<void>;
    isLoading: boolean;
    localizations: any;
    title: string;
    isContainers?: boolean;
};

const PackagesFormModal = ({
    shipment,
    localizations,
    Trigger,
    updateShipment,
    deleteShipmentContainer,
    deleteShipmentPackage,
    isLoading,
    title,
    isContainers
}: Props) => {
    const initialValues = {
        shipment_type: shipment.typeCode,
        sub_type: shipment.subTypeCode,
        isTotals: shipment.isTotals,
        containers: shipment.containers,
        packages: shipment.packages,
        quantity: shipment.quantity,
        volume: shipment.volume,
        volumeUnit: shipment.volumeUnit,
        weight: shipment.weight,
        weightUnit: shipment.weightUnit,
        containerNumber: shipment.containerNumber
    };

    const validate = (values: any) => {
        const errors: any = {};
        if (values.packages) validatePackages(values.packages, errors, localizations);
        if (values.containers) validateContainers(values.containers, errors, localizations);
        return errors;
    };

    const onSubmit = (setVisible?: React.Dispatch<React.SetStateAction<boolean>>) => async (values: any) => {
        const isContainersShipment = values.sub_type === 'FCL' || values.sub_type === 'CON';
        const packages = packagesPrepareSubmit(values, shipment?.id);
        const containers = containersPrepareSubmit(values, shipment?.id);

        const isTotals = values.isTotals || (!packages?.length && !containers?.length);

        const { weight, weightUnit, volume, volumeUnit } = calcWeightVolume(
            values.shipment_type as shipmentType,
            packages,
            undefined,
            values.isTotals,
            {
                weight: values.weight,
                weightUnit: values.weightUnit,
                volume: values.volume,
                volumeUnit: values.volumeUnit
            }
        );

        const quantity = values.quantity;

        const payload = {
            weight: !!weight ? weight : undefined,
            weightUnit: !!weightUnit ? weightUnit : undefined,
            volume: !!volume ? volume : undefined,
            volumeUnit: !!volumeUnit ? volumeUnit : undefined,
            isTotals: isTotals,
            quantity: quantity,
            packages: packages,
            containers: containers,
            containerNumber: values.containerNumber
        } as shipment;

        await updateShipment(shipment.id, payload);
        if (setVisible) setVisible(false);
    };

    return (
        <CustomModal
            title={{ text: title }}
            Trigger={Trigger}
            Content={(setVisible?: React.Dispatch<React.SetStateAction<boolean>>, setConfirm?: React.Dispatch<React.SetStateAction<boolean>>) => (
                <Formik initialValues={initialValues} onSubmit={onSubmit(setVisible)} validate={validate}>
                    {(formik) => {
                        const { isValid, values, setFieldValue, handleSubmit } = formik;
                        return (
                            <Form>
                                <Packages
                                    shipmentId={shipment.id}
                                    deleteShipmentContainer={deleteShipmentContainer}
                                    deleteShipmentPackage={deleteShipmentPackage}
                                    values={values}
                                    setFieldValue={setFieldValue}
                                    localizations={localizations}
                                    isContainers={isContainers}
                                />
                                <Buttons>
                                    <Button
                                        width="auto"
                                        height="30px"
                                        type="button"
                                        buttonType="clean"
                                        textColor="#394372"
                                        onClick={() => {
                                            if (setVisible) setVisible(false);
                                        }}
                                    >
                                        <span>{'Cancel'}</span>
                                    </Button>
                                    <Button
                                        buttonType="filled"
                                        width="100px"
                                        height="30px"
                                        type="button"
                                        onClick={(e) => {
                                            handleSubmit();
                                        }}
                                    >
                                        {isLoading ? <Loader width="20px" marginTop="0px" showText={false} /> : <span>{'Save'}</span>}
                                    </Button>
                                </Buttons>
                            </Form>
                        );
                    }}
                </Formik>
            )}
            width="700px"
        />
    );
};

const Buttons = styled.div`
    width: 100%;
    padding-top: 25px;
    display: flex;
    justify-content: flex-end;
    height: 55px;

    button {
        margin-right: 20px;
    }
`;

const mapStateToProps = (state: RootState) => ({
    localizations: localizationSelectors.allShipmentsPage(state),
    isLoading: !!state.loading.effects.shipments.updateShipment
});

const mapDispatchToProps = (dispatch: any) => ({
    updateShipment: (id: string, shipment: shipment) => dispatch.shipments.updateShipment({ id, shipment }),
    deleteShipmentContainer: (shipmentId: string, containerId: string) => dispatch.shipments.deleteShipmentContainer({ shipmentId, containerId }),
    deleteShipmentPackage: (shipmentId: string, packageId: string) => dispatch.shipments.deleteShipmentPackage({ shipmentId, packageId })
});

export default connect(mapStateToProps, mapDispatchToProps)(PackagesFormModal);
