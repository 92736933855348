import React from 'react';
import Menu from '../../Menu/Menu';
import MenuItem from '../../Menu/MenuItem';
import edit from '../../../../static/icons/edit.svg';
import trash from '../../../../static/icons/trash.svg';
import { RootState } from '../../../../state/store/store';
import { connect } from 'react-redux';
import CellTrigger from '../../Menu/MenuTriggers/CellTrigger';
import { allBusinessPartnersPageSelectors } from '../../../../state/ducks/allBusinessPartnersPage/selectors';
import { businessPartner } from '../../../../models/entities/businessPartner/businessPartner';
import { userSelectors } from '../../../../state/ducks/user/selectors';
import { allowedCompany } from '../../../../models/entities/user';
type onRowMoreOptionClickType = () => void;
type deleteBusinessPartner = (businessPartnerId: string) => void;
type Props = {
    entityId: string;
    localization: any;
    onRowMoreOptionsClick?: onRowMoreOptionClickType;
    deleteBusinessPartner: deleteBusinessPartner;
    companies: Array<businessPartner>;
    allowedCompanies: allowedCompany[];
};
const BusinessPartnerMenu = ({ entityId, companies, allowedCompanies, localization, onRowMoreOptionsClick, deleteBusinessPartner }: Props) => {
    const entity = companies.find((item) => item.id === entityId);
    if (entity && !allowedCompanies.map((item) => item.companyId).includes(entity.companyId)) return null;
    const menuItems = [
        <MenuItem onClick={() => onRowMoreOptionsClick && onRowMoreOptionsClick()} icon={edit} text={localization.edit} />,
        <MenuItem onClick={() => deleteBusinessPartner(entityId)} icon={trash} text={localization.delete} />
    ];

    return <Menu options={menuItems} MenuTrigger={CellTrigger} strStyle="display: flex;align-items: center;width: 100%;height: 100%;" />;
};
const mapStateToProps = (state: RootState) => ({
    companies: allBusinessPartnersPageSelectors.gridData(state),
    allowedCompanies: userSelectors.allowedCompanies(state)
});

const mapDispatchToProps = (dispatch: any) => ({
    deleteBusinessPartner: (businessPartnerId: string) => dispatch.allBusinessPartnersPage.deleteBusinessPartner(businessPartnerId)
});

export default connect(mapStateToProps, mapDispatchToProps)(BusinessPartnerMenu);
