import React, { PropsWithChildren } from 'react';
import { styled } from '../Theme/theme';
import MaterialPopover from '../Popover/MaterialPopover';
import { chat } from '../../../models/entities/chat/chat';
import HexagonAvatar from '../SmallComponents/HexagonAvatar';
import { getUserStatusTheme } from '../Theme/util';
import peopleIcon from '../../../static/icons/people.svg';
type Props = {
    chat: chat;
    popoverLeft?: boolean;
};

const GroupBadge = ({ chat, popoverLeft }: Props) => {
    return (
        <MaterialPopover
            anchor={
                <HexagonAvatar
                    width={40}
                    img={<GroupNameContainer textLength={chat.name?.slice(0, 5).length || 1}>{chat.name?.slice(0, 5)}</GroupNameContainer>}
                    borderColor={getUserStatusTheme('OOTO').color}
                />
            }
            popoverLeft={popoverLeft}
        >
            <ContentContainer>
                <div className="name">{chat.name}</div>
                <div className="participants">
                    <img src={peopleIcon} alt="People" />
                    <span className="participants-count">{chat.participants.length}</span>
                </div>
            </ContentContainer>
        </MaterialPopover>
    );
};

const GroupNameContainer = styled.div<{ textLength: number }>`
    font-size: ${(props) => (props.textLength === 5 ? '11px' : '14px')};
    color: black;
    height: 100%;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
}
`;
const ContentContainer = styled.div`
    display: flex;
    align-items: center;

    .name {
        margin: 0 10px;
        font-size: 16px;
    }

    .participants-count {
        width: 12px;
        height: 12px;
        line-height: 12px;
        font-size: 8px;
        border-radius: 50%;
        color: white;
        text-align: center;
        background-color: #008ac9;
        display: inline-block;
    }
`;
const LoaderContainer = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 100%;
`;

export default GroupBadge;
