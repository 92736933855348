import BaseController from '../..';
import { orderFollower } from '../../../../models/entities/order/orderFollower/orderFollower';
import {
    createOrderFollowerPayload,
    deleteOrderFollowerPayload,
    bulkFollowPayload,
    bulkUnfollowPayload
} from '../../../../models/entities/order/orderFollower/payloads';
import IOrderFollower from './IOrderFollowers';

export default class OrderFollowers extends BaseController implements IOrderFollower {
    async createOrderFollower(payload: createOrderFollowerPayload): Promise<orderFollower> {
        const response = await this.client.post(`/order/followers`, JSON.stringify(payload));
        return response;
    }
    async bulkFollow(payload: bulkFollowPayload): Promise<orderFollower[]> {
        const response = await this.client.post(`/order/followers/bulkFollow`, JSON.stringify(payload));
        return response;
    }
    async bulkUnfollow(payload: bulkUnfollowPayload) {
        const response = await this.client.delete(`/order/followers/bulkUnfollow`, JSON.stringify(payload));
        return response;
    }
    async deleteOrderFollower(payload: deleteOrderFollowerPayload) {
        const response = await this.client.delete(`/order/followers`, JSON.stringify(payload));
        return response;
    }
}
