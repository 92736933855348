import { Formik } from 'formik';
import { Form } from 'formik-antd';
import React, { useMemo, useState } from 'react';
import styled from 'styled-components';
import { allowedCompany, companyAdminCreateUserPayload } from '../../../../../models/entities/user';
import FormFieldLabel from '../../../../shared/Form/FormFieldLabel';
import { FormDatePicker, FormTextInput } from '../../../../shared/inputs/form';
import Button from '../../../../shared/SmallComponents/Button';
import { createUserSchema } from '../../../../../validationSchemas/admin/createUserForm';
import Loader from '../../../../shared/SmallComponents/Loader';
import { company } from '../../../../../models/entities/company/company';
import { eShipmentTypes } from '../../../../../models/entities/shipment/shipmentTypes';
import ClaimsFormField from './components/claimsFormField';
import { Modal } from 'antd';
import AccordionCheckBoxsesField from './components/accordionCheckBoxsesField';
import { emailPattern } from '../../../../../utils/patterns';

type Props = {
    createUser: (payload: companyAdminCreateUserPayload) => Promise<void>;
    checkEmailExist: (email: string) => Promise<boolean>;
    companies: Array<company>;
    localization: any;
};
const AddUserForm = ({ createUser, companies, localization, checkEmailExist }: Props) => {
    const [submitLoading, setLoading] = useState(false);
    const [isCheckingEmailExist, setIsCheckingEmailExist] = useState(false);
    const [canCheckEmailExist, setCanCheckEmailExist] = useState(false);
    const [isCurrentEmailExist, setIsCurrentEmailExist] = useState(false);

    const fieldsLocalization = localization.fields;
    const initialValues: companyAdminCreateUserPayload = {
        firstName: '',
        lastName: '',
        email: '',
        username: '',
        birthDate: null,
        role: '',
        phoneNumber: '',
        allowedCompanies: [],
        allowedShipmentTypes: [],
        claims: {}
    };

    const submitUser = async (values: companyAdminCreateUserPayload, { resetForm }: any) => {
        setLoading(true);
        await createUser(values);
        resetForm();
        setLoading(false);
        Modal.success({
            zIndex: 1001,
            title: localization.success,
            content: `${localization.email_sent} ${values.email}`
        });
    };

    const validate = async (values: any) => {
        const errors: any = {};

        if (!values.email) errors.email = localization.errors.required;
        if (values.email && !values.email.match(emailPattern)) errors.email = localization.errors.email_invalid;
        if (values.email?.toString()?.length >= 100) errors.email = localization.errors.max_100;

        if (values.email && !errors.email) {
            if (canCheckEmailExist) {
                setIsCheckingEmailExist(true);
                const exist = await checkEmailExist(values.email);
                if (exist) errors.email = localization.errors.email_exist;
                setIsCheckingEmailExist(false);
                setIsCurrentEmailExist(exist);
                setCanCheckEmailExist(false);
            } else {
                if (isCurrentEmailExist) errors.email = localization.errors.email_exist;
            }
        }
        return errors;
    };

    return (
        <Wrapper>
            <Title>{localization.title}</Title>
            <FormWrapper>
                <Formik initialValues={initialValues} validate={validate} validationSchema={createUserSchema(localization)} onSubmit={submitUser}>
                    {(formik) => {
                        const { isValid, values, setFieldValue } = formik;
                        return (
                            <Form>
                                <FormFieldsWrapper>
                                    <FieldsSectionWrapper>
                                        <FieldContainer>
                                            <FormFieldLabel text={fieldsLocalization.first_name} isMandatory={true} />
                                            <FormTextInput name="firstName" placeHolder={fieldsLocalization.first_name} />
                                        </FieldContainer>
                                        <FieldContainer>
                                            <FormFieldLabel text={fieldsLocalization.last_name} isMandatory={true} />
                                            <FormTextInput name="lastName" placeHolder={fieldsLocalization.last_name} />
                                        </FieldContainer>
                                        <FieldContainer>
                                            <FormFieldLabel text={fieldsLocalization.email} isMandatory={true} />
                                            <FormTextInput
                                                suffix={
                                                    <>
                                                        {isCheckingEmailExist && (
                                                            <Loader wrapperWidth="auto" showText={false} marginTop="0" width="15px" />
                                                        )}
                                                    </>
                                                }
                                                name="email"
                                                placeHolder={fieldsLocalization.email}
                                                onChange={() => {
                                                    setCanCheckEmailExist(true);
                                                }}
                                            />
                                        </FieldContainer>
                                    </FieldsSectionWrapper>
                                    <FieldsSectionWrapper>
                                        <FieldContainer>
                                            <FormFieldLabel text={fieldsLocalization.username} />
                                            <FormTextInput name="username" placeHolder={fieldsLocalization.username} />
                                        </FieldContainer>
                                        <FieldContainer>
                                            <FormFieldLabel text={fieldsLocalization.role} />
                                            <FormTextInput name="role" placeHolder={fieldsLocalization.role} />
                                        </FieldContainer>
                                        <FieldContainer>
                                            <FormFieldLabel text={fieldsLocalization.phone_num} />
                                            <FormTextInput name="phoneNumber" placeHolder={fieldsLocalization.phone_num} />
                                        </FieldContainer>
                                        <FieldContainer>
                                            <FormFieldLabel text={fieldsLocalization.birth_date} />
                                            <FormDatePicker name="birthDate" placeHolder={fieldsLocalization.birth_date} />
                                        </FieldContainer>
                                    </FieldsSectionWrapper>
                                </FormFieldsWrapper>
                                <AccordionWrapper>
                                    <AccordionCheckBoxsesField
                                        fieldName="allowedCompanies"
                                        title={localization.allowed_companies}
                                        options={companies.map((company) => ({
                                            label: company.name,
                                            value: company.id
                                        }))}
                                    />
                                    <AccordionCheckBoxsesField
                                        fieldName="allowedShipmentTypes"
                                        title={localization.allowed_shipment_types}
                                        options={Object.entries(eShipmentTypes).map(([typeCode, value]) => ({
                                            label: value,
                                            value: typeCode
                                        }))}
                                    />
                                </AccordionWrapper>
                                <AccordionWrapper>
                                    <PermissionsTitle>{localization.permissions}:</PermissionsTitle>
                                    <ClaimsFormField fieldName="claims" localization={localization.claims} />
                                </AccordionWrapper>
                                <Button margin="30px 0 0 0" width="150px" buttonType="filled" type="submit" disabled={!isValid}>
                                    {submitLoading ? <Loader width="20px" marginTop="0px" showText={false} /> : localization.submit_btn}
                                </Button>
                            </Form>
                        );
                    }}
                </Formik>
            </FormWrapper>
        </Wrapper>
    );
};

const Wrapper = styled.div`
    display: flex;
    flex-direction: column;
    padding: 10px;
`;

const Title = styled.div`
    font-size: 18px;
`;

const FormWrapper = styled.div`
    width: 100%;
`;

const PermissionsTitle = styled.div`
    font-size: 18px;
    font-weight: 500;
    padding: 10px 5px;
`;

const FormFieldsWrapper = styled.div`
    width: 100%;
    min-width: 200px;
    display: flex;
`;

const FieldsSectionWrapper = styled.div`
    flex: 1 1 0;
    padding-right: 10px;
`;
const AccordionWrapper = styled.div`
    margin-top: 20px;
`;

const FieldContainer = styled.div`
    margin-top: 12px;
`;

export default AddUserForm;
