import { createSelector } from 'reselect';
import { chat, chatType, message } from '../../../models/entities/chat/chat';
import { RootState } from '../../store/store';
import moment from 'moment';
import { chatRequests as chatRequestsModel } from './chats';
import { userDetails, usersObject } from '../users/users';
import { groupBy, Dictionary } from 'lodash';

const chats = (state: RootState) => state.chats.chats;
const chatRequests = (state: RootState) => state.chats.network.chat_requests;
const users = (state: RootState) => state.users.users;
const currentUserId = (state: RootState) => state.user.userInfo.id;

const getChatByParticipantsIds = createSelector(chats, (chats: chat[]) => (participantsIds: string[], type: chatType): chat | undefined => {
    return chats?.find(
        (chat: chat) =>
            chat.type === type &&
            chat.participants?.length === participantsIds.length &&
            chat.participants.every((p) => participantsIds.includes(p.userId))
    );
});

const getContactsWithoutChat = createSelector(
    users,
    currentUserId,
    getChatByParticipantsIds,
    (users: usersObject, userId: string, getChatByParticipantsIds: (participantsIds: string[], type: chatType) => chat | undefined) => {
        const conatcts = Object.values(users).filter((u) => u.id !== userId && u.isActive && !getChatByParticipantsIds([u.id, userId], 'user'));
        return conatcts;
    }
);
const notReadMessagesCount = createSelector(chats, currentUserId, (chats: chat[], userId: string) => (chatId: string) => {
    return chats?.find((c) => c.id === chatId)?.messages.filter((m) => !m.isRead && m.userId !== userId && !m.readBy?.includes(userId)).length;
});

const typingUsers = createSelector(chats, currentUserId, (chats: chat[], currentUserId: string) => (chatId: string) => {
    return chats?.find((c) => c.id === chatId)?.typingUsers?.filter((id) => id !== currentUserId);
});

const activeChats = createSelector(chats, (chats: chat[]) => {
    return chats.sort((a: chat, b: chat) => {
        const aMessages = a.messages?.sort((aa, ab) => moment(aa.sendDate).valueOf() - moment(ab.sendDate).valueOf());
        const bMessages = b.messages?.sort((ba, bb) => moment(ba.sendDate).valueOf() - moment(bb.sendDate).valueOf());
        const aCreatedAt = aMessages[aMessages.length - 1]?.sendDate;
        const bCreatedAt = bMessages[bMessages.length - 1]?.sendDate;

        return aCreatedAt == null ? 1 : bCreatedAt == null ? -1 : moment(bCreatedAt).valueOf() - moment(aCreatedAt).valueOf();
    });
});

const getChatById = createSelector(chats, (chats: chat[]) => (id: string) => {
    return chats.find((c) => c.id === id);
});
const getMessageById = createSelector(chats, (chats: chat[]) => (chatId: string, messageId: string) => {
    return chats.find((c) => c.id === chatId)?.messages.find((m) => m.id === messageId);
});

const getMessagesByChatId = createSelector(chats, (chats: chat[]) => (chatId: string) => {
    const messages = groupBy(
        chats.find((c) => c.id === chatId)?.messages?.sort((a, b) => moment(a.sendDate).valueOf() - moment(b.sendDate).valueOf()),
        (m) => {
            const date = moment(m.sendDate);
            const diffFromToday = date.startOf('day').diff(moment().startOf('day'), 'days');
            if (diffFromToday === 0) return 'Today';
            if (diffFromToday === -1) return 'Yesterday';

            return date.format('MMMM Do YYYY');
        }
    );
    return messages;
});
const sentRequests = createSelector(chatRequests, (chatRequests?: chatRequestsModel) => {
    return chatRequests?.sent;
});
const recievedRequests = createSelector(chatRequests, (chatRequests?: chatRequestsModel) => {
    return chatRequests?.recieved;
});

const chatUsersNotIsState = createSelector(chats, users, (chats: chat[], users: usersObject) => {
    return chats
        ?.map((chat) => chat.participants.map((p) => p.userId))
        ?.flat()
        .filter((id) => !users[id]);
});
export { default as chatsSelectors } from './selectors';

export default {
    getChatByParticipantsIds,
    notReadMessagesCount,
    getContactsWithoutChat,
    chatUsersNotIsState,
    activeChats,
    typingUsers,
    sentRequests,
    getChatById,
    recievedRequests,
    getMessagesByChatId,
    getMessageById
};
