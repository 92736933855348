import { RootState } from '../../../state/store/store';
import { createSelector } from 'reselect';

const applicationState = (state: RootState) => state.application;

const isMobile = (state: RootState) => state.application.isMobile;

export { default as applicationSelectors } from './selectors';

const selectors = { isMobile };

export default selectors;
