import React, { useEffect } from 'react';
import { Switch, Route, Redirect, useLocation, withRouter } from 'react-router-dom';
import DashboardPageConnected from './components/pages/Dashboard/DashboardPageConnected';
import Calendar from './components/pages/Calendar';
import LoginPageConnected from './components/pages/Login/LoginPageConnected';
import GetStartedPageConnected from './components/pages/GetStarted/GetStartedPageConnected';
import ShipmentsPageConnected from './components/pages/shipments/grid/ShipmentsPageConnected';
import ShipmentsBoardPageConnected from './components/pages/shipments/board/ShipmentsBoardPageConnected';
import SingleShipmentPageConnected from './components/pages/singleShipment/SingleShipmentPageConnected';
import SingleShipmentFormConnected from './components/pages/singleShipment/ShipmentForm/ShipmentFormConnected';
import { PagesRoutes } from './routing/PagesRoutes';
import AuthRoute from './routing/AuthRoute';
import 'antd/dist/antd.css';
import NotificationsPageConnected from './components/pages/Notifications/NotificationsPageConnected';
import EditProfilePageConnected from './components/pages/User/EditProfilePageConnected';
import BusinessPartnersPageConnected from './components/pages/businessPartners/BusinessPartnersPageConnected';
import SingleBusinessPartnerPageConnected from './components/pages/businessPartners/singleBusinessPartner/SingleBusinessPartnerPageConnected';
import PurchaseOrdersPageConnected from './components/pages/purchaseOrders/PurchaseOrdersPageConnected';
import SinglePurchaseOrderPageConnected from './components/pages/singlePurchaseOrder/SinglePurchaseOrderPageConnected';
import SinglePurchaseOrderFormConnected from './components/pages/singlePurchaseOrder/PurchaseOrderForm/PurchaseOrderFormConnected';
import ExceptionPage from './components/pages/ExceptionPage/ExceptionPage';
import { connect } from 'react-redux';
import { RootState } from './state/store/store';
import { localizationSelectors } from './state/ducks/localization/selectors';
import BidFormConnected from './components/pages/singleBid/BidForm/BidFormConnected';
import QuotationReplyPageConnected from './components/pages/public/QuotationReply/QuotationReplyPageConnected';
import OrderConfirmationPageConnected from './components/pages/public/OrderConfirmation/OrderConfirmationPageConnected';
import PoSupplierUpdatesConnected from './components/pages/public/PoSupplierUpdates/PoSupplierUpdatesConnected';
import BidsPageConnected from './components/pages/bids/BidsPageConnected';
import SingleBidPageConnected from './components/pages/bids/singleBid/SingleBidPageConnected';
import ChatsPageConnected from './components/pages/Chat/ChatsPageConnected';
import { userSelectors } from './state/ducks/user/selectors';
import { featureFlagsModel } from './models/entities/businessPartner/settings';
import ContactsPageConnected from './components/pages/Chat/ContactsPageConnected';
import ContainersPageConnected from './components/pages/Containers/ContainersPageConnected';
import UsersMgmtPageConnected from './components/pages/Admin/Users/UsersMgmtPageConnected';
import EditUserPageConnected from './components/pages/Admin/Users/AddEditUser/AddEditUserPaneConnected';
import CompaniesMgmtPageConnected from './components/pages/Admin/Companies/CompaniesMgmtPageConnected';
import PublicRoute from './routing/PublicRoute';
import ProductsPageConnected from './components/pages/products/ProductsPageConnected';
import PurchaseDemandsPageConnected from './components/pages/purchaseDemands/PurchaseDemandsPageConnected';
import SinglePurchaseDemandPageConnected from './components/pages/singlePurchaseDemand/SinglePurchaseDemandPageConnected';
import SingleCompanyPageConnected from './components/pages/Admin/Companies/SingleCompanyPage/SingleCompanyPageConnected';
import CompaniesRelationsPageConnected from './components/pages/Admin/CompaniesRelations/CompaniesRelationsPageConnected';
import DeletedRecordsPageConnected from './components/pages/Admin/DeletedRecords/DeletedRecordsPageConnected';
import UserSettingsPageConnected from './components/pages/User/UserSettings/UserSettingsPageConnected';
import InventoryLogsPageConnected from './components/pages/inventory/InventoryLogsPageConnected';
import OrdersPageConnected from './components/pages/Orders/OrdersPageConnected';
import OrdersBoardPageConnected from './components/pages/Orders/OrdersBoardPageConnected';
import OrderFormConnected from './components/pages/SingleOrder/OrderForm/OrderFormConnected';
import InternalFlowInventoryLogsPageConnected from './components/pages/InternalFlowInventoryLogs/InternalFlowInventoryLogsPageConnected';
import TarrifsPageConnected from './components/pages/Tariffs/TariffsPageConnected';
import TariffFormConnected from './components/pages/SingleTariff/TariffForm/TariffFormConnected';
import SingleTatiffPageConnected from './components/pages/SingleTariff/SingleTatiffPageConnected';
import SingleOrderPageConnected from './components/pages/SingleOrder/SingleOrderPageConnected';

type Props = {
    setCurrentPageTitle: (title: string | null) => void;
    setHasGoBackBtn: (visible: boolean) => void;
    addPath: (path: string) => void;
    localization: any;
    featureFlags?: featureFlagsModel;
};

const Routes = ({ setCurrentPageTitle, setHasGoBackBtn, addPath, localization, featureFlags }: Props) => {
    const location = useLocation();

    useEffect(() => {
        addPath(location.pathname);
        setCurrentPageTitle(mapRouteToTitle(location.pathname));
    }, [location]);

    const mapRouteToTitle = (pathname: string) => {
        const localizationTitle = getLocalizationPathKey(pathname);

        return localizationTitle ? localization.titles[localizationTitle] : null;
    };

    const getLocalizationPathKey = (pathname: string) => {
        const localizationPathTitleKey = Object.keys(PagesRoutes)
            .find((key: string) => (PagesRoutes as any)[key] === pathname)
            ?.toLowerCase();
        let newLocalizationPathTitleKey = '';

        const pathSegments: string[] = pathname.substring(1).split('/');
        if (!localizationPathTitleKey) {
            pathSegments.forEach((segment: string, i: number) => {
                i === 0 ? (newLocalizationPathTitleKey += segment) : (newLocalizationPathTitleKey = 'sub_' + newLocalizationPathTitleKey);
            });
        }

        if (pathSegments.length >= 2) {
            setHasGoBackBtn(true);
        } else {
            setHasGoBackBtn(false);
        }

        return localizationPathTitleKey || newLocalizationPathTitleKey;
    };

    const noFeatureComp = () => <ExceptionPage status={404} title="Doesn't have access to this feature" />;

    return (
        <>
            <Switch>
                <AuthRoute component={DashboardPageConnected} exact path={PagesRoutes.Home} />
                <AuthRoute component={Calendar} exact path={PagesRoutes.Calendar} />
                <AuthRoute component={ShipmentsPageConnected} exact path={PagesRoutes.Shipments} />
                <AuthRoute component={ShipmentsBoardPageConnected} exact path={PagesRoutes.ShipmentsBoard} />
                <AuthRoute component={SingleShipmentPageConnected} path={`${PagesRoutes.Shipments}/:shipmentId`} />
                <AuthRoute
                    component={!featureFlags?.UNDER_FORWARDER ? SingleShipmentFormConnected : noFeatureComp}
                    path={`${PagesRoutes.EditShipment}/:shipmentId`}
                />
                <AuthRoute component={SingleShipmentFormConnected} path={`${PagesRoutes.DuplicateShipment}/:shipmentId`} />
                <AuthRoute component={SingleShipmentFormConnected} path={PagesRoutes.NewShipment} />
                <AuthRoute component={NotificationsPageConnected} path={PagesRoutes.Notifications} />
                <AuthRoute component={EditProfilePageConnected} path={PagesRoutes.EditProfile} />
                <AuthRoute component={UserSettingsPageConnected} path={PagesRoutes.UserSettings} />
                <AuthRoute
                    component={!featureFlags?.UNDER_FORWARDER ? BusinessPartnersPageConnected : noFeatureComp}
                    exact
                    path={PagesRoutes.BusinessPartners}
                />
                <AuthRoute component={SingleBusinessPartnerPageConnected} path={`${PagesRoutes.BusinessPartners}/:companyId`} />
                <AuthRoute component={featureFlags?.PO ? PurchaseOrdersPageConnected : noFeatureComp} exact path={PagesRoutes.PurchaseOrders} />
                <AuthRoute
                    component={featureFlags?.PO ? SinglePurchaseOrderPageConnected : noFeatureComp}
                    path={`${PagesRoutes.PurchaseOrders}/:purchaseOrderId`}
                />
                <AuthRoute
                    component={featureFlags?.PO ? SinglePurchaseOrderFormConnected : noFeatureComp}
                    path={`${PagesRoutes.EditPurchaseOrder}/:purchaseOrderId`}
                />
                <AuthRoute
                    component={featureFlags?.PO ? SinglePurchaseOrderFormConnected : noFeatureComp}
                    path={`${PagesRoutes.DuplicatePurchaseOrder}/:purchaseOrderId`}
                />
                <AuthRoute component={featureFlags?.PO ? SinglePurchaseOrderFormConnected : noFeatureComp} path={PagesRoutes.NewPurchaseOrder} />

                <AuthRoute component={featureFlags?.PD ? PurchaseDemandsPageConnected : noFeatureComp} exact path={PagesRoutes.PurchaseDemands} />
                <AuthRoute
                    component={featureFlags?.PD ? SinglePurchaseDemandPageConnected : noFeatureComp}
                    path={`${PagesRoutes.PurchaseDemands}/:purchaseDemandId`}
                />
                <AuthRoute component={featureFlags?.BIDS ? BidsPageConnected : noFeatureComp} exact path={PagesRoutes.Bids} />
                <AuthRoute component={featureFlags?.BIDS ? SingleBidPageConnected : noFeatureComp} path={`${PagesRoutes.Bids}/:bidId`} />
                <AuthRoute component={featureFlags?.BIDS ? BidFormConnected : noFeatureComp} path={`${PagesRoutes.EditBid}/:bidId`} />
                <AuthRoute component={featureFlags?.BIDS ? BidFormConnected : noFeatureComp} path={PagesRoutes.NewBid} />
                {/* <AuthRoute component={featureFlags?.CHAT ? ContactsPageConnected : noFeatureComp} path={PagesRoutes.Contacts} /> */}
                <AuthRoute component={featureFlags?.CHAT ? ChatsPageConnected : noFeatureComp} path={PagesRoutes.Messages} />
                <AuthRoute component={featureFlags?.CONTAINERS ? ContainersPageConnected : noFeatureComp} path={PagesRoutes.Containers} />

                <AuthRoute component={EditUserPageConnected} path={`${PagesRoutes.UsersMgmt}/:userId`} />
                <AuthRoute component={UsersMgmtPageConnected} path={PagesRoutes.UsersMgmt} />

                <AuthRoute component={SingleCompanyPageConnected} path={`${PagesRoutes.CompaniesMgmt}/:companyId`} />
                <AuthRoute component={CompaniesMgmtPageConnected} path={PagesRoutes.CompaniesMgmt} />

                <AuthRoute component={CompaniesRelationsPageConnected} path={PagesRoutes.CompaniesRelations} />

                <AuthRoute component={DeletedRecordsPageConnected} path={PagesRoutes.DeletedRecords} />

                <AuthRoute
                    component={featureFlags?.CATALOG ? ProductsPageConnected : noFeatureComp}
                    exact
                    path={[PagesRoutes.Products, `${PagesRoutes.Products}/:productId`]}
                />
                <AuthRoute component={featureFlags?.INVENTORY ? InventoryLogsPageConnected : noFeatureComp} exact path={PagesRoutes.Inventory} />
                <AuthRoute
                    component={featureFlags?.INTERNAL_FLOW ? InternalFlowInventoryLogsPageConnected : noFeatureComp}
                    exact
                    path={PagesRoutes.InternalFlowInventoryLogs}
                />

                <AuthRoute component={QuotationReplyPageConnected} path={`${PagesRoutes.Quotation}`} />

                <AuthRoute component={SingleOrderPageConnected} path={`${PagesRoutes.Orders}/:orderId`} exact />
                <AuthRoute component={featureFlags?.ORDERS ? OrdersPageConnected : noFeatureComp} path={`${PagesRoutes.Orders}`} />
                <AuthRoute component={featureFlags?.ORDERS ? OrdersBoardPageConnected : noFeatureComp} path={`${PagesRoutes.OrdersBoard}`} />
                <AuthRoute component={featureFlags?.ORDERS ? OrderFormConnected : noFeatureComp} path={`${PagesRoutes.NewOrder}`} />
                <AuthRoute component={featureFlags?.ORDERS ? OrderFormConnected : noFeatureComp} path={`${PagesRoutes.EditOrder}/:orderId`} />

                <AuthRoute
                    component={featureFlags?.TARIFF ? SingleTatiffPageConnected : noFeatureComp}
                    path={`${PagesRoutes.Tariffs}/:tariffId`}
                    exact
                />
                <AuthRoute component={featureFlags?.TARIFF ? TarrifsPageConnected : noFeatureComp} path={`${PagesRoutes.Tariffs}`} />
                <AuthRoute component={featureFlags?.TARIFF ? TariffFormConnected : noFeatureComp} path={PagesRoutes.NewTariff} />

                <Route path={PagesRoutes.GetStarted}>
                    <GetStartedPageConnected />
                </Route>
                <Route path={PagesRoutes.Login}>
                    <LoginPageConnected />
                </Route>

                <PublicRoute component={QuotationReplyPageConnected} path={`${PagesRoutes.QuotationReply}/:bidToken`} />
                <PublicRoute component={OrderConfirmationPageConnected} path={`${PagesRoutes.OrderConfirmation}/:token`} />
                <PublicRoute component={PoSupplierUpdatesConnected} path={`${PagesRoutes.PoSupplierUpdates}/:poSupplierUpdatesToken`} />

                <AuthRoute component={() => <ExceptionPage status={404} title="Not found" />} path={PagesRoutes.Error} />
                <Redirect to={PagesRoutes.Error} />
            </Switch>
        </>
    );
};

const mapStateToProps = (state: RootState) => ({
    localization: localizationSelectors.routes(state),
    featureFlags: userSelectors.featureFlags(state)
});

const mapDispatchToProps = (dispatch: any) => ({
    setCurrentPageTitle: (title: string | null) => dispatch.header.setCurrentPageTitle(title),
    setHasGoBackBtn: (visible: boolean) => dispatch.header.setHasGoBackBtn(visible),
    addPath: (path: string) => dispatch.pagesHistory.addPath(path)
});

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Routes));
