import critical from '../../../static/icons/warning-red-18dp.svg';
import warning from '../../../static/icons/warning-orange-18dp.svg';
import good from '../../../static/icons/warning-green-18dp.svg';

export type shipmentAlert = {
    id: number;
    shipmentId: string;
    message: string;
    level: number;
    createdDate: Date;
    isDeleted: boolean;
    isDismissed: boolean;
};

export const shipmentAlertsEnum: { [key: number]: string } = {
    1: 'critical',
    2: 'warning',
    3: 'good'
};

export const alertImgs: { [key: number]: any } = {
    1: critical,
    2: warning,
    3: good
};
