import React from 'react';
import SingleValueForm, { SingleValueFormProps } from './SingleValueForm';
import CustomModal, { ModalTriggerProps } from '../Modal/Modal';

type Props<T = any> = SingleValueFormProps<T> & {
    title?: string;
    Trigger?: (props: ModalTriggerProps) => JSX.Element;
    isOpen?: boolean;
    modalWidth?: string;
    onCancelCallback?: () => void;
};
function SingleValueFormModal<T = any>({ title, Trigger, isOpen, onCancelCallback, modalWidth, ...restProps }: Props<T>) {
    return (
        <CustomModal
            isOpen={isOpen}
            title={title ? { text: title } : undefined}
            Trigger={Trigger}
            Content={(setVisible?: React.Dispatch<React.SetStateAction<boolean>>) => {
                return (
                    <SingleValueForm<T>
                        {...restProps}
                        onCancelCallback={onCancelCallback}
                        openedFromModal={setVisible ? { setVisible } : undefined}
                    />
                );
            }}
            onCancelCallback={onCancelCallback}
            maxWidth={modalWidth || '400px'}
            width={modalWidth || '400px'}
        />
    );
}

export default SingleValueFormModal;
