import React, { useEffect, useState } from 'react';
import Loader from '../../shared/SmallComponents/Loader';
import { styled } from '../../shared/Theme/theme';
import { purchaseOrder, settings } from '../../../models/entities/purchaseOrder/purchaseOrder';
import { drawers } from '../../../state/ducks/header/header';
import useWindowSize from '../../../hooks/windowSize';
import { ColumnType } from '../../shared/Grid/types/Column';
import PurchaseOrderItem from '../../shared/SmallComponents/PurchaseOrderItem';

type Props = {
    localization: any;
    setDrawerEmptyState: (drawer: drawers, isEmpty: boolean) => void;
    setDrawerOpen: (open: drawers) => void;
    followingPurchaseOrdersData?: Array<purchaseOrder>;
    isLoadingPurchaseOrders: boolean;
    isEmptyStateDrawer: boolean;
    gridColumns: Array<ColumnType<purchaseOrder>>;
    settings?: settings;
};

const FollowingPurchaseOrders = ({
    localization,
    setDrawerEmptyState,
    isLoadingPurchaseOrders,
    followingPurchaseOrdersData,
    setDrawerOpen,
    gridColumns,
    settings
}: Props) => {
    const [windowWidth] = useWindowSize();

    const renderPurchaseOrderItems = () => {
        return followingPurchaseOrdersData?.map((purchaseOrder: purchaseOrder) => (
            <PurchaseOrderItemContainer key={purchaseOrder.id}>
                <PurchaseOrderItem
                    purchaseOrder={purchaseOrder}
                    gridColumns={gridColumns}
                    onClickRef={() => {
                        if (windowWidth < 1280) {
                            setDrawerOpen(null);
                        }
                    }}
                    hasMenu={true}
                    settings={settings}
                />
            </PurchaseOrderItemContainer>
        ));
    };
    return (
        <Container>
            <HeaderContainer></HeaderContainer>
            {!followingPurchaseOrdersData?.length && <EmptyState>{localization.empty_state}</EmptyState>}
            {isLoadingPurchaseOrders ? <Loader /> : <ContentContainer>{followingPurchaseOrdersData && renderPurchaseOrderItems()}</ContentContainer>}
        </Container>
    );
};

const Container = styled.div`
    height: 100%;
    & :last-child {
        border: none;
    }
`;
const HeaderContainer = styled.div`
    width: 100%;
    padding: 10px 32px;
`;
const ContentContainer = styled.div`
    width: 100%;
    overflow-y: overlay;
    height: calc(100% - 84px);
    padding: 10px 32px 0 32px;
`;
const EmptyState = styled.div`
    color: #008ac9;
    margin: 20px;
    padding: 0 32px;
    font-size: 20px;
`;
const FilterContainer = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;

    & :first-child {
        margin-right: 5px;
    }

    .clear {
        color: #008ac9;
        cursor: pointer;
    }
`;

const Header = styled.div`
    font-size: 16px;
    font-weight: bold;
    color: #008ac9;
    padding: 4px;
`;

const PurchaseOrderItemContainer = styled.div`
    padding: 8px 0;
`;

export default FollowingPurchaseOrders;
