import React from 'react';
import { shipmentFollower } from '../../../../../models/entities/shipment/shipmentFollower';
import { styled } from '../../../../shared/Theme/theme';
import ShipmentFollower from './ShipmentFollower';

type Props = {
    followers?: Array<shipmentFollower>;
};

const ShipmentFollowers = ({ followers}: Props) => {
    if (!followers) return null;
    return (
        <FollowersContainer>
            {followers.map((follower) => (
                <ShipmentFollower key={follower.id} follower={follower} />
            ))}
        </FollowersContainer>
    );
};

const FollowersContainer = styled.div`
    display: flex;
    align-items: center;
    margin-right: 20px;
`;

export default ShipmentFollowers;
