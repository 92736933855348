import React from 'react';
import { FormikProps } from 'formik';
import { genericCreateTariffChargePayload } from '../../../../../../models/entities/tariff/charge';
import FormInputWrapper from '../../../../../shared/Form/FormInputWrapper';
import { FormNumberInput } from '../../../../../shared/inputs/form';
import { numberParser } from '../../../../../shared/inputs/form/NumberUnitInput';
import { currencyFormatter } from '../../../../../shared/inputs/form/CurrencyInput/CurrencyInput';

export const emptyFixCalcData = {
    price: 0
};

type Props = {
    localization: any;
    formikProps: FormikProps<genericCreateTariffChargePayload<'FIX'>>;
};

const Fix = ({ formikProps, localization }: Props) => {
    const { values } = formikProps;
    return (
        <FormInputWrapper fieldName="price" localization={localization.fields} isMandatory>
            <FormNumberInput
                name="chargeCalcData.price"
                formatter={currencyFormatter(values.currency)}
                parser={numberParser}
                style={{ width: '200px', marginRight: '15px' }}
            />
        </FormInputWrapper>
    );
};

export default Fix;
