import BaseController from '../..';
import { orderDiscussion } from '../../../../models/entities/order/orderDiscussion/orderDiscussion';
import {
    createOrderDiscussionPayload,
    updateOrderDiscussionPayload,
    deleteOrderDiscussionPayload
} from '../../../../models/entities/order/orderDiscussion/payloads';
import IOrderDiscussion from './IOrderDiscussions';

export default class OrderDiscussions extends BaseController implements IOrderDiscussion {
    async createOrderDiscussion(payload: createOrderDiscussionPayload): Promise<orderDiscussion> {
        const response = await this.client.post(`/order/discussions`, JSON.stringify(payload));
        return response;
    }
    async updateOrderDiscussion(payload: updateOrderDiscussionPayload) {
        const response = await this.client.put(`/order/discussions`, JSON.stringify(payload));
        return response;
    }
    async deleteOrderDiscussion(payload: deleteOrderDiscussionPayload) {
        const response = await this.client.delete(`/order/discussions`, JSON.stringify(payload));
        return response;
    }
}
