import React from 'react';
import { styled } from '../../shared/Theme/theme';
import logo from '../../../static/common/vertical_logo.svg';
import loginBackground from '../../../static/images/login_background.jpg';
import { match, Route, Switch, Redirect } from 'react-router-dom';
import LoginForm from './components/LoginForm';
import ForgotPasswordForm from './components/ForgotPasswordForm';
import ResetPasswordForm from './components/ResetPasswordForm';

type LoginPageProps = {
    showLoader: boolean;
    error: string;
    login: (username: string, password: string, recaptchaToken: string) => void;
    localization: any;
    match: match;
};

const LoginPage = React.memo(({ showLoader, error, login, localization, match }: LoginPageProps) => {
    return (
        <React.Fragment>
            <LoginPageWrapper>
                <LeftSide>
                    <LogoImg src={logo} />
                    <Switch>
                        <Route exact path={match.path}>
                            <LoginForm showLoader={showLoader} error={error} login={login} localization={localization} match={match} />
                        </Route>
                        <Route exact path={`${match.path}/forgotPassword`}>
                            <ForgotPasswordForm localization={localization.forgot_pswd_form} match={match} />
                        </Route>
                        <Route exact path={`${match.path}/forgotPassword/:token`}>
                            <ResetPasswordForm localization={localization.reset_pswd_form} />
                        </Route>
                        <Redirect to="/" />
                    </Switch>
                    <ErrorMsg>{error}</ErrorMsg>
                    <Footer>{localization.rights}</Footer>
                </LeftSide>
                <RightSide />
            </LoginPageWrapper>
        </React.Fragment>
    );
});

const LogoImg = styled.img`
    width: 115px;
    margin: 32px;
`;

const Div = styled.div`
    justify-content: center;
`;

const LoginPageWrapper = styled(Div)`
    min-height: 500px;
    display: flex;
    flex-direction: row;
    position: absolute;
    width: 100%;
    height: 100%;
`;

const LeftSide = styled(Div)`
    flex: 1;
    padding: 1rem;
    display: flex;
    flex-direction: column;
    align-items: center;
`;

const RightSide = styled(Div)`
    flex-direction: column;
    background-image: url(${loginBackground});
    background-size: cover;
    flex: 2;
    padding: 1rem;

    @media screen and (max-width: 764px) {
        display: none;
    }
`;

const ForgetPWText = styled.span`
    margin: 12px;
    color: #008ac9;
    cursor: pointer;
`;
const ErrorMsg = styled.div`
    color: red;
`;

const Footer = styled.div`
    position: absolute;
    bottom: 0;
    margin: 16px 0;
`;

export default LoginPage;
