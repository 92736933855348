import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { RootState } from '../../../../state/store/store';
import TasksStrip from './TasksStrip';
import { task } from '../../../../models/entities/task';
import { tasksSelectors } from '../../../../state/ducks/tasks/selectors';
import { localizationSelectors } from '../../../../state/ducks/localization/selectors';
import { headerSelectors } from '../../../../state/ducks/header/selectors';
import { drawers } from '../../../../state/ducks/header/header';

type TaskStripeParams = {
    highlightedTaskId: string;
};
type Props = {
    tasks: Array<task> | null;
    fetchTasks: () => void;
    updateTask: (task: task) => void;
    deleteTask: (taskId: string) => void;
    setDrawerEmptyState: (drawer: drawers, isEmpty: boolean) => void;
    isFetching: boolean;
    localizations: any;
    params?: TaskStripeParams;
};

const TasksStripConnected = ({ tasks, fetchTasks, updateTask, deleteTask, setDrawerEmptyState, isFetching, localizations, params }: Props) => {
    useEffect(() => {
        fetchTasks();
    }, [fetchTasks]);

    return (
        <TasksStrip
            updateTask={updateTask}
            deleteTask={deleteTask}
            localization={localizations}
            tasks={tasks}
            isFetching={isFetching}
            setDrawerEmptyState={setDrawerEmptyState}
            highlightedTaskId={params?.highlightedTaskId}
        />
    );
};

const mapStateToProps = (state: RootState) => ({
    tasks: tasksSelectors.visibleTasks(state),
    isFetching: tasksSelectors.isFetching(state),
    localizations: localizationSelectors.tasksStrip(state),
    params: headerSelectors.drawerParams(state)
});

const mapDispatchToProps = (dispatch: any) => ({
    fetchTasks: () => dispatch.tasks.fetchTasks(),
    updateTask: (task: task) => dispatch.tasks.updateTask(task),
    deleteTask: (taskId: string) => dispatch.tasks.deleteTask(taskId),
    setDrawerEmptyState: (drawer: drawers, isEmpty: boolean) => dispatch.header.setDrawerEmptyState({ drawer, isEmpty })
});

export default connect(mapStateToProps, mapDispatchToProps)(TasksStripConnected);
