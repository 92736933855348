import React, { PropsWithChildren, ReactNode } from 'react';
import styled from 'styled-components';
import CZTooltip, { QuestionToolTip } from '../../../shared/CZTooltip/CZTooltip';

type Props = {
    title: string | ReactNode;
    dataColor?: string;
    onClick?: () => void;
    toolTip?: string;
};

const SummaryBox = ({ title, dataColor, onClick, toolTip, children }: PropsWithChildren<Props>) => (
    <Container clickable={!!onClick} onClick={onClick}>
        <InnerContainer className="inner">
            <Title>
                {title}
                {!!toolTip && <CZTooltip text={toolTip}>{QuestionToolTip}</CZTooltip>}
            </Title>
            <Data color={dataColor}>{children}</Data>
        </InnerContainer>
    </Container>
);

const InnerContainer = styled.div`
    margin-top: 15px;
    margin-bottom: 15px;
    height: 100px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    border-right: 1px solid #e4e4e4;

    &:first-child {
        padding-left: 0;
    }

    @media screen and (max-width: 1200px) {
        padding-left: 0;
    }
`;

interface ContainerProps {
    clickable?: boolean;
}

const Container = styled.div<ContainerProps>`
    flex: 1;
    cursor: ${(props) => (props.clickable ? 'pointer' : '')};
    &:hover {
        background-color: #f5f5f5;
    }

    @media screen and (max-width: 768px) {
        flex: auto;
        width: 50%;
        &:nth-child(even) {
            .inner {
                border-right: none;
            }
        }
        border-bottom: 1px solid #e4e4e4;
    }

    &:last-child {
        .inner {
            border-right: none;
        }
    }
`;

interface DataProps {
    color?: string;
}

const Data = styled.div<DataProps>`
    margin-top: 15px;
    color: ${(props) => props.color ?? props.theme.colors.primaryBlue};
    font-size: ${(props) => props.theme.fontSizes.summaryBoxData};
    letter-spacing: 0;
    line-height: 28px;

    @media screen and (max-width: 1200px) {
        font-size: 16px;
        margin-top: 5px;
        text-align: center;
    }
`;

const Title = styled.div`
    display: flex;
    align-items: center;
    margin-top: 5px;
    color: ${(props) => props.theme.colors.summaryBoxTitle};
    font-size: ${(props) => props.theme.fontSizes.summaryBoxTitle};
    letter-spacing: 0;
    line-height: 16px;

    @media screen and (max-width: 1200px) {
        font-size: 12px;
        margin-top: 3px;
        margin: 0 auto;
        text-align: center;
    }
`;

export const BoxesContainer = styled.div`
    border-top: 1px solid #e4e4e4;
    border-bottom: 1px solid #e4e4e4;
    display: flex;
    flex-wrap: wrap;

    @media screen and (max-width: 768px) {
        border-bottom: none;
    }
`;

export default React.memo(SummaryBox);
