import { Modal } from 'antd';
import React, { useRef, useState } from 'react';
import styled from 'styled-components';
import { useOutsideClickHandler } from '../../../hooks/handleClickOutside';
import { FadeInAnimation } from '../../animations/Animations';
import theme from '../Theme/theme';

const menuTheme = theme.colors.menu;

type position = 'left-center' | 'right-center' | 'bottom-center' | 'top-center' | 'left-top';
export type TriggerProps = {
    icon?: string;
    onClick: (e: React.MouseEvent<HTMLElement, MouseEvent>) => void;
    isOpen: boolean;
};
type Props = {
    options: JSX.Element[];
    icon?: string;
    position: position;
    menuWidth?: number;
    MenuTrigger: (props: TriggerProps) => JSX.Element;
    strStyle?: string;
    containerStyle?: React.CSSProperties;
    arrowOnTrigger: boolean;
    isModalOpened?: boolean;
};

export function showConfirm(onOkFunc: () => void) {
    Modal.confirm({
        content: <div>Are you sure?</div>,
        onOk() {
            onOkFunc();
        },
        onCancel() {}
    });
}
function Menu(props: Props) {
    const dropdownRef = useRef(null);
    const [isOpen, onToggle] = useState(false);
    const [triggerOptions, setTriggerOptions] = useState({ width: 0, height: 0 });
    useOutsideClickHandler(dropdownRef, () => !props.isModalOpened && onToggle(false));
    const calcPos = calculateByPosition(props.position, triggerOptions, props.arrowOnTrigger);
    return (
        <MenuContainer
            ref={dropdownRef}
            strStyle={props.strStyle}
            style={props.strStyle ? undefined : { position: 'relative', ...(props.containerStyle || {}) }}
        >
            <props.MenuTrigger
                isOpen={isOpen}
                onClick={(e: React.MouseEvent<HTMLElement, MouseEvent>) => {
                    setTriggerOptions({ height: e.currentTarget.clientHeight, width: e.currentTarget.clientWidth });
                    onToggle(!isOpen);
                }}
            />
            {isOpen && <OptionsOverlay onClick={() => onToggle(false)}></OptionsOverlay>}
            {isOpen && (
                <OptionsList menuWidth={props.menuWidth} posStyle={calcPos.menu}>
                    {props.options.map((item, index) => {
                        return (
                            <div
                                key={index}
                                onClick={() => {
                                    if (item.props.requiredConfirm) showConfirm(item.props.onClick);
                                    else if (!item.props.MenuTrigger) !props.isModalOpened && onToggle(!isOpen);
                                }}
                            >
                                {item}
                            </div>
                        );
                    })}
                    <Arrow posStyle={calculateArrowByPosition(props.position, false)} />
                    <Arrow posStyle={calculateArrowByPosition(props.position, true)} />
                </OptionsList>
            )}
        </MenuContainer>
    );
}

interface MenuContainerProps {
    strStyle?: string;
}
export const MenuContainer = styled.div<MenuContainerProps>`
    position: relative;
    ${(props) => props.strStyle}
`;
const OptionsOverlay = styled.div`
    @media screen and (max-width: 768px) {
        position: fixed;
        width: 100%;
        bottom: 40px;
        left: 0;
        right: 0;
        z-index: 1001;
        height: 100%;
        background: ${theme.colors.overlayBG};
    }
`;
type triggerOptionsProps = {
    width: number;
    height: number;
};
interface OptionListDisplayProps {
    posStyle: string;
    menuWidth?: number;
}
const OptionsList = styled.div<OptionListDisplayProps>`
    z-index: 1002;
    position: absolute;
    border-top: 1px solid ${menuTheme.boxBorder};
    border-right: 1px solid ${menuTheme.boxBorder};
    border-left: 1px solid ${menuTheme.boxBorder};
    margin-left: -1px;
    margin-right: -1px;
    box-shadow: 0 3px 6px -4px rgba(0, 0, 0, 0.12), 0 6px 16px 0 rgba(0, 0, 0, 0.08), 0 9px 28px 8px rgba(0, 0, 0, 0.05);
    animation: ${FadeInAnimation} 0.75s;
    background-color: #fff;
    min-width: 120px;
    ${(props) => (props.menuWidth ? `width: ${props.menuWidth}px;` : 'width:100%;')}
    ${(props) => props.posStyle};

    @media screen and (max-width: 768px) {
        position: fixed;
        bottom: 40px;
        left: 1px;
        transform: translate(0, 0);
        top: inherit;
        width: 100%;
        border-top-left-radius: 5px;
        border-top-right-radius: 5px;
        overflow: auto;
    }
`;

interface ArrowProps {
    posStyle: string;
}
const Arrow = styled.div<ArrowProps>`
    position: absolute;
    width: 0;
    height: 0;
    ${(props) => props.posStyle}
    @media screen and (max-width: 768px) {
        display: none;
    }
`;

const calculateByPosition = (position: position, triggerOptions: triggerOptionsProps, arrowOnTrigger: boolean) => {
    const res = { menu: `` };
    switch (position) {
        case 'left-center':
            res.menu += `right: ${triggerOptions.width + 1}px;`;
            res.menu += `top: ${triggerOptions.height / 2 - 17}px;`;
            if (!arrowOnTrigger) res.menu += `margin-right: 15px;`;
            break;
        case 'left-top':
            res.menu += `right: ${triggerOptions.width + 1}px;`;
            res.menu += `transform: translate(0, -100%);`;
            res.menu += `top: 29px;`;
            if (!arrowOnTrigger) res.menu += `margin-right: 15px;`;
            break;
        case 'right-center':
            res.menu += `left: ${triggerOptions.width + 1}px;`;
            res.menu += `top: ${triggerOptions.height / 2 - 17}px;`;
            if (!arrowOnTrigger) res.menu += `margin-left: 15px;`;
            break;
        case 'bottom-center':
            res.menu += `top: ${triggerOptions.height}px;`;
            res.menu += `left: 50%;
            transform: translate(-50%, 0);`;
            if (!arrowOnTrigger) res.menu += `margin-top: 15px;`;
            break;
        case 'top-center':
            res.menu += `bottom: ${triggerOptions.height}px;`;
            res.menu += `
            left: 50%;
            transform: translate(-50%, 0);`;
            if (!arrowOnTrigger) res.menu += `margin-bottom: 15px;`;
            break;
    }
    return res;
};

const calculateArrowByPosition = (position: position, arrowBorder: boolean) => {
    let arrow = ``;
    switch (position) {
        case 'left-center':
            arrow += arrowRight(arrowBorder);
            break;
        case 'left-top':
            arrow += arrowRightBottom(arrowBorder);
            break;
        case 'right-center':
            arrow += arrowLeft(arrowBorder);
            break;
        case 'bottom-center':
            arrow += arrowUp(arrowBorder);
            break;
        case 'top-center':
            arrow += arrowDown(arrowBorder);
            break;
    }
    return arrow;
};
const borderColor = '#a7a7a7';
const arrowSize = 10;
const arrowUp = (arrowBorder: boolean) => `
top: -10px;
left: 50%;
transform: translate(-50%, 0);
border-left: 6px solid transparent;
border-right: 6px solid transparent;
border-bottom: ${arrowBorder ? arrowSize + 1 : arrowSize}px solid ${arrowBorder ? 'white' : borderColor};
`;
const arrowDown = (arrowBorder: boolean) => `
bottom: -10px;
left: 50%;
transform: translate(-50%, 0);
border-left: 6px solid transparent;
border-right: 6px solid transparent;
border-top: ${arrowBorder ? arrowSize + 1 : arrowSize}px solid ${arrowBorder ? 'white' : borderColor};
`;
const arrowRight = (arrowBorder: boolean) => `
right: -10px;
top: 10px;
border-top: 6px solid transparent;
border-left: ${arrowBorder ? arrowSize + 1 : arrowSize}px solid ${arrowBorder ? 'white' : borderColor};
border-bottom: 6px solid transparent;
`;
const arrowRightBottom = (arrowBorder: boolean) => `
right: -10px;
top: calc(100% - 21px);
border-top: 6px solid transparent;
border-left: ${arrowBorder ? arrowSize + 1 : arrowSize}px solid ${arrowBorder ? 'white' : borderColor};
border-bottom: 6px solid transparent;
`;
const arrowLeft = (arrowBorder: boolean) => `
left: -10px;
top: 10px;
border-top: 6px solid transparent;
border-right: ${arrowBorder ? arrowSize + 1 : arrowSize}px solid ${arrowBorder ? 'white' : borderColor};
border-bottom: 6px solid transparent;
`;
Menu.defaultProps = {
    position: 'left-center',
    arrowOnTrigger: true
};
export default Menu;
