import BaseController from '../..';
import { purchaseDemandApprover } from '../../../../models/entities/purchaseDemand/purchaseDemandApprover';
import IPurchaseDemandApprovers from './IPurchaseDemandApprovers';

export default class PurchaseDemandApprovers extends BaseController implements IPurchaseDemandApprovers {
    constructor(baseUrl: string) {
        super(baseUrl);
    }
    async createPurchaseDemandApprover(purchaseDemandId: string, approver: purchaseDemandApprover) {
        const response = await this.client.post(`/purchaseDemand/approvers`, JSON.stringify({ ...approver, purchaseDemandId }));
        return response;
    }
    async updatePurchaseDemandApproverState(approver: purchaseDemandApprover) {
        const response = await this.client.put(`/purchaseDemand/approvers/state`, JSON.stringify({ ...approver }));
        return response;
    }
    async deletePurchaseDemandApprover(purchaseDemandId: string, approverId: string) {
        const response = await this.client.delete(`/purchaseDemand/approvers`, JSON.stringify({ purchaseDemandId, id: approverId }));
        return response;
    }
}
