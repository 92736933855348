import { ActionButtonWrapper, Badge, IconButton } from './Header';
import React from 'react';
import theme, { styled } from '../../Theme/theme';
import { PagesRoutes } from '../../../../routing/PagesRoutes';
const notificationTheme = theme.colors.notifications;

type Props = {
    isOpen: boolean;
    setDrawerOpen: () => void;
    unReadNotificationsCount?: number;
    pathname: string;
};

const NotificationsHeader = ({ setDrawerOpen, isOpen, unReadNotificationsCount, pathname }: Props) => {
    return (
        <>
            <ActionButtonWrapper isOpen={isOpen || pathname === PagesRoutes.Notifications} onClick={setDrawerOpen}>
                {unReadNotificationsCount && unReadNotificationsCount > 0 ? <Badge>{unReadNotificationsCount}</Badge> : ''}
                <BellIcon isOpen={isOpen} className="material-icons icon-btn">
                    notifications_none
                </BellIcon>
            </ActionButtonWrapper>
        </>
    );
};
//
const BellIcon = styled((props) => <IconButton {...props} />)`
    && {
        ${(props) => (props.isOpen ? `border: 1px solid white;color:white;background-color: ${notificationTheme.menu.headerColor}` : '')}
    }
`;
export default NotificationsHeader;
