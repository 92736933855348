import { BoardColumnMap } from './../../../components/shared/Board/types/BoardColumn';
import { min } from 'lodash';
import { createSelector } from 'reselect';
import { orderGridDataFn } from '../../../components/shared/Grid/Grid';
import { order } from '../../../models/entities/order/order';
import { RootState } from '../../store/store';
import filterOrders from './gridFilters/ordersGridFilter';
import { orderSettingsType, orderSettingsByLanguage } from '../../../models/entities/order/orderSettings/orderSettings';
import mockOrderSettings from '../../../sideEffects/orders/settings/mockData';
import { entries } from '../../../utils/typedEntries';

const ordersData = (state: RootState) => state.orders.orders;
const isFetchingOrders = (state: RootState) => !!state.loading.effects.orders.fetchOrders;
const pagination = (state: RootState) => state.orders.pagination;
const gridFilter = (state: RootState) => state.orders.gridFilter;
const filterFields = (state: RootState) => state.orders.filterFields;
const ordersGridSort = (state: RootState) => state.orders.ordersGridSort;

const gridData = createSelector(
    ordersData,
    gridFilter,
    filterFields,
    ordersGridSort,
    (ordersData: Array<order>, gridFilter, filterFields, ordersGridSort) => {
        if (gridFilter && gridFilter !== '' && gridFilter.length > 2) {
            ordersData = ordersData.filter((s) => JSON.stringify(s).toLocaleLowerCase().includes(gridFilter.toLocaleLowerCase()));
        }

        if (filterFields) {
            ordersData = filterOrders(ordersData, filterFields);
        }

        if (ordersGridSort) {
            ordersData = orderGridDataFn(ordersData, ordersGridSort);
        }

        return ordersData;
    }
);

const activeFiltersCount = createSelector(filterFields, (filterFields) => {
    if (!filterFields) return 0;
    return filterFields.length;
});

const activeOrdersCount = createSelector(ordersData, (ordersData: Array<order>) => {
    return ordersData.length;
});

const filteredOrdersCount = createSelector(gridData, (gridData) => {
    return gridData?.length || 0;
});

const currentGridDataPage = createSelector(pagination, gridData, (pagination, gridData) => {
    const { currentPage, rowsPerPage } = pagination;

    return gridData.slice(currentPage * rowsPerPage, currentPage * rowsPerPage + rowsPerPage);
});

const getAlertsByOrderId = createSelector(ordersData, (ordersData: Array<order>) => (id: string) => {
    return ordersData.find((order: order) => order.id === id)?.alerts;
});

const getAlertStatusByOrderId = createSelector(ordersData, (ordersData: Array<order>) => (id: string) => {
    const order = ordersData.find((order: order) => order.id === id);

    if (!order?.alerts?.length) return 3;

    const { alerts } = order;

    return min(alerts.map((alert) => alert.level)) || 3;
});

const companySettings = (state: RootState) => state.user.userInfo.companySettings;
const currentLanguage = (state: RootState) => state.localization.currentLanguage;

const orderSettings = createSelector(companySettings, currentLanguage, (companySettings, currentLanguage) => {
    let orderSettingsByCurrentLanguage = entries<orderSettingsByLanguage>(mockOrderSettings).find(
        ([language, _settings]) => language === currentLanguage.symbol
    )?.[1];

    if (companySettings && orderSettingsByCurrentLanguage) {
        companySettings
            .filter((s) => (s.entityType === 'PURCHASE_DEMAND' || !s.entityType) && s.language === currentLanguage.symbol)
            .forEach((s) => {
                orderSettingsByCurrentLanguage![s.key as keyof orderSettingsType] = s.value;
            });
    }

    return orderSettingsByCurrentLanguage;
});

const ordersBoardData = createSelector(gridData, orderSettings, (orderData: Array<order>, orderSettings) => {
    let boardData: BoardColumnMap<order> = {};

    if (!orderSettings) return {};
    orderSettings?.state
        ?.sort((a, b) => a.displayOrder - b.displayOrder)
        .forEach((state) => {
            const ordersByState = orderData.filter((order: order) => order.state === state.code);
            boardData[state.code] = {
                isOpen: !!ordersByState.length,
                title: state.name,
                // toolTipText: state.toolTipText,
                items: ordersByState.sort((a, b) => (a.alertStatus || 10) - (b.alertStatus || 10))
            };
        });

    return boardData;
});

export { default as ordersSelectors } from './selectors';

const selectors = {
    gridData,
    activeFiltersCount,
    activeOrdersCount,
    filteredOrdersCount,
    currentGridDataPage,
    getAlertsByOrderId,
    getAlertStatusByOrderId,
    orderSettings,
    ordersBoardData,
    isFetchingOrders,
    pagination,
    ordersData,
    filterFields,
    ordersGridSort
};

export default selectors;
