import React from 'react';
import styled from 'styled-components';
import moment from 'moment';
import ReferencesItems from './References/PoReferencesItems';
import { purchaseOrder } from '../../../../models/entities/purchaseOrder/purchaseOrder';
import UserBadge from '../../../shared/UserBadge/UserBadge';
import Button from '../../../shared/SmallComponents/Button';
import { Modal } from 'antd';

type Props = {
    purchaseOrder: purchaseOrder;
    localization: any;
    isSupplierUpdates?: boolean;
    supplierUpdatesConfirmPo: (purchaseOrderId: string) => Promise<void>;
    isPoStateAfterOrEqual: (purchaseOrderId: string, checkState: string) => boolean;
};

const PoOverviewHeader = ({ purchaseOrder, localization, isSupplierUpdates, supplierUpdatesConfirmPo, isPoStateAfterOrEqual }: Props) => {
    return (
        <OverviewHeaderWrapper>
            <ReferencesSectionWrapper>
                <PurchaseOrderNumberWrapper>
                    <PurchaseOrderNumber id="purchaseOrder-number">
                        <span>{localization.purchaseOrder}</span> #{purchaseOrder.CargoZoneNumber}
                    </PurchaseOrderNumber>
                </PurchaseOrderNumberWrapper>
                <DetailWrapper>
                    <DetailHeader>{'Open Date: '}</DetailHeader>
                    {moment(purchaseOrder.createdAt).format('DD/MM/YYYY')}
                </DetailWrapper>
                <ReferencesSection>
                    <SingleRefWrapper>
                        <RefText>{localization.client_ref}</RefText> #
                        <ReferencesItems purchaseOrderId={purchaseOrder.id} type="CLIENT" />
                    </SingleRefWrapper>
                </ReferencesSection>
            </ReferencesSectionWrapper>
            <SectionsSeparator />
            <SectionWrapper>
                <DetailWrapper>
                    <div>{localization.company}:</div>
                    <div style={{ fontWeight: 'bolder', fontSize: '20px' }}>{purchaseOrder.companyName}</div>
                </DetailWrapper>
                {purchaseOrder.companyLogoUrl && <ImgLogo src={purchaseOrder.companyLogoUrl} alt={purchaseOrder.companyName} width={80} />}
            </SectionWrapper>
            <SectionsSeparator />
            <SectionWrapper>
                <RowWrapper>
                    <UserBadge width={40} userId={purchaseOrder.userId} userName={purchaseOrder.userName} />
                </RowWrapper>
            </SectionWrapper>
            {isSupplierUpdates && (
                <>
                    <SectionsSeparator />
                    <Button
                        onClick={async () => {
                            await supplierUpdatesConfirmPo(purchaseOrder.id);

                            Modal.success({
                                zIndex: 1100,
                                content: <div>{localization.confirmed_message}</div>,
                                cancelButtonProps: {
                                    hidden: true
                                }
                            });
                        }}
                        buttonType="filled"
                        margin="0 12px 0 0"
                        type="button"
                        disabled={isPoStateAfterOrEqual(purchaseOrder.id, 'CONFIRM')}
                    >
                        {localization.confirm}
                    </Button>
                </>
            )}
        </OverviewHeaderWrapper>
    );
};

const OverviewHeaderWrapper = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    width: 100%;
    border-bottom: solid 1px #e0e0e0;
    padding: 24px 18px 18px 48px;
`;

const ReferencesSectionWrapper = styled.div`
    flex: 1;
    max-width: fit-content;
`;

const PurchaseOrderNumberWrapper = styled.div`
    display: flex;
    flex-direction: row;
    width: 100%;
`;

const SectionWrapper = styled.div`
    flex: 1;
    max-width: 200px;
`;

const PurchaseOrderNumber = styled.div`
    color: ${(props) => props.theme.colors.pageTitle};
    font-size: ${(props) => props.theme.fontSizes.pageTitle};
    font-weight: bold;
    height: 24px;
    white-space: nowrap;

    & span {
        @media screen and (max-width: 768px) {
            display: none;
        }
    }
`;

const RowWrapper = styled.div`
    display: flex;
    white-space: nowrap;
    flex-direction: row;
    padding: 6px 0;
    color: #394372;
`;

const DetailWrapper = styled.div`
    color: #394372;

    margin: 4px 0;
    &:first-child {
        margin-top: 0;
    }
`;

const DetailHeader = styled.span`
    font-weight: bolder;
`;

const ReferencesSection = styled.div`
    color: ${(props) => props.theme.colors.pageTitle};
    font-size: ${(props) => props.theme.fontSizes.subTitlte};
    margin-top: 6px;
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;

    & ::-webkit-scrollbar-thumb {
        background-color: rgba(0, 0, 0, 0.3) !important;
        border-radius: 5.5px;
    }
`;

const SingleRefWrapper = styled.div`
    display: flex;
    align-items: center;
    margin-right: 12px;
    flex-wrap: wrap;
    padding: 6px 0;
`;

const RefText = styled.span`
    font-weight: bold;
    margin-right: 4px;
`;

const ImgLogo = styled.img`
    max-width: 200px;
`;

const SectionsSeparator = styled.div`
    width: 1px;
    border-right: solid 1px #e0e0e0;
    margin: 0 18px;
`;

export default PoOverviewHeader;
