import React from 'react';
import { useEffect } from 'react';
import { connect } from 'react-redux';
import { businessPartner } from '../../../../models/entities/businessPartner/businessPartner';
import { companiesRelationsResponseType, companiesRelationType } from '../../../../models/entities/company/companiesRelation';
import { companiesRelationRequestsResponseType } from '../../../../models/entities/company/companiesRelationRequest';
import { businessPartnersSelectors } from '../../../../state/ducks/businessPartner/selectors';
import { companiesSelectors } from '../../../../state/ducks/companies/selectors';
import { companiesRelationsSelectors } from '../../../../state/ducks/companiesRelations/selectors';
import { localizationSelectors } from '../../../../state/ducks/localization/selectors';
import { RootState } from '../../../../state/store/store';
import Loader from '../../../shared/SmallComponents/Loader';
import CompaniesRelationsPage from './CompaniesRelationsPage';

type Props = {
    localization: any;
    isLoading: boolean;
    relations: companiesRelationsResponseType;
    relationRequests: companiesRelationRequestsResponseType;
    businessPartners: Array<businessPartner>;
    businessPartnerCargoOwners: Array<businessPartner>;
    relationToken?: string;
    userCompanyId: string;
    isGeneratingToken: boolean;
    fetchCompaniesRelations: () => void;
    fetchCompaniesRelationRequests: () => void;
    approveCompaniesRelationRequest: (
        requestId: string,
        businessPartnerId: string,
        recievedBusinessPartnerId?: string,
        recievedRelationType?: companiesRelationType
    ) => Promise<void>;
    sendCompaniesRelationRequest: (
        relationToken: string,
        relationType: companiesRelationType,
        recieverBusinessPartnerId?: string,
        recieverRelationType?: companiesRelationType
    ) => Promise<void>;
    denyCompaniesRelationRequest: (requestId: string) => Promise<void>;
    deleteCompaniesRelation: (relationId: string) => Promise<void>;
    generateRelationToken: () => Promise<void>;
    fetchCompanyById: (companyId: string) => Promise<void>;
};
const CompaniesRelationsPageConnected = ({
    localization,
    isLoading,
    relations,
    relationRequests,
    businessPartners,
    businessPartnerCargoOwners,
    relationToken,
    isGeneratingToken,
    userCompanyId,
    fetchCompaniesRelations,
    fetchCompaniesRelationRequests,
    approveCompaniesRelationRequest,
    sendCompaniesRelationRequest,
    denyCompaniesRelationRequest,
    deleteCompaniesRelation,
    generateRelationToken,
    fetchCompanyById
}: Props) => {
    useEffect(() => {
        fetchCompaniesRelations();
        fetchCompaniesRelationRequests();
        fetchCompanyById(userCompanyId);
    }, []);

    if (isLoading) return <Loader />;

    return (
        <CompaniesRelationsPage
            localization={localization}
            relations={relations}
            relationRequests={relationRequests}
            businessPartners={businessPartners}
            businessPartnerCargoOwners={businessPartnerCargoOwners}
            relationToken={relationToken}
            isGeneratingToken={isGeneratingToken}
            denyCompaniesRelationRequest={denyCompaniesRelationRequest}
            deleteCompaniesRelation={deleteCompaniesRelation}
            approveCompaniesRelationRequest={approveCompaniesRelationRequest}
            sendCompaniesRelationRequest={sendCompaniesRelationRequest}
            generateRelationToken={generateRelationToken}
        />
    );
};

const mapStateToProps = (state: RootState) => ({
    relationToken: companiesSelectors.getRelationToken(state),
    userCompanyId: companiesSelectors.userCompanyId(state),
    relations: companiesRelationsSelectors.companiesRelations(state),
    businessPartners: businessPartnersSelectors.businessPartnerForwardersSuppliesrs(state),
    businessPartnerCargoOwners: businessPartnersSelectors.businessPartnerCargoOwners(state),
    relationRequests: companiesRelationsSelectors.companiesRelationRequests(state),
    localization: localizationSelectors.companiesRelationsPage(state),
    isGeneratingToken: !!state.loading.effects.companies.generateRelationToken,
    isLoading:
        state.loading.effects.companiesRelations.fetchCompaniesRelations ||
        state.loading.effects.companiesRelations.fetchCompaniesRelationRequests ||
        state.loading.effects.companies.fetchCompanyById
});

const mapDispatchToProps = (dispatch: any) => ({
    fetchCompaniesRelations: () => dispatch.companiesRelations.fetchCompaniesRelations(),
    fetchCompaniesRelationRequests: () => dispatch.companiesRelations.fetchCompaniesRelationRequests(),
    approveCompaniesRelationRequest: (
        requestId: string,
        businessPartnerId: string,
        recievedBusinessPartnerId?: string,
        recievedRelationType?: companiesRelationType
    ) =>
        dispatch.companiesRelations.approveCompaniesRelationRequest({
            requestId,
            businessPartnerId,
            recievedBusinessPartnerId,
            recievedRelationType
        }),
    sendCompaniesRelationRequest: (
        relationToken: string,
        relationType: companiesRelationType,
        recieverBusinessPartnerId?: string,
        recieverRelationType?: companiesRelationType
    ) => dispatch.companiesRelations.sendCompaniesRelationRequest({ relationToken, relationType, recieverBusinessPartnerId, recieverRelationType }),
    denyCompaniesRelationRequest: (requestId: string) => dispatch.companiesRelations.denyCompaniesRelation(requestId),
    deleteCompaniesRelation: (relationId: string) => dispatch.companiesRelations.deleteCompaniesRelation(relationId),
    generateRelationToken: () => dispatch.companies.generateRelationToken(),
    fetchCompanyById: (companyId: string) => dispatch.companies.fetchCompanyById(companyId)
});

export default connect(mapStateToProps, mapDispatchToProps)(CompaniesRelationsPageConnected);
