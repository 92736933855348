import React from 'react';
import styled from 'styled-components';
import { userDropDown, userInfo } from '../../../../models/entities/user';
import { getUserStatusTheme } from '../../../shared/Theme/util';
import HexagonAvatar from '../../../shared/SmallComponents/HexagonAvatar';
import { userDetails } from '../../../../state/ducks/users/users';
import { UserImageAvatar } from '../../../shared/Layout/Header/AvatarHeader';

type Props = {
    user: userDetails;
    userInfo: userInfo;
    // getUserById: (userId: string) => userDetails | undefined;
    // getUserPicture: (userId: string) => string;
    onClick: () => void;
};
const ContactItem = ({ user, userInfo, onClick }: Props) => {
    const { firstName, lastName, id } = user;
    // const userDetails = getUserById(id);
    const userName = user ? user.name : firstName + ' ' + lastName;

    return (
        <ItemContainer onClick={onClick}>
            <HexagonAvatar
                width={40}
                img={UserImageAvatar({
                    firstName,
                    lastName,
                    avatarColor: user?.avatarColor,
                    profileImgUrl: user?.profileImgUrl
                } as userDetails)}
                borderColor={getUserStatusTheme(user?.status || 'OOTO').color}
            />
            <ItemDetails>
                <div className="username">{userName}</div>
                <div className="user-role">{user?.role}</div>
            </ItemDetails>
        </ItemContainer>
    );
};

interface ItemContainerProps {
    isOpenedChat?: boolean;
}

const ItemContainer = styled.div<ItemContainerProps>`
    height: 72px;
    padding: 11px 15px;
    display: flex;
    align-items: center;
    cursor: pointer;
    position: relative;
    border-bottom: 1px solid #e1e1e1;
    &:hover {
        background-color: #f1f1f1;
    }
`;

const ItemDetails = styled.div`
    display: flex;
    flex-direction: column;
    height: 100%;
    flex: 1;
    padding: 0 10px;
    .username {
        color: #252b4a;
        font-size: 16px;
    }
    .user-role {
        color: rgba(0, 0, 0, 0.5);
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        margin-top: 3px;
    }
    justify-content: center;
    overflow: hidden;
`;

export default ContactItem;
