import React, { useEffect } from 'react';
import { RootState } from '../../../state/store/store';
import { connect } from 'react-redux';
import ContainersPage from './ContainersPage';
import { localizationSelectors } from '../../../state/ducks/localization/selectors';
import { containersGridView } from '../../../models/entities/containers/containersGridView';
import { containersSelectors } from '../../../state/ducks/containers/selectors';
import { shipmentsSelectors } from '../../../state/ducks/shipments/selectors';
import { settings } from '../../../models/entities/shipment/shipment';
import { containersStatsType } from '../../../models/entities/containers/containersStats';

type Props = {
    fetchContainersGridView(): () => void;
    updateContainerState: (shipmentId: string, containerId: string, state: string) => Promise<void>;
    filterContainersByTerm: (term: string) => void;
    textFilterValue: string;
    filteredContainers: containersGridView;
    localization?: any;
    showLoader: boolean;
    settings?: settings;
    containersStats: containersStatsType;
};

const ContainersPageConnected = ({
    fetchContainersGridView,
    updateContainerState,
    filterContainersByTerm,
    textFilterValue,
    localization,
    showLoader,
    filteredContainers,
    containersStats,
    settings
}: Props) => {
    useEffect(() => {
        fetchContainersGridView();
    }, []);

    return (
        <ContainersPage
            localization={localization}
            showLoader={showLoader}
            filteredContainers={filteredContainers}
            settings={settings}
            textFilterValue={textFilterValue}
            updateContainerState={updateContainerState}
            fetchContainersGridView={fetchContainersGridView}
            filterContainersByTerm={filterContainersByTerm}
            containersStats={containersStats}
        />
    );
};

const mapStateToProps = (state: RootState) => ({
    filteredContainers: containersSelectors.filteredContainers(state),
    textFilterValue: containersSelectors.textFilterValue(state),
    containersStats: containersSelectors.containersStats(state),
    localization: localizationSelectors.containersPage(state),
    showLoader: state.loading.effects.containers.fetchContainersGridView,
    settings: shipmentsSelectors.settingsByLanguage(state)()
});

const mapDispatchToProps = (dispatch: any) => ({
    fetchContainersGridView: () => dispatch.containers.fetchContainersGridView(),
    filterContainersByTerm: (term: string) => dispatch.containers.filterContainersByTerm(term),
    updateContainerState: (shipmentId: string, containerId: string, state: string) =>
        dispatch.containers.updateContainerState({ shipmentId, containerId, state })
});

export default connect(mapStateToProps, mapDispatchToProps)(ContainersPageConnected);
