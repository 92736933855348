import React, { useEffect } from 'react';
import styled from 'styled-components';
import { notification } from '../../../../models/entities/notification/notification';
import HexagonAvatar from '../../../shared/SmallComponents/HexagonAvatar';
import ShipmentType from '../../../shared/Grid/specialColumns/ShipmentType';
import moment from 'moment';
import theme from '../../../shared/Theme/theme';
import CZTooltip from '../../../shared/CZTooltip/CZTooltip';
import { parseContent } from '../util';
import { drawers } from '../../../../state/ducks/header/header';
import useWindowSize from '../../../../hooks/windowSize';
import followIcon from '../../../../static/icons/followBlue.svg';
import bidsIcon from '../../../../static/icons/bidsBlue.svg';
import {
    AddPersonNotificationIcon,
    MentionNotificationIcon,
    TaskNotificationIcon,
    PurchaseDemandIcon,
    PurchaseOrderIcon
} from '../../../shared/PushNotifications/utils';
import { useHistory } from 'react-router-dom';
import { PagesRoutes } from '../../../../routing/PagesRoutes';
const notificationTheme = theme.colors.notifications;

type Props = {
    localization: any;
    groupedNotifications: Array<Array<notification>> | null;
    setNotificationRead: (notificationId: string, isRead: boolean) => void;
    setMultipleNotificationsRead: (notificationIds: string[], isRead: boolean) => void;
    deleteMultipleNotifications: (notificationIds: string[]) => void;
    deleteNotification: (notificationId: string) => void;
    followShipment: (shipmentId: string) => void;
    unFollowShipment: (shipmentId: string) => void;
    openedFromMenu?: boolean;
    followingShipments?: Array<string>;
    setMenuOpen?: () => void;
    setDrawerOpen: (open: drawers, params?: any) => void;
};

const NotificationsView = ({
    localization,
    groupedNotifications,
    setNotificationRead,
    setMultipleNotificationsRead,
    deleteMultipleNotifications,
    deleteNotification,
    openedFromMenu,
    followShipment,
    unFollowShipment,
    followingShipments,
    setDrawerOpen,
    setMenuOpen
}: Props) => {
    const [windowWidth] = useWindowSize();
    const history = useHistory();

    const closeDrawer = () => {
        if (windowWidth < 1280) {
            setDrawerOpen(null);
        }
    };

    const getLatestNotification = (notifications: notification[]): number => Math.max(...notifications.map((n) => moment(n.createdDate).valueOf()));

    const renderNotification = (notification: notification, notificationIds: string[]) => {
        const onClickItem = (() => {
            const { relatedEntityType, subType, relatedEntityId } = notification.content;
            if (relatedEntityType === 'TASK')
                return () =>
                    setDrawerOpen('TASK', {
                        highlightedTaskId: relatedEntityId
                    });

            if (relatedEntityType === 'CHAT_REQUEST' && subType === 'RECIEVED_REQUEST') {
                return () => setDrawerOpen('CHAT_REQUESTS');
            }

            if (relatedEntityType.includes('COMPANIES_RELATION')) {
                return () => history.push(PagesRoutes.CompaniesRelations);
            }

            if (relatedEntityType.includes('PURCHASE_DEMAND')) {
                return () => history.push(`${PagesRoutes.PurchaseDemands}${relatedEntityId ? `/${relatedEntityId}` : ''}`);
            }

            return undefined;
        })();

        return (
            <Item isRead={notification.isRead} key={notification.id} onClick={onClickItem} className={onClickItem ? 'clickable-item' : ''}>
                {/* {notification.content.relatedEntityType === 'USER' && (
                            <HexagonAvatar width={40} borderColor={notificationTheme.page.item.hexagonBorderColor} /> //TODO: get user img
                        )} */}
                {
                    notification.content.relatedEntityType === 'SHIPMENT' && (
                        <ShipmentType value={notification.content.shipmentType || ''} imgWidth={40} />
                    ) //TODO: get shipment type
                }
                {notification.content.subType === 'INVITE_TO_FOLLOW' && <img width="40px" src={followIcon} alt="Follow" />}
                {['NETWORK_CREATED', 'RECIEVED_REQUEST'].includes(notification.content.subType) && AddPersonNotificationIcon}
                {notification.content.subType === 'DISCUSSION_MENTION' && MentionNotificationIcon}
                {notification.content.relatedEntityType === 'TASK' && TaskNotificationIcon}
                {notification.content.relatedEntityType === 'BID' && <img width="40px" src={bidsIcon} alt="Bid" />}
                {notification.content.relatedEntityType === 'PURCHASE_DEMAND' && PurchaseDemandIcon}
                {notification.content.relatedEntityType === 'PURCHASE_ORDER' && PurchaseOrderIcon}
                <ContentContainer>
                    {parseContent(notification.content, () => {
                        closeDrawer();
                        setMultipleNotificationsRead(notificationIds, true);
                    })}
                    <Date>{moment(notification.createdDate).fromNow()}</Date>
                    <ActionsContainer className="actions-container" openedFromMenu={openedFromMenu}>
                        {notification.content.relatedEntityType === 'SHIPMENT' && (
                            <UnFollowButton
                                onClick={() =>
                                    followingShipments?.includes(notification.content.relatedEntityId)
                                        ? unFollowShipment(notification.content.relatedEntityId)
                                        : followShipment(notification.content.relatedEntityId)
                                }
                            >
                                {followingShipments?.includes(notification.content.relatedEntityId)
                                    ? localization.item.unfollow
                                    : localization.item.follow}
                            </UnFollowButton>
                        )}
                        <CZTooltip text={notification.isRead ? localization.item.tooltipUnread : localization.item.tooltipRead}>
                            <ActionButton
                                onClick={() => setMultipleNotificationsRead(notificationIds, !notification.isRead)}
                                className="material-icons"
                            >
                                {!notification.isRead ? 'drafts' : 'markunread'}
                            </ActionButton>
                        </CZTooltip>
                        <CZTooltip text={localization.item.tooltipRemove}>
                            <ActionButton onClick={() => deleteMultipleNotifications(notificationIds)} className="material-icons">
                                delete
                            </ActionButton>
                        </CZTooltip>
                    </ActionsContainer>
                </ContentContainer>
            </Item>
        );
    };
    return (
        <Container>
            {groupedNotifications?.length ? (
                groupedNotifications
                    ?.sort((a, b) => getLatestNotification(b) - getLatestNotification(a))
                    .map((notifications: notification[], i: number) => (
                        <GroupLatestNotification key={i}>
                            {/* {notifications.length > 1 && <GroupSizeBadge>{notifications.length}</GroupSizeBadge>} */}
                            {renderNotification(
                                notifications[0],
                                notifications.map((n) => n.id)
                            )}
                        </GroupLatestNotification>
                    ))
            ) : (
                <EmptyStateContainer>
                    <div className="icon">
                        <span className="material-icons">notifications_none</span>
                    </div>
                    <div className="title">{localization.empty_state.title}</div>
                    <div className="sub-title">{localization.empty_state.sub_title}</div>
                    <SettingsRef>{localization.empty_state.settings_ref}</SettingsRef>
                </EmptyStateContainer>
            )}
        </Container>
    );
};
interface ItemProps {
    isRead: boolean;
}

const GroupSizeBadge = styled.div`
    height: 0px;
    overflow: hidden;
    position: absolute;
    top: 3px;
    left: 3px;
    width: 18px;
    height: 18px;
    line-height: 18px;
    font-size: 10px;
    border-radius: 50%;
    background-color: #048ac9;
    color: white;
    text-align: center;
    z-index: 2;
`;
const GroupLatestNotification = styled.div`
    position: relative;
    border-bottom: 1px solid ${notificationTheme.page.item.borderColor};
`;
const Container = styled.div`
    flex: 1;
    display: flex;
    flex-direction: column;
    & :last-child {
        border-bottom: none;
    }
    .clickable-item {
        cursor: pointer;
    }
`;
const EmptyStateContainer = styled.div`
    flex: 1;
    display: flex;
    flex-direction: column;
    align-items: center;
    margin: 0 30px 30px 30px;

    .icon {
        .material-icons {
            font-size: 100px;
        }
        margin: 30px;
    }

    .title {
        font-size: 22px;
        margin-bottom: 7px;
    }

    .sub-title {
        max-width: 300px;
        text-align: center;
    }
`;

const SettingsRef = styled.div`
    cursor: pointer;
    color: #008ac9;
    font-size: 15px;
    font-weight: 500;
    margin-top: 16px;
`;

const Item = styled.div<ItemProps>`
    position: relative;
    display: flex;
    min-height: 86px;
    align-items: center;
    padding: 12px;

    ${(props) => !props.isRead && `background-color: ${notificationTheme.page.item.isUnreadBG};`}
    :hover {
        background-color: ${(props) => (props.isRead ? notificationTheme.page.item.hoverReadBG : notificationTheme.page.item.hoverUnreadBG)};
    }
    :hover .actions-container {
        visibility: visible;
    }
`;
const ContentContainer = styled.div`
    margin-left: 10px;
    line-height: 15px;
`;
const Date = styled.span`
    font-size: 11px;
    color: ${notificationTheme.page.item.dateColor};
`;

const actionsStyleMobile = `
bottom: 0px;
top: inherit;
padding: 5px;
`;
interface ActionsContainerProps {
    openedFromMenu?: boolean;
}
const ActionsContainer = styled.div<ActionsContainerProps>`
    display: flex;
    align-items: center;
    position: absolute;
    right: 0;
    bottom: 0;
    font-size: 11px;
    padding: 10px;
    visibility: hidden;
    > * {
        margin-left: 5px;
    }
    ${(props) => (props.openedFromMenu ? actionsStyleMobile : '')}
    @media screen and (max-width: 768px) {
        ${actionsStyleMobile}
    }
`;
const UnFollowButton = styled.div`
    cursor: pointer;
`;
const ReadButton = styled.div`
    cursor: pointer;
`;

const ActionButton = styled.div`
    color: ${notificationTheme.page.item.removeIcon};
    font-size: 18px;
    cursor: pointer;
`;
// const RemoveButton = styled.div`
//     display: flex;
//     cursor: pointer;
//     span.material-icons {
//         color: ${notificationTheme.page.item.removeIcon};
//         font-size: 18px;
//     }
// `;
export default NotificationsView;
