import { ColumnType } from '../../shared/Grid/types/Column';
import { bidGrid } from '../../../models/entities/bid/bid';

export const gridColumns: Array<ColumnType<bidGrid>> = [
    {
        field: 'CargoZoneNumber',
        sortable: true,
        isDisplayed: true,
        columnType: 'bidLink',
        bindedFields: ['id'],
        width: '120px'
    },
    {
        field: 'bidShipmentNumber',
        title: 'Shipment number',
        sortable: true,
        isDisplayed: true,
        width: '120px'
    },
    {
        title: 'Incoterms',
        field: 'incoterms',
        width: '100px',
        sortable: true,
        textAlign: 'center'
    },
    {
        title: 'State',
        field: 'state',
        width: '100px',
        sortable: true,
        textAlign: 'center'
    },
    {
        title: 'Origin',
        field: 'originStation',
        width: '100px',
        sortable: false,
        bindedFields: ['originCountry'],
        columnType: 'origin'
    },
    {
        title: 'Destination',
        field: 'destinationStation',
        width: '100px',
        sortable: false,
        bindedFields: ['destinationCountry'],
        columnType: 'destination'
    },
    {
        field: 'expirationDate',
        sortable: true,
        isDisplayed: true,
        columnType: 'date-time'
    }
];
