import React from 'react';
import { shipmentPackage } from '../../../../../../models/entities/shipment/shipmentPackage';
import { FormDropDown, FormTextInput, NumberUnitInput } from '../../../../../shared/inputs/form';
import { styled } from '../../../../../shared/Theme/theme';
import { Flex, renderFieldLabelByName } from '../../ShipmentForm';

type Props = {
    values: any;
    localizations: any;
};
const Totals = ({ values, localizations }: Props) => {
    const packagesSectionLocalizations = localizations.form.sections.packages;
    const fieldsLocalizations = packagesSectionLocalizations.fields;
    const renderFieldTitle = (name: string, isMandatory: boolean) => renderFieldLabelByName(name, isMandatory, fieldsLocalizations);
    return (
        <Flex style={{ alignItems: 'center' }}>
            <StyledFieldContainer>
                <div>{renderFieldTitle('quantity', false)}</div>
                <div>
                    <FormTextInput
                        name={`quantity`}
                        placeHolder={fieldsLocalizations.quantity.title}
                        type="number"
                        style={{ width: '60px' }}
                        min="0"
                    />
                </div>
            </StyledFieldContainer>

            <StyledFieldContainer>
                <div>{renderFieldTitle('total_volume', true)}</div>
                <NumberUnitInput
                    numberInputName={`volume`}
                    numberPlaceholder={fieldsLocalizations.total_volume.title}
                    numberInputWidth="90px"
                    min={0}
                    step={0.001}
                    unitInputName={`volumeUnit`}
                    unitPlaceholder={fieldsLocalizations.volume_unit.CBM}
                    unitOptions={[
                        { value: 'CBM', text: fieldsLocalizations.volume_unit.CBM },
                        { value: 'CFT', text: fieldsLocalizations.volume_unit.CFT }
                    ]}
                />
            </StyledFieldContainer>
            <StyledFieldContainer>
                <div>{renderFieldTitle('total_weight', true)}</div>
                <NumberUnitInput
                    numberInputName={`weight`}
                    numberPlaceholder={fieldsLocalizations.total_weight.title}
                    numberInputWidth="90px"
                    min={0}
                    step={0.001}
                    unitInputName={`weightUnit`}
                    unitPlaceholder={fieldsLocalizations.weight_unit.KG}
                    unitOptions={[
                        { value: 'KG', text: fieldsLocalizations.weight_unit.KG },
                        { value: 'LB', text: fieldsLocalizations.weight_unit.LB }
                    ]}
                />
            </StyledFieldContainer>

            {['OI', 'OEX', 'OE', 'DROP_OCEAN'].includes(values.shipment_type) && (
                <StyledFieldContainer>
                    <div>{renderFieldTitle('container_number', false)}</div>
                    <div>
                        <FormTextInput name={`containerNumber`} placeHolder={fieldsLocalizations.container_number.title} />
                    </div>
                </StyledFieldContainer>
            )}
        </Flex>
    );
};

const StyledFieldContainer = styled.div`
    margin-bottom: 12px;
    padding-right: 30px;
`;
export default Totals;
