import React, { useState } from 'react';
import { styled } from '../../../shared/Theme/theme';
import { party } from '../../../../models/entities/common_subentities/party';
import Loader from '../../../shared/SmallComponents/Loader';
import CustomModal, { ModalTriggerProps } from '../../../shared/Modal/Modal';
import Button from '../../../shared/SmallComponents/Button';
import { TriggerProps } from '../../../shared/Menu/Menu';
import { formMode } from './PartyForm/PartyFormConnected';
import { purchaseOrder } from '../../../../models/entities/purchaseOrder/purchaseOrder';
import { shipment_po } from '../../../../models/entities/shipment_po';
import { settings } from '../../../../models/entities/businessPartner/businessPartner';
import { featureFlagsModel } from '../../../../models/entities/businessPartner/settings';

type Props<entityType, settingsType> = {
    entity: entityType;
    localization: any;
    settings?: settingsType;
    PartyMenu?: any;
    PartyFormConnected?: any;
    printMode?: boolean;
    featureFlags?: featureFlagsModel;
};

function Parties<entityType extends shipment_po, settingsType extends settings>({
    entity,
    localization,
    PartyMenu,
    settings,
    PartyFormConnected,
    printMode,
    featureFlags
}: Props<entityType, settingsType>) {
    const [modalVisible, setVisible] = useState(false);
    const [party, setParty] = useState({} as party);
    const parties = entity.parties;
    return (
        <PartiesWrapper>
            {parties ? (
                <>
                    {!featureFlags?.UNDER_FORWARDER && PartyFormConnected && (
                        <CustomModal
                            title={{ text: localization.parties.modal.add_title }}
                            Trigger={(props: ModalTriggerProps) => (
                                <ButtonContainer>
                                    <Button onClick={props.onClick} width="auto" buttonType="hollow" padding="4px 12px">
                                        {localization.parties.add_party}
                                    </Button>
                                </ButtonContainer>
                            )}
                            Content={(
                                setVisible?: React.Dispatch<React.SetStateAction<boolean>>,
                                setConfirm?: React.Dispatch<React.SetStateAction<boolean>>
                            ) => {
                                return (
                                    <PartyFormConnected
                                        mode={formMode.NEW}
                                        openedFromModal={setVisible ? { setVisible } : undefined}
                                        confirmation={setConfirm ? { setConfirm } : undefined}
                                    />
                                );
                            }}
                            width="393px"
                        />
                    )}
                    {parties.length > 0 ? (
                        <>
                            {parties.map((party, index) => {
                                const partyType = settings?.partyTypes?.find((item) => item.code === party.type);
                                let type = partyType;
                                if (party.subType) type = partyType?.subTypes?.find((item: any) => item.code === party.subType);
                                return (
                                    <PartyWrapper key={`party_${index}`}>
                                        <div>
                                            <PartyType>{type?.name || party.type}</PartyType> <PartyTitle>: {party.name.toUpperCase()}</PartyTitle>
                                        </div>
                                        <div>
                                            {[party.contactName, party.phoneNumber, party.email].filter((p) => p != null && p !== '').join(' | ')}
                                        </div>
                                        {entity.forwarderId !== party.businessPartnerId &&
                                            entity.consigneeId !== party.businessPartnerId &&
                                            entity.shipperId !== party.businessPartnerId &&
                                            PartyMenu && (
                                                <PartyMenu
                                                    entityId={party.id}
                                                    localization={localization.parties.menu}
                                                    onRowMoreOptionsClick={() => {
                                                        setParty(party);
                                                        setVisible(true);
                                                    }}
                                                    menuTrigger={(props: TriggerProps) => (
                                                        <MenuDisplay className="menu-trigger" onClick={props.onClick}>
                                                            <span className="material-icons">{props.icon || 'more_vert'}</span>
                                                        </MenuDisplay>
                                                    )}
                                                />
                                            )}
                                    </PartyWrapper>
                                );
                            })}
                            {modalVisible && (
                                <CustomModal
                                    title={{ text: localization.parties.modal.add_title }}
                                    isOpen={true}
                                    Content={(
                                        setModalVisible?: React.Dispatch<React.SetStateAction<boolean>>,
                                        setConfirm?: React.Dispatch<React.SetStateAction<boolean>>
                                    ) => {
                                        return (
                                            <PartyFormConnected
                                                mode={formMode.EDIT}
                                                party={party}
                                                openedFromModal={{ setVisible }}
                                                confirmation={setConfirm ? { setConfirm } : undefined}
                                            />
                                        );
                                    }}
                                    onCancelCallback={() => {
                                        setVisible(false);
                                        setParty({} as party);
                                    }}
                                    width="393px"
                                />
                            )}
                        </>
                    ) : (
                        <EmaptyStateText>{localization.parties.empty_state}</EmaptyStateText>
                    )}
                </>
            ) : (
                <Loader />
            )}
        </PartiesWrapper>
    );
}

const PartiesWrapper = styled.div`
    display: flex;
    flex-direction: column;
    width: 100%;
`;

const PartyWrapper = styled.div`
    position: relative;
    font-size: 12px;
    padding: 10px;
    :hover {
        background: ${(props) => props.theme.colors.hoverBG};
        .menu-trigger {
            visibility: visible;
            height: 100%;
            display: flex;
            align-items: center;
        }
    }
`;

const PartyTitle = styled.span`
    font-size: 16px;
    color: ${(props) => props.theme.colors.darkBlue};
`;

const PartyType = styled.span`
    font-size: 16px;
    color: ${(props) => props.theme.colors.pageTitle};
`;

const EmaptyStateText = styled.span`
    color: ${(props) => props.theme.colors.emptyStateText};
`;

const ButtonContainer = styled.div`
    display: flex;
    justify-content: flex-end;
    padding-top: 8px;
    margin-bottom: 10px;
`;

const MenuDisplay = styled.div`
    visibility: hidden;
    cursor: pointer;
    & span {
        user-select: none;
        display: block;
        line-height: inherit;
        color: ${(props) => props.theme.colors.primaryBlue};
    }
`;

export default Parties;
