import { ColumnType } from '../../components/shared/Grid/types/Column';
import { purchaseDemand } from '../../models/entities/purchaseDemand/purchaseDemand';

const mockColumns: Array<ColumnType<purchaseDemand>> = [
    {
        field: 'CargoZoneNumber',
        bindedFields: ['id'],
        width: '150px',
        sortable: true,
        isDisplayed: true,
        textAlign: 'center',
        columnType: 'purchaseDemandLink'
    },
    {
        title: 'Company',
        field: 'companyName',
        bindedFields: ['companyId'],
        width: '100%',
        sortable: true,
        isDisplayed: true,
        columnType: 'company'
    },
    {
        field: 'userName',
        width: '200px',
        sortable: true,
        isDisplayed: true
    },
    {
        field: 'state',
        sortable: true,
        columnType: 'state_name',
        bindedFields: ['state']
    },
    {
        field: 'quantity',
        sortable: true,
        isDisplayed: true
    },
    {
        field: 'approvers',
        sortable: true,
        isDisplayed: true,
        columnType: 'purchaseDemandApprovers'
    },
    {
        field: 'createdAt',
        sortable: true,
        isDisplayed: true,
        columnType: 'date'
    }
];

export default mockColumns;
