import React from 'react';
import { prepareNotification, pushNotification } from '.';
import { notification } from '../../../models/entities/notification/notification';
import { PagesRoutes } from '../../../routing/PagesRoutes';
import followIcon from '../../../static/icons/followBlue.svg';
import bidsIcon from '../../../static/icons/bidsBlue.svg';
import pdIcon from '../../../static/icons/purchaseDemandBlue.svg';
import poIcon from '../../../static/icons/purchaseOrderBlue.svg';

export async function pushNotificationHandle(notification: notification, dispatch: any, myUserId: string, localization: any) {
    console.log('utils : notification', notification);
    if (notification.content.subType === 'INVITE_TO_FOLLOW')
        await pushNotification(
            prepareNotification(notification, localization, {
                onClick: () => dispatch.allShipmentsPage.followShipment(notification.content.relatedEntityId),
                text: localization.follow
            }),
            localization,
            followIcon
        );
    else if (notification.content.relatedEntityType === 'PURCHASE_ORDER') {
        await pushNotification(
            prepareNotification(notification, localization, {
                onClick: () => (window.location.href = `${PagesRoutes.PurchaseOrders}/${notification.content.relatedEntityId}`),
                text: localization.view
            }),
            localization,
            PurchaseOrderIcon
        );
    } else if (notification.content.relatedEntityType === 'PURCHASE_DEMAND') {
        await pushNotification(
            prepareNotification(notification, localization, {
                onClick: () => (window.location.href = `${PagesRoutes.PurchaseDemands}/${notification.content.relatedEntityId}`),
                text: localization.view
            }),
            localization,
            PurchaseDemandIcon
        );
    } else if (notification.content.relatedEntityType === 'SHIPMENT' && notification.content.subType !== 'DELETE') {
        await pushNotification(
            prepareNotification(notification, localization, {
                onClick: () => (window.location.href = `${PagesRoutes.Shipments}/${notification.content.relatedEntityId}`),
                text: localization.view
            }),
            localization
        );
    } else if (notification.content.subType === 'NETWORK_CREATED') {
        const participantsIds = [notification.userId, myUserId];
        await pushNotification(
            prepareNotification(notification, localization, {
                onClick: () => dispatch.chats.openChatDrawer({ participantsIds, chatType: 'user' }),
                text: localization.go_to_chat
            }),
            localization,
            AddPersonNotificationIcon
        );
        dispatch.chats.getMyNetwork();
        dispatch.chats.getChatRequests();
    } else if (notification.content.subType === 'RECIEVED_REQUEST') {
        await pushNotification(
            prepareNotification(notification, localization, {
                onClick: () =>
                    dispatch.header.setDrawerOpenParams({
                        open: 'CHAT_REQUESTS'
                    }),
                text: localization.see_requests
            }),
            localization,
            AddPersonNotificationIcon
        );
        dispatch.chats.getChatRequests();
    } else if (notification.content.relatedEntityType === 'BID') {
        if (notification.content.subType === 'QUOTATION_REPLY') {
            await pushNotification(
                prepareNotification(notification, localization, {
                    onClick: () => (window.location.href = `${PagesRoutes.Shipments}/${notification.content.relatedEntityId}?tab=Quotations`),
                    text: localization.view
                }),
                localization,
                bidsIcon
            );
        } else await pushNotification(prepareNotification(notification, localization), localization, bidsIcon);
    } else await pushNotification(prepareNotification(notification, localization), localization);
}

const iconStyle = { color: '#384273', fontSize: '35px' };

export const AddPersonNotificationIcon = (
    <span style={iconStyle} className="material-icons">
        person_add
    </span>
);

export const MentionNotificationIcon = (
    <span style={iconStyle} className="material-icons">
        alternate_email
    </span>
);

export const TaskNotificationIcon = (
    <span style={iconStyle} className="material-icons">
        schedule
    </span>
);
export const PurchaseDemandIcon = <img width="40px" src={pdIcon} alt="PD" />;
export const PurchaseOrderIcon = <img width="40px" src={poIcon} alt="PO" />;
