import { RootState } from '../../../state/store/store';
import { createSelector } from 'reselect';
import tariffsSelectors from '../tariffs/selectors';

const currentTariffId = (state: RootState) => state.singleTariff.tariffId;
const fetchTariffError = (state: RootState) => state.error.effects.tariffs.fetchTariffById;
const isLoadingTariff = (state: RootState) => state.loading.effects.tariffs.fetchTariffById;
const tariff = createSelector(currentTariffId, tariffsSelectors.tariffsData, (tariffId, tariffs) => {
    return tariffs?.find((tariff) => tariff.id === tariffId);
});
const charges = createSelector(tariff, (tariff) => {
    return tariff!.charges;
});

export { default as singleTariffSelectors } from './selectors';

const selectors = {
    tariff,
    charges,
    fetchTariffError,
    isLoadingTariff,
    currentTariffId
};

export default selectors;
