export const phonePattern = /^[+]*[(]{0,1}[0-9]{1,4}[)]{0,1}[-\s/0-9]*$/;
export const emailPattern = /^\w+([.-]?\w+)*@\w+([.-]?\w+)*([.]\w{2,4})+$/;
export const rmHtmlTags = /<[^>]*>|&nbsp;/g;
export const guidPattern = /^[0-9a-f]{8}-[0-9a-f]{4}-[1-5][0-9a-f]{3}-[89ab][0-9a-f]{3}-[0-9a-f]{12}$/i;
export const numbersPattern = /^-?\d+\.?\d*$/;
export function numberWithCommas(value: string | number) {
    return value.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');
}
export function uuidv4() {
    return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function (c) {
        var r = (Math.random() * 16) | 0,
            v = c == 'x' ? r : (r & 0x3) | 0x8;
        return v.toString(16);
    });
}
