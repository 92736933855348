import BaseController from '..';
import INotification from './INotification';

export default class Notification extends BaseController implements INotification {
    constructor(baseUrl: string) {
        super(baseUrl);
    }
    async fetchNotifications() {
        const response = await this.client.get('/notifications');
        return response;
    }
    async setMultipleNotificationsRead(notificationIds: string[], isRead: boolean) {
        const response = await this.client.put(`/notifications/${isRead ? 'read' : 'unread'}/multiple`, JSON.stringify({ ids: notificationIds }));
        return response;
    }
    async setNotificationRead(notificationId: string, isRead: boolean) {
        const response = await this.client.put(`/notifications/${isRead ? 'read' : 'unread'}/${notificationId}`);
        return response;
    }
    async setAllNotificationRead(isRead: boolean) {
        const response = await this.client.put(`/notifications/${isRead ? 'readAll' : 'unreadAll'}`);
        return response;
    }
    async setMultipleNotificationDeleted(notificationIds: string[]) {
        const response = await this.client.delete(`/notifications/delete/multiple`, JSON.stringify({ ids: notificationIds }));
        return response;
    }
    async setNotificationDeleted(notificationId: string) {
        const response = await this.client.delete(`/notifications/delete/${notificationId}`);
        return response;
    }
    async setAllNotificationDeleted() {
        const response = await this.client.delete('/notifications/deleteAll');
        return response;
    }
}
