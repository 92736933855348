import { ModelConfig } from '@rematch/core';
import { randDarkColor } from '../../../components/shared/Theme/util';
import { userInfo } from '../../../models/entities/user';
import { localSDK as client } from '../../../sdk';
import { bufferToBase64 } from '../../../utils/files';
import { RootState } from '../../store/store';

type pictureObject = {
    fileId: string;
    url: string;
};

export type addUserPayload = {
    userId: string;
    username: string;
    email: string;
    userPic: string;
};

export type userDetails = {
    id: string;
    companyId: string;
    companyName: string;
    userName: string;
    email: string;
    profileImg: string;
    profileImgUrl: string;
    picture: pictureObject;
    name: string;
    firstName: string;
    lastName: string;
    role: string;
    phoneNumber: string;
    birthDate: string;
    status: string;
    statusMessage: string;
    isActive: boolean;
    networkId?: string;
    avatarColor?: string;
};

export type usersObject = {
    [key: string]: userDetails;
};

export type usersStateType = {
    users: usersObject;
    connectedUsers: string[];
};

export const users: ModelConfig<usersStateType> = {
    state: {
        users: {},
        connectedUsers: []
    },
    reducers: {
        setUserPicture(state: usersStateType, userId: string, pictureObject: pictureObject): usersStateType {
            const users = { ...state.users };
            if (users[userId]) {
                const newUser = { ...users[userId], picture: pictureObject };
                users[userId] = newUser;
            }
            return { ...state, users };
        },
        setUsers(state: usersStateType, userId: string, userDetails: userDetails): usersStateType {
            const newUsers = { ...state.users, [userId]: userDetails };
            return { ...state, users: newUsers };
        },
        appendUsers(state: usersStateType, users: usersObject): usersStateType {
            const newUsers = { ...state.users, ...users };
            return { ...state, users: newUsers };
        },
        setUserIsActive(state: usersStateType, params: { userId: string; isActive: boolean; networkId?: string }): usersStateType {
            const { userId, isActive, networkId } = params;
            const users = { ...state.users };
            if (users[userId]) {
                const newUser = { ...users[userId], isActive, networkId };
                users[userId] = newUser;
            }
            return { ...state, users };
        },
        setUserStatus(state: usersStateType, userId: string, status: string): usersStateType {
            const users = { ...state.users };
            if (users[userId]) {
                users[userId].status = status;

                return { ...state, users };
            }

            return { ...state };
        },
        setConnectedUsers(state: usersStateType, connectedUsers: string[]): usersStateType {
            return { ...state, connectedUsers };
        }
    },
    effects: (dispatch: any) => ({
        async addUsers(usersIds: string[], state: RootState) {
            const users = state.users.users;
            const usersToAdd = usersIds.filter((id) => !users[id]);

            if (usersToAdd?.length) {
                const response = await client.user().getUsersByIds(usersToAdd);
                const entries = Object.entries(response);

                const usersObject: usersObject = {};
                entries.forEach(([userId, info]) => {
                    const { firstName, lastName, profileImg, email, role, phoneNumber, companyName, status, id } = info;
                    const details: userDetails = {
                        id,
                        name: `${firstName} ${lastName}`,
                        profileImg,
                        profileImgUrl: profileImg ? `${process.env.REACT_APP_API_URL}/api/user/image/${profileImg}` : null,
                        email,
                        role,
                        phoneNumber,
                        companyName,
                        status
                    } as userDetails;

                    usersObject[userId] = details;
                });

                dispatch.users.appendUsers(usersObject);
            }
        },
        async addUser(userId: string, state: RootState) {
            const users = state.users.users;
            const user = users[userId];

            if (!user) {
                const newUser: userInfo = await client.user().getUserById(userId);
                return setUser(newUser);
            }
            async function setUser(newUser: userInfo) {
                const { firstName, lastName, profileImg, email, role, phoneNumber, companyName, status, avatarColor } = newUser;
                const user: userDetails = {
                    id: userId,
                    name: `${firstName} ${lastName}`,
                    profileImg,
                    profileImgUrl: profileImg ? `${process.env.REACT_APP_API_URL}/api/user/image/${profileImg}` : null,
                    email,
                    role,
                    phoneNumber,
                    companyName,
                    status,
                    avatarColor
                } as userDetails;

                dispatch.users.setUsers(userId, user);

                return user;
            }
        }
    })
};
