import React, { useState } from 'react';
import { purchaseDemand } from '../../../../../models/entities/purchaseDemand/purchaseDemand';
import { purchaseDemandItem } from '../../../../../models/entities/purchaseDemand/purchaseDemandItem';
import { styled } from '../../../../shared/Theme/theme';
import MuiDataGrid from '../../../../shared/MuiDataGrid/MuiDataGrid';
import { GridRowId } from '@material-ui/data-grid';
import Button from '../../../../shared/SmallComponents/Button';
import { ItemsGridColumns } from './gridColumns/SelectItemsBySuppliersItemsGridCols';

type ItemsBySupplierIdType = { [key: string]: purchaseDemandItem[] };

type Props = {
    purchaseDemand: purchaseDemand;
    localization: any;
    onSelectItemsCallBack: (items: purchaseDemandItem[], selectedSupplierId?: string) => Promise<void>;
};

const SelectItemsBySupplier = ({ purchaseDemand, onSelectItemsCallBack, localization }: Props) => {
    const itemsBySupplierId: ItemsBySupplierIdType = (purchaseDemand.items || []).reduce((acc: ItemsBySupplierIdType, item: purchaseDemandItem) => {
        const { productSupplierId, openedQuantity, quantity } = item;

        if (!productSupplierId || (openedQuantity || 0) >= quantity) return acc;

        return { ...acc, [productSupplierId]: [...(acc[productSupplierId] || []), item] };
    }, {});

    const suppliersIds = Object.keys(itemsBySupplierId);
    const singleSupplierId = suppliersIds.length === 1 ? suppliersIds[0] : undefined;

    const [selectedSupplier, setSelectedSupplier] = React.useState<string | undefined>(singleSupplierId);

    const [pdItems, setPdItems] = useState<purchaseDemandItem[] | undefined>(singleSupplierId ? itemsBySupplierId[singleSupplierId] : undefined);

    const [selectedItems, setSelectedItems] = React.useState<GridRowId[]>(
        singleSupplierId ? itemsBySupplierId[singleSupplierId]?.map((item) => item.id) || [] : []
    );

    const changePdItemQuantity = (pdItemId: string, quantity: number) => {
        if (!selectedSupplier) return;

        const supplierPdItems = itemsBySupplierId[selectedSupplier];

        if (!supplierPdItems) return;

        const newPdItems = [...(pdItems || [])];

        const initPdItemIndex = supplierPdItems.findIndex((item) => item.id === pdItemId);

        if (initPdItemIndex === -1) return;

        const currentPdItemIndex = newPdItems.findIndex((item) => item.id === pdItemId);

        if (currentPdItemIndex === -1) return;

        const currentPdItem = newPdItems[currentPdItemIndex];

        const initLeftQuantity = Number(supplierPdItems[initPdItemIndex]?.leftQuantity) || 0;

        if (quantity > initLeftQuantity || quantity < 1) return;

        const newPdItem = { ...currentPdItem, leftQuantity: quantity };

        newPdItems[currentPdItemIndex] = newPdItem;

        setPdItems(newPdItems);
    };
    return (
        <div>
            {suppliersIds.length ? (
                !pdItems ? (
                    suppliersIds.map((id) => (
                        <SupplierContainer
                            onClick={() => {
                                setSelectedSupplier(id);
                                setPdItems(itemsBySupplierId[id]);
                                setSelectedItems(itemsBySupplierId[id]?.map((item) => item.id) || []);
                            }}
                        >
                            <SupplierName>{itemsBySupplierId[id][0].productSupplierName}</SupplierName>
                            <SupplierItemsCount>
                                {itemsBySupplierId[id].length} {localization.items_title}
                            </SupplierItemsCount>
                        </SupplierContainer>
                    ))
                ) : (
                    <div>
                        <MuiDataGrid
                            columns={ItemsGridColumns(
                                localization.items.grid.columns,
                                changePdItemQuantity,
                                pdItems || [],
                                selectedSupplier ? itemsBySupplierId[selectedSupplier] : []
                            )}
                            rows={pdItems}
                            autoHeight
                            disableColumnFilter
                            disableColumnMenu
                            disableColumnReorder
                            checkboxSelection
                            onSelectionModelChange={(newSelection) => {
                                setSelectedItems(newSelection.selectionModel);
                            }}
                            selectionModel={selectedItems}
                        />
                        <ButtonsContainer>
                            <Button
                                width="auto"
                                type="button"
                                buttonType="hollow"
                                onClick={() => {
                                    setPdItems(undefined);
                                    setSelectedItems([]);
                                }}
                            >
                                <span style={{ fontSize: '16px', marginRight: '5px' }} className="material-icons">
                                    arrow_back
                                </span>
                                <span>{localization.back_btn}</span>
                            </Button>
                            <Button
                                width="auto"
                                type="button"
                                buttonType="filled"
                                onClick={async () => {
                                    if (!selectedItems.length) return;

                                    await onSelectItemsCallBack(
                                        pdItems.filter((item) => selectedItems.includes(item.id)),
                                        selectedSupplier
                                    );
                                }}
                            >
                                <span>{localization.next_btn}</span>
                                <span style={{ fontSize: '16px', marginLeft: '5px' }} className="material-icons">
                                    arrow_forward
                                </span>
                            </Button>
                        </ButtonsContainer>
                    </div>
                )
            ) : (
                <div>{localization.no_suppliers}</div>
            )}
        </div>
    );
};

const SupplierContainer = styled.div`
    padding: 20px;
    cursor: pointer;
    border-bottom: 1px solid ${(props) => props.theme.colors.gridRowBorder};
    display: flex;
    align-items: center;
    justify-content: space-between;
    :hover {
        background: ${(props) => props.theme.colors.hoverBG};
    }
`;

const SupplierName = styled.div`
    font-size: 16px;
    color: ${(props) => props.theme.colors.pageTitle};
`;

const SupplierItemsCount = styled.div``;

const ButtonsContainer = styled.div`
    display: flex;
    justify-content: space-between;
    margin-top: 20px;
`;

export default SelectItemsBySupplier;
