import React from 'react';
import moment from 'moment';
import styled from 'styled-components';
import SummarySection from './Summary/SummarySection';
import { summaryData } from '../../../models/entities/dashboard/summaryData';
import GridSection from './GridSection';
import { shipment } from '../../../models/entities/shipment/shipment';
import Loader from '../../shared/SmallComponents/Loader';
import { ColumnType } from '../../shared/Grid/types/Column';
import { purchaseOrder } from '../../../models/entities/purchaseOrder/purchaseOrder';
import { filterFieldsType } from '../../../state/ducks/allPurchaseOrdersPage/allPurchaseOrdersPage';
import { drawers } from '../../../state/ducks/header/header';
import { shipmentsStatistics } from '../../../models/entities/dashboard/shipmentsStatistics';
import ShipmentCard from '../../shared/ShipmentCard/ShipmentCard';
import { useHistory } from 'react-router';
import { PagesRoutes } from '../../../routing/PagesRoutes';
import { featureFlagsModel } from '../../../models/entities/businessPartner/settings';
import { purchaseDemand } from '../../../models/entities/purchaseDemand/purchaseDemand';
import { bid } from '../../../models/entities/bid/bid';
import { eQuotationState, quotationView } from '../../../models/entities/bid/quotation';
import { currencyFormatter } from '../../shared/inputs/form/CurrencyInput/CurrencyInput';
import { eBidShipmentTypes } from '../singleShipment/BidForm/ShipmentBidForm';
import CZTooltip from '../../shared/CZTooltip/CZTooltip';

type Props = {
    localization: any;
    shipmentsStatistics: shipmentsStatistics | null;
    summaryData: summaryData | null;
    purchaseOrderGridColumns: Array<ColumnType<purchaseOrder>>;
    upcomingArrivals?: Array<shipment>;
    readySoonShipments?: Array<shipment>;
    purchaseOrders?: Array<purchaseOrder>;
    purchaseDemandsGridColumns: Array<ColumnType<purchaseDemand>>;
    purchaseDemands: Array<purchaseDemand>;
    showLoader: boolean;
    userName: string;
    myRFQ: Array<bid>;
    sentQuotations: Array<quotationView>;
    isFreeLancer?: boolean;
    isForwarder?: boolean;
    featureFlags?: featureFlagsModel;
    orderShipments: shipment[];
    setShipmentFilters: (filter: filterFieldsType) => void;
    setGridFilter: (text: string) => void;
    setDrawerOpen: (open: drawers, params?: any) => void;
};

const DashboardPage = ({
    localization,
    summaryData,
    upcomingArrivals,
    readySoonShipments,
    showLoader,
    userName,
    purchaseOrders,
    purchaseOrderGridColumns,
    shipmentsStatistics,
    purchaseDemandsGridColumns,
    purchaseDemands,
    isFreeLancer,
    isForwarder,
    myRFQ,
    sentQuotations,
    featureFlags,
    orderShipments,
    setShipmentFilters,
    setDrawerOpen,
    setGridFilter
}: Props) => {
    const history = useHistory();

    const ShipmentTypeIconName = ({ type }: { type: string }) => {
        const typeIcon = require(`../../../static/icons/shipmentTypes/${type}.svg`).default;
        return (
            <CZTooltip text={eBidShipmentTypes[type]}>
                <div>
                    <img width={25} src={typeIcon} alt={type} />
                </div>
            </CZTooltip>
        );
    };
    const upcomingArrivalsMockColumns: Array<ColumnType<shipment>> = [
        {
            field: 'CargoZoneNumber',
            bindedFields: ['id'],
            width: '100px',
            sortable: true,
            isDisplayed: true,
            textAlign: 'center',
            columnType: 'shipmentLink'
        },
        {
            field: 'alertStatus',
            width: '40px',
            sortable: true,
            columnType: 'shipmentAlertStatus',
            textAlign: 'center',
            toolTipText: localization.upcoming_arrivals.grid.columns.alertStatus_tooltip,
            bindedFields: ['id']
        },
        {
            field: 'typeCode',
            bindedFields: ['subTypeCode'],
            width: '70px',
            sortable: true,
            columnType: 'shipmentType',
            textAlign: 'center'
        },
        {
            field: 'createdAt',
            width: '100px',
            sortable: true,
            toolTipText: localization.upcoming_arrivals.grid.columns.createdAt_tooltip,
            columnType: 'date',
            isDisplayed: false
        },
        {
            field: 'companyName',
            width: '100%',
            sortable: true,
            isDisplayed: false
        },
        {
            field: 'forwarderName',
            width: '100px',
            sortable: true,
            isDisplayed: false
        },
        {
            field: 'clientRef',
            width: '100px',
            toolTipText: localization.upcoming_arrivals.grid.columns.clientRef_tooltip,
            sortable: false
        },
        {
            field: 'forwarderRef',
            width: '100px',
            sortable: false,
            isDisplayed: false
        },
        {
            field: 'incoterms',
            width: '80px',
            sortable: true,
            textAlign: 'center',
            isDisplayed: false
        },
        {
            field: 'originStation',
            width: '90px',
            sortable: false,
            bindedFields: ['originCountry', 'originStationName'],
            columnType: 'origin',
            toolTipText: localization.upcoming_arrivals.grid.columns.originStation_tooltip
        },
        {
            field: 'destinationStation',
            width: '90px',
            sortable: false,
            bindedFields: ['destinationCountry', 'destinationStationName'],
            columnType: 'destination',
            toolTipText: localization.upcoming_arrivals.grid.columns.destinationStation_tooltip
        },
        {
            field: 'shipperName',
            width: '100%',
            sortable: true
        },
        {
            field: 'consigneeName',
            width: '100%',
            sortable: true,
            isDisplayed: false
        },
        {
            field: 'userName',
            width: '100%',
            sortable: true,
            isDisplayed: false
        },
        {
            field: 'quantity',
            width: '100%',
            sortable: false,
            toolTipText: localization.upcoming_arrivals.grid.columns.quantity_tooltip,
            textAlign: 'center',
            isDisplayed: false
        },
        {
            field: 'weight',
            width: '100%',
            sortable: false,
            columnType: 'weight_volume',
            bindedFields: ['weight', 'weightUnit', 'volume', 'volumeUnit'],
            isDisplayed: false
        },
        {
            field: 'carrierCode',
            width: '100%',
            sortable: true,
            toolTipText: localization.upcoming_arrivals.grid.columns.carrierCode_tooltip,
            columnType: 'carrier',
            isDisplayed: false
        },
        {
            field: 'containerNumber',
            width: '100%',
            sortable: false,
            isDisplayed: false
        },
        {
            field: 'eta',
            width: '100%',
            sortable: true,
            columnType: 'date'
        },
        {
            field: 'commodity',
            width: '100%',
            sortable: false,
            isDisplayed: false
        },
        {
            field: 'state',
            width: '100%',
            sortable: true,
            columnType: 'state_name',
            toolTipText: localization.upcoming_arrivals.grid.columns.state_tooltip,
            bindedFields: ['state'],
            isDisplayed: false
        },
        {
            field: 'lastEvent',
            width: '100%',
            sortable: true,
            columnType: 'event_name',
            toolTipText: localization.upcoming_arrivals.grid.columns.lastEvent_tooltip,
            bindedFields: ['lastEvent'],
            isDisplayed: false
        },
        {
            field: 'updatedAt',
            width: '100%',
            sortable: true,
            toolTipText: localization.upcoming_arrivals.grid.columns.updatedAt_tooltip,
            columnType: 'date-time',
            isDisplayed: false
        }
    ];
    const myRFQMockColumns: Array<ColumnType<bid>> = [
        {
            field: 'companyName',
            width: '200px',
            sortable: true,
            isDisplayed: true
        },
        {
            field: 'expirationDate',
            width: '100%',
            sortable: true,
            columnType: 'date-time'
        },
        {
            field: 'shipmentTypes',
            width: '100%',
            sortable: false,
            renderer: (cellValue: any) => {
                if (typeof cellValue === 'string') return <ShipmentTypeIconName type={cellValue} />;

                if (Array.isArray(cellValue) && cellValue.length > 0) {
                    if (cellValue.length === 1) return <ShipmentTypeIconName type={cellValue[0]} />;

                    return (
                        <div style={{ display: 'flex' }}>
                            {cellValue.map((type, i) => {
                                return (
                                    <div style={{ margin: '0 3px' }}>
                                        <ShipmentTypeIconName type={type} />
                                    </div>
                                );
                            })}
                        </div>
                    );
                }

                return <div>-</div>;
            }
        },
        {
            field: 'note',
            width: '100%',
            sortable: false
        }
        // {
        //     field: 'id',
        //     title: '',
        //     width: '150px',
        //     hideTitleAttribute: true,
        //     sortable: false,
        //     renderer: (cellValue: any) => <Button>Send an offer</Button>
        // }
    ];
    const sentQuotationsColumns: Array<ColumnType<quotationView>> = [
        {
            field: 'shipmentType',
            width: '130px',
            sortable: true,
            renderer: (cellValue: any) => <div>{!cellValue ? '-' : <ShipmentTypeIconName type={cellValue} />}</div>
        },
        {
            field: 'freightPrice',
            bindedFields: ['freightPriceCurrency'],
            width: '100%',
            sortable: true,
            renderer: (cellValue: any, bindedData?: any[] | null | undefined) => {
                const freightPrice = Number(cellValue);
                const freightPriceCurrency = bindedData?.[0];

                if (!(freightPrice || freightPriceCurrency)) return <div>-</div>;

                return <div>{currencyFormatter(freightPriceCurrency)(freightPrice)}</div>;
            }
        },
        {
            field: 'transitTime',
            width: '100%',
            sortable: true,
            renderer: (cellValue: any) => <div>{!cellValue ? '-' : `${cellValue}d`}</div>
        },
        {
            field: 'validTill',
            width: '100%',
            sortable: true,
            columnType: 'date-time'
        },
        {
            field: 'note',
            width: '100%',
            sortable: true
        },
        {
            field: 'state',
            width: '100%',
            sortable: true,
            renderer: (cellValue: any) => <div>{!cellValue ? '-' : eQuotationState[cellValue]}</div>
        }
    ];

    return (
        <Container>
            <Sections>
                {showLoader ? (
                    <Loader />
                ) : (
                    <>
                        <TitleWrapper>
                            <div className="title">
                                {localization.title}, {userName}
                            </div>
                            <div className="sub-title">{localization.sub_title}</div>
                        </TitleWrapper>
                        <SummarySection
                            localization={localization.summary}
                            data={summaryData}
                            setShipmentFilters={setShipmentFilters}
                            setDrawerOpen={setDrawerOpen}
                            setGridFilter={setGridFilter}
                        />
                        {featureFlags?.DEV && (
                            <>
                                {shipmentsStatistics && (
                                    <AssemblySuggestionsContainer>
                                        {[shipmentsStatistics.ForwardingAssemblyAlerts, shipmentsStatistics.CustomsAssemblyAlerts].map(
                                            (suggestions) =>
                                                suggestions.map((shipmentsArr, index) => {
                                                    const arrivalDate = moment(shipmentsArr[0].eta).format('L');
                                                    return (
                                                        <AssemblySuggestion key={index}>
                                                            <AssemblySuggestionHeader>
                                                                {shipmentsArr.map((s) => {
                                                                    return (
                                                                        <AssemblyShipment
                                                                            key={s.id}
                                                                            onClick={() => {
                                                                                history.push(PagesRoutes.Shipments + '/' + s.id);
                                                                            }}
                                                                        >
                                                                            <ShipmentCard shipmentId={s.id}>{s.CargoZoneNumber}</ShipmentCard>
                                                                        </AssemblyShipment>
                                                                    );
                                                                })}
                                                            </AssemblySuggestionHeader>
                                                            <AssemblyDetailsContainer>
                                                                <AssemblyDetail>Arrival Date: {arrivalDate}</AssemblyDetail>
                                                                {Object.keys(shipmentsArr[0])
                                                                    .filter((k) => !['eta', 'id', 'CargoZoneNumber'].includes(k))
                                                                    .map((key) => {
                                                                        return (
                                                                            <AssemblyDetail key={key}>
                                                                                {key}: {shipmentsArr[0][key as keyof shipment]}
                                                                            </AssemblyDetail>
                                                                        );
                                                                    })}
                                                            </AssemblyDetailsContainer>
                                                        </AssemblySuggestion>
                                                    );
                                                })
                                        )}
                                    </AssemblySuggestionsContainer>
                                )}
                            </>
                        )}
                        <GridsContainer>
                            <GridSection<purchaseDemand>
                                columns={purchaseDemandsGridColumns}
                                data={purchaseDemands}
                                localization={localization.purchase_demands}
                            />
                            {isForwarder && (
                                <>
                                    <GridSection<bid>
                                        columns={myRFQMockColumns}
                                        data={myRFQ}
                                        localization={localization.my_rfq}
                                        onRowClick={(bid) => {
                                            history.push({
                                                pathname: PagesRoutes.Quotation,
                                                state: {
                                                    quotationPublicDetails: {
                                                        shipment: bid.shipment,
                                                        bid,
                                                        party: bid.parties?.[0],
                                                        shipmentItemsRelatedProducts: bid.shipmentItemsRelatedProducts
                                                    }
                                                }
                                            });
                                        }}
                                    />
                                    <GridSection<quotationView>
                                        columns={sentQuotationsColumns}
                                        data={sentQuotations}
                                        localization={localization.sent_quotations}
                                        onRowClick={(quotationView) => {
                                            console.log(quotationView);
                                            setDrawerOpen('QUOTATION', { quotation: quotationView, shipmentId: '', bid: null });
                                        }}
                                    />
                                </>
                            )}
                            {isFreeLancer ? (
                                <GridSection<shipment>
                                    columns={upcomingArrivalsMockColumns}
                                    data={readySoonShipments || []}
                                    localization={localization.ready_soon_shipments}
                                />
                            ) : (
                                <GridSection<purchaseOrder>
                                    columns={purchaseOrderGridColumns}
                                    data={purchaseOrders || []}
                                    localization={localization.ready_soon_po}
                                />
                            )}
                        </GridsContainer>
                        <GridsContainer>
                            <GridSection<shipment>
                                columns={upcomingArrivalsMockColumns}
                                data={upcomingArrivals || []}
                                localization={localization.upcoming_arrivals}
                            />
                            {isForwarder && (
                                <GridSection<shipment>
                                    columns={upcomingArrivalsMockColumns}
                                    data={orderShipments || []}
                                    localization={localization.order_shipments}
                                />
                            )}
                        </GridsContainer>
                    </>
                )}
            </Sections>
        </Container>
    );
};

const AssemblySuggestionsContainer = styled.div`
    display: flex;
    overflow-x: auto;
`;
const AssemblySuggestion = styled.div`
    margin: 10px;
    border: 1px solid black;
    border-radius: 5px;
`;
const AssemblySuggestionHeader = styled.div`
    padding: 5px;
    border-bottom: 1px solid black;
    display: flex;
`;
const AssemblyShipment = styled.div`
    cursor: pointer;
    margin: 2px;
    background-color: #f1f1f1;
    padding: 2px;
`;
const AssemblyDetailsContainer = styled.div`
    padding: 5px;
`;
const AssemblyDetail = styled.div``;
const Sections = styled.div`
    flex: 1;
    overflow-y: overlay;
`;

const GridsContainer = styled.div`
    display: flex;

    @media screen and (max-width: 1200px) {
        display: initial;
    }
`;

const TitleWrapper = styled.div`
    color: ${(props) => props.theme.colors.sectionTitle};
    padding: 30px 30px 15px 30px;
    width: 50%;

    @media screen and (max-width: 1490px) {
        width: 100%;
    }

    .title {
        font-size: 25px;
        font-weight: 500;
    }

    .sub-title {
        font-size: 13px;
        white-space: pre-line;
    }
`;

const Container = styled.div`
    display: flex;
`;

export default DashboardPage;
