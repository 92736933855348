import { RootState } from '../../../state/store/store';
import { createSelector } from 'reselect';
import purchaseOrdersSelectors from '../purchaseOrders/selectors';
import { byDate, byNumber } from '../../../utils/sortArray';
import { party } from '../../../models/entities/common_subentities/party';
import { event } from '../../../models/entities/common_subentities/event';
import { document } from '../../../models/entities/common_subentities/document';
import { discussion } from '../../../models/entities/common_subentities/discussion';

const currentPurchaseOrderId = (state: RootState) => state.singlePurchaseOrder.purchaseOrderId;
const products = (state: RootState) => state.products.products;
const fetchPurchaseOrderError = (state: RootState) => state.error.effects.purchaseOrders.fetchPurchaseOrderById;

const purchaseOrder = createSelector(currentPurchaseOrderId, purchaseOrdersSelectors.purchaseOrdersData, (purchaseOrderId, purchaseOrders) => {
    return purchaseOrders?.find((purchaseOrder) => purchaseOrder.id === purchaseOrderId);
});
const poItemsRelatedProducts = createSelector(purchaseOrder, products, (purchaseOrder, products) => {
    return products.filter((product) => purchaseOrder?.items?.some((item) => item.productId === product.id));
});
const purchaseOrderState = createSelector(currentPurchaseOrderId, purchaseOrdersSelectors.purchaseOrdersData, (purchaseOrderId, purchaseOrders) => {
    const purchaseOrder = purchaseOrders?.find((purchaseOrder) => purchaseOrder.id === purchaseOrderId);
    if (purchaseOrder) return purchaseOrder.state;
});
const parties = createSelector(currentPurchaseOrderId, purchaseOrdersSelectors.purchaseOrdersData, (purchaseOrderId, purchaseOrders) => {
    const purchaseOrder = purchaseOrders?.find((item) => item.id === purchaseOrderId);
    if (purchaseOrder) return purchaseOrder.parties?.sort(byNumber(false, (party: party) => party.id));
});
const events = createSelector(currentPurchaseOrderId, purchaseOrdersSelectors.purchaseOrdersData, (purchaseOrderId, purchaseOrders) => {
    const purchaseOrder = purchaseOrders?.find((item) => item.id === purchaseOrderId);
    if (purchaseOrder) return purchaseOrder.events?.sort(byDate(false, (event: event) => event.eventDate));
});
const documents = createSelector(currentPurchaseOrderId, purchaseOrdersSelectors.purchaseOrdersData, (purchaseOrderId, purchaseOrders) => {
    const purchaseOrder = purchaseOrders?.find((item) => item.id === purchaseOrderId);
    if (purchaseOrder) return purchaseOrder.documents?.documents?.sort(byDate(true, (document: document) => document.createdDate));
});
const discussions = createSelector(currentPurchaseOrderId, purchaseOrdersSelectors.purchaseOrdersData, (purchaseOrderId, purchaseOrders) => {
    const purchaseOrder = purchaseOrders?.find((item) => item.id === purchaseOrderId);
    if (purchaseOrder) return purchaseOrder.discussions?.sort(byDate(true, (discussion: discussion) => discussion.createdDate));
});

export { default as singlePurchaseOrderSelectors } from './selectors';

export default {
    fetchPurchaseOrderError,
    purchaseOrder,
    purchaseOrderState,
    parties,
    events,
    documents,
    discussions,
    poItemsRelatedProducts
};
