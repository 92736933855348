import React, { PropsWithChildren } from 'react';
import styled from 'styled-components';
import FormFieldLabel from './FormFieldLabel';

type Props = {
    fieldName: string;
    isMandatory?: boolean;
    localization?: any;
};
const FormInputWrapper = ({ fieldName, isMandatory, localization, children }: PropsWithChildren<Props>) => {
    const { title, tooltip } = localization?.[fieldName] || { title: fieldName, tooltip: undefined };
    return (
        <FieldContainer>
            <div>
                <FormFieldLabel text={title} isMandatory={isMandatory} tooltipText={tooltip} />
            </div>
            <div>{children}</div>
        </FieldContainer>
    );
};

export const FieldContainer = styled.div`
    margin-bottom: 12px;
`;

export default FormInputWrapper;
