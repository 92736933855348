import { createSelector } from 'reselect';
import moment from 'moment';
import { notification } from '../../../models/entities/notification/notification';
import { RootState } from '../../store/store';
import headerSelector from '../header/selectors';
import { Dictionary, groupBy } from 'lodash';

const notifications = (state: RootState) => state.notifications.notifications;
const notificationDrawerIsOpen = (state: RootState) => headerSelector.drawerOpen(state) === 'NOTIFICATION';
const error = (state: RootState) => state.notifications.error;

const groupedNotifications = createSelector(notifications, (notifications) => {
    const notificationsGroups = groupBy(
        notifications?.sort((a, b) => moment(b.createdDate).valueOf() - moment(a.createdDate).valueOf()),
        'content.message'
    );

    return Object.keys(notificationsGroups).map((k: keyof Dictionary<notification>) => notificationsGroups[k]);
});

const unReadNotificationsCount = createSelector(groupedNotifications, (groupedNotifications) => {
    return groupedNotifications.filter((notifications) => notifications.some((notification) => notification.isRead === false)).length;
});

const isAllNotificationsRead = createSelector(notifications, (notifications) => {
    return notifications?.every((n) => n.isRead);
});

export { default as notificationsSelectors } from './selectors';

export default {
    notifications,
    notificationDrawerIsOpen,
    unReadNotificationsCount,
    groupedNotifications,
    isAllNotificationsRead,
    error
};
