import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import BidsPage from './BidsPage';
import { RootState } from '../../../state/store/store';
import { localizationSelectors } from '../../../state/ducks/localization/selectors';
import { gridColumns } from './gridColumns';
import ExceptionPage from '../ExceptionPage/ExceptionPage';
import { bidsSelectors } from '../../../state/ducks/bids/selectors';
import { bid } from '../../../models/entities/bid/bid';
import { userSettings } from '../../../models/entities/user';
import { filterColsByUserSettings } from '../../shared/Grid/util';
import { userSelectors } from '../../../state/ducks/user/selectors';

type Props = {
    bids: Array<bid>;
    fetchBidsError: any;
    fetchBids: () => void;
    localizations: any;
    showLoader?: boolean;
    bidsGridSettings?: userSettings;
};

const BidsPageConnected = ({ bids, fetchBidsError, fetchBids, localizations, showLoader, bidsGridSettings }: Props) => {
    useEffect(() => {
        fetchBids();
    }, [fetchBids]);

    const parsedError = fetchBidsError ? JSON.parse(fetchBidsError) : null;
    const userSettingKey = 'BidGrid';
    return !fetchBidsError ? (
        <BidsPage
            bids={bids}
            gridColumns={filterColsByUserSettings(gridColumns, bidsGridSettings) as any}
            localization={localizations}
            showLoader={showLoader}
            userSettingKey={userSettingKey}
        />
    ) : (
        <ExceptionPage status={parsedError.status} subTitle={parsedError.message} goBackPath="/" />
    );
};

const mapStateToProps = (state: RootState) => ({
    bids: bidsSelectors.bidsData(state),
    fetchBidsError: state.error.effects.bids.fetchBids,
    localizations: localizationSelectors.bid(state),
    showLoader: state.loading.effects.bids.fetchBids,
    bidsGridSettings: userSelectors.bidsGridSettings(state)
});

const mapDispatchToProps = (dispatch: any) => ({
    fetchBids: () => dispatch.bids.fetchBids()
});

export default connect(mapStateToProps, mapDispatchToProps)(BidsPageConnected);
