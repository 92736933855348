import React, { useEffect, useState } from 'react';
import { purchaseDemand } from '../../../../models/entities/purchaseDemand/purchaseDemand';
import { PagesRoutes } from '../../../../routing/PagesRoutes';
import theme, { styled } from '../../../shared/Theme/theme';
import CZTooltip, { QuestionToolTip } from '../../../shared/CZTooltip/CZTooltip';
import { Formik, Form } from 'formik';
import Button from '../../../shared/SmallComponents/Button';
import { useHistory } from 'react-router-dom';
import Items from './sections/Items';
import { itemsPrepareSubmit, validateItems } from './sections/Items/util';
import { BaseProps } from './PurchaseDemandFormConnected';
import Loader from '../../../shared/SmallComponents/Loader';
import { FormDropDown, UserDropDownForm, ErrorText, FormDatePicker, FormCheckbox } from '../../../shared/inputs/form';
import { purchaseDemandApprover } from '../../../../models/entities/purchaseDemand/purchaseDemandApprover';
import UserBadge from '../../../shared/UserBadge/UserBadge';
import { purchaseDemandItem } from '../../../../models/entities/purchaseDemand/purchaseDemandItem';
import { localSDK as client } from '../../../../sdk';
import { userDropDown } from '../../../../models/entities/user';
import { userDetails } from '../../../../state/ducks/users/users';

type Props = BaseProps & {
    usersInSameCompany: userDropDown[] | undefined;
};

export const renderFieldLabel = (text: string, isMandatory: boolean, tooltipText?: string) => {
    return (
        <LabelContainer>
            <Label>
                {isMandatory && <MandatoryIndication>*</MandatoryIndication>}
                {text}
            </Label>
            {tooltipText && <CZTooltip text={tooltipText}>{QuestionToolTip}</CZTooltip>}
        </LabelContainer>
    );
};
const SinglePurchaseDemandForm = ({
    localizations,
    createPurchaseDemand,
    allowedCompanies,
    openedFromModal,
    usersInSameCompany,
    allowedCompaniesDropDownOptions
}: Props) => {
    const [showLoader, setShowLoader] = useState(false);
    const [defaultApprovers, setDefaultApprovers] = useState<string[] | undefined>();

    const setInitialValues = () => {
        const initValues = {
            companyId: '',
            companyName: '',
            requestedGoodsReady: null,
            isAsap: false,
            approvers: [] as string[],
            items: [] as purchaseDemandItem[]
        };
        return initValues;
    };

    const validate = (values: any) => {
        const errors: any = {};
        if (values.items) validateItems(values.items, errors, localizations);
        if (values.items.length === 0) errors.items = localizations.form.requiredItem;
        if (values.approvers.length === 0 && !defaultApprovers) errors.approvers = localizations.form.requiredFieldMessage;
        if (allowedCompanies.length > 1 && !values.companyId) errors.companyId = localizations.form.requiredFieldMessage;
        return errors;
    };
    return (
        <>
            <Formik
                initialValues={setInitialValues()}
                validate={validate}
                onSubmit={async (values) => {
                    setShowLoader(true);
                    const items = itemsPrepareSubmit(values);
                    const approvers = values.approvers;
                    defaultApprovers?.forEach((item) => {
                        if (!approvers.includes(item)) approvers.push(item);
                    });
                    const purchaseDemandObject = {
                        companyId: values.companyId,
                        companyName: values.companyName,
                        requestedGoodsReady: values.requestedGoodsReady,
                        isAsap: values.isAsap,
                        items: items,
                        approvers: approvers.map((item: string) => {
                            return { userId: item } as purchaseDemandApprover;
                        })
                    } as purchaseDemand;

                    await createPurchaseDemand(purchaseDemandObject)
                        .then((response: string) => {
                            if (response) {
                                if (openedFromModal) openedFromModal.setVisible(false);
                            }
                        })
                        .finally(() => {
                            setShowLoader(false);
                        });
                }}
            >
                {({ values, errors, setFieldValue }) => {
                    return (
                        <>
                            <Form style={{ height: '100%', display: 'flex', flexDirection: 'column' }}>
                                <Container>
                                    <InputsContainer>
                                        {allowedCompanies.length > 1 && (
                                            <FieldContainer style={{ display: 'flex', flexDirection: 'column' }}>
                                                <div>{renderFieldLabel(localizations.form.company, true)}</div>
                                                <FormDropDown
                                                    name="companyId"
                                                    style={{ width: '200px' }}
                                                    placeHolder={localizations.form.company}
                                                    options={allowedCompaniesDropDownOptions}
                                                    onChange={async (value: any) => {
                                                        setFieldValue(
                                                            'companyName',
                                                            allowedCompanies?.find((item) => item.companyId === value)?.companyName
                                                        );
                                                        await client
                                                            .companies()
                                                            .fetchCompanySettingsById(value)
                                                            .then((value) => {
                                                                if (value.purchaseDemandApprovers) setDefaultApprovers(value.purchaseDemandApprovers);
                                                                else setDefaultApprovers(undefined);
                                                            });
                                                    }}
                                                />
                                            </FieldContainer>
                                        )}
                                        <FieldContainer>
                                            <div>{renderFieldLabel(localizations.form.approvers, true)}</div>
                                            {defaultApprovers && defaultApprovers.length > 0 && (
                                                <DefaultApproversContainer>
                                                    <div>{localizations.form.default_approvers}</div>
                                                    <DefaultApprovers>
                                                        {defaultApprovers.map((item) => {
                                                            return <UserBadge flexRow width={25} userId={item} />;
                                                        })}
                                                    </DefaultApprovers>
                                                </DefaultApproversContainer>
                                            )}
                                            <div style={{ width: '200px' }}>
                                                <UserDropDownForm
                                                    name="approvers"
                                                    placeHolder={localizations.form.select_users}
                                                    users={usersInSameCompany as userDetails[]}
                                                    mode="multiple"
                                                    showSearch
                                                />
                                            </div>
                                        </FieldContainer>
                                        <FieldContainer>
                                            <div>{renderFieldLabel(localizations.form.requested_goods_ready, false)}</div>
                                            <div>
                                                <FormDatePicker
                                                    name="requestedGoodsReady"
                                                    style={{ width: '200px' }}
                                                    placeHolder={localizations.form.requested_goods_ready}
                                                    disabled={values.isAsap}
                                                />
                                            </div>
                                            <FormCheckbox style={{ marginTop: 10 }} name="isAsap">
                                                {localizations.form.asap}
                                            </FormCheckbox>
                                        </FieldContainer>
                                    </InputsContainer>
                                    <ItemsContainer>
                                        <Items values={values} setFieldValue={setFieldValue} />
                                        {errors.items && <ErrorText className="err-msg">{`${errors.items}`}</ErrorText>}
                                    </ItemsContainer>
                                </Container>
                                <Buttons>
                                    <Button
                                        width="100px"
                                        height="30px"
                                        type="button"
                                        onClick={() => openedFromModal && openedFromModal.setVisible(false)}
                                    >
                                        <span>{localizations.form.cancel_btn}</span>
                                    </Button>
                                    <Button buttonType="filled" width="100px" height="30px" type="submit">
                                        {showLoader ? (
                                            <Loader width="20px" marginTop="0px" showText={false} />
                                        ) : (
                                            <span>{localizations.form.save_btn}</span>
                                        )}
                                    </Button>
                                </Buttons>
                            </Form>
                        </>
                    );
                }}
            </Formik>
        </>
    );
};

const Buttons = styled.div`
    width: 100%;
    display: flex;
    justify-content: flex-end;

    button {
        margin-right: 20px;
    }
`;
export const FieldContainer = styled.div`
    margin-bottom: 30px;
    margin-right: 20px;
`;

const ItemsContainer = styled.div`
    flex: 1 1 0;
    overflow-y: overlay;
    margin-bottom: 30px;
`;

const Label = styled.label`
    color: ${(props) => props.theme.colors.primaryBlue} !important;
`;

const MandatoryIndication = styled.span`
    color: red;
    margin-right: 7px;
`;

const InputsContainer = styled.div`
    display: flex;
    align-items: flex-start;
`;

const Container = styled.div`
    position: relative;
    width: 100%;
    display: flex;
    flex-direction: column;
    flex: 1;
`;

const LabelContainer = styled.div`
    display: flex;
    margin-bottom: 5px;

    & img {
        margin-top: -2px;
        margin-left: 5px;
    }
`;
export const Flex = styled.div`
    display: flex;
`;
const DefaultApproversContainer = styled.div`
    margin: 10px 0;
`;
const DefaultApprovers = styled.div`
    display: flex;
    > * {
        margin-right: 15px;
    }
`;
export default SinglePurchaseDemandForm;
