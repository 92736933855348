import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { RootState } from '../../../../state/store/store';
import { localizationSelectors } from '../../../../state/ducks/localization/selectors';
import { userSelectors } from '../../../../state/ducks/user/selectors';
import { userSettingDefinition } from '../../../../models/entities/user';
import Loader from '../../../shared/SmallComponents/Loader';
import UserSettingsPage from './UserSettingsPage';

type Props = {
    localization: any;
    userSettingsFromDefinitions: userSettingDefinition[];
    getUserSettingDefinitions: () => Promise<void>;
    showLoader: boolean;
    setUserSettings: (key: string, value: any, id?: number) => Promise<boolean>;
};

const UserSettingsPageConnected = ({ getUserSettingDefinitions, userSettingsFromDefinitions, localization, showLoader, setUserSettings }: Props) => {
    useEffect(() => {
        getUserSettingDefinitions();
    }, [getUserSettingDefinitions]);

    return showLoader ? (
        <Loader />
    ) : (
        <UserSettingsPage userSettingsFromDefinitions={userSettingsFromDefinitions} setUserSettings={setUserSettings} localization={localization} />
    );
};

const mapStateToProps = (state: RootState) => ({
    localization: localizationSelectors.user(state),
    userSettingsFromDefinitions: userSelectors.userSettingsFromDefinitions(state),
    showLoader: state.loading.effects.user.getUserSettingDefinitions
});

const mapDispatchToProps = (dispatch: any) => ({
    getUserSettingDefinitions: () => dispatch.user.getUserSettingDefinitions(),
    setUserSettings: (key: string, value: any, id?: number) => dispatch.user.setUserSettings({ key, value, id })
});

export default connect(mapStateToProps, mapDispatchToProps)(UserSettingsPageConnected);
