import React, { useEffect, useState } from 'react';
import { Modal, Button } from 'antd';
import styled from 'styled-components';
import { integrationKeys } from '../../../../../models/entities/company/company';
import CopyToClipboard from '../../../../shared/CopyToClipboard';

type Props = {
    keys: Required<integrationKeys>;
    visible: boolean;
    localization: any;
    onOk?: () => void;
};
const ApiKeysResponseModal = ({ onOk, keys, visible: modalVisible, localization }: Props) => {
    const [visible, setVisible] = useState(modalVisible);

    useEffect(() => {
        setVisible(modalVisible);
    }, [modalVisible]);

    return (
        <>
            <Modal
                title={<div style={{ color: '#629e31' }}>{localization.title}</div>}
                centered
                visible={visible}
                onOk={() => {
                    onOk?.();
                    setVisible(false);
                }}
                cancelButtonProps={{ hidden: true }}
                width={600}
            >
                <ModalContent>
                    <Info>{localization.info}</Info>
                    <Keys>
                        <KeyContainer>
                            <span style={{ marginRight: 5 }}>
                                {localization.secret_key}: {keys.secretKey}
                            </span>
                            <CopyToClipboard value={keys.secretKey} />
                        </KeyContainer>
                        <KeyContainer>
                            <span style={{ marginRight: 5 }}>
                                {localization.access_key}: {keys.accessKey}
                            </span>
                            <CopyToClipboard value={keys.accessKey} />
                        </KeyContainer>
                    </Keys>
                </ModalContent>
            </Modal>
        </>
    );
};

const ModalContent = styled.div``;

const Info = styled.div`
    margin-bottom: 24px;
`;

const Keys = styled.div`
    font-size: 12px;
    color: #394372;
    background-color: #ffeca1;
    padding: 12px;
    border-radius: 3px;
`;

const KeyContainer = styled.div`
    display: flex;
    align-items: center;
`;
export default ApiKeysResponseModal;
