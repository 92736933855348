import React, { useState } from 'react';
import { settings, bid } from '../../../../models/entities/bid/bid';
import { localizationSelectors } from '../../../../state/ducks/localization/selectors';
import { bidsSelectors } from '../../../../state/ducks/bids/selectors';
import { RootState } from '../../../../state/store/store';
import { connect } from 'react-redux';
import { singleBidSelectors } from '../../../../state/ducks/singleBid/selectors';
import { document } from '../../../../models/entities/common_subentities/document';
import { userSelectors } from '../../../../state/ducks/user/selectors';
import MultipleDocumentFormConnected, { formMode } from '../../commonSubEntities/documents/DocumentForm/MultipleDocumentFormConnected';

type Props = {
    bid?: bid;
    mode: formMode;
    localizations?: any;
    settings?: settings;
    openedFromModal?: {
        setVisible: React.Dispatch<React.SetStateAction<boolean>>;
    };
    confirmation?: { setConfirm: React.Dispatch<React.SetStateAction<boolean>> };
    createMultipleBidDocument: (bidNumber: string, documents: Array<document>) => void;
    showLoader?: boolean;
};

const BidMultipleDocumentFormConnected = ({
    bid,
    localizations,
    mode,
    settings,
    openedFromModal,
    confirmation,
    createMultipleBidDocument,
    showLoader
}: Props) => {
    return (
        <MultipleDocumentFormConnected<bid, settings>
            entity={bid || ({ id: '' } as bid)}
            mode={mode}
            localizations={localizations}
            settings={settings}
            openedFromModal={openedFromModal}
            confirmation={confirmation}
            createMultipleDocument={createMultipleBidDocument}
            showLoader={showLoader}
            optionalFields={{ type: false, comment: false }}
        />
    );
};

const mapStateToProps = (state: RootState) => ({
    localizations: localizationSelectors.bid(state),
    settings: bidsSelectors.settingsByLanguage(state),
    bid: singleBidSelectors.bid(state),
    showLoader: state.loading.effects.bids.createMultipleBidDocument,
    userInfo: userSelectors.userInfo(state)
});

const mapDispatchToProps = (dispatch: any) => ({});

export default connect(mapStateToProps, mapDispatchToProps)(BidMultipleDocumentFormConnected);
