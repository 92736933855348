import BaseController from '../..';
import IShipmentPackages from './IShipmentPackages';

export default class ShipmentPackages extends BaseController implements IShipmentPackages {
    constructor(baseUrl: string) {
        super(baseUrl);
    }

    async deleteShipmentPackage(shipmentId: string, packageId: string): Promise<void> {
        await this.client.delete(`/shipment/packages`, JSON.stringify({ shipmentId, id: packageId }));
    }
}
