import { adsApiAuthorizationPayload } from './../../../models/entities/company/adsApiAuthorizationPayload';
import { companyOverview } from './../../../models/entities/company/companyOverview';
import { createCompanyPayload } from './../../../models/entities/company/createCompanyPayload';
import { company, integrationKeys } from './../../../models/entities/company/company';
import CompaniesAddresses from './addresses/companiesAddresses';
import CompaniesRelations from './relations/companiesRelations';
import BaseController from '..';
import ICompanies from './ICompanies';
import { RcFile } from 'antd/lib/upload';
import { spApiLwaAuthorizationPayload } from '../../../models/entities/company/spApiLwaAuthorizationPayload';

export default class Companies extends BaseController implements ICompanies {
    private companiesAddresses: CompaniesAddresses;
    private companiesRelations: CompaniesRelations;

    constructor(baseUrl: string) {
        super(baseUrl);

        this.companiesAddresses = new CompaniesAddresses(baseUrl);
        this.companiesRelations = new CompaniesRelations(baseUrl);
    }
    async fetchCompanyById(companyId: string): Promise<company> {
        const response = await this.client.get(`/companyAdmin/company/${companyId}`);
        return response;
    }
    async fetchMyCompanies(): Promise<Array<company>> {
        const response = await this.client.get('/companyAdmin/company/myCompanies');
        return response;
    }
    async createCompany(payload: createCompanyPayload): Promise<company> {
        const response = await this.client.post('/companyAdmin/company', JSON.stringify(payload));
        return response;
    }
    async fetchCompanySettingsById(companyId: string): Promise<any> {
        const response = await this.client.get(`/company/companySettings/${companyId}`);
        return response;
    }

    async subdomainExist(): Promise<boolean> {
        const response = await this.client.get(`/company/subdomainExist`);
        return response;
    }

    async updateCompanyOverview(id: string, payload: companyOverview): Promise<company> {
        const response = await this.client.put(`/companyAdmin/company/overview`, JSON.stringify({ companyId: id, data: payload }));
        return response;
    }

    async updateCompanyLogo(companyId: string, file: RcFile): Promise<string | null> {
        this.client.setBodyType('FORM-DATA');
        const formData = new FormData();
        formData.append('file', file);
        if (companyId) formData.append('companyId', companyId);
        const response = await this.client.post('/companyAdmin/company/uploadLogo', formData);
        return response;
    }

    async createIntegrationApiKeys(companyId: string): Promise<Required<integrationKeys>> {
        const response = await this.client.post(`/companyAdmin/company/createIntegrationApiKeys`, JSON.stringify({ companyId }));
        return response;
    }

    async generateRelationToken(): Promise<company> {
        const response = await this.client.get(`/companyAdmin/company/relationToken`);
        return response;
    }

    async getLwaState(companyId: string): Promise<string> {
        const state = await this.client.post(`/companyAdmin/company/getLwaState`, JSON.stringify({ companyId }));
        return state;
    }

    async spApiLwaAuthorization(payload: spApiLwaAuthorizationPayload): Promise<boolean> {
        const response = await this.client.post(
            `/companyAdmin/company/spApiLwaAuthorization`,
            JSON.stringify({ ...payload, state: encodeURIComponent(payload.state) })
        );
        return response;
    }

    async adsApiAuthorization(payload: adsApiAuthorizationPayload): Promise<boolean> {
        const response = await this.client.post(`/companyAdmin/company/adsApiAuthorization`, JSON.stringify(payload));
        return response;
    }

    addresses() {
        return this.companiesAddresses;
    }

    relations() {
        return this.companiesRelations;
    }
}
