import React, { useState } from 'react';
import { styled } from '../../../shared/Theme/theme';
import { settings, shipment, shipmentOverview } from '../../../../models/entities/shipment/shipment';
import ShipmentStation from '../../../shared/Grid/specialColumns/ShipmentStation';
import Tag from '../../../shared/Tags/Tag';
import CustomModal, { ModalTriggerProps } from '../../../shared/Modal/Modal';
import UserBadge from '../../../shared/UserBadge/UserBadge';
import RoutesTable from './RoutesTable';
import moment from 'moment';
import ShipmentTypeWithTooltip from '../../../shared/SmallComponents/ShipmentTypeWithTooltip';
import EditIcon from '../../../../static/icons/edit.svg';
import Button from '../../../shared/SmallComponents/Button';
import { Form } from 'formik-antd';
import { Formik } from 'formik';
import Loader from '../../../shared/SmallComponents/Loader';
import { connect } from 'react-redux';
import { dispatch, RootState } from '../../../../state/store/store';
import { shipmentOverviewSchema } from '../../../../validationSchemas/shipmentOverviewForm';
import { renderShipmentDetailsGrid } from './overviewUtils';
import ReferencesItems from './References/ShipmentReferencesItems';
import { isEqual, isNumber } from 'lodash';
import { Link } from 'react-router-dom';
import CZTooltip from '../../../shared/CZTooltip/CZTooltip';
import { featureFlagsModel } from '../../../../models/entities/businessPartner/settings';
import shipmentItem from '../../../../models/entities/shipment/shipmentItem';
import { PagesRoutes } from '../../../../routing/PagesRoutes';
import { userSelectors } from '../../../../state/ducks/user/selectors';
import SingleValueFormModal from '../../../shared/SingleValueFormModal/SingleValueFormModal';
import { businessPartnersSelectors } from '../../../../state/ducks/businessPartner/selectors';
import { contact } from '../../../../models/entities/businessPartner/contact';
import { Modal } from 'antd';
import { settings as businessPartnerSettings } from '../../../../models/entities/businessPartner/businessPartner';
import { getShipmentPdfFile } from '../ShipmentPdfDocument';
import ShipmentTitleWithTooltip from '../../../shared/SmallComponents/ShipmentTitleWithTooltip';

type Props = {
    shipment: shipment;
    tags?: Array<string>;
    onTagRemoveClicked: (tagName: string) => void;
    updateShipmentOverview: (id: string, shipmentOverview: shipmentOverview) => Promise<void>;
    updateTransportationPaid: (id: string, transportationPaid: boolean) => Promise<void>;
    createShipmentItems: (shipmentId: string, items: shipmentItem[]) => Promise<void>;
    getBpContactsById: (id: string) => contact[];
    getCargoOwnerLogoById: (id: string) => string;
    sendShipmentPaidEmail: (contactsEmails: string[], shipmentCzNumber: string, shipmentPdfFile: File) => Promise<void>;
    showLoader?: boolean;
    localization: any;
    settings: settings;
    businessPartnerSettings: businessPartnerSettings;
    printMode?: boolean;
    isFreelancerOrForwarder?: boolean;
    featureFlags?: featureFlagsModel;
    isAllowedShipment?: boolean;
    isForwarder?: boolean;
    isAdmin: boolean;
};

const ShipmentOverview = ({
    shipment,
    tags,
    onTagRemoveClicked,
    updateShipmentOverview,
    updateTransportationPaid,
    createShipmentItems,
    getBpContactsById,
    getCargoOwnerLogoById,
    sendShipmentPaidEmail,
    businessPartnerSettings,
    showLoader,
    localization,
    settings,
    printMode,
    isFreelancerOrForwarder,
    featureFlags,
    isAllowedShipment,
    isForwarder,
    isAdmin
}: Props) => {
    const [detailsEdit, setDetailsEdit] = useState(false);

    const [transportationPaidBpContactsModalOpened, setTransportationPaidBpContactsModalOpened] = useState<boolean>(false);

    const showEditTotals: boolean = !shipment.packages || !shipment.packages?.length;

    let initialFormValues: any = {
        goodsValueCurrency: shipment.goodsValueCurrency || 'USD',
        transportationPriceCurrency: shipment.transportationPriceCurrency || 'USD',
        buyingRateCurrency: shipment.buyingRateCurrency || 'USD',
        sellingRateCurrency: shipment.sellingRateCurrency || 'USD',
        volumeUnit: shipment.volumeUnit || 'CBM',
        weightUnit: shipment.weightUnit || 'KG',
        master: shipment.master,
        customsFile: shipment.customsFile,
        customsResponsible: shipment.customsResponsible,
        operator: shipment.operator,
        incoterms: shipment.incoterms,
        goodsReady: shipment.goodsReady,
        etd: shipment.etd,
        atd: shipment.atd,
        eta: shipment.eta,
        ata: shipment.ata,
        pickUp: shipment.pickUp,
        carrierCode: shipment.carrierCode,
        vesselName: shipment.vesselName,
        voyageNumber: shipment.voyageNumber,
        commodityType: shipment.commodityType,
        specialInstructions: shipment.specialInstructions,
        quantity: shipment.quantity,
        volume: shipment.volume,
        weight: shipment.weight,
        containerNumber: shipment.containerNumber,
        goodsValue: shipment.goodsValue,
        transportationPrice: shipment.transportationPrice,
        buyingRate: shipment.buyingRate,
        sellingRate: shipment.sellingRate,
        deliveryNote: shipment.deliveryNote,
        totalCartons: shipment.totalCartons
    };

    if (featureFlags?.AMAZON) {
        initialFormValues.amazonAppointmentDate = shipment.amazonAppointmentDate;
    }

    const submit = async (values: any) => {
        let packages = shipment.packages;
        const volume = values.volume;
        const volumeUnit = values.volumeUnit;
        const weight = values.weight;
        const weightUnit = values.weightUnit;
        const quantity = values.quantity;
        const containerNumber = values.containerNumber;

        const payload = {
            master: values.master,
            house: values.house,
            customsFile: values.customsFile,
            customsResponsible: values.customsResponsible,
            commodity: values.commodity,
            commodityType: values.commodityType,
            specialInstructions: values.specialInstructions,
            carrierCode: values.carrierCode,
            operator: values.operator,
            incoterms: values.incoterms,
            etd: values.etd,
            eta: values.eta,
            atd: values.atd,
            ata: values.ata,
            vesselName: values.vesselName,
            goodsReady: values.goodsReady,
            pickUp: values.pickUp,
            voyageNumber: values.voyageNumber,
            goodsValue: isNumber(Number(values.goodsValue)) ? Number(values.goodsValue) : undefined,
            goodsValueCurrency: values.goodsValue ? values.goodsValueCurrency : undefined,
            transportationPrice: isFreelancerOrForwarder
                ? isNumber(Number(values.sellingRate))
                    ? Number(values.sellingRate)
                    : null
                : isNumber(Number(values.transportationPrice))
                ? Number(values.transportationPrice)
                : null,
            transportationPriceCurrency: values.transportationPrice ? values.transportationPriceCurrency : undefined,
            buyingRate: isNumber(Number(values.buyingRate)) ? Number(values.buyingRate) : null,
            buyingRateCurrency: values.buyingRateCurrency ? values.buyingRateCurrency : null,
            sellingRate: isNumber(Number(values.sellingRate)) ? Number(values.sellingRate) : null,
            sellingRateCurrency: values.sellingRateCurrency ? values.sellingRateCurrency : null,
            containerNumber,
            weight,
            weightUnit: !!weight ? weightUnit : undefined,
            volume,
            volumeUnit: !!volume ? volumeUnit : undefined,
            deliveryNote: values.deliveryNote,
            totalCartons: values.totalCartons,
            packages,
            quantity: !!quantity ? quantity : null
        } as shipmentOverview;
        if (featureFlags?.AMAZON) {
            payload.amazonAppointmentDate = values.amazonAppointmentDate;
        }
        await updateShipmentOverview(shipment.id, payload);
        setDetailsEdit(false);
    };

    const forwarderContacts = getBpContactsById(shipment?.forwarderId || '');
    const transportationPaidCallback = async (paid: boolean) => {
        if (paid && forwarderContacts?.length) {
            Modal.confirm({
                zIndex: 1300,
                content: <div>{localization.paid_mail_modal.dialog_text}</div>,
                onOk() {
                    setTransportationPaidBpContactsModalOpened(true);
                },
                onCancel() {}
            });
        }
    };

    return (
        <OverviewWrapper>
            <OverviewHeaderWrapper>
                <SectionWrapper>
                    <ShipmentNumberWrapper>
                        <ShipmentTypeWithTooltip typeCode={shipment.typeCode} subTypeCode={shipment.subTypeCode} iconWidth={34} />

                        <ShipmentNumber id="shipment-number">
                            <span>{localization.shipment}</span> #{shipment.CargoZoneNumber}
                        </ShipmentNumber>
                    </ShipmentNumberWrapper>
                    <ReferencesSection>
                        <SingleRefWrapper>
                            <RefText>{localization.client_ref}</RefText> #
                            <ReferencesItems
                                shipmentId={shipment.id}
                                references={shipment.references || []}
                                type="CLIENT"
                                printMode={printMode}
                                isForwarder={isForwarder}
                            />
                        </SingleRefWrapper>

                        <SingleRefWrapper>
                            <>
                                <RefText>{localization.forwarder_ref}</RefText> #
                                <ReferencesItems
                                    shipmentId={shipment.id}
                                    references={shipment.references || []}
                                    type="FORWARDER"
                                    printMode={printMode}
                                />
                            </>
                        </SingleRefWrapper>
                        {shipment.typeCode === 'EXPRESS' && (
                            <SingleRefWrapper>
                                <RefText>
                                    {localization.tracking_number}:
                                    {!shipment.externalTackingUrl ? (
                                        shipment.forwarderUniqueId
                                    ) : (
                                        <CZTooltip text={localization.visit_site}>
                                            <ExternalTrackedLink to={{ pathname: shipment.externalTackingUrl }} target="_blank">
                                                {shipment.forwarderUniqueId}
                                                <span className="material-icons">link</span>
                                            </ExternalTrackedLink>
                                        </CZTooltip>
                                    )}
                                </RefText>
                            </SingleRefWrapper>
                        )}
                        {shipment.relatedShipments && !!shipment.relatedShipments?.length && (
                            <SingleRefWrapper>
                                <RefText>{localization.related_shipments}</RefText>
                                <RelatedShipments>
                                    {shipment.relatedShipments.map((item, index) => {
                                        return [
                                            <Link style={{}} to={{ pathname: PagesRoutes.Shipments + '/' + item.id }}>
                                                {item.CargoZoneNumber}
                                            </Link>,
                                            index !== shipment.relatedShipments!.length - 1 && (
                                                <span key={index} style={{ display: 'flex', alignItems: 'center', margin: '0 5px' }}>
                                                    |
                                                </span>
                                            )
                                        ];
                                    })}
                                </RelatedShipments>
                            </SingleRefWrapper>
                        )}
                    </ReferencesSection>
                    <TagsWrapper>
                        {tags?.map((tagText, index) => (
                            <SingleTagWrapper key={`tag_${index}`}>
                                <Tag text={tagText} onClose={onTagRemoveClicked} />
                            </SingleTagWrapper>
                        ))}
                    </TagsWrapper>
                </SectionWrapper>
                <SectionsSeparator />
                <SectionWrapper>
                    <CustomModal
                        title={{ text: localization.routes.modal_title }}
                        Trigger={(props: ModalTriggerProps) => (
                            <RouteRowWrapper onClick={props.onClick}>
                                <ShipmentStation country={shipment.originCountry} port={shipment.originStation} flagSize={'24px'} />
                                <RightArrowIcon />
                                <ShipmentStation country={shipment.destinationCountry} port={shipment.destinationStation} flagSize={'24px'} />
                            </RouteRowWrapper>
                        )}
                        Content={() => <RoutesTable routes={shipment.routes} localization={localization} />}
                        width="700px"
                    />
                    <RowWrapper>
                        <span>
                            {localization.last_event}{' '}
                            <span style={{ fontWeight: 'bolder' }}>
                                {settings.eventTypes?.find((type: any) => type.code === shipment.lastEvent)?.name || shipment.lastEvent || '-'}
                            </span>
                        </span>
                    </RowWrapper>
                    <RowWrapper>
                        {(() => {
                            const ata = shipment.ata || shipment.events?.find((item) => item.eventCode === 'ARRIVAL')?.eventDate;
                            return ata ? (
                                <span style={{ fontWeight: 'bolder', color: '#238823' }}>
                                    {localization.ata}
                                    {': '}
                                    <span>{moment(ata).isValid() ? moment(ata).format('DD/MM/YYYY') : '-'}</span>{' '}
                                </span>
                            ) : (
                                <span style={{ fontWeight: 'bolder', color: '#F2A805' }}>
                                    {shipment.typeCode === 'EXPRESS' ? localization.estimate_delivery : localization.eta}
                                    {': '}
                                    <span>{moment(shipment.eta).isValid() ? moment(shipment.eta).format('DD/MM/YYYY') : '-'}</span>{' '}
                                </span>
                            );
                        })()}
                    </RowWrapper>
                </SectionWrapper>

                <SectionsSeparator />

                {shipment.forwarderLogoUrl || shipment.companyLogoUrl ? (
                    <>
                        <SectionWrapper>
                            <Wrapper>
                                {shipment.typeCode === 'CUSTOMS' ? localization.broker : localization.forwarder}:{' '}
                                <div style={{ fontWeight: 'bolder', fontSize: '12px' }}>{shipment.forwarderName}</div>
                            </Wrapper>
                            {shipment.forwarderLogoUrl && <ImgLogo src={shipment.forwarderLogoUrl} alt={shipment.forwarderName} width={80} />}
                        </SectionWrapper>
                        <SectionsSeparator />
                        <SectionWrapper>
                            <Wrapper>
                                <ShipmentTitleWithTooltip
                                    title={localization.company}
                                    content={shipment.companyName}
                                    tooltipText={getBpContactsById(shipment.cargoOwnerId ? shipment.cargoOwnerId : '')}
                                    imgSrc={
                                        isForwarder
                                            ? getCargoOwnerLogoById(shipment.cargoOwnerId ? shipment.cargoOwnerId : '')
                                            : shipment.companyLogoUrl
                                    }
                                    imgAlt={isForwarder ? shipment.cargoOwnerName : shipment.companyName}
                                ></ShipmentTitleWithTooltip>
                            </Wrapper>
                        </SectionWrapper>
                    </>
                ) : (
                    <SectionWrapper>
                        <Wrapper>
                            <DetailWrapper>
                                <div>{shipment.typeCode === 'CUSTOMS' ? localization.broker : localization.forwarder}:</div>
                                <div style={{ fontWeight: 'bolder', fontSize: '20px' }}>{shipment.forwarderName}</div>
                            </DetailWrapper>

                            <DetailWrapper style={{ fontWeight: 'bolder', fontSize: '20px' }}>
                                <ShipmentTitleWithTooltip
                                    title={localization.company}
                                    content={shipment.companyName}
                                    tooltipText={(() => {
                                        const cargoOwnerId = shipment.cargoOwnerId || '';
                                        const contacts = getBpContactsById(cargoOwnerId);
                                        return contacts.toString() !== '' ? contacts : 'No contacts yet';
                                    })()}
                                ></ShipmentTitleWithTooltip>
                            </DetailWrapper>
                        </Wrapper>
                    </SectionWrapper>
                )}

                <SectionsSeparator />

                <SectionWrapper>
                    <RowWrapper>
                        <UserBadge width={40} userId={shipment.userId} userName={shipment.userName} />
                    </RowWrapper>
                </SectionWrapper>
            </OverviewHeaderWrapper>
            <DetailsWrapperContainer>
                {!featureFlags?.UNDER_FORWARDER && !printMode && (
                    <DetailsEditButton onClick={() => setDetailsEdit(!detailsEdit)}>
                        <Button margin="5px" buttonType={detailsEdit ? 'filled' : 'hollow'}>
                            <img src={EditIcon} alt={localization.edit} />
                        </Button>
                    </DetailsEditButton>
                )}
                <Formik initialValues={initialFormValues} validationSchema={shipmentOverviewSchema()} onSubmit={submit} enableReinitialize>
                    {({ values, setFieldValue }) => {
                        return (
                            <Form>
                                <DetailsWrapper>
                                    {renderShipmentDetailsGrid(
                                        values,
                                        detailsEdit,
                                        shipment,
                                        localization,
                                        isAdmin,
                                        updateTransportationPaid,
                                        createShipmentItems,
                                        transportationPaidCallback,
                                        isFreelancerOrForwarder,
                                        featureFlags,
                                        isAllowedShipment
                                    )}
                                    {detailsEdit && (
                                        <div>
                                            <Button buttonType="filled" type="submit">
                                                {showLoader ? (
                                                    <Loader width="20px" marginTop="0px" showText={false} />
                                                ) : (
                                                    <span>{localization.save_btn}</span>
                                                )}
                                            </Button>
                                        </div>
                                    )}
                                    <SingleValueFormModal<string[]>
                                        title={localization.paid_mail_modal.title}
                                        initialValue={[]}
                                        valueType="dropdown"
                                        dropDownMode="multiple"
                                        onSubmit={async (value: string[]) => {
                                            const file = await getShipmentPdfFile({
                                                shipment,
                                                localization,
                                                settings: businessPartnerSettings,
                                                featureFlags,
                                                shipmentSettings: settings
                                            });

                                            await sendShipmentPaidEmail(value, shipment.CargoZoneNumber, file);
                                        }}
                                        dropDownOptions={
                                            forwarderContacts?.map((item) => {
                                                return { value: '' + item.email, text: item.name };
                                            }) || []
                                        }
                                        placeholder={localization.paid_mail_modal.contacts_placeholder}
                                        btnText={localization.paid_mail_modal.send_btn}
                                        onCancelCallback={() => setTransportationPaidBpContactsModalOpened(false)}
                                        isOpen={transportationPaidBpContactsModalOpened}
                                    />
                                </DetailsWrapper>
                            </Form>
                        );
                    }}
                </Formik>
            </DetailsWrapperContainer>
        </OverviewWrapper>
    );
};

const OverviewWrapper = styled.div`
    display: flex;
    flex-direction: column;
    width: 100%;
`;

const OverviewHeaderWrapper = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    width: 100%;
    border-bottom: solid 1px #e0e0e0;
    padding: 24px 18px 18px 48px;
`;

const ShipmentNumberWrapper = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
    width: 100%;
`;

const SectionWrapper = styled.div``;

const RowWrapper = styled.div`
    display: flex;
    white-space: nowrap;
    flex-direction: row;
    padding: 6px 0;
    color: #394372;
`;
const Wrapper = styled.div`
    flex-direction: row;
    padding: 6px 0;
    color: #394372;
`;
const RouteRowWrapper = styled(RowWrapper)`
    padding: 8px;
    cursor: pointer;
    :hover {
        background: #e0e0e0;
    }
`;
const SectionsSeparator = styled.div`
    width: 1px;
    border-right: solid 1px #e0e0e0;
    margin: 0 18px;
`;

const ShipmentNumber = styled.div`
    color: ${(props) => props.theme.colors.pageTitle};
    font-size: ${(props) => props.theme.fontSizes.pageTitle};
    font-weight: bold;
    height: 24px;
    margin-left: 10px;
    white-space: nowrap;

    & span {
        @media screen and (max-width: 768px) {
            display: none;
        }
    }
`;
const ReferencesSection = styled.div`
    color: ${(props) => props.theme.colors.pageTitle};
    font-size: ${(props) => props.theme.fontSizes.subTitlte};
    margin-top: 6px;
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;

    & ::-webkit-scrollbar-thumb {
        background-color: rgba(0, 0, 0, 0.3) !important;
        border-radius: 5.5px;
    }
`;

const SingleRefWrapper = styled.div`
    display: flex;
    align-items: center;
    margin-right: 12px;
    flex-wrap: wrap;
    padding: 3px 0;
`;

const RefText = styled.span`
    font-weight: bold;
    margin-right: 4px;
`;

const TagsWrapper = styled.div`
    display: none;
    margin-top: 13px;
    overflow-x: auto;
    width: 100%;

    & :-webkit-scrollbar {
        opacity: 0;
    }
`;

const SingleTagWrapper = styled.div`
    margin-right: 10px;
    display: flex;
    white-space: nowrap;
`;

const RightArrowIcon = styled.div`
    width: 20px;
    height: 20px;
    margin: 10px 15px;

    background: #d1d1d1;
    height: 1px;
    width: 15px;
    position: relative;

    &:before,
    &:after {
        content: '';
        background: #d1d1d1;
        position: absolute;
        height: 1px;
        width: 8px;
    }

    &:before {
        right: -4px;
        bottom: -2px;
        transform: rotate(-30deg);
    }

    &:after {
        right: -4px;
        top: -2px;
        transform: rotate(30deg);
    }
`;

const ImgLogo = styled.img`
    max-width: 200px;
`;

const DetailWrapper = styled.div`
    max-width: 200px;
    &:first-child {
        margin-bottom: 10px;
    }
`;
const DetailsWrapperContainer = styled.div`
    position: relative;
`;
const DetailsEditButton = styled.div`
    display: inline-block;
    position: absolute;
    right: 0;
`;
const DetailsWrapper = styled.div`
    display: flex;
    flex-direction: column;
    padding: 24px 52px 18px 48px;
`;
const RelatedShipments = styled.div`
    display: flex;
    align-items: center;
    max-width: 250px;
    overflow-y: overlay;
`;
export const DetailsRow = styled.div`
    display: flex;
    flex-direction: row;
    max-width: 1000px;
`;

export const DetailBox = styled.div`
    flex: 1;
    margin: 10px 5px;
    max-width: 200px;
    white-space: nowrap;
    word-break: break-word;
`;

export const DetailTitle = styled.div`
    color: #008ac9;
    width: 100%;
    overflow: hidden;
`;

export const ExternalTrackedLink = styled(Link)`
    display: inline-flex;
    align-items: center;
`;
const mapStateToProps = (state: RootState) => ({
    showLoader: state.loading.effects.shipments.updateShipmentOverview,
    isForwarder: userSelectors.userInfo(state).companyType === 'FORWARDER',
    isAdmin: !!userSelectors.getUserClaims(state)('admin').view,
    businessPartnerSettings: businessPartnersSelectors.settingsByLanguage(state),
    getBpContactsById: (id: string) => businessPartnersSelectors.getBpContactsById(state)(id),
    getCargoOwnerLogoById: (id: string) => businessPartnersSelectors.getCargoOwnerLogoById(state)(id)
});

const mapDispatchToProps = (dispatch: any) => ({
    updateShipmentOverview: (id: string, shipmentOverview: shipmentOverview) => dispatch.shipments.updateShipmentOverview({ id, shipmentOverview }),
    updateTransportationPaid: (id: string, transportationPaid: boolean) =>
        dispatch.shipments.updateShipmentTransportationPaid({ shipmentId: id, transportationPaid }),
    createShipmentItems: (shipmentId: string, items: shipmentItem[]) => dispatch.shipments.createShipmentItems({ shipmentId, items }),
    sendShipmentPaidEmail: (contactsEmails: string[], shipmentCzNumber: string, shipmentPdfFile: File) =>
        dispatch.shipments.sendShipmentPaidEmail({
            contactsEmails,
            shipmentCzNumber,
            shipmentPdfFile
        })
});

export default connect(mapStateToProps, mapDispatchToProps)(ShipmentOverview);
