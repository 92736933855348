import React from 'react';
import { Route } from 'react-router-dom';
import PublicLayout from '../components/shared/Layout/public/Layout';

const PublicRoute = ({ component, ...rest }: any) => {
    const Component = component;
    return (
        <>
            <Route
                {...rest}
                render={(props) => {
                    return (
                        <PublicLayout>
                            <Component {...props} />
                        </PublicLayout>
                    );
                }}
            />
        </>
    );
};
export default PublicRoute;
