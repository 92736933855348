import React from 'react';
import { businessPartner } from '../../../../../../models/entities/businessPartner/businessPartner';
import CustomModal, { ModalTriggerProps } from '../../../../../shared/Modal/Modal';
import { styled } from '../../../../../shared/Theme/theme';
import BusinessPartnerFormConnected, { formMode } from '../../../../businessPartners/components/BusinessPartnerForm/BusinessPartnerFormConnected';

type Props = {
    type?: string;
    submitCallback?: (businessPartner: businessPartner) => void;
    modalTitle: string;
    disabled?: boolean;
};
const CreateBusinessPartner = ({ type, submitCallback, modalTitle, disabled }: Props) => {
    if (disabled) {
        return null;
    }
    return (
        <CustomModal
            title={{ text: modalTitle }}
            Trigger={(props: ModalTriggerProps) => (
                <AddButton className="material-icons" onClick={props.onClick}>
                    add
                </AddButton>
            )}
            Content={(setVisible?: React.Dispatch<React.SetStateAction<boolean>>, setConfirm?: React.Dispatch<React.SetStateAction<boolean>>) => {
                return (
                    <BusinessPartnerFormConnected
                        mode={formMode.NEW}
                        type={type}
                        openedFromModal={setVisible ? { setVisible } : undefined}
                        confirmation={setConfirm ? { setConfirm } : undefined}
                        submitCallback={submitCallback}
                    />
                );
            }}
            width="393px"
        />
    );
};
const AddButton = styled.span`
    color: ${(props) => props.theme.colors.primaryBlue};
    cursor: pointer;
    display: flex;
    align-items: center;
`;
export default CreateBusinessPartner;
