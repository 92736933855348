import React, { useEffect } from 'react';
import { RootState } from '../../../state/store/store';
import { connect } from 'react-redux';
import { localizationSelectors } from '../../../state/ducks/localization/selectors';
import { notificationsSelectors } from '../../../state/ducks/notifications/selectors';
import NotificationsPage from './NotificationsPage';
import { notification } from '../../../models/entities/notification/notification';
import { userSelectors } from '../../../state/ducks/user/selectors';
import { drawers } from '../../../state/ducks/header/header';

type Props = {
    groupedNotifications: Array<Array<notification>> | null;
    localizations?: any;
    showLoader: boolean;
    isAllNotificationsRead?: boolean;
    setNotificationRead: (notificationId: string, isRead: boolean) => void;
    setMultipleNotificationsRead: (notificationIds: string[], isRead: boolean) => void;
    setAllNotificationsRead: (isRead: boolean) => void;
    deleteNotification: (notificationId: string) => void;
    deleteMultipleNotifications: (notificationIds: string[]) => void;
    deleteAllNotifications: () => void;
    followShipment: (shipmentId: string) => void;
    unFollowShipment: (shipmentId: string) => void;
    followingShipments?: Array<string>;
    setDrawerEmptyState: (drawer: drawers, isEmpty: boolean) => void;
    setDrawerOpen: (open: drawers, params?: any) => void;
};

const NotificationsPageConnected = ({
    groupedNotifications,
    localizations,
    showLoader,
    isAllNotificationsRead,
    setNotificationRead,
    setMultipleNotificationsRead,
    setAllNotificationsRead,
    deleteNotification,
    deleteMultipleNotifications,
    deleteAllNotifications,
    followShipment,
    unFollowShipment,
    followingShipments,
    setDrawerEmptyState,
    setDrawerOpen
}: Props) => {
    return (
        <NotificationsPage
            localization={localizations}
            groupedNotifications={groupedNotifications}
            showLoader={showLoader}
            isAllNotificationsRead={isAllNotificationsRead}
            setNotificationRead={setNotificationRead}
            setMultipleNotificationsRead={setMultipleNotificationsRead}
            setAllNotificationsRead={setAllNotificationsRead}
            deleteNotification={deleteNotification}
            deleteMultipleNotifications={deleteMultipleNotifications}
            deleteAllNotifications={deleteAllNotifications}
            followShipment={followShipment}
            unFollowShipment={unFollowShipment}
            followingShipments={followingShipments}
            setDrawerEmptyState={setDrawerEmptyState}
            setDrawerOpen={setDrawerOpen}
        />
    );
};

const mapStateToProps = (state: RootState) => ({
    groupedNotifications: notificationsSelectors.groupedNotifications(state),
    isAllNotificationsRead: notificationsSelectors.isAllNotificationsRead(state),
    localizations: localizationSelectors.notifications(state),
    showLoader: state.loading.effects.notifications.fetchNotifications,
    followingShipments: userSelectors.followingShipments(state)
});

const mapDispatchToProps = (dispatch: any) => ({
    setNotificationRead: (notificationId: string, isRead: boolean) => dispatch.notifications.setNotificationRead({ notificationId, isRead }),
    setMultipleNotificationsRead: (notificationIds: string[], isRead: boolean) =>
        dispatch.notifications.setMultipleNotificationsRead({ notificationIds, isRead }),
    setAllNotificationsRead: (isRead: boolean) => dispatch.notifications.setAllNotificationsRead(isRead),
    deleteNotification: (notificationId: string) => dispatch.notifications.deleteNotification(notificationId),
    deleteMultipleNotifications: (notificationIds: string[]) => dispatch.notifications.deleteMultipleNotifications(notificationIds),
    deleteAllNotifications: () => dispatch.notifications.deleteAllNotifications(),
    followShipment: async (shipmentId: string) => dispatch.allShipmentsPage.followShipment(shipmentId),
    unFollowShipment: async (shipmentId: string) => dispatch.allShipmentsPage.unFollowShipment(shipmentId),
    setDrawerEmptyState: (drawer: drawers, isEmpty: boolean) => dispatch.header.setDrawerEmptyState({ drawer, isEmpty }),
    setDrawerOpen: (open: drawers, params?: any) => dispatch.header.setDrawerOpenParams({ open, params })
});

export default connect(mapStateToProps, mapDispatchToProps)(NotificationsPageConnected);
