import React, { useEffect, useState } from 'react';
import { styled } from '../Theme/theme';
import { Formik } from 'formik';
import Button from '../SmallComponents/Button';
import { ModalFooter, ModalFormContainer } from '../Modal/Modal';
import Loader from '../SmallComponents/Loader';
import { CurrencyInput, FormDatePicker, FormTextInput, FormCheckbox, FormDropDown } from '../inputs/form';
import FieldLabel from '../SmallComponents/FieldLabel';
import { Form } from 'formik-antd';
import { Option } from '../inputs/base/DropDown';

export type valueType = 'text' | 'date' | 'currency' | 'dropdown' | 'dropdown-multiple' | 'dialog';

export type SingleValueFormProps<T = any> = {
    openedFromModal?: {
        setVisible: React.Dispatch<React.SetStateAction<boolean>>;
    };
    onSubmit: (value: T, checkboxValue?: boolean, valueCurrency?: string) => Promise<void>;
    placeholder?: string;
    showLoader?: boolean;
    initialValue?: T;
    initialCurrencyValue?: string;
    valueType?: valueType;
    dropDownOptions?: Option[];
    dropDownMode?: 'multiple' | 'tags';
    checkbox?: { text: string; initialValue?: boolean };
    dialogContent?: JSX.Element;
    onCancelCallback?: () => void;
    btnText?: string | ((value?: T) => string);
};

function SingleValueForm<T = any>({
    openedFromModal,
    valueType,
    initialValue,
    checkbox,
    dropDownOptions,
    dropDownMode,
    initialCurrencyValue,
    placeholder,
    onSubmit,
    onCancelCallback,
    btnText,
    dialogContent
}: SingleValueFormProps<T>) {
    const [isLoading, setIsLoading] = useState(false);

    const submit = async (values: any) => {
        setIsLoading(true);
        await onSubmit(values.value, values.checkbox, values.valueCurrency);
        if (openedFromModal) openedFromModal.setVisible(false);
        setIsLoading(false);
    };

    const RenderInput = (values: any) => {
        switch (valueType) {
            case 'date':
                return <FormDatePicker name="value" placeHolder={placeholder || 'Value'} />;

            case 'currency':
                return (
                    <CurrencyInput
                        currencyValue={values.valueCurrency}
                        numberInputName="value"
                        numberPlaceholder={placeholder || 'Value'}
                        unitInputName="valueCurrency"
                        numberInputWidth="100%"
                    />
                );

            case 'dropdown':
                return (
                    <FormDropDown
                        name={`value`}
                        mode={dropDownMode}
                        placeHolder={placeholder || 'Value'}
                        style={{ width: '100%', maxWidth: '100%' }}
                        options={dropDownOptions || []}
                        showSearch
                    />
                );

            case 'dialog':
                return <div style={{ width: '200px' }}>{dialogContent}</div>;

            default:
                return <FormTextInput name="value" placeHolder={placeholder || 'Value'} />;
        }
    };
    return (
        <Formik
            initialValues={{
                value: initialValue,
                valueCurrency: initialCurrencyValue || 'USD',
                checkbox: checkbox?.initialValue || false
            }}
            onSubmit={submit}
        >
            {({ values, setFieldValue, handleSubmit }) => {
                return (
                    <Form>
                        <Container>
                            {valueType !== 'dialog' && <FieldLabel>{placeholder || 'Value'}</FieldLabel>}
                            {valueType === 'dialog' ? RenderInput(values) : <FieldContainer>{RenderInput(values)}</FieldContainer>}
                            {checkbox && (
                                <FieldContainer>
                                    <FormCheckbox name="checkbox">{checkbox.text}</FormCheckbox>
                                </FieldContainer>
                            )}
                        </Container>
                        {openedFromModal && (
                            <FormFooter>
                                <Button
                                    minWidth="55px"
                                    onClick={() => {
                                        onCancelCallback?.();
                                        openedFromModal.setVisible(false);
                                    }}
                                >
                                    <span>{'Cancel'}</span>
                                </Button>
                                <Button
                                    minWidth="55px"
                                    buttonType="filled"
                                    type="submit"
                                    onClick={() => !isLoading && handleSubmit()}
                                    disabled={isLoading}
                                >
                                    {isLoading ? (
                                        <Loader width="20px" marginTop="0px" showText={false} />
                                    ) : (
                                        <span>{(typeof btnText === 'string' ? btnText : btnText?.(values.value)) || 'Save'}</span>
                                    )}
                                </Button>
                            </FormFooter>
                        )}
                    </Form>
                );
            }}
        </Formik>
    );
}
const FieldContainer = styled.div`
    margin-bottom: 12px;
`;

const Container = styled(ModalFormContainer)`
    padding: 0;
    padding-bottom: 50px;
`;

const FormFooter = styled(ModalFooter)`
    position: absolute;
    bottom: 0;
    width: 100%;
    left: 0;
    right: 0;
    background: white;
`;

const Flex = styled.div`
    display: flex;
`;

export default SingleValueForm;
