import React, { useState } from 'react';
import { settings, shipment, shipmentSettingsFunc } from '../../../../models/entities/shipment/shipment';
import { localizationSelectors } from '../../../../state/ducks/localization/selectors';
import { shipmentsSelectors } from '../../../../state/ducks/shipments/selectors';
import { RootState } from '../../../../state/store/store';
import { connect } from 'react-redux';
import { singleShipmentSelectors } from '../../../../state/ducks/singleShipment/selectors';
import { document } from '../../../../models/entities/common_subentities/document';
import { userSelectors } from '../../../../state/ducks/user/selectors';
import MultipleDocumentFormConnected, { formMode } from '../../commonSubEntities/documents/DocumentForm/MultipleDocumentFormConnected';

type Props = {
    shipment?: shipment;
    mode: formMode;
    localizations?: any;
    settings: shipmentSettingsFunc;
    openedFromModal?: {
        setVisible: React.Dispatch<React.SetStateAction<boolean>>;
    };
    confirmation?: { setConfirm: React.Dispatch<React.SetStateAction<boolean>> };
    createMultipleShipmentDocument: (shipmentNumber: string, documents: Array<document>) => void;
    showLoader?: boolean;
};

const ShipmentMultipleDocumentFormConnected = ({
    shipment,
    localizations,
    mode,
    settings,
    openedFromModal,
    confirmation,
    createMultipleShipmentDocument,
    showLoader
}: Props) => {
    return (
        <MultipleDocumentFormConnected<shipment, settings>
            entity={shipment}
            mode={mode}
            localizations={localizations}
            settings={settings(shipment)}
            openedFromModal={openedFromModal}
            confirmation={confirmation}
            createMultipleDocument={createMultipleShipmentDocument}
            showLoader={showLoader}
            typeMode="tags"
        />
    );
};

const mapStateToProps = (state: RootState) => ({
    localizations: localizationSelectors.singleShipmentPage(state),
    settings: shipmentsSelectors.settingsByLanguage(state),
    shipment: singleShipmentSelectors.shipment(state),
    showLoader: state.loading.effects.shipments.createMultipleShipmentDocument,
    userInfo: userSelectors.userInfo(state)
});

const mapDispatchToProps = (dispatch: any) => ({
    createMultipleShipmentDocument: (shipmentNumber: string, documents: Array<document>) =>
        dispatch.shipments.createMultipleShipmentDocument({ shipmentNumber, documents })
});

export default connect(mapStateToProps, mapDispatchToProps)(ShipmentMultipleDocumentFormConnected);
