import React, { useState } from 'react';
import { styled } from '../../../shared/Theme/theme';
import { event } from '../../../../models/entities/common_subentities/event';
import Loader from '../../../shared/SmallComponents/Loader';
import { ColumnType, specialColumnType } from '../../../shared/Grid/types/Column';
import Grid from '../../../shared/Grid/Grid';
import Button from '../../../shared/SmallComponents/Button';
import CustomModal, { ModalTriggerProps } from '../../../shared/Modal/Modal';
import clock from '../../../../static/icons/clock.svg';
import { settings } from '../../../../models/entities/shipment/shipment';
import { formMode } from './EventForm/EventFormConnected';
import claimsType from '../../../../models/entities/claimsType';
import CZTooltip from '../../../shared/CZTooltip/CZTooltip';
import { featureFlagsModel } from '../../../../models/entities/businessPartner/settings';

type Props<settingsType> = {
    events?: Array<event>;
    localization: any;
    settings?: settingsType;
    menuType: specialColumnType;
    EventFormConnected: any;
    printMode?: boolean;
    userShipmentEventsClaims?: claimsType;
    featureFlags?: featureFlagsModel;
};

function Events<settingsType extends settings>({
    events,
    localization,
    menuType,
    EventFormConnected,
    settings,
    printMode,
    userShipmentEventsClaims,
    featureFlags
}: Props<settingsType>) {
    const mockColumns: Array<ColumnType<event>> = [
        {
            field: 'id',
            sortable: false,
            isDisplayed: false
        },
        {
            field: 'eventCode',
            sortable: false,
            isDisplayed: false
        },
        {
            field: 'name',
            width: '150px',
            sortable: false,
            isDisplayed: true,
            bindedFields: ['eventCode'],
            columnType: 'event_name'
        },
        {
            field: 'description',
            sortable: false,
            isDisplayed: true,
            bindedFields: ['eventCode', 'description'],
            columnType: 'event_description'
        },
        {
            field: 'userName',
            width: '120px',
            sortable: false,
            isDisplayed: true
        },
        {
            field: 'eventDate',
            width: '140px',
            sortable: true,
            isDisplayed: true,
            textAlign: 'center',
            columnType: 'date-time'
        },
        {
            field: 'createdAt',
            width: '140px',
            sortable: false,
            isDisplayed: false,
            textAlign: 'center',
            columnType: 'date'
        },
        {
            field: 'comment',
            sortable: false,
            isDisplayed: true
        },
        {
            field: 'displayOnlyForCompanyId',
            width: '40px',
            sortable: false,
            isDisplayed: true,
            renderer: (cellValue: any, bindedData?: Array<any> | null) => {
                if (!cellValue || typeof cellValue !== 'string' || cellValue === '-') return <div></div>;
                return (
                    <CZTooltip text={localization.events.grid.columns.displayOnlyForCompanyIdTooltip}>
                        <span className="material-icons-sharp">security</span>
                    </CZTooltip>
                );
            }
        }
    ];
    const [modalVisible, setVisible] = useState(false);
    const [event, setEvent] = useState({} as event);
    return (
        <EventsWrapper>
            {events ? (
                <>
                    {!featureFlags?.UNDER_FORWARDER && !printMode && (!userShipmentEventsClaims || userShipmentEventsClaims?.add_event) ? (
                        <CustomModal
                            title={{ icon: clock, text: localization.events.modal.add_title }}
                            Trigger={(props: ModalTriggerProps) => (
                                <ButtonContainer>
                                    <Button onClick={props.onClick} width="auto" buttonType="hollow" padding="4px 12px">
                                        {localization.events.add_event}
                                    </Button>
                                </ButtonContainer>
                            )}
                            Content={(
                                setVisible?: React.Dispatch<React.SetStateAction<boolean>>,
                                setConfirm?: React.Dispatch<React.SetStateAction<boolean>>
                            ) => {
                                return (
                                    <EventFormConnected
                                        mode={formMode.NEW}
                                        openedFromModal={setVisible ? { setVisible } : undefined}
                                        confirmation={setConfirm ? { setConfirm } : undefined}
                                    />
                                );
                            }}
                            width="393px"
                        />
                    ) : (
                        <h2>Events</h2>
                    )}
                    {events.length > 0 ? (
                        <GridWrapper>
                            <Grid<event>
                                columns={mockColumns}
                                data={events}
                                localization={localization.events.grid}
                                onSettingsClick={() => console.log('settings')}
                                onRowMoreOptionsClick={(row) => {
                                    setEvent(row);
                                    setVisible(true);
                                }}
                                menuType={menuType}
                                settings={settings}
                                printMode={printMode}
                                userIdKey="userId"
                            />

                            {modalVisible && (
                                <CustomModal
                                    title={{ icon: clock, text: localization.events.modal.edit_title }}
                                    isOpen={true}
                                    Content={(
                                        setModalVisible?: React.Dispatch<React.SetStateAction<boolean>>,
                                        setConfirm?: React.Dispatch<React.SetStateAction<boolean>>
                                    ) => {
                                        return (
                                            <EventFormConnected
                                                mode={formMode.EDIT}
                                                event={event}
                                                openedFromModal={{ setVisible }}
                                                confirmation={setConfirm ? { setConfirm } : undefined}
                                            />
                                        );
                                    }}
                                    onCancelCallback={() => {
                                        setVisible(false);
                                        setEvent({} as event);
                                    }}
                                    width="393px"
                                />
                            )}
                        </GridWrapper>
                    ) : (
                        <EmaptyStateText>{localization.events.empty_state}</EmaptyStateText>
                    )}
                </>
            ) : (
                <Loader />
            )}
        </EventsWrapper>
    );
}

const GridWrapper = styled.div`
    flex: 0 0 100%;
    max-width: 100%;
    width: 100%;
    box-sizing: border-box;
    overflow-y: overlay;
    height: 100%;
    width: 100%;
`;

const EventsWrapper = styled.div`
    display: flex;
    flex-direction: column;
    width: 100%;
`;

const EmaptyStateText = styled.span`
    color: ${(props) => props.theme.colors.emptyStateText};
`;
const ButtonContainer = styled.div`
    display: flex;
    justify-content: flex-end;
    padding-top: 8px;
`;
export default Events;
