import { Input } from 'antd';
import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import { containersGridView } from '../../../../models/entities/containers/containersGridView';
import { TextInput } from '../../../shared/inputs/base';
import Button from '../../../shared/SmallComponents/Button';
import Loader from '../../../shared/SmallComponents/Loader';
import ExportMenu from './ExportMenu';

type Props = {
    containers: containersGridView;
    fetchContainersGridView: () => void;
    filterContainersByTerm: (term: string) => void;
    textFilterValue: string;
    localization?: any;
};
const ContainersPageHeader = ({ fetchContainersGridView, filterContainersByTerm, textFilterValue, localization, containers }: Props) => {
    const [filterValue, setFilterValue] = useState(textFilterValue);
    const [isTypingFilter, setIsTypingFilter] = useState(false);

    useEffect(() => {
        setIsTypingFilter(true);
        const timeout = setTimeout(() => {
            filterContainersByTerm(filterValue);
            setIsTypingFilter(false);
        }, 500);

        return () => clearTimeout(timeout);
    }, [filterValue]);

    return (
        <Wrapper>
            <Title>{localization.header.title}</Title>
            <ActionsWrapper></ActionsWrapper>
            <ExportMenu localization={localization} containers={containers} />
            <Button
                width="auto"
                height="auto"
                buttonType="hollow"
                padding="0 8px"
                margin="4px 4px 4px 8px"
                borderRadiusMobile="50%"
                minHeightMobile="32px"
                paddingMobile="0 4px"
                widthMobile="auto"
                onClick={() => {
                    fetchContainersGridView();
                }}
            >
                <span className="material-icons">refresh</span>
            </Button>
            <Input
                style={{
                    height: '30px',
                    margin: '4px',
                    width: '250px'
                }}
                allowClear={true}
                onChange={(e) => setFilterValue(e.currentTarget.value)}
                placeholder={localization.header.text_filter.placeholder}
                value={filterValue}
                suffix={isTypingFilter && <Loader wrapperWidth="auto" showText={false} marginTop="0" width="15px" />}
            />
        </Wrapper>
    );
};

const Wrapper = styled.div`
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
`;

const Title = styled.div`
    font-weight: bold;
    font-size: 24px;
    color: ${(props) => props.theme.colors.pageTitle};
`;

const ActionsWrapper = styled.div`
    font-weight: bold;
    font-size: 24px;
    width: 100%;
    flex: 1;
    display: flex;
    align-items: center;
    justify-content: center;
`;

export default ContainersPageHeader;
