import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import PurchaseDemandsPage from './PurchaseDemandsPage';
import { purchaseDemand, settings } from '../../../models/entities/purchaseDemand/purchaseDemand';
import { RootState } from '../../../state/store/store';
import { exportTypes, gridSortState, paginationType } from '../../../state/ducks/allPurchaseDemandsPage/allPurchaseDemandsPage';
import { allPurchaseDemandsPageSelectors } from '../../../state/ducks/allPurchaseDemandsPage/selectors';
import { purchaseDemandsSelectors } from '../../../state/ducks/purchaseDemands/selectors';
import { localizationSelectors } from '../../../state/ducks/localization/selectors';
import { ColumnType } from '../../shared/Grid/types/Column';
import ExceptionPage from '../ExceptionPage/ExceptionPage';
import { userSelectors } from '../../../state/ducks/user/selectors';
import { userSettings } from '../../../models/entities/user';
import { filterColsByUserSettings } from '../../shared/Grid/util';
import { claimsDomainsType } from '../../../userClaims';
import claimsType from '../../../models/entities/claimsType';

type Props = {
    purchaseDemands: Array<purchaseDemand>;
    purchaseDemandsPage: Array<purchaseDemand>;
    activePurchaseDemandsCount: number;
    fetchPurchaseDemands: () => void;
    fetchGridColumns: () => void;
    gridColumns: Array<ColumnType<purchaseDemand>>;
    currentGridSort: gridSortState | null;
    currentGridFilter: string | null;
    setGridSort: (sortData: gridSortState) => void;
    setGridFilter: (text: string) => void;
    exportDataTable: (type: exportTypes) => void;
    setCurrentPage: (page: number) => void;
    setRowsPerPage: (rowsPerPage: number) => void;
    filteredPDsCount: number;
    pagination: paginationType;
    localizations: any;
    showLoader?: boolean;
    settings?: settings;
    purchaseDemandsGridSettings?: userSettings;
    pdUserClaims: claimsType;
};

const PurchaseDemandsPageConnected = ({
    purchaseDemands,
    purchaseDemandsPage,
    activePurchaseDemandsCount,
    fetchPurchaseDemands,
    fetchGridColumns,
    gridColumns,
    currentGridSort,
    currentGridFilter,
    setGridSort,
    setGridFilter,
    exportDataTable,
    localizations,
    showLoader,
    settings,
    purchaseDemandsGridSettings,
    setCurrentPage,
    setRowsPerPage,
    filteredPDsCount,
    pagination,
    pdUserClaims
}: Props) => {
    useEffect(() => {
        fetchPurchaseDemands();
        fetchGridColumns();
    }, [fetchPurchaseDemands, fetchGridColumns]);

    const DEFAULT_SORT_DIRECTION = 'asc';
    const SECOND_SORT_DIRECTION = 'desc';

    const onGridColumnClick = (newColumn: keyof purchaseDemand) => {
        if (currentGridSort) {
            const { direction, column } = currentGridSort;
            if (column === newColumn)
                if (direction === DEFAULT_SORT_DIRECTION)
                    setGridSort({
                        column: newColumn,
                        direction: SECOND_SORT_DIRECTION
                    });
                else
                    setGridSort({
                        column: newColumn,
                        direction: DEFAULT_SORT_DIRECTION
                    });
            else {
                setGridSort({
                    column: newColumn,
                    direction: DEFAULT_SORT_DIRECTION
                });
            }
        } else {
            setGridSort({
                column: newColumn,
                direction: DEFAULT_SORT_DIRECTION
            });
        }
    };

    const onGridFilter = (text: string) => {
        setGridFilter(text);
        setCurrentPage(0);
    };

    const userSettingKey = 'PurchaseDemandGrid';
    return (
        <PurchaseDemandsPage
            purchaseDemands={purchaseDemands}
            purchaseDemandsPage={purchaseDemandsPage}
            activePurchaseDemandsCount={activePurchaseDemandsCount}
            gridColumns={filterColsByUserSettings(gridColumns, purchaseDemandsGridSettings)}
            onGridColumnClick={onGridColumnClick}
            onFilter={onGridFilter}
            filterText={currentGridFilter ?? ''}
            gridSortDirection={currentGridSort?.direction}
            gridSortedBy={currentGridSort?.column}
            localization={localizations}
            exportDataTable={exportDataTable}
            showLoader={showLoader}
            settings={settings}
            userSettingKey={userSettingKey}
            setCurrentPage={setCurrentPage}
            setRowsPerPage={setRowsPerPage}
            filteredPDsCount={filteredPDsCount}
            pagination={pagination}
            fetchPurchaseDemands={fetchPurchaseDemands}
            pdUserClaims={pdUserClaims}
        />
    );
};

const mapStateToProps = (state: RootState) => ({
    purchaseDemands: allPurchaseDemandsPageSelectors.gridData(state),
    purchaseDemandsPage: allPurchaseDemandsPageSelectors.currentGridDataPage(state),
    activePurchaseDemandsCount: allPurchaseDemandsPageSelectors.activePurchaseDemandsCount(state),
    gridColumns: allPurchaseDemandsPageSelectors.gridColumns(state),
    currentGridSort: allPurchaseDemandsPageSelectors.gridSort(state),
    currentGridFilter: allPurchaseDemandsPageSelectors.gridFilter(state),
    localizations: localizationSelectors.allPurchaseDemandsPage(state),
    showLoader: !!state.loading.effects.allPurchaseDemandsPage.fetchGridColumns || !!state.loading.effects.purchaseDemands.fetchPurchaseDemands,
    settings: purchaseDemandsSelectors.settingsByLanguage(state),
    filteredPDsCount: allPurchaseDemandsPageSelectors.filteredPDsCount(state),
    pagination: allPurchaseDemandsPageSelectors.pagination(state),
    purchaseDemandsGridSettings: userSelectors.purchaseDemandsGridSettings(state),
    pdUserClaims: userSelectors.getUserClaims(state)('pd')
});

const mapDispatchToProps = (dispatch: any) => ({
    fetchPurchaseDemands: () => dispatch.purchaseDemands.fetchPurchaseDemands(),
    fetchGridColumns: () => dispatch.allPurchaseDemandsPage.fetchGridColumns(),
    setGridFilter: (text: string) => dispatch.allPurchaseDemandsPage.setGridFilter(text),
    setGridSort: (sortData: gridSortState) => dispatch.allPurchaseDemandsPage.setGridSort(sortData),
    setCurrentPage: (page: number) => dispatch.allPurchaseDemandsPage.setCurrentPage(page),
    setRowsPerPage: (rowsPerPage: number) => dispatch.allPurchaseDemandsPage.setRowsPerPage(rowsPerPage),
    exportDataTable: (type: exportTypes) => dispatch.allPurchaseDemandsPage.exportDataTable(type)
});

export default connect(mapStateToProps, mapDispatchToProps)(PurchaseDemandsPageConnected);
