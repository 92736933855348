import { ModelConfig } from '@rematch/core';
import { RcFile } from 'antd/lib/upload';
import { address } from '../../../models/entities/company/address';
import { company } from '../../../models/entities/company/company';
import { companyOverview } from '../../../models/entities/company/companyOverview';
import { createCompanyPayload } from '../../../models/entities/company/createCompanyPayload';
import { localSDK as client } from '../../../sdk';
import crypto from 'crypto';
import { spApiLwaAuthorizationPayload } from '../../../models/entities/company/spApiLwaAuthorizationPayload';
import { adsApiAuthorizationPayload } from '../../../models/entities/company/adsApiAuthorizationPayload';

export type CompaniesStateType = {
    companies: company[];
};

export const companies: ModelConfig<CompaniesStateType> = {
    state: {
        companies: []
    },
    reducers: {
        setSingleCompany(state: CompaniesStateType, newCompany: company): CompaniesStateType {
            const newCompanies = [...state.companies];
            const companyIndex = newCompanies.findIndex((company) => company.id === newCompany.id);
            if (companyIndex !== -1) {
                newCompanies[companyIndex] = newCompany;
            } else {
                newCompanies.push(newCompany);
            }
            return { ...state, companies: newCompanies };
        },
        setCompanies(state: CompaniesStateType, companies: company[]): CompaniesStateType {
            return { ...state, companies };
        },
        addCompany(state: CompaniesStateType, company: company): CompaniesStateType {
            const newCompanies = [...state.companies, company];
            return { ...state, companies: newCompanies };
        },

        setCompanyAddresses(state: CompaniesStateType, companyId: string, companyAddresses: Array<address>): CompaniesStateType {
            if (!state.companies.length) return state;
            const newCompanies = [...state.companies];
            const companyIndex = newCompanies.findIndex((company) => company.id === companyId);
            if (companyIndex !== -1) {
                newCompanies[companyIndex] = { ...newCompanies[companyIndex], addresses: companyAddresses };
            }
            return { ...state, companies: newCompanies };
        },
        addCompanyAddress(state: CompaniesStateType, companyId: string, address: address): CompaniesStateType {
            if (!state.companies.length) return state;
            const newCompanies = [...state.companies];
            const companyIndex = newCompanies.findIndex((company) => company.id === companyId);
            if (companyIndex !== -1) {
                let addresses = [address];
                const oldAddresses = newCompanies[companyIndex].addresses;
                if (oldAddresses && oldAddresses.length > 0) addresses = [...oldAddresses, ...addresses];
                newCompanies[companyIndex] = { ...newCompanies[companyIndex], addresses };
            }
            return { ...state, companies: newCompanies };
        },
        editCompanyAddress(state: CompaniesStateType, companyId: string, address: address): CompaniesStateType {
            if (!state.companies.length) return state;
            const newCompanies = [...state.companies];
            const companyIndex = newCompanies.findIndex((company) => company.id === companyId);
            if (companyIndex !== -1) {
                const addresses = newCompanies[companyIndex].addresses?.map((item) => {
                    if (item.id === address.id) return { ...address };
                    else return { ...item };
                });
                newCompanies[companyIndex] = { ...newCompanies[companyIndex], addresses };
            }
            return { ...state, companies: newCompanies };
        },
        removeCompanyAddress(state: CompaniesStateType, companyId: string, addressId: number): CompaniesStateType {
            if (!state.companies.length) return state;
            const newCompanies = [...state.companies];
            const companyIndex = newCompanies.findIndex((company) => company.id === companyId);
            if (companyIndex !== -1) {
                const oldAddresses = newCompanies[companyIndex].addresses?.filter((item) => item.id !== addressId);
                newCompanies[companyIndex] = { ...newCompanies[companyIndex], addresses: oldAddresses };
            }
            return { ...state, companies: newCompanies };
        },
        setCompanyValue(state: CompaniesStateType, companyId: string, [key, value]: [keyof company, any]): CompaniesStateType {
            if (!state.companies.length) return state;
            const newCompanies = [...state.companies];
            const companyIndex = newCompanies.findIndex((company) => company.id === companyId);
            if (companyIndex !== -1) {
                const newCompany = { ...newCompanies[companyIndex], [key]: value };
                newCompanies[companyIndex] = newCompany;
            }
            return { ...state, companies: newCompanies };
        }
    },
    effects: (dispatch: any) => ({
        async fetchCompanyById(companyId: string) {
            const company = await client.companies().fetchCompanyById(companyId);
            dispatch.companies.setSingleCompany(company);

            return company;
        },
        async updateCompanyOverview({ id, companyOverview }: { id: string; companyOverview: companyOverview }) {
            const company = await client.companies().updateCompanyOverview(id, companyOverview);
            dispatch.companies.setSingleCompany(company);

            return true;
        },
        async fetchMyCompanies() {
            const companies = await client.companies().fetchMyCompanies();
            dispatch.companies.setCompanies(companies);
        },
        async createCompany(payload: createCompanyPayload) {
            const company = await client.companies().createCompany(payload);
            dispatch.companies.addCompany(company);
        },
        async createCompanyAddress({ companyId, address }: { companyId: string; address: address }) {
            const createdAddress = await client.companies().addresses().createCompanyAddress(companyId, address);
            dispatch.companies.addCompanyAddress(companyId, createdAddress);
        },
        async updateCompanyAddress({ companyId, address }: { companyId: string; address: address }) {
            const updatedAddress = await client.companies().addresses().updateCompanyAddress(companyId, address);
            dispatch.companies.editCompanyAddress(companyId, updatedAddress);
        },
        async deleteCompanyAddress({ companyId, addressId }: { companyId: string; addressId: number }) {
            await client.companies().addresses().deleteCompanyAddress(companyId, addressId);
            dispatch.companies.removeCompanyAddress(companyId, addressId);
        },
        async updateCompanyLogo({ companyId, file }: { companyId: string; file: RcFile }) {
            const logoUrl = await client.companies().updateCompanyLogo(companyId, file);
            if (logoUrl) dispatch.companies.setCompanyValue(companyId, ['logoUrl', logoUrl]);
        },
        async createIntegrationApiKeys(companyId: string) {
            const { secretKey, accessKey } = await client.companies().createIntegrationApiKeys(companyId);
            dispatch.companies.setCompanyValue(companyId, ['accessKey', accessKey]);
            return { secretKey, accessKey };
        },
        async generateRelationToken() {
            const company = await client.companies().generateRelationToken();
            if (!company) return;

            const { id, relationToken } = company;
            if (!(id && relationToken)) return;

            dispatch.companies.setCompanyValue(id, ['relationToken', relationToken]);
        },
        async getLwaState(companyId: string) {
            const state = await client.companies().getLwaState(companyId);
            return state;
        },
        async spApiLwaAuthorization(payload: spApiLwaAuthorizationPayload) {
            const response = await client.companies().spApiLwaAuthorization(payload);
            return response;
        },
        async adsApiAuthorization(payload: adsApiAuthorizationPayload) {
            const response = await client.companies().adsApiAuthorization(payload);
            return response;
        }
    })
};
