import { Formik } from 'formik';
import { Form } from 'formik-antd';
import React, { useState } from 'react';
import styled from 'styled-components';
import FormFieldLabel from '../../../../shared/Form/FormFieldLabel';
import { FormDropDown, FormTextInput } from '../../../../shared/inputs/form';
import Button from '../../../../shared/SmallComponents/Button';
import { createCompanySchema } from '../../../../../validationSchemas/admin/createCompanyForm';
import { createCompanyPayload } from '../../../../../models/entities/company/createCompanyPayload';
import Loader from '../../../../shared/SmallComponents/Loader';
import { SketchPicker } from 'react-color';
import marketplaces from '../../../../../static/data/marketplaces.json';
import CountryOption from '../../../../shared/SmallComponents/CountryOption';
import { Option } from '../../../../shared/inputs/base/DropDown';
import { featureFlagsModel } from '../../../../../models/entities/businessPartner/settings';

type marketplace = {
    country: string;
    countryCode: string;
    marketplaceId: string;
};

type Props = {
    createCompany: (payload: createCompanyPayload) => Promise<void>;
    localization: any;
    featureFlags?: featureFlagsModel;
};
const AddCompanyForm = ({ createCompany, localization, featureFlags }: Props) => {
    const [submitLoading, setLoading] = useState(false);
    const [showColorPicker, setShowColorPicker] = useState(false);

    const fieldsLocalization = localization.fields;
    const initialValues: createCompanyPayload = {
        name: '',
        type: '',
        vatNumber: '',
        address: '',
        phoneNumber: '',
        color: '#394372'
    };

    const submitCompany = async (values: createCompanyPayload, { resetForm }: any) => {
        setLoading(true);
        await createCompany(values);
        resetForm();
        setLoading(false);
    };

    const marketplacesDropDownOptions = Object.entries(marketplaces).reduce((acc, [region, regionMarketplaces]) => {
        (acc as any)[region] = regionMarketplaces.map((marketplace) => ({
            text: marketplace.country,
            value: marketplace.marketplaceId
        }));

        return acc;
    }, {});

    const flatMarketplaces = Object.entries(marketplaces)
        .reduce((acc: marketplace[], [region, regionMarketplaces]: [string, marketplace[]]) => [...acc, ...regionMarketplaces], [])
        .flat();

    return (
        <Wrapper>
            <Title>{localization.title}</Title>
            <FormWrapper>
                <Formik initialValues={initialValues} validationSchema={createCompanySchema(localization)} onSubmit={submitCompany}>
                    {(formik) => {
                        const { errors, touched, isValid, isSubmitting, values, setFieldValue } = formik;
                        return (
                            <Form>
                                <FieldContainer>
                                    <FormFieldLabel text={fieldsLocalization.name} isMandatory={true} />
                                    <FormTextInput name="name" placeHolder={fieldsLocalization.name} />
                                </FieldContainer>
                                <FieldContainer>
                                    <FormFieldLabel text={fieldsLocalization.type} isMandatory={true} />
                                    <FormTextInput name="type" placeHolder={fieldsLocalization.type} />
                                </FieldContainer>
                                {!!featureFlags?.AMAZON && (
                                    <FieldContainer>
                                        <FormFieldLabel text={fieldsLocalization.marketplaces} />
                                        <FormDropDown
                                            name="marketplaceIds"
                                            style={{ width: '100%' }}
                                            placeHolder={fieldsLocalization.marketplaces}
                                            options={marketplacesDropDownOptions}
                                            showSearch={true}
                                            mode="multiple"
                                            optionRenderer={(option: Option) => {
                                                const marketplace = flatMarketplaces.find((m) => m.marketplaceId === option.value);
                                                return <CountryOption country={marketplace?.country || ''} code={marketplace?.countryCode || ''} />;
                                            }}
                                        />
                                    </FieldContainer>
                                )}
                                <FieldContainer>
                                    <FormFieldLabel text={fieldsLocalization.vat_number} />
                                    <FormTextInput name="vatNumber" placeHolder={fieldsLocalization.vat_number} />
                                </FieldContainer>
                                <FieldContainer>
                                    <FormFieldLabel text={fieldsLocalization.address} />
                                    <FormTextInput name="address" placeHolder={fieldsLocalization.address} />
                                </FieldContainer>
                                <FieldContainer>
                                    <FormFieldLabel text={fieldsLocalization.phone_num} />
                                    <FormTextInput name="phoneNumber" placeHolder={fieldsLocalization.phone_num} />
                                </FieldContainer>
                                <FieldContainer>
                                    <FormFieldLabel text={'Color'} />
                                    <ColorButtonWrapper
                                        onClick={() => {
                                            setShowColorPicker(true);
                                        }}
                                    >
                                        <ColorButton color={values.color} />
                                    </ColorButtonWrapper>
                                    {showColorPicker && (
                                        <>
                                            <PickerWrapper>
                                                <SketchPicker
                                                    color={values.color}
                                                    onChange={(color) => {
                                                        setFieldValue('color', color.hex);
                                                    }}
                                                />
                                            </PickerWrapper>
                                            <PickerBack
                                                onClick={() => {
                                                    setShowColorPicker(false);
                                                }}
                                            />
                                        </>
                                    )}
                                </FieldContainer>
                                <Button margin="30px 0 0 0" width="150px" buttonType="filled" type="submit" disabled={!isValid}>
                                    {submitLoading ? <Loader width="20px" marginTop="0px" showText={false} /> : localization.submit_btn}
                                </Button>
                            </Form>
                        );
                    }}
                </Formik>
            </FormWrapper>
        </Wrapper>
    );
};

const Wrapper = styled.div`
    display: flex;
    flex-direction: column;
    padding: 10px;
`;

const Title = styled.div`
    font-size: 18px;
`;

const FormWrapper = styled.div`
    width: 50%;
    min-width: 200px;
`;

const ColorButtonWrapper = styled.div`
    padding: 5px;
    background: #fff;
    border-radius: 1px;
    box-shadow: 0 0 0 1px rgba(0, 0, 0, 0.1);
    display: inline-block;
    cursor: pointer;
`;
const ColorButton = styled.div<{ color?: string }>`
    background: ${(props) => props.color ?? 'black'};
    width: 36px;
    height: 14px;
    borderradius: 2px;
`;

const PickerWrapper = styled.div`
    position: absolute;
    z-index: 2;
`;

const PickerBack = styled.div`
    z-index: 1;
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
`;

const FieldContainer = styled.div`
    margin-top: 12px;
`;

const LabelContainer = styled.div`
    display: flex;
    margin-bottom: 5px;
`;
export default AddCompanyForm;
