import React from 'react';
import { GridCellParams, GridColDef, GridColumnHeaderParams, GridValueFormatterParams } from '@material-ui/data-grid';
import { containersGridView } from '../../../../models/entities/containers/containersGridView';
import MuiDataGrid from '../../../shared/MuiDataGrid/MuiDataGrid';
import moment from 'moment';
import styled from 'styled-components';
import StyledLink from '../../../shared/SmallComponents/StyledLink';
import { PagesRoutes } from '../../../../routing/PagesRoutes';
import { isNumber } from 'lodash';
import { DropDown } from '../../../shared/inputs/base';
import { settings } from '../../../../models/entities/shipment/shipment';
import renderStateOption from '../../../shared/SmallComponents/StateOption';
import CZTooltip, { QuestionToolTip } from '../../../shared/CZTooltip/CZTooltip';

type Props = {
    updateContainerState: (shipmentId: string, containerId: string, state: string) => Promise<void>;
    containersGridData: containersGridView;
    showLoader: boolean;
    settings?: settings;
    localization?: any;
};

const ContainersGrid = ({ updateContainerState, containersGridData, settings, showLoader, localization }: Props) => {
    const geridFieldsLocalization = localization.grid.fields;
    const { state: stateTooltip } = localization.grid.tooltips;

    const renderTooltipHeader = (tooltip: string) => (params: GridColumnHeaderParams) =>
        (
            <TooltipHeaderWrapper>
                {geridFieldsLocalization[params.field] || params.field} <CZTooltip text={tooltip}>{QuestionToolTip}</CZTooltip>
            </TooltipHeaderWrapper>
        );
    const renderDaysCells = (warningMin: number, warningMax: number, critical: number) => (params: GridCellParams) => {
        const { value } = params;
        if (!value || !isNumber(params.value)) return <div>-</div>;

        const days = Number(value);

        if (days < 0) return <div>-</div>;
        if (days >= critical) return <NumberBadge color="#d3445b">{days}</NumberBadge>;
        if (days >= warningMin && days <= warningMax) return <NumberBadge color="#ed9b00">{days}</NumberBadge>;

        return <div>{days}</div>;
    };
    const columns: GridColDef[] = [
        { field: 'containerNumber', headerName: geridFieldsLocalization.containerNumber, width: 170 },
        {
            field: 'shipmentCargoZoneNumber',
            headerName: geridFieldsLocalization.shipmentCargoZoneNumber,
            flex: 1,
            renderCell: (params: GridCellParams) => (
                <StyledLink to={`${PagesRoutes.Shipments}/${params.getValue(params.id, 'shipmentId')}`}>#{params.value}</StyledLink>
            )
        },
        { field: 'containerType', headerName: geridFieldsLocalization.containerType, flex: 1 },
        {
            field: 'lastEvent',
            headerName: geridFieldsLocalization.lastEvent,
            flex: 1,
            valueFormatter: (params: GridValueFormatterParams) =>
                settings?.eventTypes?.find((type: any) => type.code === params.value)?.name || params.value || '-'
        },
        {
            field: 'arrivalDate',
            headerName: geridFieldsLocalization.arrivalDate,
            type: 'date',
            valueFormatter: (params: GridValueFormatterParams) =>
                moment(params.value as string).isValid() ? moment(params.value as string).format('DD/MM/YY') : '-',
            flex: 1
        },
        {
            field: 'storageDays',
            headerName: geridFieldsLocalization.storageDays,
            flex: 1,
            renderCell: renderDaysCells(2, 3, 4)
        },
        {
            field: 'returnDays',
            headerName: geridFieldsLocalization.returnDays,
            flex: 1,
            renderCell: renderDaysCells(5, 7, 8)
        },
        {
            field: 'estimateCosts',
            headerName: geridFieldsLocalization.estimateCosts,
            flex: 1
        },
        {
            renderHeader: renderTooltipHeader(stateTooltip),
            field: 'state',
            headerName: geridFieldsLocalization.state,
            width: 200,
            renderCell: (params: GridCellParams) => {
                const containerStates = settings?.containerState;
                const currentState = containerStates?.find((c) => c.code === params.value);
                return (
                    <DropDown
                        value={params.value as string}
                        onChange={(value: string) => {
                            updateContainerState(
                                params.getValue(params.id, 'shipmentId') as string,
                                params.getValue(params.id, 'id') as string,
                                value
                            );
                        }}
                        disabled={currentState && containerStates && currentState.code === containerStates[containerStates.length - 1].code}
                        optionRenderer={renderStateOption}
                        options={
                            containerStates
                                ?.filter((c) => (currentState ? c.displayOrder >= currentState.displayOrder : true))
                                ?.sort((a, b) => a.displayOrder - b.displayOrder)
                                .map((item) => ({
                                    text: item.name,
                                    value: item.code,
                                    color: item.color
                                })) || []
                        }
                    />
                );
            }
        }
    ];

    return (
        <Wrapper>
            <MuiDataGrid rows={containersGridData} columns={columns} loading={!!showLoader} />
        </Wrapper>
    );
};

const Wrapper = styled.div`
    width: 100%;
    height: 100%;

    .MuiDataGrid-root {
        border: none;
    }
    .MuiDataGrid-root .MuiDataGrid-window {
        overflow-y: overlay !important;
    }
`;

const TooltipHeaderWrapper = styled.div`
    overflow: hidden;
    font-weight: 500;
    white-space: nowrap;
    width: 100%;
    display: flex;
    align-items: center;
    text-overflow: ellipsis;
`;

const NumberBadge = styled.div<{ color: string }>`
    color: #fff;
    background: ${(props) => props.color};
    border-radius: 20px;
    height: 22px;
    font-size: 13px;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 24px;
}
`;

export default ContainersGrid;
