import { party } from '../../../../models/entities/common_subentities/party';
type stateType = { [key: string]: any };
function setParties(state: stateType, entityId: string, entityType: keyof stateType, parties: Array<party>) {
    const newArr = [...state[entityType]];
    const index = newArr.findIndex((item) => item.id === entityId);
    if (index !== -1) {
        newArr[index].parties = parties;
    }
    return newArr;
}
function addParty(state: stateType, entityId: string, entityType: keyof stateType, party: party) {
    const newArr = [...state[entityType]];
    const index = newArr.findIndex((item) => item.id === entityId);
    if (index !== -1) {
        let parties = [party];
        const oldParties = newArr[index].parties;
        if (oldParties && oldParties.length > 0) parties = [...oldParties, ...parties];
        newArr[index].parties = parties;
    }
    return newArr;
}
function editParty(state: stateType, entityId: string, entityType: keyof stateType, party: party) {
    const newArr = [...state[entityType]];
    const index = newArr.findIndex((item) => item.id === entityId);
    if (index !== -1) {
        const parties = newArr[index].parties?.map((item: party) => {
            if (item.id === party.id) return { ...party };
            else return { ...item };
        });
        newArr[index].parties = parties;
    }
    return newArr;
}
function removeParty(state: stateType, entityId: string, entityType: keyof stateType, partyId: number) {
    const newArr = [...state[entityType]];
    const index = newArr.findIndex((item) => item.id === entityId);
    if (index !== -1) {
        const oldParties = newArr[index].parties?.filter((item: party) => item.id !== partyId);
        newArr[index].parties = oldParties;
    }
    return newArr;
}

export { setParties, addParty, editParty, removeParty };
