import React from 'react';
import { PagesRoutes } from '../../../../routing/PagesRoutes';
import { styled } from '../../../shared/Theme/theme';
import Button from '../../../shared/SmallComponents/Button';
import EditIcon from '../../../../static/icons/edit.svg';
import BackButton from '../../../shared/SmallComponents/BackButton';
import { Link } from 'react-router-dom';
import { bid, settings } from '../../../../models/entities/bid/bid';
import Overview from './sections/Overview';
import Tabs from './sections/Tabs';
import { shipment } from '../../../../models/entities/shipment/shipment';
import OverviewHeader from './sections/OverviewHeader';
import { CircleComp } from '../../../shared/SmallComponents/StateOption';
import Loader from '../../../shared/SmallComponents/Loader';

type Props = {
    bid: bid;
    localization?: any;
    shipment: shipment;
    startBid: (bidId: string) => void;
    startBidLoader: boolean;
    settings?: settings;
};

const SingleBidPage = ({ bid, localization, shipment, startBid, startBidLoader, settings }: Props) => {
    const stateColorMap: { [key: string]: string } = {
        NEW: '#b2b2b2',
        IN_PROGRESS: '#007acc',
        CLOSE: '#23ab34'
    };
    return (
        <PageWrapper>
            <LeftSide>
                <SectionWrapper>
                    <div>
                        <BackButton style={{ marginLeft: '-30px' }} toPath={PagesRoutes.Bids} text={localization.backLink} />
                    </div>
                    {bid.state === 'NEW' && (
                        <HeaderRightSide>
                            <Link
                                to={{
                                    pathname: `${PagesRoutes.EditBid}/${bid.id}`
                                }}
                            >
                                <Button margin="0 12px 0 0">
                                    <img src={EditIcon} alt={localization.edit} />
                                    <span>{localization.edit}</span>
                                </Button>
                            </Link>
                            <Button buttonType="filled" margin="0 12px 0 0" onClick={() => startBid(bid.id)}>
                                {startBidLoader ? <Loader width="20px" marginTop="0px" showText={false} /> : <span>{localization.startBid}</span>}
                            </Button>
                        </HeaderRightSide>
                    )}
                </SectionWrapper>
                <StateWrapper>
                    <CircleComp text={bid.state} color={stateColorMap[bid.state]} />
                </StateWrapper>
                <OverviewHeader shipment={shipment} bid={bid} localization={localization} />
                <OverviewSectionWrapper>
                    <Overview bid={bid} localization={localization.grid.columns} />
                </OverviewSectionWrapper>
                <SectionWrapper>
                    <Tabs bid={bid} localization={localization} settings={settings} />
                </SectionWrapper>
            </LeftSide>
        </PageWrapper>
    );
};

const PageWrapper = styled.div`
    display: flex;
    flex-direction: row;

    @media screen and (max-width: 768px) {
        height: calc(100vh - 65px - 40px);
    }
`;

const LeftSide = styled.div`
    width: 100%;
    border-right: 1px solid ${(props) => props.theme.colors.grayDefaultBorder};

    @media screen and (max-width: 1200px) {
        width: 100%;
        max-width: 100%;
    }
`;
const StateWrapper = styled.div`
    display: flex;
    align-items: center;
    padding-left: 48px;
    margin-top: 10px;
    height: 23px;
`;
const OverviewSectionWrapper = styled.div`
    display: flex;
    flex-direction: row;
    border-bottom: solid 1px ${(props) => props.theme.colors.grayDefaultBorder};
    justify-content: space-between;
`;

const HeaderRightSide = styled.div`
    display: flex;
    height: 30px;
    margin: 30px 0 0 0;
`;

export const SectionWrapper = styled.div`
    display: flex;
    flex-direction: row;
    padding: 24px 18px 18px 48px;
    border-bottom: solid 1px ${(props) => props.theme.colors.grayDefaultBorder};
    justify-content: space-between;
`;
export default SingleBidPage;
