import { purchaseOrderItem } from '../../../../../../models/entities/purchaseOrder/purchaseOrderItem';

export const emptyObject: purchaseOrderItem = {
    id: '',
    purchaseOrderId: '',
    productId: '',
    productSku: '',
    quantity: 1,
    code: '',
    name: '',
    price: 0,
    currency: 'USD',
    length: 0,
    width: 0,
    height: 0,
    dimensionsUnit: 'CM',
    weight: 0,
    weightUnit: 'KG',
    shipmentId: undefined,
    readyDate: new Date()
};
