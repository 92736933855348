import React from 'react';
import { discussion } from '../../../../../../models/entities/common_subentities/discussion';
import { userSelectors } from '../../../../../../state/ducks/user/selectors';
import { RootState } from '../../../../../../state/store/store';
import { connect } from 'react-redux';
import { userInfo } from '../../../../../../models/entities/user';
import DiscussionsSection from '../../../../commonSubEntities/discussions/DiscussionsSection';
import { singleBusinessPartnersSelectors } from '../../../../../../state/ducks/singleBusinessPartner/selectors';
import { usersSelectors } from '../../../../../../state/ducks/users/selectors';
import { userDetails } from '../../../../../../state/ducks/users/users';
import { shipmentsSelectors } from '../../../../../../state/ducks/shipments/selectors';
import { shipment } from '../../../../../../models/entities/shipment/shipment';
import { ColumnType } from '../../../../../shared/Grid/types/Column';
import { allShipmentsPageSelectors } from '../../../../../../state/ducks/allShipmentsPage/selectors';

type Props = {
    businessPartnerId?: string;
    businessPartnerNumber?: string;
    discussions?: Array<discussion>;
    localization: any;
    currentUser: userInfo;
    createBusinessPartnerDiscussion: (businessPartnerId: string, discussion: discussion, businessPartnerNumber?: string) => void;
    updateBusinessPartnerDiscussion: (businessPartnerId: string, discussion: discussion, businessPartnerNumber?: string) => void;
    deleteBusinessPartnerDiscussion: (businessPartnerId: string, discussionId: string) => void;
    showLoader: boolean;
    gridColumns: Array<ColumnType<shipment>>;
    getUserById: (userId: string) => userDetails | undefined;
    getShipmentById: (id: string) => shipment | undefined;
};

const BusinessPartnerDiscussionsSection = ({
    businessPartnerId,
    businessPartnerNumber,
    discussions,
    localization,
    currentUser,
    createBusinessPartnerDiscussion,
    updateBusinessPartnerDiscussion,
    deleteBusinessPartnerDiscussion,
    getUserById,
    showLoader,
    getShipmentById,
    gridColumns
}: Props) => {
    return (
        <DiscussionsSection
            entityId={businessPartnerId}
            entityName={businessPartnerNumber}
            discussions={discussions}
            localization={localization}
            currentUser={currentUser}
            createDiscussion={createBusinessPartnerDiscussion}
            updateDiscussion={updateBusinessPartnerDiscussion}
            deleteDiscussion={deleteBusinessPartnerDiscussion}
            showLoader={showLoader}
            getUserById={getUserById}
            getShipmentById={getShipmentById}
            gridColumns={gridColumns}
        />
    );
};
const mapStateToProps = (state: RootState) => ({
    currentUser: userSelectors.userInfo(state),
    businessPartnerId: singleBusinessPartnersSelectors.businessPartner(state)?.id,
    businessPartnerNumber: singleBusinessPartnersSelectors.businessPartner(state)?.name,
    discussions: singleBusinessPartnersSelectors.businessPartner(state)?.discussions,
    showLoader:
        state.loading.effects.businessPartners.createBusinessPartnerDiscussion ||
        state.loading.effects.businessPartners.updateBusinessPartnerDiscussion ||
        state.loading.effects.businessPartners.deleteBusinessPartnerDiscussion,
    getUserById: (id: string) => usersSelectors.getUserById(state)(id),
    getShipmentById: (id: string) => shipmentsSelectors.getShipmentById(state, id),
    gridColumns: allShipmentsPageSelectors.gridColumns(state)
});

const mapDispatchToProps = (dispatch: any) => ({
    createBusinessPartnerDiscussion: (businessPartnerId: string, discussion: discussion, businessPartnerNumber?: string) =>
        dispatch.businessPartners.createBusinessPartnerDiscussion({ businessPartnerId, discussion, businessPartnerNumber }),
    updateBusinessPartnerDiscussion: (businessPartnerId: string, discussion: discussion, businessPartnerNumber?: string) =>
        dispatch.businessPartners.updateBusinessPartnerDiscussion({ businessPartnerId, discussion, businessPartnerNumber }),
    deleteBusinessPartnerDiscussion: (businessPartnerId: string, discussionId: string) =>
        dispatch.businessPartners.deleteBusinessPartnerDiscussion({ businessPartnerId, discussionId })
});

export default connect(mapStateToProps, mapDispatchToProps)(BusinessPartnerDiscussionsSection);
