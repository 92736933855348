import React from 'react';
import Menu from '../../Menu/Menu';
import MenuItem from '../../Menu/MenuItem';
import edit from '../../../../static/icons/edit.svg';
import trash from '../../../../static/icons/trash.svg';
import CellTrigger from '../../Menu/MenuTriggers/CellTrigger';
import { RootState } from '../../../../state/store/store';
import { connect } from 'react-redux';
import { singlePurchaseOrderSelectors } from '../../../../state/ducks/singlePurchaseOrder/selectors';
import { purchaseOrder } from '../../../../models/entities/purchaseOrder/purchaseOrder';
type onRowMoreOptionClickType = () => void;
type Props = {
    entityId: string;
    localization: any;
    onRowMoreOptionsClick?: onRowMoreOptionClickType;
    purchaseOrder?: purchaseOrder;
    deletePurchaseOrderItem: (purchaseOrderId: string, itemId: string) => void;
};

const PurchaseOrderItemMenu = ({ entityId, purchaseOrder, deletePurchaseOrderItem, localization, onRowMoreOptionsClick }: Props) => {
    const menuItems = [
        <MenuItem
            onClick={() => {
                if (purchaseOrder) deletePurchaseOrderItem(purchaseOrder.id, entityId);
            }}
            icon={trash}
            text={localization.delete}
        />
    ];

    return <Menu options={menuItems} MenuTrigger={CellTrigger} strStyle="display: flex;align-items: center;width: 100%;height: 100%;" />;
};

const mapStateToProps = (state: RootState) => ({
    purchaseOrder: singlePurchaseOrderSelectors.purchaseOrder(state)
});

const mapDispatchToProps = (dispatch: any) => ({
    deletePurchaseOrderItem: (purchaseOrderId: string, itemId: string) => dispatch.purchaseOrders.deletePurchaseOrderItem({ purchaseOrderId, itemId })
});

export default connect(mapStateToProps, mapDispatchToProps)(PurchaseOrderItemMenu);
