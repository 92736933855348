import React, { useState, useEffect } from 'react';
import SinglePurchaseOrderPage from './SinglePurchaseOrderPage';
import { RouteComponentProps, withRouter } from 'react-router-dom';
import { settings, purchaseOrder, poOverview } from '../../../models/entities/purchaseOrder/purchaseOrder';
import { Dispatch, RootState } from '../../../state/store/store';
import Loader from '../../shared/SmallComponents/Loader';
import { singlePurchaseOrderSelectors } from '../../../state/ducks/singlePurchaseOrder/selectors';
import { localizationSelectors } from '../../../state/ducks/localization/selectors';
import { connect } from 'react-redux';
import { purchaseOrdersSelectors } from '../../../state/ducks/purchaseOrders/selectors';
import ExceptionPage from '../ExceptionPage/ExceptionPage';
import { settings as companySettings } from '../../../models/entities/businessPartner/businessPartner';
import { businessPartnersSelectors } from '../../../state/ducks/businessPartner/selectors';
import { headerSelectors } from '../../../state/ducks/header/selectors';
import { claimsDomainsType } from '../../../userClaims';
import { userSelectors } from '../../../state/ducks/user/selectors';
import claimsType from '../../../models/entities/claimsType';

type PathParams = {
    purchaseOrderId: string;
};

type Props = RouteComponentProps<PathParams> & {
    purchaseOrder: purchaseOrder | undefined;
    setCurrentPurchaseOrderId: (currentPurchaseOrderId: string) => void;
    fetchSinglePurchaseOrder: (purchaseOrderId: string) => Promise<purchaseOrder>;
    fetchPurchaseOrderError: any;
    updatePurchaseOrderState: (purchaseOrderId: string, purchaseOrderState: string) => void;
    isPoStateAfterOrEqual: (purchaseOrderId: string, checkState: string) => boolean;
    purchaseOrderState?: string;
    localizations?: any;
    printMode?: boolean;
    settings?: settings;
    companySettings?: companySettings;
    getUserClaims: (domain: claimsDomainsType) => claimsType;
};

const SinglePurchaseOrderPageConnected = ({
    purchaseOrder,
    setCurrentPurchaseOrderId,
    fetchSinglePurchaseOrder,
    updatePurchaseOrderState,
    getUserClaims,
    isPoStateAfterOrEqual,
    purchaseOrderState,
    fetchPurchaseOrderError,
    localizations,
    match,
    settings,
    printMode,
    companySettings
}: Props) => {
    const [hideItemsPrices, setHideItemsPrices] = useState(false);
    const urlPurchaseOrderId = match.params.purchaseOrderId;

    const parsedError = fetchPurchaseOrderError ? JSON.parse(fetchPurchaseOrderError) : null;

    const queryString = window.location.search;
    const urlParams = new URLSearchParams(queryString);
    useEffect(() => {
        if (urlParams.getAll('hidePrices').length > 0) {
            const value = urlParams.getAll('hidePrices')[0];

            setHideItemsPrices(value.toLowerCase() === 'true');
        }
    }, []);

    useEffect(() => {
        setCurrentPurchaseOrderId(urlPurchaseOrderId);
        fetchSinglePurchaseOrder(urlPurchaseOrderId);
    }, [urlPurchaseOrderId, setCurrentPurchaseOrderId, fetchSinglePurchaseOrder]);

    useEffect(() => {
        const urlParams = new URLSearchParams(window.location.search);
        if (urlParams.getAll('windowPrint').length > 0 && purchaseOrder?.id) {
            setTimeout(() => window.print(), 0);
        }
    }, [purchaseOrder?.id]);

    return (
        <>
            {!fetchPurchaseOrderError ? (
                purchaseOrder ? (
                    <SinglePurchaseOrderPage
                        purchaseOrder={purchaseOrder}
                        purchaseOrderState={purchaseOrderState}
                        updatePurchaseOrderState={updatePurchaseOrderState}
                        localization={localizations}
                        settings={settings}
                        companySettings={companySettings}
                        printMode={printMode}
                        getUserClaims={getUserClaims}
                        hideItemsPrices={hideItemsPrices}
                        isPoStateAfterOrEqual={isPoStateAfterOrEqual}
                    />
                ) : (
                    <Loader />
                )
            ) : (
                <ExceptionPage status={parsedError.status} subTitle={parsedError.message} localization={localizations} goBackPath="/purchaseOrders" />
            )}
        </>
    );
};

const mapStateToProps = (state: RootState) => ({
    purchaseOrder: singlePurchaseOrderSelectors.purchaseOrder(state),
    purchaseOrderState: singlePurchaseOrderSelectors.purchaseOrderState(state),
    parties: singlePurchaseOrderSelectors.parties(state),
    events: singlePurchaseOrderSelectors.events(state),
    documents: singlePurchaseOrderSelectors.documents(state),
    discussions: singlePurchaseOrderSelectors.discussions(state),
    fetchPurchaseOrderError: singlePurchaseOrderSelectors.fetchPurchaseOrderError(state),
    localizations: localizationSelectors.singlePurchaseOrderPage(state),
    settings: purchaseOrdersSelectors.settingsByLanguage(state),
    companySettings: businessPartnersSelectors.settingsByLanguage(state),
    printMode: headerSelectors.printMode(state),
    isPoStateAfterOrEqual: (purchaseOrderId: string, checkState: string) =>
        purchaseOrdersSelectors.isPoStateAfterOrEqual(state)(purchaseOrderId, checkState),
    getUserClaims: (domain: claimsDomainsType) => userSelectors.getUserClaims(state)(domain)
});

const mapDispatchToProps = (dispatch: Dispatch) => ({
    setCurrentPurchaseOrderId: (currentPurchaseOrderId: string) => dispatch.singlePurchaseOrder.setCurrentPurchaseOrderId(currentPurchaseOrderId),
    fetchSinglePurchaseOrder: (purchaseOrderId: string) => dispatch.purchaseOrders.fetchPurchaseOrderById(purchaseOrderId),
    updatePurchaseOrderState: (purchaseOrderId: string, purchaseOrderState: string) =>
        dispatch.purchaseOrders.updatePurchaseOrderState({ purchaseOrderId, purchaseOrderState })
});

export default withRouter(connect(mapStateToProps, mapDispatchToProps as any)(SinglePurchaseOrderPageConnected));
