import { Modal } from 'antd';
import { Formik, Form } from 'formik';
import React from 'react';
import AnimateHeight from 'react-animate-height';
import { css } from 'styled-components';
import { chat, participant } from '../../../../../models/entities/chat/chat';
import UserDropDownForm from '../../../../shared/inputs/form/UserDropDown';
import Button from '../../../../shared/SmallComponents/Button';
import HexagonAvatar from '../../../../shared/SmallComponents/HexagonAvatar';
import { styled } from '../../../../shared/Theme/theme';
import avatarImg from '../../../../../static/images/avatar.png';
import UserBadge from '../../../../shared/UserBadge/UserBadge';
import { userInfo } from '../../../../../models/entities/user';
import { userDetails } from '../../../../../state/ducks/users/users';

type Props = {
    userInfo: userInfo;
    chat?: chat;
    getUserById: (userId: string) => userDetails | undefined;
    deleteGroupParticipant: (chatId: string, userId: string, userName: string) => Promise<void>;
    isAdmin: boolean;
    showParticipants: boolean;
    setShowAddParticipantsInput: React.Dispatch<React.SetStateAction<boolean>>;
    showAddParticipantsInput: boolean;
    addGroupParticipants: (chatId: string, users: { id: string; name: string }[]) => Promise<void>;
    localization: any;
};

export const ParticipantsHeader = ({
    chat,
    isAdmin,
    showAddParticipantsInput,
    setShowAddParticipantsInput,
    showParticipants,
    deleteGroupParticipant,
    userInfo,
    getUserById,
    addGroupParticipants,
    localization
}: Props) => {
    return React.useMemo(() => {
        return (
            <>
                <CollapseHeader visible={showParticipants} height="77px">
                    <ParticipantsContainer>
                        {chat?.participants?.map((participant: participant, i: number) => {
                            const { userId } = participant;
                            return (
                                <ParticipantsHeaderItem key={i}>
                                    <UserBadge userId={userId} width={35} popoverLeft toggleOnClick />
                                    {isAdmin && userInfo.id !== userId && (
                                        <span
                                            className="material-icons remove-icon"
                                            onClick={() => {
                                                Modal.confirm({
                                                    zIndex: 1100,
                                                    content: <div>{localization.are_you_sure}</div>,
                                                    async onOk() {
                                                        await deleteGroupParticipant(chat.id, userId, getUserById(userId)?.name || '');
                                                    },
                                                    onCancel() {}
                                                });
                                            }}
                                        >
                                            remove_circle
                                        </span>
                                    )}
                                </ParticipantsHeaderItem>
                            );
                        })}
                    </ParticipantsContainer>
                    {isAdmin && (
                        <AddParticipantContainer>
                            <AddParticipant onClick={() => setShowAddParticipantsInput(!showAddParticipantsInput)}>
                                <HexagonAvatar width={40} img={avatarImg} borderColor="#e0e0e0" />

                                <span className="material-icons add-icon">add_circle</span>
                            </AddParticipant>
                        </AddParticipantContainer>
                    )}
                </CollapseHeader>
                {isAdmin && (
                    <AnimateHeight duration={300} height={showAddParticipantsInput ? 'auto' : 0} animateOpacity={true}>
                        <UserInputContainer>
                            <Formik
                                initialValues={{ users: [] }}
                                onSubmit={async ({ users }: { users: string[] }, { resetForm }) => {
                                    if (chat) {
                                        const usersToAdd: { id: string; name: string }[] = [];
                                        users.forEach((id) => {
                                            const user = getUserById(id);
                                            if (user) usersToAdd.push({ id: user.id, name: user.name });
                                        });
                                        addGroupParticipants(chat?.id, usersToAdd);
                                        resetForm({});
                                        setShowAddParticipantsInput(false);
                                    }
                                }}
                            >
                                {(formik) => {
                                    return (
                                        <Form>
                                            <UsersFormContainer>
                                                <div style={{ flex: 1, marginRight: '5px' }}>
                                                    <UserDropDownForm
                                                        name="users"
                                                        placeHolder="Select users"
                                                        users={undefined}
                                                        customUsers
                                                        usersToExclude={chat?.participants.map((p) => p.userId) || []}
                                                        mode="multiple"
                                                        showSearch
                                                    />
                                                </div>

                                                <Button
                                                    height="30px"
                                                    width="30px"
                                                    buttonType="filled"
                                                    type="submit"
                                                    borderRadius="50%"
                                                    disabled={!formik.values.users?.length}
                                                >
                                                    <span className="material-icons add-icon">add</span>
                                                </Button>
                                            </UsersFormContainer>
                                        </Form>
                                    );
                                }}
                            </Formik>
                        </UserInputContainer>
                    </AnimateHeight>
                )}
            </>
        );
    }, [JSON.stringify(chat?.participants), showAddParticipantsInput, showParticipants, isAdmin]);
};
const CollapseHeader = styled.div<{ visible: boolean; height: string }>`
    display: flex;
    background-color: #eeeeef;
    border-top: 1px solid #d6d6d6;
    align-items: center;
    transition: 0.3s;
    overflow: hidden;
    ${(props) =>
        props.visible
            ? css`
                  height: ${props.height};
                  opacity: 1;
              `
            : css`
                  opacity: 0;
                  height: 0;
              `};
`;
const ParticipantsHeaderItem = styled.div`
    min-width: fit-content;
    padding: 0 10px;
    position: relative;
    .remove-icon {
        display: none;
    }
    &:hover {
        .remove-icon {
            display: initial;
            cursor: pointer;
            position: absolute;
            color: red;
            top: -1px;
            right: 10px;
            font-size: 15px;
        }
    }
`;
const ParticipantsContainer = styled.div`
    display: flex;
    overflow-x: auto;
    flex: 1;
    height: 100%;
    align-items: center;
`;
const AddParticipantContainer = styled.div`
    height: 100%;
    display: flex;
    width: 70px;
    align-items: center;
    justify-content: center;
    border-left: 1px solid #d6d6d6;
    cursor: pointer;
`;
const AddParticipant = styled.div`
    position: relative;

    .add-icon {
        position: absolute;
        font-size: 15px;
        color: #00b5b9;
        top: -2px;
        right: -2px;
    }
`;
const UsersFormContainer = styled.div`
    display: flex;
    align-items: center;
`;
const UserInputContainer = styled.div`
    flex: 1;
    padding: 10px 5px;
    background-color: #e8e8e89e;
    border-top: 1px solid #d6d6d6;
`;
