export type quotation = {
    id: number;
    bidId: string;
    partyId: string;
    freightPrice: string;
    freightPriceCurrency: string;
    transitTime: number;
    freeDays: number;
    validTill: Date;
    note: string;
    shipmentType: string;
    file?: File;
};
export type quotationView = {
    id: number;
    bidId: string;
    partyId: number;
    freightPrice: string;
    freightPriceCurrency: string;
    transitTime: number;
    freeDays: number;
    validTill: Date;
    note: string;
    forwarderName: string;
    contactName: string;
    fileId: string;
    fileName: string;
    fileType: string;
    shipmentType: string;
    state: string;
};
export const eQuotationState: { [key: string]: string } = {
    RECIEVED: 'Recieved',
    APPROVED: 'Approved'
};
