import { ModelConfig } from '@rematch/core';

export type singleOrderStateType = {
    orderId: string | null;
};

export const singleOrder: ModelConfig<singleOrderStateType> = {
    state: {
        orderId: null
    },
    reducers: {
        setCurrentOrderId(state: singleOrderStateType, orderId: string): singleOrderStateType {
            return { ...state, orderId };
        }
    }
};
