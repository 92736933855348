export const ShipmentDomainClaims = {
    edit_state: 'SHIPMENT_STATE_EDIT',
    view_document: 'SHIPMENT_DOCUMENT_VIEW',
    add_document: 'SHIPMENT_DOCUMENT_ADD',
    edit_document: 'SHIPMENT_DOCUMENT_EDIT',
    delete_document: 'SHIPMENT_DOCUMENT_DELETE',
    view_event: 'SHIPMENT_EVENT_VIEW',
    add_event: 'SHIPMENT_EVENT_ADD',
    edit_event: 'SHIPMENT_EVENT_EDIT',
    edit_billingLine: 'SHIPMENT_BILLING_LINE_EDIT',
    delete_event: 'SHIPMENT_EVENT_DELETE',
    delete_billingLine: 'SHIPMENT_BILLING_LINE_DELETE',
    add_shipment: 'SHIPMENT_ADD',
    duplicate_shipment: 'SHIPMENT_DUPLICATE'
};

export const PdDomainClaims = {
    view_pd: 'VIEW_PD',
    edit_state: 'PD_STATE_EDIT',
    add_pd: 'PD_ADD',
    edit_pd: 'PD_EDIT',
    delete_pd: 'PD_DELETE',
    add_approver: 'PD_APPROVER_ADD',
    delete_approver: 'PD_APPROVER_DELETE',
    add_pd_item: 'PD_ITEM_ADD',
    edit_pd_item: 'PD_ITEM_EDIT',
    edit_pd_item_cost: 'PD_ITEM_COST_EDIT',
    delete_pd_item: 'PD_ITEM_DELETE'
};

export const PoDomainClaims = {
    create: 'PO_CREATE',
    edit_state: 'PO_STATE_EDIT',
    edit_item_cost: 'PO_ITEM_COST_EDIT'
};

export const AdminDomainClaims = {
    view: 'ADMIN_MANAGEMENT'
};

export const UserClaims = {
    shipment: ShipmentDomainClaims,
    admin: AdminDomainClaims,
    po: PoDomainClaims,
    pd: PdDomainClaims
};

export type claimsDomainsType = keyof typeof UserClaims;
export type shipmentDomainClaimsType = typeof ShipmentDomainClaims;
export type poDomainClaimsType = typeof PoDomainClaims;
export type pdDomainClaimsType = typeof PdDomainClaims;
export type adminDomainClaimsType = typeof AdminDomainClaims;
