import React, { useState } from 'react';
import { styled } from '../../../../shared/Theme/theme';
import Loader from '../../../../shared/SmallComponents/Loader';
import { ColumnType } from '../../../../shared/Grid/types/Column';
import Grid from '../../../../shared/Grid/Grid';
import CustomModal, { ModalTriggerProps } from '../../../../shared/Modal/Modal';
import Button from '../../../../shared/SmallComponents/Button';
import { attributeOption } from '../../../../../models/entities/product/attribute';
import { RootState } from '../../../../../state/store/store';
import { connect } from 'react-redux';
import { localizationSelectors } from '../../../../../state/ducks/localization/selectors';
import AttributeOptionFormConnected, { formMode } from './form/AttributeOptionFormConnected';

type Props = {
    attributeOptions: Array<attributeOption>;
    localization: any;
    attributeId: string;
    productId?: string;
};

const AttributeOptionsGridView = ({ attributeOptions, localization, productId, attributeId }: Props) => {
    const gridColumns: Array<ColumnType<attributeOption>> = [
        {
            field: 'name',
            sortable: false,
            isDisplayed: true,
            width: '150px'
        },
        {
            field: 'skuAdjustment',
            sortable: false,
            isDisplayed: true
        },
        {
            field: 'costAdjustment',
            sortable: false,
            isDisplayed: true,
            bindedFields: ['costAdjustmentCurrency'],
            columnType: 'currency_input'
        },
        {
            field: 'priceAdjustment',
            sortable: false,
            isDisplayed: true,
            bindedFields: ['priceAdjustmentCurrency'],
            columnType: 'currency_input'
        },
        {
            field: 'weightAdjustment',
            sortable: false,
            isDisplayed: true,
            bindedFields: ['weightAdjustment', 'weightAdjustmentUnit'],
            columnType: 'weight'
        },
        {
            field: 'dimensionAdjustmentUnit',
            sortable: false,
            isDisplayed: true,
            bindedFields: ['lengthAdjustment', 'widthAdjustment', 'heightAdjustment', 'dimensionAdjustmentUnit'],
            columnType: 'dimensions'
        },
        {
            field: 'displayOrder',
            sortable: false,
            isDisplayed: true
        },
        {
            field: 'isDefault',
            sortable: false,
            isDisplayed: true,
            columnType: 'bool'
        }
    ];

    const [modalVisible, setVisible] = useState(false);
    const [attributeOptionEntity, setAttributeOption] = useState({} as attributeOption);
    return (
        <CustomModal
            title={{ text: localization.attributeOptions.modal.title }}
            Trigger={(props: ModalTriggerProps) => {
                const length = attributeOptions.length;
                return (
                    <StyledLink onClick={props.onClick}>
                        {length === 0
                            ? `${localization.attributeOptions.add_options} `
                            : length === 1
                            ? `${length} ${localization.attributeOptions.option}`
                            : `${length} ${localization.attributeOptions.options}`}
                    </StyledLink>
                );
            }}
            Content={() => {
                return (
                    <AttributeOptionsWrapper>
                        {attributeOptions && productId ? (
                            <>
                                <CustomModal
                                    title={{ text: localization.attributeOptions.modal.add_title }}
                                    Trigger={(props: ModalTriggerProps) => (
                                        <ButtonContainer>
                                            <Button onClick={props.onClick} width="auto" buttonType="hollow" padding="4px 12px">
                                                {localization.attributeOptions.add_attribute}
                                            </Button>
                                        </ButtonContainer>
                                    )}
                                    Content={(
                                        setVisible?: React.Dispatch<React.SetStateAction<boolean>>,
                                        setConfirm?: React.Dispatch<React.SetStateAction<boolean>>
                                    ) => {
                                        return (
                                            <AttributeOptionFormConnected
                                                productId={productId}
                                                attributeId={attributeId}
                                                mode={formMode.NEW}
                                                openedFromModal={setVisible ? { setVisible } : undefined}
                                            />
                                        );
                                    }}
                                    width="500px"
                                />
                                {attributeOptions.length > 0 ? (
                                    <GridWrapper>
                                        <Grid<attributeOption>
                                            columns={gridColumns}
                                            data={attributeOptions}
                                            localization={localization.attributeOptions.grid}
                                            onSettingsClick={() => console.log('settings')}
                                            onRowMoreOptionsClick={(row) => {
                                                setAttributeOption(row);
                                                setVisible(true);
                                            }}
                                            parentEntityId={attributeId}
                                            menuType="productAttributeOptionMenu"
                                        />
                                        {modalVisible && (
                                            <CustomModal
                                                title={{ text: localization.attributeOptions.modal.edit_title }}
                                                isOpen={true}
                                                Content={(
                                                    setModalVisible?: React.Dispatch<React.SetStateAction<boolean>>,
                                                    setConfirm?: React.Dispatch<React.SetStateAction<boolean>>
                                                ) => {
                                                    return (
                                                        <AttributeOptionFormConnected
                                                            productId={productId}
                                                            attributeId={attributeId}
                                                            mode={formMode.EDIT}
                                                            attributeOption={attributeOptionEntity}
                                                            openedFromModal={{ setVisible }}
                                                        />
                                                    );
                                                }}
                                                onCancelCallback={() => {
                                                    setVisible(false);
                                                    setAttributeOption({} as attributeOption);
                                                }}
                                                width="393px"
                                            />
                                        )}
                                    </GridWrapper>
                                ) : (
                                    <EmaptyStateText>{localization.attributeOptions.empty_state}</EmaptyStateText>
                                )}
                            </>
                        ) : (
                            <Loader />
                        )}
                    </AttributeOptionsWrapper>
                );
            }}
            width="600px"
        />
    );
};

const GridWrapper = styled.div`
    flex: 0 0 100%;
    max-width: 100%;
    width: 100%;
    box-sizing: border-box;
    overflow-y: overlay;
    height: 100%;
    width: 100%;
`;

const AttributeOptionsWrapper = styled.div`
    display: flex;
    flex-direction: column;
    width: 100%;
`;

const EmaptyStateText = styled.span`
    color: ${(props) => props.theme.colors.emptyStateText};
`;

const ButtonContainer = styled.div`
    display: flex;
    justify-content: flex-end;
    padding-top: 8px;
`;
const StyledLink = styled.div`
    color: #1890ff;
    cursor: pointer;
    :hover {
        text-decoration: underline;
    }
`;

const mapStateToProps = (state: RootState) => ({
    localization: localizationSelectors.products(state).attributes
});

const mapDispatchToProps = (dispatch: any) => ({});

export default connect(mapStateToProps, mapDispatchToProps)(AttributeOptionsGridView);
