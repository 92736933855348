import React from 'react';
import { Text, View, StyleSheet } from '@react-pdf/renderer';
import { detail } from '../EntityDetails/EntityDetails';

const styles = StyleSheet.create({
    body: {
        padding: 10
    },
    detailsRow: {
        display: 'flex',
        flexDirection: 'row',
        maxWidth: '1000px'
    },
    detailBox: {
        flex: 1,
        margin: '10px 5px',
        whiteSpace: 'nowrap',
        wordBreak: 'break-word',
        dispaly: 'flex',
        justifyContent: 'flex-start',
        alignItems: 'flex-start',
        fontSize: 8
    },
    detailTitle: {
        color: '#008ac9',
        display: 'flex',
        alignItems: 'center'
    },
    detailValue: {
        color: '#394372',
        overflow: 'hidden',
        textOverflow: 'ellipsis',
        whiteSpace: 'normal'
    }
});

const renderDetail = (detail: detail, key: number, detailsEdit: boolean) => {
    const { showCondition, content, title, value, editable, hideTitle } = detail;

    return (
        showCondition &&
        (editable || !detailsEdit) && (
            <View style={styles.detailBox} key={key}>
                {!hideTitle && (
                    <View style={{ display: 'flex', alignItems: 'center', height: '15px' }}>
                        <Text style={styles.detailTitle}>{title}</Text>
                    </View>
                )}
                {!!content && !detailsEdit ? content : <Text style={styles.detailValue}>{value || '-'}</Text>}
            </View>
        )
    );
};

type Props = {
    detailsEdit: boolean;
    detailsData: Array<Array<detail>>;
    excludedFields?: string[];
};

const PdfEntityDetails = ({ detailsData, detailsEdit, excludedFields }: Props) => {
    const maxLengthRow = Math.max(
        ...detailsData
            .map((row) => row.filter((d) => !excludedFields?.includes(d.name) && d.showCondition && (!!d.editable || !detailsEdit)))
            .map((arr) => arr.length)
    );

    return (
        <View>
            {detailsData.map((row: Array<detail>, i: number) => {
                const rowLength = row.filter((d) => !excludedFields?.includes(d.name) && d.showCondition && (!!d.editable || !detailsEdit)).length;
                if (rowLength === 0) return <View key={i} />;
                return (
                    <View style={styles.detailsRow} key={i}>
                        {row.map((detail: detail, j: number) => {
                            return renderDetail(detail, j, detailsEdit);
                        })}
                        {rowLength < maxLengthRow &&
                            [...Array(maxLengthRow - rowLength)].map((e, g) => <View style={styles.detailBox} key={g}></View>)}
                    </View>
                );
            })}
        </View>
    );
};

export default PdfEntityDetails;
