import moment from 'moment';
import { filterFieldsType } from '../../../state/ducks/allPurchaseOrdersPage/allPurchaseOrdersPage';
import { ePurchaseOrderMode } from '../../../models/entities/purchaseOrder/purchaseOrderMode';
import countries from '../../../static/data/countries.json';
import airports from '../../../static/data/airports.json';
import { settings } from '../../../models/entities/purchaseOrder/purchaseOrder';

export type parsedFilterFieldType = {
    field: string;
    parsedValue: string;
    originalValue: any;
};

export const parseFilterFields = (filterFields: filterFieldsType, localization: any, settings?: settings) => {
    let parsedFilterFields: parsedFilterFieldType[] = [];
    filterFields.forEach(({ field, value }: { field: string; value: any }) => {
        switch (field) {
            case 'state':
                value.forEach((v: string) => {
                    parsedFilterFields.push({ field, parsedValue: settings?.state?.find((s) => s.code === v)?.name || v, originalValue: v });
                });
                break;
            case 'mode':
                value.forEach((type: string) => {
                    parsedFilterFields.push({ field, parsedValue: ePurchaseOrderMode[type], originalValue: type });
                });
                break;

            case 'incoterms':
                value.forEach((type: string) => {
                    parsedFilterFields.push({ field, parsedValue: type, originalValue: type });
                });
                break;

            case 'forwarderName':
                parsedFilterFields.push({ field, parsedValue: value, originalValue: value });

                break;

            case 'fromDate':
            case 'toDate':
                let title = (() => {
                    switch (field) {
                        case 'fromDate':
                            return localization.date_from;
                        case 'toDate':
                            return localization.date_to;
                        default:
                            return '';
                    }
                })();
                parsedFilterFields.push({ field, parsedValue: title + moment(value).format('DD/MM/YYYY'), originalValue: value });

                break;

            case 'originCountry':
            case 'destinationCountry':
                const country = countries.find((country) => country.code === value);
                parsedFilterFields.push({ field, parsedValue: country?.name || '', originalValue: value });
                break;

            case 'originStation':
            case 'destinationStation':
                const airport = airports.find((airport) => airport.iata === value);
                parsedFilterFields.push({ field, parsedValue: airport?.name || '', originalValue: value });
                break;

            default:
                parsedFilterFields.push({ field, parsedValue: value, originalValue: value });

                break;
        }
    });

    return parsedFilterFields;
};

export const removeFilterItem = (removedFilter: parsedFilterFieldType, filterFields: filterFieldsType) => {
    let newFilterFields: filterFieldsType = [...filterFields];
    const { field, originalValue } = removedFilter;

    switch (field) {
        case 'mode':
        case 'incoterms':
        case 'state':
            let arrayFilterIndex = newFilterFields.findIndex((filter) => filter.field === field);
            if (arrayFilterIndex > -1) {
                const newArray = (newFilterFields[arrayFilterIndex]?.value as string[]).filter((type) => type !== originalValue);
                newFilterFields[arrayFilterIndex] = {
                    field: field,
                    value: newArray
                };
                if (!newArray.length) {
                    newFilterFields.splice(arrayFilterIndex, 1);
                }
            }
            break;

        default:
            let filterIndex = newFilterFields.findIndex((filter) => filter.field === field && filter.value === originalValue);
            if (filterIndex > -1) {
                newFilterFields.splice(filterIndex, 1);
            }
            break;
    }

    return newFilterFields;
};
