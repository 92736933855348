import React from 'react';
import styled from 'styled-components';
import Grid from '../../../../shared/Grid/Grid';
import Loader from '../../../../shared/SmallComponents/Loader';
import { deletedRecord } from '../../../../../models/entities/admin/deletedRecord';
import GridColumns from './gridColumns';
import { useHistory } from 'react-router-dom';
type Props = {
    restoreEntity: (entityId: string, entityType: string) => Promise<void>;
    deleteEntity: (entityId: string, entityType: string) => Promise<void>;
    data: Array<deletedRecord>;
    showLoader: boolean;
    localization: any;
};

const DeletedRecordsGrid = ({ data, showLoader, localization, restoreEntity, deleteEntity }: Props) => {
    const history = useHistory();
    return showLoader ? (
        <Loader />
    ) : (
        <Wrapper>
            <Grid<deletedRecord>
                columns={GridColumns(localization, restoreEntity, deleteEntity, history.push)}
                data={data}
                localization={localization.grid}
                emptyStateText={localization.grid.emptyState}
            />
        </Wrapper>
    );
};

const Wrapper = styled.div`
    width: 100%;
    height: 100%;

    .MuiDataGrid-root {
        border: none;
    }
    .MuiDataGrid-root .MuiDataGrid-window {
        overflow-y: overlay !important;
    }
`;

export default DeletedRecordsGrid;
