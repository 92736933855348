import React from 'react';
import { discussion } from '../../../../../models/entities/common_subentities/discussion';
import { userSelectors } from '../../../../../state/ducks/user/selectors';
import { RootState } from '../../../../../state/store/store';
import { connect } from 'react-redux';
import { singlePurchaseOrderSelectors } from '../../../../../state/ducks/singlePurchaseOrder/selectors';
import { userInfo } from '../../../../../models/entities/user';
import DiscussionsSection from '../../../commonSubEntities/discussions/DiscussionsSection';
import { userDetails } from '../../../../../state/ducks/users/users';
import { usersSelectors } from '../../../../../state/ducks/users/selectors';
import { shipment } from '../../../../../models/entities/shipment/shipment';
import { shipmentsSelectors } from '../../../../../state/ducks/shipments/selectors';
import { ColumnType } from '../../../../shared/Grid/types/Column';
import { allShipmentsPageSelectors } from '../../../../../state/ducks/allShipmentsPage/selectors';

type Props = {
    purchaseOrderId?: string;
    poCzNumber?: string;
    discussions?: Array<discussion>;
    localization: any;
    currentUser: userInfo;
    createPurchaseOrderDiscussion: (purchaseOrderNumber: string, discussion: discussion, poCzNumber?: string) => void;
    updatePurchaseOrderDiscussion: (purchaseOrderNumber: string, discussion: discussion, poCzNumber?: string) => void;
    deletePurchaseOrderDiscussion: (purchaseOrderNumber: string, discussionId: string) => void;
    getUserById: (userId: string) => userDetails | undefined;
    getShipmentById: (id: string) => shipment | undefined;
    showLoader: boolean;
    gridColumns: Array<ColumnType<shipment>>;
};

const PurchaseOrderDiscussionsSection = ({
    purchaseOrderId,
    poCzNumber,
    discussions,
    localization,
    currentUser,
    createPurchaseOrderDiscussion,
    updatePurchaseOrderDiscussion,
    deletePurchaseOrderDiscussion,
    showLoader,
    getUserById,
    getShipmentById,
    gridColumns
}: Props) => {
    return (
        <DiscussionsSection
            entityId={purchaseOrderId}
            entityName={poCzNumber}
            discussions={discussions}
            localization={localization}
            currentUser={currentUser}
            createDiscussion={createPurchaseOrderDiscussion}
            updateDiscussion={updatePurchaseOrderDiscussion}
            deleteDiscussion={deletePurchaseOrderDiscussion}
            showLoader={showLoader}
            getUserById={getUserById}
            getShipmentById={getShipmentById}
            gridColumns={gridColumns}
        />
    );
};
const mapStateToProps = (state: RootState) => ({
    currentUser: userSelectors.userInfo(state),
    purchaseOrderId: singlePurchaseOrderSelectors.purchaseOrder(state)?.id,
    poCzNumber: singlePurchaseOrderSelectors.purchaseOrder(state)?.CargoZoneNumber,
    discussions: singlePurchaseOrderSelectors.purchaseOrder(state)?.discussions,
    showLoader:
        state.loading.effects.purchaseOrders.createPurchaseOrderDiscussion ||
        state.loading.effects.purchaseOrders.updatePurchaseOrderDiscussion ||
        state.loading.effects.purchaseOrders.deletePurchaseOrderDiscussion,
    getUserById: (id: string) => usersSelectors.getUserById(state)(id),
    getShipmentById: (id: string) => shipmentsSelectors.getShipmentById(state, id),
    gridColumns: allShipmentsPageSelectors.gridColumns(state)
});

const mapDispatchToProps = (dispatch: any) => ({
    createPurchaseOrderDiscussion: (purchaseOrderNumber: string, discussion: discussion, poCzNumber?: string) =>
        dispatch.purchaseOrders.createPurchaseOrderDiscussion({ purchaseOrderNumber, discussion, poCzNumber }),
    updatePurchaseOrderDiscussion: (purchaseOrderNumber: string, discussion: discussion, poCzNumber?: string) =>
        dispatch.purchaseOrders.updatePurchaseOrderDiscussion({ purchaseOrderNumber, discussion, poCzNumber }),
    deletePurchaseOrderDiscussion: (purchaseOrderNumber: string, discussionId: string) =>
        dispatch.purchaseOrders.deletePurchaseOrderDiscussion({ purchaseOrderNumber, discussionId })
});

export default connect(mapStateToProps, mapDispatchToProps)(PurchaseOrderDiscussionsSection);
