import { FormikProps } from 'formik';
import React, { useState } from 'react';
import { tariff } from '../../../../../../models/entities/tariff';
import { createTariffChargePayload } from '../../../../../../models/entities/tariff/charge';
import CustomModal, { ModalTriggerProps } from '../../../../../shared/Modal/Modal';
import TariffChargeForm from '../TariffChargeForm';

type Props = {
    tariff: tariff;
    localization: any;
    Trigger?: (props: ModalTriggerProps) => JSX.Element;
};
const TariffChargesFormModal = ({ tariff, localization, Trigger }: Props) => {
    const formRef = React.createRef<FormikProps<createTariffChargePayload>>();
    const [isSubmitting, setIsSubmitting] = useState<boolean>(false);

    const submitForm = async () => {
        setIsSubmitting(true);
        await formRef?.current?.submitForm();
        setIsSubmitting(false);
    };
    return (
        <CustomModal
            title={{ text: localization.charges.addCharge }}
            contentPadding="10px 30px 10px 30px"
            Trigger={Trigger}
            Content={(setVisible?: React.Dispatch<React.SetStateAction<boolean>>, setConfirm?: React.Dispatch<React.SetStateAction<boolean>>) => {
                return <TariffChargeForm tariff={tariff} formRef={formRef} />;
            }}
            confirmBtn={{
                handler: async () => await submitForm(),
                text: 'Create',
                closeModal: true,
                disabled: isSubmitting,
                isLoading: isSubmitting
            }}
            cancelBtn={{
                text: 'Cancel'
            }}
            width="400px"
        />
    );
};

export default TariffChargesFormModal;
