import { ModelConfig } from '@rematch/core';
import { localSDK as client } from '../../../sdk';
import { category, createCategoryPayload, updateCategoryPayload } from '../../../models/entities/category/category';

export type categoriesStateType = {
    categories: Array<category>;
};

export const categories: ModelConfig<categoriesStateType> = {
    state: {
        categories: []
    },
    reducers: {
        setCategories(state: categoriesStateType, categories: Array<category>): categoriesStateType {
            return { ...state, categories };
        },
        setCategory(state: categoriesStateType, category: category): categoriesStateType {
            const newCategories = [...state.categories];
            const index = newCategories.findIndex((c) => c.id === category.id);

            if (index !== -1) {
                newCategories[index] = category;
            } else {
                newCategories.push(category);
            }

            return { ...state, categories: newCategories };
        }
    },
    effects: (dispatch: any) => ({
        async fetchAllCategories() {
            const categories = await client.categories().getAllCategories();
            dispatch.categories.setCategories(categories);
        },
        async createCategory(payload: createCategoryPayload) {
            const category = await client.categories().createCategory(payload);
            dispatch.categories.setCategory(category);
            return category;
        },
        async updateCategory(payload: updateCategoryPayload) {
            const category = await client.categories().updateCategory(payload);
            dispatch.categories.setCategory(category);
            return category;
        }
    })
};
