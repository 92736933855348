import React from 'react';
import { document } from '../../../../../models/entities/common_subentities/document';
import MultipleDocumentFormConnected from './MultipleDocumentFormConnected';
import DocumentFormConnected from './DocumentFormConnected';
import Documents from '../../../commonSubEntities/documents/Documents';

type Props = {
    documents?: Array<document>;
    localization: any;
    orderId: string;
};

const OrderDocuments = ({ documents, localization, orderId }: Props) => {
    return (
        <Documents
            documents={documents}
            localization={localization}
            menuType="orderDocumentMenu"
            MultipleDocumentFormConnected={MultipleDocumentFormConnected}
            DocumentFormConnected={DocumentFormConnected}
            entityId={orderId}
        />
    );
};

export default OrderDocuments;
