import React, { useState } from 'react';
import theme, { styled } from '../../../shared/Theme/theme';
import { discussion } from '../../../../models/entities/common_subentities/discussion';
import { userInfo } from '../../../../models/entities/user';
import moment from 'moment';
import edit from '../../../../static/icons/edit.svg';
import trash from '../../../../static/icons/trash.svg';
import Menu, { TriggerProps } from '../../../shared/Menu/Menu';
import MenuItem from '../../../shared/Menu/MenuItem';
import UserBadge from '../../../shared/UserBadge/UserBadge';
import TextEditor from '../../../shared/inputs/base/TextEditor';
import {
    convertHtmlToEditorState,
    getResetEditorState,
    convertEditorStateToHtml,
    parseHtmlToJsx,
    isEditorEmpty
} from '../../../../utils/textEditorUtils';
import { userDetails } from '../../../../state/ducks/users/users';
import { shipment } from '../../../../models/entities/shipment/shipment';
import { ColumnType } from '../../../shared/Grid/types/Column';

type Props = {
    entityId?: string;
    entityName?: string;
    discussion: discussion;
    currentUser: userInfo;
    localization: any;
    isNew?: boolean;
    isSubmit?: boolean;
    gridColumns: Array<ColumnType<shipment>>;
    createDiscussion?: (entityId: string, discussion: discussion, entityName?: string) => void;
    updateDiscussion?: (entityId: string, discussion: discussion, entityName?: string) => void;
    deleteDiscussion?: (entityId: string, discussionId: string) => void;
    getUserById: (userId: string) => userDetails | undefined;
    getShipmentById: (id: string) => shipment | undefined;
};
const DiscussionItem = ({
    entityId,
    entityName,
    discussion,
    currentUser,
    localization,
    isNew,
    createDiscussion,
    updateDiscussion,
    deleteDiscussion,
    isSubmit,
    getUserById,
    getShipmentById,
    gridColumns
}: Props) => {
    const [editable, setEdit] = useState(isNew);

    let htmlState = convertHtmlToEditorState(discussion.message || '', getUserById, getShipmentById);
    const [editorState, setEditorState] = useState(htmlState);

    const menuItems = [
        <MenuItem onClick={() => setEdit(true)} icon={edit} text={localization.discussions.item.menu.edit} />,
        <MenuItem
            onClick={() => entityId && deleteDiscussion && deleteDiscussion(entityId, discussion.id)}
            icon={trash}
            text={localization.discussions.item.menu.delete}
        />
    ];
    const onSubmit = () => {
        if (entityId) {
            const message = convertEditorStateToHtml(editorState);

            const payload: discussion = {
                message: message,
                userPic: currentUser.profileImg || ''
            } as discussion;
            if (isNew && payload.message) {
                createDiscussion && createDiscussion(entityId, payload, entityName);
                setEditorState(getResetEditorState(editorState));
            } else if (!isNew) {
                payload.id = discussion.id;
                updateDiscussion && updateDiscussion(entityId, payload, entityName);
                setEdit(false);
            }
        }
    };

    const getItemDate = () => {
        const discussionItemDate = moment(discussion.createdDate);
        const dateFromNow = discussionItemDate.fromNow();

        const daysDiff = moment().diff(discussionItemDate, 'days');

        if (daysDiff < 1) return dateFromNow;

        return `${dateFromNow} - ${discussionItemDate.format('DD/MM/YYYY')} ${discussionItemDate.format('h:mm a')}`;
    };

    return (
        <Wrapper>
            <LeftSide>
                <UserBadge width={40} userId={discussion.userId} userName={discussion.userName} hideBadgeName={true} borderColor="#f7f7f7" />
            </LeftSide>
            <RightSide>
                {!isNew && (
                    <DetailsHeader>
                        <Name>{discussion.userName}</Name>
                        <DateText>{getItemDate()}</DateText>
                    </DetailsHeader>
                )}
                {!isNew && !editable ? (
                    <div style={{ color: '#757575', paddingBottom: '10px' }}>{parseHtmlToJsx(discussion.message || '')}</div>
                ) : (
                    <TextEditor
                        id={`discussion-editor-${discussion?.id || 'first'}`}
                        editorState={editorState}
                        onChange={(state) => {
                            setEditorState(state);
                        }}
                        style={{ border: 'none' }}
                        readOnly={!editable}
                        onSubmit={onSubmit}
                        canSubmit={!isEditorEmpty(editorState) && !isSubmit}
                        placeholder={localization.discussions.item.placeholder}
                        hasAttachments={false}
                    />
                )}
            </RightSide>
            <ActionContainer>
                {!isNew && currentUser.id === discussion.userId && (
                    <Menu
                        arrowOnTrigger={false}
                        options={menuItems}
                        MenuTrigger={(props: TriggerProps) => (
                            <MenuDisplay className="menu-trigger" onClick={props.onClick}>
                                <span className="material-icons">more_horiz</span>
                            </MenuDisplay>
                        )}
                    />
                )}
            </ActionContainer>
        </Wrapper>
    );
};

const Wrapper = styled.div`
    position: relative;
    width: 100%;
    display: flex;
    padding: 9px 15px;
    padding-bottom: 0;
    border: 1px solid #e0e0e0;
    border-radius: 10px;
    margin-bottom: 15px;
    align-items: center;
    :hover {
        .menu-trigger {
            visibility: visible;
        }
    }
`;
const LeftSide = styled.div``;
const RightSide = styled.div`
    margin-left: auto;
    padding-top: 5px;
    padding-left: 15px;
    flex: 1;
    width: 0;
`;
const DetailsHeader = styled.div`
    display: flex;
    align-items: center;
`;
const Name = styled.div`
    color: #394372;
    font-size: 12px;
    font-weight: bold;
`;
const DateText = styled.span`
    font-size: 10px;
    color: #757575;
    margin-left: 8px;
`;

const UserMention = styled.span`
    color: #008ac9;
    cursor: pointer;
    &:hover {
        background-color: #ffff0075;
    }
`;

const MenuDisplay = styled.div`
    visibility: hidden;
    cursor: pointer;
    align-items: center;
    justify-content: center;
    display: flex;
    width: 30px;
    height: 30px;
    font-size: 14px;
    :hover {
        background: ${theme.colors.hoverBG};
    }
    & span {
        user-select: none;
        display: block;
        line-height: inherit;
        font-size: 23px;
    }
`;

const ActionContainer = styled.div`
    position: absolute;
    right: 0;
    top: 5px;
    margin-right: 5px;
`;

export default DiscussionItem;
