import React from 'react';
import Menu, { TriggerProps } from '../../Menu/Menu';
import MenuItem from '../../Menu/MenuItem';
import edit from '../../../../static/icons/edit.svg';
import trash from '../../../../static/icons/trash.svg';
import { RootState } from '../../../../state/store/store';
import { connect } from 'react-redux';
import { singleShipmentSelectors } from '../../../../state/ducks/singleShipment/selectors';
import { shipment } from '../../../../models/entities/shipment/shipment';
type onRowMoreOptionClickType = () => void;
type deleteShipmentPartyType = (shipmentNumber: string, partyId: number) => void;
type Props = {
    entityId: number;
    localization: any;
    onRowMoreOptionsClick?: onRowMoreOptionClickType;
    deleteShipmentParty: deleteShipmentPartyType;
    shipment?: shipment;
    menuTrigger: (props: TriggerProps) => JSX.Element;
};
const menuStyle = 'position: absolute;right: 0;top: 0;width: auto;font-size:14px;margin-right:5px;height:100%;';
const ShipmentPartyMenu = ({ entityId, shipment, localization, onRowMoreOptionsClick, deleteShipmentParty, menuTrigger }: Props) => {
    const menuItems = [
        <MenuItem onClick={() => onRowMoreOptionsClick && onRowMoreOptionsClick()} icon={edit} text={localization.edit} />,
        <MenuItem onClick={() => shipment && deleteShipmentParty(shipment.id, entityId)} icon={trash} text={localization.delete} />
    ];

    return <Menu options={menuItems} MenuTrigger={menuTrigger} strStyle={menuStyle} />;
};
const mapStateToProps = (state: RootState) => ({
    shipment: singleShipmentSelectors.shipment(state)
});

const mapDispatchToProps = (dispatch: any) => ({
    deleteShipmentParty: (shipmentNumber: string, partyId: number) => dispatch.shipments.deleteShipmentParty({ shipmentNumber, partyId })
});

export default connect(mapStateToProps, mapDispatchToProps)(ShipmentPartyMenu);
