import React from 'react';
import { styled } from '../../../../shared/Theme/theme';
import moment from 'moment';
import { bid } from '../../../../../models/entities/bid/bid';
import { eShipmentTypes } from '../../../../../models/entities/shipment/shipmentTypes';

type Props = {
    bid: bid;
    localization: any;
};

const Overview = ({ bid, localization }: Props) => {
    return (
        <OverviewWrapper>
            <DetailsWrapper>
                <DetailsRow>
                    <DetailBox>
                        <DetailTitle>{localization.expirationDate}</DetailTitle>
                        <DetailValue>
                            {moment(bid.expirationDate).isValid() ? moment(bid.expirationDate).format('DD/MM/YYYY HH:mm') : '-'}
                        </DetailValue>
                    </DetailBox>
                </DetailsRow>
            </DetailsWrapper>
        </OverviewWrapper>
    );
};

const OverviewWrapper = styled.div`
    display: flex;
    flex-direction: column;
    width: 100%;
`;

const OverviewHeaderWrapper = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    width: 100%;
    border-bottom: solid 1px #e0e0e0;
    padding: 24px 18px 18px 48px;
`;

const DetailsWrapper = styled.div`
    display: flex;
    flex-direction: column;
    padding: 24px 18px 18px 48px;
`;

const DetailsRow = styled.div`
    display: flex;
    flex-direction: row;
    max-width: 1000px;
`;

const DetailBox = styled.div`
    padding: 0 60px 26px 0;
    min-width: 20%;
    white-space: nowrap;
`;

const DetailTitle = styled.div`
    color: #008ac9;
`;

const DetailValue = styled.div`
    color: #394372;
    text-overflow: ellipsis;
    white-space: normal;
    -webkit-line-clamp: 2;
    display: -webkit-box;
    -webkit-box-orient: vertical;
`;
export default Overview;
