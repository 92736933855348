import React from 'react';
import { RootState } from '../../../../../state/store/store';
import { connect } from 'react-redux';
import { companyAdminUpdateUserPayload } from '../../../../../models/entities/user';
import { localizationSelectors } from '../../../../../state/ducks/localization/selectors';
import { companiesSelectors } from '../../../../../state/ducks/companies/selectors';
import AddEditCompany from './AddEditCompany';
import { createCompanyPayload } from '../../../../../models/entities/company/createCompanyPayload';
import { company } from '../../../../../models/entities/company/company';
import { userSelectors } from '../../../../../state/ducks/user/selectors';
import { featureFlagsModel } from '../../../../../models/entities/businessPartner/settings';

type Props = {
    params: { companyId?: string };
    getCompanyById: (companyId: string) => company | undefined;
    updateUserDetails: (details: companyAdminUpdateUserPayload, userId: string) => Promise<void>;
    createCompany: (payload: createCompanyPayload) => Promise<void>;
    localization: any;
    featureFlags?: featureFlagsModel;
};

const AddEditCompanyPaneConnected = ({ params, getCompanyById, localization, createCompany, featureFlags }: Props) => {
    return (
        <AddEditCompany
            company={params?.companyId ? getCompanyById(params.companyId) : undefined}
            createCompany={createCompany}
            localization={localization}
            featureFlags={featureFlags}
        />
    );
};

const mapStateToProps = (state: RootState) => ({
    localization: localizationSelectors.companiesMgmtPage(state),
    getCompanyById: (companyId: string) => companiesSelectors.getCompanyById(state)(companyId),
    featureFlags: userSelectors.featureFlags(state)
});

const mapDispatchToProps = (dispatch: any) => ({
    updateUserDetails: (details: companyAdminUpdateUserPayload, userId: string) => dispatch.admin.updateUserDetails({ details, userId }),
    createCompany: (payload: createCompanyPayload) => dispatch.companies.createCompany(payload)
});

export default connect(mapStateToProps, mapDispatchToProps)(AddEditCompanyPaneConnected);
