import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import PurchaseOrdersPage from './PurchaseOrdersPage';
import { purchaseOrder, settings } from '../../../models/entities/purchaseOrder/purchaseOrder';
import { RootState } from '../../../state/store/store';
import { exportTypes, gridSortState, paginationType } from '../../../state/ducks/allPurchaseOrdersPage/allPurchaseOrdersPage';
import { allPurchaseOrdersPageSelectors } from '../../../state/ducks/allPurchaseOrdersPage/selectors';
import { purchaseOrdersSelectors } from '../../../state/ducks/purchaseOrders/selectors';
import { localizationSelectors } from '../../../state/ducks/localization/selectors';
import { ColumnType } from '../../shared/Grid/types/Column';
import ExceptionPage from '../ExceptionPage/ExceptionPage';
import { userSelectors } from '../../../state/ducks/user/selectors';
import { userSettings } from '../../../models/entities/user';
import { filterColsByUserSettings } from '../../shared/Grid/util';
import { claimsDomainsType } from '../../../userClaims';
import claimsType from '../../../models/entities/claimsType';

type Props = {
    purchaseOrders: Array<purchaseOrder>;
    purchaseOrdersPage: Array<purchaseOrder>;
    fetchPurchaseOrdersError: any;
    activePurchaseOrdersCount: number;
    fetchPurchaseOrders: () => void;
    fetchGridColumns: () => void;
    gridColumns: Array<ColumnType<purchaseOrder>>;
    currentGridSort: gridSortState | null;
    currentGridFilter: string | null;
    setGridSort: (sortData: gridSortState) => void;
    setGridFilter: (text: string) => void;
    exportDataTable: (type: exportTypes) => void;
    setCurrentPage: (page: number) => void;
    setRowsPerPage: (rowsPerPage: number) => void;
    filteredPOsCount: number;
    pagination: paginationType;
    localizations: any;
    showLoader?: boolean;
    settings?: settings;
    purchaseOrdersGridSettings?: userSettings;
    getUserClaims: (domain: claimsDomainsType) => claimsType;
};

const PurchaseOrdersPageConnected = ({
    purchaseOrders,
    purchaseOrdersPage,
    fetchPurchaseOrdersError,
    activePurchaseOrdersCount,
    fetchPurchaseOrders,
    fetchGridColumns,
    gridColumns,
    currentGridSort,
    currentGridFilter,
    setGridSort,
    setGridFilter,
    exportDataTable,
    localizations,
    showLoader,
    settings,
    purchaseOrdersGridSettings,
    getUserClaims,
    setCurrentPage,
    setRowsPerPage,
    filteredPOsCount,
    pagination
}: Props) => {
    useEffect(() => {
        fetchGridColumns();
        fetchPurchaseOrders();
    }, [fetchGridColumns, fetchPurchaseOrders]);

    const DEFAULT_SORT_DIRECTION = 'asc';
    const SECOND_SORT_DIRECTION = 'desc';

    const onGridColumnClick = (newColumn: keyof purchaseOrder) => {
        if (currentGridSort) {
            const { direction, column } = currentGridSort;
            if (column === newColumn)
                if (direction === DEFAULT_SORT_DIRECTION)
                    setGridSort({
                        column: newColumn,
                        direction: SECOND_SORT_DIRECTION
                    });
                else
                    setGridSort({
                        column: newColumn,
                        direction: DEFAULT_SORT_DIRECTION
                    });
            else {
                setGridSort({
                    column: newColumn,
                    direction: DEFAULT_SORT_DIRECTION
                });
            }
        } else {
            setGridSort({
                column: newColumn,
                direction: DEFAULT_SORT_DIRECTION
            });
        }
    };

    const onGridFilter = (text: string) => {
        setGridFilter(text);
        setCurrentPage(0);
    };

    const parsedError = fetchPurchaseOrdersError ? JSON.parse(fetchPurchaseOrdersError) : null;
    const userSettingKey = 'PurchaseOrderGrid';
    return !fetchPurchaseOrdersError ? (
        <PurchaseOrdersPage
            purchaseOrders={purchaseOrders}
            purchaseOrdersPage={purchaseOrdersPage}
            activePurchaseOrdersCount={activePurchaseOrdersCount}
            gridColumns={filterColsByUserSettings(gridColumns, purchaseOrdersGridSettings)}
            onGridColumnClick={onGridColumnClick}
            onFilter={onGridFilter}
            filterText={currentGridFilter ?? ''}
            gridSortDirection={currentGridSort?.direction}
            gridSortedBy={currentGridSort?.column}
            localization={localizations}
            exportDataTable={exportDataTable}
            showLoader={showLoader}
            settings={settings}
            userSettingKey={userSettingKey}
            getUserClaims={getUserClaims}
            setCurrentPage={setCurrentPage}
            setRowsPerPage={setRowsPerPage}
            filteredPOsCount={filteredPOsCount}
            fetchPurchaseOrders={fetchPurchaseOrders}
            pagination={pagination}
        />
    ) : (
        <ExceptionPage status={parsedError.status} subTitle={parsedError.message} goBackPath="/" />
    );
};

const mapStateToProps = (state: RootState) => ({
    purchaseOrders: allPurchaseOrdersPageSelectors.gridData(state),
    purchaseOrdersPage: allPurchaseOrdersPageSelectors.currentGridDataPage(state),
    fetchPurchaseOrdersError: purchaseOrdersSelectors.fetchPurchaseOrdersError(state),
    activePurchaseOrdersCount: allPurchaseOrdersPageSelectors.activePurchaseOrdersCount(state),
    gridColumns: allPurchaseOrdersPageSelectors.gridColumns(state),
    currentGridSort: allPurchaseOrdersPageSelectors.gridSort(state),
    currentGridFilter: allPurchaseOrdersPageSelectors.gridFilter(state),
    localizations: localizationSelectors.allPurchaseOrdersPage(state),
    showLoader: state.loading.effects.allPurchaseOrdersPage.fetchGridColumns || state.loading.effects.purchaseOrders.fetchPurchaseOrders,
    settings: purchaseOrdersSelectors.settingsByLanguage(state),
    purchaseOrdersGridSettings: userSelectors.purchaseOrdersGridSettings(state),
    getUserClaims: (domain: claimsDomainsType) => userSelectors.getUserClaims(state)(domain),
    filteredPOsCount: allPurchaseOrdersPageSelectors.filteredPOsCount(state),
    pagination: allPurchaseOrdersPageSelectors.pagination(state)
});

const mapDispatchToProps = (dispatch: any) => ({
    fetchPurchaseOrders: () => dispatch.purchaseOrders.fetchPurchaseOrders(),
    fetchGridColumns: () => dispatch.allPurchaseOrdersPage.fetchGridColumns(),
    setGridFilter: (text: string) => dispatch.allPurchaseOrdersPage.setGridFilter(text),
    setGridSort: (sortData: gridSortState) => dispatch.allPurchaseOrdersPage.setGridSort(sortData),
    exportDataTable: (type: exportTypes) => dispatch.allPurchaseOrdersPage.exportDataTable(type),
    setCurrentPage: (page: number) => dispatch.allPurchaseOrdersPage.setCurrentPage(page),
    setRowsPerPage: (rowsPerPage: number) => dispatch.allPurchaseOrdersPage.setRowsPerPage(rowsPerPage)
});

export default connect(mapStateToProps, mapDispatchToProps)(PurchaseOrdersPageConnected);
