import { range } from 'lodash';
import moment from 'moment';
import React, { useState } from 'react';
import { XAxis, YAxis, CartesianGrid, Tooltip, Legend, ResponsiveContainer, BarChart, Bar } from 'recharts';

type Props = {
    localization: any;
    exceptionsByMonth: {
        [month: string]: {
            [carrier: string]: number;
        };
    };
};

const COLORS = ['#ED9B00', '#2AC18B', '#04BFF7', '#6BC6C9'];

const ExceptionsByMonth = ({ localization, exceptionsByMonth }: Props) => {
    const [[year, half], setYearHalf] = useState<[number, 0 | 1]>([moment().year(), 0]);

    const months = range(!half ? 0 : 6, !half ? 6 : 12).map((m) => moment([year, m]).format('MM/YY'));

    const exceptionsByMonthEntries = Object.entries(exceptionsByMonth);
    const exceptionsByMonthData = months.map((month: string) => ({ month, ...(exceptionsByMonth[month] || []) }));

    const carriersNames = exceptionsByMonthEntries.reduce((names: string[], entry) => {
        const [month, carriers] = entry;

        const newNames = Object.keys(carriers);
        const inRange = months.includes(month);

        if (!inRange) return names;

        for (const name of newNames) {
            if (!names.includes(name)) names.push(name);
        }

        return names;
    }, []);

    const longestLabelLengthBar = Object.values(exceptionsByMonth)
        .map((carriers: object) => Object.values(carriers))
        .flat()
        .reduce((acc: number, cur: number) => (`${cur}`.length > acc ? `${cur}`.length : acc), 0);

    return (
        <ResponsiveContainer width="100%" height="100%">
            <BarChart data={exceptionsByMonthData} margin={{ top: 20, left: 20, right: 20, bottom: 20 }}>
                <CartesianGrid vertical={false} opacity={0.5} strokeDasharray="3 3" />
                <XAxis dataKey="month" stroke="#8b8b8b" fontSize={12} axisLine={false} tickLine={false} dx={5} dy={5} />
                <YAxis
                    width={longestLabelLengthBar * 10 + 10}
                    tickCount={4}
                    stroke="#8b8b8b"
                    fontSize={12}
                    axisLine={false}
                    tickLine={false}
                    dx={-5}
                />
                <Tooltip />
                <Legend />
                {carriersNames.map((name, index) => {
                    return <Bar dataKey={name} stackId="a" fill={COLORS[index % COLORS.length]} />;
                })}
            </BarChart>
        </ResponsiveContainer>
    );
};

export default ExceptionsByMonth;
