import React, { useState } from 'react';
import { connect } from 'react-redux';
import { RootState } from '../../../../state/store/store';
import { ColumnType } from '../../../shared/Grid/types/Column';
import CustomModal from '../../../shared/Modal/Modal';
import settingsIcon from '../../../../static/icons/settings-light-blue.svg';
import CustomizeColumnsContent from './CustomizeColumnsContent';
import { userSelectors } from '../../../../state/ducks/user/selectors';
import { userSettings } from '../../../../models/entities/user';

type ModalProps = {
    gridColumns: Array<ColumnType<any>>;
    userSettingKey?: string;
    showLoader?: boolean;
    userSettings: Array<userSettings>;
    setVisible: (bool: boolean) => void;
    setGridColumns: (columns: Array<ColumnType<any>>) => void;
    setUserSettings: (key: string, value: any, id?: number) => Promise<void>;
};

export const CustomizeColumnsModal = ({
    gridColumns,
    userSettingKey,
    showLoader,
    userSettings,
    setGridColumns,
    setVisible,
    setUserSettings
}: ModalProps) => {
    const settings = userSettings.find((item) => item.key === userSettingKey);
    const [currGridColumns, setCurrGridColumns] = useState<ColumnType<any>[]>(gridColumns);

    return (
        <CustomModal
            title={{ icon: settingsIcon, text: 'Customize columns' }}
            isOpen={true}
            Content={(
                setModalVisible?: React.Dispatch<React.SetStateAction<boolean>>,
                setConfirm?: React.Dispatch<React.SetStateAction<boolean>>
            ) => {
                return <CustomizeColumnsContent currGridColumns={currGridColumns} setCurrGridColumns={setCurrGridColumns} />;
            }}
            confirmBtn={{
                handler: async () => {
                    setGridColumns(currGridColumns);
                    if (userSettingKey) {
                        const value = currGridColumns.map((item) => {
                            return { field: item.field, isDisplayed: item.isDisplayed };
                        });
                        await setUserSettings(userSettingKey, value, settings?.id).finally(() => setVisible(false));
                    } else {
                        setVisible(false);
                    }
                },
                text: 'Save',
                closeModal: true,
                isLoading: showLoader
            }}
            cancelBtn={{
                text: 'Cancel'
            }}
            onCancelCallback={() => {
                setVisible(false);
            }}
            width="662px"
            contentPadding="0px"
        />
    );
};

const mapStateToProps = (state: RootState) => ({
    userSettings: userSelectors.userSettings(state),
    showLoader: state.loading.effects.user.setUserSettings
});

const mapDispatchToProps = (dispatch: any) => ({
    setUserSettings: async (key: string, value: any, id?: number) => dispatch.user.setUserSettings({ key, value, id })
});

export default connect(mapStateToProps, mapDispatchToProps)(CustomizeColumnsModal);
