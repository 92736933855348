import BaseController from '..';
import IContainers from './IContainers';

export default class Containers extends BaseController implements IContainers {
    constructor(baseUrl: string) {
        super(baseUrl);
    }
    async getContainers() {
        const response = await this.client.get('/dashboard/fclContainers');
        return response;
    }
}
