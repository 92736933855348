import React, { useEffect, useState } from 'react';
import { styled } from '../../../shared/Theme/theme';
import { Formik, Form } from 'formik';
import { localizationSelectors } from '../../../../state/ducks/localization/selectors';
import { RootState } from '../../../../state/store/store';
import { connect } from 'react-redux';
import Button from '../../../shared/SmallComponents/Button';
import { ModalFooter, ModalFormContainer } from '../../../shared/Modal/Modal';
import Loader from '../../../shared/SmallComponents/Loader';
import { TaskStatusEnum, TaskPriorityEnum, task } from '../../../../models/entities/task';
import { FormDatePicker, FormDropDown, FormTextArea } from '../../../shared/inputs/form';
import FieldLabel from '../../../shared/SmallComponents/FieldLabel';
import StatusDropDown from './StatusDropDown';
import PriorityDropDown from './PriorityDropDown';
import { eReminderDates } from '../../../../models/entities/shipment/reminder_dates';
import { enumToDropdownOptions } from '../../../shared/inputs/base/DropDown';
import { getReminderDate } from '../../../../utils/date';
import moment from 'moment';

interface formFieldsErrors {
    status?: string;
    priority?: string;
    content?: string;
    dueDate?: string;
}
type Props = {
    localizations?: any;
    openedFromModal?: {
        setVisible: React.Dispatch<React.SetStateAction<boolean>>;
    };
    confirmation?: { setConfirm: React.Dispatch<React.SetStateAction<boolean>> };
    createTask: (task: task) => void;
    showLoader?: boolean;
    defaultValues?: task;
};

let AFTER_LOADING: boolean = false;
const AddTaskForm = ({ localizations, openedFromModal, confirmation, createTask, showLoader, defaultValues }: Props) => {
    const [submitLoading, setLoading] = useState(false);

    const statusDropDownOptions = [
        {
            text: localizations.status.new,
            value: TaskStatusEnum.New,
            icon: StatusDropDown.renderNewIcon
        },
        {
            text: localizations.status.pending,
            value: TaskStatusEnum.Pending,
            icon: StatusDropDown.renderPendingIcon
        },
        {
            text: localizations.status.stuck,
            value: TaskStatusEnum.Stuck,
            icon: StatusDropDown.renderStuckIcon
        },
        {
            text: localizations.status.onWork,
            value: TaskStatusEnum.OnWork,
            icon: StatusDropDown.renderOnWorkIcon
        },
        {
            text: localizations.status.done,
            value: TaskStatusEnum.Done,
            icon: StatusDropDown.renderDoneIcon
        }
    ];
    const priorityDropDownOptions = [
        {
            text: localizations.priority.low,
            value: TaskPriorityEnum.Low,
            icon: PriorityDropDown.renderLowIcon
        },
        {
            text: localizations.priority.medium,
            value: TaskPriorityEnum.Medium,
            icon: PriorityDropDown.renderMediumIcon
        },
        {
            text: localizations.priority.high,
            value: TaskPriorityEnum.High,
            icon: PriorityDropDown.renderHighIcon
        }
    ];

    const reminderDatesDropDownOptions = enumToDropdownOptions(eReminderDates);

    const setInitialValues = () => {
        return {
            status: TaskStatusEnum.New,
            priority: TaskPriorityEnum.Medium,
            content: defaultValues?.content || '',
            dueDate: new Date(),
            reminderChoice: 'NONE'
        };
    };
    const validate = (values: any) => {
        if (confirmation) {
            if (JSON.stringify(values) === JSON.stringify(setInitialValues())) {
                confirmation.setConfirm(false);
            } else {
                confirmation.setConfirm(true);
            }
        }

        const errors: formFieldsErrors = {};

        if (values.status === undefined) errors.status = localizations.form.required_error;
        if (values.priority === undefined) errors.priority = localizations.form.required_error;
        if (!values.content) errors.content = localizations.form.required_error;
        if (values.content.length > 500) {
            errors.content = localizations.form.max_length_error;
        }
        if (!values.dueDate) errors.dueDate = localizations.form.required_error;
        return errors;
    };
    const submit = (values: any) => {
        const task = {
            content: values.content,
            status: values.status,
            priority: values.priority,
            dueDate: values.dueDate,
            reminder: getReminderDate(values.reminderChoice, values.dueDate)
        } as task;
        createTask(task);
    };
    useEffect(() => {
        if (showLoader) {
            setLoading(true);
            AFTER_LOADING = true;
        } else {
            setLoading(false);
            if (AFTER_LOADING && openedFromModal) {
                openedFromModal.setVisible(false);
                AFTER_LOADING = false;
            }
        }
    }, [showLoader, openedFromModal]);

    const renderIconOption = (option: any) => {
        return (
            <IconOptionWrapper>
                {option.icon()}
                {option.text}
            </IconOptionWrapper>
        );
    };

    return (
        <Formik initialValues={setInitialValues()} validate={validate} onSubmit={submit}>
            <Form>
                <Container>
                    <FieldLabel>{localizations.form.status_placeholder}</FieldLabel>
                    <FieldContainer>
                        <FormDropDown
                            name="status"
                            placeHolder={localizations.form.status_placeholder}
                            options={statusDropDownOptions}
                            optionRenderer={renderIconOption}
                        />
                    </FieldContainer>
                    <FieldLabel>{localizations.form.priority_placeholder}</FieldLabel>
                    <FieldContainer>
                        <FormDropDown
                            name="priority"
                            options={priorityDropDownOptions}
                            placeHolder={localizations.form.priority_placeholder}
                            optionRenderer={renderIconOption}
                        />
                    </FieldContainer>
                    <FieldLabel>{localizations.form.content_placeholder}</FieldLabel>
                    <FieldContainer>
                        <FormTextArea name="content" placeHolder={localizations.form.content_placeholder} />
                    </FieldContainer>
                    <FieldLabel>{localizations.form.duedate_placeholder}</FieldLabel>
                    <FieldContainer>
                        <FormDatePicker
                            name="dueDate"
                            placeHolder={localizations.form.duedate_placeholder}
                            showTime
                            disabledDate={(current) => moment(current).isBefore(moment())}
                        />
                    </FieldContainer>
                    <FieldLabel>{localizations.form.reminder_placeholder}</FieldLabel>
                    <FieldContainer>
                        <FormDropDown
                            name="reminderChoice"
                            options={reminderDatesDropDownOptions}
                            placeHolder={localizations.form.reminder_placeholder}
                        />
                    </FieldContainer>
                </Container>
                {openedFromModal && (
                    <FormFooter>
                        <Button minWidth="55px" buttonType="filled" type="submit">
                            {submitLoading ? <Loader width="20px" marginTop="0px" showText={false} /> : <span>{localizations.modal.submit_btn}</span>}
                        </Button>
                    </FormFooter>
                )}
            </Form>
        </Formik>
    );
};
const FieldContainer = styled.div`
    margin-bottom: 12px;
`;

const Container = styled(ModalFormContainer)``;

const FormFooter = styled(ModalFooter)`
    position: absolute;
    bottom: 0;
    width: 100%;
    left: 0;
    right: 0;
    background: white;
    justify-content: flex-end;
`;
const IconOptionWrapper = styled.div`
    display: flex;
`;
const mapStateToProps = (state: RootState) => ({
    localizations: localizationSelectors.tasksStrip(state),
    showLoader: state.loading.effects.tasks.createTask
});

const mapDispatchToProps = (dispatch: any) => ({
    createTask: (task: task) => dispatch.tasks.createTask(task)
});

export default connect(mapStateToProps, mapDispatchToProps)(AddTaskForm);
