import React, { useState } from 'react';
import moment from 'moment';
import { bid } from '../../../../models/entities/bid/bid';
import theme, { styled } from '../../../shared/Theme/theme';
import CZTooltip, { QuestionToolTip } from '../../../shared/CZTooltip/CZTooltip';
import { Formik, Form } from 'formik';
import Button from '../../../shared/SmallComponents/Button';
import { validationSchema } from '../../../../validationSchemas/shipmentBidForm';
import { BaseProps, eBidFormMode } from './ShipmentBidFormConnected';
import Loader from '../../../shared/SmallComponents/Loader';
import { FormCheckbox, FormDatePicker, FormDropDown, FormTextArea } from '../../../shared/inputs/form';
import Parties, { emptyPartyObj } from '../../singleBid/BidForm/sections/Parties';
import { partiesPrepareSubmit, validateParties } from '../../singleBid/BidForm/sections/Parties/utils';
import Documents from '../../singleBid/BidForm/sections/Documents';
import { enumToDropdownOptions } from '../../../shared/inputs/base/DropDown';
import { renderShipmentTypeOption } from '../../../shared/SmallComponents/ShipmentTypeOption';
import { party } from '../../../../models/entities/bid/party';
import { isEmpty } from 'lodash';
import PartiesFormModalConnected from '../../singleBid/BidForm/sections/Parties/PartiesFormModal/PartiesFormModalConnected';
import { ModalTriggerProps } from '../../../shared/Modal/Modal';

type Props = BaseProps & {
    mode: string;
};
export type bidObject = { bid: bid; publish: boolean };

export const eBidShipmentTypes: { [key: string]: string } = {
    AIR: 'Air',
    EXPRESS: 'Express',
    OEX: 'Ocean Express',
    OCEAN_FCL: 'Ocean-FCL',
    OCEAN_LCL: 'Ocean-LCL',
    INLAND: 'Inland'
};
const shipmentTypesDropDownOptions = enumToDropdownOptions(eBidShipmentTypes);

export const renderFieldLabel = (text: string, isMandatory: boolean, tooltipText?: string) => {
    return (
        <LabelContainer>
            <Label>
                {isMandatory && <MandatoryIndication>*</MandatoryIndication>}
                {text}
            </Label>
            {tooltipText && <CZTooltip text={tooltipText}>{QuestionToolTip}</CZTooltip>}
        </LabelContainer>
    );
};
const SingleBidForm = ({
    shipment,
    localizations,
    mode,
    businessPartners,
    onPublishCallBack,
    openedFromModal,
    bid,
    reSendEmail,
    updateBidOverview,
    bidTemplateNote,
    hideSaveAsDraft
}: Props) => {
    const [saveAsDraftLoader, setSaveAsDraftLoader] = useState(false);
    const [publishLoader, setPublishLoader] = useState(false);

    const [formMode, setFormMode] = useState(mode);

    const setInitialValues = () => {
        const initValues = {
            shipmentId: '',
            companyId: '',
            shipmentTypes: [],
            note: bidTemplateNote?.value || null,
            expirationDate: moment(new Date()).add('7', 'days'),
            parties: [emptyPartyObj],
            documents: [],
            includeInsurance: false,
            publish: false
        };
        if ([eBidFormMode.EDIT, eBidFormMode.VIEW].includes(formMode) && bid) {
            return {
                companyId: bid.companyId,
                shipmentId: bid.shipmentId,
                shipmentTypes: bid.shipmentTypes,
                expirationDate: bid.expirationDate,
                note: bid.note,
                parties: bid.parties && bid.parties.length > 0 ? bid.parties : [emptyPartyObj],
                documents: bid.documents,
                includeInsurance: bid.includeInsurance,
                publish: false
            };
        }
        return initValues;
    };
    const formValidationSchema = validationSchema(localizations);

    const validate = (values: any) => {
        const errors: any = {};
        if (!values.shipmentTypes?.length) errors.shipmentTypes = localizations.required_field_message;
        if (values.parties.length === 0 || values.parties[0].id === '') errors.parties = '';
        else if (values.parties.length > 0) {
            validateParties(values.parties, errors, localizations);
        }
        return errors;
    };

    const updateOverview = (values: any) => {
        if (!bid) return;
        setSaveAsDraftLoader(true);
        const bidObject = {
            note: values.note
        } as bid;
        updateBidOverview(bid.id, bidObject, bid.shipmentId).finally(() => {
            setSaveAsDraftLoader(false);
        });
    };

    const disabled = formMode === eBidFormMode.VIEW;
    return (
        <>
            <Formik
                initialValues={setInitialValues()}
                validationSchema={formValidationSchema}
                validate={validate}
                onSubmit={async (values) => {
                    if (formMode === eBidFormMode.VIEW) return;
                    const bidObject = {
                        shipmentId: values.shipmentId,
                        companyId: values.companyId,
                        shipmentTypes: values.shipmentTypes,
                        expirationDate: values.expirationDate,
                        note: values.note,
                        parties: partiesPrepareSubmit(values, businessPartners),
                        includeInsurance: values.includeInsurance,
                        documents: values.documents
                    } as bid;
                    if (onPublishCallBack) {
                        if (values.publish) setPublishLoader(true);
                        else setSaveAsDraftLoader(true);
                        await onPublishCallBack(bidObject, values.publish).finally(() => {
                            setPublishLoader(false);
                            setSaveAsDraftLoader(false);
                        });
                    }
                    if (openedFromModal) openedFromModal.setVisible(false);
                }}
                enableReinitialize
            >
                {({ values, errors, setFieldValue, isSubmitting, isValidating }) => {
                    return (
                        <PageContainer>
                            <Form>
                                <Container>
                                    <StyledFieldContainer>
                                        <div>{renderFieldLabel(localizations.shipment_types, true)}</div>
                                        <FormDropDown
                                            name="shipmentTypes"
                                            placeHolder={localizations.pick_types}
                                            style={{ width: '300px' }}
                                            options={shipmentTypesDropDownOptions}
                                            optionRenderer={renderShipmentTypeOption}
                                            mode="multiple"
                                            disabled={disabled}
                                        />
                                    </StyledFieldContainer>
                                    <Flex>
                                        <StyledFieldContainer>
                                            <div>{renderFieldLabel(localizations.expirationdate, true)}</div>
                                            <div>
                                                <FormDatePicker
                                                    name="expirationDate"
                                                    style={{ width: '300px' }}
                                                    placeHolder={localizations.expirationdate}
                                                    showTime
                                                    disabled={disabled}
                                                    disabledDate={(current) => moment(current).isBefore(moment())}
                                                />
                                            </div>
                                        </StyledFieldContainer>
                                    </Flex>
                                    <Flex>
                                        <StyledFieldContainer>
                                            <div>
                                                <FormCheckbox name="includeInsurance" disabled={disabled}>{localizations.includeInsurance}</FormCheckbox>
                                            </div>
                                        </StyledFieldContainer>
                                    </Flex>
                                    <div>
                                        <div style={{ display: 'flex', alignItems: 'center' }}>
                                            {renderFieldLabel(localizations.parties.choose_forwarders, true)}
                                            {bid && disabled && (
                                                <PartiesFormModalConnected
                                                    shipment={shipment}
                                                    shipmentId={bid.shipmentId}
                                                    bidId={bid.id}
                                                    Trigger={(props: ModalTriggerProps) => (
                                                        <AddPartiesButton onClick={props.onClick} className="material-icons">
                                                            add
                                                        </AddPartiesButton>
                                                    )}
                                                />
                                            )}
                                        </div>
                                        <PartiesContainer>
                                            <Parties
                                                disabled={disabled}
                                                values={values}
                                                setFieldValue={setFieldValue}
                                                businessPartners={businessPartners}
                                                reSendEmail={
                                                    bid?.state === 'IN_PROGRESS' && shipment
                                                        ? (partyId: string) => reSendEmail(bid.id, partyId, shipment)
                                                        : undefined
                                                }
                                                localization={localizations.parties}
                                            />
                                        </PartiesContainer>
                                    </div>
                                    <br />
                                    <StyledFieldContainer>
                                        <div>{renderFieldLabel(localizations.note, false)}</div>
                                        <FormTextArea name="note" placeHolder={localizations.note} />
                                    </StyledFieldContainer>
                                    <div>
                                        <Documents
                                            values={values}
                                            setFieldValue={setFieldValue}
                                            disabled={disabled}
                                            localization={localizations.documents}
                                        />
                                    </div>
                                </Container>
                                <Buttons>
                                    {disabled ? (
                                        <>
                                            <Button
                                                width="auto"
                                                height="30px"
                                                type="button"
                                                buttonType="clean"
                                                textColor="#394372"
                                                onClick={() => setFormMode(eBidFormMode.EDIT)}
                                            >
                                                {saveAsDraftLoader ? (
                                                    <Loader width="20px" marginTop="0px" showText={false} />
                                                ) : (
                                                    <span>{localizations.edit || 'Edit'}</span>
                                                )}
                                            </Button>
                                            <Button
                                                width="auto"
                                                height="30px"
                                                type="button"
                                                buttonType="filled"
                                                onClick={() => updateOverview(values)}
                                            >
                                                {saveAsDraftLoader ? (
                                                    <Loader width="20px" marginTop="0px" showText={false} />
                                                ) : (
                                                    <span>{localizations.save}</span>
                                                )}
                                            </Button>
                                        </>
                                    ) : (
                                        <>
                                            {!hideSaveAsDraft && (
                                                <Button
                                                    width="auto"
                                                    height="30px"
                                                    type="submit"
                                                    buttonType="clean"
                                                    textColor="#394372"
                                                    onClick={() => setFieldValue('publish', false)}
                                                    disabled={formMode === eBidFormMode.VIEW}
                                                >
                                                    {saveAsDraftLoader ? (
                                                        <Loader width="20px" marginTop="0px" showText={false} />
                                                    ) : (
                                                        <span>{localizations.save_as_draft}</span>
                                                    )}
                                                </Button>
                                            )}
                                            <Button
                                                buttonType="filled"
                                                width="100px"
                                                height="30px"
                                                type="submit"
                                                onClick={() => setFieldValue('publish', true)}
                                                disabled={formMode === eBidFormMode.VIEW}
                                            >
                                                {publishLoader ? (
                                                    <Loader width="20px" marginTop="0px" showText={false} />
                                                ) : (
                                                    <span>{localizations.publish}</span>
                                                )}
                                            </Button>
                                        </>
                                    )}
                                </Buttons>
                            </Form>
                        </PageContainer>
                    );
                }}
            </Formik>
        </>
    );
};

const Buttons = styled.div`
    width: 100%;
    padding-top: 25px;
    display: flex;
    justify-content: flex-end;

    button {
        margin-right: 20px;
    }
`;

const LoaderOverlay = styled.div`
    position: absolute;
    width: inherit;
    z-index: 1001;
    height: 100%;
    background: ${theme.colors.overlayBG};
`;
const FieldContainer = styled.div`
    margin-bottom: 12px;
`;
const StyledFieldContainer = styled.div`
    margin-bottom: 12px;
    padding-right: 30px;
`;
const PageContainer = styled.div`
    position: relative;
    width: 100%;
`;

const Label = styled.label`
    color: ${(props) => props.theme.colors.pageTitle};
`;

const MandatoryIndication = styled.span`
    color: red;
    margin-right: 7px;
`;
const AddPartiesButton = styled.span`
    font-size: 16px;
    margin-left: 5px;
    margin-bottom: 5px;
    cursor: pointer;
    color: #008ac9;
`;
const FormHeader = styled.div`
    flex-direction: row;
    justify-content: space-between;
    margin-bottom: 30px;

    & a {
        position: relative;
        left: -30px;
    }
`;

const Container = styled.div`
    position: relative;
    width: 100%;
    padding: 25px;
    padding-left: 0;
`;

const LabelContainer = styled.div`
    display: flex;
    margin-bottom: 5px;

    & img {
        margin-top: -2px;
        margin-left: 5px;
    }
`;
export const Flex = styled.div`
    display: flex;
`;

export const PartiesContainer = styled.div`
    margin-top: 5px;
    max-height: 200px;
    overflow-y: overlay;
`;

export const AddContainer = styled.div`
    cursor: pointer;
    display: inline-flex;
    align-items: center;
    font-size: 13px;
    line-height: 0;
    color: ${(props) => props.theme.colors.pageTitle};
    span.material-icons {
        font-size: inherit;
        margin-right: 5px;
        user-select: none;
    }
`;
export default SingleBidForm;
