import React from 'react';
import { Input } from 'antd';
import styled, { CSSProperties } from 'styled-components';
import { FieldInputProps } from 'formik';
export type BaseProps = {
    placeHolder?: string;
    value?: string | number;
    onChange?: any;
    onFocus?: any;
    style?: CSSProperties;
    name?: string;
    field?: FieldInputProps<any>;
    hasError?: boolean;
    type?: string;
    min?: string | number;
    step?: string | number;
    allowClear?: boolean;
    isPassword?: boolean;
    disabled?: boolean;
    suffix?: React.ReactNode;
};
const TextInput = ({
    onChange,
    hasError,
    value,
    name,
    placeHolder,
    style,
    field,
    type,
    min,
    step,
    allowClear,
    isPassword,
    suffix,
    disabled,
    onFocus
}: BaseProps) => {
    return (
        <Container hasError={hasError}>
            {isPassword ? (
                <Input.Password
                    type={type}
                    autoComplete="off"
                    name={name}
                    {...field}
                    onChange={(e) => {
                        onChange && onChange(e.currentTarget.value);
                        if (field?.onChange) field.onChange(e);
                    }}
                    value={value ?? field?.value}
                    style={style}
                    placeholder={placeHolder}
                    min={min}
                    step={step}
                    allowClear={allowClear}
                    disabled={disabled}
                />
            ) : (
                <Input
                    type={type}
                    autoComplete="off"
                    name={name}
                    {...field}
                    onChange={(e) => {
                        onChange && onChange(e.currentTarget.value);
                        if (field?.onChange) field.onChange(e);
                    }}
                    value={value ?? field?.value}
                    style={style}
                    placeholder={placeHolder}
                    min={min}
                    step={step}
                    allowClear={allowClear}
                    suffix={suffix}
                    onFocus={onFocus}
                    disabled={disabled}
                />
            )}
        </Container>
    );
};
TextInput.defaultProps = {
    type: 'text'
};
interface ContainerProps {
    hasError?: boolean;
}
const Container = styled.div<ContainerProps>`
    position: relative;
    & .ant-input,
    .ant-input-affix-wrapper {
        border-radius: 70px;
        border-color: ${(props) => (props.hasError ? 'red' : props.theme.colors.primaryBlue)} !important;
    }
    .ant-input-affix-wrapper > input.ant-input {
        padding-left: 2px;
    }
    & .ant-input:focus,
    .ant-input-affix-wrapper:focus,
    .ant-input-affix-wrapper-focused {
        box-shadow: ${(props) => (props.hasError ? '0 0 0 2px rgb(255 24 24 / 20%)' : '0 0 0 2px rgba(24, 144, 255, 0.2)')};
    }
`;
export default TextInput;
