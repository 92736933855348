import React, { useEffect, useState } from 'react';
import { localSDK as client } from '../../../sdk';
import FileExtIcon from '../Grid/specialColumns/FileExtIcon';
import CustomModal, { ModalTriggerProps } from '../Modal/Modal';
import Loader from '../SmallComponents/Loader';
import { styled } from '../Theme/theme';
import Iframe from 'react-iframe';
import { downloadFileByBlobUrl } from '../../../utils/files';
import { ModalPreviewContainer } from './ModalPreview';
import { previewFileModel } from '../../../sdk/controllers/files/IFiles';
import { connect } from 'react-redux';
import { RootState } from '../../../state/store/store';
import { localizationSelectors } from '../../../state/ducks/localization/selectors';
export type PreviewBaseProps = {
    fileName: string;
    fileId: string;
    fileUrl?: string;
};
type PreviewFileProps = PreviewBaseProps & {
    src: string;
    width: string;
    height: string;
    allowClick?: boolean;
    localization: any;
};
const OFFICE_APPS = ['doc', 'docx', 'xlsx', 'xls', 'csv', 'pptx', 'ppt'];
const IFRAME_SUPPORT = ['pdf', 'txt', ...OFFICE_APPS];
const IMAGES_SUPPORT = ['png', 'jpg', 'jpeg'];

const PreviewFile = ({ src, width, height, fileName, fileId, allowClick, localization }: PreviewFileProps) => {
    const type = (fileName?.split('.').pop() || '').toLowerCase();
    let children = null;
    if (IMAGES_SUPPORT.includes(type)) children = <img src={src} alt={fileName} width={width} height={height} />;
    else if (IFRAME_SUPPORT.includes(type)) {
        let url = src;
        if (OFFICE_APPS.includes(type)) {
            url = `https://view.officeapps.live.com/op/embed.aspx?src=${encodeURIComponent(url)}`;
        }
        children = (
            <div style={{ position: 'relative', width: '100%', height: '100%' }}>
                {allowClick && <IframeBlocker></IframeBlocker>}
                <Iframe id={fileId} height="100%" url={url} width="100%" frameBorder={0} />
            </div>
        );
    } else {
        children = allowClick ? (
            <FileExtIcon filename={fileName} />
        ) : (
            <div style={{ fontSize: '60px', color: '#e4e4e4', display: 'flex', alignItems: 'center' }}>
                <span style={{ fontSize: 'inherit' }} className="material-icons">
                    remove_red_eye
                </span>
                {localization.no_preview}
            </div>
        );
    }
    return children;
};
PreviewFile.defaultProps = {
    allowClick: false
};

const IframeBlocker = styled.div`
    height: 100%;
    width: calc(100% - 20px);
    position: absolute;
`;

export type getUrlFuncType = (fileId: string, ...args: any[]) => Promise<previewFileModel | undefined>;

type PreviewFileAsync = PreviewBaseProps & {
    width: string;
    height: string;
    setParentFile?: (url: previewFileModel) => void;
    showPreview?: boolean;
    getFileUrl: getUrlFuncType;
    customFileUrlFunc?: getUrlFuncType;
    localization: any;
};

const PreviewFileAsync = ({
    fileId,
    fileUrl,
    fileName,
    showPreview,
    width,
    height,
    setParentFile,
    getFileUrl,
    customFileUrlFunc,
    localization
}: PreviewFileAsync) => {
    const [loader, setLoader] = useState(false);
    const [previewFile, setPreviewFile] = useState<undefined | previewFileModel>();
    const getUrl = customFileUrlFunc || getFileUrl;

    const isImage = IMAGES_SUPPORT.includes((fileName?.split('.').pop() || '').toLowerCase());
    useEffect(() => {
        if (!isImage || !fileUrl) {
            setLoader(true);
            getUrl(fileId)
                .then((urls) => {
                    if (urls) {
                        setPreviewFile(urls);
                        setParentFile && setParentFile(urls);
                    }
                })
                .finally(() => {
                    setLoader(false);
                });
        }
    }, [fileId, fileUrl, fileName]);

    const previewFileUrl = previewFile?.fileUrl;
    return loader || !((fileUrl && isImage) || previewFileUrl) ? (
        <AttachmentContainer>
            <FileExtIcon filename={fileName} />
        </AttachmentContainer>
    ) : showPreview ? (
        <PreviewFile
            src={`${previewFileUrl || fileUrl}`}
            fileId={fileId}
            fileName={fileName}
            width={width}
            height={height}
            localization={localization}
        />
    ) : (
        <CustomModal
            title={{ text: fileName }}
            Trigger={(props: ModalTriggerProps) => (
                <PreviewContainer onClick={props.onClick}>
                    <PreviewFile
                        src={`${previewFileUrl || fileUrl}`}
                        fileId={fileId}
                        fileName={fileName}
                        width="100%"
                        height="100%"
                        allowClick={true}
                        localization={localization}
                    />
                </PreviewContainer>
            )}
            Content={() => {
                return (
                    <ModalPreviewContainer>
                        <PreviewFile
                            src={`${previewFileUrl || fileUrl}`}
                            fileId={fileId}
                            fileName={fileName}
                            width="auto"
                            height="auto"
                            localization={localization}
                        />
                    </ModalPreviewContainer>
                );
            }}
            confirmBtn={{
                handler: () => {
                    if (previewFile) downloadFileByBlobUrl(previewFile.blobUrl, fileName);
                    getUrl(fileId).then((urls) => {
                        if (urls) {
                            setPreviewFile(urls);
                            setParentFile && setParentFile(urls);
                            downloadFileByBlobUrl(urls.blobUrl, fileName);
                        }
                    });
                },
                text: localization.modal.download_btn,
                closeModal: false
            }}
            width="90%"
            maxWidth="90%"
            fullScreen
        />
    );
};
PreviewFileAsync.defaultProps = {
    width: '100%',
    height: '100%'
};

const AttachmentContainer = styled.div`
    padding: 8px;
    background-color: #e1e1e145;
    margin: 1px 0px;
    display: flex;
    align-items: center;
`;

export const PreviewContainer = styled.div`
    position: relative;
    height: 100%;
    cursor: pointer;
    display: flex;
    width: 100%;
    :hover {
        opacity: 0.6;
    }
`;

const mapStateToProps = (state: RootState) => ({
    localization: localizationSelectors.files(state).preview
});

const mapDispatchToProps = (dispatch: any) => ({
    getFileUrl: (fileId: string, ...args: any[]) => dispatch.user.getFileUrl(fileId)
});

export default React.memo(connect(mapStateToProps, mapDispatchToProps)(PreviewFileAsync));
