import React, { useState } from 'react';
import { connect } from 'react-redux';
import TabsContainer from '../../../../shared/Tabs/TabsContainer';
import Tab from '../../../../shared/Tabs/Tab';
import { order } from '../../../../../models/entities/order/order';
import OverviewItems from './../OverviewItems/OverviewItems';
import Events from './Events';
import OrderDocuments from './Documents';
import { singleOrderSelectors } from '../../../../../state/ducks/singleOrder/selectors';
import { RootState } from '../../../../../state/store/store';
import { TimeLineItem } from '../../../singleShipment/sections/Events/express/TimeLine';
import History from '../../../commonSubEntities/history/HistoryTab';
import { orderEvent } from '../../../../../models/entities/order/orderEvent/orderEvent';
import { deleteOrderEventPayload } from '../../../../../models/entities/order/orderEvent/payloads';

type Props = {
    order: order;
    eventsTabData: TimeLineItem<orderEvent>[];
    localization: any;
    deleteOrderEvent: (payload: deleteOrderEventPayload) => Promise<void>;
};

const OrderTabs = ({ order, localization, eventsTabData, deleteOrderEvent }: Props) => {
    const [activeTab, setActiveTab] = useState('Items');

    return (
        <TabsContainer>
            <Tab title="Items" onClick={() => setActiveTab('Items')} isActive={activeTab === 'Items'}>
                <OverviewItems order={order} />
            </Tab>
            <Tab title="Events" onClick={() => setActiveTab('Events')} isActive={activeTab === 'Events'}>
                <Events eventsTabData={eventsTabData} localization={localization} deleteOrderEvent={deleteOrderEvent} />
            </Tab>
            <Tab title="Documents" onClick={() => setActiveTab('Documents')} isActive={activeTab === 'Documents'}>
                <OrderDocuments documents={order.documents} orderId={order.id} localization={localization} />
            </Tab>
            <Tab title="History" onClick={() => setActiveTab('History')} isActive={activeTab === 'History'}>
                <History history={order.history} localization={localization} />
            </Tab>
        </TabsContainer>
    );
};
const mapStateToProps = (state: RootState) => ({
    eventsTabData: singleOrderSelectors.eventsTabData(state)
});

const mapDispatchToProps = (dispatch: any) => ({
    deleteOrderEvent: (payload: deleteOrderEventPayload) => dispatch.orders.deleteOrderEvent(payload)
});

export default connect(mapStateToProps, mapDispatchToProps)(OrderTabs);
