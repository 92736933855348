import React, { PropsWithChildren } from 'react';
import { connect } from 'react-redux';
import ResponsiveDrawer, { desktopVariantType } from './ResponsiveDrawer';
import { drawers } from '../../../state/ducks/header/header';
import NotificationsPageConnected from '../../pages/Notifications/NotificationsPageConnected';
import { headerSelectors } from '../../../state/ducks/header/selectors';
import { RootState } from '../../../state/store/store';
import TasksStripConnected from '../../pages/Dashboard/Tasks/TasksStripConnected';
import ShipmentPaneConnected from '../ShipmentPane/ShipmentPaneConnected';
import ChatPaneConnected from '../../pages/Chat/ChatPaneConnected';
import { featureFlagsModel } from '../../../models/entities/businessPartner/settings';
import { userSelectors } from '../../../state/ducks/user/selectors';
import ChatRequestsConnected from '../../pages/Chat/ChatRequestsConnected';
import FollowingTabs from '../../pages/Following/FollowingTabs';
import QuotationPane from '../../pages/singleShipment/Quotation/QuotationPane';
import TariffQuotationPane from '../../pages/singleShipment/sections/TariffQuotations/TariffQuotationPane';
import AddEditUserPaneConnected from '../../pages/Admin/Users/AddEditUser/AddEditUserPaneConnected';
import AddEditCompanyPaneConnected from '../../pages/Admin/Companies/AddEditCompany/AddEditCompanyPaneConnected';
import ProductView from '../../pages/products/components/ProductView';
import ProductForm from '../../pages/products/components/Form/ProductForm';
import InventoryFormConnected from '../../pages/inventory/components/Form/InventoryFormConnected';

type Props = {
    setDrawerOpen: (open: drawers) => void;
    isEmptyStateDrawer: boolean;
    drawerOpen: drawers;
    drawerParams: any;
    isSideBarOpen: boolean;
    setSideBarOpen: (open: boolean) => void;
    printMode?: boolean;
    featureFlags?: featureFlagsModel;
};
const specialConfiguration: { [key: string]: { defaultDrawerWidth?: number; drawerVariant?: desktopVariantType } } = {
    QUOTATION: {
        drawerVariant: 'temporary',
        defaultDrawerWidth: 710
    },
    ADD_EDIT_USER: {
        defaultDrawerWidth: 710
    },
    ADD_EDIT_COMPANY: {
        defaultDrawerWidth: 710
    },
    PRODUCT: {
        drawerVariant: 'temporary',
        defaultDrawerWidth: 710
    },
    INVENTORY: {
        drawerVariant: 'temporary',
        defaultDrawerWidth: 710
    },
    FOLLOW: {
        drawerVariant: 'temporary',
        defaultDrawerWidth: 710
    }
};
const ResponsiveDrawerConnected = ({
    setDrawerOpen,
    isEmptyStateDrawer,
    drawerOpen,
    drawerParams,
    isSideBarOpen,
    setSideBarOpen,
    printMode,
    featureFlags,
    children
}: PropsWithChildren<Props>) => {
    const drawerContent = (() => {
        switch (drawerOpen) {
            case 'NOTIFICATION':
                return <NotificationsPageConnected />;
            case 'TASK':
                return <TasksStripConnected />;
            case 'FOLLOW':
                return <FollowingTabs />;
            case `SHIPMENT${drawerParams?.shipmentId}`:
                return <ShipmentPaneConnected params={drawerParams} />;
            case `CHAT-${drawerParams?.chatType}-${JSON.stringify(drawerParams?.chatId || drawerParams?.participantsIds)}`:
                return <ChatPaneConnected params={drawerParams} />;
            case 'CHAT_REQUESTS':
                return <ChatRequestsConnected />;
            case 'QUOTATION':
                return <QuotationPane params={drawerParams} />;
            case 'TARIFF_QUOTATION':
                return <TariffQuotationPane params={drawerParams} />;
            case `ADD_EDIT_USER${drawerParams?.userId || ''}`:
                return <AddEditUserPaneConnected params={drawerParams} />;
            case `ADD_EDIT_COMPANY${drawerParams?.userId || ''}`:
                return <AddEditCompanyPaneConnected params={drawerParams} />;
            case 'PRODUCT':
                return <ProductView params={drawerParams} />;
            case 'INVENTORY':
                return <InventoryFormConnected mode={'NEW'} />;
            default:
                return <div></div>;
        }
    })();

    const config = drawerOpen ? Object.entries(specialConfiguration).find(([key, _]) => drawerOpen.includes(key))?.[1] : undefined;
    return (
        <>
            <ResponsiveDrawer
                isOpen={drawerOpen !== null}
                setIsOpen={() => setDrawerOpen(drawerOpen)}
                drawerContent={drawerContent}
                isEmptyState={isEmptyStateDrawer}
                isSideBarOpen={isSideBarOpen}
                setSideBarOpen={setSideBarOpen}
                printMode={printMode}
                hasChat={featureFlags?.CHAT}
                desktopVariant={config?.drawerVariant}
                defaultDrawerWidth={config?.defaultDrawerWidth}
            >
                {children}
            </ResponsiveDrawer>
        </>
    );
};

const mapStateToProps = (state: RootState) => ({
    drawerOpen: headerSelectors.drawerOpen(state),
    drawerParams: headerSelectors.drawerParams(state),
    isEmptyStateDrawer: headerSelectors.isEmptyStateDrawer(state),
    isSideBarOpen: headerSelectors.sideBarOpen(state),
    printMode: headerSelectors.printMode(state),
    featureFlags: userSelectors.featureFlags(state)
});

const mapDispatchToProps = (dispatch: any) => ({
    setDrawerOpen: (open: drawers) => dispatch.header.setDrawerOpen(open),
    setSideBarOpen: (open: boolean) => dispatch.header.setSideBarOpen(open)
});

export default connect(mapStateToProps, mapDispatchToProps)(ResponsiveDrawerConnected);
