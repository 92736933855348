// eslint-disable-next-line import/no-anonymous-default-export
export default {
    en: {
        state: [
            { code: 'DRAFT', name: 'Draft', description: 'Draft', displayOrder: 0, color: '#b2b2b2' },
            { code: 'WAITING_FOR_APPROVAL', name: 'Waiting for approval', description: 'Waiting for approval', displayOrder: 1, color: '#007acc' },
            { code: 'APPROVED', name: 'Approved', description: 'Approved', displayOrder: 2, color: 'green' },
            { code: 'PARTIAL_APPROVED', name: 'Partial approved', description: 'Partial approved', displayOrder: 3, color: 'orange' },
            { code: 'REJECTED', name: 'Rejected', description: 'Rejected', displayOrder: 4, color: 'red' },
            { code: 'CLOSED', name: 'Closed', description: 'Closed', displayOrder: 7, color: '#b2b2b2' }
        ],
        approverState: [
            { code: 'WAITING', name: 'Waiting', description: 'Waiting', displayOrder: 0, color: 'orange' },
            { code: 'APPROVED', name: 'Approved', description: 'Approved', displayOrder: 1, color: 'green' },
            { code: 'DECLINE', name: 'Decline', description: 'Decline', displayOrder: 2, color: 'red' }
        ],
        documentTypes: [{ code: 'OTHER', name: 'Other', description: 'Other file description' }]
    }
};
