import * as Yup from 'yup';

export const orderFormValidationSchema = (localization: any) => {
    const requiredFieldMessage = 'Required';
    const yupStringRequired = Yup.string().required(requiredFieldMessage);

    return Yup.object({
        companyId: yupStringRequired,
        orderDate: yupStringRequired.nullable(),
        customerBusinessPartnerId: yupStringRequired
    });
};
