import { event } from '../../../../models/entities/common_subentities/event';
type stateType = { [key: string]: any };
function setEvents(state: stateType, entityId: string, entityType: keyof stateType, events: Array<event>) {
    const newArr = [...state[entityType]];
    const index = newArr.findIndex((item) => item.id === entityId);
    if (index !== -1) {
        newArr[index].events = events;
    }
    return newArr;
}
function addEvent(
    state: stateType,
    entityId: string,
    entityType: keyof stateType,
    event: event,
    checkLastEvent: (events?: Array<event>) => event | undefined
) {
    const newArr = [...state[entityType]];
    const index = newArr.findIndex((item) => item.id === entityId);
    if (index !== -1) {
        let events = [event];
        const oldEvents = newArr[index].events;
        if (oldEvents && oldEvents.length > 0) events = [...oldEvents, ...events];
        newArr[index].events = events;

        const lastEvent = checkLastEvent(events);
        if (lastEvent) newArr[index].lastEvent = lastEvent.eventCode;
    }
    return newArr;
}
function editEvent(
    state: stateType,
    entityId: string,
    entityType: keyof stateType,
    event: event,
    checkLastEvent: (events?: Array<event>) => event | undefined
) {
    const newArr = [...state[entityType]];
    const index = newArr.findIndex((item) => item.id === entityId);

    if (index !== -1) {
        const events = newArr[index].events?.map((item: event) => {
            if (item.id === event.id) {
                item.comment = event.comment;
                item.eventCode = event.eventCode;
                item.eventDate = event.eventDate;
            }
            return { ...item };
        });
        newArr[index].events = events;

        const lastEvent = checkLastEvent(events);
        if (lastEvent) newArr[index].lastEvent = lastEvent.eventCode;
    }
    return newArr;
}
function removeEvent(
    state: stateType,
    entityId: string,
    entityType: keyof stateType,
    eventId: number,
    checkLastEvent: (events?: Array<event>) => event | undefined
) {
    const newArr = [...state[entityType]];
    const index = newArr.findIndex((item) => item.id === entityId);
    if (index !== -1) {
        const oldEvents = newArr[index].events?.filter((item: event) => item.id !== eventId);
        newArr[index].events = oldEvents;

        const lastEvent = checkLastEvent(oldEvents);
        if (lastEvent) newArr[index].lastEvent = lastEvent.eventCode;
    }
    return newArr;
}

export { setEvents, addEvent, editEvent, removeEvent };
