import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import QuotationReplyPage from './QuotationReplyPage';
import { RouteComponentProps, useLocation, withRouter } from 'react-router-dom';
import { RootState, Dispatch } from '../../../../state/store/store';
import { QuotationPublicDetails } from '../../../../sdk/controllers/bids/IBids';
import Loader from '../../../shared/SmallComponents/Loader';
import { localizationSelectors } from '../../../../state/ducks/localization/selectors';
import ExceptionPage from '../../../../components/pages/ExceptionPage/ExceptionPage';
import { getUrlFuncType } from '../../../shared/FilesPreview/Preview';
import { businessPartnersSelectors } from '../../../../state/ducks/businessPartner/selectors';
import { settings } from '../../../../models/entities/businessPartner/businessPartner';

type PathParams = {
    bidToken: string;
};

type Props = RouteComponentProps<PathParams> & {
    fetchByToken: (token: string) => Promise<QuotationPublicDetails>;
    createQuotation: (formData: FormData, isPublic?: boolean) => Promise<boolean>;
    fetchError?: string;
    localization: any;
    quotationGetFile: getUrlFuncType;
    companySettings: settings;
};

const QuotationReplyPageConnected = ({
    match,
    fetchByToken,
    localization,
    createQuotation,
    fetchError,
    quotationGetFile,
    companySettings
}: Props) => {
    const location = useLocation<{ quotationPublicDetails?: QuotationPublicDetails } | undefined>();
    const [data, setData] = useState<QuotationPublicDetails | undefined>();
    const bidToken = match.params.bidToken;
    useEffect(() => {
        if (bidToken) {
            fetchByToken(bidToken)
                .then((d) => {
                    setData(d);
                })
                .catch((e) => { });
        }
    }, [bidToken]);
    useEffect(() => {
        if (location?.state?.quotationPublicDetails) {
            setData(location.state.quotationPublicDetails);
        }
    }, [location]);

    if (fetchError) {
        let error = { message: undefined, status: 500 };
        try {
            error = JSON.parse(fetchError);
        } catch (e) { }
        return <ExceptionPage status={error.status} title={error.message || localization.reply_page_default_error_message} />;
    }
    if (!data) return <Loader />;
    return (
        <>
            <QuotationReplyPage
                data={data}
                localization={localization}
                createQuotation={createQuotation}
                quotationGetFile={quotationGetFile}
                isPublic={!!bidToken}
                companySettings={companySettings}
            />
        </>
    );
};

const mapStateToProps = (state: RootState) => ({
    localization: localizationSelectors.quotation(state),
    fetchError: state.error.effects.bids.fetchByToken,
    companySettings: businessPartnersSelectors.settingsByLanguage(state)
});

const mapDispatchToProps = (dispatch: Dispatch) => ({
    fetchByToken: (token: string) => dispatch.bids.fetchByToken(token),
    createQuotation: (formData: FormData, isPublic?: boolean) => dispatch.bids.createQuotation({ formData, isPublic }),
    quotationGetFile: (fileId: string, bidId: string, partyId: string, isPublic?: boolean) =>
        dispatch.bids.quotationGetFile({ fileId, bidId, partyId, isPublic })
});

export default withRouter(connect(mapStateToProps, mapDispatchToProps as any)(QuotationReplyPageConnected));
