import * as Yup from 'yup';

export const createUserSchema = (localization: any) => {
    return Yup.object().shape({
        firstName: Yup.string().required(localization.errors.required).test('len', localization.errors.max_50, maxLengthValidation(50)),
        lastName: Yup.string().required(localization.errors.required).test('len', localization.errors.max_50, maxLengthValidation(50))
    });
};

const maxLengthValidation = (maxLength: number) => {
    return (val: any) => (val ? val.toString().length <= maxLength : true);
};
