import React, { useEffect } from 'react';
import styled from 'styled-components';
import { containersGridView } from '../../../models/entities/containers/containersGridView';
import { containersStatsType } from '../../../models/entities/containers/containersStats';
import { settings } from '../../../models/entities/shipment/shipment';
import ContainersGrid from './components/ContainersGrid';
import ContainersPageHeader from './components/ContainersPageHeader';
import CZTooltip, { QuestionToolTip } from '../../shared/CZTooltip/CZTooltip';

type Props = {
    fetchContainersGridView(): () => void;
    updateContainerState: (shipmentId: string, containerId: string, state: string) => Promise<void>;
    filterContainersByTerm: (term: string) => void;
    textFilterValue: string;
    filteredContainers: containersGridView;
    settings?: settings;
    localization?: any;
    showLoader: boolean;
    containersStats: containersStatsType;
};

const ContainersPage = ({
    localization,
    showLoader,
    filteredContainers,
    settings,
    textFilterValue,
    filterContainersByTerm,
    updateContainerState,
    fetchContainersGridView,
    containersStats
}: Props) => {
    const stats: { title: string; value: number; tooltip?: string }[] = Object.entries(containersStats)
        .map(([key, value]) => {
            return {
                title: localization.stats[key],
                tooltip: localization.stats[`${key}Tooltip`],
                value
            };
        })
        .concat([
            {
                title: localization.stats.estimateCosts,
                tooltip: localization.stats.estimateCostsTooltip,
                value: 23
            }
        ]);
    return (
        <PageWrapper>
            <HeaderWrapper>
                <ContainersPageHeader
                    containers={filteredContainers}
                    fetchContainersGridView={fetchContainersGridView}
                    filterContainersByTerm={filterContainersByTerm}
                    textFilterValue={textFilterValue}
                    localization={localization}
                />
            </HeaderWrapper>
            <ContainersStatsWrapper>
                {stats.map(({ title, value, tooltip }, index) => (
                    <ContainersStat key={index}>
                        <StatTitle>
                            {title} {!!tooltip && <CZTooltip text={tooltip}>{QuestionToolTip}</CZTooltip>}
                        </StatTitle>
                        <StatValue>{value}</StatValue>
                    </ContainersStat>
                ))}
            </ContainersStatsWrapper>
            <ContainersGridWrapper>
                <ContainersGrid
                    containersGridData={filteredContainers}
                    settings={settings}
                    showLoader={showLoader}
                    updateContainerState={updateContainerState}
                    localization={localization}
                />
            </ContainersGridWrapper>
        </PageWrapper>
    );
};

const PageWrapper = styled.div`
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
`;
const HeaderWrapper = styled.div`
    width: 100%;
    padding: 20px 30px;
`;
const PageTitle = styled.div`
    font-weight: bold;
    font-size: 24px;
`;
const ContainersStatsWrapper = styled.div`
    width: 100%;
    padding: 15px 10px;
    border-top: 1px solid #e0e0e0;
    border-bottom: 1px solid #e0e0e0;
    display: flex;
    div:first-child {
        border-left: 0;
    }
`;
const ContainersStat = styled.div`
    flex: 1;
    padding: 0 20px;
    border-left: 1px solid #e0e0e0;
`;
const StatTitle = styled.div`
    display: flex;
    align-items: flex-start;
    margin-bottom: 20px;
    font-size: 15px;
`;
const StatValue = styled.div`
    font-size: 32px;
    color: #158cc6;
`;
const ContainersGridWrapper = styled.div`
    flex: 1;
`;

export default ContainersPage;
