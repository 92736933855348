import React from 'react';
import { document } from '../../../../../models/entities/common_subentities/document';
import DocumentFormConnected from '../../DocumentForm/DocumentFormConnected';
import { settings } from '../../../../../models/entities/shipment/shipment';
import claimsType from '../../../../../models/entities/claimsType';
import MultipleDocumentFormConnected from '../../DocumentForm/MultipleDocumentFormConnected';
import Documents from '../../../commonSubEntities/documents/Documents';

type Props = {
    documents?: Array<document>;
    requiredDocs?: Array<string>;
    localization: any;
    settings?: settings;
    printMode?: boolean;
    dismissRequiredDoc?: (shipmentId: string, docType: string) => string;
    userShipmentDocsClaims?: claimsType;
    shipmentId: string;
};

const ShipmentDocuments = ({
    documents,
    localization,
    settings,
    requiredDocs,
    printMode,
    dismissRequiredDoc,
    shipmentId,
    userShipmentDocsClaims
}: Props) => {
    return (
        <Documents<settings>
            documents={documents}
            localization={localization}
            settings={settings}
            requiredDocs={requiredDocs}
            menuType="shipmentDocumentMenu"
            MultipleDocumentFormConnected={MultipleDocumentFormConnected}
            DocumentFormConnected={DocumentFormConnected}
            printMode={printMode}
            dismissRequiredDoc={dismissRequiredDoc}
            userDocumentsClaims={userShipmentDocsClaims}
            entityId={shipmentId}
        />
    );
};

export default ShipmentDocuments;
