import React from 'react';
import { history } from '../../../../models/entities/common_subentities/history';
import { styled } from '../../../shared/Theme/theme';
import moment from 'moment';
import UserBadge from '../../../shared/UserBadge/UserBadge';

type Props = {
    localization: any;
    item: history;
    onClick: () => void;
    isActive?: boolean;
};

const HistoryItem = ({ item, localization, onClick, isActive }: Props) => {
    const { item_dictionary } = localization.history;
    return (
        <Item onClick={onClick} isActive={isActive}>
            <ImageWrapper>
                <UserBadge width={30} userId={item.userId} userName={item.userName} hideBadgeName={true} borderColor="#f7f7f7" />
            </ImageWrapper>
            <div>
                {item.userName} {getFromDictionary(item.actionType, item.entityType, item_dictionary)}{' '}
                {item.subEntityName ? `"${item.subEntityName}"` : ''}
            </div>
            <DateWrapper>{moment(item.createdDate).format('DD/MM/YY HH:mm:ss')}</DateWrapper>
        </Item>
    );
};

function getFromDictionary(actionType: string, entityType: string, item_dictionary: any) {
    const str = actionType + '_' + entityType;
    if (item_dictionary && item_dictionary[str]) return item_dictionary[str];
    else return `action ${actionType} entityType ${entityType}`;
}
interface ItemProps {
    isActive?: boolean;
}
const Item = styled.div<ItemProps>`
    display: flex;
    height: 57px;
    align-items: center;
    position: relative;
    background: ${(props) => (props.isActive ? '#deecf9' : '#f9f9f9')};
    cursor: pointer;
    :hover {
        background: #e3e3e3;
    }
`;

const ImageWrapper = styled.div`
    width: 40px;
    height: 40px;
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 7px;
`;

const DateWrapper = styled.div`
    position: absolute;
    right: 10px;
    bottom: 5px;
    font-size: 11px;
    color: gray;
`;

export default HistoryItem;
