import React, { useState } from 'react';
import styled from 'styled-components';
import { checkOrderConfirmationResponse, confirmOrderPayload } from '../../../../models/entities/shipment/public/orderConfirmation';
import RippleContainer from '../../../shared/Animations/RippleContainer';
import Button from '../../../shared/SmallComponents/Button';
import Loader from '../../../shared/SmallComponents/Loader';
import Confetti from 'react-confetti';
import useWindowSize from '../../../../hooks/windowSize';
type Props = {
    localization: any;
    confirmOrder: (payload: confirmOrderPayload) => Promise<void>;
    checkOrderConfirmedResp: checkOrderConfirmationResponse;
};
const OrderConfirmationPage = ({ localization, confirmOrder, checkOrderConfirmedResp }: Props) => {
    const [confirmedStatus, setConfirmedStatus] = useState<checkOrderConfirmationResponse>(checkOrderConfirmedResp);
    const [isSubmitConfirm, setIsSubmitConfirm] = useState<boolean>(false);

    const onConfirmOrder = async (confirmed: boolean) => {
        setIsSubmitConfirm(true);
        await confirmOrder({ confirmed });
        if (confirmed) setConfirmedStatus({ confirmed });
        else setConfirmedStatus({ rejected: true });
        setIsSubmitConfirm(false);
    };

    const [width, height] = useWindowSize();

    const { confirmed, rejected } = confirmedStatus;

    return (
        <>
            {!isSubmitConfirm && confirmed && <Confetti width={width} height={height - 64} colors={['#509ed7', 'white']} />}
            <PageContainer>
                <Title>{confirmed ? localization.confirmed_title : rejected ? localization.rejected_title : localization.ask_confirm_title}</Title>
                {!confirmed && !rejected && (
                    <ButtonsContainer>
                        <div
                            style={{
                                position: 'absolute',
                                top: 0,
                                right: 0
                            }}
                        >
                            {isSubmitConfirm && <Loader width={'20px'} showText={false} />}
                        </div>
                        <RippleContainer style={{ borderRadius: '100px', margin: '10px' }} color="#fff">
                            <Button
                                onClick={async () => await onConfirmOrder(false)}
                                disabled={isSubmitConfirm}
                                width={'250px'}
                                height={'61px'}
                                buttonType="hollow"
                                color="#ffffff"
                            >
                                {localization.reject}
                            </Button>
                        </RippleContainer>
                        <RippleContainer style={{ borderRadius: '100px', margin: '10px' }} color="#008ac9">
                            <Button
                                onClick={async () => await onConfirmOrder(true)}
                                disabled={isSubmitConfirm}
                                width={'250px'}
                                height={'61px'}
                                buttonType="filled"
                                color="white"
                                textColor="#008ac9"
                                border="none"
                            >
                                {localization.confirm}
                            </Button>
                        </RippleContainer>
                    </ButtonsContainer>
                )}
            </PageContainer>
        </>
    );
};

const PageContainer = styled.div`
    width: 100%;
    height: 100%;
    background-color: ${(props) => props.theme.colors.primaryBlue};
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
`;
const Title = styled.div`
    color: #fff;
    font-size: 40px;
    font-weight: 500;
    margin-bottom: 50px;
`;

const ButtonsContainer = styled.div`
    position: relative;
    display: flex;
    justify-content: center;
    width: 40%;
    padding: 20px;
    margin-bottom: 150px;
    flex-wrap: wrap;
`;

export default OrderConfirmationPage;
