export const orderStates = {
    NEW: {
        displayOrder: 0,
        color: '#b2b2b2',
        code: 'NEW',
        name: 'New'
    },
    READY_TO_SHIP: {
        displayOrder: 1,
        color: '#007acc',
        code: 'READY_TO_SHIP',
        name: 'Ready To Ship'
    },
    PICKED_UP: {
        displayOrder: 2,
        color: '#007acc',
        code: 'PICKED_UP',
        name: 'Picked Up'
    },
    IN_TRANSIT: {
        displayOrder: 3,
        color: '#007acc',
        code: 'IN_TRANSIT',
        name: 'In Transit'
    },
    DELIVERED: {
        displayOrder: 4,
        color: '#339933',
        code: 'DELIVERED',
        name: 'Delivered'
    },
    EXCEPTION: {
        displayOrder: 5,
        color: 'red',
        code: 'EXCEPTION',
        name: 'Exception'
    },
    EXCEPTION_HANDLING: {
        displayOrder: 6,
        color: 'red',
        code: 'EXCEPTION_HANDLING',
        name: 'Exception Handling'
    },
    CANCELED: {
        displayOrder: 7,
        color: 'red',
        code: 'CANCELED',
        name: 'Canceled'
    }
} as const;

export type OrderStateType = keyof typeof orderStates;
