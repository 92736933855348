import React from 'react';
import { inventoryTotal, inventoryLog, eInventoryActionType } from '../../../../../../models/entities/product/inventoryLog';
import { ColumnType } from '../../../../../shared/Grid/types/Column';
import { PagesRoutes } from '../../../../../../routing/PagesRoutes';
import { Link } from 'react-router-dom';

export const totalsColumns: Array<ColumnType<inventoryTotal>> = [
    {
        field: 'warehouseName',
        sortable: false,
        isDisplayed: true,
        bindedFields: ['warehouseId'],
        width: '200px',
        renderer: (cellValue: any, bindedData?: Array<any> | null) => (
            <Link to={{ pathname: `${PagesRoutes.BusinessPartners}/${bindedData?.[0]}` }}>{cellValue}</Link>
        )
    },
    {
        field: 'reserved',
        sortable: false,
        isDisplayed: true
    },
    {
        field: 'onHand',
        sortable: false,
        isDisplayed: true
    },
    {
        field: 'inbound',
        sortable: false,
        isDisplayed: true
    },
    {
        field: 'outbound',
        sortable: false,
        isDisplayed: true
    },
    {
        field: 'available',
        sortable: false,
        isDisplayed: true
    }
];

export const logsColumns: Array<ColumnType<inventoryLog>> = [
    {
        field: 'actionType',
        sortable: false,
        isDisplayed: true,
        width: '200px',
        renderer: (cellValue: any, bindedData: any) => <div>{eInventoryActionType[cellValue]}</div>
    },
    {
        field: 'cost',
        sortable: true,
        isDisplayed: true,
        bindedFields: ['costCurrency'],
        columnType: 'currency_input'
    },
    {
        field: 'quantity',
        sortable: false,
        isDisplayed: true
    },
    {
        field: 'location',
        sortable: false,
        isDisplayed: true
    },
    {
        field: 'reason',
        sortable: false,
        isDisplayed: true
    },
    {
        field: 'createdAt',
        sortable: false,
        isDisplayed: true,
        columnType: 'date'
    }
];
