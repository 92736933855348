import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import LoginPage from './LoginPage';
import { Redirect, withRouter, RouteComponentProps, useLocation } from 'react-router-dom';
import { RootState } from '../../../state/store/store';
import { PagesRoutes } from '../../../routing/PagesRoutes';
import { authenticationSelectors } from '../../../state/ducks/authentication/selectors';
import { localizationSelectors } from '../../../state/ducks/localization/selectors';
import { Modal } from 'antd';

export type LoginPageConnectedProps = RouteComponentProps & {
    showLoader: boolean;
    error: string;
    login: (username: string, password: string, recaptchaToken: string) => void;
    isLoggedin: boolean;
    localization: any;
    refreshToken: () => Promise<void>;
};

const LoginPageConnected = ({ refreshToken, showLoader, error, login, isLoggedin, localization, match }: LoginPageConnectedProps) => {
    const queryString = window.location.search;
    const urlParams = new URLSearchParams(queryString);
    const location = useLocation<{ userHasRegistered?: string }>();

    useEffect(() => {
        refreshToken();
    }, []);

    useEffect(() => {
        if (location?.state?.userHasRegistered) {
            Modal.success({
                zIndex: 1100,
                title: `Welcome ${location.state.userHasRegistered}!`,
                content: <div>Password url has been sent to your email</div>,
                onOk() {}
            });
        }
    }, [location?.state?.userHasRegistered]);

    return isLoggedin ? (
        <Redirect to={urlParams.get('refer') || PagesRoutes.Home} />
    ) : (
        <LoginPage error={error} login={login} showLoader={showLoader} localization={localization} match={match} />
    );
};

const mapStateToProps = (state: RootState) => ({
    showLoader: state.loading.effects.authentication.loginAsync,
    error: authenticationSelectors.loginError(state),
    isLoggedin: authenticationSelectors.isAuthentication(state),
    localization: localizationSelectors.loginPage(state)
});

const mapDispatchToProps = (dispatch: any) => ({
    refreshToken: dispatch.authentication.refreshToken,
    login: (username: string, password: string, recaptchaToken: string) => dispatch.authentication.loginAsync({ username, password, recaptchaToken })
});

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(LoginPageConnected));
