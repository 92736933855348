import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { purchaseOrder } from '../../../models/entities/purchaseOrder/purchaseOrder';
import { purchaseOrdersSelectors } from '../../../state/ducks/purchaseOrders/selectors';
import { RootState } from '../../../state/store/store';
import { Formik, Form } from 'formik';
import { FormTextArea } from '../inputs/form';
import Button from '../SmallComponents/Button';
import Loader from '../SmallComponents/Loader';
import { styled } from '../Theme/theme';
import { localizationSelectors } from '../../../state/ducks/localization/selectors';
import UserDropDownForm from '../inputs/form/UserDropDown';
import { userDropDown } from '../../../models/entities/user';
import { userInfo } from '../../../models/entities/user';
import { userSelectors } from '../../../state/ducks/user/selectors';
import { userDetails } from '../../../state/ducks/users/users';

type Props = {
    purchaseOrderId: string;
    userInfo: userInfo;
    setModalVisible?: (visible: boolean) => void;
    confirmation?: { setConfirm: React.Dispatch<React.SetStateAction<boolean>> };
    getPurchaseOrderById: (id: string) => purchaseOrder | undefined;
    localization: any;
    showLoader?: boolean;
    inviteUsersToFollow: (users: Array<string>, purchaseOrderId: string, purchaseOrderNumber: string, message: string) => Promise<boolean>;
};
const InviteUserFollowPurchaseOrderForm = ({
    userInfo,
    purchaseOrderId,
    setModalVisible,
    getPurchaseOrderById,
    confirmation,
    localization,
    showLoader,
    inviteUsersToFollow
}: Props) => {
    const purchaseOrder: purchaseOrder | undefined = getPurchaseOrderById(purchaseOrderId);
    const [users, setUsers] = useState<Array<userDetails>>();

    const initialValues = {
        users: [],
        message: ''
    };
    const validate = (values: any) => {
        const errors: any = {};
        if (!values.users || values.users.length === 0) errors.users = localization.errors.required;
        if (values.message && values.message.length > 250) errors.message = localization.errors.maxLengthMessage(250);
        return errors;
    };
    return (
        <>
            <Formik
                initialValues={initialValues}
                onSubmit={async (values) => {
                    if (purchaseOrder)
                        await inviteUsersToFollow(values.users, purchaseOrder.id, purchaseOrder.CargoZoneNumber, values.message).then((success) => {
                            if (success) setModalVisible && setModalVisible(false);
                        });
                }}
                validate={validate}
            >
                {(formik) => {
                    return (
                        <FormContainer>
                            <Form>
                                <FieldContainer>
                                    <UserDropDownForm
                                        name="users"
                                        placeHolder={localization.placeholders.users}
                                        users={users}
                                        customUsers
                                        mode="multiple"
                                        showSearch
                                    />
                                </FieldContainer>
                                <FieldContainer>
                                    <FormTextArea name="message" placeHolder={localization.placeholders.message} height="106px" />
                                </FieldContainer>
                                <ButtonContainer>
                                    <Button height="32px" width="100%" buttonType="filled" type="submit">
                                        {showLoader ? <Loader width="20px" marginTop="0px" showText={false} /> : <span>{localization.invite}</span>}
                                    </Button>
                                </ButtonContainer>
                            </Form>
                        </FormContainer>
                    );
                }}
            </Formik>
        </>
    );
};

const FormContainer = styled.div`
    width: 100%;
`;

const FieldContainer = styled.div`
    .err-msg {
        position: relative;
    }
`;
const ButtonContainer = styled.div`
    color: #263255;
    background-color: #fff;
    margin-top: 15px;
`;
const mapStateToProps = (state: RootState) => ({
    userInfo: userSelectors.userInfo(state),
    getPurchaseOrderById: (id: string) => purchaseOrdersSelectors.getPurchaseOrderById(state, id),
    localization: localizationSelectors.inviteToFollowModal(state),
    showLoader: state.loading.effects.purchaseOrders.inviteUsersToFollow
});

const mapDispatchToProps = (dispatch: any) => ({
    inviteUsersToFollow: (users: Array<string>, purchaseOrderId: string, purchaseOrderNumber: string, message: string) =>
        dispatch.purchaseOrders.inviteUsersToFollow({ users, purchaseOrderId, purchaseOrderNumber, message })
});

export default connect(mapStateToProps, mapDispatchToProps)(InviteUserFollowPurchaseOrderForm);
