import React from 'react';
import styled from 'styled-components';
import { userInfo } from '../../../../../models/entities/user';
import { FadeInAnimation } from '../../../../animations/Animations';
import DropDownOption from '../../../ColoredDropDown/DropDownOption';
import { connect } from 'react-redux';
import { RootState } from '../../../../../state/store/store';
import { getUserStatusTheme } from '../../../Theme/util';
import theme from '../../../Theme/theme';
const statusDropdownTheme = theme.colors.userMenu.statusDropDown;
export type StatusDropDownProps = {
    items: [{}];
    localizations?: any;
    userInfo: userInfo;
    isOpen: boolean;
    updateUserStatus: (status: string) => void;
};
function StatusDropDown({ items, localizations, userInfo, isOpen, updateUserStatus }: StatusDropDownProps) {
    return isOpen ? (
        <OptionsList>
            {items.map((option: any) => {
                const statusTheme = getUserStatusTheme(option.value);
                return (
                    <StatusDropDownOption
                        onSelect={(option) => updateUserStatus(option.value)}
                        key={option.value}
                        height={36 + 'px'}
                        option={{
                            ...option,
                            icon: () => {
                                return (
                                    <DotContainer>
                                        <Dot color={statusTheme.color} isFill={statusTheme.dot_fill} />
                                    </DotContainer>
                                );
                            },
                            isSelected: userInfo.status === option.value,
                            text: localizations.statuses[option.key]
                        }}
                        selectedIconColor={theme.colors.primaryBlue}
                    />
                );
            })}
        </OptionsList>
    ) : (
        <></>
    );
}
interface DotProps {
    color: string;
    isFill: boolean;
}
const StatusDropDownOption = styled(DropDownOption)`
    && {
        background: inherit;
        font-size: 14px;
        color: black;
        border: none;
    }
`;
const DotContainer = styled.div`
    left: 10px;
    top: 15px;
`;
const Dot = styled.div<DotProps>`
    height: 6px;
    width: 6px;
    margin-right: 5px;
    ${(props) => {
        if (props.isFill) return `background: ${props.color};`;
        else return `background: white;border:1px solid ${props.color};`;
    }}
    border-radius: 50%;
`;
const OptionsList = styled.div`
    width: 145px;
    position: absolute;
    margin-top: -38px;
    right: 200px;
    border-top: 1px solid ${statusDropdownTheme.borderColor};
    border-right: 1px solid ${statusDropdownTheme.borderColor};
    border-left: 1px solid ${statusDropdownTheme.borderColor};
    margin-left: -1px;
    margin-right: -1px;
    border-radius: 3px;
    z-index: 2;
    -webkit-animation: gqJLNN 0.75s;
    animation: gqJLNN 0.75s;
    background-color: ${statusDropdownTheme.background};
    animation: ${FadeInAnimation} 0.75s;
    & > div {
        background-color: ${statusDropdownTheme.background};
        font-size: 14px;
        color: ${statusDropdownTheme.color};
        border: none;
    }
`;

const mapStateToProps = (state: RootState) => ({});

const mapDispatchToProps = (dispatch: any) => ({
    updateUserStatus: (status: string) => dispatch.user.updateUserStatus(status)
});

export default connect(mapStateToProps, mapDispatchToProps)(StatusDropDown);
