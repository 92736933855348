import React, { useEffect, useState } from 'react';
import { styled } from '../../../shared/Theme/theme';
import Loader from '../../../shared/SmallComponents/Loader';
import Button from '../../../shared/SmallComponents/Button';
import { match, useHistory } from 'react-router-dom';
import { PagesRoutes } from '../../../../routing/PagesRoutes';
import StyledLink from '../../../shared/SmallComponents/StyledLink';
import { Formik, Form } from 'formik';
import * as Yup from 'yup';
import { FormTextInput } from '../../../shared/inputs/form';
import { Location } from 'history';
import * as qs from 'querystring';
import { LocationStateType } from '../GetStartedPageConnected';

type CreateNewProps = {
    localization: any;
    sendEmailValidation: (email: string) => Promise<boolean>;
    isSendingValidationEmail: boolean;
    match: match;
    location: Location<LocationStateType>;
};

const CreateNew = ({ localization, sendEmailValidation, isSendingValidationEmail, match, location }: CreateNewProps) => {
    const history = useHistory();
    const [emailExistError, setEmailExistError] = useState<boolean | null>(null);
    const queryToken = qs.parse(location.search.replace('?', '')).token as string;

    // useEffect(() => {
    //     if (!queryToken && !location?.state?.pricingPlanId) history.replace(`${match.url}/pricing`);
    // }, [queryToken, location?.state?.pricingPlanId]);

    return (
        <Formik
            initialValues={{ email: '' }}
            validationSchema={Yup.object().shape({
                email: Yup.string().trim().email(localization.email_not_valid).required(localization.required)
            })}
            onSubmit={async ({ email }) => {
                const emailNotExist = await sendEmailValidation(email);
                if (!emailNotExist) return setEmailExistError(true);
                history.push({ pathname: `${match.url}/confirmEmail`, search: location.search, state: { ...(location?.state || {}), email: email } });
            }}
        >
            {(formik) => {
                const { errors, touched, isValid } = formik;

                return (
                    <Form style={{ width: '100%', display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center' }}>
                        <Title>{localization.title}</Title>
                        <SubTitle>{localization.sub_title}</SubTitle>
                        <FieldContainer style={{ marginBottom: errors.email && touched.email ? '5px' : '0px' }}>
                            <FormTextInput
                                name="email"
                                placeHolder={localization.email_placeholder}
                                style={{ height: '44px', width: 360, fontSize: 18 }}
                            />
                        </FieldContainer>
                        <Button widthMobile="280px" height="44px" margin="16px 0" width="360px" buttonType="filled" type="submit" disabled={!isValid}>
                            {isSendingValidationEmail ? (
                                <Loader width="20px" marginTop="0px" showText={false} />
                            ) : (
                                <span>{localization.continue_btn}</span>
                            )}
                        </Button>
                        {emailExistError && <EmailExistIndication>{localization.email_exist_err}</EmailExistIndication>}
                        <Bottom>
                            <div style={{ color: '#5f5f5f' }}>{localization.ask_account}</div>
                            <StyledLink to={PagesRoutes.Login}>{localization.sign_in}</StyledLink>
                        </Bottom>
                    </Form>
                );
            }}
        </Formik>
    );
};

const Title = styled.div`
    font-weight: 700;
    font-size: 48px;
    line-height: 46px;
    max-width: 700px;
    text-align: center;
    letter-spacing: -0.75px;
    margin-bottom: 10px;
`;

const SubTitle = styled.div`
    font-size: 18px;
    line-height: 27px;
    margin-bottom: 32px;
    color: #454245;
    max-width: 700px;
    text-align: center;
`;

const Bottom = styled.div`
    margin-top: 24px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
`;

const EmailExistIndication = styled.div`
    margin-top: 5px;
    border-radius: 4px;
    font-size: 13px;
    padding: 12px 16px;
    display: flex;
    align-items: center;
    justify-content: center;
    min-width: 400px;

    background-color: rgba(224, 30, 90, 0.1);
    border: 1px solid rgba(224, 30, 90, 0.4);
`;

const FieldContainer = styled.div`
    margin-top: 12px;
`;

export default CreateNew;
