import SideEffectJS from 'side-effect-js';
import mockColumns from './mockColumns';

const delay = (ms: number) => new Promise((res) => setTimeout(res, ms));

const fetchGridColumnsMock = async () => {
    await delay(1500);
    return Promise.resolve(mockColumns);
};
const fetchGridColumnsReal = () => Promise.reject('Implement API');

const fetchGridColumnsSideEffect = SideEffectJS.CreateEffect('gridColumnsShipmentsPage', fetchGridColumnsReal, fetchGridColumnsMock);

export default fetchGridColumnsSideEffect;
