import React from 'react';
import { PagesRoutes } from '../../../routing/PagesRoutes';
import { settings, purchaseOrder, poOverview } from '../../../models/entities/purchaseOrder/purchaseOrder';
import Overview from './sections/Overview';
import { styled } from '../../shared/Theme/theme';
import Button from '../../shared/SmallComponents/Button';
import EditIcon from '../../../static/icons/edit.svg';
import MenuIcon from '../../../static/icons/menu.svg';
import { Link } from 'react-router-dom';
import BackButton from '../../shared/SmallComponents/BackButton';
import PurchaseOrderTabs from './sections/Tabs';
import ItemsTab from './sections/Items/ItemsTab';
import DiscussionsSection from './sections/Discussions/DiscussionsSection';
import moment from 'moment';
import PurchaseOrderMenu from '../../shared/Grid/specialColumns/PurchaseOrderMenu';
import { TriggerProps } from '../../shared/Menu/Menu';
import { useHistory } from 'react-router-dom';
import { DropDown } from '../../shared/inputs/base';
import { renderStateOption } from '../../shared/SmallComponents/StateOption';
import { settings as companySettings } from '../../../models/entities/businessPartner/businessPartner';
import { claimsDomainsType } from '../../../userClaims';
import claimsType from '../../../models/entities/claimsType';
import PurchaseOrderFollowers from './components/purchaseOrderFollowers';
import SendSupplierUpdatesEmailModal from './sendSupplierUpdatesEmailModal/sendSupplierUpdatesEmailModal';
import { ModalTriggerProps } from '../../shared/Modal/Modal';

type Props = {
    purchaseOrder: purchaseOrder;
    updatePurchaseOrderState: (purchaseOrderId: string, purchaseOrderState: string) => void;
    getUserClaims: (domain: claimsDomainsType) => claimsType;
    isPoStateAfterOrEqual: (purchaseOrderId: string, checkState: string) => boolean;
    purchaseOrderState?: string;
    localization?: any;
    settings?: settings;
    printMode?: boolean;
    companySettings?: companySettings;
    hideItemsPrices?: boolean;
};

const SinglePurchaseOrderPage = ({
    purchaseOrder,
    updatePurchaseOrderState,
    getUserClaims,
    isPoStateAfterOrEqual,
    purchaseOrderState,
    localization,
    settings,
    companySettings,
    printMode,
    hideItemsPrices
}: Props) => {
    const history = useHistory();
    return (
        <PageWrapper>
            <LeftSide>
                {!printMode && (
                    <SectionWrapper>
                        <div>
                            <BackButton style={{ marginLeft: '-30px' }} toPath={PagesRoutes.PurchaseOrders} text={localization.backLink} />
                            <LastUpdateText>
                                {localization.lastUpdate} {moment(purchaseOrder.updatedAt).format('DD/MM/YYYY HH:mm')}
                            </LastUpdateText>
                        </div>
                        <PurchaseOrderStateDropDownContainer>
                            <DropDown
                                placeHolder="Purchase Order State"
                                style={{ width: '100%' }}
                                options={
                                    settings?.state
                                        ?.sort((a, b) => a.displayOrder - b.displayOrder)
                                        .map((item) => ({
                                            text: item.name,
                                            value: item.code,
                                            color: item.color
                                        })) || []
                                }
                                value={purchaseOrderState}
                                disabled={!getUserClaims('po').edit_state}
                                optionRenderer={renderStateOption}
                                onChange={(value: string) => {
                                    updatePurchaseOrderState(purchaseOrder.id, value);
                                }}
                            />
                        </PurchaseOrderStateDropDownContainer>

                        {purchaseOrder.deletedAt && <InactiveBadge>{localization.deleted}</InactiveBadge>}
                        <HeaderRightSide>
                            <PurchaseOrderFollowers followers={purchaseOrder.followers} />
                            <SendSupplierUpdatesEmailModal
                                purchaseOrderId={purchaseOrder.id}
                                Trigger={(props: ModalTriggerProps) => (
                                    <Button
                                        disabled={isPoStateAfterOrEqual(purchaseOrder.id, 'CONFIRM')}
                                        onClick={props.onClick}
                                        buttonType="filled"
                                        margin="0 12px 0 0"
                                        type="button"
                                        width="150px"
                                    >
                                        Send to supplier
                                    </Button>
                                )}
                            />
                            <Link
                                to={{
                                    pathname: `${PagesRoutes.EditPurchaseOrder}/${purchaseOrder.id}`
                                }}
                            >
                                <Button margin="0 12px 0 0">
                                    <img src={EditIcon} alt={localization.edit} />
                                    <span>{localization.edit}</span>
                                </Button>
                            </Link>
                            <Link
                                to={{
                                    pathname: `${PagesRoutes.DuplicatePurchaseOrder}/${purchaseOrder.id}`
                                }}
                            >
                                <Button margin="0 12px 0 0">
                                    <span>{localization.duplicate}</span>
                                </Button>
                            </Link>

                            <PurchaseOrderMenu
                                purchaseOrder={purchaseOrder}
                                entityId={purchaseOrder.id}
                                entityData={{ CargoZoneNumber: purchaseOrder.CargoZoneNumber }}
                                trigger={(props: TriggerProps) => (
                                    <img style={{ cursor: 'pointer' }} onClick={(e) => props.onClick(e)} src={MenuIcon} alt="Menu" />
                                )}
                                onDelete={() => history.push(PagesRoutes.PurchaseOrders)}
                            />
                        </HeaderRightSide>
                    </SectionWrapper>
                )}
                <OverviewSectionWrapper>
                    <Overview purchaseOrder={purchaseOrder} localization={localization} settings={settings} printMode={printMode} />
                </OverviewSectionWrapper>
                <SectionWrapper>
                    {!printMode ? (
                        <PurchaseOrderTabs
                            purchaseOrder={purchaseOrder}
                            localization={localization}
                            settings={settings}
                            companySettings={companySettings}
                        />
                    ) : (
                        <ItemsTab
                            purchaseOrder={purchaseOrder}
                            items={purchaseOrder.items}
                            localization={localization}
                            settings={settings}
                            printMode={printMode}
                            hideItemsPrices={hideItemsPrices}
                        />
                    )}
                </SectionWrapper>
                {!printMode && (
                    <SectionWrapper>
                        <DiscussionsSection localization={localization} />
                    </SectionWrapper>
                )}
            </LeftSide>
        </PageWrapper>
    );
};

const PageWrapper = styled.div`
    height: calc(100vh - 65px);
    display: flex;
    flex-direction: row;

    @media screen and (max-width: 768px) {
        height: calc(100vh - 65px - 40px);
    }
`;
const PurchaseOrderStateDropDownContainer = styled.div`
    align-self: flex-end;
    width: 25%;
    margin-left: 20px;
`;

const LeftSide = styled.div`
    width: 100%;

    @media screen and (max-width: 1300px) {
        width: 100%;
        max-width: 100%;
    }
`;

const InactiveBadge = styled.span`
    border-radius: 2vh;
    border: 1px solid #ff7676;
    color: #ff7676;
    font-size: 14px;
    height: 24px;
    padding: 3px 8px;
    margin-left: 10px;
    display: inline-flex;
    align-items: center;
    line-height: 1px;
    align-self: flex-end;
    min-width: max-content;
`;

const OverviewSectionWrapper = styled.div`
    display: flex;
    flex-direction: row;
    border-bottom: solid 1px ${(props) => props.theme.colors.grayDefaultBorder};
    justify-content: space-between;
`;

export const SectionWrapper = styled.div`
    display: flex;
    flex-direction: row;
    padding: 24px 18px 18px 48px;
    border-bottom: solid 1px ${(props) => props.theme.colors.grayDefaultBorder};
    justify-content: space-between;
`;

const HeaderRightSide = styled.div`
    display: flex;
    height: 30px;
    margin: 30px 0 0 0;
`;

const LastUpdateText = styled.div`
    font-size: 12px;
    font-style: italic;
    color: #757575;
    padding: 16px 0 0;
`;

export default SinglePurchaseOrderPage;
