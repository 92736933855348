import { businessPartner } from '../../../models/entities/businessPartner/businessPartner';
import { filterFieldsType } from '../allBusinessPartnersPage/allBusinessPartnersPage';

const filterBusinessPartners = (businessPartners: businessPartner[], filterFields: filterFieldsType) => {
    let filteredBusinessPartners = [...businessPartners];
    filterFields.forEach((filter) => {
        switch (filter.field) {
            case 'type':
                if (filter.value.length > 0) {
                    filteredBusinessPartners = filteredBusinessPartners.filter((businessPartner) =>
                        (filter.value as string[]).includes(businessPartner.type?.toUpperCase())
                    );
                }
                break;
            default:
                filteredBusinessPartners = filteredBusinessPartners.filter((businessPartner) =>
                    (businessPartner as any)[filter.field] ? (businessPartner as any)[filter.field].includes(filter.value as string) : businessPartner
                );
                break;
        }
    });
    return filteredBusinessPartners;
};

export default filterBusinessPartners;
