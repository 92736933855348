import React, { useEffect, useState } from 'react';
import { purchaseDemandItem } from '../../../../../models/entities/purchaseDemand/purchaseDemandItem';
import { purchaseDemand } from '../../../../../models/entities/purchaseDemand/purchaseDemand';
import SelectItemsBySupplier from './SelectItemsBySupplier';
import CustomModal from '../../../../shared/Modal/Modal';
import { connect } from 'react-redux';
import { RootState } from '../../../../../state/store/store';
import { purchaseOrder } from '../../../../../models/entities/purchaseOrder/purchaseOrder';
import MergePDtoPOGrid from './MergePDtoPOGrid';
import Loader from '../../../../shared/SmallComponents/Loader';
import { purchaseOrdersSelectors } from '../../../../../state/ducks/purchaseOrders/selectors';
import { purchaseOrderItem } from '../../../../../models/entities/purchaseOrder/purchaseOrderItem';

type Props = {
    purchaseOrders: Array<purchaseOrder>;
    fetchPurchaseOrders: () => void;
    purchaseDemand: purchaseDemand;
    localization: any;
    showLoader: boolean;
    createPurchaseOrderItems: (purchaseOrderId: string, items: Array<purchaseOrderItem>) => Promise<void>;
};

const MergePDtoPO = ({ purchaseOrders, fetchPurchaseOrders, purchaseDemand, localization, showLoader, createPurchaseOrderItems }: Props) => {
    const [poModalData, setPoModalData] = useState<{ pdItems: purchaseDemandItem[]; selectedSupplierId: string } | undefined>(undefined);

    useEffect(() => {
        fetchPurchaseOrders();
    }, []);

    return (
        <>
            <SelectItemsBySupplier
                purchaseDemand={purchaseDemand}
                localization={localization}
                onSelectItemsCallBack={async (pdItems: purchaseDemandItem[], selectedSupplierId?: string) => {
                    if (pdItems.length && selectedSupplierId) {
                        setPoModalData({ pdItems, selectedSupplierId });
                    }
                }}
            />
            {poModalData?.pdItems && poModalData?.selectedSupplierId && (
                <CustomModal
                    isOpen={true}
                    title={{ text: 'Choose POs' }}
                    Content={(
                        setVisible?: React.Dispatch<React.SetStateAction<boolean>>,
                        setConfirm?: React.Dispatch<React.SetStateAction<boolean>>
                    ) => {
                        return showLoader ? (
                            <Loader marginTop={'0'} />
                        ) : (
                            <MergePDtoPOGrid
                                purchaseOrders={purchaseOrders.filter(
                                    (po) => po.vendorId === poModalData.selectedSupplierId && po.state !== 'CLOSED'
                                )}
                                pdItems={poModalData.pdItems}
                                supplierId={poModalData.selectedSupplierId}
                                createPurchaseOrderItems={createPurchaseOrderItems}
                                localization={localization}
                                purchaseDemand={purchaseDemand}
                            />
                        );
                    }}
                    onCancelCallback={() => {
                        setPoModalData(undefined);
                    }}
                    width="900px"
                />
            )}
        </>
    );
};

const mapStateToProps = (state: RootState) => ({
    purchaseOrders: purchaseOrdersSelectors.purchaseOrdersData(state),
    showLoader: !!state.loading.effects.purchaseOrders.fetchPurchaseOrders
});

const mapDispatchToProps = (dispatch: any) => ({
    fetchPurchaseOrders: () => dispatch.purchaseOrders.fetchPurchaseOrders(),
    createPurchaseOrderItems: (purchaseOrderId: string, items: Array<purchaseOrderItem>) =>
        dispatch.purchaseOrders.createPurchaseOrderItems({ purchaseOrderId, items })
});

export default connect(mapStateToProps, mapDispatchToProps)(MergePDtoPO);
