import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import BusinessPartnersPage from './BusinessPartnersPage';
import { businessPartner, settings } from '../../../models/entities/businessPartner/businessPartner';
import { RootState } from '../../../state/store/store';
import { exportTypes, gridSortState, paginationType } from '../../../state/ducks/allBusinessPartnersPage/allBusinessPartnersPage';
import { allBusinessPartnersPageSelectors } from '../../../state/ducks/allBusinessPartnersPage/selectors';
import { localizationSelectors } from '../../../state/ducks/localization/selectors';
import { gridColumns } from './gridColumns';
import ExceptionPage from '../ExceptionPage/ExceptionPage';
import { businessPartnersSelectors } from '../../../state/ducks/businessPartner/selectors';

type Props = {
    companies: Array<businessPartner>;
    companiesPage: Array<businessPartner>;
    fetchCompaniesError: any;
    fetchCompanies: () => void;
    currentGridSort: gridSortState | null;
    currentGridFilter: string | null;
    setGridSort: (sortData: gridSortState) => void;
    setGridFilter: (text: string) => void;
    exportDataTable: (type: exportTypes) => void;
    setCurrentPage: (page: number) => void;
    setRowsPerPage: (rowsPerPage: number) => void;
    filteredBPsCount: number;
    activeBusinessPartnersCount: number;
    pagination: paginationType;
    localizations: any;
    showLoader?: boolean;
    settings?: settings;
};

const BusinessPartnersPageConnected = ({
    companies,
    companiesPage,
    fetchCompaniesError,
    fetchCompanies,
    currentGridSort,
    currentGridFilter,
    setGridSort,
    setGridFilter,
    exportDataTable,
    localizations,
    showLoader,
    settings,
    setCurrentPage,
    setRowsPerPage,
    filteredBPsCount,
    pagination,
    activeBusinessPartnersCount
}: Props) => {
    useEffect(() => {
        fetchCompanies();
    }, [fetchCompanies]);

    const DEFAULT_SORT_DIRECTION = 'asc';
    const SECOND_SORT_DIRECTION = 'desc';

    const onGridColumnClick = (newColumn: keyof businessPartner) => {
        if (currentGridSort) {
            const { direction, column } = currentGridSort;
            if (column === newColumn)
                if (direction === DEFAULT_SORT_DIRECTION)
                    setGridSort({
                        column: newColumn,
                        direction: SECOND_SORT_DIRECTION
                    });
                else
                    setGridSort({
                        column: newColumn,
                        direction: DEFAULT_SORT_DIRECTION
                    });
            else {
                setGridSort({
                    column: newColumn,
                    direction: DEFAULT_SORT_DIRECTION
                });
            }
        } else {
            setGridSort({
                column: newColumn,
                direction: DEFAULT_SORT_DIRECTION
            });
        }
    };

    const onGridFilter = (text: string) => {
        setGridFilter(text);
        setCurrentPage(0);
    };

    // const parsedError = fetchCompaniesError ? JSON.parse(fetchCompaniesError) : null;

    return !fetchCompaniesError ? (
        <BusinessPartnersPage
            companies={companies}
            companiesPage={companiesPage}
            gridColumns={gridColumns}
            onGridColumnClick={onGridColumnClick}
            fetchCompanies={fetchCompanies}
            onFilter={onGridFilter}
            filterText={currentGridFilter ?? ''}
            gridSortDirection={currentGridSort?.direction}
            gridSortedBy={currentGridSort?.column}
            localization={localizations}
            exportDataTable={exportDataTable}
            showLoader={showLoader}
            settings={settings}
            setCurrentPage={setCurrentPage}
            setRowsPerPage={setRowsPerPage}
            filteredBPsCount={filteredBPsCount}
            pagination={pagination}
            activeBusinessPartnersCount={activeBusinessPartnersCount}
        />
    ) : (
        <ExceptionPage status={400} subTitle={fetchCompaniesError} goBackPath="/" />
    );
};

const mapStateToProps = (state: RootState) => ({
    companies: allBusinessPartnersPageSelectors.gridData(state),
    companiesPage: allBusinessPartnersPageSelectors.currentGridDataPage(state),
    fetchCompaniesError: state.error.effects.businessPartners.fetchBusinessPartners,
    currentGridSort: allBusinessPartnersPageSelectors.gridSort(state),
    currentGridFilter: allBusinessPartnersPageSelectors.gridFilter(state),
    localizations: localizationSelectors.allBusinessPartnersPage(state),
    showLoader: state.loading.effects.businessPartners.fetchBusinessPartners,
    settings: businessPartnersSelectors.settingsByLanguage(state),
    filteredBPsCount: allBusinessPartnersPageSelectors.filteredBPsCount(state),
    activeBusinessPartnersCount: allBusinessPartnersPageSelectors.activeBusinessPartnersCount(state),
    pagination: allBusinessPartnersPageSelectors.pagination(state)
});

const mapDispatchToProps = (dispatch: any) => ({
    fetchCompanies: () => dispatch.businessPartners.fetchBusinessPartners(),
    fetchGridColumns: () => dispatch.allBusinessPartnersPage.fetchGridColumns(),
    setGridFilter: (text: string) => dispatch.allBusinessPartnersPage.setGridFilter(text),
    setGridSort: (sortData: gridSortState) => dispatch.allBusinessPartnersPage.setGridSort(sortData),
    exportDataTable: (type: exportTypes) => dispatch.allBusinessPartnersPage.exportDataTable(type),
    setCurrentPage: (page: number) => dispatch.allBusinessPartnersPage.setCurrentPage(page),
    setRowsPerPage: (rowsPerPage: number) => dispatch.allBusinessPartnersPage.setRowsPerPage(rowsPerPage)
});

export default connect(mapStateToProps, mapDispatchToProps)(BusinessPartnersPageConnected);
