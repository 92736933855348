import React from 'react';
import { Field } from 'formik-antd';
import styled from 'styled-components';
import { FieldProps } from 'formik';
import FileExtIcon from '../../Grid/specialColumns/FileExtIcon';
import { ErrorText } from '.';

type Props = {
    id: string;
    name: string;
    onChange?: any;
    placeHolder?: string;
    asButton?: boolean;
    multiple?: boolean;
};

const FormUploadInput = React.forwardRef(
    (
        { id, name, asButton, multiple, onChange, placeHolder, ...restProps }: Props,
        ref: ((instance: HTMLInputElement | null) => void) | React.RefObject<HTMLInputElement> | null | undefined
    ) => {
        return (
            <Field name={name}>
                {({ field: { value }, meta, form: { setFieldValue } }: FieldProps) => {
                    const { touched, error } = meta;
                    const hasError = touched && error !== undefined;
                    return (
                        <>
                            <UploadFileContainer>
                                <CustomUploadInput htmlFor={id}>
                                    {!asButton && value ? (
                                        <>
                                            <FileExtIcon filename={value.name} />
                                        </>
                                    ) : (
                                        <span>{placeHolder}</span>
                                    )}
                                </CustomUploadInput>
                                <UploadInput
                                    id={id}
                                    name={name}
                                    type="file"
                                    multiple={multiple}
                                    onChange={(event) => {
                                        if (event && event.currentTarget && event.currentTarget.files) {
                                            let value: File | FileList = event.currentTarget.files[0];
                                            if (multiple) value = event.currentTarget.files;
                                            setFieldValue(name, value);
                                            onChange && onChange(value);
                                        }
                                    }}
                                    ref={ref}
                                    {...restProps}
                                />
                            </UploadFileContainer>
                            <ErrorText>{hasError && error}</ErrorText>
                        </>
                    );
                }}
            </Field>
        );
    }
);
const UploadInput = styled.input`
    display: none;
`;

const UploadFileContainer = styled.div`
    display: flex;
    justify-content: left;
    flex-wrap: wrap;
`;
const CustomUploadInput = styled.label`
    height: 32px;
    border-radius: 16px;
    background-color: rgba(224, 224, 224, 0.6);
    display: flex;
    width: 100%;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    :hover {
        background-color: rgb(224, 224, 224);
    }
    > div {
        justify-content: center;
        > img {
            width: 20px;
        }
    }
`;
export default FormUploadInput;
