import React, { PropsWithChildren, useRef, useState } from 'react';
import { styled } from '../Theme/theme';
import { makeStyles } from '@material-ui/core/styles';
import { Popover } from '@material-ui/core';

type Props = {
    anchor: React.ReactNode;
    anchorStyle?: React.CSSProperties;
    popoverEnterFn?: () => void;
    popoverLeaveFn?: () => void;
    popoverLeft?: boolean;
    toggleOnClick?: boolean;
};
const useStyles = makeStyles((theme) => ({
    popover: {
        pointerEvents: 'none'
    },
    popoverContent: {
        pointerEvents: 'auto',
        minWidth: '90px',
        minHeight: '90px',
        display: 'flex',
        alignItems: 'center',
        backgroundColor: 'transparent',
        boxShadow: 'none'
    }
}));

const MaterialPopover: React.FC<PropsWithChildren<Props>> = ({
    anchor,
    popoverEnterFn,
    popoverLeaveFn,
    popoverLeft,
    children,
    toggleOnClick,
    anchorStyle
}) => {
    const [openedPopover, setOpenedPopover] = useState(false);
    const popoverAnchor = useRef(null);

    const classes = useStyles();

    const popoverEnter = async () => {
        setOpenedPopover(true);
        popoverEnterFn && popoverEnterFn();
    };

    const popoverLeave = () => {
        setOpenedPopover(false);
        popoverLeaveFn && popoverLeaveFn();
    };

    return (
        <>
            <span
                ref={popoverAnchor}
                aria-owns="mouse-over-popover"
                aria-haspopup="true"
                onClick={(e) => {
                    if (toggleOnClick) !openedPopover ? popoverEnter() : popoverLeave();
                }}
                onMouseEnter={() => {
                    if (!toggleOnClick) popoverEnter();
                }}
                onMouseLeave={popoverLeave}
                style={anchorStyle}
            >
                {anchor}
            </span>
            <Popover
                id="mouse-over-popover"
                className={classes.popover}
                classes={{
                    paper: classes.popoverContent
                }}
                PaperProps={{
                    onMouseEnter: popoverEnter,
                    onMouseLeave: popoverLeave
                }}
                open={openedPopover}
                anchorEl={popoverAnchor.current}
                anchorOrigin={{
                    vertical: 'center',
                    horizontal: popoverLeft ? 'left' : 'right'
                }}
                transformOrigin={{
                    vertical: 'center',
                    horizontal: popoverLeft ? 'right' : 'left'
                }}
            >
                <PopoverWrapper>
                    <PopoverContent>{children}</PopoverContent>
                </PopoverWrapper>
            </Popover>
        </>
    );
};

const PopoverContent = styled.div`
    display: flex;
    padding: 12px;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 100%;
    box-shadow: 0px 5px 5px -3px rgb(0 0 0 / 20%), 0px 8px 10px 1px rgb(0 0 0 / 14%), 0px 3px 14px 2px rgb(0 0 0 / 12%);
    background-color: #fff;
`;
const PopoverWrapper = styled.div`
    background-color: transparent;
    padding: 14px;
    width: 100%;

    height: 100%;
`;

export default MaterialPopover;
