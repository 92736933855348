import React from 'react';
import Menu from '../../Menu/Menu';
import MenuItem from '../../Menu/MenuItem';
import trash from '../../../../static/icons/trash.svg';
import { RootState } from '../../../../state/store/store';
import { connect } from 'react-redux';
import CellTrigger from '../../Menu/MenuTriggers/CellTrigger';
type onRowMoreOptionClickType = () => void;
type deleteProduct = (productId: string) => void;
type Props = {
    entityId: string;
    localization: any;
    onRowMoreOptionsClick?: onRowMoreOptionClickType;
    deleteProduct: deleteProduct;
};
const ProductMenu = ({ entityId, localization, onRowMoreOptionsClick, deleteProduct }: Props) => {
    const menuItems = [<MenuItem onClick={() => deleteProduct(entityId)} icon={trash} text={localization.delete} />];

    return <Menu options={menuItems} MenuTrigger={CellTrigger} strStyle="display: flex;align-items: center;width: 100%;height: 100%;" />;
};
const mapStateToProps = (state: RootState) => ({});

const mapDispatchToProps = (dispatch: any) => ({
    deleteProduct: (productId: string) => dispatch.products.deleteProduct(productId)
});

export default connect(mapStateToProps, mapDispatchToProps)(ProductMenu);
