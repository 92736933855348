import moment from 'moment';
import { OrderStateType } from './../../../../../models/entities/order/orderState';
import { order } from './../../../../../models/entities/order/order';
import { allowedCompany } from './../../../../../models/entities/user';
import { orderFilterFields, orderParsedFilterFieldType } from './../../../../../state/ducks/orders/gridFilters/types';
import countries from '../../../../../static/data/countries.json';

export const parseFilterFields = (filterFields: orderFilterFields, allowedCompanies: allowedCompany[], localization: any) => {
    let parsedFilterFields: orderParsedFilterFieldType[] = [];

    const fieldsLocalization = localization.fields;

    const alertParsedValue = (status: string) => {
        switch (status) {
            case '1':
                return localization.alerts.status.critical;
            case '2':
                return localization.alerts.status.warning;
            default:
                return localization.alerts.status.success;
        }
    };

    for (const { field, value } of filterFields) {
        if (!value) continue;
        switch (field) {
            case 'orderDateFrom':
            case 'orderDateTo':
                const title = (() => {
                    switch (field) {
                        case 'orderDateFrom':
                            return fieldsLocalization.orderDateFrom.title;
                        case 'orderDateTo':
                            return fieldsLocalization.orderDateTo.title;
                        default:
                            return '';
                    }
                })();
                parsedFilterFields.push({
                    field: 'orderDate',
                    parsedValue: title + ': ' + moment(value as Date).format('DD/MM/YYYY'),
                    originalValue: value as Date
                });

                break;
            case 'alertStatus':
                (value as string[]).forEach((status: string) => {
                    parsedFilterFields.push({
                        field,
                        parsedValue: alertParsedValue(status || '3'),
                        originalValue: Number(status) || 3
                    });
                });
                break;
            case 'companyId':
                (value as string[]).forEach((type: string) => {
                    parsedFilterFields.push({
                        field,
                        parsedValue: allowedCompanies.find((c) => c.companyId === type)?.companyName || '',
                        originalValue: type
                    });
                });
                break;
            case 'state':
                (value as OrderStateType[]).forEach((type: OrderStateType) => {
                    parsedFilterFields.push({
                        field,
                        parsedValue: localization.state[type],
                        originalValue: type
                    });
                });
                break;
            case 'customerCountry': {
                parsedFilterFields.push({
                    field,
                    parsedValue: countries.find((item) => item.code === value)?.name || (value as string),
                    originalValue: value as string
                });
                break;
            }
            default:
                parsedFilterFields.push({ field, parsedValue: `${value}`, originalValue: value } as orderParsedFilterFieldType);

                break;
        }
    }

    return parsedFilterFields;
};

export const removeFilterItem = (removedFilter: orderParsedFilterFieldType, filterFields: orderFilterFields) => {
    let newFilterFields: orderFilterFields = [...filterFields];
    const { field, originalValue } = removedFilter;

    switch (field) {
        case 'state': {
            let arrayFilterIndex = newFilterFields.findIndex((filter) => filter.field === field);
            if (arrayFilterIndex > -1) {
                const newArray = (newFilterFields[arrayFilterIndex].value as OrderStateType[]).filter((type) => type !== originalValue);
                newFilterFields[arrayFilterIndex] = {
                    field: field,
                    value: newArray
                };
                if (!newArray.length) {
                    newFilterFields.splice(arrayFilterIndex, 1);
                }
            }
            break;
        }
        case 'alertStatus':
        case 'companyId':
            let arrayFilterIndex = newFilterFields.findIndex((filter) => filter.field === field);
            if (arrayFilterIndex > -1) {
                const newArray = (newFilterFields[arrayFilterIndex].value as string[]).filter((value) => `${value}` !== `${originalValue}`);
                newFilterFields[arrayFilterIndex] = {
                    field: field,
                    value: newArray
                };
                if (!newArray.length) {
                    newFilterFields.splice(arrayFilterIndex, 1);
                }
            }
            break;
        case 'orderDate': {
            let filterIndex = newFilterFields.findIndex((filter) => `${filter.value}` === `${originalValue}`);

            if (filterIndex > -1) {
                newFilterFields.splice(filterIndex, 1);
            }
            break;
        }
        default:
            let filterIndex = newFilterFields.findIndex((filter) => filter.field === field && `${filter.value}` === `${originalValue}`);

            if (filterIndex > -1) {
                newFilterFields.splice(filterIndex, 1);
            }
            break;
    }

    return newFilterFields;
};
