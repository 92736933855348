import { isEmpty } from 'lodash';

export function isErrorsEmpty(arr: Array<any>) {
    for (const item of arr) {
        if (!isEmpty(item)) {
            return false;
        }
    }
    return true;
}
export const camalize = (str: string) => {
    return str.replace(/^([A-Z])|[\s-_]+(\w)/g, function (match, p1, p2, offset) {
        if (p2) return p2.toUpperCase();
        return p1.toLowerCase();
    });
};
