import SideEffectJS from 'side-effect-js';
import mockColumns from './mockColumns';

const delay = (ms: number) => new Promise((res) => setTimeout(res, ms));

const fetchInternalFlowInventoryLogsGridColumnsMock = async () => {
    await delay(1500);
    return Promise.resolve(mockColumns);
};
const fetchInternalFlowInventoryLogsGridColumnsReal = () => Promise.reject('Implement API');

const fetchInternalFlowInventoryLogsGridColumnsSideEffect = SideEffectJS.CreateEffect(
    'gridColumnsInternalFlowInventoryLogs',
    fetchInternalFlowInventoryLogsGridColumnsReal,
    fetchInternalFlowInventoryLogsGridColumnsMock
);

export default fetchInternalFlowInventoryLogsGridColumnsSideEffect;
