import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import ProductsPage from './ProductsPage';
import { product } from '../../../models/entities/product/product';
import { RootState } from '../../../state/store/store';
import { filterFieldsType, gridSortState } from '../../../state/ducks/products/products';
import { productsSelectors } from '../../../state/ducks/products/selectors';
import { localizationSelectors } from '../../../state/ducks/localization/selectors';
import { ColumnType } from '../../shared/Grid/types/Column';
import ExceptionPage from '../ExceptionPage/ExceptionPage';
import { filterColsByUserSettings } from '../../shared/Grid/util';
import { userSelectors } from '../../../state/ducks/user/selectors';
import { userSettings } from '../../../models/entities/user';
import { drawers } from '../../../state/ducks/header/header';
import { RouteComponentProps } from 'react-router-dom';

type PathParams = {
    productId: string;
};

type Props = RouteComponentProps<PathParams> & {
    products: Array<product>;
    activeProductsCount: number;
    fetchProductsError: any;
    fetchProducts: () => void;
    fetchGridColumns: () => void;
    gridColumns: Array<ColumnType<product>>;
    currentGridSort: gridSortState | null;
    setGridSort: (sortData: gridSortState) => void;
    localizations: any;
    showLoader?: boolean;
    productsGridSettings?: userSettings;
    fetchProductsByFilter: (filter: filterFieldsType) => Array<product>;
    filterFields: filterFieldsType | null;
    resetProductFilter: () => void;
    setDrawerOpen: (open: drawers, params?: any) => void;
};

const ProductsPageConnected = ({
    products,
    activeProductsCount,
    fetchProductsError,
    fetchProducts,
    fetchGridColumns,
    gridColumns,
    currentGridSort,
    setGridSort,
    localizations,
    showLoader,
    productsGridSettings,
    filterFields,
    resetProductFilter,
    fetchProductsByFilter,
    setDrawerOpen,
    match
}: Props) => {
    useEffect(() => {
        if (!filterFields?.find((f: { field: string; value: string }) => f.field === 'isActive')) {
            fetchProductsByFilter([]);
        }
        fetchGridColumns();
    }, [fetchProducts, fetchGridColumns]);

    useEffect(() => {
        const activeFilter = filterFields?.find((f: { field: string; value: string }) => f.field === 'isActive');
        if (activeFilter) {
            fetchProductsByFilter([activeFilter]);
        }
    }, [filterFields]);

    const DEFAULT_SORT_DIRECTION = 'asc';
    const SECOND_SORT_DIRECTION = 'desc';

    const onGridColumnClick = (newColumn: keyof product) => {
        if (currentGridSort) {
            const { direction, column } = currentGridSort;
            if (column === newColumn)
                if (direction === DEFAULT_SORT_DIRECTION)
                    setGridSort({
                        column: newColumn,
                        direction: SECOND_SORT_DIRECTION
                    });
                else
                    setGridSort({
                        column: newColumn,
                        direction: DEFAULT_SORT_DIRECTION
                    });
            else {
                setGridSort({
                    column: newColumn,
                    direction: DEFAULT_SORT_DIRECTION
                });
            }
        } else {
            setGridSort({
                column: newColumn,
                direction: DEFAULT_SORT_DIRECTION
            });
        }
    };
    const parsedError = fetchProductsError ? JSON.parse(fetchProductsError) : null;

    const userSettingKey = 'ProductGrid';
    return !fetchProductsError ? (
        <ProductsPage
            products={products}
            fetchProducts={fetchProducts}
            gridColumns={filterColsByUserSettings(gridColumns, productsGridSettings)}
            onGridColumnClick={onGridColumnClick}
            gridSortDirection={currentGridSort?.direction}
            gridSortedBy={currentGridSort?.column}
            localization={localizations}
            showLoader={showLoader}
            userSettingKey={userSettingKey}
            resetFilterFunc={filterFields && filterFields.length > 0 && products.length === 0 ? resetProductFilter : undefined}
            setDrawerOpen={setDrawerOpen}
            activeProductsCount={activeProductsCount}
        />
    ) : (
        <ExceptionPage status={parsedError.status} subTitle={parsedError.message} goBackPath="/" />
    );
};

const mapStateToProps = (state: RootState) => ({
    products: productsSelectors.currentGridDataPage(state),
    activeProductsCount: productsSelectors.activeProductsCount(state),
    gridColumns: productsSelectors.gridColumns(state),
    currentGridSort: productsSelectors.gridSort(state),
    localizations: localizationSelectors.products(state),
    showLoader: state.loading.effects.products.fetchProducts || state.loading.effects.products.fetchProductsByFilter,
    // settings: productsSelectors.settingsByLanguage(state),
    productsGridSettings: userSelectors.productsGridSettings(state),
    filterFields: productsSelectors.filterFields(state)
});

const mapDispatchToProps = (dispatch: any) => ({
    fetchProducts: () => dispatch.products.fetchProducts(),
    fetchGridColumns: () => dispatch.products.fetchGridColumns(),
    setGridSort: (sortData: gridSortState) => dispatch.products.setGridSort(sortData),
    resetProductFilter: () => dispatch.products.setProductFilters([]),
    fetchProductsByFilter: (filter: filterFieldsType) => dispatch.products.fetchProductsByFilter(filter),
    setDrawerOpen: (open: drawers, params?: any) => dispatch.header.setDrawerOpenParams({ open, params })
});

export default connect(mapStateToProps, mapDispatchToProps)(ProductsPageConnected);
