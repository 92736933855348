import React from 'react';
import { document } from '../../../../../models/entities/common_subentities/document';
import DocumentFormConnected from '../../DocumentForm/DocumentFormConnected';
import { settings } from '../../../../../models/entities/purchaseDemand/purchaseDemand';
import MultipleDocumentFormConnected from '../../DocumentForm/MultipleDocumentFormConnected';
import Documents from '../../../commonSubEntities/documents/Documents';

type Props = {
    documents?: Array<document>;
    requiredDocs?: Array<string>;
    localization: any;
    settings?: settings;
};

const PurchaseDemandDocuments = ({ documents, localization, settings, requiredDocs }: Props) => {
    return (
        <Documents<settings>
            documents={documents}
            localization={localization}
            settings={settings}
            requiredDocs={requiredDocs}
            menuType="purchaseDemandDocumentMenu"
            MultipleDocumentFormConnected={MultipleDocumentFormConnected}
            DocumentFormConnected={DocumentFormConnected}
        />
    );
};

export default PurchaseDemandDocuments;
