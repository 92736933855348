import { GridCellParams, GridColDef, GridColumnHeaderParams, GridSelectionModelChangeParams, GridValueFormatterParams } from '@material-ui/data-grid';
import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import { product } from '../../../../../models/entities/product/product';
import CZTooltip from '../../../../shared/CZTooltip/CZTooltip';
import { currencyFormatter } from '../../../../shared/inputs/form/CurrencyInput/CurrencyInput';
import MuiDataGrid, { MultiLineCell } from '../../../../shared/MuiDataGrid/MuiDataGrid';
import MaterialPopover from '../../../../shared/Popover/MaterialPopover';
import infoIcon from '../../../../../static/icons/info.svg';
import { Input } from 'antd';
import Loader from '../../../../shared/SmallComponents/Loader';
import { ModalTriggerProps } from '../../../../shared/Modal/Modal';
import ProductAttributesModal from '../ProductAttributesModal/ProductAttributesModal';
import { attributeOption } from '../../../../../models/entities/product/attribute';
import ProductModal from '../ProductModal/ProductModal';
import Button from '../../../../shared/SmallComponents/Button';
import usePrevious from '../../../../../hooks/usePrevious';
import NumberInput from '../../../../shared/inputs/base/NumberInput';
import { DropDown } from '../../../../shared/inputs/base';
import { productGridRow } from './ProductsModal';
import { supplier } from '../../../../../models/entities/product/supplier';

type Props = {
    products: Array<product>;
    fetchProducts: () => void;
    isLoadingProducts: boolean;
    showAttributesModal?: boolean;
    showQuantities?: boolean;
    onSubmitProductsCallBack?: (selectedProducts: productGridRow[]) => void;
    openedFromModal?: {
        setVisible: React.Dispatch<React.SetStateAction<boolean>>;
    };
    localization: any;
    supplierId?: string;
    productsFetched?: boolean;
};
const ProductsModalContent = ({
    products,
    fetchProducts,
    isLoadingProducts,
    onSubmitProductsCallBack,
    openedFromModal,
    localization,
    showAttributesModal,
    showQuantities,
    supplierId,
    productsFetched
}: Props) => {
    useEffect(() => {
        if (!productsFetched) fetchProducts();
    }, []);

    const productsRowsInitState: productGridRow[] = products.reduce((acc: productGridRow[], product: product) => {
        const { suppliers, isMultipleSuppliers } = product;
        const supplier: supplier | undefined = suppliers?.find((s: supplier) => s.isDefault) || suppliers?.[0];

        let supplierData = {};

        if (isMultipleSuppliers && supplier) {
            const { supplierId, supplierName, cost, costCurrency, minOrderQuantity, productionDays } = supplier;
            supplierData = {
                supplierId,
                supplierName,
                quantity: Number(minOrderQuantity) || 1,
                cost,
                costCurrency,
                minOrderQuantity: minOrderQuantity || 1,
                productionDays: productionDays || 1
            };
        }
        return [
            ...acc,
            {
                ...product,
                quantity: Number(product.minOrderQuantity) || 1,
                ...supplierData
            }
        ];
    }, []);

    const [productsRows, setProductsRows] = useState<productGridRow[]>(productsRowsInitState);

    useEffect(() => {
        setProductsRows(productsRowsInitState);
    }, [products.length]);

    const [isSubmit, setIsSubmit] = useState(false);

    const [filterValue, setFilterValue] = useState('');
    const [filteredProducts, setFilteredProducts] = useState<productGridRow[]>(productsRows);
    const [isTypingFilter, setIsTypingFilter] = useState(false);

    const [selectedProductWithAttributes, setSelectedProductWithAttributes] = useState<productGridRow | null>(null);
    const [selectedProducts, setSelectedProducts] = React.useState<string[]>([]);

    const [page, setPage] = React.useState(0);

    const prevTermRef = usePrevious(filterValue.toLowerCase());
    useEffect(() => {
        setIsTypingFilter(true);
        const timeout = setTimeout(() => {
            setFilteredProducts(productsRows.filter((p) => JSON.stringify(p).toLowerCase().includes(filterValue.toLowerCase())));
            setIsTypingFilter(false);
            if (prevTermRef !== filterValue.toLowerCase()) {
                setPage(0);
            }
        }, 500);

        return () => clearTimeout(timeout);
    }, [filterValue, products, setPage]);

    const SkuGridCell = (id: string, sku: string) =>
        React.useMemo(
            () => (
                <div
                    onClick={(e) => {
                        e.stopPropagation();
                    }}
                    onKeyDown={(e) => e.stopPropagation()}
                >
                    <ProductModal
                        productId={id}
                        Trigger={(props: ModalTriggerProps) => (
                            <GridCell onMouseDown={props.onClick}>
                                <ProductBtn>{sku}</ProductBtn>
                            </GridCell>
                        )}
                    />
                </div>
            ),
            [id, sku]
        );

    const columns: GridColDef[] = [
        {
            field: 'sku',
            headerName: localization.grid.columns.sku,
            flex: 1,
            renderCell: (params: GridCellParams) => {
                return SkuGridCell(params.getValue(params.id, 'id') as string, params.value as string);
            }
        },
        {
            field: 'name',
            headerName: localization.grid.columns.name,
            flex: 1,
            renderCell: (params: GridCellParams) => <MultiLineCell>{params.value}</MultiLineCell>
        },
        {
            field: 'cost',
            headerName: localization.grid.columns.cost,
            flex: 1,
            valueFormatter: (params: GridValueFormatterParams) => {
                const productId = params.row.id;

                const productRow = productsRows.find((p) => p.id === productId);

                if (!productRow?.cost) return '-';

                return currencyFormatter(productRow.costCurrency)(productRow.cost);
            }
        },
        {
            field: 'supplierName',
            headerName: localization.grid.columns.supplierName,
            flex: 1,
            renderCell: (params: GridCellParams) => {
                const { id, isMultipleSuppliers, suppliers } = params.row as product;

                const newProductsRows = [...productsRows];

                const productRowIndex = newProductsRows.findIndex((p) => p.id === id);
                if (productRowIndex < 0) return <div>{params.value}</div>;

                if (isMultipleSuppliers && suppliers?.length) {
                    return (
                        <div
                            onClick={(e) => {
                                e.stopPropagation();
                            }}
                            style={{ width: '100%' }}
                        >
                            <DropDown
                                options={suppliers.map((s) => ({ text: s.supplierName || '', value: s.supplierId }))}
                                onChange={(supplierId: string) => {
                                    const newProductsRows = [...productsRows];

                                    const supplier = suppliers.find((s) => s.supplierId === supplierId);
                                    const productRowIndex = newProductsRows.findIndex((p) => p.id === id);

                                    if (productRowIndex < 0 || !supplier) return;

                                    newProductsRows[productRowIndex].supplierId = supplier.supplierId;
                                    newProductsRows[productRowIndex].supplierName = supplier.supplierName;
                                    newProductsRows[productRowIndex].cost = supplier.cost;
                                    newProductsRows[productRowIndex].costCurrency = supplier.costCurrency;
                                    newProductsRows[productRowIndex].minOrderQuantity = supplier.minOrderQuantity || 1;
                                    newProductsRows[productRowIndex].quantity = supplier.minOrderQuantity || 1;
                                    newProductsRows[productRowIndex].productionDays = supplier.productionDays || 1;

                                    setProductsRows(newProductsRows);
                                }}
                                value={newProductsRows[productRowIndex].supplierId}
                            />
                        </div>
                    );
                }

                return <div>{params.value || '-'}</div>;
            }
        },
        {
            hide: !showQuantities,
            field: 'quantity',
            headerName: localization.quantity,
            renderCell: (params: GridCellParams) => {
                const productId = params.row.id as string;

                const productRowIndex = productsRows.findIndex((p) => p.id === productId);

                if (productRowIndex < 0) return <GridCell>-</GridCell>;

                const productRow = productsRows[productRowIndex];

                const minOrderQuantity = Number(productRow.minOrderQuantity) || 1;
                const quantity = Number(productRow.quantity);
                const isMinOrderValueExceeded = quantity < minOrderQuantity;

                return (
                    <CZTooltip text={`Minimum order value is ${minOrderQuantity}`} hidden={!isMinOrderValueExceeded}>
                        <div onClick={(e) => e.stopPropagation()}>
                            <NumberInput
                                onChange={(value: any) => {
                                    const newProductsRows = [...productsRows];
                                    const newProductRow = { ...productRow, quantity: Number(value) };
                                    newProductsRows[productRowIndex] = newProductRow;

                                    setProductsRows(newProductsRows);
                                }}
                                value={quantity}
                                placeHolder={localization.quantity}
                                style={{ width: '90px', color: isMinOrderValueExceeded ? 'red' : 'inherit' }}
                                min={1}
                            />
                        </div>
                    </CZTooltip>
                );
            },
            width: 110
        },
        {
            hide: !productsRows.some((p) => p.selectedAttributes),
            field: 'attributes',
            headerName: 'attributes',
            flex: 1,
            renderCell: (params: GridCellParams) => {
                const productId = params.row.id as string;

                const productRow = productsRows.find((p) => p.id === productId);

                if (!productRow?.selectedAttributes) return <GridCell>-</GridCell>;

                const { selectedAttributes } = productRow;

                return (
                    <div style={{ width: '100%' }} onClick={(e) => e.stopPropagation()}>
                        <MaterialPopover
                            anchor={
                                <GridCell>
                                    {Object.entries(selectedAttributes)
                                        .map(([key, name]) => `${key}: ${name}`)
                                        .join(', ')}
                                </GridCell>
                            }
                        >
                            <GridCell>
                                {Object.entries(selectedAttributes).map(([key, name], i) => (
                                    <Detail key={`${params.row.id}-att-option-${i}`}>
                                        <DetailName>{key}:</DetailName>
                                        <DetailValue>{name}</DetailValue>
                                    </Detail>
                                ))}
                            </GridCell>
                        </MaterialPopover>
                    </div>
                );
            }
        },
        {
            field: 'id',
            renderHeader: (params: GridColumnHeaderParams) => <div></div>,
            renderCell: (params: GridCellParams) => {
                return (
                    <div
                        onClick={(e) => e.stopPropagation()}
                        style={{ width: '100%', display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}
                    >
                        <MaterialPopover
                            anchor={
                                <InfoIconWrapper>
                                    <InfoIcon src={infoIcon} />
                                </InfoIconWrapper>
                            }
                        >
                            <DetailsWrapper>
                                {Object.entries(params.row)
                                    .filter(
                                        ([key, value]) =>
                                            !!value &&
                                            Number(value) !== 0 &&
                                            !['sku', 'name', 'cost', 'costCurrency', 'number', 'createdAt', 'updatedAt', 'attributes'].includes(
                                                key
                                            ) &&
                                            (!key.toLowerCase().includes('id') || key === 'width') &&
                                            !key.toLowerCase().includes('currency') &&
                                            !key.toLowerCase().includes('unit') &&
                                            typeof value !== 'object'
                                    )
                                    .map(([key, value], i) => {
                                        let parsedValue = value;
                                        if (['retailPrice', 'salePrice'].includes(key)) {
                                            parsedValue = currencyFormatter(params.getValue(params.id, `${key}Currency`))(Number(value) || 0);
                                        } else if (key === 'weight') {
                                            parsedValue = `${value} ${params.getValue(params.id, `${key}Unit`)}`;
                                        } else if (['width', 'length', 'height'].includes(key)) {
                                            parsedValue = `${value} ${params.getValue(params.id, `dimensionUnit`)}`;
                                        }
                                        return (
                                            <Detail key={`${params.row.id}-modal-detail-${i}`}>
                                                <DetailName>{localization.grid.columns?.[key] || key}:</DetailName>
                                                <DetailValue>{parsedValue}</DetailValue>
                                            </Detail>
                                        );
                                    })}
                            </DetailsWrapper>
                        </MaterialPopover>
                    </div>
                );
            }
        }
    ];

    const MemoProductsGrid = React.useMemo(
        () => (
            <MuiDataGrid
                rows={filteredProducts}
                columns={columns}
                autoHeight
                disableColumnFilter
                disableColumnMenu
                disableColumnReorder
                checkboxSelection
                page={page}
                onPageChange={(params) => {
                    setPage(params.page);
                }}
                pageSize={10}
                pagination
                showFooter
                onRowSelected={async ({ isSelected, data }) => {
                    let product = data as productGridRow;

                    if (isSelected) {
                        if (showAttributesModal && product?.attributes?.length) {
                            setSelectedProductWithAttributes(product);
                            return;
                        }
                    }
                }}
                onSelectionModelChange={(newSelectionModel: GridSelectionModelChangeParams) => {
                    setSelectedProducts(newSelectionModel.selectionModel as string[]);
                }}
                selectionModel={selectedProducts}
            />
        ),
        [productsRows, filteredProducts, setSelectedProducts, selectedProducts, selectedProductWithAttributes, page, setPage]
    );
    // [productsRows,filteredProducts, setSelectedProducts, selectedProducts, selectedProductWithAttributes, page, setPage]

    if (isLoadingProducts) return <Loader marginTop={'0'} />;
    return (
        <>
            {showAttributesModal && (
                <ProductAttributesModal
                    title={'Attributes'}
                    attributes={selectedProductWithAttributes?.attributes || []}
                    isOpen={!!selectedProductWithAttributes?.attributes?.length}
                    onSubmit={(attributes, firstAttributeOption?: attributeOption) => {
                        if (selectedProductWithAttributes) {
                            const newProductsRows = [...productsRows];

                            const productIndex = newProductsRows.findIndex((p) => p.id === selectedProductWithAttributes.id);

                            const product = {
                                ...selectedProductWithAttributes,
                                cost: firstAttributeOption?.costAdjustment || selectedProductWithAttributes.cost,
                                costCurrency: firstAttributeOption?.costAdjustmentCurrency || selectedProductWithAttributes.costCurrency,
                                length: firstAttributeOption?.lengthAdjustment || selectedProductWithAttributes.length,
                                width: firstAttributeOption?.widthAdjustment || selectedProductWithAttributes.width,
                                height: firstAttributeOption?.heightAdjustment || selectedProductWithAttributes.height,
                                weight: firstAttributeOption?.weightAdjustment || selectedProductWithAttributes.weight,
                                sku: firstAttributeOption?.skuAdjustment || selectedProductWithAttributes.sku,
                                selectedAttributes: attributes
                            };

                            if (productIndex > -1) {
                                newProductsRows[productIndex] = product;
                                setProductsRows(newProductsRows);
                            }
                        }
                        setSelectedProductWithAttributes(null);
                    }}
                    onCancelCallback={() => {
                        setSelectedProductWithAttributes(null);
                    }}
                />
            )}
            <Input
                style={{
                    height: '30px',
                    width: '100%'
                }}
                allowClear={true}
                onChange={(e) => setFilterValue(e.currentTarget.value)}
                placeholder={'Search Products'}
                value={filterValue}
                suffix={isTypingFilter && <Loader wrapperWidth="auto" showText={false} marginTop="0" width="15px" />}
            />
            <Container>{MemoProductsGrid}</Container>
            <BottomSection>
                <Buttons>
                    <Button
                        width="auto"
                        height="30px"
                        type="button"
                        buttonType="clean"
                        textColor="#394372"
                        onClick={() => {
                            if (openedFromModal) openedFromModal.setVisible(false);
                        }}
                    >
                        <span>{'Cancel'}</span>
                    </Button>
                    <Button
                        buttonType="filled"
                        width="100px"
                        height="30px"
                        type="button"
                        onClick={async (e) => {
                            if (!onSubmitProductsCallBack) return;
                            setIsSubmit(true);
                            await onSubmitProductsCallBack(productsRows.filter((p) => selectedProducts.includes(p.id)));
                            setIsSubmit(false);
                            if (openedFromModal) openedFromModal.setVisible(false);
                        }}
                    >
                        {isSubmit ? <Loader width="20px" marginTop="0px" showText={false} /> : <span>{'Submit'}</span>}
                    </Button>
                </Buttons>
            </BottomSection>
        </>
    );
};
const Container = styled.div`
    max-height: 65vh;
    overflow-y: overlay;
`;
const InfoIcon = styled.img`
    width: 20px;
    height: 20px;
`;
const InfoIconWrapper = styled.div`
    display: flex;
    position: relative;
    width: 25px;
    justify-content: center;
`;

const DetailsWrapper = styled.div`
    width: 300px;
`;
const Detail = styled.div`
    display: flex;
    flex-wrap: wrap;
    margin: 10px 0;
`;
const DetailName = styled.div`
    color: #394372;
    font-weight: bold;
    margin-right: 5px;
`;

const DetailValue = styled.div`
    color: #757575;
`;

const GridCell = styled.div`
    width: 100%;
    display: block;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
`;

const ProductBtn = styled.div`
    color: #1890ff;
    text-decoration: none;
    background-color: transparent;
    outline: none;
    cursor: pointer;
    transition: color 0.3s;

    &:hover {
        color: #40a9ff;
    }
`;

const BottomSection = styled.div`
    width: 100%;
    padding-top: 25px;
    display: flex;
    justify-content: flex-end;
    align-items: center;
    height: 55px;
`;

const Buttons = styled.div`
    display: flex;
    justify-content: flex-end;

    button {
        margin-right: 20px;
    }
`;

export default ProductsModalContent;
