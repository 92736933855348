import React from 'react';
import { ColumnType } from '../../../../shared/Grid/types/Column';
import { deletedRecord } from '../../../../../models/entities/admin/deletedRecord';
import Button from '../../../../shared/SmallComponents/Button';
import { Modal } from 'antd';
import { PagesRoutes } from '../../../../../routing/PagesRoutes';
import { useHistory, Link } from 'react-router-dom';

const GridColumns = (
    localization: any,
    restoreEntity: (entityId: string, entityType: string) => Promise<void>,
    deleteEntity: (entityId: string, entityType: string) => Promise<void>,
    historyPush: (path: string) => void
): Array<ColumnType<deletedRecord>> => [
    {
        field: 'CargoZoneNumber',
        width: '150px',
        sortable: true,
        textAlign: 'center',
        bindedFields: ['id', 'entity'],
        renderer: (cellValue: any, bindedData?: Array<any> | null) => (
            <Link to={{}} onClick={() => redirectByType(bindedData?.[0], bindedData?.[1], historyPush)}>
                {cellValue}
            </Link>
        )
    },
    {
        field: 'entity',
        width: '200px',
        sortable: true
    },
    {
        field: 'deletedAt',
        width: '200px',
        columnType: 'date',
        sortable: true
    },
    {
        field: 'id',
        sortable: false,
        bindedFields: ['entity'],
        width: '100%',
        renderer: (cellValue: any, bindedData?: Array<any> | null) => (
            <Button
                onClick={() =>
                    restoreEntity(cellValue, bindedData?.[0]).then(() => {
                        Modal.confirm({
                            zIndex: 1100,
                            content: <div>{localization.undo_modal_title}</div>,
                            okText: localization.goto_btn,
                            cancelText: localization.undo_btn,
                            onOk() {
                                redirectByType(cellValue, bindedData?.[0], historyPush);
                            },
                            async onCancel() {
                                await deleteEntity(cellValue, bindedData?.[0]);
                            }
                        });
                    })
                }
            >
                {localization.grid.columns.restoreBtn}
            </Button>
        )
    }
];

const redirectByType = (id: string, type: string, historyPush: (path: string) => void) => {
    switch (type) {
        case 'SHIPMENT':
            historyPush(PagesRoutes.Shipments + '/' + id);
            break;
        case 'PO':
            historyPush(PagesRoutes.PurchaseOrders + '/' + id);
            break;
        case 'PD':
            historyPush(PagesRoutes.PurchaseDemands + '/' + id);
            break;
        case 'BUSINESS_PARTNER':
            historyPush(PagesRoutes.BusinessPartners + '/' + id);
            break;
    }
};
export default GridColumns;
