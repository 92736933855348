import { ColumnType } from '../../../components/shared/Grid/types/Column';
import { purchaseOrder } from '../../../models/entities/purchaseOrder/purchaseOrder';
import SideEffectJS from 'side-effect-js';
import { ModelConfig } from '@rematch/core';
import { RootState } from '../../store/store';
import { localSDK as client } from '../../../sdk';

export type filterFieldsType = Array<{ field: string; value: any }>;

export type paginationType = { currentPage: number; rowsPerPage: number };

export type purchaseOrdersPageStateType = {
    gridColumns: Array<ColumnType<purchaseOrder>>;
    fetchGridColumnsError: string | null;
    gridSort: gridSortState | null;
    gridFilter: string | null;
    error: string | null;
    filterFields: filterFieldsType | null;
    pagination: paginationType;
};

export type gridSortState = {
    direction: 'asc' | 'desc';
    column: keyof purchaseOrder;
};

export type exportTypes = 'PDF' | 'EXCEL';

const defaultGridSort: gridSortState = {
    direction: 'desc',
    column: 'CargoZoneNumber'
};

export const allPurchaseOrdersPage: ModelConfig<purchaseOrdersPageStateType> = {
    state: {
        gridColumns: [],
        fetchGridColumnsError: null,
        gridSort: defaultGridSort,
        gridFilter: null,
        error: null,
        filterFields: null,
        pagination: {
            currentPage: 0,
            rowsPerPage: 30
        }
    },
    reducers: {
        setGridColumns(state: purchaseOrdersPageStateType, columns: Array<ColumnType<purchaseOrder>>): purchaseOrdersPageStateType {
            return { ...state, gridColumns: columns, fetchGridColumnsError: null };
        },
        setFetchColumnsError(state: purchaseOrdersPageStateType, error: string): purchaseOrdersPageStateType {
            return { ...state, fetchGridColumnsError: error };
        },
        setGridSort(state: purchaseOrdersPageStateType, sortData: gridSortState): purchaseOrdersPageStateType {
            return { ...state, gridSort: sortData };
        },
        setGridFilter(state: purchaseOrdersPageStateType, filterText: string): purchaseOrdersPageStateType {
            return { ...state, gridFilter: filterText };
        },
        setCurrentPage(state: purchaseOrdersPageStateType, currentPage: number): purchaseOrdersPageStateType {
            return { ...state, pagination: { ...state.pagination, currentPage } };
        },
        setRowsPerPage(state: purchaseOrdersPageStateType, rowsPerPage: number): purchaseOrdersPageStateType {
            return { ...state, pagination: { ...state.pagination, rowsPerPage } };
        },
        setFilterFields(state: purchaseOrdersPageStateType, filterFields: filterFieldsType): purchaseOrdersPageStateType {
            return { ...state, filterFields };
        },
        setError(state: purchaseOrdersPageStateType, error: string): purchaseOrdersPageStateType {
            return { ...state, error: error };
        }
    },
    effects: (dispatch: any) => ({
        async fetchGridColumns() {
            try {
                const fetchGridColumns = SideEffectJS.Get('gridColumnsPurchaseOrdersPage');
                const gridColumns = await fetchGridColumns();
                dispatch.allPurchaseOrdersPage.setGridColumns(gridColumns);
            } catch (e) {
                dispatch.allPurchaseOrdersPage.setFetchColumnsError(e);
            }
        },
        async exportDataTable(type: exportTypes, state: RootState) {},
        async exportDataRow({ purchaseOrderId, type, fileName }: { purchaseOrderId: string; type: exportTypes; fileName: string }) {
            dispatch.loadingNotify.setLoadingState({ visible: true, text: 'Export purchase order...' });
            await client.purchaseOrders().exportPurchaseOrder(purchaseOrderId, type, fileName);
            dispatch.loadingNotify.setLoadingState({ visible: false });
        },
        async setPurchaseOrderFilters(filter: filterFieldsType) {
            dispatch.allPurchaseOrdersPage.setFilterFields(filter);
            dispatch.allPurchaseOrdersPage.setCurrentPage(0);
        },
        async fetchPurchaseOrdersByFilter(filter: filterFieldsType) {
            try {
                dispatch.allPurchaseOrdersPage.setFilterFields(filter);
                const purchaseOrders = await client.purchaseOrders().fetchPurchaseOrdersByFilter(filter);
                dispatch.purchaseOrders.setPurchaseOrders(purchaseOrders);
                dispatch.allPurchaseOrdersPage.setCurrentPage(0);
            } catch (e) {
                dispatch.allPurchaseOrdersPage.setError(e);
            }
        },
        async deletePurchaseOrder(purchaseOrderId: string) {
            await client.purchaseOrders().deletePurchaseOrder(purchaseOrderId);
            dispatch.purchaseOrders.removePurchaseOrder(purchaseOrderId);
        },
        async followPurchaseOrder(purchaseOrderId: string, state: RootState) {
            const follower = await client.purchaseOrders().follow().followPurchaseOrder(purchaseOrderId, state.user.userInfo.profileImg);
            dispatch.user.setPurchaseOrderFollow(purchaseOrderId, true);
            dispatch.purchaseOrders.addPurchaseOrderFollower(purchaseOrderId, follower);
        },
        async unFollowPurchaseOrder(purchaseOrderId: string, state: RootState) {
            await client.purchaseOrders().follow().unFollowPurchaseOrder(purchaseOrderId);
            dispatch.user.setPurchaseOrderFollow(purchaseOrderId, false);
            dispatch.purchaseOrders.removePurchaseOrderFollower(purchaseOrderId, state.user.userInfo.id);
        }
    })
};
