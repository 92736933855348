import { RootState } from '../../store/store';
import { createSelector } from 'reselect';
import orderBy from 'lodash/orderBy';
import { businessPartnersSelectors } from '../businessPartner/selectors';
import { businessPartner } from '../../../models/entities/businessPartner/businessPartner';
import filterBusinessPartners from './companiesGridFilter';
import { gridSortCallback } from '../../../utils/sortArray';

const gridSort = (state: RootState) => state.allBusinessPartnersPage.gridSort;
const gridFilter = (state: RootState) => state.allBusinessPartnersPage.gridFilter;
const filterFields = (state: RootState) => state.allBusinessPartnersPage.filterFields;
const pagination = (state: RootState) => state.allBusinessPartnersPage.pagination;

const activeFiltersCount = createSelector(filterFields, (filterFields) => {
    if (!filterFields) return 0;
    return filterFields.length;
});

const activeBusinessPartnersCount = createSelector(businessPartnersSelectors.businessPartners, (businessPartners: Array<businessPartner>) => {
    return businessPartners.length;
});

const gridData = createSelector(
    gridSort,
    gridFilter,
    businessPartnersSelectors.businessPartners,
    filterFields,
    (gridSort, gridFilter, companiesData: Array<businessPartner>, filterFields) => {
        if (gridFilter && gridFilter !== '' && gridFilter.length > 2) {
            companiesData = companiesData?.filter((s) => JSON.stringify(s).toLocaleLowerCase().includes(gridFilter.toLocaleLowerCase()));
        }
        if (filterFields) {
            companiesData = filterBusinessPartners(companiesData, filterFields);
        }
        if (gridSort && companiesData) {
            switch (gridSort.column) {
                default:
                    companiesData = orderBy(companiesData, gridSortCallback(gridSort.column), [gridSort.direction]);
            }
        }
        return companiesData;
    }
);

const filteredBPsCount = createSelector(gridData, (gridData) => {
    return gridData?.length || 0;
});

const currentGridDataPage = createSelector(pagination, gridData, (pagination, gridData) => {
    const { currentPage, rowsPerPage } = pagination;

    return gridData.slice(currentPage * rowsPerPage, currentPage * rowsPerPage + rowsPerPage);
});

export { default as allBusinessPartnersPageSelectors } from './selectors';

export default {
    gridSort,
    gridFilter,
    gridData,
    activeFiltersCount,
    activeBusinessPartnersCount,
    filteredBPsCount,
    currentGridDataPage,
    filterFields,
    pagination
};
