import React from 'react';
import { PagesRoutes } from '../../../../routing/PagesRoutes';
import StyledLink from '../../SmallComponents/StyledLink';

type Props = {
    purchaseOrderId: string;
    purchaseOrderNumber: string;
};

const PurchaseOrderLink = ({ purchaseOrderId, purchaseOrderNumber }: Props) => {
    return <StyledLink to={`${PagesRoutes.PurchaseOrders}/${purchaseOrderId}`}>{purchaseOrderNumber}</StyledLink>;
};

export default PurchaseOrderLink;
