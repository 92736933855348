import BaseController from '../..';
import { document } from '../../../../models/entities/common_subentities/document';
import IPurchaseOrderDocuments from './IPurchaseOrderDocuments';

export default class PurchaseOrderDocuments extends BaseController implements IPurchaseOrderDocuments {
    constructor(baseUrl: string) {
        super(baseUrl);
    }
    async fetchPurchaseOrderDocuments(purchaseOrderNumber: string) {
        const response = await this.client.get(`/purchaseOrder/documents/${purchaseOrderNumber}`);
        return response;
    }
    async createMultiplePurchaseOrderDocument(purchaseOrderId: string, data: Array<document>) {
        this.client.setBodyType('FORM-DATA');
        const response = await this.client.post(`/purchaseOrder/documents/upload-multiple`, this.convertToArrayFormData(data, purchaseOrderId));
        return response;
    }
    async createPurchaseOrderDocument(purchaseOrderId: string, data: document) {
        this.client.setBodyType('FORM-DATA');
        const response = await this.client.post(`/purchaseOrder/documents/upload`, this.convertToFormData(data, purchaseOrderId));
        return response;
    }
    async updatePurchaseOrderDocument(purchaseOrderId: string, data: document) {
        this.client.setBodyType('FORM-DATA');
        const response = await this.client.put(`/purchaseOrder/documents`, this.convertToFormData(data, purchaseOrderId));
        return response;
    }
    async deletePurchaseOrderDocument(purchaseOrderId: string, documentId: number) {
        const response = await this.client.delete(`/purchaseOrder/documents`, JSON.stringify({ purchaseOrderId, id: documentId }));
        return response;
    }
    private convertToFormData(document: document, purchaseOrderId: string): FormData {
        const formData = new FormData();
        let type = document.type;
        if (Array.isArray(type)) type = JSON.stringify(type);
        formData.append('purchaseOrderId', purchaseOrderId);
        formData.append('type', type);
        formData.append('comment', document.comment);
        formData.append('fileName', document.fileName);
        formData.append('fileType', document.fileType);
        if (document.expDate) formData.append('expDate', JSON.stringify(document.expDate));
        if (document.file) formData.append('file', document.file);
        if (document.id) formData.append('id', '' + document.id);
        return formData;
    }
    private convertToArrayFormData(documents: Array<document>, purchaseOrderId: string): FormData {
        const formData = new FormData();
        formData.append('purchaseOrderId', purchaseOrderId);
        documents.forEach((document) => {
            let type = document.type;
            if (Array.isArray(type)) type = JSON.stringify(type);
            formData.append('type[]', type);
            formData.append('comment[]', document.comment);
            formData.append('fileName[]', document.fileName);
            formData.append('fileType[]', document.fileType);
            if (document.expDate) formData.append('expDate[]', JSON.stringify(document.expDate));
            if (document.file) formData.append('file[]', document.file);
            if (document.id) formData.append('id[]', '' + document.id);
        });
        return formData;
    }
}
