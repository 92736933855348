import React from 'react';
import { Link } from 'react-router-dom';
import { notificationContent } from '../../../models/entities/notification/notificationContent';
import { PagesRoutes } from '../../../routing/PagesRoutes';
import { styled } from '../../shared/Theme/theme';

export const parseContent = (content: notificationContent, onClickRef?: () => void) => {
    const regex = /(^|@|#)([^@#]+?)(?=$|@|#)/g;
    let res;
    const elements = [];
    let key = 0;

    const shipmentRefTab = (() => {
        if (content.relatedEntityType === 'SHIPMENT') {
            switch (content.subType) {
                case 'EVENT':
                    return 'events';
                case 'DOCUMENT':
                    return 'documents';
                case 'DISCUSSION_MENTION':
                    return 'discussions';

                default:
                    return 'history';
            }
        }
    })();

    while ((res = regex.exec(content.message))) {
        const [match, prefix, text] = res; // eslint-disable-line @typescript-eslint/no-unused-vars
        const splittedText = text.split(' ');
        if (!prefix || prefix === '') elements.push(<span key={key}>{text}</span>);
        else if (prefix === '@')
            elements.push(
                content.relatedEntityType !== 'TASK' ? <UserRef key={key}>@{splittedText[0]} </UserRef> : <span key={key}>@{splittedText[0]} </span>,
                <span key={key + 1}>{splittedText.filter((item, index) => index !== 0).join(' ')}</span>
            );
        else if (prefix === '#')
            elements.push(
                content.relatedEntityType !== 'TASK' ? (
                    content.relatedEntityType === 'PURCHASE_ORDER' ? (
                        <EntityRef onClick={onClickRef} to={PagesRoutes.PurchaseOrders + `/${content.relatedEntityId}`} key={key}>
                            #{splittedText[0]}{' '}
                        </EntityRef>
                    ) : content.relatedEntityType === 'PURCHASE_DEMAND' ? (
                        <EntityRef onClick={onClickRef} to={PagesRoutes.PurchaseDemands + `/${content.relatedEntityId}`} key={key}>
                            #{splittedText[0]}{' '}
                        </EntityRef>
                    ) : content.relatedEntityType === 'ORDER' ? (
                        <EntityRef onClick={onClickRef} to={PagesRoutes.Orders + `/${content.relatedEntityId}`} key={key}>
                            #{splittedText[0]}{' '}
                        </EntityRef>
                    ) : (
                        <EntityRef
                            onClick={onClickRef}
                            to={PagesRoutes.Shipments + `/${content.relatedEntityId}${!!shipmentRefTab ? `?tab=${shipmentRefTab}` : ''}`}
                            key={key}
                        >
                            #{splittedText[0]}{' '}
                        </EntityRef>
                    )
                ) : (
                    <span key={key}>#{splittedText[0]} </span>
                ),
                <span key={key + 1}>{splittedText.filter((item, index) => index !== 0).join(' ')}</span>
            );
        key = key + 2;
    }
    return <div>{elements}</div>;
};

const UserRef = styled.span`
    cursor: pointer;
    color: #008ac9;
`;
const EntityRef = styled(Link)`
    cursor: pointer;
    color: #008ac9;
`;
