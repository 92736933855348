import React from 'react';
import { reference } from '../../../../models/entities/common_subentities/reference';
import CustomModal, { ModalTriggerProps } from '../../../shared/Modal/Modal';
import { styled } from '../../../shared/Theme/theme';
import RefFormConnected, { formMode } from './RefFormConnected';

type Props = {
    entityId: string;
    reference: reference;
    localization: any;
    isEditable: boolean;
    createReference: (entityId: string, reference: reference) => Promise<void>;
    updateReference: (entityId: string, reference: reference) => Promise<void>;
    deleteReference: (entityId: string, referenceId: string) => Promise<void>;
    showLoader?: boolean;
};

const ReferencesItem = ({ entityId, reference, localization, isEditable, createReference, updateReference, deleteReference, showLoader }: Props) => {
    return isEditable ? (
        <CustomModal
            title={{ text: `${localization.modal.edit} ${localization.types[reference.companyType]}` }}
            Trigger={(props: ModalTriggerProps) => <RefItemEditable onClick={props.onClick}>{reference.value}</RefItemEditable>}
            Content={(setVisible?: React.Dispatch<React.SetStateAction<boolean>>) => {
                return (
                    <RefFormConnected
                        mode={formMode.EDIT}
                        openedFromModal={setVisible ? { setVisible } : undefined}
                        entityId={entityId}
                        reference={reference}
                        createReference={createReference}
                        updateReference={updateReference}
                        deleteReference={deleteReference}
                        localization={localization}
                        showLoader={showLoader}
                    />
                );
            }}
        />
    ) : (
        <RefItem>{reference.value}</RefItem>
    );
};

const RefItem = styled.div`
    padding: 3px;
    margin: 0 2px;
`;
const RefItemEditable = styled(RefItem)`
    cursor: pointer;
    :hover {
        text-decoration: underline;
    }
`;

export default ReferencesItem;
