import React from 'react';
import CZTooltip, { QuestionToolTip } from '../CZTooltip/CZTooltip';
import { contact } from '../../../models/entities/businessPartner/contact';
import styled from 'styled-components';

type Props = {
    title: string;
    content: string;
    tooltipText: string | Array<contact>;
    imgSrc?: string;
    imgAlt?: string;
};

const ShipmentTitleWithTooltip = ({ title, content, tooltipText, imgSrc, imgAlt }: Props) => {
    const renderTooltipContent = () => {
        if (typeof tooltipText === 'string') {
            return tooltipText;
        } else {
            return tooltipText.map((c: { name: string; email: string; phoneNumber: string }) => (
                <div key={c.email}>
                    <div>
                        <strong>Name:</strong> {c.name}
                    </div>
                    <div>
                        <strong>Email:</strong> {c.email}
                    </div>
                    <div>
                        <strong>Phone Number:</strong> {c.phoneNumber}
                    </div>
                </div>
            ));
        }
    };

    return (
        <Container>
            <TitleContainer>
                <Title>{title}</Title>
                <StyledCZTooltip text={renderTooltipContent()} placement={'top'}>
                    {QuestionToolTip}
                </StyledCZTooltip>
            </TitleContainer>
            <div>{content}</div>
            <div>{imgSrc && <img src={imgSrc} alt={imgAlt} width={80} />}</div>
        </Container>
    );
};

const Container = styled.div`
    align-items: center;
`;

const TitleContainer = styled.div`
    display: flex;
    align-items: center;
`;

const Title = styled.label`
    white-space: nowrap;
`;

const StyledCZTooltip = styled(CZTooltip)`
    .ant-tooltip-inner {
        white-space: pre-line;
    }
`;

export default ShipmentTitleWithTooltip;
