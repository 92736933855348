import React from 'react';
import { document } from '../../../../../../models/entities/common_subentities/document';
import DocumentFormConnected from '../../DocumentForm/DocumentFormConnected';
import { settings } from '../../../../../../models/entities/product/product';
import MultipleDocumentFormConnected from '../../DocumentForm/MultipleDocumentFormConnected';
import Documents from '../../../../commonSubEntities/documents/Documents';

type Props = {
    productId: string;
    documents?: Array<document>;
    localization: any;
    settings?: settings;
};

const ProductDocuments = ({ documents, localization, settings, productId }: Props) => {
    return (
        <Documents<settings>
            documents={documents}
            localization={localization}
            settings={settings}
            menuType="productDocumentMenu"
            MultipleDocumentFormConnected={MultipleDocumentFormConnected}
            DocumentFormConnected={DocumentFormConnected}
            entityId={productId}
        />
    );
};

export default ProductDocuments;
