import shipmentItem from '../../../../../models/entities/shipment/shipmentItem';
import IDimensionsWeight from '../../../../../interfaces/dimensionsWeight';
import { calcTotalVolume, calcTotalWeight, calcVolume } from '../../../../../utils/measurementUtils';

const parseShipmentItemDimenssionsWeight = (item: shipmentItem) => {
    const {
        masterCartonWeight,
        masterCartonWeightUnit,
        masterCartonHeight,
        masterCartonWidth,
        masterCartonLength,
        masterCartonDimensionUnit,
        quantity,
        weight,
        weightUnit,
        height,
        width,
        length,
        dimensionsUnit,
        unitsPerMasterCarton
    } = item;

    const isCartonDataExist =
        unitsPerMasterCarton &&
        masterCartonWeight &&
        masterCartonWeightUnit &&
        masterCartonHeight &&
        masterCartonWidth &&
        masterCartonLength &&
        masterCartonDimensionUnit;

    const dimensionWeight: IDimensionsWeight = {
        weight: isCartonDataExist ? masterCartonWeight : weight,
        weightUnit: isCartonDataExist ? masterCartonWeightUnit : weightUnit,
        height: isCartonDataExist ? masterCartonHeight : height,
        width: isCartonDataExist ? masterCartonWidth : width,
        length: isCartonDataExist ? masterCartonLength : length,
        dimensionsUnit: isCartonDataExist ? masterCartonDimensionUnit : dimensionsUnit,
        quantity: isCartonDataExist && quantity && unitsPerMasterCarton ? Math.ceil(quantity / unitsPerMasterCarton) : quantity
    };

    return { dimensionWeight, isCartonDataExist };
};

const dimensionsWeights = (items: shipmentItem[]) =>
    items.reduce(
        (dimensionsWeights: Array<IDimensionsWeight>, item: shipmentItem) => [
            ...dimensionsWeights,
            parseShipmentItemDimenssionsWeight(item).dimensionWeight
        ],
        []
    );
export const calcShipmentItemsTotalVolumeWeight = (items: shipmentItem[]) => {
    const itemsDimensionsWeights = dimensionsWeights(items);

    return {
        volume: calcTotalVolume(itemsDimensionsWeights),
        weight: calcTotalWeight(itemsDimensionsWeights),
        quantity: items.reduce((totalQuantity: number, item: shipmentItem) => {
            return totalQuantity + (item.quantity || 0);
        }, 0)
    };
};

export const calcItemVolume = (item: shipmentItem, isAvgVolumeByCartons?: boolean) => {
    const { dimensionWeight, isCartonDataExist } = parseShipmentItemDimenssionsWeight(item);
    let { volume, unit } = calcVolume(dimensionWeight);

    if (isCartonDataExist && item.unitsPerMasterCarton && dimensionWeight.quantity && item.quantity) {
        volume = isAvgVolumeByCartons ? volume / item.quantity : (volume / dimensionWeight.quantity) * item.unitsPerMasterCarton;
        return { volume, unit };
    }

    volume /= item.quantity || 1;

    return { volume, unit };
};
