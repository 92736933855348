import React from 'react';
import { GridCellParams, GridColDef, GridValueFormatterParams } from '@material-ui/data-grid';
import styled from 'styled-components';
import { orderItem } from '../../../../../models/entities/order/orderItem/orderItem';
import { MultiLineCell } from '../../../../shared/MuiDataGrid/MuiDataGrid';
import MaterialPopover from '../../../../shared/Popover/MaterialPopover';
import { currencyFormatter } from '../../../../shared/inputs/form/CurrencyInput/CurrencyInput';
import ObjectDetailsPopover from '../../../../shared/ObjectDetails/ObjectDetailsPopover';
import infoIcon from '../../../../../static/icons/info.svg';
import EditIcon from '../../../../../static/icons/edit.svg';
import SingleValueFormModal from '../../../../shared/SingleValueFormModal/SingleValueFormModal';
import { ModalTriggerProps } from '../../../../shared/Modal/Modal';
import { updateOrderItemPayload, deleteOrderItemPayload } from '../../../../../models/entities/order/orderItem/payloads';
import { Modal } from 'antd';
import NumberInput from '../../../../shared/inputs/base/NumberInput';
import { drawers } from '../../../../../state/ducks/header/header';
import { product } from '../../../../../models/entities/product/product';
import { Image } from 'antd';

type OrderItemsGridColDefFunc = (
    items: Array<orderItem>,
    updateItem: (payload: updateOrderItemPayload) => Promise<void>,
    deleteItem: (payload: deleteOrderItemPayload) => Promise<void>,
    setDrawerOpen: (open: drawers, params?: any) => void,
    localization: any,
    orderItemsRelatedProducts: Array<product>
) => GridColDef[];

export const OrderItemsGridColDef: OrderItemsGridColDefFunc = (
    items,
    updateItem,
    deleteItem,
    setDrawerOpen,
    localization,
    orderItemsRelatedProducts
) => [
    {
        field: 'productSku',
        headerName: localization.productSku.title,
        flex: 1,
        renderCell: (params: GridCellParams) => {
            return (
                <GridCell
                    onClick={(e) => {
                        e.stopPropagation();
                        if (params.getValue(params.id, 'productId') && setDrawerOpen)
                            setDrawerOpen('PRODUCT', { productId: params.getValue(params.id, 'productId') });
                    }}
                >
                    <LinkButton>{params.value}</LinkButton>
                </GridCell>
            );
        }
    },
    {
        field: 'name',
        headerName: localization.name.title,
        renderCell: (params: GridCellParams) => <MultiLineCell>{params.value}</MultiLineCell>,
        flex: 1
    },
    {
        field: 'Img',
        headerName: localization.images,
        hide: orderItemsRelatedProducts.every((p) => !p.latestProductImgUrl),
        renderCell: (params: GridCellParams) => {
            const relatedProductId = params.row.productId;

            if (!relatedProductId) return <GridCell>-</GridCell>;

            const relatedProductImgUrl = orderItemsRelatedProducts.find((p) => p.id === relatedProductId)?.latestProductImgUrl;

            if (!relatedProductImgUrl) return <GridCell>-</GridCell>;

            const restImges = orderItemsRelatedProducts
                ?.find((p) => p.id === relatedProductId)
                ?.documents?.filter((d) => d.type.includes('PRODUCT_IMG') && !!d.imgUrl && d.imgUrl !== relatedProductImgUrl);

            return (
                <Image.PreviewGroup>
                    <ItemImgContainer
                        onClick={(e) => {
                            e.stopPropagation();
                        }}
                    >
                        <Image width={35} src={relatedProductImgUrl} />
                    </ItemImgContainer>
                    <div style={{ display: 'none' }}>
                        {restImges?.map((img, i) => (
                            <Image key={i} width={0} src={img.imgUrl} />
                        ))}
                    </div>
                </Image.PreviewGroup>
            );
        },
        width: 100,
        sortable: false
    },
    {
        hide: !items?.some((item: orderItem) => !!item.attributes),
        field: 'attributes',
        headerName: localization.attributes.title,
        flex: 1,
        renderCell: (params: GridCellParams) => {
            const value = (params.value as { [key: string]: string }) || undefined;
            if (!value) return <GridCell>-</GridCell>;

            return (
                <div style={{ width: '100%' }}>
                    <MaterialPopover
                        anchor={
                            <MultiLineCell>
                                {Object.entries(value)
                                    .map(([key, name]) => `${key}: ${name}`)
                                    .join(', ')}
                            </MultiLineCell>
                        }
                    >
                        <GridCell>
                            {Object.entries(value).map(([key, name], i) => (
                                <Detail key={`${params.row.id}-att-option-${i}`}>
                                    <DetailName>{key}:</DetailName>
                                    <DetailValue>{name}</DetailValue>
                                </Detail>
                            ))}
                        </GridCell>
                    </MaterialPopover>
                </div>
            );
        }
    },
    {
        field: 'quantity',
        headerName: localization.quantity.title,
        width: 110,
        renderCell: (params: GridCellParams) => {
            const quantity: number = Number(params.value) || 1;
            const { id: itemId, orderId } = params.row;
            return (
                <NumberInput
                    onChange={async (value: number) => {
                        await updateItem({ orderId, itemId, item: { quantity: value } });
                    }}
                    value={quantity}
                    min={1}
                />
            );
        }
    },
    {
        field: 'price',
        headerName: localization.price.title,
        flex: 1,
        valueFormatter: (params: GridValueFormatterParams) => {
            const { currency } = params.row;
            return params.value ? currencyFormatter(currency)(params.value) : '-';
        }
    },
    {
        field: 'currency',
        headerName: 'Total Price',
        flex: 1,
        valueFormatter: (params: GridValueFormatterParams) => {
            const { price, quantity } = params.row;
            return price ? currencyFormatter(params.value)((Number(price) || 0) * (Number(quantity) || 1) || 0) : '-';
        }
    },
    {
        field: 'hsCodes',
        headerName: localization.hsCodes.title,
        hide: !items?.some((item: orderItem) => !!item.hsCodes?.length),
        width: 150,
        renderCell: (params: GridCellParams) => {
            const { hsCodes } = params.row;

            if (!hsCodes?.length) return <GridCell>-</GridCell>;

            const hsCodesObj: { [key: string]: string } = hsCodes.reduce((acc: { [key: string]: string }, hs: any) => {
                acc[hs.code] = `${hs.percent}%`;

                return acc;
            }, {});
            return (
                <div style={{ width: '100%' }}>
                    <ObjectDetailsPopover
                        anchor={<GridCell>{hsCodes.map((hsCode: any) => `${hsCode.code} - ${hsCode.percent}%`).join(', ')}</GridCell>}
                        object={hsCodesObj}
                        // localization={localization}
                    />
                </div>
            );
        }
    },
    {
        field: 'serialNumber',
        headerName: localization.serialNumber.title,
        flex: 1,
        renderCell: (params: GridCellParams) => {
            const { value } = params;
            const { id: itemId, orderId } = params.row;

            return (
                <EditableCell
                    onKeyDown={(e) => e.stopPropagation()}
                    onClick={(e) => {
                        e.stopPropagation();
                    }}
                >
                    {value || '-'}
                    {itemId && (
                        <SingleValueFormModal
                            title={'Serial Number'}
                            initialValue={value as string}
                            valueType="text"
                            onSubmit={async (value: string) => {
                                await updateItem({ orderId, itemId, item: { serialNumber: value } });
                            }}
                            Trigger={(props: ModalTriggerProps) => {
                                return (
                                    <span>
                                        <img
                                            src={EditIcon}
                                            onMouseDown={(e) => {
                                                props.onClick();
                                            }}
                                            className="edit-icon"
                                            alt={'edit'}
                                        />
                                    </span>
                                );
                            }}
                        />
                    )}
                </EditableCell>
            );
        }
    },
    {
        field: 'id',
        headerName: ' ',
        flex: 1,
        renderCell: (params: GridCellParams) => {
            const { id, orderId } = params.row;
            const itemIndex = items?.findIndex((item) => item.id === params.id);
            if (itemIndex === -1) return <GridCell>-</GridCell>;

            const parseObjectValues = (key: string, value: any): string => {
                if (key === 'weight') {
                    return `${value} ${items[itemIndex].weightUnit || ''}`;
                }

                if (['width', 'length', 'height'].includes(key)) {
                    return `${value} ${items[itemIndex].dimensionsUnit || ''}`;
                }

                return value;
            };

            const filterCondition = (key: string, value: any): boolean =>
                !!value &&
                Number(value) !== 0 &&
                !['productSku', 'name', 'price', 'quantity', 'updatedAt', 'attributes', 'serialNumber'].includes(key) &&
                (!key.toLowerCase().includes('id') || key === 'width') &&
                !key.toLowerCase().includes('currency') &&
                !key.toLowerCase().includes('unit');

            return (
                <ItemOptionsContainer>
                    <ObjectDetailsPopover
                        anchor={
                            <InfoIconWrapper>
                                <InfoIcon src={infoIcon} />
                            </InfoIconWrapper>
                        }
                        parseObjectValues={parseObjectValues}
                        filterCondition={filterCondition}
                        object={items?.[itemIndex] || {}}
                        localization={localization}
                    />

                    <VerticalDivider />
                    <DeleteContainer
                        onClick={async (e) => {
                            e.stopPropagation();
                            Modal.confirm({
                                zIndex: 1100,
                                title: <div>{'Are you sure?'}</div>,
                                async onOk() {
                                    await deleteItem({ orderId, itemId: id });
                                },
                                onCancel() {}
                            });
                        }}
                    >
                        <span className="material-icons">delete</span>
                    </DeleteContainer>
                </ItemOptionsContainer>
            );
        }
    }
];

const Detail = styled.div`
    display: flex;
    flex-wrap: wrap;
    margin: 10px 0;
`;

const DetailName = styled.div`
    color: #394372;
    font-weight: bold;
    margin-right: 5px;
`;

const DetailValue = styled.div`
    color: #757575;
`;

const GridCell = styled.div`
    width: 100%;
    display: block;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
`;

const ItemOptionsContainer = styled.div`
    display: flex;
    width: 100%;
    height: 40%;
    justify-content: space-around;
    align-items: center;
`;

const InfoIcon = styled.img`
    width: 20px;
    height: 20px;
`;

const InfoIconWrapper = styled.div`
    display: flex;
    position: relative;
    width: 25px;
    justify-content: center;
`;

const DeleteContainer = styled.div`
    display: flex;
    color: ${(props) => props.theme.colors.primaryBlue};
    span.material-icons {
        cursor: pointer;
    }
`;

const VerticalDivider = styled.div`
    height: 100%;
    width: 1px;
    background: rgb(0 0 0 / 14%);
`;

const EditableCell = styled.div`
    width: 100%;
    display: flex;

    .edit-icon {
        display: none;
    }

    &:hover {
        .edit-icon {
            display: initial;
        }
    }
`;

const LinkButton = styled.div`
    color: #1890ff;
    text-decoration: none;
    background-color: transparent;
    outline: none;
    cursor: pointer;
    transition: color 0.3s;

    &:hover {
        color: #40a9ff;
    }
`;

const ItemImgContainer = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;

    .ant-image-mask-info {
        font-size: 0;
        display: flex;
        justify-content: center;
        align-items: center;
    }

    .ant-image-mask-info .anticon {
        font-size: 14px;
        margin-inline-end: 0;
    }
`;
