import React from 'react';
import editIcon from '../../../../static/icons/edit.svg';
import trash from '../../../../static/icons/trash.svg';
import CellTrigger from '../../Menu/MenuTriggers/CellTrigger';
import { RootState } from '../../../../state/store/store';
import { connect } from 'react-redux';
import { singleShipmentSelectors } from '../../../../state/ducks/singleShipment/selectors';
import { shipment } from '../../../../models/entities/shipment/shipment';
import Menu from '../../Menu/Menu';
import MenuItem from '../../Menu/MenuItem';
import { userSelectors } from '../../../../state/ducks/user/selectors';
import claimsType from '../../../../models/entities/claimsType';
import { userInfo } from '../../../../models/entities/user';
import { claimsDomainsType } from '../../../../userClaims';
type onRowMoreOptionClickType = () => void;
type deleteShipmentDocumentType = (shipmentNumber: string, documentId: string, userId: string) => void;
type Props = {
    entityId: string;
    userId: string;
    localization: any;
    onRowMoreOptionsClick?: onRowMoreOptionClickType;
    deleteShipmentDocument: deleteShipmentDocumentType;
    getUserClaims: (domain: claimsDomainsType) => claimsType;
    shipment?: shipment;
    userInfo: userInfo;
};

const ShipmentDocumentMenu = ({
    entityId,
    userId,
    shipment,
    localization,
    getUserClaims,
    userInfo,
    onRowMoreOptionsClick,
    deleteShipmentDocument
}: Props) => {
    const { edit_document: editClaim, delete_document: deleteClaim } = getUserClaims('shipment');
    const isDocumentCreatedByCurrentUser = userId === userInfo.id;

    const editItem = <MenuItem onClick={() => onRowMoreOptionsClick && onRowMoreOptionsClick()} icon={editIcon} text={localization.edit} />;
    const deleteItem = (
        <MenuItem onClick={() => shipment && deleteShipmentDocument(shipment.id, entityId, userId)} icon={trash} text={localization.delete} />
    );

    const menuItems =
        isDocumentCreatedByCurrentUser || (editClaim && deleteClaim)
            ? [editItem, deleteItem]
            : editClaim
            ? [editItem]
            : deleteClaim
            ? [deleteItem]
            : [];

    return menuItems.length ? (
        <Menu options={menuItems} MenuTrigger={CellTrigger} strStyle="display: flex;align-items: center;width: 100%;height: 100%;" />
    ) : (
        <div></div>
    );
};

const mapStateToProps = (state: RootState) => ({
    shipment: singleShipmentSelectors.shipment(state),
    getUserClaims: (domain: claimsDomainsType) => userSelectors.getUserClaims(state)(domain),
    userInfo: userSelectors.userInfo(state)
});

const mapDispatchToProps = (dispatch: any) => ({
    deleteShipmentDocument: (shipmentNumber: string, documentId: string, userId: string) =>
        dispatch.shipments.deleteShipmentDocument({ shipmentNumber, documentId, userId })
});

export default connect(mapStateToProps, mapDispatchToProps)(ShipmentDocumentMenu);
