import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { RootState } from '../../../state/store/store';
import { localizationSelectors } from '../../../state/ducks/localization/selectors';
import { tariffsSelectors } from '../../../state/ducks/tariffs/selectors';
import { tariff } from '../../../models/entities/tariff/tariff';
import Loader from '../../shared/SmallComponents/Loader';
import tariffsGridColumns from './tariffsGridColumns';
import GridPage from '../../shared/PageTemplates/GridPage/GridPage';
import { paginationType } from '../../shared/PageTemplates/GridPage/components/GridPageHeader/models';
import { PagesRoutes } from '../../../routing/PagesRoutes';

type Props = {
    tariffs: tariff[];
    isFetchingTariffs: boolean;
    localization: any;
    activeTariffsCount: number;
    filteredTariffsCount: number;
    pagination: paginationType;
    fetchTariffs: () => Promise<void>;
    setGridFilter: (text: string) => void;
    setCurrentPage: (page: number) => void;
    setRowsPerPage: (rowsPerPage: number) => void;
};

const TariffsPageConnected = ({
    tariffs,
    isFetchingTariffs,
    localization,
    activeTariffsCount,
    filteredTariffsCount,
    pagination,
    fetchTariffs,
    setGridFilter,
    setCurrentPage,
    setRowsPerPage
}: Props) => {
    useEffect(() => {
        fetchTariffs();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    if (isFetchingTariffs && tariffs.length <= 1) return <Loader />;
    return (
        <GridPage<tariff>
            showLoadingOverlay={isFetchingTariffs && activeTariffsCount > 1}
            gridProps={{
                data: tariffs,
                columns: tariffsGridColumns(localization),
                emptyStateText: 'localization.grid.emptyState'
            }}
            headerProps={{
                data: tariffs,
                activeDataCount: activeTariffsCount,
                filteredDataCount: filteredTariffsCount,
                pagination,
                gridColumns: tariffsGridColumns(localization),
                currentGridFilter: null,
                localization,
                isMobile: false,
                addBtn: {
                    title: localization.newTariff,
                    pathname: PagesRoutes.NewTariff
                },
                setGridFilter,
                fetchData: fetchTariffs,
                setCurrentPage,
                setRowsPerPage
            }}
        />
    );
};

const mapStateToProps = (state: RootState) => ({
    localization: localizationSelectors.tariffsPage(state),
    tariffs: tariffsSelectors.currentGridDataPage(state),
    isFetchingTariffs: tariffsSelectors.isFetchingTariffs(state),
    activeTariffsCount: tariffsSelectors.activeTariffsCount(state),
    filteredTariffsCount: tariffsSelectors.filteredTariffsCount(state),
    pagination: tariffsSelectors.pagination(state)
});

const mapDispatchToProps = (dispatch: any) => ({
    fetchTariffs: () => dispatch.tariffs.fetchTariffs(),
    setGridFilter: (text: string) => dispatch.tariffs.setGridFilter(text),
    setCurrentPage: (page: number) => dispatch.tariffs.setCurrentPage(page),
    setRowsPerPage: (rowsPerPage: number) => dispatch.tariffs.setRowsPerPage(rowsPerPage)
});

export default connect(mapStateToProps, mapDispatchToProps)(TariffsPageConnected);
