import React from 'react';
import { Link } from 'react-router-dom';

type Props = {
    trackingNumber: string;
};
const TrackingNumberLink = ({ trackingNumber }: Props) => {
    return (
        <Link
            to={{
                pathname: `https://cargozone.trackingmore.com/?track_info=${trackingNumber}`
            }}
            target="_blank"
        >
            {trackingNumber}
        </Link>
    );
};

export default TrackingNumberLink;
