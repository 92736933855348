import { product } from '../../../models/entities/product/product';
import { filterFieldsType } from '../products/products';

const filterProducts = (products: product[], filterFields: filterFieldsType) => {
    let filteredProducts = [...products];

    filterFields.forEach((filter) => {
        switch (filter.field) {
            case 'supplierId':
                if (filter.value.length > 0) {
                    filteredProducts = filteredProducts.filter((product) => (filter.value as string[]).includes(product.supplierId));
                }
                break;
            case 'companyId':
                if (filter.value.length > 0) {
                    filteredProducts = filteredProducts.filter((product) => (filter.value as string[]).includes(product.companyId));
                }
                break;
            case 'madeIn':
                filteredProducts = filteredProducts.filter((product) => (filter.value as string).includes(product.madeIn));
                break;
            case 'brand':
                filteredProducts = filteredProducts.filter((product) => filterCaseSensitive(filter.value as string, product.brand));
                break;
            default:
                filteredProducts = filteredProducts.filter((product) =>
                    (product as any)[filter.field] ? (product as any)[filter.field].toString().includes(filter.value as string) : product
                );
                break;
        }
    });
    return filteredProducts;
};
const filterCaseSensitive = (filterValue: string, value: string) => {
    return filterValue && value ? filterValue.toLowerCase() === value.toLowerCase() : false;
};

export default filterProducts;
