import React from 'react';
import { FormikProps } from 'formik';
import OrderPartyForm from './OrderPartyForm';
import { OrderFormValues } from '../OrderForm';
import styled from 'styled-components';

type Props = {
    formikProps: FormikProps<OrderFormValues>;
    localization: any;
};

const Parties = (props: Props) => {
    const { values } = props.formikProps;
    const { parties } = values;
    return (
        <Container>
            <PartyContainer>
                <OrderPartyForm {...props} partyType="CUSTOMER" />
            </PartyContainer>
            {parties.PICKUP && (
                <PartyContainer>
                    <OrderPartyForm {...props} partyType="PICKUP" />
                </PartyContainer>
            )}
            {parties.PAYER && (
                <PartyContainer>
                    <OrderPartyForm {...props} partyType="PAYER" />
                </PartyContainer>
            )}
        </Container>
    );
};

const Container = styled.div`
    display: flex;
`;
const PartyContainer = styled.div`
    margin-right: 30px;
`;
export default Parties;
