import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { withRouter, RouteComponentProps } from 'react-router-dom';
import logo from '../../../../static/common/logo.svg';
import theme, { styled } from '../../Theme/theme';
import AvatarHeader from './AvatarHeader';
import { userInfo, userMenu } from '../../../../models/entities/user';
import { RootState } from '../../../../state/store/store';
import { userSelectors } from '../../../../state/ducks/user/selectors';
import NotificationsHeader from './NotificationsHeader';
import { notificationsSelectors } from '../../../../state/ducks/notifications/selectors';
import { drawers, headerMenus } from '../../../../state/ducks/header/header';
import { tasksSelectors } from '../../../../state/ducks/tasks/selectors';
import followIcon from '../../../../static/icons/follow.svg';
import { headerSelectors } from '../../../../state/ducks/header/selectors';
import { chatsSelectors } from '../../../../state/ducks/chats/selectors';
import { userDetails, usersObject } from '../../../../state/ducks/users/users';
import { usersSelectors } from '../../../../state/ducks/users/selectors';

const headerTheme = theme.colors.header;

type Props = RouteComponentProps & {
    userInfo: userInfo;
    avatarColor?: string;
    userMenu: userMenu;
    setUserMenuIsOpen: (isOpen: boolean) => void;
    addUsers: (usersIds: string[]) => void;
    setMenuOpen: (open: headerMenus) => void;
    setDrawerOpen: (open: drawers) => void;
    setUserStatus: (id: string, status: string) => void;
    notificationDrawerIsOpen: boolean;
    userMenuIsOpen: boolean;
    taskDrawerIsOpen: boolean;
    followDrawerIsOpen: boolean;
    unReadNotificationsCount?: number;
    currentPageTitle: string | null;
    hasGoBackBtn: boolean;
    chatUsersNotIsState: string[];
    connectedUsers: string[];
    users: usersObject;
    logout: () => void;
};

const HeaderConnected = ({
    userInfo,
    avatarColor,
    userMenu,
    setUserMenuIsOpen,
    setMenuOpen,
    setDrawerOpen,
    addUsers,
    setUserStatus,
    notificationDrawerIsOpen,
    userMenuIsOpen,
    taskDrawerIsOpen,
    followDrawerIsOpen,
    unReadNotificationsCount,
    history,
    currentPageTitle,
    chatUsersNotIsState,
    hasGoBackBtn,
    connectedUsers,
    users,
    logout
}: Props) => {
    useEffect(() => {
        if (chatUsersNotIsState.length) {
            addUsers(chatUsersNotIsState);
        }
    }, [chatUsersNotIsState.length, addUsers]);

    useEffect(() => {
        Object.keys(users).forEach((id: string) => {
            if (!connectedUsers.includes(id)) {
                setUserStatus(id, 'OOTO');
            }
        });
    }, [users.length, connectedUsers.length]);

    return (
        <HeaderContainer>
            <StyledLogo src={logo} alt="CARGOZONE" />
            <RightBar>
                <MobliePageTitle hasGoBackBtn={hasGoBackBtn}>{currentPageTitle}</MobliePageTitle>
                {hasGoBackBtn && (
                    <BackArrow className="material-icons" onClick={() => history.goBack()}>
                        keyboard_arrow_left
                    </BackArrow>
                )}
                <ActionButtonWrapper isOpen={followDrawerIsOpen} onClick={() => setDrawerOpen('FOLLOW')}>
                    <Icon isOpen={followDrawerIsOpen} className="icon-btn">
                        <img src={followIcon} alt="Following" />
                    </Icon>
                </ActionButtonWrapper>
                <ActionButtonWrapper isOpen={taskDrawerIsOpen} onClick={() => setDrawerOpen('TASK')}>
                    <Icon isOpen={taskDrawerIsOpen} className="material-icons icon-btn">
                        assignment_turned_in
                    </Icon>
                </ActionButtonWrapper>
                <ActionsContainer>
                    <NotificationsHeader
                        isOpen={notificationDrawerIsOpen}
                        setDrawerOpen={() => setDrawerOpen('NOTIFICATION')}
                        unReadNotificationsCount={unReadNotificationsCount}
                        pathname={history.location.pathname}
                    />
                    {/* <ActionButtonWrapper>
                        <IconButton className="material-icons">search</IconButton>
                    </ActionButtonWrapper> */}
                </ActionsContainer>
                <AvatarHeader
                    userInfo={
                        {
                            firstName: userInfo.firstName,
                            lastName: userInfo.lastName,
                            userName: userInfo.username,
                            status: userInfo.status,
                            avatarColor,
                            profileImgUrl: userInfo.profileImgUrl
                        } as userDetails
                    }
                    isOpen={userMenuIsOpen}
                    setMenuOpen={() => setMenuOpen('USER')}
                    hasGoBackBtn={hasGoBackBtn}
                />
            </RightBar>
        </HeaderContainer>
    );
};
const mapStateToProps = (state: RootState) => ({
    userInfo: userSelectors.userInfo(state),
    userMenu: userSelectors.userMenu(state),
    notificationDrawerIsOpen: notificationsSelectors.notificationDrawerIsOpen(state),
    taskDrawerIsOpen: tasksSelectors.taskDrawerIsOpen(state),
    followDrawerIsOpen: tasksSelectors.followDrawerIsOpen(state),
    userMenuIsOpen: userSelectors.userMenuIsOpen(state),
    unReadNotificationsCount: notificationsSelectors.unReadNotificationsCount(state),
    currentPageTitle: headerSelectors.currentPageTitle(state),
    chatUsersNotIsState: chatsSelectors.chatUsersNotIsState(state),
    hasGoBackBtn: headerSelectors.hasGoBackBtn(state),
    connectedUsers: usersSelectors.connectedUsers(state),
    users: usersSelectors.users(state)
});

const mapDispatchToProps = (dispatch: any) => ({
    setUserMenuIsOpen: (isOpen: boolean) => dispatch.user.setUserMenuIsOpen(isOpen),
    setMenuOpen: (open: headerMenus) => dispatch.header.setMenuOpen(open),
    setDrawerOpen: (open: drawers) => dispatch.header.setDrawerOpen(open),
    addUsers: (usersIds: string[]) => dispatch.users.addUsers(usersIds),
    logout: () => dispatch.authentication.logout(),
    setUserStatus: (id: string, status: string) => dispatch.users.setUserStatus(id, status)
});

const HeaderContainer = styled.div`
    height: 100%;
    margin-left: 10px;
    position: relative;

    @media screen and (max-width: 768px) {
        margin-left: 0;
    }
`;
const BackArrow = styled.div`
    padding: 8px;
    position: absolute;
    font-size: 32px;
    color: #00b5b9;
    left: 0;
    cursor: pointer;

    @media screen and (min-width: 768px) {
        display: none;
    }
`;

export interface MobliePageTitle {
    hasGoBackBtn?: boolean;
}
const MobliePageTitle = styled.span<MobliePageTitle>`
    position: absolute;
    font-size: 18px;
    color: #252b4a;
    font-weight: bold;
    display: none;

    @media screen and (max-width: 576px) {
        left: ${(props) => (!props.hasGoBackBtn ? '85px' : '50px')};
    }

    @media screen and (min-width: 576px) and (max-width: 768px) {
        left: ${(props) => (!props.hasGoBackBtn ? '15px' : '50px')};
    }
    @media screen and (max-width: 768px) {
        display: initial;
    }
`;
const RightBar = styled.div`
    position: absolute;
    right: 0;
    top: 0;
    display: flex;
    align-items: center;
    height: 100%;
    width: 100%;
    justify-content: flex-end;
    @media screen and (max-width: 576px) {
        padding-right: 10px;
    }
`;
const StyledLogo = styled.img`
    width: 175px;
    height: 30px;
    margin-left: 14px;
    margin-top: 18px;

    @media screen and (max-width: 768px) {
        display: none;
    }
`;
const ActionsContainer = styled.div`
    height: 100%;
    display: flex;
    align-items: center;
    position: relative;
`;

export interface ActionButtonProps {
    isOpen?: boolean;
}
export const ActionButtonWrapper = styled.div<ActionButtonProps>`
    position: relative;
    user-select: none;
    width: 55px;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    &:hover {
        .icon-btn {
            border: 1px solid white;
            color: white;
            background-color: ${headerTheme.actions.bgColor};
        }
    }
    ${(props) => (props.isOpen ? `border-bottom: 5px solid ${headerTheme.actions.color}; padding-top: 5px;` : '')}

    @media screen and (max-width: 576px) {
        width: 45px;

        img {
            height: 20px;
        }

        .material-icons {
            font-size: 20px;
        }
    }
`;
export const IconButton = styled.span<ActionButtonProps>`
    transition: 0.1s;
    border: 1px solid ${headerTheme.actions.color};
    color: ${headerTheme.actions.color};
    padding: 6px;
    border-radius: 50%;
`;

export const Badge = styled.span`
    top: 10px;
    right: 3px;
    font-weight: bold;
    background-color: #d3445b;

    height: 0px;
    overflow: hidden;
    position: absolute;
    width: 18px;
    height: 18px;
    line-height: 18px;
    font-size: 10px;
    border-radius: 50%;
    color: white;
    text-align: center;
    z-index: 2;
`;

const Icon = styled((props) => <IconButton className="icon-btn" {...props} />)``;
export default withRouter(connect(mapStateToProps, mapDispatchToProps)(HeaderConnected));
