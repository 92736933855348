import SideEffectJS from 'side-effect-js';
import fetchGridColumnsSideEffect from './shipmentsPage/fetchGridColumnsSideEffect';
import { fetchUserSideEffect } from './user/fetchUserSideEffect';
import fetchPurchaseOrderGridColumnsSideEffect from './purchaseOrdersPage/fetchGridColumnsSideEffect';
import fetchPurchaseDemandGridColumnsSideEffect from './purchaseDemandsPage/fetchGridColumnsSideEffect';
import fetchProductsGridColumnsSideEffect from './products/fetchGridColumnsSideEffect';
import fetchInventoryGridColumnsSideEffect from './inventory/fetchGridColumnsSideEffect';
import fetchInternalFlowInventoryLogsGridColumnsSideEffect from './internalFlowInventoryLogs/fetchGridColumnsSideEffect';

const InitializeSideEffects = () => {
    //When needed new side effect - just add it to this array and it will be registered
    const sideEffects = [
        fetchGridColumnsSideEffect,
        fetchUserSideEffect,
        fetchPurchaseOrderGridColumnsSideEffect,
        fetchProductsGridColumnsSideEffect,
        fetchPurchaseDemandGridColumnsSideEffect,
        fetchInventoryGridColumnsSideEffect,
        fetchInternalFlowInventoryLogsGridColumnsSideEffect
    ];
    SideEffectJS.Load(sideEffects);
};

export default InitializeSideEffects;
