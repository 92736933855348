import React from 'react';
import { shipment } from '../../../../models/entities/shipment/shipment';
import { localizationSelectors } from '../../../../state/ducks/localization/selectors';
import { shipmentsSelectors } from '../../../../state/ducks/shipments/selectors';
import { RootState } from '../../../../state/store/store';
import { connect } from 'react-redux';
import { singleShipmentSelectors } from '../../../../state/ducks/singleShipment/selectors';
import { party } from '../../../../models/entities/common_subentities/party';
import { businessPartner, settings } from '../../../../models/entities/businessPartner/businessPartner';
import { businessPartnersSelectors } from '../../../../state/ducks/businessPartner/selectors';
import PartyFormConnected, { formMode } from '../../commonSubEntities/parties/PartyForm/PartyFormConnected';
import CreateBusinessPartner from '../ShipmentForm/sections/BusinessPartners/CreateBusinessPartner';

type Props = {
    shipment?: shipment;
    party?: party;
    mode: formMode;
    localizations?: any;
    settings: settings;
    openedFromModal?: {
        setVisible: React.Dispatch<React.SetStateAction<boolean>>;
    };
    confirmation?: { setConfirm: React.Dispatch<React.SetStateAction<boolean>> };
    createShipmentParty: (shipmentNumber: string, party: party) => void;
    updateShipmentParty: (shipmentNumber: string, party: party) => void;
    showLoader?: boolean;
    businessPartners?: Array<businessPartner>;
};
const ShipmentPartyFormConnected = ({
    shipment,
    party,
    localizations,
    mode,
    settings,
    openedFromModal,
    confirmation,
    createShipmentParty,
    updateShipmentParty,
    showLoader,
    businessPartners
}: Props) => {
    return (
        <PartyFormConnected<shipment, settings>
            entity={shipment}
            party={party}
            mode={mode}
            localizations={localizations}
            settings={settings}
            openedFromModal={openedFromModal}
            confirmation={confirmation}
            createParty={createShipmentParty}
            updateParty={updateShipmentParty}
            showLoader={showLoader}
            businessPartners={businessPartners}
            CreateBusinessPartner={CreateBusinessPartner}
        />
    );
};
const mapStateToProps = (state: RootState) => ({
    localizations: localizationSelectors.singleShipmentPage(state),
    settings: businessPartnersSelectors.settingsByLanguage(state),
    shipment: singleShipmentSelectors.shipment(state),
    showLoader: state.loading.effects.shipments.createShipmentParty || state.loading.effects.shipments.updateShipmentParty,
    businessPartners: businessPartnersSelectors.businessPartnersWithoutForwarder(state)
});

const mapDispatchToProps = (dispatch: any) => ({
    createShipmentParty: (shipmentNumber: string, party: party) => dispatch.shipments.createShipmentParty({ shipmentNumber, party }),
    updateShipmentParty: (shipmentNumber: string, party: party) => dispatch.shipments.updateShipmentParty({ shipmentNumber, party })
});

export default connect(mapStateToProps, mapDispatchToProps)(ShipmentPartyFormConnected);
