import React from 'react';
import { Field } from 'formik-antd';
import DropDown from '../base/DropDown';
import { FieldProps } from 'formik';
import { ErrorText } from '.';
import { styled } from '../../Theme/theme';
import UserDropDown from '../base/UserDropDown';
import { userDropDown } from '../../../../models/entities/user';
import { userDetails } from '../../../../state/ducks/users/users';

type Props = {
    name: string;
    placeHolder?: string;
    showSearch?: boolean;
    mode?: 'multiple' | 'tags';
    users?: Array<userDetails>;
    customUsers?: boolean;
    usersToExclude?: string[];
};

const UserDropDownForm = ({ name, ...restProps }: Props) => {
    return (
        <Field name={name}>
            {({ field: { value }, meta, form: { setFieldValue, setFieldTouched } }: FieldProps) => {
                const { touched, error } = meta;
                const hasError = touched && error !== undefined;
                return (
                    <Container>
                        <UserDropDown
                            onChange={(value: any, option: any) => {
                                setFieldValue(name, value);
                            }}
                            onBlur={(value: any) => {
                                setFieldTouched(name);
                            }}
                            hasError={hasError}
                            value={value === '' || value === null ? undefined : value}
                            {...restProps}
                        />
                        <ErrorText className="err-msg">{hasError && error}</ErrorText>
                    </Container>
                );
            }}
        </Field>
    );
};

interface ContainerProps {
    width?: string;
}
const Container = styled.div<ContainerProps>`
    width: ${(props) => props.width};
    .ant-select-multiple .ant-select-selection-item {
        border-radius: 100px !important;
        background-color: #cce8f4 !important;
        border: 1px solid #cce8f4 !important;
    }
    .ant-select-multiple .ant-select-selection-item-remove > .anticon {
        background-color: #fff !important;
        border-radius: 100px !important;

        svg {
            padding-top: 2px;
            width: 12px;
            height: 10px;
        }
    }
`;

export default UserDropDownForm;
