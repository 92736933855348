import BaseController from '../..';
import { orderItem } from '../../../../models/entities/order/orderItem/orderItem';
import { createOrderItemsPayload } from '../../../../models/entities/order/orderItem/payloads/createOrderItemsPayload';
import { updateOrderItemPayload, deleteOrderItemPayload } from '../../../../models/entities/order/orderItem/payloads';
import IOrderItems from './IOrderItems';

export default class OrderItems extends BaseController implements IOrderItems {
    async createOrderItem(item: orderItem) {
        const response = await this.client.post(`/order/items`, JSON.stringify(item));
        return response;
    }
    async createMultipleOrderItems(payload: createOrderItemsPayload) {
        const response = await this.client.post(`/order/items/multiple`, JSON.stringify(payload));
        return response;
    }
    async updateOrderItem(payload: updateOrderItemPayload) {
        const response = await this.client.put(`/order/items`, JSON.stringify(payload));
        return response;
    }
    async deleteOrderItem({ orderId, itemId }: deleteOrderItemPayload) {
        const response = await this.client.delete(`/order/items`, JSON.stringify({ orderId, itemId }));
        return response;
    }
}
