import React from 'react';
import { shipment } from '../../../models/entities/shipment/shipment';
import ShipmentStation from '../Grid/specialColumns/ShipmentStation';
import ShipmentType from '../Grid/specialColumns/ShipmentType';
import { styled } from '../Theme/theme';
import { RightArrowIcon } from '../../shared/Grid/CellRenderer/CellRenderer';
import hexagonOpened from '../../../static/icons/hexagonCheck/opened.svg';
import hexagonClosed from '../../../static/icons/hexagonCheck/closed.svg';
import WeightIcon from '../../../static/icons/weight.svg';
import { shipmentCalcWeightVolume } from '../../pages/singleShipment/ShipmentForm/sections/Packages/util';
import moment from 'moment';
import CZTooltip, { QuestionToolTip } from '../CZTooltip/CZTooltip';
import CustomModal, { ModalTriggerProps } from '../Modal/Modal';
import PackagesTable from '../../pages/singleShipment/sections/shipmentPackages/PackagesTable';
import { Link } from 'react-router-dom';
import { PagesRoutes } from '../../../routing/PagesRoutes';
import { settings } from '../../../models/entities/businessPartner/businessPartner';
import ShipmentTypeWithTooltip from '../SmallComponents/ShipmentTypeWithTooltip';
import ShipmentRoute from '../SmallComponents/ShipmentRoute';
import { settings as shipmentSettings } from '../../../models/entities/shipment/shipment';
import { drawers } from '../../../state/ducks/header/header';
import { ExternalTrackedLink } from '../../pages/singleShipment/sections/Overview';

type Props = {
    shipment: shipment;
    settings?: settings;
    shipmentSettings?: shipmentSettings;
    localization: any;
    setDrawerOpen: (open: drawers) => void;
};

const ShipmentPane = ({ shipment, settings, localization, shipmentSettings, setDrawerOpen }: Props) => {
    const { chargeableWeight } = shipmentCalcWeightVolume(shipment);

    const currentState = shipmentSettings?.state?.find((s) => s.code === shipment.state);
    const nextState = shipmentSettings?.state?.find((s) => currentState?.displayOrder != null && s.displayOrder === currentState.displayOrder + 1);

    return (
        <Container>
            <Link
                to={{
                    pathname: `${PagesRoutes.Shipments}/${shipment.id}`
                }}
                onClick={() => setDrawerOpen(`SHIPMENT${shipment.id}`)}
            >
                <span>#{shipment.CargoZoneNumber}</span>
            </Link>
            <Header>
                <PageTitle>{localization.shipmentDetails}</PageTitle>
                <Flex style={{ paddingRight: '20px' }}>
                    <div style={{ marginRight: '15px' }}>
                        <ShipmentTypeWithTooltip iconWidth={25} typeCode={shipment.typeCode} subTypeCode={shipment.subTypeCode} />
                    </div>
                    <ShipmentRoute entity={shipment} flagSize="20px" fontSize="14px" />
                </Flex>
            </Header>
            <Section>
                <FlexField>
                    <SmallerField>{localization.clientRef}</SmallerField>
                    <div>#{shipment.clientRef}</div>
                </FlexField>
                {shipment.typeCode !== 'EXPRESS' ? (
                    <FlexField>
                        <SmallerField>{localization.forwarderRef}</SmallerField>
                        <div>#{shipment.forwarderRef}</div>
                    </FlexField>
                ) : (
                    <FlexField>
                        <SmallerField>{localization.trackingNumber}</SmallerField>
                        {!shipment.externalTackingUrl ? (
                            <div>{shipment.forwarderUniqueId}</div>
                        ) : (
                            <CZTooltip text="Visit site">
                                <ExternalTrackedLink to={{ pathname: shipment.externalTackingUrl }} target="_blank">
                                    {shipment.forwarderUniqueId}
                                    <span className="material-icons">link</span>
                                </ExternalTrackedLink>
                            </CZTooltip>
                        )}
                    </FlexField>
                )}
                <FlexField>
                    <SmallerField>{localization.commodity}</SmallerField>
                    <div>{shipment.commodity}</div>
                </FlexField>
            </Section>
            <Section>
                {/* <FlexField>
                    <img src={hexagonClosed} alt="hexagon" />
                    <SmallerField>{localization.lastEvent}</SmallerField>
                    <div>{shipment.lastEvent}</div>
                </FlexField>
                <FlexField>
                    <img src={hexagonOpened} alt="hexagon" />
                    <SmallerField>{localization.nextEvent}</SmallerField>
                    <div>Pick up</div> 
                </FlexField> */}
                {!!currentState && (
                    <FlexField>
                        <img src={hexagonClosed} alt="hexagon" />
                        <SmallerField>{localization.currentState}</SmallerField>
                        <div>{currentState.name}</div>
                    </FlexField>
                )}
                {!!nextState && (
                    <FlexField>
                        <img src={hexagonOpened} alt="hexagon" />
                        <SmallerField>{localization.nextState}</SmallerField>
                        <div>{nextState.name}</div>
                    </FlexField>
                )}
            </Section>
            <Section>
                <FlexField>
                    <Field>{localization.etd}</Field>
                    <div>{moment(shipment.etd).isValid() ? moment(shipment.etd).format('DD/MM/YY') : ''}</div>
                    <CZTooltip text={localization.etdTooltip}>{QuestionToolTip}</CZTooltip>
                </FlexField>
                <FlexField>
                    <Field>{localization.eta}</Field>
                    <div>{moment(shipment.eta).isValid() ? moment(shipment.eta).format('DD/MM/YY') : ''}</div>
                    <CZTooltip text={localization.etaTooltip}>{QuestionToolTip}</CZTooltip>
                </FlexField>
            </Section>
            {(shipment.weight || shipment.volume) && (
                <Section>
                    <Flex>
                        <img style={{ verticalAlign: 'sub' }} src={WeightIcon} alt="weight" />
                        {shipment.weight && (
                            <span>
                                {shipment.weight}
                                {shipment.weightUnit} /
                            </span>
                        )}
                        {shipment.volume && (
                            <span>
                                {shipment.volume}
                                {shipment.volumeUnit}
                            </span>
                        )}
                        {chargeableWeight > 0 && (
                            <>
                                <Field style={{ marginLeft: '10px' }}>{localization.chargeableWeight}</Field>
                                <div>{chargeableWeight}</div>
                            </>
                        )}
                    </Flex>
                </Section>
            )}
            <Section>
                <FlexField>
                    <Field>{localization.incoterms}</Field>
                    <div>{shipment.incoterms}</div>
                </FlexField>
                <FlexField>
                    <Field>{localization.quantity}</Field>
                    {shipment.quantity}
                    {(shipment.packages?.length || shipment.containers?.length) && (
                        <CustomModal
                            title={{ text: localization.packages.modal_title }}
                            Trigger={(props: ModalTriggerProps) => <PackageSeeMore onClick={props.onClick}>{localization.see_more}</PackageSeeMore>}
                            Content={() => (
                                <PackagesTable
                                    packages={shipment.packages}
                                    containers={shipment.containers}
                                    localization={localization}
                                    type={shipment.typeCode}
                                    sub_type={shipment.subTypeCode}
                                />
                            )}
                            width="700px"
                        />
                    )}
                </FlexField>
                <FlexField>
                    <Field>{localization.carrier}</Field>
                    <div>{shipment.carrierCode}</div>
                </FlexField>
                <FlexField>
                    <Field>{localization.master}</Field>
                    <div>{shipment.master}</div>
                    <CZTooltip text={localization.masterTooltip}>{QuestionToolTip}</CZTooltip>
                </FlexField>
                <FlexField>
                    <Field>{localization.house}</Field>
                    <div>{shipment.house}</div>
                    <CZTooltip text={localization.houseTooltip}>{QuestionToolTip}</CZTooltip>
                </FlexField>
            </Section>
            {shipment.parties && (
                <Section>
                    <SectionTitle>{localization.parties.title}</SectionTitle>
                    {shipment.parties.map((party, index) => {
                        const partyType = settings?.partyTypes?.find((item) => item.code === party.type);
                        let type = partyType;
                        if (party.subType) type = partyType?.subTypes.find((item: any) => item.code === party.subType);
                        return (
                            <PartyWrapper key={index}>
                                <Flex>
                                    <Field>{type?.name || party.type}</Field>
                                    <div>{party.name}</div>
                                </Flex>
                                <div>{[party.address, party.contactName, party.phoneNumber].filter((p) => p != null && p !== '').join(' | ')}</div>
                            </PartyWrapper>
                        );
                    })}
                </Section>
            )}
        </Container>
    );
};

const Container = styled.div`
    padding-left: 15px;
    padding-right: 5px;
`;

const Flex = styled.div`
    display: flex;
    align-items: center;
`;

const FlexField = styled(Flex)`
    margin-bottom: 5px;
`;

const Header = styled(Flex)`
    padding-top: 15px;
    justify-content: space-between;
`;
const PageTitle = styled.div`
    font-size: 16px;
    font-weight: bold;
    color: ${(props) => props.theme.colors.pageTitle};
`;
const SectionTitle = styled.div`
    font-size: 18px;
    font-weight: bold;
    color: ${(props) => props.theme.colors.primaryBlue};
`;
const OriginCountryWrapper = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
    min-width: 75px;
`;

const DestinationCountryWrapper = styled.div`
    display: flex;
    align-items: center;
    margin-left: 10px;
`;

const Section = styled.div`
    padding: 15px 0;
    border-bottom: 1px solid ${(props) => props.theme.colors.grayDefaultBorder};
`;
const Field = styled.div`
    color: ${(props) => props.theme.colors.primaryBlue};
    font-size: 16px;
    margin-right: 5px;
    &::after {
        content: ':';
    }
`;
const SmallerField = styled(Field)`
    font-size: 14px;
`;

const PackageSeeMore = styled.div`
    font-size: 12px;
    margin-left: 5px;
    color: #1890ff;
    cursor: pointer;
    :hover {
        text-decoration: underline;
    }
`;

const PartyWrapper = styled.div`
    padding: 10px 0;
`;

export default ShipmentPane;
