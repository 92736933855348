export const eBpContactDepartments: { [key: string]: string } = {
    SALES: 'Sales',
    CS: 'Customer Service',
    ACCOUNTING: 'Accounting',
    OE: 'Ocean Export',
    OI: 'Ocean Import',
    OEX: 'Ocean Express',
    AE: 'Air Export',
    AI: 'Air Import',
    EXPRESS: 'Express',
    DROP_OCEAN: 'Drop Ocean',
    DROP_AIR: 'Drop Air',
    CUSTOMS: 'Customs',
    INLAND: 'Inland',
    RAIL: 'Rail',
    WAREHOUSING: 'Warehousing'
};
