// eslint-disable-next-line import/no-anonymous-default-export
export default {
    en: {
        eventTypes: [
            {
                code: 'OPENED',
                name: 'Opened',
                description: 'Shipment Created'
            },
            {
                code: 'ORDER',
                name: 'Order',
                description: 'Shipment assigned to Forwarder'
            },
            {
                code: 'ORC',
                name: 'Order Confirmation',
                description: 'Forwarder Confirmed Shipment Handling'
            },
            {
                code: 'BOOKING',
                name: 'Booking',
                description: 'Booking Info Received'
            },
            {
                code: 'PICK_UP',
                name: 'Pick up',
                description: 'P/U Scheduled'
            },
            {
                code: 'ON_HAND',
                name: 'On hand at POL',
                description: 'Goods at Forwarder W/H / Port Terminal'
            },
            {
                code: 'GOODS_ON_BOARD',
                name: 'Goods on board',
                description: 'Shipment Left the Port of Loading And on His Way to Destination Port'
            },
            {
                code: 'ABD',
                name: 'Actual booking details',
                description: 'Actual Departure + Estimated Arrival'
            },
            {
                code: 'PRE_ALERT',
                name: 'Pre alert',
                description: 'Shipment Mandatory Details & Files for Customs Use Was Received'
            },
            {
                code: 'ARRIVAL',
                name: 'Arrival',
                description: 'Shipment Arrived To Port of Destination'
            },
            {
                code: 'TELEX',
                name: 'Telex Release - Confirmation Received',
                description: 'Telex Release is approved. Original Bill of Lading documents have been surrendered at origin.'
            },
            {
                code: 'CUSTOMS_PAID',
                name: 'Paid to customs',
                description: 'Entry Form Sent To Customs Authorities'
            },
            {
                code: 'CUSTOMS_CHECK',
                name: 'Customs check',
                description: 'Shipment Is Under Customs Hold for Exam'
            },
            // {
            //     code: 'CUSTOMS_RELEASED',
            //     name: 'Customs released',
            //     description: 'Shipment Was Cleared from Customs'
            // },
            {
                code: 'CUSTOMS_EVENT',
                name: 'Customs event',
                description: 'Customs event has occurred'
            },
            {
                code: 'GATEPASS',
                name: 'Gatepass',
                description: 'Gatepass'
            },
            {
                code: 'DELIVEREDTT',
                name: 'Delivered to Trucker',
                description: 'Gatepass'
            },
            {
                code: 'DELIVERY',
                name: 'Customs released', // hard Code Change for visual - originally called 'Delivery' - TO DO - fit the logic accordingly
                // name: 'Delivery',
                description: 'Shipment Was Transferred for Distribution Scheduling to Final Destination'
            },
            {
                code: 'POD',
                name: 'POD - Proof of delivery',
                description: 'Goods Received at Final Destination'
            },
            {
                code: 'BID_PUBLISHED',
                name: 'Bid Published',
                description: 'Bid has been published'
            },
            {
                code: 'BID_CREATED',
                name: 'Bid Created',
                description: 'Bid has been created'
            },
            {
                code: 'QUOTATION_RECIEVED',
                name: 'Quotation Recieved',
                description: 'Quotation has been recieved'
            }
        ],
        containerState: [
            {
                code: 'IN_TRANSIT',
                name: 'In Transit',
                description: 'In Transit',
                color: '#b2b2b2',
                displayOrder: 0
            },
            {
                code: 'IN_PORT',
                name: 'In Port',
                description: 'In Port',
                color: '#f9d19b',
                displayOrder: 1
            },
            {
                code: 'UOS',
                name: 'Unloading on site',
                description: 'Unloading on site',
                color: '#f9d19b',
                displayOrder: 2
            },
            {
                code: 'SIWH',
                name: 'Stored in W/H',
                description: 'Stored in W/H',
                color: '#f9d19b',
                displayOrder: 3
            },
            {
                code: 'RTD',
                name: 'Returned to Depot',
                description: 'Returned to Depot',
                color: '#339933',
                displayOrder: 4
            }
        ],
        state: [
            {
                code: 'DRAFT',
                name: 'Draft',
                description: 'Ready to ship',
                displayOrder: 0,
                color: '#b2b2b2',
                toolTipText: `Ready Purchase order/shipment that wasn't assigned to a forwarder yet`
            },
            {
                code: 'ORC',
                name: 'Order Confirmation',
                description: 'Forwarder recived & handle shipment',
                displayOrder: 3,
                color: '#b2b2b2',
                toolTipText: 'Order confirmation & handling received by the forwarder   '
            },
            {
                code: 'ORDER',
                name: 'Order',
                description: 'Assign to Forwarder',
                displayOrder: 2,
                color: '#b2b2b2',
                toolTipText: `Shipments that was assigned to a forwarder but handling approval didn't receive yet`
            },
            {
                code: 'BID',
                name: 'Bid',
                description: 'Under shipping qoutes',
                displayOrder: 1,
                color: '#b2b2b2',
                toolTipText: 'Shipments you asked to get a quotation for'
            },
            {
                code: 'BOOKING',
                name: 'Booking',
                description: ' Shipment is booked',
                displayOrder: 4,
                color: '#007acc',
                toolTipText: 'Shipments that got booking details and/or pick up have been scheduled for'
            },
            {
                code: 'CUSTOMS',
                name: 'Customs',
                description: 'Shipment is under import customs procces',
                displayOrder: 7,
                color: '#007acc',
                toolTipText: 'Shipments under import customs process'
            },
            {
                code: 'ON_BOARD',
                name: 'On Board',
                description: 'Shipment is on board',
                displayOrder: 5,
                color: '#007acc',
                toolTipText: 'Shipments indicated to be on the Flight/Voyage/Truck'
            },
            {
                code: 'ARRIVAL',
                name: 'Arrival',
                description: 'Shipment arrived to port of destination',
                displayOrder: 6,
                color: '#007acc',
                toolTipText: 'Shipments reported as Arrived at destination Port/Terminal'
            },
            {
                code: 'DELIVERY',
                name: 'Customs released', // hard Code Change for visual - originally called 'Delivery' - TO DO - fit the logic accordingly
                // name: 'Delivery',
                description: 'Goods are intended for delivery',
                displayOrder: 8,
                color: '#339933',
                toolTipText: 'Shipments that was cleared from import customs & advanced to inland transporter for scheduling'
            },
            {
                code: 'DELIVERED',
                name: 'Delivered',
                description: 'Goods arrived to final destination',
                displayOrder: 9,
                color: '#339933',
                toolTipText: 'Shipments that was already delivered to importer warehouse  (POD - proof of delivery)'
            }
        ],
        documentTypes: [
            {
                code: 'BL',
                name: 'Original Bill of landing',
                description: 'Original Bill of landing description'
            },
            {
                code: 'WTS',
                name: 'Waybill/Telex/Surrendered',
                description: 'Original Bill of landing description'
            },
            {
                code: 'INV',
                name: 'Invoice',
                description: 'Invoice description'
            },
            {
                code: 'CE',
                name: 'Customs entry',
                description: 'Customs entry description'
            },
            {
                code: 'MO',
                name: 'Money Order',
                description: 'Money Order description'
            },
            {
                code: 'PROINV',
                name: 'Pro-forma invoice',
                description: 'Pro-forma invoice description'
            },
            {
                code: 'PL',
                name: 'Packing list',
                description: 'Packing list description'
            },
            {
                code: 'QUOTE',
                name: 'Quotation',
                description: 'Quotation description'
            },
            {
                code: 'COF',
                name: 'Certificate of origin',
                description: 'Certificate of origin description'
            },
            {
                code: 'EURO',
                name: 'Euro1/Euro-Med',
                description: 'Euro1/Euro-Med description'
            },
            {
                code: 'MSDS',
                name: 'MSDS',
                description: 'MSDS description'
            },
            {
                code: 'CBTEST',
                name: 'CB Test',
                description: 'CB Test description'
            },
            {
                code: 'IEPERMIT',
                name: 'Import/Export permit',
                description: 'Import/Export permit description'
            },
            {
                code: 'CATALOG',
                name: 'Catalog',
                description: 'Catalog description'
            },
            {
                code: 'IELINSE',
                name: 'Import/Export License',
                description: 'Import/Export License description'
            },
            {
                code: 'PO',
                name: 'PO License',
                description: 'PO License description'
            },
            {
                code: 'LOC',
                name: 'Letter of credit License',
                description: 'Letter of credit License description'
            },
            {
                code: 'COA',
                name: 'Certificate of Analysis',
                description: 'Certificate of Analysis description'
            },
            {
                code: 'POD',
                name: 'Proof of Delivery',
                description: 'Proof of Delivery description'
            },

            {
                code: 'ADT',
                name: 'Agent Debit',
                description: 'Agent Debit'
            },
            {
                code: 'ISF',
                name: 'ISF - Importer Security Filing',
                description: 'Importer Security Filing'
            },
            {
                code: 'CEF',
                name: 'Customs entry file',
                description: 'Customs entry file'
            },
            {
                code: 'BND',
                name: 'Bond',
                description: 'Bond'
            },
            {
                code: 'BRR',
                name: 'Buying rate RFQ',
                description: 'Buying rate RFQ'
            },
            {
                code: 'SRR',
                name: 'Selling rate RFQ',
                description: 'Selling rate RFQ'
            },
            {
                code: 'OTHER',
                name: 'Other',
                description: 'Other file description'
            }
        ],
        billingLineServiceType: [
            { code: '9999', name: 'Empty Line', description: 'שורה ריקה' },
            { code: '075', name: 'Origin Pick Up', description: 'איסוף בחול' },
            { code: '032', name: 'Origin/Destination Charges', description: 'הוצאות בחול' },
            { code: '001', name: 'Air Freight', description: 'הובלה אווירית' },
            { code: '168', name: 'Ocean Freight', description: 'הובלה ימית' },
            { code: '033', name: 'Air Express', description: 'הובלה + היטלים' },
            { code: '258', name: 'Collection Fee', description: 'דמי גביה' },
            { code: '260', name: 'THC', description: 'THC' },
            { code: '022', name: 'Delivery Order -', description: "פק' מסירה -" },
            { code: '074', name: 'Delivery Order +', description: "פק' מסירה +" },
            { code: '180', name: 'Un/stuffing', description: 'ריקון והמכלה' },
            { code: '200', name: 'EDI', description: 'שידור' },
            { code: '014', name: 'Custom Clearance', description: 'עמילות מכס' },
            { code: '149', name: 'Custom Exam -', description: 'בדיקת מכס' },
            { code: '044', name: 'Custom Exam +', description: '+ בדיקת מכס' },
            { code: '016', name: 'SLA Fee', description: 'דמי טיפול' },
            { code: '064', name: 'Demurrage -', description: 'השהייה' },
            { code: '218', name: 'Demurrage +', description: '+ השהייה' },
            { code: '019', name: 'Handling & Storage', description: 'ניטול ואחסנה' },
            { code: '008', name: 'Inland Delivery', description: 'הובלה יבשתית' },
            { code: '018', name: 'Special Handling ISI +', description: 'דמי טיפול במכון התקנים' },
            { code: '062', name: 'Government office fees +', description: 'מכון התקנים' },
            { code: '021', name: 'Air / Ocean Port Fees +', description: 'תשלומי נמל +' },
            { code: '005', name: 'Air / Ocean Fees -', description: 'תשלומי נמל -' },
            { code: '029', name: 'VAT', description: 'מע"מ' },
            { code: '030', name: 'Computer Fee (Reshimon)', description: 'אגרת מחשב' },
            { code: '031', name: 'Security Fee (Reshimon)', description: 'אגרת ביטחון' },
            { code: '163', name: 'World Gate & Cyber +', description: 'שער עולמי וסייבר' },
            { code: '281', name: "Importer's affidavit +", description: 'תצהיר יבואן' },
            { code: '280', name: 'POA +', description: 'ייפוי כח' },
            { code: '189', name: 'DGD', description: 'חומ"ס' },
            { code: '012', name: 'Export Declaration', description: 'רשימון יצוא' },
            { code: '061', name: 'Purchase Tax', description: 'מס קניה' },
            { code: '003', name: 'Import Duty', description: 'מכס' },
            { code: '071', name: 'OPD', description: 'איסוף מרוחק' },
            { code: '205', name: 'Exceeding Dimensions', description: 'מידות חורגות' },
            { code: '047', name: 'Customs Deficit', description: 'גרעון מכס' },
            { code: '048', name: 'Communication', description: 'תקשורת' },
            { code: '138', name: 'Mihun', description: 'מיכון' },
            { code: '077', name: 'Ministry of Health', description: 'אגרת משרד הבריאות' },
            { code: '078', name: 'Ministry of Agriculture', description: 'אגרת משרד החקלאות' },
            { code: '004', name: 'Insurance', description: 'ביטוח' },
            { code: '295', name: 'Veterinary Certificate', description: 'אישור וטרינרי' },
            { code: '162', name: 'Itur', description: 'איתור' },
            { code: '136', name: 'Generator', description: 'חיבור לגנרטור' },
            { code: '135', name: 'Graph', description: 'גרף' },
            { code: '134', name: 'RF Handling Fee', description: 'דמי טיפול מכולות קירור' },
            { code: '055', name: 'AWB Fee', description: 'דמי שטר מטען' },
            { code: '267', name: 'War', description: 'היטל מלחמה' },
            { code: '166', name: 'Waiting Time', description: 'המתנה' },
            { code: '015', name: 'COO', description: 'עמלה תעודת מקור' },
            { code: '225', name: 'Chamber of Commerce', description: 'לשכת המסחר' },
            { code: '039', name: 'PL3', description: 'מחסן חיצוני' },
            { code: '011', name: 'Porterage', description: 'סבלות' },
            { code: '028', name: 'Tax Credit Fee', description: 'עמלת אשראי מיסים' },
            { code: '265', name: 'Formality', description: 'פורמאליות' },
            { code: '089', name: 'Container Deposit', description: 'פיקדון מכולה' },
            { code: '177', name: 'Customs Deposit', description: 'פיקדון מכס' },
            { code: '125', name: 'Congestion', description: 'צפיפות' },
            { code: '241', name: 'Buying Delivery Order', description: 'קניית פקודת מסירה' },
            { code: '176', name: 'Carnet', description: 'קרנה' },
            { code: '059', name: 'Export license', description: 'רישיון יצוא בחול' },
            { code: '095', name: 'Transshipment', description: 'שטעון' },
            { code: '112', name: 'Oversize Fee', description: 'תוספת יחידה כבדה' },
            { code: '116', name: 'Fupa Fee', description: 'תשלום קופה' },
            { code: '355', name: 'Logger', description: 'לוגרים' }
        ],
        accountingState: [
            {
                code: 'WATING_PRICING',
                name: 'Waiting for 3rd party pricing',
                description: 'Waiting for 3rd party pricing',
                color: '#c1292e',
                displayOrder: 0
            },
            {
                code: 'NO_INTERNAL_PRICING',
                name: 'No Pricing Internal',
                description: 'No Pricing Internal',
                color: '#c1292e',
                displayOrder: 1
            },
            {
                code: 'WATING_OPERATOR',
                name: 'Waiting for operator billing',
                description: 'Waiting for operator billing',
                color: '#f4c915',
                displayOrder: 2
            },
            {
                code: 'WAITING_ORDER_NUMBER',
                name: 'Waiting for order # / order details',
                description: 'Waiting for order # / order details',
                color: '#f4c915',
                displayOrder: 3
            },
            {
                code: 'READY_FOR_CHARGE',
                name: 'Ready for freight charge - waiting for T&D charges',
                description: 'Ready for freight charge - waiting for T&D charges',
                color: '#00B5B9',
                displayOrder: 4
            },
            {
                code: 'READY_TO_ISSUE',
                name: 'Ready to issue',
                description: 'Ready to issue',
                color: '#639e31',
                displayOrder: 5
            }
        ]
    }
};
