import { RootState } from '../../store/store';
import { createSelector } from 'reselect';
import bidsSelectors from '../bids/selectors';
import { byDate, byNumber } from '../../../utils/sortArray';
import { party } from '../../../models/entities/bid/party';
import { quotationView } from '../../../models/entities/bid/quotation';
import { document } from '../../../models/entities/common_subentities/document';

const currentBidId = (state: RootState) => state.singleBid.bidId;
const fetchBidError = (state: RootState) => state.error.effects.bids.fetchBidById;
const isLoadingBid = (state: RootState) => state.loading.effects.bids.fetchBidById;

const bid = createSelector(currentBidId, bidsSelectors.bidsData, (bidId, bids) => {
    return bids?.find((bid) => bid.id === bidId);
});
const parties = createSelector(currentBidId, bidsSelectors.bidsData, (bidId, bids) => {
    const bid = bids?.find((item) => item.id === bidId);
    if (bid) return bid.parties?.sort(byNumber(false, (party: party) => party.id));
});
const documents = createSelector(currentBidId, bidsSelectors.bidsData, (bidId, bids) => {
    const bid = bids?.find((item) => item.id === bidId);
    if (bid) return bid.documents?.sort(byDate(true, (document: document) => document.createdDate));
});
const quotations = createSelector(currentBidId, bidsSelectors.bidsData, (bidId, bids) => {
    const bid = bids?.find((item) => item.id === bidId);
    if (bid) return bid.quotations?.sort(byNumber(true, (quotation: quotationView) => quotation.id));
});

export { default as singleBidSelectors } from './selectors';

export default {
    fetchBidError,
    isLoadingBid,
    bid,
    parties,
    documents,
    quotations
};
