import { ModelConfig } from '@rematch/core';
import { companyAdminUpdateUserPayload, companyAdminCreateUserPayload, userInfo } from '../../../models/entities/user';
import { localSDK as client } from '../../../sdk';
import { deletedRecord } from '../../../models/entities/admin/deletedRecord';
import { gridSortType } from '../../../components/shared/Grid/Grid';

export type AdminStateType = {
    users: userInfo[];
    usersGridSort?: gridSortType<userInfo>;
    deletedRecords: deletedRecord[];
    gridFilter: string | null;
};

export const admin: ModelConfig<AdminStateType> = {
    state: {
        users: [],
        deletedRecords: [],
        gridFilter: null,
        usersGridSort: {
            deletedAt: 'asc'
        }
    },
    reducers: {
        addUser(state: AdminStateType, user: userInfo): AdminStateType {
            const newUsers = [...state.users, user];
            return { ...state, users: newUsers };
        },
        setUsers(state: AdminStateType, users: userInfo[]): AdminStateType {
            return { ...state, users };
        },
        setUsersGridSort(state: AdminStateType, usersGridSort?: gridSortType<userInfo>): AdminStateType {
            return { ...state, usersGridSort };
        },
        setDeletedRecords(state: AdminStateType, deletedRecords: deletedRecord[]): AdminStateType {
            return { ...state, deletedRecords };
        },
        setUserDetails(state: AdminStateType, userDetails: companyAdminUpdateUserPayload, userId: string): AdminStateType {
            const currentUsers = [...state.users];
            const currentUserIndex = currentUsers.findIndex((u) => u.id === userId);

            if (currentUserIndex !== -1) {
                currentUsers[currentUserIndex] = {
                    ...currentUsers[currentUserIndex],
                    ...userDetails
                };
            }
            return { ...state, users: currentUsers };
        },
        setResetPassword(state: AdminStateType, userId: string): AdminStateType {
            const currentUsers = [...state.users];
            const currentUserIndex = currentUsers.findIndex((u) => u.id === userId);

            if (currentUserIndex !== -1) {
                currentUsers[currentUserIndex] = { ...currentUsers[currentUserIndex], shouldResetPassword: true };
            }
            return { ...state, users: currentUsers };
        },
        setUserDeletedAt(state: AdminStateType, userId: string, restore?: boolean): AdminStateType {
            const currentUsers = [...state.users];
            const currentUserIndex = currentUsers.findIndex((u) => u.id === userId);

            if (currentUserIndex !== -1) {
                currentUsers[currentUserIndex] = { ...currentUsers[currentUserIndex], deletedAt: restore ? null : new Date() };
            }
            return { ...state, users: currentUsers };
        },
        setGridFilter(state: AdminStateType, filterText: string): AdminStateType {
            return { ...state, gridFilter: filterText };
        }
    },
    effects: (dispatch: any) => ({
        async fetchUsers() {
            const users = await client.admin().users().fetchUsers();
            dispatch.admin.setUsers(users);
        },
        async updateUserDetails({ details, userId }: { details: companyAdminUpdateUserPayload; userId: string }) {
            await client.admin().users().updateUserDetails(details, userId);
            dispatch.admin.setUserDetails(details, userId);
            return true;
        },
        async resetUserPassword({ userId, email }: { userId: string; email: string }) {
            dispatch.admin.setResetPassword(userId, email);
            await client.admin().users().resetPassword(userId, email);
            return true;
        },
        async createUser(payload: companyAdminCreateUserPayload) {
            const createdUser = await client.admin().users().createUser(payload);
            dispatch.admin.addUser(createdUser);
            return true;
        },
        async checkEmailExist(email: string) {
            const exist = await client.admin().users().checkEmailExist(email);
            return exist;
        },
        async deleteUser(userId: string) {
            dispatch.admin.setUserDeletedAt(userId);
            await client.admin().users().deleteUser(userId);
            return true;
        },
        async restoreUser(userId: string) {
            dispatch.admin.setUserDeletedAt(userId, true);
            await client.admin().users().restoreUser(userId);
            return true;
        },
        async fetchDeletedRecords() {
            const dr = await client.admin().fetchDeletedRecords();
            dispatch.admin.setDeletedRecords(dr);
        },

        async restoreEntity({ entityId, entityType }: { entityId: string; entityType: string }) {
            await client.admin().restoreEntity(entityId, entityType);
            dispatch.admin.fetchDeletedRecords();
        },
        async deleteEntity({ entityId, entityType }: { entityId: string; entityType: string }) {
            await client.admin().deleteEntity(entityId, entityType);
            dispatch.admin.fetchDeletedRecords();
        }
    })
};
