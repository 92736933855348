export const eContainerTypes = [
    {
        type: 'FL/IG20',
        Description: "FLAT IN GAUGE 20'"
    },
    {
        type: 'FL/IG40',
        Description: "FLAT IN GAUGE 40'"
    },
    {
        type: 'FL/OG20',
        Description: "FLAT OUT GAUGE 20'"
    },
    {
        type: 'FL/OG40',
        Description: "FLAT OUT GAUGE 40'"
    },
    {
        type: 'FL20',
        Description: "FLAT 20'"
    },
    {
        type: 'FL40',
        Description: "FLAT 40'"
    },
    {
        type: 'HC20',
        Description: "Full Trailer Above 20'"
    },
    {
        type: 'HC40',
        Description: "HIGH CUBE 40'"
    },
    {
        type: 'HC45',
        Description: "HIGH CUBE 45'"
    },
    {
        type: 'HG40',
        Description: 'HG 40'
    },
    {
        type: 'OS/IG20',
        Description: "OPEN SIDE IN GAUGE 20'"
    },
    {
        type: 'OS/OG20',
        Description: "OPEN SIDE OUT GAUGE 20'"
    },
    {
        type: 'OS20',
        Description: "OPEN SIDE 20'"
    },
    {
        type: 'OT/IG20',
        Description: "OPEN TOP IN GAUGE 20'"
    },
    {
        type: 'OT/IG40',
        Description: "OPEN TOP IN GAUGE 40'"
    },
    {
        type: 'OT/OG20',
        Description: "OPEN TOP OUT GAUGE 20'"
    },
    {
        type: 'OT/OG40',
        Description: "OPEN TOP OUT GAUGE 40'"
    },
    {
        type: 'OT20',
        Description: 'OPEN TOP 20'
    },
    {
        type: 'OT40',
        Description: "OPEN TOP 40'"
    },
    {
        type: 'RF20',
        Description: "REEFER 20'"
    },
    {
        type: 'RF40',
        Description: "REEFER 40'"
    },
    {
        type: 'RF46',
        Description: 'Refrigerated 40'
    },
    {
        type: 'RG20',
        Description: 'REGULAR 20'
    },
    {
        type: 'RG40',
        Description: 'REGULAR 40'
    },
    {
        type: 'RG45',
        Description: 'REGULAR 45'
    },
    {
        type: 'RGCON',
        Description: 'General Container'
    },
    {
        type: 'RH/HC40',
        Description: "REEFER HIGH CUBE 40'"
    },
    {
        type: 'TKISO20',
        Description: "ISO TANK 20'"
    }
];
