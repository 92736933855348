import EntityDetails, { detail } from '../../../shared/EntityDetails/EntityDetails';
import { Form, Formik } from 'formik';
import { Option, enumToDropdownOptions } from '../../../shared/inputs/base/DropDown';
import React, { useState } from 'react';
import { calcTotalVolume, calcTotalWeight } from '../../../../utils/measurementUtils';
import { order, updateOrderPayload } from '../../../../models/entities/order';
import Button from '../../../shared/SmallComponents/Button';
import { Checkbox } from 'antd';
import EditIcon from '../../../../static/icons/edit.svg';
import Loader from '../../../shared/SmallComponents/Loader';
import OrderOverviewHeader from './OrderOverviewHeader';
import OrderTabs from './Tabs/Tabs';
import OverviewParties from './OverviewParties/OverviewParties';
import WeightIcon from '../../../../static/icons/weight.svg';
import carriers from '../../../../static/data/carriers.json';
import { chain } from 'lodash';
import { currencyFormatter } from '../../../shared/inputs/form/CurrencyInput/CurrencyInput';
import { eIncoterms } from '../../../../models/entities/shipment/incoterms';
import moment from 'moment';
import { orderEvent } from '../../../../models/entities/order/orderEvent/orderEvent';
import { orderItem } from '../../../../models/entities/order/orderItem/orderItem';
import styled from 'styled-components';

type Props = {
    order: order;
    allowedCompaniesDropDownOptions: Option[];
    businessPartnersDropDownOptions: Option[];
    allowedShipmentTypes: Array<string>;
    localization: any;
    isUpdateOrder: boolean;
    orderLastEvent: orderEvent | null;
    updateOrder: (payload: updateOrderPayload) => Promise<void>;
};

type additionalOverviewFields = {
    estimatedItemsVolume?: number;
    estimatedItemsWeight?: number;
    itemsTotalQuantity?: number;
    itemsTotalPrice?: number;
};

const incotermsDropDownOptions = enumToDropdownOptions(eIncoterms);

const OrderOverview = ({
    order,
    allowedCompaniesDropDownOptions,
    businessPartnersDropDownOptions,
    allowedShipmentTypes,
    orderLastEvent,
    localization,
    isUpdateOrder,
    updateOrder
}: Props) => {
    const [detailsEdit, setDetailsEdit] = useState(false);

    const fieldsLocalization = localization.fields;

    const itemsTotalVolume = calcTotalVolume(order.items || []);
    const itemsTotalWeight = calcTotalWeight(order.items || []);

    const itemsTotalQuantity =
        order.items?.reduce((totalQuantity: number, item: orderItem) => {
            return totalQuantity + (item.quantity || 0);
        }, 0) || 0;

    const itemsTotalCost = order?.items
        ? chain(order.items)
              .groupBy((item) => item.currency)
              .map((items, index) => {
                  const totalItemsCost = items
                      ?.reduce((total: number, item: orderItem) => {
                          const { price, quantity } = item;
                          if (!price || !quantity) return total;
                          return total + Number(price) * quantity;
                      }, 0)
                      ?.toString();

                  const value = currencyFormatter(items[0].currency)(totalItemsCost);
                  return <div key={`currency${index}`}>{value}</div>;
              })
              .value()
        : undefined;

    const initialValues: updateOrderPayload = {
        orderId: order.id,
        orderDate: order.orderDate,
        dueDate: order.dueDate,
        poDate: order.poDate,
        poNumber: order.poNumber,
        soNumber: order.soNumber,
        invoiceNumber: order.invoiceNumber,
        incoterms: order.incoterms,
        trackingNumber: order.trackingNumber,
        shippingInstructions: order.shippingInstructions,
        weight: order.weight,
        weightUnit: order.weightUnit,
        volume: order.volume,
        volumeUnit: order.volumeUnit,
        carrier: order.carrier,
        eta: order.eta
    };

    const submit = async (values: updateOrderPayload) => {
        await updateOrder(values);

        setDetailsEdit(false);
    };

    const detailsData = (values: updateOrderPayload, setFieldValue: any): Array<Array<detail<updateOrderPayload & additionalOverviewFields>>> => [
        [
            {
                name: 'soNumber',
                showCondition: true,
                editable: {},
                title: fieldsLocalization?.soNumber?.title || 'So Number',
                value: order.soNumber
            },
            {
                name: 'orderDate',
                showCondition: true,
                editable: {
                    type: 'date'
                },
                title: fieldsLocalization?.activationDate?.title || 'Order Date',
                value: order.orderDate ? moment(order.orderDate).format('DD/MM/YYYY HH:mm') : undefined
            },
            {
                name: 'dueDate',
                showCondition: true,
                editable: {
                    type: 'date'
                },
                title: fieldsLocalization?.dueDate?.title || 'Due Date',
                value: order.dueDate ? moment(order.dueDate).format('DD/MM/YYYY HH:mm') : undefined
            },
            {
                name: 'eta',
                showCondition: true,
                editable: {
                    type: 'date'
                },
                title: fieldsLocalization?.eta?.title || 'ETA',
                value: order.eta ? moment(order.eta).format('DD/MM/YYYY HH:mm') : undefined
            }
        ],
        [
            {
                name: 'poNumber',
                showCondition: detailsEdit || !!values.poNumber,
                editable: {},
                title: fieldsLocalization?.poNumber?.title || 'Po Number',
                value: order.poNumber
            },
            {
                name: 'poDate',
                showCondition: detailsEdit || !!values.poDate,
                editable: {
                    type: 'date'
                },
                title: fieldsLocalization?.poDate?.title || 'Po Date',
                value: order.poDate ? moment(order.poDate).format('DD/MM/YYYY HH:mm') : undefined
            },
            {
                name: 'invoiceNumber',
                showCondition: detailsEdit || !!values.invoiceNumber,
                editable: {},
                title: fieldsLocalization?.invoiceNumber?.title || 'Invoice Number',
                value: order.invoiceNumber
            },
            {
                name: 'incoterms',
                showCondition: detailsEdit || !!values.incoterms,
                editable: {
                    type: 'dropdown',
                    options: incotermsDropDownOptions
                },
                title: fieldsLocalization?.incoterms?.title || 'Incoterms',
                value: Array.isArray(order.incoterms) ? order.incoterms?.join(', ') : order.incoterms
            }
        ],
        [
            {
                name: 'itemsTotalQuantity',
                showCondition: !!Number(itemsTotalQuantity),
                editable: false,
                title: fieldsLocalization.itemsTotalQuantity?.title,
                value: <div>{itemsTotalQuantity ? `${itemsTotalQuantity}` : '-'}</div>
            },
            {
                name: 'itemsTotalPrice',
                showCondition: !!itemsTotalCost,
                editable: false,
                title: fieldsLocalization.itemsTotalCost?.title,
                value: <div>{itemsTotalCost}</div>
            },
            {
                name: 'estimatedItemsVolume',
                showCondition: !Number(order.volume) && !!order.items?.length,
                editable: false,
                title: fieldsLocalization.estimatedItemsVolume?.title,
                value: <div>{order.items ? `${Number(itemsTotalVolume.volume.toFixed(3))} ${itemsTotalVolume.unit}` : ''}</div>
            },
            {
                name: 'volume',
                showCondition: detailsEdit ? true : !!Number(order.volume),
                editable: {
                    type: 'unit',
                    value: order.volume,
                    options: [
                        { value: 'CBM', text: 'CBM' },
                        { value: 'CFT', text: 'CFT' }
                    ]
                },
                title: fieldsLocalization?.volume?.title,
                value: order.volume ? `${Number(order.volume).toFixed(3)} ${order.volumeUnit?.toUpperCase()}` : undefined
            },
            {
                name: 'estimatedItemsWeight',
                showCondition: !Number(order.weight) && !!order.items?.length,
                editable: false,
                title: fieldsLocalization.estimatedItemsWeight?.title,
                value: <div>{order?.items ? `${Number(itemsTotalWeight.weight.toFixed(3))} ${itemsTotalWeight.unit}` : ''}</div>
            },
            {
                name: 'weight',
                showCondition: detailsEdit ? true : !!Number(order.weight),
                editable: {
                    type: 'unit',
                    value: order.weight,
                    options: [
                        { value: 'KG', text: 'KG' },
                        { value: 'LB', text: 'LB' }
                    ]
                },
                title: (
                    <>
                        {!detailsEdit && <img style={{ verticalAlign: 'sub' }} src={WeightIcon} alt={localization.weight} />}
                        {fieldsLocalization?.weight?.title}
                    </>
                ),
                value: order.weight ? `${Number(order.weight).toFixed(3)} ${order.weightUnit?.toUpperCase()}` : undefined
            }
        ],
        [
            {
                name: 'shippingInstructions',
                showCondition: true,
                editable: {
                    type: 'textarea'
                },
                title: fieldsLocalization?.shippingInstructions?.title || 'Shipping Instructions',
                value: order.shippingInstructions
            },
            {
                name: 'requiredCustomsClearance',
                showCondition: true,
                editable: false,
                title: fieldsLocalization.requiredCustomsClearance.title,
                value: (
                    <Checkbox
                        checked={!!order.requiredCustomsClearance}
                        onChange={(e) => {
                            const requiredCustomsClearance = !order.requiredCustomsClearance;
                            updateOrder({ orderId: order.id, requiredCustomsClearance });
                        }}
                    />
                )
            },
            {
                name: 'carrier',
                showCondition: true,
                editable: {
                    value: order.carrier,
                    type: 'autocomplete',
                    placeholder: fieldsLocalization.carrier.placeholder,
                    options: carriers.map((c) => ({
                        text: c.carrierName,
                        value: c.carrierCode
                    }))
                },
                title: fieldsLocalization.carrier.title,
                value: carriers.find((item) => item.carrierCode === order.carrier)?.carrierName || order.carrier
            }
        ]
    ];

    return (
        <OverviewWrapper>
            <OrderOverviewHeader
                order={order}
                localization={localization}
                allowedCompaniesDropDownOptions={allowedCompaniesDropDownOptions}
                orderLastEvent={orderLastEvent}
                updateOrder={updateOrder}
            />
            <OverviewSectionWrapper>
                <OverviewParties order={order} localization={localization} />
            </OverviewSectionWrapper>
            <OverviewSectionWrapper>
                <DetailsEditButton onClick={() => setDetailsEdit(!detailsEdit)}>
                    <Button margin="5px" buttonType={detailsEdit ? 'filled' : 'hollow'}>
                        <img src={EditIcon} alt={'Edit'} />
                    </Button>
                </DetailsEditButton>
                <Formik<updateOrderPayload> initialValues={initialValues} onSubmit={submit} enableReinitialize>
                    {({ values, setFieldValue }) => {
                        return (
                            <Form>
                                <EntityDetails<updateOrderPayload>
                                    detailsData={detailsData(values, setFieldValue)}
                                    values={initialValues}
                                    detailsEdit={detailsEdit}
                                />
                                {detailsEdit && (
                                    <div>
                                        <Button buttonType="filled" type="submit" width="80px" padding="0">
                                            {isUpdateOrder ? (
                                                <Loader width="20px" marginTop="0px" showText={false} />
                                            ) : (
                                                <span>{localization.overview.saveBtn}</span>
                                            )}
                                        </Button>
                                    </div>
                                )}
                            </Form>
                        );
                    }}
                </Formik>
            </OverviewSectionWrapper>
            <OverviewSectionWrapper>
                <OrderTabs order={order} localization={localization} />
            </OverviewSectionWrapper>
        </OverviewWrapper>
    );
};

const OverviewWrapper = styled.div`
    display: flex;
    flex-direction: column;
    width: 100%;
`;

const DetailsEditButton = styled.div`
    display: inline-block;
    position: absolute;
    right: 0;
`;

const OverviewSectionWrapper = styled.div`
    position: relative;
    padding: 24px 18px 18px 48px;
    border-bottom: solid 1px ${(props) => props.theme.colors.grayDefaultBorder};
`;

export default OrderOverview;
