import { GridCellParams, GridColDef, GridColumnHeaderParams, GridValueFormatterParams } from '@material-ui/data-grid';
import React from 'react';
import { product } from '../../../../../../models/entities/product/product';
import MuiDataGrid from '../../../../../shared/MuiDataGrid/MuiDataGrid';
import { currencyFormatter } from '../../../../../shared/inputs/form/CurrencyInput/CurrencyInput';
import MaterialPopover from '../../../../../shared/Popover/MaterialPopover';
import infoIcon from '../../../../../../static/icons/info.svg';
import styled from 'styled-components';
import { drawers } from '../../../../../../state/ducks/header/header';

type Props = {
    products: Array<product>;
    localization: any;
    setDrawerOpen: (open: drawers, params?: any) => void;
};
const ProductsTab = ({ products, localization, setDrawerOpen }: Props) => {
    const ProductsGridColumns: GridColDef[] = [
        { field: 'sku', headerName: localization.grid.columns.sku, flex: 1 },
        { field: 'name', headerName: localization.grid.columns.name, flex: 1 },
        {
            field: 'cost',
            headerName: localization.grid.columns.cost,
            flex: 1,
            valueFormatter: (params: GridValueFormatterParams) =>
                params.value ? currencyFormatter(params.getValue(params.id, 'costCurrency'))(params.value) : '-'
        },
        {
            field: 'id',
            renderHeader: (params: GridColumnHeaderParams) => <div></div>,
            renderCell: (params: GridCellParams) => {
                return (
                    <div
                        onClick={(e) => e.stopPropagation()}
                        style={{ width: '100%', display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}
                    >
                        <MaterialPopover
                            anchor={
                                <InfoIconWrapper>
                                    <InfoIcon src={infoIcon} />
                                </InfoIconWrapper>
                            }
                            popoverLeft
                        >
                            <DetailsWrapper>
                                {Object.entries(params.row)
                                    .filter(
                                        ([key, value]) =>
                                            !!value &&
                                            Number(value) !== 0 &&
                                            !['sku', 'name', 'cost', 'costCurrency', 'number', 'createdAt', 'updatedAt', 'attributes'].includes(
                                                key
                                            ) &&
                                            (!key.toLowerCase().includes('id') || key === 'width') &&
                                            !key.toLowerCase().includes('currency') &&
                                            !key.toLowerCase().includes('unit') &&
                                            typeof value !== 'object'
                                    )
                                    .map(([key, value], i) => {
                                        let parsedValue = value;
                                        if (['retailPrice', 'salePrice'].includes(key)) {
                                            parsedValue = currencyFormatter(params.getValue(params.id, `${key}Currency`))(Number(value) || 0);
                                        } else if (key === 'weight') {
                                            parsedValue = `${value} ${params.getValue(params.id, `${key}Unit`)}`;
                                        } else if (['width', 'length', 'height'].includes(key)) {
                                            parsedValue = `${value} ${params.getValue(params.id, `dimensionUnit`)}`;
                                        }
                                        return (
                                            <Detail key={`${params.row.id}-modal-detail-${i}`}>
                                                <DetailName>{localization.grid.columns?.[key] || key}:</DetailName>
                                                <DetailValue>{parsedValue}</DetailValue>
                                            </Detail>
                                        );
                                    })}
                            </DetailsWrapper>
                        </MaterialPopover>
                    </div>
                );
            }
        }
    ];

    return (
        <MuiDataGrid
            columns={ProductsGridColumns}
            rows={products}
            onRowClick={(row) => setDrawerOpen('PRODUCT', { productId: row.id })}
            autoHeight
            disableColumnFilter
            disableColumnMenu
            disableColumnReorder
        />
    );
};

const InfoIcon = styled.img`
    width: 20px;
    height: 20px;
`;
const InfoIconWrapper = styled.div`
    display: flex;
    position: relative;
    width: 25px;
    justify-content: center;
`;

const DetailsWrapper = styled.div`
    width: 300px;
`;
const Detail = styled.div`
    display: flex;
    flex-wrap: wrap;
    margin: 10px 0;
`;
const DetailName = styled.div`
    color: #394372;
    font-weight: bold;
    margin-right: 5px;
`;

const DetailValue = styled.div`
    color: #757575;
`;

export default ProductsTab;
