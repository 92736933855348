import { MentionData } from '@draft-js-plugins/mention';
import { purchaseDemand } from '../../../../models/entities/purchaseDemand/purchaseDemand';
import { purchaseOrder } from '../../../../models/entities/purchaseOrder/purchaseOrder';
import { shipment } from '../../../../models/entities/shipment/shipment';

export type entityMentionType = 'shipment' | 'po' | 'pd';

export type EntityMentionData = MentionData & { entityType: entityMentionType };
export const getEntitySuggestions = async (
    searchValue: string | null,
    searchShipmentsByCZNumber: (czNumber: string) => Promise<shipment[]>,
    searchPurchaseOrdersByCZNumber: (czNumber: string) => Promise<purchaseOrder[]>,
    searchPurchaseDemandsByCZNumber: (czNumber: string) => Promise<purchaseDemand[]>
): Promise<EntityMentionData[] | undefined> => {
    if (searchValue != null && searchValue.length < 8) {
        if (searchValue?.toUpperCase()?.startsWith('PO')) {
            const purchaseOrders = await searchPurchaseOrdersByCZNumber(searchValue || ' ');
            const poMentions = purchaseOrders?.map((po) => ({ entityType: 'po', name: po.CargoZoneNumber, ...po })) as EntityMentionData[];

            return poMentions;
        }

        if (searchValue?.toUpperCase()?.startsWith('PD')) {
            const purchaseDemands = await searchPurchaseDemandsByCZNumber(searchValue || ' ');
            const poMentions = purchaseDemands?.map((pd) => ({ entityType: 'pd', name: pd.CargoZoneNumber, ...pd })) as EntityMentionData[];

            return poMentions;
        }

        const shipments = await searchShipmentsByCZNumber(searchValue || ' ');
        const entityMentions = shipments?.map((s) => ({ entityType: 'shipment', name: s.CargoZoneNumber, ...s })) as EntityMentionData[];

        return entityMentions;
    }
};
