import React from 'react';
import styled from 'styled-components';
import { companiesRelationRequestsResponseType } from '../../../../../models/entities/company/companiesRelationRequest';
import { Formik, Form } from 'formik';
import { FormTextInput, FormDropDown } from '../../../../shared/inputs/form';
import DropDown from '../../../../shared/inputs/base/DropDown';
import Button from '../../../../shared/SmallComponents/Button';
import Loader from '../../../../shared/SmallComponents/Loader';
import { businessPartner } from '../../../../../models/entities/businessPartner/businessPartner';
import { companiesRelationType } from '../../../../../models/entities/company/companiesRelation';
import { useState } from 'react';
import { partiesToDropdownOptions } from '../../../businessPartners/components/BusinessPartnerForm/BusinessPartnerFormConnected';
import { Modal } from 'antd';
import FieldLabel from '../../../../shared/SmallComponents/FieldLabel';
import CopyToClipboard from '../../../../shared/CopyToClipboard';

type Props = {
    localization: any;
    relationRequests: companiesRelationRequestsResponseType;
    businessPartners: Array<businessPartner>;
    businessPartnerCargoOwners: Array<businessPartner>;
    relationToken?: string;
    isGeneratingToken: boolean;
    approveCompaniesRelationRequest: (
        requestId: string,
        businessPartnerId: string,
        recievedBusinessPartnerId?: string,
        recievedRelationType?: companiesRelationType
    ) => Promise<void>;
    sendCompaniesRelationRequest: (
        token: string,
        relationType: companiesRelationType,
        recieverBusinessPartnerId?: string,
        recieverRelationType?: companiesRelationType
    ) => Promise<void>;
    denyCompaniesRelationRequest: (requestId: string) => Promise<void>;
    generateRelationToken: () => Promise<void>;
};

const RequestsSection = ({
    localization,
    relationRequests,
    businessPartners,
    businessPartnerCargoOwners,
    relationToken,
    isGeneratingToken,
    approveCompaniesRelationRequest,
    sendCompaniesRelationRequest,
    denyCompaniesRelationRequest,
    generateRelationToken
}: Props) => {
    const [isSubmit, setIsSubmit] = useState(false);
    const [isApprove, setIsApprove] = useState<false | string>(false);
    const [selectedBusinessPartners, setSelectedBusinessPartners] = useState<{ [key: string]: string }>({});

    const initialValues = {
        token: '',
        relationType: '',
        recieverBusinessPartnerId: null,
        recieverRelationType: null
    };

    const selectBusinessPartner = (requestId: string, businessPartnerId: string) => {
        const newSelectedBusinessPartners = { ...selectedBusinessPartners };
        newSelectedBusinessPartners[requestId] = businessPartnerId;
        setSelectedBusinessPartners(newSelectedBusinessPartners);
    };

    const onSubmit = async (values: any, { resetForm }: any) => {
        setIsSubmit(true);
        await sendCompaniesRelationRequest(
            values.token,
            values.relationType,
            values.recieverBusinessPartnerId || undefined,
            values.recieverRelationType || undefined
        );
        resetForm();
        setIsSubmit(false);
    };

    const validate = (values: any) => {
        let errors: any = {};

        if (!values.token) errors.token = localization.required;
        if (!values.relationType) errors.relationType = localization.required;

        return errors;
    };
    return (
        <Wrapper>
            <FormWrapper>
                <Formik initialValues={initialValues} onSubmit={onSubmit} validate={validate}>
                    {(formik) => {
                        return (
                            <Form>
                                <FormContent>
                                    <FormFieldWrapper>
                                        <RequestInputWrapper>
                                            <GenerateTokenWrapper>
                                                {!!relationToken && (
                                                    <>
                                                        {/* <span>{localization.my_token}: </span>S */}
                                                        <CopyToClipboard value={relationToken} />
                                                        <span style={{ marginLeft: 5 }}>{relationToken}</span>
                                                    </>
                                                )}
                                                {isGeneratingToken ? (
                                                    <span style={{ marginLeft: 5 }}>
                                                        <Loader marginTop="0" showText={false} width="10px" inline />
                                                    </span>
                                                ) : (
                                                    <span
                                                        className="generate-btn"
                                                        onClick={() => {
                                                            generateRelationToken();
                                                        }}
                                                    >
                                                        {!relationToken ? localization.generate_token : localization.new_token}
                                                    </span>
                                                )}
                                            </GenerateTokenWrapper>
                                            <FormTextInput name="token" placeHolder="token" style={{ width: 285 }} />
                                        </RequestInputWrapper>
                                    </FormFieldWrapper>
                                    <FormFieldWrapper>
                                        <FormDropDown
                                            name="relationType"
                                            placeHolder="relationType"
                                            options={[
                                                {
                                                    value: 'FORWARDER',
                                                    text: localization.forwarder
                                                },
                                                {
                                                    value: 'SUPPLIER',
                                                    text: localization.supplier
                                                },
                                                {
                                                    value: 'CARGO_OWNER',
                                                    text: localization.cargo_owner
                                                }
                                            ]}
                                        />
                                    </FormFieldWrapper>
                                    <Button buttonType="filled" height="30px" type="submit">
                                        {isSubmit ? <Loader width="20px" marginTop="0px" showText={false} /> : <span>{localization.send}</span>}
                                    </Button>
                                </FormContent>
                                <FormContent>
                                    <FormFieldWrapper>
                                        <FormDropDown
                                            name="recieverBusinessPartnerId"
                                            placeHolder="recieverBusinessPartnerId"
                                            options={partiesToDropdownOptions([...businessPartners, ...businessPartnerCargoOwners])}
                                        />
                                    </FormFieldWrapper>
                                    <FormFieldWrapper>
                                        <FormDropDown
                                            name="recieverRelationType"
                                            placeHolder="recieverRelationType"
                                            options={[
                                                {
                                                    value: 'FORWARDER',
                                                    text: localization.forwarder
                                                },
                                                {
                                                    value: 'SUPPLIER',
                                                    text: localization.supplier
                                                },
                                                {
                                                    value: 'CARGO_OWNER',
                                                    text: localization.cargo_owner
                                                }
                                            ]}
                                        />
                                    </FormFieldWrapper>
                                </FormContent>
                            </Form>
                        );
                    }}
                </Formik>
            </FormWrapper>
            <RequestsWrapper>
                {!relationRequests.recieved.length && !relationRequests.sent.length ? (
                    <EmptyState>{localization.requests_empty_state}</EmptyState>
                ) : (
                    <>
                        <RequestsContainer>
                            {!!relationRequests.recieved.length && <RequestsHeader>{localization.recieved}</RequestsHeader>}
                            {relationRequests.recieved.map((req) => (
                                <ItemContainer key={req.id}>
                                    <span>
                                        <b>{req.senderCompanyName}</b>
                                        {' as '}
                                        {req.relationType}
                                    </span>
                                    {req.status === 'PENDING' && (
                                        <div style={{ display: 'flex', alignItems: 'center' }}>
                                            <DropDown
                                                value={selectedBusinessPartners[req.id]}
                                                style={{ width: '200px', marginRight: 5 }}
                                                placeHolder={'Business Partner'}
                                                options={partiesToDropdownOptions(
                                                    req.relationType === 'CARGO_OWNER' ? businessPartnerCargoOwners : businessPartners
                                                )}
                                                onChange={(bpId: string) => {
                                                    selectBusinessPartner(req.id, bpId);
                                                }}
                                                showSearch
                                                allowClear
                                            />
                                            <Button
                                                margin="0 5px 0 0"
                                                buttonType="filled"
                                                disabled={!selectedBusinessPartners[req.id] && isApprove !== req.id}
                                                onClick={async () => {
                                                    const selectedBp = selectedBusinessPartners[req.id];
                                                    if (selectedBp) {
                                                        setIsApprove(req.id);
                                                        await approveCompaniesRelationRequest(
                                                            req.id,
                                                            selectedBp,
                                                            req.recieverBusinessPartnerId,
                                                            req.recieverRelationType
                                                        );
                                                        setIsApprove(false);
                                                    }
                                                }}
                                            >
                                                {isApprove === req.id ? (
                                                    <Loader width="20px" marginTop="0px" showText={false} />
                                                ) : (
                                                    <span>{localization.approve}</span>
                                                )}
                                            </Button>
                                            <span
                                                onClick={async (e) => {
                                                    e.stopPropagation();
                                                    Modal.confirm({
                                                        zIndex: 1100,
                                                        content: <div>{localization.are_you_sure}</div>,
                                                        async onOk() {
                                                            await denyCompaniesRelationRequest(req.id);
                                                        },
                                                        onCancel() {}
                                                    });
                                                }}
                                                className="material-icons"
                                                style={{ color: '#a2a2a2', fontSize: '12px', cursor: 'pointer' }}
                                            >
                                                close
                                            </span>
                                        </div>
                                    )}
                                    {req.status === 'DENIED' && <StatusText style={{ color: '#008ac9' }}>{req.status}</StatusText>}
                                </ItemContainer>
                            ))}
                        </RequestsContainer>
                        <RequestsContainer>
                            {!!relationRequests.sent.length && <RequestsHeader>{localization.sent}</RequestsHeader>}
                            {relationRequests.sent.map((req) => (
                                <ItemContainer key={req.id}>
                                    <span>
                                        {localization.waiting_for} <b>{req.recieverCompanyName}</b> {localization.to_approve}
                                    </span>
                                    <StatusText style={{ color: req.status === 'DENIED' ? '#f44336' : '#f0a333' }}>{req.status}</StatusText>
                                </ItemContainer>
                            ))}
                        </RequestsContainer>
                    </>
                )}
            </RequestsWrapper>
        </Wrapper>
    );
};

const Wrapper = styled.div`
    padding: 25px 25px 25px 5px;
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
`;

const RequestsHeader = styled.div`
    color: #394372;
    font-size: 16px;
    font-weight: bold;
    margin: 10px;
`;

const FormWrapper = styled.div`
    width: 100%;
    height: 150px;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: #fff;
`;

const RequestsWrapper = styled.div`
    width: 100%;
    flex: 1;
    align-items: center;
    justify-content: center;
    background-color: #fff;
    margin-top: 10px;
`;

const GenerateTokenWrapper = styled.div`
    position: absolute;
    top: 0;
    left: 0;
    transform: translateY(-100%);
    font-size: 12px;
    white-space: nowrap;
    display: flex;
    align-items: center;
    .generate-btn {
        color: #1890ff;
        text-decoration: none;
        background-color: transparent;
        outline: none;
        cursor: pointer;
        transition: color 0.3s;
        margin-left: 5px;

        &:hover {
            color: #40a9ff;
        }
    }
`;

const RequestInputWrapper = styled.div`
    position: relative;
`;

const FormFieldWrapper = styled.div`
    margin-right: 5px;
`;

const FormContent = styled.div`
    display: flex;
    width: 100%;
    display: flex;
    align-items: center;
    margin-top: 5px;
`;

const RequestsContainer = styled.div``;

const ItemContainer = styled.div`
    display: flex;
    align-items: center;
    height: 50px;
    background-color: #e5f4fa5e;
    justify-content: space-between;
    padding: 10px;
    border-bottom: 1px solid #e4e4e4;
`;

const StatusText = styled.b`
    font-size: 12px;
`;

const EmptyState = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 100%;
    font-size: 18px;
`;

export default RequestsSection;
