import React from 'react';
import { purchaseDemand } from '../../../../../models/entities/purchaseDemand/purchaseDemand';
import { purchaseDemandItem } from '../../../../../models/entities/purchaseDemand/purchaseDemandItem';
import { useHistory } from 'react-router-dom';
import { PagesRoutes } from '../../../../../routing/PagesRoutes';
import { uuidv4 } from '../../../../../utils/patterns';
import SelectItemsBySupplier from './SelectItemsBySupplier';

type Props = {
    purchaseDemand: purchaseDemand;
    localization: any;
};

const CreatePOFromPD = ({ purchaseDemand, localization }: Props) => {
    const history = useHistory();

    return (
        <SelectItemsBySupplier
            purchaseDemand={purchaseDemand}
            localization={localization}
            onSelectItemsCallBack={async (pdItems: purchaseDemandItem[]) => {
                history.push({
                    pathname: PagesRoutes.NewPurchaseOrder,
                    state: {
                        relatedPdId: purchaseDemand.id,
                        initialValues: {
                            items: pdItems.map((item) => {
                                return {
                                    id: uuidv4(),
                                    productId: item.productId,
                                    quantity: item.leftQuantity,
                                    name: item.name,
                                    code: item.code,
                                    price: item.price,
                                    currency: item.currency,
                                    length: item.length,
                                    width: item.width,
                                    height: item.height,
                                    dimensionsUnit: item.dimensionsUnit,
                                    weight: item.weight,
                                    weightUnit: item.weightUnit,
                                    productSku: item.productSku,
                                    attributes: item.attributes,
                                    purchaseDemandId: item.purchaseDemandId,
                                    purchaseDemandItemId: item.id,
                                    minOrderQuantity: item.minOrderQuantity,
                                    productionDays: item.productionDays,
                                    unitsPerMasterCarton: item.unitsPerMasterCarton,
                                    masterCartonWeight: item.masterCartonWeight,
                                    masterCartonWeightUnit: item.masterCartonWeightUnit,
                                    masterCartonHeight: item.masterCartonHeight,
                                    masterCartonWidth: item.masterCartonWidth,
                                    masterCartonLength: item.masterCartonLength,
                                    masterCartonDimensionUnit: item.masterCartonDimensionUnit
                                };
                            }),
                            companyId: purchaseDemand.companyId,
                            vendorId: pdItems[0].productSupplierId,
                            references: [
                                {
                                    id: '',
                                    entityId: purchaseDemand.id,
                                    value: purchaseDemand.CargoZoneNumber,
                                    companyType: 'CLIENT'
                                }
                            ]
                        }
                    }
                });
            }}
        />
    );
};

export default CreatePOFromPD;
