import { Formik } from 'formik';
import { pick } from 'lodash';
import React from 'react';
import styled from 'styled-components';
import { company } from '../../../../../models/entities/company/company';

type Props = {
    company: company;
};
const EditCompanyForm = ({ company }: Props) => {
    return (
        <Wrapper>
            <Title>{company.name}</Title>
            <DetailsContainer>
                {Object.entries(company).map(([key, value]) => {
                    return (
                        <DetailWrapper>
                            <DetailName>{key}</DetailName>
                            <DetailValue>{value}</DetailValue>
                        </DetailWrapper>
                    );
                })}
            </DetailsContainer>
        </Wrapper>
    );
};

const Wrapper = styled.div``;

const Title = styled.div`
    font-size: 18px;
`;

const DetailsContainer = styled.div`
    padding-top: 20px;
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(120px, 1fr));
    grid-gap: 1.5rem;
`;

const DetailWrapper = styled.div`
    line-bre
`;

const DetailName = styled.div`
    color: #008ac9;
`;

const DetailValue = styled.div`
    color: #394372;
`;

export default EditCompanyForm;
