import React from 'react';
import shipmentItem from '../../../../../models/entities/shipment/shipmentItem';
import { styled } from '../../../../shared/Theme/theme';
import MuiDataGrid, { MultiLineCell } from '../../../../shared/MuiDataGrid/MuiDataGrid';
import { GridCellParams, GridColDef, GridValueFormatterParams } from '@material-ui/data-grid';
import { currencyFormatter } from '../../../../shared/inputs/form/CurrencyInput/CurrencyInput';
import { Image } from 'antd';
import moment from 'moment';
import ObjectDetailsPopover from '../../../../shared/ObjectDetails/ObjectDetailsPopover';
import { product } from '../../../../../models/entities/product/product';

type Props = {
    items: Array<shipmentItem>;
    localization: any;
    itemsRelatedProducts?: product[];
};

const Items = ({ items, localization, itemsRelatedProducts }: Props) => {
    const gridColumns: GridColDef[] = [
        { field: 'productSku', headerName: localization.sku, width: 100 },
        {
            field: 'name',
            headerName: localization.product_name,
            width: 150,
            renderCell: (params: GridCellParams) => <MultiLineCell>{params.value}</MultiLineCell>
        },
        {
            field: 'Img',
            headerName: localization.images,
            hide: !itemsRelatedProducts?.some((i) => !!i.latestProductImgUrl),
            renderCell: (params: GridCellParams) => {
                const relatedProductId = params.row.productId;

                if (!relatedProductId) return <GridCell>-</GridCell>;

                const relatedProductImgUrl = itemsRelatedProducts?.find((p) => p.id === relatedProductId)?.latestProductImgUrl;

                if (!relatedProductImgUrl) return <GridCell>-</GridCell>;

                const restImges = itemsRelatedProducts
                    ?.find((p) => p.id === relatedProductId)
                    ?.documents?.filter((d) => d.type.includes('PRODUCT_IMG') && !!d.imgUrl && d.imgUrl !== relatedProductImgUrl);

                return (
                    <Image.PreviewGroup>
                        <ItemImgContainer
                            onClick={(e) => {
                                e.stopPropagation();
                            }}
                        >
                            <Image width={35} src={relatedProductImgUrl} />
                        </ItemImgContainer>
                        <div style={{ display: 'none' }}>
                            {restImges?.map((img, i) => (
                                <Image key={i} width={0} src={img.imgUrl} />
                            ))}
                        </div>
                    </Image.PreviewGroup>
                );
            },
            width: 100,
            sortable: false
        },
        {
            field: 'attributes',
            headerName: localization.attributes,
            hide: !items?.some((i) => !!i.attributes),
            width: 150,
            renderCell: (params: GridCellParams) => {
                const value = (params.value as { [key: string]: string }) || undefined;
                if (!value) return <GridCell>-</GridCell>;

                return (
                    <div style={{ width: '100%' }}>
                        <ObjectDetailsPopover
                            anchor={
                                <GridCell>
                                    {Object.entries(value)
                                        .map(([key, name]) => `${key}: ${name}`)
                                        .join(', ')}
                                </GridCell>
                            }
                            object={value}
                            localization={localization}
                        />
                    </div>
                );
            }
        },
        {
            field: 'hsCodes',
            hide: !itemsRelatedProducts?.some((i) => !!i.hsCodes?.filter((hs) => !!hs.code).length),
            width: 150,
            renderCell: (params: GridCellParams) => {
                const relatedProductId = params.row.productId;

                if (!relatedProductId) return <GridCell>-</GridCell>;

                const relatedProductHsCodes = itemsRelatedProducts?.find((p) => p.id === relatedProductId)?.hsCodes?.filter((hs) => !!hs.code);

                if (!relatedProductHsCodes?.length) return <GridCell>-</GridCell>;

                const hsCodesObj: { [key: string]: string } = relatedProductHsCodes.reduce((acc: { [key: string]: string }, hs) => {
                    acc[hs.code] = `${hs.percent}%`;

                    return acc;
                }, {});
                return (
                    <div style={{ width: '100%' }}>
                        <ObjectDetailsPopover
                            anchor={<GridCell>{relatedProductHsCodes.map((hsCode) => `${hsCode.code} - ${hsCode.percent}%`).join(', ')}</GridCell>}
                            object={hsCodesObj}
                            localization={localization}
                        />
                    </div>
                );
            }
        },
        {
            field: 'quantity',
            headerName: localization.quantity,
            width: 110
        },
        {
            field: 'unitsPerMasterCarton',
            headerName: localization.cartons,
            width: 110,
            renderCell: (params: GridCellParams) => {
                const unitsPerMasterCarton = Number(params.row.unitsPerMasterCarton);
                const quantity = Number(params.row.quantity);

                if (!unitsPerMasterCarton) return <GridCell>-</GridCell>;

                const cartonsNumber = Math.ceil(quantity / unitsPerMasterCarton);

                return <div>{cartonsNumber}</div>;
            }
        },
        {
            field: 'currency',
            width: 140,
            headerName: localization.total_cost,
            valueFormatter: (params: GridValueFormatterParams) =>
                params.getValue(params.id, 'price')
                    ? currencyFormatter(params.value)(
                          (Number(params.getValue(params.id, 'price')) || 0) * (Number(params.getValue(params.id, 'quantity')) || 1) || 0
                      )
                    : '-'
        },
        {
            field: 'readyDate',
            width: 140,
            headerName: localization.ready_date,
            valueFormatter: (params: GridValueFormatterParams) =>
                moment(params.value as string).isValid() ? moment(params.value as string).format('DD/MM/YY') : '-'
        },
        {
            field: 'id',
            headerName: ' ',
            width: 100,
            renderCell: (params: GridCellParams) => {
                const item = items?.find((item) => item.id === params.id);

                if (!item) return <GridCell>-</GridCell>;

                return (
                    <ObjectDetailsPopover
                        object={item}
                        filterCondition={(key: string, value: any) => {
                            return (
                                !!value &&
                                Number(value) !== 0 &&
                                !['productSku', 'name', 'price', 'quantity', 'updatedAt', 'attributes', 'readyDate'].includes(key) &&
                                (!key.toLowerCase().includes('id') || key === 'width') &&
                                !key.toLowerCase().includes('currency') &&
                                !key.toLowerCase().includes('unit')
                            );
                        }}
                        parseObjectValues={(key: string, value: any) => {
                            let parsedValue = value;
                            if (key === 'weight') {
                                parsedValue = `${value} ${item.weightUnit}`;
                            } else if (['width', 'length', 'height'].includes(key)) {
                                parsedValue = `${value} ${item.dimensionsUnit}`;
                            }

                            return parsedValue;
                        }}
                    />
                );
            }
        }
    ];
    return (
        <MuiDataGrid
            columns={gridColumns}
            rows={items}
            autoHeight
            disableColumnFilter
            disableColumnMenu
            disableColumnReorder
            disableSelectionOnClick
        />
    );
};

const GridCell = styled.div`
    width: 100%;
    display: block;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
`;

const ItemImgContainer = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;

    .ant-image-mask-info {
        font-size: 0;
        display: flex;
        justify-content: center;
        align-items: center;
    }

    .ant-image-mask-info .anticon {
        font-size: 14px;
        margin-inline-end: 0;
    }
`;

export default Items;
