import React from 'react';
import { styled } from '../../shared/Theme/theme';
import { match, Route, Switch, Redirect, useHistory } from 'react-router-dom';
import { Location } from 'history';
import CreateNew from './components/CreateNew';
import Pricing from './components/Pricing';
import ConfirmEmail from './components/ConfirmEmail';
import SetupCompany from './components/SetupCompany';
import CreateUserForm from './components/CreateUserForm';
import RegistrationSuccess from './components/RegistrationSuccess';
import { getStartedCreateCompanyPayload } from '../../../models/entities/company/getStartedCreateCompanyPayload';
import logo from '../../../static/common/logo.svg';
import { LocationStateType } from './GetStartedPageConnected';

type GetStartedPageProps = {
    localization: any;
    sendEmailValidation: (email: string) => Promise<boolean>;
    validateEmail: (email: string, pinCode: string, pricingPlanId?: string, underForwarderToken?: string) => Promise<string>;
    createCompany: (payload: getStartedCreateCompanyPayload) => Promise<void>;
    validateCompanyName: (name: string) => Promise<boolean>;
    isSendingValidationEmail: boolean;
    isValidateEmail: boolean;
    isCreateCompany: boolean;
    isValidateCompanyName: boolean;
    match: match;
    location: Location<LocationStateType>;
    underForwarderToken?: string;
};

const GetStartedPage = ({
    localization,
    sendEmailValidation,
    validateEmail,
    createCompany,
    validateCompanyName,
    isSendingValidationEmail,
    isValidateEmail,
    isCreateCompany,
    isValidateCompanyName,
    match,
    location,
    underForwarderToken
}: GetStartedPageProps) => {
    const history = useHistory();

    return (
        <PageContainer>
            <LogoImg src={logo} />

            <Switch>
                <Route exact path={`${match.path}/pricing`}>
                    <Pricing
                        onSelectPlan={(planId) => history.push({ pathname: match.url, search: location.search, state: { pricingPlanId: planId } })}
                    />
                </Route>
                <Route exact path={match.path}>
                    <CreateNew
                        localization={localization.create_new}
                        sendEmailValidation={sendEmailValidation}
                        isSendingValidationEmail={isSendingValidationEmail}
                        match={match}
                        location={location}
                    />
                </Route>
                <Route exact path={`${match.path}/confirmEmail`}>
                    <ConfirmEmail
                        localization={localization.confirm_email}
                        validateEmail={validateEmail}
                        isValidateEmail={isValidateEmail}
                        match={match}
                        location={location}
                        underForwarderToken={underForwarderToken}
                    />
                </Route>
                <Route exact path={`${match.path}/setupCompany`}>
                    <SetupCompany
                        localization={localization.setup_company}
                        validateCompanyName={validateCompanyName}
                        isValidateCompanyName={isValidateCompanyName}
                        originMatch={match}
                        location={location}
                    />
                </Route>
                <Route exact path={`${match.path}/provideDetails`}>
                    <CreateUserForm
                        localization={localization.create_user}
                        createCompany={createCompany}
                        isCreateCompany={isCreateCompany}
                        match={match}
                        location={location}
                    />
                </Route>
                <Route exact path={`${match.path}/success`}>
                    <RegistrationSuccess localization={localization.registration_success} match={match} location={location} />
                </Route>
                <Redirect to="/" />
            </Switch>
        </PageContainer>
    );
};

const PageContainer = styled.div`
    width: 70%;
    height: 100%;
    margin: 0 auto;
    display: flex;
    align-items: center;
    flex-direction: column;
`;

const LogoImg = styled.img`
    width: 200px;
    padding: 48px 0 40px;
`;

export default GetStartedPage;
