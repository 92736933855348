import React, { useEffect, useState } from 'react';
import { styled } from '../../../shared/Theme/theme';
import { Formik, Form } from 'formik';
import Loader from '../../../shared/SmallComponents/Loader';
import * as Yup from 'yup';
import { FormDropDown, FormTextInput } from '../../../shared/inputs/form';
import { match, useHistory } from 'react-router-dom';
import Button from '../../../shared/SmallComponents/Button';
import FieldLabel from '../../../shared/SmallComponents/FieldLabel';
import { css } from 'styled-components';
import { Location } from 'history';
import { LocationStateType } from '../GetStartedPageConnected';

type SetupCompanyProps = {
    localization: any;
    originMatch: match;
    validateCompanyName: (name: string) => Promise<boolean>;
    isValidateCompanyName: boolean;
    location: Location<LocationStateType>;
};

const SetupCompany = ({ location, localization, originMatch, isValidateCompanyName, validateCompanyName }: SetupCompanyProps) => {
    const [nameExistError, setNameExistError] = useState(false);
    const history = useHistory();

    useEffect(() => {
        if (!location?.state?.email) history.replace(`${originMatch.url}`);
    }, [location?.state?.email]);

    return (
        <Formik
            initialValues={{ name: '', type: '' }}
            validationSchema={Yup.object().shape({
                name: Yup.string().required(localization.required),
                type: Yup.string().required(localization.required)
            })}
            onSubmit={async ({ name, type }) => {
                const exist = await validateCompanyName(name);
                if (exist) return setNameExistError(true);
                history.push({
                    pathname: `${originMatch.url}/provideDetails`,
                    search: location.search,
                    state: { email: location.state.email, companyDetails: { name, type } }
                });
            }}
        >
            {(formik) => {
                const { errors, touched, isValid } = formik;

                return (
                    <Form style={{ width: '100%', display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center' }}>
                        <Title>{localization.title}</Title>
                        <SubTitle>{localization.sub_title}</SubTitle>
                        <FieldContainer style={{ marginBottom: errors.name && touched.name ? '5px' : '0px' }}>
                            <FieldLabel>{localization.name}</FieldLabel>
                            <FormTextInput name="name" placeHolder={localization.name} style={{ height: '44px', width: 400, fontSize: 18 }} />
                        </FieldContainer>
                        <FieldContainer style={{ marginBottom: errors.type && touched.type ? '5px' : '0px' }}>
                            <FieldLabel>{localization.type}</FieldLabel>
                            <FormDropDown
                                name="type"
                                placeHolder={localization.type}
                                style={{ height: '44px', width: 360 }}
                                options={[
                                    {
                                        text: 'Online Seller',
                                        value: 'ONLINE_SELLER'
                                    },
                                    {
                                        text: 'Forwarder',
                                        value: 'FORWARDER'
                                    },
                                    {
                                        text: 'Importer/Exporter',
                                        value: 'CARGO_OWNER'
                                    },
                                    {
                                        text: 'Other',
                                        value: 'OTHER'
                                    }
                                ]}
                                selectorStyle={css`
                                    height: 100% !important;
                                    display: flex;
                                    align-items: center;
                                    border-radius: 70px !important;
                                    font-size: 18px;
                                `}
                            />
                        </FieldContainer>
                        <Button widthMobile="280px" height="44px" margin="30px 0" width="360px" buttonType="filled" type="submit" disabled={!isValid}>
                            {isValidateCompanyName ? (
                                <Loader width="20px" marginTop="0px" showText={false} />
                            ) : (
                                <span>{localization.create_btn}</span>
                            )}
                        </Button>
                        {nameExistError && <CreateError>{localization.company_name_err}</CreateError>}
                    </Form>
                );
            }}
        </Formik>
    );
};

const Title = styled.div`
    font-weight: 700;
    font-size: 48px;
    line-height: 46px;
    max-width: 700px;
    text-align: center;
    letter-spacing: -0.75px;
    margin-bottom: 10px;
`;

const SubTitle = styled.div`
    font-size: 18px;
    line-height: 27px;
    margin-bottom: 32px;
    color: #454245;
    max-width: 700px;
    text-align: center;
`;

const Bottom = styled.div`
    margin-top: 24px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
`;

const CreateError = styled.div`
    margin-top: 20px;
    border-radius: 4px;
    font-size: 13px;
    padding: 12px 16px;
    display: flex;
    align-items: center;
    justify-content: center;

    background-color: rgba(224, 30, 90, 0.1);
    border: 1px solid rgba(224, 30, 90, 0.4);
`;

const FieldContainer = styled.div`
    margin-top: 12px;
`;

export default SetupCompany;
