import React, { PropsWithChildren, useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { RootState } from '../../../state/store/store';
import { styled } from '../../shared/Theme/theme';
import Loader from '../SmallComponents/Loader';
import { shipmentsSelectors } from '../../../state/ducks/shipments/selectors';
import MaterialPopover from '../Popover/MaterialPopover';
import { shipment } from '../../../models/entities/shipment/shipment';
import ShipmentItem from '../SmallComponents/ShipmentItem';
import { allShipmentsPageSelectors } from '../../../state/ducks/allShipmentsPage/selectors';
import { ColumnType } from '../Grid/types/Column';
import ExceptionPage from '../../pages/ExceptionPage/ExceptionPage';
import { localizationSelectors } from '../../../state/ducks/localization/selectors';
import { Result, Button } from 'antd';

type Props = {
    fetchShipmentById: (shipmentId: string) => Promise<shipment>;
    isLoadingShipment: boolean;
    fetchShipmentError?: string;
    shipmentId: string;
    popoverLeft?: boolean;
    getShipmentById: (id: string) => shipment | undefined;
    gridColumns: Array<ColumnType<shipment>>;
    fetchGridColumns: () => void;
    localizations?: any;
};

const ShipmentCard: React.FC<PropsWithChildren<Props>> = ({
    isLoadingShipment,
    shipmentId,
    popoverLeft,
    children,
    fetchShipmentError,
    fetchShipmentById,
    getShipmentById,
    fetchGridColumns,
    localizations,
    gridColumns
}) => {
    const [shipment, setShipment] = useState<shipment | undefined>(undefined);
    const [currentGridColumns, setCurrentGridColumns] = useState<Array<ColumnType<shipment>>>(gridColumns);

    useEffect(() => {
        setShipment(getShipmentById(shipmentId));
    }, [getShipmentById, shipmentId]);

    useEffect(() => {
        setCurrentGridColumns(gridColumns);
    }, [gridColumns]);

    return (
        <MaterialPopover
            anchor={children}
            popoverEnterFn={async () => {
                if (!currentGridColumns?.length) {
                    fetchGridColumns();
                }
                if (!isLoadingShipment && !shipment) {
                    const shipment = await fetchShipmentById(shipmentId);
                    setShipment(shipment);
                }
            }}
            popoverLeft={popoverLeft}
        >
            {shipment ? (
                <ShipmentItem shipment={shipment} gridColumns={gridColumns} opened={true}></ShipmentItem>
            ) : fetchShipmentError ? (
                <div>{localizations?.exceptions[401]?.sub_title}</div>
            ) : (
                <LoaderContainer>
                    <Loader width="30px" marginTop="0" showText={false}></Loader>
                </LoaderContainer>
            )}
        </MaterialPopover>
    );
};

const LoaderContainer = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 100%;
`;

const mapStateToProps = (state: RootState) => ({
    localizations: localizationSelectors.singleShipmentPage(state),
    isLoadingShipment: state.loading.effects.shipments.fetchShipmentById || state.loading.effects.allShipmentsPage.fetchGridColumns,
    fetchShipmentError: state.error.effects.shipments.fetchShipmentById,
    getShipmentById: (id: string) => shipmentsSelectors.getShipmentById(state, id),
    gridColumns: allShipmentsPageSelectors.gridColumns(state)
});

const mapDispatchToProps = (dispatch: any) => ({
    fetchShipmentById: (shipmentId: string) => dispatch.shipments.fetchShipmentById(shipmentId),
    fetchGridColumns: () => dispatch.allShipmentsPage.fetchGridColumns()
});

export default connect(mapStateToProps, mapDispatchToProps)(ShipmentCard);
