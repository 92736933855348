import React from 'react';
import { styled } from '../../../shared/Theme/theme';
import Loader from '../../../shared/SmallComponents/Loader';
import Grid from '../../../shared/Grid/Grid';
import { route } from '../../../../models/entities/shipment/route';
import { ColumnType } from '../../../shared/Grid/types/Column';

type Props = {
    routes?: Array<route>;
    localization: any;
};

const routesTable = ({ routes, localization }: Props) => {
    const { origin_station, destination_station, departure, arrival, flight_number } = localization.routes.grid.columns;
    const gridColumns: Array<ColumnType<route>> = [
        {
            title: origin_station,
            field: 'origin_station',
            sortable: false,
            isDisplayed: true,
            bindedFields: ['origin_country'],
            columnType: 'origin',
            width: '90px'
        },
        {
            title: destination_station,
            field: 'destination_station',
            sortable: false,
            isDisplayed: true,
            bindedFields: ['destination_country'],
            columnType: 'destination',
            width: '90px'
        },
        {
            title: departure,
            field: 'departure',
            sortable: false,
            isDisplayed: true,
            columnType: 'date'
        },
        {
            title: arrival,
            field: 'arrival',
            sortable: false,
            isDisplayed: true,
            columnType: 'date'
        },
        {
            title: flight_number,
            field: 'flight_number',
            sortable: false,
            isDisplayed: true
        }
    ];
    return (
        <Wrapper>
            {routes ? (
                routes.length > 0 ? (
                    <GridWrapper>
                        <Grid<route>
                            sortedByColumn="flight_number"
                            sortDirection="asc"
                            columns={gridColumns}
                            data={routes}
                            localization={localization.routes.grid}
                        />
                    </GridWrapper>
                ) : (
                    <EmaptyStateText>{localization.routes.empty_state}</EmaptyStateText>
                )
            ) : (
                <Loader marginTop="0px" />
            )}
        </Wrapper>
    );
};

const GridWrapper = styled.div`
    flex: 0 0 100%;
    max-width: 100%;
    width: 100%;
    box-sizing: border-box;
    overflow-y: overlay;
    height: 100%;
    width: 100%;
`;

const Wrapper = styled.div`
    display: flex;
    flex-direction: column;
    width: 100%;
`;

const EmaptyStateText = styled.span`
    color: ${(props) => props.theme.colors.emptyStateText};
`;

export default routesTable;
