export const eIncoterms = {
    EXW: 'EXW',
    FCA: 'FCA',
    FAS: 'FAS',
    FOB: 'FOB',
    CIF: 'CIF',
    CFR: 'CFR',
    CIP: 'CIP',
    CPT: 'CPT',
    DAT: 'DAT',
    DAP: 'DAP',
    DDP: 'DDP'
};
