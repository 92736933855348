import React from 'react';
import styled from 'styled-components';
import { company } from '../../../../models/entities/company/company';
import CompaniesMgmtPageHeader from './components/CompaniesMgmtPageHeader';
import CompaniesMgmtGrid from './components/CompaniesMgmtGrid';
import { drawers } from '../../../../state/ducks/header/header';
import { spApiLwaAuthorizationPayload } from '../../../../models/entities/company/spApiLwaAuthorizationPayload';
import { featureFlagsModel } from '../../../../models/entities/businessPartner/settings';
import { adsApiAuthorizationPayload } from '../../../../models/entities/company/adsApiAuthorizationPayload';

type Props = {
    fetchMyCompanies: () => Promise<void>;
    companies: company[];
    localization?: any;
    showLoader: boolean;
    setDrawerOpen: (open: drawers, params?: any) => void;
    setGridFilter: (text: string) => void;
    gridFilter?: string;
    getLwaState: (companyId: string) => Promise<string>;
    spApiLwaAuthorization: (payload: spApiLwaAuthorizationPayload) => Promise<boolean>;
    adsApiAuthorization: (payload: adsApiAuthorizationPayload) => Promise<boolean>;
    featureFlags?: featureFlagsModel;
};
const CompaniesMgmtPage = ({
    fetchMyCompanies,
    companies,
    localization,
    showLoader,
    setDrawerOpen,
    setGridFilter,
    gridFilter,
    getLwaState,
    spApiLwaAuthorization,
    adsApiAuthorization,
    featureFlags
}: Props) => {
    return (
        <PageWrapper>
            <HeaderWrapper>
                <CompaniesMgmtPageHeader
                    fetchMyCompanies={fetchMyCompanies}
                    localization={localization}
                    setDrawerOpen={setDrawerOpen}
                    setGridFilter={setGridFilter}
                    gridFilter={gridFilter}
                />
            </HeaderWrapper>
            <ContainersGridWrapper>
                <CompaniesMgmtGrid
                    companiesGridData={companies}
                    showLoader={showLoader}
                    localization={localization}
                    setDrawerOpen={setDrawerOpen}
                    getLwaState={getLwaState}
                    featureFlags={featureFlags}
                />
            </ContainersGridWrapper>
        </PageWrapper>
    );
};

const PageWrapper = styled.div`
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
`;
const HeaderWrapper = styled.div`
    width: 100%;
    padding: 20px 30px;
`;
const ContainersGridWrapper = styled.div`
    flex: 1;
`;

export default CompaniesMgmtPage;
