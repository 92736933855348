import BaseController from '../..';
import IPurchaseOrderFollow from './IPurchaseOrderFollow';

export default class PurchaseOrderFollow extends BaseController implements IPurchaseOrderFollow {
    constructor(baseUrl: string) {
        super(baseUrl);
    }
    async followPurchaseOrder(purchaseOrderId: string, userPicture: string) {
        const response = await this.client.post(`/purchaseOrder/followers`, JSON.stringify({ purchaseOrderId, userPicture }));
        return response;
    }
    async unFollowPurchaseOrder(purchaseOrderId: string) {
        const response = await this.client.delete(`/purchaseOrder/followers`, JSON.stringify({ purchaseOrderId }));
        return response;
    }
    async getMyFollowingInvitations() {
        const response = await this.client.get(`/purchaseOrder/followers/invitations`);
        return response;
    }
}
