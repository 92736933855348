import React from 'react';
import { settings } from '../../../../models/entities/businessPartner/businessPartner';
import { featureFlagsModel } from '../../../../models/entities/businessPartner/settings';
import { shipment } from '../../../../models/entities/shipment/shipment';
import ShipmentPartyMenu from '../../../shared/Grid/specialColumns/ShipmentPartyMenu';
import Parties from '../../commonSubEntities/parties/Parties';
import PartyFormConnected from '../PartyForm/PartyFormConnected';

type Props = {
    shipment: shipment;
    localization: any;
    settings?: settings;
    printMode?: boolean;
    featureFlags?: featureFlagsModel;
};

const ShipmentParties = ({ shipment, localization, settings, printMode, featureFlags }: Props) => {
    return (
        <Parties<shipment, settings>
            entity={shipment}
            localization={localization}
            settings={settings}
            PartyMenu={ShipmentPartyMenu}
            PartyFormConnected={PartyFormConnected}
            printMode={printMode}
            featureFlags={featureFlags}
        />
    );
};

export default ShipmentParties;
