import { Radio } from 'antd';
import React from 'react';
import Boxes from './Boxes';
import FCLPackage from './FCL';
import Totals from './Totals';

type Props = {
    values: any;
    setFieldValue: any;
    localizations: any;
    isContainers?: boolean;
    shipmentId?: string;
    deleteShipmentContainer?: (shipmentId: string, containerId: string) => Promise<void>;
    deleteShipmentPackage?: (shipmentId: string, packageId: string) => Promise<void>;
};

const Packages = ({ values, setFieldValue, localizations, isContainers, shipmentId, deleteShipmentContainer, deleteShipmentPackage }: Props) => {
    return values.sub_type === 'FCL' || values.sub_type === 'CON' || isContainers ? (
        <FCLPackage
            values={values}
            setFieldValue={setFieldValue}
            localizations={localizations}
            shipmentId={shipmentId}
            deleteShipmentContainer={deleteShipmentContainer}
        />
    ) : (
        <div>
            <Radio.Group onChange={(e) => setFieldValue('isTotals', e.target.value)} value={values.isTotals} style={{ marginBottom: '10px' }}>
                <Radio value={false}>BOXES/PALLETS</Radio>
                <Radio value={true}>TOTALS</Radio>
            </Radio.Group>
            {values.isTotals ? (
                <Totals values={values} localizations={localizations} />
            ) : (
                <Boxes
                    values={values}
                    setFieldValue={setFieldValue}
                    localizations={localizations}
                    deleteShipmentPackage={deleteShipmentPackage}
                    shipmentId={shipmentId}
                />
            )}
        </div>
    );
};

export default Packages;
