import React, { useState } from 'react';
import { DraggableLocation, DraggableProvided } from 'react-beautiful-dnd';
import { shipment, shipmentSettingsFunc } from '../../../../models/entities/shipment/shipment';
import Board from '../../../shared/Board/Board';
import { BoardColumnMap } from '../../../shared/Board/types/BoardColumn';
import styled from 'styled-components';
import AllShipmentsHeader from '../components/AllShipmentsHeader';
import { drawers } from '../../../../state/ducks/header/header';
import { ColumnType } from '../../../shared/Grid/types/Column';
import ShipmentItem from '../../../shared/SmallComponents/ShipmentItem';
import { Modal } from 'antd';
import ChooseForwarder, { stateProps } from '../../../shared/ChooseForwarder';
import { chooseForwarderHandler } from '../../../shared/ChooseForwarder/utils';
import claimsType from '../../../../models/entities/claimsType';
import { BidFormModal } from '../../singleShipment/BidForm/ShipmentBidFormConnected';
import { bid } from '../../../../models/entities/bid/bid';
import { claimsDomainsType } from '../../../../userClaims';

type Props = {
    shipmentsBoardData: BoardColumnMap<shipment>;
    gridColumns: Array<ColumnType<shipment>>;
    fetchShipments: () => void;
    updateShipmentState: (shipmentId: string, shipmentState: string, forwarderId?: string) => Promise<void>;
    settings?: shipmentSettingsFunc;
    setDrawerOpen: (open: drawers, params?: any) => void;
    getUserClaims: (domain: claimsDomainsType) => claimsType;
    lastVisitShipmentId?: string;
    resetFilterFunc?: () => void;
    localization: any;
    createBid: (shipment: shipment, bid: bid, publish: boolean) => Promise<string>;
    updateShipmentForwarder: (id: string, forwarderId: string | null) => Promise<boolean>;
    isFreelancerOrForwarder?: boolean;
};

const ShipmentsBoardPage = ({
    shipmentsBoardData,
    fetchShipments,
    updateShipmentState,
    settings,
    setDrawerOpen,
    getUserClaims,
    gridColumns,
    lastVisitShipmentId,
    resetFilterFunc,
    localization,
    createBid,
    updateShipmentForwarder,
    isFreelancerOrForwarder
}: Props) => {
    const [chooseForwarder, setChooseForwarder] = useState<stateProps>();
    const [showBidModal, setShowBidModal] = useState<{ open: boolean; shipment?: shipment }>({ open: false });
    const delay = (ms: number) => new Promise<void>((res) => setTimeout(res, ms));
    const onDragItemEnd = async (source: DraggableLocation, destination: DraggableLocation) => {
        if (source.droppableId !== destination.droppableId) {
            const shipment: shipment = shipmentsBoardData[source.droppableId].items[source.index];
            if (destination.droppableId === 'BID') {
                if (shipment.forwarderId) {
                    Modal.confirm({
                        title: `${localization.board_page.you_are_moving_to_bid_with_forwarder}
                    ${localization.board_page.are_you_sure}`,
                        async onOk() {
                            await updateShipmentForwarder(shipment.id, null).then((success) => {
                                if (success) setShowBidModal({ open: true, shipment });
                            });
                        },
                        onCancel() {}
                    });
                } else setShowBidModal({ open: true, shipment });
                return;
            }
            Modal.confirm({
                title: `${localization.board_page.you_are_moving_message} ${
                    settings ? settings(shipment).state?.find((item) => item.code === destination.droppableId)?.name : destination.droppableId
                }.
            ${localization.board_page.are_you_sure}`,
                async onOk() {
                    await chooseForwarderHandler(
                        shipment,
                        destination.droppableId,
                        setChooseForwarder,
                        updateShipmentState,
                        isFreelancerOrForwarder,
                        settings && settings(shipment)
                    );
                },
                onCancel() {}
            });
        }
    };

    const renderItem = (item: shipment, isDragging: boolean, provided: DraggableProvided) => {
        return (
            <>
                <ShipmentItem
                    shipment={item}
                    isDragging={isDragging}
                    provided={provided}
                    hasMenu={true}
                    gridColumns={gridColumns}
                    refOpenShipmentPane={true}
                    isLastVisited={item.id === lastVisitShipmentId}
                />
            </>
        );
    };
    return (
        <>
            {chooseForwarder && (
                <ChooseForwarder
                    companyId={chooseForwarder.companyId}
                    onSubmit={chooseForwarder.func}
                    submitArgs={chooseForwarder.args}
                    closeModal={() => setChooseForwarder(undefined)}
                />
            )}
            {showBidModal.open && (
                <BidFormModal
                    shipment={showBidModal.shipment}
                    onPublishCallBack={async (createdBid, publish) => {
                        if (createBid && showBidModal.shipment) {
                            await createBid(showBidModal.shipment, { ...createdBid, shipmentId: showBidModal.shipment.id }, publish);
                            setShowBidModal({ open: false });
                        }
                    }}
                    Trigger={(props) => <>{props.onClick()}</>}
                    onCancelCallback={() => setShowBidModal({ open: false })}
                />
            )}
            <AllShipmentsHeader fetchShipments={fetchShipments} hidePagination getUserClaims={getUserClaims} />
            <BoardContainer>
                <Board<shipment>
                    columns={shipmentsBoardData}
                    onDragItemEndFn={onDragItemEnd}
                    renderItem={renderItem}
                    resetFilterFunc={resetFilterFunc}
                    localization={localization}
                    isDragDisabled={!getUserClaims('shipment').edit_state}
                />
            </BoardContainer>
        </>
    );
};

const BoardContainer = styled.div`
    min-height: calc(100% - 58px);
    height: 1px;
`;
export default ShipmentsBoardPage;
