import React, { useEffect, useState } from 'react';
import { styled } from '../../../shared/Theme/theme';
import { Formik, Form } from 'formik';
import Button from '../../../shared/SmallComponents/Button';
import { ModalFooter, ModalFormContainer } from '../../../shared/Modal/Modal';
import Loader from '../../../shared/SmallComponents/Loader';
import { FormTextInput } from '../../../shared/inputs/form';
import FieldLabel from '../../../shared/SmallComponents/FieldLabel';
import { connect } from 'react-redux';
import { reference } from '../../../../models/entities/common_subentities/reference';

export enum formMode {
    NEW,
    EDIT
}

interface formFields {
    value?: string;
    companyType?: string;
}

type Props = {
    entityId: string;
    reference?: reference;
    mode: formMode;
    openedFromModal?: {
        setVisible: React.Dispatch<React.SetStateAction<boolean>>;
    };
    createReference: (entityId: string, reference: reference) => Promise<void>;
    updateReference: (entityId: string, reference: reference) => Promise<void>;
    deleteReference: (entityId: string, referenceId: string) => Promise<void>;
    showLoader?: boolean;
    localization: any;
};

function RefFormConnected({
    reference,
    entityId,
    mode,
    openedFromModal,
    createReference,
    updateReference,
    deleteReference,
    showLoader,
    localization
}: Props) {
    const [submitLoading, setLoading] = useState(false);
    const initFormValues: formFields = {
        value: '',
        companyType: ''
    };
    const setInitialValues = () => {
        if (!reference && mode === formMode.NEW) {
            return initFormValues;
        } else if (reference) {
            initFormValues.value = reference.value;
            initFormValues.companyType = reference.companyType;
        }
        return initFormValues;
    };
    const validate = (values: any) => {
        const errors: any = {};
        if (values.value.length > 20) errors.value = localization.form.maxLength(20);

        return errors;
    };

    const submit = async (values: any) => {
        setLoading(true);

        const payload = {
            value: values.value,
            companyType: values.companyType
        } as reference;
        if (mode === formMode.NEW) await createReference(entityId, { ...payload });
        else if (reference)
            await updateReference(entityId, {
                ...payload,
                id: reference.id
            } as reference);

        setLoading(false);
        openedFromModal?.setVisible(false);
    };

    const onDelete = async () => {
        setLoading(true);
        if (reference) await deleteReference(entityId, reference.id);
        setLoading(false);
        openedFromModal?.setVisible(false);
    };

    return (
        <Formik initialValues={setInitialValues()} validate={validate} onSubmit={submit}>
            {({ values, setFieldValue }) => {
                return (
                    <Form>
                        <Container>
                            <FieldLabel>{localization.form.value}</FieldLabel>
                            <FieldContainer>
                                <FormTextInput name="value" placeHolder="Value" />
                            </FieldContainer>
                        </Container>
                        {openedFromModal && (
                            <FormFooter>
                                {mode === formMode.NEW ? (
                                    <Button minWidth="55px" onClick={() => openedFromModal.setVisible(false)}>
                                        <span>{localization.form.cancel_btn}</span>
                                    </Button>
                                ) : (
                                    <Button minWidth="55px" onClick={async () => await onDelete()} type="button">
                                        {submitLoading ? (
                                            <Loader width="20px" marginTop="0px" showText={false} />
                                        ) : (
                                            <span>{localization.form.delete_btn}</span>
                                        )}
                                    </Button>
                                )}
                                <Button minWidth="55px" buttonType="filled" type="submit">
                                    {submitLoading ? (
                                        <Loader width="20px" marginTop="0px" showText={false} />
                                    ) : (
                                        <span>{localization.form.save_btn}</span>
                                    )}
                                </Button>
                            </FormFooter>
                        )}
                    </Form>
                );
            }}
        </Formik>
    );
}
const FieldContainer = styled.div`
    margin-bottom: 12px;
`;

const Container = styled(ModalFormContainer)``;

const FormFooter = styled(ModalFooter)`
    position: absolute;
    bottom: 0;
    width: 100%;
    left: 0;
    right: 0;
    background: white;
`;
const Flex = styled.div`
    display: flex;
`;

export default RefFormConnected;
