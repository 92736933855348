import React from 'react';
import { GridCellParams, GridColDef, GridValueFormatterParams } from '@material-ui/data-grid';
import styled from 'styled-components';
import { Image } from 'antd';
import MaterialPopover from '../../../../shared/Popover/MaterialPopover';
import { currencyFormatter } from '../../../../shared/inputs/form/CurrencyInput/CurrencyInput';
import infoIcon from '../../../../../static/icons/info.svg';
import EditIcon from '../../../../../static/icons/edit.svg';
import { ModalTriggerProps } from '../../../../shared/Modal/Modal';
import { drawers } from '../../../../../state/ducks/header/header';
import { product } from '../../../../../models/entities/product/product';
import { MultiLineCell } from '../../../../shared/MuiDataGrid/MuiDataGrid';
import NumberInput from '../../../../shared/inputs/base/NumberInput';
import CZTooltip from '../../../../shared/CZTooltip/CZTooltip';
import ObjectDetailsPopover from '../../../../shared/ObjectDetails/ObjectDetailsPopover';
import shipmentItem from '../../../../../models/entities/shipment/shipmentItem';
import { Link } from 'react-router-dom';
import { PagesRoutes } from '../../../../../routing/PagesRoutes';
import { shipment } from '../../../../../models/entities/shipment/shipment';
import SingleValueFormModal from '../../../../shared/SingleValueFormModal/SingleValueFormModal';
import { featureFlagsModel } from '../../../../../models/entities/businessPartner/settings';
import { calcItemVolume, calcShipmentItemsTotalVolumeWeight } from './utils';

export const ShipmentItemsGridColDef = (
    shipment: shipment,
    items: Array<shipmentItem>,
    localization: any,
    featureFlags?: featureFlagsModel,
    onUpdateItemQuantity?: (updatedItem: any) => Promise<void>,
    onDeleteItem?: (itemIndex: number) => Promise<void>,
    setDrawerOpen?: (open: drawers, params?: any) => void,
    setFieldValue?: any,
    itemsRelatedProducts?: Array<product>,
    isAllowedShipment?: boolean,
    updateShipmentItem?: (item: shipmentItem) => Promise<void>,
    isFreelancerOrForwarder?: boolean
): GridColDef[] => {
    const editableField = (name: string, title: string, hide?: boolean): GridColDef => ({
        hide,
        field: name,
        headerName: title,
        flex: 1,
        renderCell: (params: GridCellParams) => {
            const amazonReferenceId = params.value as string | undefined;

            const itemId = params.getValue(params.id, 'id') as string;
            const itemIndex = items?.findIndex((item) => item.id === params.id);

            if (itemIndex === -1) return <GridCell>-</GridCell>;

            return (
                <CostCell
                    onKeyDown={(e) => e.stopPropagation()}
                    onClick={(e) => {
                        e.stopPropagation();
                    }}
                >
                    {amazonReferenceId || '-'}
                    {itemId && !!updateShipmentItem && (
                        <SingleValueFormModal
                            title={title}
                            initialValue={amazonReferenceId || ''}
                            valueType="text"
                            onSubmit={async (value: any) => {
                                setFieldValue?.(`items[${itemIndex}][${name}]`, value);
                                await updateShipmentItem({ ...items?.[itemIndex], [name]: value });
                            }}
                            Trigger={(props: ModalTriggerProps) => {
                                return (
                                    <span>
                                        <img
                                            src={EditIcon}
                                            onMouseDown={(e) => {
                                                props.onClick();
                                            }}
                                            className="edit-icon"
                                            alt={localization.edit}
                                        />
                                    </span>
                                );
                            }}
                        />
                    )}
                </CostCell>
            );
        }
    });

    return [
        {
            field: 'productSku',
            headerName: localization.sku,
            flex: 1,
            renderCell: (params: GridCellParams) => {
                return (
                    <GridCell
                        onClick={(e) => {
                            e.stopPropagation();
                            if (params.getValue(params.id, 'productId') && setDrawerOpen)
                                setDrawerOpen('PRODUCT', { productId: params.getValue(params.id, 'productId') });
                        }}
                    >
                        <LinkButton>{params.value}</LinkButton>
                    </GridCell>
                );
            }
        },
        {
            field: 'name',
            headerName: localization.product_name,
            renderCell: (params: GridCellParams) => <MultiLineCell>{params.value}</MultiLineCell>,
            flex: 1
        },
        {
            field: 'Img',
            headerName: localization.images,
            hide: itemsRelatedProducts?.every((p) => !p.latestProductImgUrl),
            renderCell: (params: GridCellParams) => {
                const relatedProductId = params.row.productId;

                if (!relatedProductId) return <GridCell>-</GridCell>;

                const relatedProductImgUrl = itemsRelatedProducts?.find((p) => p.id === relatedProductId)?.latestProductImgUrl;

                if (!relatedProductImgUrl) return <GridCell>-</GridCell>;

                const restImges = itemsRelatedProducts
                    ?.find((p) => p.id === relatedProductId)
                    ?.documents?.filter((d) => d.type.includes('PRODUCT_IMG') && !!d.imgUrl && d.imgUrl !== relatedProductImgUrl);

                return (
                    <Image.PreviewGroup>
                        <ItemImgContainer
                            onClick={(e) => {
                                e.stopPropagation();
                            }}
                        >
                            <Image width={35} src={relatedProductImgUrl} />
                        </ItemImgContainer>
                        <div style={{ display: 'none' }}>
                            {restImges?.map((img, i) => (
                                <Image key={i} width={0} src={img.imgUrl} />
                            ))}
                        </div>
                    </Image.PreviewGroup>
                );
            },
            width: 100,
            sortable: false
        },
        {
            field: 'attributes',
            headerName: localization.attributes,
            flex: 1,
            renderCell: (params: GridCellParams) => {
                const value = (params.value as { [key: string]: string }) || undefined;
                if (!value) return <GridCell>-</GridCell>;

                return (
                    <div style={{ width: '100%' }}>
                        <MaterialPopover
                            anchor={
                                <MultiLineCell>
                                    {Object.entries(value)
                                        .map(([key, name]) => `${key}: ${name}`)
                                        .join(', ')}
                                </MultiLineCell>
                            }
                        >
                            <GridCell>
                                {Object.entries(value).map(([key, name], i) => (
                                    <Detail key={`${params.row.id}-att-option-${i}`}>
                                        <DetailName>{key}:</DetailName>
                                        <DetailValue>{name}</DetailValue>
                                    </Detail>
                                ))}
                            </GridCell>
                        </MaterialPopover>
                    </div>
                );
            }
        },
        {
            field: 'quantity',
            headerName: localization.quantity,
            width: 110,
            renderCell: (params: GridCellParams) => {
                const itemIndex = items?.findIndex((item) => item.id === params.id);
                const minOrderQuantity = Number(params.getValue(params.id, 'minOrderQuantity')) || 1;
                const quantity = Number(params.row.quantity) || minOrderQuantity;
                const isMinOrderValueExceeded = quantity < minOrderQuantity;

                if (itemIndex === -1) return <GridCell>-</GridCell>;

                if (!onUpdateItemQuantity) return <GridCell>{params.value}</GridCell>;
                return (
                    <CZTooltip text={`Minimum order value is ${minOrderQuantity}`} hidden={!isMinOrderValueExceeded}>
                        <div onClick={(e) => e.stopPropagation()}>
                            <NumberInput
                                onChange={async (value: any) => {
                                    if (setFieldValue) setFieldValue(`items[${itemIndex}][quantity]`, value || minOrderQuantity);
                                    if (onUpdateItemQuantity)
                                        await onUpdateItemQuantity({ ...items?.[itemIndex], quantity: value || minOrderQuantity });
                                }}
                                value={Number(items[itemIndex]['quantity']) || minOrderQuantity}
                                placeHolder={localization.quantity}
                                style={{ width: '90px', color: isMinOrderValueExceeded ? 'red' : 'inherit' }}
                                min={1}
                            />
                        </div>
                    </CZTooltip>
                );
            }
        },
        {
            field: 'unitsPerMasterCarton',
            headerName: localization.cartons,
            width: 110,
            renderCell: (params: GridCellParams) => {
                const itemIndex = items?.findIndex((item: shipmentItem) => item.id === params.id);

                const unitsPerMasterCarton = Number(params.row.unitsPerMasterCarton);
                const quantity = Number(params.row.quantity);

                if (itemIndex === -1 || !unitsPerMasterCarton) return <GridCell>-</GridCell>;

                const cartonsNumber = Math.ceil(quantity / unitsPerMasterCarton);

                return <div>{cartonsNumber}</div>;
            }
        },
        {
            field: 'price',
            flex: 1,
            headerName: localization.price,
            valueFormatter: (params: GridValueFormatterParams) =>
                params.value ? currencyFormatter(params.getValue(params.id, 'currency'))(params.value) : '-',
            renderCell: (params: GridCellParams) => {
                const { value } = params;

                if (!value) return <GridCell>-</GridCell>;

                const currencyValue = params.getValue(params.id, 'currency');
                const parsedValue = currencyFormatter(currencyValue)(value);
                const itemIndex = items?.findIndex((item) => item.id === params.id);

                if (itemIndex === -1) return <GridCell>-</GridCell>;

                return (
                    <CostCell
                        onKeyDown={(e) => e.stopPropagation()}
                        onClick={(e) => {
                            e.stopPropagation();
                        }}
                    >
                        {parsedValue}
                    </CostCell>
                );
            }
        },
        {
            field: 'currency',
            flex: 1,
            headerName: localization.total_cost,
            valueFormatter: (params: GridValueFormatterParams) =>
                params.getValue(params.id, 'price')
                    ? currencyFormatter(params.value)(
                          (Number(params.getValue(params.id, 'price')) || 0) * (Number(params.getValue(params.id, 'quantity')) || 1) || 0
                      )
                    : '-'
        },
        {
            field: 'pricePerUnit',
            headerName: localization.transportation_cost_per_unit,
            flex: 1,
            renderCell: (params: GridCellParams) => {
                const approvedQuotation = shipment.bid?.quotations?.filter((item) => item.state === 'APPROVED')?.[0];

                if (!approvedQuotation) return;

                const freightPrice = Number(approvedQuotation.freightPrice);

                let shipmentVolume = Number(shipment.volume);
                let shipmentVolumeUnit = shipment.volumeUnit;

                if (!(shipmentVolume && shipmentVolumeUnit) && shipment.items?.length) {
                    const { volume: itemsTotalVolume } = calcShipmentItemsTotalVolumeWeight(shipment.items);
                    const { volume, unit } = itemsTotalVolume;

                    shipmentVolume = volume;
                    shipmentVolumeUnit = unit;
                }

                const quotationCurrency = approvedQuotation.freightPriceCurrency;
                const { volume: itemVolume, unit: itemVolumeUnit } = calcItemVolume(params.row as shipmentItem, true);

                if (!(freightPrice && shipmentVolume && quotationCurrency && Number(itemVolume)) && shipmentVolumeUnit) return '-';

                const quotationPricePerVolumeUnit = freightPrice / shipmentVolume;

                let conversionFactor = 1;

                if (shipmentVolumeUnit === 'CBM' && itemVolumeUnit === 'CFT') conversionFactor = 0.0283168;
                if (shipmentVolumeUnit === 'CFT' && itemVolumeUnit === 'CBM') conversionFactor = 35.3147;

                const pricePerOneItem = quotationPricePerVolumeUnit * itemVolume * conversionFactor;

                return <GridCell>{currencyFormatter(quotationCurrency)(pricePerOneItem.toFixed(3))}</GridCell>;
            }
        },
        {
            field: 'purchaseOrderId',
            headerName: localization.po_ref,
            flex: 1,
            renderCell: (params: GridCellParams) => {
                const poId = params.value as string | undefined;
                const poCzNumber = params.getValue(params.id, 'purchaseOrderCzNumber') as string | undefined;

                if (!poId || !poCzNumber) return <GridCell>-</GridCell>;

                if (!isAllowedShipment) return <GridCell>{poCzNumber}</GridCell>;

                return <Link to={{ pathname: PagesRoutes.PurchaseOrders + '/' + poId }}>{poCzNumber}</Link>;
            }
        },
        editableField('amazonReferenceId', localization.amazon_reference_id, !featureFlags?.AMAZON),
        editableField('amazonShipmentId', localization.amazon_shipment_id, !featureFlags?.AMAZON),
        {
            field: 'id',
            headerName: ' ',
            flex: 1,
            renderCell: (params: GridCellParams) => {
                const itemIndex = items?.findIndex((item) => item.id === params.id);
                if (itemIndex === -1) return <GridCell>-</GridCell>;

                const parseObjectValues = (key: string, value: any): string => {
                    if (key === 'weight') {
                        return `${value} ${items[itemIndex].weightUnit || ''}`;
                    }

                    if (['width', 'length', 'height'].includes(key)) {
                        return `${value} ${items[itemIndex].dimensionsUnit || ''}`;
                    }

                    return value;
                };

                const filterCondition = (key: string, value: any): boolean =>
                    !!value &&
                    Number(value) !== 0 &&
                    !['productSku', 'name', 'price', 'quantity', 'updatedAt', 'attributes'].includes(key) &&
                    (!key.toLowerCase().includes('id') || key === 'width') &&
                    !key.toLowerCase().includes('currency') &&
                    !key.toLowerCase().includes('unit');

                return (
                    <ItemOptionsContainer>
                        <ObjectDetailsPopover
                            anchor={
                                <InfoIconWrapper>
                                    <InfoIcon src={infoIcon} />
                                </InfoIconWrapper>
                            }
                            parseObjectValues={parseObjectValues}
                            filterCondition={filterCondition}
                            object={items?.[itemIndex] || {}}
                            localization={localization}
                        />

                        {!!onDeleteItem && <VerticalDivider />}
                        {!!onDeleteItem && (
                            <DeleteContainer
                                onClick={async (e) => {
                                    e.stopPropagation();
                                    await onDeleteItem(itemIndex);
                                }}
                            >
                                <span className="material-icons">delete</span>
                            </DeleteContainer>
                        )}
                    </ItemOptionsContainer>
                );
            }
        }
    ];
};
const ItemImgContainer = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;

    .ant-image-mask-info {
        font-size: 0;
        display: flex;
        justify-content: center;
        align-items: center;
    }

    .ant-image-mask-info .anticon {
        font-size: 14px;
        margin-inline-end: 0;
    }
`;
const DeleteContainer = styled.div`
    display: flex;
    color: ${(props) => props.theme.colors.primaryBlue};
    span.material-icons {
        cursor: pointer;
    }
`;
const VerticalDivider = styled.div`
    height: 100%;
    width: 1px;
    background: rgb(0 0 0 / 14%);
`;

const LinkButton = styled.div`
    color: #1890ff;
    text-decoration: none;
    background-color: transparent;
    outline: none;
    cursor: pointer;
    transition: color 0.3s;

    &:hover {
        color: #40a9ff;
    }
`;
const InfoIcon = styled.img`
    width: 20px;
    height: 20px;
`;
const InfoIconWrapper = styled.div`
    display: flex;
    position: relative;
    width: 25px;
    justify-content: center;
`;

const DetailsWrapper = styled.div`
    width: auto;
`;
const Detail = styled.div`
    display: flex;
    flex-wrap: wrap;
    margin: 10px 0;
`;
const DetailName = styled.div`
    color: #394372;
    font-weight: bold;
    margin-right: 5px;
`;

const CostCell = styled.div`
    width: 100%;
    display: flex;

    .edit-icon {
        display: none;
    }

    &:hover {
        .edit-icon {
            display: initial;
        }
    }
`;

const DetailValue = styled.div`
    color: #757575;
`;
const GridCell = styled.div`
    width: 100%;
    display: block;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
`;

const ItemOptionsContainer = styled.div`
    display: flex;
    width: 100%;
    height: 40%;
    justify-content: space-around;
    align-items: center;
`;

const EditableCell = styled.div`
    width: 100%;
    display: flex;

    .edit-icon {
        display: none;
    }

    &:hover {
        .edit-icon {
            display: initial;
        }
    }
`;
