import { CircularProgress } from '@material-ui/core';
import React from 'react';
import { connect } from 'react-redux';
import { loadingNotifyStateType } from '../../../../state/ducks/loading_notify/loading_notify';
import { loadingNotifySelectors } from '../../../../state/ducks/loading_notify/selectors';
import { localizationSelectors } from '../../../../state/ducks/localization/selectors';
import { RootState } from '../../../../state/store/store';
import Loader from '../../SmallComponents/Loader';
import { styled } from '../../Theme/theme';

type Props = {
    loadingNotify: loadingNotifyStateType;
    localization: any;
};

const LayoutLoadingNotify = ({ loadingNotify, localization }: Props) => {
    return loadingNotify.visible ? (
        <Wrapper>
            <CircularProgress size={30} color="inherit" />
            <span className="text">{loadingNotify.text || `${localization.loaderText}...`}</span>
        </Wrapper>
    ) : null;
};
const Wrapper = styled.div`
    position: fixed;
    bottom: 10px;
    right: 15px;
    padding: 5px;
    background: black;
    min-width: 250px;
    text-align: center;
    border-radius: 5px;
    color: white;
    display: flex;
    flex-direction: column;
    align-items: center;
    .text {
        margin-left: 10px;
        margin-top: 5px;
    }
    z-index: 1003;
`;
const mapStateToProps = (state: RootState) => ({
    loadingNotify: loadingNotifySelectors.loadingNotify(state),
    localization: localizationSelectors.common(state)
});

const mapDispatchToProps = (dispatch: any) => ({});

export default connect(mapStateToProps, mapDispatchToProps)(LayoutLoadingNotify);
