import React from 'react';
import { styled } from '../Theme/theme';
import tooltip from '../../../static/icons/tooltip.svg';
import { Tooltip } from 'antd';
import { TooltipPlacement } from 'antd/lib/tooltip';

type Props = {
    text?: string | JSX.Element[];
    children?: JSX.Element;
    placement?: TooltipPlacement;
    hidden?: boolean;
    zIndex?: number;
};
const CZTooltip = ({ text, children, placement, hidden, zIndex }: Props) => {
    if (!text) return <SpaceKeeper />;
    return (
        <Tooltip
            overlayClassName="custom-ant-tooltip-design"
            placement={placement}
            title={text}
            overlayStyle={hidden ? { display: 'none' } : undefined}
            zIndex={zIndex}
        >
            {children}
        </Tooltip>
    );
};
const SpaceKeeper = styled.div`
    height: 25px;
    width: 25px;
`;

export default CZTooltip;

const QuestionMarkIcon = styled.img`
    width: 25px;
    height: 25px;
`;
const Wrapper = styled.div`
    display: flex;
    position: relative;
    width: 25px;
    justify-content: center;
`;

export const QuestionToolTip = (
    <Wrapper>
        <QuestionMarkIcon src={tooltip} />
    </Wrapper>
);
