import React from 'react';
import { connect } from 'react-redux';
import { Dispatch, RootState } from '../../../../state/store/store';
import { localizationSelectors } from '../../../../state/ducks/localization/selectors';
import { businessPartnersSelectors } from '../../../../state/ducks/businessPartner/selectors';
import BidForm, { eBidShipmentTypes } from './ShipmentBidForm';
import { businessPartner } from '../../../../models/entities/businessPartner/businessPartner';
import { userSettings } from '../../../../models/entities/user';
import CustomModal, { ModalTriggerProps } from '../../../shared/Modal/Modal';
import { bid } from '../../../../models/entities/bid/bid';
import Button from '../../../shared/SmallComponents/Button';
import theme, { styled } from '../../../shared/Theme/theme';
import { userSelectors } from '../../../../state/ducks/user/selectors';
import { shipment } from '../../../../models/entities/shipment/shipment';

export type BaseProps = {
    shipment?: shipment;
    localizations?: any;
    businessPartners: Array<businessPartner>;
    onPublishCallBack?: (bid: bid, publish: boolean) => Promise<void>;
    openedFromModal?: {
        setVisible: React.Dispatch<React.SetStateAction<boolean>>;
    };
    bid?: bid;
    disabled?: boolean;
    reSendEmail: (bidId: string, partyId: string, shipment: shipment) => void;
    updateBidOverview: (bidId: string, bid: bid, shipmentId: string) => Promise<boolean>;
    bidTemplateNote?: userSettings;
    hideSaveAsDraft?: boolean;
};
type Props = BaseProps;
export const eBidFormMode = {
    NEW: 'new',
    EDIT: 'edit',
    DUPLICATE: 'duplicate',
    VIEW: 'view'
};
const Comp = ({
    localizations,
    businessPartners,
    onPublishCallBack,
    openedFromModal,
    bid,
    disabled,
    reSendEmail,
    updateBidOverview,
    bidTemplateNote,
    hideSaveAsDraft,
    shipment
}: Props) => {
    let mode: string = eBidFormMode.NEW;
    if (bid) mode = eBidFormMode.EDIT;
    if (disabled) mode = eBidFormMode.VIEW;
    return (
        <BidForm
            shipment={shipment}
            mode={mode}
            localizations={localizations.form}
            businessPartners={businessPartners}
            onPublishCallBack={onPublishCallBack}
            openedFromModal={openedFromModal}
            bid={bid}
            reSendEmail={reSendEmail}
            updateBidOverview={updateBidOverview}
            bidTemplateNote={bidTemplateNote}
            hideSaveAsDraft={hideSaveAsDraft}
        />
    );
};

const mapStateToProps = (state: RootState) => ({
    localizations: localizationSelectors.singleShipmentPage(state).bid,
    businessPartners: businessPartnersSelectors.businessPartnerForwarders(state),
    bidTemplateNote: userSelectors.bidTemplateNote(state)
});

const mapDispatchToProps = (dispatch: any) => ({
    reSendEmail: (bidId: string, partyId: string, shipment: shipment) => dispatch.bids.reSendEmail({ bidId, partyId, shipment }),
    updateBidOverview: (bidId: string, bid: bid, shipmentId: string) => dispatch.bids.updateBidOverview({ id: bidId, bid, shipmentId })
});

const ShipmentBidFormConnected = connect(mapStateToProps, mapDispatchToProps)(Comp);

export default ShipmentBidFormConnected;

type ModalProps = {
    shipment?: shipment;
    bid?: bid;
    onPublishCallBack?: (bid: bid, publish: boolean) => Promise<void>;
    Trigger?: (props: ModalTriggerProps) => JSX.Element;
    disabled?: boolean;
    btnDisabled?: boolean;
    onCancelCallback?: () => void;
    isOpen?: boolean;
    hideSaveAsDraft?: boolean;
};
export const BidFormModal = ({
    shipment,
    onPublishCallBack,
    bid,
    Trigger,
    disabled,
    btnDisabled,
    onCancelCallback,
    isOpen,
    hideSaveAsDraft
}: ModalProps) => {
    return (
        <CustomModal
            title={{ text: 'Add bid for this shipment' }}
            isOpen={isOpen}
            Trigger={
                Trigger
                    ? Trigger
                    : (props: ModalTriggerProps) =>
                          !bid ? (
                              <Button disabled={btnDisabled} type="button" onMouseDown={props.onClick} margin="5px">
                                  <span>Add Bid</span>
                              </Button>
                          ) : (
                              <ExistBidContainer>
                                  <BidInfo>
                                      <Header>
                                          <span>Bid {`#${bid.CargoZoneNumber ?? '0000000'}`}</span>
                                          <Button type="button" onMouseDown={props.onClick} height="15px" padding="13px">
                                              <span style={{ fontSize: '15px', marginRight: '5px' }} className="material-icons">
                                                  edit
                                              </span>
                                              <span>Edit</span>
                                          </Button>
                                      </Header>
                                      <Footer>
                                          {bid.shipmentTypes.map(
                                              (item, index) => `${eBidShipmentTypes[item]} ${index !== bid.shipmentTypes.length - 1 ? ',' : ''}`
                                          )}{' '}
                                          | {`${bid.parties?.length} Forwarders`}
                                      </Footer>
                                  </BidInfo>

                                  <Message>
                                      <span style={{ fontSize: 'inherit', marginRight: '4px' }} className="material-icons">
                                          info
                                      </span>
                                      <span>This bid will be sent to forworders once the shipment's setup is complete</span>
                                  </Message>
                              </ExistBidContainer>
                          )
            }
            Content={(setVisible?: React.Dispatch<React.SetStateAction<boolean>>, setConfirm?: React.Dispatch<React.SetStateAction<boolean>>) => {
                return (
                    <ShipmentBidFormConnected
                        shipment={shipment}
                        onPublishCallBack={onPublishCallBack}
                        openedFromModal={setVisible ? { setVisible } : undefined}
                        bid={bid}
                        disabled={disabled}
                        hideSaveAsDraft={hideSaveAsDraft}
                    />
                );
            }}
            width="700px"
            onCancelCallback={onCancelCallback}
        />
    );
};

const ExistBidContainer = styled.div`
    width: 100%;
    max-width: 350px;
`;
const BidInfo = styled.div`
    padding: 7px 10px;
    border: 1px solid ${theme.colors.primaryBlue};
    border-radius: 12px;
    min-height: 80px;
    margin-bottom: 10px;
`;
const Message = styled.div`
    background-color: #ffeca1;
    color: ${theme.colors.pageTitle};
    font-size: 14px;
    padding: 11px;
    border-radius: 4px;
    display: flex;
`;
const Header = styled.div`
    display: flex;
    justify-content: space-between;
    padding: 4px;
`;
const Footer = styled.div`
    color: #7a7a7a;
    font-size: 14px;
`;
