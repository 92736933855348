import React, { PropsWithChildren, useCallback, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import CssBaseline from '@material-ui/core/CssBaseline';
import Drawer from '@material-ui/core/Drawer';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import { makeStyles } from '@material-ui/core/styles';
import emptyState from '../../../static/images/emptyState.png';
import useWindowSize from '../../../hooks/windowSize';
import { useLocation } from 'react-router-dom';
import { PagesRoutes } from '../../../routing/PagesRoutes';

const minDrawerWidth = 392;
const maxDrawerWidth = 710;

export type DrawerTriggerProps = {
    onClick: () => void;
};
export type desktopVariantType = 'permanent' | 'persistent' | 'temporary';
type Props = {
    isOpen: boolean;
    setIsOpen: () => void;
    drawerContent: JSX.Element;
    isSideBarOpen: boolean;
    setSideBarOpen: (open: boolean) => void;
    isEmptyState?: boolean;
    printMode?: boolean;
    hasChat?: boolean;
    desktopVariant?: desktopVariantType;
    defaultDrawerWidth: number;
};

function ResponsiveDrawer({
    isOpen,
    setIsOpen,
    drawerContent,
    isSideBarOpen,
    setSideBarOpen,
    isEmptyState,
    printMode,
    children,
    hasChat,
    desktopVariant,
    defaultDrawerWidth
}: PropsWithChildren<Props>) {
    const [windowWidth] = useWindowSize();
    const [drawerWidth, setDrawerWidth] = useState(defaultDrawerWidth);

    const location = useLocation();
    const [animatedContent, setAnimatedContent] = useState(true);

    const pageOffsetRight = hasChat && !printMode && !location.pathname.includes(PagesRoutes.Messages) && windowWidth >= 768 ? 70 : 0;

    // const styleProps = { drawerWidth: drawerWidth };
    const useStyles = makeStyles((theme) => ({
        dragger: {
            width: '5px',
            cursor: 'ew-resize',
            padding: '4px 0 0',
            borderTop: '1px solid #ddd',
            position: 'absolute',
            top: 0,
            left: 0,
            bottom: 0,
            zIndex: 100,
            backgroundColor: 'transparent',
            userSelect: 'none'
        },
        root: {
            [theme.breakpoints.up('sm')]: {
                display: 'flex'
            },
            zIndex: 0,
            paddingRight: pageOffsetRight
        },
        drawerModal: {
            zIndex: '1001!important' as any
        },
        drawer: {
            width: defaultDrawerWidth,
            flexShrink: 0
        },
        toolbar: {
            height: printMode ? 0 : '64px'
        },
        drawerPaper: {
            width: defaultDrawerWidth,
            zIndex: 1,
            marginTop: '64px',
            right: pageOffsetRight,
            height: 'calc(100% - 64px)',
            [theme.breakpoints.down('xs')]: {
                width: '100%'
            }
        },
        animatedContent: {
            flexGrow: 1,
            [theme.breakpoints.up('lg')]: {
                transition: theme.transitions.create('margin', {
                    easing: theme.transitions.easing.sharp,
                    duration: theme.transitions.duration.leavingScreen
                }),
                marginRight: pageOffsetRight
            }
        },
        content: {
            flexGrow: 1,
            [theme.breakpoints.up('lg')]: {
                marginRight: pageOffsetRight
            }
        },
        closeMenuButton: {
            marginRight: 'auto',
            marginLeft: 0
        },
        closeMenuContainer: {
            position: 'absolute',
            backgroundColor: 'transparent',
            top: 0,
            zIndex: 2
        },
        animatedContentShift: {
            [theme.breakpoints.up('lg')]: {
                transition: theme.transitions.create('margin', {
                    easing: theme.transitions.easing.easeOut,
                    duration: theme.transitions.duration.enteringScreen
                }),
                marginRight: defaultDrawerWidth + pageOffsetRight
            }
        },
        contentShift: {
            [theme.breakpoints.up('lg')]: {
                marginRight: defaultDrawerWidth + pageOffsetRight
            }
        }
    }));
    const classes = useStyles();

    useEffect(() => {
        setAnimatedContent(location.pathname.indexOf('/shipments/') === -1);
        if (windowWidth < 1280 && isOpen && isSideBarOpen) {
            setSideBarOpen(false);
        }
        setDrawerWidth(defaultDrawerWidth);
    }, [isOpen, windowWidth, setSideBarOpen, isSideBarOpen, location, defaultDrawerWidth]);

    const handleMouseDown = (e: any) => {
        document.addEventListener('mouseup', handleMouseUp, true);
        document.addEventListener('mousemove', handleMouseMove, true);
    };

    const handleMouseUp = () => {
        document.removeEventListener('mouseup', handleMouseUp, true);
        document.removeEventListener('mousemove', handleMouseMove, true);
    };

    const handleMouseMove = useCallback(
        (e) => {
            const newWidth = window.innerWidth - e.clientX;

            if (newWidth > minDrawerWidth && newWidth < maxDrawerWidth) {
                setDrawerWidth(newWidth - pageOffsetRight);
            }
        },
        [hasChat]
    );
    function handleDrawerToggle() {
        setIsOpen();
    }
    const drawer = (
        <div style={{ height: '100%' }}>
            <div className={classes.closeMenuContainer}>
                <IconButton onClick={handleDrawerToggle} className={classes.closeMenuButton}>
                    <CloseIcon />
                </IconButton>
            </div>
            <div onMouseDown={(e) => handleMouseDown(e)} className={classes.dragger} />
            <div style={{ height: '100%' }}>{drawerContent}</div>
            {isEmptyState && <img src={emptyState} alt="" style={{ position: 'fixed', right: -48, bottom: -74 }} height="250px" />}
        </div>
    );
    const notTemporary = windowWidth >= 1280 && desktopVariant !== 'temporary';
    return (
        <div className={classes.root} style={{ height: '100%' }}>
            <CssBaseline />

            <nav>
                {notTemporary ? (
                    <Drawer
                        variant={desktopVariant}
                        anchor="right"
                        open={isOpen}
                        onClose={handleDrawerToggle}
                        BackdropProps={{ invisible: true }}
                        classes={{
                            paper: classes.drawerPaper
                        }}
                        ModalProps={{
                            keepMounted: true // Better open performance on mobile.
                        }}
                        PaperProps={{ style: { width: drawerWidth, overflow: isEmptyState ? 'hidden' : 'auto' } }}
                    >
                        {drawer}
                    </Drawer>
                ) : (
                    <Drawer
                        className={classes.drawer}
                        variant="temporary"
                        disableEnforceFocus
                        anchor="right"
                        onClose={handleDrawerToggle}
                        open={isOpen}
                        classes={{
                            paper: classes.drawerPaper,
                            modal: classes.drawerModal
                        }}
                        BackdropProps={{ invisible: true }}
                        PaperProps={{ style: { width: windowWidth >= 768 ? drawerWidth : '100%', overflow: isEmptyState ? 'hidden' : 'auto' } }}
                    >
                        {drawer}
                    </Drawer>
                )}
            </nav>
            <div
                style={{
                    width: notTemporary ? `${windowWidth - drawerWidth - pageOffsetRight}px` : `${windowWidth - pageOffsetRight}px`,
                    marginRight: isOpen && notTemporary ? `${drawerWidth}px` : '0px',
                    height: '100%'
                }}
                className={clsx(animatedContent ? classes.animatedContent : classes.content, {
                    [animatedContent ? classes.animatedContentShift : classes.contentShift]: isOpen
                })}
            >
                <div className={classes.toolbar} />
                {children}
            </div>
        </div>
    );
}
ResponsiveDrawer.defaultProps = {
    desktopVariant: 'persistent',
    defaultDrawerWidth: 392
};
export default ResponsiveDrawer;
