import React, { FunctionComponent, useState } from 'react';
import styled from '@emotion/styled';
import type { DraggableProvided } from 'react-beautiful-dnd';
import { IBoardItem } from '../types/BoardColumn';
import { ItemContentProps } from './VirtualizedBoard';

export type BoardItemProps<T extends IBoardItem> = {
    item: T;
    isDragging: boolean;
    provided: DraggableProvided;
    isGroupedOver?: boolean;
    style?: Object;
    index?: number;
    ItemContent: FunctionComponent<ItemContentProps<T>>;
    measure?: () => void;
    registerChild?: (element?: Element | undefined) => void;
};

function BoardItem<T extends IBoardItem>(props: BoardItemProps<T>) {
    const { item, isDragging, isGroupedOver, provided, style, index, measure, ItemContent, registerChild } = props;

    const [isItemOpen, setItemOpen] = useState<boolean>(false);

    const toggleItemOpen = () => {
        setItemOpen(!isItemOpen);
        setTimeout(() => {
            measure?.();
        }, 10);
    };

    return (
        <Container
            isDragging={isDragging}
            isGroupedOver={isGroupedOver}
            ref={(ref) => {
                provided.innerRef(ref);
                registerChild?.(ref as Element);
            }}
            {...provided.draggableProps}
            {...provided.dragHandleProps}
            style={getStyle(provided, style)}
            data-is-dragging={isDragging}
            data-testid={item.id}
            data-index={index}
            aria-label={`${item.id}`}
        >
            <ItemContent item={item} measure={measure} isItemOpen={isItemOpen} toggleItemOpen={toggleItemOpen} />

            {/* Items spacing hack */}
            <div style={{ height: 8, flex: '0 0 8px' }}></div>
        </Container>
    );
}

const Container = styled.div<{ isDragging: boolean; isGroupedOver?: boolean }>`
    display: flex;
    flex-direction: column;
    box-shadow: 0 0 10px rgb(228, 228, 228);
    border-color: ${(props) => getBorderColor(props.isDragging)};
    background-color: ${(props) => getBackgroundColor(props.isDragging, props?.isGroupedOver)};
    box-shadow: ${({ isDragging }) => (isDragging ? `2px 2px 1px #aa1a1` : 'none')};
    box-sizing: border-box;
    margin: 0 0 8px 0;
    user-select: none;
    color: ${'green'};
    &:hover,
    &:active {
        color: ${'green'};
        text-decoration: none;
    }
    &:focus {
        outline: none;
        border-color: '#3e3e3e';
        box-shadow: none;
    }
    display: flex;
`;

const getBackgroundColor = (isDragging: boolean, isGroupedOver?: boolean) => {
    if (isDragging) {
        return '#f1f1f1';
    }

    if (isGroupedOver) {
        return '#a1a1a1';
    }

    return '#fff';
};

const getBorderColor = (isDragging: boolean) => (isDragging ? '#e4e4e4' : '#e4e4e4');

const getStyle = (provided: DraggableProvided, style?: Object): any => {
    if (!style) {
        return provided.draggableProps.style;
    }

    return {
        ...provided.draggableProps.style,
        ...style
    };
};

export default React.memo(BoardItem) as typeof BoardItem;
