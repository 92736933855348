import React, { useEffect, useState } from 'react';
import moment from 'moment';
import styled, { css } from 'styled-components';
import { scroller, Events } from 'react-scroll';
import { chat, message, chatType, messageAttachment, editMessagePayload, createChatPayload } from '../../../../models/entities/chat/chat';
import FileDownload from '../../../shared/SmallComponents/FileDownload';
import FileExtIcon from '../../../shared/Grid/specialColumns/FileExtIcon';
import Loader from '../../../shared/SmallComponents/Loader';
import unread from '../../../../static/icons/unread.svg';
import read from '../../../../static/icons/read.svg';
import editIcon from '../../../../static/icons/edit.svg';
import trashIcon from '../../../../static/icons/trash.svg';
import replyIcon from '../../../../static/icons/reply.svg';
import clockIcon from '../../../../static/icons/clock.svg';
import { parseHtmlToJsx } from '../../../../utils/textEditorUtils';
import { userInfo } from '../../../../models/entities/user';
import { userDetails } from '../../../../state/ducks/users/users';
import { shipment } from '../../../../models/entities/shipment/shipment';
import { ColumnType } from '../../../shared/Grid/types/Column';
import MenuItem from '../../../shared/Menu/MenuItem';
import Menu, { TriggerProps } from '../../../shared/Menu/Menu';
import { Modal } from 'antd';
import HexagonAvatar from '../../../shared/SmallComponents/HexagonAvatar';
import { UserImageAvatar } from '../../../shared/Layout/Header/AvatarHeader';
import UserBadge from '../../../shared/UserBadge/UserBadge';
import parse, { domToReact } from 'html-react-parser';
import CustomModal, { ModalTriggerProps } from '../../../shared/Modal/Modal';
import AddTaskForm from '../../Dashboard/Tasks/AddTaskForm';
import { task } from '../../../../models/entities/task';
import { rmHtmlTags } from '../../../../utils/patterns';
import PreviewFileAsync from '../../../shared/FilesPreview/Preview';

type Props = {
    chatType: chatType;
    chatId: string;
    message: message;
    userInfo: userInfo;
    isFirstInGroup: boolean;
    isLastInGroup: boolean;
    isEditMessage: message | null;
    highlightedMessageId?: string;
    topMessageRef?: React.MutableRefObject<any>;
    getShipmentById: (id: string) => shipment | undefined;
    getUserById: (userId: string) => userDetails | undefined;
    gridColumns: Array<ColumnType<shipment>>;
    onMenuEditClick: (e: any, m: message) => void;
    onMenuDeleteClick: (e: any, m: message) => void;
    setReplyMessage: (message: message) => void;
    highlightMessage: (id: string) => void;
    getMessageById: (chatId: string, messageId: string) => message | undefined;
    localization: any;
};
const Message = ({
    chatType,
    chatId,
    message,
    isFirstInGroup,
    isLastInGroup,
    getShipmentById,
    getUserById,
    gridColumns,
    getMessageById,
    isEditMessage,
    userInfo,
    highlightedMessageId,
    topMessageRef,
    onMenuEditClick,
    onMenuDeleteClick,
    setReplyMessage,
    highlightMessage,
    localization
}: Props) => {
    /** chat debug
    useEffect(() => {
        console.log('render message');
    });
*/

    const [isModalOpened, setIsModalOpened] = useState(false);
    const contentClassName = isFirstInGroup && isLastInGroup ? 'single' : isFirstInGroup ? 'first' : isLastInGroup ? 'last' : '';
    const isCurrentUserMessage = userInfo.id === message.userId;
    const user = getUserById(message.userId);

    const isEditing = !!isEditMessage && message.id === isEditMessage.id;

    const reply: any = message.replyMessageId && getMessageById(chatId, message.replyMessageId);

    const isHighlighted = message.id === highlightedMessageId;

    let menuItems: JSX.Element[] = [];

    if (!isEditing) {
        menuItems.push(
            <MenuItem
                onClick={(e) => {
                    e.stopPropagation();
                    setReplyMessage(message);
                }}
                icon={replyIcon}
                text={localization.reply}
            />,
            <CustomModal
                title={{ text: localization.set_reminder }}
                Trigger={(props: ModalTriggerProps) => (
                    <MenuItem
                        onClick={(e) => {
                            e.stopPropagation();
                            setIsModalOpened(true);
                            props.onClick();
                        }}
                        icon={clockIcon}
                        text={localization.set_reminder}
                    />
                )}
                onCancelCallback={() => {
                    setIsModalOpened(false);
                }}
                Content={(setVisible?: React.Dispatch<React.SetStateAction<boolean>>, setConfirm?: React.Dispatch<React.SetStateAction<boolean>>) => {
                    return (
                        <AddTaskForm
                            openedFromModal={setVisible ? { setVisible } : undefined}
                            confirmation={setConfirm ? { setConfirm } : undefined}
                            defaultValues={
                                {
                                    content: `${localization.reminder_about_message} "${message.content.replace(rmHtmlTags, '')}".
Received from ${message.userName}, ${moment(message.sendDate).format('DD/MM/YYYY HH:mm')}`
                                } as task
                            }
                        />
                    );
                }}
                width="393px"
            />
        );
    }
    if (isCurrentUserMessage) {
        menuItems = menuItems.concat([
            <MenuItem onClick={(e) => onMenuEditClick(e, message)} icon={editIcon} text={localization.edit} />,
            <MenuItem onClick={(e) => onMenuDeleteClick(e, message)} icon={trashIcon} text={localization.delete} />
        ]);
    }

    const MessageActionsMenu = (isFirst: boolean) => {
        return (
            <Menu
                position={isFirst ? 'bottom-center' : 'top-center'}
                arrowOnTrigger={false}
                options={menuItems}
                isModalOpened={isModalOpened}
                MenuTrigger={(props: TriggerProps) => (
                    <span className="material-icons" onClick={props.onClick}>
                        more_vert
                    </span>
                )}
                menuWidth={160}
            />
        );
    };

    return (
        <MessageContainer
            id={`message-container-${message.id}`}
            isCurrentUserMessage={isCurrentUserMessage}
            chatType={chatType}
            isFirst={isFirstInGroup}
            isEditing={isEditing}
            isHighlighted={isHighlighted}
            ref={topMessageRef}
        >
            {!!menuItems.length && (
                <MessageActionButtonsContainer className="message-action-buttons">{MessageActionsMenu(isFirstInGroup)}</MessageActionButtonsContainer>
            )}

            <MessageWrapper>
                {isFirstInGroup && !isCurrentUserMessage && chatType !== 'user' && user && (
                    <UserBadge userId={user.id} width={40} popoverLeft toggleOnClick hideBadgeName />
                )}
                <MessageContent
                    isLast={isLastInGroup}
                    isFirst={isFirstInGroup}
                    className={contentClassName}
                    isCurrentUserMessage={isCurrentUserMessage}
                    chatType={chatType}
                >
                    {!!user && isFirstInGroup && chatType === 'group' && !isCurrentUserMessage && (
                        <div style={{ fontSize: '10px', color: user.avatarColor }}>{user.name}</div>
                    )}
                    {reply && (
                        <ReplyMessageContainer
                            onClick={() => {
                                scroller.scrollTo(`message-container-${reply.id}`, {
                                    duration: 500,
                                    smooth: true,
                                    containerId: `chat-content-${chatId}`,
                                    offset: -100
                                });
                                highlightMessage(reply.id);
                            }}
                        >
                            <div className="reply-username">{reply.userName}</div>
                            <div className="reply-content">{parse(reply.content)}</div>
                        </ReplyMessageContainer>
                    )}
                    <MessageText isRtl={new RegExp('^[\\u0591-\\u07FF\\uFB1D-\\uFDFD\\uFE70-\\uFEFC]', 'g').test(message.content)}>
                        {parseHtmlToJsx(message.content || '', true)}
                    </MessageText>

                    <div className="metadata">
                        {message.isEdited && <span className="edited">(edited)</span>}
                        <span className="time">{moment(message.sendDate).format('HH:mm')}</span>
                        {isCurrentUserMessage && <img src={message.isRead ? read : unread} alt="" width={18} />}
                    </div>
                    {message.attachments && (
                        <AttachmentsContainer>
                            {!!message.attachments?.length &&
                                message.attachments.map((att, i) =>
                                    att.fileId ? (
                                        // <FileDownload fileId={att.fileId} fileName={att.name} key={i}>
                                        //     <AttachmentContainer>
                                        //         <FileExtIcon filename={att.name} />
                                        //     </AttachmentContainer>
                                        // </FileDownload>
                                        <PreviewFileAsync key={i} fileId={att.fileId} fileName={att.name} fileUrl={att.fileUrl} />
                                    ) : (
                                        <AttachmentContainer key={i}>
                                            <FileExtIcon filename={att.name} />
                                            <Loader showText={false} width="15px" marginTop="0" wrapperWidth="30px"></Loader>
                                        </AttachmentContainer>
                                    )
                                )}
                        </AttachmentsContainer>
                    )}
                </MessageContent>
            </MessageWrapper>
        </MessageContainer>
    );
};

interface MessageProps {
    isCurrentUserMessage: boolean;
    isLast?: boolean;
    isFirst?: boolean;
    isEditing?: boolean;
    isHighlighted?: boolean;
    chatType?: chatType;
}

const MessageContainer = styled.div<MessageProps>`
    padding: ${(props) => (props.isFirst ? '8px 16px 4px 16px' : '4px 16px 4px 16px')};

    .metadata {
        font-size: 11px;
        padding-top: 5px;
        float: right;
        margin-left: 8px;
        color: rgba(0, 0, 0, 0.45);
        display: flex;
        align-items: center;

        .edited {
            margin-right: 5px;
        }
    }
    align-self: flex-end;
    display: flex;
    flex-direction: ${(props) => (props.isCurrentUserMessage ? 'row' : 'row-reverse')};
    justify-content: flex-end;
    align-items: center;
    width: 100%;
    transition: background-color 0.3s;
    ${(props) =>
        props.isEditing || props.isHighlighted
            ? css`
                  background-color: #fff06880;
              `
            : css`
                  background-color: transparent;
              `}
    &:hover {
        .message-action-buttons {
            visibility: visible;
        }
    }
`;

const ReplyMessageContainer = styled.div`
    height: 50px;
    padding: 5px 10px;
    background-color: #f7f7f7;
    margin-bottom: 7px;
    margin-top: 5px;
    border-radius: 5px;
    box-shadow: 0 1px 3px 0 rgb(0 0 0 / 10%), 0 1px 2px 0 rgb(0 0 0 / 6%);
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    cursor: pointer;

    &:hover {
        background-color: #f1f1f1;
    }
    .reply-username {
        color: black;
        font-size: 12px;
    }

    .reply-content {
        color: #7f7f7f;
        font-size: 12px;
        font-style: italic;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        max-width: 200px;
        margin: 0;

        p {
            text-overflow: ellipsis;
            overflow: hidden;
            margin: 0;
        }
    }
`;

interface MessageTextProps {
    isRtl: boolean;
}

const MessageText = styled.div<MessageTextProps>`
    direction: ${(props) => (props.isRtl ? 'rtl' : 'ltr')};
    white-space: pre-line;
    display: inline-block;

    p {
        margin-bottom: 0;
    }
`;
const AttachmentsContainer = styled.div`
    margin-top: 5px;
`;
const AttachmentContainer = styled.div`
    padding: 8px;
    background-color: #e1e1e145;
    margin: 1px 0px;
    display: flex;
    align-items: center;
`;

const MessageContent = styled.div<MessageProps>`
    border-radius: 5px;
    padding: 5px 10px;
    width: fit-content;
    max-width: 100%;
    background-color: ${(props) => (props.isCurrentUserMessage ? '#c7f0ff' : '#ffffff')};
    box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.1), 0 1px 2px 0 rgba(0, 0, 0, 0.06);
    margin-left: ${(props) => (props.isCurrentUserMessage || props.chatType === 'user' ? '0' : props.isFirst ? '16px' : '56px')};
    overflow-wrap: anywhere;
`;
const MessageWrapper = styled.div`
    display: flex;
    width: fit-content;
    max-width: 85%;
`;

const MessageActionButtonsContainer = styled.span`
    display: flex;
    visibility: hidden;
    align-items: center;
    justify-content: center;
    .material-icons {
        cursor: pointer;

        color: #787878;
    }
`;
export default React.memo(Message);
