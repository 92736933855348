import React from 'react';
import { connect } from 'react-redux';
import styled from 'styled-components';
import { filterFieldsType } from '../../../../../state/ducks/allBusinessPartnersPage/allBusinessPartnersPage';
import { RootState } from '../../../../../state/store/store';
import { allPurchaseOrdersPageSelectors } from '../../../../../state/ducks/allPurchaseOrdersPage/selectors';
import { parseFilterFields, removeFilterItem, parsedFilterFieldType } from '../../utils';
import { purchaseOrdersSelectors } from '../../../../../state/ducks/purchaseOrders/selectors';
import { settings } from '../../../../../models/entities/purchaseOrder/purchaseOrder';

type Props = {
    filterFields: filterFieldsType | null;
    setPurchaseOrderFilters: (filter: filterFieldsType) => void;
    localization: any;
    settings?: settings;
};

const FiltersStripe = ({ filterFields, setPurchaseOrderFilters, localization, settings }: Props) => {
    const renderFiltersStrip = () => {
        return parseFilterFields(filterFields || [], localization, settings)?.map((filter: parsedFilterFieldType, i) => (
            <FilterBadge key={i}>
                {filter.parsedValue}
                <FilterRemove
                    className="material-icons"
                    onClick={() => {
                        setPurchaseOrderFilters(removeFilterItem(filter, filterFields || []));
                    }}
                >
                    close
                </FilterRemove>
            </FilterBadge>
        ));
    };

    return <FiltersStripeContainer>{renderFiltersStrip()}</FiltersStripeContainer>;
};

const FiltersStripeContainer = styled.div`
    flex: 1;
    padding: 10px 0px;
    margin: 0 25px;
    font-size: 12px;
    display: flex;
    overflow-x: hidden;

    @media screen and (max-width: 768px) {
        display: none;
    }

    &:hover {
        overflow-x: overlay;
    }
    
     &::-webkit-scrollbar {
         height: 2px;

     }
     
     &::-webkit-scrollbar-track {
         background-color: white;
         border-radius: 10px;
     }
     
     &::-webkit-scrollbar-thumb {
         border-radius: 10px;
     }
     ​
`;
const FilterBadge = styled.span`
    border-radius: 2vh;
    border: 1px solid #008ac9;
    color: rgba(0, 0, 0, 0.65);
    font-size: 14px;
    height: 24px;
    padding: 3px 8px;
    margin-left: 10px;
    display: inline-flex;
    align-items: center;
    line-height: 1px;
    min-width: max-content;
`;

const FilterRemove = styled.span`
    font-size: 12px;
    color: #008ac9;
    margin-left: 5px;
    cursor: pointer;
`;

const mapStateToProps = (state: RootState) => ({
    filterFields: allPurchaseOrdersPageSelectors.filterFields(state),
    settings: purchaseOrdersSelectors.settingsByLanguage(state)
});

const mapDispatchToProps = (dispatch: any) => ({
    setPurchaseOrderFilters: (filter: filterFieldsType) => dispatch.allPurchaseOrdersPage.setPurchaseOrderFilters(filter)
});

export default connect(mapStateToProps, mapDispatchToProps)(FiltersStripe);
