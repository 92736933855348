import React from 'react';
import { PagesRoutes } from '../../../../routing/PagesRoutes';
import StyledLink from '../../SmallComponents/StyledLink';

type Props = {
    shipmentId: string;
    shipmentNumber: string;
};

const ShipmentLink = ({ shipmentId, shipmentNumber }: Props) => {
    return <StyledLink to={`${PagesRoutes.Shipments}/${shipmentId}`}>{shipmentNumber}</StyledLink>;
};

export default ShipmentLink;
