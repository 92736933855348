import { Formik } from 'formik';
import { Form } from 'formik-antd';
import { isEqual, pick } from 'lodash';
import React, { useState } from 'react';
import styled from 'styled-components';
import { company } from '../../../../../models/entities/company/company';
import { companyAdminUpdateUserPayload, userInfo } from '../../../../../models/entities/user';
import Button from '../../../../shared/SmallComponents/Button';
import AccordionCheckBoxsesField from './components/accordionCheckBoxsesField';
import { Modal } from 'antd';
import Loader from '../../../../shared/SmallComponents/Loader';
import ClaimsFormField from './components/claimsFormField';
import { eShipmentTypes } from '../../../../../models/entities/shipment/shipmentTypes';
import { companiesRelationsResponseType } from '../../../../../models/entities/company/companiesRelation';

type Props = {
    user: userInfo;
    companies: company[];
    relations: companiesRelationsResponseType;
    updateUserDetails: (details: companyAdminUpdateUserPayload, userId: string) => Promise<void>;
    resetUserPassword: (userId: string, email: string) => Promise<void>;
    deleteUser: (userId: string) => Promise<void>;
    restoreUser: (userId: string) => Promise<void>;
    localization: any;
};
const EditUserForm = ({ user, companies, updateUserDetails, localization, resetUserPassword, deleteUser, restoreUser, relations }: Props) => {
    const [formChanged, setFormChanged] = useState(false);
    const [isSubmit, setIsSubmit] = useState(false);

    const relatedCompaniesIds: string[] = relations.requested.map((relation) => relation.companyId);

    const relatedCompaniesIdsWithSubCompanies: { companyId: string; subCompaniesIds: string[] }[] = relations.requested.map((relation) => ({
        companyId: relation.companyId,
        subCompaniesIds: relation.subCompaniesIds
    }));

    const initialValues: any = {
        claims: user.claims,
        allowedCompanies: (user.allowedCompanies as Array<string>) || [],
        allowedShipmentTypes: user.allowedShipmentTypes || [],
        allowedRelatedCompanies: relatedCompaniesIds.filter(
            (id) => !(user.notAllowedRelatedCompanies || []).some((notAllowed) => notAllowed.companyId === id)
        )
    };

    const hasChanged = (values: any) => {
        const filteredClaimsValues: { [key: string]: any } = {};
        Object.entries(values.claims || {}).forEach(([key, value]) => (Number(value) ? (filteredClaimsValues[key] = value) : undefined));

        const filteredClaimsInitialValues: { [key: string]: any } = {};
        Object.entries(initialValues.claims || {}).forEach(([key, value]) =>
            Number(value) ? (filteredClaimsInitialValues[key] = value) : undefined
        );

        if (Object.keys(filteredClaimsInitialValues).length !== Object.keys(filteredClaimsValues).length) return true;

        if (!isEqual(initialValues.allowedCompanies, values.allowedCompanies)) return true;
        if (!isEqual(initialValues.allowedShipmentTypes, values.allowedShipmentTypes)) return true;
        if (!isEqual(initialValues.allowedRelatedCompanies, values.allowedRelatedCompanies)) return true;
        if (!isEqual(filteredClaimsInitialValues, filteredClaimsValues)) return true;

        return false;
    };

    const onSubmit = async (values: any) => {
        if (isSubmit || !formChanged) return;

        setIsSubmit(true);

        const payload: companyAdminUpdateUserPayload = {
            claims: values.claims,
            allowedCompanies: (values.allowedCompanies as Array<string>) || [],
            allowedShipmentTypes: values.allowedShipmentTypes || [],
            notAllowedRelatedCompanies: relatedCompaniesIdsWithSubCompanies.filter(
                (relatedIds) => !values.allowedRelatedCompanies.includes(relatedIds.companyId)
            )
        };
        await updateUserDetails(payload, user.id);
        setIsSubmit(false);
    };

    return (
        <Wrapper>
            <Formik enableReinitialize initialValues={initialValues} onSubmit={onSubmit}>
                {(formik) => {
                    const { isValid, values, setFieldValue } = formik;
                    setFormChanged(hasChanged(values));
                    return (
                        <>
                            <Form>
                                <HeaderWrapper>
                                    <div style={{ display: 'flex' }}>
                                        <Title>
                                            {user.firstName} {user.lastName}
                                        </Title>
                                        <Button
                                            margin="0 15px"
                                            padding="0"
                                            width="auto"
                                            minHeight="auto"
                                            height="27px"
                                            buttonType="clean"
                                            textColor={!user.deletedAt ? '#fb3232' : undefined}
                                            onClick={async () => {
                                                Modal.confirm({
                                                    zIndex: 1100,
                                                    content: <div>{localization.are_you_sure}</div>,
                                                    async onOk() {
                                                        if (!user.deletedAt) {
                                                            await deleteUser(user.id);
                                                        } else {
                                                            await restoreUser(user.id);
                                                        }
                                                    },
                                                    onCancel() {}
                                                });
                                            }}
                                            disabled={false}
                                        >
                                            {!user.deletedAt ? localization.delete : localization.restore}
                                        </Button>
                                    </div>
                                    <div style={{ display: 'flex' }}>
                                        <Button
                                            margin="0 15px 0 0"
                                            padding="0"
                                            width="auto"
                                            minHeight="auto"
                                            height="27px"
                                            buttonType="clean"
                                            onClick={async () => {
                                                await resetUserPassword(user.id, user.email);
                                            }}
                                            //disabled={user.shouldResetPassword}
                                        >
                                            {localization.reset_pass}
                                        </Button>
                                        <Button
                                            margin="0"
                                            padding="0"
                                            width="auto"
                                            minHeight="auto"
                                            height="27px"
                                            buttonType="clean"
                                            type="submit"
                                            disabled={!formChanged}
                                        >
                                            {isSubmit ? <Loader width="20px" marginTop="0px" showText={false} /> : localization.submit_btn}
                                        </Button>
                                    </div>
                                </HeaderWrapper>
                                <DetailsContainer>
                                    {Object.entries(
                                        pick(user, ['firstName', 'lastName', 'role', 'email', 'companyName', 'phoneNumber', 'birthDate'])
                                    ).map(([key, value], index) => {
                                        return (
                                            <DetailWrapper key={`${key}-user-detail-${index}`}>
                                                <DetailName>{localization.fields?.[key] || key}</DetailName>
                                                <DetailValue>{value}</DetailValue>
                                            </DetailWrapper>
                                        );
                                    })}
                                </DetailsContainer>
                                <AccordionWrapper>
                                    <AccordionWrapper>
                                        <AccordionCheckBoxsesField
                                            fieldName="allowedCompanies"
                                            title={localization.allowed_companies}
                                            options={companies.map((company) => ({
                                                label: company.name,
                                                value: company.id
                                            }))}
                                        />
                                        <AccordionCheckBoxsesField
                                            fieldName="allowedShipmentTypes"
                                            title={localization.allowed_shipment_types}
                                            options={Object.entries(eShipmentTypes).map(([typeCode, value]) => ({
                                                label: value,
                                                value: typeCode
                                            }))}
                                        />
                                        <AccordionCheckBoxsesField
                                            fieldName="allowedRelatedCompanies"
                                            title={localization.allowed_related_companies}
                                            options={relations.requested.map((relation) => ({
                                                label: relation.companyName,
                                                value: relation.companyId
                                            }))}
                                        />
                                    </AccordionWrapper>
                                </AccordionWrapper>
                                <AccordionWrapper>
                                    <PermissionsTitle>{localization.permissions}:</PermissionsTitle>
                                    <ClaimsFormField fieldName="claims" localization={localization.claims} />
                                </AccordionWrapper>
                            </Form>
                        </>
                    );
                }}
            </Formik>
        </Wrapper>
    );
};

const Wrapper = styled.div``;

const HeaderWrapper = styled.div`
    display: flex;
    height: auto;
    padding: 10px 5px;
    margin: 0 20px;
    border-bottom: 1px solid rgba(0, 0, 0, 0.125);
    justify-content: space-between;
`;

const AccordionWrapper = styled.div`
    margin-top: 20px;
`;

const PermissionsTitle = styled.div`
    font-size: 18px;
    font-weight: 500;
    padding: 10px 5px;
`;

const Title = styled.div`
    font-size: 18px;
    font-weight: 500;
`;

const DetailsContainer = styled.div`
    padding: 20px;
    background-color: #f7f7f700;
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(189px, 1fr));
    grid-gap: 1.7rem;
`;

const DetailWrapper = styled.div``;

const DetailName = styled.div`
    color: #008ac9;
`;

const DetailValue = styled.div`
    color: #394372;
`;

export default EditUserForm;
