import React from 'react';
import edit from '../../../../static/icons/edit.svg';
import trash from '../../../../static/icons/trash.svg';
import CellTrigger from '../../Menu/MenuTriggers/CellTrigger';
import { RootState } from '../../../../state/store/store';
import { connect } from 'react-redux';
import { businessPartner } from '../../../../models/entities/businessPartner/businessPartner';
import Menu from '../../Menu/Menu';
import MenuItem from '../../Menu/MenuItem';
import { singleBusinessPartnersSelectors } from '../../../../state/ducks/singleBusinessPartner/selectors';
type onRowMoreOptionClickType = () => void;
type deleteBusinessPartnerAddressType = (businessPartnerId: string, addressId: string) => void;
type Props = {
    entityId: string;
    localization: any;
    onRowMoreOptionsClick?: onRowMoreOptionClickType;
    deleteBusinessPartnerAddress: deleteBusinessPartnerAddressType;
    businessPartner?: businessPartner;
};

const BusinessPartnerAddressMenu = ({ entityId, businessPartner, localization, onRowMoreOptionsClick, deleteBusinessPartnerAddress }: Props) => {
    const menuItems = [
        <MenuItem onClick={() => onRowMoreOptionsClick && onRowMoreOptionsClick()} icon={edit} text={localization.edit} />,
        <MenuItem
            onClick={() => businessPartner && deleteBusinessPartnerAddress(businessPartner.id, entityId)}
            icon={trash}
            text={localization.delete}
        />
    ];

    return <Menu options={menuItems} MenuTrigger={CellTrigger} strStyle="display: flex;align-items: center;width: 100%;height: 100%;" />;
};

const mapStateToProps = (state: RootState) => ({
    businessPartner: singleBusinessPartnersSelectors.businessPartner(state)
});

const mapDispatchToProps = (dispatch: any) => ({
    deleteBusinessPartnerAddress: (businessPartnerId: string, addressId: string) =>
        dispatch.businessPartners.deleteBusinessPartnerAddress({ businessPartnerId, addressId })
});

export default connect(mapStateToProps, mapDispatchToProps)(BusinessPartnerAddressMenu);
