import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { featureFlagsModel } from '../../../../../models/entities/businessPartner/settings';
import { businessPartner } from '../../../../../models/entities/businessPartner/businessPartner';
import { localizationSelectors } from '../../../../../state/ducks/localization/selectors';
import { userSelectors } from '../../../../../state/ducks/user/selectors';
import { RootState } from '../../../../../state/store/store';
import Loader from '../../../../shared/SmallComponents/Loader';
import InventoryForm from './InventoryForm';
import { businessPartnersSelectors } from '../../../../../state/ducks/businessPartner/selectors';
import { allowedCompany } from '../../../../../models/entities/user';
import { inventoryLog } from '../../../../../models/entities/product/inventoryLog';
import { product } from '../../../../../models/entities/product/product';
import { productsSelectors } from '../../../../../state/ducks/products/selectors';
import { drawers } from '../../../../../state/ducks/header/header';

export type BaseProps = {
    inventory?: inventoryLog;
    localizations: any;
    createInventory: (inventory: inventoryLog) => Promise<string>;
    onClose?: (inventoryId?: string) => void;
    businessPartnerWarehouses: businessPartner[];
    featureFlags?: featureFlagsModel;
    initSupplierId?: string;
    allowedCompanies: allowedCompany[];
    mode: eFormMode;
    products: Array<product>;
    setDrawerOpen: (open: drawers, params?: any) => void;
};
export type eFormMode = 'NEW' | 'EDIT' | 'DUPLICATE';

type Props = BaseProps & {
    fetchProducts: () => void;
};

const InventoryFormConnected = ({
    localizations,
    inventory,
    createInventory,
    onClose,
    featureFlags,
    businessPartnerWarehouses,
    allowedCompanies,
    initSupplierId,
    mode,
    products,
    fetchProducts,
    setDrawerOpen
}: Props) => {
    useEffect(() => {
        fetchProducts();
    }, [fetchProducts]);
    return (
        <InventoryForm
            localizations={localizations.form}
            mode={mode}
            inventory={inventory}
            createInventory={createInventory}
            onClose={onClose}
            businessPartnerWarehouses={businessPartnerWarehouses}
            featureFlags={featureFlags}
            allowedCompanies={allowedCompanies}
            initSupplierId={initSupplierId}
            products={products}
            setDrawerOpen={setDrawerOpen}
        />
    );
};

const mapStateToProps = (state: RootState) => ({
    localizations: localizationSelectors.inventory(state),
    featureFlags: userSelectors.featureFlags(state),
    businessPartnerWarehouses: businessPartnersSelectors.businessPartnerWarehouses(state),
    allowedCompanies: userSelectors.allowedCompanies(state),
    products: productsSelectors.productsData(state)
});

const mapDispatchToProps = (dispatch: any) => ({
    createInventory: (inventory: inventoryLog) => dispatch.inventory.create(inventory),
    fetchProducts: () => dispatch.products.fetchProducts(),
    setDrawerOpen: (open: drawers, params?: any) => dispatch.header.setDrawerOpenParams({ open, params })
});

export default connect(mapStateToProps, mapDispatchToProps)(InventoryFormConnected);
