export type task = {
    id: string;
    priority: string;
    content: string;
    dueDate: Date;
    reminder: Date | null;
    status: string;
    createdAt: Date;
};

export const TaskPriorityEnum = {
    Low: 'LOW',
    Medium: 'MEDIUM',
    High: 'HIGH'
};

export const TaskStatusEnum = {
    Done: 'DONE',
    Pending: 'PENDING',
    New: 'NEW',
    Stuck: 'STUCK',
    OnWork: 'ON_WORK',
    Archived: 'ARCHIVED'
};
