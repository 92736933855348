import React from 'react';
import { styled } from '../../shared/Theme/theme';
import StyledLink from '../../shared/SmallComponents/StyledLink';
import { useHistory, useLocation } from 'react-router-dom';
import { connect } from 'react-redux';
import { RootState } from '../../../state/store/store';
import { localizationSelectors } from '../../../state/ducks/localization/selectors';

type Props = {
    style?: React.CSSProperties;
    text?: string;
    toPath?: string;
    localization: any;
};

const BackButton = ({ text, style, toPath, localization }: Props) => {
    const history = useHistory();
    return (
        <GoBackButton style={style} onClick={toPath ? () => history.push(toPath) : history.goBack}>
            <LeftArrowIcon /> <span>{text || localization.go_back}</span>
        </GoBackButton>
    );
};

const GoBackButton = styled.div`
    cursor: pointer;
    display: flex;
    font-size: ${(props) => props.theme.fontSizes.gridCell};
    color: ${(props) => props.theme.colors.link};
`;
const LeftArrowIcon = styled.div`
    width: 20px;
    height: 20px;
    background: ${(props) => props.theme.colors.primaryBlue};
    height: 1px;
    position: relative;
    top: 10px;
    margin-right: 10px;

    @media screen and (max-width: 768px) {
    }

    &:before,
    &:after {
        content: '';
        background: ${(props) => props.theme.colors.primaryBlue};
        position: absolute;
        height: 1px;
        width: 8px;
        right: 12px;
    }

    &:before {
        top: -2px;
        transform: rotate(-30deg);
    }

    &:after {
        bottom: -2px;
        transform: rotate(30deg);
    }
`;

const mapStateToProps = (state: RootState) => ({
    localization: localizationSelectors.common(state)
});

const mapDispatchToProps = (dispatch: any) => ({});

export default connect(mapStateToProps, mapDispatchToProps)(BackButton);
