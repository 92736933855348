import BaseController from '../..';
import { discussion } from '../../../../models/entities/common_subentities/discussion';
import IPurchaseDemandDiscussions from './IPurchaseDemandDiscussions';

export default class PurchaseDemandDiscussions extends BaseController implements IPurchaseDemandDiscussions {
    constructor(baseUrl: string) {
        super(baseUrl);
    }
    async fetchPurchaseDemandDiscussions(purchaseDemandNumber: string) {
        const response = await this.client.get(`/purchaseDemand/discussions/${purchaseDemandNumber}`);
        return response;
    }
    async createPurchaseDemandDiscussion(purchaseDemandId: string, data: discussion, purchaseDemandNumber?: string) {
        const response = await this.client.post(`/purchaseDemand/discussions`, JSON.stringify({ purchaseDemandId, data, purchaseDemandNumber }));
        return response;
    }
    async updatePurchaseDemandDiscussion(purchaseDemandId: string, data: discussion, purchaseDemandNumber?: string) {
        const response = await this.client.put(`/purchaseDemand/discussions`, JSON.stringify({ purchaseDemandId, data, purchaseDemandNumber }));
        return response;
    }
    async deletePurchaseDemandDiscussion(purchaseDemandId: string, discussionId: number) {
        const response = await this.client.delete(`/purchaseDemand/discussions`, JSON.stringify({ purchaseDemandId, id: discussionId }));
        return response;
    }
}
