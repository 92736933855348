import React from 'react';
import { order } from '../../../models/entities/order/order';
import { PagesRoutes } from '../../../routing/PagesRoutes';
import { ColumnType } from '../../shared/Grid/types/Column';
import { currencyFormatter } from '../../shared/inputs/form/CurrencyInput/CurrencyInput';
import StyledLink from '../../shared/SmallComponents/StyledLink';
import TrackingNumberLink from '../SingleOrder/components/TrackingNumberLink';
import OrderAlertPopover from './OrderAlertPopover';

const ordersGridColumns: (localization: any) => Array<ColumnType<order>> = (localization: any) => {
    const fieldTitle = (fieldName: string) => localization?.fields?.[fieldName]?.title;
    return [
        {
            field: 'cargoZoneNumber',
            width: '140px',
            sortable: true,
            isDisplayed: true,
            textAlign: 'center',
            title: localization?.fields?.['orderNumber']?.title,
            bindedFields: ['id'],
            renderer: (cellValue: any, bindedData?: any[] | null) => (
                <StyledLink to={`${PagesRoutes.Orders}/${bindedData?.[0]}`}>#{cellValue}</StyledLink>
            )
        },
        {
            field: 'alertStatus',
            width: '40px',
            sortable: true,
            bindedFields: ['id'],
            title: fieldTitle('alertStatus'),
            hideTitle: true,
            renderer: (cellValue: any, bindedData?: any[] | null) => <OrderAlertPopover alertStatus={cellValue} orderId={bindedData![0]} />
        },
        {
            field: 'state',
            width: '100%',
            sortable: true,
            title: fieldTitle('state'),
            valueFormatter: (cellValue: any, bindedData?: any[] | null) => localization.state[cellValue]
        },
        {
            field: 'userName',
            width: '100%',
            sortable: true,
            title: fieldTitle('userName')
        },
        {
            field: 'companyName',
            width: '100%',
            sortable: true,
            title: fieldTitle('companyName'),
            bindedFields: ['companyId'],
            columnType: 'company'
        },
        {
            field: 'customer',
            width: '100%',
            sortable: true,
            title: fieldTitle('customerId')
        },
        {
            field: 'trackingNumber',
            width: '100%',
            sortable: true,
            title: fieldTitle('trackingNumber'),
            renderer: (cellValue: any, bindedData?: Array<any> | null) => {
                if (!cellValue) return <div>-</div>;

                return <TrackingNumberLink trackingNumber={cellValue} />;
            }
        },
        {
            field: 'totalItems',
            width: '100px',
            sortable: true,
            title: fieldTitle('totalItems')
        },
        {
            field: 'productsName',
            width: '100px',
            sortable: true,
            title: fieldTitle('productsName')
        },
        {
            title: 'Total Cost',
            field: 'totalCost',
            width: '100px',
            sortable: true,
            hideTitleAttribute: true,
            renderer: (cellValue: any, bindedData?: Array<any> | null) => {
                if (!Array.isArray(cellValue) || cellValue.length === 0) return <div>-</div>;

                return (
                    <>
                        {cellValue.map((cost) => {
                            const value = currencyFormatter(cost.currency)(cost.sum);
                            return <div>{value}</div>;
                        })}
                    </>
                );
            },
            textAlign: 'center'
        },
        {
            field: 'orderDate',
            width: '100%',
            sortable: true,
            columnType: 'date',
            title: fieldTitle('orderDate')
        },
        {
            field: 'dueDate',
            width: '100%',
            sortable: true,
            columnType: 'date',
            title: fieldTitle('dueDate')
        },
        {
            field: 'poDate',
            width: '100%',
            sortable: true,
            columnType: 'date',
            title: fieldTitle('poDate')
        },
        {
            field: 'eta',
            width: '100%',
            sortable: true,
            columnType: 'date',
            title: fieldTitle('eta')
        },
        {
            field: 'poNumber',
            width: '100%',
            sortable: true,
            title: fieldTitle('poNumber')
        },
        {
            field: 'soNumber',
            width: '100%',
            sortable: true,
            title: fieldTitle('soNumber')
        },
        {
            field: 'invoiceNumber',
            width: '100%',
            sortable: true,
            title: fieldTitle('invoiceNumber')
        },
        {
            field: 'incoterms',
            width: '100%',
            sortable: true,
            title: fieldTitle('incoterms')
        },
        {
            field: 'carrier',
            width: '100%',
            sortable: true,
            title: fieldTitle('carrier')
        },
        {
            field: 'weight',
            title: fieldTitle('weight'),
            width: '100%',
            sortable: true,
            columnType: 'weight_volume',
            bindedFields: ['weight', 'weightUnit', 'volume', 'volumeUnit']
        },
        {
            field: 'paymentTerms',
            width: '100%',
            sortable: true,
            title: fieldTitle('paymentTerms')
        },
        {
            field: 'shippingInstructions',
            width: '100%',
            sortable: true,
            title: fieldTitle('shippingInstructions')
        },
        {
            field: 'lastEvent',
            sortable: true,
            width: '100px',
            title: fieldTitle('lastEvent')
        },
        {
            field: 'requiredCustomsClearance',
            title: fieldTitle('requiredCustomsClearance'),
            width: '50px',
            sortable: true,
            isDisplayed: true,
            columnType: 'bool'
        },
        {
            field: 'createdAt',
            title: fieldTitle('createdAt'),
            width: '100px',
            sortable: true,
            isDisplayed: true,
            columnType: 'date'
        },
        {
            field: 'updatedAt',
            title: fieldTitle('updatedAt'),
            width: '100px',
            sortable: true,
            columnType: 'date-time'
        }
        // ...fields.map((f) => ({
        //     field: f,
        //     width: '100%',
        //     sortable: true,
        //     title: localization?.fields?.[f]?.title
        // }))
    ];
};

export default ordersGridColumns;
