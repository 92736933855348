import BaseController from '../..';
import { discussion } from '../../../../models/entities/common_subentities/discussion';
import IPurchaseOrderDiscussions from './IPurchaseOrderDiscussions';

export default class PurchaseOrderDiscussions extends BaseController implements IPurchaseOrderDiscussions {
    constructor(baseUrl: string) {
        super(baseUrl);
    }
    async fetchPurchaseOrderDiscussions(purchaseOrderNumber: string) {
        const response = await this.client.get(`/purchaseOrder/discussions/${purchaseOrderNumber}`);
        return response;
    }
    async createPurchaseOrderDiscussion(purchaseOrderId: string, data: discussion, purchaseOrderNumber?: string) {
        const response = await this.client.post(`/purchaseOrder/discussions`, JSON.stringify({ purchaseOrderId, data, purchaseOrderNumber }));
        return response;
    }
    async updatePurchaseOrderDiscussion(purchaseOrderId: string, data: discussion, purchaseOrderNumber?: string) {
        const response = await this.client.put(`/purchaseOrder/discussions`, JSON.stringify({ purchaseOrderId, data, purchaseOrderNumber }));
        return response;
    }
    async deletePurchaseOrderDiscussion(purchaseOrderId: string, discussionId: number) {
        const response = await this.client.delete(`/purchaseOrder/discussions`, JSON.stringify({ purchaseOrderId, id: discussionId }));
        return response;
    }
}
