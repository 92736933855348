import React from 'react';
import styled from 'styled-components';
import ae from '../../../../static/icons/shipmentTypes/AE.svg';
import ai from '../../../../static/icons/shipmentTypes/AI.svg';
import drop from '../../../../static/icons/shipmentTypes/DROP_AIR.svg';
import inland from '../../../../static/icons/shipmentTypes/INLAND.svg';
import oe from '../../../../static/icons/shipmentTypes/OE.svg';
import oi from '../../../../static/icons/shipmentTypes/OI.svg';
import oex from '../../../../static/icons/shipmentTypes/OEX.svg';
import customs from '../../../../static/icons/shipmentTypes/CUSTOMS.svg';
import rail from '../../../../static/icons/shipmentTypes/RAIL.svg';
import express from '../../../../static/icons/shipmentTypes/EXPRESS.svg';
type Props = {
    value: string;
    imgWidth?: number;
};
export const IconByShipmentType = (shipmentType: string) => {
    switch (shipmentType.replace(/ /g, '')) {
        case 'AE':
            return ae;
        case 'INLAND':
            return inland;
        case 'AI':
            return ai;
        case 'DROP_AIR':
        case 'DROP_OCEAN':
            return drop;
        case 'OE':
            return oe;
        case 'OI':
            return oi;
        case 'OEX':
            return oex;
        case 'RAIL':
            return rail;
        case 'CUSTOMS':
            return customs;
        case 'EXPRESS':
            return express;
        default:
            return null;
    }
};
const ShipmentType = ({ value, imgWidth }: Props) => {
    const icon = IconByShipmentType(value);

    if (!icon) return <span>{value}</span>;

    return <Image width={imgWidth} src={icon} alt={value} />;
};

interface ImageProps {
    width?: number;
}

const Image = styled.img<ImageProps>`
    ${(props) => (props.width ? 'width:' + props.width + 'px' : 'width:33px')};
`;

export default ShipmentType;
