import React from 'react';
import styled from 'styled-components';
import theme from '../../Theme/theme';

const menuTheme = theme.colors.userMenu;
export type MenuItemProps = {
    name: string;
    type: string;
    localizations?: any;
    onClick: Function;
};
function MenuItem({ name, localizations, onClick }: MenuItemProps) {
    return (
        <Item onClick={() => onClick()}>
            <div>{localizations[name]}</div>
        </Item>
    );
}
export const Item = styled.div`
    padding: 0 25px;
    display: flex;
    justify-content: space-between;
    height: 38px;
    align-items: center;
    cursor: pointer;
    &: hover {
        background: ${menuTheme.itemHoverBG};
    }
`;
export default MenuItem;
