import React from 'react';
import styled from 'styled-components';
import { Formik, Form } from 'formik';
import { partiesPrepareSubmit, validateParties } from '../utils';
import { connect } from 'react-redux';
import { localizationSelectors } from '../../../../../../../state/ducks/localization/selectors';
import { RootState } from '../../../../../../../state/store/store';
import Parties, { emptyPartyObj } from '..';
import CustomModal, { ModalTriggerProps } from '../../../../../../shared/Modal/Modal';
import Button from '../../../../../../shared/SmallComponents/Button';
import Loader from '../../../../../../shared/SmallComponents/Loader';
import { businessPartnersSelectors } from '../../../../../../../state/ducks/businessPartner/selectors';
import { businessPartner } from '../../../../../../../models/entities/businessPartner/businessPartner';
import { party } from '../../../../../../../models/entities/bid/party';
import { shipment } from '../../../../../../../models/entities/shipment/shipment';

type Props = {
    shipment?: shipment;
    shipmentId: string;
    bidId: string;
    localization: any;
    businessPartners: businessPartner[];
    loading: boolean;
    Trigger: (props: ModalTriggerProps) => JSX.Element;
    addBidParties: (shipmentId: string, bidId: string, parties: party[], shipment?: shipment) => Promise<void>;
};
const PartiesFormModalConnected = ({ shipment, shipmentId, bidId, localization, businessPartners, loading, Trigger, addBidParties }: Props) => {
    const initialValues = {
        parties: [emptyPartyObj]
    };

    const validate = (values: any) => {
        const errors: any = {};
        if (values.parties.length === 0 || values.parties[0].id === '') errors.parties = '';
        else if (values.parties.length > 0) {
            validateParties(values.parties, errors, localization);
        }
        return errors;
    };
    return (
        <CustomModal
            title={{ text: 'Add Forwarders' }}
            Trigger={Trigger}
            Content={(setVisible?: React.Dispatch<React.SetStateAction<boolean>>, setConfirm?: React.Dispatch<React.SetStateAction<boolean>>) => {
                return (
                    <Formik
                        initialValues={initialValues}
                        validate={validate}
                        onSubmit={async (values) => {
                            const parties = partiesPrepareSubmit(values, businessPartners, bidId);
                            await addBidParties(shipmentId, bidId, parties, shipment);
                            setVisible?.(false);
                        }}
                    >
                        {({ values, errors, setFieldValue, isSubmitting, isValidating }) => (
                            <Form>
                                <Parties
                                    disabled={false}
                                    values={values}
                                    setFieldValue={setFieldValue}
                                    businessPartners={businessPartners}
                                    localization={localization.parties}
                                />
                                <Buttons>
                                    <Button width="auto" height="30px" type="button" buttonType="clean" textColor="#394372">
                                        <span
                                            onClick={() => {
                                                setVisible?.(false);
                                            }}
                                        >
                                            {localization.parties.cancel}
                                        </span>
                                    </Button>
                                    <Button buttonType="filled" width="100px" height="30px" type="submit">
                                        {loading ? <Loader width="20px" marginTop="0px" showText={false} /> : <span>{localization.parties.add}</span>}
                                    </Button>
                                </Buttons>
                            </Form>
                        )}
                    </Formik>
                );
            }}
            width="600px"
        />
    );
};

const Buttons = styled.div`
    width: 100%;
    padding-top: 25px;
    display: flex;
    justify-content: flex-end;

    button {
        margin-right: 20px;
    }
`;

const mapStateToProps = (state: RootState) => ({
    localization: localizationSelectors.singleShipmentPage(state).bid.form,
    businessPartners: businessPartnersSelectors.businessPartnerForwarders(state),
    loading: !!state.loading.effects.bids.addBidParties
});

const mapDispatchToProps = (dispatch: any) => ({
    addBidParties: (shipmentId: string, bidId: string, parties: party[], shipment?: shipment) =>
        dispatch.bids.addBidParties({ shipmentId, bidId, parties, shipment })
});

export default connect(mapStateToProps, mapDispatchToProps)(PartiesFormModalConnected);
