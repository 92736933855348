import React, { useEffect } from 'react';
import { RootState } from '../../../state/store/store';
import { connect } from 'react-redux';
import { localizationSelectors } from '../../../state/ducks/localization/selectors';
import { userSelectors } from '../../../state/ducks/user/selectors';
import { purchaseOrder, settings } from '../../../models/entities/purchaseOrder/purchaseOrder';
import FollowingPurchaseOrders from './FollowingPurchaseOrders';
import { headerSelectors } from '../../../state/ducks/header/selectors';
import { drawers } from '../../../state/ducks/header/header';
import { ColumnType } from '../../shared/Grid/types/Column';
import { userSettings } from '../../../models/entities/user';
import { allPurchaseOrdersPageSelectors } from '../../../state/ducks/allPurchaseOrdersPage/selectors';
import { filterColsByUserSettings } from '../../shared/Grid/util';
import { purchaseOrdersSelectors } from '../../../state/ducks/purchaseOrders/selectors';

type Props = {
    localization: any;
    fetchPurchaseOrdersByIds: (purchaseOrdersIds: string[]) => void;
    setDrawerEmptyState: (drawer: drawers, isEmpty: boolean) => void;
    setDrawerOpen: (open: drawers) => void;
    fetchGridColumns: () => void;
    followingPurchaseOrders?: Array<string>;
    followingPurchaseOrdersData?: Array<purchaseOrder>;
    isLoadingPurchaseOrders: boolean;
    isEmptyStateDrawer: boolean;
    gridColumns: Array<ColumnType<purchaseOrder>>;
    purchaseOrdersGridSettings?: userSettings;
    settings?: settings;
};

const FollowingPurchaseOrdersConnected = ({
    localization,
    fetchPurchaseOrdersByIds,
    setDrawerEmptyState,
    setDrawerOpen,
    followingPurchaseOrders,
    followingPurchaseOrdersData,
    isLoadingPurchaseOrders,
    isEmptyStateDrawer,
    fetchGridColumns,
    gridColumns,
    purchaseOrdersGridSettings,
    settings
}: Props) => {
    useEffect(() => {
        followingPurchaseOrders && fetchPurchaseOrdersByIds(followingPurchaseOrders);
        // if (!followingPurchaseOrders?.every((purchaseOrderId) => followingPurchaseOrdersData?.some((purchaseOrder) => purchaseOrder.id === purchaseOrderId))) {
        //     followingPurchaseOrders && fetchPurchaseOrdersByIds(followingPurchaseOrders);
        // }
        if (!gridColumns || !gridColumns?.length) fetchGridColumns();
    }, [fetchPurchaseOrdersByIds, fetchGridColumns]);

    return (
        <FollowingPurchaseOrders
            localization={localization}
            setDrawerEmptyState={setDrawerEmptyState}
            setDrawerOpen={setDrawerOpen}
            followingPurchaseOrdersData={followingPurchaseOrdersData}
            isLoadingPurchaseOrders={isLoadingPurchaseOrders}
            isEmptyStateDrawer={isEmptyStateDrawer}
            gridColumns={filterColsByUserSettings(gridColumns, purchaseOrdersGridSettings)}
            settings={settings}
        />
    );
};

const mapStateToProps = (state: RootState) => ({
    localization: localizationSelectors.followingPurchaseOrders(state),
    followingPurchaseOrders: userSelectors.followingPurchaseOrders(state),
    followingPurchaseOrdersData: userSelectors.followingPurchaseOrdersData(state),
    isLoadingPurchaseOrders:
        state.loading.effects.purchaseOrders.fetchPurchaseOrdersByIds || state.loading.effects.allPurchaseOrdersPage.fetchGridColumns,
    isEmptyStateDrawer: headerSelectors.isEmptyStateDrawer(state),
    gridColumns: allPurchaseOrdersPageSelectors.gridColumns(state),
    purchaseOrdersGridSettings: userSelectors.purchaseOrdersGridSettings(state),
    settings: purchaseOrdersSelectors.settingsByLanguage(state)
});

const mapDispatchToProps = (dispatch: any) => ({
    fetchGridColumns: () => dispatch.allPurchaseOrdersPage.fetchGridColumns(),
    fetchPurchaseOrdersByIds: async (purchaseOrdersIds: string[]) => dispatch.purchaseOrders.fetchPurchaseOrdersByIds(purchaseOrdersIds),
    setDrawerEmptyState: (drawer: drawers, isEmpty: boolean) => dispatch.header.setDrawerEmptyState({ drawer, isEmpty }),
    setDrawerOpen: (open: drawers) => dispatch.header.setDrawerOpen(open)
});

export default connect(mapStateToProps, mapDispatchToProps)(FollowingPurchaseOrdersConnected);
