import React from 'react';
import { product } from '../../../../../../models/entities/product/product';
import { pdItemPoRef, purchaseDemandItem } from '../../../../../../models/entities/purchaseDemand/purchaseDemandItem';
import { currencyFormatter } from '../../../../../shared/inputs/form/CurrencyInput/CurrencyInput';
import { styled } from '../../../../../shared/Theme/theme';
import { calcCosts } from './util';
import ProductsModal, { productGridRow } from '../../../../products/components/ProductsModal/ProductsModal';
import CustomModal, { ModalTriggerProps } from '../../../../../shared/Modal/Modal';
import MaterialPopover from '../../../../../shared/Popover/MaterialPopover';
import infoIcon from '../../../../../../static/icons/info.svg';
import EditIcon from '../../../../../../static/icons/edit.svg';
import MuiDataGrid, { MultiLineCell } from '../../../../../shared/MuiDataGrid/MuiDataGrid';
import { GridCellParams, GridColDef, GridValueFormatterParams } from '@material-ui/data-grid';
import { uuidv4 } from '../../../../../../utils/patterns';
import Button from '../../../../../shared/SmallComponents/Button';
import { Link } from 'react-router-dom';
import { PagesRoutes } from '../../../../../../routing/PagesRoutes';
import PdItemPoRefsModal from '../../../PdItemPoRefsModal/PdItemsPoRefsModal';
import { purchaseDemand } from '../../../../../../models/entities/purchaseDemand/purchaseDemand';
import CreatePOFromPD from '../../../sections/purchaseDemandItems/CreatePOFromPD';
import MergePDtoPO from '../../../sections/purchaseDemandItems/MergePDtoPO';
import { drawers } from '../../../../../../state/ducks/header/header';
import SingleValueFormModal from '../../../../../shared/SingleValueFormModal/SingleValueFormModal';
import { claimsDomainsType } from '../../../../../../userClaims';
import claimsType from '../../../../../../models/entities/claimsType';
import CZTooltip from '../../../../../shared/CZTooltip/CZTooltip';
import { calcTotalVolume, calcTotalWeight, calcQuantity } from '../../../../../../utils/measurementUtils';
import { Image } from 'antd';
import { Modal } from 'antd';
import NumberInput from '../../../../../shared/inputs/base/NumberInput';
import { DropDown } from '../../../../../shared/inputs/base';
import { supplier } from '../../../../../../models/entities/product/supplier';

type Props = {
    values: any;
    setFieldValue: any;
    hideBottomDetails?: boolean;
    isProductModalOpened?: boolean;
    localization: any;
    onDeleteItem?: (itemId: string) => void;
    onAddItemsCallback?: (items: purchaseDemandItem[]) => Promise<void>;
    onUpdatePurchaseDemandItem?: (updatedItem: purchaseDemandItem, itemIndex?: string, cancelTimeoutInterval?: boolean) => Promise<void>;
    onUpdateItemCost?: (updatedItem: purchaseDemandItem) => Promise<void>;
    setDrawerOpen: (open: drawers, params?: any) => void;
    getUserClaims: (domain: claimsDomainsType) => claimsType;
    updateProduct?: (id: string, product: product, deletedSuppliers?: string[]) => Promise<boolean>;
    purchaseDemand?: purchaseDemand;
    pdItemsRelatedProducts: Array<product>;
    disableAdd?: boolean;
    disableEdit?: boolean;
    disableDelete?: boolean;
};
const PdItemsGrid = ({
    values,
    setFieldValue,
    hideBottomDetails,
    localization,
    isProductModalOpened,
    onDeleteItem,
    onAddItemsCallback,
    onUpdatePurchaseDemandItem,
    setDrawerOpen,
    onUpdateItemCost,
    getUserClaims,
    updateProduct,
    purchaseDemand,
    pdItemsRelatedProducts,
    disableAdd,
    disableEdit,
    disableDelete
}: Props) => {
    const volumeCalc = calcTotalVolume(values.items);
    const weightCalc = calcTotalWeight(values.items);
    const amounts = calcCosts(values.items);
    const isPdStateChanged =
        purchaseDemand &&
        (purchaseDemand.approvers?.some((app) => app.state !== 'WAITING') ||
            ['APPROVED', 'PARTIAL_APPROVED', 'REJECTED'].includes(purchaseDemand?.state));

    const ItemsGridColumns: GridColDef[] = [
        {
            field: 'productSku',
            headerName: localization.sku,
            flex: 1,
            renderCell: (params: GridCellParams) => {
                return (
                    <GridCell
                        onClick={(e) => {
                            e.stopPropagation();
                            if (params.getValue(params.id, 'productId') && setDrawerOpen)
                                setDrawerOpen('PRODUCT', { productId: params.getValue(params.id, 'productId') });
                        }}
                    >
                        <LinkButton>{params.value}</LinkButton>
                    </GridCell>
                );
            }
        },
        {
            field: 'name',
            headerName: localization.product_name,
            flex: 1,
            renderCell: (params: GridCellParams) => <MultiLineCell>{params.value}</MultiLineCell>
        },
        {
            field: 'Img',
            headerName: localization.images,
            hide: pdItemsRelatedProducts.every((p) => !p.latestProductImgUrl),
            renderCell: (params: GridCellParams) => {
                const relatedProductId = params.row.productId;

                if (!relatedProductId) return <GridCell>-</GridCell>;

                const relatedProductImgUrl = pdItemsRelatedProducts.find((p) => p.id === relatedProductId)?.latestProductImgUrl;

                if (!relatedProductImgUrl) return <GridCell>-</GridCell>;

                const restImges = pdItemsRelatedProducts
                    ?.find((p) => p.id === relatedProductId)
                    ?.documents?.filter((d) => d.type.includes('PRODUCT_IMG') && !!d.imgUrl && d.imgUrl !== relatedProductImgUrl);

                return (
                    <Image.PreviewGroup>
                        <ItemImgContainer
                            onClick={(e) => {
                                e.stopPropagation();
                            }}
                        >
                            <Image width={35} src={relatedProductImgUrl} />
                        </ItemImgContainer>
                        <div style={{ display: 'none' }}>
                            {restImges?.map((img, i) => (
                                <Image key={i} width={0} src={img.imgUrl} />
                            ))}
                        </div>
                    </Image.PreviewGroup>
                );
            },
            width: 100,
            sortable: false
        },
        {
            field: 'attributes',
            headerName: localization.attributes,
            flex: 1,
            renderCell: (params: GridCellParams) => {
                const value = (params.value as { [key: string]: string }) || undefined;
                if (!value) return <GridCell>-</GridCell>;

                return (
                    <div style={{ width: '100%' }}>
                        <MaterialPopover
                            anchor={
                                <GridCell>
                                    {Object.entries(value)
                                        .map(([key, name]) => `${key}: ${name}`)
                                        .join(', ')}
                                </GridCell>
                            }
                        >
                            <GridCell>
                                {Object.entries(value).map(([key, name], i) => (
                                    <Detail key={`${params.row.id}-att-option-${i}`}>
                                        <DetailName>{key}:</DetailName>
                                        <DetailValue>{name}</DetailValue>
                                    </Detail>
                                ))}
                            </GridCell>
                        </MaterialPopover>
                    </div>
                );
            }
        },
        {
            field: 'quantity',
            headerName: localization.quantity,
            width: 110,
            renderCell: (params: GridCellParams) => {
                const itemIndex = values.items?.findIndex((item: purchaseDemandItem) => item.id === params.id);
                const minOrderQuantity = Number(params.getValue(params.id, 'minOrderQuantity')) || 1;
                const quantity = Number(params.row.quantity) || minOrderQuantity;
                const isMinOrderValueExceeded = quantity < minOrderQuantity;

                if (disableEdit) return <GridCell>{quantity}</GridCell>;

                if (itemIndex === -1) return <GridCell>-</GridCell>;

                return (
                    <CZTooltip text={`Minimum order value is ${minOrderQuantity}`} hidden={!isMinOrderValueExceeded}>
                        <div onClick={(e) => e.stopPropagation()}>
                            <NumberInput
                                onChange={async (value: any) => {
                                    const newQuantity = value || minOrderQuantity;
                                    setFieldValue(`items[${itemIndex}][quantity]`, newQuantity);
                                    const leftQuantity = (Number(newQuantity) || 0) - (Number(params.getValue(params.id, 'openedQuantity')) || 0);
                                    if (onUpdatePurchaseDemandItem) {
                                        await onUpdatePurchaseDemandItem(
                                            { ...values.items?.[itemIndex], quantity: newQuantity, leftQuantity },
                                            itemIndex
                                        );
                                    }
                                }}
                                value={Number(params.row.quantity) || minOrderQuantity}
                                placeHolder={localization.quantity}
                                style={{ width: '90px', color: isMinOrderValueExceeded ? 'red' : 'inherit' }}
                                min={1}
                            />
                        </div>
                    </CZTooltip>
                );
            }
        },
        {
            field: 'unitsPerMasterCarton',
            headerName: localization.cartons,
            width: 110,
            renderCell: (params: GridCellParams) => {
                const itemIndex = values.items?.findIndex((item: purchaseDemandItem) => item.id === params.id);

                const unitsPerMasterCarton = Number(params.row.unitsPerMasterCarton);
                const quantity = Number(params.row.quantity);

                if (itemIndex === -1 || !unitsPerMasterCarton) return <GridCell>-</GridCell>;

                const cartonsNumber = Math.ceil(quantity / unitsPerMasterCarton);

                return <div>{cartonsNumber}</div>;
            }
        },
        {
            field: 'price',
            flex: 1,
            headerName: localization.price,
            renderCell: (params: GridCellParams) => {
                const { value } = params;

                if (!value) return <GridCell>-</GridCell>;

                const { productId, productSupplierId, productSupplierName } = params.row;
                const relatedProduct = pdItemsRelatedProducts.find((p) => p.id === productId);
                const isMultipleSuppliers = !!relatedProduct?.isMultipleSuppliers;
                const suppliers = relatedProduct?.suppliers;

                const currencyValue = params.getValue(params.id, 'currency');
                const parsedValue = currencyFormatter(currencyValue)(value);

                const itemId = params.getValue(params.id, 'id') as string;
                const itemIndex = values.items?.findIndex((item: purchaseDemandItem) => item.id === params.id);

                if (itemIndex === -1) return <GridCell>-</GridCell>;

                return (
                    <EditableCell
                        onKeyDown={(e) => e.stopPropagation()}
                        onClick={(e) => {
                            e.stopPropagation();
                        }}
                    >
                        {parsedValue}
                        {itemId && !!onUpdateItemCost && !disableEdit && (
                            <SingleValueFormModal
                                title={localization.price}
                                initialValue={value}
                                initialCurrencyValue={(currencyValue || 'USD') as string}
                                valueType="currency"
                                checkbox={{
                                    text:
                                        isMultipleSuppliers && suppliers?.length
                                            ? `${localization.update_product_cost} of supplier ${productSupplierName}`
                                            : localization.update_product_cost
                                }}
                                onSubmit={async (value: any, checkboxValue?: boolean, currencyValue?: string) => {
                                    setFieldValue(`items[${itemIndex}][price]`, value);
                                    setFieldValue(`items[${itemIndex}][currency]`, currencyValue);
                                    await onUpdateItemCost({ ...values.items?.[itemIndex], price: value, currency: currencyValue });
                                    if (!!checkboxValue) {
                                        const { productId, productSku, name } = values.items?.[itemIndex] || {};
                                        if (productId && productSku && name && updateProduct && currencyValue) {
                                            let updatedSupplier: Partial<supplier> | undefined;

                                            if (isMultipleSuppliers && suppliers?.length && productSupplierId && relatedProduct) {
                                                const selectedSupplier = relatedProduct.suppliers.find((s) => s.supplierId === productSupplierId);
                                                if (selectedSupplier) {
                                                    updatedSupplier = {
                                                        ...selectedSupplier,
                                                        cost: value,
                                                        costCurrency: currencyValue
                                                    };
                                                }
                                            }

                                            const updatedProduct = {
                                                name,
                                                sku: productSku,
                                                cost: !updatedSupplier ? value : undefined,
                                                costCurrency: !updatedSupplier ? currencyValue : undefined,
                                                suppliers: updatedSupplier ? [updatedSupplier] : []
                                            } as unknown as product;

                                            updateProduct(productId, updatedProduct);
                                        }
                                    }
                                }}
                                Trigger={(props: ModalTriggerProps) => {
                                    return (
                                        <span>
                                            <img
                                                src={EditIcon}
                                                onMouseDown={(e) => {
                                                    if (isPdStateChanged) {
                                                        Modal.confirm({
                                                            zIndex: 1100,
                                                            title: <div>{localization.are_you_sure}</div>,
                                                            content: <div>{localization.reset_po_state_message}</div>,
                                                            async onOk() {
                                                                await props.onClick();
                                                            },
                                                            onCancel() {}
                                                        });
                                                    } else {
                                                        props.onClick();
                                                    }
                                                }}
                                                className="edit-icon"
                                                alt={localization.edit}
                                            />
                                        </span>
                                    );
                                }}
                            />
                        )}
                    </EditableCell>
                );
            }
        },
        {
            field: 'currency',
            flex: 1,
            headerName: localization.total_cost,
            valueFormatter: (params: GridValueFormatterParams) =>
                params.getValue(params.id, 'price')
                    ? currencyFormatter(params.value)(
                          (Number(params.getValue(params.id, 'price')) || 0) * (Number(params.getValue(params.id, 'quantity')) || 1) || 0
                      )
                    : '-'
        },
        {
            field: 'productSupplierName',
            headerName: localization.supplier,
            flex: 1,
            renderCell: (params: GridCellParams) => {
                const { id: itemId, productId, productSupplierId, productSupplierName } = params.row;

                const itemIndex = values.items?.findIndex((item: purchaseDemandItem) => item.id === params.id);

                if (!productId || itemIndex < 0) return <GridCell>-</GridCell>;

                const relatedProduct = pdItemsRelatedProducts.find((p) => p.id === productId);

                if (!relatedProduct) return <GridCell>-</GridCell>;

                const { suppliers, isMultipleSuppliers, supplierName, cost, costCurrency, minOrderQuantity, productionDays } = relatedProduct;

                return (
                    <EditableCell
                        onKeyDown={(e) => e.stopPropagation()}
                        onClick={(e) => {
                            e.stopPropagation();
                        }}
                    >
                        {productSupplierName}
                        {itemId && !!onUpdateItemCost && !disableEdit && (
                            <SingleValueFormModal<string>
                                title={localization.supplier}
                                valueType={isMultipleSuppliers ? 'dropdown' : 'dialog'}
                                placeholder={isMultipleSuppliers ? localization.supplier : undefined}
                                dropDownOptions={
                                    isMultipleSuppliers ? suppliers.map((s) => ({ text: s.supplierName || '', value: s.supplierId })) : undefined
                                }
                                onSubmit={async (value: string) => {
                                    if (!onUpdatePurchaseDemandItem) return;

                                    if (!isMultipleSuppliers) {
                                        await onUpdatePurchaseDemandItem(
                                            {
                                                ...values.items?.[itemIndex],
                                                productSupplierId: relatedProduct.supplierId,
                                                productSupplierName: relatedProduct.supplierName,
                                                price: relatedProduct.cost,
                                                currency: relatedProduct.costCurrency,
                                                minOrderQuantity: relatedProduct.minOrderQuantity || 1,
                                                productionDays: relatedProduct.productionDays || 1
                                            },
                                            itemIndex,
                                            true
                                        );
                                        return;
                                    }

                                    const supplier = suppliers.find((s) => s.supplierId === value);

                                    if (!supplier) return;

                                    const { supplierId, supplierName, cost, costCurrency, minOrderQuantity, productionDays } = supplier;

                                    await onUpdatePurchaseDemandItem(
                                        {
                                            ...values.items?.[itemIndex],
                                            productSupplierId: supplierId,
                                            productSupplierName: supplierName,
                                            price: cost,
                                            currency: costCurrency,
                                            minOrderQuantity: minOrderQuantity || 1,
                                            productionDays: productionDays || 1
                                        },
                                        itemIndex,
                                        true
                                    );
                                }}
                                dialogContent={
                                    !isMultipleSuppliers ? (
                                        <ProductDataContainer>
                                            {Object.entries({
                                                [localization.supplier]: supplierName,
                                                [localization.price]: currencyFormatter(costCurrency)(cost),
                                                [localization.minOrderQuantity]: minOrderQuantity || 1,
                                                [localization.productionDays]: productionDays || 1
                                            }).map(([key, value]) => (
                                                <>
                                                    <ProductDetailWrapper>
                                                        <ProductField>{key}: </ProductField>
                                                        <ProductValue>{value}</ProductValue>
                                                    </ProductDetailWrapper>
                                                </>
                                            ))}
                                        </ProductDataContainer>
                                    ) : undefined
                                }
                                Trigger={(props: ModalTriggerProps) => {
                                    return (
                                        <span>
                                            <img
                                                src={EditIcon}
                                                onMouseDown={(e) => {
                                                    if (isPdStateChanged) {
                                                        Modal.confirm({
                                                            zIndex: 1100,
                                                            title: <div>{localization.are_you_sure}</div>,
                                                            content: <div>{localization.reset_po_state_message}</div>,
                                                            async onOk() {
                                                                await props.onClick();
                                                            },
                                                            onCancel() {}
                                                        });
                                                    } else {
                                                        props.onClick();
                                                    }
                                                }}
                                                className="edit-icon"
                                                alt={localization.edit}
                                            />
                                        </span>
                                    );
                                }}
                            />
                        )}
                    </EditableCell>
                );
            }
        },
        {
            hide: !values.items?.some((item: purchaseDemandItem) => item.purchaseOrdersRefs?.length),
            field: 'purchaseOrdersRefs',
            headerName: localization.pos_refs,
            flex: 1,
            renderCell: (params: GridCellParams) => {
                const value: pdItemPoRef[] = params.value as pdItemPoRef[];

                if (!value?.length) return <GridCell>-</GridCell>;

                if (value.length === 1) return <Link to={{ pathname: PagesRoutes.PurchaseOrders + '/' + value[0].id }}>{value[0].czNumber}</Link>;

                return (
                    <GridCell
                        onClick={(e) => {
                            e.stopPropagation();
                        }}
                    >
                        <PdItemPoRefsModal
                            localization={localization}
                            pdItemPoRefs={params.value as pdItemPoRef[]}
                            Trigger={(props: ModalTriggerProps) => (
                                <LinkButton
                                    onMouseDown={(e) => {
                                        props.onClick();
                                    }}
                                >
                                    {value.length + ' ' + localization.pos}
                                </LinkButton>
                            )}
                        />
                    </GridCell>
                );
            }
        },
        {
            hide: !values.items?.some((item: purchaseDemandItem) => item.purchaseOrdersRefs?.length),
            field: 'leftQuantity',
            headerName: localization.remaining_quantity,
            flex: 1,
            renderCell: (params: GridCellParams) => {
                const value = Number(params.value) || 0;
                return <div style={{ color: value < 0 ? 'red' : '#000000de' }}>{value}</div>;
            }
        },
        {
            field: 'id',
            headerName: ' ',
            flex: 1,
            renderCell: (params: GridCellParams) => {
                const itemIndex = values.items?.findIndex((item: purchaseDemandItem) => item.id === params.id);
                if (itemIndex === -1) return <GridCell>-</GridCell>;

                const pdItem = values.items[itemIndex];

                return (
                    <ItemOptionsContainer>
                        <MaterialPopover
                            popoverLeft
                            anchor={
                                <InfoIconWrapper>
                                    <InfoIcon src={infoIcon} />
                                </InfoIconWrapper>
                            }
                        >
                            <DetailsWrapper>
                                {Object.entries(pdItem || {})
                                    .filter(
                                        ([key, value]) =>
                                            !!value &&
                                            Number(value) !== 0 &&
                                            ![
                                                'productSku',
                                                'name',
                                                'price',
                                                'quantity',
                                                'updatedAt',
                                                'attributes',
                                                'readyDate',
                                                'purchaseOrdersRefs'
                                            ].includes(key) &&
                                            (!key.toLowerCase().includes('id') || key === 'width') &&
                                            !key.toLowerCase().includes('currency') &&
                                            !key.toLowerCase().includes('unit')
                                    )
                                    .map(([key, value]: [string, any], i: number) => {
                                        let parsedValue = value;
                                        if (key === 'weight') {
                                            parsedValue = `${value} ${pdItem.weightUnit}`;
                                        } else if (['width', 'length', 'height'].includes(key)) {
                                            parsedValue = `${value} ${pdItem.dimensionsUnit}`;
                                        }
                                        return (
                                            <Detail key={`${params.row.id}-detail-${i}`}>
                                                <DetailName>{localization[key] || key}:</DetailName>
                                                <DetailValue>{parsedValue}</DetailValue>
                                            </Detail>
                                        );
                                    })}
                            </DetailsWrapper>
                        </MaterialPopover>
                        {!disableDelete && (
                            <>
                                <VerticalDivider />

                                <DeleteContainer
                                    onClick={(e) => {
                                        e.stopPropagation();
                                        const items: Array<purchaseDemandItem> = [...values.items];
                                        const removedItems = items.splice(itemIndex, 1);
                                        if (onDeleteItem && removedItems && removedItems[0]?.id) {
                                            onDeleteItem(removedItems[0].id);
                                            return;
                                        }
                                        setFieldValue('items', items);
                                    }}
                                >
                                    <span className="material-icons">delete</span>
                                </DeleteContainer>
                            </>
                        )}
                    </ItemOptionsContainer>
                );
            }
        }
    ];
    return (
        <div>
            <ButtonsContainer>
                {!disableAdd && (
                    <ProductsModal
                        title={'Products'}
                        isOpened={isProductModalOpened}
                        companyId={values.companyId}
                        showQuantities
                        onSubmitProductsCallBack={async (products: productGridRow[]) => {
                            const itemsToAdd: purchaseDemandItem[] = [];

                            products.forEach((product) => {
                                const itemToAdd = {
                                    productId: product.id,
                                    price: product.cost,
                                    currency: product.costCurrency,
                                    name: product.name,
                                    length: product.length,
                                    width: product.width,
                                    height: product.height,
                                    dimensionsUnit: product.dimensionUnit,
                                    weight: product.weight,
                                    weightUnit: product.weightUnit,
                                    productSku: product.sku,
                                    productSupplierId: product.supplierId,
                                    productSupplierName: product.supplierName,
                                    attributes: product.selectedAttributes,
                                    quantity: product.quantity || product.minOrderQuantity,
                                    minOrderQuantity: product.minOrderQuantity,
                                    unitsPerMasterCarton: product.unitsPerMasterCarton,
                                    productionDays: product.productionDays,
                                    masterCartonWeight: product.masterCartonWeight,
                                    masterCartonWeightUnit: product.masterCartonWeightUnit,
                                    masterCartonHeight: product.masterCartonHeight,
                                    masterCartonWidth: product.masterCartonWidth,
                                    masterCartonLength: product.masterCartonLength,
                                    masterCartonDimensionUnit: product.masterCartonDimensionUnit
                                } as purchaseDemandItem;

                                if (!itemToAdd.id) itemToAdd.id = uuidv4();

                                itemsToAdd.push(itemToAdd);
                            });

                            if (onAddItemsCallback) {
                                await onAddItemsCallback(
                                    itemsToAdd.map((i) => {
                                        const item = { ...i, id: undefined } as any;

                                        return item;
                                    })
                                );
                                return;
                            }
                            setFieldValue('items', [...(values.items || []), ...itemsToAdd]);
                        }}
                        showAttributesModal
                        Trigger={(props: ModalTriggerProps) => {
                            return (
                                <AddContainer>
                                    <Button
                                        onClick={() => {
                                            if (isPdStateChanged) {
                                                Modal.confirm({
                                                    zIndex: 1100,
                                                    title: <div>{localization.are_you_sure}</div>,
                                                    content: <div>{localization.reset_po_state_message}</div>,
                                                    async onOk() {
                                                        await props.onClick();
                                                    },
                                                    onCancel() {}
                                                });
                                            } else {
                                                props.onClick();
                                            }
                                        }}
                                        width="auto"
                                        buttonType="hollow"
                                        padding="4px 12px"
                                        type="button"
                                    >
                                        <span className="material-icons">add</span>
                                        <span>{localization.add_item}</span>
                                    </Button>
                                </AddContainer>
                            );
                        }}
                    />
                )}
                {!!purchaseDemand && (
                    <div style={{ display: 'flex' }}>
                        <CustomModal
                            title={{ text: localization.merge_po_modal_title }}
                            Trigger={(props: ModalTriggerProps) => (
                                <CZTooltip text={localization.no_permission} hidden={getUserClaims('po').create}>
                                    <div>
                                        <Button
                                            disabled={purchaseDemand.state !== 'APPROVED' || !getUserClaims('po').create}
                                            onClick={props.onClick}
                                            buttonType="hollow"
                                            margin="0 10px"
                                        >
                                            {localization.merge_po_modal_title}
                                        </Button>
                                    </div>
                                </CZTooltip>
                            )}
                            Content={(
                                setVisible?: React.Dispatch<React.SetStateAction<boolean>>,
                                setConfirm?: React.Dispatch<React.SetStateAction<boolean>>
                            ) => {
                                return <MergePDtoPO purchaseDemand={purchaseDemand} localization={localization} />;
                            }}
                            width="900px"
                        />
                        <CustomModal
                            title={{ text: localization.create_po_modal_title }}
                            Trigger={(props: ModalTriggerProps) => (
                                <CZTooltip text={localization.no_permission} hidden={getUserClaims('po').create}>
                                    <div>
                                        <Button
                                            disabled={purchaseDemand.state !== 'APPROVED' || !getUserClaims('po').create}
                                            onClick={props.onClick}
                                            buttonType="hollow"
                                            margin="0 10px"
                                        >
                                            {localization.create_po}
                                        </Button>
                                    </div>
                                </CZTooltip>
                            )}
                            Content={(
                                setVisible?: React.Dispatch<React.SetStateAction<boolean>>,
                                setConfirm?: React.Dispatch<React.SetStateAction<boolean>>
                            ) => {
                                return <CreatePOFromPD purchaseDemand={purchaseDemand} localization={localization} />;
                            }}
                            width="900px"
                        />
                    </div>
                )}
            </ButtonsContainer>

            {!!values.items?.length && (
                <MuiDataGrid columns={ItemsGridColumns} rows={values.items} autoHeight disableColumnFilter disableColumnMenu disableColumnReorder />
            )}
            {values.items && !hideBottomDetails && (
                <BottomDetails>
                    <div>PD total: {calcQuantity(values.items)} items</div>
                    <div>
                        {Object.keys(amounts).map((key) => {
                            return amounts[key] > 0 && <div key={key}>{currencyFormatter(key)(amounts[key])}</div>;
                        })}
                    </div>
                    <div>
                        {Number(volumeCalc.volume?.toFixed(3)) + ' ' + volumeCalc.unit} ,{' '}
                        {Number(weightCalc.weight?.toFixed(3)) + ' ' + weightCalc.unit}
                    </div>
                </BottomDetails>
            )}
        </div>
    );
};
const StyledFieldContainer = styled.div`
    margin-bottom: 12px;
    padding-right: 15px;
`;
const ItemImgContainer = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;

    .ant-image-mask-info {
        font-size: 0;
        display: flex;
        justify-content: center;
        align-items: center;
    }

    .ant-image-mask-info .anticon {
        font-size: 14px;
        margin-inline-end: 0;
    }
`;

const StyledCheckboxContainer = styled.div`
    margin-top: 10px;
`;

const ButtonsContainer = styled.div`
    display: flex;
    justify-content: space-between;
`;

const ProductWrapper = styled.div<{ hasError: boolean }>`
    border-radius: 70px;
    border: ${(props) => `1px solid ${props.hasError ? `red` : `#008ac9`}`};
    padding: 4px 11px;
    height: 30px;
    width: 135px;
    cursor: pointer;
`;
const AddContainer = styled.div`
    cursor: pointer;
    display: inline-flex;
    align-items: center;
    margin-right: 10px;
    font-size: 13px;
    line-height: 0;
    color: ${(props) => props.theme.colors.primaryBlue};
    span.material-icons {
        font-size: inherit;
        margin-right: 5px;
        user-select: none;
    }
`;

const LinkButton = styled.div`
    color: #1890ff;
    text-decoration: none;
    background-color: transparent;
    outline: none;
    cursor: pointer;
    transition: color 0.3s;

    &:hover {
        color: #40a9ff;
    }
`;
const DeleteContainer = styled.div`
    display: flex;
    color: ${(props) => props.theme.colors.primaryBlue};
    span.material-icons {
        cursor: pointer;
    }
`;
const VerticalDivider = styled.div`
    height: 100%;
    width: 1px;
    background: rgb(0 0 0 / 14%);
`;

const BottomDetails = styled.div`
    margin-top: 10px;
    width: 100%;
    border-top: 1px solid rgb(0 0 0 / 14%);
    text-align: right;
    color: ${(props) => props.theme.colors.primaryBlue};
`;

const InfoIcon = styled.img`
    width: 20px;
    height: 20px;
`;
const InfoIconWrapper = styled.div`
    display: flex;
    position: relative;
    width: 25px;
    justify-content: center;
`;

const DetailsWrapper = styled.div`
    width: auto;
`;
const Detail = styled.div`
    display: flex;
    flex-wrap: wrap;
    margin: 10px 0;
`;
const DetailName = styled.div`
    color: #394372;
    font-weight: bold;
    margin-right: 5px;
`;

const DetailValue = styled.div`
    color: #757575;
`;
const GridCell = styled.div`
    width: 100%;
    display: block;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
`;

const EditableCell = styled.div`
    width: 100%;
    display: flex;

    .edit-icon {
        display: none;
    }

    &:hover {
        .edit-icon {
            display: initial;
        }
    }
`;
const ItemOptionsContainer = styled.div`
    display: flex;
    width: 100%;
    height: 40%;
    justify-content: space-around;
    align-items: center;
`;

const ProductDataContainer = styled.div`
    display: flex;
    flex-direction: column;
`;

const ProductDetailWrapper = styled.div`
    display: flex;
`;

const ProductField = styled.div`
    color: #394372;
    font-weight: bold;
    margin-right: 5px;
    white-space: pre-wrap;
`;

const ProductValue = styled.div`
    color: #757575;
`;
export default PdItemsGrid;
