import BaseController from '../..';
import { address } from '../../../../models/entities/businessPartner/address';
import IBusinessPartnersAddresses from './IBusinessPartnersAddresses';

export default class BusinessPartnersAddresses extends BaseController implements IBusinessPartnersAddresses {
    constructor(baseUrl: string) {
        super(baseUrl);
    }
    async createBusinessPartnerAddress(businessPartnerId: string, data: address) {
        const response = await this.client.post(`/businessPertner/addresses`, JSON.stringify({ ...data, businessPartnerId }));
        return response;
    }
    async updateBusinessPartnerAddress(businessPartnerId: string, data: address) {
        const response = await this.client.put(`/businessPertner/addresses`, JSON.stringify({ ...data, businessPartnerId }));
        return response;
    }
    async deleteBusinessPartnerAddress(businessPartnerId: string, addressId: number) {
        const response = await this.client.delete(`/businessPertner/addresses`, JSON.stringify({ businessPartnerId, id: addressId }));
        return response;
    }
}
