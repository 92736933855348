import React from 'react';
import { connect } from 'react-redux';
import { product } from '../../../../../../models/entities/product/product';
import { localizationSelectors } from '../../../../../../state/ducks/localization/selectors';
import { RootState } from '../../../../../../state/store/store';
import Grid from '../../../../../shared/Grid/Grid';
import { styled } from '../../../../../shared/Theme/theme';
import { inventoryTotal, inventoryLog } from '../../../../../../models/entities/product/inventoryLog';
import { totalsColumns, logsColumns } from './gridColumns';

type Props = {
    product: product;
    localization: any;
};

const InventoryTab = ({ product, localization }: Props) => {
    return (
        <Wrapper>
            <GridWrapper>
                <GridHeader>{localization.totals_grid.header}</GridHeader>
                <Grid<inventoryTotal>
                    onSettingsClick={() => console.log('settings')}
                    onRowMoreOptionsClick={(row) => console.log(row)}
                    columns={totalsColumns}
                    data={product.inventoryTotals || []}
                    localization={localization.totals_grid}
                    emptyStateText={localization.totals_grid.emptyState}
                />
                <GridHeader>{localization.logs_grid.header}</GridHeader>
                <Grid<inventoryLog>
                    onSettingsClick={() => console.log('settings')}
                    onRowMoreOptionsClick={(row) => console.log(row)}
                    columns={logsColumns}
                    data={product.inventoryLogs || []}
                    localization={localization.logs_grid}
                    emptyStateText={localization.logs_grid.emptyState}
                />
            </GridWrapper>
        </Wrapper>
    );
};
const Wrapper = styled.div`
    height: 100%;
    display: flex;
    flex-direction: column;
`;

const GridWrapper = styled.div`
    flex: 1;
    max-width: 100%;
    width: 100%;
    box-sizing: border-box;
    height: 100%;
    width: 100%;
    z-index: 1;
    overflow: overlay;

    @media screen and (min-width: 768px) {
        height: calc(100% - 127px);
    }

    @media screen and (max-width: 768px) {
        height: calc(100% - 135px - 40px);
    }

    @media screen and (max-height: 400px) {
        height: calc(100% - 135px);
    }
`;
const GridHeader = styled.h2`
    color: ${(props) => props.theme.colors.pageTitle};
    font-size: 17px;
    margin-top: 20px;
`;
const mapStateToProps = (state: RootState) => ({
    localization: localizationSelectors.products(state).inventory
});

const mapDispatchToProps = (dispatch: any) => ({});

export default connect(mapStateToProps, mapDispatchToProps)(InventoryTab);
