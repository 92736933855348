import React, { useEffect } from 'react';
import moment from 'moment';
import { useHistory } from 'react-router-dom';
import AnimateHeight from 'react-animate-height';
import styled from 'styled-components';
import { order } from '../../../../models/entities/order';
import { PagesRoutes } from '../../../../routing/PagesRoutes';
import OrderAlertPopover from '../OrderAlertPopover';
import { filterColsByUserSettings } from '../../../shared/Grid/util';
import ordersGridColumns from '../ordersGridColumns';
import { userSettings } from '../../../../models/entities/user';
import { connect } from 'react-redux';
import { RootState } from '../../../../state/store/store';
import { localizationSelectors } from '../../../../state/ducks/localization/selectors';
import { userSelectors } from '../../../../state/ducks/user/selectors';
import { ItemContentProps } from '../../../shared/Board/VirtualizedBoard/VirtualizedBoard';

type Props = {
    localization: any;
    ordersGridSettings?: userSettings;
} & ItemContentProps<order>;

const OrderItem = ({ item: order, localization, ordersGridSettings, measure, isItemOpen, toggleItemOpen }: Props) => {
    const history = useHistory();

    useEffect(() => {
        measure?.();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const renderOrderDetails = (skip?: number, take?: number) => {
        const fieldsToExclude: (keyof order)[] = ['cargoZoneNumber', 'totalCost'];

        return filterColsByUserSettings(ordersGridColumns(localization), ordersGridSettings)
            .filter((column) => {
                const key = column.field;
                const value = order[key];
                return (
                    column.isDisplayed !== false &&
                    value != null &&
                    typeof value !== 'object' &&
                    value.toString().trim() !== '' &&
                    !fieldsToExclude.includes(key)
                );
            })
            .slice(skip, take)
            .map((column, i) => {
                let detailValue = order[column.field];

                if (column.field === 'state') {
                    detailValue = localization.state[detailValue as string];
                }
                switch (column?.columnType) {
                    case 'date':
                        detailValue = moment(detailValue?.toString()).format('DD/MM/YY');
                        break;
                    case 'date-time':
                        detailValue = moment(detailValue?.toString()).format('DD/MM/YYYY HH:mm');
                        break;
                }
                const bold = ['consigneeName', 'shipperName'].includes(column.field);
                return (
                    <div className="detail" key={i}>
                        <span className="detail-header">{column?.title}: </span>
                        <span className={`detail-value ${bold && 'bold'}`}>{detailValue}</span>
                    </div>
                );
            });
    };
    const itemColor = (() => {
        if (order.state === 'NEW') return '#b2b2b2';
        switch (order.alertStatus) {
            case null:
            case 3:
                return '#639e31';
            case 2:
                return '#f4c915';
            case 1:
                return '#c1292e';
            default:
                return '#639e31';
        }
    })();
    return (
        <ItemContainer>
            <StateColor color={itemColor} />
            <ItemContent>
                <OrderItemTitle>
                    <span
                        className="order-number"
                        onClick={() => {
                            history.push(PagesRoutes.Orders + '/' + order.id);
                        }}
                    >
                        #{order.cargoZoneNumber}
                    </span>
                    <div className="order-alert-icon">
                        <OrderAlertPopover alertStatus={order.alertStatus} orderId={order.id} iconWidth={15} />
                    </div>
                </OrderItemTitle>
                <Details>
                    {renderOrderDetails(0, 2)}
                    <AnimateHeight id="example-panel" duration={0} height={isItemOpen ? 'auto' : 0} animateOpacity={true}>
                        {renderOrderDetails(2)}
                    </AnimateHeight>
                </Details>
            </ItemContent>
            <div
                className="top-hidden-container"
                onClick={() => {
                    toggleItemOpen();
                }}
            >
                {<span className="material-icons details-toggle">{!isItemOpen ? 'keyboard_arrow_down' : 'keyboard_arrow_up'}</span>}
            </div>
        </ItemContainer>
    );
};

interface StateColorProps {
    color: string;
}

const StateColor = styled.div<StateColorProps>`
    width: 3px;
    background-color: ${(props) => props.color};
`;

const ItemContent = styled.div`
    flex: 1;
    height: 100%;
    padding: 12px;
`;

const Details = styled.div`
    .detail {
        margin-top: 4px;
        .detail-header {
            color: #394372;
        }
        .detail-value {
            color: #757575;
        }
        .bold {
            font-weight: bold;
        }
    }
`;

interface ItemContainerProps {
    isDragging?: boolean;
    isLastVisited?: boolean;
}
const ItemContainer = styled.div<ItemContainerProps>`
    user-select: none;
    background-color: ${(props) => (props.isDragging ? '#f1f1f1' : '#fff')};
    display: flex;
    box-shadow: 0 0 10px rgb(228, 228, 228);
    border: 1px solid #e4e4e4;
    width: 100%;
    z-index: ${(props) => (props.isDragging ? '999 !important' : 'auto')};
    ${(props) => props.isLastVisited && 'box-shadow:rgb(0, 114, 255) 0px 0px 5px 1px;'}
    position: relative;

    :hover {
        .top-hidden-container {
            display: flex;
            align-items: center;
            justify-content: center;
        }
    }
    .menu-container {
        top: 0 !important;
        right: 0;
        width: auto !important;
        justify-content: flex-end !important;
        bottom: auto !important;
    }
    .top-hidden-container {
        display: none;
        position: absolute;
        bottom: 0;
        width: 100%;

        .details-toggle {
            border: 1px solid #d4d4d4;
            width: 30px;
            height: 15px;
            text-align: center;
            font-size: 14px;
            border-bottom: none;
            cursor: pointer;
            background-color: #f0f0f0;
        }
        .menu-toggle {
            margin: 5px;
            border: 1px solid #d4d4d4;
            text-align: center;
            cursor: pointer;
            background-color: #f0f0f0;

            .material-icons {
                font-size: 18px;
            }
        }
    }
`;

const OrderItemTitle = styled.div`
    display: flex;
    align-items: center;
    color: #394372;
    font-size: 14px;
    white-space: nowrap;

    .order-icon {
        margin-right: 10px;
    }
    .order-number {
        cursor: pointer;
        transition: color 0.1s;

        &:hover {
            color: #008ac9;
        }
    }
    .order-alert-icon {
        margin-left: 10px;
    }
`;

const mapStateToProps = (state: RootState) => ({
    localization: localizationSelectors.ordersPage(state),
    ordersGridSettings: userSelectors.ordersGridSettings(state)
});

const mapDispatchToProps = (dispatch: any) => ({});

export default connect(mapStateToProps, mapDispatchToProps)(React.memo(OrderItem));
