import React from 'react';
import moment from 'moment';
import { ColumnType } from '../../components/shared/Grid/types/Column';
import MaterialPopover from '../../components/shared/Popover/MaterialPopover';
import { purchaseOrder } from '../../models/entities/purchaseOrder/purchaseOrder';
import { Checkbox } from 'antd';
import { currencyFormatter } from '../../components/shared/inputs/form/CurrencyInput/CurrencyInput';

const formatDate = (date: any) => (date && moment(date).isValid() ? moment(date).format('DD/MM/YY') : '-');
const mockColumns: Array<ColumnType<purchaseOrder>> = [
    {
        title: 'PO Number',
        field: 'CargoZoneNumber',
        bindedFields: ['id'],
        width: '150px',
        sortable: true,
        isDisplayed: true,
        textAlign: 'center',
        columnType: 'purchaseOrderLink'
    },
    {
        title: 'Company',
        field: 'companyName',
        bindedFields: ['companyId'],
        width: '100%',
        sortable: true,
        isDisplayed: true,
        columnType: 'company'
    },
    {
        title: 'Open Date',
        field: 'createdAt',
        width: '100px',
        sortable: true,
        isDisplayed: true,
        columnType: 'date'
    },
    {
        title: 'Estimate Goods Ready',
        field: 'expectedReadyDate',
        width: '150px',
        sortable: true,
        isDisplayed: true,
        columnType: 'date'
    },
    {
        title: 'Actual Goods Ready',
        field: 'actualGoodsReady',
        width: '150px',
        sortable: true,
        isDisplayed: true,
        columnType: 'date'
    },
    {
        title: 'Requested Goods Ready',
        field: 'requestedGoodsReady',
        bindedFields: ['isAsap'],
        width: '150px',
        sortable: true,
        isDisplayed: true,
        valueFormatter: (cellValue: any, bindedData?: Array<any> | null) => {
            if (!!bindedData?.[0]) return 'As Soon As Possible';
            return formatDate(cellValue);
        },
        columnType: 'date-time'
    },
    {
        title: 'Payment Terms',
        field: 'paymentTerms',
        width: '100px',
        sortable: true,
        textAlign: 'center'
    },
    {
        title: 'Note',
        field: 'note',
        width: '100px',
        sortable: true,
        textAlign: 'center'
    },
    {
        title: 'Payment Dates',
        field: 'paymentDates',
        width: '100px',
        sortable: true,
        hideTitleAttribute: true,
        renderer: (cellValue: any, bindedData?: Array<any> | null) => {
            if (!Array.isArray(cellValue) || cellValue.length === 0) return <div>-</div>;
            const paymentsSortedByDate = cellValue.sort((a, b) => moment(a.date).valueOf() - moment(b.date).valueOf());
            const value = (() => {
                if (cellValue.length === 1) return cellValue[0];

                const erliestNotPayed = paymentsSortedByDate.find((p) => !p.payed);

                if (erliestNotPayed) return erliestNotPayed;

                return cellValue[0];
            })();

            const getColor = (payed: boolean, date: any) => `${payed ? '#629e31' : moment(date).isBefore(moment()) ? '#c1292e' : '#fda119'}`;

            return formatDate(value.date) === '-' ? (
                <div>-</div>
            ) : (
                <MaterialPopover anchor={<div style={{ color: getColor(value.payed, value.date) }}>{formatDate(value.date)}</div>}>
                    <div>
                        {paymentsSortedByDate.map((paymentDate, index) => {
                            const { date, currency, amount, payed } = paymentDate;
                            return (
                                <div style={{ color: getColor(payed, date) }} key={`paymentDate${index}`}>
                                    {moment(date).format('DD/MM/YYYY')} - {currencyFormatter(currency)(amount)}
                                    <Checkbox style={{ marginLeft: 5 }} checked={!!payed}></Checkbox>
                                </div>
                            );
                        })}
                    </div>
                </MaterialPopover>
            );
        },
        textAlign: 'center'
    },
    {
        title: 'Total Cost',
        field: 'totalCost',
        width: '100px',
        sortable: true,
        hideTitleAttribute: true,
        renderer: (cellValue: any, bindedData?: Array<any> | null) => {
            if (!Array.isArray(cellValue) || cellValue.length === 0) return <div>-</div>;

            return (
                <>
                    {cellValue.map((cost) => {
                        const value = currencyFormatter(cost.currency)(cost.sum);
                        return <div>{value}</div>;
                    })}
                </>
            );
        },
        textAlign: 'center'
    },
    {
        title: 'Taxes Rate',
        field: 'taxesRate',
        width: '100px',
        sortable: true,
        valueFormatter: (cellValue: any, bindedData?: Array<any> | null) => (Number(cellValue) ? `${cellValue}%` : '-'),
        textAlign: 'center'
    },
    {
        title: 'Incoterms',
        field: 'incoterms',
        width: '80px',
        sortable: true,
        textAlign: 'center'
    },
    {
        title: 'Origin',
        field: 'originStation',
        width: '90px',
        sortable: true,
        bindedFields: ['originCountry'],
        columnType: 'origin',
        toolTipText: 'Origin Country + Port'
    },
    {
        title: 'Destination',
        field: 'destinationStation',
        width: '90px',
        sortable: true,
        bindedFields: ['destinationCountry'],
        columnType: 'destination',
        toolTipText: 'destination Country + Port'
    },
    {
        title: 'Vendor',
        field: 'vendorName',
        width: '100%',
        sortable: true
    },
    {
        title: 'Consignee',
        field: 'consigneeName',
        width: '100%',
        sortable: true
    },
    {
        title: 'Created by',
        field: 'userName',
        width: '100%',
        sortable: true
    },
    {
        title: 'Items',
        field: 'quantity',
        width: '100%',
        sortable: true,
        textAlign: 'center'
    },
    {
        title: 'Products name',
        field: 'productsName',
        width: '100%',
        sortable: true,
        textAlign: 'center'
    },
    {
        title: 'Weight',
        field: 'weight',
        width: '100%',
        sortable: true,
        columnType: 'weight_volume',
        bindedFields: ['weight', 'weightUnit', 'volume', 'volumeUnit']
    },
    {
        title: 'State',
        field: 'state',
        width: '100%',
        sortable: true,
        columnType: 'state_name',
        bindedFields: ['state']
    },
    {
        title: 'Last event',
        field: 'lastEvent',
        width: '100%',
        sortable: true,
        columnType: 'event_name',
        bindedFields: ['lastEvent']
    }
];

export default mockColumns;
