import React, { useState } from 'react';
import Grid from '../../shared/Grid/Grid';
import { ColumnType } from '../../shared/Grid/types/Column';
import { styled } from '../../shared/Theme/theme';
import AllInventoryHeader from './components/AllInventoryHeader';
import { drawers } from '../../../state/ducks/header/header';
import { inventoryTotalView } from '../../../models/entities/product/inventoryLog';
import Loader from '../../shared/SmallComponents/Loader';
type Props = {
    inventoryLogs: Array<inventoryTotalView>;
    gridColumns: Array<ColumnType<inventoryTotalView>>;
    fetchInventory: () => void;
    onGridColumnClick?: (column: keyof inventoryTotalView) => void;
    gridSortedBy?: keyof inventoryTotalView;
    gridSortDirection?: 'asc' | 'desc';
    localization: any;
    showLoader?: boolean;
    userSettingKey?: string;
    resetFilterFunc?: () => void;
    setDrawerOpen: (open: drawers, params?: any) => void;
    activeInventoryCount: number;
};

const InventoryPage = ({
    inventoryLogs,
    gridColumns,
    fetchInventory,
    onGridColumnClick,
    gridSortedBy,
    gridSortDirection,
    localization,
    showLoader,
    userSettingKey,
    resetFilterFunc,
    setDrawerOpen,
    activeInventoryCount
}: Props) => {
    return (
        <PageWrapper>
            {!!showLoader && activeInventoryCount > 1 && !!gridColumns.length && (
                <LoadingOverlay>
                    <Loader width="20px" marginTop="0" inline />
                </LoadingOverlay>
            )}
            <AllInventoryHeader fetchInventory={fetchInventory} isLoading={!!showLoader} />
            <GridWrapper>
                <Grid<inventoryTotalView>
                    sortedByColumn={gridSortedBy}
                    sortDirection={gridSortDirection}
                    onSettingsClick={() => console.log('settings')}
                    onRowMoreOptionsClick={(row) => console.log(row)}
                    columns={gridColumns}
                    data={inventoryLogs}
                    onColumnClick={onGridColumnClick}
                    localization={localization.grid}
                    emptyStateText={localization.grid.emptyState}
                    showLoader={showLoader && (activeInventoryCount <= 1 || !gridColumns.length)}
                    userSettingKey={userSettingKey}
                    idKey="id"
                    resetFilterFunc={resetFilterFunc}
                    onRowClick={(row) => setDrawerOpen('PRODUCT', { productId: row.productId, tab: 'inventory' })}
                />
            </GridWrapper>
        </PageWrapper>
    );
};

const PageWrapper = styled.div`
    height: 100%;
    display: flex;
    flex-direction: column;
`;

const LoadingOverlay = styled.div`
    background-color: #edf8f9;
    padding: 6px 10px;
    border-radius: 7px;
    position: absolute;
    z-index: 1012;
    top: 10px;
    left: 50%;
    transform: translateX(-50%);
`;

const GridWrapper = styled.div`
    flex: 1;
    max-width: 100%;
    width: 100%;
    box-sizing: border-box;
    height: 100%;
    width: 100%;
    z-index: 1;
    overflow: overlay;

    @media screen and (min-width: 768px) {
        height: calc(100% - 127px);
    }

    @media screen and (max-width: 768px) {
        height: calc(100% - 135px - 40px);
    }

    @media screen and (max-height: 400px) {
        height: calc(100% - 135px);
    }
`;

export default InventoryPage;
