import { marketplace } from '../../../../../models/entities/company/company';
import marketplaces from '../../../../../static/data/marketplaces.json';

export const getMarketplacesDropDownOptions = (marketplacesIds?: string[], excludeMarketplaces?: string[]) =>
    Object.entries(marketplaces).reduce((acc, [region, regionMarketplaces]) => {
        const marketplacesOptions = regionMarketplaces
            .filter(
                (mp) =>
                    (marketplacesIds?.length ? marketplacesIds.includes(mp.marketplaceId) : true) && !excludeMarketplaces?.includes(mp.marketplaceId)
            )
            .map((marketplace) => ({
                text: marketplace.country,
                value: marketplace.marketplaceId
            }));

        if (marketplacesOptions.length) {
            (acc as any)[region] = marketplacesOptions;
        }

        return acc;
    }, {});

export const marketplacesDropDownOptions = getMarketplacesDropDownOptions();

export const getFlatMarketplaces = (marketplacesGroups: { [region: string]: marketplace[] }) =>
    Object.entries(marketplacesGroups)
        .reduce((acc: marketplace[], [_, regionMarketplaces]: [string, marketplace[]]) => [...acc, ...regionMarketplaces], [])
        .flat();

export const flatMarketplaces = getFlatMarketplaces(marketplaces);
