import { RootState } from '../../store/store';
import { createSelector } from 'reselect';
import businessPartnersSelectors from '../businessPartner/selectors';
import { document } from '../../../models/entities/businessPartner/document';
import { contact } from '../../../models/entities/businessPartner/contact';
import { byDate, byString } from '../../../utils/sortArray';
import { address } from '../../../models/entities/businessPartner/address';
import { discussion } from '../../../models/entities/common_subentities/discussion';

const currentCompanyId = (state: RootState) => state.singleBusinessPartner.businessPartnerId;

const businessPartner = createSelector(currentCompanyId, businessPartnersSelectors.businessPartners, (businessPartnerId, businessPartners) => {
    return businessPartners?.find((businessPartner) => businessPartner.id === businessPartnerId);
});

const documents = createSelector(currentCompanyId, businessPartnersSelectors.businessPartners, (businessPartnerId, businessPartners) => {
    const businessPartner = businessPartners?.find((item) => item.id === businessPartnerId);
    if (businessPartner) return businessPartner.documents?.documents?.sort(byDate(true, (document: document) => document.createdDate));
});
const contacts = createSelector(currentCompanyId, businessPartnersSelectors.businessPartners, (businessPartnerId, businessPartners) => {
    const businessPartner = businessPartners?.find((item) => item.id === businessPartnerId);
    if (businessPartner) return businessPartner.contacts?.sort(byString(true, (contact: contact) => contact.name));
});
const addresses = createSelector(currentCompanyId, businessPartnersSelectors.businessPartners, (businessPartnerId, businessPartners) => {
    const businessPartner = businessPartners?.find((item) => item.id === businessPartnerId);
    if (businessPartner) return businessPartner.addresses?.sort(byString(true, (address: address) => address.country));
});
const discussions = createSelector(currentCompanyId, businessPartnersSelectors.businessPartners, (businessPartnerId, businessPartners) => {
    const businessPartner = businessPartners?.find((item) => item.id === businessPartnerId);
    if (businessPartner) return businessPartner.discussions?.sort(byDate(true, (discussion: discussion) => discussion.createdDate));
});
export { default as singleBusinessPartnersSelectors } from './selectors';

export default {
    businessPartner,
    documents,
    contacts,
    addresses,
    discussions
};
