import React, { useEffect, useState } from 'react';
import CustomModal, { ModalTriggerProps } from '../Modal/Modal';
import { styled } from '../Theme/theme';
import { downloadFileByBlobUrl } from '../../../utils/files';
import PreviewFileAsync, { getUrlFuncType, PreviewBaseProps, PreviewContainer } from './Preview';
import { previewFileModel } from '../../../sdk/controllers/files/IFiles';
import { RootState } from '../../../state/store/store';
import { localizationSelectors } from '../../../state/ducks/localization/selectors';
import { connect } from 'react-redux';

type PreviewFileAsync = PreviewBaseProps & {
    triggerChildren: JSX.Element;
    getFileUrl?: getUrlFuncType;
    localization: any;
};

const Comp = ({ fileId, fileName, triggerChildren, getFileUrl, localization }: PreviewFileAsync) => {
    const [previewFile, setPreviewFile] = useState<undefined | previewFileModel>();
    return (
        <CustomModal
            title={{ text: fileName }}
            Trigger={(props: ModalTriggerProps) => <PreviewContainer onMouseDown={props.onClick}>{triggerChildren}</PreviewContainer>}
            Content={() => {
                return (
                    <ModalPreviewContainer>
                        <PreviewFileAsync
                            fileId={fileId}
                            fileName={fileName}
                            showPreview={true}
                            width="auto"
                            height="auto"
                            setParentFile={setPreviewFile}
                            customFileUrlFunc={getFileUrl}
                        />
                    </ModalPreviewContainer>
                );
            }}
            confirmBtn={{
                handler: () => previewFile && downloadFileByBlobUrl(previewFile.blobUrl, fileName),
                text: localization.modal.download_btn,
                closeModal: false
            }}
            width="90%"
            maxWidth="90%"
            fullScreen
        />
    );
};
export const ModalPreviewContainer = styled.div`
    height: 100%;
    display: flex;
    width: 100%;
    > * {
        margin: auto;
    }
`;
const mapStateToProps = (state: RootState) => ({
    localization: localizationSelectors.files(state).preview
});

const mapDispatchToProps = (dispatch: any) => ({});

export const ModalPreviewFileAsync = connect(mapStateToProps, mapDispatchToProps)(Comp);
