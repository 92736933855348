import React from 'react';
import styled from 'styled-components';

export type Props = {
    object: Object;
    localization?: any;
    filterCondition?: (key: string, value: any) => boolean;
    parseObjectValues?: (key: string, value: any) => string;
};

const ObjectDetails = ({ object, localization, filterCondition, parseObjectValues }: Props) => {
    return (
        <DetailsWrapper>
            {Object.entries(object)
                .filter(([key, value]) => {
                    const parsedValue = parseObjectValues ? parseObjectValues(key, value) : value;

                    return (!filterCondition ? true : filterCondition(key, parsedValue)) && typeof parsedValue !== 'object';
                })
                .map(([key, value]: [string, any], i: number) => {
                    const parsedValue = parseObjectValues ? parseObjectValues(key, value) : value;
                    return (
                        <Detail key={`${key}-${value}-detail-${i}`}>
                            <DetailName>{localization?.[key]?.title || localization?.[key] || key}:</DetailName>
                            <DetailValue>{parsedValue}</DetailValue>
                        </Detail>
                    );
                })}
        </DetailsWrapper>
    );
};

const DetailsWrapper = styled.div`
    width: auto;
`;
const Detail = styled.div`
    display: flex;
    flex-wrap: wrap;
    margin: 10px 0;
`;
const DetailName = styled.div`
    color: #394372;
    font-weight: bold;
    margin-right: 5px;
`;

const DetailValue = styled.div`
    color: #757575;
`;

export default ObjectDetails;
