import { RootState } from '../../store/store';
import { createSelector } from 'reselect';
import purchaseDemandsSelectors from '../purchaseDemands/selectors';
import { byDate } from '../../../utils/sortArray';
import { discussion } from '../../../models/entities/common_subentities/discussion';
import { purchaseDemandItem } from '../../../models/entities/purchaseDemand/purchaseDemandItem';
import { purchaseDemandApprover } from '../../../models/entities/purchaseDemand/purchaseDemandApprover';
import { history as historyModel } from '../../../models/entities/common_subentities/history';
import { document } from '../../../models/entities/common_subentities/document';

const currentPurchaseDemandId = (state: RootState) => state.singlePurchaseDemand.purchaseDemandId;
const products = (state: RootState) => state.products.products;
const fetchPurchaseDemandError = (state: RootState) => state.error.effects.purchaseDemands.fetchPurchaseDemandById;

const purchaseDemand = createSelector(currentPurchaseDemandId, purchaseDemandsSelectors.purchaseDemandsData, (purchaseDemandId, purchaseDemands) => {
    return purchaseDemands?.find((purchaseDemand) => purchaseDemand.id === purchaseDemandId);
});
const pdItemsRelatedProducts = createSelector(purchaseDemand, products, (purchaseDemand, products) => {
    return products.filter((product) => purchaseDemand?.items?.some((item) => item.productId === product.id));
});
const purchaseDemandState = createSelector(
    currentPurchaseDemandId,
    purchaseDemandsSelectors.purchaseDemandsData,
    (purchaseDemandId, purchaseDemands) => {
        const purchaseDemand = purchaseDemands?.find((purchaseDemand) => purchaseDemand.id === purchaseDemandId);
        if (purchaseDemand) return purchaseDemand.state;
    }
);
const discussions = createSelector(currentPurchaseDemandId, purchaseDemandsSelectors.purchaseDemandsData, (purchaseDemandId, purchaseDemands) => {
    const purchaseDemand = purchaseDemands?.find((item) => item.id === purchaseDemandId);
    if (purchaseDemand) return purchaseDemand.discussions?.sort(byDate(true, (discussion: discussion) => discussion.createdDate));
});
const items = createSelector(currentPurchaseDemandId, purchaseDemandsSelectors.purchaseDemandsData, (purchaseDemandId, purchaseDemands) => {
    const purchaseDemand = purchaseDemands?.find((item) => item.id === purchaseDemandId);
    if (purchaseDemand) return purchaseDemand.items;
});
const approvers = createSelector(currentPurchaseDemandId, purchaseDemandsSelectors.purchaseDemandsData, (purchaseDemandId, purchaseDemands) => {
    const purchaseDemand = purchaseDemands?.find((item) => item.id === purchaseDemandId);
    if (purchaseDemand) return purchaseDemand.approvers?.sort(byDate(true, (approver: purchaseDemandApprover) => approver.createdAt));
});
const history = createSelector(currentPurchaseDemandId, purchaseDemandsSelectors.purchaseDemandsData, (purchaseDemandId, purchaseDemands) => {
    const purchaseDemand = purchaseDemands?.find((item) => item.id === purchaseDemandId);
    if (purchaseDemand) return purchaseDemand.history?.sort(byDate(true, (history: historyModel) => history.createdDate));
});

const documents = createSelector(currentPurchaseDemandId, purchaseDemandsSelectors.purchaseDemandsData, (purchaseDemandId, purchaseDemands) => {
    const purchaseDemand = purchaseDemands?.find((item) => item.id === purchaseDemandId);
    if (purchaseDemand) return purchaseDemand.documents?.documents?.sort(byDate(true, (document: document) => document.createdDate));
});
export { default as singlePurchaseDemandSelectors } from './selectors';

export default {
    fetchPurchaseDemandError,
    purchaseDemand,
    purchaseDemandState,
    discussions,
    items,
    approvers,
    history,
    documents,
    pdItemsRelatedProducts
};
