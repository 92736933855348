import BaseController from '../..';
import { link } from '../../../../models/entities/product/link';
import IProductsLinks from './IProductLinks';

export default class ProductsLinks extends BaseController implements IProductsLinks {
    constructor(baseUrl: string) {
        super(baseUrl);
    }
    async createProductLink(productId: string, data: link) {
        const response = await this.client.post(`/product/links`, JSON.stringify({ productId, data }));
        return response;
    }
    async updateProductLink(productId: string, data: link) {
        const response = await this.client.put(`/product/links`, JSON.stringify({ productId, data }));
        return response;
    }
    async deleteProductLink(productId: string, linkId: number) {
        const response = await this.client.delete(`/product/links`, JSON.stringify({ id: linkId, productId }));
        return response;
    }
}
