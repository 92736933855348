import BaseController from '..';
import { category, createCategoryPayload, updateCategoryPayload } from '../../../models/entities/category/category';
import ICategories from './ICategories';

export default class Categories extends BaseController implements ICategories {
    constructor(baseUrl: string) {
        super(baseUrl);
    }
    async getAllCategories(): Promise<category[]> {
        const response = await this.client.get(`/catalog/categories`);
        return response;
    }
    async getCategoriesByCompanyId(companyId: string): Promise<category[]> {
        const response = await this.client.get(`/catalog/categories/company/${companyId}`);
        return response;
    }
    async createCategory(payload: createCategoryPayload): Promise<category> {
        const response = await this.client.post(`/catalog/categories`, JSON.stringify(payload));
        return response;
    }
    async updateCategory(payload: updateCategoryPayload): Promise<category> {
        const response = await this.client.put(`/catalog/categories`, JSON.stringify(payload));
        return response;
    }
    async deleteCategory(categoryId: string): Promise<void> {
        const response = await this.client.delete(`/catalog/categories`, JSON.stringify({ id: categoryId }));
        return response;
    }
}
