import React, { useEffect, useState } from 'react';
import { settings, purchaseDemand } from '../../../../models/entities/purchaseDemand/purchaseDemand';
import { localizationSelectors } from '../../../../state/ducks/localization/selectors';
import { purchaseDemandsSelectors } from '../../../../state/ducks/purchaseDemands/selectors';
import { RootState } from '../../../../state/store/store';
import { connect } from 'react-redux';
import { singlePurchaseDemandSelectors } from '../../../../state/ducks/singlePurchaseDemand/selectors';
import { document } from '../../../../models/entities/common_subentities/document';
import DocumentFormConnected, { formMode } from '../../commonSubEntities/documents/DocumentForm/DocumentFormConnected';

type Props = {
    purchaseDemand?: purchaseDemand;
    document?: document;
    mode: formMode;
    localizations?: any;
    settings?: settings;
    openedFromModal?: {
        setVisible: React.Dispatch<React.SetStateAction<boolean>>;
    };
    confirmation?: { setConfirm: React.Dispatch<React.SetStateAction<boolean>> };
    createPurchaseDemandDocument: (purchaseDemandNumber: string, document: document) => void;
    updatePurchaseDemandDocument: (purchaseDemandNumber: string, document: document) => void;
    showLoader?: boolean;
    defaultValues?: document;
};

const PurchaseDemandDocumentFormConnected = ({
    purchaseDemand,
    document,
    localizations,
    mode,
    settings,
    openedFromModal,
    confirmation,
    createPurchaseDemandDocument,
    updatePurchaseDemandDocument,
    showLoader,
    defaultValues
}: Props) => {
    return (
        <DocumentFormConnected<purchaseDemand, settings>
            entity={purchaseDemand}
            document={document}
            localizations={localizations}
            mode={mode}
            settings={settings}
            openedFromModal={openedFromModal}
            confirmation={confirmation}
            createDocument={createPurchaseDemandDocument}
            updateDocument={updatePurchaseDemandDocument}
            showLoader={showLoader}
            defaultValues={defaultValues}
        />
    );
};
const mapStateToProps = (state: RootState) => ({
    localizations: localizationSelectors.singlePurchaseDemandPage(state),
    settings: purchaseDemandsSelectors.settingsByLanguage(state),
    purchaseDemand: singlePurchaseDemandSelectors.purchaseDemand(state),
    showLoader:
        state.loading.effects.purchaseDemands.createPurchaseDemandDocument || state.loading.effects.purchaseDemands.updatePurchaseDemandDocument
});

const mapDispatchToProps = (dispatch: any) => ({
    createPurchaseDemandDocument: (purchaseDemandNumber: string, document: document) =>
        dispatch.purchaseDemands.createPurchaseDemandDocument({ purchaseDemandNumber, document }),
    updatePurchaseDemandDocument: (purchaseDemandNumber: string, document: document) =>
        dispatch.purchaseDemands.updatePurchaseDemandDocument({ purchaseDemandNumber, document })
});

export default connect(mapStateToProps, mapDispatchToProps)(PurchaseDemandDocumentFormConnected);
