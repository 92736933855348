import React from 'react';
import { Field } from 'formik-antd';
import TextInput, { BaseProps } from '../base/TextInput';
import { FieldProps } from 'formik';
import { ErrorText } from '.';

type Props = BaseProps & {
    name: string;
};

const FormTextInput = ({ name, ...restProps }: Props) => {
    return (
        <Field name={name}>
            {({ field, meta }: FieldProps) => {
                const { touched, error } = meta;
                const hasError = touched && error !== undefined;
                return (
                    <div>
                        <TextInput field={field} hasError={hasError} {...restProps} />
                        <ErrorText>{hasError && error}</ErrorText>
                    </div>
                );
            }}
        </Field>
    );
};

export default FormTextInput;
