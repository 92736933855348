import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { RootState } from '../../../state/store/store';
import { userDetails } from '../../../state/ducks/users/users';
import HexagonAvatar from '../SmallComponents/HexagonAvatar';
import { styled } from '../../shared/Theme/theme';
import { usersSelectors } from '../../../state/ducks/users/selectors';
import { UserImageAvatar } from '../Layout/Header/AvatarHeader';
import { userInfo } from '../../../models/entities/user';
import { getUserStatusTheme } from '../Theme/util';
import UserCard from '../UserCard/UserCard';

type Props = {
    getUserPicture: (userId: string) => string;
    getUserById: (userId: string) => userDetails | undefined;
    isLoadingUser: boolean;
    userId: string;
    userPictureUrl?: string;
    userName?: string;
    borderColor?: string;
    width: number;
    hideBadgeName?: boolean;
    popoverLeft?: boolean;
    toggleOnClick?: boolean;
    flexRow?: boolean;
};

const UserBadge = ({
    getUserPicture,
    getUserById,
    width,
    userId,
    userPictureUrl,
    userName,
    borderColor,
    hideBadgeName,
    popoverLeft,
    toggleOnClick,
    flexRow
}: Props) => {
    const [user, setUser] = useState<userDetails | null | undefined>(null);

    useEffect(() => {
        setUser(getUserById(userId));
    }, [getUserById, userId]);

    return (
        <UserCard userId={userId} userName={userName} popoverLeft={popoverLeft} toggleOnClick={toggleOnClick}>
            <BadgeContainer aria-owns="mouse-over-popover" aria-haspopup="true" flexRow={flexRow}>
                <HexagonAvatar
                    width={width}
                    img={UserImageAvatar(
                        { userName: user?.name || userName, avatarColor: user?.avatarColor, profileImgUrl: user?.profileImgUrl } as userDetails,
                        width
                    )}
                    borderColor={(getUserStatusTheme(user?.status || 'OOTO').color || borderColor) ?? '#00b5b9'}
                />
                {(userName || user?.name) && !hideBadgeName && <span className="user-name">{userName || user?.name}</span>}
            </BadgeContainer>
        </UserCard>
    );
};

const BadgeContainer = styled.div<{ flexRow?: boolean }>`
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    ${(props) => {
        if (props.flexRow) {
            return `flex-direction: row;`;
        }
    }}

    .user-name {
        color: #00b5b9;
        font-size: 11px;
        margin-top: 8px;
        ${(props) => {
            if (props.flexRow) {
                return `margin-top:0px;margin-left:8px;`;
            }
        }}
    }
`;

const mapStateToProps = (state: RootState) => ({
    isLoadingUser: state.loading.effects.users.addUser,
    getUserPicture: (id: string) => usersSelectors.userPicture(state, id),
    getUserById: (id: string) => usersSelectors.getUserById(state)(id)
});

const mapDispatchToProps = (dispatch: any) => ({});

export default connect(mapStateToProps, mapDispatchToProps)(UserBadge);
