import React from 'react';
import { eShipmentTypes } from '../../../models/entities/shipment/shipmentTypes';
import ShipmentType from '../Grid/specialColumns/ShipmentType';
import CZTooltip from '../CZTooltip/CZTooltip';

type Props = {
    typeCode: string;
    subTypeCode?: string;
    iconWidth?: number;
};
const ShipmentTypeWithTooltip = ({ typeCode, subTypeCode, iconWidth }: Props) => {
    return (
        <CZTooltip text={`${eShipmentTypes[typeCode]}${!!subTypeCode?.trim() ? ` - ${subTypeCode}` : ''}`}>
            <span>
                <ShipmentType value={typeCode} imgWidth={iconWidth} />
            </span>
        </CZTooltip>
    );
};

export default ShipmentTypeWithTooltip;
