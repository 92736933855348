import React from 'react';
import Menu from '../../Menu/Menu';
import MenuItem from '../../Menu/MenuItem';
import edit from '../../../../static/icons/edit.svg';
import trash from '../../../../static/icons/trash.svg';
import CellTrigger from '../../Menu/MenuTriggers/CellTrigger';
import { RootState } from '../../../../state/store/store';
import { connect } from 'react-redux';
import { singleShipmentSelectors } from '../../../../state/ducks/singleShipment/selectors';
import { shipment } from '../../../../models/entities/shipment/shipment';
import { userSelectors } from '../../../../state/ducks/user/selectors';
import claimsType from '../../../../models/entities/claimsType';
import { userInfo } from '../../../../models/entities/user';
import { claimsDomainsType } from '../../../../userClaims';
type onRowMoreOptionClickType = () => void;
type deleteShipmentEventType = (shipmentNumber: string, eventId: number, userId: string) => void;
type Props = {
    entityId: number;
    userId: string;
    localization: any;
    onRowMoreOptionsClick?: onRowMoreOptionClickType;
    deleteShipmentEvent: deleteShipmentEventType;
    getUserClaims: (domain: claimsDomainsType) => claimsType;
    shipment?: shipment;
    userInfo: userInfo;
};

const ShipmentEventMenu = ({
    entityId,
    shipment,
    localization,
    userId,
    userInfo,
    onRowMoreOptionsClick,
    deleteShipmentEvent,
    getUserClaims
}: Props) => {
    const { edit_event: editClaim, delete_event: deleteClaim } = getUserClaims('shipment');

    const isEventCreatedByCurrentUser = userId === userInfo.id;

    const editItem = <MenuItem onClick={() => onRowMoreOptionsClick && onRowMoreOptionsClick()} icon={edit} text={localization.edit} />;
    const deleteItem = (
        <MenuItem onClick={() => shipment && deleteShipmentEvent(shipment.id, entityId, userId)} icon={trash} text={localization.delete} />
    );

    const menuItems =
        isEventCreatedByCurrentUser || (editClaim && deleteClaim) ? [editItem, deleteItem] : editClaim ? [editItem] : deleteClaim ? [deleteItem] : [];

    return menuItems.length ? (
        <Menu options={menuItems} MenuTrigger={CellTrigger} strStyle="display: flex;align-items: center;width: 100%;height: 100%;" />
    ) : (
        <div></div>
    );
};

const mapStateToProps = (state: RootState) => ({
    shipment: singleShipmentSelectors.shipment(state),
    getUserClaims: (domain: claimsDomainsType) => userSelectors.getUserClaims(state)(domain),
    userInfo: userSelectors.userInfo(state)
});

const mapDispatchToProps = (dispatch: any) => ({
    deleteShipmentEvent: (shipmentNumber: string, eventId: number, userId: string) =>
        dispatch.shipments.deleteShipmentEvent({ shipmentNumber, eventId, userId })
});

export default connect(mapStateToProps, mapDispatchToProps)(ShipmentEventMenu);
