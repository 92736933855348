import React, { useState } from 'react';
import { eQuotationState } from '../../../../../models/entities/bid/quotation';
import { currencyFormatter } from '../../../../shared/inputs/form/CurrencyInput/CurrencyInput';
import { styled } from '../../../../shared/Theme/theme';
import moment from 'moment';
import { connect } from 'react-redux';
import { RootState } from '../../../../../state/store/store';
import Button from '../../../../shared/SmallComponents/Button';
import { tariffQuotation } from '../../../../../models/entities/shipment/tariffQuotation';

type Props = {
    params: any;
};

const QuotationPane = ({ params }: Props) => {
    const { tariffQuotation, shipmentId }: { tariffQuotation: tariffQuotation; shipmentId: string } = params;
    const [state, setState] = useState(tariffQuotation.state);

    const approve = async () => {
        console.log('approve');
    };

    return (
        <Container>
            <Header>Tariff Quotation</Header>
            <Details>
                <BoxDetail>
                    <BoxTitle>{'Price'}</BoxTitle>
                    <BoxValue>
                        {tariffQuotation.freightPrice ? currencyFormatter(tariffQuotation?.freightPriceCurrency)(tariffQuotation.freightPrice) : '-'}
                    </BoxValue>
                </BoxDetail>
                <BoxDetail>
                    <BoxTitle>{'Transit Time'}</BoxTitle>
                    <BoxValue>{tariffQuotation.transitTime ? `${tariffQuotation.transitTime}d` : '-'}</BoxValue>
                </BoxDetail>
                <BoxDetail>
                    <BoxTitle>{'Valid Untill'}</BoxTitle>
                    <BoxValue>{moment(tariffQuotation.validTill).isValid() ? moment(tariffQuotation.validTill).format('DD/MM/YY') : '-'}</BoxValue>
                </BoxDetail>
                <BoxDetail>
                    {true ? (
                        <BoxValue style={{ display: 'flex' }}>
                            <Button buttonType="filled" onClick={approve}>
                                {'Confirm Btn'}
                            </Button>
                        </BoxValue>
                    ) : (
                        <BoxValue>{eQuotationState[state]}</BoxValue>
                    )}
                </BoxDetail>
            </Details>
            <br />
            <BoxDetail>
                <BoxTitle>{'Note'}</BoxTitle>
                <BoxValue style={{ fontSize: '12px' }}>{tariffQuotation.note || '-'}</BoxValue>
            </BoxDetail>
        </Container>
    );
};
const Container = styled.div`
    padding-left: 23px;
    padding-right: 20px;
    padding-top: 48px;
    padding-bottom: 20px;
`;
const Header = styled.div`
    color: ${(props) => props.theme.colors.pageTitle};
    font-size: 18px;
    padding-bottom: 15px;
`;
const Details = styled.div`
    display: flex;
`;
const BoxDetail = styled.div`
    flex: 1;
`;
const BoxTitle = styled.div`
    font-size: 14px;
    color: #95939b;
`;
const BoxValue = styled.div`
    font-size: 20px;
    color: #413e48;
    white-space: pre;
`;
const DeumurrageFreeDays = styled.div`
    padding-top: 15px;
    color: ${(props) => props.theme.colors.pageTitle};
`;

const mapStateToProps = (state: RootState) => ({});

const mapDispatchToProps = (dispatch: any) => ({});

export default connect(mapStateToProps, mapDispatchToProps)(QuotationPane);
