import React, { useState } from 'react';
import { history, diffType } from '../../../../models/entities/common_subentities/history';
import { styled } from '../../../shared/Theme/theme';
import HistoryItem from './HistoryItem';
import HistoryActiveItem from './HistoryActiveItem';
import moment from 'moment';
type Props = {
    localization: any;
    history?: Array<history>;
    excludedFields?: string[];
};

const History = ({ localization, history, excludedFields }: Props) => {
    if (!history) return null;
    history = history.filter((h) => !['PACKAGES', 'PARTIES'].includes(h.entityType));

    const calcNewDiff = (lastDiff: diffType, currentDiff: diffType) => {
        const newDiff: diffType = {};

        const entries = Object.entries(lastDiff);
        for (const entry of entries) {
            const [key, value] = entry;

            if (!(value?.old && value?.new)) continue;

            if (!currentDiff[key]) {
                newDiff[key] = value;
                continue;
            }

            const { old: currentOld } = currentDiff[key];
            if (Array.isArray(value.old)) {
                newDiff[key] = { new: value.new, old: [...value.old, currentOld] };
                continue;
            }

            newDiff[key] = { new: value.new, old: [value.old, currentOld] };
        }

        return { ...currentDiff, ...newDiff };
    };
    history = history.reduce((total: Array<history>, currentItem: history) => {
        const { userId, subEntityId, actionType, entityType, createdDate } = currentItem;
        const lastItem = total[total.length - 1];

        if (
            lastItem &&
            userId === lastItem.userId &&
            subEntityId == lastItem.subEntityId &&
            actionType == lastItem.actionType &&
            entityType == lastItem.entityType
        ) {
            const isInHour = moment(lastItem.createdDate).isBetween(moment(createdDate), moment(createdDate).add(1, 'hours'), null, '[]');

            if (isInHour && lastItem.diff && currentItem.diff) {
                const newDiff: diffType = calcNewDiff(lastItem.diff, currentItem.diff);
                total[total.length - 1] = { ...lastItem, diff: newDiff };
            } else {
                total.push(currentItem);
            }
        } else {
            total.push(currentItem);
        }
        return total;
    }, []);

    // eslint-disable-next-line react-hooks/rules-of-hooks
    const [activeItem, setActiveItem] = useState<history | null>(history.length > 0 ? history[0] : null);
    return (
        <HistoryWrapper>
            <LeftSide>
                {history.map((item) => {
                    return (
                        <div key={item.id}>
                            <HistoryItem
                                item={item}
                                localization={localization}
                                onClick={() => (item.id !== activeItem?.id ? setActiveItem(item) : setActiveItem(null))}
                                isActive={activeItem?.id === item.id}
                            />
                            <MobileBehavior>{activeItem?.id === item.id && <HistoryActiveItem item={activeItem} history={history} />}</MobileBehavior>
                        </div>
                    );
                })}
            </LeftSide>
            <RightSide>{activeItem && <HistoryActiveItem item={activeItem} history={history} excludedFields={excludedFields} />}</RightSide>
        </HistoryWrapper>
    );
};

const HistoryWrapper = styled.div`
    display: flex;
`;

const LeftSide = styled.div`
    width: 50%;
    max-height: 300px;
    overflow: auto;
    @media screen and (max-width: 768px) {
        width: 100%;
    }
`;

const RightSide = styled.div`
    width: 50%;
    padding: 2px 5px;
    max-height: 300px;
    overflow: auto;

    @media screen and (max-width: 768px) {
        display: none;
    }
`;
const MobileBehavior = styled.div`
    display: none;
    @media screen and (max-width: 768px) {
        display: initial;
    }
`;

export default History;
