import React, { useRef } from 'react';
import styled from 'styled-components';
import DropDownOption from './DropDownOption';
import { useOutsideClickHandler } from '../../../hooks/handleClickOutside';
import { FadeInAnimation } from '../../animations/Animations';

type Props = {
    options: Array<DropDownItem>;
    selectedOption: DropDownItem | undefined;
    onSelect: (selectedOption: DropDownItem) => void;
    onToggle: () => void;
    onClickOutside: () => void;
    width: string;
    height: number;
    isOpen: boolean;
    backgroundColor?: string;
};

export type DropDownItem = {
    text: string;
    value: string;
    isSelected: boolean;
    icon: () => JSX.Element;
};

function ColoredDropDown<T>(props: Props) {
    const dropdownRef = useRef(null);
    useOutsideClickHandler(dropdownRef, props.onClickOutside);

    return (
        <div ref={dropdownRef}>
            <DropDownDisplay onClick={props.onToggle} height={props.height} backgroundColor={props.backgroundColor}>
                <span className="icon-name">
                    <span className="icon">{props.selectedOption?.icon()}</span> {props.selectedOption?.text}
                </span>
                <span className="material-icons">keyboard_arrow_down</span>
            </DropDownDisplay>
            {props.isOpen && (
                <OptionsList>
                    {props.options.map((option) => (
                        <DropDownOption
                            onSelect={(option) => props.onSelect(option)}
                            key={option.value}
                            height={props.height + 'px'}
                            option={option}
                        />
                    ))}
                </OptionsList>
            )}
        </div>
    );
}

interface DropDownDisplayProps {
    readonly height: number;
    backgroundColor?: string;
}

const DropDownDisplay = styled.div<DropDownDisplayProps>`
    height: ${(props) => props.height - 2 + 'px'};
    width: 100%;
    cursor: pointer;
    font-size: 11px;
    color: #3d4e7e;
    background: ${(props) => props.backgroundColor ?? 'white'};
    position: relative;
    display: flex;
    align-items: center;
    justify-content: space-around;
    padding: 7px 6px 7px 7px;

    & span {
    }

    .icon-name {
        display: flex;
        align-items: center;
        flex: 1;
        .icon {
        }
    }
`;

const OptionsList = styled.div`
    position: relative;
    border-top: 1px solid #e0e0e0;
    border-right: 1px solid #e0e0e0;
    border-left: 1px solid #e0e0e0;
    margin-left: -1px;
    margin-right: -1px;
    box-shadow: 0 3px 6px -4px rgba(0, 0, 0, 0.12), 0 6px 16px 0 rgba(0, 0, 0, 0.08), 0 9px 28px 8px rgba(0, 0, 0, 0.05);
    z-index: 2;
    animation: ${FadeInAnimation} 0.75s;
    background-color: #fff;

    &:hover {
        border-top: 1px solid #e1e3e2;
        border-right: 1px solid #e1e3e2;
        border-left: 1px solid #e1e3e2;
    }
`;

export default ColoredDropDown;
