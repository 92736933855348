import BaseController from '..';
import { purchaseDemand } from '../../../models/entities/purchaseDemand/purchaseDemand';
import { filterFieldsType } from '../../../state/ducks/allPurchaseDemandsPage/allPurchaseDemandsPage';
import PurchaseDemandItems from './items/purchaseDemandItems';
import PurchaseDemandApprovers from './approvers/purchaseDemandApprovers';
import IPurchaseDemands from './IPurchaseDemands';
import PurchaseDemandDiscussions from './discussions/purchaseDemandDiscussions';
import PurchaseDemandDocuments from './documents/purchaseDemandDocuments';
import PurchaseDemandHistory from './history/purchaseDemandHistory';

export default class PurchaseDemands extends BaseController implements IPurchaseDemands {
    private purchaseDemandItems: PurchaseDemandItems;
    private purchaseDemandApprovers: PurchaseDemandApprovers;
    private purchaseDemandDiscussions: PurchaseDemandDiscussions;
    private purchaseDemandDocuments: PurchaseDemandDocuments;
    private purchaseDemandHistory: PurchaseDemandHistory;
    constructor(baseUrl: string) {
        super(baseUrl);
        this.purchaseDemandItems = new PurchaseDemandItems(baseUrl);
        this.purchaseDemandApprovers = new PurchaseDemandApprovers(baseUrl);
        this.purchaseDemandDiscussions = new PurchaseDemandDiscussions(baseUrl);
        this.purchaseDemandDocuments = new PurchaseDemandDocuments(baseUrl);
        this.purchaseDemandHistory = new PurchaseDemandHistory(baseUrl);
    }
    async fetchPurchaseDemands() {
        const response = await this.client.get('/purchaseDemands/allActive');
        return response;
    }
    async fetchById(purchaseDemandNumber: string) {
        const response = await this.client.get(`/purchaseDemands/id/${purchaseDemandNumber}`);
        return response;
    }
    async fetchByCZNumber(czNumber: string) {
        const response = await this.client.get(`/purchaseDemands/search/${czNumber}`);
        return response;
    }
    async fetchByIds(purchaseDemandsIds: string[]) {
        const response = await this.client.post(`/purchaseDemands/getByIds`, JSON.stringify({ purchaseDemandsIds }));
        return response;
    }
    async fetchPurchaseDemandsByFilter(filter: filterFieldsType) {
        const response = await this.client.post('/purchaseDemands/filtered', JSON.stringify({ filters: filter }));
        return response;
    }
    async createPurchaseDemand(purchaseDemand: purchaseDemand) {
        const response = await this.client.post('/purchaseDemands', JSON.stringify({ data: purchaseDemand }));
        return response;
    }
    async updatePurchaseDemand(id: string, purchaseDemand: purchaseDemand) {
        const response = await this.client.put('/purchaseDemands', JSON.stringify({ purchaseDemandId: id, data: purchaseDemand }));
        return response;
    }
    async restorePurchaseDemand(purchaseDemandNumber: string) {
        const response = await this.client.put(`/purchaseDemands/restore`, JSON.stringify({ entityId: purchaseDemandNumber }));
        return response;
    }
    async deletePurchaseDemand(purchaseDemandNumber: string) {
        await this.client.delete(`/purchaseDemands/id/${purchaseDemandNumber}`);
    }
    items() {
        return this.purchaseDemandItems;
    }
    discussions() {
        return this.purchaseDemandDiscussions;
    }
    approvers() {
        return this.purchaseDemandApprovers;
    }

    documents() {
        return this.purchaseDemandDocuments;
    }
    history() {
        return this.purchaseDemandHistory;
    }

    async fetchPurchaseDemandItems(purchaseDemandNumber: string) {
        const response = await this.client.get(`/purchaseDemand/items/${purchaseDemandNumber}`);
        return response;
    }

    async updatePurchaseDemandState(purchaseDemandId: string, state: string) {
        const response = await this.client.put(`/purchaseDemands/state`, JSON.stringify({ purchaseDemandId, data: { state } }));
        return response;
    }
}
