import React from 'react';
import { purchaseDemand } from '../../../../models/entities/purchaseDemand/purchaseDemand';
import { connect } from 'react-redux';
import { Dispatch, RootState } from '../../../../state/store/store';
import { localizationSelectors } from '../../../../state/ducks/localization/selectors';
import PurchaseDemandForm from './PurchaseDemandForm';
import { userSelectors } from '../../../../state/ducks/user/selectors';
import { allowedCompany, userDropDown } from '../../../../models/entities/user';
import { Option } from '../../../shared/inputs/base/DropDown';

export type BaseProps = {
    localizations?: any;
    createPurchaseDemand: (purchaseDemand: purchaseDemand) => Promise<string>;
    allowedCompanies: allowedCompany[];
    openedFromModal?: {
        setVisible: React.Dispatch<React.SetStateAction<boolean>>;
    };
    usersInSameCompany: userDropDown[] | undefined;
    allowedCompaniesDropDownOptions: Option[];
};
type Props = BaseProps & {};
const SinglePurchaseDemandFormConnected = ({
    localizations,
    createPurchaseDemand,
    allowedCompanies,
    openedFromModal,
    usersInSameCompany,
    allowedCompaniesDropDownOptions
}: Props) => {
    return (
        <PurchaseDemandForm
            localizations={localizations}
            createPurchaseDemand={createPurchaseDemand}
            allowedCompanies={allowedCompanies}
            openedFromModal={openedFromModal}
            usersInSameCompany={usersInSameCompany}
            allowedCompaniesDropDownOptions={allowedCompaniesDropDownOptions}
        />
    );
};

const mapStateToProps = (state: RootState) => ({
    localizations: localizationSelectors.allPurchaseDemandsPage(state),
    allowedCompanies: userSelectors.allowedCompanies(state),
    allowedCompaniesDropDownOptions: userSelectors.allowedCompaniesDropDownOptions(state),
    usersInSameCompany: userSelectors.usersInSameCompany(state)
});

const mapDispatchToProps = (dispatch: any) => ({
    createPurchaseDemand: (purchaseDemand: purchaseDemand) => dispatch.purchaseDemands.createPurchaseDemand({ purchaseDemand })
});

export default connect(mapStateToProps, mapDispatchToProps)(SinglePurchaseDemandFormConnected);
