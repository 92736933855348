import React from 'react';
import { NestedMenuItem, IconMenuItem } from './components';
import { ContextMenuItemType } from './types';
import { Modal } from 'antd';

type Props = {
    items: ContextMenuItemType[];
    isOpen: boolean;
    handleClose: () => void;
};

export const nestedMenuItemsFromObject = ({ items, isOpen, handleClose }: Props) => {
    return items.map((item, index) => {
        const { leftIcon, rightIcon, label, items: NestedItems, callback, uid, showConfirm } = item;

        const isLast = index === items.length - 1;

        if (NestedItems) {
            return (
                <NestedMenuItem
                    key={uid || label}
                    leftIcon={leftIcon}
                    rightIcon={rightIcon}
                    label={label}
                    parentMenuOpen={isOpen}
                    className={isLast ? 'lastItem' : undefined}
                >
                    {nestedMenuItemsFromObject({ items: NestedItems, isOpen, handleClose })}
                </NestedMenuItem>
            );
        } else {
            return (
                <IconMenuItem
                    key={uid || label}
                    leftIcon={leftIcon}
                    rightIcon={rightIcon}
                    label={label}
                    className={isLast ? 'lastItem' : undefined}
                    onClick={async (e) => {
                        handleClose();
                        if (showConfirm) {
                            Modal.confirm({
                                zIndex: 1100,
                                content: <div>{'Are you sure?'}</div>,
                                async onOk() {
                                    await callback?.();
                                },
                                onCancel() {}
                            });
                            return;
                        }
                        await callback?.();
                    }}
                />
            );
        }
    });
};
