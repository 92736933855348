import React from 'react';
import { userInfo } from '../../../../models/entities/user';
import theme, { styled } from '../../Theme/theme';
import { getUserStatusTheme } from '../../Theme/util';
import HexagonAvatar, { AvatarNameDiv } from '../../SmallComponents/HexagonAvatar';
import { userDetails } from '../../../../state/ducks/users/users';
const avatarTheme = theme.colors.header.avatar;

type Props = {
    userInfo: userDetails;
    isOpen: boolean;
    hasGoBackBtn: boolean;
    setMenuOpen: () => void;
};

const AvatarHeader = ({ userInfo, isOpen, setMenuOpen, hasGoBackBtn }: Props) => {
    const statusTheme = getUserStatusTheme(userInfo.status);
    return (
        <AvatarWrapper onClick={setMenuOpen} hasGoBackBtn={hasGoBackBtn}>
            <HexagonAvatar width={40} img={UserImageAvatar(userInfo)} borderColor={statusTheme.color} />
        </AvatarWrapper>
    );
};

export const UserImageAvatar = (userInfo: userDetails, avatarSize?: number) => {
    let { firstName, lastName, userName, avatarColor } = userInfo;
    if (!firstName && !lastName && userName) {
        const splitted = userName.split(' ');
        firstName = splitted[0];
        lastName = splitted[splitted.length - 1];
    }
    return (
        userInfo.profileImgUrl || (
            <AvatarNameDiv color={avatarColor} fontSize={avatarSize ? avatarSize / 2 - 6 : 14}>
                {`${firstName?.charAt(0).toUpperCase() + lastName?.charAt(0).toUpperCase()}`}
            </AvatarNameDiv>
        )
    );
};

export interface AvatarWrapperProps {
    hasGoBackBtn: boolean;
}

const AvatarWrapper = styled.div<AvatarWrapperProps>`
    width: 70px;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    border-left: 2px solid ${avatarTheme.borderColor};
    cursor: pointer;
    &:hover {
        background: ${avatarTheme.hoverBG};
    }
    @media screen and (max-width: 576px) {
        display: ${(props) => (props.hasGoBackBtn ? 'none' : 'inherit')};
        position: absolute;
        border-right: 2px solid ${avatarTheme.borderColor};
        border-left: none;
        left: 0;
    }
`;

export default AvatarHeader;
