import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import claimsType from '../../../../../../models/entities/claimsType';
import { product } from '../../../../../../models/entities/product/product';
import { purchaseDemand } from '../../../../../../models/entities/purchaseDemand/purchaseDemand';
import { purchaseDemandItem } from '../../../../../../models/entities/purchaseDemand/purchaseDemandItem';
import { drawers } from '../../../../../../state/ducks/header/header';
import { localizationSelectors } from '../../../../../../state/ducks/localization/selectors';
import { singlePurchaseDemandSelectors } from '../../../../../../state/ducks/singlePurchaseDemand/selectors';
import { userSelectors } from '../../../../../../state/ducks/user/selectors';
import { RootState } from '../../../../../../state/store/store';
import { claimsDomainsType } from '../../../../../../userClaims';
import PdItemsGrid from './PdItemsGrid';

type Props = {
    values: any;
    setFieldValue: any;
    hideBottomDetails?: boolean;
    localization: any;
    isProductModalOpened?: boolean;
    onDeleteItem?: (itemId: string) => void;
    onAddItemsCallback?: (items: purchaseDemandItem[]) => Promise<void>;
    onUpdatePurchaseDemandItem?: (updatedItem: purchaseDemandItem, itemIndex?: string, cancelTimeoutInterval?: boolean) => Promise<void>;
    onUpdateItemCost?: (updatedItem: purchaseDemandItem) => Promise<void>;
    setDrawerOpen: (open: drawers, params?: any) => void;
    getUserClaims: (domain: claimsDomainsType) => claimsType;
    updateProduct: (id: string, product: product, deletedSuppliers?: string[]) => Promise<boolean>;
    fetchProducts: (ids: string[]) => Promise<Array<product>>;
    purchaseDemand?: purchaseDemand;
    pdItemsRelatedProducts: Array<product>;
    disableAdd?: boolean;
    disableEdit?: boolean;
    disableDelete?: boolean;
};

const Items = ({
    values,
    setFieldValue,
    hideBottomDetails,
    localization,
    isProductModalOpened,
    onDeleteItem,
    onAddItemsCallback,
    onUpdatePurchaseDemandItem,
    onUpdateItemCost,
    setDrawerOpen,
    getUserClaims,
    updateProduct,
    fetchProducts,
    purchaseDemand,
    pdItemsRelatedProducts,
    disableAdd,
    disableEdit,
    disableDelete
}: Props) => {
    useEffect(() => {
        if (values.items?.length && purchaseDemand?.id) {
            const productsIds: string[] = values.items.map((item: purchaseDemandItem) => item.productId);
            fetchProducts(productsIds);
        }
    }, [values.items?.length, purchaseDemand?.id]);
    return (
        <div>
            <PdItemsGrid
                isProductModalOpened={isProductModalOpened}
                values={values}
                setFieldValue={setFieldValue}
                localization={{ ...localization, ...localization.form }}
                hideBottomDetails={hideBottomDetails}
                onDeleteItem={onDeleteItem}
                onAddItemsCallback={onAddItemsCallback}
                onUpdatePurchaseDemandItem={onUpdatePurchaseDemandItem}
                onUpdateItemCost={onUpdateItemCost}
                setDrawerOpen={setDrawerOpen}
                purchaseDemand={purchaseDemand}
                getUserClaims={getUserClaims}
                updateProduct={updateProduct}
                pdItemsRelatedProducts={pdItemsRelatedProducts}
                disableAdd={disableAdd}
                disableEdit={disableEdit}
                disableDelete={disableDelete}
            />
        </div>
    );
};
const mapStateToProps = (state: RootState) => ({
    localization: localizationSelectors.allPurchaseDemandsPage(state),
    getUserClaims: (domain: claimsDomainsType) => userSelectors.getUserClaims(state)(domain),
    pdItemsRelatedProducts: singlePurchaseDemandSelectors.pdItemsRelatedProducts(state)
});

const mapDispatchToProps = (dispatch: any) => ({
    setDrawerOpen: (open: drawers, params?: any) => dispatch.header.setDrawerOpenParams({ open, params }),
    updateProduct: (id: string, product: product, deletedSuppliers?: string[]) => dispatch.products.updateProduct({ id, product, deletedSuppliers }),
    fetchProducts: (ids: string[]) => dispatch.products.fetchProductsByIds(ids)
});

export default connect(mapStateToProps, mapDispatchToProps)(Items);
