import React from 'react';
import edit from '../../../../static/icons/edit.svg';
import trash from '../../../../static/icons/trash.svg';
import CellTrigger from '../../Menu/MenuTriggers/CellTrigger';
import { RootState } from '../../../../state/store/store';
import { connect } from 'react-redux';
import { singlePurchaseOrderSelectors } from '../../../../state/ducks/singlePurchaseOrder/selectors';
import { purchaseOrder } from '../../../../models/entities/purchaseOrder/purchaseOrder';
import Menu from '../../Menu/Menu';
import MenuItem from '../../Menu/MenuItem';
type onRowMoreOptionClickType = () => void;
type deletePurchaseOrderDocumentType = (purchaseOrderId: string, documentId: string) => void;
type Props = {
    entityId: string;
    localization: any;
    onRowMoreOptionsClick?: onRowMoreOptionClickType;
    deletePurchaseOrderDocumentSupplierUpdates: deletePurchaseOrderDocumentType;
    purchaseOrder?: purchaseOrder;
};

const PurchaseOrderDocumentMenuSupplierUpdates = ({
    entityId,
    purchaseOrder,
    localization,
    onRowMoreOptionsClick,
    deletePurchaseOrderDocumentSupplierUpdates
}: Props) => {
    const menuItems = [
        <MenuItem onClick={() => onRowMoreOptionsClick && onRowMoreOptionsClick()} icon={edit} text={localization.edit} />,
        <MenuItem
            onClick={() => purchaseOrder && deletePurchaseOrderDocumentSupplierUpdates(purchaseOrder.id, entityId)}
            icon={trash}
            text={localization.delete}
        />
    ];

    return <Menu options={menuItems} MenuTrigger={CellTrigger} strStyle="display: flex;align-items: center;width: 100%;height: 100%;" />;
};

const mapStateToProps = (state: RootState) => ({
    purchaseOrder: singlePurchaseOrderSelectors.purchaseOrder(state)
});

const mapDispatchToProps = (dispatch: any) => ({
    deletePurchaseOrderDocumentSupplierUpdates: (purchaseOrderId: string, documentId: string) =>
        dispatch.purchaseOrders.deletePurchaseOrderDocumentSupplierUpdates({ purchaseOrderId, documentId })
});

export default connect(mapStateToProps, mapDispatchToProps)(PurchaseOrderDocumentMenuSupplierUpdates);
