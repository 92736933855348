import React from 'react';
import ButtonUnstyled, { buttonUnstyledClasses } from '@mui/base/ButtonUnstyled';
import styled from 'styled-components';
import { currencyFormatter } from '../inputs/form/CurrencyInput/CurrencyInput';
import ArrowForwardIosRoundedIcon from '@mui/icons-material/ArrowForwardIosRounded';
type Props = {
    planName: string;
    planId: string;
    description?: string;
    price: number;
    currency?: string;
    per?: string;
    onSelect?: (planId: string) => void;
};
const PricingPlan = ({ planName, planId, description, price, currency, per, onSelect }: Props) => {
    return (
        <Container>
            <Plan>{planName}</Plan>
            <Description>{description}</Description>
            <Price>
                {currencyFormatter(currency || 'USD')(price)}
                <Per>{per ? ` / ${per}` : ''}</Per>
            </Price>
            <CustomButton className="get-started-btn" onClick={() => onSelect?.(planId)}>
                Get Started <ArrowForwardIosRoundedIcon className="arrow-icon" />
            </CustomButton>
        </Container>
    );
};

const Container = styled.div`
    display: flex;
    flex-direction: column;
    width: 100%;
    align-items: flex-start;
    font-family: IBM Plex Sans, sans-serif;
    height: 100%;

    .get-started-btn {
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
    }

    .arrow-icon {
        font-size: 16px;
        margin-left: 10px;
        font-weight: 700;
    }
`;

const Plan = styled.div`
    font-size: 0.875rem;
    font-weight: 700;
    line-height: 1.5;
    letter-spacing: 0;
`;
const Description = styled.div`
    font-size: 0.875rem;
    font-weight: 400;
    color: #3e5060;
    margin-top: 10px;
    line-height: 1.5;
    letter-spacing: 0;
    text-align: start;
`;

const Price = styled.div`
    font-size: 1.75rem;
    font-family: 'PlusJakartaSans-Bold', 'Segoe UI', Roboto, 'Helvetica Neue', Arial, sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji',
        'Segoe UI Symbol';
    margin-top: 10px;
    margin-bottom: 20px;
    display: flex;
    align-items: flex-end;
`;

const Per = styled.div`
    margin-left: 7px;
    line-height: 1.5;
    letter-spacing: 0;
    font-family: IBM Plex Sans, sans-serif;
    font-weight: 400;
    color: #3e5060;
    font-size: 0.875rem;
`;

const blue = {
    500: '#007FFF',
    600: '#0072E5',
    700: '#0059B2'
};

const CustomButtonRoot = styled('button')`
    font-family: IBM Plex Sans, sans-serif;
    font-weight: bold;
    background-color: ${blue[500]};
    padding: 12px 24px;
    border-radius: 8px;
    color: white;
    transition: all 150ms ease;
    cursor: pointer;
    border: none;
    margin-top: auto;

    &:hover {
        background-color: ${blue[600]};
    }

    &.${buttonUnstyledClasses.active} {
        background-color: ${blue[700]};
    }

    &.${buttonUnstyledClasses.focusVisible} {
        box-shadow: 0 4px 20px 0 rgba(61, 71, 82, 0.1), 0 0 0 5px rgba(0, 127, 255, 0.5);
        outline: none;
    }

    &.${buttonUnstyledClasses.disabled} {
        opacity: 0.5;
        cursor: not-allowed;
    }
`;

function CustomButton(props: any) {
    return <ButtonUnstyled {...props} component={CustomButtonRoot} />;
}

export default PricingPlan;
