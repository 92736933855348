import React from 'react';
import { Field } from 'formik-antd';
import NumberInput, { BaseProps } from '../base/NumberInput';
import { FieldProps } from 'formik';
import { ErrorText } from '.';
import { styled } from '../../Theme/theme';

type Props = BaseProps & {
    name: string;
};

const FormNumberInput = ({ name, ...restProps }: Props) => {
    return (
        <Field name={name}>
            {({ field, meta, form }: FieldProps) => {
                const { setFieldValue } = form;
                const { touched, error } = meta;
                const hasError = touched && error !== undefined;
                return (
                    <Container width={restProps.width}>
                        <NumberInput field={field} hasError={hasError} {...restProps} onChange={(v: number) => setFieldValue(name, v)} />
                        <ErrorText>{hasError && error}</ErrorText>
                    </Container>
                );
            }}
        </Field>
    );
};

interface ContainerProps {
    width?: string;
}
const Container = styled.div<ContainerProps>`
    width: ${(props) => props.width};
`;
export default FormNumberInput;
