import React from 'react';
import styled from 'styled-components';
import CZTooltip, { QuestionToolTip } from '../CZTooltip/CZTooltip';
import { GroupedOptions, Option } from '../inputs/base/DropDown';
import {
    CurrencyInput,
    FormCheckbox,
    FormDatePicker,
    FormDropDown,
    FormNumberInput,
    FormTextArea,
    FormTextInput,
    NumberUnitInput,
    FormAutoComplete
} from '../inputs/form';

export type editTypes = 'dropdown' | 'date' | 'currency' | 'unit' | 'checkbox' | 'number' | 'textarea' | 'autocomplete';

export type editableDetail = {
    value?: any;
    type?: editTypes;
    options?: Option[];
    groupedOptions?: GroupedOptions;
    placeholder?: string;
    hideTime?: boolean;
    min?: number;
    max?: number;
    step?: number;
    dropDownMode?: 'multiple' | 'tags';
    dropDownOptionRenderer?: (option: Option & { color?: string }) => Promise<JSX.Element> | JSX.Element;
};
export type detail<T = any> = {
    name: Extract<keyof T, string>;
    showCondition: boolean;
    editable: false | JSX.Element | editableDetail;
    content?: JSX.Element;
    title: string | JSX.Element;
    value?: string | JSX.Element;
    tooltip?: string;
    hideTitle?: boolean;
};

const renderEditable = (name: string, editable: editableDetail, values: any): JSX.Element => {
    const { type, options, groupedOptions, placeholder, hideTime, min, max, step, dropDownMode, dropDownOptionRenderer } = editable;
    switch (type) {
        case 'dropdown':
            return (
                <FormDropDown
                    name={name}
                    style={{ width: '100%' }}
                    placeHolder={placeholder}
                    options={groupedOptions || options || []}
                    showSearch={true}
                    mode={dropDownMode}
                    optionRenderer={dropDownOptionRenderer}
                />
            );
        case 'date':
            return <FormDatePicker name={name} style={{ width: '100%' }} placeHolder={placeholder} showTime={!hideTime} />;
        case 'number':
            return <FormNumberInput name={name} placeHolder={placeholder} style={{ width: '100%' }} min={min} max={max} step={step} />;
        case 'currency':
            return (
                <CurrencyInput
                    currencyValue={values[`${name}Currency`] ?? 'USD'}
                    numberInputName={name}
                    numberPlaceholder={placeholder}
                    unitInputName={`${name}Currency`}
                />
            );
        case 'unit':
            return (
                <NumberUnitInput
                    numberInputName={name}
                    numberInputWidth="auto"
                    min={0}
                    step={0.001}
                    unitInputName={`${name}Unit`}
                    numberPlaceholder={placeholder}
                    unitOptions={options || []}
                />
            );
        case 'checkbox':
            return (
                <FormCheckbox name={name}>
                    <span style={{ color: '#008ac9' }}>{placeholder}</span>
                </FormCheckbox>
            );

        case 'textarea':
            return <FormTextArea name={name} placeHolder={placeholder} style={{ width: '100%' }} />;

        case 'autocomplete':
            return <FormAutoComplete name={name} style={{ width: '100%' }} placeHolder={placeholder} options={options || []} showSearch={true} />;

        default:
            return <FormTextInput name={name} placeHolder={placeholder} style={{ width: '100%' }} />;
    }
};

function renderDetail<T = any>(detail: detail<T>, key: number, values: any, detailsEdit: boolean) {
    const { name, showCondition, content, title, value, editable, tooltip, hideTitle } = detail;

    return (
        showCondition &&
        (editable || !detailsEdit) && (
            <DetailBox key={key}>
                {!hideTitle && (
                    <div style={{ display: 'flex', alignItems: 'center', height: '22px' }}>
                        <DetailTitle>{title}</DetailTitle>
                        {!!tooltip && <CZTooltip text={tooltip}>{QuestionToolTip}</CZTooltip>}
                    </div>
                )}
                {!!content && !detailsEdit ? (
                    content
                ) : editable && detailsEdit ? (
                    React.isValidElement(editable) ? (
                        editable
                    ) : (
                        renderEditable(name, editable as editableDetail, values)
                    )
                ) : (
                    <DetailValue>{value || '-'}</DetailValue>
                )}
            </DetailBox>
        )
    );
}

type Props<T = any> = {
    values: T;
    detailsEdit: boolean;
    detailsData: Array<Array<detail<T>>>;
};

function EntityDetails<T = any>({ detailsData, detailsEdit, values }: Props<T>) {
    const maxLengthRow = Math.max(
        ...detailsData.map((row) => row.filter((d) => d.showCondition && (!!d.editable || !detailsEdit))).map((arr) => arr.length)
    );

    return (
        <>
            {detailsData.map((row: Array<detail<T>>, i: number) => {
                const rowLength = row.filter((d) => d.showCondition && (!!d.editable || !detailsEdit)).length;
                if (rowLength === 0) return <div key={i}> </div>;
                return (
                    <DetailsRow key={i}>
                        {row.map((detail: detail<T>, j: number) => {
                            return renderDetail<T>(detail, j, values, detailsEdit);
                        })}
                        {rowLength < maxLengthRow && [...Array(maxLengthRow - rowLength)].map((e, g) => <DetailBox key={g}></DetailBox>)}
                    </DetailsRow>
                );
            })}{' '}
        </>
    );
}

const DetailsRow = styled.div`
    display: flex;
    flex-direction: row;
    max-width: 1000px;
`;

// max-width: 200px;
const DetailBox = styled.div`
    flex: 1;
    margin: 10px 5px;
    white-space: nowrap;
    word-break: break-word;
`;

const DetailTitle = styled.div`
    color: #008ac9;
    display: flex;
    align-items: center;
`;

// -webkit-line-clamp: 2;
// display: -webkit-box;
// -webkit-box-orient: vertical;
const DetailValue = styled.div`
    color: #394372;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: normal;
`;

export default EntityDetails;
