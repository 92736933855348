import React, { useState } from 'react';
import Menu, { TriggerProps } from '../../Menu/Menu';
import MenuItem from '../../Menu/MenuItem';
import shareIcon from '../../../../static/icons/share.svg';
import followIcon from '../../../../static/icons/follow.svg';
import trashIcon from '../../../../static/icons/trash.svg';
import exportIcon from '../../../../static/icons/download.svg';
import pdfIcon from '../../../../static/icons/documentsType/pdf.svg';
import excelIcon from '../../../../static/icons/documentsType/excel.svg';
import CellTrigger from '../../Menu/MenuTriggers/CellTrigger';
import { connect } from 'react-redux';
import { RootState } from '../../../../state/store/store';
import { exportTypes } from '../../../../state/ducks/allPurchaseOrdersPage/allPurchaseOrdersPage';
import { localizationSelectors } from '../../../../state/ducks/localization/selectors';
import { userSelectors } from '../../../../state/ducks/user/selectors';
import { PagesRoutes } from '../../../../routing/PagesRoutes';
import CustomModal, { ModalTriggerProps } from '../../Modal/Modal';
import InviteUserFollowPurchaseOrderForm from '../../InviteUserFollowPurchaseOrderForm/InviteUserFollowPurchaseOrderForm';
import { PDFViewer } from '@react-pdf/renderer';
import PurchaseOrderPdfDocument from '../../../pages/singlePurchaseOrder/PurchaseOrderPdfDocument';
import { purchaseOrder } from '../../../../models/entities/purchaseOrder/purchaseOrder';

type exportDataRowProps = (purchaseOrderId: string, type: exportTypes, fileName: string) => void;
type Props = {
    purchaseOrder: purchaseOrder;
    entityId: string;
    entityData: { CargoZoneNumber: string };
    localization: any;
    singlePOLocalization: any;
    exportDataRow: exportDataRowProps;
    trigger?: (props: TriggerProps) => JSX.Element;
    deletePurchaseOrder: (purchaseOrderId: string) => Promise<any>;
    onDelete?: () => void;
    followPurchaseOrder: (purchaseOrderId: string) => void;
    unFollowPurchaseOrder: (purchaseOrderId: string) => void;
    followingPurchaseOrders?: Array<string>;
    setIsModalOpened?: (isOpened: boolean) => void;
};
type MenuItemsProps = Props & {
    isUserFollowing?: boolean;
    allPurchaseOrdersLocalization: any;
};

const menuItems = ({
    purchaseOrder,
    localization,
    singlePOLocalization,
    entityId,
    entityData,
    exportDataRow,
    deletePurchaseOrder,
    onDelete,
    followPurchaseOrder,
    unFollowPurchaseOrder,
    isUserFollowing,
    setIsModalOpened,
    allPurchaseOrdersLocalization
}: MenuItemsProps) => {
    const renderPdfItem = (title: string, hidePrices?: boolean) => (
        <CustomModal
            title={{ text: title }}
            Trigger={(props: ModalTriggerProps) => (
                <MenuItem
                    onClick={(e) => {
                        e.stopPropagation();
                        if (setIsModalOpened) setIsModalOpened(true);
                        props.onClick();
                    }}
                    icon={exportIcon}
                    text={title}
                />
            )}
            onCancelCallback={() => {
                if (setIsModalOpened) setIsModalOpened(false);
            }}
            Content={() => {
                return (
                    <PDFViewer width="100%" height="100%">
                        <PurchaseOrderPdfDocument
                            purchaseOrder={purchaseOrder}
                            localization={singlePOLocalization}
                            allPurchaseOrdersLocalization={allPurchaseOrdersLocalization}
                            hidePrices={hidePrices}
                        />
                    </PDFViewer>
                );
            }}
            width="90%"
            maxWidth="90%"
            fullScreen
        />
    );
    return [
        <MenuItem
            onClick={() => {
                isUserFollowing ? unFollowPurchaseOrder(entityId) : followPurchaseOrder(entityId);
            }}
            icon={followIcon}
            text={isUserFollowing ? localization.unfollow : localization.follow}
        />,
        <MenuItem icon={shareIcon} text={localization.share} />,
        <Menu
            position="left-center"
            options={[renderPdfItem(localization.pdf), renderPdfItem(localization.pdf_no_prices, true)]}
            MenuTrigger={(props) => (
                <MenuItem
                    onClick={(e) => {
                        props.onClick(e);
                    }}
                    icon={exportIcon}
                    text={localization.export}
                />
            )}
        />,
        <CustomModal
            title={{ text: localization.invite_to_follow, icon: followIcon }}
            Trigger={(props: ModalTriggerProps) => (
                <MenuItem
                    onClick={(e) => {
                        e.stopPropagation();
                        if (setIsModalOpened) setIsModalOpened(true);
                        props.onClick();
                    }}
                    icon={followIcon}
                    text={localization.invite_to_follow}
                />
            )}
            onCancelCallback={() => {
                if (setIsModalOpened) setIsModalOpened(false);
            }}
            Content={(setVisible?: React.Dispatch<React.SetStateAction<boolean>>, setConfirm?: React.Dispatch<React.SetStateAction<boolean>>) => {
                return (
                    <InviteUserFollowPurchaseOrderForm
                        purchaseOrderId={entityId}
                        setModalVisible={(visible: boolean) => {
                            setVisible && setVisible(visible);
                            setIsModalOpened && setIsModalOpened(visible);
                        }}
                        confirmation={setConfirm ? { setConfirm } : undefined}
                    ></InviteUserFollowPurchaseOrderForm>
                );
            }}
            width="393px"
            maxWidth="393px"
        />,
        <MenuItem
            onClick={() => {
                deletePurchaseOrder(entityId).then(() => {
                    if (onDelete) onDelete();
                });
            }}
            icon={trashIcon}
            text={localization.delete}
            requiredConfirm
        />
    ];
};

const PurchaseOrderMenu = ({
    entityId,
    entityData,
    localization,
    singlePOLocalization,
    exportDataRow,
    deletePurchaseOrder,
    trigger,
    onDelete,
    followPurchaseOrder,
    unFollowPurchaseOrder,
    followingPurchaseOrders,
    purchaseOrder
}: Props) => {
    const [isModalOpened, setIsModalOpened] = useState(false);
    const isUserFollowing = followingPurchaseOrders?.includes(entityId);
    return (
        <Menu
            menuWidth={165}
            options={menuItems({
                singlePOLocalization,
                allPurchaseOrdersLocalization: localization,
                localization: localization.grid.menu,
                entityId,
                exportDataRow,
                deletePurchaseOrder,
                onDelete,
                followPurchaseOrder,
                unFollowPurchaseOrder,
                isUserFollowing,
                entityData,
                setIsModalOpened,
                purchaseOrder
            })}
            isModalOpened={isModalOpened}
            MenuTrigger={trigger || CellTrigger}
            strStyle="display: flex;align-items: center;width: 100%;height: 100%;"
        />
    );
};

const mapStateToProps = (state: RootState) => ({
    localization: localizationSelectors.allPurchaseOrdersPage(state),
    singlePOLocalization: localizationSelectors.singlePurchaseOrderPage(state),
    followingPurchaseOrders: userSelectors.followingPurchaseOrders(state)
});

const mapDispatchToProps = (dispatch: any) => ({
    exportDataRow: (purchaseOrderId: string, type: exportTypes, fileName: string) =>
        dispatch.allPurchaseOrdersPage.exportDataRow({ purchaseOrderId, type, fileName }),
    deletePurchaseOrder: async (purchaseOrderId: string) => dispatch.allPurchaseOrdersPage.deletePurchaseOrder(purchaseOrderId),
    followPurchaseOrder: async (purchaseOrderId: string) => dispatch.allPurchaseOrdersPage.followPurchaseOrder(purchaseOrderId),
    unFollowPurchaseOrder: async (purchaseOrderId: string) => dispatch.allPurchaseOrdersPage.unFollowPurchaseOrder(purchaseOrderId)
});

export default connect(mapStateToProps, mapDispatchToProps)(PurchaseOrderMenu);
