import React from 'react';
import { styled } from '../Theme/theme';
import loaderImg from '../../../static/common/loader.svg';
import { css, keyframes } from 'styled-components';
import { connect } from 'react-redux';
import { RootState } from '../../../state/store/store';
import { localizationSelectors } from '../../../state/ducks/localization/selectors';
type Props = LoaderStyle & {
    localization: any;
};
type LoaderStyle = {
    width?: string;
    wrapperWidth?: string;
    marginTop?: string;
    showText?: boolean;
    text?: string;
    fontSize?: string;
    inline?: boolean;
};

const Loader = ({ width, marginTop, showText, wrapperWidth, localization, inline, text, fontSize }: Props) => {
    return (
        <LoaderWrapper width={width} marginTop={marginTop} wrapperWidth={wrapperWidth} inline={inline} fontSize={fontSize}>
            <img src={loaderImg} alt="CargoZone loader" />
            {showText && <span>{text || localization.loaderText}...</span>}
        </LoaderWrapper>
    );
};
Loader.defaultProps = {
    showText: true
};
const Rotate = keyframes`
    to {transform: rotateZ(720deg);}
`;

const LoaderWrapper = styled.div<LoaderStyle>`
    display: flex;
    width: ${(props) => props.wrapperWidth ?? '100%'};
    padding-top: ${(props) => props.marginTop ?? '150px'};

    justify-content: center;
    align-items: center;

    ${(props) =>
        props.inline
            ? css`
                  flex-direction: row;
              `
            : css`
                  flex-direction: column;
              `}

    & span {
        font-size: ${(props) => props.fontSize ?? '18px'};
        margin-top: ${(props) => (props.inline ? '0' : '16px')};
        margin-left: ${(props) => (props.inline ? '10px' : '0')};
        color: ${(props) => props.theme.colors.primaryBlue};
    }

    & img {
        width: ${(props) => props.width ?? '64px'};
        animation: ${Rotate} 4s linear infinite;
    }
`;
const mapStateToProps = (state: RootState) => ({
    localization: localizationSelectors.common(state)
});

const mapDispatchToProps = (dispatch: any) => ({});

export default connect(mapStateToProps, mapDispatchToProps)(Loader);
