import React, { useEffect, useRef } from 'react';
import Button from '../../../shared/SmallComponents/Button';
import styled from 'styled-components';

type Props = {
    name: string;
    number: number;
    isActive: boolean;
    isLast: boolean;
    children?: React.ReactNode | React.ReactNode[];
    onNextClick: (event: any) => void;
    onPreviousClick: (event: any) => void;
    onSectionNumberClick: (sectionNumber: number) => void;
};

const FormSection = ({ name, number, isActive, isLast, children, onNextClick, onPreviousClick, onSectionNumberClick }: Props) => {
    const sectionRef = useRef<HTMLDivElement>(null);

    useEffect(() => {
        if (isActive && sectionRef.current) sectionRef.current.scrollIntoView({ behavior: 'smooth', block: 'nearest', inline: 'start' });
    }, [isActive]);

    return (
        <SectionContianer>
            <SectionNumber ref={sectionRef} onClick={() => onSectionNumberClick(number)} isActive={isActive}>
                {number + 1}
            </SectionNumber>
            <SectionTitle>{name}</SectionTitle>
            <ContentContainer shouldBeDisplayed={isActive} isLastSection={isLast}>
                <Content shouldBeDisplayed={isActive}>
                    {children}
                    {isActive && (
                        <ButtonsSection>
                            <Button disabled={number === 0} onClick={onPreviousClick}>
                                Previous
                            </Button>
                            <Button disabled={isLast} onClick={onNextClick}>
                                Next
                            </Button>
                        </ButtonsSection>
                    )}
                </Content>
            </ContentContainer>
        </SectionContianer>
    );
};

const ButtonsSection = styled.div`
    margin-top: 15px;
    display: flex;

    & button {
        margin-right: 20px;
        height: 30px;
        width: 100px;
    }
`;

const SectionContianer = styled.div`
    position: relative;
    margin-top: 20px;
`;

type SectionNumberProps = {
    isActive: boolean;
};

const SectionNumber = styled.div<SectionNumberProps>`
    cursor: pointer;
    height: 36px;
    width: 36px;
    font-size: 15px;
    text-align: center;
    line-height: 36px;
    border: 1px solid ${(props) => props.theme.colors.primaryBlue};
    background-color: ${(props) => (props.isActive ? props.theme.colors.primaryBlue : 'transparent')};
    color: ${(props) => (props.isActive ? 'white' : props.theme.colors.primaryBlue)};
    border-radius: 50px;
    position: absolute;
    left: -16px;
    top: -7px;
`;

type ContentProps = {
    shouldBeDisplayed: boolean;
};

const Content = styled.div<ContentProps>`
    display: ${(props) => (props.shouldBeDisplayed ? 'block' : 'none')};
`;

type ContentContainerProps = {
    isLastSection: boolean;
    shouldBeDisplayed: boolean;
};

const ContentContainer = styled.div<ContentContainerProps>`
    padding-bottom: ${(props) => (props.isLastSection ? (props.shouldBeDisplayed ? '15px' : '0') : '15px')};
    min-height: ${(props) => (props.isLastSection ? '0' : '40px')};
    padding-left: 30px;
    border-left: 2px dotted ${(props) => props.theme.colors.primaryBlue};
`;

const SectionTitle = styled.div`
    margin-top: 10px;
    margin-bottom: 20px;
    position: relative;
    left: 30px;
`;

export default FormSection;
