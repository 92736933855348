export const eContainerCapacity = {
    FCL: 'FCL',
    LCL: 'LCL'
};
export const eInlandSubTypes = {
    FTL: 'FTL',
    LTL: 'LTL',
    SPD: 'SPD'
};
export const eCustomsSubTypes = {
    BOX: 'Boxes',
    CON: 'Containers'
};
