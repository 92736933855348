import { ColumnType } from '../../../components/shared/Grid/types/Column';
import { shipment } from '../../../models/entities/shipment/shipment';
import SideEffectJS from 'side-effect-js';
import { ModelConfig } from '@rematch/core';
import { RootState } from '../../store/store';
import { localSDK as client } from '../../../sdk';
export type shipmentFilterField = { field: keyof shipment | string; value: any };
export type filterFieldsType = Array<shipmentFilterField>;

export type paginationType = { currentPage: number; rowsPerPage: number };

export type shipmentsPageStateType = {
    gridColumns: Array<ColumnType<shipment>>;
    fetchGridColumnsError: string | null;
    gridSort: gridSortState | null;
    gridFilter: string | null;
    error: string | null;
    filterFields: filterFieldsType | null;
    pagination: paginationType;
};
export type gridSortState = {
    direction: 'asc' | 'desc';
    column: keyof shipment;
};
export type exportTypes = 'PDF' | 'EXCEL';
const defaultGridSort: gridSortState = {
    direction: 'desc',
    column: 'updatedAt'
};
export const activeShipmentsFilter: shipmentFilterField = { field: 'isActive', value: 'true' };
export const allShipmentsPage: ModelConfig<shipmentsPageStateType> = {
    state: {
        gridColumns: [],
        fetchGridColumnsError: null,
        gridSort: defaultGridSort,
        gridFilter: null,
        error: null,
        filterFields: [activeShipmentsFilter],
        pagination: {
            currentPage: 0,
            rowsPerPage: 30
        }
    },
    reducers: {
        setGridColumns(state: shipmentsPageStateType, columns: Array<ColumnType<shipment>>): shipmentsPageStateType {
            return { ...state, gridColumns: columns, fetchGridColumnsError: null };
        },
        setFetchColumnsError(state: shipmentsPageStateType, error: string): shipmentsPageStateType {
            return { ...state, fetchGridColumnsError: error };
        },
        setGridSort(state: shipmentsPageStateType, sortData: gridSortState): shipmentsPageStateType {
            return { ...state, gridSort: sortData };
        },
        setGridFilter(state: shipmentsPageStateType, filterText: string): shipmentsPageStateType {
            return { ...state, gridFilter: filterText };
        },
        setCurrentPage(state: shipmentsPageStateType, currentPage: number): shipmentsPageStateType {
            return { ...state, pagination: { ...state.pagination, currentPage } };
        },
        setRowsPerPage(state: shipmentsPageStateType, rowsPerPage: number): shipmentsPageStateType {
            return { ...state, pagination: { ...state.pagination, rowsPerPage } };
        },
        setFilterFields(state: shipmentsPageStateType, filterFields: filterFieldsType, disableDefaultActiveFilter?: boolean): shipmentsPageStateType {
            const newFilterFields = [...filterFields];
            const hasActiveFilter = newFilterFields.some((filter) => filter.field === 'isActive');
            if (!(disableDefaultActiveFilter || hasActiveFilter)) newFilterFields.unshift(activeShipmentsFilter);
            return { ...state, filterFields: newFilterFields };
        },
        setError(state: shipmentsPageStateType, error: string): shipmentsPageStateType {
            return { ...state, error: error };
        }
    },
    effects: (dispatch: any) => ({
        async fetchGridColumns() {
            try {
                const fetchGridColumns = SideEffectJS.Get('gridColumnsShipmentsPage');
                const gridColumns = await fetchGridColumns();
                dispatch.allShipmentsPage.setGridColumns(gridColumns);
            } catch (e) {
                dispatch.allShipmentsPage.setFetchColumnsError(e);
            }
        },
        async exportDataTable(type: exportTypes, state: RootState) {},
        async exportDataRow({ shipmentId, type, fileName }: { shipmentId: string; type: exportTypes; fileName: string }) {
            dispatch.loadingNotify.setLoadingState({ visible: true, text: 'Export shipment...' });
            await client.shipments().exportShipment(shipmentId, type, fileName);
            dispatch.loadingNotify.setLoadingState({ visible: false });
            return true;
        },
        async setShipmentFilters({ filter, disableDefaultActiveFilter }: { filter: filterFieldsType; disableDefaultActiveFilter?: boolean }) {
            dispatch.allShipmentsPage.setFilterFields(filter, disableDefaultActiveFilter);
            dispatch.allShipmentsPage.setCurrentPage(0);
        },
        async fetchShipmentsByFilter(filter: filterFieldsType) {
            try {
                const shipments = await client.shipments().fetchShipmentsByFilter(filter);
                dispatch.shipments.setShipments(shipments);
                dispatch.allShipmentsPage.setCurrentPage(0);
            } catch (e) {
                dispatch.allShipmentsPage.setError(e);
            }
        },
        async deleteShipment(shipmentId: string) {
            await client.shipments().deleteShipment(shipmentId);
            dispatch.shipments.removeShipment(shipmentId);
        },
        async followShipment(shipmentId: string, state: RootState) {
            const follower = await client.shipments().follow().followShipment(shipmentId, state.user.userInfo.profileImg);
            dispatch.user.setShipmentFollow(shipmentId, true);
            dispatch.shipments.addShipmentFollower(shipmentId, follower);
        },
        async unFollowShipment(shipmentId: string, state: RootState) {
            await client.shipments().follow().unFollowShipment(shipmentId);
            dispatch.user.setShipmentFollow(shipmentId, false);
            dispatch.shipments.removeShipmentFollower(shipmentId, state.user.userInfo.id);
        }
    })
};
