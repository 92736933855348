import { ContentState, EditorState } from 'draft-js';
import React, { useEffect, useRef, useState } from 'react';
import { css } from 'styled-components';
import usePrevious from '../../../../../hooks/usePrevious';
import { chat, createChatPayload, editMessagePayload, message, messageAttachment } from '../../../../../models/entities/chat/chat';
import { chatRequest } from '../../../../../models/entities/chat/chatRequest';
import { userInfo } from '../../../../../models/entities/user';
import { userDetails } from '../../../../../state/ducks/users/users';
import {
    convertEditorStateToHtml,
    isEditorEmpty,
    getResetEditorState,
    moveSelectionToEnd,
    convertHtmlToEditorState
} from '../../../../../utils/textEditorUtils';
import DotsWaveAnimation from '../../../../shared/Animations/DotsWaveAnimation';
import TextEditor from '../../../../shared/inputs/base/TextEditor';
import Button from '../../../../shared/SmallComponents/Button';
import { styled } from '../../../../shared/Theme/theme';
import { createEditorStateWithText } from '@draft-js-plugins/editor';
import RealtimeService from '../../../../../services/realtime/realtimeService';
import { shipment } from '../../../../../models/entities/shipment/shipment';

type Props = {
    getUserById: (userId: string) => userDetails | undefined;
    getShipmentById: (id: string) => shipment | undefined;
    scrollToBottom: () => void;
    typingUsers?: string[];
    inviteUser: (email: string) => void;
    sentRequests?: Array<chatRequest>;
    isEditMessage: message | null;
    setIsEditMessage: React.Dispatch<React.SetStateAction<message | null>>;
    chatDisabled: boolean;
    userInfo: userInfo;
    chat?: chat;
    replyMessage: message | undefined;
    setReplyMessage: React.Dispatch<React.SetStateAction<message | undefined>>;
    createChat: (payload: createChatPayload) => void;
    editMessage: (payload: editMessagePayload) => void;
    setShowParticipants: React.Dispatch<React.SetStateAction<boolean>>;
    sendMessage: (chatId: string, message: message) => void;
    saveChatEditorState: (chatId: string, editorContentState: ContentState) => void;
    saveChatAttachmentsEditorState: (chatId: string, attachments: messageAttachment[]) => void;
    contactUser: userDetails | undefined;
    participantsIds: string[];
    localization: any;
};

const Footer = ({
    typingUsers,
    getUserById,
    getShipmentById,
    scrollToBottom,
    isEditMessage,
    setIsEditMessage,
    replyMessage,
    setReplyMessage,
    chatDisabled,
    sentRequests,
    contactUser,
    inviteUser,
    userInfo,
    chat,
    createChat,
    editMessage,
    setShowParticipants,
    sendMessage,
    saveChatEditorState,
    saveChatAttachmentsEditorState,
    participantsIds,
    localization
}: Props) => {
    const [isSubmit, setIsSubmit] = useState(false);
    const emptyEditorState = useRef(createEditorStateWithText(''));

    const [editorState, setEditorState] = useState<EditorState | undefined>(
        chatDisabled
            ? undefined
            : EditorState.push(emptyEditorState.current, chat?.editorState?.contentState || ContentState.createFromText(''), 'change-block-data')
    );
    const [editEditorState, setEditEditorState] = useState<EditorState>(emptyEditorState.current);
    const [isTyping, setIsTyping] = useState(false);
    const [typingTimeout, setTypingTimeout] = useState(0);

    const prevChat: chat = usePrevious(chat);

    const socket = RealtimeService.getSocket();

    useEffect(() => {
        if (isEditMessage) {
            setEditEditorState(moveSelectionToEnd(convertHtmlToEditorState(isEditMessage.content, getUserById, getShipmentById, editorState)));
        }
    }, [isEditMessage]);

    useEffect(() => {
        if (replyMessage && editorState) {
            setEditorState(moveSelectionToEnd(editorState));
        }
    }, [replyMessage]);

    const currentEditorState = useRef(editorState);
    useEffect(() => {
        currentEditorState.current = editorState;
    }, [editorState]);

    const firstComponentUpdate = useRef(true);
    useEffect(() => {
        if (firstComponentUpdate.current) {
            firstComponentUpdate.current = false;
            return;
        }

        if (chatDisabled) {
            setEditorState(undefined);
            return;
        }

        const newEditorState = EditorState.push(
            editorState || emptyEditorState.current,
            chat?.editorState?.contentState || ContentState.createFromText(''),
            'change-block-data'
        );
        setEditorState(newEditorState);

        return () => {
            if (chat && currentEditorState.current) {
                saveChatEditorState(chat.id, currentEditorState.current.getCurrentContent());
            }
        };
    }, [chat, chatDisabled, saveChatEditorState]);

    const onTyping = () => {
        clearTimeout(typingTimeout);

        if (!isTyping) {
            socket.emit('typing', { chatId: chat?.id, userId: userInfo.id, isTyping: true });
        }

        setTypingTimeout(
            setTimeout(() => {
                setIsTyping(false);
                socket.emit('typing', { chatId: chat?.id, userId: userInfo.id, isTyping: false });
            }, 1000)
        );
        setIsTyping(true);
    };

    const onSubmitMessage = async (attachments?: messageAttachment[]) => {
        if (!editorState) return;

        const state = isEditMessage ? editEditorState : editorState;
        const setState = isEditMessage ? setEditEditorState : setEditorState;

        const message = convertEditorStateToHtml(state);

        if (!!message.trim() && !isSubmit) {
            setIsSubmit(true);

            setState(getResetEditorState(state));
            if (!chat) {
                await createChat({ participantsIds, message, chatType: 'user', attachments, replyMessageId: replyMessage?.id, sendDate: new Date() });
            } else if (isEditMessage) {
                editMessage({ id: isEditMessage.id, chatId: chat.id, message, attachments });
                setIsEditMessage(null);
            } else {
                const { id, firstName, lastName } = userInfo;
                const messageToSend: message = {
                    id: '',
                    userId: id,
                    userName: `${firstName} ${lastName}`,
                    content: message,
                    sendDate: new Date(),
                    chatId: chat.id,
                    isRead: false,
                    attachments,
                    replyMessageId: replyMessage?.id
                };
                await sendMessage(chat.id, messageToSend);
                scrollToBottom();
            }
            setReplyMessage(undefined);
            setIsSubmit(false);
        }
    };

    const renderTypingUsers = () => {
        if (!!typingUsers && typingUsers.length > 0) {
            const names = typingUsers
                .map((id) => {
                    const user = getUserById(id);

                    if (user) {
                        return user.name;
                    }

                    return '';
                })
                .filter((n) => !!n)
                .join(', ');

            return (
                <TypingContainer>
                    <span style={{ marginRight: '2px' }}>
                        <span className="typing-user">{names}</span> {typingUsers.length > 1 ? localization.are : localization.is} typing{' '}
                    </span>
                    <span style={{ position: 'absolute', bottom: '2px' }}>
                        <DotsWaveAnimation dotSize={2} />
                    </span>
                </TypingContainer>
            );
        }
    };

    return (
        <ChatInputContainer>
            {renderTypingUsers()}

            <div style={{ width: '100%' }}>
                <EditingMessageIndication isEditing={!!isEditMessage}>
                    <span
                        className="material-icons"
                        style={{ marginRight: '5px', cursor: 'pointer', fontSize: '14px' }}
                        onClick={() => setIsEditMessage(null)}
                    >
                        cancel
                    </span>
                    {localization.editing_message}
                </EditingMessageIndication>
                <ReplyMessageIdIndication isReplying={!!replyMessage && !isEditMessage}>
                    <span
                        className="material-icons"
                        style={{ marginRight: '5px', cursor: 'pointer', fontSize: '14px' }}
                        onClick={() => setReplyMessage(undefined)}
                    >
                        cancel
                    </span>
                    {localization.reply_to} {replyMessage?.userName}
                </ReplyMessageIdIndication>
                {!editorState ? (
                    <SendRequestButton>
                        <Button
                            padding="15px"
                            buttonType="filled"
                            disabled={!!sentRequests?.find((item) => item.recieverEmail === contactUser?.email)}
                            onClick={() => contactUser && inviteUser(contactUser.email)}
                        >
                            {localization.send_connection_request}
                        </Button>
                    </SendRequestButton>
                ) : (
                    <TextEditor
                        id="message-text-editor"
                        editorState={isEditMessage ? editEditorState : editorState}
                        onChange={(state: EditorState) => {
                            const currentContent = state.getCurrentContent();
                            const newContent = (isEditMessage ? editEditorState : editorState).getCurrentContent();
                            if (currentContent !== newContent) onTyping();

                            isEditMessage ? setEditEditorState(state) : setEditorState(state);
                        }}
                        onInsertText={(text: string) => {}}
                        canSubmit={!isEditorEmpty(isEditMessage ? editEditorState : editorState) && !isSubmit}
                        onSubmit={onSubmitMessage}
                        editAttachments={isEditMessage ? isEditMessage.attachments : undefined}
                        currentAttachments={chat?.editorState?.attachments}
                        onAttachmentsChange={(attachments?: messageAttachment[]) => {
                            if (chat) {
                                saveChatAttachmentsEditorState(chat.id, attachments || []);
                            }
                        }}
                    />
                )}
            </div>
        </ChatInputContainer>
    );
};

const ChatInputContainer = styled.div`
    display: flex;
    flex-direction: column;
    width: 100%;
    padding: 20px 16px 16px 16px;
    position: relative;
`;
const TypingContainer = styled.div`
    position: absolute;
    top: 3px;
    left: 25px;
    font-size: 11px;
    .typing-user {
        font-weight: 500;
    }
`;

const EditingMessageIndication = styled.span<{ isEditing: boolean }>`
    display: flex;
    align-items: center;
    padding: 0 5px;
    transition: 0.3s;
    color: #787878;
    overflow: hidden;
    border-radius: 5px;
    background-color: #f1f1f1;
    ${(props) =>
        props.isEditing
            ? css`
                  height: 30px;
                  opacity: 1;
              `
            : css`
                  opacity: 0;
                  height: 0;
              `};
`;
const ReplyMessageIdIndication = styled.span<{ isReplying: boolean }>`
    display: flex;
    align-items: center;
    padding: 0 5px;
    transition: 0.3s;
    color: #787878;
    overflow: hidden;
    border-radius: 5px;
    background-color: #f1f1f1;
    ${(props) =>
        props.isReplying
            ? css`
                  height: 30px;
                  opacity: 1;
              `
            : css`
                  opacity: 0;
                  height: 0;
              `};
`;

const SendRequestButton = styled.div`
    display: flex;
    justify-content: center;
`;

export default React.memo(Footer);
