import React, { useEffect, useState } from 'react';
import { settings, shipment } from '../../../../models/entities/shipment/shipment';
import Parties from './Parties';
import Events from './Events/EventsTab';
import Documents from './Documents/DocumentsTab';
import TabsContainer from '../../../shared/Tabs/TabsContainer';
import Tab from '../../../shared/Tabs/Tab';
import History from '../../commonSubEntities/history/HistoryTab';
import ShipmentOverviewTab from './OverviewTab';
import { useLocation } from 'react-router-dom';
import { settings as companySettings } from '../../../../models/entities/businessPartner/businessPartner';
import ShipmentExpressEvents from './Events/express/ExpressEventsTab';
import claimsType from '../../../../models/entities/claimsType';
import Quotations from '../../bids/singleBid/sections/Quotations';
import { bid } from '../../../../models/entities/bid/bid';
import { featureFlagsModel } from '../../../../models/entities/businessPartner/settings';
import { drawers } from '../../../../state/ducks/header/header';
import { claimsDomainsType } from '../../../../userClaims';
import ShipmentItemsTab from './ShipmentItems/ShipmentItemsTab';
import Accounting from './Accounting';

type BaseProps = {
    shipment: shipment;
    settings: settings;
    isAllowedShipment?: boolean;
    localization?: any;
    companySettings?: companySettings;
    printMode?: boolean;
};
type Props = BaseProps & {
    isLoadingShipment?: boolean;
    isFreelancerOrForwarder?: boolean;
    isMobile?: boolean;
    tab?: string;
    printMode?: boolean;
    getUserClaims: (domain: claimsDomainsType) => claimsType;
    dismissRequiredDoc?: (shipmentId: string, docType: string) => string;
    updateBid?: (id: string, bid: bid, shipment: shipment, publish: boolean) => Promise<boolean>;
    createBid?: (shipment: shipment, bid: bid, publish: boolean) => Promise<string>;
    updateShipmentAccountingState: (shipmentId: string, shipmentAccountingState: string) => Promise<void>;
    startBid?: (id: string, shipmentId: string, shipment: shipment) => Promise<void>;
    featureFlags?: featureFlagsModel;
    setDrawerOpen: (open: drawers, params?: any) => void;
};

const ShipmentTabs = ({
    shipment,
    isLoadingShipment,
    localization,
    settings,
    companySettings,
    isMobile,
    dismissRequiredDoc,
    getUserClaims,
    updateBid,
    createBid,
    startBid,
    featureFlags,
    setDrawerOpen,
    updateShipmentAccountingState,
    isAllowedShipment,
    isFreelancerOrForwarder
}: Props) => {
    const location = useLocation();

    const tabParam = new URLSearchParams(location.search).get('tab');

    const compareCaseInsensitive = (first: string, second: string) => {
        return first.toUpperCase() === second.toUpperCase();
    };

    const getActiveTab = () => {
        if (tabParam) {
            const tabs = ['Parties', 'Events', 'Documents', 'History', 'Quotations', 'Accounting'];
            const tabsMobile = tabs.concat('Overview');

            return (isMobile ? tabsMobile : tabs).some((tab) => compareCaseInsensitive(tab, tabParam)) ? tabParam : isMobile ? 'Overview' : 'Parties';
        }
        return isMobile ? 'Overview' : shipment.typeCode !== 'EXPRESS' ? 'Parties' : 'ExpressEvents';
    };

    const [activeTab, setActiveTab] = useState(getActiveTab());

    useEffect(() => {
        setActiveTab(getActiveTab());
    }, [location]);

    const mobileToUpper = (title: string) => {
        return isMobile ? title.toUpperCase() : title;
    };

    const compareActiveTab = (tab: string) => {
        return compareCaseInsensitive(activeTab, tab);
    };

    return (
        <TabsContainer scrollToTabs={!!tabParam && !isLoadingShipment}>
            {isMobile && (
                <Tab title={mobileToUpper('Overview')} onClick={() => setActiveTab('Overview')} isActive={compareActiveTab('Overview')}>
                    <ShipmentOverviewTab shipment={shipment} localization={localization} settings={settings} />
                </Tab>
            )}
            {shipment.typeCode === 'EXPRESS' && (
                <Tab title={mobileToUpper('Events')} onClick={() => setActiveTab('ExpressEvents')} isActive={compareActiveTab('ExpressEvents')}>
                    <ShipmentExpressEvents
                        events={shipment.events}
                        settings={settings}
                        localization={localization.events}
                        userShipmentEventsClaims={getUserClaims('shipment')}
                    />
                </Tab>
            )}
            <Tab title={mobileToUpper('Parties')} onClick={() => setActiveTab('Parties')} isActive={compareActiveTab('Parties')}>
                <Parties shipment={shipment} localization={localization} settings={companySettings} featureFlags={featureFlags} />
            </Tab>
            {!!shipment.items?.length && (
                <Tab title={mobileToUpper('Items')} onClick={() => setActiveTab('Items')} isActive={compareActiveTab('Items')}>
                    <ShipmentItemsTab
                        shipment={shipment}
                        localization={localization.items}
                        isAllowedShipment={isAllowedShipment}
                        featureFlags={featureFlags}
                        isFreelancerOrForwarder={isFreelancerOrForwarder}
                    />
                </Tab>
            )}
            {featureFlags?.BIDS && isAllowedShipment && (
                <Tab title="Quotations" onClick={() => setActiveTab('Quotations')} isActive={activeTab === 'Quotations'}>
                    <Quotations
                        featureFlags={featureFlags}
                        localization={localization.bid}
                        updateBid={updateBid}
                        startBid={startBid}
                        createBid={createBid}
                        shipment={shipment}
                        setDrawerOpen={setDrawerOpen}
                    />
                </Tab>
            )}
            {getUserClaims('shipment').view_event && shipment.typeCode !== 'EXPRESS' && (
                <Tab title={mobileToUpper('Events')} onClick={() => setActiveTab('Events')} isActive={compareActiveTab('Events')}>
                    <Events
                        events={shipment.events}
                        localization={localization}
                        settings={settings}
                        userShipmentEventsClaims={getUserClaims('shipment')}
                        featureFlags={featureFlags}
                    />
                </Tab>
            )}
            {getUserClaims('shipment').view_document && (
                <Tab title={mobileToUpper('Documents')} onClick={() => setActiveTab('Documents')} isActive={compareActiveTab('Documents')}>
                    <Documents
                        documents={shipment.documents?.documents}
                        requiredDocs={shipment.documents?.requiredDocs}
                        localization={localization}
                        settings={settings}
                        dismissRequiredDoc={dismissRequiredDoc}
                        userShipmentDocsClaims={getUserClaims('shipment')}
                        shipmentId={shipment.id}
                    />
                </Tab>
            )}
            {/* <Tab title="References" onClick={() => setActiveTab('References')} isActive={activeTab === 'References'}>
                <div>References content</div>
            </Tab> */}
            {!featureFlags?.UNDER_FORWARDER && isAllowedShipment && (
                <Tab title={mobileToUpper('History')} onClick={() => setActiveTab('History')} isActive={compareActiveTab('History')}>
                    <History
                        history={shipment.history}
                        localization={localization}
                        excludedFields={
                            isFreelancerOrForwarder ? undefined : ['buyingRate', 'buyingRateCurrency', 'sellingRate', 'sellingRateCurrency']
                        }
                    />
                </Tab>
            )}
            {isFreelancerOrForwarder && isAllowedShipment && (
                <Tab title={mobileToUpper('Accounting')} onClick={() => setActiveTab('Accounting')} isActive={compareActiveTab('Accounting')}>
                    <Accounting
                        shipment={shipment}
                        billingLines={shipment.billingLines}
                        updateShipmentAccountingState={updateShipmentAccountingState}
                        localization={localization}
                        settings={settings}
                        featureFlags={featureFlags}
                    />
                </Tab>
            )}
        </TabsContainer>
    );
};

export const ShipmentTabsPrintMode = ({ shipment, localization, settings, companySettings, printMode, isAllowedShipment }: BaseProps) => {
    return (
        <div style={{ width: '100%' }}>
            <Parties shipment={shipment} localization={localization} settings={companySettings} printMode={printMode} />
        </div>
    );
};

export default ShipmentTabs;
