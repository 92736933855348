import BaseController from '../..';
import { discussion } from '../../../../models/entities/common_subentities/discussion';
import IShipmentDiscussions from './IShipmentDiscussions';

export default class ShipmentDiscussions extends BaseController implements IShipmentDiscussions {
    constructor(baseUrl: string) {
        super(baseUrl);
    }
    async fetchShipmentDiscussions(shipmentNumber: string) {
        const response = await this.client.get(`/shipment/discussions/${shipmentNumber}`);
        return response;
    }
    async createShipmentDiscussion(shipmentId: string, data: discussion, shipmentNumber?: string) {
        const response = await this.client.post(`/shipment/discussions`, JSON.stringify({ shipmentId, shipmentNumber, data }));
        return response;
    }
    async updateShipmentDiscussion(shipmentId: string, data: discussion, shipmentNumber?: string) {
        const response = await this.client.put(`/shipment/discussions`, JSON.stringify({ shipmentId, shipmentNumber, data }));
        return response;
    }
    async deleteShipmentDiscussion(shipmentId: string, discussionId: number) {
        const response = await this.client.delete(`/shipment/discussions`, JSON.stringify({ shipmentId, id: discussionId }));
        return response;
    }
}
