import BaseController from '../..';
import { event } from '../../../../models/entities/common_subentities/event';
import IShipmentEvents from './IShipmentEvents';

export default class ShipmentEvents extends BaseController implements IShipmentEvents {
    constructor(baseUrl: string) {
        super(baseUrl);
    }
    async fetchShipmentEvents(shipmentNumber: string) {
        const response = await this.client.get(`/shipment/events/${shipmentNumber}`);
        return response;
    }
    async createShipmentEvent(shipmentId: string, data: event) {
        const response = await this.client.post(`/shipment/events`, JSON.stringify({ shipmentId, data }));
        return response;
    }
    async updateShipmentEvent(shipmentId: string, data: event) {
        const response = await this.client.put(`/shipment/events`, JSON.stringify({ shipmentId, data, userId: data.userId }));
        return response;
    }
    async deleteShipmentEvent(shipmentId: string, eventId: number, userId: string) {
        const response = await this.client.delete(`/shipment/events`, JSON.stringify({ shipmentId, id: eventId, userId }));
        return response;
    }
}
