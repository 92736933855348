import React from 'react';
import styled from 'styled-components';
type Props = {
    dotSize?: number;
    dotsNumber?: number;
    dotColor?: string;
};
const DotsWaveAnimation = ({ dotSize, dotsNumber, dotColor }: Props) => {
    return (
        <Wave dotColor={dotColor} dotSize={dotSize} dotsNumber={dotsNumber}>
            {[...Array(dotsNumber || 3)].map((n, i) => (
                <Dot dotColor={dotColor} dotSize={dotSize} dotIndex={i} key={i}></Dot>
            ))}
        </Wave>
    );
};

interface WaveProps {
    dotSize?: number;
    dotsNumber?: number;
    dotColor?: string;
}
const Wave = styled.span<WaveProps>`
    position: relative;
    display: flex;
    flex-direction: row-reverse;
    align-items: center;
    justify-content: center;

    .dot {
        display: inline-block;
        width: ${(props) => (props.dotSize ? `${props.dotSize}px` : '3px')};
        height: ${(props) => (props.dotSize ? `${props.dotSize}px` : '3px')};
        border-radius: 50%;
        margin-right: 3px;
        background: ${(props) => props.dotColor ?? '#303131'};
        animation: wave 1.3s linear infinite;
        animation-delay: 1.3s;

        &:nth-child(2) {
            animation-delay: 1.1s;
            background: red;
        }

        &:nth-child(3) {
            animation-delay: 0.9s;
            background: blue;
        }
    }

    @keyframes wave {
        0%,
        60%,
        100% {
            transform: initial;
        }

        30% {
            transform: ${(props) => (props.dotSize ? `translateY(-${props.dotSize}px)` : 'translateY(-6px)')};
        }
    }
`;

interface DotProps {
    dotSize?: number;
    dotIndex?: number;
    dotColor?: string;
}

const Dot = styled.span<DotProps>`
    display: inline-block;
    width: ${(props) => (props.dotSize ? `${props.dotSize}px` : '3px')};
    height: ${(props) => (props.dotSize ? `${props.dotSize}px` : '3px')};
    border-radius: 50%;
    margin-right: 3px;
    background: ${(props) => props.dotColor ?? '#303131'};
    animation: wave 1.3s linear infinite;

    animation-delay: ${(props) => (props.dotIndex ? `-${props.dotIndex * 0.2}s` : '0s')};

    @keyframes wave {
        0%,
        60%,
        100% {
            transform: initial;
        }

        30% {
            transform: ${(props) => (props.dotSize ? `translateY(-${props.dotSize}px)` : 'translateY(-6px)')};
        }
    }
`;

export default DotsWaveAnimation;
