import { purchaseOrderItem } from '../../../../../../models/entities/purchaseOrder/purchaseOrderItem';

import { isEmpty } from 'lodash';
import { emptyObject } from './emptyObjects';

export const itemsPrepareSubmit = (values: any, purchaseOrderId?: string) => {
    let items: Array<purchaseOrderItem> = [];
    if (values.items) {
        items = values.items
            .filter((i: purchaseOrderItem) => !!i.productId)
            .map((item: purchaseOrderItem) => {
                return { ...item, purchaseOrderId };
            });
    }
    return items;
};
export const itemsPrepareEdit = (purchaseOrder_items?: Array<purchaseOrderItem>) => {
    let items = purchaseOrder_items;
    if (!items || items.length === 0) items = [];
    return items;
};
export const validateItems = (items: Array<any>, errors: any, localizations: any) => {
    const requiredFieldMessage = localizations.form.requiredFieldMessage;
    const maxLengthMessage = localizations.form.maxLengthMessage;
    if (!errors.items) errors.items = [];
    items.forEach(() => {
        errors.items.push({});
    });
    items.forEach((item, index) => {
        if (!item.quantity) {
            errors.items[index].quantity = requiredFieldMessage;
        }
        if (items.length > 1 && !item.productId) {
            errors.items[index].productId = requiredFieldMessage;
        }
    });
    if (isErrorsEmpty(errors.items)) delete errors.items;
};

// // calculations
export function calcCosts(items: Array<purchaseOrderItem>) {
    const costs = items.reduce((costs: { [key: string]: number }, item: purchaseOrderItem) => {
        const newCosts = { ...costs };
        const { currency, price, quantity } = item;
        if (currency && price && quantity) {
            newCosts[currency] = (newCosts[currency] || 0) + (Number(price) || 0) * (Number(quantity) || 0);
        }
        return newCosts;
    }, {});

    return costs;
}

function isErrorsEmpty(items: Array<any>) {
    for (const item of items) {
        if (!isEmpty(item)) {
            return false;
        }
    }
    return true;
}
export const defaultItems = [emptyObject];
