import { GridCellParams, GridColDef } from '@material-ui/data-grid';
import React, { useState } from 'react';
import styled from 'styled-components';
import { companiesRelation, companiesRelationsResponseType } from '../../../../../models/entities/company/companiesRelation';
import MuiDataGrid from '../../../../shared/MuiDataGrid/MuiDataGrid';
import { Modal } from 'antd';
import { useEffect } from 'react';

type Props = {
    localization: any;
    relations: companiesRelationsResponseType;
    deleteCompaniesRelation: (relationId: string) => Promise<void>;
};

const RelationsSection = ({ localization, relations, deleteCompaniesRelation }: Props) => {
    const [filteredData, setFilteredData] = useState<companiesRelation[]>(relations.requested);
    const [filter, setFilter] = useState(localization.filters.requested);

    const { requested, approved, all } = localization.filters;
    useEffect(() => {
        switch (filter) {
            case requested:
                setFilteredData(relations.requested);
                break;
            case approved:
                setFilteredData(relations.approved);
                break;
            case all:
                setFilteredData([...relations.approved, ...relations.requested]);
                break;
        }
    }, [filter, relations]);
    const columns: GridColDef[] = [
        {
            field: 'companyName',
            headerName: localization.companyName,
            flex: 1
        },
        {
            field: 'relatedCompanyName',
            headerName: localization.relatedCompanyName,
            flex: 1
        },
        {
            field: 'relationType',
            headerName: localization.relationType,
            flex: 1
        },
        {
            field: 'id',
            headerName: ' ',
            width: 100,
            renderCell: (params: GridCellParams) => {
                return (
                    <DeleteContainer
                        onClick={async (e) => {
                            e.stopPropagation();
                            Modal.confirm({
                                zIndex: 1100,
                                content: <div>{localization.are_you_sure}</div>,
                                async onOk() {
                                    await deleteCompaniesRelation(params.value as string);
                                },
                                onCancel() {}
                            });
                        }}
                    >
                        <span className="material-icons">delete</span>
                    </DeleteContainer>
                );
            }
        }
    ];

    const filterChips = [requested, approved, all];

    return (
        <Wrapper>
            <HeaderWrapper>
                <span>{localization.relations}</span>
                <FiltersContainer>
                    {filterChips.map((chip) => (
                        <FilterChip
                            isSelected={filter === chip}
                            onClick={() => {
                                setFilter(chip);
                            }}
                        >
                            {chip}
                        </FilterChip>
                    ))}
                </FiltersContainer>
            </HeaderWrapper>
            <GridWrapper>
                {!filteredData.length ? (
                    <EmptyState>{localization.relations_empty_state}</EmptyState>
                ) : (
                    <MuiDataGrid rows={filteredData} columns={columns} autoHeight />
                )}
            </GridWrapper>
        </Wrapper>
    );
};

const Wrapper = styled.div`
    padding: 25px 5px 25px 25px;
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 100%;
`;

const HeaderWrapper = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 100px;
    background-color: #fff;
`;
const FiltersContainer = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    padding: 10px 0;
`;

const FilterChip = styled.div<{ isSelected?: boolean }>`
    height: 28px;
    font-size: 12px;
    min-width: 12px;
    padding: 0 10px;
    margin: 0 5px;
    border-radius: 16px;
    box-sizing: border-box;
    outline: none;
    overflow: hidden;
    cursor: pointer;
    user-select: none;
    display: flex;
    align-items: center;
    justify-content: center;

    border: ${(props) => (props.isSelected ? 'none' : `1px solid #39437261`)};
    color: ${(props) => (props.isSelected ? '#fff' : 'props.theme.colors.pageTitle')};
    background-color: ${(props) => (props.isSelected ? props.theme.colors.primaryBlue : '#eff6fb')};
`;

const GridWrapper = styled.div`
    width: 100%;
    flex: 1;
    background-color: #fff;
    margin-top: 10px;
`;

const EmptyState = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 100%;
    font-size: 18px;
`;

const DeleteContainer = styled.div`
    display: flex;
    color: ${(props) => props.theme.colors.primaryBlue};
    span.material-icons {
        cursor: pointer;
    }
`;
export default RelationsSection;
