import { RootState } from '../../store/store';
import { bid, settings as settingsModel } from '../../../models/entities/bid/bid';
import { createSelector } from 'reselect';
import mockData from '../../../sideEffects/bid/settings/mockData';
import { quotation, quotationView } from '../../../models/entities/bid/quotation';
import { userInfo as userInfoType } from '../../../models/entities/user';

const bidsData = (state: RootState) => state.bids.bids;
const fetchBidsError = (state: RootState) => state.error.effects.bids.fetchBids;
const userInfo = (state: RootState) => state.user.userInfo;
const settings = (state: RootState) => state.user.userInfo.companySettings;
const shipment = (state: RootState) => state.bids.shipment;
const myRFQ = (state: RootState) => state.bids.myRFQ;

const currentLanguage = (state: RootState) => state.localization.currentLanguage;
const settingsByLanguage = createSelector(settings, currentLanguage, (settings, currentLanguage) => {
    const mockDataIndex = Object.keys(mockData).findIndex((item) => item === currentLanguage.symbol);
    const settingsObj: settingsModel = Object.values(mockData)[mockDataIndex] as settingsModel;
    if (settings) {
        settings
            .filter((s) => (s.entityType === 'BID' || !s.entityType) && s.language === currentLanguage.symbol)
            .forEach((s) => {
                settingsObj[s.key as keyof settingsModel] = s.value;
            });
        return settingsObj;
    }
});

const sentQuotations = createSelector(myRFQ, userInfo, (myRFQ: bid[], userInfo: userInfoType) => {
    return myRFQ.reduce((quotations: quotationView[], currentBid: bid) => {
        const myParty = currentBid.parties?.[0];
        if (!myParty?.quotations?.length || myParty?.email !== userInfo.email) return quotations;

        return quotations.concat(myParty.quotations);
    }, []);
});

const getBidById = (state: RootState, id: string): bid | undefined => {
    return state.bids.bids?.find((bid: bid) => bid.id === id);
};

const error = (state: RootState) => state.bids.error;

export { default as bidsSelectors } from './selectors';

export default {
    bidsData,
    fetchBidsError,
    getBidById,
    error,
    settingsByLanguage,
    shipment,
    myRFQ,
    sentQuotations
};
