import React from 'react';
import styled, { css } from 'styled-components';
import { Option } from '../inputs/base/DropDown';

type Props = {
    text: string;
    value: string;
    size?: string;
    hideOptionBorder?: boolean;
};

const ShipmentTypeOption = ({ text, value, size, hideOptionBorder }: Props) => {
    if (!value) return null;
    const typeIcon = require(`../../../static/icons/shipmentTypes/${value}.svg`).default;
    return (
        <Wrapper hideBorder={hideOptionBorder}>
            <Icon alt={text} src={typeIcon} width={size} marginRight={'6px'} /> <Text> {text} </Text>
        </Wrapper>
    );
};

export const renderShipmentTypeOption = (option: Option) => {
    return <ShipmentTypeOption text={option.text} value={option.value} />;
};

type WrapperProps = {
    hideBorder?: boolean;
};

const Wrapper = styled.div<WrapperProps>`
    display: flex;
    justify-content: left;
    align-items: center;
    ${(props) =>
        !props.hideBorder
            ? css`
                  border-bottom: 1px solid #dadada;
              `
            : ''};
    padding-bottom: 3px;
`;

type IconProps = {
    width?: string;
    marginRight?: string;
};

const Icon = styled.img<IconProps>`
    width: ${(props) => props.width ?? '20px'};
    ${(props) =>
        props.marginRight
            ? css`
                  margin-right: ${props.marginRight};
              `
            : ''}
`;

const Text = styled.div`
    color: ${(props) => props.theme.colors.gridCell};
    font-size: ${(props) => props.theme.fontSizes.gridCell};
    font-weight: 400;
`;

export default ShipmentTypeOption;
