import React, { useState } from 'react';
import Menu, { TriggerProps } from '../../../shared/Menu/Menu';
import MenuItem from '../../../shared/Menu/MenuItem';
import exportIcon from '../../../../static/icons/download.svg';
import pdfIcon from '../../../../static/icons/documentsType/pdf.svg';
import excelIcon from '../../../../static/icons/documentsType/excel.svg';
import Button from '../../../shared/SmallComponents/Button';
import { exportTypes } from '../../../../state/ducks/allPurchaseOrdersPage/allPurchaseOrdersPage';
import styled from 'styled-components';
import { purchaseOrder } from '../../../../models/entities/purchaseOrder/purchaseOrder';
import { ColumnType } from '../../../shared/Grid/types/Column';
import ArrayToExcelModal from '../../../shared/ArrayToExcel/ArrayToExcelModal';
import { pickBy } from 'lodash';
import { ModalTriggerProps } from '../../../shared/Modal/Modal';
import { containersGridView, containersViewItem } from '../../../../models/entities/containers/containersGridView';
type Props = {
    containers: containersGridView;
    localization: any;
};
const ExportMenu = ({ localization, containers }: Props) => {
    const [isModalOpened, setIsModalOpened] = useState(false);

    const menuItems = [
        <ArrayToExcelModal<containersViewItem>
            localization={{ ...localization.grid.fields, ...localization.header.exportOptions.export_excel_modal }}
            data={
                containers.map((c) =>
                    pickBy(c, (value, key) => !['shipmentNumber', 'id', 'typeCode', 'companyId'].includes(key))
                ) as Array<containersViewItem>
            }
            title={localization.header.exportOptions.export_excel_modal.title}
            fileName={localization.header.exportOptions.export_file_name}
            onCancelCallback={() => {
                setIsModalOpened(false);
            }}
            Trigger={(props: ModalTriggerProps) => (
                <MenuItem
                    onClick={(e) => {
                        e.stopPropagation();
                        setIsModalOpened(true);
                        props.onClick();
                    }}
                    icon={excelIcon}
                    text={localization.header.exportOptions.excel}
                />
            )}
        />
    ];
    return (
        <Menu
            position="bottom-center"
            arrowOnTrigger={false}
            options={menuItems}
            isModalOpened={isModalOpened}
            MenuTrigger={(props: TriggerProps) => (
                <Button
                    padding="0 8px"
                    margin="4px"
                    height="auto"
                    borderRadiusMobile="50%"
                    minHeightMobile="32px"
                    paddingMobile="0 4px"
                    onClick={props.onClick}
                >
                    <img src={exportIcon} alt="Export" />
                    <ExportLabel>{localization.export_btn}</ExportLabel>
                </Button>
            )}
        />
    );
};

const ExportLabel = styled.span`
    @media screen and (max-width: 768px) {
        display: none;
    }
`;
export default ExportMenu;
