import { ModelConfig } from '@rematch/core';

export type pagesHistoryStateType = {
    history: string[];
};

export const pagesHistory: ModelConfig<pagesHistoryStateType> = {
    state: {
        history: []
    },
    reducers: {
        addPath(state: pagesHistoryStateType, path: string): pagesHistoryStateType {
            const newHistory = [path, ...state.history];
            return { ...state, history: newHistory };
        }
    }
};
