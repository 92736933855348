import React from 'react';
import { FormDropDown, FormTextArea, FormTextInput, LocationSearchInput, UserDropDownForm } from '../../../../../shared/inputs/form';
import { FieldContainer, renderFieldLabel } from '../../PurchaseOrderForm';
import { renderCountryOption } from '../../../../../shared/SmallComponents/CountryOption';
import countries from '../../../../../../static/data/countries.json';
import airports from '../../../../../../static/data/airports.json';
import { updatePickupAddress, updateContactDetails } from '../utils';
import { filterFieldsType } from '../../../../../../state/ducks/allPurchaseOrdersPage/allPurchaseOrdersPage';
import { address, baseAddress } from '../../../../../../models/entities/businessPartner/address';
import { contact } from '../../../../../../models/entities/businessPartner/contact';

const countriesDropDownOptions = countries.map((country) => ({
    text: country.name,
    value: country.code
}));

type Props = {
    values: any;
    setFieldValue: any;
    vendorAddresses: baseAddress[];
    vendorContacts: contact[];
    fetchShipmentsByFilter: (filter: filterFieldsType) => void;
    localizations: any;
};
const Vendor = ({ values, setFieldValue, fetchShipmentsByFilter, vendorAddresses, vendorContacts, localizations }: Props) => {
    return (
        <div style={{ paddingRight: '30px' }}>
            <div style={{ marginBottom: '10px' }}>Purchase from</div>
            {vendorContacts.length > 1 && (
                <FieldContainer>
                    <div>{renderFieldLabel(localizations.pick_contact, false)}</div>
                    <FormDropDown
                        name="vendor_contacts"
                        options={vendorContacts.map((item) => {
                            return { text: item.name, value: item.id?.toString() };
                        })}
                        onChange={(value: string) => {
                            const contact = vendorContacts.find((item) => '' + item.id === value);
                            if (contact) updateContactDetails('vendor', setFieldValue, contact, values);
                        }}
                        placeHolder={localizations.pick_contact}
                    />
                </FieldContainer>
            )}
            <FieldContainer>
                <div>{renderFieldLabel(localizations.contact_name, false, localizations.contact_name)}</div>
                <div>
                    <FormTextInput name="vendor_contact_name" style={{ width: '200px' }} placeHolder={localizations.contact_name} />
                </div>
            </FieldContainer>
            <FieldContainer>
                <div>{renderFieldLabel(localizations.contact_phone, false, localizations.contact_phone)}</div>
                <div>
                    <FormTextInput name="vendor_contact_phoneNumber" style={{ width: '200px' }} placeHolder={localizations.contact_phone} />
                </div>
            </FieldContainer>
            <FieldContainer>
                <div>{renderFieldLabel(localizations.contact_email, false, localizations.contact_email)}</div>
                <div>
                    <FormTextInput name="vendor_contact_email" style={{ width: '200px' }} placeHolder={localizations.contact_email} />
                </div>
            </FieldContainer>
            {vendorAddresses.length > 1 && (
                <FieldContainer>
                    <div>{renderFieldLabel(localizations.pick_address, false)}</div>
                    <FormDropDown
                        name="vendor_addresses"
                        options={vendorAddresses.map((item) => {
                            return { text: item.name || item.city + ',' + item.country, value: item.id.toString() };
                        })}
                        onChange={(value: string) => {
                            const address = vendorAddresses.find((item) => '' + item.id === value);
                            if (address) updatePickupAddress('origin', setFieldValue, address, values);
                        }}
                        placeHolder={localizations.pick_address}
                    />
                </FieldContainer>
            )}
            <FieldContainer>
                <div>{renderFieldLabel(localizations.origin_country, true, localizations.origin_country)}</div>
                <div>
                    <FormDropDown
                        name="origin_country"
                        style={{ width: '200px' }}
                        placeHolder={localizations.pick_country}
                        options={countriesDropDownOptions}
                        optionRenderer={renderCountryOption}
                        onChange={(value: any) => {
                            fetchShipmentsByFilter([
                                { field: 'originCountry', value },
                                { field: 'destinationCountry', value: values.destination_country }
                            ]);
                            setFieldValue('origin', '');
                            setFieldValue('origin_zipcode', '');
                        }}
                        showSearch
                    />
                </div>
            </FieldContainer>
            <FieldContainer>
                <div>{renderFieldLabel(localizations.origin_port, false, localizations.origin_port)}</div>
                <div>
                    <FormDropDown
                        showSearch
                        disabled={!values.origin_country || !(values.origin_country.length > 0)}
                        name="origin"
                        style={{ width: '200px' }}
                        placeHolder={localizations.pick_port}
                        options={airports
                            .filter((airport) => airport.iso === values.origin_country)
                            .map((airport) => ({
                                text: airport.name ? airport.name : airport.iata,
                                value: airport.iata
                            }))}
                    />
                </div>
            </FieldContainer>
            <FieldContainer>
                <div>{renderFieldLabel(localizations.origin_city, true, localizations.origin_city)}</div>
                <div>
                    <LocationSearchInput
                        name="origin_zipcode"
                        placeHolder={localizations.pick_zipcode}
                        countryCode={values.origin_country}
                        width={'200px'}
                    />
                </div>
            </FieldContainer>
            <FieldContainer>
                <div>{renderFieldLabel(localizations.origin_pick_up_address, false, localizations.origin_pick_up_address)}</div>
                <div>
                    <FormTextArea placeHolder={localizations.origin_pick_up_address} name="origin_pickup_address" width={'200px'} height={'100px'} />
                </div>
            </FieldContainer>
        </div>
    );
};

export default Vendor;
