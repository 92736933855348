import { ModelConfig } from '@rematch/core';

export type singleCompanyStateType = {
    companyId: string | null;
};

export const singleCompany: ModelConfig<singleCompanyStateType> = {
    state: {
        companyId: null
    },
    reducers: {
        setCurrentCompanyId(state: singleCompanyStateType, companyId: string): singleCompanyStateType {
            return { ...state, companyId };
        }
    }
};
