import React, { useState } from 'react';
import { eQuotationState, quotationView } from '../../../../models/entities/bid/quotation';
import { currencyFormatter } from '../../../shared/inputs/form/CurrencyInput/CurrencyInput';
import { styled } from '../../../shared/Theme/theme';
import moment from 'moment';
import DropDown, { enumToDropdownOptions, Option } from '../../../shared/inputs/base/DropDown';
import PreviewFileAsync from '../../../shared/FilesPreview/Preview';
import { connect } from 'react-redux';
import { RootState } from '../../../../state/store/store';
import Loader from '../../../shared/SmallComponents/Loader';
import { bid } from '../../../../models/entities/bid/bid';
import { localizationSelectors } from '../../../../state/ducks/localization/selectors';
import { Modal } from 'antd';
import Button from '../../../shared/SmallComponents/Button';
import SingleValueFormModal from '../../../shared/SingleValueFormModal/SingleValueFormModal';
import { ModalTriggerProps } from '../../../shared/Modal/Modal';
import { businessPartnersSelectors } from '../../../../state/ducks/businessPartner/selectors';
import { useEffect } from 'react';

type Props = {
    params: any;
    approveQuotation: (
        quotationId: number,
        shipmentId: string,
        transportationPrice: number,
        transportationPriceCurrency: string,
        sendNotApprovedEmails?: boolean,
        ccRecipients?: string[]
    ) => Promise<boolean>;
    localization: any;
    getBusinessPartnerContactsEmailsOptions: (id: string) => Option[];
    fetchBusinessPartnerById: (id: string) => Promise<void>;
};

const QuotationPane = ({ params, approveQuotation, localization, getBusinessPartnerContactsEmailsOptions, fetchBusinessPartnerById }: Props) => {
    const { quotation, shipmentId, bid }: { quotation: quotationView; shipmentId: string | undefined; bid: bid | undefined } = params;
    const [state, setState] = useState(quotation.state);
    const [showLoader, setShowLoader] = useState(false);

    const party = bid?.parties?.find((p) => p.id === quotation?.partyId);
    const businessPartnerId = party?.businessPartnerId;

    useEffect(() => {
        if (businessPartnerId) fetchBusinessPartnerById(businessPartnerId);
    }, [businessPartnerId]);
    const quotationLocalization = localization.quotations.pane;

    const approve = async (sendNotApprovedEmails?: boolean, ccRecipients?: string[]) => {
        if (shipmentId) {
            const response = await approveQuotation(
                quotation.id,
                shipmentId,
                Number(quotation.freightPrice),
                quotation.freightPriceCurrency,
                sendNotApprovedEmails,
                ccRecipients
            );
            if (response) setState('APPROVED');
        }
    };

    if (!quotation) return <Container>{quotationLocalization.no_quotation}</Container>;
    return (
        <Container>
            <Header>{quotation.contactName} Bid</Header>
            <Details>
                <BoxDetail>
                    <BoxTitle>{quotationLocalization.total_price}</BoxTitle>
                    <BoxValue>{quotation.freightPrice ? currencyFormatter(quotation?.freightPriceCurrency)(quotation.freightPrice) : '-'}</BoxValue>
                </BoxDetail>
                <BoxDetail>
                    <BoxTitle>{quotationLocalization.transit_time}</BoxTitle>
                    <BoxValue>{quotation.transitTime ? `${quotation.transitTime}d` : '-'}</BoxValue>
                </BoxDetail>
                <BoxDetail>
                    <BoxTitle>{quotationLocalization.valid_untill}</BoxTitle>
                    <BoxValue>{moment(quotation.validTill).isValid() ? moment(quotation.validTill).format('DD/MM/YY') : '-'}</BoxValue>
                </BoxDetail>
                <BoxDetail>
                    {bid ? (
                        <BoxValue style={{ display: 'flex' }}>
                            <SingleValueFormModal<string[]>
                                Trigger={(props: ModalTriggerProps) => (
                                    <Button disabled={bid?.state === 'CLOSED' || showLoader} buttonType="filled" onClick={props.onClick}>
                                        {quotationLocalization.confirm_btn}
                                    </Button>
                                )}
                                valueType="dropdown"
                                dropDownMode="tags"
                                dropDownOptions={businessPartnerId ? getBusinessPartnerContactsEmailsOptions(businessPartnerId) : []}
                                onSubmit={async (ccRecipients: string[]) => {
                                    Modal.confirm({
                                        zIndex: 1100,
                                        title: quotationLocalization.inform_not_approved,
                                        okText: localization.yes,
                                        cancelText: localization.no,
                                        async onOk() {
                                            await approve(true, ccRecipients);
                                        },
                                        async onCancel() {
                                            await approve(false, ccRecipients);
                                        }
                                    });
                                }}
                                btnText={(ccRecipients) => (!ccRecipients?.length ? 'Skip' : 'OK')}
                                title={'Would you like to add CC recipients to the Confirmation email?'}
                            />
                        </BoxValue>
                    ) : (
                        <BoxValue>{eQuotationState[state]}</BoxValue>
                    )}
                </BoxDetail>
            </Details>
            {quotation.shipmentType === 'OCEAN_FCL' && (
                <DeumurrageFreeDays>{quotation.freeDays} Free days for returning the continer</DeumurrageFreeDays>
            )}
            <br />
            <BoxDetail>
                <BoxTitle>{quotationLocalization.note}</BoxTitle>
                <BoxValue style={{ fontSize: '12px' }}>{quotation.note || '-'}</BoxValue>
            </BoxDetail>
            <Documents>
                <DocumentsHeader>{quotationLocalization.attached_file}</DocumentsHeader>
                {quotation.fileId ? (
                    <FileContainer>
                        <FileHeader>{quotation.fileName}</FileHeader>
                        <div style={{ height: '616px' }}>
                            <PreviewFileAsync fileId={quotation.fileId} fileName={quotation.fileName} />
                        </div>
                    </FileContainer>
                ) : (
                    <div>{quotationLocalization.no_file}</div>
                )}
            </Documents>
        </Container>
    );
};
const Container = styled.div`
    padding-left: 23px;
    padding-right: 20px;
    padding-top: 48px;
    padding-bottom: 20px;
`;
const Header = styled.div`
    color: ${(props) => props.theme.colors.pageTitle};
    font-size: 18px;
    padding-bottom: 15px;
`;
const Details = styled.div`
    display: flex;
`;
const BoxDetail = styled.div`
    flex: 1;
`;
const BoxTitle = styled.div`
    font-size: 14px;
    color: #95939b;
`;
const BoxValue = styled.div`
    font-size: 20px;
    color: #413e48;
`;
const Documents = styled.div`
    padding-top: 20px;
`;
const DocumentsHeader = styled.div`
    font-weight: 500;
    font-size: 18px;
    color: ${(props) => props.theme.colors.pageTitle};
    padding-bottom: 15px;
`;
const FileContainer = styled.div`
    border: 1px solid #eeeeee;
    box-sizing: border-box;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
    border-radius: 4px;
    width: 100%;
    min-height: 616px;
    padding: 14px 21px;
`;
const FileHeader = styled.div`
    font-size: 14px;
    color: ${(props) => props.theme.colors.pageTitle};
`;
const DeumurrageFreeDays = styled.div`
    padding-top: 15px;
    color: ${(props) => props.theme.colors.pageTitle};
`;

const mapStateToProps = (state: RootState) => ({
    getBusinessPartnerContactsEmailsOptions: (id: string) => businessPartnersSelectors.getBusinessPartnerContactsEmailsOptions(state)(id),
    localization: localizationSelectors.singleShipmentPage(state)
});

const mapDispatchToProps = (dispatch: any) => ({
    approveQuotation: (
        quotationId: number,
        shipmentId: string,
        transportationPrice: number,
        transportationPriceCurrency: string,
        sendNotApprovedEmails?: boolean,
        ccRecipients?: string[]
    ) =>
        dispatch.bids.approveQuotation({
            quotationId,
            shipmentId,
            transportationPrice,
            transportationPriceCurrency,
            sendNotApprovedEmails,
            ccRecipients
        }),
    fetchBusinessPartnerById: (id: string) => dispatch.businessPartners.fetchBusinessPartnerById(id)
});
export default connect(mapStateToProps, mapDispatchToProps)(QuotationPane);
