import { RootState } from '../../store/store';
import { product, settings as settingsModel } from '../../../models/entities/product/product';
import { createSelector } from 'reselect';
import { orderBy } from 'lodash';
import filterProducts from './productsGridFilter';
import mockData from '../../../sideEffects/products/settings/mockData';
import { document } from '../../../models/entities/common_subentities/document';
import { byDate, gridSortCallback } from '../../../utils/sortArray';
import { attribute } from '../../../models/entities/product/attribute';
import { link } from '../../../models/entities/product/link';
import { UploadFile } from 'antd/lib/upload/interface';
import { inventoryLog } from '../../../models/entities/product/inventoryLog';

const productsData = (state: RootState) => state.products.products;
const currentProductId = (state: RootState) => state.products.singleProductId;
const settings = (state: RootState) => state.user.userInfo.companySettings;
const gridColumns = (state: RootState) => state.products.gridColumns;
const fetchGridColumnsError = (state: RootState) => state.products.fetchGridColumnsError;
const gridSort = (state: RootState) => state.products.gridSort;
const gridFilter = (state: RootState) => state.products.gridFilter;
const filterFields = (state: RootState) => state.products.filterFields;
const pagination = (state: RootState) => state.products.pagination;

const currentLanguage = (state: RootState) => state.localization.currentLanguage;
const settingsByLanguage = createSelector(settings, currentLanguage, (settings, currentLanguage) => {
    const mockDataIndex = Object.keys(mockData).findIndex((item) => item === currentLanguage.symbol);
    const settingsObj: settingsModel = Object.values(mockData)[mockDataIndex] as settingsModel;
    if (settings) {
        settings
            .filter((s) => (s.entityType === 'PRODUCT' || !s.entityType) && s.language === currentLanguage.symbol)
            .forEach((s) => {
                settingsObj[s.key as keyof settingsModel] = s.value;
            });
    }
    return settingsObj;
});

const documents = createSelector(productsData, currentProductId, (products, currentProductId) => {
    const product = products?.find((item) => item.id === currentProductId);
    if (product) return product.documents?.sort(byDate(true, (document: document) => document.createdDate));
});
const attributes = createSelector(productsData, currentProductId, (products, currentProductId) => {
    const product = products?.find((item) => item.id === currentProductId);
    if (product) return product.attributes?.sort(byDate(true, (attribute: attribute) => attribute.createdAt));
});
const links = createSelector(productsData, currentProductId, (products, currentProductId) => {
    const product = products?.find((item) => item.id === currentProductId);
    if (product) return product.links?.sort(byDate(true, (link: link) => link.createdAt));
});

const getProductById = (state: RootState, id: string): product | undefined => {
    return state.products.products?.find((product: product) => product.id === id);
};
const productActiveFilter = createSelector(filterFields, (filterFields) => {
    return filterFields?.find((f: { field: string; value: string }) => f.field === 'isActive');
});

const activeFiltersCount = createSelector(filterFields, (filterFields) => {
    if (!filterFields) return 0;
    return filterFields.length;
});

const activeProductsCount = createSelector(productsData, (productsData: Array<product>) => {
    return productsData.length;
});

const productsBySupplier = createSelector(productsData, (productsData: Array<product>) => (supplierId: string) => {
    return productsData.filter((p) => p.supplierId === supplierId);
});
const currentProduct = createSelector(currentProductId, productsData, (productId, products) => {
    const product = products?.find((product) => product.id === productId);
    if (product?.inventoryLogs) product.inventoryLogs = product?.inventoryLogs.sort(byDate(true, (log: inventoryLog) => log.createdAt));
    return product;
});
const nonRelatedProducts = createSelector(currentProduct, productsData, (currentProduct, products) => {
    return products?.filter((p) => p.id !== currentProduct?.id && !currentProduct?.relatedProducts?.map((item) => item.id).includes(p.id));
});

const getProductsBySupplierId = createSelector(productsData, (products) => (supplierId: string) => {
    return products.filter((p) => p.supplierId === supplierId);
});

const productImagesList = createSelector(documents, (documents) => {
    if (!documents?.length) return [];

    return documents
        .reduce((images: UploadFile<any>[], doc: document) => {
            const { imgUrl } = doc;
            const newImages = [...images];
            if (imgUrl)
                newImages.push({
                    uid: `${doc.id}`,
                    size: 0,
                    name: doc.fileName || '',
                    status: 'done',
                    url: imgUrl
                });
            return newImages;
        }, [])
        .slice(0, 5)
        .reverse();
});

const gridData = createSelector(
    productsData,
    gridSort,
    gridFilter,
    filterFields,
    (productsData: Array<product>, gridSort, gridFilter, filterFields) => {
        // const settings: settings = settingsByLanguage();

        if (gridFilter && gridFilter !== '' && gridFilter.length > 2) {
            productsData = productsData.filter((s) => JSON.stringify(s).toLocaleLowerCase().includes(gridFilter.toLocaleLowerCase()));
        }

        if (filterFields) {
            productsData = filterProducts(productsData, filterFields);
        }

        if (gridSort) {
            switch (gridSort.column) {
                default:
                    productsData = orderBy(productsData, gridSortCallback(gridSort.column), [gridSort.direction]);
            }
        }

        return productsData;
    }
);

const getProductsByCompanyId = createSelector(gridData, (gridData) => (companyId?: string) => {
    if (!companyId) return gridData;

    return gridData.filter((p) => p.companyId === companyId);
});

const getProductsByFilter = createSelector(productsData, (products) => (predicate: (p: product) => boolean) => {
    return products.filter(predicate);
});

const filteredProductsCount = createSelector(gridData, (gridData) => {
    return gridData?.length || 0;
});

const currentGridDataPage = createSelector(pagination, gridData, (pagination, gridData) => {
    const { currentPage, rowsPerPage } = pagination;

    return gridData.slice(currentPage * rowsPerPage, currentPage * rowsPerPage + rowsPerPage);
});

export { default as productsSelectors } from './selectors';

export default {
    productsData,
    getProductById,
    gridColumns,
    fetchGridColumnsError,
    productActiveFilter,
    activeFiltersCount,
    activeProductsCount,
    gridData,
    filterFields,
    gridSort,
    gridFilter,
    settingsByLanguage,
    productsBySupplier,
    documents,
    attributes,
    links,
    currentProduct,
    currentProductId,
    nonRelatedProducts,
    getProductsBySupplierId,
    productImagesList,
    filteredProductsCount,
    pagination,
    currentGridDataPage,
    getProductsByCompanyId,
    getProductsByFilter
};
