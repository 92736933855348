import React, { useEffect, useRef } from 'react';
import { FormikProps } from 'formik';
import { genericCreateTariffChargePayload, breakCalcData } from '../../../../../../models/entities/tariff/charge';
import FormInputWrapper from '../../../../../shared/Form/FormInputWrapper';
import { FormNumberInput, FormTextInput } from '../../../../../shared/inputs/form';
import styled from 'styled-components';
import { numberParser } from '../../../../../shared/inputs/form/NumberUnitInput';
import { currencyFormatter } from '../../../../../shared/inputs/form/CurrencyInput/CurrencyInput';

export const emptyBreakCalcData = {
    value: 0,
    price: 0
};

type Props = {
    localization: any;
    formikProps: FormikProps<genericCreateTariffChargePayload<'BREAK'>>;
    dynamicInputsContainerRef?: React.RefObject<HTMLDivElement>;
};

const Break = ({ formikProps, localization, dynamicInputsContainerRef }: Props) => {
    const { values, setFieldValue } = formikProps;
    const { chargeCalcData } = values;

    const bottomRef = useRef<HTMLDivElement>(null);

    useEffect(() => {
        if (bottomRef?.current && dynamicInputsContainerRef?.current) {
            const { current: dynamicInputsContainerCurrent } = dynamicInputsContainerRef;
            const { scrollHeight, clientHeight, scrollTop } = dynamicInputsContainerCurrent;
            const isBottom = Math.abs(scrollHeight - clientHeight - scrollTop) < 100;

            if (isBottom) bottomRef.current.scrollIntoView({ behavior: 'smooth', block: 'nearest', inline: 'start' });
        }
    }, [chargeCalcData.length, bottomRef, dynamicInputsContainerRef]);
    return (
        <>
            {chargeCalcData.map((breakData: breakCalcData, i: number) => {
                const isFirst = i === 0;
                const isLast = i === chargeCalcData.length - 1;
                return (
                    <Flex>
                        <FormInputWrapper fieldName="value" localization={localization.fields} isMandatory>
                            <FormTextInput type="number" name={`chargeCalcData[${i}].value`} style={{ width: '150px', marginRight: '15px' }} />
                        </FormInputWrapper>
                        <FormInputWrapper fieldName="price" localization={localization.fields} isMandatory>
                            <FormNumberInput
                                name={`chargeCalcData[${i}].price`}
                                formatter={currencyFormatter(values.currency)}
                                parser={numberParser}
                                style={{ width: '150px', marginRight: '15px' }}
                            />
                        </FormInputWrapper>
                        {!isFirst && (
                            <IconBtnContainer
                                onClick={() => {
                                    const newBreakData = [...chargeCalcData];
                                    newBreakData.splice(i, 1);
                                    setFieldValue('chargeCalcData', newBreakData);
                                }}
                            >
                                <span className="material-icons">delete</span>
                            </IconBtnContainer>
                        )}
                        {isLast && (
                            <IconBtnContainer
                                onClick={() => {
                                    const newBreakData = [...chargeCalcData, emptyBreakCalcData];
                                    setFieldValue('chargeCalcData', newBreakData);
                                }}
                            >
                                <span className="material-icons">add</span>
                            </IconBtnContainer>
                        )}
                    </Flex>
                );
            })}
            <div ref={bottomRef}></div>
        </>
    );
};

const Flex = styled.div`
    display: flex;
    align-items: center;
`;

const IconBtnContainer = styled.div`
    cursor: pointer;
    font-size: 25px;
    line-height: 0;
    align-self: self-end;
    height: 42px;
    color: ${(props) => props.theme.colors.pageTitle};
    span.material-icons {
        font-size: inherit;
        margin-right: 5px;
        user-select: none;
    }
`;
export default Break;
