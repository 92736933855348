import React from 'react';
import { shipmentPackage } from '../../../../../../models/entities/shipment/shipmentPackage';
import { DimensionInput, FormTextInput, NumberUnitInput } from '../../../../../shared/inputs/form';
import { styled } from '../../../../../shared/Theme/theme';
import { Flex, renderFieldLabelByName } from '../../ShipmentForm';
import { lclPackageEmptyObj } from './emptyObjects';
import { calcTotalWeight, calcQuantity, calcTotalVolume } from '../../../../../../utils/measurementUtils';
import { Modal } from 'antd';

type Props = {
    values: any;
    setFieldValue: any;
    localizations: any;
    shipmentId?: string;
    deleteShipmentPackage?: (shipmentId: string, packageId: string) => Promise<void>;
};
const Boxes = ({ values, setFieldValue, localizations, deleteShipmentPackage, shipmentId }: Props) => {
    const volumeCalc = calcTotalVolume(values.packages);
    const weightCalc = calcTotalWeight(values.packages);

    const packagesSectionLocalizations = localizations.form.sections.packages;
    const fieldsLocalizations = packagesSectionLocalizations.fields;
    const renderFieldTitle = (name: string, isMandatory: boolean) => renderFieldLabelByName(name, isMandatory, fieldsLocalizations);
    return (
        <div>
            {values.packages &&
                values.packages.map((item: shipmentPackage, index: number) => {
                    return (
                        <Flex key={index} style={{ alignItems: 'center' }}>
                            <StyledFieldContainer>
                                <div>{renderFieldTitle('quantity', false)}</div>
                                <div>
                                    <FormTextInput
                                        name={`packages[${index}][quantity]`}
                                        placeHolder={fieldsLocalizations.quantity.title}
                                        type="number"
                                        style={{ width: '60px' }}
                                        min="0"
                                    />
                                </div>
                            </StyledFieldContainer>

                            <StyledFieldContainer>
                                <div>{renderFieldTitle('dimensions', true)}</div>
                                <DimensionInput
                                    lengthInputName={`packages[${index}][length]`}
                                    widthInputName={`packages[${index}][width]`}
                                    heightInputName={`packages[${index}][height]`}
                                    unitInputName={`packages[${index}][dimensionsUnit]`}
                                    unitPlaceHolder={fieldsLocalizations.dimensions_unit.CM}
                                    options={[
                                        { value: 'CM', text: fieldsLocalizations.dimensions_unit.CM },
                                        { value: 'IN', text: fieldsLocalizations.dimensions_unit.IN }
                                    ]}
                                    onUnitChange={(value: any) => {
                                        values.packages.forEach((item: shipmentPackage, number: number) => {
                                            if (index !== number) setFieldValue(`packages[${number}][dimensionsUnit]`, value);
                                        });
                                    }}
                                />
                            </StyledFieldContainer>

                            <StyledFieldContainer>
                                <div>{renderFieldTitle('weight', true)}</div>
                                <NumberUnitInput
                                    numberInputName={`packages[${index}][weight]`}
                                    numberPlaceholder={fieldsLocalizations.weight.title}
                                    numberInputWidth="90px"
                                    min={0}
                                    step={0.001}
                                    unitInputName={`packages[${index}][weightUnit]`}
                                    unitPlaceholder={fieldsLocalizations.weight_unit.KG}
                                    unitOptions={[
                                        { value: 'KG', text: fieldsLocalizations.weight_unit.KG },
                                        { value: 'LB', text: fieldsLocalizations.weight_unit.LB }
                                    ]}
                                    onUnitChange={(value: any) => {
                                        values.packages.forEach((item: shipmentPackage, number: number) => {
                                            if (index !== number) setFieldValue(`packages[${number}][weightUnit]`, value);
                                        });
                                    }}
                                />
                            </StyledFieldContainer>
                            {['OI', 'OEX', 'OE', 'DROP_OCEAN'].includes(values.shipment_type) && (
                                <StyledFieldContainer>
                                    <div>{renderFieldTitle('container_number', false)}</div>
                                    <div>
                                        <FormTextInput
                                            name={`packages[${index}][containerNumber]`}
                                            placeHolder={fieldsLocalizations.container_number.title}
                                        />
                                    </div>
                                </StyledFieldContainer>
                            )}
                            <StyledFieldContainer>
                                <div>{renderFieldTitle('description', false)}</div>
                                <div>
                                    <FormTextInput name={`packages[${index}][description]`} placeHolder={fieldsLocalizations.description.title} />
                                </div>
                            </StyledFieldContainer>
                            <StyledFieldContainer>
                                <div>{renderFieldTitle('hs_code', false)}</div>
                                <div>
                                    <FormTextInput name={`packages[${index}][hsCode]`} placeHolder={fieldsLocalizations.hs_code.title} />
                                </div>
                            </StyledFieldContainer>
                            <DeleteContainer
                                onClick={async () => {
                                    const packages: Array<any> = [...values.packages];

                                    const packageToDelete = packages[index];
                                    if (deleteShipmentPackage && packageToDelete?.id && shipmentId) {
                                        Modal.confirm({
                                            content: <div>{localizations.are_you_sure}</div>,
                                            zIndex: 1010,
                                            async onOk() {
                                                packages.splice(index, 1);
                                                await deleteShipmentPackage(shipmentId, packageToDelete.id);
                                            },
                                            onCancel() {}
                                        });
                                    } else {
                                        packages.splice(index, 1);
                                    }
                                    setFieldValue('packages', packages);
                                }}
                            >
                                <VerticalDivider />
                                <span className="material-icons">delete</span>
                            </DeleteContainer>
                        </Flex>
                    );
                })}
            <AddContainer
                onClick={() => {
                    let packages: Array<any> = [];
                    if (values.packages) packages = [...values.packages];
                    packages.push(lclPackageEmptyObj);
                    setFieldValue('packages', packages);
                }}
            >
                <span className="material-icons">add_circle</span>
                <span>Add package</span>
            </AddContainer>
            {values.packages && (
                <BottomDetails>
                    <span>
                        Shipment total: {calcQuantity(values.packages)} packages, {volumeCalc.volume.toFixed(2) + ' ' + volumeCalc.unit} ,{' '}
                        {weightCalc.weight.toFixed(2) + ' ' + weightCalc.unit}
                    </span>
                </BottomDetails>
            )}
        </div>
    );
};
const StyledFieldContainer = styled.div`
    margin-bottom: 12px;
    padding-right: 30px;
`;

const AddContainer = styled.div`
    cursor: pointer;
    display: inline-flex;
    align-items: center;
    font-size: 13px;
    line-height: 0;
    color: ${(props) => props.theme.colors.primaryBlue};
    span.material-icons {
        font-size: inherit;
        margin-right: 5px;
        user-select: none;
    }
`;
const DeleteContainer = styled.div`
    display: flex;
    color: ${(props) => props.theme.colors.primaryBlue};
    span.material-icons {
        cursor: pointer;
    }
`;
const VerticalDivider = styled.div`
    height: auto;
    width: 1px;
    background: rgb(0 0 0 / 14%);
    margin-right: 20px;
`;

const BottomDetails = styled.div`
    margin-top: 10px;
    width: 100%;
    border-top: 1px solid rgb(0 0 0 / 14%);
    text-align: right;
    color: ${(props) => props.theme.colors.primaryBlue};
`;
export default Boxes;
