import React, { useState } from 'react';
import CustomModal, { ModalTriggerProps } from '../../../../shared/Modal/Modal';
import { styled } from '../../../../shared/Theme/theme';
import UserBadge from '../../../../shared/UserBadge/UserBadge';
import EditGroupNameForm from '../../components/EditGroupNameForm';
import MenuItem from '../../../../shared/Menu/MenuItem';
import Menu, { TriggerProps } from '../../../../shared/Menu/Menu';
import { Modal } from 'antd';
import exitGroupIcon from '../../../../../static/icons/exit-group.svg';
import editIcon from '../../../../../static/icons/edit.svg';
import peopleIcon from '../../../../../static/icons/people.svg';
import { chat, chatType } from '../../../../../models/entities/chat/chat';
import { userInfo } from '../../../../../models/entities/user';
import { userDetails } from '../../../../../state/ducks/users/users';
import { ParticipantsHeader } from './ParticipantsHeader';

type Props = {
    chatType: chatType;
    userInfo: userInfo;
    chat?: chat;
    getUserById: (userId: string) => userDetails | undefined;
    deleteGroupParticipant: (chatId: string, userId: string, userName: string) => Promise<void>;
    contactUser?: userDetails;
    isAdmin: boolean;
    setShowParticipants: React.Dispatch<React.SetStateAction<boolean>>;
    showParticipants: boolean;
    addGroupParticipants: (chatId: string, users: { id: string; name: string }[]) => Promise<void>;
    localization: any;
};
const Header = ({
    chat,
    chatType,
    contactUser,
    isAdmin,
    setShowParticipants,
    showParticipants,
    deleteGroupParticipant,
    userInfo,
    getUserById,
    addGroupParticipants,
    localization
}: Props) => {
    const [showAddParticipantsInput, setShowAddParticipantsInput] = useState<boolean>(false);
    const renderHeader = () => {
        if (chatType === 'user' && contactUser) {
            return (
                <ChatHeader>
                    <UserBadge width={40} userName={contactUser.name} hideBadgeName={true} userId={contactUser.id} />
                    <div className="header-name">{contactUser.name}</div>
                </ChatHeader>
            );
        } else if (chat) {
            return (
                <>
                    <ChatHeader className="group-header">
                        <div className="header-name">
                            {chat?.name}

                            {isAdmin && (
                                <CustomModal
                                    title={{ text: `Edit Group Name` }}
                                    Trigger={(props: ModalTriggerProps) => (
                                        <img className="edit-name" onClick={props.onClick} src={editIcon} alt="Edit" />
                                    )}
                                    Content={(setVisible?: React.Dispatch<React.SetStateAction<boolean>>) => {
                                        return (
                                            <EditGroupNameForm
                                                group={chat}
                                                openedFromModal={setVisible ? { setVisible } : undefined}
                                                localization={localization}
                                            />
                                        );
                                    }}
                                />
                            )}
                        </div>
                        <div style={{ display: 'flex', color: '#7d7d7d' }}>
                            <div
                                className="header-participants"
                                onClick={() => {
                                    setShowParticipants(!showParticipants);
                                    setShowAddParticipantsInput(false);
                                }}
                            >
                                <img src={peopleIcon} alt="People" />
                                <span className="participants-count">{chat?.participants.length}</span>
                            </div>
                            <Menu
                                position="left-center"
                                arrowOnTrigger={false}
                                options={[
                                    <MenuItem
                                        onClick={() => {
                                            Modal.confirm({
                                                zIndex: 1100,
                                                content: <div>Exit "{chat.name}" group?</div>,
                                                async onOk() {
                                                    await deleteGroupParticipant(chat.id, userInfo.id, getUserById(userInfo.id)?.name || '');
                                                },
                                                onCancel() {}
                                            });
                                        }}
                                        icon={exitGroupIcon}
                                        text={localization.header.exit_group}
                                    />
                                ]}
                                MenuTrigger={(props: TriggerProps) => (
                                    <span className="material-icons" onClick={props.onClick} style={{ cursor: 'pointer' }}>
                                        more_vert
                                    </span>
                                )}
                            />
                        </div>
                    </ChatHeader>
                    <ParticipantsHeader
                        chat={chat}
                        isAdmin={isAdmin}
                        deleteGroupParticipant={deleteGroupParticipant}
                        userInfo={userInfo}
                        getUserById={getUserById}
                        showParticipants={showParticipants}
                        showAddParticipantsInput={showAddParticipantsInput}
                        setShowAddParticipantsInput={setShowAddParticipantsInput}
                        addGroupParticipants={addGroupParticipants}
                        localization={localization.participants_header}
                    />
                </>
            );
        }
    };

    return <ChatHeaderContainer>{renderHeader()}</ChatHeaderContainer>;
};

const ChatHeaderContainer = styled.div``;
const ChatHeader = styled.div`
    display: flex;
    align-items: center;
    padding-left: 50px;
    height: 60px;
    .header-name {
        font-size: 16px;
        margin-left: 8px;

        .edit-name {
            cursor: pointer;
            visibility: hidden;
        }

        &:hover {
            .edit-name {
                visibility: visible;
            }
        }
    }
    .header-participants {
        cursor: pointer;
        .participants-count {
            width: 12px;
            height: 12px;
            line-height: 12px;
            font-size: 8px;
            border-radius: 50%;
            color: white;
            text-align: center;
            background-color: #008ac9;
            display: inline-block;
        }
    }
    background-color: #e8e8e89e;
`;

export default React.memo(Header);
