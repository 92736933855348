import React from 'react';

import { styled } from '../../Theme/theme';
import { mapCodeIcon } from '../../../../utils/countries-flags';

type Props = {
    value: string;
    text?: string;
    flagSize?: string;
};
const Country = ({ value, text, flagSize }: Props) => {
    const flagURL = mapCodeIcon(value?.toUpperCase());
    return (
        <Wrapper>
            <FlagIcon alt={text} src={flagURL} width={flagSize} />
            <CountryName> {text} </CountryName>
        </Wrapper>
    );
};

const Wrapper = styled.div`
    display: flex;
    justify-content: left;
    align-items: center;
`;

type StyledFlag = {
    width?: string;
};

const FlagIcon = styled.img<StyledFlag>`
    width: ${(props) => props.width ?? '20px'};
    margin-right: 6px;
`;

const CountryName = styled.div``;

export default Country;
