import React, { useState } from 'react';
import { useEffect } from 'react';
import { connect } from 'react-redux';
import { featureFlagsModel } from '../../../../../models/entities/businessPartner/settings';
import { businessPartner } from '../../../../../models/entities/businessPartner/businessPartner';
import { product } from '../../../../../models/entities/product/product';
import { localizationSelectors } from '../../../../../state/ducks/localization/selectors';
import { userSelectors } from '../../../../../state/ducks/user/selectors';
import { RootState } from '../../../../../state/store/store';
import Loader from '../../../../shared/SmallComponents/Loader';
import ProductForm from './ProductForm';
import { businessPartnersSelectors } from '../../../../../state/ducks/businessPartner/selectors';
import { categoriesSelectors } from '../../../../../state/ducks/categories/selectors';
import { allowedCompany } from '../../../../../models/entities/user';
import { Option } from '../../../../shared/inputs/base/DropDown';

export type BaseProps = {
    product?: product;
    localizations: any;
    createProduct: (product: product) => Promise<string>;
    duplicateProduct: (product: product, origProductId: string, includeDocuments?: boolean, includeAttributes?: boolean) => Promise<string>;
    updateProduct: (id: string, product: product, deletedSuppliers?: string[]) => Promise<boolean>;
    onClose?: (productId?: string) => void;
    fetchAllCategories?: () => Promise<void>;
    getCategoriesOptionsByCompanyId: (companyId: string) => Option[];
    businessPartnerSuppliersManufacturers: businessPartner[];
    businessPartnerManufacturers: businessPartner[];
    featureFlags?: featureFlagsModel;
    initSupplierId?: string;
    allowedCompanies: allowedCompany[];
    mode: eFormMode;
};
export type eFormMode = 'NEW' | 'EDIT' | 'DUPLICATE';

type Props = BaseProps & {
    showLoader: boolean;
};

const ProductFormConnected = ({
    localizations,
    showLoader,
    product,
    createProduct,
    updateProduct,
    duplicateProduct,
    fetchAllCategories,
    getCategoriesOptionsByCompanyId,
    onClose,
    businessPartnerSuppliersManufacturers,
    featureFlags,
    businessPartnerManufacturers,
    allowedCompanies,
    initSupplierId,
    mode
}: Props) => {
    useEffect(() => {
        if (fetchAllCategories) fetchAllCategories();
    }, [fetchAllCategories]);
    return showLoader ? (
        <Loader />
    ) : (
        <ProductForm
            localizations={localizations.form}
            mode={mode}
            product={product}
            createProduct={createProduct}
            updateProduct={updateProduct}
            duplicateProduct={duplicateProduct}
            getCategoriesOptionsByCompanyId={getCategoriesOptionsByCompanyId}
            onClose={onClose}
            businessPartnerSuppliersManufacturers={businessPartnerSuppliersManufacturers}
            businessPartnerManufacturers={businessPartnerManufacturers}
            featureFlags={featureFlags}
            allowedCompanies={allowedCompanies}
            initSupplierId={initSupplierId}
        />
    );
};

const mapStateToProps = (state: RootState) => ({
    showLoader: state.loading.effects.products.fetchProductById,
    localizations: localizationSelectors.products(state),
    featureFlags: userSelectors.featureFlags(state),
    businessPartnerSuppliersManufacturers: businessPartnersSelectors.businessPartnerSuppliersManufacturers(state),
    businessPartnerManufacturers: businessPartnersSelectors.businessPartnerManufacturers(state),
    allowedCompanies: userSelectors.allowedCompanies(state),
    getCategoriesOptionsByCompanyId: (companyId: string) => categoriesSelectors.getCategoriesOptionsByCompanyId(state)(companyId)
});

const mapDispatchToProps = (dispatch: any) => ({
    fetchAllCategories: () => dispatch.categories.fetchAllCategories(),
    createProduct: (product: product) => dispatch.products.createProduct(product),
    updateProduct: (id: string, product: product, deletedSuppliers?: string[]) => dispatch.products.updateProduct({ id, product, deletedSuppliers }),
    duplicateProduct: (product: product, origProductId: string, includeDocuments?: boolean, includeAttributes?: boolean) =>
        dispatch.products.duplicateProduct({ product, origProductId, includeDocuments, includeAttributes })
});

export default connect(mapStateToProps, mapDispatchToProps)(ProductFormConnected);
