import React from 'react';
import edit from '../../../../static/icons/edit.svg';
import trash from '../../../../static/icons/trash.svg';
import CellTrigger from '../../Menu/MenuTriggers/CellTrigger';
import { RootState } from '../../../../state/store/store';
import { connect } from 'react-redux';
import { singlePurchaseOrderSelectors } from '../../../../state/ducks/singlePurchaseOrder/selectors';
import { purchaseOrder } from '../../../../models/entities/purchaseOrder/purchaseOrder';
import Menu from '../../Menu/Menu';
import MenuItem from '../../Menu/MenuItem';
type onRowMoreOptionClickType = () => void;
type deletePurchaseOrderDocumentType = (purchaseOrderNumber: string, documentId: string) => void;
type Props = {
    entityId: string;
    localization: any;
    onRowMoreOptionsClick?: onRowMoreOptionClickType;
    deletePurchaseOrderDocument: deletePurchaseOrderDocumentType;
    purchaseOrder?: purchaseOrder;
};

const PurchaseOrderDocumentMenu = ({ entityId, purchaseOrder, localization, onRowMoreOptionsClick, deletePurchaseOrderDocument }: Props) => {
    const menuItems = [
        <MenuItem onClick={() => onRowMoreOptionsClick && onRowMoreOptionsClick()} icon={edit} text={localization.edit} />,
        <MenuItem onClick={() => purchaseOrder && deletePurchaseOrderDocument(purchaseOrder.id, entityId)} icon={trash} text={localization.delete} />
    ];

    return <Menu options={menuItems} MenuTrigger={CellTrigger} strStyle="display: flex;align-items: center;width: 100%;height: 100%;" />;
};

const mapStateToProps = (state: RootState) => ({
    purchaseOrder: singlePurchaseOrderSelectors.purchaseOrder(state)
});

const mapDispatchToProps = (dispatch: any) => ({
    deletePurchaseOrderDocument: (purchaseOrderNumber: string, documentId: string) =>
        dispatch.purchaseOrders.deletePurchaseOrderDocument({ purchaseOrderNumber, documentId })
});

export default connect(mapStateToProps, mapDispatchToProps)(PurchaseOrderDocumentMenu);
