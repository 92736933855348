import React from 'react';
import { Field } from 'formik-antd';
import DropDown, { ExtendedProps } from '../base/DropDown';
import { FieldProps } from 'formik';
import { ErrorText } from '.';
import { styled } from '../../Theme/theme';

type Props = ExtendedProps & {
    name: string;
};

const FormDropDown = ({ name, mode, onChange, onBlur, ...restProps }: Props) => {
    return (
        <Field name={name}>
            {({ field: { value }, meta, form: { setFieldValue, setFieldTouched } }: FieldProps) => {
                const { touched, error } = meta;
                const hasError = touched && error !== undefined;
                return (
                    <Container width={restProps.width} minWidth={restProps.minWidth}>
                        <DropDown
                            mode={mode}
                            hasError={hasError}
                            onChange={(value: any, option: any) => {
                                setFieldValue(name, value);
                                onChange && onChange(value, option);
                            }}
                            onBlur={(value: any) => {
                                setFieldTouched(name);
                                onBlur && onBlur(value);
                            }}
                            value={value === '' || value === null ? undefined : value}
                            {...restProps}
                        />
                        <ErrorText className="err-msg">{hasError && error}</ErrorText>
                    </Container>
                );
            }}
        </Field>
    );
};

interface ContainerProps {
    width?: string;
    minWidth?: string;
}
const Container = styled.div<ContainerProps>`
    width: ${(props) => props.width};
    min-width: ${(props) => props.minWidth};
`;

export default FormDropDown;
