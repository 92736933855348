import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { RouteComponentProps } from 'react-router-dom';
import { order, updateOrderPayload, updateOrderStatePayload } from '../../../models/entities/order';
import { orderAlert } from '../../../models/entities/order/orderAlert/orderAlert';
import { orderEvent } from '../../../models/entities/order/orderEvent/orderEvent';
import { businessPartnersSelectors } from '../../../state/ducks/businessPartner/selectors';
import { headerSelectors } from '../../../state/ducks/header/selectors';
import { localizationSelectors } from '../../../state/ducks/localization/selectors';
import { ordersSelectors } from '../../../state/ducks/orders/selectors';
import { singleOrderSelectors } from '../../../state/ducks/singleOrder/selectors';
import { userSelectors } from '../../../state/ducks/user/selectors';
import { RootState } from '../../../state/store/store';
import { Option } from '../../shared/inputs/base/DropDown';
import Loader from '../../shared/SmallComponents/Loader';
import ExceptionPage from '../ExceptionPage/ExceptionPage';
import { TimeLineItem } from '../singleShipment/sections/Events/express/TimeLine';
import SingleOrderPage from './SingleOrderPage';

type PathParams = {
    orderId: string;
};

type Props = RouteComponentProps<PathParams> & {
    order?: order;
    allowedCompaniesDropDownOptions: Option[];
    businessPartnersDropDownOptions: Option[];
    allowedShipmentTypes: Array<string>;
    localization: any;
    isUpdateOrder: boolean;
    isLoadingOrder: boolean;
    orderLastEvent: orderEvent | null;
    eventsTabData: TimeLineItem[];
    isDrawerOpen: boolean;
    fetchOrderError: string;
    setCurrentOrderId: (currentOrderId: string | null) => void;
    fetchSingleOrder: (orderId: string) => Promise<order>;
    updateOrder: (order: updateOrderPayload) => Promise<void>;
    updateOrderState: (payload: updateOrderStatePayload) => Promise<void>;
    getAlertStatusByOrderId: (orderId: string) => number;
    dismissOrderAlert: (orderId: string, alert: orderAlert) => Promise<void>;
};

const SingleOrderPageConnected = ({
    localization,
    order,
    match,
    allowedCompaniesDropDownOptions,
    businessPartnersDropDownOptions,
    allowedShipmentTypes,
    isUpdateOrder,
    isLoadingOrder,
    orderLastEvent,
    eventsTabData,
    isDrawerOpen,
    fetchOrderError,
    setCurrentOrderId,
    fetchSingleOrder,
    updateOrder,
    updateOrderState,
    getAlertStatusByOrderId,
    dismissOrderAlert
}: Props) => {
    const orderId = match.params.orderId;

    useEffect(() => {
        setCurrentOrderId(orderId);
        fetchSingleOrder(orderId);
    }, [orderId, setCurrentOrderId, fetchSingleOrder]);

    if (fetchOrderError) {
        const parsedError = JSON.parse(fetchOrderError);
        return <ExceptionPage status={parsedError.status} subTitle={parsedError.message} localization={localization} goBackPath="/orders" />;
    }
    if (!order || isLoadingOrder) return <Loader />;

    return (
        <SingleOrderPage
            order={order}
            allowedCompaniesDropDownOptions={allowedCompaniesDropDownOptions}
            businessPartnersDropDownOptions={businessPartnersDropDownOptions}
            allowedShipmentTypes={allowedShipmentTypes}
            localization={localization}
            isUpdateOrder={isUpdateOrder}
            orderLastEvent={orderLastEvent}
            eventsTabData={eventsTabData}
            isDrawerOpen={isDrawerOpen}
            refreshOrder={async () => {
                fetchSingleOrder(orderId);
            }}
            updateOrder={updateOrder}
            updateOrderState={updateOrderState}
            getAlertStatusByOrderId={getAlertStatusByOrderId}
            dismissOrderAlert={dismissOrderAlert}
        />
    );
};
const mapStateToProps = (state: RootState) => ({
    localization: localizationSelectors.ordersPage(state),
    order: singleOrderSelectors.order(state),
    isLoadingOrder: singleOrderSelectors.isLoadingOrder(state),
    allowedCompaniesDropDownOptions: userSelectors.allowedCompaniesDropDownOptions(state),
    businessPartnersDropDownOptions: businessPartnersSelectors.businessPartnersDropDownOptions(state),
    isUpdateOrder: singleOrderSelectors.isUpdateOrder(state),
    allowedShipmentTypes: userSelectors.allowedTypes(state),
    orderLastEvent: singleOrderSelectors.lastEvent(state),
    eventsTabData: singleOrderSelectors.eventsTabData(state),
    isDrawerOpen: !!headerSelectors.drawerOpen(state),
    fetchOrderError: singleOrderSelectors.fetchOrderError(state),
    getAlertStatusByOrderId: (orderId: string) => ordersSelectors.getAlertStatusByOrderId(state)(orderId)
});

const mapDispatchToProps = (dispatch: any) => ({
    setCurrentOrderId: (currentOrderId: string | null) => dispatch.singleOrder.setCurrentOrderId(currentOrderId),
    fetchSingleOrder: (orderId: string) => dispatch.orders.fetchOrderById(orderId),
    updateOrder: (payload: updateOrderPayload) => dispatch.orders.updateOrder(payload),
    updateOrderState: (payload: updateOrderStatePayload) => dispatch.orders.updateOrderState(payload),
    dismissOrderAlert: (orderId: string, alert: orderAlert) => dispatch.orders.dismissOrderAlert({ orderId, alert })
});

export default connect(mapStateToProps, mapDispatchToProps)(SingleOrderPageConnected);
