import React from 'react';
import Button from '../../../shared/SmallComponents/Button';
import styled from 'styled-components';

type Props = {
    name: string;
    number: number;
    children?: JSX.Element | JSX.Element[];
    isActive: boolean;
    isLast: boolean;
    onNextClick: (event: any) => void;
    onPreviousClick: (event: any) => void;
    onSectionNumberClick: (sectionNumber: number) => void;
    localizations: any;
};

const FormSection = (props: Props) => {
    return (
        <SectionContianer>
            <SectionNumber onClick={() => props.onSectionNumberClick(props.number)} isActive={props.isActive}>
                {props.number}
            </SectionNumber>
            <SectionTitle>{props.name}</SectionTitle>
            <ContentContainer shouldBeDisplayed={props.isActive} isLastSection={props.isLast}>
                <Content shouldBeDisplayed={props.isActive}>
                    {props.children}
                    {props.isActive && (
                        <ButtonsSection>
                            <Button disabled={props.number === 1} onClick={props.onPreviousClick}>
                                {props.localizations.previous_btn}
                            </Button>
                            <Button disabled={props.isLast} onClick={props.onNextClick}>
                                {props.localizations.next_btn}
                            </Button>
                        </ButtonsSection>
                    )}
                </Content>
            </ContentContainer>
        </SectionContianer>
    );
};

const ButtonsSection = styled.div`
    margin-top: 15px;
    display: flex;

    & button {
        margin-right: 20px;
        height: 30px;
        width: 100px;
    }
`;

const SectionContianer = styled.div`
    position: relative;
    margin-top: 20px;
`;

type SectionNumberProps = {
    isActive: boolean;
};

const SectionNumber = styled.div<SectionNumberProps>`
    cursor: pointer;
    height: 36px;
    width: 36px;
    font-size: 15px;
    text-align: center;
    line-height: 36px;
    border: 1px solid ${(props) => props.theme.colors.primaryBlue};
    background-color: ${(props) => (props.isActive ? props.theme.colors.primaryBlue : 'transparent')};
    color: ${(props) => (props.isActive ? 'white' : props.theme.colors.primaryBlue)};
    border-radius: 50px;
    position: absolute;
    left: -16px;
    top: -7px;
`;

type ContentProps = {
    shouldBeDisplayed: boolean;
};

const Content = styled.div<ContentProps>`
    display: ${(props) => (props.shouldBeDisplayed ? 'block' : 'none')};
`;

type ContentContainerProps = {
    isLastSection: boolean;
    shouldBeDisplayed: boolean;
};

const ContentContainer = styled.div<ContentContainerProps>`
    padding-bottom: ${(props) => (props.isLastSection ? (props.shouldBeDisplayed ? '15px' : '0') : '15px')};
    min-height: ${(props) => (props.isLastSection ? '0' : '40px')};
    padding-left: 30px;
    border-left: 2px dotted ${(props) => props.theme.colors.primaryBlue};
`;

const SectionTitle = styled.div`
    margin-top: 10px;
    margin-bottom: 20px;
    position: relative;
    left: 30px;
`;

export default FormSection;
