import React, { useEffect } from 'react';
import { RootState } from '../../../state/store/store';
import { connect } from 'react-redux';
import { userSelectors } from '../../../state/ducks/user/selectors';
import { shipmentFollowingInvitation } from '../../../models/entities/shipment/shipmentFollowingInvitation';
import Invitations from './Invitations';
import { followingInvitation } from '../../../models/entities/common_subentities/followingInvitation';
import { localizationSelectors } from '../../../state/ducks/localization/selectors';

type Props = {
    followingInvitations?: Array<followingInvitation>;
    isLoading: boolean;
    followShipment: (shipmentId: string, invitationId: string) => void;
    followPurchaseOrder: (purchaseOrderId: string, invitationId: string) => void;
    shipmentLocalization: any;
    purchaseOrderLocalization: any;
};

const InvitationsConnected = ({
    followingInvitations,
    isLoading,
    followShipment,
    followPurchaseOrder,
    shipmentLocalization,
    purchaseOrderLocalization
}: Props) => {
    return (
        <Invitations
            followingInvitations={followingInvitations}
            isLoading={isLoading}
            followShipment={followShipment}
            followPurchaseOrder={followPurchaseOrder}
            shipmentLocalization={shipmentLocalization}
            purchaseOrderLocalization={purchaseOrderLocalization}
        />
    );
};

const mapStateToProps = (state: RootState) => ({
    followingInvitations: userSelectors.mergeFollowingInvitations(state),
    isLoading:
        state.loading.effects.user.fetchUserShipmentFollowingInvitations || state.loading.effects.user.fetchUserPurchaseOrderFollowingInvitations,
    shipmentLocalization: localizationSelectors.followingShipments(state),
    purchaseOrderLocalization: localizationSelectors.followingPurchaseOrders(state)
});

const mapDispatchToProps = (dispatch: any) => ({
    followShipment: async (shipmentId: string, invitationId: string) => dispatch.user.followShipment({ shipmentId, invitationId }),
    followPurchaseOrder: async (purchaseOrderId: string, invitationId: string) => dispatch.user.followPurchaseOrder({ purchaseOrderId, invitationId })
});

export default connect(mapStateToProps, mapDispatchToProps)(InvitationsConnected);
