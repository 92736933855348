import React, { useRef } from 'react';
import { Form, Formik, FormikProps } from 'formik';
import {
    CalcType,
    createTariffChargePayload,
    genericCreateTariffChargePayload,
    tariffChargeCalcTypes,
    tariffChargeTypes,
    tariffChargeUnitTypes
} from '../../../../../models/entities/tariff/charge';
import { tariff } from '../../../../../models/entities/tariff';
import FormInputWrapper from '../../../../shared/Form/FormInputWrapper';
import { FormDropDown, FormNumberInput, FormTextArea, FormTextInput } from '../../../../shared/inputs/form';
import { Option } from '../../../../shared/inputs/base/DropDown';
import { currencyFormatter, currencyOptions } from '../../../../shared/inputs/form/CurrencyInput/CurrencyInput';
import { FIX, FLAT, BREAK, PERCENT, emptyFixCalcData, emptyFlatCalcData, emptyBreakCalcData, emptyPercentCalcData } from './CalcData';
import { connect } from 'react-redux';
import { RootState } from '../../../../../state/store/store';
import { numberParser } from '../../../../shared/inputs/form/NumberUnitInput';
import styled from 'styled-components';
import { localizationSelectors } from '../../../../../state/ducks/localization/selectors';

type Props = {
    tariff: tariff;
    localization: any;
    formRef?: React.Ref<FormikProps<createTariffChargePayload>>;
    createTariffCharge: (payload: createTariffChargePayload) => Promise<void>;
};
const chargeTypesOptions: Option[] = tariffChargeTypes.map((type) => ({ value: type, text: type }));
const unitTypesOptions: Option[] = tariffChargeUnitTypes.map((type) => ({ value: type, text: type }));
const calcTypesOptions: Option[] = tariffChargeCalcTypes.map((type) => ({ value: type, text: type }));

const CalcDataFormByType: {
    [K in CalcType]: React.FunctionComponent<{
        localization: any;
        formikProps: FormikProps<genericCreateTariffChargePayload<any>>;
        dynamicInputsContainerRef?: React.RefObject<HTMLDivElement>;
    }>;
} = {
    FIX: FIX,
    FLAT: FLAT,
    BREAK: BREAK,
    PERCENT: PERCENT
};
const emptyCalcDataByType = {
    FIX: emptyFixCalcData,
    FLAT: emptyFlatCalcData,
    BREAK: [emptyBreakCalcData],
    PERCENT: emptyPercentCalcData
};
const TariffChargeForm = ({ tariff, localization, formRef, createTariffCharge }: Props) => {
    const dynamicInputsContainerRef = useRef<HTMLDivElement>(null);

    const initialValues: createTariffChargePayload = {
        tariffId: tariff.id,
        chargeCode: '',
        chargeType: 'OC',
        currency: 'USD',
        unitType: 'KG',
        calcType: 'FIX',
        chargeCalcData: { price: 0 }
    };

    const onSubmit = async (values: createTariffChargePayload) => {
        await createTariffCharge(values);
    };

    return (
        <Formik<createTariffChargePayload> initialValues={initialValues} onSubmit={onSubmit} innerRef={formRef}>
            {(props: FormikProps<createTariffChargePayload>) => {
                const CalcDataForm = CalcDataFormByType[props.values.calcType];
                return (
                    <Form>
                        <StaticInuptsContainer>
                            <div style={{ width: '100%', marginRight: '15px' }}>
                                <FormInputWrapper fieldName="chargeCode" localization={localization.fields} isMandatory>
                                    <FormTextInput name="chargeCode" style={{ width: '100%' }} />
                                </FormInputWrapper>
                                <FormInputWrapper fieldName="chargeType" localization={localization.fields} isMandatory>
                                    <FormDropDown name="chargeType" style={{ width: '100%' }} options={chargeTypesOptions} />
                                </FormInputWrapper>
                                <FormInputWrapper fieldName="currency" localization={localization.fields} isMandatory>
                                    <FormDropDown name={'currency'} options={currencyOptions} style={{ width: '100%' }} showSearch={true} />
                                </FormInputWrapper>
                            </div>
                            <div style={{ width: '100%' }}>
                                <FormInputWrapper fieldName="unitType" localization={localization.fields} isMandatory>
                                    <FormDropDown name="unitType" style={{ width: '100%' }} options={unitTypesOptions} />
                                </FormInputWrapper>
                                <FormInputWrapper fieldName="calcType" localization={localization.fields} isMandatory>
                                    <FormDropDown
                                        name="calcType"
                                        style={{ width: '100%' }}
                                        options={calcTypesOptions}
                                        onChange={(type: CalcType) => {
                                            props.setFieldValue('chargeCalcData', emptyCalcDataByType[type]);
                                        }}
                                    />
                                </FormInputWrapper>
                                <FormInputWrapper fieldName="minimum" localization={localization.fields}>
                                    <FormNumberInput
                                        name="minimum"
                                        formatter={currencyFormatter(props.values.currency)}
                                        parser={numberParser}
                                        style={{ width: '100%' }}
                                    />
                                </FormInputWrapper>
                            </div>
                        </StaticInuptsContainer>
                        <DescriptionInuptContainer>
                            <FormInputWrapper fieldName="description" localization={localization.fields}>
                                <FormTextArea name="description" />
                            </FormInputWrapper>
                        </DescriptionInuptContainer>
                        <DynamicInuptsContainer ref={dynamicInputsContainerRef}>
                            <CalcDataForm formikProps={props} localization={localization} dynamicInputsContainerRef={dynamicInputsContainerRef} />
                        </DynamicInuptsContainer>
                    </Form>
                );
            }}
        </Formik>
    );
};

const StaticInuptsContainer = styled.div`
    display: flex;
`;
const DescriptionInuptContainer = styled.div`
    width: 100%;
    border-bottom: solid 1px ${(props) => props.theme.colors.grayDefaultBorder};
    margin-bottom: 20px;
    padding-bottom: 18px;
`;
const DynamicInuptsContainer = styled.div`
    width: 100%;
    max-height: 200px;
    overflow-y: auto;
`;
const mapStateToProps = (state: RootState) => ({
    localization: localizationSelectors.singleTariff(state).charges
});

const mapDispatchToProps = (dispatch: any) => ({
    createTariffCharge: (payload: createTariffChargePayload) => dispatch.tariffs.createTariffCharge(payload)
});

export default connect(mapStateToProps, mapDispatchToProps)(TariffChargeForm);
