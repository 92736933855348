import React, { useEffect } from 'react';
import SingleBusinessPartnerPage from './SingleBusinessPartnerPage';
import { RouteComponentProps, withRouter } from 'react-router-dom';
import { businessPartner } from '../../../../models/entities/businessPartner/businessPartner';
import { Dispatch, RootState } from '../../../../state/store/store';
import Loader from '../../../shared/SmallComponents/Loader';
import { singleBusinessPartnersSelectors } from '../../../../state/ducks/singleBusinessPartner/selectors';
import { localizationSelectors } from '../../../../state/ducks/localization/selectors';
import { connect } from 'react-redux';
import { businessPartnersSelectors } from '../../../../state/ducks/businessPartner/selectors';
import { settings } from '../../../../models/entities/businessPartner/businessPartner';
import { userSelectors } from '../../../../state/ducks/user/selectors';
import { allowedCompany, userInfo } from '../../../../models/entities/user';
import { product } from '../../../../models/entities/product/product';
import { drawers } from '../../../../state/ducks/header/header';
import { productsSelectors } from '../../../../state/ducks/products/selectors';
import { RcFile } from 'antd/lib/upload';
import { adminSelectors } from '../../../../state/ducks/admin/selectors';

type PathParams = {
    companyId: string;
};

type Props = RouteComponentProps<PathParams> & {
    businessPartner: businessPartner | undefined;
    setCurrentBusinessPartnerId: (currentCompanyId: string) => void;
    fetchSingleBusinessPartner: (companyId: string) => void;
    fetchProductsBySupplierId: (supplierId: string) => Promise<void>;
    updateBusinessPartnerIsActive: (businessPartnerId: string, isActive: boolean) => Promise<void>;
    updateBusinessPartnerLogo: (businessPartnerId: string, file: RcFile) => Promise<void>;
    showLogoLoader: boolean;
    localizations?: any;
    settings?: settings;
    allowedCompanies: allowedCompany[];
    getProductsBySupplierId: (supplierId: string) => product[];
    isLoading: boolean;
    loadingIsActive: boolean;
    isForwarder: boolean;
    setDrawerOpen: (open: drawers, params?: any) => void;
    fetchUsers: () => Promise<void>;
    usersInSameNetworkIncludeSelf: Array<userInfo>;
    users: userInfo[];
};

const SingleBusinessPartnerPageConnected = ({
    fetchUsers,
    usersInSameNetworkIncludeSelf,
    users,
    businessPartner,
    allowedCompanies,
    setCurrentBusinessPartnerId,
    fetchSingleBusinessPartner,
    fetchProductsBySupplierId,
    updateBusinessPartnerIsActive,
    updateBusinessPartnerLogo,
    showLogoLoader,
    localizations,
    match,
    settings,
    getProductsBySupplierId,
    isLoading,
    setDrawerOpen,
    loadingIsActive,
    isForwarder
}: Props) => {
    const urlCompanyId = match.params.companyId;
    useEffect(() => {
        fetchUsers();
        setCurrentBusinessPartnerId(urlCompanyId);
        fetchSingleBusinessPartner(urlCompanyId);
        fetchProductsBySupplierId(urlCompanyId);
    }, [urlCompanyId, fetchUsers, setCurrentBusinessPartnerId, fetchSingleBusinessPartner, fetchProductsBySupplierId]);

    return (
        <>
            {businessPartner && !isLoading ? (
                <SingleBusinessPartnerPage
                    businessPartner={businessPartner}
                    localization={localizations}
                    settings={settings}
                    allowedCompanies={allowedCompanies}
                    products={getProductsBySupplierId(urlCompanyId)}
                    setDrawerOpen={setDrawerOpen}
                    updateBusinessPartnerIsActive={updateBusinessPartnerIsActive}
                    loadingIsActive={loadingIsActive}
                    updateBusinessPartnerLogo={updateBusinessPartnerLogo}
                    showLogoLoader={showLogoLoader}
                    isForwarder={isForwarder}
                    users={usersInSameNetworkIncludeSelf}
                />
            ) : (
                <Loader />
            )}
        </>
    );
};

const mapStateToProps = (state: RootState) => ({
    users: adminSelectors.usersData(state),
    usersInSameNetworkIncludeSelf: userSelectors.usersInSameNetworkIncludeSelf(state),
    businessPartner: singleBusinessPartnersSelectors.businessPartner(state),
    documents: singleBusinessPartnersSelectors.documents(state),
    contacts: singleBusinessPartnersSelectors.contacts(state),
    addresses: singleBusinessPartnersSelectors.addresses(state),
    discussions: singleBusinessPartnersSelectors.discussions(state),
    localizations: localizationSelectors.SingleBusinessPartnerPage(state),
    settings: businessPartnersSelectors.settingsByLanguage(state),
    allowedCompanies: userSelectors.allowedCompanies(state),
    isLoading: !!state.loading.effects.products.fetchProductsBySupplierId,
    loadingIsActive: !!state.loading.effects.businessPartners.updateBusinessPartnerIsActive,
    showLogoLoader: !!state.loading.effects.companies.updateCompanyLogo,
    isForwarder: userSelectors.userInfo(state).companyType === 'FORWARDER',
    getProductsBySupplierId: (supplierId: string) => productsSelectors.getProductsBySupplierId(state)(supplierId)
});

const mapDispatchToProps = (dispatch: Dispatch) => ({
    fetchUsers: () => dispatch.admin.fetchUsers(),
    setCurrentBusinessPartnerId: (currentCompanyId: string) => dispatch.singleBusinessPartner.setCurrentBusinessPartnerId(currentCompanyId),
    updateBusinessPartnerIsActive: (businessPartnerId: string, isActive: boolean) =>
        dispatch.businessPartners.updateBusinessPartnerIsActive({ businessPartnerId, isActive }),
    fetchSingleBusinessPartner: (companyId: string) => dispatch.businessPartners.fetchBusinessPartnerById(companyId),
    fetchProductsBySupplierId: (supplierId: string) => dispatch.products.fetchProductsBySupplierId(supplierId),
    setDrawerOpen: (open: drawers, params?: any) => dispatch.header.setDrawerOpenParams({ open, params }),
    updateBusinessPartnerLogo: (businessPartnerId: string, file: RcFile) =>
        dispatch.businessPartners.updateBusinessPartnerLogo({ businessPartnerId, file })
});

export default withRouter(connect(mapStateToProps, mapDispatchToProps as any)(SingleBusinessPartnerPageConnected));
