import { RcFile } from 'antd/lib/upload';
import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { RouteComponentProps, withRouter } from 'react-router';
import { featureFlagsModel } from '../../../../../models/entities/businessPartner/settings';
import { company, integrationKeys } from '../../../../../models/entities/company/company';
import { companyOverview } from '../../../../../models/entities/company/companyOverview';
import { localizationSelectors } from '../../../../../state/ducks/localization/selectors';
import { singleCompanySelectors } from '../../../../../state/ducks/singleCompany/selectors';
import { userSelectors } from '../../../../../state/ducks/user/selectors';
import { RootState } from '../../../../../state/store/store';
import Loader from '../../../../shared/SmallComponents/Loader';
import SingleCompanyPage from './SingleCompanyPage';

type PathParams = {
    companyId: string;
};

type Props = RouteComponentProps<PathParams> & {
    company?: company;
    setCurrentCompanyId: (currentCompanyId: string | null) => void;
    fetchSingleCompany: (companyId: string) => Promise<company>;
    updateCompanyOverview: (companyId: string, companyOverview: companyOverview) => Promise<void>;
    updateCompanyLogo: (companyId: string, file: RcFile) => Promise<void>;
    localization: any;
    showLoader: boolean;
    showLogoLoader: boolean;
    createIntegrationApiKeys: (companyId: string) => Promise<Required<integrationKeys>>;
    isCreatingApiKeys: boolean;
    featureFlags?: featureFlagsModel;
};

const SingleCompanyPageConnected = ({
    company,
    updateCompanyOverview,
    setCurrentCompanyId,
    fetchSingleCompany,
    updateCompanyLogo,
    localization,
    match,
    showLoader,
    showLogoLoader,
    createIntegrationApiKeys,
    isCreatingApiKeys,
    featureFlags
}: Props) => {
    const urlCompanyId = match.params.companyId;

    useEffect(() => {
        setCurrentCompanyId(urlCompanyId);
        fetchSingleCompany(urlCompanyId);
    }, [urlCompanyId, setCurrentCompanyId, fetchSingleCompany]);

    return company ? (
        <SingleCompanyPage
            company={company}
            localization={localization}
            updateCompanyOverview={updateCompanyOverview}
            showLoader={showLoader}
            updateCompanyLogo={updateCompanyLogo}
            showLogoLoader={showLogoLoader}
            createIntegrationApiKeys={createIntegrationApiKeys}
            isCreatingApiKeys={isCreatingApiKeys}
            featureFlags={featureFlags}
        />
    ) : (
        <Loader />
    );
};

const mapStateToProps = (state: RootState) => ({
    company: singleCompanySelectors.company(state),
    localization: localizationSelectors.singleCompanyPage(state),
    showLoader: !!state.loading.effects.companies.updateCompanyOverview,
    showLogoLoader: !!state.loading.effects.companies.updateCompanyLogo,
    isCreatingApiKeys: !!state.loading.effects.companies.createIntegrationApiKeys,
    featureFlags: userSelectors.featureFlags(state)
});

const mapDispatchToProps = (dispatch: any) => ({
    setCurrentCompanyId: (currentCompanyId: string | null) => dispatch.singleCompany.setCurrentCompanyId(currentCompanyId),
    fetchSingleCompany: (companyId: string) => dispatch.companies.fetchCompanyById(companyId),
    updateCompanyOverview: (companyId: string, companyOverview: companyOverview) =>
        dispatch.companies.updateCompanyOverview({ id: companyId, companyOverview }),
    updateCompanyLogo: (companyId: string, file: RcFile) => dispatch.companies.updateCompanyLogo({ companyId, file }),
    createIntegrationApiKeys: (companyId: string) => dispatch.companies.createIntegrationApiKeys(companyId)
});

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(SingleCompanyPageConnected));
