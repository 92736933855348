import React, { useEffect, useState } from 'react';
import { Form, Formik } from 'formik';
import { RootState } from '../../../../../state/store/store';
import { connect } from 'react-redux';
import { styled } from '../../../../shared/Theme/theme';
import Button from '../../../../shared/SmallComponents/Button';
import { localizationSelectors } from '../../../../../state/ducks/localization/selectors';
import { filterFieldsType } from '../../../../../state/ducks/products/products';
import { businessPartnersSelectors } from '../../../../../state/ducks/businessPartner/selectors';
import { businessPartner } from '../../../../../models/entities/businessPartner/businessPartner';
import { userSelectors } from '../../../../../state/ducks/user/selectors';
import { allowedCompany } from '../../../../../models/entities/user';
import { productsSelectors } from '../../../../../state/ducks/products/selectors';
import { product } from '../../../../../models/entities/product/product';
import FieldLabel from '../../../../shared/SmallComponents/FieldLabel';
import { FormDropDown, FormTextInput } from '../../../../shared/inputs/form';
import { partiesToDropdownOptions } from '../../../businessPartners/components/BusinessPartnerForm/BusinessPartnerFormConnected';
import { renderCountryOption } from '../../../../shared/SmallComponents/CountryOption';
import countries from '../../../../../static/data/countries.json';

type Props = {
    localizations: any;
    filterFields: filterFieldsType | null;
    onCancel: () => void;
    setProductFilters: (filter: filterFieldsType) => void;
    businessPartnerForwarders: Array<businessPartner>;
    allowedCompanies: allowedCompany[];
    companySupplier: businessPartner[];
    businessPartnerManufacturers: businessPartner[];
};

const countriesDropDownOptions = countries.map((country) => ({
    text: country.name,
    value: country.code
}));

type formFilterFields = {
    isActive?: string;
    supplierId?: string[];
    companyId?: string[];
    brand?: string;
    madeIn?: string;
};

const FilterContent = ({
    localizations,
    allowedCompanies,
    filterFields,
    onCancel,
    setProductFilters,
    businessPartnerManufacturers,
    companySupplier
}: Props) => {
    const [formFieldsValues, setFormFieldsValues] = useState<formFilterFields>({});

    useEffect(() => {
        const filterFieldsValues: formFilterFields = {};
        filterFields?.forEach(({ field, value }: { field: string; value: any }) => {
            (filterFieldsValues as any)[field] = value;
        });
        setFormFieldsValues(filterFieldsValues);
    }, [filterFields]);

    const setInitialValues = () => {
        return formFieldsValues;
    };
    const validate = (values: any) => {
        const errors: any = {};
        return errors;
    };
    const submit = (values: any) => {
        const filter: filterFieldsType = [];

        Object.keys(formFieldsValues).forEach((item) => {
            if ((formFieldsValues as any)[item]) filter.push({ field: item as keyof product, value: (formFieldsValues as any)[item] });
        });

        setProductFilters(filter);

        onCancel();
    };

    const resetForm = () => {
        setFormFieldsValues({});
        setProductFilters([]);
    };

    return (
        <Formik initialValues={setInitialValues()} validate={validate} onSubmit={submit}>
            {({ values, setFieldValue }: any) => {
                return (
                    <FormContainer>
                        <Container>
                            <FieldContainer data-popover-bug>
                                <FieldLabel>{localizations.active_products}</FieldLabel>
                                <FormDropDown
                                    preventPopoverBug={true}
                                    name="isActive"
                                    placeHolder={localizations.active_products}
                                    options={[
                                        { text: localizations.active, value: 'true' },
                                        { text: localizations.inactive, value: 'false' }
                                    ]}
                                    allowClear
                                    onChange={(value: string) => setFormFieldsValues({ ...formFieldsValues, isActive: value })}
                                    value={formFieldsValues.isActive}
                                />
                            </FieldContainer>

                            <FieldContainer data-popover-bug>
                                <FieldLabel>{localizations.supplier}</FieldLabel>
                                <FormDropDown
                                    preventPopoverBug={true}
                                    name="supplierId"
                                    placeHolder={localizations.supplier}
                                    options={partiesToDropdownOptions(companySupplier?.concat(businessPartnerManufacturers || []))}
                                    allowClear
                                    onChange={(value: string[]) => setFormFieldsValues({ ...formFieldsValues, supplierId: value })}
                                    value={formFieldsValues.supplierId}
                                    mode="multiple"
                                />
                            </FieldContainer>

                            <FieldContainer data-popover-bug>
                                <FieldLabel>{localizations.madeIn}</FieldLabel>
                                <FormDropDown
                                    preventPopoverBug={true}
                                    name="madeIn"
                                    placeHolder={localizations.madeIn}
                                    options={countriesDropDownOptions}
                                    optionRenderer={renderCountryOption}
                                    showSearch={true}
                                    onChange={(value: string) => setFormFieldsValues({ ...formFieldsValues, madeIn: value })}
                                    value={formFieldsValues.madeIn}
                                    allowClear
                                />
                            </FieldContainer>
                            <FieldContainer data-popover-bug>
                                <FieldLabel>{localizations.brand}</FieldLabel>
                                <FormTextInput
                                    name="brand"
                                    placeHolder={localizations.brand}
                                    onChange={(value: string) => setFormFieldsValues({ ...formFieldsValues, brand: value })}
                                    value={formFieldsValues.brand}
                                    allowClear
                                />
                            </FieldContainer>

                            {allowedCompanies.length > 1 && (
                                <>
                                    <FieldContainer style={{ display: 'flex', flexDirection: 'column' }}>
                                        <FieldLabel>{localizations.company}</FieldLabel>
                                        <FormDropDown
                                            name="companyId"
                                            style={{ width: '200px', marginRight: '15px' }}
                                            placeHolder={localizations.company}
                                            options={allowedCompanies.map((item) => {
                                                return { value: item.companyId, text: item.companyName };
                                            })}
                                            allowClear
                                            mode="multiple"
                                            onChange={(value: string[]) => setFormFieldsValues({ ...formFieldsValues, companyId: value })}
                                            value={formFieldsValues.companyId}
                                        />
                                    </FieldContainer>
                                </>
                            )}
                        </Container>
                        <FormFooter>
                            <Button minWidth="55px" type="button" onClick={onCancel}>
                                <span>{localizations.cancel_btn}</span>
                            </Button>
                            <Flex>
                                <Button minWidth="55px" type="button" onClick={resetForm}>
                                    <span>{localizations.reset_btn}</span>
                                </Button>
                                <Button minWidth="55px" buttonType="filled" type="submit">
                                    <span>{localizations.filter_btn}</span>
                                </Button>
                            </Flex>
                        </FormFooter>
                    </FormContainer>
                );
            }}
        </Formik>
    );
};
const FormContainer = styled(Form)`
    min-width: 500px;
    @media screen and (max-width: 768px) {
        min-width: 100%;
    }
`;
const FieldContainer = styled.div`
    margin-bottom: 12px;
    flex-basis: 45%;
    width: 0;
    padding: 0 10px;
    @media screen and (max-width: 768px) {
        flex-basis: 100%;
    }
`;
const Divider = styled.div`
    width: 100%;
    margin-bottom: 12px;
    border-top: 1px solid rgba(0, 0, 0, 0.06);
    @media screen and (max-width: 768px) {
        display: none;
    }
`;
const Container = styled.div`
    width: 100%;
    max-height: 420px;
    overflow: auto;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-around;
    @media screen and (max-width: 768px) {
        height: calc(100vh - 70px);
        padding: 4px 12px;
        max-height: 100%;
    }
`;

const FormFooter = styled.div`
    position: absolute;
    width: 100%;
    display: flex;
    justify-content: space-between;
    height: 70px;
    padding: 17px 30px;
    border-top: 1px solid #eaeaea;
    left: 0;
    right: 0;
    bottom: -70px;
    background: white;
    box-shadow: 0 9px 12px 1px rgb(0 0 0 / 9%);
    button {
        padding: 15px;
    }
    @media screen and (max-width: 768px) {
        bottom: 0;
        button {
            span {
                display: -webkit-box;
            }
        }
    }
`;
const Flex = styled.div`
    display: flex;
`;

const mapStateToProps = (state: RootState) => ({
    localizations: localizationSelectors.products(state).filter,
    filterFields: productsSelectors.filterFields(state),
    businessPartnerForwarders: businessPartnersSelectors.businessPartnerForwarders(state),
    allowedCompanies: userSelectors.allowedCompanies(state),
    companySupplier: businessPartnersSelectors.businessPartnerSuppliers(state),
    businessPartnerManufacturers: businessPartnersSelectors.businessPartnerManufacturers(state)
});

const mapDispatchToProps = (dispatch: any) => ({
    setProductFilters: (filter: filterFieldsType) => dispatch.products.setProductFilters(filter)
});

export default connect(mapStateToProps, mapDispatchToProps)(FilterContent);
