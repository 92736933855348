import { purchaseDemand, settingsLanguage } from '../../../models/entities/purchaseDemand/purchaseDemand';
import { ModelConfig } from '@rematch/core';
import { localSDK as client } from '../../../sdk';
import moment from 'moment';
import { addDiscussion, editDiscussion, removeDiscussion, setDiscussions } from '../../util/common_subentities/discussions';
import { discussion } from '../../../models/entities/common_subentities/discussion';
import { filterFieldsType } from '../allPurchaseDemandsPage/allPurchaseDemandsPage';
import { purchaseDemandItem } from '../../../models/entities/purchaseDemand/purchaseDemandItem';
import { addEntity, editEntity, removeEntity } from '../../util/common_subentities/generic';
import { purchaseDemandApprover } from '../../../models/entities/purchaseDemand/purchaseDemandApprover';
import { setDocuments, addDocument, editDocument, removeDocument } from '../../util/common_subentities/documents';
import { document, documents } from '../../../models/entities/common_subentities/document';
import { history } from '../../../models/entities/common_subentities/history';
import { setHistory } from '../../util/common_subentities/history';

export type purchaseDemandsStateType = {
    purchaseDemands: Array<purchaseDemand>;
};

type createMultiplePurchaseDemandItemsPayload = {
    purchaseDemandId: string;
    items: Array<purchaseDemandItem>;
};

type createPurchaseDemandPayload = {
    purchaseDemand: purchaseDemand;
};

type updatePurchaseDemandPayload = {
    id: string;
    purchaseDemand: purchaseDemand;
};

export const purchaseDemands: ModelConfig<purchaseDemandsStateType> = {
    state: {
        purchaseDemands: []
    },
    reducers: {
        setPurchaseDemands(state: purchaseDemandsStateType, purchaseDemands: Array<purchaseDemand>): purchaseDemandsStateType {
            return { ...state, purchaseDemands };
        },
        setSinglePurchaseDemand(state: purchaseDemandsStateType, newPurchaseDemand: purchaseDemand): purchaseDemandsStateType {
            const newPurchaseDemands = [...state.purchaseDemands];
            const purchaseDemandIndex = newPurchaseDemands.findIndex((purchaseDemand) => purchaseDemand.id === newPurchaseDemand.id);
            if (purchaseDemandIndex !== -1) {
                newPurchaseDemands[purchaseDemandIndex] = newPurchaseDemand;
            } else {
                newPurchaseDemands.push(newPurchaseDemand);
            }
            return { ...state, purchaseDemands: newPurchaseDemands };
        },
        setMultiplePurchaseDemands(state: purchaseDemandsStateType, purchaseDemands: purchaseDemand[]): purchaseDemandsStateType {
            const newPurchaseDemands = [...state.purchaseDemands];
            purchaseDemands.forEach((purchaseDemand) => {
                const purchaseDemandIndex = newPurchaseDemands.findIndex((s) => s.id === purchaseDemand.id);

                if (purchaseDemandIndex !== -1) {
                    // newPurchaseDemands[purchaseDemandIndex] = purchaseDemand;
                } else {
                    newPurchaseDemands.push(purchaseDemand);
                }
            });
            return { ...state, purchaseDemands: newPurchaseDemands };
        },
        editPurchaseDemand(state: purchaseDemandsStateType, purchaseDemand: purchaseDemand): purchaseDemandsStateType {
            let currentPurchaseDemands = [...state.purchaseDemands];
            const purchaseDemandIndex = currentPurchaseDemands.findIndex((currentPurchaseDemand) => currentPurchaseDemand.id === purchaseDemand.id);
            currentPurchaseDemands[purchaseDemandIndex] = purchaseDemand;
            return { ...state, purchaseDemands: currentPurchaseDemands };
        },
        removePurchaseDemand(state: purchaseDemandsStateType, purchaseDemandNumber: string): purchaseDemandsStateType {
            const newPurchaseDemands = [...state.purchaseDemands];
            return { ...state, purchaseDemands: newPurchaseDemands.filter((item) => item.id !== purchaseDemandNumber) };
        },
        setPurchaseDemandState(state: purchaseDemandsStateType, purchaseDemandId: string, purchaseDemandState: string): purchaseDemandsStateType {
            const newPurchaseDemands = [...state.purchaseDemands];
            const purchaseDemandsIndex = newPurchaseDemands.findIndex((purchaseDemand) => purchaseDemand.id === purchaseDemandId);
            if (purchaseDemandsIndex !== -1) {
                newPurchaseDemands[purchaseDemandsIndex] = { ...newPurchaseDemands[purchaseDemandsIndex], state: purchaseDemandState };
            }
            return { ...state, purchaseDemands: newPurchaseDemands };
        },
        setPurchaseDemandItem(state: purchaseDemandsStateType, item: purchaseDemandItem): purchaseDemandsStateType {
            const newPurchaseDemands = [...state.purchaseDemands];
            const purchaseDemandIndex = newPurchaseDemands.findIndex((purchaseDemand) => purchaseDemand.id === item.purchaseDemandId);
            if (purchaseDemandIndex !== -1) {
                const currentPD = { ...newPurchaseDemands[purchaseDemandIndex] };
                const currentPoItems = [...(currentPD.items || [])];
                const poItemIndex = currentPoItems.findIndex((poItem) => poItem.id === item.id);
                if (purchaseDemandIndex !== -1) {
                    currentPoItems[poItemIndex] = item;
                    currentPD.items = currentPoItems;
                    newPurchaseDemands[purchaseDemandIndex] = currentPD;
                }
            }
            return { ...state, purchaseDemands: newPurchaseDemands };
        },
        addMultiplePurchaseDemandItems(
            state: purchaseDemandsStateType,
            purchaseDemandId: string,
            items: Array<purchaseDemandItem>
        ): purchaseDemandsStateType {
            const newPDs = [...state.purchaseDemands];
            const purchaseDemandIndex = newPDs.findIndex((p) => p.id === purchaseDemandId);
            if (purchaseDemandIndex !== -1) {
                const currentPD = { ...newPDs[purchaseDemandIndex] };
                const newItemsIds = items.map((i) => i.id);
                const currentPDItems = [...(currentPD.items || [])].filter((item) => !newItemsIds.includes(item.id));
                const newPoItems = [...currentPDItems, ...items];
                currentPD.items = newPoItems;
                newPDs[purchaseDemandIndex] = currentPD;
            }
            return { ...state, purchaseDemands: [...newPDs] };
        },
        removePurchaseDemandItem(state: purchaseDemandsStateType, purchaseDemandId: string, itemId: string) {
            const newPDs = [...state.purchaseDemands];
            const purchaseDemandIndex = newPDs.findIndex((p) => p.id === purchaseDemandId);

            if (purchaseDemandIndex !== -1) {
                const currentPD = { ...newPDs[purchaseDemandIndex] };
                const currentPDItems = [...(currentPD.items || [])];

                const purchaseDemandItemIndex = currentPDItems.findIndex((item) => item.id === itemId);

                if (purchaseDemandItemIndex != null && purchaseDemandItemIndex !== -1) {
                    currentPDItems.splice(purchaseDemandItemIndex, 1);
                    currentPD.items = currentPDItems;
                }

                newPDs[purchaseDemandIndex] = currentPD;
            }
            return { ...state, purchaseDemands: [...newPDs] };
        },
        setPurchaseDemandDiscussions(
            state: purchaseDemandsStateType,
            purchaseDemandId: string,
            purchaseDemandDiscussions: Array<discussion>
        ): purchaseDemandsStateType {
            return { ...state, purchaseDemands: setDiscussions(state, purchaseDemandId, 'purchaseDemands', purchaseDemandDiscussions) };
        },
        addPurchaseDemandDiscussion(state: purchaseDemandsStateType, purchaseDemandNumber: string, discussion: discussion): purchaseDemandsStateType {
            return { ...state, purchaseDemands: addDiscussion(state, purchaseDemandNumber, 'purchaseDemands', discussion) };
        },
        editPurchaseDemandDiscussion(
            state: purchaseDemandsStateType,
            purchaseDemandNumber: string,
            discussion: discussion
        ): purchaseDemandsStateType {
            return { ...state, purchaseDemands: editDiscussion(state, purchaseDemandNumber, 'purchaseDemands', discussion) };
        },
        removePurchaseDemandDiscussion(
            state: purchaseDemandsStateType,
            purchaseDemandNumber: string,
            discussionId: string
        ): purchaseDemandsStateType {
            return { ...state, purchaseDemands: removeDiscussion(state, purchaseDemandNumber, 'purchaseDemands', discussionId) };
        },
        addPurchaseDemandApprover(
            state: purchaseDemandsStateType,
            purchaseDemandNumber: string,
            approver: purchaseDemandApprover
        ): purchaseDemandsStateType {
            return {
                ...state,
                purchaseDemands: addEntity<purchaseDemandApprover>(state, purchaseDemandNumber, 'purchaseDemands', 'approvers', approver)
            };
        },
        editPurchaseDemandApprover(
            state: purchaseDemandsStateType,
            purchaseDemandNumber: string,
            approver: purchaseDemandApprover
        ): purchaseDemandsStateType {
            return {
                ...state,
                purchaseDemands: editEntity<purchaseDemandApprover>(state, purchaseDemandNumber, 'purchaseDemands', 'approvers', approver)
            };
        },
        removePurchaseDemandApprover(state: purchaseDemandsStateType, purchaseDemandNumber: string, approverId: number): purchaseDemandsStateType {
            return {
                ...state,
                purchaseDemands: removeEntity<purchaseDemandApprover>(state, purchaseDemandNumber, 'purchaseDemands', 'approvers', approverId)
            };
        },

        setPurchaseDemandDocuments(
            state: purchaseDemandsStateType,
            purchaseDemandId: string,
            purchaseDemandDocuments: documents
        ): purchaseDemandsStateType {
            return { ...state, purchaseDemands: setDocuments(state, purchaseDemandId, 'purchaseDemands', purchaseDemandDocuments) };
        },
        setPurchaseDemandHistory(
            state: purchaseDemandsStateType,
            purchaseDemandId: string,
            purchaseDemandHistory: Array<history>
        ): purchaseDemandsStateType {
            return { ...state, purchaseDemands: setHistory(state, purchaseDemandId, 'purchaseDemands', purchaseDemandHistory) };
        },
        addPurchaseDemandDocument(state: purchaseDemandsStateType, purchaseDemandNumber: string, document: document): purchaseDemandsStateType {
            return { ...state, purchaseDemands: addDocument(state, purchaseDemandNumber, 'purchaseDemands', document) };
        },
        editPurchaseDemandDocument(state: purchaseDemandsStateType, purchaseDemandNumber: string, document: document): purchaseDemandsStateType {
            return { ...state, purchaseDemands: editDocument(state, purchaseDemandNumber, 'purchaseDemands', document) };
        },
        removePurchaseDemandDocument(state: purchaseDemandsStateType, purchaseDemandNumber: string, documentId: number): purchaseDemandsStateType {
            return { ...state, purchaseDemands: removeDocument(state, purchaseDemandNumber, 'purchaseDemands', documentId) };
        }
    },
    effects: (dispatch: any) => ({
        async fetchPurchaseDemands() {
            const purchaseDemands = await client.purchaseDemands().fetchPurchaseDemands();
            dispatch.purchaseDemands.setPurchaseDemands(purchaseDemands);
            dispatch.allPurchaseDemandsPage.setCurrentPage(0);
        },
        async fetchPurchaseDemandHistoryById(purchaseDemandId: string) {
            const purchaseDemandHistoryData = await client.purchaseDemands().history().fetchPurchaseDemandHistoryById(purchaseDemandId);
            dispatch.purchaseDemands.setPurchaseDemandHistory(purchaseDemandId, purchaseDemandHistoryData);
        },
        async fetchShipmentsByFilter(filter: filterFieldsType) {
            const shipments = await client.shipments().fetchShipmentsByFilter(filter);
            dispatch.purchaseDemands.setFilteredShipments(shipments);
        },
        async fetchPurchaseDemandById(purchaseDemandId: string) {
            const purchaseDemandData = await client.purchaseDemands().fetchById(purchaseDemandId);
            dispatch.purchaseDemands.setSinglePurchaseDemand(purchaseDemandData);

            return purchaseDemandData;
        },
        async fetchPurchaseDemandsByCZNumber(czNumber: string) {
            const purchaseDemandsData = await client.purchaseDemands().fetchByCZNumber(czNumber);
            return purchaseDemandsData;
        },
        async fetchPurchaseDemandsByIds(purchaseDemandsIds: string[]) {
            const purchaseDemandsData = await client.purchaseDemands().fetchByIds(purchaseDemandsIds);
            dispatch.purchaseDemands.setMultiplePurchaseDemands(purchaseDemandsData);
        },
        async fetchPurchaseDemandItemsById(purchaseDemandId: string) {
            const purchaseDemandItemsData = await client.purchaseDemands().fetchPurchaseDemandItems(purchaseDemandId);
            dispatch.purchaseDemands.setPurchaseDemandItems(purchaseDemandId, purchaseDemandItemsData);
        },
        async fetchPurchaseDemandDiscussionsById(purchaseDemandId: string) {
            const purchaseDemandDiscussionsData = await client.purchaseDemands().discussions().fetchPurchaseDemandDiscussions(purchaseDemandId);
            dispatch.purchaseDemands.setPurchaseDemandDiscussions(purchaseDemandId, purchaseDemandDiscussionsData);
        },
        async createPurchaseDemand({ purchaseDemand }: createPurchaseDemandPayload) {
            const createdPurchaseDemandId = await client.purchaseDemands().createPurchaseDemand(purchaseDemand);
            dispatch.purchaseDemands.fetchPurchaseDemands();
            return createdPurchaseDemandId;
        },
        async updatePurchaseDemand({ id, purchaseDemand }: updatePurchaseDemandPayload) {
            await client.purchaseDemands().updatePurchaseDemand(id, purchaseDemand);
            dispatch.purchaseDemands.editPurchaseDemand(purchaseDemand);
            return true;
        },
        async createPurchaseDemandItems({ purchaseDemandId, items }: createMultiplePurchaseDemandItemsPayload) {
            const createdItems = await client.purchaseDemands().items().createMultiplePurchaseDemandItems(purchaseDemandId, items);
            dispatch.purchaseDemands.addMultiplePurchaseDemandItems(purchaseDemandId, createdItems);
            dispatch.purchaseDemands.fetchPurchaseDemandHistoryById(purchaseDemandId);
        },
        async updatePurchaseDemandItem(item: purchaseDemandItem) {
            dispatch.purchaseDemands.setPurchaseDemandItem(item);
            await client.purchaseDemands().items().updatePurchaseDemandItem(item);
            dispatch.purchaseDemands.fetchPurchaseDemandHistoryById(item.purchaseDemandId);
        },
        async deletePurchaseDemandItem({ purchaseDemandId, itemId }: { purchaseDemandId: string; itemId: string }) {
            dispatch.purchaseDemands.removePurchaseDemandItem(purchaseDemandId, itemId);
            await client.purchaseDemands().items().deletePurchaseDemandItem(purchaseDemandId, itemId);
        },
        async createPurchaseDemandApprover({ purchaseDemandNumber, approver }: { purchaseDemandNumber: string; approver: purchaseDemandApprover }) {
            const createdApprover = await client.purchaseDemands().approvers().createPurchaseDemandApprover(purchaseDemandNumber, approver);
            dispatch.purchaseDemands.fetchPurchaseDemandHistoryById(purchaseDemandNumber);
        },
        async updatePurchaseDemandApproverState({
            purchaseDemandNumber,
            approver
        }: {
            purchaseDemandNumber: string;
            approver: purchaseDemandApprover;
        }) {
            await client.purchaseDemands().approvers().updatePurchaseDemandApproverState(approver);
            dispatch.purchaseDemands.editPurchaseDemandApprover(purchaseDemandNumber, approver);
        },
        async deletePurchaseDemandApprover({ purchaseDemandNumber, approverId }: { purchaseDemandNumber: string; approverId: string }) {
            await client.purchaseDemands().approvers().deletePurchaseDemandApprover(purchaseDemandNumber, approverId);
            dispatch.purchaseDemands.removePurchaseDemandApprover(purchaseDemandNumber, approverId);
        },
        async createPurchaseDemandDiscussion({
            purchaseDemandNumber,
            discussion,
            pdCzNumber
        }: {
            purchaseDemandNumber: string;
            discussion: discussion;
            pdCzNumber?: string;
        }) {
            const createdDiscussion = await client
                .purchaseDemands()
                .discussions()
                .createPurchaseDemandDiscussion(purchaseDemandNumber, discussion, pdCzNumber);
            dispatch.purchaseDemands.addPurchaseDemandDiscussion(purchaseDemandNumber, createdDiscussion);
        },
        async updatePurchaseDemandDiscussion({
            purchaseDemandNumber,
            discussion,
            pdCzNumber
        }: {
            purchaseDemandNumber: string;
            discussion: discussion;
            pdCzNumber?: string;
        }) {
            dispatch.purchaseDemands.editPurchaseDemandDiscussion(purchaseDemandNumber, discussion);
            await client.purchaseDemands().discussions().updatePurchaseDemandDiscussion(purchaseDemandNumber, discussion, pdCzNumber);
        },
        async deletePurchaseDemandDiscussion({ purchaseDemandNumber, discussionId }: { purchaseDemandNumber: string; discussionId: number }) {
            await client.purchaseDemands().discussions().deletePurchaseDemandDiscussion(purchaseDemandNumber, discussionId);
            dispatch.purchaseDemands.removePurchaseDemandDiscussion(purchaseDemandNumber, discussionId);
        },
        async updatePurchaseDemandState({ purchaseDemandId, purchaseDemandState }: { purchaseDemandId: string; purchaseDemandState: string }) {
            dispatch.purchaseDemands.setPurchaseDemandState(purchaseDemandId, purchaseDemandState);
            await client.purchaseDemands().updatePurchaseDemandState(purchaseDemandId, purchaseDemandState);
            dispatch.purchaseDemands.fetchPurchaseDemandHistoryById(purchaseDemandId);
        },

        async createMultiplePurchaseDemandDocument({
            purchaseDemandNumber,
            documents
        }: {
            purchaseDemandNumber: string;
            documents: Array<document>;
        }) {
            dispatch.loadingNotify.setLoadingState({ visible: true, text: 'Uploading files...' });
            const createdDocuments = await client.purchaseDemands().documents().createMultiplePurchaseDemandDocument(purchaseDemandNumber, documents);
            createdDocuments.forEach((document: document) => {
                dispatch.purchaseDemands.addPurchaseDemandDocument(purchaseDemandNumber, document);
            });
            dispatch.loadingNotify.setLoadingState({ visible: false });
        },
        async createPurchaseDemandDocument({ purchaseDemandNumber, document }: { purchaseDemandNumber: string; document: document }) {
            const createdDocument = await client.purchaseDemands().documents().createPurchaseDemandDocument(purchaseDemandNumber, document);
            dispatch.purchaseDemands.addPurchaseDemandDocument(purchaseDemandNumber, createdDocument);
        },
        async updatePurchaseDemandDocument({ purchaseDemandNumber, document }: { purchaseDemandNumber: string; document: document }) {
            await client.purchaseDemands().documents().updatePurchaseDemandDocument(purchaseDemandNumber, document);
            dispatch.purchaseDemands.editPurchaseDemandDocument(purchaseDemandNumber, document);
        },
        async deletePurchaseDemandDocument({ purchaseDemandNumber, documentId }: { purchaseDemandNumber: string; documentId: number }) {
            await client.purchaseDemands().documents().deletePurchaseDemandDocument(purchaseDemandNumber, documentId);
            dispatch.purchaseDemands.removePurchaseDemandDocument(purchaseDemandNumber, documentId);
        }
    })
};
