import React from 'react';
import { Page, Text, View, Document, StyleSheet, Image, Font } from '@react-pdf/renderer';
import { settings as shipmentSettingsType, shipment } from '../../../models/entities/shipment/shipment';
import PdfEntityDetails from '../../shared/PDF/PdfEntityDetails';
import PdfTable from '../../shared/PDF/PdfTable';
import SvgComponent from '../../shared/PDF/SvgPdf';
import { getDetailsGridData } from './sections/overviewUtils';
import { PoItemsGridColDef } from '../singlePurchaseOrder/PurchaseOrderForm/sections/Items/PoItemsGridColDef';
import { settings } from '../../../models/entities/businessPartner/businessPartner';
import { IconByShipmentType } from '../../shared/Grid/specialColumns/ShipmentType';
import { mapCodeIcon } from '../../../utils/countries-flags';
import moment from 'moment';
import { currencyFormatter } from '../../shared/inputs/form/CurrencyInput/CurrencyInput';
import { featureFlagsModel } from '../../../models/entities/businessPartner/settings';
import arrowRight from '../../../static/icons/arrow-right.svg';
import { ShipmentItemsGridColDef } from './sections/ShipmentItems/ShipmentItemsGridColDef';
import { pdf } from '@react-pdf/renderer';
import { shipmentPdfSvgIconsType } from '../../../state/ducks/singleShipment/singleShipment';

const fetchSvgString = async (src: string) => {
    if (!src) return '';

    const response = await fetch(src);
    const svgString = await response.text();
    return svgString;
};

export const loadShipmentSvgIcons = async (shipment: shipment): Promise<shipmentPdfSvgIconsType> => {
    const typeCodeSvg = await fetchSvgString(IconByShipmentType(shipment.typeCode) || '');
    const originCountrySvg = await fetchSvgString(mapCodeIcon(shipment.originCountry.toUpperCase()) || '');
    const arrowIconSvg = await fetchSvgString(arrowRight);
    const destinationCountrySvg = await fetchSvgString(mapCodeIcon(shipment.destinationCountry.toUpperCase()) || '');

    return { typeCodeSvg, originCountrySvg, arrowIconSvg, destinationCountrySvg };
};

export const getShipmentPdfFile = async ({
    shipment,
    localization,
    featureFlags,
    settings,
    shipmentSettings
}: Omit<ShipmentPdfDocumentProps, 'shipmentIcons'>): Promise<File> => {
    const shipmentIcons = await loadShipmentSvgIcons(shipment);
    const pdfDoc = (
        <ShipmentPdfDocument
            shipment={shipment}
            localization={localization}
            shipmentSettings={shipmentSettings}
            settings={settings}
            featureFlags={featureFlags}
            shipmentIcons={shipmentIcons}
        />
    );
    const myPdf = pdf();
    myPdf.updateContainer(pdfDoc);
    const blob = await myPdf.toBlob();
    const file = new File([blob], `#${shipment.CargoZoneNumber}.pdf`, { lastModified: new Date().getTime() });

    return file;
};

type ShipmentPdfDocumentProps = {
    shipment: shipment;
    localization: any;
    shipmentSettings: shipmentSettingsType;
    settings: settings;
    shipmentIcons: shipmentPdfSvgIconsType;
    featureFlags?: featureFlagsModel;
};

// Register Font
Font.register({ family: 'Rubik', src: 'https://fonts.gstatic.com/s/rubik/v3/4sMyW_teKWHB3K8Hm-Il6A.ttf' });

Font.register({
    family: 'Roboto',
    fonts: [
        {
            src: `https://fonts.gstatic.com/s/heebo/v7/NGS6v5_NC0k9P-HxR7BDsbMB.ttf`
        },
        {
            src: `https://fonts.gstatic.com/s/heebo/v7/NGS3v5_NC0k9P9kFbpRLmq8I0LVF.ttf`,
            fontWeight: 'bold'
        }
    ]
});

const ShipmentPdfDocument = ({ shipment, localization, featureFlags, shipmentSettings, settings, shipmentIcons }: ShipmentPdfDocumentProps) => (
    <Document title={`#${shipment.CargoZoneNumber}`}>
        <Page style={styles.body}>
            <View style={styles.headerWrapper}>
                <View style={styles.headerSection}>
                    <View style={styles.shipmentNumberWrapper}>
                        <View style={styles.shipmentTypeIcon}>
                            <SvgComponent svg={shipmentIcons.typeCodeSvg} width={24} />
                        </View>
                        <View style={styles.shipmentNumber}>
                            <Text>{localization.shipment}</Text>
                            <Text> </Text>
                            <Text>{` #${shipment.CargoZoneNumber}`}</Text>
                        </View>
                    </View>
                    <View style={styles.refsWrapper}>
                        <Text style={{ marginRight: 0, fontWeight: 'bold' }}>{localization.client_ref}</Text>
                        {shipment.references
                            ?.filter((r) => r.companyType === 'CLIENT')
                            ?.map((ref, index) => {
                                return <Text key={`client_ref_${index}`} style={styles.ref}>{`${index !== 0 ? '| ' : ''}${ref.value}`}</Text>;
                            })}
                    </View>
                    <View style={styles.refsWrapper}>
                        <Text style={{ marginRight: 0, fontWeight: 'bold' }}>{localization.forwarder_ref}</Text>
                        {shipment.references
                            ?.filter((r) => r.companyType === 'FORWARDER')
                            ?.map((ref, index) => {
                                return <Text key={`forwarder_ref_${index}`} style={styles.ref}>{`${index !== 0 ? '| ' : ''}${ref.value}`}</Text>;
                            })}
                    </View>
                </View>
                <View style={styles.headerSectionSeperator} />
                <View style={styles.headerSection}>
                    <View style={styles.routeWrapper}>
                        <View style={styles.shipmentStationWrapper}>
                            <SvgComponent svg={shipmentIcons.originCountrySvg} width={15} />
                            <Text style={styles.portName}>{shipment.originStation}</Text>
                        </View>
                        <View style={styles.arrowWrapper}>
                            <SvgComponent svg={shipmentIcons.arrowIconSvg} width={14} />
                        </View>
                        <View style={styles.shipmentStationWrapper}>
                            <SvgComponent svg={shipmentIcons.destinationCountrySvg} width={15} />
                            <Text style={styles.portName}>{shipment.destinationStation}</Text>
                        </View>
                    </View>

                    <View style={styles.rowWrapper}>
                        <Text style={{ fontWeight: 'bold' }}>{`${localization.last_event} ${
                            shipmentSettings.eventTypes?.find((type: any) => type.code === shipment.lastEvent)?.name || shipment.lastEvent || '-'
                        }`}</Text>
                    </View>
                    <View style={styles.rowWrapper}>
                        {(() => {
                            const ata = shipment.ata || shipment.events?.find((item) => item.eventCode === 'ARRIVAL')?.eventDate;
                            return ata ? (
                                <View style={{ fontWeight: 'bold', color: '#238823' }}>
                                    <Text>{`${localization.ata}: ${moment(ata).isValid() ? moment(ata).format('DD/MM/YYYY HH:mm') : '-'}`}</Text>
                                </View>
                            ) : (
                                <View style={{ fontWeight: 'bold', color: '#F2A805' }}>
                                    <Text>{`${shipment.typeCode === 'EXPRESS' ? localization.estimate_delivery : localization.eta}: ${
                                        moment(shipment.eta).isValid() ? moment(shipment.eta).format('DD/MM/YYYY HH:mm') : '-'
                                    }`}</Text>
                                </View>
                            );
                        })()}
                    </View>
                </View>
                <View style={styles.headerSectionSeperator} />
                {shipment.forwarderLogoUrl || shipment.companyLogoUrl ? (
                    <>
                        <View style={styles.headerSection}>
                            <View style={styles.wrapper}>
                                <Text>{`${shipment.typeCode === 'CUSTOMS' ? localization.broker : localization.forwarder}:`}</Text>
                                <Text style={{ fontWeight: 'bold' }}>{`${shipment.forwarderName || localization.not_available}`}</Text>
                            </View>
                            {shipment.forwarderLogoUrl && <Image src={shipment.forwarderLogoUrl} style={{ width: 80 }} />}
                        </View>
                        <View style={styles.headerSectionSeperator} />

                        <View style={styles.headerSection}>
                            <View style={styles.wrapper}>
                                <Text>{localization.company}:</Text>
                                <Text style={{ fontWeight: 'bold' }}>{shipment.companyName}</Text>
                            </View>
                            {shipment.companyLogoUrl && <Image src={shipment.companyLogoUrl} style={{ width: 80 }} />}
                        </View>
                    </>
                ) : (
                    <View style={styles.headerSection}>
                        <View>
                            <Text>{shipment.typeCode === 'CUSTOMS' ? localization.broker : localization.forwarder}:</Text>
                            <Text style={{ fontWeight: 'bold' }}>{shipment.forwarderName || localization.not_available}</Text>
                        </View>

                        <View>
                            <Text>{localization.company}:</Text>
                            <Text style={{ fontWeight: 'bold' }}>{shipment.companyName}</Text>
                        </View>
                    </View>
                )}
                <View style={styles.headerSectionSeperator} />
                <View style={styles.headerSection}>
                    <Text style={styles.userName}>{shipment.userName}</Text>
                </View>
            </View>
            <View style={styles.seperator} />

            <PdfEntityDetails
                detailsData={getDetailsGridData(
                    shipment,
                    localization,
                    false,
                    undefined,
                    undefined,
                    undefined,
                    undefined,
                    featureFlags,
                    undefined,
                    true
                )}
                detailsEdit={false}
            />
            {!!shipment.parties?.length && (
                <>
                    <View style={styles.seperator} />
                    <View style={styles.partiesTitleWrapper}>
                        <Text style={styles.partiesTitle}>Parties</Text>
                    </View>

                    <View style={styles.partiesContainer}>
                        {shipment.parties?.map((party, index) => {
                            const partyType = settings?.partyTypes?.find((item) => item.code === party.type);
                            let type = partyType;
                            if (party.subType) type = partyType?.subTypes?.find((item: any) => item.code === party.subType);
                            return (
                                <View key={`party_${index}`} style={styles.partyContainer}>
                                    <View style={styles.partyHeader}>
                                        <Text>{type?.name || party.type}</Text>
                                        <Text style={styles.partyName}>{` : ${party.name.toUpperCase()}`}</Text>
                                    </View>
                                    <Text style={styles.partyDetails}>
                                        {[party.address, party.contactName, party.phoneNumber].filter((p) => p != null && p !== '').join(' | ')}
                                    </Text>
                                </View>
                            );
                        })}
                    </View>
                </>
            )}

            {!!shipment.items?.length && (
                <>
                    <View style={styles.seperator} />
                    <PdfTable
                        columns={ShipmentItemsGridColDef(shipment, shipment.items || [], localization.items, featureFlags)}
                        rows={shipment.items || []}
                        excludeFields={['Img', 'id', 'purchaseDemandId', 'purchaseOrderId', 'pricePerUnit']}
                        valueFormatter={(field: string, value: any, row: any) => {
                            switch (field) {
                                case 'price':
                                    return value && row['currency'] ? currencyFormatter(row['currency'])(value) : '-';
                                case 'currency':
                                    return value && row['price'] && row['quantity']
                                        ? currencyFormatter(value)((Number(row['price']) || 0) * (Number(row['quantity']) || 1) || 0)
                                        : '-';
                                default:
                                    return value;
                            }
                        }}
                        title="Items"
                    />
                </>
            )}
        </Page>
    </Document>
);

const styles = StyleSheet.create({
    body: {
        padding: 20,
        fontFamily: 'Roboto'
    },
    shipmentTypeIcon: {},
    headerWrapper: {
        display: 'flex',
        flexDirection: 'row',
        fontSize: '8px',
        marginTop: 10
    },
    headerSectionSeperator: {
        width: '1px',
        backgroundColor: '#bfbfbf',
        margin: '0 10px'
    },
    headerSection: {
        display: 'flex',
        flexDirection: 'column',
        color: '#394372',
        maxWidth: '150px',
        overflow: 'hidden'
    },
    shipmentNumberWrapper: {
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        fontWeight: 'bold',
        marginBottom: '10px'
    },
    shipmentNumber: {
        fontSize: '10px',
        fontWeight: 'bold',
        height: '24px',
        marginLeft: '10px',
        display: 'flex',
        flexDirection: 'row',
        flexWrap: 'wrap'
    },
    refsWrapper: {
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        flexWrap: 'wrap',
        padding: '3px 0'
    },
    ref: {
        marginLeft: '3px'
    },
    routeWrapper: {
        display: 'flex',
        flexDirection: 'row'
    },
    arrowWrapper: {
        padding: '0 5px'
    },
    rowWrapper: {
        display: 'flex',
        whiteSpace: 'nowrap',
        flexDirection: 'row',
        padding: '6px 0',
        color: '#394372'
    },
    userName: {
        color: '#00b5b9',
        fontSize: '11px'
    },
    shipmentStationWrapper: {
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center'
    },
    portName: {
        color: '#394372',
        textTransform: 'uppercase',
        marginLeft: '5px',
        fontWeight: 'bold'
    },
    wrapper: {
        flexDirection: 'column',
        flexWrap: 'wrap',
        color: '#394372'
    },
    partiesContainer: {
        fontSize: '12px',
        color: '#394372'
    },
    partyContainer: {
        padding: '10px 0'
    },
    partiesTitleWrapper: {
        marginBottom: 10
    },
    partiesTitle: {
        fontSize: 20,
        color: '#252b4a'
    },
    partyHeader: {
        display: 'flex',
        flexDirection: 'row'
    },
    partyName: {
        color: '#757575'
    },
    partyDetails: {
        fontSize: '10px'
    },
    seperator: {
        margin: '20px 0',
        width: 'auto',
        backgroundColor: '#bfbfbf',
        height: '.5px'
    }
});

export default ShipmentPdfDocument;
