import { useState, useEffect, MutableRefObject } from 'react';
import { useResizeDetector } from 'react-resize-detector';
import { calcGroupedHeadersWidths, groupHeadersType } from '../util';
import { ColumnType } from '../types/Column';

type groupedHeadersWidthType = {
    name?: string | undefined;
    width: number;
};

export default function useGroupedHeaders<T>(
    columns: ColumnType<T>[],
    groupHeaders?: groupHeadersType<T>
): [groupedHeadersWidthType[], MutableRefObject<HTMLDivElement | null>, MutableRefObject<HTMLDivElement | null>] {
    const [groupedHeadersWidths, setGroupedHeadersWidths] = useState<groupedHeadersWidthType[]>([]);
    const { ref: firstColumnContainerRef } = useResizeDetector<HTMLDivElement>();
    const { ref: innerColumnsContainerRef } = useResizeDetector<HTMLDivElement>();

    const firstColumnContainerScrollWidth = firstColumnContainerRef?.current?.scrollWidth || 0;
    const innerColumnsContainerScrollWidth = innerColumnsContainerRef?.current?.scrollWidth || 0;
    const totalContentWidth = firstColumnContainerScrollWidth + innerColumnsContainerScrollWidth;

    useEffect(() => {
        const visibleColumns = columns.filter((col) => col.isDisplayed !== false);
        setGroupedHeadersWidths(calcGroupedHeadersWidths<T>(visibleColumns, totalContentWidth, groupHeaders));
    }, [totalContentWidth, columns, firstColumnContainerRef, groupHeaders, innerColumnsContainerRef]);

    return [groupedHeadersWidths, firstColumnContainerRef, innerColumnsContainerRef];
}
