import React, { useState } from 'react';
import Menu, { TriggerProps } from '../../Menu/Menu';
import MenuItem from '../../Menu/MenuItem';
import shareIcon from '../../../../static/icons/share.svg';
import followIcon from '../../../../static/icons/follow.svg';
import trashIcon from '../../../../static/icons/trash.svg';
import exportIcon from '../../../../static/icons/download.svg';
import pdfIcon from '../../../../static/icons/documentsType/pdf.svg';
import excelIcon from '../../../../static/icons/documentsType/excel.svg';
import CellTrigger from '../../Menu/MenuTriggers/CellTrigger';
import { connect } from 'react-redux';
import { RootState } from '../../../../state/store/store';
import { exportTypes } from '../../../../state/ducks/allShipmentsPage/allShipmentsPage';
import { localizationSelectors } from '../../../../state/ducks/localization/selectors';
import { userSelectors } from '../../../../state/ducks/user/selectors';
import CustomModal, { ModalTriggerProps } from '../../Modal/Modal';
import ShareShipmentForm from '../../ShareShipmentForm/ShareShipmentFormConnected';
import InviteUserFollowShipmentForm from '../../InviteUserFollowShipmentForm/InviteUserFollowShipmentForm';
import { shipmentsSelectors } from '../../../../state/ducks/shipments/selectors';
import { allowedCompany } from '../../../../models/entities/user';
import { PagesRoutes } from '../../../../routing/PagesRoutes';
import ShipmentPdfDocument from '../../../pages/singleShipment/ShipmentPdfDocument';
import { pdf, PDFDownloadLink, PDFViewer } from '@react-pdf/renderer';
import { settings as shipmentSettingType, shipment } from '../../../../models/entities/shipment/shipment';
import { featureFlagsModel } from '../../../../models/entities/businessPartner/settings';
import { saveAs } from 'file-saver';
import { businessPartnersSelectors } from '../../../../state/ducks/businessPartner/selectors';
import { settings } from '../../../../models/entities/businessPartner/businessPartner';
import { singleShipmentSelectors } from '../../../../state/ducks/singleShipment/selectors';
import { shipmentPdfSvgIconsType } from '../../../../state/ducks/singleShipment/singleShipment';

type exportDataRowProps = (shipmentId: string, type: exportTypes, fileName: string) => Promise<boolean>;
type Props = {
    shipment: shipment;
    entityId: string;
    entityData: { CargoZoneNumber: string; companyId: string };
    localization: any;
    singleShipmentLocalization: any;
    settings: settings;
    shipmentSettings: shipmentSettingType;
    exportDataRow: exportDataRowProps;
    trigger?: (props: TriggerProps) => JSX.Element;
    deleteShipment: (shipmentId: string) => Promise<any>;
    onDelete?: () => void;
    followShipment: (shipmentId: string) => void;
    unFollowShipment: (shipmentId: string) => void;
    getShipmentIsActiveById: (shipmentId: string) => boolean;
    updateShipmentIsActive: (shipmentId: string, isActive: boolean) => void;
    followingShipments?: Array<string>;
    allowedCompanies: allowedCompany[];
    featureFlags?: featureFlagsModel;
    shipmentPdfSvgIcons: shipmentPdfSvgIconsType;
};
type MenuItemsProps = Props & {
    isActiveShipment: boolean;
    isUserFollowing?: boolean;
    setIsModalOpened?: (isOpened: boolean) => void;
};

const menuItems = ({
    shipment,
    localization,
    entityId,
    entityData,
    exportDataRow,
    singleShipmentLocalization,
    deleteShipment,
    onDelete,
    followShipment,
    unFollowShipment,
    isUserFollowing,
    setIsModalOpened,
    updateShipmentIsActive,
    isActiveShipment,
    allowedCompanies,
    featureFlags,
    settings,
    shipmentPdfSvgIcons,
    shipmentSettings
}: MenuItemsProps) => {
    const menuItemsArr = [
        <MenuItem
            onClick={() => {
                isUserFollowing ? unFollowShipment(entityId) : followShipment(entityId);
            }}
            icon={followIcon}
            text={isUserFollowing ? localization.unfollow : localization.follow}
        />,
        <CustomModal
            title={{ text: localization.share_shipment, icon: shareIcon }}
            Trigger={(props: ModalTriggerProps) => (
                <MenuItem
                    onClick={(e) => {
                        e.stopPropagation();
                        if (setIsModalOpened) setIsModalOpened(true);
                        props.onClick();
                    }}
                    icon={shareIcon}
                    text={localization.share}
                />
            )}
            onCancelCallback={() => {
                if (setIsModalOpened) setIsModalOpened(false);
            }}
            Content={(setVisible?: React.Dispatch<React.SetStateAction<boolean>>, setConfirm?: React.Dispatch<React.SetStateAction<boolean>>) => {
                return (
                    <ShareShipmentForm
                        shipmentId={entityId}
                        setModalVisible={(visible: boolean) => {
                            setVisible && setVisible(visible);
                            setIsModalOpened && setIsModalOpened(visible);
                        }}
                        featureFlags={featureFlags}
                        settings={settings}
                        confirmation={setConfirm ? { setConfirm } : undefined}
                    ></ShareShipmentForm>
                );
            }}
            headerPadding="0 32px"
            contentPadding="0 32px"
            containerPadding="32px 0"
            headerHeight={24}
            footerHeight={0}
            headerFontSize="21px"
            width="393px"
            maxWidth="393px"
        />,
        <CustomModal
            title={{ text: localization.invite_to_follow, icon: followIcon }}
            Trigger={(props: ModalTriggerProps) => (
                <MenuItem
                    onClick={(e) => {
                        e.stopPropagation();
                        if (setIsModalOpened) setIsModalOpened(true);
                        props.onClick();
                    }}
                    icon={followIcon}
                    text={localization.invite_to_follow}
                />
            )}
            onCancelCallback={() => {
                if (setIsModalOpened) setIsModalOpened(false);
            }}
            Content={(setVisible?: React.Dispatch<React.SetStateAction<boolean>>, setConfirm?: React.Dispatch<React.SetStateAction<boolean>>) => {
                return (
                    <InviteUserFollowShipmentForm
                        shipmentId={entityId}
                        setModalVisible={(visible: boolean) => {
                            setVisible && setVisible(visible);
                            setIsModalOpened && setIsModalOpened(visible);
                        }}
                        confirmation={setConfirm ? { setConfirm } : undefined}
                    ></InviteUserFollowShipmentForm>
                );
            }}
            width="393px"
            maxWidth="393px"
        />,
        // <MenuItem
        //     onClick={async () => {
        //         // const result = await exportDataRow(entityId, 'PDF', `${entityData.CargoZoneNumber}.pdf`);
        //         // if (!result) {
        //         //     window.open(`${PagesRoutes.Shipments}/${entityId}?printMode=true&windowPrint=true`, '_blank');
        //         // }
        //     }}
        //     icon={exportIcon}
        //     text={localization.export + ' ' + localization.pdf}
        // />,
        <CustomModal
            title={{ text: localization.export + ' ' + localization.pdf }}
            Trigger={(props: ModalTriggerProps) => (
                <MenuItem
                    onClick={(e) => {
                        e.stopPropagation();
                        if (setIsModalOpened) setIsModalOpened(true);
                        props.onClick();
                    }}
                    icon={exportIcon}
                    text={localization.export + ' ' + localization.pdf}
                />
            )}
            onCancelCallback={() => {
                if (setIsModalOpened) setIsModalOpened(false);
            }}
            Content={() => {
                return (
                    <PDFViewer width="100%" height="100%">
                        <ShipmentPdfDocument
                            shipment={shipment}
                            localization={singleShipmentLocalization}
                            featureFlags={featureFlags}
                            shipmentSettings={shipmentSettings}
                            settings={settings}
                            shipmentIcons={shipmentPdfSvgIcons}
                        />
                    </PDFViewer>
                );
            }}
            width="90%"
            maxWidth="90%"
            fullScreen
        />
    ];
    if (!featureFlags?.UNDER_FORWARDER && allowedCompanies.find((item) => item.companyId === entityData.companyId))
        menuItemsArr.push(
            ...[
                <MenuItem
                    onClick={() => {
                        updateShipmentIsActive(entityId, !isActiveShipment);
                    }}
                    icon={trashIcon}
                    text={`${localization.make_shipment} ${isActiveShipment ? 'inactive' : 'active'}`}
                    requiredConfirm
                />,
                <MenuItem
                    onClick={() => {
                        deleteShipment(entityId).then(() => {
                            if (onDelete) onDelete();
                        });
                    }}
                    icon={trashIcon}
                    text={localization.delete}
                    requiredConfirm
                />
            ]
        );
    return menuItemsArr;
};

const ShipmentMenu = ({
    shipment,
    entityId,
    entityData,
    localization,
    singleShipmentLocalization,
    exportDataRow,
    deleteShipment,
    trigger,
    onDelete,
    followShipment,
    unFollowShipment,
    updateShipmentIsActive,
    getShipmentIsActiveById,
    followingShipments,
    allowedCompanies,
    shipmentSettings,
    settings,
    shipmentPdfSvgIcons
}: Props) => {
    const [isModalOpened, setIsModalOpened] = useState(false);
    const isUserFollowing = followingShipments?.includes(entityId);
    const isActiveShipment = getShipmentIsActiveById(entityId);
    return (
        <Menu
            menuWidth={165}
            options={menuItems({
                shipment,
                localization: localization.grid.menu,
                singleShipmentLocalization,
                entityId,
                entityData,
                exportDataRow,
                deleteShipment,
                onDelete,
                followShipment,
                unFollowShipment,
                updateShipmentIsActive,
                getShipmentIsActiveById,
                isUserFollowing,
                isActiveShipment,
                setIsModalOpened,
                allowedCompanies,
                shipmentSettings,
                settings,
                shipmentPdfSvgIcons
            })}
            isModalOpened={isModalOpened}
            MenuTrigger={trigger || CellTrigger}
            strStyle="display: flex;align-items: center;width: 100%;height: 100%;"
        />
    );
};

const mapStateToProps = (state: RootState) => ({
    localization: localizationSelectors.allShipmentsPage(state),
    singleShipmentLocalization: localizationSelectors.singleShipmentPage(state),
    followingShipments: userSelectors.followingShipments(state),
    getShipmentIsActiveById: (shipmentId: string) => shipmentsSelectors.getShipmentIsActiveById(state)(shipmentId),
    allowedCompanies: userSelectors.allowedCompanies(state),
    featureFlags: userSelectors.featureFlags(state),
    settings: businessPartnersSelectors.settingsByLanguage(state),
    shipmentPdfSvgIcons: singleShipmentSelectors.shipmentPdfSvgIcons(state),
    shipmentSettings: shipmentsSelectors.settingsByLanguage(state)()
});

const mapDispatchToProps = (dispatch: any) => ({
    exportDataRow: (shipmentId: string, type: exportTypes, fileName: string) =>
        dispatch.allShipmentsPage.exportDataRow({ shipmentId, type, fileName }),
    deleteShipment: async (shipmentId: string) => dispatch.allShipmentsPage.deleteShipment(shipmentId),
    followShipment: async (shipmentId: string) => dispatch.allShipmentsPage.followShipment(shipmentId),
    unFollowShipment: async (shipmentId: string) => dispatch.allShipmentsPage.unFollowShipment(shipmentId),
    updateShipmentIsActive: async (shipmentId: string, isActive: boolean) => dispatch.shipments.updateShipmentIsActive({ shipmentId, isActive })
});

export default connect(mapStateToProps, mapDispatchToProps)(ShipmentMenu);
