import React from 'react';
import styled from 'styled-components';
import { order } from '../../../../../models/entities/order';
import { orderParty } from '../../../../../models/entities/order/orderParty';
import PartySection from './PartySection';

type Props = {
    order: order;
    localization: any;
};
const OverviewParties = ({ order, localization }: Props) => {
    return (
        <Container>
            {order.parties?.map((party: orderParty) => (
                <PartyContainer key={party.id}>
                    <PartySection party={party} localization={localization} />
                </PartyContainer>
            ))}
        </Container>
    );
};

const Container = styled.div`
    display: flex;
`;
const PartyContainer = styled.div``;
export default OverviewParties;
