import React, { useState } from 'react';
import { Formik, Form, FormikConfig, FormikErrors, FormikProps } from 'formik';
import { FormSectionType } from './models/formSectionType';
import FormSection from './components/FormSection';
import styled from 'styled-components';
import Button from '../SmallComponents/Button';
import { useHistory } from 'react-router-dom';
import BackButton from '../SmallComponents/BackButton';
import Loader from '../SmallComponents/Loader';
import theme from '../Theme/theme';

type formHeaderProps = {
    title: string;
    goBackBtnTitle: string;
    entityNumber?: string;
};
export type SectionsFormProps<T> = {
    formikProps: FormikConfig<T>;
    sections: FormSectionType<T>[];
    previousPage: string;
    formHeader?: formHeaderProps;
};

let sectionErrorIndexes: number[] = [];

// T is the form values type
function SectionsForm<T>({ formikProps, sections, previousPage, formHeader }: SectionsFormProps<T>) {
    const history = useHistory();

    const [activeFormSection, setActiveFormSection] = useState<number>(0);

    const onNextClick = (event: any) => {
        event.preventDefault();
        setActiveFormSection(activeFormSection + 1);
    };
    const onPreviousClick = (event: any) => {
        event.preventDefault();
        setActiveFormSection(activeFormSection - 1);
    };
    const onSectionNumberClick = (sectionNumber: number) => {
        setActiveFormSection(sectionNumber);
    };

    const onCancelClick = () => {
        history.push(previousPage);
    };
    return (
        <Formik<T> {...formikProps}>
            {(formikProps: FormikProps<T>) => {
                const { errors, isSubmitting, isValidating, isValid, dirty } = formikProps;
                if (isSubmitting && !isValidating) sectionErrorIndexes = sectionErrors(errors, sections);

                return (
                    <Form style={{ display: 'flex', flexDirection: 'column', height: '100%', position: 'relative' }}>
                        {isSubmitting && !isValidating && (
                            <LoaderOverlay>
                                <Loader />
                            </LoaderOverlay>
                        )}
                        <FormContainer>
                            {formHeader && (
                                <FormHeader>
                                    <BackButton toPath={previousPage} text={formHeader.goBackBtnTitle} />
                                    <NewEntityTitle>
                                        <IdLabel>{formHeader.title}</IdLabel>
                                        <EntityId>{`#${formHeader.entityNumber || '00000000'}`}</EntityId>
                                        <MandaotoryFields>* Mandatory Fields</MandaotoryFields>
                                    </NewEntityTitle>
                                </FormHeader>
                            )}
                            {sections.map(({ name, component }, index) => (
                                <FormSection
                                    onSectionNumberClick={onSectionNumberClick}
                                    onNextClick={onNextClick}
                                    onPreviousClick={onPreviousClick}
                                    isLast={index === sections.length - 1}
                                    isActive={sectionErrorIndexes.includes(index) || activeFormSection === index}
                                    name={name}
                                    number={index}
                                >
                                    {component(formikProps)}
                                </FormSection>
                            ))}
                        </FormContainer>

                        <Buttons>
                            <Button buttonType="filled" width="100px" height="30px" type="submit" disabled={!dirty || !isValid || isSubmitting}>
                                {'Save'}
                            </Button>
                            <Button width="100px" height="30px" type="button" onClick={onCancelClick}>
                                {'Cancel'}
                            </Button>
                        </Buttons>
                    </Form>
                );
            }}
        </Formik>
    );
}

function sectionErrors<T>(errors: FormikErrors<T>, sections: FormSectionType<T>[]) {
    const sectionsErrors: {
        [key: number]: Array<keyof T>;
    } = sections.reduce(
        (errorsObj: { [key: number]: Array<keyof T> }, section: FormSectionType<T>, index: number) => ({
            ...errorsObj,
            [index]: section.errors || []
        }),
        {}
    );

    const indexes: number[] = [];
    for (const key of Object.keys(sectionsErrors)) {
        const index = Number(key);
        for (const item of sectionsErrors[index]) {
            if (Object.keys(errors).includes(item as string)) indexes.push(index);
        }
    }
    return indexes;
}

const FormContainer = styled.div`
    position: relative;
    width: 100%;
    height: 100%;
    padding: 25px;
    padding-left: 40px;
    padding-right: 40px;
    overflow-y: auto;
    flex: 1;
`;

const Buttons = styled.div`
    width: 100%;
    border-top: 1px solid #e0e0e0;
    padding-top: 25px;
    padding-bottom: 25px;
    padding-left: 70px;
    display: flex;

    button {
        margin-right: 20px;
    }
`;

const FormHeader = styled.div`
    flex-direction: row;
    justify-content: space-between;

    & a {
        position: relative;
        left: -30px;
    }
`;

const NewEntityTitle = styled.div`
    display: flex;
    align-content: flex-start;
    flex-direction: row;
    align-items: flex-start;
    position: relative;
    left: 0;
    margin-top: 20px;
`;

const IdLabel = styled.div`
    color: ${(props) => props.theme.colors.pageTitle};
    font-size: 18px;
    font-weight: bold;
`;

const MandaotoryFields = styled.div`
    font-style: italic;
    line-height: 30px;
    font-size: 11px;
    color: red;
`;

const EntityId = styled.div`
    color: ${(props) => props.theme.colors.grey};
    margin-left: 5px;
    margin-right: 30px;
    line-height: 30px;
`;

const LoaderOverlay = styled.div`
    position: absolute;
    width: inherit;
    z-index: 1001;
    height: 100%;
    width: 100%;
    background: ${theme.colors.overlayBG};
`;

export default SectionsForm;
