import React, { useState } from 'react';
import { styled } from '../../../../shared/Theme/theme';
import { Form, Formik } from 'formik';
import { localizationSelectors } from '../../../../../state/ducks/localization/selectors';
import { RootState } from '../../../../../state/store/store';
import { connect } from 'react-redux';
import Button from '../../../../shared/SmallComponents/Button';
import { ModalFooter, ModalFormContainer } from '../../../../shared/Modal/Modal';
import Loader from '../../../../shared/SmallComponents/Loader';
import { category, createCategoryPayload, updateCategoryPayload } from '../../../../../models/entities/category/category';
import { FormDropDown, FormTextInput } from '../../../../shared/inputs/form';
import FieldLabel from '../../../../shared/SmallComponents/FieldLabel';
import { allowedCompany } from '../../../../../models/entities/user';
import { userSelectors } from '../../../../../state/ducks/user/selectors';
import { Option } from '../../../../shared/inputs/base/DropDown';

export enum formMode {
    NEW,
    EDIT
}

interface formFields {
    companyId?: string;
    name?: string;
}
type Props = {
    companyId?: string;
    category?: category;
    allowedCompaniesDropDownOptions: Option[];
    mode: formMode;
    localizations?: any;
    openedFromModal?: {
        setVisible: React.Dispatch<React.SetStateAction<boolean>>;
    };
    createCategory: (payload: createCategoryPayload) => Promise<category>;
    updateCategory: (payload: updateCategoryPayload) => Promise<category>;
    submitCallback?: (category: category) => void;
};

const AttributeFormConnected = ({
    companyId,
    category,
    allowedCompaniesDropDownOptions,
    localizations,
    mode,
    openedFromModal,
    createCategory,
    updateCategory,
    submitCallback
}: Props) => {
    const [submitLoading, setLoading] = useState(false);
    let initFormValues: formFields = {
        companyId: companyId || '',
        name: ''
    };
    const setInitialValues = () => {
        if (!category || mode === formMode.NEW) {
            return initFormValues;
        } else {
            initFormValues.companyId = category.companyId;
            initFormValues.name = category.name;
            return initFormValues;
        }
    };
    const validate = (values: any) => {
        const errors: any = {};
        if (!values.companyId) errors.name = localizations.form.errors.required;
        if (!values.name) errors.name = localizations.form.errors.required;

        const maxLengthValidation = (field: string, maxLength: number, message: string) => {
            if (values[field] && values[field].length > maxLength) {
                errors[field] = message;
            }
        };

        maxLengthValidation('name', 50, localizations.form.errors.max_length?.(50));
        return errors;
    };
    const submit = async (values: any) => {
        const payload = {
            companyId: values.companyId,
            name: values.name
        };
        setLoading(true);
        if (mode === formMode.NEW) {
            const createdCategory = await createCategory(payload);
            if (submitCallback) submitCallback(createdCategory);
        } else if (category) {
            const updatedCategory = await updateCategory({
                id: category.id,
                ...payload
            });
            if (submitCallback) submitCallback(updatedCategory);
        }
        setLoading(false);
        if (openedFromModal) openedFromModal.setVisible(false);
    };

    return (
        <Formik initialValues={setInitialValues()} validate={validate} onSubmit={submit}>
            {({ values, errors }) => {
                return (
                    <Form>
                        <Container>
                            <FieldLabel>{localizations.form.company}</FieldLabel>
                            <FieldContainer>
                                <FormDropDown
                                    name={'companyId'}
                                    style={{ width: '100%' }}
                                    placeHolder={localizations.form.company}
                                    options={allowedCompaniesDropDownOptions}
                                    showSearch={true}
                                />
                            </FieldContainer>
                            <FieldLabel>{localizations.form.name}</FieldLabel>
                            <FieldContainer>
                                <FormTextInput name="name" placeHolder={localizations.form.name} />
                            </FieldContainer>
                        </Container>
                        {openedFromModal && (
                            <FormFooter>
                                <Button minWidth="55px" buttonType="filled" type="submit">
                                    {submitLoading ? (
                                        <Loader width="20px" marginTop="0px" showText={false} />
                                    ) : (
                                        <span>{mode === formMode.NEW ? localizations.modal.add_btn : localizations.modal.edit_btn}</span>
                                    )}
                                </Button>
                            </FormFooter>
                        )}
                    </Form>
                );
            }}
        </Formik>
    );
};
const FieldContainer = styled.div`
    margin-bottom: 12px;
`;

const Container = styled(ModalFormContainer)``;

const FormFooter = styled(ModalFooter)`
    position: absolute;
    bottom: 0;
    width: 100%;
    left: 0;
    right: 0;
    background: white;
    justify-content: flex-end;
`;
const mapStateToProps = (state: RootState) => ({
    localizations: localizationSelectors.categories(state),
    allowedCompaniesDropDownOptions: userSelectors.allowedCompaniesDropDownOptions(state)
});

const mapDispatchToProps = (dispatch: any) => ({
    createCategory: (payload: createCategoryPayload) => dispatch.categories.createCategory(payload),
    updateCategory: (payload: updateCategoryPayload) => dispatch.categories.updateCategory(payload)
});

export default connect(mapStateToProps, mapDispatchToProps)(AttributeFormConnected);
