import BaseController from '../..';
import { chatNetwork } from '../../../../models/entities/chat/chatNetwork';
import { chatRequest } from '../../../../models/entities/chat/chatRequest';
import { usersObject } from '../../../../state/ducks/users/users';
import IChatNetwork from './IChatNetwork';

export default class ChatNetwork extends BaseController implements IChatNetwork {
    constructor(baseUrl: string) {
        super(baseUrl);
    }
    async getMyNetwork(): Promise<usersObject> {
        const response = await this.client.get('/chat/network/myNetwork');
        return response;
    }
    async getChatRequests(): Promise<chatRequest[]> {
        const response = await this.client.get('/chat/network/chatRequests');
        return response;
    }
    async inviteUser(email: string | string[]): Promise<void> {
        const data = typeof email === 'string' ? [email] : email;
        const response = await this.client.post(
            '/chat/network/inviteUser',
            JSON.stringify({
                emails: data
            })
        );
        return response;
    }
    async stopNetwork(networkId?: string): Promise<void> {
        const response = await this.client.post(
            '/chat/network/stopNetwork',
            JSON.stringify({
                networkId
            })
        );
        return response;
    }
    async approveRequest(requestId: string): Promise<void> {
        const response = await this.client.post(
            '/chat/network/approveRequest',
            JSON.stringify({
                requestId
            })
        );
        return response;
    }
    async denyRequest(requestId: string): Promise<void> {
        const response = await this.client.post(
            '/chat/network/denyRequest',
            JSON.stringify({
                requestId
            })
        );
        return response;
    }
}
