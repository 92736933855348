import React from 'react';
import { currencyFormatter } from '../../../shared/inputs/form/CurrencyInput/CurrencyInput';
import { FieldArray } from 'formik';
import { CurrencyInput, FormCheckbox, FormDatePicker, FormDropDown } from '../../../shared/inputs/form';
import { paymentDate, poOverview, purchaseOrder } from '../../../../models/entities/purchaseOrder/purchaseOrder';
import { detail } from '../../../shared/EntityDetails/EntityDetails';
import { purchaseOrderItem } from '../../../../models/entities/purchaseOrder/purchaseOrderItem';
import moment from 'moment';
import { chain, isEmpty } from 'lodash';
import { enumToDropdownOptions } from '../../../shared/inputs/base/DropDown';
import { ePaymentTerms } from '../../../../models/entities/businessPartner/paymentTerms';
import WeightIcon from '../../../../static/icons/weight.svg';
import WandIcon from '../../../../static/icons/magic_wand.svg';
import { Checkbox } from 'antd';
import { calcTotalVolume, calcTotalWeight } from '../../../../utils/measurementUtils';
import styled from 'styled-components';
import { Modal } from 'antd';
import CZTooltip from '../../../shared/CZTooltip/CZTooltip';
import { Text, View } from '@react-pdf/renderer';

export const detailsData = (
    purchaseOrder: purchaseOrder,
    values: any,
    setFieldValue: any,
    localization: any,
    detailsEdit: boolean,
    errors: any,
    updatePurchaseOrderPaymentDates?: (id: string, paymentDates: Array<paymentDate>) => void,
    updatePurchaseOrderOverview?: (id: string, poOverview: poOverview) => void,
    updatePaymentDatesByPaymentTermPrecentage?: (purchaseOrderId: string) => Promise<void>,
    isPdf?: boolean,
    isSupplierUpdates?: boolean
): Array<Array<detail>> => {
    const { volume: totalVolume, unit: totalVolumeUnit } = calcTotalVolume(purchaseOrder.items || []);

    const { weight: totalWeight, unit: totalWeightUnit } = calcTotalWeight(purchaseOrder?.items || []);

    const paymentTermsPrecentRegex = new RegExp(/^([0-9]%\+[0-9]%|[0-9]%\+([1-9][0-9])%|([1-9][0-9])%\+[0-9]%|([1-9][0-9])%\+([1-9][0-9])%)$/g);
    const paymentTermsNoSpaces = purchaseOrder?.paymentTerms?.split(' ')?.join('');
    const paymentTermsMatchRegex = paymentTermsPrecentRegex.test(paymentTermsNoSpaces || '');
    const totalCost = purchaseOrder?.items
        ? chain(purchaseOrder.items)
              .groupBy((item) => item.currency)
              .map((items, index) => {
                  const totalItemsCost = items
                      ?.reduce((total: number, item: purchaseOrderItem) => {
                          const { price, quantity } = item;
                          if (!price) return total;
                          return total + Number(price) * quantity;
                      }, 0)
                      ?.toString();

                  const value = currencyFormatter(items[0].currency)(totalItemsCost);
                  return isPdf ? <Text key={`currency${index}`}>{value}</Text> : <div key={`currency${index}`}>{value}</div>;
              })
              .value()
        : undefined;

    const totalCartons = purchaseOrder.items?.reduce(
        (acc, item) => acc + (item.quantity && item.unitsPerMasterCarton ? Math.ceil(item.quantity / item.unitsPerMasterCarton) : 0),
        0
    );

    const paymentDatesErrors = errors?.paymentDates as any;

    const canAddPaymentDate =
        !paymentDatesErrors?.length ||
        !paymentDatesErrors?.[paymentDatesErrors?.length - 1]! ||
        isEmpty(paymentDatesErrors[paymentDatesErrors.length - 1]);
    return [
        [
            {
                name: 'requestedGoodsReady',
                showCondition: true,
                value: purchaseOrder.isAsap
                    ? localization.asap
                    : purchaseOrder.requestedGoodsReady
                    ? moment(purchaseOrder.requestedGoodsReady).format('DD/MM/YYYY')
                    : undefined,
                editable: isSupplierUpdates ? (
                    false
                ) : (
                    <div>
                        <FormDatePicker
                            name="requestedGoodsReady"
                            style={{ width: '100%' }}
                            placeHolder={localization.expected_ready_date}
                            disabled={values.isAsap}
                        />
                        <FormCheckbox name="isAsap">{localization.asap}</FormCheckbox>
                    </div>
                ),
                title: localization.requested_goods_ready
            },
            {
                name: 'expectedReadyDate',
                showCondition: true,
                value: purchaseOrder.expectedReadyDate ? moment(purchaseOrder.expectedReadyDate).format('DD/MM/YYYY') : undefined,
                editable: {
                    type: 'date',
                    hideTime: true
                },
                title: localization.expected_ready_date
            },
            {
                name: 'actualGoodsReady',
                showCondition: true,
                value: purchaseOrder.actualGoodsReady ? moment(purchaseOrder.actualGoodsReady).format('DD/MM/YYYY') : undefined,
                editable: {
                    type: 'date',
                    hideTime: true
                },
                title: localization.actual_goods_ready
            }
        ],
        [
            {
                name: 'items',
                showCondition: true,
                editable: false,
                value: purchaseOrder?.items?.reduce((total, item) => total + Number(item.quantity) || 0, 0)?.toString(),
                title: 'Items'
            },
            {
                name: 'totalCartons',
                showCondition: !!totalCartons,
                editable: false,
                title: localization.total_cartons,
                value: `${totalCartons}`
            },
            {
                name: 'volume',
                showCondition: true,
                editable: false,
                value: totalVolume ? `${totalVolume} ${totalVolumeUnit}` : undefined,
                title: 'Volume'
            },
            {
                name: 'weight',
                showCondition: true,
                editable: false,
                value: totalWeight ? `${totalWeight.toFixed(2)} ${totalWeightUnit}` : undefined,
                title: (
                    <>
                        {!detailsEdit && <img style={{ verticalAlign: 'sub' }} src={WeightIcon} alt={'Weight'} />}
                        Weight
                    </>
                )
            }
        ],
        [
            {
                name: 'totalCost',
                showCondition: true,
                editable: false,
                content: isPdf ? <View style={{ display: 'flex', flexDirection: 'column' }}>{totalCost}</View> : <div>{totalCost}</div>,
                title: 'Total Cost'
            },
            {
                name: 'taxesRate',
                showCondition: true,
                value: purchaseOrder.taxesRate ? `${purchaseOrder.taxesRate}%` : undefined,
                editable: {
                    type: 'number',
                    step: 0.01
                },
                title: localization.taxes_rate
            },
            {
                name: 'paymentTerms',
                showCondition: !isSupplierUpdates,
                value: purchaseOrder.paymentTerms ? ePaymentTerms[purchaseOrder.paymentTerms] || purchaseOrder.paymentTerms : undefined,
                editable: (
                    <FormDropDown
                        name="paymentTerms"
                        options={enumToDropdownOptions(ePaymentTerms)}
                        placeHolder={localization.payment_terms}
                        style={{ width: '100%' }}
                        showSearch
                        mode="tags"
                        onChange={(arr: Array<string>) => {
                            if (arr.length > 0) setFieldValue?.('paymentTerms', [arr.pop()]);
                        }}
                    />
                ),
                title: localization.payment_terms
            },
            {
                name: 'paymentDates',
                showCondition: !isSupplierUpdates,
                content: purchaseOrder.paymentDates ? (
                    <View>
                        {purchaseOrder.paymentDates
                            .sort((a, b) => moment(a.date).valueOf() - moment(b.date).valueOf())
                            .map((paymentDate, index) => {
                                const { date, currency, amount, payed } = paymentDate;
                                if (isPdf) {
                                    return (
                                        <Text
                                            style={{ color: `${payed ? '#629e31' : moment(date).isBefore(moment()) ? '#c1292e' : '#fda119'}` }}
                                            key={`paymentDate${index}`}
                                        >
                                            {moment(date).format('DD/MM/YYYY')} - {currencyFormatter(currency)(amount)}
                                        </Text>
                                    );
                                }
                                return (
                                    <div
                                        style={{ color: `${payed ? '#629e31' : moment(date).isBefore(moment()) ? '#c1292e' : '#fda119'}` }}
                                        key={`paymentDate${index}`}
                                    >
                                        {moment(date).format('DD/MM/YYYY')} - {currencyFormatter(currency)(amount)}
                                        <Checkbox
                                            style={{ marginLeft: 5 }}
                                            checked={!!payed}
                                            onChange={(e) => {
                                                const { checked } = e.target;
                                                const currenPaymentDates = [...(purchaseOrder?.paymentDates || [])];
                                                if (currenPaymentDates[index]) {
                                                    currenPaymentDates[index].payed = checked;
                                                    updatePurchaseOrderPaymentDates?.(purchaseOrder.id, currenPaymentDates);
                                                }
                                            }}
                                        ></Checkbox>
                                    </div>
                                );
                            })}
                    </View>
                ) : undefined,
                editable: (
                    <FieldArray
                        name="paymentDates"
                        render={(arrayHelpers) => (
                            <div>
                                {values.paymentDates?.length &&
                                    values.paymentDates.map((paymentDate: paymentDate, index: number) => {
                                        return (
                                            <div
                                                style={{
                                                    display: 'flex',
                                                    alignItems: 'center',
                                                    width: '100%',
                                                    marginBottom: 10,
                                                    justifyContent: 'space-between'
                                                }}
                                                key={index}
                                            >
                                                <FormDatePicker name={`paymentDates.${index}.date`} />
                                                <CurrencyInput
                                                    numberInputWidth="70px"
                                                    currencyValue={values.paymentDates?.[index]?.currency ?? 'USD'}
                                                    numberInputName={`paymentDates.${index}.amount`}
                                                    numberPlaceholder={'Amount'}
                                                    unitInputName={`paymentDates.${index}.currency`}
                                                />
                                                <span
                                                    className="material-icons"
                                                    style={{ fontSize: 20, marginLeft: 5, cursor: 'Pointer', color: '#008ac9' }}
                                                    onClick={() => arrayHelpers.remove(index)}
                                                >
                                                    delete
                                                </span>
                                            </div>
                                        );
                                    })}
                            </div>
                        )}
                    />
                ),
                title: isPdf ? (
                    localization.payment_dates
                ) : (
                    <PaymentDatesTitleContainer>
                        {localization.payment_dates}
                        {detailsEdit ? (
                            <span
                                className="material-icons"
                                style={{
                                    fontSize: 15,
                                    marginLeft: 5,
                                    cursor: `${canAddPaymentDate ? 'pointer' : 'default'}`,
                                    color: `${canAddPaymentDate ? 'inherit' : '#cccccc'}`
                                }}
                                onClick={() => {
                                    if (!canAddPaymentDate) return;
                                    const newPaymentDates = [...(values.paymentDates || []), { date: null, amount: 0, currency: 'USD' }];
                                    setFieldValue?.('paymentDates', newPaymentDates);
                                }}
                            >
                                add_circle_outline
                            </span>
                        ) : (
                            paymentTermsMatchRegex && (
                                <CZTooltip text={localization.wand_tooltip}>
                                    <WandBtn
                                        style={{ verticalAlign: 'sub' }}
                                        src={WandIcon}
                                        alt={'Weight'}
                                        width={20}
                                        onClick={async () => {
                                            if (purchaseOrder?.paymentDates?.length) {
                                                Modal.confirm({
                                                    zIndex: 1100,
                                                    content: <div>{localization.are_you_sure}</div>,
                                                    async onOk() {
                                                        await updatePaymentDatesByPaymentTermPrecentage?.(purchaseOrder.id);
                                                    },
                                                    onCancel() {}
                                                });
                                                return;
                                            }
                                            await updatePaymentDatesByPaymentTermPrecentage?.(purchaseOrder.id);
                                        }}
                                    />
                                </CZTooltip>
                            )
                        )}
                    </PaymentDatesTitleContainer>
                )
            }
        ],
        [
            {
                name: 'note',
                showCondition: true,
                value: purchaseOrder.note,
                editable: isSupplierUpdates
                    ? false
                    : {
                          type: 'textarea'
                      },
                title: localization.note
            },
            {
                name: 'supplierNote',
                showCondition: true,
                value: purchaseOrder.supplierNote,
                editable: !isSupplierUpdates
                    ? false
                    : {
                          type: 'textarea'
                      },
                title: `Supplier ${localization.note}`
            }
        ]
    ];
};

const PaymentDatesTitleContainer = styled.div`
    position: relative;
    width: fit-content;
`;
const WandBtn = styled.img`
    margin-left: 5px;
    width: 20px;
    &:hover {
        width: 23px;
    }
    cursor: pointer;
    transition: all 0.1s;
    position: absolute;
    right: -10;
`;
