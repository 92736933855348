import React from 'react';
import styled from 'styled-components';
import SummaryBox from './SummaryBox';
import { summaryData } from '../../../../models/entities/dashboard/summaryData';
import PieCharts from './PieCharts/PieCharts';
import { currencyFormatter } from '../../../shared/inputs/form/CurrencyInput/CurrencyInput';
import critical from '../../../../static/icons/warning-red-18dp.svg';
import warning from '../../../../static/icons/warning-orange-18dp.svg';
import { filterFieldsType } from '../../../../state/ducks/allPurchaseOrdersPage/allPurchaseOrdersPage';
import { useHistory } from 'react-router-dom';
import { PagesRoutes } from '../../../../routing/PagesRoutes';
import moment from 'moment';
import { drawers } from '../../../../state/ducks/header/header';
import { numberWithCommas } from '../../../../utils/patterns';

type Props = {
    localization: any;
    setShipmentFilters: (filter: filterFieldsType) => void;
    setGridFilter: (text: string) => void;
    setDrawerOpen: (open: drawers) => void;
    data: summaryData | null;
};

const SummarySection = ({ localization, data, setShipmentFilters, setDrawerOpen, setGridFilter }: Props) => {
    const history = useHistory();

    const transportationCostAvgColor = ((value?: number) => {
        if (!value) return;
        if (value <= 5) return '#629e31';
        else if (value >= 35) return '#c1292e';
        else if (value >= 20) return '#f4c915';
    })(data?.transportationCostAvg);

    return (
        <Container>
            {data && (
                <React.Fragment>
                    <TopContainer>
                        <BoxesContainer>
                            <SummaryBox
                                title={localization.activeShipments.title}
                                onClick={() => {
                                    setShipmentFilters([{ field: 'isActive', value: 'true' }]);
                                    history.push(PagesRoutes.Shipments);
                                }}
                            >
                                {data.activeShipments}
                            </SummaryBox>
                            <SummaryBox
                                title={localization.monthArrivals.title}
                                onClick={() => {
                                    const startOfMonth = moment().clone().startOf('month').format('YYYY-MM-DD hh:mm');
                                    const endOfMonth = moment().clone().endOf('month').format('YYYY-MM-DD hh:mm');
                                    setShipmentFilters([
                                        { field: 'fromETA', value: startOfMonth },
                                        { field: 'toETA', value: endOfMonth }
                                    ]);
                                    history.push(PagesRoutes.Shipments);
                                }}
                            >
                                {data.currentMonthArrivals}
                            </SummaryBox>
                            {/* <SummaryBox title={localization.missingDocuments.title}>{data.missingDocuments}</SummaryBox> */}
                            <SummaryBox title={localization.starredShipments.title} onClick={() => setDrawerOpen('FOLLOW')}>
                                {data.starredShipments}
                            </SummaryBox>
                            <SummaryBox title={localization.avg_transit_time} toolTip={localization.toolTips.avg_transit_time}>
                                <div style={{ fontSize: '14px' }}>Air - {Math.round(data.airAvgTransitTime)} days</div>
                                <div style={{ fontSize: '14px' }}>Ocean - {Math.round(data.oceanAvgTransitTime)} days</div>
                            </SummaryBox>
                        </BoxesContainer>
                        <BoxesContainer className="last">
                            <SummaryBox
                                title={localization.critical_alerts}
                                dataColor="#c1292e"
                                toolTip={localization.toolTips.critical_alerts}
                                onClick={() => {
                                    setShipmentFilters([
                                        {
                                            field: 'alertStatus',
                                            value: ['1']
                                        }
                                    ]);
                                    history.push(PagesRoutes.Shipments);
                                }}
                            >
                                <Flex>
                                    <img src={critical} alt={'critical'} width={20} style={{ marginRight: '5px' }} />

                                    {data.criticalAlertsCount}
                                </Flex>
                            </SummaryBox>
                            <SummaryBox
                                title={localization.warning_alerts}
                                dataColor="#f4c915"
                                toolTip={localization.toolTips.warning_alerts}
                                onClick={() => {
                                    setShipmentFilters([
                                        {
                                            field: 'alertStatus',
                                            value: ['2']
                                        }
                                    ]);
                                    history.push(PagesRoutes.Shipments);
                                }}
                            >
                                <Flex>
                                    <img src={warning} alt={'warning'} width={20} style={{ marginRight: '5px' }} />
                                    {data.warningAlertsCount}
                                </Flex>
                            </SummaryBox>
                            {!!data.transportationCostAvg && (
                                <SummaryBox
                                    title={localization.avg_trans_cost}
                                    dataColor={transportationCostAvgColor}
                                    toolTip={localization.toolTips.avg_trans_cost}
                                >
                                    {data.transportationCostAvg}%
                                </SummaryBox>
                            )}
                            {!!data.shipmentProfitAvg && (
                                <SummaryBox
                                    title={localization.avg_shipment_profit}
                                    // toolTip={localization.toolTips.avg_trans_cost}
                                >
                                    {numberWithCommas(data.shipmentProfitAvg.toFixed(2))}
                                </SummaryBox>
                            )}
                            {data.openInboundSpend && (
                                <SummaryBox title={localization.inbound_spend} toolTip={localization.toolTips.inbound_spend}>
                                    {data.openInboundSpend.map((v, i) => {
                                        return (
                                            <div key={i} style={{ fontSize: '14px' }}>
                                                {currencyFormatter(v.currency)(Number(v.price)?.toFixed(2))}
                                            </div>
                                        );
                                    })}
                                </SummaryBox>
                            )}
                        </BoxesContainer>
                    </TopContainer>
                    <PieCharts
                        chartsData={data.charts}
                        localization={localization}
                        setShipmentFilters={setShipmentFilters}
                        setGridFilter={setGridFilter}
                    />
                </React.Fragment>
            )}
        </Container>
    );
};

const Container = styled.div`
    padding: 15px 30px 30px 30px;

    @media screen and (max-width: 1000px) {
        padding-right: 10px;
        padding-left: 10px;
        padding-top: 20px;
    }

    @media screen and (max-width: 768px) {
        padding-right: 0px;
        padding-left: 0px;
        padding-top: 0px;
    }
`;

const TopContainer = styled.div`
    .last {
        border-top: none;
    }
`;
const BoxesContainer = styled.div`
    border-top: 1px solid #e4e4e4;
    border-bottom: 1px solid #e4e4e4;
    display: flex;
    flex-wrap: wrap;

    @media screen and (max-width: 768px) {
        border-bottom: none;
    }
`;

const Flex = styled.div`
    display: flex;
    align-items: center;
`;
const FlexColumn = styled.div`
    flex-direction: column;
    display: flex;
    align-items: center;
`;
export default SummarySection;
