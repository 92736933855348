import React, { useState } from 'react';
import styled from 'styled-components';
import { purchaseOrder, settings } from '../../../../../models/entities/purchaseOrder/purchaseOrder';
import { purchaseOrderItem } from '../../../../../models/entities/purchaseOrder/purchaseOrderItem';
import Items from '../../PurchaseOrderForm/sections/Items';
import { Formik } from 'formik';
import { Form } from 'formik-antd';
import { RootState } from '../../../../../state/store/store';
import { connect } from 'react-redux';
import { Modal } from 'antd';
import { useHistory } from 'react-router';
import { PagesRoutes } from '../../../../../routing/PagesRoutes';
import { updatePurchaseOrderItemsValuesPayload } from '../../../../../models/entities/purchaseOrder/updatePurchaseOrderItemsValuesPayload';
import moment from 'moment';
import claimsType from '../../../../../models/entities/claimsType';
import { claimsDomainsType } from '../../../../../userClaims';
import { userSelectors } from '../../../../../state/ducks/user/selectors';

type Props = {
    purchaseOrder: purchaseOrder;
    items?: Array<purchaseOrderItem>;
    localization: any;
    printMode?: boolean;
    hideItemsPrices?: boolean;
    settings?: settings;
    updatePurchaseOrderItem: (item: purchaseOrderItem) => Promise<void>;
    createPurchaseOrderItems: (purchaseOrderId: string, items: Array<purchaseOrderItem>) => Promise<void>;
    updatePurchaseOrderItemsValues: (payload: updatePurchaseOrderItemsValuesPayload) => Promise<void>;
    deletePurchaseOrderItem: (purchaseOrderId: string, itemId: string) => void;
    getUserClaims: (domain: claimsDomainsType) => claimsType;
    onCreateShipment?: (itemsIds: string[]) => void;
};

const ItemsTab = ({
    items,
    purchaseOrder,
    localization,
    settings,
    printMode,
    hideItemsPrices,
    updatePurchaseOrderItem,
    createPurchaseOrderItems,
    deletePurchaseOrderItem,
    getUserClaims,
    updatePurchaseOrderItemsValues
}: Props) => {
    const history = useHistory();
    const [updateItemQuantityTimeout, setUpdateItemQuantityTimeout] = useState(0);
    const [item, setItem] = useState({} as purchaseOrderItem);
    return (
        <ItemsWrapper>
            <Formik
                initialValues={{
                    vendorId: purchaseOrder.vendorId,
                    items: purchaseOrder?.items || []
                }}
                onSubmit={() => {}}
                enableReinitialize
            >
                {({ values, errors, setFieldValue, isSubmitting, isValidating }) => {
                    return (
                        <Form>
                            <Items
                                values={values}
                                hideItemsPrices={hideItemsPrices}
                                purchaseOrder={purchaseOrder}
                                setFieldValue={setFieldValue}
                                updatePurchaseOrderItem={updatePurchaseOrderItem}
                                onAddItemsCallback={async (items: purchaseOrderItem[]) => {
                                    await createPurchaseOrderItems(purchaseOrder.id, items);
                                }}
                                onUpdateItemsReadyDate={async (ids: string[], readyDate?: Date) => {
                                    const payload: updatePurchaseOrderItemsValuesPayload = {
                                        purchaseOrderId: purchaseOrder.id,
                                        ids,
                                        values: { readyDate } as purchaseOrderItem
                                    };
                                    await updatePurchaseOrderItemsValues(payload);
                                }}
                                onUpdateItemQuantity={async (updatedItem: purchaseOrderItem) => {
                                    clearTimeout(updateItemQuantityTimeout);
                                    const timeout = setTimeout(async () => {
                                        await updatePurchaseOrderItem({ ...updatedItem, purchaseOrderId: purchaseOrder.id });
                                    }, 500);
                                    setUpdateItemQuantityTimeout(timeout);
                                }}
                                onUpdateItemCost={
                                    !getUserClaims('po').edit_item_cost
                                        ? undefined
                                        : async (updatedItem: purchaseOrderItem) => {
                                              await updatePurchaseOrderItem({ ...updatedItem, purchaseOrderId: purchaseOrder.id });
                                          }
                                }
                                onDeleteItem={async (itemId: string) => {
                                    Modal.confirm({
                                        zIndex: 1100,
                                        content: <div>{localization.are_you_sure}</div>,
                                        async onOk() {
                                            await deletePurchaseOrderItem(purchaseOrder.id, itemId);
                                            const newItems = items?.filter((item) => item.id !== itemId);
                                            setFieldValue('items', newItems);
                                        },
                                        onCancel() {}
                                    });
                                }}
                                onCreateShipment={(itemsIds) => {
                                    const poItems = purchaseOrder.items?.filter((item) => itemsIds.includes(item.id));
                                    let goodsValue, currency, latestItemReadyDate;
                                    if (poItems) {
                                        if (poItems.every((item) => item.currency && item.currency === poItems?.[0].currency && item.price)) {
                                            currency = poItems[0].currency;
                                            goodsValue = poItems.reduce((sum: number, item: purchaseOrderItem) => {
                                                return sum + Number(item.price) * Number(item.quantity);
                                            }, 0);
                                        }

                                        const readyDates = poItems.filter((item) => !!item.readyDate).map((i) => i.readyDate);
                                        if (readyDates.length) {
                                            latestItemReadyDate = readyDates.sort((a, b) => moment(b).valueOf() - moment(a).valueOf())[0];
                                        }
                                    }

                                    history.push({
                                        pathname: PagesRoutes.NewShipment,
                                        state: {
                                            initialValues: {
                                                companyId: purchaseOrder.companyId,
                                                companyName: purchaseOrder.companyName,
                                                incoterms: purchaseOrder.incoterms,
                                                references: [
                                                    {
                                                        id: '',
                                                        entityId: '',
                                                        value: purchaseOrder.CargoZoneNumber,
                                                        companyType: 'CLIENT'
                                                    }
                                                ],
                                                shipperId: purchaseOrder.vendorId,
                                                consigneeId: purchaseOrder.consigneeId,
                                                origin: purchaseOrder.originStation,
                                                origin_zipcode: purchaseOrder.originZipCode,
                                                origin_country: purchaseOrder.originCountry,
                                                origin_pickup_address: purchaseOrder.originPickupAddress,
                                                destination: purchaseOrder.destinationStation,
                                                destination_zipcode: purchaseOrder.destinationZipCode,
                                                destination_country: purchaseOrder.destinationCountry,
                                                destination_pickup_address: purchaseOrder.destinationPickupAddress,
                                                consignee_same_as_company: purchaseOrder.consigneeSameAsCompany,
                                                goods_value: goodsValue || 0,
                                                goods_value_currency: currency || 'USD',
                                                quantity: poItems?.reduce((total, item) => total + Number(item.quantity) || 0, 0),
                                                goods_ready: latestItemReadyDate || null,
                                                commodity:
                                                    poItems
                                                        ?.map((item) => item.name)
                                                        ?.join(', ')
                                                        ?.substring(0, 200) || '',
                                                parties: purchaseOrder.parties || []
                                            },
                                            poItems,
                                            purchaseOrderId: purchaseOrder.id,
                                            purchaseOrderCzNumber: purchaseOrder.CargoZoneNumber
                                        }
                                    });
                                }}
                            />
                        </Form>
                    );
                }}
            </Formik>
        </ItemsWrapper>
    );
};

const GridWrapper = styled.div`
    flex: 0 0 100%;
    max-width: 100%;
    width: 100%;
    box-sizing: border-box;
    overflow-y: overlay;
    height: 100%;
    width: 100%;
`;

const ItemsWrapper = styled.div`
    display: flex;
    flex-direction: column;
    width: 100%;
    padding-top: 8px;
`;

const EmaptyStateText = styled.span`
    color: ${(props) => props.theme.colors.emptyStateText};
`;
const ButtonContainer = styled.div`
    display: flex;
    justify-content: flex-end;
    padding-top: 8px;
`;
const mapStateToProps = (state: RootState) => ({
    getUserClaims: (domain: claimsDomainsType) => userSelectors.getUserClaims(state)(domain)
});

const mapDispatchToProps = (dispatch: any) => ({
    createPurchaseOrderItems: (purchaseOrderId: string, items: Array<purchaseOrderItem>) =>
        dispatch.purchaseOrders.createPurchaseOrderItems({ purchaseOrderId, items }),
    updatePurchaseOrderItem: (item: purchaseOrderItem) => dispatch.purchaseOrders.updatePurchaseOrderItem(item),
    updatePurchaseOrderItemsValues: (payload: updatePurchaseOrderItemsValuesPayload) =>
        dispatch.purchaseOrders.updatePurchaseOrderItemsValues(payload),
    deletePurchaseOrderItem: (purchaseOrderId: string, itemId: string) => dispatch.purchaseOrders.deletePurchaseOrderItem({ purchaseOrderId, itemId })
});

export default connect(mapStateToProps, mapDispatchToProps)(ItemsTab);
