import critical from '../../../../static/icons/warning-red-18dp.svg';
import warning from '../../../../static/icons/warning-orange-18dp.svg';
import good from '../../../../static/icons/warning-green-18dp.svg';

export type orderAlert = {
    id: string;
    orderId: string;
    message: string;
    level: number;
    createdDate: Date;
    isDismissed: boolean;
    ruleType: string;
    createdAt?: Date;
    updatedAt?: Date;
    deletedAt?: Date;
};

export const orderAlertLevelEnum: { [key: number]: string } = {
    1: 'critical',
    2: 'warning',
    3: 'good'
};

export const orderAlertImg: { [key: number]: any } = {
    1: critical,
    2: warning,
    3: good
};
