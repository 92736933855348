import { shipmentAlert } from './../../../../models/entities/shipment/shipmentAlert';
import BaseController from '../..';
import IShipmentParties from './IShipmentAlerts';

export default class ShipmentAlerts extends BaseController implements IShipmentParties {
    constructor(baseUrl: string) {
        super(baseUrl);
    }
    async fetchShipmentAlerts(shipmentId: string): Promise<Array<shipmentAlert>> {
        const response = await this.client.get(`/shipment/alerts/${shipmentId}`);
        return response;
    }
    async dismissShipmentAlert(shipmentId: string, alert: shipmentAlert): Promise<void> {
        const response = await this.client.post(
            `/shipment/alerts/${alert.id}/dismiss`,
            JSON.stringify({ shipmentId, data: { level: alert.level, message: alert.message } })
        );
        return response;
    }
}
