import React, { useEffect, useState } from 'react';
import { documentTypes } from '../../../../../models/entities/shipment/shipment';
import { styled } from '../../../../shared/Theme/theme';
import { Form, Formik } from 'formik';
import Button from '../../../../shared/SmallComponents/Button';
import { ModalFooter, ModalFormContainer } from '../../../../shared/Modal/Modal';
import Loader from '../../../../shared/SmallComponents/Loader';
import { document } from '../../../../../models/entities/common_subentities/document';
import { FormDatePicker, FormDropDown, FormTextArea, FormUploadInput } from '../../../../shared/inputs/form';
import FieldLabel from '../../../../shared/SmallComponents/FieldLabel';

export enum formMode {
    NEW,
    EDIT
}

interface formFields {
    type?: string | string[];
    comment?: string;
    file?: File;
    expDate?: Date;
}
interface formFieldsErrors {
    type?: string | string[];
    comment?: string;
    expDate?: string;
    file?: string;
}
type Props<entityType, settingsType> = {
    entity?: entityType;
    document?: document;
    mode: formMode;
    localizations?: any;
    settings?: settingsType;
    openedFromModal?: {
        setVisible: React.Dispatch<React.SetStateAction<boolean>>;
    };
    confirmation?: { setConfirm: React.Dispatch<React.SetStateAction<boolean>> };
    createDocument: (entityId: string, document: document) => void;
    updateDocument: (entityId: string, document: document) => void;
    showLoader?: boolean;
    defaultValues?: document;
    typeMode?: 'multiple' | 'tags';
};

let AFTER_LOADING: boolean = false;
function DocumentFormConnected<entityType extends { id: string }, settingsType extends { documentTypes?: documentTypes } = any>({
    entity,
    document,
    localizations,
    mode,
    settings,
    openedFromModal,
    confirmation,
    createDocument,
    updateDocument,
    showLoader,
    defaultValues,
    typeMode
}: Props<entityType, settingsType>) {
    const [submitLoading, setLoading] = useState(false);
    const documentTypesOptions = settings?.documentTypes?.map((item) => ({
        text: item.name,
        value: item.code
    }));
    let initFormValues: formFields = {
        type: '',
        comment: '',
        expDate: undefined,
        file: undefined
    };
    const setInitialValues = () => {
        if (!document || mode === formMode.NEW) {
            if (defaultValues) initFormValues = defaultValues;
            return initFormValues;
        } else {
            initFormValues.type = document.type;
            initFormValues.comment = document.comment;
            initFormValues.expDate = document.expDate;
            initFormValues.file = { name: document.fileName, type: document.fileType } as File;
            return initFormValues;
        }
    };
    const validate = (values: any) => {
        if (confirmation) {
            if (JSON.stringify(values) === JSON.stringify(initFormValues)) {
                confirmation.setConfirm(false);
            } else {
                confirmation.setConfirm(true);
            }
        }
        const errors: formFieldsErrors = {};
        if (!values.type) errors.type = localizations.documents.form.required_error;
        if (!values.file) errors.file = localizations.documents.form.required_error;
        return errors;
    };
    const submit = (values: any) => {
        if (entity) {
            const payload: document = {
                type: values.type,
                comment: values.comment,
                fileName: values.file.name,
                fileType: values.file.type,
                file: values.file,
                expDate: values.expDate
            } as document;
            if (mode === formMode.NEW) createDocument(entity.id, payload);
            else if (document) {
                updateDocument(entity.id, {
                    ...document,
                    ...payload,
                    id: document.id
                });
            }
        }
    };
    useEffect(() => {
        if (showLoader) {
            setLoading(true);
            AFTER_LOADING = true;
        } else {
            setLoading(false);
            if (AFTER_LOADING && openedFromModal) {
                openedFromModal.setVisible(false);
                AFTER_LOADING = false;
            }
        }
    }, [showLoader, openedFromModal]);
    return (
        <Formik initialValues={setInitialValues()} validate={validate} onSubmit={submit}>
            <Form>
                <Container>
                    <FieldLabel>{localizations.documents.form.documenttype}</FieldLabel>
                    <FieldContainer>
                        <FormDropDown
                            minWidth="160px"
                            name="type"
                            placeHolder={localizations.documents.form.documenttype_placeholder}
                            options={documentTypesOptions || []}
                            mode={typeMode}
                        />
                    </FieldContainer>
                    <FieldLabel>{'Expiration Date'}</FieldLabel>
                    <FieldContainer>
                        <FormDatePicker name="expDate" placeHolder={'Expiration Date'} />
                    </FieldContainer>
                    <FieldLabel>{localizations.documents.form.comment}</FieldLabel>
                    <FieldContainer>
                        <FormTextArea name="comment" placeHolder={localizations.documents.form.comment_placeholder} />
                    </FieldContainer>
                    <FieldContainer>
                        <FormUploadInput name="file" placeHolder={localizations.documents.form.addfile_placeholder} id="file-upload" />
                    </FieldContainer>
                </Container>
                {openedFromModal && (
                    <FormFooter>
                        <Button minWidth="55px" buttonType="filled" type="submit">
                            {submitLoading ? (
                                <Loader width="20px" marginTop="0px" showText={false} />
                            ) : (
                                <span>{localizations.documents.modal.submit_btn}</span>
                            )}
                        </Button>
                    </FormFooter>
                )}
            </Form>
        </Formik>
    );
}
const FieldContainer = styled.div`
    margin-bottom: 12px;
`;

const Container = styled(ModalFormContainer)``;

const FormFooter = styled(ModalFooter)`
    position: absolute;
    bottom: 0;
    width: 100%;
    left: 0;
    right: 0;
    background: white;
    justify-content: flex-end;
`;

export default DocumentFormConnected;
