import BaseController from '../..';
import { address } from '../../../../models/entities/company/address';
import ICompaniesAddresses from './ICompaniesAddresses';

export default class CompaniesAddresses extends BaseController implements ICompaniesAddresses {
    constructor(baseUrl: string) {
        super(baseUrl);
    }
    async createCompanyAddress(companyId: string, data: address) {
        const response = await this.client.post(`/companyAdmin/company/addresses`, JSON.stringify({ ...data, companyId }));
        return response;
    }
    async updateCompanyAddress(companyId: string, data: address) {
        const response = await this.client.put(`/companyAdmin/company/addresses`, JSON.stringify({ ...data, companyId }));
        return response;
    }
    async deleteCompanyAddress(companyId: string, addressId: number) {
        const response = await this.client.delete(`/companyAdmin/company/addresses`, JSON.stringify({ companyId, id: addressId }));
        return response;
    }
}
