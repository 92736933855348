import { userSettings } from '../../../models/entities/user';
import { ColumnType } from './types/Column';

export function filterColsByUserSettings<T = any>(columns: Array<ColumnType<T>>, settings?: userSettings): ColumnType<T>[] {
    if (!settings || settings.value.length === 0) return columns;
    else {
        const arr = settings.value.map((col: any) => {
            const item = columns.find((item) => item.field === col.field);
            if (item) return { ...item, ...col };
            else return col;
        });
        columns.forEach((item) => {
            if (arr.findIndex((col: any) => item.field === col.field) === -1) {
                arr.push({ ...item });
            }
        });
        return arr;
    }
}

export function calcGroupedHeadersWidths<T>(
    columns: Array<ColumnType<T>>,
    contentContainerElWidth: number,
    groupHeaders?: { name: string; fields: (keyof T)[] }[]
) {
    if (contentContainerElWidth && groupHeaders?.length) {
        const { totalFixedColsWidth, colsNumWithWidth } = columns.reduce(
            (
                acc: {
                    totalFixedColsWidth: number;
                    colsNumWithWidth: number;
                },
                col: ColumnType<T>
            ) => {
                const { totalFixedColsWidth, colsNumWithWidth } = acc;
                if (!col.width) return { totalFixedColsWidth, colsNumWithWidth };

                const widthNum = Number(col.width.replace(/[^0-9]/g, ''));

                if (!widthNum) return { totalFixedColsWidth, colsNumWithWidth };

                return { totalFixedColsWidth: totalFixedColsWidth + widthNum, colsNumWithWidth: colsNumWithWidth + 1 };
            },
            { totalFixedColsWidth: 0, colsNumWithWidth: 0 }
        );

        const widthReminderPerCol = (contentContainerElWidth - totalFixedColsWidth) / (columns.length - colsNumWithWidth);

        const colsHeadersWidths = columns.reduce((acc: { field: keyof T; width: number }[], col: ColumnType<T>) => {
            const { field } = col;
            const reminderCol = [...acc, { field, width: widthReminderPerCol }];

            if (!col.width) return reminderCol;

            const widthNum = Number(col.width.replace(/[^0-9]/g, ''));

            if (!widthNum) return reminderCol;

            return [...acc, { field, width: widthNum }];
        }, []);

        const groupedHeadersWidths: { name?: string; width: number }[] = [];
        let checkGroupIndex = 0;

        for (let i = 0; i < colsHeadersWidths.length; i++) {
            const col = colsHeadersWidths[i];
            const group = groupHeaders.find((gh) => gh.fields[checkGroupIndex] === col.field);

            if (!group) {
                groupedHeadersWidths.push({ width: col.width });
                continue;
            }

            if (group.fields.length === checkGroupIndex + 1) {
                groupedHeadersWidths.push({
                    name: group.name,
                    width: [...Array(checkGroupIndex + 1)].reduce(
                        (sum: number, _el: undefined, index: number) => sum + colsHeadersWidths[i - index].width,
                        0
                    )
                });
                checkGroupIndex = 0;
                continue;
            }

            checkGroupIndex++;
        }

        return groupedHeadersWidths;
    }
    return [];
}

export const camelToSentence = (text: string) => {
    const result = text.replace(/([A-Z])/g, ' $1');
    return result.charAt(0).toUpperCase() + result.slice(1);
};

export type groupHeadersType<T> = { name: string; fields: (keyof T)[] }[];
export type groupElType<T> = [string | undefined, ColumnType<T>[]];
export function groupColumns<T>(columns: ColumnType<T>[], groupHeaders?: groupHeadersType<T>) {
    return groupHeaders?.length
        ? columns.reduce((acc: groupElType<T>[], col: ColumnType<T>) => {
              const currentAcc = [...acc];
              const group = groupHeaders?.find((group) => group.fields.includes(col.field));

              if (group) {
                  const existingGroupIndex = currentAcc.findIndex((g: groupElType<T>) => g[0] === group.name);
                  if (existingGroupIndex >= 0) {
                      const existingGroup = currentAcc[existingGroupIndex];
                      currentAcc[existingGroupIndex] = [existingGroup[0], [...existingGroup[1], col]];
                      return currentAcc;
                  }

                  return [...currentAcc, [group.name, [col]]] as groupElType<T>[];
              }
              return [...currentAcc, [undefined, [col]]] as groupElType<T>[];
          }, [])
        : undefined;
}

export function bindData<T>(row: T, fields: Array<keyof T> | undefined): Array<any> | null {
    if (!row || !fields) return null;
    return fields.map((field) => row[field]);
}
