import BaseController from '..';
import { businessPartner } from '../../../models/entities/businessPartner/businessPartner';
import { filterFieldsType } from '../../../state/ducks/allBusinessPartnersPage/allBusinessPartnersPage';
import IBusinessPartners from './IBusinessPartners';
import BusinessPartnersDocuments from './documents/businessPartnersDocuments';
import BusinessPartnersContacts from './contacts/businessPartnersContacts';
import BusinessPartnersAddresses from './addresses/businessPartnersAddresses';
import BusinessPartnersDiscussions from './discussions/businessPartnersDiscussions';
import { RcFile } from 'antd/lib/upload';

export default class BusinessPartners extends BaseController implements IBusinessPartners {
    private businessPartnersDocuments: BusinessPartnersDocuments;
    private businessPartnersContacts: BusinessPartnersContacts;
    private businessPartnersAddresses: BusinessPartnersAddresses;
    private businessPartnersDiscussions: BusinessPartnersDiscussions;
    constructor(baseUrl: string) {
        super(baseUrl);
        this.businessPartnersDocuments = new BusinessPartnersDocuments(baseUrl);
        this.businessPartnersContacts = new BusinessPartnersContacts(baseUrl);
        this.businessPartnersAddresses = new BusinessPartnersAddresses(baseUrl);
        this.businessPartnersDiscussions = new BusinessPartnersDiscussions(baseUrl);
    }

    async fetchBusinessPartners() {
        const response = await this.client.get('/businessPertner');
        return response;
    }
    async uploadBusinessPartnersByExcel(file: File, companyId?: string) {
        this.client.setBodyType('FORM-DATA');
        const formData = new FormData();
        formData.append('file', file);
        if (companyId) formData.append('companyId', companyId);
        const response = await this.client.post('/businessPertner/excel-upload', formData);
        return response;
    }

    async createBusinessPartner(party: businessPartner) {
        const response = await this.client.post('/businessPertner', JSON.stringify({ data: party }));
        return response;
    }
    async updateBusinessPartner(businessPartnerId: string, party: businessPartner) {
        const response = await this.client.put('/businessPertner', JSON.stringify({ id: businessPartnerId, data: party }));
        return response;
    }
    async updateBusinessPartnerIsActive(businessPartnerId: string, isActive: boolean) {
        await this.client.put('/businessPertner/isActive', JSON.stringify({ id: businessPartnerId, isActive }));
    }
    async restoreBusinessPartner(businessPartnerId: string) {
        const response = await this.client.put(`/businessPertner/restore`, JSON.stringify({ entityId: businessPartnerId }));
        return response;
    }
    async deleteBusinessPartner(businessPartnerId: string) {
        const response = await this.client.delete('/businessPertner', JSON.stringify({ id: businessPartnerId }));
        return response;
    }
    async fetchCompaniesByFilter(filter: filterFieldsType) {
        const response = await this.client.post('/businessPertners', JSON.stringify({ filters: filter }));
        return response;
    }
    async fetchById(companyId: number) {
        const response = await this.client.get(`/businessPertner/${companyId}`);
        return response;
    }

    async sendShipmentPaidEmail(contactsEmails: string[], shipmentCzNumber: string, shipmentPdfFile: File) {
        this.client.setBodyType('FORM-DATA');

        const formData = new FormData();

        formData.append('file', shipmentPdfFile);
        formData.append('shipmentCzNumber', shipmentCzNumber);

        contactsEmails.forEach((email) => formData.append('contactsEmails[]', email));

        const response = await this.client.post(`/businessPertner/sendShipmentPaidEmail`, formData);
        return response;
    }

    async sendGetStartedInventationEmail(businessPartnerId: string, email: string): Promise<void> {
        await this.client.post(`/businessPertner/sendGetStartedInventationEmail`, JSON.stringify({ businessPartnerId, email }));
    }

    async updateBusinessPartnerLogo(businessPartnerId: string, file: RcFile): Promise<string | null> {
        this.client.setBodyType('FORM-DATA');
        const formData = new FormData();
        formData.append('file', file);
        if (businessPartnerId) formData.append('businessPartnerId', businessPartnerId);
        const response = await this.client.post('/businessPertner/uploadLogo', formData);
        return response;
    }
    documents() {
        return this.businessPartnersDocuments;
    }
    contacts() {
        return this.businessPartnersContacts;
    }
    addresses() {
        return this.businessPartnersAddresses;
    }
    discussions() {
        return this.businessPartnersDiscussions;
    }
}
