import React from 'react';
import CreateGroupForm from './CreateGroupForm';
import CustomModal, { ModalTriggerProps } from '../../../shared/Modal/Modal';

type Props = {
    Trigger?: (props: ModalTriggerProps) => JSX.Element;
    onCancelCallback?: () => void;
    localization: any;
};

const CreateGroupModal = ({ Trigger, onCancelCallback, localization }: Props) => {
    return (
        <CustomModal
            title={{ text: localization.create_new_group }}
            Trigger={Trigger}
            onCancelCallback={onCancelCallback}
            Content={(setVisible?: React.Dispatch<React.SetStateAction<boolean>>, setConfirm?: React.Dispatch<React.SetStateAction<boolean>>) => {
                return (
                    <CreateGroupForm
                        setModalVisible={(visible: boolean) => {
                            setVisible && setVisible(visible);
                        }}
                        confirmation={setConfirm ? { setConfirm } : undefined}
                    />
                );
            }}
            width="393px"
            maxWidth="393px"
        />
    );
};

export default CreateGroupModal;
