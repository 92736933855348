import React, { useState } from 'react';
import { bid, settings } from '../../../../../models/entities/bid/bid';
import Tab from '../../../../shared/Tabs/Tab';
import TabsContainer from '../../../../shared/Tabs/TabsContainer';
import History from '../../../commonSubEntities/history/HistoryTab';
import BidDocuments from './DocumentsTab';
import Quotations from './Quotations';

type Props = {
    bid: bid;
    localization?: any;
    settings?: settings;
};

const PurchaseOrderTabs = ({ bid, localization, settings }: Props) => {
    const [activeTab, setActiveTab] = useState('Quotations');
    return (
        <TabsContainer>
            <Tab title="Quotations" onClick={() => setActiveTab('Quotations')} isActive={activeTab === 'Quotations'}>
                {/* <Quotations bid={bid} localization={localization} /> */}
            </Tab>
            <Tab title="Documents" onClick={() => setActiveTab('Documents')} isActive={activeTab === 'Documents'}>
                <BidDocuments documents={bid.documents} localization={localization} bidId={bid.id} settings={settings} />
            </Tab>
            <Tab title="History" onClick={() => setActiveTab('History')} isActive={activeTab === 'History'}>
                <History history={bid.history} localization={localization} />
            </Tab>
        </TabsContainer>
    );
};

export default PurchaseOrderTabs;
