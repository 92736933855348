import countries from './countries';

export function mapCodeIcon(code: string) {
    let defaultFlag = require('../../static/icons/question_mark.svg').default; //default
    const country = countries.find((item) => item.code === code);
    if (country) {
        try {
            return require(`../../static/flagsIcons/${code}.svg`).default;
        } catch (ex) {
            return defaultFlag;
        }
    } else return defaultFlag;
}
