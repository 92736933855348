import React from 'react';
import { match, useHistory } from 'react-router-dom';
import { styled } from '../../../shared/Theme/theme';
import PinInput from 'react-pin-input';
import { useEffect } from 'react';
import Loader from '../../../shared/SmallComponents/Loader';
import { css } from 'styled-components';
import { useState } from 'react';
import { Location } from 'history';
import { LocationStateType } from '../GetStartedPageConnected';

type ConfirmEmailProps = {
    localization: any;
    validateEmail: (email: string, pinCode: string, pricingPlanId?: string, underForwarderToken?: string) => Promise<string>;
    isValidateEmail: boolean;
    match: match;
    location: Location<LocationStateType>;
    underForwarderToken?: string;
};

const ConfirmEmail = ({ location, localization, validateEmail, isValidateEmail, match, underForwarderToken }: ConfirmEmailProps) => {
    const history = useHistory();
    const [pinInputRef, setPinInputRef] = useState<PinInput | null>(null);

    const [validationSuccess, setValidationSuccess] = useState<boolean | null>(null);
    useEffect(() => {
        if (!location?.state?.email) history.replace(`${match.url}`);
    }, [location?.state?.email]);
    const handlePinInputComplete = async (value: string, index: number) => {
        if (location?.state?.email) {
            const res = await validateEmail(location.state.email, value, location.state.pricingPlanId, underForwarderToken);
            if (!res && pinInputRef) {
                setValidationSuccess(false);
                pinInputRef.clear();
                pinInputRef.focus();
                return;
            }

            history.push({ pathname: `${match.url}/setupCompany`, search: location.search, state: { email: location.state.email } });
        }
    };
    return (
        <>
            <Title>{localization.title}</Title>
            <SubTitle>{localization.sub_title?.(location?.state?.email)}</SubTitle>
            <PinInputContainer disabled={isValidateEmail}>
                <PinInput
                    length={6}
                    focus
                    onComplete={handlePinInputComplete}
                    type="custom"
                    disabled={isValidateEmail}
                    ref={(ref) => setPinInputRef(ref)}
                />
                {isValidateEmail && <Loader text={localization.checking_code} inline showText width={'15px'} fontSize={'16px'} marginTop="10px" />}
                {validationSuccess != null && (
                    <ValidationResIndication isError={!validationSuccess}>
                        {!validationSuccess ? localization.validate_email_err : 'Success'}
                    </ValidationResIndication>
                )}
            </PinInputContainer>
            <Bottom>
                <div style={{ color: '#5f5f5f' }}>{localization.ask_cannot_find}</div>
            </Bottom>
        </>
    );
};

const Title = styled.div`
    font-weight: 700;
    font-size: 48px;
    line-height: 46px;
    max-width: 700px;
    text-align: center;
    letter-spacing: -0.75px;
    margin-bottom: 10px;
`;

const SubTitle = styled.div`
    font-size: 18px;
    line-height: 27px;
    margin-bottom: 32px;
    color: #454245;
    max-width: 700px;
    text-align: center;
`;

const PinInputContainer = styled.div<{ disabled: boolean }>`
    .pincode-input-text {
        padding: 0 !important;
        margin: 0 !important;
        text-align: center;
        border: 1px solid #8e8d8e !important;
        background: ${(props) => (props.disabled ? '#f5f5f5 !important' : 'transparent')};
        cursor: ${(props) => (props.disabled ? 'wait' : 'auto')};
        width: 77px !important;
        height: 92px !important;
        font-size: 50px;
    }
    .pincode-input-text:not(:first-child) {
        border-left: none !important;
    }
    .pincode-input-text:last-child {
        border-top-right-radius: 5px;
        border-bottom-right-radius: 5px;
    }
    .pincode-input-text:first-child {
        border-top-left-radius: 5px;
        border-bottom-left-radius: 5px;
    }
    .pincode-input-text:focus {
        border: 1px solid rgba(0, 138, 201, 1) !important;
        box-shadow: 0 0 0 1px rgba(0, 138, 201, 1), 0 0 7px rgba(0, 138, 201, 0.3) !important;
    }
`;

const ValidationResIndication = styled.div<{ isError: boolean }>`
    margin-top: 20px;
    border-radius: 4px;
    font-size: 13px;
    padding: 12px 16px;
    display: flex;
    align-items: center;
    justify-content: center;
    ${(props) =>
        props.isError
            ? css`
                  background-color: rgba(224, 30, 90, 0.1);
                  border: 1px solid rgba(224, 30, 90, 0.4);
              `
            : css`
                  background-color: rgb(30 224 32 / 10%);
                  border: 1px solid rgb(72 224 30 / 40%);
              `}
`;

const Bottom = styled.div`
    margin-top: 24px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
`;

export default ConfirmEmail;
