import React, { useEffect, useState } from 'react';
import { purchaseOrder } from '../../../../models/entities/purchaseOrder/purchaseOrder';
import BackButton from '../../../shared/SmallComponents/BackButton';
import RouteLeavingGuard from '../../../shared/SmallComponents/RouteLeavingGuard';
import { PagesRoutes } from '../../../../routing/PagesRoutes';
import theme, { styled } from '../../../shared/Theme/theme';
import FormSection from './FormSection';
import CZTooltip, { QuestionToolTip } from '../../../shared/CZTooltip/CZTooltip';
import { Formik, Form } from 'formik';
import Button from '../../../shared/SmallComponents/Button';
import { validationSchema } from '../../../../validationSchemas/purchaseOrderForm';
import { useHistory } from 'react-router-dom';
import General from './sections/General';
import Geography from './sections/Details/Geography';
import Items from './sections/Items';
import { defaultItems, itemsPrepareSubmit, itemsPrepareEdit, validateItems } from './sections/Items/util';
import { emptyRefObj } from './sections/References';
import { getBusinessPartnerDefaultAddressAndContact, partiesPrepareSubmit } from './sections/utils';
import { BaseProps, ePurchaseOrderFormMode } from './PurchaseOrderFormConnected';
import Loader from '../../../shared/SmallComponents/Loader';
import CustomModal, { ModalTriggerProps } from '../../../shared/Modal/Modal';
import { emailPattern, phonePattern } from '../../../../utils/patterns';
import { party } from '../../../../models/entities/common_subentities/party';
import { purchaseOrderItem } from '../../../../models/entities/purchaseOrder/purchaseOrderItem';
import { calcTotalWeight, calcTotalVolume } from '../../../../utils/measurementUtils';

type Props = BaseProps & {
    mode: string;
    purchaseOrderFormError?: string;
    initialValues?: purchaseOrder;
    relatedPdId?: string;
};

export const renderFieldLabel = (text: string, isMandatory: boolean, tooltipText?: string) => {
    return (
        <LabelContainer>
            <Label>
                {isMandatory && <MandatoryIndication>*</MandatoryIndication>}
                {text}
            </Label>
            {tooltipText && <CZTooltip text={tooltipText}>{QuestionToolTip}</CZTooltip>}
        </LabelContainer>
    );
};
let sectionErrorIndexes: Array<number> = [];
const SinglePurchaseOrderForm = ({
    localizations,
    businessPartnerVendors,
    businessPartnerConsignees,
    businessPartnerForwarders,
    businessPartnerSuppliers,
    businessPartnerSuppliersManufacturers,
    businessPartnerManufacturers,
    businessPartnerWarehouses,
    businessPartners,
    createPurchaseOrder,
    updatePurchaseOrder,
    purchaseOrder,
    mode,
    allowedTypes,
    allowedCompanies,
    allowedCompaniesDropDownOptions,
    purchaseOrderFormError,
    fetchShipmentsByFilter,
    filteredShipments,
    getUsersByCompaniesIds,
    usersInSameCompany,
    initialValues,
    relatedPdId
}: Props) => {
    const history = useHistory();
    const [activeFormSection, setActiveFormSection] = useState(1);
    const [submitClicked, setSubmitClicked] = useState(false);
    const [currentPurchaseOrderId, setCurrentPurchaseOrderId] = useState<string | null>(null);
    const [showGoBackModal, setShowGoBackModal] = useState(false);

    const onCancelClick = () => {
        history.push(PagesRoutes.PurchaseOrders);
    };

    useEffect(() => {
        if ([ePurchaseOrderFormMode.EDIT, ePurchaseOrderFormMode.DUPLICATE].includes(mode) && purchaseOrder) {
            if (purchaseOrder.id) setCurrentPurchaseOrderId(purchaseOrder.id);
            if (purchaseOrder.originCountry && purchaseOrder.destinationCountry)
                fetchShipmentsByFilter([
                    { field: 'originCountry', value: purchaseOrder.originCountry },
                    { field: 'destinationCountry', value: purchaseOrder.destinationCountry }
                ]);
        } else {
            setCurrentPurchaseOrderId(null);
        }
    }, [mode, purchaseOrder, setCurrentPurchaseOrderId, fetchShipmentsByFilter]);

    const renderErrorModal = () => {
        return (
            purchaseOrderFormError &&
            submitClicked && (
                <CustomModal
                    title={{ text: 'Error' }}
                    Trigger={(props: ModalTriggerProps) => <>{props.onClick()}</>}
                    Content={(setVisible?: React.Dispatch<React.SetStateAction<boolean>>) => {
                        return <div style={{ width: '393px', wordBreak: 'break-word' }}>{purchaseOrderFormError}</div>;
                    }}
                    onCancelCallback={() => {
                        setSubmitClicked(false);
                    }}
                    width="393px"
                />
            )
        );
    };

    const renderGoBackModal = () => {
        return (
            <CustomModal
                title={{ text: 'Where do you want to go?' }}
                Trigger={(props: ModalTriggerProps) => <>{props.onClick()}</>}
                Content={(setVisible?: React.Dispatch<React.SetStateAction<boolean>>) => {
                    return (
                        <div style={{ display: 'flex', justifyContent: 'space-around' }}>
                            <Button
                                onClick={() => {
                                    history.push(PagesRoutes.PurchaseOrders);
                                }}
                            >
                                {localizations.form.back_modal.purchaseOrders_page}
                            </Button>
                            {!!relatedPdId && (
                                <Button
                                    onClick={() => {
                                        history.push(PagesRoutes.PurchaseDemands + '/' + relatedPdId);
                                    }}
                                >
                                    {localizations.form.back_modal.purchaseDemand_details}
                                </Button>
                            )}
                            <Button
                                onClick={() => {
                                    history.push(PagesRoutes.PurchaseOrders + '/' + currentPurchaseOrderId);
                                }}
                            >
                                {localizations.form.back_modal.purchaseOrder_details}
                            </Button>
                        </div>
                    );
                }}
                onCancelCallback={() => {
                    setShowGoBackModal(false);
                }}
                width="393px"
            />
        );
    };

    const setInitialValues = () => {
        let companyName;
        let payment_terms, note;
        let vendor_addresses, origin, origin_country, origin_zipcode, origin_pickup_address;
        let vendor_contacts, vendor_contact_name, vendor_contact_phoneNumber, vendor_contact_email;

        if (initialValues) {
            const { companyId, vendorId } = initialValues;

            if (companyId) companyName = allowedCompanies?.find((item) => item.companyId === companyId)?.companyName;

            if (vendorId) {
                const {
                    businessPartner: vendor,
                    defaultAddress,
                    defaultContact
                } = getBusinessPartnerDefaultAddressAndContact(businessPartnerSuppliersManufacturers, vendorId);

                if (vendor) {
                    payment_terms = vendor?.paymentTerms ? [vendor?.paymentTerms] : [];
                    note = vendor?.paymentTermsRemarks;
                }

                if (defaultAddress) {
                    vendor_addresses = defaultAddress.id;
                    origin = defaultAddress.airPort;
                    origin_country = defaultAddress.country;
                    origin_zipcode = defaultAddress.city;
                    origin_pickup_address = defaultAddress.address;
                }

                if (defaultContact) {
                    vendor_contacts = defaultContact.id;
                    vendor_contact_name = defaultContact.name;
                    vendor_contact_phoneNumber = defaultContact.phoneNumber;
                    vendor_contact_email = defaultContact.email;
                }
            }
        }
        const initValues = {
            forwarderId: null,
            vendorId: null,
            consigneeId: null,
            companyId: '',
            companyName: companyName || '',
            origin: origin || '',
            origin_country: origin_country || '',
            origin_pickup_address: origin_pickup_address || '',
            origin_zipcode: origin_zipcode || '',
            destination: '',
            destination_country: '',
            destination_pickup_address: '',
            destination_zipcode: '',
            port_terminal: '',
            incoterms: '',
            mode: '',
            subMode: '',
            weight: 0,
            weightUnit: 'KG',
            volume: 0,
            volumeUnit: '',
            chargeable_weight_unit: 'KG',
            expectedReadyDate: null,
            requestedGoodsReady: null,
            isAsap: null,
            payment_terms: payment_terms,
            note: note || '',
            items: [],
            consignee_same_as_company: false,
            references: [emptyRefObj],
            parties: [],
            ...initialValues,
            vendor_addresses,
            vendor_contacts,
            vendor_contact_name,
            vendor_contact_phoneNumber,
            vendor_contact_email
        };
        if ([ePurchaseOrderFormMode.EDIT, ePurchaseOrderFormMode.DUPLICATE].includes(mode) && purchaseOrder) {
            let vendor: party | undefined;
            if (purchaseOrder.parties) vendor = purchaseOrder.parties.find((item) => item.businessPartnerId === purchaseOrder.vendorId);
            let consignee: party | undefined;
            if (purchaseOrder.parties) consignee = purchaseOrder.parties.find((item) => item.businessPartnerId === purchaseOrder.consigneeId);
            return {
                forwarderId: purchaseOrder.forwarderId,
                vendorId: purchaseOrder.vendorId,
                consigneeId: purchaseOrder.consigneeId,
                companyId: purchaseOrder.companyId,
                companyName: purchaseOrder.companyName,
                origin: purchaseOrder.originStation,
                origin_country: purchaseOrder.originCountry,
                origin_zipcode: purchaseOrder.originZipCode,
                destination: purchaseOrder.destinationStation,
                destination_country: purchaseOrder.destinationCountry,
                destination_zipcode: purchaseOrder.destinationZipCode,
                incoterms: purchaseOrder.incoterms,
                origin_pickup_address: purchaseOrder.originPickupAddress,
                destination_pickup_address: purchaseOrder.destinationPickupAddress,
                expectedReadyDate: purchaseOrder.expectedReadyDate,
                requestedGoodsReady: purchaseOrder.requestedGoodsReady,
                isAsap: purchaseOrder.isAsap,
                items: itemsPrepareEdit(purchaseOrder.items),
                consignee_same_as_company: purchaseOrder.consigneeSameAsCompany,
                references: purchaseOrder.references ? purchaseOrder.references : [emptyRefObj],
                parties: purchaseOrder.parties,
                payment_terms: purchaseOrder.paymentTerms ? [purchaseOrder.paymentTerms] : undefined,
                note: purchaseOrder.note,
                vendor_contact_name: vendor?.contactName,
                vendor_contact_phoneNumber: vendor?.phoneNumber,
                vendor_contact_email: vendor?.email,
                consignee_contact_name: consignee?.contactName,
                consignee_contact_phoneNumber: consignee?.phoneNumber,
                consignee_contact_email: consignee?.email
            };
        }
        return initValues;
    };

    const onNextClick = (event: any) => {
        event.preventDefault();
        setActiveFormSection(activeFormSection + 1);
    };
    const onPreviousClick = (event: any) => {
        event.preventDefault();
        setActiveFormSection(activeFormSection - 1);
    };
    const onSectionNumberClick = (sectionNumber: number) => {
        setActiveFormSection(sectionNumber);
    };

    const formValidationSchema = validationSchema(localizations);

    const validate = (values: any) => {
        const errors: any = {};
        if (values.items) validateItems(values.items, errors, localizations);
        if (allowedCompanies.length > 1 && !values.companyId) errors.companyId = localizations.form.requiredFieldMessage;
        if (values.vendor_contact_phoneNumber && !values.vendor_contact_phoneNumber.match(phonePattern))
            errors.vendor_contact_phoneNumber = localizations.form.phone_error;
        if (values.vendor_contact_email && !values.vendor_contact_email.match(emailPattern))
            errors.vendor_contact_email = localizations.form.email_error;
        if (values.consignee_contact_phoneNumber && !values.consignee_contact_phoneNumber.match(phonePattern))
            errors.consignee_contact_phoneNumber = localizations.form.phone_error;
        if (values.consignee_contact_email && !values.consignee_contact_email.match(emailPattern))
            errors.consignee_contact_email = localizations.form.email_error;

        if (values.payment_terms && values.payment_terms?.length > 0 && values.payment_terms[0].length > 25)
            errors.payment_terms = 'Max 25 characters';

        return errors;
    };
    return (
        <>
            <Formik
                initialValues={setInitialValues()}
                validationSchema={formValidationSchema}
                validate={validate}
                onSubmit={async (values) => {
                    setSubmitClicked(true);

                    const items = itemsPrepareSubmit(values, purchaseOrder?.id);
                    const { weight, unit: weightUnit } = calcTotalWeight(items);
                    const { volume, unit: volumeUnit } = calcTotalVolume(items);
                    const purchaseOrderObject = {
                        forwarderId: values.forwarderId,
                        vendorId: values.vendorId,
                        companyId: values.companyId,
                        companyName: values.companyName,
                        consigneeId: values.consignee_same_as_company ? null : values.consigneeId,
                        incoterms: values.incoterms,
                        originPickupAddress: values.origin_pickup_address,
                        destinationPickupAddress: values.destination_pickup_address,
                        destinationStation: values.destination,
                        originStation: values.origin,
                        destinationCountry: values.destination_country,
                        originCountry: values.origin_country,
                        originZipCode: values.origin_zipcode,
                        destinationZipCode: values.destination_zipcode,
                        requestedGoodsReady: values.requestedGoodsReady,
                        isAsap: values.isAsap,
                        weight,
                        weightUnit,
                        volume,
                        volumeUnit,
                        expectedReadyDate: values.expectedReadyDate,
                        items: items,
                        references: values.references,
                        note: values.note,
                        paymentTerms: values.payment_terms && values.payment_terms.length > 0 ? values?.payment_terms[0] : null,
                        parties: partiesPrepareSubmit(values, setInitialValues(), purchaseOrder?.id, businessPartners, allowedCompanies),
                        consigneeSameAsCompany: values.consignee_same_as_company
                    } as purchaseOrder;

                    if ([ePurchaseOrderFormMode.NEW, ePurchaseOrderFormMode.DUPLICATE].includes(mode)) {
                        await createPurchaseOrder(purchaseOrderObject).then((response: string) => {
                            if (response) {
                                setCurrentPurchaseOrderId(response);
                                setShowGoBackModal(true);
                            }
                        });
                    } else if (purchaseOrder) {
                        await updatePurchaseOrder(purchaseOrder.id, purchaseOrderObject).then((response) => {
                            if (response) history.push(PagesRoutes.PurchaseOrders + '/' + currentPurchaseOrderId);
                        });
                    }
                }}
            >
                {({ values, errors, setFieldValue, isSubmitting, isValidating }) => {
                    if (isSubmitting && !isValidating) sectionErrorIndexes = sectionErrors(errors);
                    return (
                        <>
                            {isSubmitting && !isValidating ? (
                                <LoaderOverlay>
                                    <Loader></Loader>
                                </LoaderOverlay>
                            ) : (
                                renderErrorModal()
                            )}
                            {showGoBackModal && currentPurchaseOrderId && renderGoBackModal()}
                            <Form>
                                <Container>
                                    <FormHeader>
                                        <BackButton
                                            style={{}}
                                            toPath={
                                                currentPurchaseOrderId
                                                    ? PagesRoutes.PurchaseOrders + '/' + currentPurchaseOrderId
                                                    : PagesRoutes.PurchaseOrders
                                            }
                                            text="Back to purchaseOrders page"
                                        />
                                        <NewPurchaseOrderTitle>
                                            <IdLabel>New PO:</IdLabel>
                                            <PurchaseOrderId>
                                                {mode === ePurchaseOrderFormMode.NEW ? '#00000000' : purchaseOrder?.CargoZoneNumber}
                                            </PurchaseOrderId>
                                            <MandaotoryFields>* Mandatory Fields</MandaotoryFields>
                                        </NewPurchaseOrderTitle>
                                    </FormHeader>
                                    <FormSection
                                        onSectionNumberClick={onSectionNumberClick}
                                        onNextClick={onNextClick}
                                        onPreviousClick={onPreviousClick}
                                        isLast={false}
                                        isActive={sectionErrorIndexes.includes(1) || activeFormSection === 1}
                                        name="General"
                                        number={1}
                                    >
                                        <General
                                            values={values}
                                            setFieldValue={setFieldValue}
                                            businessPartnerSuppliers={businessPartnerSuppliers}
                                            businessPartnerSuppliersManufacturers={businessPartnerSuppliersManufacturers}
                                            businessPartnerConsignees={businessPartnerConsignees}
                                            businessPartnerForwarders={businessPartnerForwarders}
                                            businessPartnerManufacturers={businessPartnerManufacturers}
                                            businessPartnerWarehouses={businessPartnerWarehouses}
                                            localizations={localizations}
                                            allowedTypes={allowedTypes}
                                            allowedCompanies={allowedCompanies}
                                            allowedCompaniesDropDownOptions={allowedCompaniesDropDownOptions}
                                            mode={mode}
                                        />
                                    </FormSection>
                                    <FormSection
                                        onSectionNumberClick={onSectionNumberClick}
                                        onNextClick={onNextClick}
                                        onPreviousClick={onPreviousClick}
                                        isLast={false}
                                        isActive={sectionErrorIndexes.includes(2) || activeFormSection === 2}
                                        name="Geography"
                                        number={2}
                                    >
                                        <Geography
                                            values={values}
                                            setFieldValue={setFieldValue}
                                            fetchShipmentsByFilter={fetchShipmentsByFilter}
                                            businessPartnerSuppliers={businessPartnerSuppliers}
                                            businessPartnerConsignees={businessPartnerConsignees}
                                            localizations={localizations}
                                            usersInSameCompany={usersInSameCompany}
                                            allowedCompanies={allowedCompanies}
                                        />
                                    </FormSection>
                                    <FormSection
                                        onSectionNumberClick={onSectionNumberClick}
                                        onNextClick={onNextClick}
                                        onPreviousClick={onPreviousClick}
                                        isLast={true}
                                        isActive={sectionErrorIndexes.includes(3) || activeFormSection === 3}
                                        name="Items"
                                        number={3}
                                    >
                                        <Items values={values} setFieldValue={setFieldValue} filteredShipments={filteredShipments} />
                                    </FormSection>
                                </Container>
                                <Buttons>
                                    <Button buttonType="filled" width="100px" height="30px" type="submit">
                                        Save
                                    </Button>
                                    <Button width="100px" height="30px" type="button" onClick={onCancelClick}>
                                        Cancel
                                    </Button>
                                </Buttons>
                            </Form>
                        </>
                    );
                }}
            </Formik>
            <RouteLeavingGuard
                title={localizations.form.route_guard}
                when={!submitClicked}
                navigate={(path) => history.push(path)}
                shouldBlockNavigation={(location) => {
                    return true;
                }}
            />
        </>
    );
};

function sectionErrors(errors: any) {
    const sections: { [key: number]: Array<string> } = {
        1: ['mode', 'subMode', 'vendorId', 'consigneeId', 'incoterms'],
        2: ['origin_country', 'origin_zipcode', 'destination_country', 'destination_zipcode'],
        3: ['items']
    };
    const indexes = [];
    for (const index of [1, 2, 3]) {
        for (const item of sections[index]) {
            if (Object.keys(errors).includes(item)) indexes.push(index);
        }
    }
    return indexes;
}

const Buttons = styled.div`
    width: 100%;
    border-top: 1px solid #e0e0e0;
    margin-top: -15px;
    padding-top: 25px;
    padding-bottom: 25px;
    padding-left: 70px;
    display: flex;

    button {
        margin-right: 20px;
    }
`;

const LoaderOverlay = styled.div`
    position: absolute;
    width: inherit;
    z-index: 1001;
    height: 100%;
    background: ${theme.colors.overlayBG};
`;
export const FieldContainer = styled.div`
    margin-bottom: 12px;
`;

const Label = styled.label`
    color: ${(props) => props.theme.colors.primaryBlue} !important;
`;

const MandatoryIndication = styled.span`
    color: red;
    margin-right: 7px;
`;

const NewPurchaseOrderTitle = styled.div`
    display: flex;
    align-content: flex-start;
    flex-direction: row;
    align-items: flex-start;
    position: relative;
    left: 0;
    margin-top: 20px;
`;

const IdLabel = styled.div`
    color: ${(props) => props.theme.colors.pageTitle};
    font-size: 18px;
    font-weight: bold;
`;

const MandaotoryFields = styled.div`
    font-style: italic;
    line-height: 30px;
    font-size: 11px;
    color: red;
`;

const PurchaseOrderId = styled.div`
    color: ${(props) => props.theme.colors.grey};
    margin-left: 5px;
    margin-right: 30px;
    line-height: 30px;
`;

const FormHeader = styled.div`
    flex-direction: row;
    justify-content: space-between;

    & a {
        position: relative;
        left: -30px;
    }
`;

const Container = styled.div`
    position: relative;
    width: 100%;
    padding: 25px;
    padding-left: 40px;
    padding-right: 40px;
`;

const LabelContainer = styled.div`
    display: flex;
    margin-bottom: 5px;

    & img {
        margin-top: -2px;
        margin-left: 5px;
    }
`;
export const Flex = styled.div`
    display: flex;
`;
export default SinglePurchaseOrderForm;
