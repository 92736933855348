import React from 'react';
import { marketplaceAsin } from '../../../../models/entities/product/product';
import AsinView from '../../../pages/products/components/AsinsView/AsinView';
import MaterialPopover from '../../Popover/MaterialPopover';
import styled from 'styled-components';

type Props = {
    marketplaceAsin?: marketplaceAsin[];
};

const ProductMarketplaceAsin = ({ marketplaceAsin }: Props) => {
    if (!marketplaceAsin || !Array.isArray(marketplaceAsin) || !marketplaceAsin?.length) return <div>-</div>;

    return (
        <Container onClick={(e) => e.stopPropagation()} clickable={marketplaceAsin.length > 1}>
            {marketplaceAsin.length === 1 ? (
                <AsinView asin={marketplaceAsin} singleAsinPaddingBottom={0} />
            ) : (
                <MaterialPopover
                    anchor={
                        <AnchorWrapper>
                            <AsinView asin={[marketplaceAsin[0]]} singleAsinPaddingBottom={0} />
                            <MoreAsins>(+{marketplaceAsin.length})</MoreAsins>
                        </AnchorWrapper>
                    }
                    toggleOnClick
                >
                    <AsinView asin={marketplaceAsin} />
                </MaterialPopover>
            )}
        </Container>
    );
};

const Container = styled.div<{ clickable: boolean }>``;
const AnchorWrapper = styled.div`
    display: flex;
    align-items: center;
`;

const MoreAsins = styled.span`
    display: flex;
    align-items: center;
    color: ${(props) => props.theme.colors.link};
    margin-left: 5px;
`;

export default ProductMarketplaceAsin;
