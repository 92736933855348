import React from 'react';
import { document } from '../../../../models/entities/common_subentities/document';
import { styled } from '../../../shared/Theme/theme';
import missingDoc from '../../../../static/icons/missing_doc.svg';
import Button from '../../../shared/SmallComponents/Button';
import CustomModal, { ModalTriggerProps } from '../../../shared/Modal/Modal';
import attachment from '../../../../static/icons/attachment.svg';
import { formMode } from './DocumentForm/DocumentFormConnected';
import { settings } from '../../../../models/entities/shipment/shipment';
import claimsType from '../../../../models/entities/claimsType';

type Props<settingsType> = {
    requiredDocs?: Array<string>;
    documents?: Array<document>;
    settings?: settingsType;
    localization: any;
    DocumentFormConnected: any;
    userDocumentsClaims?: claimsType;
    dismissRequiredDoc?: (shipmentId: string, docType: string) => string;
    entityId?: string;
    additionalData?: { [key: string]: any };
};

function MissingDocs<settingsType extends settings>({
    requiredDocs,
    documents,
    settings,
    localization,
    DocumentFormConnected,
    dismissRequiredDoc,
    entityId,
    userDocumentsClaims,
    additionalData
}: Props<settingsType>) {
    if (!requiredDocs || requiredDocs.length === 0) return null;
    const missingDocs = requiredDocs.filter((type) => {
        if (!documents?.find((doc) => doc.type && doc.type.includes(type))) return type;
        else return null;
    });
    if (!missingDocs || missingDocs.length === 0) return null;
    return (
        <Container>
            {missingDocs.map((item, index) => {
                return (
                    <Row key={index}>
                        <div>
                            <img src={missingDoc} alt="missing doc" />
                        </div>
                        <DocumentType>{settings?.documentTypes?.find((d) => d.code === item)?.name || item}</DocumentType>
                        <MissingText>{localization.documents.missing_docs.missing}...</MissingText>
                        {(!userDocumentsClaims || userDocumentsClaims?.add_document) && (
                            <ButtonsContainer>
                                <CustomModal
                                    title={{ icon: attachment, text: localization.documents.modal.add_title }}
                                    Trigger={(props: ModalTriggerProps) => (
                                        <Button onClick={props.onClick} buttonType="filled" width="auto" padding="0px 12px">
                                            {localization.documents.modal.submit_btn}
                                        </Button>
                                    )}
                                    Content={(
                                        setVisible?: React.Dispatch<React.SetStateAction<boolean>>,
                                        setConfirm?: React.Dispatch<React.SetStateAction<boolean>>
                                    ) => {
                                        return (
                                            <DocumentFormConnected
                                                mode={formMode.NEW}
                                                defaultValues={{ type: item } as document}
                                                openedFromModal={setVisible ? { setVisible } : undefined}
                                                confirmation={setConfirm ? { setConfirm } : undefined}
                                                additionalData={additionalData}
                                            />
                                        );
                                    }}
                                    width="393px"
                                />
                                {dismissRequiredDoc && entityId && (
                                    <Button onClick={() => dismissRequiredDoc(entityId, item)} width="auto" padding="0px 12px">
                                        {localization.documents.missing_docs.dismiss_btn}
                                    </Button>
                                )}
                            </ButtonsContainer>
                        )}
                    </Row>
                );
            })}
        </Container>
    );
}

const Container = styled.div`
    border: 1px solid #ed7c7c;
    padding: 20px 28px;
    margin: 25px 0;
    border-radius: 15px;
`;
const Row = styled.div`
    display: flex;
    padding: 10px;
    align-items: center;
    > * {
        min-width: 40px;
    }
`;
const MissingText = styled.span`
    margin-left: 20px;
    min-width: 300px;
`;
const DocumentType = styled.span`
    width: 200px;
`;
const ButtonsContainer = styled.div`
    display: flex;
    flex: 1;
    justify-content: space-between;
`;
export default MissingDocs;
