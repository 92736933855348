import { orderStates } from '../../../models/entities/order';
import { orderSettingsByLanguage } from '../../../models/entities/order/orderSettings/orderSettings';

const settingsByLanguage: orderSettingsByLanguage = {
    en: {
        eventTypes: [
            { code: 'READY_TO_SHIP', name: 'Ready To Ship', description: 'Ready to ship description' },
            { code: 'PICKED_UP', name: 'Picked Up', description: 'Picked up description' },
            { code: 'IN_TRANSIT', name: 'In Transit', description: 'In transit description' },
            { code: 'DELIVERED', name: 'Delivered', description: 'Delivered description' }
        ],
        state: Object.values(orderStates)
    }
};

export default settingsByLanguage;
