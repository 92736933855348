import React, { useEffect, useState } from 'react';
import { styled } from '../../../shared/Theme/theme';
import Button from '../../../shared/SmallComponents/Button';
import { connect } from 'react-redux';
import { localizationSelectors } from '../../../../state/ducks/localization/selectors';
import { RootState } from '../../../../state/store/store';
import { DropDown, TextInput } from '../../../shared/inputs/base';
import Loader from '../../../shared/SmallComponents/Loader';
import { ColumnType } from '../../../shared/Grid/types/Column';
import { drawers } from '../../../../state/ducks/header/header';
import { internalFlowInventoryLogsGridDataType, inventoryTotalView } from '../../../../models/entities/product/inventoryLog';
import { filterFieldsType } from '../../../../state/ducks/inventory/inventory';
import { internalFlowInventoryLogsSelectors } from '../../../../state/ducks/internalFlowInventoryLogs/selectors';
import { paginationType } from '../../../../state/ducks/inventory/inventory';
import { IconButton, Tooltip } from '@material-ui/core';
import { eInternalFlowGridMode, gridModeType } from '../../../../state/ducks/internalFlowInventoryLogs/internalFlowInventoryLogs';

type Props = {
    internalFlowInventoryLogsGridData: internalFlowInventoryLogsGridDataType[];
    gridColumns: Array<ColumnType<internalFlowInventoryLogsGridDataType>>;
    currentGridFilter: string | null;
    localization: any;
    activeInternalFlowInventoryLogsGridDataCount: number;
    isLoading?: boolean;
    pagination: paginationType;
    filteredInventoryCount: number;
    gridMode: gridModeType;
    setGridFilter: (text: string) => void;
    fetchInternalFlowInventoryLogs: () => void;
    setDrawerOpen: (open: drawers, params?: any) => void;
    setCurrentPage: (page: number) => void;
    setRowsPerPage: (rowsPerPage: number) => void;
    setGridMode: (gridMode: gridModeType) => void;
};
const InternalFlowInventoryLogsHeader = ({
    internalFlowInventoryLogsGridData,
    currentGridFilter,
    localization,
    activeInternalFlowInventoryLogsGridDataCount,
    isLoading,
    filteredInventoryCount,
    pagination,
    gridMode,
    setGridFilter,
    fetchInternalFlowInventoryLogs,
    setDrawerOpen,
    setCurrentPage,
    setRowsPerPage,
    setGridMode,
    gridColumns
}: Props) => {
    const [textFilterValue, setTextFilterValue] = useState(currentGridFilter);
    const [isTypingTextFilter, setIsTypingTextFilter] = useState(false);

    const { currentPage, rowsPerPage } = pagination;
    useEffect(() => {
        setIsTypingTextFilter(true);
        const timeout = setTimeout(() => {
            setGridFilter(textFilterValue || '');
            setIsTypingTextFilter(false);
        }, 500);

        return () => clearTimeout(timeout);
    }, [textFilterValue]);

    const inventoryFrom = currentPage * rowsPerPage + 1;
    const inventoryTo = (currentPage + 1) * rowsPerPage;
    return (
        <PageHeader>
            <Title>{localization.title}</Title>
            <div style={{ flex: 1 }}>
                <DropDown
                    placeHolder={'Type'}
                    style={{ marginLeft: 20, width: '200px' }}
                    options={
                        Object.values(eInternalFlowGridMode).map((type) => ({
                            text: type,
                            value: type
                        })) || []
                    }
                    value={gridMode}
                    onChange={async (value: gridModeType) => {
                        setGridMode(value);
                    }}
                />
            </div>
            <HeaderRightSide>
                <Button
                    width="auto"
                    height="auto"
                    buttonType="hollow"
                    padding="0 8px"
                    margin="4px"
                    borderRadiusMobile="50%"
                    minHeightMobile="32px"
                    paddingMobile="0 4px"
                    widthMobile="auto"
                    onClick={() => {
                        fetchInternalFlowInventoryLogs();
                    }}
                >
                    <Tooltip title={localization.refresh_btn} aria-label="refresh">
                        <span className="material-icons">refresh</span>
                    </Tooltip>
                </Button>
                <TextInput
                    style={{
                        height: '30px',
                        margin: '4px',
                        width: 'auto'
                    }}
                    allowClear={true}
                    onChange={(value: string) => setTextFilterValue(value)}
                    placeHolder={localization.filterPlaceHolder}
                    value={textFilterValue || ''}
                    suffix={isTypingTextFilter && <Loader wrapperWidth="auto" showText={false} marginTop="0" width="15px" />}
                />
                <NewButtonWrapper>
                    <Button
                        width="auto"
                        height="auto"
                        buttonType="filled"
                        padding="0 15px"
                        margin="4px"
                        borderRadiusMobile="50%"
                        minHeightMobile="32px"
                        paddingMobile="0 4px"
                        widthMobile="auto"
                        onClick={() => setDrawerOpen('INVENTORY', {})}
                    >
                        <span className="material-icons">add</span>
                    </Button>
                </NewButtonWrapper>
                {!(isLoading && (activeInternalFlowInventoryLogsGridDataCount <= 1 || !gridColumns.length)) && (
                    <NavButtonsContainer>
                        {filteredInventoryCount > 0
                            ? `${inventoryFrom} – ${filteredInventoryCount <= inventoryTo ? filteredInventoryCount : inventoryTo}${
                                  internalFlowInventoryLogsGridData.length <= filteredInventoryCount ? ' of ' + filteredInventoryCount : ''
                              }`
                            : ''}
                        <IconButton
                            onClick={() => setCurrentPage(currentPage - 1)}
                            disabled={isLoading || inventoryFrom <= 1}
                            className="material-icons nav-btn"
                        >
                            navigate_before
                        </IconButton>
                        <IconButton
                            onClick={() => setCurrentPage(currentPage + 1)}
                            disabled={isLoading || filteredInventoryCount <= inventoryTo}
                            className="material-icons nav-btn"
                        >
                            navigate_next
                        </IconButton>
                    </NavButtonsContainer>
                )}
            </HeaderRightSide>
        </PageHeader>
    );
};

const PageHeader = styled.div`
    display: flex;
    padding: 20px 12px 0 12px;
    align-items: center;

    @media screen and (max-width: 768px) {
        justify-content: center;
        padding: 20px 12px 8px 8px;
    }

    position: sticky;
    top: 0px;
    left: 0px;
    z-index: 1000;
    background-color: #fff;
`;

const Title = styled.div`
    display: flex;
    color: ${(props) => props.theme.colors.pageTitle};
    font-size: ${(props) => props.theme.fontSizes.pageTitle};
    font-weight: bold;

    @media screen and (max-width: 768px) {
        display: none;
    }
`;

const HeaderRightSide = styled.div`
    display: flex;
    flex-direction: columm;
    width: 100%;
    max-width: fit-content;
    align-items: center;

    @media screen and (max-width: 768px) {
        height: 40px;
        justify-content: center;
    }

    .add-icon {
        display: none;
        @media screen and (max-width: 768px) {
            display: initial;
        }
    }

    .new-label {
        @media screen and (max-width: 768px) {
            display: none;
        }
    }
`;
const NewButtonWrapper = styled.div`
    @media screen and (max-width: 768px) {
        width: auto;
    }
    .add-icon {
        display: none;
        @media screen and (max-width: 768px) {
            display: initial;
        }
    }

    .new-label {
        @media screen and (max-width: 768px) {
            display: none;
        }
    }
`;
const NavButtonsContainer = styled.div`
    display: flex;
    align-items: center;
    margin: 0 15px;
    color: ${(props) => props.theme.colors.primaryBlue};
    font-weight: bold;
    .nav-btn {
        font-size: 1.4rem;
        color: ${(props) => props.theme.colors.primaryBlue};
    }
`;

const mapStateToProps = (state: RootState) => ({
    currentGridFilter: internalFlowInventoryLogsSelectors.gridFilter(state),
    gridColumns: internalFlowInventoryLogsSelectors.gridColumns(state),
    internalFlowInventoryLogsGridData: internalFlowInventoryLogsSelectors.gridData(state),
    localization: localizationSelectors.internalFlowInventory(state),
    activeInternalFlowInventoryLogsGridDataCount: internalFlowInventoryLogsSelectors.activeInternalFlowInventoryLogsGridDataCount(state),
    filteredInventoryCount: internalFlowInventoryLogsSelectors.filteredInventoryCount(state),
    pagination: internalFlowInventoryLogsSelectors.pagination(state),
    gridMode: internalFlowInventoryLogsSelectors.gridMode(state)
});

const mapDispatchToProps = (dispatch: any) => ({
    fetchInternalFlowInventoryLogs: () => dispatch.internalFlowInventoryLogs.fetchInternalFlowInventoryLogs(),
    setGridFilter: (text: string) => dispatch.internalFlowInventoryLogs.setGridFilter(text),
    setDrawerOpen: (open: drawers, params?: any) => dispatch.header.setDrawerOpenParams({ open, params }),
    setCurrentPage: (page: number) => dispatch.internalFlowInventoryLogs.setCurrentPage(page),
    setRowsPerPage: (rowsPerPage: number) => dispatch.internalFlowInventoryLogs.setRowsPerPage(rowsPerPage),
    setGridMode: (gridMode: gridModeType) => dispatch.internalFlowInventoryLogs.setGridMode(gridMode)
});

export default connect(mapStateToProps, mapDispatchToProps)(InternalFlowInventoryLogsHeader);
