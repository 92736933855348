import React from 'react';
import { BrowserRouter as Router } from 'react-router-dom';
import Routes from './Routes';
import SmartLook from 'smartlook-client';

class App extends React.Component {
    componentDidMount() {
        const urlParams = new URLSearchParams(window.location.search);
        const isPrintMode = urlParams.getAll('printMode').length > 0;
        if (process.env.REACT_APP_SMARTLOOK_ID && !isPrintMode) SmartLook.init(process.env.REACT_APP_SMARTLOOK_ID);
    }
    render() {
        return (
            <>
                <Router>
                    <Routes />
                </Router>
            </>
        );
    }
}

export default App;
