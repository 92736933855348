import React, { useState } from 'react';
import { purchaseOrder, settings } from '../../../models/entities/purchaseOrder/purchaseOrder';
import { styled } from '../Theme/theme';
import moment from 'moment';
import { drawers } from '../../../state/ducks/header/header';
import { connect } from 'react-redux';
import { RootState } from '../../../state/store/store';
import { ColumnType } from '../Grid/types/Column';
import PurchaseOrderMenu from '../Grid/specialColumns/PurchaseOrderMenu';
import { TriggerProps } from '../Menu/Menu';
import { purchaseOrdersSelectors } from '../../../state/ducks/purchaseOrders/selectors';
import { DraggableProvided } from 'react-beautiful-dnd';
import { useHistory } from 'react-router-dom';
import { PagesRoutes } from '../../../routing/PagesRoutes';
import AnimateHeight from 'react-animate-height';
import ShipmentRoute from './ShipmentRoute';

type Props = {
    purchaseOrder: purchaseOrder;
    gridColumns: Array<ColumnType<purchaseOrder>>;
    hasMenu?: boolean;
    isDragging?: boolean;
    provided?: DraggableProvided;
    refOpenPurchaseOrderPane?: boolean;
    setDrawerOpen?: (open: drawers, params?: any) => void;
    onClickRef?: () => void;
    isLastVisited?: boolean;
    opened?: boolean;
    settings?: settings;
};
const PurchaseOrderItem = ({
    purchaseOrder,
    setDrawerOpen,
    gridColumns,
    isDragging,
    refOpenPurchaseOrderPane,
    hasMenu,
    provided,
    onClickRef,
    isLastVisited,
    opened,
    settings
}: Props) => {
    const [isDetailsHidden, setIsDetailsHidden] = useState(!opened);
    const history = useHistory();

    const renderPurchaseOrderDetails = (skip?: number, take?: number) => {
        const fieldsToExclude = ['CargoZoneNumber', 'originStation', 'destinationStation'];

        return gridColumns
            .filter((column) => {
                const key = column.field;
                const value = purchaseOrder[key];
                return column.isDisplayed !== false && value != null && value.toString().trim() != '' && !fieldsToExclude.includes(key);
            })
            .slice(skip, take)
            .map((column, i) => {
                let detailValue = purchaseOrder[column.field];

                switch (column?.columnType) {
                    case 'date':
                        detailValue = moment(detailValue?.toString()).format('DD/MM/YY');
                        break;
                    case 'date-time':
                        detailValue = moment(detailValue?.toString()).format('DD/MM/YYYY HH:mm');
                        break;
                }
                const bold = ['consigneeName'].includes(column.field);
                return (
                    <div className="detail" key={i}>
                        <span className="detail-header">{column?.title}: </span>
                        <span className={`detail-value ${bold && 'bold'}`}>{detailValue}</span>
                    </div>
                );
            });
    };

    const itemColor = (() => {
        return settings?.state?.find((item) => item.code === purchaseOrder.state)?.color || '#b2b2b2';
    })();
    return (
        <ItemContainer
            ref={provided?.innerRef}
            isDragging={isDragging}
            {...provided?.draggableProps}
            {...provided?.dragHandleProps}
            style={{
                ...provided?.draggableProps.style
            }}
            isLastVisited={isLastVisited}
        >
            <StateColor color={itemColor} />
            <ItemContent>
                <PurchaseOrderItemTitle>
                    <span
                        className="purchaseOrder-number"
                        onClick={() => {
                            if (refOpenPurchaseOrderPane)
                                setDrawerOpen && setDrawerOpen(`SHIPMENT${purchaseOrder.id}`, { purchaseOrderId: purchaseOrder.id });
                            else history.push(PagesRoutes.PurchaseOrders + '/' + purchaseOrder.id);

                            onClickRef && onClickRef();
                        }}
                    >
                        #{purchaseOrder.CargoZoneNumber}
                    </span>
                </PurchaseOrderItemTitle>
                <PurchaseOrderRouteContainer>
                    <ShipmentRoute entity={purchaseOrder} flagSize="20px" fontSize="14px" fontWeight="300px" />
                </PurchaseOrderRouteContainer>
                <Details>
                    {renderPurchaseOrderDetails(0, 2)}
                    {opened ? (
                        renderPurchaseOrderDetails(2)
                    ) : (
                        <AnimateHeight id="example-panel" duration={500} height={!isDetailsHidden ? 'auto' : 0} animateOpacity={true}>
                            {renderPurchaseOrderDetails(2)}
                        </AnimateHeight>
                    )}
                    {/* <HiddenDetails isHidden={isDetailsHidden}>{renderPurchaseOrderDetails(2)}</HiddenDetails> */}
                </Details>
            </ItemContent>
            <div className="top-hidden-container" onClick={() => setIsDetailsHidden(!isDetailsHidden)}>
                {!opened && <span className="material-icons details-toggle">{isDetailsHidden ? 'keyboard_arrow_down' : 'keyboard_arrow_up'}</span>}
            </div>

            {hasMenu && (
                <div className="top-hidden-container menu-container">
                    <span className="menu-toggle">
                        <PurchaseOrderMenu
                            purchaseOrder={purchaseOrder}
                            entityId={purchaseOrder.id}
                            entityData={{ CargoZoneNumber: purchaseOrder.CargoZoneNumber }}
                            trigger={(props: TriggerProps) => (
                                <span className="material-icons " style={{ cursor: 'pointer' }} onClick={(e) => props.onClick(e)}>
                                    more_vert
                                </span>
                            )}
                        />
                    </span>
                </div>
            )}
        </ItemContainer>
    );
};

interface StateColorProps {
    color: string;
}

const StateColor = styled.div<StateColorProps>`
    width: 3px;
    background-color: ${(props) => props.color};
`;

const ItemContent = styled.div`
    flex: 1;
    height: 100%;
    padding: 12px;
`;

const Details = styled.div`
    .detail {
        margin-top: 4px;
        .detail-header {
            color: #394372;
        }
        .detail-value {
            color: #757575;
        }
        .bold {
            font-weight: bold;
        }
    }
`;
interface HiddenDetailsProps {
    isHidden: boolean;
}
const HiddenDetails = styled.div<HiddenDetailsProps>`
    height: ${(props) => (props.isHidden ? '0px' : 'auto')};
    overflow: hidden;
`;

interface ItemContainerProps {
    isDragging?: boolean;
    isLastVisited?: boolean;
}
const ItemContainer = styled.div<ItemContainerProps>`
    user-select: none;
    margin: 0 0 8px 0;
    background-color: ${(props) => (props.isDragging ? '#f1f1f1' : '#fff')};
    display: flex;
    box-shadow: 0 0 10px rgb(228, 228, 228);
    border: 1px solid #e4e4e4;
    width: 100%;
    z-index: ${(props) => (props.isDragging ? '999 !important' : 'auto')};
    ${(props) => props.isLastVisited && 'box-shadow:rgb(0, 114, 255) 0px 0px 5px 1px;'}
    position: relative;

    :hover {
        .top-hidden-container {
            display: flex;
            align-items: center;
            justify-content: center;
        }
    }
    .menu-container {
        top: 0 !important;
        right: 0;
        width: auto !important;
        justify-content: flex-end !important;
        bottom: auto !important;
    }
    .top-hidden-container {
        display: none;
        position: absolute;
        bottom: 0;
        width: 100%;

        .details-toggle {
            border: 1px solid #d4d4d4;
            width: 30px;
            height: 15px;
            text-align: center;
            font-size: 14px;
            border-bottom: none;
            cursor: pointer;
            background-color: #f0f0f0;
        }
        .menu-toggle {
            margin: 5px;
            border: 1px solid #d4d4d4;
            text-align: center;
            cursor: pointer;
            background-color: #f0f0f0;

            .material-icons {
                font-size: 18px;
            }
        }
    }
`;

const PurchaseOrderItemTitle = styled.div`
    display: flex;
    align-items: center;
    color: #394372;
    font-size: 14px;
    white-space: nowrap;

    .purchaseOrder-icon {
        margin-right: 10px;
    }
    .purchaseOrder-number {
        cursor: pointer;
        transition: color 0.1s;

        &:hover {
            color: #008ac9;
        }
    }
    .purchaseOrder-alert-icon {
        margin-left: 10px;
    }
`;

const PurchaseOrderRouteContainer = styled.div`
    margin-top: 10px;
`;

const mapStateToProps = (state: RootState) => ({});

const mapDispatchToProps = (dispatch: any) => ({
    setDrawerOpen: (open: drawers, params?: any) => dispatch.header.setDrawerOpenParams({ open, params })
});

export default connect(mapStateToProps, mapDispatchToProps)(React.memo(PurchaseOrderItem));
