import { ModelConfig } from '@rematch/core';
export type loadingNotifyStateType = {
    visible: boolean;
    text: string;
};

export const loadingNotify: ModelConfig<loadingNotifyStateType> = {
    state: {
        visible: false,
        text: ''
    },
    reducers: {
        setLoadingState(currState: loadingNotifyStateType, newState: loadingNotifyStateType): loadingNotifyStateType {
            return { ...currState, ...newState };
        }
    },
    effects: (dispatch: any) => ({})
};
