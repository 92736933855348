import React from 'react';
import { GridCellParams, GridColDef, GridValueFormatterParams } from '@material-ui/data-grid';
import styled from 'styled-components';
import { Image } from 'antd';
import MaterialPopover from '../../../../../shared/Popover/MaterialPopover';
import { FormNumberInput, FormTextInput } from '../../../../../shared/inputs/form';
import { currencyFormatter } from '../../../../../shared/inputs/form/CurrencyInput/CurrencyInput';
import moment from 'moment';
import { poItemShipmentRef, purchaseOrderItem } from '../../../../../../models/entities/purchaseOrder/purchaseOrderItem';
import infoIcon from '../../../../../../static/icons/info.svg';
import EditIcon from '../../../../../../static/icons/edit.svg';
import { Link } from 'react-router-dom';
import { PagesRoutes } from '../../../../../../routing/PagesRoutes';
import { pdItemPoRef } from '../../../../../../models/entities/purchaseDemand/purchaseDemandItem';
import PoItemShipmentsRefsModal from '../../../PoItemShipmentsRefsModal/PoItemShipmentsRefsModal';
import { ModalTriggerProps } from '../../../../../shared/Modal/Modal';
import ShipmentCard from '../../../../../shared/ShipmentCard/ShipmentCard';
import { drawers } from '../../../../../../state/ducks/header/header';
import SingleValueFormModal from '../../../../../shared/SingleValueFormModal/SingleValueFormModal';
import { product } from '../../../../../../models/entities/product/product';
import { MultiLineCell } from '../../../../../shared/MuiDataGrid/MuiDataGrid';
import NumberInput from '../../../../../shared/inputs/base/NumberInput';
import CZTooltip from '../../../../../shared/CZTooltip/CZTooltip';
import { Checkbox } from 'antd';

export const PoItemsGridColDef = (
    items: Array<purchaseOrderItem>,
    localization: any,
    onUpdateItemQuantity?: (updatedItem: any) => Promise<void>,
    updatePurchaseOrderItem?: (updatedItem: purchaseOrderItem) => Promise<void>,
    onUpdateItemCost?: (updatedItem: any) => Promise<void>,
    onDeleteItem?: (itemIndex: number) => Promise<void>,
    setDrawerOpen?: (open: drawers, params?: any) => void,
    setFieldValue?: any,
    printMode?: boolean,
    updateProduct?: (id: string, product: product, deletedSuppliers?: string[]) => Promise<boolean>,
    itemsRelatedProducts?: Array<product>,
    hideItemsPrices?: boolean,
    isSupplierUpdates?: boolean
): GridColDef[] => [
    {
        field: 'productSku',
        headerName: localization.sku,
        flex: printMode ? undefined : 1,
        width: printMode ? 140 : undefined,
        renderCell: (params: GridCellParams) => {
            return (
                <GridCell
                    onClick={(e) => {
                        e.stopPropagation();
                        if (params.getValue(params.id, 'productId') && setDrawerOpen)
                            setDrawerOpen('PRODUCT', { productId: params.getValue(params.id, 'productId') });
                    }}
                >
                    <LinkButton>{params.value}</LinkButton>
                </GridCell>
            );
        }
    },
    {
        field: 'name',
        headerName: localization.product_name,
        renderCell: (params: GridCellParams) => <MultiLineCell>{params.value}</MultiLineCell>,
        flex: printMode ? undefined : 1,
        width: printMode ? 140 : undefined
    },
    {
        field: 'Img',
        headerName: localization.images,
        hide: itemsRelatedProducts?.every((p) => !p.latestProductImgUrl),
        renderCell: (params: GridCellParams) => {
            const relatedProductId = params.row.productId;

            if (!relatedProductId) return <GridCell>-</GridCell>;

            const relatedProductImgUrl = itemsRelatedProducts?.find((p) => p.id === relatedProductId)?.latestProductImgUrl;

            if (!relatedProductImgUrl) return <GridCell>-</GridCell>;

            const restImges = itemsRelatedProducts
                ?.find((p) => p.id === relatedProductId)
                ?.documents?.filter((d) => d.type.includes('PRODUCT_IMG') && !!d.imgUrl && d.imgUrl !== relatedProductImgUrl);

            return (
                <Image.PreviewGroup>
                    <ItemImgContainer
                        onClick={(e) => {
                            e.stopPropagation();
                        }}
                    >
                        <Image width={35} src={relatedProductImgUrl} />
                    </ItemImgContainer>
                    <div style={{ display: 'none' }}>
                        {restImges?.map((img, i) => (
                            <Image key={i} width={0} src={img.imgUrl} />
                        ))}
                    </div>
                </Image.PreviewGroup>
            );
        },
        width: 100,
        sortable: false
    },
    {
        hide: !items?.some((item: purchaseOrderItem) => item.attributes),
        field: 'attributes',
        headerName: localization.attributes,
        flex: printMode ? undefined : 1,
        width: printMode ? 140 : undefined,
        renderCell: (params: GridCellParams) => {
            const value = (params.value as { [key: string]: string }) || undefined;
            if (!value) return <GridCell>-</GridCell>;

            return (
                <div style={{ width: '100%' }}>
                    <MaterialPopover
                        anchor={
                            <MultiLineCell>
                                {Object.entries(value)
                                    .map(([key, name]) => `${key}: ${name}`)
                                    .join(', ')}
                            </MultiLineCell>
                        }
                    >
                        <GridCell>
                            {Object.entries(value).map(([key, name], i) => (
                                <Detail key={`${params.row.id}-att-option-${i}`}>
                                    <DetailName>{key}:</DetailName>
                                    <DetailValue>{name}</DetailValue>
                                </Detail>
                            ))}
                        </GridCell>
                    </MaterialPopover>
                </div>
            );
        }
    },
    {
        field: 'quantity',
        headerName: localization.quantity,
        width: 110,
        renderCell: (params: GridCellParams) => {
            const itemIndex = items?.findIndex((item) => item.id === params.id);
            const minOrderQuantity = Number(params.getValue(params.id, 'minOrderQuantity')) || 1;
            const quantity = Number(params.row.quantity) || minOrderQuantity;
            const isMinOrderValueExceeded = quantity < minOrderQuantity;

            if (itemIndex === -1) return <GridCell>-</GridCell>;

            if (!onUpdateItemQuantity) return <GridCell>{params.value}</GridCell>;
            return (
                <CZTooltip text={`Minimum order value is ${minOrderQuantity}`} hidden={!isMinOrderValueExceeded}>
                    <div onClick={(e) => e.stopPropagation()}>
                        <NumberInput
                            onChange={async (value: any) => {
                                if (setFieldValue) setFieldValue(`items[${itemIndex}][quantity]`, value || minOrderQuantity);
                                if (onUpdateItemQuantity) await onUpdateItemQuantity({ ...items?.[itemIndex], quantity: value || minOrderQuantity });
                            }}
                            value={Number(items[itemIndex]['quantity']) || minOrderQuantity}
                            placeHolder={localization.quantity}
                            style={{ width: '90px', color: isMinOrderValueExceeded ? 'red' : 'inherit' }}
                            min={1}
                        />
                    </div>
                </CZTooltip>
            );
        }
    },
    {
        field: 'unitsPerMasterCarton',
        headerName: localization.cartons,
        width: 110,
        renderCell: (params: GridCellParams) => {
            const itemIndex = items?.findIndex((item: purchaseOrderItem) => item.id === params.id);

            const unitsPerMasterCarton = Number(params.row.unitsPerMasterCarton);
            const quantity = Number(params.row.quantity);

            if (itemIndex === -1 || !unitsPerMasterCarton) return <GridCell>-</GridCell>;

            const cartonsNumber = Math.ceil(quantity / unitsPerMasterCarton);

            return <div>{cartonsNumber}</div>;
        }
    },
    {
        hide: printMode && hideItemsPrices,
        field: 'price',
        flex: printMode ? undefined : 1,
        width: printMode ? 140 : undefined,
        headerName: localization.price,
        valueFormatter: (params: GridValueFormatterParams) =>
            params.value ? currencyFormatter(params.getValue(params.id, 'currency'))(params.value) : '-',
        renderCell: (params: GridCellParams) => {
            const { value } = params;

            const price = value || 0;

            const currencyValue = params.getValue(params.id, 'currency');
            const parsedValue = currencyFormatter(currencyValue)(price);

            const itemId = params.getValue(params.id, 'id') as string;
            const itemIndex = items?.findIndex((item) => item.id === params.id);

            if (itemIndex === -1) return <GridCell>-</GridCell>;

            return (
                <CostCell
                    onKeyDown={(e) => e.stopPropagation()}
                    onClick={(e) => {
                        e.stopPropagation();
                    }}
                >
                    {parsedValue}
                    {itemId && !!onUpdateItemCost && (
                        <SingleValueFormModal
                            title={localization.price}
                            initialValue={price}
                            initialCurrencyValue={(currencyValue || 'USD') as string}
                            valueType="currency"
                            checkbox={{ text: localization.update_product_cost }}
                            onSubmit={async (value: any, checkboxValue?: boolean, currencyValue?: string) => {
                                setFieldValue?.(`items[${itemIndex}][price]`, value);
                                setFieldValue?.(`items[${itemIndex}][currency]`, currencyValue);
                                await onUpdateItemCost({ ...items?.[itemIndex], price: value, currency: currencyValue });
                                if (!!checkboxValue) {
                                    const { productId, productSku, name } = items?.[itemIndex] || {};
                                    if (productId && productSku && name && updateProduct && currencyValue)
                                        updateProduct(productId, { name, sku: productSku, cost: value, costCurrency: currencyValue } as product);
                                }
                            }}
                            Trigger={(props: ModalTriggerProps) => {
                                return (
                                    <span>
                                        <img
                                            src={EditIcon}
                                            onMouseDown={(e) => {
                                                props.onClick();
                                            }}
                                            className="edit-icon"
                                            alt={localization.edit}
                                        />
                                    </span>
                                );
                            }}
                        />
                    )}
                </CostCell>
            );
        }
    },
    {
        hide: printMode && hideItemsPrices,
        field: 'currency',
        flex: printMode ? undefined : 1,
        width: printMode ? 140 : undefined,
        headerName: localization.total_cost,
        valueFormatter: (params: GridValueFormatterParams) =>
            params.getValue(params.id, 'price')
                ? currencyFormatter(params.value)(
                      (Number(params.getValue(params.id, 'price')) || 0) * (Number(params.getValue(params.id, 'quantity')) || 1) || 0
                  )
                : '-'
    },
    {
        field: 'readyDate',
        flex: printMode ? undefined : 1,
        width: printMode ? 140 : undefined,
        headerName: localization.ready_date,
        valueFormatter: (params: GridValueFormatterParams) =>
            params.value && moment(params.value as string).isValid() ? moment(params.value as string).format('DD/MM/YY') : '-'
    },
    {
        hide: !!isSupplierUpdates || !items?.some((item: purchaseOrderItem) => item?.purchaseDemandId),
        field: 'purchaseDemandId',
        headerName: localization.pd_ref,
        flex: printMode ? undefined : 1,
        width: printMode ? 140 : undefined,
        renderCell: (params: GridCellParams) => {
            const pdId = params.value as string | undefined;
            const pdCzNumber = params.getValue(params.id, 'purchaseDemandCzNumber') as string | undefined;

            if (!pdId || !pdCzNumber) return <GridCell>-</GridCell>;

            return <Link to={{ pathname: PagesRoutes.PurchaseDemands + '/' + pdId }}>{pdCzNumber}</Link>;
        }
    },
    {
        hide: !!isSupplierUpdates || !items?.some((item: purchaseOrderItem) => item.shipmentsRefs?.length),
        field: 'shipmentsRefs',
        headerName: localization.shipments_refs,
        flex: printMode ? undefined : 1,
        width: printMode ? 140 : undefined,
        renderCell: (params: GridCellParams) => {
            const value: poItemShipmentRef[] = params.value as poItemShipmentRef[];

            if (!value?.length) return <GridCell>-</GridCell>;

            if (value.length === 1) {
                return (
                    <ShipmentCard shipmentId={value[0].id} popoverLeft>
                        <Link to={{ pathname: PagesRoutes.Shipments + '/' + value[0].id }}>{value[0].czNumber}</Link>
                    </ShipmentCard>
                );
            }

            return (
                <GridCell
                    onClick={(e) => {
                        e.stopPropagation();
                    }}
                >
                    <PoItemShipmentsRefsModal
                        localization={localization}
                        poItemShipmentRef={params.value as poItemShipmentRef[]}
                        Trigger={(props: ModalTriggerProps) => (
                            <LinkButton
                                onMouseDown={(e) => {
                                    props.onClick();
                                }}
                            >
                                {value.length + ' ' + localization.shipments}
                            </LinkButton>
                        )}
                    />
                </GridCell>
            );
        }
    },
    {
        hide: !!isSupplierUpdates || !items?.some((item: purchaseOrderItem) => item.shipmentsRefs?.length),
        field: 'openedQuantity',
        headerName: localization.remaining_quantity,
        flex: printMode ? undefined : 1,
        width: printMode ? 140 : undefined,
        valueFormatter: (params: GridValueFormatterParams) =>
            (Number(params.getValue(params.id, 'quantity')) || 0) - (Number(params.value) || 0) || '0'
    },
    {
        hide: !!isSupplierUpdates,
        field: 'qcApproved',
        headerName: localization.qc_approved,
        width: 50,
        hideSortIcons: true,
        renderCell: (params: GridCellParams) => {
            const itemIndex = items?.findIndex((item) => item.id === params.id);
            const { value } = params;

            if (itemIndex === -1) return <GridCell>-</GridCell>;

            return (
                <div onClick={(e) => e.stopPropagation()}>
                    <Checkbox
                        checked={!!value}
                        onChange={async (e) => {
                            const { checked } = e.target;
                            if (setFieldValue) setFieldValue(`items[${itemIndex}][qcApproved]`, checked);
                            if (updatePurchaseOrderItem) await updatePurchaseOrderItem({ ...items?.[itemIndex], qcApproved: !!checked });
                        }}
                    ></Checkbox>
                </div>
            );
        }
    },
    {
        field: 'id',
        headerName: ' ',
        flex: printMode ? undefined : 1,
        width: printMode ? 140 : undefined,
        renderCell: (params: GridCellParams) => {
            const itemIndex = items?.findIndex((item) => item.id === params.id);
            if (itemIndex === -1) return <GridCell>-</GridCell>;

            return (
                <ItemOptionsContainer>
                    <MaterialPopover
                        popoverLeft
                        anchor={
                            <InfoIconWrapper>
                                <InfoIcon src={infoIcon} />
                            </InfoIconWrapper>
                        }
                    >
                        <DetailsWrapper>
                            {Object.entries(items?.[itemIndex] || {})
                                .filter(
                                    ([key, value]) =>
                                        !!value &&
                                        Number(value) !== 0 &&
                                        !['productSku', 'name', 'price', 'quantity', 'updatedAt', 'attributes', 'readyDate'].includes(key) &&
                                        (!key.toLowerCase().includes('id') || key === 'width') &&
                                        !key.toLowerCase().includes('currency') &&
                                        !key.toLowerCase().includes('unit') &&
                                        typeof value !== 'object'
                                )
                                .map(([key, value]: [string, any], i: number) => {
                                    let parsedValue = value;
                                    if (key === 'weight') {
                                        parsedValue = `${value} ${items[itemIndex].weightUnit}`;
                                    } else if (['width', 'length', 'height'].includes(key)) {
                                        parsedValue = `${value} ${items[itemIndex].dimensionsUnit}`;
                                    }
                                    return (
                                        <Detail key={`${params.row.id}-detail-${i}`}>
                                            <DetailName>{localization[key] || key}:</DetailName>
                                            <DetailValue>{parsedValue}</DetailValue>
                                        </Detail>
                                    );
                                })}
                        </DetailsWrapper>
                    </MaterialPopover>
                    {!!onDeleteItem && <VerticalDivider />}
                    {!!onDeleteItem && (
                        <DeleteContainer
                            onClick={async (e) => {
                                e.stopPropagation();
                                await onDeleteItem(itemIndex);
                            }}
                        >
                            <span className="material-icons">delete</span>
                        </DeleteContainer>
                    )}
                </ItemOptionsContainer>
            );
        }
    }
];

const ItemImgContainer = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;

    .ant-image-mask-info {
        font-size: 0;
        display: flex;
        justify-content: center;
        align-items: center;
    }

    .ant-image-mask-info .anticon {
        font-size: 14px;
        margin-inline-end: 0;
    }
`;
const DeleteContainer = styled.div`
    display: flex;
    color: ${(props) => props.theme.colors.primaryBlue};
    span.material-icons {
        cursor: pointer;
    }
`;
const VerticalDivider = styled.div`
    height: 100%;
    width: 1px;
    background: rgb(0 0 0 / 14%);
`;

const LinkButton = styled.div`
    color: #1890ff;
    text-decoration: none;
    background-color: transparent;
    outline: none;
    cursor: pointer;
    transition: color 0.3s;

    &:hover {
        color: #40a9ff;
    }
`;
const InfoIcon = styled.img`
    width: 20px;
    height: 20px;
`;
const InfoIconWrapper = styled.div`
    display: flex;
    position: relative;
    width: 25px;
    justify-content: center;
`;

const DetailsWrapper = styled.div`
    width: auto;
`;
const Detail = styled.div`
    display: flex;
    flex-wrap: wrap;
    margin: 10px 0;
`;
const DetailName = styled.div`
    color: #394372;
    font-weight: bold;
    margin-right: 5px;
`;

const CostCell = styled.div`
    width: 100%;
    display: flex;

    .edit-icon {
        display: none;
    }

    &:hover {
        .edit-icon {
            display: initial;
        }
    }
`;

const DetailValue = styled.div`
    color: #757575;
`;
const GridCell = styled.div`
    width: 100%;
    display: block;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
`;

const ItemOptionsContainer = styled.div`
    display: flex;
    width: 100%;
    height: 40%;
    justify-content: space-around;
    align-items: center;
`;
