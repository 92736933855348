import React from 'react';
import { document } from '../../../../../models/entities/common_subentities/document';
import FileExtIcon from '../../../../shared/Grid/specialColumns/FileExtIcon';
import { styled } from '../../../../shared/Theme/theme';
import { ModalPreviewFileAsync } from '../../../../shared/FilesPreview/ModalPreview';
import { getUrlFuncType } from '../../../../shared/FilesPreview/Preview';

type Props = {
    documents: Array<document>;
    quotationGetFile: getUrlFuncType;
};

const Documents = ({ documents, quotationGetFile }: Props) => {
    return (
        <Wrapper>
            {documents?.map((item) => {
                return (
                    <ModalPreviewFileAsync
                        triggerChildren={
                            <FileContainer>
                                <FileExtIcon filename={item.fileName} />
                            </FileContainer>
                        }
                        key={item.id}
                        fileId={item.fileId}
                        fileName={item.fileName}
                        getFileUrl={quotationGetFile}
                    />
                );
            })}
        </Wrapper>
    );
};
const Wrapper = styled.div`
    display: flex;
`;
const FileContainer = styled.div`
    width: 280px;
    height: 76px;
    box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.15);
    border: 1px solid #eeeeee;
    border-radius: 4px;
    margin-right: 20px;
    padding: 17px;
    display: flex;
    align-items: center;
    cursor: pointer;
    :hover {
        border: 1px solid ${(props) => props.theme.colors.primaryBlue};
    }
`;

export default Documents;
