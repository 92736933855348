import React from 'react';
import { connect } from 'react-redux';
import { userInfo, userMenu } from '../../../models/entities/user';
import { RootState } from '../../../state/store/store';
import { userSelectors } from '../../../state/ducks/user/selectors';
import { localizationSelectors } from '../../../state/ducks/localization/selectors';

import UserMenu from './UserMenu';
import { usersSelectors } from '../../../state/ducks/users/selectors';
import { useHistory } from 'react-router-dom';
import { PagesRoutes } from '../../../routing/PagesRoutes';

type Props = {
    userInfo: userInfo;
    userMenu: userMenu;
    avatarColor?: string;
    setMenuOpen: () => void;
    userMenuIsOpen: boolean;
    localizations?: any;
    logout: Function;
};

export type menuItemsEventsProps = {
    [key: string]: Function;
};

const UserMenuConnected = ({ userInfo, userMenu, avatarColor, setMenuOpen, localizations, logout, userMenuIsOpen }: Props) => {
    const history = useHistory();
    const menuItemsEvents: menuItemsEventsProps = {
        SIGN_OUT: logout,
        USER_SETTINGS: () => {
            history.push(PagesRoutes.UserSettings);
            setMenuOpen();
        }
    };
    return (
        <UserMenu
            userMenu={userMenu}
            isOpen={userMenuIsOpen}
            setMenuOpen={setMenuOpen}
            userInfo={userInfo}
            avatarColor={avatarColor}
            localizations={localizations.user_menu}
            menuItemsEvents={menuItemsEvents}
        />
    );
};

const mapStateToProps = (state: RootState) => ({
    userInfo: userSelectors.userInfo(state),
    userMenu: userSelectors.userMenu(state),
    userMenuIsOpen: userSelectors.userMenuIsOpen(state),
    localizations: localizationSelectors.user(state)
});

const mapDispatchToProps = (dispatch: any) => ({
    setMenuOpen: () => dispatch.header.setMenuOpen('USER'),
    logout: () => dispatch.authentication.logout()
});

export default connect(mapStateToProps, mapDispatchToProps)(UserMenuConnected);
