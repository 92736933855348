import React from 'react';
import { FormCheckbox, FormDropDown, FormTextInput } from '../../../../../shared/inputs/form';
import { styled } from '../../../../../shared/Theme/theme';
import { Flex, renderFieldLabelByName } from '../../ShipmentForm';
import { eContainerTypes } from '../../../../../../models/entities/shipment/containerTypes';
import { fclPackageEmptyObj } from './emptyObjects';
import { shipmentContainer } from '../../../../../../models/entities/shipment/shipmentContainer';
import { Modal } from 'antd';

const containerTypesDropDownOptions = eContainerTypes.map((item) => {
    return { text: item.type, value: item.type };
});

type Props = {
    values: any;
    setFieldValue: any;
    localizations: any;
    shipmentId?: string;
    deleteShipmentContainer?: (shipmentId: string, containerId: string) => Promise<void>;
};
const FCLPackage = ({ values, setFieldValue, localizations, deleteShipmentContainer, shipmentId }: Props) => {
    const packagesSectionLocalizations = localizations.form.sections.packages;
    const fieldsLocalizations = packagesSectionLocalizations.fields;
    const renderFieldTitle = (name: string, isMandatory: boolean) => renderFieldLabelByName(name, isMandatory, fieldsLocalizations);
    return (
        <div>
            {values.containers &&
                values.containers.map((item: shipmentContainer, index: number) => {
                    return (
                        <Flex key={index} style={{ alignItems: 'center' }}>
                            <StyledFieldContainer>
                                <div>{renderFieldTitle('container_type', true)}</div>
                                <div>
                                    <FormDropDown
                                        name={`containers[${index}][type]`}
                                        placeHolder={fieldsLocalizations.container_type.title}
                                        options={containerTypesDropDownOptions}
                                        style={{ width: '200px' }}
                                        showSearch
                                    />
                                </div>
                            </StyledFieldContainer>

                            <StyledFieldContainer>
                                <div>{renderFieldTitle('container_number', false)}</div>
                                <div>
                                    <FormTextInput name={`containers[${index}][number]`} placeHolder={fieldsLocalizations.container_number.title} />
                                </div>
                            </StyledFieldContainer>
                            <StyledFieldContainer>
                                <div>{renderFieldTitle('description', false)}</div>
                                <div>
                                    <FormTextInput name={`containers[${index}][description]`} placeHolder={fieldsLocalizations.description.title} />
                                </div>
                            </StyledFieldContainer>
                            <StyledCheckboxContainer>
                                <FormCheckbox name={`containers[${index}][isOverweight]`}>
                                    <span>{renderFieldTitle('overweight', false)}</span>
                                </FormCheckbox>
                            </StyledCheckboxContainer>
                            <DeleteContainer
                                onClick={async () => {
                                    const containers: Array<any> = [...values.containers];
                                    const containerToDelete = containers[index];
                                    if (deleteShipmentContainer && containerToDelete?.id && shipmentId) {
                                        Modal.confirm({
                                            content: <div>{localizations.are_you_sure}</div>,
                                            zIndex: 1010,
                                            async onOk() {
                                                containers.splice(index, 1);
                                                await deleteShipmentContainer(shipmentId, containerToDelete.id);
                                            },
                                            onCancel() {}
                                        });
                                    } else {
                                        containers.splice(index, 1);
                                    }
                                    setFieldValue('containers', containers);
                                }}
                            >
                                <VerticalDivider />
                                <span className="material-icons">delete</span>
                            </DeleteContainer>
                        </Flex>
                    );
                })}
            <AddContainer
                onClick={() => {
                    let containers: Array<any> = [];
                    if (values.containers) containers = [...values.containers];
                    containers.push(fclPackageEmptyObj);
                    setFieldValue('containers', containers);
                    setFieldValue('isTotals', !containers?.length);
                }}
            >
                <span className="material-icons">add_circle</span>
                <span>{packagesSectionLocalizations.add_container}</span>
            </AddContainer>
        </div>
    );
};

const StyledFieldContainer = styled.div`
    margin-bottom: 12px;
    padding-right: 30px;
`;
const StyledCheckboxContainer = styled.div`
    padding-right: 30px;
    height: 20px;
    margin-bottom: 0;
`;

const AddContainer = styled.div`
    cursor: pointer;
    display: inline-flex;
    align-items: center;
    font-size: 13px;
    line-height: 0;
    color: ${(props) => props.theme.colors.primaryBlue};
    span.material-icons {
        font-size: inherit;
        margin-right: 5px;
        user-select: none;
    }
`;
const DeleteContainer = styled.div`
    display: flex;
    color: ${(props) => props.theme.colors.primaryBlue};
    span.material-icons {
        cursor: pointer;
    }
`;
const VerticalDivider = styled.div`
    height: auto;
    width: 1px;
    background: rgb(0 0 0 / 14%);
    margin-right: 20px;
`;
export default FCLPackage;
