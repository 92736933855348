import BaseController from '../..';
import { purchaseOrderItem } from '../../../../models/entities/purchaseOrder/purchaseOrderItem';
import { updatePurchaseOrderItemsValuesPayload } from '../../../../models/entities/purchaseOrder/updatePurchaseOrderItemsValuesPayload';
import IPurchaseOrderItems from './IPurchaseOrderItems';

export default class PurchaseOrderItems extends BaseController implements IPurchaseOrderItems {
    constructor(baseUrl: string) {
        super(baseUrl);
    }
    async getPurchaseOrderItemsByShipment(shipmentId: string): Promise<purchaseOrderItem[]> {
        const response = await this.client.get(`/purchaseOrder/items/shipmentId/${shipmentId}`);
        return response;
    }
    async createPurchaseOrderItem(purchaseOrderId: string, item: purchaseOrderItem) {
        const response = await this.client.post(`/purchaseOrder/items`, JSON.stringify({ ...item, purchaseOrderId }));
        return response;
    }
    async createMultiplePurchaseOrderItems(purchaseOrderId: string, items: Array<purchaseOrderItem>) {
        const response = await this.client.post(
            `/purchaseOrder/items/multiple`,
            JSON.stringify({ purchaseOrderId, items: items.map((i) => ({ ...i, purchaseOrderId })) })
        );
        return response;
    }
    async updatePurchaseOrderItem(item: purchaseOrderItem) {
        const response = await this.client.put(`/purchaseOrder/items/`, JSON.stringify(item));
        return response;
    }
    async updatePurchaseOrderItemsValues({ purchaseOrderId, ids, values, shipmentsRefs }: updatePurchaseOrderItemsValuesPayload) {
        const response = await this.client.put(
            `/purchaseOrder/items/multiple`,
            JSON.stringify({ ids, ...(values || {}), purchaseOrderId, shipmentsRefs })
        );
        return response;
    }
    async deletePurchaseOrderItem(purchaseOrderId: string, itemId: string) {
        const response = await this.client.delete(`/purchaseOrder/items`, JSON.stringify({ purchaseOrderId, id: itemId }));
        return response;
    }
}
