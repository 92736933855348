import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { featureFlagsModel } from '../../../../models/entities/businessPartner/settings';
import { product, settings } from '../../../../models/entities/product/product';
import { drawers } from '../../../../state/ducks/header/header';
import { localizationSelectors } from '../../../../state/ducks/localization/selectors';
import { userSelectors } from '../../../../state/ducks/user/selectors';
import { RootState } from '../../../../state/store/store';
import Button from '../../../shared/SmallComponents/Button';
import Loader from '../../../shared/SmallComponents/Loader';
import UploadImgButton from '../../../shared/SmallComponents/UploadImgButton';
import { styled } from '../../../shared/Theme/theme';
import { editableDetail, overviewFieldsArr, renderEditable, sectionType } from '../utils';
import ProductForm, { eFormMode } from './Form/ProductFormConnected';
import ProductTabs from './Tabs/Tabs';
import UserBadge from '../../../shared/UserBadge/UserBadge';
import { productsSelectors } from '../../../../state/ducks/products/selectors';
import { document } from '../../../../models/entities/common_subentities/document';
import { attribute } from '../../../../models/entities/product/attribute';
import { Switch } from 'antd';
import { RcFile, UploadFile } from 'antd/lib/upload/interface';
import { UploadRequestOption } from 'rc-upload/lib/interface';
import { Modal } from 'antd';

type Props = {
    params: any;
    setDrawerOpen: (open: drawers, params?: any) => void;
    localizations: any;
    fetchProductById: (productId: string) => Promise<product>;
    showLoader: boolean;
    featureFlags?: featureFlagsModel;
    settings: settings;
    documents?: Array<document>;
    productImagesList?: UploadFile<any>[];
    attributes?: Array<attribute>;
    loadingIsActive: boolean;
    setSingleProductId: (productId: string | null) => void;
    updateProductIsActive: (productId: string, isActive: boolean) => void;
    createMultipleProductDocument: (productId: string, documents: Array<document>) => void;
    deleteProductDocument: (productId: string, documentId: string) => void;
    currentProductId: string | null;
    product?: product;
    padding?: string;
};

const renderFieldLabel = (text: string) => {
    return <Label>{text}</Label>;
};

const ProductView = ({
    params,
    setDrawerOpen,
    localizations,
    showLoader,
    fetchProductById,
    featureFlags,
    settings,
    loadingIsActive,
    setSingleProductId,
    updateProductIsActive,
    createMultipleProductDocument,
    product,
    padding,
    documents,
    productImagesList,
    deleteProductDocument
}: Props) => {
    const { productId, tab }: { productId: string; tab?: string } = params;
    const [mode, setMode] = useState<'EDIT' | 'DUPLICATE' | undefined>(undefined);
    const [isUploadingImage, setIsUploadingImage] = useState(false);

    useEffect(() => {
        setSingleProductId(productId);
        if (productId) {
            fetchProductById(productId);
        }
        return function cleanup() {
            setSingleProductId(null);
        };
    }, [fetchProductById, setSingleProductId, productId]);
    if (showLoader) return <Loader />;
    const isCreateMode = !product && !productId;
    return (
        <Container padding={padding}>
            {!isCreateMode && product && (
                <OverviewHeader>
                    {!mode && (
                        <ProductName>
                            {product.name}{' '}
                            <Switch
                                loading={loadingIsActive}
                                checked={product.isActive}
                                onChange={async (checked) => await updateProductIsActive(product.id, checked)}
                                checkedChildren={localizations.active}
                                unCheckedChildren={localizations.inactive}
                            />
                        </ProductName>
                    )}
                    {!mode && (
                        <div style={{ display: 'flex', marginLeft: 'auto' }}>
                            <Button
                                width="auto"
                                height="auto"
                                padding="0 15px"
                                borderRadiusMobile="50%"
                                minHeightMobile="32px"
                                paddingMobile="0 4px"
                                widthMobile="auto"
                                onClick={() => setMode('DUPLICATE')}
                            >
                                <span className="material-icons add-icon" style={{ fontSize: '14px', marginRight: '5px' }}>
                                    content_copy
                                </span>
                                <span className="new-label">{localizations.duplicate_btn}</span>
                            </Button>
                            <Button
                                width="auto"
                                height="auto"
                                buttonType="filled"
                                padding="0 15px"
                                margin="0 0 0 5px"
                                borderRadiusMobile="50%"
                                minHeightMobile="32px"
                                paddingMobile="0 4px"
                                widthMobile="auto"
                                onClick={() => setMode('EDIT')}
                            >
                                <span className="material-icons add-icon" style={{ fontSize: '14px', marginRight: '5px' }}>
                                    edit
                                </span>
                                <span className="new-label">{localizations.edit_btn}</span>
                            </Button>
                        </div>
                    )}
                </OverviewHeader>
            )}
            {mode || isCreateMode ? (
                <ProductForm
                    product={product}
                    mode={mode ? mode : 'NEW'}
                    onClose={(id) => {
                        if (id) {
                            setDrawerOpen('PRODUCT', { productId: id });
                            return;
                        }
                        setMode(undefined);
                    }}
                />
            ) : (
                product && (
                    <Overview>
                        {product.userId && (
                            <UserContainer>
                                <UserBadge width={40} userId={product.userId} />
                            </UserContainer>
                        )}
                        <UploadImgButton
                            mode="multiple"
                            isLoading={isUploadingImage}
                            onRemove={async (file) => {
                                Modal.confirm({
                                    zIndex: 1100,
                                    content: <div>{localizations.are_you_sure}</div>,
                                    async onOk() {
                                        await deleteProductDocument(product.id, file.uid);
                                    },
                                    onCancel() {}
                                });

                                return false;
                            }}
                            customRequest={async ({ file, onProgress }: UploadRequestOption<any>) => {
                                setIsUploadingImage(true);
                                await createMultipleProductDocument(product.id, [
                                    {
                                        type: ['PRODUCT_IMG'],
                                        fileName: (file as RcFile).name,
                                        fileType: (file as RcFile).type,
                                        comment: '',
                                        file
                                    }
                                ] as Array<any>);
                                setIsUploadingImage(false);
                            }}
                            filesList={productImagesList}
                        />

                        {overviewFieldsArr({ localizations: localizations.form, featureFlags, values: product }).map(
                            (outer: sectionType, index: number) => {
                                return (
                                    !outer.hide && (
                                        <Section key={index}>
                                            {outer.title && <SectionTitle>{outer.title}</SectionTitle>}
                                            <Flex>
                                                {outer.items
                                                    .filter((i) => !i.hidden)
                                                    .map((item: editableDetail, index: number) => {
                                                        return (
                                                            <StyledFieldContainer key={index}>
                                                                {renderEditable(item, product, renderFieldLabel, true)}
                                                            </StyledFieldContainer>
                                                        );
                                                    })}
                                            </Flex>
                                        </Section>
                                    )
                                );
                            }
                        )}
                        <ProductTabs product={product} localization={localizations} settings={settings} defaultTab={tab} />
                    </Overview>
                )
            )}
        </Container>
    );
};
const Container = styled.div<{ padding?: string }>`
    padding: ${(props) => props.padding ?? '43px 0 20px 0'};
    height: 100%;
    width: 100%;
`;

const StyledFieldContainer = styled.div`
    margin-bottom: 12px;
    padding-right: 50px;
    max-width: 33%;
    width: 33%;
    :only-child {
        flex: 3;
        max-width: 100%;
        padding-right: 0;

        .value {
            white-space: pre-wrap;
        }
    }
`;

const Flex = styled.div`
    display: flex;
`;
const Overview = styled.div`
    position: relative;
    padding: 25px;
`;

const Label = styled.div`
    color: ${(props) => props.theme.colors.primaryBlue};
    font-size: 15px;
`;
const OverviewHeader = styled.div`
    padding: 0 25px;
    display: flex;
    align-items: center;
    position: relative;
`;
const ProductName = styled.div`
    color: ${(props) => props.theme.colors.pageTitle};
    font-size: 16px;
    font-weight: bold;
`;

const Section = styled.div``;
const SectionTitle = styled.div`
    font-size: 16px;
    color: ${(props) => props.theme.colors.pageTitle};
    font-weight: 500;
    margin: 5px 0;
`;
const UserContainer = styled.div`
    position: absolute;
    right: 35px;
    top: 113px;
`;
const mapStateToProps = (state: RootState) => ({
    localizations: localizationSelectors.products(state),
    showLoader: state.loading.effects.products.fetchProductById,
    featureFlags: userSelectors.featureFlags(state),
    settings: productsSelectors.settingsByLanguage(state),
    productImagesList: productsSelectors.productImagesList(state),
    documents: productsSelectors.documents(state),
    attributes: productsSelectors.attributes(state),
    links: productsSelectors.links(state),
    currentProductId: productsSelectors.currentProductId(state),
    product: productsSelectors.currentProduct(state),
    loadingIsActive: !!state.loading.effects.products.updateProductIsActive
});

const mapDispatchToProps = (dispatch: any) => ({
    setDrawerOpen: (open: drawers, params?: any) => dispatch.header.setDrawerOpenParams({ open, params }),
    fetchProductById: (productId: string) => dispatch.products.fetchProductById(productId),
    setSingleProductId: (productId: string | null) => dispatch.products.setSingleProductId(productId),
    updateProductIsActive: (productId: string, isActive: boolean) => dispatch.products.updateProductIsActive({ id: productId, isActive }),
    createMultipleProductDocument: (productId: string, documents: Array<document>) =>
        dispatch.products.createMultipleProductDocument({ productId, documents }),
    deleteProductDocument: (productId: string, documentId: string) => dispatch.products.deleteProductDocument({ productId, documentId })
});

export default connect(mapStateToProps, mapDispatchToProps)(ProductView);
