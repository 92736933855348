import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { RootState } from '../../../state/store/store';
import ChatPane from './ChatPane';
import { chatsSelectors } from '../../../state/ducks/chats/selectors';
import { chat, chatType, createChatPayload, editMessagePayload, message, messageAttachment } from '../../../models/entities/chat/chat';
import { userInfo } from '../../../models/entities/user';
import { userSelectors } from '../../../state/ducks/user/selectors';
import { usersSelectors } from '../../../state/ducks/users/selectors';
import { userDetails, usersObject } from '../../../state/ducks/users/users';
import { allShipmentsPageSelectors } from '../../../state/ducks/allShipmentsPage/selectors';
import { shipment } from '../../../models/entities/shipment/shipment';
import { ColumnType } from '../../shared/Grid/types/Column';
import { shipmentsSelectors } from '../../../state/ducks/shipments/selectors';
import { chatRequest } from '../../../models/entities/chat/chatRequest';
import { localizationSelectors } from '../../../state/ducks/localization/selectors';
import { ContentState, EditorState } from 'draft-js';

export type chatPaneParams = {
    participantsIds: string[];
    chatType: chatType;
    chatId?: string;
};
type Props = {
    currentChatId: string;
    shipments: Array<shipment>;
    params: chatPaneParams;
    userInfo: userInfo;
    setCurrentChatId: (chatId: string) => void;
    getChatByParticipantsIds: (participantsIds: string[], type: chatType) => chat | undefined;
    getChatById: (chatId: string) => chat | undefined;
    getMessagesByChatId: (chatId: string) => { [key: string]: message[] } | undefined;
    fetchNextMessages: (chatId: string, messagesToId: string) => Promise<chat | undefined>;
    createChat: (payload: createChatPayload) => void;
    sendMessage: (chatId: string, message: message) => void;
    editMessage: (payload: editMessagePayload) => void;
    deleteMessage: (id: string, chatId: string) => void;
    readMessages: (chatId: string, messagesIds: string[], userId: string) => void;
    getUserById: (userId: string) => userDetails | undefined;
    typingUsers: (chatId: string) => string[] | undefined;
    deleteGroupParticipant: (chatId: string, userId: string, userName: string) => Promise<void>;
    addGroupParticipants: (chatId: string, users: { id: string; name: string }[]) => Promise<void>;
    getMessageById: (chatId: string, messageId: string) => message | undefined;
    saveChatEditorState: (chatId: string, editorContentState: ContentState) => void;
    saveChatAttachmentsEditorState: (chatId: string, attachments: messageAttachment[]) => void;
    isFetchingNextMessages: boolean;
    myNetwork: usersObject;
    gridColumns: Array<ColumnType<shipment>>;
    getShipmentById: (id: string) => shipment | undefined;
    inviteUser: (email: string) => void;
    sentRequests?: Array<chatRequest>;
    localization: any;
};
const ChatPaneConnected = ({
    currentChatId,
    setCurrentChatId,
    shipments,
    params,
    getChatByParticipantsIds,
    fetchNextMessages,
    getChatById,
    sendMessage,
    createChat,
    editMessage,
    deleteMessage,
    userInfo,
    getUserById,
    readMessages,
    typingUsers,
    getMessageById,
    myNetwork,
    gridColumns,
    getShipmentById,
    inviteUser,
    sentRequests,
    deleteGroupParticipant,
    isFetchingNextMessages,
    getMessagesByChatId,
    addGroupParticipants,
    saveChatEditorState,
    saveChatAttachmentsEditorState,
    localization
}: Props) => {
    /** chat debug
     * //console.log('render chat connected');
     */

    const [currentChat, setCurrentChat] = useState<chat | undefined>(getChatById(currentChatId));
    const [currentChatMessages, setCurrentChatMessages] = useState<{ [key: string]: message[] } | undefined>(undefined);

    useEffect(() => {
        const chat = getChatById(currentChatId) || getChatByParticipantsIds(params.participantsIds, params.chatType);
        setCurrentChat(chat);
        setCurrentChatMessages(chat?.id ? getMessagesByChatId(chat.id) : undefined);
    }, [currentChatId, setCurrentChat, getMessagesByChatId, getChatByParticipantsIds, params]);

    return (
        <ChatPane
            participantsIds={params.participantsIds}
            chatType={params.chatType}
            chat={currentChat}
            createChat={createChat}
            sendMessage={sendMessage}
            editMessage={editMessage}
            deleteMessage={deleteMessage}
            userInfo={userInfo}
            getUserById={getUserById}
            readMessages={readMessages}
            typingUsers={currentChat ? typingUsers(currentChat.id) : undefined}
            myNetwork={myNetwork}
            shipments={shipments}
            gridColumns={gridColumns}
            getShipmentById={getShipmentById}
            inviteUser={inviteUser}
            deleteGroupParticipant={deleteGroupParticipant}
            addGroupParticipants={addGroupParticipants}
            fetchNextMessages={fetchNextMessages}
            getMessageById={getMessageById}
            messages={currentChatMessages}
            sentRequests={sentRequests}
            isFetchingNextMessages={isFetchingNextMessages}
            saveChatEditorState={saveChatEditorState}
            saveChatAttachmentsEditorState={saveChatAttachmentsEditorState}
            localization={localization}
        ></ChatPane>
    );
};

const mapStateToProps = (state: RootState) => ({
    currentChatId: state.chats.currentPaneChatId,
    userInfo: userSelectors.userInfo(state),
    getChatByParticipantsIds: (participantsIds: string[], type: chatType) => chatsSelectors.getChatByParticipantsIds(state)(participantsIds, type),
    isFetchingNextMessages: !!state.loading.effects.chats.fetchNextMessages,
    getChatById: (chatId: string) => chatsSelectors.getChatById(state)(chatId),
    getMessagesByChatId: (chatId: string) => chatsSelectors.getMessagesByChatId(state)(chatId),
    getMessageById: (chatId: string, messageId: string) => chatsSelectors.getMessageById(state)(chatId, messageId),
    getUserById: (id: string) => usersSelectors.getUserById(state)(id),
    typingUsers: (chatId: string) => chatsSelectors.typingUsers(state)(chatId),
    myNetwork: usersSelectors.users(state),
    shipments: allShipmentsPageSelectors.gridData(state),
    gridColumns: allShipmentsPageSelectors.gridColumns(state),
    getShipmentById: (id: string) => shipmentsSelectors.getShipmentById(state, id),
    sentRequests: chatsSelectors.sentRequests(state),
    localization: localizationSelectors.chat(state)
});

const mapDispatchToProps = (dispatch: any) => ({
    setCurrentChatId: (chatId: string) => dispatch.chats.setCurrentChatId(chatId),
    createChat: (payload: createChatPayload) => dispatch.chats.createChat(payload),
    sendMessage: (chatId: string, message: message) => dispatch.chats.sendMessage({ chatId, message }),
    fetchNextMessages: (chatId: string, messagesToId: string) => dispatch.chats.fetchNextMessages({ chatId, messagesToId }),
    editMessage: (payload: editMessagePayload) => dispatch.chats.editMessage(payload),
    deleteMessage: (id: string, chatId: string) => dispatch.chats.deleteMessage({ id, chatId }),
    readMessages: (chatId: string, messagesIds: string[], userId: string) => dispatch.chats.readMessages({ chatId, messagesIds, userId }),
    deleteGroupParticipant: (chatId: string, userId: string, userName: string) => dispatch.chats.deleteGroupParticipant({ chatId, userId, userName }),
    addGroupParticipants: (chatId: string, users: { id: string; name: string }[]) => dispatch.chats.addGroupParticipants({ chatId, users }),
    saveChatEditorState: (chatId: string, editorContentState: ContentState) => dispatch.chats.saveChatEditorState(chatId, editorContentState),
    saveChatAttachmentsEditorState: (chatId: string, attachments: messageAttachment[]) =>
        dispatch.chats.saveChatAttachmentsEditorState(chatId, attachments),
    inviteUser: (email: string) => dispatch.chats.inviteUser(email)
});

export default connect(mapStateToProps, mapDispatchToProps)(ChatPaneConnected);
