import { styled } from '../../Theme/theme';
import DatePicker from './DatePicker';
import DropDown from './DropDown';
import UserSettingsDropDown from './UserSettingsDropDown';
import TextArea from './TextArea';
import TextInput from './TextInput';
import UploadInput from './UploadInput';
import Checkbox from './Checkbox';
import LocationSearchInput from './LocationSearchInput';
import FormNumberInput from './NumberInput';
import CurrencyInput from './CurrencyInput/CurrencyInput';
import NumberUnitInput from './NumberUnitInput';
import TextEditor from './TextEditor';
import DimensionInput from './DimensionInput';
import UserDropDownForm from './UserDropDown';
import FormHsCodeInput from './HsCodeInput';
import FormAutoComplete from './AutoComplete';

export {
    DatePicker as FormDatePicker,
    DropDown as FormDropDown,
    UserSettingsDropDown as UserSettingsFormDropDown,
    TextArea as FormTextArea,
    TextInput as FormTextInput,
    UploadInput as FormUploadInput,
    Checkbox as FormCheckbox,
    LocationSearchInput,
    FormNumberInput,
    NumberUnitInput,
    CurrencyInput,
    TextEditor as FormTextEditor,
    DimensionInput,
    UserDropDownForm,
    FormAutoComplete,
    FormHsCodeInput
};

export const ErrorText = styled.span`
    font-size: 11px;
    color: red;
    position: absolute;
    margin-left: 5px;
`;
