import React, { useEffect, useState } from 'react';
import { internalFlowInventoryLogsGridDataType } from '../../../models/entities/product/inventoryLog';
import { drawers } from '../../../state/ducks/header/header';
import styled from 'styled-components';
import Grid from '../../shared/Grid/Grid';
import { ColumnType } from '../../shared/Grid/types/Column';
import { gridSortState } from '../../../state/ducks/internalFlowInventoryLogs/internalFlowInventoryLogs';
import InternalFlowInventoryLogsHeader from './components/InternalFlowInventoryLogsHeader';

type Props = {
    internalFlowInventoryLogsGridData: internalFlowInventoryLogsGridDataType[];
    gridColumns: Array<ColumnType<internalFlowInventoryLogsGridDataType>>;
    currentGridSort: gridSortState | null;
    gridSortedBy?: keyof internalFlowInventoryLogsGridDataType;
    gridSortDirection?: 'asc' | 'desc';
    showLoader: boolean;
    localization: any;
    setDrawerOpen: (open: drawers, params?: any) => void;
    onGridColumnClick?: (column: keyof internalFlowInventoryLogsGridDataType) => void;
};

const InternalFlowInventoryLogsPage = ({
    internalFlowInventoryLogsGridData,
    gridColumns,
    currentGridSort,
    gridSortedBy,
    gridSortDirection,
    showLoader,
    localization,
    setDrawerOpen,
    onGridColumnClick
}: Props) => {
    return (
        <TableWrapper>
            <InternalFlowInventoryLogsHeader />
            <Grid<internalFlowInventoryLogsGridDataType>
                sortedByColumn={gridSortedBy}
                sortDirection={gridSortDirection}
                columns={gridColumns}
                data={internalFlowInventoryLogsGridData}
                onColumnClick={onGridColumnClick}
                localization={localization.grid}
                emptyStateText={localization.grid.emptyState}
                showLoader={showLoader}
                idKey="productId"
                groupHeaders={[
                    {
                        name: localization.grid.columns.internalFlow,
                        fields: ['pdReservedTotal', 'poInboundTotal', 'shipmentInboundTotal']
                    },
                    {
                        name: localization.grid.columns.amazon,
                        fields: ['amazonOnHandTotal', 'amazonInboundTotal', 'amazonReservedTotal']
                    },
                    {
                        name: localization.grid.columns.manual,
                        fields: ['manualOnHand', 'manualInbound', 'manualReserved']
                    }
                ]}
                onRowClick={(row) => setDrawerOpen('PRODUCT', { productId: row.productId, tab: 'inventory' })}
            />
        </TableWrapper>
    );
};

const TableWrapper = styled.div`
    width: 100%;
    height: 100%;
    overflow: auto;
`;

export default InternalFlowInventoryLogsPage;
