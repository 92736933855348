import { createSelector } from 'reselect';
import claimsType, { claimsKeys } from '../../../models/entities/claimsType';
import { featureFlagsModel } from '../../../models/entities/businessPartner/settings';
import { RootState } from '../../../state/store/store';
import headerSelector from '../header/selectors';
import { UserClaims, claimsDomainsType } from '../../../userClaims';
import { allowedCompany, userSettingDefinition } from '../../../models/entities/user';
import { Option } from '../../../components/shared/inputs/base/DropDown';
import { followingInvitation } from '../../../models/entities/common_subentities/followingInvitation';

const userInfo = (state: RootState) => state.user.userInfo;
const userClaims = (state: RootState) => state.user.userInfo.claims;
const users = (state: RootState) => state.users.users;
const shipments = (state: RootState) => state.shipments.shipments;
const purchaseOrders = (state: RootState) => state.purchaseOrders.purchaseOrders;
const userMenu = (state: RootState) => state.user.userMenu;
const userMenuIsOpen = (state: RootState) => headerSelector.menuOpen(state) === 'USER';
const error = (state: RootState) => state.user.error;
const allowedCompanies = (state: RootState) => state.user.userInfo.allowedCompanies as allowedCompany[];
const companySettings = (state: RootState) => state.user.userInfo.companySettings;
const userInvitations = (state: RootState) => state.user.userInvitations;
const userSettingDefinitions = (state: RootState) => state.user.userSettingDefinitions;
const companyType = (state: RootState) => state.user.userInfo.companyType;
const isFreelancerOrForwarder = (state: RootState) =>
    state.user.userInfo.companyType === 'FREELANCER' || state.user.userInfo.companyType === 'FORWARDER';

const isOrdersOrientedCompany = createSelector(companyType, (companyType) => companyType === 'ORDERS_ORIENTED');

const getUserClaims = createSelector(userClaims, (userClaims) => (domain: claimsDomainsType) => {
    const claims = UserClaims[domain];
    if (!claims) return {};

    const allowedActions: claimsType = {};

    Object.entries(claims).forEach(([key, value]) => {
        if (userClaims && value && userClaims[value]) allowedActions[key as claimsKeys] = !!Number(userClaims[value]);
    });
    return allowedActions;
});

const getColorByConpanyId = createSelector(allowedCompanies, (allowedCompanies) => (id: string) => {
    return allowedCompanies.find((c) => c.companyId === id)?.color;
});

const usersInSameCompany = createSelector(userInfo, (userInfo) => {
    return userInfo.usersInSameCompany;
});

const usersCompanyId = createSelector(userInfo, (userInfo) => {
    return userInfo.companyId;
});

const usersInSameNetwork = createSelector(users, userInfo, (users, userInfo) => {
    return Object.values(users).filter((item) => item.id !== userInfo.id);
});

const usersInSameNetworkIncludeSelf = createSelector(users, userInfo, (users, userInfo) => {
    return Object.values(users);
});

const followingShipments = createSelector(userInfo, (userInfo) => {
    return userInfo.followingShipments;
});

const followingShipmentsData = createSelector(shipments, userInfo, (shipments, userInfo) => {
    return shipments.filter((shipment) => userInfo.followingShipments.includes(shipment.id));
});

const followingPurchaseOrdersData = createSelector(purchaseOrders, userInfo, (purchaseOrders, userInfo) => {
    return purchaseOrders.filter((purchaserOrder) => userInfo.followingPurchaseOrders.includes(purchaserOrder.id));
});
const userSettings = createSelector(userInfo, (userInfo) => {
    return userInfo.userSettings;
});

const shipmentsGridSettings = createSelector(userSettings, (userSettings) => {
    return userSettings.find((item) => item.key === 'ShipmentGrid');
});
const purchaseOrdersGridSettings = createSelector(userSettings, (userSettings) => {
    return userSettings.find((item) => item.key === 'PurchaseOrderGrid');
});
const purchaseDemandsGridSettings = createSelector(userSettings, (userSettings) => {
    return userSettings.find((item) => item.key === 'PurchaseDemandGrid');
});
const bidsGridSettings = createSelector(userSettings, (userSettings) => {
    return userSettings.find((item) => item.key === 'BidGrid');
});
const productsGridSettings = createSelector(userSettings, (userSettings) => {
    return userSettings.find((item) => item.key === 'ProductGrid');
});
const inventoryGridSettings = createSelector(userSettings, (userSettings) => {
    return userSettings.find((item) => item.key === 'InventoryGrid');
});
const emailsAutoCompleteSettings = createSelector(userSettings, (userSettings) => {
    return userSettings.find((item) => item.key === 'EmailAutoComplete');
});
const bidTemplateNote = createSelector(userSettings, (userSettings) => {
    return userSettings.find((item) => item.key === 'BidTemplateNote');
});
const ordersGridSettings = createSelector(userSettings, (userSettings) => {
    return userSettings.find((item) => item.key === 'OrdersGrid');
});
const featureFlags = createSelector(companySettings, (companySettings) => {
    const flags = companySettings?.find((item) => item.key === 'featureFlags');
    if (flags) return flags.value as featureFlagsModel;
});

const followingPurchaseOrders = createSelector(userInfo, (userInfo) => {
    return userInfo.followingPurchaseOrders;
});

const allowedTypes = createSelector(userInfo, (userInfo) => {
    return userInfo.allowedShipmentTypes;
});
const userName = createSelector(userInfo, (userInfo) => {
    return userInfo.username;
});
const shipmentFollowingInvitations = createSelector(userInvitations, (userInvitations) => {
    return userInvitations.shipmentFollowing;
});
const purchaseOrderFollowingInvitations = createSelector(userInvitations, (userInvitations) => {
    return userInvitations.purchaseOrderFollowing;
});
const mergeFollowingInvitations = createSelector(
    shipmentFollowingInvitations,
    purchaseOrderFollowingInvitations,
    (shipmentFollowingInvitations, purchaseOrderFollowingInvitations) => {
        const arr: followingInvitation[] = [];
        if (shipmentFollowingInvitations) {
            arr.push(
                ...shipmentFollowingInvitations.map((item) => {
                    return {
                        id: item.id,
                        itemId: item.shipmentId,
                        itemNumber: item.shipmentNumber,
                        inviteUserId: item.inviteUserId,
                        inviteUserName: item.inviteUserName,
                        userId: item.userId,
                        status: item.status,
                        type: 'SHIPMENT'
                    };
                })
            );
        }
        if (purchaseOrderFollowingInvitations) {
            arr.push(
                ...purchaseOrderFollowingInvitations.map((item) => {
                    return {
                        id: item.id,
                        itemId: item.purchaseOrderId,
                        itemNumber: item.purchaseOrderNumber,
                        inviteUserId: item.inviteUserId,
                        inviteUserName: item.inviteUserName,
                        userId: item.userId,
                        status: item.status,
                        type: 'PURCHASE_ORDER'
                    };
                })
            );
        }
        return arr;
    }
);
const allowedCompaniesDropDownOptions = createSelector(allowedCompanies, (allowedCompanies): Option[] => {
    return allowedCompanies
        .filter((c) => !!c.parentCompany || !allowedCompanies.some((comp) => comp.parentCompany === c.companyId))
        .map((item) => {
            return { value: item.companyId, text: item.companyName };
        });
});

const userSettingsFromDefinitions = createSelector(
    userSettings,
    userSettingDefinitions,
    (userSettings, userSettingDefinitions): userSettingDefinition[] => {
        return userSettingDefinitions.map((def) => {
            const userSetting = userSettings.find((item) => item.key === def.key);
            return {
                id: userSetting?.id || undefined,
                key: def.key,
                value: userSetting?.value || def.value,
                type: def.type
            } as userSettingDefinition;
        });
    }
);
export { default as userSelectors } from './selectors';

const exports = {
    isOrdersOrientedCompany,
    getUserClaims,
    followingShipments,
    followingShipmentsData,
    followingPurchaseOrdersData,
    followingPurchaseOrders,
    purchaseDemandsGridSettings,
    userName,
    allowedTypes,
    userSettings,
    userSettingsFromDefinitions,
    shipmentsGridSettings,
    purchaseOrdersGridSettings,
    bidsGridSettings,
    featureFlags,
    usersInSameCompany,
    usersInSameNetwork,
    usersInSameNetworkIncludeSelf,
    shipmentFollowingInvitations,
    purchaseOrderFollowingInvitations,
    mergeFollowingInvitations,
    emailsAutoCompleteSettings,
    getColorByConpanyId,
    productsGridSettings,
    inventoryGridSettings,
    allowedCompaniesDropDownOptions,
    bidTemplateNote,
    ordersGridSettings,
    usersCompanyId,
    userInfo,
    userMenu,
    userMenuIsOpen,
    error,
    userClaims,
    isFreelancerOrForwarder,
    allowedCompanies
};

export default exports;
