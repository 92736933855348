import React from 'react';
import { hsCode } from '../../../models/entities/common_subentities/hsCode';
import { styled } from '../Theme/theme';
import MaterialPopover from '../Popover/MaterialPopover';

type Props = {
    hsCodes: Array<hsCode>;
    localizations: any;
};

const ViewHsCodes = ({ hsCodes, localizations }: Props) => {
    const renderHsCodeRow = (hsCode: hsCode) => (
        <Flex>
            <Code>
                <b>{localizations.code}:</b> {hsCode.code}
            </Code>
            <HyphenDivider />
            <Percent>{hsCode.percent}</Percent>
        </Flex>
    );
    const renderInfoPopover = (hsCodes: hsCode[]) => (
        <MaterialPopover
            anchor={
                <span style={{ fontSize: '19px', marginLeft: '5px' }} className="material-icons">
                    info
                </span>
            }
            anchorStyle={{ display: 'flex' }}
        >
            <Flex style={{ flexDirection: 'column' }}>{hsCodes.map((item) => renderHsCodeRow(item))}</Flex>
        </MaterialPopover>
    );
    if (!hsCodes || hsCodes.length === 0) return <span>-</span>;
    return (
        <Container>
            {hsCodes.length === 1 ? (
                renderHsCodeRow(hsCodes[0])
            ) : (
                <Flex>
                    {hsCodes.length} {localizations.hs_codes}
                    <HyphenDivider />
                    <Percent>{hsCodes.map((item) => item.percent).reduce((a, b) => a + b, 0)}</Percent>
                    {renderInfoPopover(hsCodes)}
                </Flex>
            )}
        </Container>
    );
};
const Container = styled.div``;
const Flex = styled.div`
    display: flex;
`;
const Code = styled.div``;
const Percent = styled.div`
    ::after {
        content: '%';
    }
`;
const HyphenDivider = styled.span`
    margin: 0 15px;
    ::after {
        content: '-';
    }
`;
export default ViewHsCodes;
