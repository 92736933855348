import BaseController from '../..';
import { document } from '../../../../models/entities/common_subentities/document';
import IShipmentDocuments from './IShipmentDocuments';

export default class ShipmentDocuments extends BaseController implements IShipmentDocuments {
    constructor(baseUrl: string) {
        super(baseUrl);
    }
    async fetchShipmentDocuments(shipmentNumber: string) {
        const response = await this.client.get(`/shipment/documents/${shipmentNumber}`);
        return response;
    }
    async createMultipleShipmentDocument(shipmentId: string, data: Array<document>) {
        this.client.setBodyType('FORM-DATA');
        const response = await this.client.post(`/shipment/documents/upload-multiple`, this.convertToArrayFormData(data, shipmentId));
        return response;
    }
    async createShipmentDocument(shipmentId: string, data: document) {
        this.client.setBodyType('FORM-DATA');
        const response = await this.client.post(`/shipment/documents/upload`, this.convertToFormData(data, shipmentId));
        return response;
    }
    async updateShipmentDocument(shipmentId: string, data: document) {
        this.client.setBodyType('FORM-DATA');
        const response = await this.client.put(`/shipment/documents`, this.convertToFormData(data, shipmentId));
        return response;
    }
    async deleteShipmentDocument(shipmentId: string, documentId: number, userId: string) {
        const response = await this.client.delete(`/shipment/documents`, JSON.stringify({ shipmentId, id: documentId, userId }));
        return response;
    }
    private convertToFormData(document: document, shipmentId: string): FormData {
        const formData = new FormData();
        let type = document.type;
        if (Array.isArray(type)) type = JSON.stringify(type);
        formData.append('shipmentId', shipmentId);
        formData.append('type', type);
        formData.append('comment', document.comment);
        formData.append('fileName', document.fileName);
        formData.append('fileType', document.fileType);
        if (document.expDate) formData.append('expDate', JSON.stringify(document.expDate));
        if (document.userId) formData.append('userId', document.userId);
        if (document.file) formData.append('file', document.file);
        if (document.id) formData.append('id', '' + document.id);
        return formData;
    }
    private convertToArrayFormData(documents: Array<document>, shipmentId: string): FormData {
        const formData = new FormData();
        formData.append('shipmentId', shipmentId);
        documents.forEach((document) => {
            let type = document.type;
            if (Array.isArray(type)) type = JSON.stringify(type);
            formData.append('type[]', type);
            formData.append('comment[]', document.comment);
            formData.append('fileName[]', document.fileName);
            formData.append('fileType[]', document.fileType);
            if (document.expDate) formData.append('expDate[]', JSON.stringify(document.expDate));
            if (document.file) formData.append('file[]', document.file);
            if (document.id) formData.append('id[]', '' + document.id);
        });
        return formData;
    }
}
