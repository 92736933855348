import React from 'react';
import pdf from '../../../../static/icons/documentsType/pdf.svg';
import excel from '../../../../static/icons/documentsType/excel.svg';
import picture from '../../../../static/icons/documentsType/picture.jpg';
import txt from '../../../../static/icons/documentsType/txt.svg';
import ppt from '../../../../static/icons/documentsType/ppt.svg';
import word from '../../../../static/icons/documentsType/word.svg';

import { styled } from '../../Theme/theme';

type Props = {
    filename: string;
    width?: string;
    iconWidth?: string;
};
const mapExtesionIcon = (ext: string) => {
    switch (ext.toUpperCase()) {
        case 'PDF':
            return pdf;
        case 'JPG':
        case 'JPEG':
        case 'PNG':
            return picture;
        case 'XLS':
        case 'XLSX':
            return excel;
        case 'PPT':
            return ppt;
        case 'DOC':
        case 'DOCX':
            return word;

        default:
            return txt;
    }
};
const FileExtIcon = ({ filename, width, iconWidth }: Props) => {
    const ext: string = filename?.split('.').pop() || '';
    const icon = mapExtesionIcon(ext);
    return (
        <Wrapper width={width}>
            <FileIcon src={icon} alt={icon} iconWidth={iconWidth} />
            <CellSpan>{filename}</CellSpan>
        </Wrapper>
    );
};
interface WrapperProps {
    width?: string;
}
FileExtIcon.defaultProps = {
    width: '100%'
};
const Wrapper = styled.div<WrapperProps>`
    display: flex;
    justify-content: left;
    align-items: center;
    width: ${(props) => props.width};
`;
const CellSpan = styled.span`
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    -webkit-line-clamp: 2;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    display: block;
`;

const FileIcon = styled.img<{ iconWidth?: string }>`
    width: ${(props) => props.iconWidth ?? '25px'};
    margin-right: 6px;
`;

export default FileExtIcon;
