import React from 'react';
import { styled } from '../../../../shared/Theme/theme';
import { businessPartner, settings } from '../../../../../models/entities/businessPartner/businessPartner';
import { currencyFormatter } from '../../../../shared/inputs/form/CurrencyInput/CurrencyInput';
import { ePaymentTerms } from '../../../../../models/entities/businessPartner/paymentTerms';
import { Checkbox } from '../../../../shared/inputs/base';
import UploadImgButton from '../../../../shared/SmallComponents/UploadImgButton';
import { RcFile } from 'antd/lib/upload/interface';
import { UploadRequestOption } from 'rc-upload/lib/interface';
import { parseHtmlToJsx } from '../../../../../utils/textEditorUtils';
import { userInfo } from '../../../../../models/entities/user';

type Props = {
    updateBusinessPartnerLogo: (businessPartnerId: string, file: RcFile) => Promise<void>;
    showLogoLoader: boolean;
    businessPartner: businessPartner;
    localization: any;
    settings?: settings;
    users: userInfo[];
};

const CompanyOverview = ({ updateBusinessPartnerLogo, showLogoLoader, businessPartner, localization, settings, users }: Props) => {
    const partyType = settings?.partyTypes?.find((item: any) => item.code === businessPartner.type);
    const creditLine = currencyFormatter(businessPartner.creditLineCurrency)(businessPartner.creditLine);
    const userIdsToUserNameArray = (userslist: string | undefined) => {
        var useridArray: string[] | undefined = userslist?.split(',');
        var result = users
            ?.filter((user: userInfo) => useridArray?.includes(user.id))
            .map((user: userInfo) => user.firstName + ' ' + user.lastName)
            ?.join(', ');
        return result;
    };
    return (
        <OverviewWrapper>
            <OverviewHeaderWrapper>
                <SectionWrapper>
                    <BusinessPartnerWrapper>
                        <div>
                            <BusinessPartnerName>
                                <span>{businessPartner.name}</span> #{businessPartner.BusinessPartnerNumber}
                            </BusinessPartnerName>
                            <UploadImgButton
                                imgUrl={businessPartner.logoUrl}
                                isLoading={showLogoLoader}
                                customRequest={(options: UploadRequestOption<any>) =>
                                    updateBusinessPartnerLogo(businessPartner.id, options.file as RcFile)
                                }
                                localization={{}}
                            />
                        </div>
                    </BusinessPartnerWrapper>
                </SectionWrapper>
            </OverviewHeaderWrapper>
            <DetailsWrapper>
                <DetailsRow>
                    <DetailBox>
                        <DetailTitle>{localization.type}</DetailTitle>
                        <DetailValue>{partyType?.name || businessPartner.type}</DetailValue>
                    </DetailBox>
                </DetailsRow>
                <DetailsRow>
                    <DetailBox>
                        <DetailTitle>{localization.phoneNumber}</DetailTitle>
                        <DetailValue>{businessPartner.phoneNumber || '-'}</DetailValue>
                    </DetailBox>
                    <DetailBox>
                        <DetailTitle>{localization.email}</DetailTitle>
                        <DetailValue>{businessPartner.email || '-'}</DetailValue>
                    </DetailBox>
                    <DetailBox>
                        <DetailTitle>{localization.vatNumber}</DetailTitle>
                        <DetailValue>{businessPartner.vatNumber || '-'}</DetailValue>
                    </DetailBox>
                    <DetailBox>
                        <DetailTitle>{localization.website}</DetailTitle>
                        <DetailValue>{businessPartner.website || '-'}</DetailValue>
                    </DetailBox>
                </DetailsRow>
                <DetailsRow>
                    <DetailBox>
                        <DetailTitle>{localization.taxesDuties}</DetailTitle>
                        <DetailValue>
                            <Checkbox checked={businessPartner.taxesDuties === true} />
                        </DetailValue>
                    </DetailBox>
                    <DetailBox>
                        <DetailTitle>{localization.creditLine}</DetailTitle>
                        <DetailValue>{creditLine && creditLine !== '0' ? creditLine : '-'}</DetailValue>
                    </DetailBox>
                    <DetailBox>
                        <DetailTitle>{localization.monthlyIntrest}</DetailTitle>
                        <DetailValue>{businessPartner.monthlyIntrest || '-'}</DetailValue>
                    </DetailBox>
                </DetailsRow>
                <DetailsRow>
                    <DetailBox>
                        <DetailTitle>{localization.paymentTerms}</DetailTitle>
                        <DetailValue>
                            {businessPartner.paymentTerms ? ePaymentTerms[businessPartner.paymentTerms] || businessPartner.paymentTerms : '-'}
                        </DetailValue>
                    </DetailBox>
                    <DetailBox>
                        <DetailTitle>{localization.paymentTermsRemarks}</DetailTitle>
                        <DetailValue>{businessPartner.paymentTermsRemarks ?? '-'}</DetailValue>
                    </DetailBox>
                </DetailsRow>
                <DetailsRow>
                    <DetailBox>
                        <DetailTitle>{localization.remarks}</DetailTitle>
                        <DetailValue lineClamp={businessPartner.remarks ? 'initial' : undefined}>
                            {businessPartner.remarks ? parseHtmlToJsx(businessPartner.remarks || '', true) : '-'}
                        </DetailValue>
                    </DetailBox>
                    <DetailBox>
                        <DetailTitle>{localization.remarksAlert}</DetailTitle>
                        <DetailValue>
                            <Checkbox checked={businessPartner.remarksAlert === true} />
                        </DetailValue>
                    </DetailBox>
                    <DetailBox>
                        <DetailTitle>{localization.salesman}</DetailTitle>
                        <DetailValue>{userIdsToUserNameArray(businessPartner.salesman)}</DetailValue>
                    </DetailBox>
                </DetailsRow>
                <DetailsRow>
                    <DetailBox>
                        <DetailTitle>{localization.stateupdatenotification}</DetailTitle>
                    </DetailBox>
                    <DetailBox>
                        <DetailValue>{businessPartner.changestatealertvalues}</DetailValue>
                    </DetailBox>
                </DetailsRow>
            </DetailsWrapper>
        </OverviewWrapper>
    );
};

const OverviewWrapper = styled.div`
    display: flex;
    flex-direction: column;
    width: 100%;
`;

const OverviewHeaderWrapper = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    width: 100%;
    border-bottom: solid 1px #e0e0e0;
    padding: 24px 18px 18px 48px;
`;

const SectionWrapper = styled.div``;

const DetailsWrapper = styled.div`
    display: flex;
    flex-direction: column;
    padding: 24px 18px 18px 48px;
`;

const DetailsRow = styled.div`
    display: flex;
    flex-direction: row;
    max-width: 1000px;
`;

const DetailBox = styled.div`
    padding: 0 60px 26px 0;
    min-width: 20%;
    white-space: nowrap;
`;

const DetailTitle = styled.div`
    color: #008ac9;
`;

const DetailValue = styled.div<{ lineClamp?: string | number }>`
    color: #394372;
    text-overflow: ellipsis;
    white-space: normal;
    -webkit-line-clamp: ${(props) => props.lineClamp || 2};
    display: -webkit-box;
    -webkit-box-orient: vertical;
`;
const BusinessPartnerWrapper = styled.div`
    display: flex;
    flex-direction: row;
    width: 100%;
`;

const BusinessPartnerName = styled.div`
    color: ${(props) => props.theme.colors.pageTitle};
    font-size: ${(props) => props.theme.fontSizes.pageTitle};
    font-weight: bold;
    height: 24px;
    margin-left: 10px;
    white-space: nowrap;

    & span {
        @media screen and (max-width: 768px) {
            display: none;
        }
    }
`;

export default CompanyOverview;
