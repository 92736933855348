import { createSelector } from 'reselect';
import { businessPartner, settings as settingsModel } from '../../../models/entities/businessPartner/businessPartner';
import { RootState } from '../../../state/store/store';
import mockData from '../../../sideEffects/company/settings/mockData';
import { contact } from '../../../models/entities/businessPartner/contact';
import { Option } from '../../../components/shared/inputs/base/DropDown';

const businessPartners = (state: RootState) => state.businessPartners.parties;
const error = (state: RootState) => state.businessPartners.errorFetchBusinessPartners;
const settings = (state: RootState) => state.user.userInfo.companySettings;

const currentLanguage = (state: RootState) => state.localization.currentLanguage;
const settingsByLanguage = createSelector(settings, currentLanguage, (settings, currentLanguage) => {
    const mockDataIndex = Object.keys(mockData).findIndex((item) => item === currentLanguage.symbol);
    const settingsObj: settingsModel = Object.values(mockData)[mockDataIndex] as settingsModel;
    if (settings) {
        settings
            .filter((s) => (s.entityType === 'BUSINESS_PARTNER' || !s.entityType) && s.language === currentLanguage.symbol)
            .forEach((s) => {
                settingsObj[s.key as keyof settingsModel] = s.value;
            });
    }
    return settingsObj;
});

const getById = (state: RootState, id: string) => {
    return state.businessPartners.parties?.find((p) => p.id === id);
};

const getBpContactsById = createSelector(businessPartners, (businessPartners) => (id: string) => {
    return businessPartners?.find((p) => p.id === id)?.contacts || [];
});
const getBPRemarksById = createSelector(businessPartners, (businessPartners) => (id: string) => {
    return businessPartners?.find((p) => p.id === id)?.remarks || '';
});
const getBPRemarksAlertById = createSelector(businessPartners, (businessPartners) => (id: string) => {
    return businessPartners?.find((p) => p.id === id)?.remarksAlert || false;
});

const getCargoOwnerLogoById = createSelector(businessPartners, (businessPartners) => (id: string) => {
    return businessPartners?.find((p) => p.id === id)?.logoUrl || '';
});

const getBusinessPartnerContactsEmailsOptions = createSelector(businessPartners, (businessPartners) => (id: string) => {
    const businessPartner = businessPartners?.find((p) => p.id === id);

    if (!businessPartner) return [];

    const opt = (value: string) => ({ text: value, value });
    const trimLower = (value: string) => value.split(' ').join().toLowerCase();

    return (
        businessPartner.contacts?.reduce(
            (options: Option[], contact: contact) => {
                if (!contact.email) return options;

                if (options.some((opt) => trimLower(opt.value) === trimLower(contact.email))) return options;
                return [...options, opt(contact.email)];
            },
            businessPartner.email ? [opt(businessPartner.email)] : []
        ) || []
    );
});

const businessPartnersDropDownOptions = createSelector(businessPartners, (businessPartners): Option[] => {
    return businessPartners.map((bp) => {
        return { value: bp.id, text: bp.name };
    });
});

const activeBusinessPartners = createSelector(businessPartners, (businessPartners) => {
    return businessPartners.filter((p) => !!p.isActive);
});

const getBusinessPartnersByTypes = createSelector(activeBusinessPartners, (businessPartners: businessPartner[]) => (types: string[]) => {
    return businessPartners.filter((p) => types.includes(p.type?.toUpperCase()));
});

const getBusinessPartnersDropDownOptionsByTypes = createSelector(
    activeBusinessPartners,
    (businessPartners: businessPartner[]) => (types: string[]) => {
        return businessPartners
            .filter((p) => types.includes(p.type?.toUpperCase()))
            .map((bp) => {
                return { value: bp.id, text: bp.name };
            });
    }
);

const businessPartnerVatNumbers = createSelector(businessPartners, (businessPartners) => {
    return businessPartners?.map((p) => p.vatNumber);
});
const businessPartnerForwarders = createSelector(activeBusinessPartners, (businessPartners) => {
    return businessPartners?.filter((p) => p.isActive && p.type?.toUpperCase() === 'FORWARDER_BROKER');
});
const businessPartnerBrokers = createSelector(activeBusinessPartners, (businessPartners) => {
    return businessPartners?.filter((p) => p.type?.toUpperCase() === 'FORWARDER_BROKER');
});

const businessPartnerShippers = createSelector(activeBusinessPartners, (businessPartners) => {
    return businessPartners?.filter((p) => p.type?.toUpperCase() === 'CARGO_OWNER');
    //return businessPartners;
});

const businessPartnerCargoOwners = createSelector(activeBusinessPartners, (businessPartners) => {
    return businessPartners?.filter((p) => ['CARGO_OWNER', 'CUSTOMER'].includes(p.type?.toUpperCase()));
    //return businessPartners;
});

const businessPartnerConsignees = createSelector(activeBusinessPartners, (businessPartners) => {
    return businessPartners?.filter((p) => ['WAREHOUSE', 'CUSTOMER', 'SUPPLIER', 'MANUFACTURER', 'CARGO_OWNER'].includes(p.type?.toUpperCase()));
    //return businessPartners;
});

const businessPartnerVendors = createSelector(activeBusinessPartners, (businessPartners) => {
    return businessPartners?.filter((p) => ['WAREHOUSE', 'CUSTOMER', 'SUPPLIER', 'MANUFACTURER'].includes(p.type?.toUpperCase()));
});

const businessPartnerSuppliersManufacturers = createSelector(activeBusinessPartners, (businessPartners) => {
    return businessPartners?.filter((p) => ['SUPPLIER', 'MANUFACTURER'].includes(p.type?.toUpperCase()));
});

const businessPartnerSuppliers = createSelector(activeBusinessPartners, (businessPartners) => {
    return businessPartners?.filter((p) => p.type?.toUpperCase() === 'SUPPLIER');
});

const businessPartnerManufacturers = createSelector(activeBusinessPartners, (businessPartners) => {
    return businessPartners?.filter((p) => p.type?.toUpperCase() === 'MANUFACTURER');
});

const businessPartnerWarehouses = createSelector(activeBusinessPartners, (businessPartners) => {
    return businessPartners?.filter((p) => p.type?.toUpperCase() === 'WAREHOUSE');
});

const businessPartnerCustomers = createSelector(activeBusinessPartners, (businessPartners) => {
    return businessPartners?.filter((p) => p.type?.toUpperCase() === 'CUSTOMER');
});

const businessPartnerForwardersSuppliesrs = createSelector(activeBusinessPartners, (businessPartners) => {
    return businessPartners?.filter((p) => ['SUPPLIER', 'MANUFACTURER', 'FORWARDER', 'FORWARDER_BROKER'].includes(p.type?.toUpperCase()));
});

const businessPartnersWithoutForwarder = createSelector(activeBusinessPartners, (businessPartners) => {
    return businessPartners?.filter((p) => p.type?.toUpperCase() !== 'FORWARDER');
});

export { default as businessPartnersSelectors } from './selectors';

const selectors = {
    businessPartners,
    getById,
    getBusinessPartnersByTypes,
    getBusinessPartnersDropDownOptionsByTypes,
    businessPartnersWithoutForwarder,
    getBpContactsById,
    getBPRemarksById,
    getBPRemarksAlertById,
    getCargoOwnerLogoById,
    businessPartnerForwarders,
    businessPartnerVatNumbers,
    businessPartnerShippers,
    businessPartnerVendors,
    businessPartnerBrokers,
    businessPartnerConsignees,
    businessPartnerSuppliers,
    businessPartnerSuppliersManufacturers,
    businessPartnerManufacturers,
    businessPartnerWarehouses,
    businessPartnerCustomers,
    businessPartnerForwardersSuppliesrs,
    businessPartnerCargoOwners,
    getBusinessPartnerContactsEmailsOptions,
    settingsByLanguage,
    businessPartnersDropDownOptions,
    error
};

export default selectors;
