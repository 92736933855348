import React from 'react';
import { PieChart, Pie, Legend, Cell, ResponsiveContainer, Sector, Text } from 'recharts';
import { styled } from '../../../../shared/Theme/theme';
import { useState } from 'react';
import { pieChartData } from '../../../../../models/entities/dashboard/pieChartData';
import { filterFieldsType } from '../../../../../state/ducks/allPurchaseOrdersPage/allPurchaseOrdersPage';
import { useHistory } from 'react-router-dom';
import { PagesRoutes } from '../../../../../routing/PagesRoutes';
import CZTooltip, { QuestionToolTip } from '../../../../shared/CZTooltip/CZTooltip';
import { css } from 'styled-components';

type Props = {
    chartsData: { [key: string]: pieChartData | undefined };
    setShipmentFilters?: (filter: filterFieldsType) => void;
    setGridFilter?: (text: string) => void;
    localization: any;
    removeLastBorder?: boolean;
};

const renderActiveShape =
    (data: pieChartData | undefined) =>
    ({ cx, cy, midAngle, innerRadius, outerRadius, startAngle, endAngle, fill, payload, percent, value }: any) => {
        const RADIAN = Math.PI / 180;
        const sin = Math.sin(-RADIAN * midAngle);
        const cos = Math.cos(-RADIAN * midAngle);
        const sx = cx + (outerRadius + 10) * cos;
        const sy = cy + (outerRadius + 10) * sin;
        const mx = cx + (outerRadius + 30) * cos;
        const my = cy + (outerRadius + 30) * sin;
        const ex = mx + (cos >= 0 ? 1 : -1) * 22;
        const ey = my;
        const textAnchor = cos >= 0 ? 'start' : 'end';

        return (
            <g style={{ cursor: 'pointer' }}>
                <Text x={cx} y={cy} dy={-15} dx={0} textAnchor="middle" fill={fill} fontSize="18" width={150}>
                    {data?.labelTextFormatter?.(payload.name) || payload.name}
                </Text>
                <text x={cx} y={cy} dy={15} dx={5} textAnchor="middle" fill={fill} fontWeight="bold" fontSize="28">
                    {`${(percent * 100).toFixed(1)}%`}
                </text>
                <Sector cx={cx} cy={cy} innerRadius={innerRadius} outerRadius={outerRadius} startAngle={startAngle} endAngle={endAngle} fill={fill} />
                <Sector
                    cx={cx}
                    cy={cy}
                    startAngle={startAngle}
                    endAngle={endAngle}
                    innerRadius={outerRadius + 6}
                    outerRadius={outerRadius + 10}
                    fill={fill}
                />
                <path d={`M${sx},${sy}L${mx},${my}L${ex},${ey}`} stroke={fill} fill="none" />
                <circle cx={ex} cy={ey} r={2} fill={fill} stroke="none" />
                <text x={ex + (cos >= 0 ? 1 : -1) * 12} y={ey} textAnchor={textAnchor} fill="#333">{`${value}`}</text>
            </g>
        );
    };
const PieCharts = ({ chartsData, localization, removeLastBorder, setShipmentFilters, setGridFilter }: Props) => {
    const initActiveIndexes = Object.keys(chartsData).reduce((obj: { [key: string]: number }, key) => ((obj[key] = 0), obj), {});
    const [chartsActiveIndexes, setChartsActiveIndex] = useState(initActiveIndexes);
    const history = useHistory();

    const setActiveIndex = (chartKey: string, index: number) => {
        setChartsActiveIndex({ ...chartsActiveIndexes, [chartKey]: index });
    };
    const getActiveIndex = (chartKey: string): number => chartsActiveIndexes[chartKey];

    const COLORS = ['#1E75D1', '#D3445B', '#ED9B00', '#2AC18B', '#04BFF7', '#6BC6C9'];

    const renderChart = (chartKey: string) => {
        if (!chartsData[chartKey]) return null;
        const data = chartsData[chartKey]?.data;
        const hasLegend = chartsData[chartKey]?.hasLegend;
        const filterObjectFn = chartsData[chartKey]?.filterObjectFn;
        const filterGridText = chartsData[chartKey]?.filterGridText;

        return (
            <PieContainer key={chartKey}>
                <Title>
                    <div className="title">{localization?.charts?.[chartKey] || chartKey}</div>
                    {!!localization?.charts?.tooltips?.[chartKey] && (
                        <CZTooltip text={localization.charts.tooltips[chartKey]}>{QuestionToolTip}</CZTooltip>
                    )}
                </Title>

                <ResponsiveContainer width="100%" height="100%">
                    <PieChart>
                        <Pie
                            activeIndex={getActiveIndex(chartKey)}
                            activeShape={renderActiveShape(chartsData[chartKey])}
                            data={data}
                            dataKey="value"
                            nameKey="name"
                            innerRadius="65%"
                            outerRadius="75%"
                            fill="#8884d8"
                            onMouseEnter={(data: any, index: number) => {
                                setActiveIndex(chartKey, index);
                            }}
                            onClick={(data: any, index: number) => {
                                chartsData[chartKey]?.onClick?.(data.name);
                                if (filterObjectFn) {
                                    setShipmentFilters?.([{ field: 'isActive', value: 'true' }].concat(filterObjectFn(data.name)));
                                    history.push(PagesRoutes.Shipments);
                                } else if (filterGridText) {
                                    setShipmentFilters?.([{ field: 'isActive', value: 'true' }]);
                                    setGridFilter?.(filterGridText(data.name));
                                    history.push(PagesRoutes.Shipments);
                                }
                            }}
                        >
                            {data?.map((entry, index) => {
                                return <Cell key={`cell-${index}`} fill={COLORS[index % COLORS.length]} style={{ cursor: 'pointer' }} />;
                            })}
                        </Pie>
                        {hasLegend !== false && (
                            <Legend
                                payload={
                                    chartsData[chartKey]?.labelTextFormatter
                                        ? data?.map((item, index) => ({
                                              id: item.name,
                                              type: 'rect',
                                              value: `${chartsData[chartKey]?.labelTextFormatter?.(item.name)}`,
                                              color: COLORS[index % COLORS.length]
                                          }))
                                        : undefined
                                }
                            />
                        )}
                    </PieChart>
                </ResponsiveContainer>
            </PieContainer>
        );
    };

    return (
        <>
            <Container>
                {Object.keys(chartsData)
                    .slice(0, 2)
                    .map((chartKey: string) => renderChart(chartKey))}
            </Container>
            <Container removeLastBorder={removeLastBorder}>
                {Object.keys(chartsData)
                    .slice(2)
                    .map((chartKey: string) => renderChart(chartKey))}
            </Container>
        </>
    );
};
const Container = styled.div<{ removeLastBorder?: boolean }>`
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-wrap: wrap;
    padding-bottom: 15px;
    border-bottom: 1px solid #e4e4e4;

    ${(props) =>
        props.removeLastBorder
            ? css`
                  &:last-child {
                      border-bottom: none;
                  }
              `
            : ''}
    > div {
        margin: 30px 0;
    }
`;

const Title = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;

    .title {
        color: #415a8a;
        font-size: 16px;
        font-weight: 500;
    }
`;
const PieContainer = styled.div`
    flex: 1 1 0;
    width: 0;
    height: 350px;
    text-align: center;
    border-right: 1px solid #e4e4e4;

    &:last-child {
        border: none;
    }
    @media screen and (max-width: 768px) {
        flex: auto;
        height: 300px;
        width: 100%;
        border: none;
    }
`;
export default PieCharts;
