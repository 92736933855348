import { RootState } from '../../../state/store/store';
import { createSelector } from 'reselect';
import shipmentsSelectors from '../shipments/selectors';
import { byDate, byNumber } from '../../../utils/sortArray';
import { party } from '../../../models/entities/common_subentities/party';
import { event } from '../../../models/entities/common_subentities/event';
import { billingLine } from '../../../models/entities/common_subentities/billingLine';
import { document } from '../../../models/entities/common_subentities/document';
import { discussion } from '../../../models/entities/common_subentities/discussion';
import { TimeLineItem } from '../../../components/shared/TimeLine/TimeLine';
import { uniqBy } from 'lodash';
import { allowedCompany } from '../../../models/entities/user';

const currentShipmentId = (state: RootState) => state.singleShipment.shipmentId;
const shipmentPdfSvgIcons = (state: RootState) => state.singleShipment.shipmentPdfSvgIcons;
const products = (state: RootState) => state.products.products;
const fetchShipmentError = (state: RootState) => state.error.effects.shipments.fetchShipmentById;
const isLoadingShipment = (state: RootState) => state.loading.effects.shipments.fetchShipmentById;
const allowedCompanies = (state: RootState) => state.user.userInfo.allowedCompanies as allowedCompany[];

const shipment = createSelector(currentShipmentId, shipmentsSelectors.shipmentsData, (shipmentId, shipments) => {
    return shipments?.find((shipment) => shipment.id === shipmentId);
});
const shipmentItemsRelatedProducts = createSelector(shipment, products, (shipment, products) => {
    return products.filter((product) => shipment?.items?.some((item) => item.productId === product.id));
});
const isAllowedShipment = createSelector(shipment, allowedCompanies, (shipment, allowedCompanies) => {
    return allowedCompanies.some((c) => c.companyId === shipment?.companyId);
});
const shipmentState = createSelector(currentShipmentId, shipmentsSelectors.shipmentsData, (shipmentId, shipments) => {
    const shipment = shipments?.find((shipment) => shipment.id === shipmentId);
    if (shipment) return shipment.state;
});
const parties = createSelector(currentShipmentId, shipmentsSelectors.shipmentsData, (shipmentId, shipments) => {
    const shipment = shipments?.find((item) => item.id === shipmentId);
    if (shipment) return shipment.parties?.sort(byNumber(false, (party: party) => party.id));
});
const events = createSelector(currentShipmentId, shipmentsSelectors.shipmentsData, (shipmentId, shipments) => {
    const shipment = shipments?.find((item) => item.id === shipmentId);
    if (shipment) return shipment.events?.sort(byDate(true, (event: event) => event.eventDate));
});
const billingLines = createSelector(currentShipmentId, shipmentsSelectors.shipmentsData, (shipmentId, shipments) => {
    const shipment = shipments?.find((item) => item.id === shipmentId);
    if (shipment) return shipment.billingLines?.sort(byDate(true, (billingLine: billingLine) => billingLine.date));
});
const documents = createSelector(currentShipmentId, shipmentsSelectors.shipmentsData, (shipmentId, shipments) => {
    const shipment = shipments?.find((item) => item.id === shipmentId);
    if (shipment) return shipment.documents?.documents?.sort(byDate(true, (document: document) => document.createdDate));
});
const discussions = createSelector(currentShipmentId, shipmentsSelectors.shipmentsData, (shipmentId, shipments) => {
    const shipment = shipments?.find((item) => item.id === shipmentId);
    if (shipment) return shipment.discussions?.sort(byDate(true, (discussion: discussion) => discussion.createdDate));
});
const shipmentHistoryUsers = createSelector(currentShipmentId, shipmentsSelectors.shipmentsData, (shipmentId, shipments) => {
    const shipment = shipments?.find((item) => item.id === shipmentId);
    if (shipment?.history)
        return uniqBy(
            shipment.history.map((item) => ({ id: item.userId, picture: item.userPicture })),
            'id'
        );
    return [];
});

const shipmentStateTimelineData = createSelector(
    currentShipmentId,
    shipmentsSelectors.shipmentsData,
    shipmentsSelectors.settingsByLanguage,
    (shipmentId, shipments, settings) => {
        const shipment = shipments?.find((item) => item.id === shipmentId);

        if (shipment) {
            const timelineData: Array<TimeLineItem> = [];
            const currentShipmentState = shipment.state;
            const currentShipmentStateOrder = settings(shipment).state?.find((s) => s.code === currentShipmentState)?.displayOrder ?? 0;

            settings(shipment)
                .state?.filter((s) => s.code !== 'BID')
                .sort((a, b) => a.displayOrder - b.displayOrder)
                .forEach((state) => {
                    const timelineItem: TimeLineItem = {
                        title: state.name,
                        isCompleted: state.displayOrder <= currentShipmentStateOrder,
                        content: {
                            header: state.description
                        }
                    };

                    const historyItem = shipment.history?.find((h) => h.entityType === 'STATE' && (h.data as any)?.state === state.code);

                    if (historyItem) {
                        timelineItem.date = historyItem.createdDate;
                        timelineItem.content = Object.assign(timelineItem.content, {
                            'Inserted By': historyItem.userName
                        });
                    }

                    timelineData.push(timelineItem);
                });

            const historyBidItem = shipment.history?.find((h) => h.entityType === 'STATE' && (h.data as any)?.state === 'BID');

            if (historyBidItem) {
                const bidStateSettings = settings(shipment).state?.find((s) => s.code === 'BID');

                if (bidStateSettings) {
                    timelineData.splice(1, 0, {
                        title: bidStateSettings.name,
                        isCompleted: true,
                        date: historyBidItem.createdDate,
                        content: {
                            header: bidStateSettings.description,
                            'Inserted By': historyBidItem.userName
                        }
                    });
                }
            }

            return timelineData;
        }
    }
);

export { default as singleShipmentSelectors } from './selectors';

export default {
    shipmentPdfSvgIcons,
    isAllowedShipment,
    fetchShipmentError,
    isLoadingShipment,
    shipment,
    shipmentState,
    shipmentStateTimelineData,
    parties,
    events,
    billingLines,
    documents,
    discussions,
    shipmentHistoryUsers,
    shipmentItemsRelatedProducts,
    currentShipmentId
};
