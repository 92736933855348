import React from 'react';
import styled from 'styled-components';
import { ColumnType } from '../../components/shared/Grid/types/Column';
import { product } from '../../models/entities/product/product';
import { Image } from 'antd';
import ProductMarketplaceAsin from '../../components/shared/Grid/specialColumns/ProductMarketplaceAsin';

const mockColumns: Array<ColumnType<product>> = [
    {
        field: 'name',
        sortable: true,
        isDisplayed: true,
        width: '200px',
        columnType: 'fakeLink'
    },
    {
        field: 'sku',
        sortable: true,
        isDisplayed: true
    },
    {
        field: 'asin',
        sortable: true,
        isDisplayed: true,
        width: '160px',
        hideTitleAttribute: true,
        renderer: (cellValue: any) => {
            return <ProductMarketplaceAsin marketplaceAsin={cellValue} />;
        }
    },
    {
        field: 'latestProductImgUrl',
        sortable: true,
        isDisplayed: true,
        textAlign: 'center',
        renderer: (cellValue: any, bindedData?: Array<any> | null) => {
            if (!cellValue || typeof cellValue !== 'string' || cellValue === '-') return <div>-</div>;
            return (
                <ImgContainer
                    onClick={(e) => {
                        e.stopPropagation();
                    }}
                >
                    <Image height={35} src={cellValue as string} />
                </ImgContainer>
            );
        }
    },
    {
        field: 'supplierName',
        sortable: true,
        isDisplayed: true
    },
    {
        field: 'description',
        sortable: true,
        isDisplayed: true
    },
    {
        field: 'brand',
        sortable: true,
        isDisplayed: true
    },
    {
        field: 'madeIn',
        sortable: true,
        isDisplayed: true,
        columnType: 'country'
    },
    {
        field: 'cost',
        sortable: true,
        isDisplayed: true,
        bindedFields: ['costCurrency'],
        columnType: 'currency_input'
    },
    {
        field: 'retailPrice',
        sortable: true,
        isDisplayed: true,
        bindedFields: ['retailPriceCurrency'],
        columnType: 'currency_input'
    },
    {
        field: 'salePrice',
        sortable: true,
        isDisplayed: true,
        bindedFields: ['salePriceCurrency'],
        columnType: 'currency_input'
    },
    {
        field: 'weight',
        sortable: true,
        isDisplayed: true,
        bindedFields: ['weight', 'weightUnit'],
        columnType: 'weight'
    },
    {
        field: 'dimensionUnit',
        bindedFields: ['length', 'width', 'height', 'dimensionUnit'],
        sortable: true,
        isDisplayed: true,
        columnType: 'dimensions'
    },
    {
        field: 'note',
        sortable: true,
        isDisplayed: true
    }
];

const ImgContainer = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 100%;

    .ant-image-mask-info {
        font-size: 0;
        display: flex;
        justify-content: center;
        align-items: center;
    }

    .ant-image-mask-info .anticon {
        font-size: 14px;
        margin-inline-end: 0;
    }
`;

export default mockColumns;
