import { tariffCharge, createTariffChargePayload } from '../../../../models/entities/tariff/charge';
import BaseController from '../..';
import ITariffCharges from './ITariffCharges';

export default class TariffCharges extends BaseController implements ITariffCharges {
    async fetchChargesByTariffId(tariffId: string): Promise<tariffCharge[]> {
        const response = await this.client.get(`/tariff/charges/${tariffId}`);
        return response;
    }

    async createTariffCharge(tariffCharge: createTariffChargePayload): Promise<tariffCharge> {
        const response = await this.client.post(`/tariff/charges`, JSON.stringify(tariffCharge));
        return response;
    }
}
