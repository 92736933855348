import React, { useState, useRef, useCallback, useEffect } from 'react';
import styled from 'styled-components';
import { EditorState, getDefaultKeyBinding, DraftHandleValue, ContentBlock, convertToRaw } from 'draft-js';
import Editor from '@draft-js-plugins/editor';
import createToolbarPlugin from '@draft-js-plugins/static-toolbar';
import { BoldButton, UnorderedListButton, OrderedListButton } from '@draft-js-plugins/buttons';
import createMentionPlugin, { defaultSuggestionsFilter } from '@draft-js-plugins/mention';
import { MentionData } from '@draft-js-plugins/mention';
import '@draft-js-plugins/static-toolbar/lib/plugin.css';
import 'draft-js/dist/Draft.css';
import '@draft-js-plugins/mention/lib/plugin.css';
import createEmojiPlugin from '@draft-js-plugins/emoji';
import { CSSProperties } from '@material-ui/core/styles/withStyles';
import '@draft-js-plugins/emoji/lib/plugin.css';
import UserMentionEntryComponent from '../../TextEditorMentions/UserMention/UserMentionEntryComponent';
import UserMentionComponent from '../../TextEditorMentions/UserMention/UserMentionComponent';
import EntityMentionEntryComponent from '../../TextEditorMentions/EntityMention/EntityMentionEntryComponent';
import EntityMentionComponent from '../../TextEditorMentions/EntityMention/EntityMentionComponent';
import { getEntitySuggestions } from '../../TextEditorMentions/EntityMention/utils';
import atIcon from '../../../../static/icons/at.svg';
import attachmentIcon from '../../../../static/icons/attachment.svg';
import emojiIcon from '../../../../static/icons/emoji.svg';
import { ToolbarChildrenProps } from '@draft-js-plugins/static-toolbar/lib/components/Toolbar';
import { insertTextToEditor } from '../../../../utils/textEditorUtils';
import { connect } from 'react-redux';
import { RootState } from '../../../../state/store/store';
import { userSelectors } from '../../../../state/ducks/user/selectors';
import { allShipmentsPageSelectors } from '../../../../state/ducks/allShipmentsPage/selectors';
import { shipment } from '../../../../models/entities/shipment/shipment';
import { userInfo } from '../../../../models/entities/user';
import { usersSelectors } from '../../../../state/ducks/users/selectors';
import { userDetails } from '../../../../state/ducks/users/users';
import { messageAttachment } from '../../../../models/entities/chat/chat';
import FileExtIcon from '../../Grid/specialColumns/FileExtIcon';
import createLinkDetectionPlugin from '../../../../utils/draftJsPlugins/draft-js-link-detection-plugin';
import { purchaseOrder } from '../../../../models/entities/purchaseOrder/purchaseOrder';
import { purchaseDemand } from '../../../../models/entities/purchaseDemand/purchaseDemand';

export type BaseProps = {
    id: string;
    editorState: EditorState;
    hasError?: boolean;
    canSubmit?: boolean;
    style?: CSSProperties;
    placeholder?: string;
    readOnly?: boolean;
    editAttachments?: messageAttachment[];
    currentAttachments?: messageAttachment[];
    hasAttachments?: boolean;
    usersInSameNetwork?: userDetails[];
    onChange: (editorState: EditorState) => void;
    getUserById?: (userId: string) => userDetails | undefined;
    onSubmit?: (attachments?: messageAttachment[]) => void;
    onInsertText?: (text: string) => void;
    blockRendererFn?: (block: ContentBlock) => any;
    onAttachmentsChange?: (attachments?: messageAttachment[]) => void;
    searchShipmentsByCZNumber?: (czNumber: string) => Promise<shipment[]>;
    searchPurchaseOrdersByCZNumber?: (czNumber: string) => Promise<purchaseOrder[]>;
    searchPurchaseDemandsByCZNumber?: (czNumber: string) => Promise<purchaseDemand[]>;
};

const TextEditor = ({
    id,
    editorState,
    canSubmit,
    style,
    readOnly,
    placeholder,
    editAttachments,
    currentAttachments,
    hasAttachments,
    usersInSameNetwork,
    onChange,
    getUserById,
    onSubmit,
    onInsertText,
    blockRendererFn,
    onAttachmentsChange,
    searchShipmentsByCZNumber,
    searchPurchaseOrdersByCZNumber,
    searchPurchaseDemandsByCZNumber
}: BaseProps) => {
    const editorRef = useRef<Editor | null>(null);

    // User Mentions
    const userMentions = usersInSameNetwork as MentionData[] | undefined;
    const [userMentionsOpen, setOpenUserMentionsOpen] = useState(false);
    const [userSuggestions, setUserSuggestions] = useState(userMentions);

    // Entity Mentions (Shipment, PO, PD)
    const [entityMentionsOpen, EntityMentionsOpen] = useState(false);
    const [entitySuggestions, setEntitySuggestions] = useState<MentionData[]>([]);
    const [entityMentionSearchValue, EntityMentionSearchValue] = useState<string | null>(null);

    //Editor Attachments
    const [editorAttachments, setEditorAttachments] = useState<messageAttachment[]>(currentAttachments || []);
    const [editEditorAttachments, setEditEditorAttachments] = useState<messageAttachment[] | undefined>(editAttachments);
    const attachments = !!editEditorAttachments ? editEditorAttachments : editorAttachments;
    const setAttachments = !!editEditorAttachments ? setEditEditorAttachments : setEditorAttachments;

    useEffect(() => {
        const delayDebounceFn = setTimeout(async () => {
            if (searchShipmentsByCZNumber && searchPurchaseOrdersByCZNumber && searchPurchaseDemandsByCZNumber) {
                const suggestions = await getEntitySuggestions(
                    entityMentionSearchValue,
                    searchShipmentsByCZNumber,
                    searchPurchaseOrdersByCZNumber,
                    searchPurchaseDemandsByCZNumber
                );
                if (suggestions) setEntitySuggestions(suggestions);
            }
        }, 500);

        return () => clearTimeout(delayDebounceFn);
    }, [entityMentionSearchValue]);

    const [{ plugins, Toolbar, UserMentionSuggestions, EntityMentionSuggestions, EmojiSelect }] = useState(() => {
        const positionSuggestions = (position: any): CSSProperties => {
            let leftAdjustment = 0;
            if (position.decoratorRect.left + position.popover.clientWidth > window.innerWidth - 68) {
                leftAdjustment = position.popover.clientWidth - (window.innerWidth - position.decoratorRect.left - 68) + 10;
            }
            return {
                left: position.decoratorRect.left - leftAdjustment + 'px',
                top: position.decoratorRect.top - 10 + 'px', // change this value (40) for manage the distance between cursor and bottom edge of popover
                display: 'block',
                transform: 'scale(1) translateY(-100%)',
                transformOrigin: '1em 0% 0px',
                transition: 'all 0.25s cubic-bezier(0.3, 1.2, 0.2, 1)',
                position: 'fixed'
            };
        };

        // Toolbar plugin
        const staticToolbarPlugin = createToolbarPlugin();
        const { Toolbar } = staticToolbarPlugin;

        // Emoji plugin
        const emojiPlugin = createEmojiPlugin({
            useNativeArt: true,
            positionSuggestions: positionSuggestions,
            selectButtonContent: <img src={emojiIcon} alt="Emoji" />
        });
        const { EmojiSuggestions, EmojiSelect } = emojiPlugin;

        // User Mentions plugin
        const userMentionPlugin = createMentionPlugin({
            entityMutability: 'IMMUTABLE',
            mentionPrefix: '@',
            mentionComponent: UserMentionComponent,
            positionSuggestions: positionSuggestions
        });
        const { MentionSuggestions: UserMentionSuggestions } = userMentionPlugin;

        // Entity Mentions plugin
        const entityMentionPlugin = createMentionPlugin({
            entityMutability: 'IMMUTABLE',
            mentionPrefix: '#',
            mentionTrigger: '#',
            mentionComponent: EntityMentionComponent,
            positionSuggestions: positionSuggestions
        });
        const { MentionSuggestions: EntityMentionSuggestions } = entityMentionPlugin;

        const linkDetectionPlugin = createLinkDetectionPlugin();
        const plugins = [userMentionPlugin, entityMentionPlugin, staticToolbarPlugin, emojiPlugin, linkDetectionPlugin];

        return { plugins, Toolbar, UserMentionSuggestions, EntityMentionSuggestions, EmojiSelect };
    });

    const onOpenUserMentionsChange = useCallback((_open: boolean) => {
        setOpenUserMentionsOpen(_open);
    }, []);
    const onSearchUserMentionsChange = useCallback(({ value }: { value: string }) => {
        if (userMentions) setUserSuggestions(defaultSuggestionsFilter(value, userMentions));
    }, []);
    const onOpenEntityMentionsChange = useCallback((_open: boolean) => {
        EntityMentionsOpen(_open);
    }, []);
    const onSearchEntityMentionsChange = useCallback(async ({ value }: { value: string }) => {
        EntityMentionSearchValue(value);
    }, []);

    return (
        <EditorWrapper style={style}>
            <div
                onClick={() => {
                    editorRef.current!.focus();
                }}
            >
                <div className="editor">
                    <Editor
                        editorState={editorState}
                        onChange={(state) => {
                            onChange(state);
                        }}
                        keyBindingFn={
                            userMentionsOpen || entityMentionsOpen
                                ? undefined
                                : (e: any): string | null => {
                                      if (e.keyCode === 13 && !e.shiftKey) return 'editor-submit';
                                      return getDefaultKeyBinding(e);
                                  }
                        }
                        handleKeyCommand={(command: string): DraftHandleValue => {
                            if (command === 'editor-submit' && !!onSubmit && (canSubmit || !!attachments?.length)) {
                                onSubmit(attachments);
                                setAttachments([]);
                                return 'handled';
                            }
                            return 'not-handled';
                        }}
                        plugins={plugins}
                        ref={editorRef}
                        spellCheck={true}
                        readOnly={readOnly}
                        placeholder={placeholder}
                        blockRendererFn={blockRendererFn}
                    />
                    <div style={{ visibility: userSuggestions?.length ? 'visible' : 'hidden' }}>
                        <UserMentionSuggestions
                            open={userMentionsOpen}
                            onOpenChange={onOpenUserMentionsChange}
                            suggestions={userSuggestions || []}
                            onSearchChange={onSearchUserMentionsChange}
                            entryComponent={UserMentionEntryComponent}
                            onAddMention={() => {
                                // get the mention object selected
                            }}
                        />
                    </div>

                    <div style={{ visibility: entitySuggestions.length ? 'visible' : 'hidden' }}>
                        <EntityMentionSuggestions
                            open={entityMentionsOpen}
                            onOpenChange={onOpenEntityMentionsChange}
                            suggestions={entitySuggestions}
                            onSearchChange={onSearchEntityMentionsChange}
                            entryComponent={EntityMentionEntryComponent}
                            onAddMention={() => {
                                // get the mention object selected
                            }}
                        />
                    </div>
                </div>
                {!!attachments?.length && (
                    <AttachmentsContainer>
                        {attachments.map((att, index) => (
                            <AttachmentContainer key={index}>
                                <FileExtIcon filename={att.name} iconWidth="14px"></FileExtIcon>
                                <span
                                    className="material-icons"
                                    onClick={() => setAttachments(attachments.filter((a: messageAttachment, i: number) => i !== index))}
                                >
                                    close
                                </span>
                            </AttachmentContainer>
                        ))}
                    </AttachmentsContainer>
                )}
                {!readOnly && (
                    <Toolbar>
                        {(externalProps: ToolbarChildrenProps) => (
                            <>
                                <BoldButton {...externalProps} />
                                <UnorderedListButton {...externalProps} />
                                <OrderedListButton {...externalProps} />
                                <EmojiSelect />

                                <div className="bi09khh">
                                    <button
                                        type="button"
                                        onClick={(e) => {
                                            const currentState = editorRef?.current?.getEditorState();
                                            if (currentState) {
                                                e.stopPropagation();
                                                onChange(insertTextToEditor(currentState, ' @'));
                                            }
                                        }}
                                        className="bc4rxid"
                                    >
                                        <img src={atIcon} alt="At" />
                                    </button>
                                </div>
                                <div className="bi09khh">
                                    <button
                                        type="button"
                                        onClick={(e) => {
                                            const currentState = editorRef?.current?.getEditorState();
                                            if (currentState) {
                                                e.stopPropagation();
                                                onChange(insertTextToEditor(currentState, ' #'));
                                            }
                                        }}
                                        className="bc4rxid"
                                    >
                                        #
                                    </button>
                                </div>
                                {hasAttachments !== false && (
                                    <div className="bi09khh">
                                        <label
                                            className="bc4rxid"
                                            htmlFor={`message-attachments-input-${id}`}
                                            onClick={(e) => {
                                                e.stopPropagation();
                                            }}
                                        >
                                            <img src={attachmentIcon} alt="At" />
                                        </label>
                                        <input
                                            id={`message-attachments-input-${id}`}
                                            type="file"
                                            name="file"
                                            style={{ display: 'none' }}
                                            multiple={true}
                                            onChange={(event) => {
                                                if (event && event.currentTarget && event.currentTarget.files) {
                                                    const files: FileList = event.currentTarget.files;
                                                    const att: messageAttachment[] = [];
                                                    Array.from(files).forEach((file) => {
                                                        attachments.push({ file, name: file.name });
                                                    });
                                                    setAttachments([...attachments, ...att]);
                                                    onAttachmentsChange && onAttachmentsChange([...attachments, ...att]);
                                                }
                                            }}
                                        />
                                    </div>
                                )}

                                {!!onSubmit && (
                                    <div className="bi09khh submit-btn">
                                        <button
                                            onClick={(e) => {
                                                e.stopPropagation();

                                                onSubmit(attachments);
                                                setAttachments([]);
                                                onAttachmentsChange && onAttachmentsChange([]);
                                            }}
                                            className="bc4rxid"
                                            disabled={!canSubmit && !attachments?.length}
                                        >
                                            <span className="material-icons">send</span>
                                        </button>
                                    </div>
                                )}
                            </>
                        )}
                    </Toolbar>
                )}
            </div>
        </EditorWrapper>
    );
};

const EditorWrapper = styled.div`
    background-color: #fff;
    border: 1px solid #008ac9;
    border-radius: 15px;

    .editor {
        box-sizing: border-box;
        cursor: text;
        padding: 10px;
    }

    .editor :global(.public-DraftEditor-content) {
        min-height: 140px;
    }

    .t16lpgj {
        background: none;
        border: none;
        box-shadow: none;
        display: flex;
        align-items: center;
    }
    .e6nwac2 {
        width: 32px;
        height: 36px;
        font-size: 24px;
    }
    .bc4rxid,
    .e8k2yoa,
    .e13wqaj6 {
        background: none;
        color: inherit;
        border: none;
        padding: 0;
        font: inherit;
        cursor: pointer;
        outline: inherit;
        display: flex;
        align-items: center;
        justify-content: center;
        color: #008ac9;
        height: 34px;
        width: 36px;
        font-size: 24px;
        & svg {
            fill: #008ac9b3;
        }
    }

    button:disabled,
    button[disabled] {
        cursor: initial;
        color: #008ac9b3;
    }
    .ejr02pv {
        transform: translateY(-110%) translateX(-20%);
    }

    .akzb7t5 {
        & svg {
            fill: #008ac9 !important;
        }
    }
    .submit-btn {
        margin-left: auto;
    }

    .toolbar-btn-wrapper {
        display: inline-block;
    }
    .toolbar-btn {
        background: #fbfbfb;
        color: #888;
        font-size: 18px;
        border: 0;
        padding-top: 5px;
        vertical-align: bottom;
        height: 34px;
        width: 36px;
    }

    .toolbar-btn:hover,
    .toolbar-btn:focus {
        background: #f3f3f3;
    }
`;

const AttachmentsContainer = styled.div``;
const AttachmentContainer = styled.div`
    padding: 3px 8px;
    background-color: #e1e1e145;
    margin: 1px 0px;
    font-size: 12px;
    display: flex;
    align-items: center;
    .material-icons {
        margin-left: auto;
        font-size: 12px;
        text-align: right;
        color: #a2a2a2;
        cursor: pointer;
        transition: 0.1s;

        &:hover {
            color: black;
        }
    }
`;
const mapStateToProps = (state: RootState) => ({
    userInfo: userSelectors.userInfo(state),
    shipments: allShipmentsPageSelectors.gridData(state),
    getUserById: (id: string) => usersSelectors.getUserById(state)(id),
    usersInSameNetwork: userSelectors.usersInSameNetwork(state)
});

const mapDispatchToProps = (dispatch: any) => ({
    searchShipmentsByCZNumber: (czNumber: string) => dispatch.shipments.fetchShipmentsByCZNumber(czNumber),
    searchPurchaseOrdersByCZNumber: (czNumber: string) => dispatch.purchaseOrders.fetchPurchaseOrdersByCZNumber(czNumber),
    searchPurchaseDemandsByCZNumber: (czNumber: string) => dispatch.purchaseDemands.fetchPurchaseDemandsByCZNumber(czNumber)
});

export default connect(mapStateToProps, mapDispatchToProps)(TextEditor);
