import { history } from '../../../../models/entities/common_subentities/history';

type stateType = { [key: string]: any };
function setHistory(state: stateType, entityId: string, entityType: keyof stateType, history: Array<history>) {
    const newArr = [...state[entityType]];
    const index = newArr.findIndex((item) => item.id === entityId);
    if (index !== -1) {
        newArr[index].history = history;
    }
    return newArr;
}
export { setHistory };
