export const eCommodityType: { [key: string]: string } = {
    '': '',
    dangerous: 'Dangerous',
    perishable: 'Perishable',
    temperature: 'Temperature Controlled',
    sensitive: 'Sensitive',
    dryIce: 'Dry Ice',
    personalEffects: 'Personal Effects',
    liveAnimals: 'Live Animals',
    valuable: 'Valuable',
    humanRemains: 'Human Remains',
    nonStackable: 'Non Stackable'
};
