import * as Yup from 'yup';

export const validationSchema = (localization: any) => {
    const requiredFieldMessage = localization.required_field_message;
    return Yup.object().shape({
        name: Yup.string().required(requiredFieldMessage).nullable().test('len', localization.max_length_message(100), maxLengthValidation(100)),
        skuAdjustment: Yup.string().nullable().test('len', localization.max_length_message(150), maxLengthValidation(150)),
        costAdjustment: Yup.number().nullable(),
        costAdjustmentCurrency: Yup.string().nullable(),
        priceAdjustment: Yup.number().nullable(),
        priceAdjustmentCurrency: Yup.string().nullable(),
        weightAdjustment: Yup.number().nullable(),
        weightAdjustmentUnit: Yup.string().nullable(),
        heightAdjustment: Yup.number().nullable(),
        widthAdjustment: Yup.number().nullable(),
        lengthAdjustment: Yup.number().nullable(),
        dimensionAdjustmentUnit: Yup.string().nullable().test('len', localization.max_length_message(20), maxLengthValidation(20)),
        displayOrder: Yup.number().nullable(),
        isDefault: Yup.boolean().nullable()
    });
};

const maxLengthValidation = (maxLength: number) => {
    return (val: any) => (val ? val.toString().length <= maxLength : true);
};
