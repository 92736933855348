import React, { useEffect } from 'react';
import { styled } from '../../../shared/Theme/theme';
import { Formik, Form } from 'formik';
import Loader from '../../../shared/SmallComponents/Loader';
import { FormTextInput } from '../../../shared/inputs/form';
import { match, useHistory } from 'react-router-dom';
import Button from '../../../shared/SmallComponents/Button';
import FormFieldLabel from '../../../shared/Form/FormFieldLabel';
import { createUserSchema } from '../../../../validationSchemas/userRegistration/createUserForm';
import { getStartedCreateCompanyPayload } from '../../../../models/entities/company/getStartedCreateCompanyPayload';
import { Location } from 'history';
import qs from 'querystring';
import { LocationStateType } from '../GetStartedPageConnected';

type Props = {
    localization: any;
    match: match;
    createCompany: (payload: getStartedCreateCompanyPayload) => Promise<void>;
    isCreateCompany: boolean;
    location: Location<LocationStateType>;
};

const CreateUserForm = ({ localization, match, createCompany, isCreateCompany, location }: Props) => {
    const history = useHistory();

    const fieldStyle = { height: '44px', width: 360, fontSize: 18 };

    useEffect(() => {
        if (!location.state?.email || !location.state?.companyDetails?.name || !location.state?.companyDetails?.type) history.replace(`${match.url}`);
    }, [Object.values(location?.state || {})]);

    const initialValues = {
        firstName: '',
        lastName: ''
    };
    return (
        <Formik
            initialValues={initialValues}
            validationSchema={createUserSchema(localization)}
            onSubmit={async ({ firstName, lastName }) => {
                const { name, type } = location.state.companyDetails;
                const { email } = location.state;
                await createCompany({
                    email,
                    name,
                    type,
                    userFirstName: firstName,
                    userLastName: lastName,
                    underForwarderToken: qs.parse(location.search.replace('?', '')).token as string
                });
                history.push({
                    pathname: `${match.url}/success`,
                    search: location.search,
                    state: { userHasRegistered: true }
                });
            }}
        >
            {(formik) => {
                const { errors, touched, isValid } = formik;

                return (
                    <Form style={{ width: '100%', display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center' }}>
                        <Title>{localization.title}</Title>
                        <SubTitle>{localization.sub_title}</SubTitle>
                        <FieldContainer>
                            <FormFieldLabel text={localization.first_name} isMandatory={true} />
                            <FormTextInput name="firstName" placeHolder={localization.first_name} style={fieldStyle} />
                        </FieldContainer>
                        <FieldContainer>
                            <FormFieldLabel text={localization.last_name} isMandatory={true} />
                            <FormTextInput name="lastName" placeHolder={localization.last_name} style={fieldStyle} />
                        </FieldContainer>
                        <Button widthMobile="280px" height="44px" margin="30px 0" width="360px" buttonType="filled" type="submit" disabled={!isValid}>
                            {isCreateCompany ? <Loader width="20px" marginTop="0px" showText={false} /> : <span>{localization.create_btn}</span>}
                        </Button>
                    </Form>
                );
            }}
        </Formik>
    );
};

const Title = styled.div`
    font-weight: 700;
    font-size: 48px;
    line-height: 46px;
    max-width: 700px;
    text-align: center;
    letter-spacing: -0.75px;
    margin-bottom: 10px;
`;

const SubTitle = styled.div`
    font-size: 18px;
    line-height: 27px;
    margin-bottom: 32px;
    color: #454245;
    max-width: 700px;
    text-align: center;
`;

const Bottom = styled.div`
    margin-top: 24px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
`;

const FieldContainer = styled.div`
    margin-top: 12px;

    .ant-picker-input > input {
        font-size: 18px !important;
    }
`;

export default CreateUserForm;
