import React from 'react';
import { FormCheckbox, FormDatePicker, FormDropDown, FormTextArea, FormTextInput } from '../../../../shared/inputs/form';
import { renderPurchaseOrderTypeOption } from '../../../../shared/SmallComponents/PurchaseOrderTypeOption';
import { FieldContainer, Flex, renderFieldLabel } from '../PurchaseOrderForm';
import { ePurchaseOrderMode } from '../../../../../models/entities/purchaseOrder/purchaseOrderMode';
import { eIncoterms } from '../../../../../models/entities/purchaseOrder/incoterms';
import { enumToDropdownOptions, Option } from '../../../../shared/inputs/base/DropDown';
import References from './References';
import { styled } from '../../../../shared/Theme/theme';
import { businessPartner } from '../../../../../models/entities/businessPartner/businessPartner';
import CreateBusinessPartner from './BusinessPartners/CreateBusinessPartner';
import { partiesToDropdownOptions } from '../../../businessPartners/components/BusinessPartnerForm/BusinessPartnerFormConnected';
import {
    getAllowedTypes,
    handlePickupAddress,
    updateDestinationPickupAddress,
    updateOriginPickupAddress,
    updatePickupAddress,
    updateContactDetails,
    getBusinessPartnerDefaultAddressAndContact
} from './utils';
import { allowedCompany } from '../../../../../models/entities/user';
import { eContainerCapacity, eInlandSubTypes } from '../../../../../models/entities/shipment/containerCapacity';
import { ePurchaseOrderFormMode } from '../PurchaseOrderFormConnected';
import { ePaymentTerms } from '../../../../../models/entities/businessPartner/paymentTerms';

const purchaseOrderTypesDropDownOptions = enumToDropdownOptions(ePurchaseOrderMode);
const incotermsDropDownOptions = enumToDropdownOptions(eIncoterms);
const containerCapacityDropDownOptions = enumToDropdownOptions(eContainerCapacity);
const inlandSubTypesDropDownOptions = enumToDropdownOptions(eInlandSubTypes);

type Props = {
    values: any;
    setFieldValue: any;
    businessPartnerSuppliers?: Array<businessPartner>;
    businessPartnerSuppliersManufacturers?: Array<businessPartner>;
    businessPartnerManufacturers?: Array<businessPartner>;
    businessPartnerConsignees?: Array<businessPartner>;
    businessPartnerForwarders?: Array<businessPartner>;
    businessPartnerWarehouses?: Array<businessPartner>;
    localizations?: any;
    allowedTypes: Array<string>;
    allowedCompanies: allowedCompany[];
    allowedCompaniesDropDownOptions: Option[];
    mode: string;
};
const General = ({
    values,
    setFieldValue,
    businessPartnerSuppliers,
    businessPartnerSuppliersManufacturers,
    businessPartnerConsignees,
    businessPartnerForwarders,
    businessPartnerWarehouses,
    businessPartnerManufacturers,
    localizations,
    allowedTypes,
    allowedCompanies,
    allowedCompaniesDropDownOptions,
    mode
}: Props) => {
    const types = allowedTypes;
    const allowedPurchaseOrderTypes: Option[] = getAllowedTypes(purchaseOrderTypesDropDownOptions, types);

    return (
        <GeneralFormSection>
            <div style={{ borderRight: '1px solid #e0e0e0', paddingRight: '30px' }}>
                {allowedCompanies.length > 1 && (
                    <FieldContainer style={{ display: 'flex', flexDirection: 'column' }}>
                        <div>{renderFieldLabel('Company', true)}</div>
                        <FormDropDown
                            name="companyId"
                            style={{ width: '200px', marginRight: '15px' }}
                            placeHolder="Pick company"
                            options={allowedCompaniesDropDownOptions}
                            onChange={(value: any) => {
                                setFieldValue('companyName', allowedCompanies?.find((item) => item.companyId === value)?.companyName);

                                if (values.consignee_same_as_company) {
                                    const consigneeAddresses = allowedCompanies.find((c) => c.companyId === value)?.addresses || [];

                                    const defaultAddress =
                                        consigneeAddresses.find((item) => item.isDefault) ||
                                        (consigneeAddresses.length === 1 && consigneeAddresses[0]);
                                    if (defaultAddress && mode === ePurchaseOrderFormMode.NEW) {
                                        setFieldValue('consignee_addresses', defaultAddress.id);
                                        updatePickupAddress('destination', setFieldValue, defaultAddress, values);
                                    }
                                }
                            }}
                        />
                    </FieldContainer>
                )}

                <FieldContainer>
                    <div>{renderFieldLabel('Vendor', true, localizations.form.shipper_tooltip)}</div>
                    <Flex>
                        <FormDropDown
                            name="vendorId"
                            style={{ width: '200px' }}
                            placeHolder="Pick supplier"
                            options={partiesToDropdownOptions(businessPartnerSuppliersManufacturers)}
                            showSearch
                            onChange={(value: string) => {
                                const {
                                    businessPartner: vendor,
                                    defaultAddress,
                                    defaultContact
                                } = getBusinessPartnerDefaultAddressAndContact(businessPartnerSuppliersManufacturers, value);

                                if (defaultAddress && mode === ePurchaseOrderFormMode.NEW) {
                                    setFieldValue('vendor_addresses', defaultAddress.id);
                                    updatePickupAddress('origin', setFieldValue, defaultAddress, values);
                                }

                                if (defaultContact && mode === ePurchaseOrderFormMode.NEW) {
                                    setFieldValue('vendor_contacts', defaultContact.id);
                                    updateContactDetails('vendor', setFieldValue, defaultContact, values);
                                }

                                if (vendor?.paymentTerms) setFieldValue('payment_terms', [vendor.paymentTerms]);
                                if (vendor?.paymentTermsRemarks) setFieldValue('note', vendor.paymentTermsRemarks);
                            }}
                        />
                        <CreateBusinessPartner
                            type="SUPPLIER"
                            modalTitle={localizations.form.parties.modal.create + ' ' + localizations.form.parties.modal['VENDOR']}
                            submitCallback={(businessPartner: businessPartner) => {
                                setFieldValue('vendorId', businessPartner.id);
                            }}
                        />
                    </Flex>
                </FieldContainer>
                {!values.consignee_same_as_company && (
                    <FieldContainer>
                        <div>{renderFieldLabel(localizations.form.consignee, false, localizations.form.consignee_tooltip)}</div>
                        <Flex>
                            <FormDropDown
                                name="consigneeId"
                                style={{ width: '200px' }}
                                placeHolder={localizations.form.consignee_placeholder}
                                options={partiesToDropdownOptions(businessPartnerConsignees || [])}
                                showSearch
                                onChange={(value: string) => {
                                    const consigneeAddresses = businessPartnerConsignees?.find((item) => item.id === value)?.addresses || [];
                                    const defaultAddress =
                                        consigneeAddresses.find((item) => item.isDefault) ||
                                        (consigneeAddresses.length === 1 && consigneeAddresses[0]);
                                    if (defaultAddress && mode === ePurchaseOrderFormMode.NEW) {
                                        setFieldValue('consignee_addresses', defaultAddress.id);
                                        updatePickupAddress('destination', setFieldValue, defaultAddress, values);
                                    }

                                    const consigneeContacts = businessPartnerConsignees?.find((item) => item.id === value)?.contacts || [];
                                    const defaultContact = consigneeContacts.length === 1 && consigneeContacts[0];
                                    if (defaultContact && mode === ePurchaseOrderFormMode.NEW) {
                                        setFieldValue('consignee_contacts', defaultContact.id);
                                        updateContactDetails('consignee', setFieldValue, defaultContact, values);
                                    }
                                }}
                            />
                            <CreateBusinessPartner
                                type="CARGO_OWNER"
                                modalTitle={localizations.form.parties.modal.create + ' ' + localizations.form.parties.modal['CONSIGNEE']}
                                submitCallback={(businessPartner: businessPartner) => {
                                    setFieldValue('consigneeId', businessPartner.id);
                                }}
                            />
                        </Flex>
                    </FieldContainer>
                )}
                <FieldContainer>
                    <FormCheckbox
                        name="consignee_same_as_company"
                        onChange={(e) => {
                            if (e.target.checked) {
                                const consigneeAddresses = allowedCompanies.find((c) => c.companyId === values.companyId)?.addresses || [];

                                const defaultAddress =
                                    consigneeAddresses.find((item) => item.isDefault) || (consigneeAddresses.length === 1 && consigneeAddresses[0]);
                                if (defaultAddress && mode === ePurchaseOrderFormMode.NEW) {
                                    setFieldValue('consignee_addresses', defaultAddress.id);
                                    updatePickupAddress('destination', setFieldValue, defaultAddress, values);
                                }
                            }
                        }}
                    >
                        {localizations.form.consignee_same_as_company}
                    </FormCheckbox>
                </FieldContainer>
                <FieldContainer>
                    <div>{renderFieldLabel('Incoterms', false, localizations.form.incoterms_tooltip)}</div>
                    <div>
                        <FormDropDown style={{ width: '200px' }} placeHolder="Pick type" options={incotermsDropDownOptions} name="incoterms" />
                    </div>
                </FieldContainer>
                <FieldContainer>
                    <div>{renderFieldLabel(localizations.form.requested_goods_ready, false)}</div>
                    <div>
                        <FormDatePicker
                            name="requestedGoodsReady"
                            style={{ width: '200px' }}
                            placeHolder={localizations.form.requested_goods_ready}
                            disabled={values.isAsap}
                        />
                    </div>
                </FieldContainer>
                <FieldContainer>
                    <FormCheckbox name="isAsap">{localizations.form.asap}</FormCheckbox>
                </FieldContainer>
                <FieldContainer>
                    <div>{renderFieldLabel(localizations.form.payment_terms, false)}</div>
                    <FormDropDown
                        name="payment_terms"
                        options={enumToDropdownOptions(ePaymentTerms)}
                        placeHolder={localizations.form.payment_terms}
                        style={{ width: '200px' }}
                        showSearch
                        mode="tags"
                        onChange={(arr: Array<string>) => {
                            if (arr.length > 0) setFieldValue('payment_terms', [arr.pop()]);
                        }}
                    />
                </FieldContainer>
                <FieldContainer>
                    <div>{renderFieldLabel(localizations.form.note, false)}</div>
                    <FormTextArea name="note" style={{ width: '200px' }} placeHolder={localizations.form.note} />
                </FieldContainer>
                {/* <FieldContainer>
                    <div>{renderFieldLabel('Forwarder', false, localizations.form.forwarder_tooltip)}</div>
                    <Flex>
                        <FormDropDown
                            name="forwarderId"
                            style={{ width: '200px' }}
                            placeHolder="Pick forwarder"
                            options={partiesToDropdownOptions(businessPartnerForwarders)}
                            showSearch
                        />
                        <CreateBusinessPartner
                            type="FORWARDER_BROKER"
                            modalTitle={localizations.form.parties.modal.create + ' ' + localizations.form.parties.modal['FORWARDER']}
                            submitCallback={(businessPartner: businessPartner) => setFieldValue('forwarderId', businessPartner.id)}
                        />
                    </Flex>
                </FieldContainer> */}
            </div>
            <div style={{ paddingLeft: '30px' }}>
                <References values={values} setFieldValue={setFieldValue} localization={localizations.form} />
            </div>
        </GeneralFormSection>
    );
};

const GeneralFormSection = styled.div`
    display: flex;
    flex-direction: row;
`;

export default General;
