import { product } from '../../../models/entities/product/product';
import { ModelConfig } from '@rematch/core';
import { localSDK as client } from '../../../sdk';
import { ColumnType } from '../../../components/shared/Grid/types/Column';
import SideEffectJS from 'side-effect-js';
import { inventoryTotalView, inventoryLog, internalFlowInventoryLogs } from '../../../models/entities/product/inventoryLog';

export type paginationType = { currentPage: number; rowsPerPage: number };

export type inventoryLogStateType = {
    gridColumns: Array<ColumnType<inventoryTotalView>>;
    gridSort: gridSortState | null;
    gridFilter: string | null;
    inventoryLogs: Array<inventoryTotalView>;
    filterFields: filterFieldsType | null;
    pagination: paginationType;
    internalFlowInventoryLogs?: internalFlowInventoryLogs;
};

export type filterFieldsType = Array<{ field: keyof inventoryTotalView; value: any }>;
export type gridSortState = {
    direction: 'asc' | 'desc';
    column: keyof inventoryTotalView;
};

export type exportTypes = 'PDF' | 'EXCEL';

const defaultGridSort: gridSortState = {
    direction: 'desc',
    column: 'updatedAt'
};
export const inventory: ModelConfig<inventoryLogStateType> = {
    state: {
        inventoryLogs: [],
        gridColumns: [],
        gridSort: defaultGridSort,
        gridFilter: null,
        filterFields: null,
        pagination: {
            currentPage: 0,
            rowsPerPage: 30
        }
    },
    reducers: {
        setInventoryLogs(state: inventoryLogStateType, inventoryLogs: Array<inventoryTotalView>): inventoryLogStateType {
            return { ...state, inventoryLogs };
        },
        setGridSort(state: inventoryLogStateType, sortData: gridSortState): inventoryLogStateType {
            return { ...state, gridSort: sortData };
        },
        setGridFilter(state: inventoryLogStateType, filterText: string): inventoryLogStateType {
            return { ...state, gridFilter: filterText };
        },
        setGridColumns(state: inventoryLogStateType, columns: Array<ColumnType<inventoryTotalView>>): inventoryLogStateType {
            return { ...state, gridColumns: columns };
        },
        setCurrentPage(state: inventoryLogStateType, currentPage: number): inventoryLogStateType {
            return { ...state, pagination: { ...state.pagination, currentPage } };
        },
        setRowsPerPage(state: inventoryLogStateType, rowsPerPage: number): inventoryLogStateType {
            return { ...state, pagination: { ...state.pagination, rowsPerPage } };
        },
        setInternalFlowInventoryLogs(state: inventoryLogStateType, internalFlowInventoryLogs: internalFlowInventoryLogs): inventoryLogStateType {
            return { ...state, internalFlowInventoryLogs };
        }
    },
    effects: (dispatch: any) => ({
        async fetchGridColumns() {
            const fetchGridColumns = SideEffectJS.Get('gridColumnsInventoryLog');
            const gridColumns = await fetchGridColumns();
            dispatch.inventory.setGridColumns(gridColumns);
        },
        async fetchInventory() {
            const logs = await client.inventory().fetchInventory();
            dispatch.inventory.setInventoryLogs(logs);
            return true;
        },
        async fetchInternalFlowInventoryLogs() {
            const logs = await client.inventory().getInternalFlowInventoryLogs();
            dispatch.inventory.setInternalFlowInventoryLogs(logs);
            return true;
        },
        async create(inv: inventoryLog) {
            await client.inventory().create(inv);
            dispatch.inventory.fetchInventory();
            dispatch.internalFlowInventoryLogs.fetchInternalFlowInventoryLogs();
            return true;
        }
    })
};
