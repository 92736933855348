import { ModelConfig } from '@rematch/core';

export type singlePurchaseDemandStateType = {
    purchaseDemandId: string | null;
};

export const singlePurchaseDemand: ModelConfig<singlePurchaseDemandStateType> = {
    state: {
        purchaseDemandId: null
    },
    reducers: {
        setCurrentPurchaseDemandId(state: singlePurchaseDemandStateType, purchaseDemandId: string): singlePurchaseDemandStateType {
            return { ...state, purchaseDemandId };
        }
    }
};
