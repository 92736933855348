import { Formik } from 'formik';
import { Form } from 'formik-antd';
import React, { useState } from 'react';
import { connect } from 'react-redux';
import styled from 'styled-components';
import { createChatPayload } from '../../../../models/entities/chat/chat';
import { userDropDown, userInfo } from '../../../../models/entities/user';
import { localizationSelectors } from '../../../../state/ducks/localization/selectors';
import { userSelectors } from '../../../../state/ducks/user/selectors';
import { RootState } from '../../../../state/store/store';
import { FormTextInput } from '../../../shared/inputs/form';
import UserDropDownForm from '../../../shared/inputs/form/UserDropDown';
import Button from '../../../shared/SmallComponents/Button';
import Loader from '../../../shared/SmallComponents/Loader';

type Props = {
    userInfo: userInfo;
    createChat: (payload: createChatPayload) => Promise<void>;
    showLoader: boolean;
    setModalVisible?: (visible: boolean) => void;
    confirmation?: { setConfirm: React.Dispatch<React.SetStateAction<boolean>> };
    localization: any;
};
const CreateGroupForm = ({ userInfo, createChat, showLoader, setModalVisible, confirmation, localization }: Props) => {
    const initialValues: { name: string; users: string[] } = {
        name: '',
        users: []
    };
    const validate = (values: any) => {
        const errors: any = {};
        if (!values.name) errors.name = localization.cannot_be_empty;
        if (values.name.length >= 25) errors.name = localization.max_chars(25);
        if (!values.users || values.users.length === 0) errors.users = localization.cannot_be_empty;
        return errors;
    };
    return (
        <>
            <Formik
                initialValues={initialValues}
                onSubmit={async ({ name, users }) => {
                    createChat({
                        participantsIds: users.concat([userInfo.id]),
                        name,
                        admins: [userInfo.id],
                        sendDate: new Date(),
                        chatType: 'group',
                        message: `${userInfo.firstName} ${userInfo.lastName} ${localization.created_group} ${name}`,
                        isActivityMessage: true
                    }).then((id) => {
                        setModalVisible && setModalVisible(false);
                    });
                }}
                validate={validate}
            >
                {(formik) => {
                    return (
                        <FormContainer>
                            <Form>
                                <FieldContainer>
                                    <FormTextInput name="name" placeHolder={localization.group_name} />
                                </FieldContainer>
                                <FieldContainer>
                                    <UserDropDownForm
                                        name="users"
                                        placeHolder={localization.select_users}
                                        users={undefined}
                                        customUsers
                                        mode="multiple"
                                        showSearch
                                    />
                                </FieldContainer>
                                {/* <FieldContainer>
                                  <FormTextArea name="message" placeHolder={localization.placeholders.message} height="106px" />
                              </FieldContainer> */}
                                <ButtonContainer>
                                    <Button height="32px" width="100%" buttonType="filled" type="submit">
                                        {showLoader ? (
                                            <Loader width="20px" marginTop="0px" showText={false} />
                                        ) : (
                                            <span>{localization.create_btn}</span>
                                        )}
                                    </Button>
                                </ButtonContainer>
                            </Form>
                        </FormContainer>
                    );
                }}
            </Formik>
        </>
    );
};

const FormContainer = styled.div`
    width: 100%;
`;

const FieldContainer = styled.div`
    margin-bottom: 15px;
`;
const ButtonContainer = styled.div`
    color: #263255;
    background-color: #fff;
`;
const mapStateToProps = (state: RootState) => ({
    showLoader: state.loading.effects.chats.createChat,
    userInfo: userSelectors.userInfo(state),
    localization: localizationSelectors.chat(state)
});

const mapDispatchToProps = (dispatch: any) => ({
    createChat: (payload: createChatPayload) => dispatch.chats.createChat(payload)
});

export default connect(mapStateToProps, mapDispatchToProps)(CreateGroupForm);
