import React from 'react';
import { Page, Text, View, Document, StyleSheet } from '@react-pdf/renderer';
import { GridColDef, GridRowData } from '@material-ui/data-grid';
import SvgComponent from './SvgPdf';

type PdfTableProps = {
    columns: GridColDef[];
    rows: readonly GridRowData[];
    excludeFields?: string[];
    title?: string;
    valueFormatter?: (field: string, value: any, row: any) => any;
};

const vSvgIcon =
    '<svg xmlns="http://www.w3.org/2000/svg" height="18px" viewBox="0 0 24 24" width="18px" fill="#000000"><path d="M0 0h24v24H0V0z" fill="none"/><path d="M9 16.2L4.8 12l-1.4 1.4L9 19 21 7l-1.4-1.4L9 16.2z"/></svg>';
const xSvgIcon =
    '<svg xmlns="http://www.w3.org/2000/svg" height="18px" viewBox="0 0 24 24" width="18px" fill="#000000"><path d="M0 0h24v24H0V0z" fill="none"/><path d="M19 6.41L17.59 5 12 10.59 6.41 5 5 6.41 10.59 12 5 17.59 6.41 19 12 13.41 17.59 19 19 17.59 13.41 12 19 6.41z"/></svg>';

const PdfTable = ({ columns, rows, excludeFields, title, valueFormatter }: PdfTableProps) => {
    const filteredColumns = columns.filter((c) => !c.hide && !excludeFields?.includes(c.field));
    const pdfStyles = styles(`${100 / (filteredColumns.length || 1)}%`);

    return (
        <View>
            {title && (
                <View style={pdfStyles.tableTitleWrapper}>
                    <Text style={pdfStyles.tableTitle}>{title}</Text>
                </View>
            )}
            <View style={pdfStyles.table}>
                <View style={pdfStyles.tableRow}>
                    {filteredColumns.map((col, index) => (
                        <View style={pdfStyles.tableColHeader} key={`column_${index}`}>
                            <Text style={pdfStyles.tableCellHeader}>{col.headerName || col.field}</Text>
                        </View>
                    ))}
                </View>
                {rows.map((row: any, i) => (
                    <View style={pdfStyles.tableRow} key={`row_${i}`}>
                        {filteredColumns.map((col, j) => {
                            const { field } = col;
                            const value = valueFormatter ? valueFormatter(field, row[field], row) : row[field];

                            return (
                                <View style={pdfStyles.tableCol} key={`cell_${i}_${j}`}>
                                    {typeof value === 'boolean' ? (
                                        value ? (
                                            <SvgComponent svg={vSvgIcon} width={18} />
                                        ) : (
                                            <SvgComponent svg={xSvgIcon} width={18} />
                                        )
                                    ) : !value ? (
                                        <View style={pdfStyles.tableCell}>
                                            <Text>-</Text>
                                        </View>
                                    ) : typeof value === 'object' ? (
                                        <View style={pdfStyles.objectTableCell}>
                                            <View style={pdfStyles.objectWrapper}>
                                                {Object.entries(value).map(([key, value]: any[], index, arr) => (
                                                    <View
                                                        key={`object_entry_${i}_${j}_${index}`}
                                                        style={index !== arr.length - 1 ? pdfStyles.keyValueWrapper : pdfStyles.lastKeyValueWrapper}
                                                    >
                                                        <Text>{`${key}: `}</Text>
                                                        <Text>{value}</Text>
                                                    </View>
                                                ))}
                                            </View>
                                        </View>
                                    ) : (
                                        <View style={pdfStyles.tableCell}>
                                            <Text>{value || '-'}</Text>
                                        </View>
                                    )}
                                </View>
                            );
                        })}
                    </View>
                ))}
            </View>
        </View>
    );
};

const styles = (colWidth: string) =>
    StyleSheet.create({
        body: {
            padding: 10
        },
        table: {
            width: 'auto',
            borderStyle: 'solid',
            borderColor: '#bfbfbf',
            borderWidth: 1,
            borderRightWidth: 0,
            borderBottomWidth: 0
        },
        tableRow: {
            margin: 'auto',
            flexDirection: 'row'
        },
        tableColHeader: {
            width: colWidth,
            borderStyle: 'solid',
            borderColor: '#bfbfbf',
            color: '#252b4a',
            borderBottomColor: '#394372',
            borderWidth: 1,
            borderLeftWidth: 0,
            borderTopWidth: 0,
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            justifyContent: 'center'
        },
        tableCol: {
            width: colWidth,
            borderStyle: 'solid',
            borderColor: '#bfbfbf',
            borderWidth: 1,
            borderLeftWidth: 0,
            borderTopWidth: 0
        },
        tableCellHeader: {
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            justifyContent: 'center',
            margin: 5,
            fontSize: 12,
            fontWeight: 500,
            width: 'auto'
        },
        tableCell: {
            margin: 5,
            fontSize: 10,
            overflow: 'hidden'
        },
        objectTableCell: {
            overflow: 'hidden',
            fontSize: 10
        },
        objectWrapper: {
            display: 'flex',
            flexDirection: 'column'
        },
        keyValueWrapper: {
            display: 'flex',
            flexDirection: 'row',
            flexWrap: 'wrap',
            padding: 5,
            marginBottom: 3,
            borderBottom: '1px solid #bfbfbf'
        },
        lastKeyValueWrapper: {
            display: 'flex',
            flexDirection: 'row',
            flexWrap: 'wrap',
            padding: 5,
            marginBottom: 0
        },
        tableTitleWrapper: {
            marginBottom: 20
        },
        tableTitle: {
            fontSize: 20,
            color: '#252b4a'
        }
    });

export default PdfTable;
