import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { RouteComponentProps, useLocation, withRouter } from 'react-router-dom';
import { checkOrderConfirmationResponse, confirmOrderPayload } from '../../../../models/entities/shipment/public/orderConfirmation';
import { localizationSelectors } from '../../../../state/ducks/localization/selectors';
import { RootState, Dispatch } from '../../../../state/store/store';
import Loader from '../../../shared/SmallComponents/Loader';
import ExceptionPage from '../../ExceptionPage/ExceptionPage';
import OrderConfirmationPage from './OrderConfirmationPage';

type PathParams = {
    token: string;
};

type Props = RouteComponentProps<PathParams> & {
    localization: any;
    checkOrderConfirmed: (token: string) => Promise<checkOrderConfirmationResponse>;
    confirmOrder: (payload: confirmOrderPayload) => Promise<void>;
    fetchError?: string;
};

const OrderConfirmationPageConnected = ({ localization, checkOrderConfirmed, confirmOrder, fetchError, match }: Props) => {
    const location = useLocation();
    const [checkOrderConfirmation, setCheckOrderConfirmation] = useState<checkOrderConfirmationResponse | null>(null);
    const token = match.params.token;

    useEffect(() => {
        (async () => {
            const response = await checkOrderConfirmed(token);
            setCheckOrderConfirmation(response);
        })();
    }, [token]);

    if (fetchError) {
        let error = { message: undefined, status: 500 };
        try {
            error = JSON.parse(fetchError);
        } catch (e) {}
        return <ExceptionPage status={error.status} title={error.message || ''} />;
    }

    if (!checkOrderConfirmation) return <Loader />;

    return <OrderConfirmationPage localization={localization} confirmOrder={confirmOrder} checkOrderConfirmedResp={checkOrderConfirmation} />;
};

const mapStateToProps = (state: RootState) => ({
    fetchError: state.error.effects.shipments.checkOrderConfirmed,
    localization: localizationSelectors.orderConfirmationPage(state)
});

const mapDispatchToProps = (dispatch: Dispatch) => ({
    checkOrderConfirmed: (token: string) => dispatch.shipments.checkOrderConfirmed(token),
    confirmOrder: (payload: confirmOrderPayload) => dispatch.shipments.confirmOrder(payload)
});

export default withRouter(connect(mapStateToProps, mapDispatchToProps as any)(OrderConfirmationPageConnected));
