import React from 'react';
import { FormikProps } from 'formik';
import { genericCreateTariffChargePayload } from '../../../../../../models/entities/tariff/charge';
import FormInputWrapper from '../../../../../shared/Form/FormInputWrapper';
import { FormTextInput } from '../../../../../shared/inputs/form';
import styled from 'styled-components';

export const emptyPercentCalcData = {
    percent: 0,
    price: 0
};

type Props = {
    localization: any;
    formikProps: FormikProps<genericCreateTariffChargePayload<'PERCENT'>>;
};

const Percent = ({ formikProps, localization }: Props) => {
    return (
        <Flex>
            <div style={{ width: '100%', marginRight: '15px' }}>
                <FormInputWrapper fieldName="percent" localization={localization.fields} isMandatory>
                    <FormTextInput type="number" name="chargeCalcData.percent" style={{ width: '100%' }} />
                </FormInputWrapper>
            </div>
            <div style={{ width: '100%' }}>
                <FormInputWrapper fieldName="chargeCode" localization={localization.fields} isMandatory>
                    <FormTextInput name="chargeCalcData.chargeCode" style={{ width: '100%' }} />
                </FormInputWrapper>
            </div>
        </Flex>
    );
};

const Flex = styled.div`
    display: flex;
    align-items: center;
`;

export default Percent;
