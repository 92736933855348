import { discussion } from '../../../../models/entities/common_subentities/discussion';
type stateType = { [key: string]: any };
function setDiscussions(state: stateType, entityId: string, entityType: keyof stateType, discussions: Array<discussion>) {
    const newArr = [...state[entityType]];
    const index = newArr.findIndex((item) => item.id === entityId);
    if (index !== -1) {
        newArr[index].discussions = discussions;
    }
    return newArr;
}
function addDiscussion(state: stateType, entityId: string, entityType: keyof stateType, discussion: discussion) {
    const newArr = [...state[entityType]];
    const index = newArr.findIndex((shipment) => shipment.id === entityId);
    if (index !== -1) {
        let discussions = [discussion];
        const oldDiscussions = newArr[index].discussions;
        if (oldDiscussions && oldDiscussions.length > 0) discussions = [...oldDiscussions, ...discussions];
        newArr[index].discussions = discussions;
    }
    return newArr;
}
function editDiscussion(state: stateType, entityId: string, entityType: keyof stateType, discussion: discussion) {
    const newArr = [...state[entityType]];
    const index = newArr.findIndex((item) => item.id === entityId);
    if (index !== -1) {
        const discussions = newArr[index].discussions?.map((item: discussion) => {
            if (item.id === discussion.id) return { ...item, message: discussion.message };
            else return { ...item };
        });
        newArr[index].discussions = discussions;
    }
    return newArr;
}
function removeDiscussion(state: stateType, entityId: string, entityType: keyof stateType, discussionId: string) {
    const newArr = [...state[entityType]];
    const index = newArr.findIndex((item) => item.id === entityId);
    if (index !== -1) {
        const oldDiscussions = newArr[index].discussions?.filter((item: discussion) => item.id !== discussionId);
        newArr[index].discussions = oldDiscussions;
    }
    return newArr;
}

export { setDiscussions, addDiscussion, editDiscussion, removeDiscussion };
