import moment from 'moment';
import { purchaseDemand } from '../../../models/entities/purchaseDemand/purchaseDemand';
import { filterFieldsType } from '../allBusinessPartnersPage/allBusinessPartnersPage';

const filterPurchaseDemands = (purchaseDemands: purchaseDemand[], filterFields: filterFieldsType) => {
    let filteredPurchaseDemands = [...purchaseDemands];

    filterFields.forEach((filter) => {
        switch (filter.field) {
            case 'fromDate': {
                filteredPurchaseDemands = filteredPurchaseDemands.filter((purchaseDemand) =>
                    moment(purchaseDemand.createdAt).isSameOrAfter(moment(filter.value))
                );
                break;
            }
            case 'toDate': {
                filteredPurchaseDemands = filteredPurchaseDemands.filter((purchaseDemand) =>
                    moment(purchaseDemand.createdAt).isSameOrBefore(moment(filter.value))
                );
                break;
            }
            default:
                filteredPurchaseDemands = filteredPurchaseDemands.filter((purchaseDemand) =>
                    (purchaseDemand as any)[filter.field] ? (purchaseDemand as any)[filter.field].includes(filter.value as string) : purchaseDemand
                );
                break;
        }
    });
    return filteredPurchaseDemands;
};

export default filterPurchaseDemands;
