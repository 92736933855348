import moment from 'moment';
import { shipment } from '../../../models/entities/shipment/shipment';
import { filterFieldsType } from '../allBusinessPartnersPage/allBusinessPartnersPage';
import carriers from '../../../static/data/carriers.json';

const filterShipments = (shipments: shipment[], filterFields: filterFieldsType) => {
    let filteredShipments = [...shipments];

    filterFields.forEach((filter) => {
        switch (filter.field) {
            case 'fromDate': {
                filteredShipments = filteredShipments.filter((shipment) => moment(shipment.createdAt).isSameOrAfter(moment(filter.value)));
                break;
            }
            case 'toDate': {
                filteredShipments = filteredShipments.filter((shipment) => moment(shipment.createdAt).isSameOrBefore(moment(filter.value)));
                break;
            }
            case 'fromETA': {
                filteredShipments = filteredShipments.filter((shipment) => moment(shipment.eta).isSameOrAfter(moment(filter.value)));

                break;
            }
            case 'toETA': {
                filteredShipments = filteredShipments.filter((shipment) => moment(shipment.eta).isSameOrBefore(moment(filter.value)));

                break;
            }
            case 'typeCode':
                if (filter.value.length > 0) {
                    filteredShipments = filteredShipments.filter((shipment) => (filter.value as string[]).includes(shipment.typeCode));
                }
                break;
            case 'incoterms':
                if (filter.value.length > 0) {
                    filteredShipments = filteredShipments.filter((shipment) => (filter.value as string[]).includes(shipment.incoterms));
                }
                break;
            case 'forwarderName':
                if (filter.value.length > 0) {
                    filteredShipments = filteredShipments.filter((shipment) => (filter.value as string[]).includes(shipment.forwarderName));
                }
                break;
            case 'companyId':
                if (filter.value.length > 0) {
                    filteredShipments = filteredShipments.filter((shipment) => (filter.value as string[]).includes(shipment.companyId));
                }
                break;
            case 'alertStatus':
                filteredShipments = filteredShipments.filter((shipment) =>
                    (filter.value as string[]).includes(shipment.alertStatus ? shipment.alertStatus.toString() : '3')
                );
                break;
            case 'state':
                filteredShipments = filteredShipments.filter((shipment) => (filter.value as string[]).includes(shipment.state));
                break;
            case 'isActive':
                filteredShipments = filteredShipments.filter((shipment) => shipment.isActive === (filter.value === 'true'));
                break;
            case 'customsResponsible':
                filteredShipments = filteredShipments.filter((shipment) => shipment.customsResponsible === (filter.value === 'true'));
                break;
            case 'carrierCode':
                const carrier = carriers.find((item) => item.carrierName === filter.value)?.carrierCode || filter.value;
                filteredShipments = filteredShipments.filter(
                    (shipment) => !!shipment.carrierCode && shipment.carrierCode.toLowerCase().includes(carrier.toString().toLowerCase())
                );
                break;
            case 'shipperName':
                filteredShipments = filteredShipments.filter(
                    (shipment) => !!shipment.shipperName && shipment.shipperName.toLowerCase().includes(filter.value.toString().toLowerCase())
                );
                break;
            case 'commodity':
                filteredShipments = filteredShipments.filter((shipment) => !!shipment.commodity && shipment.commodity === filter.value);
                break;
            default:
                filteredShipments = filteredShipments.filter((shipment) =>
                    (shipment as any)[filter.field] ? (shipment as any)[filter.field].toString().includes(filter.value as string) : shipment
                );
                break;
        }
    });
    return filteredShipments;
};

export default filterShipments;
