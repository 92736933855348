import React, { useEffect, useState } from 'react';
import { Form, Formik } from 'formik';
import { RootState } from '../../../../../state/store/store';
import { connect } from 'react-redux';
import { styled } from '../../../../shared/Theme/theme';
import { eOpenDates } from '../../../../../models/entities/purchaseDemand/open_dates';
import countries from '../../../../../static/data/countries.json';
import airports from '../../../../../static/data/airports.json';
import Button from '../../../../shared/SmallComponents/Button';
import { localizationSelectors } from '../../../../../state/ducks/localization/selectors';
import { getDatesRange } from '../../../../../utils/date';
import { filterFieldsType } from '../../../../../state/ducks/allPurchaseDemandsPage/allPurchaseDemandsPage';
import { FormDatePicker, FormDropDown, FormTextInput } from '../../../../shared/inputs/form';
import { enumToDropdownOptions, Option } from '../../../../shared/inputs/base/DropDown';
import { renderCountryOption } from '../../../../shared/SmallComponents/CountryOption';
import FieldLabel from '../../../../shared/SmallComponents/FieldLabel';
import { Flex } from '../../../singlePurchaseDemand/PurchaseDemandForm/PurchaseDemandForm';
import { allPurchaseDemandsPageSelectors } from '../../../../../state/ducks/allPurchaseDemandsPage/selectors';
import renderStateOption from '../../../../shared/SmallComponents/StateOption';
import { settings } from '../../../../../models/entities/purchaseDemand/purchaseDemand';

type Props = {
    localizations: any;
    filterFields: filterFieldsType | null;
    onCancel: () => void;
    setPurchaseDemandFilters: (filter: filterFieldsType) => void;
    settings?: settings;
};

type formFilterFields = {
    fromDate?: Date;
    toDate?: Date;
    state?: string;
};

const FilterContent = ({ localizations, filterFields, onCancel, setPurchaseDemandFilters, settings }: Props) => {
    const [formFieldsValues, setFormFieldsValues] = useState<formFilterFields>({});

    useEffect(() => {
        const filterFieldsValues: formFilterFields = {};
        filterFields?.forEach(({ field, value }: { field: string; value: any }) => {
            (filterFieldsValues as any)[field] = value;
        });
        setFormFieldsValues(filterFieldsValues);
    }, [filterFields]);

    const openDatesDropDownOptions = enumToDropdownOptions(eOpenDates);

    const setInitialValues = () => {
        return formFieldsValues;
    };
    const validate = (values: any) => {
        const errors: any = {};
        return errors;
    };
    const submit = (values: any) => {
        const filter: filterFieldsType = [];
        Object.keys(formFieldsValues).forEach((item) => {
            if (item !== 'calcDate' && (formFieldsValues as any)[item]) filter.push({ field: item, value: (formFieldsValues as any)[item] });
        });

        setPurchaseDemandFilters(filter);
        onCancel();
    };

    const resetForm = () => {
        setFormFieldsValues({});
        setPurchaseDemandFilters([]);
    };

    return (
        <Formik initialValues={setInitialValues()} validate={validate} onSubmit={submit}>
            {({ values, setFieldValue }: any) => {
                return (
                    <FormContainer>
                        <Container>
                            <FieldContainer data-popover-bug>
                                <FieldLabel>{localizations.open_date}</FieldLabel>
                                <FormDropDown
                                    preventPopoverBug={true}
                                    name="calcDate"
                                    placeHolder={localizations.open_date}
                                    options={openDatesDropDownOptions}
                                    onChange={(value: string) => {
                                        const range = getDatesRange(value);
                                        setFieldValue('fromDate', range.from);
                                        setFieldValue('toDate', range.to);
                                        setFormFieldsValues({ ...formFieldsValues, fromDate: range.from, toDate: range.to });
                                    }}
                                    allowClear
                                />
                            </FieldContainer>
                            <FieldContainer>
                                <FieldLabel>{localizations.state}</FieldLabel>
                                <FormDropDown
                                    name="state"
                                    placeHolder={localizations.state}
                                    options={
                                        settings?.state
                                            ?.sort((a, b) => a.displayOrder - b.displayOrder)
                                            .map((item) => ({
                                                text: item.name,
                                                value: item.code,
                                                color: item.color
                                            })) || []
                                    }
                                    optionRenderer={renderStateOption}
                                    onChange={(value: string) => setFormFieldsValues({ ...formFieldsValues, state: value })}
                                />
                            </FieldContainer>
                            {values.calcDate === 'CUSTOM' && (
                                <>
                                    <FieldContainer data-popover-bug>
                                        <FieldLabel>{localizations.from_date}</FieldLabel>
                                        <FormDatePicker
                                            name="fromDate"
                                            placeHolder={localizations.from_date}
                                            onChange={(value: Date) => setFormFieldsValues({ ...formFieldsValues, fromDate: value })}
                                            value={formFieldsValues.fromDate}
                                            preventPopoverBug={true}
                                        />
                                    </FieldContainer>
                                    <FieldContainer data-popover-bug>
                                        <FieldLabel>{localizations.to_date}</FieldLabel>
                                        <FormDatePicker
                                            name="toDate"
                                            placeHolder={localizations.to_date}
                                            onChange={(value: Date) => setFormFieldsValues({ ...formFieldsValues, toDate: value })}
                                            value={formFieldsValues.toDate}
                                            preventPopoverBug={true}
                                        />
                                    </FieldContainer>
                                </>
                            )}
                            <Divider />
                        </Container>
                        <FormFooter>
                            <Button minWidth="55px" type="button" onClick={onCancel}>
                                <span>{localizations.cancel_btn}</span>
                            </Button>
                            <Flex>
                                <Button minWidth="55px" type="button" onClick={resetForm}>
                                    <span>{localizations.reset_btn}</span>
                                </Button>
                                <Button minWidth="55px" buttonType="filled" type="submit">
                                    <span>{localizations.filter_btn}</span>
                                </Button>
                            </Flex>
                        </FormFooter>
                    </FormContainer>
                );
            }}
        </Formik>
    );
};
const FormContainer = styled(Form)`
    min-width: 500px;
    @media screen and (max-width: 768px) {
        min-width: 100%;
    }
`;
const FieldContainer = styled.div`
    margin-bottom: 12px;
    flex-basis: 45%;
    width: 0;
    padding: 0 10px;
    @media screen and (max-width: 768px) {
        flex-basis: 100%;
    }
`;
const Divider = styled.div`
    width: 100%;
    margin-bottom: 12px;
    border-top: 1px solid rgba(0, 0, 0, 0.06);
    @media screen and (max-width: 768px) {
        display: none;
    }
`;
const Container = styled.div`
    width: 100%;
    max-height: 420px;
    overflow: auto;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-around;
    @media screen and (max-width: 768px) {
        height: calc(100vh - 70px);
        padding: 4px 12px;
        max-height: 100%;
    }
`;

const FormFooter = styled.div`
    position: absolute;
    width: 100%;
    display: flex;
    justify-content: space-between;
    height: 70px;
    padding: 17px 30px;
    border-top: 1px solid #eaeaea;
    left: 0;
    right: 0;
    bottom: -70px;
    background: white;
    box-shadow: 0 9px 12px 1px rgb(0 0 0 / 9%);
    button {
        padding: 15px;
    }
    @media screen and (max-width: 768px) {
        bottom: 0;
        button {
            span {
                display: -webkit-box;
            }
        }
    }
`;

const mapStateToProps = (state: RootState) => ({
    localizations: localizationSelectors.allPurchaseDemandsPage(state).filter,
    filterFields: allPurchaseDemandsPageSelectors.filterFields(state)
});

const mapDispatchToProps = (dispatch: any) => ({
    setPurchaseDemandFilters: (filter: filterFieldsType) => dispatch.allPurchaseDemandsPage.setPurchaseDemandFilters(filter)
});

export default connect(mapStateToProps, mapDispatchToProps)(FilterContent);
