import { userDropDown, userInfo, userInvitations, userMenu, userSettings, userSettingDefinition } from '../../../models/entities/user';
import SideEffectJS from 'side-effect-js';
import { ModelConfig } from '@rematch/core';
import { localSDK as client } from '../../../sdk';
import { bufferToBase64 } from '../../../utils/files';
import { RootState } from '../../store/store';
import { RcFile } from 'antd/lib/upload';
import RealtimeService from '../../../services/realtime/realtimeService';
import { notification } from '../../../models/entities/notification/notification';
import { userDetails } from '../users/users';
import { pushNotification } from '../../../components/shared/PushNotifications';
import { chat, message, messageAttachment } from '../../../models/entities/chat/chat';
import { pushNotificationHandle } from '../../../components/shared/PushNotifications/utils';
import { shipmentFollowingInvitation } from '../../../models/entities/shipment/shipmentFollowingInvitation';
import { companiesRelationRequest } from '../../../models/entities/company/companiesRelationRequest';
import { companiesRelation } from '../../../models/entities/company/companiesRelation';
import { purchaseDemandApprover } from '../../../models/entities/purchaseDemand/purchaseDemandApprover';
import { purchaseOrderFollowingInvitation } from '../../../models/entities/purchaseOrder/purchaseOrderFollowingInvitation';
import { quotationView } from '../../../models/entities/bid/quotation';
import SmartLook from 'smartlook-client';

export type userStateType = {
    userInfo: userInfo;
    error?: string;
    userMenu: userMenu;
    userInvitations: userInvitations;
    userSettingDefinitions: userSettingDefinition[];
};

export const user: ModelConfig<userStateType> = {
    state: {
        userInfo: {
            id: '',
            username: '',
            firstName: '',
            lastName: '',
            phoneNumber: '',
            email: '',
            profileImg: '',
            status: '',
            followingShipments: [],
            followingPurchaseOrders: [],
            allowedCompanies: [],
            allowedShipmentTypes: [],
            notAllowedRelatedCompanies: [],
            companyId: '',
            role: '',
            companyName: '',
            companyType: '',
            userSettings: [],
            shipmentSettings: [],
            profileImgUrl: '',
            usersInSameCompany: [],
            claims: {}
        },
        userInvitations: {},
        userMenu: {},
        userSettingDefinitions: []
    },
    reducers: {
        setUserInfo(state: userStateType, userInfo: userInfo): userStateType {
            return { ...state, userInfo };
        },
        setUserMenu(state: userStateType, userMenu: userMenu): userStateType {
            return { ...state, userMenu: { ...state.userMenu, ...userMenu } };
        },
        setUserSettingDefinitions(state: userStateType, userSettingDefinitions: userSettingDefinition[]): userStateType {
            return { ...state, userSettingDefinitions };
        },
        setUserDetails(state: userStateType, details): userStateType {
            return { ...state, userInfo: { ...state.userInfo, ...details } };
        },
        setFetchUserInfoError(state: userStateType, error: string): userStateType {
            return { ...state, error };
        },
        setUserStatus(state: userStateType, status: string): userStateType {
            return { ...state, userInfo: { ...state.userInfo, status: status } };
        },
        setUserPicture(state: userStateType, fileId: string): userStateType {
            return { ...state, userInfo: { ...state.userInfo, profileImg: fileId } };
        },
        setUserPictureUrl(state: userStateType, url: string): userStateType {
            return { ...state, userInfo: { ...state.userInfo, profileImgUrl: url } };
        },
        setUserShipmentFollowingInvitations(state: userStateType, invitations: Array<shipmentFollowingInvitation>): userStateType {
            return { ...state, userInvitations: { ...state.userInvitations, shipmentFollowing: invitations } };
        },
        setUserPurchaseOrderFollowingInvitations(state: userStateType, invitations: Array<purchaseOrderFollowingInvitation>): userStateType {
            return { ...state, userInvitations: { ...state.userInvitations, purchaseOrderFollowing: invitations } };
        },
        approveShipmentFollowing(state: userStateType, invitationId: string): userStateType {
            if (!state.userInvitations.shipmentFollowing) return state;
            const newInvitations = [...state.userInvitations.shipmentFollowing].filter((item) => item.id !== invitationId);
            return { ...state, userInvitations: { ...state.userInvitations, shipmentFollowing: newInvitations } };
        },
        approvePurchaseOrderFollowing(state: userStateType, invitationId: string): userStateType {
            if (!state.userInvitations.purchaseOrderFollowing) return state;
            const newInvitations = [...state.userInvitations.purchaseOrderFollowing].filter((item) => item.id !== invitationId);
            return { ...state, userInvitations: { ...state.userInvitations, purchaseOrderFollowing: newInvitations } };
        },
        setUsersInSameCompany(state: userStateType, users: Array<userDropDown>): userStateType {
            return { ...state, userInfo: { ...state.userInfo, usersInSameCompany: users } };
        },
        setShipmentFollow(state: userStateType, shipmentId: string, follow: boolean) {
            const followingShipments = [...state.userInfo.followingShipments];
            if (follow) {
                followingShipments.push(shipmentId);
            } else {
                const index = followingShipments.findIndex((item) => item === shipmentId);
                followingShipments.splice(index, 1);
            }
            return { ...state, userInfo: { ...state.userInfo, followingShipments } };
        },
        setPurchaseOrderFollow(state: userStateType, purchaseOrderId: string, follow: boolean) {
            const followingPurchaseOrders = [...state.userInfo.followingPurchaseOrders];
            if (follow) {
                followingPurchaseOrders.push(purchaseOrderId);
            } else {
                const index = followingPurchaseOrders.findIndex((item) => item === purchaseOrderId);
                followingPurchaseOrders.splice(index, 1);
            }
            return { ...state, userInfo: { ...state.userInfo, followingPurchaseOrders } };
        },
        updateUserSettings(state: userStateType, { key, value, id }: { key: string; value: any; id: number }) {
            const currSettings = [...state.userInfo.userSettings];
            const index = currSettings.findIndex((item) => item.id === id);
            if (index != -1) {
                currSettings[index] = { ...currSettings[index], key, value };
            } else {
                currSettings.push({ id, key, value } as userSettings);
            }
            return { ...state, userInfo: { ...state.userInfo, userSettings: currSettings } };
        }
    },
    effects: (dispatch: any) => ({
        async fetchUserInfo() {
            let userInfo: userInfo = await client.user().fetchUserInfo();
            const activeChats = await client.chat().fetchActiveChats();

            const { id, firstName, lastName, profileImg, email, role, phoneNumber, companyName, usersInSameCompany, status } = userInfo;
            const profileImgUrl = profileImg ? `${process.env.REACT_APP_API_URL}/api/user/image/${profileImg}` : null;
            userInfo = { ...userInfo, profileImgUrl };

            const user: userDetails = {
                id,
                firstName,
                lastName,
                profileImg,
                name: `${firstName} ${lastName}`,
                profileImgUrl,
                email,
                role,
                phoneNumber,
                companyName,
                status
            } as userDetails;

            await SmartLook.identify(user.id, {
                name: user.name,
                email: user.email,
                companyName
            });

            await dispatch.users.setUsers(id, user);

            const socket = RealtimeService.getSocket();

            //TODO:replace with real localization
            const tempLocalization = {
                click: 'Click',
                default_title: 'Default title',
                follow: 'Follow',
                view: 'View',
                go_to_chat: 'Go to chat',
                see_requests: 'See requests'
            };

            socket.on('connect', async () => {
                const parentAllowedCompany = userInfo?.allowedCompanies?.find(
                    (c) => (typeof c !== 'string' ? c.parentCompany : c) === userInfo.companyId
                );
                const userParentCompanyId =
                    (typeof parentAllowedCompany === 'string' ? parentAllowedCompany : parentAllowedCompany?.parentCompany) || userInfo.companyId;

                //#region User Events
                socket.emit('userConnected', { userId: id, status, companyId: userParentCompanyId });

                socket.on('user-status', ({ userId, status }: { userId: string; status: string }) => {
                    dispatch.users.setUserStatus(userId, status);
                });

                socket.on('notification', async (notification: notification) => {
                    if (notification.content?.action?.type === 'PUSH') {
                        pushNotificationHandle(notification, dispatch, id, tempLocalization);
                    }
                    dispatch.notifications.addNewNotification(notification);
                });

                socket.on('connectedClients', (clients: { id: string; connectedAt: string }[]) => {
                    dispatch.users.setConnectedUsers(clients.map((c) => c.id));
                });
                //#endregion

                //#region Chat Events
                socket.on('network-stopped', async ({ userId }: { userId: string }) => {
                    dispatch.users.setUserIsActive({ userId, isActive: false });
                });

                socket.emit(
                    'join-chat',
                    activeChats.map((chat) => chat.id)
                );
                for (const chat of activeChats) {
                    dispatch.chats.addChat(chat);
                }

                socket.on('join-chat', (chat: chat) => {
                    socket.emit('join-chat', chat.id);
                    dispatch.chats.addChat(chat);
                });

                socket.on('join-group', async (chatId: string) => {
                    socket.emit('join-chat', chatId);
                    const chat = await client.chat().getChatById(chatId);
                    dispatch.chats.addChat(chat);
                });

                socket.on('message', async (message: message) => {
                    if (id !== message.userId) {
                        dispatch.chats.addMessageToChat(message.chatId as string, message);
                        const participantsIds = [id, message.userId];
                        const getDrawerOpen = await dispatch.header.getDrawerOpenState();
                        if (document.hidden || !getDrawerOpen?.startsWith('CHAT')) {
                            await pushNotification(
                                {
                                    id: message.id,
                                    title: `New message from ${message.userName}`,
                                    message: message.content,
                                    click: {
                                        onClick: () =>
                                            dispatch.chats.openChatDrawer({
                                                participantsIds,
                                                chatType: 'user'
                                            }),
                                        text: 'View message'
                                    }
                                },
                                tempLocalization
                            );
                        }
                    }
                });
                socket.on('messages-read', ({ chatId, messagesIds }: { chatId: string; messagesIds: string[] }) => {
                    dispatch.chats.setMessagesRead(chatId, messagesIds);
                });
                socket.on(
                    'messages-read-by',
                    ({ chatId, messagesIds, readUserId }: { chatId: string; messagesIds: string[]; readUserId: string }) => {
                        dispatch.chats.setMessagesReadBy({ chatId, messagesIds, readUserId });
                    }
                );
                socket.on(
                    'message-edit',
                    ({
                        id,
                        chatId,
                        message,
                        userId,
                        attachments,
                        replyMessageId
                    }: {
                        id: string;
                        chatId: string;
                        message: string;
                        userId: string;
                        attachments: messageAttachment[];
                        replyMessageId: string;
                    }) => {
                        if (id !== userId) {
                            dispatch.chats.updateMessage({ id, chatId, message, attachments, replyMessageId });
                        }
                    }
                );
                socket.on('message-delete', ({ id, chatId, userId }: { id: string; chatId: string; userId: string }) => {
                    if (id !== userId) {
                        dispatch.chats.removeMessage({ id, chatId });
                    }
                });

                socket.on('typing', ({ chatId, userId, isTyping }: { chatId: string; userId: string; isTyping: boolean }) => {
                    dispatch.chats.setChatTyping({ chatId, userId, isTyping });
                });

                socket.on(
                    'group-remove-user',
                    ({
                        chatId,
                        userId,
                        removedUserId,
                        activityMessage
                    }: {
                        chatId: string;
                        userId: string;
                        removedUserId: string;
                        activityMessage: message;
                    }) => {
                        dispatch.chats.addMessageToChat(chatId, activityMessage);
                        if (id === removedUserId) {
                            dispatch.header.setDrawerOpen(null);
                            dispatch.chats.removeChat(chatId);
                            socket.emit('leave-room', chatId);
                        } else if (id !== userId) {
                            dispatch.chats.removeGroupParticipant(chatId, removedUserId);
                        }
                    }
                );
                socket.on(
                    'group-add-users',
                    ({
                        chatId,
                        userId,
                        addedUsersIds,
                        activityMessage
                    }: {
                        chatId: string;
                        userId: string;
                        addedUsersIds: string[];
                        activityMessage: message;
                    }) => {
                        dispatch.chats.addMessageToChat(chatId, activityMessage);
                        if (id !== userId) {
                            dispatch.chats.setGroupParticipants(chatId, addedUsersIds);
                        }
                    }
                );

                socket.on(
                    'group-edit-name',
                    ({ chatId, userId, name, activityMessage }: { chatId: string; userId: string; name: string; activityMessage: message }) => {
                        dispatch.chats.addMessageToChat(chatId, activityMessage);

                        if (id !== userId) {
                            dispatch.chats.setGroupName(chatId, name);
                        }
                    }
                );
                //#endregion

                //#region Companies Relations Events
                socket.on('companies-relation-request-created', (createdRequestData: companiesRelationRequest) => {
                    dispatch.companiesRelations.addCompaniesRelationRequest(createdRequestData, false);
                });

                socket.on(
                    'companies-relation-approved',
                    ({ newRelation, deletedRequestId }: { newRelation: companiesRelation; deletedRequestId: string }) => {
                        dispatch.companiesRelations.addCompaniesRelation(newRelation, false);
                        dispatch.companiesRelations.removeCompaniesRelationRequest(deletedRequestId, true);
                    }
                );

                socket.on('companies-relation-deleted', (relationId: string) => {
                    dispatch.companiesRelations.removeCompaniesRelation(relationId);
                });
                //#endregion

                socket.on(
                    `pd-approver-state`,
                    ({
                        purchaseDemandId,
                        newApprover,
                        newPdState
                    }: {
                        purchaseDemandId: string;
                        newApprover: purchaseDemandApprover;
                        newPdState: string;
                    }) => {
                        dispatch.purchaseDemands.editPurchaseDemandApprover(purchaseDemandId, newApprover);
                        if (newPdState) {
                            dispatch.purchaseDemands.setPurchaseDemandState(purchaseDemandId, newPdState);
                        }
                    }
                );

                socket.on(`pd-state`, ({ purchaseDemandId, newState }: { purchaseDemandId: string; newState: string }) => {
                    if (newState) {
                        dispatch.purchaseDemands.setPurchaseDemandState(purchaseDemandId, newState);
                    }
                });

                socket.on(
                    `pd-add-approver`,
                    ({ purchaseDemandId, newApprover }: { purchaseDemandId: string; newApprover: purchaseDemandApprover }) => {
                        if (newApprover) dispatch.purchaseDemands.addPurchaseDemandApprover(purchaseDemandId, newApprover);
                    }
                );

                socket.on(
                    'quotations-created',
                    ({ shipmentId, bidId, createdQuotations }: { shipmentId: string; bidId: string; createdQuotations: quotationView[] }) => {
                        dispatch.shipments.setShipmentBidQuotations(shipmentId, createdQuotations);
                    }
                );
            });

            dispatch.user.setUserInfo(userInfo);
        },
        async fetchUserMenu() {
            try {
                const fetchUserMenu = SideEffectJS.Get('fetchUserMenu');
                const user = await fetchUserMenu();
                dispatch.user.setUserMenu(user.userMenu);
            } catch (e) {
                dispatch.user.setFetchUserInfoError(e);
            }
        },
        async fetchUsersInSameCompany() {
            const users = await client.user().fetchUsersInSameCompany();
            dispatch.user.setUsersInSameCompany(users);
        },
        async fetchUsersInSameCompanyId(companyId: string) {
            const users = await client.user().fetchUsersInSameCompany(companyId);
            return users;
        },
        async fetchUserShipmentFollowingInvitations() {
            const invitations = await client.shipments().follow().getMyFollowingInvitations();
            dispatch.user.setUserShipmentFollowingInvitations(invitations);
        },
        async fetchUserPurchaseOrderFollowingInvitations() {
            const invitations = await client.purchaseOrders().follow().getMyFollowingInvitations();
            dispatch.user.setUserPurchaseOrderFollowingInvitations(invitations);
        },
        async followShipment({ shipmentId, invitationId }: { shipmentId: string; invitationId: string }) {
            await dispatch.allShipmentsPage.followShipment(shipmentId);
            dispatch.user.approveShipmentFollowing(invitationId);
        },
        async followPurchaseOrder({ purchaseOrderId, invitationId }: { purchaseOrderId: string; invitationId: string }) {
            await dispatch.allPurchaseOrdersPage.followPurchaseOrder(purchaseOrderId);
            dispatch.user.approvePurchaseOrderFollowing(invitationId);
        },
        async updateUserStatus(status: string) {
            await client.user().updateUserStatus(status, '');
            dispatch.user.setUserStatus(status);
        },
        async updateUserPicture(file: RcFile, state: RootState) {
            const formData = new FormData();
            formData.append('file', file);
            const fileId = await client.user().updateUserPicture(formData);
            const userInfo = { ...state.user.userInfo };
            const buffer = await file.arrayBuffer();
            userInfo.profileImgUrl = bufferToBase64(buffer);
            dispatch.user.setUserInfo(userInfo);
            dispatch.user.setUserPicture(fileId);
        },
        async updateUserDetails(details) {
            await client.user().updateUserDetails(details);
            dispatch.user.setUserDetails(details);
            return true;
        },
        async updateUserPassword({
            currentPassword,
            password,
            passwordConfirm
        }: {
            currentPassword: string;
            password: string;
            passwordConfirm: string;
        }) {
            await client.user().updateUserPassword(currentPassword, password, passwordConfirm);
            return true;
        },
        async setUserSettings({ id, key, value }: { key: string; value: any; id?: number }) {
            const settingId = await client.user().setUserSettings(key, value, id);
            dispatch.user.updateUserSettings({ key, value, id: settingId });
            return true;
        },
        async getUserSettingDefinitions() {
            const definitions = await client.user().getUserSettingDefinitions();
            dispatch.user.setUserSettingDefinitions(definitions);
        },
        async setEmailAutoCompleteSettings(emails: string[], state: RootState) {
            const key = 'EmailAutoComplete';
            const setting = state.user.userInfo.userSettings.find((item) => item.key === key);
            if (!setting) {
                dispatch.user.setUserSettings({ key, value: emails });
            } else {
                const value = Array.from(new Set(emails.concat(setting.value)));
                dispatch.user.updateUserSettings({ key, value, id: setting.id });
                client.user().setUserSettings(key, value, setting.id);
            }
        },
        async getFileUrl(fileId: string) {
            return await client.files().getFileUrl(fileId);
        }
    })
};
