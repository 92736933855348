import { document, documents } from '../../../../models/entities/common_subentities/document';
type stateType = { [key: string]: any };
function setDocuments(state: stateType, entityId: string, entityType: keyof stateType, documents: documents) {
    const newArr = [...state[entityType]];
    const index = newArr.findIndex((item) => item.id === entityId);
    if (index !== -1) {
        if (!newArr[index].documents) newArr[index].documents = { ...documents };
    }
    return JSON.parse(JSON.stringify(newArr));
}
function addDocument(state: stateType, entityId: string, entityType: keyof stateType, document: document) {
    const newArr = [...state[entityType]];
    const index = newArr.findIndex((item) => item.id === entityId);
    if (index !== -1) {
        let documents = [document];
        const oldDocuments = newArr[index].documents?.documents;
        if (oldDocuments && oldDocuments.length > 0) documents = [...oldDocuments, ...documents];
        newArr[index].documents = { ...newArr[index].documents, documents };
    }
    return JSON.parse(JSON.stringify(newArr));
}
function editDocument(state: stateType, entityId: string, entityType: keyof stateType, document: document) {
    const newArr = [...state[entityType]];
    const index = newArr.findIndex((item) => item.id === entityId);
    if (index !== -1) {
        const documents = newArr[index].documents?.documents.map((item: document) => {
            if (item.id === document.id) return { ...document };
            else return { ...item };
        });
        if (documents) newArr[index].documents = { ...newArr[index].documents, documents };
    }
    return JSON.parse(JSON.stringify(newArr));
}
function removeDocument(state: stateType, entityId: string, entityType: keyof stateType, documentId: number) {
    const newArr = [...state[entityType]];
    const index = newArr.findIndex((item) => item.id === entityId);
    if (index !== -1) {
        const oldDocuments = newArr[index].documents?.documents.filter((item: document) => item.id !== documentId);
        if (oldDocuments) newArr[index].documents = { ...newArr[index].documents, documents: oldDocuments };
    }
    return JSON.parse(JSON.stringify(newArr));
}

export { setDocuments, addDocument, editDocument, removeDocument };
