import { filterFieldsType } from './inventory';
import { inventoryTotalView } from '../../../models/entities/product/inventoryLog';

const filterInventory = (inventory: inventoryTotalView[], filterFields: filterFieldsType) => {
    let filteredInventory = [...inventory];

    filterFields.forEach((filter) => {
        switch (filter.field) {
            default:
                filteredInventory = filteredInventory.filter(() =>
                    (inventory as any)[filter.field] ? (inventory as any)[filter.field].toString().includes(filter.value as string) : inventory
                );
                break;
        }
    });
    return filteredInventory;
};

export default filterInventory;
