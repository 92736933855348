import React from 'react';
import { chatRequest } from '../../../models/entities/chat/chatRequest';
import { UserSettingsFormDropDown } from '../../shared/inputs/form';
import Button from '../../shared/SmallComponents/Button';
import Loader from '../../shared/SmallComponents/Loader';
import { styled } from '../../shared/Theme/theme';
import * as Yup from 'yup';
import { Formik, Form } from 'formik';

type Props = {
    sent?: Array<chatRequest>;
    recieved?: Array<chatRequest>;
    inviteUser: (email: string[]) => Promise<boolean>;
    approveRequest: (requestId: string) => void;
    denyRequest: (requestId: string) => void;
    setEmailAutoCompleteSettings: (emails: string[]) => void;
    localization: any;
};
const ChatRequests = ({ sent, recieved, inviteUser, approveRequest, denyRequest, setEmailAutoCompleteSettings, localization }: Props) => {
    const initialValues = { emails: [] };
    return (
        <Container>
            <Formik
                initialValues={initialValues}
                onSubmit={({ emails }, { resetForm }) => {
                    inviteUser(emails).then((success) => {
                        if (success) {
                            resetForm();
                            setEmailAutoCompleteSettings(emails);
                        }
                    });
                }}
                validationSchema={Yup.object({
                    emails: Yup.array()
                        .transform(function (value, originalValue) {
                            if (this.isType(value) && value !== null) {
                                return value;
                            }
                            return originalValue ? originalValue.split(/[\s,]+/) : [];
                        })
                        .of(Yup.string().email(({ value }) => `${value} ${localization.invalid_email} `))
                        .min(1, localization.required)
                })}
            >
                {(formik) => {
                    return (
                        <Form>
                            <InviteUser>
                                <div style={{ width: '100%', marginRight: '15px' }}>
                                    <UserSettingsFormDropDown
                                        name="emails"
                                        placeHolder={localization.enter_email}
                                        style={{ width: '100%' }}
                                    ></UserSettingsFormDropDown>
                                </div>
                                <Button buttonType="filled" height="30px">
                                    {localization.send_btn}
                                </Button>
                            </InviteUser>
                        </Form>
                    );
                }}
            </Formik>
            <RequestsContainer>
                <RequestsHeader>{localization.connection_requests}</RequestsHeader>
                {recieved ? (
                    recieved.map((req) => {
                        return (
                            <ItemContainer key={req.id}>
                                <span>
                                    <b>
                                        {req.sender_user.firstName} {req.sender_user.lastName}
                                    </b>{' '}
                                    from {req.sender_user.companyName}
                                </span>
                                {req.status === 'PENDING' && (
                                    <>
                                        <Button buttonType="filled" onClick={() => approveRequest(req.id)}>
                                            {localization.approve_btn}
                                        </Button>
                                        <span
                                            onClick={() => denyRequest(req.id)}
                                            className="material-icons"
                                            style={{ color: '#a2a2a2', fontSize: '12px', cursor: 'pointer' }}
                                        >
                                            close
                                        </span>
                                    </>
                                )}
                                {req.status === 'DENIED' && <StatusText style={{ color: '#008ac9' }}>{req.status}</StatusText>}
                            </ItemContainer>
                        );
                    })
                ) : (
                    <Loader width="40px" marginTop="0" showText={false} />
                )}
            </RequestsContainer>
            <RequestsContainer>
                <RequestsHeader>Sent</RequestsHeader>
                {sent ? (
                    sent.map((req) => {
                        return (
                            <ItemContainer key={req.id}>
                                <span>
                                    {localization.waiting_for} <b>{req.recieverEmail}</b> {localization.to_approve}
                                </span>
                                <StatusText style={{ color: '#f0a333' }}>{req.status}</StatusText>
                            </ItemContainer>
                        );
                    })
                ) : (
                    <Loader width="40px" marginTop="0" showText={false} />
                )}
            </RequestsContainer>
        </Container>
    );
};

const Container = styled.div`
    display: flex;
    flex-direction: column;
    height: 100%;
    padding: 0 10px;
    padding-top: 48px;
`;
const RequestsContainer = styled.div`
    margin: 10px 0;
`;
const ItemContainer = styled.div`
    display: flex;
    align-items: center;
    height: 50px;
    background-color: #e5f4fa5e;
    justify-content: space-between;
    padding: 10px;
    border-bottom: 1px solid #e4e4e4;
`;
const RequestsHeader = styled.div`
    color: #394372;
    font-size: 16px;
    font-weight: bold;
    margin-bottom: 10px;
`;
const InviteUser = styled.div`
    display: flex;
`;
const StatusText = styled.b`
    font-size: 12px;
`;
export default ChatRequests;
