import React, { useState } from 'react';
import { styled } from '../../../../../shared/Theme/theme';
import { link } from '../../../../../../models/entities/product/link';
import Loader from '../../../../../shared/SmallComponents/Loader';
import { ColumnType } from '../../../../../shared/Grid/types/Column';
import Grid from '../../../../../shared/Grid/Grid';
import CustomModal, { ModalTriggerProps } from '../../../../../shared/Modal/Modal';
import LinkFormConnected, { formMode } from '../../LinkForm/LinkFormConnected';
import Button from '../../../../../shared/SmallComponents/Button';
import { settings } from '../../../../../../models/entities/product/product';

type Props = {
    links?: Array<link>;
    localization: any;
    settings?: settings;
    productId: string;
};

const ProductsLinks = ({ links, localization, settings, productId }: Props) => {
    const gridColumns: Array<ColumnType<link>> = [
        {
            field: 'title',
            sortable: false,
            isDisplayed: true,
            width: '200px'
        },
        {
            field: 'url',
            sortable: false,
            isDisplayed: true,
            columnType: 'link'
        }
    ];

    const [modalVisible, setVisible] = useState(false);
    const [linkEntity, setLink] = useState({} as link);
    return (
        <LinksWrapper>
            {links ? (
                <>
                    <CustomModal
                        title={{ text: localization.links.modal.add_title }}
                        Trigger={(props: ModalTriggerProps) => (
                            <ButtonContainer>
                                <Button onClick={props.onClick} width="auto" buttonType="hollow" padding="4px 12px">
                                    {localization.links.add_link}
                                </Button>
                            </ButtonContainer>
                        )}
                        Content={(
                            setVisible?: React.Dispatch<React.SetStateAction<boolean>>,
                            setConfirm?: React.Dispatch<React.SetStateAction<boolean>>
                        ) => {
                            return (
                                <LinkFormConnected
                                    productId={productId}
                                    mode={formMode.NEW}
                                    openedFromModal={setVisible ? { setVisible } : undefined}
                                />
                            );
                        }}
                        width="500px"
                    />
                    {links.length > 0 ? (
                        <GridWrapper>
                            <Grid<link>
                                columns={gridColumns}
                                data={links}
                                localization={localization.links.grid}
                                settings={settings}
                                onSettingsClick={() => console.log('settings')}
                                onRowMoreOptionsClick={(row) => {
                                    setLink(row);
                                    setVisible(true);
                                }}
                                parentEntityId={productId}
                                menuType="productLinkMenu"
                            />
                            {modalVisible && (
                                <CustomModal
                                    title={{ text: localization.links.modal.edit_title }}
                                    isOpen={true}
                                    Content={(
                                        setModalVisible?: React.Dispatch<React.SetStateAction<boolean>>,
                                        setConfirm?: React.Dispatch<React.SetStateAction<boolean>>
                                    ) => {
                                        return (
                                            <LinkFormConnected
                                                productId={productId}
                                                mode={formMode.EDIT}
                                                link={linkEntity}
                                                openedFromModal={{ setVisible }}
                                            />
                                        );
                                    }}
                                    onCancelCallback={() => {
                                        setVisible(false);
                                        setLink({} as link);
                                    }}
                                    width="393px"
                                />
                            )}
                        </GridWrapper>
                    ) : (
                        <EmaptyStateText>{localization.links.empty_state}</EmaptyStateText>
                    )}
                </>
            ) : (
                <Loader />
            )}
        </LinksWrapper>
    );
};

const GridWrapper = styled.div`
    flex: 0 0 100%;
    max-width: 100%;
    width: 100%;
    box-sizing: border-box;
    overflow-y: overlay;
    height: 100%;
    width: 100%;
`;

const LinksWrapper = styled.div`
    display: flex;
    flex-direction: column;
    width: 100%;
`;

const EmaptyStateText = styled.span`
    color: ${(props) => props.theme.colors.emptyStateText};
`;

const ButtonContainer = styled.div`
    display: flex;
    justify-content: flex-end;
    padding-top: 8px;
`;

export default ProductsLinks;
