import React, { useState } from 'react';
import { businessPartner } from '../../../../../models/entities/businessPartner/businessPartner';
import Documents from './Documents/DocumentsTab';
import Contacts from './Contacts/ContactsTab';
import ProductsTab from './Products/ProductsTab';
import Addresses from './Addresses/AddressesTab';
import InventoryTab from './Inventory/InventoryTab';
import TabsContainer from '../../../../shared/Tabs/TabsContainer';
import Tab from '../../../../shared/Tabs/Tab';
import { settings } from '../../../../../models/entities/businessPartner/businessPartner';
import { product } from '../../../../../models/entities/product/product';
import { drawers } from '../../../../../state/ducks/header/header';

type Props = {
    businessPartner: businessPartner;
    products: product[];
    localization?: any;
    settings?: settings;
    setDrawerOpen: (open: drawers, params?: any) => void;
};

const CompanyTabs = ({ businessPartner, products, localization, settings, setDrawerOpen }: Props) => {
    const [activeTab, setActiveTab] = useState(!!products?.length ? 'Products' : businessPartner.type === 'WAREHOUSE' ? 'Inventory' : 'Contacts');
    return (
        <TabsContainer>
            {!!products?.length && (
                <Tab title="Products" onClick={() => setActiveTab('Products')} isActive={activeTab === 'Products'}>
                    <ProductsTab products={products} localization={localization.products} setDrawerOpen={setDrawerOpen} />
                </Tab>
            )}
            {businessPartner.type === 'WAREHOUSE' && businessPartner.inventory && (
                <Tab title="Inventory" onClick={() => setActiveTab('Inventory')} isActive={activeTab === 'Inventory'}>
                    <InventoryTab inventory={businessPartner.inventory} />
                </Tab>
            )}
            <Tab title="Contacts" onClick={() => setActiveTab('Contacts')} isActive={activeTab === 'Contacts'}>
                <Contacts contacts={businessPartner.contacts} localization={localization} />
            </Tab>
            <Tab title="Documents" onClick={() => setActiveTab('Documents')} isActive={activeTab === 'Documents'}>
                <Documents documents={businessPartner.documents?.documents} localization={localization} settings={settings} />
            </Tab>
            <Tab title="Addresses" onClick={() => setActiveTab('Addresses')} isActive={activeTab === 'Addresses'}>
                <Addresses addresses={businessPartner.addresses} localization={localization} />
            </Tab>
        </TabsContainer>
    );
};

export default CompanyTabs;
