import { userInfo } from './../../../models/entities/user';
import BaseController from '..';
import IUser from './IUser';

export default class UserApi extends BaseController implements IUser {
    constructor(baseUrl: string) {
        super(baseUrl);
    }
    async fetchUserInfo() {
        const response = await this.client.get('/user');
        return response;
    }
    async fetchUsersInSameCompany(companyId?: string) {
        const response = await this.client.post('/user/getUsersInSameCompany', JSON.stringify({ companyId }));
        return response;
    }

    async getUserById(userId: string) {
        const response = await this.client.get(`/user/${userId}`);
        return response;
    }

    async getUsersByIds(usersIds: string[]): Promise<{ [key: string]: userInfo }> {
        const response = await this.client.post(`/user/getMultiple`, JSON.stringify({ usersIds }));
        return response;
    }

    async updateUserStatus(status: string, statusMessage: string) {
        await this.client.post('/user/updateStatus', JSON.stringify({ status, statusMessage }));
    }
    async updateUserPicture(formData: FormData) {
        this.client.setBodyType('FORM-DATA');
        const response = await this.client.post('/user/uploadPicture', formData);
        return response;
    }
    async updateUserDetails(details: any) {
        const response = await this.client.post('/user/updateDetails', JSON.stringify(details));
        return response;
    }
    async updateUserPassword(currentPassword: string, password: string, passwordConfirm: string) {
        const response = await this.client.post('/user/updatePassword', JSON.stringify({ currentPassword, password, passwordConfirm }));
        return response;
    }
    async setUserSettings(key: string, value: any, id?: number) {
        const response = await this.client.post('/user/setSettings', JSON.stringify({ key, value, id }));
        return response;
    }
    async getUserSettingDefinitions() {
        const response = await this.client.get('/user/userSettingDefinitions');
        return response;
    }
}
