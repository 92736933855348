import React from 'react';
import { ColumnType } from '../../components/shared/Grid/types/Column';
import { amountByEntityType, amountByMarketplace, internalFlowInventoryLogsGridDataType } from '../../models/entities/product/inventoryLog';
import { Image } from 'antd';
import styled from 'styled-components';
import { currencyFormatter } from '../../components/shared/inputs/form/CurrencyInput/CurrencyInput';
import { eInternalFlowGridMode } from '../../state/ducks/internalFlowInventoryLogs/internalFlowInventoryLogs';
import EntitiesAmountsModal from './entitiesAmountsModal';
import AmazonMarketplacesAmountsModal from './amazonMarketplacesAmountsModal';
import { ModalTriggerProps } from '../../components/shared/Modal/Modal';
import ProductMarketplaceAsin from '../../components/shared/Grid/specialColumns/ProductMarketplaceAsin';

const totalsUnits = (
    unitField: keyof internalFlowInventoryLogsGridDataType,
    entitiesValuesField: keyof internalFlowInventoryLogsGridDataType,
    isAmazon?: boolean
): {
    bindedFields: (keyof internalFlowInventoryLogsGridDataType)[];
    renderer: (cellValue: any, bindedData?: any[] | null | undefined) => JSX.Element;
} => ({
    bindedFields: [unitField, entitiesValuesField, 'gridMode'],
    renderer: (cellValue: any, bindedData?: Array<any> | null) => {
        if (!cellValue || cellValue === '-') return <div>-</div>;

        const Trigger = (props: ModalTriggerProps) => (
            <LinkButton
                onMouseDown={(e) => {
                    props.onClick();
                }}
            >
                {`${
                    !bindedData?.[0]
                        ? `${cellValue}`
                        : bindedData?.[2] === eInternalFlowGridMode.VALUE
                        ? currencyFormatter(bindedData?.[0])(cellValue)
                        : `${cellValue} ${bindedData?.[0] || ''}`
                }`}
            </LinkButton>
        );
        return (
            <ModalTriggerContainer
                onClick={(e: any) => {
                    e.stopPropagation();
                }}
            >
                {isAmazon ? (
                    <AmazonMarketplacesAmountsModal amountsByMarketplaces={bindedData?.[1] as amountByMarketplace[]} Trigger={Trigger} />
                ) : (
                    <EntitiesAmountsModal amountsByEntities={bindedData?.[1] as amountByEntityType[]} Trigger={Trigger} />
                )}
            </ModalTriggerContainer>
        );
    }
});
const renderManualField = (
    unitField: keyof internalFlowInventoryLogsGridDataType
): {
    bindedFields: (keyof internalFlowInventoryLogsGridDataType)[];
    renderer: (cellValue: any, bindedData?: any[] | null | undefined) => JSX.Element;
} => ({
    bindedFields: [unitField, 'gridMode'],
    renderer: (cellValue: any, bindedData?: Array<any> | null) => {
        if (!cellValue || cellValue === '-') return <div>-</div>;

        return (
            <div>
                {`${
                    !bindedData?.[0]
                        ? `${cellValue}`
                        : bindedData?.[1] === eInternalFlowGridMode.VALUE
                        ? currencyFormatter(bindedData?.[0])(cellValue)
                        : `${cellValue} ${bindedData?.[0] || ''}`
                }`}
            </div>
        );
    }
});

const mockColumns: Array<ColumnType<internalFlowInventoryLogsGridDataType>> = [
    {
        field: 'name',
        sortable: true,
        isDisplayed: true,
        width: '200px',
        columnType: 'fakeLink'
    },
    {
        field: 'asin',
        sortable: true,
        isDisplayed: true,
        width: '160px',
        hideTitleAttribute: true,
        renderer: (cellValue: any) => {
            return <ProductMarketplaceAsin marketplaceAsin={cellValue} />;
        }
    },
    {
        field: 'sku',
        sortable: true,
        isDisplayed: true
    },
    {
        field: 'fnsku',
        sortable: true,
        isDisplayed: true
    },
    {
        field: 'productImgUrl',
        sortable: false,
        isDisplayed: true,
        textAlign: 'center',
        renderer: (cellValue: any, bindedData?: Array<any> | null) => {
            if (!cellValue || typeof cellValue !== 'string' || cellValue === '-') return <div>-</div>;
            return (
                <ImgContainer
                    onClick={(e: any) => {
                        e.stopPropagation();
                    }}
                >
                    <Image height={35} src={cellValue as string} />
                </ImgContainer>
            );
        }
    },
    {
        field: 'pdReservedTotal',
        sortable: true,
        isDisplayed: true,
        width: '100px',
        ...totalsUnits('pdReservedTotalUnit', 'reservedAmountByPDs')
    },
    {
        field: 'poInboundTotal',
        sortable: true,
        isDisplayed: true,
        ...totalsUnits('poInboundTotalUnit', 'inboundAmountByPOs')
    },
    {
        field: 'shipmentInboundTotal',
        sortable: true,
        isDisplayed: true,
        ...totalsUnits('shipmentInboundTotalUnit', 'inboundAmountByShipments')
    },
    {
        field: 'amazonOnHandTotal',
        sortable: true,
        isDisplayed: true,
        ...totalsUnits('amazonOnHandTotalUnit', 'amazonOnHand', true)
    },
    {
        field: 'amazonInboundTotal',
        sortable: true,
        isDisplayed: true,
        ...totalsUnits('amazonInboundTotalUnit', 'amazonInbound', true)
    },
    {
        field: 'amazonReservedTotal',
        sortable: true,
        isDisplayed: true,
        ...totalsUnits('amazonReservedTotalUnit', 'amazonReserved', true)
    },
    {
        field: 'manualOnHand',
        sortable: true,
        isDisplayed: true,
        ...renderManualField('manualOnHandUnit')
    },
    {
        field: 'manualInbound',
        sortable: true,
        isDisplayed: true,
        ...renderManualField('manualInboundUnit')
    },
    {
        field: 'manualReserved',
        sortable: true,
        isDisplayed: true,
        ...renderManualField('manualReservedUnit')
    }
];

const LinkButton = styled.div`
    color: #1890ff;
    text-decoration: none;
    background-color: transparent;
    outline: none;
    cursor: pointer;
    transition: color 0.3s;

    &:hover {
        color: #40a9ff;
    }
`;

const ModalTriggerContainer = styled.div``;

const ImgContainer = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;

    .ant-image-mask-info {
        font-size: 0;
        display: flex;
        justify-content: center;
        align-items: center;
    }

    .ant-image-mask-info .anticon {
        font-size: 14px;
        margin-inline-end: 0;
    }
`;

export default mockColumns;
