import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { adminSelectors } from '../../../../state/ducks/admin/selectors';
import { localizationSelectors } from '../../../../state/ducks/localization/selectors';
import { RootState } from '../../../../state/store/store';
import { deletedRecord } from '../../../../models/entities/admin/deletedRecord';
import DeletedRecordsPage from './DeletedRecordsPage';

type Props = {
    fetchDeletedRecords: () => Promise<void>;
    restoreEntity: (entityId: string, entityType: string) => Promise<void>;
    deleteEntity: (entityId: string, entityType: string) => Promise<void>;
    deletedRecords: deletedRecord[];
    localization: any;
    showLoader: boolean;
    setGridFilter: (text: string) => void;
    gridFilter: string | null;
};
const DeletedRecordsPageConnected = ({
    fetchDeletedRecords,
    deletedRecords,
    localization,
    showLoader,
    deleteEntity,
    restoreEntity,
    setGridFilter,
    gridFilter
}: Props) => {
    useEffect(() => {
        fetchDeletedRecords();
        return function cleanup() {
            setGridFilter('');
        };
    }, []);
    return (
        <DeletedRecordsPage
            fetchDeletedRecords={fetchDeletedRecords}
            deletedRecords={deletedRecords}
            localization={localization}
            showLoader={showLoader}
            restoreEntity={restoreEntity}
            deleteEntity={deleteEntity}
            setGridFilter={setGridFilter}
            gridFilter={gridFilter ?? ''}
        />
    );
};

const mapStateToProps = (state: RootState) => ({
    deletedRecords: adminSelectors.gridData(state),
    showLoader: !!state.loading.effects.admin.fetchDeletedRecords,
    localization: localizationSelectors.deletedRecordsPage(state),
    gridFilter: adminSelectors.gridFilter(state)
});

const mapDispatchToProps = (dispatch: any) => ({
    fetchDeletedRecords: () => dispatch.admin.fetchDeletedRecords(),
    restoreEntity: (entityId: string, entityType: string) => dispatch.admin.restoreEntity({ entityId, entityType }),
    deleteEntity: (entityId: string, entityType: string) => dispatch.admin.deleteEntity({ entityId, entityType }),
    setGridFilter: (text: string) => dispatch.admin.setGridFilter(text)
});

export default connect(mapStateToProps, mapDispatchToProps)(DeletedRecordsPageConnected);
