import React from 'react';
import styled from 'styled-components';
import { company } from '../../../../../models/entities/company/company';
import Grid from '../../../../shared/Grid/Grid';
import companiesGridColumns from './companiesGridColumns';
import { drawers } from '../../../../../state/ducks/header/header';
import Loader from '../../../../shared/SmallComponents/Loader';
import { useHistory } from 'react-router';
import { PagesRoutes } from '../../../../../routing/PagesRoutes';
import { featureFlagsModel } from '../../../../../models/entities/businessPartner/settings';
type Props = {
    companiesGridData: Array<company>;
    showLoader: boolean;
    localization?: any;
    setDrawerOpen: (open: drawers, params?: any) => void;
    getLwaState: (companyId: string) => Promise<string>;
    featureFlags?: featureFlagsModel;
};

const CompaniesMgmtGrid = ({ companiesGridData, showLoader, localization, setDrawerOpen, getLwaState, featureFlags }: Props) => {
    const history = useHistory();

    return showLoader ? (
        <Loader />
    ) : (
        <Wrapper>
            <Grid<company>
                columns={companiesGridColumns(localization, getLwaState, featureFlags)}
                data={companiesGridData}
                localization={localization.grid}
                emptyStateText={'localization.grid.emptyState'}
                onRowClick={(row) => history.push(`${PagesRoutes.CompaniesMgmt}/${row.id}`)}
            />
        </Wrapper>
    );
};

const Wrapper = styled.div`
    width: 100%;
    height: 100%;

    .MuiDataGrid-root {
        border: none;
    }
    .MuiDataGrid-root .MuiDataGrid-window {
        overflow-y: overlay !important;
    }
`;

export default CompaniesMgmtGrid;
