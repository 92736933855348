import React from 'react';
import { Link } from 'react-router-dom';
import { shipmentFollowingInvitation } from '../../../models/entities/shipment/shipmentFollowingInvitation';
import { PagesRoutes } from '../../../routing/PagesRoutes';
import Button from '../../shared/SmallComponents/Button';
import Loader from '../../shared/SmallComponents/Loader';
import { styled } from '../../shared/Theme/theme';
import { followingInvitation } from '../../../models/entities/common_subentities/followingInvitation';

type Props = {
    followingInvitations?: Array<followingInvitation>;
    isLoading: boolean;
    followShipment: (shipmentId: string, invitationId: string) => void;
    followPurchaseOrder: (purchaseOrderId: string, invitationId: string) => void;
    shipmentLocalization: any;
    purchaseOrderLocalization: any;
};

const Invitations = ({
    followingInvitations,
    isLoading,
    followShipment,
    followPurchaseOrder,
    shipmentLocalization,
    purchaseOrderLocalization
}: Props) => {
    if (isLoading) return <Loader />;
    const RenderShipmentItem = (item: followingInvitation) => (
        <Item key={item.id}>
            <Message>
                {item.inviteUserName} {shipmentLocalization.invite_to_follow}{' '}
                <Ref to={PagesRoutes.Shipments + `/${item.itemId}`}>#{item.itemNumber}</Ref>
            </Message>
            <Button onClick={() => followShipment(item.itemId, item.id)} buttonType="filled">
                {shipmentLocalization.follow}
            </Button>
        </Item>
    );
    const RenderPurchaseOrderItem = (item: followingInvitation) => (
        <Item key={item.id}>
            <Message>
                {item.inviteUserName} {purchaseOrderLocalization.invite_to_follow}{' '}
                <Ref to={PagesRoutes.PurchaseOrders + `/${item.itemId}`}>#{item.itemNumber}</Ref>
            </Message>
            <Button onClick={() => followPurchaseOrder(item.itemId, item.id)} buttonType="filled">
                {purchaseOrderLocalization.follow}
            </Button>
        </Item>
    );
    return (
        <div>
            {followingInvitations &&
                followingInvitations.map((item) => (item.type === 'SHIPMENT' ? RenderShipmentItem(item) : RenderPurchaseOrderItem(item)))}
        </div>
    );
};

const Item = styled.div`
    position: relative;
    display: flex;
    min-height: 86px;
    align-items: center;
    padding: 12px;
    background: whitesmoke;
    border-bottom: 1px solid #e0e0e0;
    justify-content: space-between;
`;
const Message = styled.div``;

const Ref = styled(Link)`
    cursor: pointer;
    color: #008ac9;
`;

export default Invitations;
