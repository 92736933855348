import React from 'react';
import { PagesRoutes } from '../../../routing/PagesRoutes';
import { settings, purchaseDemand } from '../../../models/entities/purchaseDemand/purchaseDemand';
import Overview from './sections/Overview';
import { styled } from '../../shared/Theme/theme';
import Button from '../../shared/SmallComponents/Button';
import EditIcon from '../../../static/icons/edit.svg';
import MenuIcon from '../../../static/icons/menu.svg';
import { Link } from 'react-router-dom';
import BackButton from '../../shared/SmallComponents/BackButton';
import PurchaseDemandTabs from './sections/Tabs';
import DiscussionsSection from './sections/Discussions/DiscussionsSection';
import moment from 'moment';
import PurchaseDemandMenu from '../../shared/Grid/specialColumns/PurchaseDemandMenu';
import { TriggerProps } from '../../shared/Menu/Menu';
import { useHistory } from 'react-router-dom';
import { DropDown } from '../../shared/inputs/base';
import { renderStateOption } from '../../shared/SmallComponents/StateOption';
import { settings as companySettings } from '../../../models/entities/businessPartner/businessPartner';
import Approvers from './PurchaseDemandForm/sections/approvers';
import { purchaseDemandApprover } from '../../../models/entities/purchaseDemand/purchaseDemandApprover';
import { userDropDown, userInfo } from '../../../models/entities/user';
import CustomModal, { ModalTriggerProps } from '../../shared/Modal/Modal';
import CreatePOFromPD from './sections/purchaseDemandItems/CreatePOFromPD';
import { claimsDomainsType } from '../../../userClaims';
import claimsType from '../../../models/entities/claimsType';

type Props = {
    purchaseDemand: purchaseDemand;
    updatePurchaseDemandState: (purchaseDemandId: string, purchaseDemandState: string) => void;
    purchaseDemandState?: string;
    localization?: any;
    settings?: settings;
    printMode?: boolean;
    companySettings?: companySettings;
    updatePurchaseDemandApproverState: (purchaseDemandId: string, approver: purchaseDemandApprover) => Promise<void>;
    createPurchaseDemandApprover: (purchaseDemandId: string, approver: purchaseDemandApprover) => Promise<void>;
    deletePurchaseDemandApprover: (purchaseDemandId: string, approverId: string) => Promise<void>;
    usersInSameCompany: userDropDown[] | undefined;
    userInfo: userInfo;
    getUserClaims: (domain: claimsDomainsType) => claimsType;
};

const SinglePurchaseDemandPage = ({
    purchaseDemand,
    updatePurchaseDemandState,
    purchaseDemandState,
    localization,
    settings,
    printMode,
    companySettings,
    updatePurchaseDemandApproverState,
    createPurchaseDemandApprover,
    usersInSameCompany,
    userInfo,
    deletePurchaseDemandApprover,
    getUserClaims
}: Props) => {
    const history = useHistory();

    return (
        <PageWrapper>
            <LeftSide>
                {!printMode && (
                    <SectionWrapper>
                        <div>
                            <BackButton style={{ marginLeft: '-30px' }} toPath={PagesRoutes.PurchaseDemands} text={localization.backLink} />
                        </div>
                        <PurchaseDemandStateDropDownContainer>
                            <DropDown
                                placeHolder={localization.purchase_demand_state}
                                style={{ width: '100%' }}
                                options={
                                    settings?.state
                                        ?.sort((a, b) => a.displayOrder - b.displayOrder)
                                        .map((item) => ({
                                            text: item.name,
                                            value: item.code,
                                            color: item.color
                                        })) || []
                                }
                                value={purchaseDemandState}
                                optionRenderer={renderStateOption}
                                onChange={(value: string) => {
                                    updatePurchaseDemandState(purchaseDemand.id, value);
                                }}
                                disabled={!getUserClaims('pd').edit_state}
                            />
                        </PurchaseDemandStateDropDownContainer>
                        {purchaseDemand.deletedAt && <InactiveBadge>{localization.deleted}</InactiveBadge>}
                        <HeaderRightSide>
                            {purchaseDemand.state === 'DRAFT' && (
                                <Button
                                    margin="0 10px"
                                    buttonType="filled"
                                    onClick={() => {
                                        updatePurchaseDemandState(purchaseDemand.id, 'WAITING_FOR_APPROVAL');
                                    }}
                                >
                                    {localization.send}
                                </Button>
                            )}
                            <PurchaseDemandMenu
                                entityId={purchaseDemand.id}
                                trigger={(props: TriggerProps) => (
                                    <img style={{ cursor: 'pointer' }} onClick={(e) => props.onClick(e)} src={MenuIcon} alt="Menu" />
                                )}
                                onDelete={() => history.push(PagesRoutes.PurchaseDemands)}
                            />
                        </HeaderRightSide>
                    </SectionWrapper>
                )}
                <OverviewSectionWrapper>
                    <Overview purchaseDemand={purchaseDemand} localization={localization} settings={settings} />
                </OverviewSectionWrapper>
                <OverviewSectionWrapper>
                    <Approvers
                        purchaseDemand={purchaseDemand}
                        localization={localization}
                        settings={settings}
                        updatePurchaseDemandApproverState={updatePurchaseDemandApproverState}
                        createPurchaseDemandApprover={createPurchaseDemandApprover}
                        usersInSameCompany={usersInSameCompany}
                        userInfo={userInfo}
                        deletePurchaseDemandApprover={deletePurchaseDemandApprover}
                        getUserClaims={getUserClaims}
                    />
                </OverviewSectionWrapper>
                <SectionWrapper>
                    <PurchaseDemandTabs
                        purchaseDemand={purchaseDemand}
                        localization={localization}
                        settings={settings}
                        companySettings={companySettings}
                    />
                </SectionWrapper>
                {!printMode && (
                    <SectionWrapper>
                        <DiscussionsSection localization={localization} />
                    </SectionWrapper>
                )}
            </LeftSide>
        </PageWrapper>
    );
};

const PageWrapper = styled.div`
    height: calc(100vh - 65px);
    display: flex;
    flex-direction: row;

    @media screen and (max-width: 768px) {
        height: calc(100vh - 65px - 40px);
    }
`;
const PurchaseDemandStateDropDownContainer = styled.div`
    align-self: flex-end;
    width: 25%;
    margin-left: 20px;
`;

const LeftSide = styled.div`
    width: 100%;

    @media screen and (max-width: 1300px) {
        width: 100%;
        max-width: 100%;
    }
`;

const OverviewSectionWrapper = styled.div`
    display: flex;
    flex-direction: row;
    border-bottom: solid 1px ${(props) => props.theme.colors.grayDefaultBorder};
    justify-content: space-between;
`;

export const SectionWrapper = styled.div`
    display: flex;
    flex-direction: row;
    padding: 24px 18px 18px 48px;
    border-bottom: solid 1px ${(props) => props.theme.colors.grayDefaultBorder};
    justify-content: space-between;
`;

const HeaderRightSide = styled.div`
    display: flex;
    height: 30px;
    margin: 30px 0 0 0;
`;

const LastUpdateText = styled.div`
    font-size: 12px;
    font-style: italic;
    color: #757575;
    padding: 16px 0 0;
`;

const InactiveBadge = styled.span`
    border-radius: 2vh;
    border: 1px solid #ff7676;
    color: #ff7676;
    font-size: 14px;
    height: 24px;
    padding: 3px 8px;
    margin-left: 10px;
    display: inline-flex;
    align-items: center;
    line-height: 1px;
    align-self: flex-end;
    min-width: max-content;
`;

export default SinglePurchaseDemandPage;
