import BaseController from '..';
import { bid } from '../../../models/entities/bid/bid';
import { party } from '../../../models/entities/bid/party';
import IBids from './IBids';

export default class Bids extends BaseController implements IBids {
    constructor(baseUrl: string) {
        super(baseUrl);
    }
    async fetchBids() {
        const response = await this.client.get('/bids/allActive');
        return response;
    }
    async fetchById(bidNumber: string) {
        const response = await this.client.get(`/bids/id/${bidNumber}`);
        return response;
    }
    async getMyRFQ(): Promise<Array<bid>> {
        const response = await this.client.get(`/bids/myRfq`);
        return response;
    }
    async createBid(bid: bid, shipmentPdfFile: File, publish: boolean) {
        this.client.setBodyType('FORM-DATA');
        const response = await this.client.post('/bids', this.convertBidToFormData(bid, publish, shipmentPdfFile));
        return response;
    }
    async updateBid(id: string, bid: bid, shipmentPdfFile: File, publish: boolean) {
        this.client.setBodyType('FORM-DATA');
        const response = await this.client.put('/bids', this.convertBidToFormData({ ...bid, id }, publish, shipmentPdfFile));
        return response;
    }
    async updateBidOverview(id: string, bid: bid) {
        const response = await this.client.put('/bids/overview', JSON.stringify({ bidId: id, bid }));
        return response;
    }
    async deleteBid(bidNumber: string) {
        await this.client.delete(`/bids/id/${bidNumber}`);
    }
    async startBid(id: string, shipmentPdfFile: File) {
        this.client.setBodyType('FORM-DATA');
        const formData = new FormData();

        formData.append('bidId', id);
        formData.append('file', shipmentPdfFile);

        const response = await this.client.put('/bids/start', formData);
        return response;
    }
    async reSendEmail(id: string, partyId: string, shipmentPdfFile: File) {
        this.client.setBodyType('FORM-DATA');

        const formData = new FormData();

        formData.append('bidId', id);
        formData.append('partyId', partyId);
        formData.append('file', shipmentPdfFile);

        const response = await this.client.post('/bids/email/resend', formData);
        return response;
    }

    async addBidParties(id: string, parties: party[], shipmentPdfFile?: File): Promise<party[]> {
        this.client.setBodyType('FORM-DATA');

        const formData = new FormData();

        formData.append('bidId', id);
        formData.append('parties', JSON.stringify(parties));

        if (shipmentPdfFile) formData.append('file', shipmentPdfFile);

        const response = await this.client.put('/bids/addParties', formData);
        return response;
    }

    //quotation
    async approveQuotation(quotationId: number, sendNotApprovedEmails?: boolean, ccRecipients?: string[]) {
        const response = await this.client.post('/quotation/approve', JSON.stringify({ quotationId, sendNotApprovedEmails, ccRecipients }));
        return response;
    }

    //public quotation
    async fetchByToken(token: string) {
        this.client.headers.Authorization = 'Bearer ' + token;
        const response = await this.client.get(`/public/quotation`);
        return response;
    }
    async createQuotation(formData: FormData, isPublic?: boolean) {
        this.client.setBodyType('FORM-DATA');
        const response = await this.client.post(`${isPublic ? '/public' : ''}/quotation`, formData);
        return response;
    }
    async quotationGetFile(fileId: string, bidId: string, partyId: string, isPublic?: boolean) {
        let response: any = '';
        if (isPublic) {
            response = await this.client.get(`/public/quotation/files/preview/${fileId}`);
        } else {
            response = await this.client.post(`/quotation/files/preview/${fileId}`, JSON.stringify({ bidId, partyId }));
        }

        if (!response || !response.data) return;
        const arrayBufferView = new Uint8Array(response.data.data);
        const blobUrl = URL.createObjectURL(new Blob([arrayBufferView], { type: response.type }));
        return { blobUrl, fileUrl: response.fileUrl ? response.fileUrl : blobUrl };
    }
    private convertBidToFormData(bid: bid, publish: boolean, shipmentPdfFile: File): FormData {
        const formData = new FormData();

        if (bid.id) formData.append('bidId', bid.id);
        if (bid.companyId) formData.append('companyId', bid.companyId);
        if (bid.shipmentId) formData.append('shipmentId', bid.shipmentId);
        if (bid.shipmentTypes) formData.append('shipmentTypes', JSON.stringify(bid.shipmentTypes));
        if (bid.note) formData.append('includeInsurance', JSON.stringify(bid.includeInsurance));
        if (bid.includeInsurance) formData.append('note', bid.note);
        if (bid.expirationDate) {
            if (typeof bid.expirationDate === 'string') formData.append('expirationDate', bid.expirationDate);
            else formData.append('expirationDate', bid.expirationDate.toISOString());
        }
        formData.append('parties', JSON.stringify(bid.parties));
        formData.append('publish', '' + publish);
        const documents = bid.documents;
        documents?.forEach((document) => {
            if (document.file) {
                formData.append('file[]', document.file);
                formData.append('fileName[]', document.fileName);
                formData.append('fileType[]', document.fileType);
                if (document.id) formData.append('id[]', '' + document.id);
            }
        });
        formData.append('file[]', shipmentPdfFile);
        return formData;
    }
}
