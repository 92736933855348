import BaseController from '../..';
import IPurchaseDemandHistory from './IPurchaseDemandHistory';

export default class PurchaseDemandHistory extends BaseController implements IPurchaseDemandHistory {
    constructor(baseUrl: string) {
        super(baseUrl);
    }
    async fetchPurchaseDemandHistoryById(purchaseDemandId: string) {
        const response = await this.client.get(`/purchaseDemand/history/${purchaseDemandId}`);
        return response;
    }
}
