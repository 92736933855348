import React, { useEffect, PropsWithChildren, useRef } from 'react';
import { styled } from '../Theme/theme';
import { scroller } from 'react-scroll';
import { has } from 'lodash';

type Props = {
    scrollToTabs?: boolean;
};

interface tabState {
    isActive: boolean;
    content: JSX.Element;
    title: string;
    key: string;
}

const TabsContainer = ({ children, scrollToTabs }: PropsWithChildren<Props>) => {
    const tabsContainerRef = useRef<HTMLHeadingElement>(null);

    const initialTabs: Array<tabState> = React.Children.toArray(children)
        .filter((c) => has(c, 'props'))
        .map((child: any, idx: number) => {
            return {
                isActive: child?.props.isActive,
                content: child?.props.children,
                title: child?.props.title,
                key: `tab_${idx}`
            };
        });

    const currentTab = initialTabs.find((tab) => tab.isActive === true);
    return (
        <ContentWrapper ref={tabsContainerRef} id="tabs-container">
            <TabsWrapper>
                <div id="container">{children}</div>
            </TabsWrapper>
            {currentTab && <TabContentWrapper>{currentTab.content}</TabContentWrapper>}
        </ContentWrapper>
    );
};

const TabsWrapper = styled.div`
    display: flex;
    width: 100%;
    border-bottom: 1px solid #e0e0e0;

    overflow-x: auto;
    @media screen and (max-width: 768px) {
        padding: 10px 10px 0 10px;
        #container {
            display: flex;
            min-width: min-content;
        }

        &::-webkit-scrollbar {
            display: none;
        }
    }

    #container {
        display: flex;
        width: 100%;
        min-width: 100%;
    }
`;
const TabContentWrapper = styled.div`
    @media screen and (max-width: 768px) {
        flex: 1;
        border-bottom: 1px solid #e0e0e0;
        overflow-y: overlay;

        &::-webkit-scrollbar {
            display: none;
        }
    }
`;

const ContentWrapper = styled.div`
    width: 100%;

    @media screen and (max-width: 768px) {
        display: flex;
        height: 100%;
        flex-direction: column;
    }
`;

export default TabsContainer;
