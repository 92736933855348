import React from 'react';
import { DateTimePicker } from '@material-ui/pickers';
import { MaterialUiPickersDate } from '@material-ui/pickers/typings/date';
import styled from 'styled-components';
import { connect } from 'react-redux';
import { RootState } from '../../../state/store/store';
import { localizationSelectors } from '../../../state/ducks/localization/selectors';

type Props = {
    onChange: (date: MaterialUiPickersDate) => void;
    value: Date;
    localization: any;
};

const StaticDateTimePicker = (props: Props) => {
    return (
        <DateTimePicker
            autoOk
            variant="static"
            value={props.value}
            onChange={props.onChange}
            dateRangeIcon={
                <Container>
                    <StyledIcon className="material-icons">today</StyledIcon>
                    <span>{props.localization.date}</span>
                </Container>
            }
            timeIcon={
                <Container>
                    <StyledIcon className="material-icons">schedule</StyledIcon>
                    <span>{props.localization.time}</span>
                </Container>
            }
        />
    );
};
const Container = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    text-transform: none;
    font-size: 15px;
`;
const StyledIcon = styled.span`
    margin-right: 5px;
    font-size: 22px;
`;
const mapStateToProps = (state: RootState) => ({
    localization: localizationSelectors.common(state)
});

const mapDispatchToProps = (dispatch: any) => ({});

export default connect(mapStateToProps, mapDispatchToProps)(StaticDateTimePicker);
