import React from 'react';
import { UserClaims } from '../../../../../../userClaims';
import { Accordion, AccordionDetails, AccordionSummary } from '../../../../../shared/MuiAccordion/MuiAccordion';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import styled from 'styled-components';
import { Field } from 'formik-antd';
import { Checkbox } from 'antd';
import { FieldProps } from 'formik';

type Props = {
    fieldName: string;
    localization: any;
};
const ClaimsFormField = ({ fieldName, localization }: Props) => {
    return (
        <>
            {Object.entries(UserClaims).map(([key, value], index) => {
                return (
                    <Accordion key={`claims-accordion-${index}`}>
                        <AccordionSummary expandIcon={<ExpandMoreIcon />}>{localization?.[key] || key}</AccordionSummary>
                        <AccordionDetails>
                            <ClaimsWrapper>
                                {Object.entries(value).map(([key, value], index) => {
                                    return (
                                        <Field name={`${fieldName}.${value}`} key={`${fieldName}-checkbox-${index}`}>
                                            {({ field: { value: fieldValue }, form: { setFieldValue } }: FieldProps) => {
                                                return (
                                                    <Checkbox
                                                        style={{ margin: 0 }}
                                                        onChange={(event) => {
                                                            setFieldValue(`${fieldName}.${value}`, event.target.checked ? '1' : '0');
                                                        }}
                                                        checked={!!Number(fieldValue)}
                                                    >
                                                        {localization?.[key] || key}
                                                    </Checkbox>
                                                );
                                            }}
                                        </Field>
                                    );
                                })}
                            </ClaimsWrapper>
                        </AccordionDetails>
                    </Accordion>
                );
            })}
        </>
    );
};

const ClaimsWrapper = styled.div`
    padding: 20px;
    display: flex;
    flex-direction: column;
`;

export default ClaimsFormField;
