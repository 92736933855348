import React from 'react';
import { PagesRoutes } from '../../../../routing/PagesRoutes';
import { Option } from '../../../shared/inputs/base/DropDown';
import { FormSectionType } from '../../../shared/SectionsForm/models/formSectionType';
import SectionsForm from '../../../shared/SectionsForm/SectionsForm';
import { General, Parties, Items } from './Sections';
import { useHistory } from 'react-router-dom';
import { createOrderPayload, updateOrderPayload } from '../../../../models/entities/order/payloads';
import { orderPartyType } from '../../../../models/entities/order/orderParty';
import { businessPartner } from '../../../../models/entities/businessPartner/businessPartner';
import { createOrderPartyPayload } from '../../../../models/entities/order/orderParty/payloads';
import { address } from '../../../../models/entities/businessPartner/address';
import { contact } from '../../../../models/entities/businessPartner/contact';
import { orderFormValidationSchema } from '../../../../validationSchemas/orderForm';
import { order } from '../../../../models/entities/order';

type Props = {
    allowedCompaniesDropDownOptions: Option[];
    getBusinessPartnerById: (id: string) => businessPartner | undefined;
    getBusinessPartnersDropDownOptionsByTypes: (types: string[]) => Option[];
    allowedShipmentTypes: Array<string>;
    localization: any;
    isEdit: boolean;
    order?: order;
    updateOrder: (order: updateOrderPayload) => Promise<void>;
    createOrder: (order: createOrderPayload) => Promise<void>;
};

type PartyFormMetadata = {
    addressId?: string;
    addresses?: address[];
    contacts?: contact[];
};

export type PartyFormValues<T extends orderPartyType = orderPartyType> = Partial<createOrderPartyPayload<T>> & PartyFormMetadata;

export type OrderFormValues = Partial<Omit<createOrderPayload, 'parties'>> & {
    parties: { [T in orderPartyType]?: PartyFormValues<T> };
};

const OrderForm = ({
    allowedCompaniesDropDownOptions,
    allowedShipmentTypes,
    localization,
    isEdit,
    order,
    createOrder,
    updateOrder,
    getBusinessPartnerById,
    getBusinessPartnersDropDownOptionsByTypes
}: Props) => {
    const history = useHistory();

    const initialValues: OrderFormValues =
        isEdit && order
            ? {
                  companyId: order.companyId,
                  companyName: order.companyName,
                  state: order.state,
                  orderDate: order.orderDate,
                  customerBusinessPartnerId: order.customerBusinessPartnerId,
                  pickupBusinessPartnerId: order.pickupBusinessPartnerId,
                  payerBusinessPartnerId: order.payerBusinessPartnerId,
                  pickupAddress: order.pickupAddress,
                  pickupCountry: order.pickupCountry,
                  pickupCity: order.pickupCity,
                  customerAddress: order.customerAddress,
                  customerCountry: order.customerCountry,
                  customerCity: order.customerCity,
                  payerSameAsCustomer: order.payerSameAsCustomer,
                  dueDate: order.dueDate,
                  poDate: order.poDate,
                  poNumber: order.poNumber,
                  soNumber: order.soNumber,
                  invoiceNumber: order.invoiceNumber,
                  incoterms: order.incoterms,
                  trackingNumber: order.trackingNumber,
                  carrier: order.carrier,
                  carrierBusinessPartnerId: order.carrierBusinessPartnerId,
                  weight: order.weight,
                  weightUnit: order.weightUnit,
                  volume: order.volume,
                  volumeUnit: order.volumeUnit,
                  paymentTerms: order.paymentTerms,
                  shippingInstructions: order.shippingInstructions,
                  requiredCustomsClearance: order.requiredCustomsClearance,
                  eta: order.eta,
                  parties: {
                      CUSTOMER: order?.parties?.find?.((p) => p.type === 'CUSTOMER') as PartyFormValues<'CUSTOMER'>,
                      PAYER: order?.parties?.find?.((p) => p.type === 'PAYER') as PartyFormValues<'PAYER'>,
                      PICKUP: order?.parties?.find?.((p) => p.type === 'PICKUP') as PartyFormValues<'PICKUP'>
                  }
              }
            : { parties: {} };

    const onSubmit = async (values: OrderFormValues) => {
        if (isEdit && order) {
            await updateOrder({ orderId: order.id, ...values });
        } else {
            await createOrder(values as createOrderPayload);
        }
        history.push(PagesRoutes.Orders);
    };

    const sections: FormSectionType<OrderFormValues>[] = [
        {
            name: 'General',
            component: (props) => (
                <General
                    formikProps={props}
                    allowedCompaniesDropDownOptions={allowedCompaniesDropDownOptions}
                    localization={localization}
                    getBusinessPartnerById={getBusinessPartnerById}
                    getBusinessPartnersDropDownOptionsByTypes={getBusinessPartnersDropDownOptionsByTypes}
                />
            ),
            errors: ['companyId', 'orderDate', 'customerBusinessPartnerId']
        },
        {
            name: 'Parties',
            component: (props) => <Parties formikProps={props} localization={localization} />,
            errors: []
        },
        {
            name: 'Items',
            component: (props) => <Items formikProps={props} localization={localization} />,
            errors: []
        }
    ];

    return (
        <SectionsForm<OrderFormValues>
            formikProps={{
                initialValues,
                onSubmit,
                validationSchema: orderFormValidationSchema
            }}
            sections={sections}
            previousPage={PagesRoutes.Orders}
            formHeader={{
                title: `${isEdit ? 'Edit' : 'New'} Order`,
                goBackBtnTitle: 'Go back to orders page',
                entityNumber: order?.cargoZoneNumber
            }}
        />
    );
};

export default OrderForm;
