import React, { useEffect, useState } from 'react';
import Tab from '../../shared/Tabs/Tab';
import TabsContainer from '../../shared/Tabs/TabsContainer';
import { styled } from '../../shared/Theme/theme';
import FollowingShipmentsConnected from '../FollowingShipments/FollowingShipmentsConnected';
import followIcon from '../../../static/icons/follow.svg';
import InvitationsConnected from './InvitationsConnected';
import { RootState } from '../../../state/store/store';
import { userSelectors } from '../../../state/ducks/user/selectors';
import { Badge } from '../../shared/Layout/Header/Header';
import { connect } from 'react-redux';
import { localizationSelectors } from '../../../state/ducks/localization/selectors';
import FollowingPurchaseOrdersConnected from '../FollowingPurchaseOrders/FollowingPurchaseOrdersConnected';
type Props = {
    shipmentInvitationsCount?: number;
    purchaseOrderInvitationsCount?: number;
    fetchUserShipmentFollowingInvitations: () => void;
    fetchUserPurchaseOrderFollowingInvitations: () => void;
    shipmentLocalization: any;
    purchaseOrderLocalization: any;
};

const FollowingTabs = ({
    shipmentInvitationsCount,
    purchaseOrderInvitationsCount,
    fetchUserShipmentFollowingInvitations,
    fetchUserPurchaseOrderFollowingInvitations,
    shipmentLocalization,
    purchaseOrderLocalization
}: Props) => {
    const [activeTab, setActiveTab] = useState('FOLLOWING_SHIPMENT');
    useEffect(() => {
        fetchUserShipmentFollowingInvitations();
        fetchUserPurchaseOrderFollowingInvitations();
    }, [fetchUserShipmentFollowingInvitations, fetchUserPurchaseOrderFollowingInvitations]);

    const compareActiveTab = (tab: string) => {
        return activeTab === tab;
    };
    let invitationsCount = 0;
    if (shipmentInvitationsCount) invitationsCount += shipmentInvitationsCount;
    if (purchaseOrderInvitationsCount) invitationsCount += purchaseOrderInvitationsCount;
    return (
        <Wrapper>
            <TabsContainer>
                <Tab
                    title={shipmentLocalization.following_tabs.following}
                    icon={<img src={followIcon} alt="Unfollow" />}
                    onClick={() => setActiveTab('FOLLOWING_SHIPMENT')}
                    isActive={compareActiveTab('FOLLOWING_SHIPMENT')}
                    fontSize="16px"
                >
                    <FollowingShipmentsConnected />
                </Tab>
                <Tab
                    title={purchaseOrderLocalization.following_tabs.following}
                    icon={<img src={followIcon} alt="Unfollow" />}
                    onClick={() => setActiveTab('FOLLOWING_PURCHASE_ORDER')}
                    isActive={compareActiveTab('FOLLOWING_PURCHASE_ORDER')}
                    fontSize="16px"
                >
                    <FollowingPurchaseOrdersConnected />
                </Tab>
                <Tab
                    fontSize="16px"
                    title={shipmentLocalization.following_tabs.invitations}
                    onClick={() => setActiveTab('INVITATIONS')}
                    isActive={compareActiveTab('INVITATIONS')}
                    badgeNumber={invitationsCount}
                >
                    <InvitationsConnected />
                </Tab>
            </TabsContainer>
        </Wrapper>
    );
};

const Wrapper = styled.div`
    padding-top: 48px;
`;
const mapStateToProps = (state: RootState) => ({
    shipmentInvitationsCount: userSelectors.shipmentFollowingInvitations(state)?.length,
    purchaseOrderInvitationsCount: userSelectors.purchaseOrderFollowingInvitations(state)?.length,
    shipmentLocalization: localizationSelectors.followingShipments(state),
    purchaseOrderLocalization: localizationSelectors.followingPurchaseOrders(state)
});

const mapDispatchToProps = (dispatch: any) => ({
    fetchUserShipmentFollowingInvitations: () => dispatch.user.fetchUserShipmentFollowingInvitations(),
    fetchUserPurchaseOrderFollowingInvitations: () => dispatch.user.fetchUserPurchaseOrderFollowingInvitations()
});
export default connect(mapStateToProps, mapDispatchToProps)(FollowingTabs);
