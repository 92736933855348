import React from 'react';
import styled from 'styled-components';
import Grid, { GridProps } from '../../Grid/Grid';
import GridPageHeader, { GridPageHeaderProps } from './components/GridPageHeader/GridPageHeader';
import Loader from '../../SmallComponents/Loader';

type Props<TEntity, TFilterFields = any> = {
    gridProps: GridProps<TEntity>;
    headerProps: GridPageHeaderProps<TEntity, TFilterFields>;
    showLoadingOverlay?: boolean;
};

function GridPage<TEntity, TFilterFields = any>({ showLoadingOverlay, gridProps, headerProps }: Props<TEntity, TFilterFields>) {
    return (
        <PageWrapper>
            {showLoadingOverlay && (
                <LoadingOverlay>
                    <Loader width="20px" marginTop="0" inline />
                </LoadingOverlay>
            )}
            <GridPageHeader<TEntity, TFilterFields> {...headerProps} />
            <GridWrapper>
                <Grid<TEntity> {...gridProps} />
            </GridWrapper>
        </PageWrapper>
    );
}

const PageWrapper = styled.div`
    height: 100%;
    display: flex;
    flex-direction: column;
`;

const LoadingOverlay = styled.div`
    background-color: #edf8f9;
    padding: 6px 10px;
    border-radius: 7px;
    position: absolute;
    z-index: 1012;
    top: 10px;
    left: 50%;
    transform: translateX(-50%);
`;

const GridWrapper = styled.div`
    flex: 1;
    max-width: 100%;
    width: 100%;
    box-sizing: border-box;
    overflow: overlay;
    height: 100%;
    width: 100%;
    z-index: 1;

    @media screen and (min-width: 768px) {
        height: calc(100% - 127px);
    }

    @media screen and (max-width: 768px) {
        height: calc(100% - 135px - 40px);
    }

    @media screen and (max-height: 400px) {
        height: calc(100% - 135px);
    }
`;

export default GridPage;
