import React from 'react';
import { FormDropDown, FormTextArea, LocationSearchInput } from '../../../../../shared/inputs/form';
import { styled } from '../../../../../shared/Theme/theme';
import { FieldContainer, renderFieldLabel } from '../../PurchaseOrderForm';
import { renderCountryOption } from '../../../../../shared/SmallComponents/CountryOption';
import countries from '../../../../../../static/data/countries.json';
import airports from '../../../../../../static/data/airports.json';
import { businessPartner } from '../../../../../../models/entities/businessPartner/businessPartner';
import { address, baseAddress } from '../../../../../../models/entities/businessPartner/address';
import { updatePickupAddress } from '../utils';
import { filterFieldsType } from '../../../../../../state/ducks/allPurchaseOrdersPage/allPurchaseOrdersPage';
import Vendor from './Vendor';
import Consignee from './Consignee';
import { contact } from '../../../../../../models/entities/businessPartner/contact';
import { userDetails } from '../../../../../../state/ducks/users/users';
import { allowedCompany, userDropDown } from '../../../../../../models/entities/user';

const countriesDropDownOptions = countries.map((country) => ({
    text: country.name,
    value: country.code
}));

type Props = {
    values: any;
    setFieldValue: any;
    businessPartnerSuppliers?: Array<businessPartner>;
    businessPartnerConsignees?: Array<businessPartner>;
    fetchShipmentsByFilter: (filter: filterFieldsType) => void;
    usersInSameCompany?: Array<userDropDown>;
    allowedCompanies: allowedCompany[];
    localizations: any;
};
const Geography = ({
    values,
    setFieldValue,
    fetchShipmentsByFilter,
    usersInSameCompany,
    businessPartnerSuppliers,
    businessPartnerConsignees,
    allowedCompanies,
    localizations
}: Props) => {
    let vendorAddresses: baseAddress[] = [];
    let consigneeAddresses: baseAddress[] = [];

    let vendorContacts: contact[] = [];
    let consigneeContacts: contact[] = [];
    if (values.vendorId) {
        vendorAddresses = businessPartnerSuppliers?.find((item) => item.id === values.vendorId)?.addresses || [];
        vendorContacts = businessPartnerSuppliers?.find((item) => item.id === values.vendorId)?.contacts || [];
    }

    if (values.consigneeId) {
        consigneeAddresses = businessPartnerConsignees?.find((item) => item.id === values.consigneeId)?.addresses || [];
        consigneeContacts = businessPartnerConsignees?.find((item) => item.id === values.consigneeId)?.contacts || [];
    }
    if (values.consignee_same_as_company) {
        consigneeContacts =
            usersInSameCompany?.map(
                ({ firstName, lastName, email, phoneNumber }, i) => ({ id: i, name: `${firstName} ${lastName}`, email, phoneNumber } as contact)
            ) || [];
        consigneeAddresses = allowedCompanies.find((c) => c.companyId === values.companyId)?.addresses || [];
    }
    return (
        <GeographyFormSection>
            <Vendor
                values={values}
                setFieldValue={setFieldValue}
                fetchShipmentsByFilter={fetchShipmentsByFilter}
                vendorAddresses={vendorAddresses}
                vendorContacts={vendorContacts}
                localizations={localizations.form}
            />
            <Consignee
                values={values}
                setFieldValue={setFieldValue}
                fetchShipmentsByFilter={fetchShipmentsByFilter}
                consigneeAddresses={consigneeAddresses}
                consigneeContacts={consigneeContacts}
                localizations={localizations.form}
            />
        </GeographyFormSection>
    );
};

const GeographyFormSection = styled.div`
    display: flex;
    flex-direction: row;
`;

export default Geography;
