import React from 'react';

import { styled } from '../../Theme/theme';
import { mapCodeIcon } from '../../../../utils/countries-flags';

type Props = {
    country: string;
    port: string;
    flagSize?: string;
    portFontWeight?: string;
    portFontSize?: string;
};
const ShipmentStation = ({ country, port, flagSize, portFontWeight, portFontSize }: Props) => {
    const flagURL = mapCodeIcon(country.toUpperCase());
    return (
        <Wrapper>
            <FlagIcon alt={port} src={flagURL} width={flagSize} />{' '}
            <PortName fontWeight={portFontWeight} fontSize={portFontSize}>
                {' '}
                {port}{' '}
            </PortName>
        </Wrapper>
    );
};

const Wrapper = styled.div`
    display: flex;
    justify-content: left;
    align-items: center;
`;

type StyledFlag = {
    width?: string;
};

const FlagIcon = styled.img<StyledFlag>`
    width: ${(props) => props.width ?? '20px'};
    margin-right: 6px;
`;

interface portNameProps {
    fontWeight?: string;
    fontSize?: string;
}
const PortName = styled.div<portNameProps>`
    color: ${(props) => props.theme.colors.gridCell};
    font-size: ${(props) => props.fontSize ?? props.theme.fontSizes.gridCell};
    text-transform: uppercase;
    font-weight: ${(props) => props.fontWeight ?? '500'};
`;

export default ShipmentStation;
