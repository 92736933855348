import DatePicker from './DatePicker';
import DropDown from './DropDown';
import TextArea from './TextArea';
import TextInput from './TextInput';
import Checkbox from './Checkbox';
import UserDropDown from './UserDropDown';
import AutoComplete from './AutoComplete';

export const getPopupContainerPopoverBug = (trigger: any, preventPopoverBug?: boolean): HTMLElement => {
    if (preventPopoverBug) {
        const parentElement = recursiveParentElement(trigger);
        return parentElement || document.body;
    } else return document.body;
};

const recursiveParentElement = (trigger: any): any => {
    if (!trigger) return null;
    else if (trigger.parentElement && trigger.parentElement.dataset && trigger.parentElement.dataset.popoverBug) return trigger.parentElement;
    return recursiveParentElement(trigger.parentElement);
};

export { DatePicker, DropDown, TextArea, TextInput, Checkbox, UserDropDown, AutoComplete };
