import { paymentDate, poOverview, purchaseOrder, settingsLanguage } from '../../../models/entities/purchaseOrder/purchaseOrder';
import { ModelConfig } from '@rematch/core';
import { localSDK as client } from '../../../sdk';
import moment from 'moment';
import { addParty, editParty, removeParty, setParties } from '../../util/common_subentities/parties';
import { addEvent, editEvent, removeEvent, setEvents } from '../../util/common_subentities/events';
import { addDocument, editDocument, removeDocument, setDocuments } from '../../util/common_subentities/documents';
import { addDiscussion, editDiscussion, removeDiscussion, setDiscussions } from '../../util/common_subentities/discussions';
import { event } from '../../../models/entities/common_subentities/event';
import { document, documents } from '../../../models/entities/common_subentities/document';
import { party } from '../../../models/entities/common_subentities/party';
import { discussion } from '../../../models/entities/common_subentities/discussion';
import { filterFieldsType } from '../allShipmentsPage/allShipmentsPage';
import { shipment } from '../../../models/entities/shipment/shipment';
import { purchaseOrderItem } from '../../../models/entities/purchaseOrder/purchaseOrderItem';
import { purchaseOrderReference } from '../../../models/entities/purchaseOrder/purchaseOrderReference';
import { addEntity, editEntity, removeEntity } from '../../util/common_subentities/generic';
import { history } from '../../../models/entities/common_subentities/history';
import { setHistory } from '../../util/common_subentities/history';
import { updatePurchaseOrderItemsValuesPayload } from '../../../models/entities/purchaseOrder/updatePurchaseOrderItemsValuesPayload';
import { purchaseOrderFollower } from '../../../models/entities/purchaseOrder/purchaseOrderFollower';
import {
    sendSupplierUpdatesEmailPayload,
    supplierUpdatesUpdatePoPayload,
    supplierUpdatesUpdatePoItemsReadyDatePayload
} from '../../../models/entities/purchaseOrder/payloads';

export type purchaseOrdersStateType = {
    purchaseOrders: Array<purchaseOrder>;
    error?: string;
    errorFetchSinglePurchaseOrder?: string;
    filteredShipments?: Array<shipment>;
};

const checkLastEvent = (events?: Array<event>): event | undefined => {
    const lastEvent: event | undefined = events?.reduce((max, event) => (moment(max.eventDate) > moment(event.eventDate) ? max : event));

    return lastEvent;
};

type createMultiplePurchaseOrderItemsPayload = {
    purchaseOrderId: string;
    items: Array<purchaseOrderItem>;
};

type createPurchaseOrderEventPayload = {
    purchaseOrderNumber: string;
    event: event;
};

type createPurchaseOrderPayload = {
    purchaseOrder: purchaseOrder;
};

type updatePurchaseOrderPayload = {
    id: string;
    purchaseOrder: purchaseOrder;
};

type inviteUsersToFollowPayload = {
    users: Array<string>;
    purchaseOrderId: string;
    purchaseOrderNumber: string;
    message: string;
};

export const purchaseOrders: ModelConfig<purchaseOrdersStateType> = {
    state: {
        purchaseOrders: []
    },
    reducers: {
        setPurchaseOrders(state: purchaseOrdersStateType, purchaseOrders: Array<purchaseOrder>): purchaseOrdersStateType {
            return { ...state, purchaseOrders };
        },
        setFetchPurchaseOrdersError(state: purchaseOrdersStateType, error: string): purchaseOrdersStateType {
            return { ...state, error };
        },
        setFilteredShipments(state: purchaseOrdersStateType, filteredShipments: Array<shipment>): purchaseOrdersStateType {
            return { ...state, filteredShipments };
        },
        setSinglePurchaseOrder(state: purchaseOrdersStateType, newPurchaseOrder: purchaseOrder): purchaseOrdersStateType {
            const newPurchaseOrders = [...state.purchaseOrders];
            const purchaseOrderIndex = newPurchaseOrders.findIndex((purchaseOrder) => purchaseOrder.id === newPurchaseOrder.id);
            if (purchaseOrderIndex !== -1) {
                newPurchaseOrders[purchaseOrderIndex] = newPurchaseOrder;
            } else {
                newPurchaseOrders.push(newPurchaseOrder);
            }
            return { ...state, purchaseOrders: newPurchaseOrders };
        },
        setPurchaseOrderOverviewData(
            state: purchaseOrdersStateType,
            purchaseOrderId: string,
            PurchaseOrderOverview: poOverview
        ): purchaseOrdersStateType {
            const newPurchaseOrders = [...state.purchaseOrders];
            const purchaseOrderIndex = newPurchaseOrders.findIndex((purchaseOrder) => purchaseOrder.id === purchaseOrderId);
            if (purchaseOrderIndex !== -1) {
                newPurchaseOrders[purchaseOrderIndex] = { ...newPurchaseOrders[purchaseOrderIndex], ...PurchaseOrderOverview };
            }
            return { ...state, purchaseOrders: newPurchaseOrders };
        },
        setPurchaseOrderPaymentDates(state: purchaseOrdersStateType, id: string, paymentDates: Array<paymentDate>): purchaseOrdersStateType {
            const newPurchaseOrders = [...state.purchaseOrders];
            const purchaseOrderIndex = newPurchaseOrders.findIndex((purchaseOrder) => purchaseOrder.id === id);
            if (purchaseOrderIndex !== -1) {
                newPurchaseOrders[purchaseOrderIndex] = { ...newPurchaseOrders[purchaseOrderIndex], paymentDates };
            }
            return { ...state, purchaseOrders: newPurchaseOrders };
        },
        setMultiplePurchaseOrders(state: purchaseOrdersStateType, purchaseOrders: purchaseOrder[]): purchaseOrdersStateType {
            const newPurchaseOrders = [...state.purchaseOrders];
            purchaseOrders.forEach((purchaseOrder) => {
                const purchaseOrderIndex = newPurchaseOrders.findIndex((s) => s.id === purchaseOrder.id);

                if (purchaseOrderIndex !== -1) {
                    // newPurchaseOrders[purchaseOrderIndex] = purchaseOrder;
                } else {
                    newPurchaseOrders.push(purchaseOrder);
                }
            });
            return { ...state, purchaseOrders: newPurchaseOrders };
        },
        editPurchaseOrder(state: purchaseOrdersStateType, purchaseOrder: purchaseOrder): purchaseOrdersStateType {
            let currentPurchaseOrders = [...state.purchaseOrders];
            const purchaseOrderIndex = currentPurchaseOrders.findIndex((currentPurchaseOrder) => currentPurchaseOrder.id === purchaseOrder.id);
            currentPurchaseOrders[purchaseOrderIndex] = purchaseOrder;
            return { ...state, purchaseOrders: currentPurchaseOrders };
        },
        removePurchaseOrder(state: purchaseOrdersStateType, purchaseOrderNumber: string): purchaseOrdersStateType {
            const newPurchaseOrders = [...state.purchaseOrders];
            return { ...state, purchaseOrders: newPurchaseOrders.filter((item) => item.id !== purchaseOrderNumber) };
        },
        addPurchaseOrderFollower(
            state: purchaseOrdersStateType,
            purchaseOrderNumber: string,
            purchaseOrderFollower: purchaseOrderFollower
        ): purchaseOrdersStateType {
            const newPurchaseOrders = [...state.purchaseOrders];
            const purchaseOrderIndex = newPurchaseOrders.findIndex((purchaseOrder) => purchaseOrder.id === purchaseOrderNumber);
            if (purchaseOrderIndex !== -1) {
                let followers = [purchaseOrderFollower];
                const oldFollowers = newPurchaseOrders[purchaseOrderIndex].followers;
                if (oldFollowers && oldFollowers.length > 0) followers = [...oldFollowers, ...followers];
                newPurchaseOrders[purchaseOrderIndex].followers = followers;
            }
            return { ...state, purchaseOrders: newPurchaseOrders };
        },
        removePurchaseOrderFollower(state: purchaseOrdersStateType, purchaseOrderNumber: string, userId: string): purchaseOrdersStateType {
            const newPurchaseOrders = [...state.purchaseOrders];
            const purchaseOrderIndex = newPurchaseOrders.findIndex((purchaseOrder) => purchaseOrder.id === purchaseOrderNumber);
            if (purchaseOrderIndex !== -1) {
                const oldFollowers = newPurchaseOrders[purchaseOrderIndex].followers?.filter((item) => item.userId !== userId);
                newPurchaseOrders[purchaseOrderIndex].followers = oldFollowers;
            }
            return { ...state, purchaseOrders: newPurchaseOrders };
        },
        setPurchaseOrderState(state: purchaseOrdersStateType, purchaseOrderId: string, purchaseOrderState: string): purchaseOrdersStateType {
            const newPurchaseOrders = [...state.purchaseOrders];
            const purchaseOrdersIndex = newPurchaseOrders.findIndex((purchaseOrder) => purchaseOrder.id === purchaseOrderId);
            if (purchaseOrdersIndex !== -1) {
                newPurchaseOrders[purchaseOrdersIndex].state = purchaseOrderState;
            }
            return { ...state, purchaseOrders: newPurchaseOrders };
        },
        setPurchaseOrderItem(state: purchaseOrdersStateType, item: purchaseOrderItem): purchaseOrdersStateType {
            const newPurchaseOrders = [...state.purchaseOrders];
            const purchaseOrderIndex = newPurchaseOrders.findIndex((purchaseOrder) => purchaseOrder.id === item.purchaseOrderId);
            if (purchaseOrderIndex !== -1) {
                const currentPO = { ...newPurchaseOrders[purchaseOrderIndex] };
                const currentPoItems = [...(currentPO.items || [])];
                const poItemIndex = currentPoItems.findIndex((poItem) => poItem.id === item.id);
                if (poItemIndex !== -1) {
                    currentPoItems[poItemIndex] = item;
                    currentPO.items = currentPoItems;
                    newPurchaseOrders[purchaseOrderIndex] = currentPO;
                }
            }
            return { ...state, purchaseOrders: newPurchaseOrders };
        },
        addMultiplePurchaseOrderItems(
            state: purchaseOrdersStateType,
            purchaseOrderId: string,
            items: Array<purchaseOrderItem>
        ): purchaseOrdersStateType {
            const newPOs = [...state.purchaseOrders];
            const purchaseOrderIndex = newPOs.findIndex((p) => p.id === purchaseOrderId);
            if (purchaseOrderIndex !== -1) {
                const currentPO = { ...newPOs[purchaseOrderIndex] };
                const newItemsIds = items.map((i) => i.id);
                const currentPOItems = [...(currentPO.items || [])].filter((item) => !newItemsIds.includes(item.id));
                const newPoItems = [...currentPOItems, ...items];
                currentPO.items = newPoItems;
                newPOs[purchaseOrderIndex] = currentPO;
            }
            return { ...state, purchaseOrders: [...newPOs] };
        },
        setPurchaseOrderItemsReadyDate(
            state: purchaseOrdersStateType,
            { purchaseOrderId, ids, values }: updatePurchaseOrderItemsValuesPayload
        ): purchaseOrdersStateType {
            const newPOs = [...state.purchaseOrders];
            const purchaseOrderIndex = newPOs.findIndex((p) => p.id === purchaseOrderId);
            if (purchaseOrderIndex !== -1) {
                const currentPO = { ...newPOs[purchaseOrderIndex] };
                const newPoItems = [...(currentPO.items || [])].map((item) => (ids.includes(item.id) ? { ...item, ...values } : item));
                currentPO.items = newPoItems;
                newPOs[purchaseOrderIndex] = currentPO;
            }
            return { ...state, purchaseOrders: [...newPOs] };
        },
        removePurchaseOrderItem(state: purchaseOrdersStateType, purchaseOrderId: string, itemId: string) {
            const newPOs = [...state.purchaseOrders];
            const purchaseOrderIndex = newPOs.findIndex((p) => p.id === purchaseOrderId);

            if (purchaseOrderIndex !== -1) {
                const currentPO = { ...newPOs[purchaseOrderIndex] };
                const currentPOItems = [...(currentPO.items || [])];
                const purchaseOrderItemIndex = currentPOItems.findIndex((item) => item.id === itemId);

                if (purchaseOrderItemIndex != null && purchaseOrderItemIndex !== -1) {
                    currentPOItems.splice(purchaseOrderItemIndex, 1);
                    currentPO.items = currentPOItems;
                }

                newPOs[purchaseOrderIndex] = currentPO;
            }
            return { ...state, purchaseOrders: [...newPOs] };
        },
        setPurchaseOrderEvents(state: purchaseOrdersStateType, purchaseOrderId: string, purchaseOrderEvents: Array<event>): purchaseOrdersStateType {
            return { ...state, purchaseOrders: setEvents(state, purchaseOrderId, 'purchaseOrders', purchaseOrderEvents) };
        },
        addPurchaseOrderEvent(state: purchaseOrdersStateType, purchaseOrderNumber: string, event: event): purchaseOrdersStateType {
            return { ...state, purchaseOrders: addEvent(state, purchaseOrderNumber, 'purchaseOrders', event, checkLastEvent) };
        },
        editPurchaseOrderEvent(state: purchaseOrdersStateType, purchaseOrderNumber: string, event: event): purchaseOrdersStateType {
            return { ...state, purchaseOrders: editEvent(state, purchaseOrderNumber, 'purchaseOrders', event, checkLastEvent) };
        },
        removePurchaseOrderEvent(state: purchaseOrdersStateType, purchaseOrderNumber: string, eventId: number): purchaseOrdersStateType {
            return { ...state, purchaseOrders: removeEvent(state, purchaseOrderNumber, 'purchaseOrders', eventId, checkLastEvent) };
        },
        setPurchaseOrderDocuments(
            state: purchaseOrdersStateType,
            purchaseOrderId: string,
            purchaseOrderDocuments: documents
        ): purchaseOrdersStateType {
            return { ...state, purchaseOrders: setDocuments(state, purchaseOrderId, 'purchaseOrders', purchaseOrderDocuments) };
        },
        addPurchaseOrderDocument(state: purchaseOrdersStateType, purchaseOrderNumber: string, document: document): purchaseOrdersStateType {
            return { ...state, purchaseOrders: addDocument(state, purchaseOrderNumber, 'purchaseOrders', document) };
        },
        editPurchaseOrderDocument(state: purchaseOrdersStateType, purchaseOrderNumber: string, document: document): purchaseOrdersStateType {
            return { ...state, purchaseOrders: editDocument(state, purchaseOrderNumber, 'purchaseOrders', document) };
        },
        removePurchaseOrderDocument(state: purchaseOrdersStateType, purchaseOrderNumber: string, documentId: number): purchaseOrdersStateType {
            return { ...state, purchaseOrders: removeDocument(state, purchaseOrderNumber, 'purchaseOrders', documentId) };
        },
        setPurchaseOrderParties(
            state: purchaseOrdersStateType,
            purchaseOrderId: string,
            purchaseOrderParties: Array<party>
        ): purchaseOrdersStateType {
            return { ...state, purchaseOrders: setParties(state, purchaseOrderId, 'purchaseOrders', purchaseOrderParties) };
        },
        addPurchaseOrderParty(state: purchaseOrdersStateType, purchaseOrderNumber: string, party: party): purchaseOrdersStateType {
            return { ...state, purchaseOrders: addParty(state, purchaseOrderNumber, 'purchaseOrders', party) };
        },
        editPurchaseOrderParty(state: purchaseOrdersStateType, purchaseOrderNumber: string, party: party): purchaseOrdersStateType {
            return { ...state, purchaseOrders: editParty(state, purchaseOrderNumber, 'purchaseOrders', party) };
        },
        removePurchaseOrderParty(state: purchaseOrdersStateType, purchaseOrderNumber: string, partyId: number): purchaseOrdersStateType {
            return { ...state, purchaseOrders: removeParty(state, purchaseOrderNumber, 'purchaseOrders', partyId) };
        },
        setPurchaseOrderDiscussions(
            state: purchaseOrdersStateType,
            purchaseOrderId: string,
            purchaseOrderDiscussions: Array<discussion>
        ): purchaseOrdersStateType {
            return { ...state, purchaseOrders: setDiscussions(state, purchaseOrderId, 'purchaseOrders', purchaseOrderDiscussions) };
        },
        addPurchaseOrderReference(
            state: purchaseOrdersStateType,
            purchaseOrderNumber: string,
            reference: purchaseOrderReference
        ): purchaseOrdersStateType {
            return {
                ...state,
                purchaseOrders: addEntity<purchaseOrderReference>(state, purchaseOrderNumber, 'purchaseOrders', 'references', reference)
            };
        },
        editPurchaseOrderReference(
            state: purchaseOrdersStateType,
            purchaseOrderNumber: string,
            reference: purchaseOrderReference
        ): purchaseOrdersStateType {
            return {
                ...state,
                purchaseOrders: editEntity<purchaseOrderReference>(state, purchaseOrderNumber, 'purchaseOrders', 'references', reference)
            };
        },
        setPurchaseOrderHistory(
            state: purchaseOrdersStateType,
            purchaseOrderId: string,
            purchaseOrderHistory: Array<history>
        ): purchaseOrdersStateType {
            return { ...state, purchaseOrders: setHistory(state, purchaseOrderId, 'purchaseOrders', purchaseOrderHistory) };
        },
        removePurchaseOrderReference(state: purchaseOrdersStateType, purchaseOrderNumber: string, referenceId: number): purchaseOrdersStateType {
            return { ...state, purchaseOrders: removeEntity<document>(state, purchaseOrderNumber, 'purchaseOrders', 'references', referenceId) };
        },
        addPurchaseOrderDiscussion(state: purchaseOrdersStateType, purchaseOrderNumber: string, discussion: discussion): purchaseOrdersStateType {
            return { ...state, purchaseOrders: addDiscussion(state, purchaseOrderNumber, 'purchaseOrders', discussion) };
        },
        editPurchaseOrderDiscussion(state: purchaseOrdersStateType, purchaseOrderNumber: string, discussion: discussion): purchaseOrdersStateType {
            return { ...state, purchaseOrders: editDiscussion(state, purchaseOrderNumber, 'purchaseOrders', discussion) };
        },
        removePurchaseOrderDiscussion(state: purchaseOrdersStateType, purchaseOrderNumber: string, discussionId: string): purchaseOrdersStateType {
            return { ...state, purchaseOrders: removeDiscussion(state, purchaseOrderNumber, 'purchaseOrders', discussionId) };
        },
        setFetchSinglePurchaseOrderError(state: purchaseOrdersStateType, error: string) {
            return { ...state, errorFetchSinglePurchaseOrder: error };
        },
        setFetchPurchaseOrderPartiesError(state: purchaseOrdersStateType, error: string): purchaseOrdersStateType {
            return { ...state, error };
        },
        setFetchPurchaseOrderEventsError(state: purchaseOrdersStateType, error: string): purchaseOrdersStateType {
            return { ...state, error };
        },
        setFetchPurchaseOrderDocumentsError(state: purchaseOrdersStateType, error: string): purchaseOrdersStateType {
            return { ...state, error };
        },
        setFetchPurchaseOrderItemsError(state: purchaseOrdersStateType, error: string): purchaseOrdersStateType {
            return { ...state, error };
        },
        setFetchPurchaseOrderReferencesError(state: purchaseOrdersStateType, error: string): purchaseOrdersStateType {
            return { ...state, error };
        },
        setError(state: purchaseOrdersStateType, error: string): purchaseOrdersStateType {
            return { ...state, error };
        }
    },
    effects: (dispatch: any) => ({
        async fetchPurchaseOrders() {
            const purchaseOrders = await client.purchaseOrders().fetchPurchaseOrders();
            dispatch.purchaseOrders.setPurchaseOrders(purchaseOrders);
            dispatch.allPurchaseOrdersPage.setCurrentPage(0);
        },
        async fetchPurchaseOrderHistoryById(purchaseOrderId: string) {
            const purchaseOrderHistoryData = await client.purchaseOrders().history().fetchPurchaseOrderHistoryById(purchaseOrderId);
            dispatch.purchaseOrders.setPurchaseOrderHistory(purchaseOrderId, purchaseOrderHistoryData);
        },
        async fetchShipmentsByFilter(filter: filterFieldsType) {
            const shipments = await client.shipments().fetchShipmentsByFilter(filter);
            dispatch.purchaseOrders.setFilteredShipments(shipments);
        },
        async fetchPurchaseOrderById(purchaseOrderId: string) {
            const purchaseOrderData = await client.purchaseOrders().fetchById(purchaseOrderId);
            dispatch.purchaseOrders.setSinglePurchaseOrder(purchaseOrderData);

            return purchaseOrderData;
        },
        async fetchPurchaseOrdersByIds(purchaseOrdersIds: string[]) {
            const purchaseOrdersData = await client.purchaseOrders().fetchByIds(purchaseOrdersIds);
            dispatch.purchaseOrders.setMultiplePurchaseOrders(purchaseOrdersData);
        },
        async fetchPurchaseOrderPartiesById(purchaseOrderId: string) {
            const purchaseOrderPartiesData = await client.purchaseOrders().parties().fetchPurchaseOrderParties(purchaseOrderId);
            dispatch.purchaseOrders.setPurchaseOrderParties(purchaseOrderId, purchaseOrderPartiesData);
        },
        async fetchPurchaseOrderEventsById(purchaseOrderId: string) {
            const purchaseOrderEventsData = await client.purchaseOrders().events().fetchPurchaseOrderEvents(purchaseOrderId);
            dispatch.purchaseOrders.setPurchaseOrderEvents(purchaseOrderId, purchaseOrderEventsData);
        },
        async fetchPurchaseOrderDocumentsById(purchaseOrderId: string) {
            const purchaseOrderDocumentsData = await client.purchaseOrders().documents().fetchPurchaseOrderDocuments(purchaseOrderId);
            dispatch.purchaseOrders.setPurchaseOrderDocuments(purchaseOrderId, purchaseOrderDocumentsData);
        },
        async fetchPurchaseOrderItemsById(purchaseOrderId: string) {
            const purchaseOrderItemsData = await client.purchaseOrders().fetchPurchaseOrderItems(purchaseOrderId);
            dispatch.purchaseOrders.setPurchaseOrderItems(purchaseOrderId, purchaseOrderItemsData);
        },
        async fetchPurchaseOrderReferencesById(purchaseOrderId: string) {
            const purchaseOrderReferencesData = await client.purchaseOrders().fetchPurchaseOrderReferences(purchaseOrderId);
            dispatch.purchaseOrders.setPurchaseOrderReferences(purchaseOrderId, purchaseOrderReferencesData);
        },
        async fetchPurchaseOrderDiscussionsById(purchaseOrderId: string) {
            const purchaseOrderDiscussionsData = await client.purchaseOrders().discussions().fetchPurchaseOrderDiscussions(purchaseOrderId);
            dispatch.purchaseOrders.setPurchaseOrderDiscussions(purchaseOrderId, purchaseOrderDiscussionsData);
        },
        async fetchPurchaseOrdersByCZNumber(czNumber: string) {
            const purchaseOrdersData = await client.purchaseOrders().fetchByCZNumber(czNumber);
            return purchaseOrdersData;
        },
        async createPurchaseOrder({ purchaseOrder }: createPurchaseOrderPayload) {
            const createdPurchaseOrderId = await client.purchaseOrders().createPurchaseOrder(purchaseOrder);
            return createdPurchaseOrderId;
        },
        async updatePurchaseOrder({ id, purchaseOrder }: updatePurchaseOrderPayload) {
            await client.purchaseOrders().updatePurchaseOrder(id, purchaseOrder);
            dispatch.purchaseOrders.editPurchaseOrder(purchaseOrder);
            return true;
        },
        async createPurchaseOrderItems({ purchaseOrderId, items }: createMultiplePurchaseOrderItemsPayload) {
            const createdItems = await client.purchaseOrders().items().createMultiplePurchaseOrderItems(purchaseOrderId, items);
            dispatch.purchaseOrders.addMultiplePurchaseOrderItems(purchaseOrderId, createdItems);
            dispatch.purchaseOrders.fetchPurchaseOrderHistoryById(purchaseOrderId);
        },
        async updatePurchaseOrderItem(item: purchaseOrderItem) {
            dispatch.purchaseOrders.setPurchaseOrderItem(item);
            await client.purchaseOrders().items().updatePurchaseOrderItem(item);
            dispatch.purchaseOrders.fetchPurchaseOrderHistoryById(item.purchaseOrderId);
        },
        async updatePurchaseOrderItemsValues(payload: updatePurchaseOrderItemsValuesPayload) {
            await client.purchaseOrders().items().updatePurchaseOrderItemsValues(payload);
            dispatch.purchaseOrders.setPurchaseOrderItemsReadyDate(payload);
        },
        async deletePurchaseOrderItem({ purchaseOrderId, itemId }: { purchaseOrderId: string; itemId: string }) {
            dispatch.purchaseOrders.removePurchaseOrderItem(purchaseOrderId, itemId);
            await client.purchaseOrders().items().deletePurchaseOrderItem(purchaseOrderId, itemId);
        },
        async createPurchaseOrderEvent({ purchaseOrderNumber, event }: createPurchaseOrderEventPayload) {
            const createdEvent = await client.purchaseOrders().events().createPurchaseOrderEvent(purchaseOrderNumber, event);
            dispatch.purchaseOrders.addPurchaseOrderEvent(purchaseOrderNumber, createdEvent);
            dispatch.purchaseOrders.fetchPurchaseOrderHistoryById(purchaseOrderNumber);
        },
        async updatePurchaseOrderEvent({ purchaseOrderNumber, event }: createPurchaseOrderEventPayload) {
            await client.purchaseOrders().events().updatePurchaseOrderEvent(purchaseOrderNumber, event);
            dispatch.purchaseOrders.editPurchaseOrderEvent(purchaseOrderNumber, event);
            dispatch.purchaseOrders.fetchPurchaseOrderHistoryById(purchaseOrderNumber);
        },
        async deletePurchaseOrderEvent({ purchaseOrderNumber, eventId }: { purchaseOrderNumber: string; eventId: number }) {
            await client.purchaseOrders().events().deletePurchaseOrderEvent(purchaseOrderNumber, eventId);
            dispatch.purchaseOrders.removePurchaseOrderEvent(purchaseOrderNumber, eventId);
        },
        async createMultiplePurchaseOrderDocument({ purchaseOrderNumber, documents }: { purchaseOrderNumber: string; documents: Array<document> }) {
            dispatch.loadingNotify.setLoadingState({ visible: true, text: 'Uploading files...' });
            const createdDocuments = await client.purchaseOrders().documents().createMultiplePurchaseOrderDocument(purchaseOrderNumber, documents);
            createdDocuments.forEach((document: document) => {
                dispatch.purchaseOrders.addPurchaseOrderDocument(purchaseOrderNumber, document);
            });
            dispatch.loadingNotify.setLoadingState({ visible: false });
        },
        async createPurchaseOrderDocument({ purchaseOrderNumber, document }: { purchaseOrderNumber: string; document: document }) {
            const createdDocument = await client.purchaseOrders().documents().createPurchaseOrderDocument(purchaseOrderNumber, document);
            dispatch.purchaseOrders.addPurchaseOrderDocument(purchaseOrderNumber, createdDocument);
        },
        async updatePurchaseOrderDocument({ purchaseOrderNumber, document }: { purchaseOrderNumber: string; document: document }) {
            await client.purchaseOrders().documents().updatePurchaseOrderDocument(purchaseOrderNumber, document);
            dispatch.purchaseOrders.editPurchaseOrderDocument(purchaseOrderNumber, document);
        },
        async deletePurchaseOrderDocument({ purchaseOrderNumber, documentId }: { purchaseOrderNumber: string; documentId: number }) {
            await client.purchaseOrders().documents().deletePurchaseOrderDocument(purchaseOrderNumber, documentId);
            dispatch.purchaseOrders.removePurchaseOrderDocument(purchaseOrderNumber, documentId);
        },
        async createMultiplePurchaseOrderDocumentSupplierUpdates({
            purchaseOrderId,
            supplierName,
            documents
        }: {
            purchaseOrderId: string;
            supplierName: string;
            documents: Array<document>;
        }) {
            dispatch.loadingNotify.setLoadingState({ visible: true, text: 'Uploading files...' });
            const createdDocuments = await client
                .purchaseOrders()
                .supplierUpdatesDocuments()
                .createMultiplePurchaseOrderDocument(supplierName, documents);
            createdDocuments.forEach((document: document) => {
                dispatch.purchaseOrders.addPurchaseOrderDocument(purchaseOrderId, document);
            });
            dispatch.loadingNotify.setLoadingState({ visible: false });
        },
        async createPurchaseOrderDocumentSupplierUpdates({
            purchaseOrderId,
            supplierName,
            document
        }: {
            purchaseOrderId: string;
            supplierName: string;
            document: document;
        }) {
            const createdDocument = await client.purchaseOrders().supplierUpdatesDocuments().createPurchaseOrderDocument(supplierName, document);
            dispatch.purchaseOrders.addPurchaseOrderDocument(purchaseOrderId, createdDocument);
        },
        async updatePurchaseOrderDocumentSupplierUpdates({
            purchaseOrderId,
            supplierName,
            document
        }: {
            purchaseOrderId: string;
            supplierName: string;
            document: document;
        }) {
            await client.purchaseOrders().supplierUpdatesDocuments().updatePurchaseOrderDocument(supplierName, document);
            dispatch.purchaseOrders.editPurchaseOrderDocument(purchaseOrderId, document);
        },
        async deletePurchaseOrderDocumentSupplierUpdates({ purchaseOrderId, documentId }: { purchaseOrderId: string; documentId: number }) {
            await client.purchaseOrders().supplierUpdatesDocuments().deletePurchaseOrderDocument(purchaseOrderId, documentId);
            dispatch.purchaseOrders.removePurchaseOrderDocument(purchaseOrderId, documentId);
        },
        async createPurchaseOrderParty({ purchaseOrderNumber, party }: { purchaseOrderNumber: string; party: party }) {
            const createdParty = await client.purchaseOrders().parties().createPurchaseOrderParty(purchaseOrderNumber, party);
            dispatch.purchaseOrders.addPurchaseOrderParty(purchaseOrderNumber, createdParty);
        },
        async updatePurchaseOrderParty({ purchaseOrderNumber, party }: { purchaseOrderNumber: string; party: party }) {
            await client.purchaseOrders().parties().updatePurchaseOrderParty(purchaseOrderNumber, party);
            dispatch.purchaseOrders.editPurchaseOrderParty(purchaseOrderNumber, party);
        },
        async updatePurchaseOrderOverview({ id, poOverview }: { id: string; poOverview: poOverview }) {
            dispatch.purchaseOrders.setPurchaseOrderOverviewData(id, poOverview);
            const poData = await client.purchaseOrders().updatePurchaseOrderOverview(id, poOverview);
            dispatch.purchaseOrders.setSinglePurchaseOrder(poData);
            dispatch.purchaseOrders.fetchPurchaseOrderHistoryById(id);
            return true;
        },
        async updatePurchaseOrderPaymentDates({ id, paymentDates }: { id: string; paymentDates: Array<paymentDate> }) {
            dispatch.purchaseOrders.setPurchaseOrderPaymentDates(id, paymentDates);
            await client.purchaseOrders().updatePurchaseOrderOverview(id, { paymentDates });
            dispatch.purchaseOrders.fetchPurchaseOrderHistoryById(id);
            return true;
        },
        async deletePurchaseOrderParty({ purchaseOrderNumber, partyId }: { purchaseOrderNumber: string; partyId: number }) {
            await client.purchaseOrders().parties().deletePurchaseOrderParty(purchaseOrderNumber, partyId);
            dispatch.purchaseOrders.removePurchaseOrderParty(purchaseOrderNumber, partyId);
        },
        async createPurchaseOrderReference({ purchaseOrderNumber, reference }: { purchaseOrderNumber: string; reference: purchaseOrderReference }) {
            const createdReference = await client.purchaseOrders().references().createPurchaseOrderReference(purchaseOrderNumber, reference);
            dispatch.purchaseOrders.addPurchaseOrderReference(purchaseOrderNumber, createdReference);
            dispatch.purchaseOrders.fetchPurchaseOrderHistoryById(purchaseOrderNumber);
        },
        async updatePurchaseOrderReference({ purchaseOrderNumber, reference }: { purchaseOrderNumber: string; reference: purchaseOrderReference }) {
            await client.purchaseOrders().references().updatePurchaseOrderReference(purchaseOrderNumber, reference);
            dispatch.purchaseOrders.editPurchaseOrderReference(purchaseOrderNumber, reference);
            dispatch.purchaseOrders.fetchPurchaseOrderHistoryById(purchaseOrderNumber);
        },
        async deletePurchaseOrderReference({ purchaseOrderNumber, referenceId }: { purchaseOrderNumber: string; referenceId: number }) {
            await client.purchaseOrders().references().deletePurchaseOrderReference(purchaseOrderNumber, referenceId);
            dispatch.purchaseOrders.removePurchaseOrderReference(purchaseOrderNumber, referenceId);
        },
        async createPurchaseOrderDiscussion({
            purchaseOrderNumber,
            discussion,
            poCzNumber
        }: {
            purchaseOrderNumber: string;
            discussion: discussion;
            poCzNumber?: string;
        }) {
            const createdDiscussion = await client
                .purchaseOrders()
                .discussions()
                .createPurchaseOrderDiscussion(purchaseOrderNumber, discussion, poCzNumber);
            dispatch.purchaseOrders.addPurchaseOrderDiscussion(purchaseOrderNumber, createdDiscussion);
        },
        async updatePurchaseOrderDiscussion({
            purchaseOrderNumber,
            discussion,
            poCzNumber
        }: {
            purchaseOrderNumber: string;
            discussion: discussion;
            poCzNumber?: string;
        }) {
            dispatch.purchaseOrders.editPurchaseOrderDiscussion(purchaseOrderNumber, discussion, poCzNumber);
            await client.purchaseOrders().discussions().updatePurchaseOrderDiscussion(purchaseOrderNumber, discussion);
        },
        async deletePurchaseOrderDiscussion({ purchaseOrderNumber, discussionId }: { purchaseOrderNumber: string; discussionId: number }) {
            await client.purchaseOrders().discussions().deletePurchaseOrderDiscussion(purchaseOrderNumber, discussionId);
            dispatch.purchaseOrders.removePurchaseOrderDiscussion(purchaseOrderNumber, discussionId);
        },
        async updatePurchaseOrderState({ purchaseOrderId, purchaseOrderState }: { purchaseOrderId: string; purchaseOrderState: string }) {
            dispatch.purchaseOrders.setPurchaseOrderState(purchaseOrderId, purchaseOrderState);
            await client.purchaseOrders().updatePurchaseOrderState(purchaseOrderId, purchaseOrderState);
            dispatch.purchaseOrders.fetchPurchaseOrderHistoryById(purchaseOrderId);
        },
        async inviteUsersToFollow({ users, purchaseOrderId, purchaseOrderNumber, message }: inviteUsersToFollowPayload) {
            await client.purchaseOrders().inviteUsersToFollow(users, purchaseOrderId, purchaseOrderNumber, message);
            return true;
        },
        async updatePaymentDatesByPaymentTermPrecentage(purchaseOrderId: string) {
            const newPaymentDates = await client.purchaseOrders().updatePaymentDatesByPaymentTermPrecentage(purchaseOrderId);
            dispatch.purchaseOrders.setPurchaseOrderPaymentDates(purchaseOrderId, newPaymentDates);
        },
        async sendSupplierUpdatesEmail(sendSupplierUpdatesEmailPayload: sendSupplierUpdatesEmailPayload) {
            await client.purchaseOrders().sendSupplierUpdatesEmail(sendSupplierUpdatesEmailPayload);
        },
        //#region po supplier updates
        async getPoSupplierUpdates(token: string) {
            const po = await client.purchaseOrders().getPoSupplierUpdates(token);
            dispatch.singlePurchaseOrder.setCurrentPurchaseOrderId(po.id);
            dispatch.purchaseOrders.setSinglePurchaseOrder(po);
            return po;
        },
        async supplierUpdatesConfirmPo(purchaseOrderId: string) {
            dispatch.purchaseOrders.setPurchaseOrderState(purchaseOrderId, 'CONFIRM');
            await client.purchaseOrders().supplierUpdatesConfirmPo();
        },
        async supplierUpdatesUpdatePo(payload: supplierUpdatesUpdatePoPayload) {
            const purchaseOrder = await client.purchaseOrders().supplierUpdatesUpdatePo(payload);
            dispatch.purchaseOrders.editPurchaseOrder(purchaseOrder);
        },
        async supplierUpdatesUpdatePoItemsReadyDate(payload: supplierUpdatesUpdatePoItemsReadyDatePayload) {
            const { purchaseOrderId, readyDate, itemsIds } = payload;
            dispatch.purchaseOrders.setPurchaseOrderItemsReadyDate({ purchaseOrderId, values: { readyDate }, ids: itemsIds });

            await client.purchaseOrders().supplierUpdatesUpdatePoItemsReadyDate(payload);
        },
        async getPoSupplierUpdatesFileUrl(fileId: string) {
            return await client.purchaseOrders().getFileUrl(fileId);
        }
        //#endregion
    })
};
