import React from 'react';
import { connect } from 'react-redux';
import { document } from '../../../../../models/entities/common_subentities/document';
import { order } from '../../../../../models/entities/order/order';
import { localizationSelectors } from '../../../../../state/ducks/localization/selectors';
import { singleOrderSelectors } from '../../../../../state/ducks/singleOrder/selectors';
import { userSelectors } from '../../../../../state/ducks/user/selectors';
import { RootState } from '../../../../../state/store/store';
import MultipleDocumentFormConnected from '../../../commonSubEntities/documents/DocumentForm/MultipleDocumentFormConnected';
import { formMode } from '../../../commonSubEntities/references/RefFormConnected';

type Props = {
    order?: order;
    mode: formMode;
    localizations?: any;
    openedFromModal?: {
        setVisible: React.Dispatch<React.SetStateAction<boolean>>;
    };
    confirmation?: { setConfirm: React.Dispatch<React.SetStateAction<boolean>> };
    createMultipleOrderDocuments: (orderId: string, documents: Array<document>) => void;
    showLoader?: boolean;
};

const OrderMultipleDocumentFormConnected = ({
    order,
    localizations,
    mode,
    openedFromModal,
    confirmation,
    createMultipleOrderDocuments,
    showLoader
}: Props) => {
    return (
        <MultipleDocumentFormConnected<order>
            entity={order}
            mode={mode}
            localizations={localizations}
            openedFromModal={openedFromModal}
            confirmation={confirmation}
            createMultipleDocument={createMultipleOrderDocuments}
            showLoader={showLoader}
            typeMode="tags"
        />
    );
};

const mapStateToProps = (state: RootState) => ({
    localizations: localizationSelectors.ordersPage(state),
    order: singleOrderSelectors.order(state),
    showLoader: state.loading.effects.orders.createMultipleOrderDocument,
    userInfo: userSelectors.userInfo(state)
});

const mapDispatchToProps = (dispatch: any) => ({
    createMultipleOrderDocuments: (orderId: string, documents: Array<document>) => dispatch.orders.createMultipleOrderDocument({ orderId, documents })
});

export default connect(mapStateToProps, mapDispatchToProps)(OrderMultipleDocumentFormConnected);
