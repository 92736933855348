import { RootState } from '../../../state/store/store';
import { createSelector } from 'reselect';
import LocalizedStrings from 'localized-strings';

const currentLanguage = (state: RootState) => state.localization.currentLanguage;
const localization = (state: RootState) => state.localization;

const allLocalization = createSelector(localization, currentLanguage, (localization, currentLanguage) => {
    const texts = localization.texts;
    currentLanguage = localization.currentLanguage;
    const localsObject: any = new LocalizedStrings(texts);
    localsObject.setLanguage(currentLanguage.symbol);
    return localsObject;
});

const routes = createSelector(allLocalization, (allLocalization) => {
    return { ...allLocalization.routes, ...allLocalization.common };
});

const allShipmentsPage = createSelector(allLocalization, (allLocalization) => {
    return { ...allLocalization.shipments, ...allLocalization.common };
});

const singleShipmentPage = createSelector(allLocalization, (allLocalization) => {
    return { ...allLocalization.shipment, ...allLocalization.common };
});
const shareShipmentModal = createSelector(allLocalization, (allLocalization) => {
    return { ...allLocalization.share_shipment_modal, ...allLocalization.common };
});
const inviteToFollowModal = createSelector(allLocalization, (allLocalization) => {
    return { ...allLocalization.inviteToFollowModal, ...allLocalization.common };
});

const calenderPage = createSelector(allLocalization, (allLocalization) => {
    return { ...allLocalization.calendar, ...allLocalization.common };
});

const loginPage = createSelector(allLocalization, (allLocalization) => {
    return { ...allLocalization.login };
});

const getStartedPage = createSelector(allLocalization, (allLocalization) => {
    return { ...allLocalization.get_started };
});

const dashboardPage = createSelector(allLocalization, (allLocalization) => {
    return { ...allLocalization.dashboard };
});

const containersPage = createSelector(allLocalization, (allLocalization) => {
    return { ...allLocalization.containers };
});

const usersMgmtPage = createSelector(allLocalization, (allLocalization) => {
    return { ...allLocalization.admin.users_mgmt, ...allLocalization.common };
});
const deletedRecordsPage = createSelector(allLocalization, (allLocalization) => {
    return { ...allLocalization.admin.deleted_records, ...allLocalization.common };
});

const companiesMgmtPage = createSelector(allLocalization, (allLocalization) => {
    return { ...allLocalization.admin.companies_mgmt };
});

const companiesRelationsPage = createSelector(allLocalization, (allLocalization) => {
    return { ...allLocalization.admin.companies_relations };
});

const singleCompanyPage = createSelector(allLocalization, (allLocalization) => {
    return { ...allLocalization.admin?.company, ...allLocalization.common };
});

const tasksStrip = createSelector(allLocalization, (allLocalization) => {
    return { ...allLocalization.tasksStrip };
});

const sidebar = createSelector(allLocalization, (allLocalization) => {
    return { ...allLocalization.sidebar, ...allLocalization.common };
});
const user = createSelector(allLocalization, (allLocalization) => {
    return { ...allLocalization.user, ...allLocalization.common };
});
const notifications = createSelector(allLocalization, (allLocalization) => {
    return { ...allLocalization.notifications, ...allLocalization.common };
});

const allBusinessPartnersPage = createSelector(allLocalization, (allLocalization) => {
    return { ...allLocalization.companies, ...allLocalization.common };
});
const SingleBusinessPartnerPage = createSelector(allLocalization, (allLocalization) => {
    return { ...allLocalization.company, ...allLocalization.common };
});

const followingShipments = createSelector(allLocalization, (allLocalization) => {
    return { ...allLocalization.followingShipments, ...allLocalization.common };
});
const followingPurchaseOrders = createSelector(allLocalization, (allLocalization) => {
    return { ...allLocalization.followingPurchaseOrders, ...allLocalization.common };
});

const allPurchaseOrdersPage = createSelector(allLocalization, (allLocalization) => {
    return { ...allLocalization.purchaseOrders, ...allLocalization.common };
});

const singlePurchaseOrderPage = createSelector(allLocalization, (allLocalization) => {
    return { ...allLocalization.purchaseOrder, ...allLocalization.common };
});

const allPurchaseDemandsPage = createSelector(allLocalization, (allLocalization) => {
    return { ...allLocalization.purchaseDemands, ...allLocalization.common };
});

const singlePurchaseDemandPage = createSelector(allLocalization, (allLocalization) => {
    return { ...allLocalization.purchaseDemand, ...allLocalization.common };
});
const bid = createSelector(allLocalization, (allLocalization) => {
    return { ...allLocalization.bid, ...allLocalization.common };
});
const quotation = createSelector(allLocalization, (allLocalization) => {
    return { ...allLocalization.quotation, ...allLocalization.common };
});
const chat = createSelector(allLocalization, (allLocalization) => {
    return { ...allLocalization.chat, ...allLocalization.common };
});
const files = createSelector(allLocalization, (allLocalization) => {
    return { ...allLocalization.files, ...allLocalization.common };
});
const common = createSelector(allLocalization, (allLocalization) => {
    return { ...allLocalization.common };
});
const modal = createSelector(allLocalization, (allLocalization) => {
    return { ...allLocalization.modal, ...allLocalization.common };
});
const categories = createSelector(allLocalization, (allLocalization) => {
    return { ...allLocalization.categories, ...allLocalization.common };
});
const products = createSelector(allLocalization, (allLocalization) => {
    return { ...allLocalization.products, ...allLocalization.common };
});
const inventory = createSelector(allLocalization, (allLocalization) => {
    return { ...allLocalization.inventory, ...allLocalization.common };
});
const internalFlowInventory = createSelector(allLocalization, (allLocalization) => {
    return { ...allLocalization.internalFlowInventory, ...allLocalization.common };
});
const orderConfirmationPage = createSelector(allLocalization, (allLocalization) => {
    return { ...allLocalization.order_confirmation, ...allLocalization.common };
});
const ordersPage = createSelector(allLocalization, (allLocalization) => {
    return { ...allLocalization.orders, ...allLocalization.common };
});
const tariffsPage = createSelector(allLocalization, (allLocalization) => {
    return { ...allLocalization.tariffs, ...allLocalization.common };
});
const singleTariff = createSelector(allLocalization, (allLocalization) => {
    return { ...allLocalization.tariff, ...allLocalization.common };
});

export { default as localizationSelectors } from './selectors';

export default {
    currentLanguage,
    localization,
    routes,
    allShipmentsPage,
    singleShipmentPage,
    shareShipmentModal,
    inviteToFollowModal,
    calenderPage,
    loginPage,
    getStartedPage,
    dashboardPage,
    tasksStrip,
    sidebar,
    user,
    notifications,
    allBusinessPartnersPage,
    SingleBusinessPartnerPage,
    followingShipments,
    followingPurchaseOrders,
    allPurchaseOrdersPage,
    singlePurchaseOrderPage,
    allPurchaseDemandsPage,
    singlePurchaseDemandPage,
    bid,
    quotation,
    chat,
    files,
    common,
    modal,
    containersPage,
    usersMgmtPage,
    companiesRelationsPage,
    companiesMgmtPage,
    singleCompanyPage,
    products,
    categories,
    deletedRecordsPage,
    inventory,
    internalFlowInventory,
    orderConfirmationPage,
    ordersPage,
    tariffsPage,
    singleTariff
};
