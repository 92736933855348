import React from 'react';
import styled from 'styled-components';
import lowIcon from '../../../../static/icons/priority/low.svg';
import mediumIcon from '../../../../static/icons/priority/medium.svg';
import highIcon from '../../../../static/icons/priority/high.svg';

const renderHighIcon = () => {
    return (
        <IconWrapper>
            <img src={highIcon} alt="High" />
        </IconWrapper>
    );
};
const renderMediumIcon = () => {
    return (
        <IconWrapper>
            <img src={mediumIcon} alt="Medium" />
        </IconWrapper>
    );
};
const renderLowIcon = () => {
    return (
        <IconWrapper>
            <img src={lowIcon} alt="Low" />
        </IconWrapper>
    );
};

type CircleProps = {
    bgColor: string;
};

const IconWrapper = styled.div`
    & img {
        height: 14px;
        margin: 0px 7px 1px 0px;
    }
`;

export default {
    renderHighIcon,
    renderMediumIcon,
    renderLowIcon
};
