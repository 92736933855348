import React, { useState } from 'react';
import { connect } from 'react-redux';
import { businessPartner } from '../../../models/entities/businessPartner/businessPartner';
import { allowedCompany } from '../../../models/entities/user';
import { businessPartnersSelectors } from '../../../state/ducks/businessPartner/selectors';
import { userSelectors } from '../../../state/ducks/user/selectors';
import { RootState } from '../../../state/store/store';
import { partiesToDropdownOptions } from '../../pages/businessPartners/components/BusinessPartnerForm/BusinessPartnerFormConnected';
import { DropDown } from '../inputs/base';
import CustomModal, { ModalTriggerProps } from '../Modal/Modal';
import { filterBusinessPartners } from '../../pages/singleShipment/ShipmentForm/sections/utils';
import Button from '../SmallComponents/Button';
import { localizationSelectors } from '../../../state/ducks/localization/selectors';

type Props = {
    allowedCompanies: allowedCompany[];
    businessPartnerForwarders?: Array<businessPartner>;
    companyId?: string;
    onSubmit: (...args: any) => void;
    submitArgs: Array<any>;
    closeModal: () => void;
    localization: any;
};
export type stateProps =
    | {
          companyId?: string;
          args: Array<any>;
          func: (...args: any) => void;
      }
    | undefined;

const ChooseForwarderModal = ({ companyId, businessPartnerForwarders, allowedCompanies, submitArgs, onSubmit, closeModal, localization }: Props) => {
    const [forwarderId, setForwarder] = useState('');
    return (
        <CustomModal
            title={{ text: localization.title }}
            Trigger={(props: ModalTriggerProps) => <>{props.onClick()}</>}
            Content={(setVisible?: React.Dispatch<React.SetStateAction<boolean>>) => {
                return (
                    <div style={{ display: 'flex', width: '50%', margin: 'auto', alignItems: 'center' }}>
                        <DropDown
                            placeHolder={localization.forwarder_placeholder}
                            options={partiesToDropdownOptions(filterBusinessPartners(businessPartnerForwarders, companyId, allowedCompanies))}
                            showSearch
                            value={forwarderId}
                            onChange={(value: string) => setForwarder(value)}
                        />
                        <Button
                            buttonType="filled"
                            onClick={() => {
                                onSubmit(...[...submitArgs, forwarderId]);
                                closeModal();
                            }}
                            margin="0 0 0 10px"
                            width="70px"
                        >
                            {localization.set_btn}
                        </Button>
                    </div>
                );
            }}
        />
    );
};
const mapStateToProps = (state: RootState) => ({
    businessPartnerForwarders: businessPartnersSelectors.businessPartnerForwarders(state),
    allowedCompanies: userSelectors.allowedCompanies(state),
    localization: localizationSelectors.singleShipmentPage(state).forwarder_modal
});

const mapDispatchToProps = (dispatch: any) => ({});

export default connect(mapStateToProps, mapDispatchToProps)(ChooseForwarderModal);
