import React from 'react';
import { GroupedOptions, Option } from '../base/DropDown';
import { FormDropDown } from '.';
import { styled } from '../../Theme/theme';
import FormNumberInput from './NumberInput';
import { css } from 'styled-components';

export type NumberProps = {
    numberInputName: string;
    numberInputWidth?: string;
    numberPlaceholder?: string;
    min?: number;
    step?: number;
    numberFormatter?: (value: any) => string;
    disabled?: boolean;
};

export type UnitProps = {
    unitInputName: string;
    unitOptions: Array<Option> | GroupedOptions;
    unitInputWidth?: string;
    unitPlaceholder?: string;
    onUnitChange?: (value: any) => void;
};

const locale = 'en-us';

export const defaultCurrencyFormatter = (value: any) => {
    let newVal = value.toString();
    if (newVal[newVal.length - 1] === '.' && newVal.slice(0, -1).includes('.')) newVal = newVal.slice(0, -1);
    const decinmalDigits = newVal.split('.')[1] ? `.${newVal.split('.')[1]}` : newVal[newVal.length - 1] === '.' ? '.' : '';

    const formattedNum = new Intl.NumberFormat(locale).format(newVal);
    return Number(newVal) ? (formattedNum.split('.')[1] ? formattedNum : formattedNum + decinmalDigits) : newVal;
    // return value;
};

export const numberParser = (val: any) => {
    try {
        // detecting and parsing between comma and dot
        const group = new Intl.NumberFormat(locale).format(1111).replace(/1/g, '');
        const decimal = new Intl.NumberFormat(locale).format(1.1).replace(/1/g, '');
        let reversedVal = val.replace(new RegExp('\\' + group, 'g'), '');
        reversedVal = reversedVal.replace(new RegExp('\\' + decimal, 'g'), '.');
        //  => 1232.21 €

        // removing everything except the digits and dot
        reversedVal = reversedVal.replace(/[^0-9.]/g, '');
        //  => 1232.21

        return reversedVal;
    } catch (error) {
        console.error(error);
    }
};

type Props = NumberProps & UnitProps;

const NumberUnitInput = ({
    numberInputName,
    unitInputName,
    unitOptions,
    numberInputWidth,
    unitInputWidth,
    numberPlaceholder,
    unitPlaceholder,
    min,
    step,
    numberFormatter,
    onUnitChange,
    disabled
}: Props) => {
    return (
        <Container>
            <FormNumberInput
                name={numberInputName}
                placeHolder={numberPlaceholder}
                style={{ width: numberInputWidth, borderTopRightRadius: 0, borderBottomRightRadius: 0, borderRight: 0 }}
                min={min}
                step={1}
                formatter={numberFormatter || defaultCurrencyFormatter}
                parser={numberParser}
                width={numberInputWidth}
                disabled={disabled}
            />
            <FormDropDown
                name={unitInputName}
                placeHolder={unitPlaceholder}
                width={unitInputWidth ?? '70px'}
                options={unitOptions}
                selectorStyle={css`
                    border-left: 0;
                    border-top-left-radius: 0 !important;
                    border-bottom-left-radius: 0 !important;
                    text-align: center;
                `}
                onChange={(value: any) => onUnitChange && onUnitChange(value)}
                showSearch={false}
                disabled={disabled}
            />
        </Container>
    );
};
NumberUnitInput.defaultProps = {
    numberInputWidth: '130px'
};
interface ContainerProps {
    width?: string;
}

const Container = styled.div<ContainerProps>`
    width: ${(props) => props.width};
    display: flex;
`;

export default NumberUnitInput;
