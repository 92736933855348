import React from 'react';
import edit from '../../../../static/icons/edit.svg';
import trash from '../../../../static/icons/trash.svg';
import CellTrigger from '../../Menu/MenuTriggers/CellTrigger';
import { RootState } from '../../../../state/store/store';
import { connect } from 'react-redux';
import Menu from '../../Menu/Menu';
import MenuItem from '../../Menu/MenuItem';
type onRowMoreOptionClickType = () => void;
type deleteProductAttributeType = (productId: string, attributeId: string) => void;
type Props = {
    entityId: string;
    localization: any;
    onRowMoreOptionsClick?: onRowMoreOptionClickType;
    deleteProductAttribute: deleteProductAttributeType;
    parentEntityId?: string;
};

const ProductAttributeMenu = ({ entityId, localization, onRowMoreOptionsClick, deleteProductAttribute, parentEntityId }: Props) => {
    const menuItems = [
        <MenuItem onClick={() => onRowMoreOptionsClick && onRowMoreOptionsClick()} icon={edit} text={localization.edit} />,
        <MenuItem onClick={() => parentEntityId && deleteProductAttribute(parentEntityId, entityId)} icon={trash} text={localization.delete} />
    ];

    return <Menu options={menuItems} MenuTrigger={CellTrigger} strStyle="display: flex;align-items: center;width: 100%;height: 100%;" />;
};

const mapStateToProps = (state: RootState) => ({});

const mapDispatchToProps = (dispatch: any) => ({
    deleteProductAttribute: (productId: string, attributeId: string) => dispatch.products.deleteProductAttribute({ productId, attributeId })
});

export default connect(mapStateToProps, mapDispatchToProps)(ProductAttributeMenu);
