import BaseController from '../..';
import { document } from '../../../../models/entities/common_subentities/document';
import IProductsDocuments from './IProductsDocuments';

export default class ProductsDocuments extends BaseController implements IProductsDocuments {
    constructor(baseUrl: string) {
        super(baseUrl);
    }
    async createMultipleProductDocument(productId: string, data: Array<document>) {
        this.client.setBodyType('FORM-DATA');
        const response = await this.client.post(`/product/documents/upload-multiple`, this.convertToArrayFormData(data, productId));
        return response;
    }
    async createProductDocument(productId: string, data: document) {
        this.client.setBodyType('FORM-DATA');
        const response = await this.client.post(`/product/documents/upload`, this.convertToFormData(data, productId));
        return response;
    }
    async updateProductDocument(productId: string, data: document) {
        this.client.setBodyType('FORM-DATA');
        const response = await this.client.put(`/product/documents`, this.convertToFormData(data, productId));
        return response;
    }
    async deleteProductDocument(productId: string, documentId: number) {
        const response = await this.client.delete(`/product/documents`, JSON.stringify({ id: documentId, productId }));
        return response;
    }
    private convertToFormData(document: document, productId: string): FormData {
        const formData = new FormData();
        let type = document.type;
        if (Array.isArray(type)) type = JSON.stringify(type);
        formData.append('productId', productId.toString());
        formData.append('type', type);
        formData.append('comment', document.comment);
        formData.append('fileName', document.fileName);
        formData.append('fileType', document.fileType);
        if (document.expDate) formData.append('expDate', JSON.stringify(document.expDate));
        if (document.file) formData.append('file', document.file);
        if (document.id) formData.append('id', '' + document.id);
        return formData;
    }
    private convertToArrayFormData(documents: Array<document>, productId: string): FormData {
        const formData = new FormData();
        formData.append('productId', productId.toString());
        documents.forEach((document) => {
            let type = document.type;
            if (Array.isArray(type)) type = JSON.stringify(type);
            formData.append('type[]', type);
            formData.append('comment[]', document.comment);
            formData.append('fileName[]', document.fileName);
            formData.append('fileType[]', document.fileType);
            if (document.expDate) formData.append('expDate[]', JSON.stringify(document.expDate));
            if (document.file) formData.append('file[]', document.file);
            if (document.id) formData.append('id[]', '' + document.id);
        });
        return formData;
    }
}
