import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { localizationSelectors } from '../../../../../../state/ducks/localization/selectors';
import { RootState } from '../../../../../../state/store/store';
import Grid from '../../../../../shared/Grid/Grid';
import { styled } from '../../../../../shared/Theme/theme';
import { inventoryTotalView } from '../../../../../../models/entities/product/inventoryLog';
import { ColumnType } from '../../../../../shared/Grid/types/Column';
import { inventorySelectors } from '../../../../../../state/ducks/inventory/selectors';
import { drawers } from '../../../../../../state/ducks/header/header';

type Props = {
    inventory: inventoryTotalView[];
    localization: any;
    gridColumns: Array<ColumnType<inventoryTotalView>>;
    fetchGridColumns: () => void;
    showLoader: boolean;
    setDrawerOpen: (open: drawers, params?: any) => void;
};

const InventoryTab = ({ inventory, localization, gridColumns, fetchGridColumns, showLoader, setDrawerOpen }: Props) => {
    useEffect(() => {
        fetchGridColumns();
    }, [fetchGridColumns]);
    return (
        <Wrapper>
            <GridWrapper>
                <Grid<inventoryTotalView>
                    onSettingsClick={() => console.log('settings')}
                    onRowMoreOptionsClick={(row) => console.log(row)}
                    columns={gridColumns}
                    data={inventory}
                    localization={localization.grid}
                    emptyStateText={localization.grid.emptyState}
                    showLoader={showLoader}
                    idKey="id"
                    onRowClick={(row) => setDrawerOpen('PRODUCT', { productId: row.productId, tab: 'inventory' })}
                />
            </GridWrapper>
        </Wrapper>
    );
};
const Wrapper = styled.div`
    height: 100%;
    display: flex;
    flex-direction: column;
`;

const GridWrapper = styled.div`
    flex: 1;
    max-width: 100%;
    width: 100%;
    box-sizing: border-box;
    height: 100%;
    width: 100%;
    z-index: 1;
    overflow: overlay;

    @media screen and (min-width: 768px) {
        height: calc(100% - 127px);
    }

    @media screen and (max-width: 768px) {
        height: calc(100% - 135px - 40px);
    }

    @media screen and (max-height: 400px) {
        height: calc(100% - 135px);
    }
`;
const mapStateToProps = (state: RootState) => ({
    gridColumns: inventorySelectors.gridColumns(state),
    localization: localizationSelectors.inventory(state),
    showLoader: state.loading.effects.inventory.fetchGridColumns
});

const mapDispatchToProps = (dispatch: any) => ({
    fetchGridColumns: () => dispatch.inventory.fetchGridColumns(),
    setDrawerOpen: (open: drawers, params?: any) => dispatch.header.setDrawerOpenParams({ open, params })
});

export default connect(mapStateToProps, mapDispatchToProps)(InventoryTab);
