import React from 'react';
import { history } from '../../../../models/entities/common_subentities/history';
import { diff, removeIds } from '../../../../utils/json-diff';
import { styled } from '../../../shared/Theme/theme';
import lodash from 'lodash';
import UserBadge from '../../../shared/UserBadge/UserBadge';
import { css } from 'styled-components';
import { RightArrowIcon } from '../../../shared/Grid/CellRenderer/CellRenderer';

type Props = {
    item: history;
    history?: Array<history>;
    excludedFields?: string[];
};

const HistoryActiveItem = ({ item, history, excludedFields }: Props) => {
    let changes;
    let data = item.diff || item.data;
    removeIds(data, ['transportationPaid']);
    if (item.actionType === 'UPDATE' && history) changes = getItemChanges(item, history, excludedFields);
    if (changes) data = changes;
    return (
        <Item>
            <Header>
                <ImageWrapper>
                    <UserBadge width={40} userId={item.userId} userName={item.userName} hideBadgeName={true} borderColor="#f7f7f7" />
                </ImageWrapper>
                <div>
                    <b>{item.userName}</b>
                </div>
            </Header>
            {renderHistoryData(data, !!changes)}
        </Item>
    );
};

function getItemChanges(item: history, history: Array<history>, excludedFields?: string[]) {
    if (!item.diff) {
        const currIndex = history.findIndex((h) => h.id === item.id);
        for (let i = currIndex + 1; i < history.length; i++) {
            if (history[i].entityType === item.entityType) {
                if (history[i].actionType === 'UPDATE' || history[i].actionType === 'CREATE') {
                    const d = diff(item.data, history[i].data);
                    return d;
                }
            }
        }
    }

    excludedFields?.forEach((field: string) => {
        delete item.diff[field];
    });

    return item.diff;
}

// const renderHistoryRowDetail = ()
function renderHistoryData(data?: { [key: string]: any }, isUpdate?: boolean) {
    const emptyVal = (value: any) => {
        switch (typeof value) {
            case 'number':
                return '0';
            case 'boolean':
                return 'false';
            default:
                return '" "';
        }
    };
    return (
        <Content>
            {data
                ? Object.entries(data)
                      .filter(([k, v]) => v != null)
                      .map(([key, value], index) => {
                          let { old: oldValue, new: newValue } = value;

                          if (value && (typeof value !== 'object' || (!oldValue && !newValue))) {
                              const parsedValue = ['object', 'boolean'].includes(typeof value) ? JSON.stringify(value) : value;
                              return (
                                  <Row key={index}>
                                      <FieldName>{lodash.startCase(key)}</FieldName>
                                      <NewValue>{parsedValue}</NewValue>
                                  </Row>
                              );
                          }

                          if (newValue && ['object', 'boolean'].includes(typeof newValue)) newValue = JSON.stringify(newValue);

                          if (Array.isArray(oldValue)) {
                              return (
                                  <Row key={index} isMultiChanges={true}>
                                      <FieldName>{lodash.startCase(key)}</FieldName>
                                      <NewValue>{newValue || emptyVal(newValue)}</NewValue>
                                      {oldValue.map((val) => (
                                          <>
                                              <div className="material-icons" style={{ color: '#b5b5b5', fontSize: 14 }}>
                                                  chevron_left
                                              </div>
                                              <OldValue>{val}</OldValue>
                                          </>
                                      ))}
                                  </Row>
                              );
                          }

                          if (oldValue && ['object', 'boolean'].includes(typeof oldValue)) oldValue = JSON.stringify(oldValue);

                          return (
                              <Row key={index}>
                                  <FieldName>{lodash.startCase(key)}</FieldName>
                                  <NewValue>{newValue || emptyVal(newValue)}</NewValue>
                                  {isUpdate && <OldValue>{oldValue || emptyVal(oldValue)}</OldValue>}
                              </Row>
                          );
                      })
                : 'No Data'}
        </Content>
    );
}

const Item = styled.div``;

const Header = styled.div`
    display: flex;
    align-items: center;
    border-bottom: 1px solid #e8e8e8;
    padding: 3px 0;

    @media screen and (max-width: 768px) {
        display: none;
    }
`;
const ImageWrapper = styled.div`
    width: 40px;
    height: 40px;
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 7px;
`;
const Content = styled.div`
    padding: 10px;
`;

const Row = styled.div<{ isMultiChanges?: boolean }>`
    &:hover {
        background-color: #fafafa;
    }

    display: flex;
    align-items: center;
    padding: 5px 0;
    > * {
        padding: 5px;
        margin-right: 15px;
        ${(props) => {
            if (props.isMultiChanges) {
                return css`
                    margin-right: 7px;
                `;
            }
            return '';
        }}
    }
`;
const FieldName = styled.div``;
const NewValue = styled.div`
    background: rgba(223, 246, 221, 1);
`;
const OldValue = styled.div`
    background: rgba(234, 234, 234, 1);
    color: rgba(0, 0, 0, 0.55);
    text-decoration: line-through;
`;
export default HistoryActiveItem;
