import React, { PropsWithChildren, useState } from 'react';
import { Menu } from '@mui/material';
import { PopoverPosition } from '@material-ui/core';
import { nestedMenuItemsFromObject } from './utils';
import { ContextMenuItemType } from './types';
import { StyledMenu } from './components';

type Props = {
    menuItemsData: ContextMenuItemType[];
    onOpen?: () => void;
    onClose?: () => void;
};
const ContextMenu = ({ menuItemsData, children, onOpen, onClose }: PropsWithChildren<Props>) => {
    const [menuPosition, setMenuPosition] = useState<PopoverPosition | null>(null);

    const handleItemClick = () => {
        onClose?.();
        setMenuPosition(null);
    };

    const handleContextMenu = (event: React.MouseEvent) => {
        event.preventDefault();

        const top = event.clientY;
        const left = event.clientX;

        if (menuPosition === null) {
            setMenuPosition({
                top,
                left
            });
            onOpen?.();
            return;
        }

        onClose?.();
        setMenuPosition(null);
    };

    const menuItems = nestedMenuItemsFromObject({
        items: menuItemsData,
        isOpen: !!menuPosition,
        handleClose: handleItemClick
    });

    return (
        <div style={{ width: '100%', height: '100%' }} onContextMenu={handleContextMenu}>
            <StyledMenu
                onContextMenu={(e) => e.preventDefault()}
                open={!!menuPosition}
                onClose={() => {
                    onClose?.();
                    setMenuPosition(null);
                }}
                anchorReference="anchorPosition"
                anchorPosition={menuPosition || undefined}
                anchorOrigin={{
                    vertical: 'top',
                    horizontal: 'left'
                }}
                transformOrigin={{
                    vertical: 'top',
                    horizontal: 'left'
                }}
                PaperProps={{
                    elevation: 3
                }}
            >
                {menuItems}
            </StyledMenu>
            {children}
        </div>
    );
};

export default ContextMenu;
