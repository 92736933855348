import React from 'react';
import { eReminderDates } from '../../../../models/entities/shipment/reminder_dates';
import { task } from '../../../../models/entities/task';
import { getReminderValue } from '../../../../utils/date';
import { enumToDropdownOptions } from '../../../shared/inputs/base/DropDown';
import Menu, { TriggerProps } from '../../../shared/Menu/Menu';
import MenuItem from '../../../shared/Menu/MenuItem';

type Props = {
    MenuTrigger: (props: TriggerProps) => JSX.Element;
    onReminderChange: (value: string) => void;
    task: task;
};

const reminderDatesDropDownOptions = enumToDropdownOptions(eReminderDates);
const SetReminderDropDown = ({ MenuTrigger, onReminderChange, task }: Props) => {
    const menuItems = reminderDatesDropDownOptions.map((item) => (
        <MenuItem
            text={item.text}
            onClick={() => onReminderChange(item.value)}
            suffixIcon={getReminderValue(task.dueDate, task.reminder) === item.value ? 'checkmark' : undefined}
        />
    ));
    return <Menu menuWidth={165} arrowOnTrigger={false} options={menuItems} MenuTrigger={MenuTrigger} />;
};

export default SetReminderDropDown;
