import React, { useEffect, useState } from 'react';
import { businessPartner } from '../../../../../models/entities/businessPartner/businessPartner';
import { styled } from '../../../../shared/Theme/theme';
import { Form, Formik } from 'formik';
import { localizationSelectors } from '../../../../../state/ducks/localization/selectors';
import { RootState } from '../../../../../state/store/store';
import { connect } from 'react-redux';
import Button from '../../../../shared/SmallComponents/Button';
import { ModalFooter, ModalFormContainer } from '../../../../shared/Modal/Modal';
import Loader from '../../../../shared/SmallComponents/Loader';
import { address } from '../../../../../models/entities/businessPartner/address';
import { FormCheckbox, FormDropDown, FormTextArea, FormTextInput, LocationSearchInput } from '../../../../shared/inputs/form';
import FieldLabel from '../../../../shared/SmallComponents/FieldLabel';
import { singleBusinessPartnersSelectors } from '../../../../../state/ducks/singleBusinessPartner/selectors';
import countries from '../../../../../utils/countries-flags/countries';
import stations from '../../../../../static/data/stations.json';
import { renderCountryOption } from '../../../../shared/SmallComponents/CountryOption';
import { phonePattern } from '../../../../../utils/patterns';

export enum formMode {
    NEW,
    EDIT
}

interface formFields {
    name?: string;
    country?: string;
    airPort?: string;
    seaPort?: string;
    city?: string;
    address?: string;
    description?: string;
    isDefault?: boolean;
    phoneNumber?: string;
    personInCharge?: number;
}
type Props = {
    businessPartner?: businessPartner;
    address?: address;
    mode: formMode;
    localizations?: any;
    openedFromModal?: {
        setVisible: React.Dispatch<React.SetStateAction<boolean>>;
    };
    confirmation?: { setConfirm: React.Dispatch<React.SetStateAction<boolean>> };
    createBusinessPartnerAddress: (businessPartnerId: string, address: address) => void;
    updateBusinessPartnerAddress: (businessPartnerId: string, address: address) => void;
    showLoader?: boolean;
};

let AFTER_LOADING: boolean = false;
const countriesDropDownOptions = countries.map((country) => ({
    text: country.name,
    value: country.code
}));
const AddressFormConnected = ({
    businessPartner,
    address,
    localizations,
    mode,
    openedFromModal,
    confirmation,
    createBusinessPartnerAddress,
    updateBusinessPartnerAddress,
    showLoader
}: Props) => {
    const [submitLoading, setLoading] = useState(false);
    let initFormValues: formFields = {
        name: '',
        country: '',
        airPort: '',
        seaPort: '',
        city: '',
        address: '',
        description: '',
        isDefault: false,
        phoneNumber: '',
        personInCharge: undefined
    };
    const setInitialValues = () => {
        if (!address || mode === formMode.NEW) {
            return initFormValues;
        } else {
            initFormValues.name = address.name;
            initFormValues.country = address.country;
            initFormValues.airPort = address.airPort;
            initFormValues.seaPort = address.seaPort;
            initFormValues.city = address.city;
            initFormValues.address = address.address;
            initFormValues.description = address.description;
            initFormValues.isDefault = address.isDefault;
            initFormValues.phoneNumber = address.phoneNumber;
            initFormValues.personInCharge = address.personInCharge;
            return initFormValues;
        }
    };
    const validate = (values: any) => {
        if (confirmation) {
            if (JSON.stringify(values) === JSON.stringify(initFormValues)) {
                confirmation.setConfirm(false);
            } else {
                confirmation.setConfirm(true);
            }
        }

        const errors: any = {};
        if (!values.country) errors.country = localizations.addresses.form.required_error;
        if (!values.city) errors.city = localizations.addresses.form.required_error;
        //if (values.phoneNumber && !values.phoneNumber.match(phonePattern)) errors.phoneNumber = localizations.addresses.form.phone_error;

        const maxLengthValidation = (field: string, maxLength: number, message: string) => {
            if (values[field] && values[field].length > maxLength) {
                errors[field] = message;
            }
        };

        maxLengthValidation('city', 200, localizations.addresses.form.city_max_length_error);
        maxLengthValidation('address', 200, localizations.addresses.form.address_max_length_error);
        maxLengthValidation('description', 200, localizations.addresses.form.description_max_length_error);
        maxLengthValidation('name', 50, localizations.addresses.form.name_max_length_error);
        maxLengthValidation('phoneNumber', 40, localizations.addresses.form.phone_number_max_length_error);
        return errors;
    };
    const submit = (values: any) => {
        if (businessPartner) {
            const payload: address = {
                name: values.name || '',
                country: values.country,
                airPort: values.airPort,
                seaPort: values.seaPort,
                city: values.city,
                address: values.address,
                description: values.description,
                isDefault: values.isDefault,
                phoneNumber: values.phoneNumber,
                personInCharge: values.personInCharge || null
            } as address;
            if (mode === formMode.NEW) createBusinessPartnerAddress(businessPartner.id, payload);
            else if (address) {
                updateBusinessPartnerAddress(businessPartner.id, {
                    ...payload,
                    id: address.id
                });
            }
        }
    };
    useEffect(() => {
        if (showLoader) {
            setLoading(true);
            AFTER_LOADING = true;
        } else {
            setLoading(false);
            if (AFTER_LOADING && openedFromModal) {
                openedFromModal.setVisible(false);
                AFTER_LOADING = false;
            }
        }
    }, [showLoader, openedFromModal]);
    return (
        <Formik initialValues={setInitialValues()} validate={validate} onSubmit={submit}>
            {({ values }) => {
                return (
                    <Form>
                        <Container>
                            <FieldLabel>{localizations.addresses.form.name}</FieldLabel>
                            <FieldContainer>
                                <FormTextInput name="name" placeHolder={localizations.addresses.form.name} />
                            </FieldContainer>
                            <FieldLabel>{localizations.addresses.form.country}</FieldLabel>
                            <FieldContainer>
                                <FormDropDown
                                    name="country"
                                    placeHolder={localizations.addresses.form.country}
                                    options={countriesDropDownOptions}
                                    optionRenderer={renderCountryOption}
                                    showSearch
                                />
                            </FieldContainer>
                            <FieldLabel>{localizations.addresses.form.airPort}</FieldLabel>
                            <FieldContainer>
                                <FormDropDown
                                    showSearch
                                    name="airPort"
                                    disabled={!values.country || !(values.country.length > 0)}
                                    placeHolder={localizations.addresses.form.airPort}
                                    options={stations
                                        .filter((station) => station.country === values.country && station.type === 'airport')
                                        .map((station) => ({
                                            text: station.name !== '' ? station.name : station.location,
                                            value: station.code
                                        }))}
                                />
                            </FieldContainer>
                            <FieldLabel>{localizations.addresses.form.seaPort}</FieldLabel>
                            <FieldContainer>
                                <FormDropDown
                                    showSearch
                                    name="seaPort"
                                    placeHolder={localizations.addresses.form.seaPort}
                                    options={stations
                                        .filter((station) => station.type === 'seaport')
                                        .map((station) => ({
                                            text: station.name !== '' ? station.name : station.location,
                                            value: station.code
                                        }))}
                                />
                            </FieldContainer>
                            <FieldLabel>{localizations.addresses.form.city}</FieldLabel>
                            <FieldContainer>
                                <LocationSearchInput name="city" placeHolder={localizations.addresses.form.city} countryCode={values.country} />
                            </FieldContainer>
                            <FieldLabel>{localizations.addresses.form.address}</FieldLabel>
                            <FieldContainer>
                                <FormTextInput name="address" placeHolder={localizations.addresses.form.address} />
                            </FieldContainer>
                            <FieldLabel>{localizations.addresses.form.phoneNumber}</FieldLabel>
                            <FieldContainer>
                                <FormTextInput name="phoneNumber" placeHolder={localizations.addresses.form.phoneNumber} />
                            </FieldContainer>
                            <FieldLabel>{localizations.addresses.form.personInCharge}</FieldLabel>
                            <FieldContainer>
                                <FormDropDown
                                    name="personInCharge"
                                    placeHolder={localizations.addresses.form.personInCharge}
                                    options={
                                        businessPartner?.contacts?.map((item) => {
                                            return { value: '' + item.id, text: item.name };
                                        }) || []
                                    }
                                    allowClear
                                />
                            </FieldContainer>
                            <FieldLabel>{localizations.addresses.form.description}</FieldLabel>
                            <FieldContainer>
                                <FormTextArea name="description" placeHolder={localizations.addresses.form.description} />
                            </FieldContainer>
                            <FieldContainer>
                                <FormCheckbox name="isDefault">{localizations.addresses.form.isDefault}</FormCheckbox>
                            </FieldContainer>
                        </Container>
                        {openedFromModal && (
                            <FormFooter>
                                <Button minWidth="55px" buttonType="filled" type="submit">
                                    {submitLoading ? (
                                        <Loader width="20px" marginTop="0px" showText={false} />
                                    ) : (
                                        <span>
                                            {mode === formMode.NEW ? localizations.addresses.modal.add_btn : localizations.addresses.modal.edit_btn}
                                        </span>
                                    )}
                                </Button>
                            </FormFooter>
                        )}
                    </Form>
                );
            }}
        </Formik>
    );
};
const FieldContainer = styled.div`
    margin-bottom: 12px;
`;

const Container = styled(ModalFormContainer)``;

const FormFooter = styled(ModalFooter)`
    position: absolute;
    bottom: 0;
    width: 100%;
    left: 0;
    right: 0;
    background: white;
    justify-content: flex-end;
`;
const mapStateToProps = (state: RootState) => ({
    localizations: localizationSelectors.SingleBusinessPartnerPage(state),
    businessPartner: singleBusinessPartnersSelectors.businessPartner(state),
    showLoader:
        state.loading.effects.businessPartners.createBusinessPartnerAddress || state.loading.effects.businessPartners.updateBusinessPartnerAddress
});

const mapDispatchToProps = (dispatch: any) => ({
    createBusinessPartnerAddress: (businessPartnerId: string, address: address) =>
        dispatch.businessPartners.createBusinessPartnerAddress({ businessPartnerId, address }),
    updateBusinessPartnerAddress: (businessPartnerId: string, address: address) =>
        dispatch.businessPartners.updateBusinessPartnerAddress({ businessPartnerId, address })
});

export default connect(mapStateToProps, mapDispatchToProps)(AddressFormConnected);
