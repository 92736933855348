import moment from 'moment';
export function combineDateWithTime(d: Date, t: Date) {
    return new Date(d.getFullYear(), d.getMonth(), d.getDate(), t.getHours(), t.getMinutes(), t.getSeconds(), t.getMilliseconds());
}
export function getDatesRange(code: string) {
    const range = {
        from: new Date(),
        to: new Date()
    };
    switch (code) {
        case 'LAST_30_DAYS':
            range.from = moment().subtract(30, 'days').toDate();
            break;
        case 'LAST_3_MONTHS':
            range.from = moment().subtract(3, 'months').toDate();
            break;
        case 'LAST_6_MONTHS':
            range.from = moment().subtract(6, 'months').toDate();
            break;
    }
    return range;
}
export function getReminderDate(code: string, dueDate: Date) {
    switch (code) {
        case 'NONE':
            return null;
        case 'ON_TIME':
            return dueDate;
        case '1_DAY_BEFORE':
            return moment(dueDate).subtract(1, 'days').toDate();
        case '3_DAYS_BEFORE':
            return moment(dueDate).subtract(3, 'days').toDate();
        case 'WEEK_BEFORE':
            return moment(dueDate).subtract(7, 'days').toDate();
        default:
            return null;
    }
}

export function getReminderValue(dueDate: Date, reminder: Date | null) {
    switch (moment(reminder).toISOString()) {
        case null:
            return 'NONE';
        case moment(dueDate).toISOString():
            return 'ON_TIME';
        case moment(dueDate).subtract(1, 'days').toISOString():
            return '1_DAY_BEFORE';
        case moment(dueDate).subtract(3, 'days').toISOString():
            return '3_DAYS_BEFORE';
        case moment(dueDate).subtract(7, 'days').toISOString():
            return 'WEEK_BEFORE';
    }
}
