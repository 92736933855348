export const eShipmentTypes: { [key: string]: string } = {
    OE: 'Ocean Export',
    OI: 'Ocean Import',
    OEX: 'Ocean Express',
    AE: 'Air Export',
    AI: 'Air Import',
    EXPRESS: 'Express',
    DROP_OCEAN: 'Drop Ocean',
    DROP_AIR: 'Drop Air',
    CUSTOMS: 'Customs',
    INLAND: 'Inland',
    RAIL: 'Rail'
};
