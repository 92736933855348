// eslint-disable-next-line import/no-anonymous-default-export
export default {
    en: {
        partyTypes: [
            {
                code: 'FORWARDER_BROKER',
                name: 'Forwarder/Broker',
                description: 'Forwarder/Broker',
                subTypes: [
                    {
                        code: 'FORWARDER',
                        name: 'Forwarder',
                        description: 'Forwarder description'
                    },
                    {
                        code: 'BROKER',
                        name: 'Broker',
                        description: 'Broker description'
                    }
                ]
            },
            {
                code: 'CARGO_OWNER',
                name: 'Cargo Owner',
                description: 'Shipper/Consignee',
                subTypes: [
                    {
                        code: 'SHIPPER',
                        name: 'Shipper',
                        description: 'Shipper description'
                    },
                    {
                        code: 'CONSIGNEE',
                        name: 'Consignee',
                        description: 'Consignee description'
                    },
                    {
                        code: 'BILL_TO',
                        name: 'Bill to',
                        description: 'Bill to description'
                    }
                ]
            },
            {
                code: 'NOTIFY',
                name: 'Notify',
                description: 'Notify description'
            },
            {
                code: 'AGENT',
                name: 'Agent',
                description: 'Agent description'
            },
            {
                code: 'CUSTOMER',
                name: 'Customer',
                description: 'Customer description'
            },
            {
                code: 'WAREHOUSE',
                name: 'Warehouse',
                description: 'Warehouse description'
            },
            {
                code: 'TRUCKER',
                name: 'Trucker',
                description: 'Trucker description'
            },
            {
                code: 'INSURER',
                name: 'Insurer',
                description: 'Insurer description'
            },
            {
                code: 'CUSTOM_BROKER',
                name: 'Custom broker',
                description: 'Custom broker description'
            },
            {
                code: 'BANK',
                name: 'Bank',
                description: 'Bank description'
            },
            {
                code: 'CHARATER_AGENT',
                name: 'Charter agent',
                description: 'Charter agent description'
            }
        ],
        documentTypes: [
            { code: 'QUO', name: 'Quotation', description: 'Quotation description' },
            { code: 'TE', name: 'Terms', description: 'Terms description' },
            { code: 'OTHER', name: 'Other', description: 'Other file description' }
        ]
    }
};
