import React from 'react';
import { featureFlagsModel } from '../../../../../models/entities/businessPartner/settings';
import claimsType from '../../../../../models/entities/claimsType';
import { event } from '../../../../../models/entities/common_subentities/event';
import { settings } from '../../../../../models/entities/shipment/shipment';
import Events from '../../../commonSubEntities/events/Events';
import EventFormConnected from '../../EventForm/EventFormConnected';

type Props = {
    events?: Array<event>;
    localization: any;
    settings?: settings;
    printMode?: boolean;
    userShipmentEventsClaims?: claimsType;
    featureFlags?: featureFlagsModel;
    
};

function ShipmentEvents({ events, localization, settings, printMode, userShipmentEventsClaims, featureFlags }: Props) {
    return (
        <Events<settings>
            events={events}
            localization={localization}
            settings={settings}
            menuType="shipmentEventMenu"
            EventFormConnected={EventFormConnected}
            printMode={printMode}
            userShipmentEventsClaims={userShipmentEventsClaims}
            featureFlags={featureFlags}
        />
    );
}

export default ShipmentEvents;
