import React from 'react';
import { FormDropDown, FormTextArea, FormTextInput, LocationSearchInput } from '../../../../shared/inputs/form';
import { styled } from '../../../../shared/Theme/theme';
import { FieldContainer, renderFieldLabelByName } from '../ShipmentForm';
import { renderCountryOption } from '../../../../shared/SmallComponents/CountryOption';
import countries from '../../../../../static/data/countries.json';
import stations from '../../../../../static/data/stations.json';
import { businessPartner } from '../../../../../models/entities/businessPartner/businessPartner';
import { address } from '../../../../../models/entities/businessPartner/address';
import { updatePickupAddress } from './utils';
import { shipmentFormLogics } from '../../util';

const countriesDropDownOptions = countries.map((country) => ({
    text: country.name,
    value: country.code
}));

type Props = {
    values: any;
    setFieldValue: any;
    localizations: any;
    businessPartnerSuppliers?: Array<businessPartner>;
    businessPartnerConsignees?: Array<businessPartner>;
};
const Geography = ({ values, setFieldValue, businessPartnerSuppliers, businessPartnerConsignees, localizations }: Props) => {
    let shipperAddresses: address[] = [];
    let consigneeAddresses: address[] = [];
    if (values.shipperId) {
        shipperAddresses = businessPartnerSuppliers?.find((item) => item.id === values.shipperId)?.addresses || [];
    }
    if (values.consigneeId) {
        consigneeAddresses = businessPartnerConsignees?.find((item) => item.id === values.consigneeId)?.addresses || [];
    }

    const geographySectionLocalizations = localizations.form.sections.geography;
    const fieldsLocalizations = geographySectionLocalizations.fields;
    const renderFieldTitle = (name: string, isMandatory: boolean) => renderFieldLabelByName(name, isMandatory, fieldsLocalizations);
    return (
        <GeographyFormSection>
            <div style={{ paddingRight: '30px' }}>
                {shipmentFormLogics.shipper_addresses(shipperAddresses, consigneeAddresses, values.shipment_type) && (
                    <FieldContainer>
                        <div>{renderFieldTitle('address', false)}</div>
                        <FormDropDown
                            name="shipper_addresses"
                            options={shipperAddresses.map((item) => {
                                return { text: item.name || item.city + ',' + item.country, value: item.id.toString() };
                            })}
                            onChange={(value: string) => {
                                const address = shipperAddresses.find((item) => '' + item.id === value);
                                if (address) updatePickupAddress(values.shipment_type, 'origin', setFieldValue, address, values);
                            }}
                            placeHolder={fieldsLocalizations.address.placeholder}
                            disabled={shipperAddresses.length <= 1}
                        />
                    </FieldContainer>
                )}
                <FieldContainer>
                    <div>{renderFieldTitle('origin_country', true)}</div>
                    <div>
                        <FormDropDown
                            name="origin_country"
                            style={{ width: '200px' }}
                            placeHolder={fieldsLocalizations.origin_country.placeholder}
                            options={countriesDropDownOptions}
                            optionRenderer={renderCountryOption}
                            onChange={() => {
                                setFieldValue('origin', '');
                                setFieldValue('origin_zipcode', '');
                            }}
                            showSearch
                        />
                    </div>
                </FieldContainer>
                {shipmentFormLogics.origin(values.shipment_type) && (
                    <FieldContainer>
                        <div>{renderFieldTitle('origin_port', false)}</div>
                        <div>
                            <FormDropDown
                                showSearch
                                disabled={!values.origin_country || !(values.origin_country.length > 0)}
                                name="origin"
                                style={{ width: '200px' }}
                                placeHolder={fieldsLocalizations.origin_port.placeholder}
                                options={stations
                                    .filter((station) => {
                                        if (values.shipment_type === 'CUSTOMS') return station.country === values.origin_country;
                                        else
                                            return (
                                                station.country === values.origin_country &&
                                                station.type ===
                                                    (['OI', 'OEX', 'OE', 'DROP_OCEAN', 'RAIL'].includes(values.shipment_type) ? 'seaport' : 'airport')
                                            );
                                    })
                                    .map((station) => ({
                                        text: station.name !== '' ? station.name : station.location,
                                        value: station.code
                                    }))}
                            />
                        </div>
                    </FieldContainer>
                )}
                <FieldContainer>
                    <div>{renderFieldTitle('origin_zipcode', ['INLAND', 'RAIL'].includes(values.shipment_type))}</div>
                    <div>
                        <LocationSearchInput
                            name="origin_zipcode"
                            placeHolder={fieldsLocalizations.origin_zipcode.placeholder}
                            countryCode={values.origin_country}
                            width={'200px'}
                        />
                    </div>
                </FieldContainer>
                <FieldContainer>
                    <div>{renderFieldTitle('pick_up_address', false)}</div>
                    <div>
                        <FormTextArea
                            placeHolder={fieldsLocalizations.pick_up_address.placeholder}
                            name="origin_pickup_address"
                            width={'200px'}
                            height={'100px'}
                        />
                    </div>
                </FieldContainer>
            </div>
            <div style={{ borderRight: '1px solid #e0e0e0', paddingRight: '30px' }}>
                {shipmentFormLogics.consignee_addresses(consigneeAddresses, shipperAddresses, values.shipment_type) && (
                    <FieldContainer>
                        <div>{renderFieldTitle('consignee_addresses', false)}</div>
                        <FormDropDown
                            name="consignee_addresses"
                            options={consigneeAddresses.map((item) => {
                                return { text: item.name || item.city + ',' + item.country, value: item.id.toString() };
                            })}
                            onChange={(value: string) => {
                                const address = consigneeAddresses.find((item) => '' + item.id === value);
                                if (address) updatePickupAddress(values.shipment_type, 'destination', setFieldValue, address, values);
                            }}
                            placeHolder={fieldsLocalizations.consignee_addresses.placeholder}
                            disabled={consigneeAddresses.length <= 1}
                        />
                    </FieldContainer>
                )}
                <FieldContainer>
                    <div>{renderFieldTitle('destination_country', true)}</div>
                    <div>
                        <FormDropDown
                            name="destination_country"
                            style={{ width: '200px' }}
                            placeHolder={fieldsLocalizations.destination_country.placeholder}
                            options={countriesDropDownOptions}
                            optionRenderer={renderCountryOption}
                            onChange={() => {
                                setFieldValue('destination', '');
                                setFieldValue('destination_zipcode', '');
                            }}
                            showSearch
                        />
                    </div>
                </FieldContainer>

                {shipmentFormLogics.destination(values.shipment_type) && (
                    <FieldContainer>
                        <div>{renderFieldTitle('destination_port', true)}</div>
                        <div>
                            <FormDropDown
                                disabled={!values.destination_country || !(values.destination_country.length > 0)}
                                name="destination"
                                style={{ width: '200px' }}
                                placeHolder={fieldsLocalizations.destination_port.placeholder}
                                options={stations
                                    .filter((station) => {
                                        if (values.shipment_type === 'CUSTOMS') return station.country === values.destination_country;
                                        else
                                            return (
                                                station.country === values.destination_country &&
                                                station.type ===
                                                    (['OI', 'OEX', 'OE', 'DROP_OCEAN', 'RAIL'].includes(values.shipment_type) ? 'seaport' : 'airport')
                                            );
                                    })
                                    .map((station) => ({
                                        text: station.name !== '' ? station.name : station.location,
                                        value: station.code
                                    }))}
                                showSearch
                            />
                        </div>
                    </FieldContainer>
                )}
                <FieldContainer>
                    <div>{renderFieldTitle('destination_zipcode', ['INLAND', 'RAIL'].includes(values.shipment_type))}</div>
                    <div>
                        <LocationSearchInput
                            name="destination_zipcode"
                            placeHolder={fieldsLocalizations.destination_zipcode.placeholder}
                            countryCode={values.destination_country}
                            width={'200px'}
                        />
                    </div>
                </FieldContainer>
                <FieldContainer>
                    <div>{renderFieldTitle('delivery_address', false)}</div>
                    <div>
                        <FormTextArea
                            placeHolder={fieldsLocalizations.delivery_address.placeholder}
                            name="destination_pickup_address"
                            width={'200px'}
                            height={'100px'}
                        />
                    </div>
                </FieldContainer>
            </div>
            {/* <div style={{ paddingLeft: '30px' }}>
                <FieldContainer>
                    <div>{renderFieldLabel('Port Terminal', true, 'Port terminal field')}</div>
                    <div>
                        <FormTextInput name="port_terminal" placeHolder="Enter name" style={{ width: '200px' }} />
                    </div>
                </FieldContainer>
            </div> */}
        </GeographyFormSection>
    );
};

const GeographyFormSection = styled.div`
    display: flex;
    flex-direction: row;
`;

export default Geography;
