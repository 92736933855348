import React from 'react';
import styled from 'styled-components';
import { currencyFormatter } from '../../../shared/inputs/form/CurrencyInput/CurrencyInput';
import SummaryBox, { BoxesContainer } from './SummaryBox';
import critical from '../../../../static/icons/warning-red-18dp.svg';
import warning from '../../../../static/icons/warning-orange-18dp.svg';
import PieCharts from './PieCharts/PieCharts';
import { ordersStatistics } from '../../../../models/entities/order/ordersStatistics';
import { orderFilterFields } from '../../../../state/ducks/orders/gridFilters/types';
import { PagesRoutes } from '../../../../routing/PagesRoutes';
import { useHistory } from 'react-router-dom';
import OrdersAmountByMonth from './OrdersOrientedCharts/OrdersAmountByMonth';
import ExceptionsByMonth from './OrdersOrientedCharts/ExceptionsByMonth';
import { OrderStateType } from '../../../../models/entities/order';
import countries from '../../../../static/data/countries.json';

type Props = {
    localization: any;
    ordersLocalization: any;
    // data: ordersOrientedSummaryData;
    ordersStatistics: ordersStatistics;
    setOrdersFilters: (filter: orderFilterFields) => void;
};

const OrdersOrientedSummarySection = ({ localization, ordersLocalization, ordersStatistics, setOrdersFilters }: Props) => {
    const history = useHistory();

    return (
        <Container>
            <BoxesContainer>
                <SummaryBox
                    title={localization.activeOrders.title}
                    onClick={() => {
                        setOrdersFilters([]);
                        history.push(PagesRoutes.Orders);
                    }}
                >
                    {ordersStatistics.activeOrders}
                </SummaryBox>
                <SummaryBox
                    title={localization.exceptions.title}
                    dataColor="#c1292e"
                    onClick={() => {
                        setOrdersFilters([
                            {
                                field: 'alertStatus',
                                value: ['1']
                            }
                        ]);
                        history.push(PagesRoutes.Orders);
                    }}
                >
                    <Flex>
                        <img src={critical} alt={'critical'} width={20} style={{ marginRight: '5px' }} />
                        {ordersStatistics.exceptions}
                    </Flex>
                </SummaryBox>
                <SummaryBox
                    title={localization.warnings.title}
                    dataColor="#f4c915"
                    onClick={() => {
                        setOrdersFilters([
                            {
                                field: 'alertStatus',
                                value: ['1']
                            }
                        ]);
                        history.push(PagesRoutes.Orders);
                    }}
                >
                    <Flex>
                        <img src={warning} alt={'warning'} width={20} style={{ marginRight: '5px' }} />
                        {ordersStatistics.warnings}
                    </Flex>
                </SummaryBox>
                <SummaryBox title={localization.activeOrdersValue.title}>
                    <div style={{ fontSize: 14 }}>
                        {Object.entries(ordersStatistics.activeOrdersValue).map((entry) => {
                            const value = currencyFormatter(entry[0])(entry[1]);
                            return <div>{value}</div>;
                        })}
                    </div>
                </SummaryBox>
            </BoxesContainer>

            <ChartsContainer>
                <ChartContainer>
                    <Title>
                        <div className="title">{localization.charts.ordersAmountByMonth}</div>
                    </Title>
                    <OrdersAmountByMonth
                        localization={localization}
                        ordersAmountByMonth={ordersStatistics.ordersAmountByMonth}
                        setOrdersFilters={setOrdersFilters}
                    />
                </ChartContainer>
                <ChartContainer>
                    <Title>
                        <div className="title">{localization.charts.exceptionsByMonth}</div>
                    </Title>
                    <ExceptionsByMonth localization={localization} exceptionsByMonth={ordersStatistics.exceptionsByMonth} />
                </ChartContainer>
            </ChartsContainer>
            <PieCharts
                chartsData={{
                    ordersByCarrier: {
                        data: ordersStatistics.ordersByCarrier,
                        onClick: (carrier: string) => {
                            setOrdersFilters([
                                {
                                    field: 'carrier',
                                    value: carrier
                                }
                            ]);
                            history.push(PagesRoutes.Orders);
                        }
                    },
                    ordersByState: {
                        data: ordersStatistics.ordersByState,
                        labelTextFormatter: (state: string) => ordersLocalization?.state?.[state] || state,
                        onClick: (state: string) => {
                            setOrdersFilters([
                                {
                                    field: 'state',
                                    value: [state as OrderStateType]
                                }
                            ]);
                            history.push(PagesRoutes.Orders);
                        }
                    },
                    ordersByCountry: {
                        data: ordersStatistics.ordersByCountry,
                        labelTextFormatter: (country: string) => countries.find((item) => item.code === country)?.name || country,
                        onClick: (country: string) => {
                            setOrdersFilters([
                                {
                                    field: 'customerCountry',
                                    value: country
                                }
                            ]);
                            history.push(PagesRoutes.Orders);
                        }
                    }
                }}
                localization={localization}
                removeLastBorder
            />
        </Container>
    );
};

const Container = styled.div`
    padding: 15px 30px 30px 30px;

    @media screen and (max-width: 1000px) {
        padding-right: 10px;
        padding-left: 10px;
        padding-top: 20px;
    }

    @media screen and (max-width: 768px) {
        padding-right: 0px;
        padding-left: 0px;
        padding-top: 0px;
    }
`;

const Flex = styled.div`
    display: flex;
    align-items: center;
`;

const ChartsContainer = styled.div`
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-wrap: wrap;
    padding-bottom: 15px;
    border-bottom: 1px solid #e4e4e4;

    > div {
        margin: 30px 0;
    }
`;

const ChartContainer = styled.div`
    flex: 1 1 0;
    width: 0px;
    height: 350px;
    padding: 10px;
    text-align: center;
    border-right: 1px solid #e4e4e4;

    &:last-child {
        border: none;
    }
    @media screen and (max-width: 768px) {
        flex: auto;
        height: 300px;
        width: 100%;
        border: none;
    }
`;

const Title = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;

    .title {
        color: #415a8a;
        font-size: 16px;
        font-weight: 500;
    }
`;
export default OrdersOrientedSummarySection;
