import React from 'react';
import { connect } from 'react-redux';
import { product } from '../../../../../../models/entities/product/product';
import { drawers } from '../../../../../../state/ducks/header/header';
import { localizationSelectors } from '../../../../../../state/ducks/localization/selectors';
import { productsSelectors } from '../../../../../../state/ducks/products/selectors';
import { RootState } from '../../../../../../state/store/store';
import Grid from '../../../../../shared/Grid/Grid';
import { ColumnType } from '../../../../../shared/Grid/types/Column';
import { ModalTriggerProps } from '../../../../../shared/Modal/Modal';
import { styled } from '../../../../../shared/Theme/theme';
import ProductsModal, { productGridRow } from '../../ProductsModal/ProductsModal';
import Button from '../../../../../shared/SmallComponents/Button';

type Props = {
    products?: Array<product>;
    gridColumns: Array<ColumnType<product>>;
    localization: any;
    setDrawerOpen: (open: drawers, params?: any) => void;
    nonRelatedProducts?: Array<product>;
    createMultipleRelatedProducts: (productId: string, relatedProducts: string[]) => Promise<void>;
    currentProductId: string | null;
};

const RelatedProductsTab = ({
    products,
    gridColumns,
    localization,
    setDrawerOpen,
    nonRelatedProducts,
    createMultipleRelatedProducts,
    currentProductId
}: Props) => {
    return (
        <Wrapper>
            <ProductsModal
                title={'Products'}
                filterProductsFn={(product: product) => (nonRelatedProducts ? nonRelatedProducts.map((p) => p.id).includes(product.id) : true)}
                onSubmitProductsCallBack={async (products: productGridRow[]) => {
                    if (!currentProductId) return;
                    await createMultipleRelatedProducts(
                        currentProductId,
                        products.map((p) => p.id)
                    );
                }}
                Trigger={(props: ModalTriggerProps) => {
                    return (
                        <ButtonContainer>
                            <Button onClick={props.onClick} width="auto" buttonType="hollow" padding="4px 12px">
                                {localization.related_products.add_btn}
                            </Button>
                        </ButtonContainer>
                    );
                }}
                productsFetched
            />
            <GridWrapper>
                <Grid<product>
                    onSettingsClick={() => console.log('settings')}
                    onRowMoreOptionsClick={(row) => console.log(row)}
                    menuType="relatedProductMenu"
                    columns={gridColumns}
                    data={products || []}
                    localization={localization.grid}
                    emptyStateText={localization.grid.relatedEmptyState}
                    idKey="id"
                    onRowClick={(row) => {
                        setDrawerOpen('PRODUCT', { productId: row.id });
                    }}
                />
            </GridWrapper>
        </Wrapper>
    );
};
const Wrapper = styled.div`
    height: 100%;
    display: flex;
    flex-direction: column;
`;

const GridWrapper = styled.div`
    flex: 1;
    max-width: 100%;
    width: 100%;
    box-sizing: border-box;
    height: 100%;
    width: 100%;
    z-index: 1;
    overflow: overlay;

    @media screen and (min-width: 768px) {
        height: calc(100% - 127px);
    }

    @media screen and (max-width: 768px) {
        height: calc(100% - 135px - 40px);
    }

    @media screen and (max-height: 400px) {
        height: calc(100% - 135px);
    }
`;
const ButtonContainer = styled.div`
    display: flex;
    justify-content: flex-end;
    padding-top: 8px;
`;

const mapStateToProps = (state: RootState) => ({
    gridColumns: productsSelectors.gridColumns(state),
    // currentGridSort: productsSelectors.gridSort(state),
    localization: localizationSelectors.products(state),
    nonRelatedProducts: productsSelectors.nonRelatedProducts(state),
    currentProductId: productsSelectors.currentProductId(state)
});

const mapDispatchToProps = (dispatch: any) => ({
    // setGridSort: (sortData: gridSortState) => dispatch.products.setGridSort(sortData),
    setDrawerOpen: (open: drawers, params?: any) => dispatch.header.setDrawerOpenParams({ open, params }),
    createMultipleRelatedProducts: (productId: string, relatedProducts: string[]) =>
        dispatch.products.createMultipleRelatedProducts({ productId, relatedProducts })
});

export default connect(mapStateToProps, mapDispatchToProps)(RelatedProductsTab);
