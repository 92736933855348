import React from 'react';
import theme, { styled } from '../../shared/Theme/theme';

export type ButtonType = 'hollow' | 'filled' | 'clean' | 'disabled';

type Props = {
    width?: string;
    disabled?: boolean;
    minWidth?: string;
    height?: string;
    padding?: string;
    paddingMobile?: string;
    margin?: string;
    buttonType?: ButtonType;
    borderRadius?: string;
    borderRadiusMobile?: string;
    minHeight?: string;
    minHeightMobile?: string;
    widthMobile?: string;
    children?: Array<JSX.Element> | JSX.Element | string;
    border?: string;
    onClick?: (e: React.MouseEvent<HTMLElement, MouseEvent>) => void;
    onMouseDown?: React.MouseEventHandler<HTMLButtonElement>;
    type?: 'button' | 'submit' | 'reset';
    textColor?: string;
    visibility?: 'hidden' | 'visible';
    color?: string;
};

const Button = React.forwardRef(
    (props: Props, ref: ((instance: HTMLButtonElement | null) => void) | React.RefObject<HTMLButtonElement> | null | undefined) => {
        const {
            width,
            minWidth,
            height,
            padding,
            margin,
            buttonType,
            children,
            onClick,
            onMouseDown,
            type,
            disabled,
            borderRadius,
            minHeight,
            paddingMobile,
            borderRadiusMobile,
            minHeightMobile,
            widthMobile,
            border,
            textColor,
            visibility,
            color
        } = props;
        return (
            <StyledButton
                type={type}
                width={width}
                minWidth={minWidth}
                height={height}
                padding={padding}
                margin={margin}
                buttonType={buttonType}
                onClick={onClick}
                onMouseDown={onMouseDown}
                disabled={disabled}
                borderRadius={borderRadius}
                minHeight={minHeight}
                paddingMobile={paddingMobile}
                borderRadiusMobile={borderRadiusMobile}
                minHeightMobile={minHeightMobile}
                widthMobile={widthMobile}
                ref={ref}
                border={border}
                textColor={textColor}
                color={color}
                visibility={visibility}
            >
                {children}
            </StyledButton>
        );
    }
);

type StyledButton = {
    width?: string;
    minWidth?: string;
    height?: string;
    padding?: string;
    paddingMobile?: string;
    margin?: string;
    borderRadius?: string;
    borderRadiusMobile?: string;
    minHeight?: string;
    minHeightMobile?: string;
    widthMobile?: string;
    buttonType?: ButtonType;
    border?: string;
    textColor?: string;
    color?: string;
    visibility?: 'hidden' | 'visible';
};

const StyledButton = styled.button<StyledButton>`
    width: ${(props) => props.width ?? ''};
    min-width: ${(props) => props.minWidth ?? ''};
    height: ${(props) => props.height ?? '100%'};
    padding: ${(props) => props.padding ?? '8px'};
    margin: ${(props) => props.margin ?? ''};

    color: ${(props) =>
        props.textColor
            ? props.textColor
            : props.disabled && props.buttonType && ['clean', 'hollow'].includes(props.buttonType)
            ? props.color
                ? `${props.color}7a`
                : '#008ac97a'
            : props.disabled || props.buttonType === 'filled'
            ? '#fff'
            : props.buttonType === 'disabled'
            ? props.color
                ? `${props.color}7a`
                : '#008ac9a1'
            : props.color || theme.colors.primaryBlue};
    background-color: ${(props) =>
        props.buttonType && ['clean', 'hollow'].includes(props.buttonType)
            ? 'transparent'
            : props.disabled
            ? props.color
                ? `${props.color}7a`
                : '#008ac9a1'
            : props.buttonType === 'filled'
            ? props.color || theme.colors.primaryBlue
            : '#fff'};
    border: ${(props) =>
        props.border ||
        `solid 1px ${
            props.buttonType === 'clean'
                ? 'transparent'
                : props.disabled && props.buttonType === 'hollow'
                ? props.color
                    ? `${props.color}7a`
                    : '#008ac97a'
                : props.color || theme.colors.primaryBlue
        }`};
    cursor: ${(props) => (props.disabled || props.buttonType === 'disabled' ? 'not-allowed' : 'pointer')};
    ${(props) => (props.visibility ? `visibility:${props.visibility};` : '')}
    border-radius: ${(props) => props.borderRadius ?? '100px'};
    font-size: 16px;
    text-align: center;
    line-height: 0;
    min-height: ${(props) => props.minHeight ?? '30px'};

    display: flex;
    justify-content: center;
    align-items: center;

    &:focus {
        outline: none;
    }

    @media screen and (max-width: 768px) {
        border-radius: ${(props) => props.borderRadiusMobile ?? props.borderRadius ?? '100px'};
        min-height: ${(props) => props.minHeightMobile ?? props.minHeight ?? '30px'};
        padding: ${(props) => props.paddingMobile ?? props.padding ?? '8px'};
        width: ${(props) => props.widthMobile ?? props.width ?? ''};
    }
`;

export default Button;
