import React, { useEffect } from 'react';
import { bid, bidShipment } from '../../../../models/entities/bid/bid';
import { RouteComponentProps } from 'react-router-dom';
import { connect } from 'react-redux';
import { Dispatch, RootState } from '../../../../state/store/store';
import { localizationSelectors } from '../../../../state/ducks/localization/selectors';
import { businessPartnersSelectors } from '../../../../state/ducks/businessPartner/selectors';
import BidForm from './BidForm';
import Loader from '../../../shared/SmallComponents/Loader';
import { userSelectors } from '../../../../state/ducks/user/selectors';
import { allowedCompany } from '../../../../models/entities/user';
import { PagesRoutes } from '../../../../routing/PagesRoutes';
import { singleBidSelectors } from '../../../../state/ducks/singleBid/selectors';
import { bidsSelectors } from '../../../../state/ducks/bids/selectors';
import { shipment } from '../../../../models/entities/shipment/shipment';
import { businessPartner } from '../../../../models/entities/businessPartner/businessPartner';

type PathParams = {
    bidId: string;
};

export type BaseProps = {
    bid: bidShipment | undefined;
    localizations?: any;
    createBid: (shipment: shipment, bid: bid) => Promise<string>;
    updateBid: (id: string, bid: bid, shipment: shipment) => Promise<boolean>;
    allowedTypes: Array<string>;
    bidFormError?: string;
    shipment: shipment;
    businessPartners: Array<businessPartner>;
};
type Props = RouteComponentProps<PathParams> &
    BaseProps & {
        setCurrentBidId: (currentBidId: string) => void;
        fetchSingleBid: (bidId: string) => void;
        fetchShipmentById: (shipmentId: string) => void;
    };
export const eBidFormMode = {
    NEW: 'new',
    EDIT: 'edit',
    DUPLICATE: 'duplicate'
};
const SingleBidFormConnected = ({
    localizations,
    match,
    createBid,
    updateBid,
    bid,
    setCurrentBidId,
    fetchSingleBid,
    allowedTypes,
    bidFormError,
    businessPartners,
    shipment,
    fetchShipmentById
}: Props) => {
    const urlBidId = match.params.bidId;
    const queryString = window.location.search;
    const urlParams = new URLSearchParams(queryString);
    const shipmentId = urlParams.get('shipmentId');
    useEffect(() => {
        if (urlBidId) {
            setCurrentBidId(urlBidId);
            fetchSingleBid(urlBidId);
        }
        if (shipmentId) fetchShipmentById(shipmentId);
    }, [urlBidId, setCurrentBidId, fetchSingleBid, fetchShipmentById]);
    let mode: string = eBidFormMode.NEW;
    if (urlBidId) mode = eBidFormMode.EDIT;
    if (match.path.indexOf(PagesRoutes.DuplicateBid) !== -1) mode = eBidFormMode.DUPLICATE;
    const currShipment = bid?.shipment || shipment;
    return (urlBidId && !bid) || !currShipment ? (
        <Loader />
    ) : (
        <BidForm
            mode={mode}
            bid={bid}
            localizations={localizations}
            createBid={createBid}
            updateBid={updateBid}
            allowedTypes={allowedTypes}
            bidFormError={bidFormError}
            shipment={currShipment}
            businessPartners={businessPartners}
        />
    );
};

const mapStateToProps = (state: RootState) => ({
    localizations: localizationSelectors.bid(state),
    businessPartners: businessPartnersSelectors.businessPartnerForwarders(state),
    bid: singleBidSelectors.bid(state),
    allowedTypes: userSelectors.allowedTypes(state),
    bidFormError: state.error.effects.bids.updateBid || state.error.effects.bids.createBid,
    shipment: bidsSelectors.shipment(state)
});

const mapDispatchToProps = (dispatch: Dispatch) => ({
    createBid: (shipment: shipment, bid: string) => dispatch.bids.createBid({ shipment, bid }),
    updateBid: (id: string, bid: string, shipment: shipment) => dispatch.bids.updateBid({ id, bid, shipment }),
    setCurrentBidId: (currentBidId: string) => dispatch.singleBid.setCurrentBidId(currentBidId),
    fetchSingleBid: (bidId: string) => dispatch.bids.fetchBidById(bidId),
    fetchShipmentById: (shipmentId: string) => dispatch.bids.fetchShipmentById(shipmentId)
});

export default connect(mapStateToProps, mapDispatchToProps as any)(SingleBidFormConnected);
