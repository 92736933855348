import React from 'react';
import { settings, shipment } from '../../../models/entities/shipment/shipment';
import { styled } from '../../shared/Theme/theme';
import ShipmentTabs from './sections/Tabs';
import DiscussionsSection from './sections/Discussions/DiscussionsSection';
import moment from 'moment';
import ShipmentStation from '../../shared/Grid/specialColumns/ShipmentStation';
import ShipmentType from '../../shared/Grid/specialColumns/ShipmentType';
import { settings as companySettings } from '../../../models/entities/businessPartner/businessPartner';
import claimsType from '../../../models/entities/claimsType';
import { bid } from '../../../models/entities/bid/bid';
import { featureFlagsModel } from '../../../models/entities/businessPartner/settings';
import { drawers } from '../../../state/ducks/header/header';
import { claimsDomainsType } from '../../../userClaims';

type Props = {
    shipment: shipment;
    shipmentTags: Array<string>;
    onTagRemoveClicked: (tagName: string) => void;
    localization?: any;
    getUserClaims: (domain: claimsDomainsType) => claimsType;
    settings: settings;
    updateShipmentAccountingState: (shipmentId: string, shipmentAccountingState: string) => Promise<void>;
    companySettings?: companySettings;
    updateBid?: (id: string, bid: bid, shipment: shipment, publish: boolean) => Promise<boolean>;
    createBid: (hsipment: shipment, bid: bid, publish: boolean) => Promise<string>;
    startBid?: (id: string, shipmentId: string, shipment: shipment) => Promise<void>;
    featureFlags?: featureFlagsModel;
    setDrawerOpen: (open: drawers, params?: any) => void;
    isFreelancerOrForwarder?: boolean;
};

const SingleShipmentPageMobile = ({
    shipment,
    shipmentTags,
    onTagRemoveClicked,
    localization,
    settings,
    updateShipmentAccountingState,
    companySettings,
    getUserClaims,
    startBid,
    updateBid,
    createBid,
    featureFlags,
    setDrawerOpen,
    isFreelancerOrForwarder
}: Props) => {
    return (
        <PageWrapper>
            <OverviewWrapper>
                <TopOverview>
                    <div id="shipment-type-ref">
                        <div id="type">
                            <ShipmentType value={shipment.typeCode} imgWidth={24} />
                            <span className="shipment-number">#{shipment.CargoZoneNumber}</span>
                        </div>
                        <div id="ref">
                            <span className="ref-label">{localization.forwarder_ref}</span> #{shipment.forwarderRef}
                        </div>
                    </div>
                    <div id="shipment-forwarder">
                        <div>{localization.forwarder}:</div>
                        <div className="forwarder-name">{shipment.forwarderName}</div>
                    </div>
                </TopOverview>
                <BottomOverview>
                    <div id="events">
                        <Label>
                            <div className="event">
                                {localization.now}:{' '}
                                <span>{shipment.lastEvent && settings?.eventTypes?.find((type: any) => type.code === shipment.lastEvent)?.name}</span>
                            </div>
                        </Label>
                        <Label>
                            <div className="event">
                                {localization.next}: <span>-</span>
                            </div>
                        </Label>
                    </div>
                    <div id="route">
                        <RowWrapper>
                            <ShipmentStation country={shipment.originCountry} port={shipment.originStation} flagSize={'24px'} />
                            <RightArrowIcon />
                            <ShipmentStation country={shipment.destinationCountry} port={shipment.destinationStation} flagSize={'24px'} />
                        </RowWrapper>
                        <div id="eta">
                            <span>
                                <Label>
                                    {localization.eta} <span>{moment(shipment.eta).format('DD/MM/YYYY HH:mm')}</span>
                                </Label>
                            </span>
                        </div>
                    </div>
                </BottomOverview>
            </OverviewWrapper>
            <ShipmentTabsWrapper>
                <ShipmentTabs
                    shipment={shipment}
                    localization={localization}
                    settings={settings}
                    companySettings={companySettings}
                    updateShipmentAccountingState={updateShipmentAccountingState}
                    isMobile={true}
                    getUserClaims={getUserClaims}
                    updateBid={updateBid}
                    createBid={createBid}
                    startBid={startBid}
                    featureFlags={featureFlags}
                    setDrawerOpen={setDrawerOpen}
                    isFreelancerOrForwarder={isFreelancerOrForwarder}
                />
            </ShipmentTabsWrapper>
            <DiscussionsWrapper>
                <DiscussionsSection localization={localization} />
            </DiscussionsWrapper>
        </PageWrapper>
    );
};

const PageWrapper = styled.div`
    height: calc(100vh - 65px - 40px);
    display: flex;
    flex-direction: column;
`;
const OverviewWrapper = styled.div`
    height: 200px;
    background-color: #f7f7f7;
    padding: 25px 20px 20px 20px;
    width: 100%;
    display: flex;
    flex-direction: column;
    border-bottom: 1px solid #e0e0e0;
`;
const ShipmentTabsWrapper = styled.div`
    height: calc(100vh - 200px - 65px - 108px);
`;
const DiscussionsWrapper = styled.div`
    padding: 15px;
`;
const TopOverview = styled.div`
    display: flex;
    height: 47%;

    #shipment-type-ref {
        width: 58%;

        #type {
            color: #394372;
            font-size: 18px;
            font-weight: bold;
            height: 24px;
            white-space: nowrap;
            display: flex;
            align-items: center;

            .shipment-number {
                margin-left: 10px;
            }
        }
        #ref {
            color: #394372;
            margin-top: 6px;
            .ref-label {
            }
        }
    }
    #shipment-forwarder {
        color: #394372;
        flex: 1;
        .forwarder-name {
            font-weight: bolder;
            font-size: 20px;
        }
    }
`;
const BottomOverview = styled.div`
    width: 100%;
    background-color: #ffffff;
    flex: 1;
    height: 50%;
    box-shadow: 0px 0px 13px 0px rgba(0, 0, 0, 0.1);
    border-radius: 3px;
    display: flex;
    justify-content: center;

    #events {
        width: 58%;
        padding: 10px;
        border-right: 1px solid #e9e9e9;
        display: flex;
        flex-direction: column;
        justify-content: space-around;

        .event {
        }
    }
    #route {
        padding: 10px;
        flex: 1;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: space-around;
    }
`;

const Label = styled.span`
    color: #394372;

    span {
        font-weight: bold;
    }
`;

const RowWrapper = styled.div`
    display: flex;
    white-space: nowrap;
    flex-direction: row;
    color: #394372;
`;

const RightArrowIcon = styled.div`
    width: 20px;
    height: 20px;
    margin: 10px 15px;

    background: #d1d1d1;
    height: 1px;
    width: 15px;
    position: relative;

    &:before,
    &:after {
        content: '';
        background: #d1d1d1;
        position: absolute;
        height: 1px;
        width: 8px;
    }

    &:before {
        right: -4px;
        bottom: -2px;
        transform: rotate(-30deg);
    }

    &:after {
        right: -4px;
        top: -2px;
        transform: rotate(30deg);
    }
`;

export default SingleShipmentPageMobile;
