import React, { useRef, useState } from 'react';
import { Formik, Form } from 'formik';
import Loader from '../../../../shared/SmallComponents/Loader';
import CZTooltip, { QuestionToolTip } from '../../../../shared/CZTooltip/CZTooltip';
import { BaseProps } from './ProductFormConnected';
import { marketplaceAsin, product } from '../../../../../models/entities/product/product';
import { validationSchema } from '../../../../../validationSchemas/productForm';
import { styled } from '../../../../shared/Theme/theme';
import Button from '../../../../shared/SmallComponents/Button';
import { editableDetail, formFieldsArr, renderEditable, sectionType, validateAsin, validateHsCodes, validateSuppliers } from '../../utils';
import { partiesToDropdownOptions } from '../../../businessPartners/components/BusinessPartnerForm/BusinessPartnerFormConnected';
import CreateBusinessPartner from '../../../singlePurchaseOrder/PurchaseOrderForm/sections/BusinessPartners/CreateBusinessPartner';
import CategoryFormModal from '../CategoryForm/CategoryFormModal';
import { businessPartner } from '../../../../../models/entities/businessPartner/businessPartner';
import DuplicateIncludeModal, { useStateType } from './DuplicateIncludeModal';
import { emptyHsCodeObj } from '../../../../shared/HsCodes/input';
import { hsCode } from '../../../../../models/entities/common_subentities/hsCode';
import { category } from '../../../../../models/entities/category/category';
import countries from '../../../../../static/data/countries.json';
import { supplier } from '../../../../../models/entities/product/supplier';
import { emptySupplierObj } from './SuppliersInput';
import { flatMarketplaces } from '../../../Admin/Companies/SingleCompanyPage/utils';
import { emptyAsinObj } from './AsinInput';
import { createEditorStateWithText } from '@draft-js-plugins/editor';
import { convertEditorStateToHtml, convertHtmlToEditorState } from '../../../../../utils/textEditorUtils';

type Props = BaseProps & {};

const countriesDropDownOptions = countries.map((country) => ({
    text: country.name,
    value: country.code
}));

export const renderFieldLabel = (text: string, isMandatory: boolean, tooltipText?: string) => {
    return (
        <LabelContainer>
            <Label>
                {isMandatory && <MandatoryIndication>*</MandatoryIndication>}
                {text}
            </Label>
            {tooltipText && <CZTooltip text={tooltipText}>{QuestionToolTip}</CZTooltip>}
        </LabelContainer>
    );
};
const ProductForm = ({
    localizations,
    mode,
    product,
    createProduct,
    updateProduct,
    duplicateProduct,
    getCategoriesOptionsByCompanyId,
    onClose,
    businessPartnerSuppliersManufacturers,
    businessPartnerManufacturers,
    featureFlags,
    allowedCompanies,
    initSupplierId
}: Props) => {
    const [loader, setLoader] = useState(false);
    const [duplicateCallback, setDuplicateCallback] = useState<useStateType>();
    const [deletedSuppliers, setDeletedSuppliers] = useState<string[]>([]);

    const emptyEditorState = useRef(createEditorStateWithText(''));

    const setInitialValues = () => {
        const initValues = {
            companyId: null,
            companyName: null,
            name: null,
            sku: null,
            description: emptyEditorState.current,
            supplierId: initSupplierId || null,
            supplierName: businessPartnerSuppliersManufacturers?.find((item) => item.id === initSupplierId)?.name || null,
            categoryId: null,
            brand: null,
            cost: null,
            costCurrency: 'USD',
            retailPrice: null,
            retailPriceCurrency: 'USD',
            salePrice: null,
            salePriceCurrency: 'USD',
            weight: null,
            weightUnit: 'KG',
            height: null,
            width: null,
            length: null,
            dimensionUnit: 'CM',
            note: null,
            userId: null,
            asin: null,
            fnsku: null,
            rank: null,
            numberOfRatings: null,
            fbaFee: null,
            fbaFeeCurrency: 'USD',
            amazonPercentFee: null,
            masterCartonWeight: null,
            masterCartonWeightUnit: 'KG',
            masterCartonHeight: null,
            masterCartonWidth: null,
            masterCartonLength: null,
            masterCartonDimensionUnit: 'CM',
            unitsPerMasterCarton: null,
            productionDays: null,
            minOrderQuantity: 1,
            minOrderQuantityAlert: 0,
            hsCodes: [emptyHsCodeObj],
            madeIn: ''
        };
        if (['EDIT', 'DUPLICATE'].includes(mode) && product) {
            const values = {
                companyId: product.companyId,
                companyName: product.companyName,
                name: product.name,
                sku: product.sku,
                description: convertHtmlToEditorState(
                    product.description,
                    (id: string) => undefined,
                    (id: string) => undefined,
                    undefined
                ),
                supplierId: product.supplierId,
                supplierName: product.supplierName,
                categoryId: product.categoryId,
                brand: product.brand,
                cost: product.cost,
                costCurrency: product.costCurrency || 'USD',
                retailPrice: product.retailPrice,
                retailPriceCurrency: product.retailPriceCurrency || 'USD',
                salePrice: product.salePrice,
                salePriceCurrency: product.salePriceCurrency || 'USD',
                weight: product.weight,
                weightUnit: product.weightUnit || 'KG',
                height: product.height,
                width: product.width,
                length: product.length,
                dimensionUnit: product.dimensionUnit || 'CM',
                note: product.note,
                userId: product.userId,
                asin: product.asin,
                fnsku: product.fnsku,
                rank: product.rank,
                numberOfRatings: product.numberOfRatings,
                fbaFee: product.fbaFee,
                fbaFeeCurrency: product.fbaFeeCurrency,
                amazonPercentFee: product.amazonPercentFee,
                masterCartonWeight: product.masterCartonWeight,
                masterCartonWeightUnit: product.masterCartonWeightUnit || 'KG',
                masterCartonHeight: product.masterCartonHeight,
                masterCartonWidth: product.masterCartonWidth,
                masterCartonLength: product.masterCartonLength,
                masterCartonDimensionUnit: product.masterCartonDimensionUnit || 'CM',
                unitsPerMasterCarton: product.unitsPerMasterCarton,
                productionDays: product.productionDays,
                minOrderQuantity: product.minOrderQuantity,
                minOrderQuantityAlert: product.minOrderQuantityAlert,
                hsCodes: product.hsCodes && product.hsCodes.length > 0 ? product.hsCodes : [emptyHsCodeObj],
                suppliers: product.suppliers && product.suppliers.length > 0 ? product.suppliers : [emptySupplierObj],
                isMultipleSuppliers: product.isMultipleSuppliers || false,
                madeIn: product.madeIn
            };
            if (mode === 'DUPLICATE') {
                values.name = `Copied of ${values.name}`;
                values.sku = `${values.sku} -1`;
            }
            return values;
        }
        return initValues;
    };
    const formValidationSchema = validationSchema(localizations);
    const validate = (values: any) => {
        const errors: any = {};
        if (allowedCompanies.length > 1 && !values.companyId) errors.companyId = localizations.required_field_message;
        if (values.hsCodes) validateHsCodes(values.hsCodes, errors, localizations);
        if (featureFlags?.AMAZON && values.asin) validateAsin(values.asin, errors, localizations);
        if (values.suppliers && values.isMultipleSuppliers) validateSuppliers(values.suppliers, !!values.isMultipleSuppliers, errors, localizations);

        return errors;
    };
    const submit = async (values: any) => {
        const stringToNumber = (value: string) => (value ? Number(value) : null);
        const verifyUnitItem = (cond: boolean, value: string) => (cond ? value : null);
        setLoader(true);
        const productObject: product = {
            ...values,
            description: convertEditorStateToHtml(values.description),
            cost: stringToNumber(values.cost),
            costCurrency: verifyUnitItem(values.cost, values.costCurrency),
            retailPrice: stringToNumber(values.retailPrice),
            retailPriceCurrency: verifyUnitItem(values.retailPrice, values.retailPriceCurrency),
            salePrice: stringToNumber(values.salePrice),
            salePriceCurrency: verifyUnitItem(values.salePrice, values.salePriceCurrency),
            weight: stringToNumber(values.weight),
            weightUnit: verifyUnitItem(values.weight, values.weightUnit),
            height: stringToNumber(values.height),
            width: stringToNumber(values.width),
            length: stringToNumber(values.length),
            dimensionUnit: verifyUnitItem(values.height && values.width && values.length, values.dimensionUnit),
            fbaFee: stringToNumber(values.fbaFee),
            fbaFeeCurrency: verifyUnitItem(values.fbaFee, values.fbaFeeCurrency),
            rank: stringToNumber(values.rank),
            amazonPercentFee: stringToNumber(values.amazonPercentFee),
            numberOfRatings: stringToNumber(values.numberOfRatings),
            masterCartonWeight: stringToNumber(values.masterCartonWeight),
            masterCartonWeightUnit: verifyUnitItem(values.masterCartonWeight, values.masterCartonWeightUnit),
            masterCartonHeight: stringToNumber(values.masterCartonHeight),
            masterCartonWidth: stringToNumber(values.masterCartonWidth),
            masterCartonLength: stringToNumber(values.masterCartonLength),
            masterCartonDimensionUnit: verifyUnitItem(
                values.masterCartonHeight && values.masterCartonWidth && values.masterCartonLength,
                values.masterCartonDimensionUnit
            ),
            unitsPerMasterCarton: stringToNumber(values.unitsPerMasterCarton),
            productionDays: stringToNumber(values.productionDays),
            minOrderQuantity: values.minOrderQuantity,
            minOrderQuantityAlert: values.minOrderQuantityAlert || 0,
            isMultipleSuppliers: values.isMultipleSuppliers || false,
            suppliers: (values.suppliers || []).filter((s: supplier) => !!s.supplierId && !!s.sku)
        } as product;
        if (mode === 'NEW') {
            createProduct(productObject)
                .then((value) => {
                    if (value) onClose && onClose(value);
                })
                .finally(() => {
                    setLoader(false);
                });
        } else if (mode === 'DUPLICATE' && product) {
            setDuplicateCallback({
                open: true,
                func: (includeDocuments: boolean, includeAttributes: boolean) => {
                    duplicateProduct(productObject, product.id, includeDocuments, includeAttributes)
                        .then((value) => {
                            if (value) onClose && onClose();
                        })
                        .finally(() => {
                            setLoader(false);
                        });
                }
            });
        } else if (mode === 'EDIT' && product) {
            updateProduct(product.id, productObject, deletedSuppliers)
                .then((value) => {
                    if (value) onClose && onClose();
                })
                .finally(() => {
                    setLoader(false);
                });
        }
    };
    return (
        <>
            {duplicateCallback?.open && (
                <DuplicateIncludeModal localizations={localizations} onCallbackFn={duplicateCallback} setDuplicateCallback={setDuplicateCallback} />
            )}
            <Formik initialValues={setInitialValues()} validationSchema={formValidationSchema} validate={validate} onSubmit={submit}>
                {({ values, setFieldValue, handleSubmit }) => {
                    return (
                        <PageContainer>
                            <Form style={{ display: 'flex', flexDirection: 'column', height: '100%' }}>
                                <Container>
                                    {formFieldsArr({
                                        values,
                                        localizations,
                                        options: {
                                            supplier: partiesToDropdownOptions(businessPartnerSuppliersManufacturers || []),
                                            category: getCategoriesOptionsByCompanyId(values.companyId || ''),
                                            allowedCompanies: allowedCompanies
                                                .filter(
                                                    (c) => !!c.parentCompany || !allowedCompanies.some((comp) => comp.parentCompany === c.companyId)
                                                )
                                                .map((item) => {
                                                    return { value: item.companyId, text: item.companyName };
                                                }),
                                            countries: countriesDropDownOptions
                                        },
                                        onChange: {
                                            weightUnit: (value: any) => {
                                                if (value === 'KG') setFieldValue('dimensionUnit', 'CM');
                                                if (value === 'G') setFieldValue('dimensionUnit', 'CM');
                                                else if (value === 'LB') setFieldValue('dimensionUnit', 'IN');
                                            },
                                            masterCartonWeightUnit: (value: any) => {
                                                if (value === 'KG') setFieldValue('masterCartonDimensionUnit', 'CM');
                                                else if (value === 'LB') setFieldValue('masterCartonDimensionUnit', 'IN');
                                            },
                                            supplierId: (value: any) => {
                                                setFieldValue(
                                                    'supplierName',
                                                    businessPartnerSuppliersManufacturers?.find((item) => item.id === value)?.name || null
                                                );
                                            },
                                            companyId: (value: string) => {
                                                const selectedCompanyMarketplaces = allowedCompanies.find(
                                                    (c) => c.companyId === value
                                                )?.marketplaceIds;
                                                const newAsinValue = emptyAsinObj(
                                                    flatMarketplaces.filter((mp) => selectedCompanyMarketplaces?.includes(mp.marketplaceId))?.[0]
                                                        ?.marketplaceId || flatMarketplaces?.find((mp) => mp.countryCode === 'US')?.marketplaceId
                                                );
                                                setFieldValue('asin', [newAsinValue]);
                                                setFieldValue('companyName', allowedCompanies?.find((item) => item.companyId === value)?.companyName);
                                                setFieldValue('categoryId', null);
                                            },
                                            hsCodes: (value: Array<hsCode>) => {
                                                setFieldValue('hsCodes', value);
                                            },
                                            suppliers: (value: Array<Partial<supplier>>) => {
                                                setFieldValue('suppliers', value);
                                            },
                                            asin: (value: Array<marketplaceAsin>) => {
                                                setFieldValue('asin', value);
                                            }
                                        },
                                        onDelete: {
                                            suppliers: (id: string) => {
                                                setDeletedSuppliers([...deletedSuppliers, id]);
                                            }
                                        },
                                        suffix: {
                                            supplierId: (
                                                <CreateBusinessPartner
                                                    type="SUPPLIER"
                                                    modalTitle={localizations.supplier}
                                                    submitCallback={(businessPartner: businessPartner) => {
                                                        setFieldValue('supplierId', businessPartner.id);
                                                        setFieldValue('supplierName', businessPartner?.name || null);
                                                    }}
                                                />
                                            ),
                                            categoryId: (
                                                <CategoryFormModal
                                                    companyId={values.companyId || undefined}
                                                    modalTitle={localizations.category}
                                                    submitCallback={(category: category) => {
                                                        setFieldValue('categoryId', category.id);
                                                    }}
                                                />
                                            )
                                        },
                                        featureFlags
                                    }).map((outer: sectionType, index: number) => {
                                        return (
                                            !outer.hide && (
                                                <Section key={index}>
                                                    {outer.title && <SectionTitle>{outer.title}</SectionTitle>}
                                                    <Flex>
                                                        {outer.items
                                                            .filter((i) => !i.hidden)
                                                            .map((item: editableDetail, index: number) => {
                                                                return (
                                                                    <StyledFieldContainer key={index}>
                                                                        {renderEditable(item, values, renderFieldLabel, false, setFieldValue)}
                                                                    </StyledFieldContainer>
                                                                );
                                                            })}
                                                    </Flex>
                                                </Section>
                                            )
                                        );
                                    })}
                                </Container>
                                <Buttons>
                                    <Button
                                        width="auto"
                                        height="30px"
                                        type="button"
                                        buttonType="clean"
                                        textColor="#394372"
                                        onClick={() => onClose && onClose()}
                                    >
                                        <span>{localizations.cancel_btn}</span>
                                    </Button>
                                    <Button
                                        buttonType="filled"
                                        width="100px"
                                        height="30px"
                                        type="button"
                                        onClick={(e) => {
                                            handleSubmit();
                                        }}
                                    >
                                        {loader ? <Loader width="20px" marginTop="0px" showText={false} /> : <span>{localizations.save_btn}</span>}
                                    </Button>
                                </Buttons>
                            </Form>
                        </PageContainer>
                    );
                }}
            </Formik>
        </>
    );
};

const Buttons = styled.div`
    width: 100%;
    padding-top: 25px;
    display: flex;
    justify-content: flex-end;
    height: 55px;

    button {
        margin-right: 20px;
    }
`;
const StyledFieldContainer = styled.div`
    margin-bottom: 12px;
    flex: 1;
    :not(:last-child) {
        padding-right: 30px;
    }
`;
const PageContainer = styled.div`
    position: relative;
    width: 100%;
    height: 100%;
`;

const Label = styled.label`
    color: ${(props) => props.theme.colors.pageTitle};
`;

const MandatoryIndication = styled.span`
    color: red;
    margin-right: 7px;
`;
const Container = styled.div`
    position: relative;
    width: 100%;
    padding: 25px;
    padding-top: 0;
    padding-bottom: 0;
    flex: 1;
    overflow: overlay;
`;

const LabelContainer = styled.div`
    display: flex;
    margin-bottom: 5px;

    & img {
        margin-top: -2px;
        margin-left: 5px;
    }
`;
const Flex = styled.div`
    display: flex;
`;
const Section = styled.div``;
const SectionTitle = styled.div`
    font-size: 16px;
    color: ${(props) => props.theme.colors.pageTitle};
    font-weight: 500;
    margin: 5px 0;
`;
export default ProductForm;
