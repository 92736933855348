import React from 'react';
import styled from 'styled-components';
import { userInfo } from '../../../../../models/entities/user';
import { drawers } from '../../../../../state/ducks/header/header';
import { ColumnType } from '../../../../shared/Grid/types/Column';

const usersGridColumns = (setDrawerOpen: (open: drawers, params?: any) => void): Array<ColumnType<userInfo>> => [
    {
        field: 'firstName',
        width: '150px',
        sortable: true,
        bindedFields: ['lastName', 'id'],
        isDisplayed: true,
        textAlign: 'center',
        renderer: (cellValue: any, bindedData?: Array<any> | null) => (
            <FullName>
                {cellValue} {bindedData?.[0] || ''}
            </FullName>
        )
    },
    {
        field: 'companyName',
        width: '100%',
        sortable: true,
        textAlign: 'center',
        minWidth: '150px'
    },
    {
        field: 'phoneNumber',
        width: '100%',
        sortable: true,
        minWidth: '150px'
    },
    {
        field: 'email',
        width: '100%',
        minWidth: '150px',
        sortable: true
    },
    {
        field: 'role',
        width: '100%',
        sortable: true
    },
    {
        field: 'birthDate',
        width: '100px',
        columnType: 'date',
        sortable: false
    },
    {
        field: 'createdAt',
        width: '100px',
        columnType: 'date',
        sortable: true
    },
    {
        field: 'deletedAt',
        width: '100px',
        columnType: 'date',
        sortable: true,
        isDisplayed: false
    }
];

const FullName = styled.div`
    color: ${(props) => props.theme.colors.link};
`;

export default usersGridColumns;
