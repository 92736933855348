import React from 'react';
import { styled } from '../../shared/Theme/theme';

const FieldLabel = (props: any) => {
    return (
        <div>
            <LabelContainer>
                <Label>{props.children}</Label>
            </LabelContainer>
        </div>
    );
};

const LabelContainer = styled.div`
    display: flex;
    margin-bottom: 5px;

    & img {
        margin-top: -2px;
        margin-left: 5px;
    }
`;

const Label = styled.label`
    color: ${(props) => props.theme.colors.primaryBlue} !important;
`;

export default FieldLabel;
