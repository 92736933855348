import React from 'react';
import Grid from '../../shared/Grid/Grid';
import { purchaseOrder, settings } from '../../../models/entities/purchaseOrder/purchaseOrder';
import { ColumnType } from '../../shared/Grid/types/Column';
import { styled } from '../../shared/Theme/theme';
import Button from '../../shared/SmallComponents/Button';
import { PagesRoutes } from '../../../routing/PagesRoutes';
import { Link } from 'react-router-dom';
import { ePurchaseOrderFormMode } from '../singlePurchaseOrder/PurchaseOrderForm/PurchaseOrderFormConnected';
import ExportMenu from './components/ExportMenu';
import FilterPopover from './components/Filter/FilterPopover';
import FiltersStripe from './components/Filter/FiltersStripe';
import { exportTypes, paginationType } from '../../../state/ducks/allPurchaseOrdersPage/allPurchaseOrdersPage';
import TextInput from '../../shared/inputs/base/TextInput';
import { claimsDomainsType } from '../../../userClaims';
import claimsType from '../../../models/entities/claimsType';
import CZTooltip from '../../shared/CZTooltip/CZTooltip';
import Tooltip from '@material-ui/core/Tooltip';
import IconButton from '@material-ui/core/IconButton';
import Loader from '../../shared/SmallComponents/Loader';

type Props = {
    purchaseOrders: Array<purchaseOrder>;
    purchaseOrdersPage: Array<purchaseOrder>;
    activePurchaseOrdersCount: number;
    gridColumns: Array<ColumnType<purchaseOrder>>;
    onGridColumnClick?: (column: keyof purchaseOrder) => void;
    onFilter?: (text: string) => void;
    filterText?: string;
    gridSortedBy?: keyof purchaseOrder;
    gridSortDirection?: 'asc' | 'desc';
    exportDataTable: (type: exportTypes) => void;
    localization: any;
    showLoader?: boolean;
    settings?: settings;
    userSettingKey?: string;
    getUserClaims: (domain: claimsDomainsType) => claimsType;
    setCurrentPage: (page: number) => void;
    setRowsPerPage: (rowsPerPage: number) => void;
    fetchPurchaseOrders: () => void;
    filteredPOsCount: number;
    pagination: paginationType;
};

const PurchaseOrdersPage = ({
    purchaseOrders,
    purchaseOrdersPage,
    activePurchaseOrdersCount,
    gridColumns,
    onGridColumnClick,
    onFilter,
    filterText,
    gridSortedBy,
    gridSortDirection,
    localization,
    exportDataTable,
    showLoader,
    settings,
    userSettingKey,
    getUserClaims,
    setCurrentPage,
    setRowsPerPage,
    filteredPOsCount,
    pagination,
    fetchPurchaseOrders
}: Props) => {
    const { currentPage, rowsPerPage } = pagination;

    const posFrom = currentPage * rowsPerPage + 1;
    const posTo = (currentPage + 1) * rowsPerPage;

    const filterHandler = (text: string) => {
        if (onFilter) onFilter(text);
    };

    const showTopLoader = activePurchaseOrdersCount > 1 && !!gridColumns.length;

    return (
        <PageWrapper>
            {!!showLoader && showTopLoader && (
                <LoadingOverlay>
                    <Loader width="20px" marginTop="0" inline />
                </LoadingOverlay>
            )}
            <PageHeader>
                <Title>{localization.title}</Title>

                <FiltersStripe localization={localization} />

                <HeaderRightSide>
                    <Button
                        width="32px"
                        height="32px"
                        borderRadius="50%"
                        margin="7px"
                        buttonType="hollow"
                        onClick={() => {
                            fetchPurchaseOrders();
                        }}
                    >
                        <Tooltip title={'Refresh'} aria-label="refresh">
                            <span className="material-icons">refresh</span>
                        </Tooltip>
                    </Button>
                    <TextInput
                        style={{
                            height: '30px',
                            margin: '4px',
                            width: 'auto'
                        }}
                        allowClear={true}
                        onChange={(value: any) => filterHandler(value)}
                        placeHolder={localization.filterPlaceHolder}
                        value={filterText}
                    />
                    <FilterPopover localization={localization.filter} />
                    <ExportMenu
                        localization={localization}
                        exportDataTable={exportDataTable}
                        purchaseOrders={purchaseOrders}
                        gridColumns={gridColumns}
                    />

                    <CZTooltip text={localization.no_permission} placement={'bottom'} hidden={getUserClaims('po').create}>
                        <Link
                            to={
                                !getUserClaims('po').create
                                    ? {}
                                    : { pathname: PagesRoutes.NewPurchaseOrder, state: { mode: ePurchaseOrderFormMode.NEW } }
                            }
                        >
                            <Button
                                width="32px"
                                height="32px"
                                borderRadius="50%"
                                margin="7px"
                                buttonType="filled"
                                disabled={!getUserClaims('po').create}
                            >
                                <Tooltip title={localization.new_purchase_order} aria-label="add_po">
                                    <span className="material-icons">add</span>
                                </Tooltip>
                            </Button>
                        </Link>
                    </CZTooltip>
                    {(!showLoader || showTopLoader) && (
                        <NavButtonsContainer>
                            {filteredPOsCount > 0
                                ? `${posFrom} – ${filteredPOsCount <= posTo ? filteredPOsCount : posTo}${purchaseOrdersPage.length <= filteredPOsCount ? ' of ' + filteredPOsCount : ''
                                }`
                                : ''}
                            <IconButton
                                onClick={() => setCurrentPage(currentPage - 1)}
                                disabled={showLoader || posFrom <= 1}
                                className="material-icons nav-btn"
                            >
                                navigate_before
                            </IconButton>
                            <IconButton
                                onClick={() => setCurrentPage(currentPage + 1)}
                                disabled={showLoader || filteredPOsCount <= posTo}
                                className="material-icons nav-btn"
                            >
                                navigate_next
                            </IconButton>
                        </NavButtonsContainer>
                    )}
                </HeaderRightSide>
            </PageHeader>
            <GridWrapper>
                <Grid<purchaseOrder>
                    sortedByColumn={gridSortedBy}
                    sortDirection={gridSortDirection}
                    onSettingsClick={() => console.log('settings')}
                    onRowMoreOptionsClick={(row) => console.log(row)}
                    menuType="purchaseOrderMenu"
                    menuBindedFields={['CargoZoneNumber']}
                    columns={gridColumns}
                    data={purchaseOrdersPage}
                    onColumnClick={onGridColumnClick}
                    localization={localization.grid}
                    emptyStateText={localization.grid.emptyState}
                    showLoader={showLoader && !showTopLoader}
                    settings={settings}
                    userSettingKey={userSettingKey}
                />
            </GridWrapper>
        </PageWrapper>
    );
};

const PageWrapper = styled.div`
    height: 100%;
    display: flex;
    flex-direction: column;
`;

const GridWrapper = styled.div`
    flex: 1;
    max-width: 100%;
    width: 100%;
    box-sizing: border-box;
    height: 100%;
    width: 100%;
    z-index: 1;
    overflow: overlay;

    @media screen and (min-width: 768px) {
        height: calc(100% - 127px);
    }

    @media screen and (max-width: 768px) {
        height: calc(100% - 135px - 40px);
    }

    @media screen and (max-height: 400px) {
        height: calc(100% - 135px);
    }
`;

const PageHeader = styled.div`
    display: flex;
    padding: 20px 12px 0 12px;
    align-items: center;

    @media screen and (max-width: 768px) {
        justify-content: center;
    }

    position: sticky;
    top: 0px;
    z-index: 1000;
    background-color: #fff;
`;

const Title = styled.div`
    display: flex;
    color: ${(props) => props.theme.colors.pageTitle};
    font-size: ${(props) => props.theme.fontSizes.pageTitle};
    font-weight: bold;

    @media screen and (max-width: 768px) {
        display: none;
    }
`;

const HeaderRightSide = styled.div`
    display: flex;
    flex-direction: columm;
    width: 100%;
    max-width: fit-content;
    align-items: center;

    @media screen and (max-width: 768px) {
        height: 40px;
        justify-content: center;
    }

    .add-icon {
        display: none;
        @media screen and (max-width: 768px) {
            display: initial;
        }
    }

    .new-label {
        @media screen and (max-width: 768px) {
            display: none;
        }
    }
`;

const NavButtonsContainer = styled.div`
    display: flex;
    align-items: center;
    margin: 0 15px;
    color: ${(props) => props.theme.colors.primaryBlue};
    font-weight: bold;
    .nav-btn {
        font-size: 1.4rem;
        color: ${(props) => props.theme.colors.primaryBlue};
    }
`;

const LoadingOverlay = styled.div`
    background-color: #edf8f9;
    padding: 6px 10px;
    border-radius: 7px;
    position: absolute;
    z-index: 1012;
    top: 10px;
    left: 50%;
    transform: translateX(-50%);
`;

export default PurchaseOrdersPage;
