import { RootState } from '../../store/store';
import { createSelector } from 'reselect';
import orderBy from 'lodash/orderBy';
import { purchaseDemandsSelectors } from '../purchaseDemands/selectors';
import { purchaseDemand } from '../../../models/entities/purchaseDemand/purchaseDemand';
import filterPurchaseDemands from './purchaseDemandrsGridFilter';
import { numbersPattern } from '../../../utils/patterns';
import { gridSortCallback } from '../../../utils/sortArray';

const gridColumns = (state: RootState) => state.allPurchaseDemandsPage.gridColumns;
const fetchGridColumnsError = (state: RootState) => state.allPurchaseDemandsPage.fetchGridColumnsError;
const gridSort = (state: RootState) => state.allPurchaseDemandsPage.gridSort;
const gridFilter = (state: RootState) => state.allPurchaseDemandsPage.gridFilter;
const filterFields = (state: RootState) => state.allPurchaseDemandsPage.filterFields;
const pagination = (state: RootState) => state.allPurchaseDemandsPage.pagination;

const activeFiltersCount = createSelector(filterFields, (filterFields) => {
    if (!filterFields) return 0;
    return filterFields.length;
});

const activePurchaseDemandsCount = createSelector(purchaseDemandsSelectors.purchaseDemandsData, (purchaseDemandsData: Array<purchaseDemand>) => {
    return purchaseDemandsData.length;
});

const dashboardGridData = createSelector(purchaseDemandsSelectors.purchaseDemandsData, (purchaseDemandsData: Array<purchaseDemand>) => {
    return purchaseDemandsData.filter((pd) => pd.state !== 'CLOSED');
});

const gridData = createSelector(
    purchaseDemandsSelectors.purchaseDemandsData,
    gridSort,
    gridFilter,
    filterFields,
    (purchaseDemandsData: Array<purchaseDemand>, gridSort, gridFilter, filterFields) => {
        if (gridFilter && gridFilter !== '' && gridFilter.length > 2) {
            purchaseDemandsData = purchaseDemandsData.filter((s) => JSON.stringify(s).toLocaleLowerCase().includes(gridFilter.toLocaleLowerCase()));
        }

        if (filterFields) {
            purchaseDemandsData = filterPurchaseDemands(purchaseDemandsData, filterFields);
        }

        if (gridSort) {
            switch (gridSort.column) {
                default:
                    purchaseDemandsData = orderBy(purchaseDemandsData, gridSortCallback(gridSort.column), [gridSort.direction]);
            }
        }

        return purchaseDemandsData;
    }
);

const filteredPDsCount = createSelector(gridData, (gridData) => {
    return gridData?.length || 0;
});

const currentGridDataPage = createSelector(pagination, gridData, (pagination, gridData) => {
    const { currentPage, rowsPerPage } = pagination;

    return gridData.slice(currentPage * rowsPerPage, currentPage * rowsPerPage + rowsPerPage);
});

export { default as allPurchaseDemandsPageSelectors } from './selectors';

export default {
    gridColumns,
    fetchGridColumnsError,
    gridSort,
    gridFilter,
    filterFields,
    pagination,
    filteredPDsCount,
    currentGridDataPage,
    gridData,
    activeFiltersCount,
    dashboardGridData,
    activePurchaseDemandsCount
};
