import type { DraggableLocation } from 'react-beautiful-dnd';
import { IBoardItem, BoardColumnMap } from '../types/BoardColumn';

const reorder = (list: any[], startIndex: number, endIndex: number): any[] => {
    const result = Array.from(list);
    const [removed] = result.splice(startIndex, 1);
    result.splice(endIndex, 0, removed);

    return result;
};

export default reorder;

type ReorderColumnMapArgs<T extends IBoardItem> = {
    columnMap: BoardColumnMap<T>;
    source: DraggableLocation;
    destination: DraggableLocation;
};

export type ReorderColumnMapResult<T extends IBoardItem> = {
    columnMap: BoardColumnMap<T>;
};

export const reorderColumnMap = <T extends IBoardItem>({ columnMap, source, destination }: ReorderColumnMapArgs<T>): ReorderColumnMapResult<T> => {
    const current: T[] = [...columnMap[source.droppableId].items];
    const next: T[] = [...columnMap[destination.droppableId].items];
    const target: T = current[source.index];

    // moving to same list
    if (source.droppableId === destination.droppableId) {
        const reordered: T[] = reorder(current, source.index, destination.index);
        const result: BoardColumnMap<T> = {
            ...columnMap,
            [source.droppableId]: { ...columnMap[source.droppableId], items: reordered }
        };
        return {
            columnMap: result
        };
    }

    // moving to different list
    // remove from original
    current.splice(source.index, 1);
    // insert into next
    next.splice(destination.index, 0, target);

    const result: BoardColumnMap<T> = {
        ...columnMap,
        [source.droppableId]: { ...columnMap[source.droppableId], items: current },
        [destination.droppableId]: { ...columnMap[destination.droppableId], items: next }
    };

    return {
        columnMap: result
    };
};
