import { ModelConfig } from '@rematch/core';
import { isEqual } from 'lodash';
import { RootState } from '../../store/store';
export type headerMenus = 'USER' | null;
export type drawers =
    | 'NOTIFICATION'
    | 'TASK'
    | 'FOLLOW'
    | 'SHIPMENT'
    | 'CHAT'
    | 'CHAT_REQUESTS'
    | 'QUOTATION'
    | 'ADD_EDIT_USER'
    | 'ADD_EDIT_COMPANY'
    | 'PRODUCT'
    | 'INVENTORY'
    | 'TARIFF_QUOTATION'
    | string
    | null;
export type headerStateType = {
    menu: {
        open: headerMenus;
    };
    drawer: {
        open: drawers;
        emptyStateDrawers: drawers[];
        params?: any;
    };
    sideBarOpen: boolean;
    currentPageTitle: string | null;
    hasGoBackBtn: boolean;
    printMode: boolean;
};

export const header: ModelConfig<headerStateType> = {
    state: {
        menu: {
            open: null
        },
        drawer: {
            open: null,
            emptyStateDrawers: [],
            params: undefined
        },
        sideBarOpen: !(localStorage.getItem('SIDEBAR_OPEN') === 'false'),
        currentPageTitle: null,
        hasGoBackBtn: false,
        printMode: false
    },
    reducers: {
        setMenuOpen(state: headerStateType, open: headerMenus): headerStateType {
            let currState = { ...state.menu };
            if (currState.open === open) currState.open = null;
            else currState.open = open;
            return { ...state, menu: currState };
        },
        setDrawerOpen(state: headerStateType, open: drawers): headerStateType {
            return { ...state, drawer: setDrawerOpen(state, open) };
        },
        setDrawerOpenParams(state: headerStateType, { open, params }: { open: drawers; params?: any }): headerStateType {
            const currState = setDrawerOpen(state, open);
            currState.params = params;

            if (!currState.open && params && !isEqual(state.drawer.params, params)) return { ...state, drawer: { ...state.drawer, params } };
            return { ...state, drawer: currState };
        },
        setSideBarOpen(state: headerStateType, open: boolean): headerStateType {
            return { ...state, sideBarOpen: open };
        },
        setDrawerEmptyState(state: headerStateType, { drawer, isEmpty }: { drawer: drawers; isEmpty: boolean }): headerStateType {
            let currState = { ...state.drawer };
            let emptyStateDrawers = [...currState.emptyStateDrawers];

            const index = emptyStateDrawers.indexOf(drawer);
            if (!isEmpty) {
                if (index > -1) {
                    emptyStateDrawers.splice(index, 1);
                }
            } else {
                if (index <= -1) {
                    emptyStateDrawers.push(drawer);
                }
            }

            currState.emptyStateDrawers = emptyStateDrawers;
            return { ...state, drawer: currState };
        },
        setCurrentPageTitle(state: headerStateType, title: string | null): headerStateType {
            return { ...state, currentPageTitle: title };
        },
        setHasGoBackBtn(state: headerStateType, hasGoBackBtn: boolean): headerStateType {
            return { ...state, hasGoBackBtn };
        },
        setPrintMode(state: headerStateType, printMode: boolean): headerStateType {
            return { ...state, printMode };
        }
    },
    effects: (dispatch: any) => ({
        async getDrawerOpenState(_, state: RootState) {
            return state.header.drawer.open;
        }
    })
};
function setDrawerOpen(state: headerStateType, open: drawers) {
    let currState = { ...state.drawer };
    if (currState.open === open) currState.open = null;
    else currState.open = open;
    currState.params = undefined;
    return currState;
}
