import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import { notification } from '../../../models/entities/notification/notification';
import Loader from '../../shared/SmallComponents/Loader';
import NotificationsView from './components/NotificationsView';
import theme from '../../shared/Theme/theme';
import { drawers } from '../../../state/ducks/header/header';
import CZTooltip from '../../shared/CZTooltip/CZTooltip';
const notificationTheme = theme.colors.notifications;

type Props = {
    localization: any;
    groupedNotifications: Array<Array<notification>> | null;
    showLoader?: boolean;
    isAllNotificationsRead?: boolean;
    setNotificationRead: (notificationId: string, isRead: boolean) => void;
    setMultipleNotificationsRead: (notificationIds: string[], isRead: boolean) => void;
    setAllNotificationsRead: (isRead: boolean) => void;
    deleteNotification: (notificationId: string) => void;
    deleteMultipleNotifications: (notificationIds: string[]) => void;
    deleteAllNotifications: () => void;
    followShipment: (shipmentId: string) => void;
    unFollowShipment: (shipmentId: string) => void;
    followingShipments?: Array<string>;
    setDrawerEmptyState: (drawer: drawers, isEmpty: boolean) => void;
    setDrawerOpen: (open: drawers, params?: any) => void;
};

const NotificationsPage = ({
    localization,
    groupedNotifications,
    showLoader,
    isAllNotificationsRead,
    setNotificationRead,
    deleteNotification,
    deleteMultipleNotifications,
    setMultipleNotificationsRead,
    setAllNotificationsRead,
    deleteAllNotifications,
    followShipment,
    unFollowShipment,
    followingShipments,
    setDrawerEmptyState,
    setDrawerOpen
}: Props) => {
    setDrawerEmptyState('NOTIFICATION', !groupedNotifications?.length);
    return (
        <Container>
            {!!groupedNotifications?.length && (
                <HeaderContainer>
                    <Header>{localization.header.text}</Header>
                    <RightSide>
                        <MarkAllRead
                            onClick={() => {
                                setAllNotificationsRead(!isAllNotificationsRead);
                            }}
                        >
                            <CZTooltip text={!isAllNotificationsRead ? localization.header.markAllRead : localization.header.markAllUnread}>
                                {!isAllNotificationsRead ? (
                                    <ActionIcon className="material-icons">drafts</ActionIcon>
                                ) : (
                                    <ActionIcon className="material-icons">markunread</ActionIcon>
                                )}
                            </CZTooltip>
                        </MarkAllRead>
                        <RemoveAll onClick={deleteAllNotifications}>
                            <ActionIcon className="material-icons">delete</ActionIcon>
                        </RemoveAll>
                    </RightSide>
                </HeaderContainer>
            )}
            {showLoader ? (
                <Loader />
            ) : (
                <NotificationsView
                    localization={localization}
                    groupedNotifications={groupedNotifications}
                    setNotificationRead={setNotificationRead}
                    setMultipleNotificationsRead={setMultipleNotificationsRead}
                    deleteMultipleNotifications={deleteMultipleNotifications}
                    deleteNotification={deleteNotification}
                    followShipment={followShipment}
                    unFollowShipment={unFollowShipment}
                    followingShipments={followingShipments}
                    setDrawerOpen={setDrawerOpen}
                />
            )}
        </Container>
    );
};

const Container = styled.div`
    display: flex;
    flex-direction: column;
    height: 100%;
    padding: 0 10px;
    padding-top: 48px;
`;
const HeaderContainer = styled.div`
    display: flex;
    border-bottom: 1px solid ${notificationTheme.page.headerBorderColor};
    justify-content: space-between;
    margin-bottom: 10px;
`;
const Header = styled.div`
    color: ${notificationTheme.page.headerColor};
    font-size: 16px;
    font-weight: bold;
`;
const RightSide = styled.div`
    display: flex;
    align-items: center;
`;
const MarkAllRead = styled.div``;
const RemoveAll = styled.div``;

const ActionIcon = styled.span`
    color: ${notificationTheme.page.markAllReadColor};
    cursor: pointer;
    margin-right: 5px;
    font-size: 20px;
    transition: 0.1s;
    &:hover {
        color: #5a5a5a;
    }
`;
export default NotificationsPage;
