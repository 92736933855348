import React from 'react';
import edit from '../../../../static/icons/edit.svg';
import trash from '../../../../static/icons/trash.svg';
import CellTrigger from '../../Menu/MenuTriggers/CellTrigger';
import { RootState } from '../../../../state/store/store';
import { connect } from 'react-redux';
import Menu from '../../Menu/Menu';
import MenuItem from '../../Menu/MenuItem';
import { productsSelectors } from '../../../../state/ducks/products/selectors';
type onRowMoreOptionClickType = () => void;
type deleteProductAttributeType = (productId: string, attributeId: string, attributeOptionId: string) => void;
type Props = {
    entityId: string;
    localization: any;
    onRowMoreOptionsClick?: onRowMoreOptionClickType;
    deleteProductAttributeOption: deleteProductAttributeType;
    parentEntityId?: string;
    currentProductId: string | null;
};

const ProductAttributeOptionMenu = ({
    entityId,
    localization,
    onRowMoreOptionsClick,
    deleteProductAttributeOption,
    parentEntityId,
    currentProductId
}: Props) => {
    const menuItems = [
        <MenuItem onClick={() => onRowMoreOptionsClick && onRowMoreOptionsClick()} icon={edit} text={localization.edit} />,
        <MenuItem
            onClick={() => currentProductId && parentEntityId && deleteProductAttributeOption(currentProductId, parentEntityId, entityId)}
            icon={trash}
            text={localization.delete}
        />
    ];

    return <Menu options={menuItems} MenuTrigger={CellTrigger} strStyle="display: flex;align-items: center;width: 100%;height: 100%;" />;
};

const mapStateToProps = (state: RootState) => ({
    currentProductId: productsSelectors.currentProductId(state)
});

const mapDispatchToProps = (dispatch: any) => ({
    deleteProductAttributeOption: (productId: string, attributeId: string, attributeOptionId: string) =>
        dispatch.products.deleteProductAttributeOption({ productId, attributeId, attributeOptionId })
});

export default connect(mapStateToProps, mapDispatchToProps)(ProductAttributeOptionMenu);
