import * as Yup from 'yup';

export const validationSchema = (localization: any) => {
    const requiredFieldMessage = localization.required_field_message;
    return Yup.object().shape({
        productId: Yup.string().nullable().required(requiredFieldMessage),
        cost: Yup.number().nullable(),
        costCurrency: Yup.string().nullable(),
        warehouseId: Yup.string().nullable(),
        location: Yup.string().nullable().test('len', localization.max_length_message(200), maxLengthValidation(200)),
        actionType: Yup.string().nullable().required(requiredFieldMessage).test('len', localization.max_length_message(25), maxLengthValidation(25)),
        quantity: Yup.number().nullable().required(requiredFieldMessage),
        reason: Yup.string().nullable().test('len', localization.max_length_message(200), maxLengthValidation(200))
    });
};

const maxLengthValidation = (maxLength: number) => {
    return (val: any) => (val ? val.toString().length <= maxLength : true);
};
