import React from 'react';
import { connect } from 'react-redux';
import { product } from '../../../../../models/entities/product/product';
import { localizationSelectors } from '../../../../../state/ducks/localization/selectors';
import { productsSelectors } from '../../../../../state/ducks/products/selectors';
import { RootState } from '../../../../../state/store/store';
import CustomModal, { ModalTriggerProps } from '../../../../shared/Modal/Modal';
import Button from '../../../../shared/SmallComponents/Button';
import ProductForm from '../Form/ProductFormConnected';
import ProductsModalContent from './ProductsModalContent';

export type productGridRow = product & { quantity: number; selectedAttributes?: { [key: string]: string } };

type Props = {
    title: string;
    products: Array<product>;
    getProductsByFilter: (predicate: (p: product) => boolean) => Array<product>;
    fetchProducts: () => void;
    isLoadingProducts: boolean;
    onSubmitProductsCallBack?: (selectedProducts: productGridRow[]) => void;
    onCancelCallback?: () => void;
    Trigger: (props: ModalTriggerProps) => JSX.Element;
    localization: any;
    isOpened?: boolean;
    showAttributesModal?: boolean;
    showQuantities?: boolean;
    supplierId?: string;
    companyId?: string;
    filterProductsFn?: (product: product, index?: number) => boolean;
    productsFetched?: boolean;
};
const ProductsModal = ({
    title,
    products,
    fetchProducts,
    isLoadingProducts,
    onCancelCallback,
    Trigger,
    onSubmitProductsCallBack,
    localization,
    isOpened,
    showAttributesModal,
    showQuantities,
    getProductsByFilter,
    supplierId,
    companyId,
    filterProductsFn,
    productsFetched
}: Props) => {
    return (
        <CustomModal
            title={{ text: title }}
            Trigger={Trigger}
            isOpen={isOpened}
            HeaderContent={(setVisible?: React.Dispatch<React.SetStateAction<boolean>>) => {
                return (
                    <CustomModal
                        title={{ text: 'Create Product' }}
                        Trigger={(props) => (
                            <Button onClick={props.onClick} width="auto" buttonType="filled" padding="4px 12px" type="button">
                                <span>{localization.add_product}</span>
                            </Button>
                        )}
                        Content={(
                            setVisible?: React.Dispatch<React.SetStateAction<boolean>>,
                            setConfirm?: React.Dispatch<React.SetStateAction<boolean>>
                        ) => {
                            return (
                                <div style={{ height: 500 }}>
                                    <ProductForm
                                        mode={'NEW'}
                                        initSupplierId={supplierId}
                                        onClose={(id) => {
                                            if (setVisible) setVisible(false);
                                        }}
                                    />
                                </div>
                            );
                        }}
                        width="800px"
                        height={700}
                    />
                );
            }}
            Content={(setVisible?: React.Dispatch<React.SetStateAction<boolean>>, setConfirm?: React.Dispatch<React.SetStateAction<boolean>>) => {
                const productsArray = getProductsByFilter((p: product) => {
                    if (supplierId && companyId) return p.supplierId === supplierId && p.companyId === companyId;

                    if (supplierId) return p.supplierId === supplierId;

                    if (companyId) return p.companyId === companyId;

                    return filterProductsFn?.(p) || true;
                });
                return (
                    <ProductsModalContent
                        products={productsArray}
                        fetchProducts={fetchProducts}
                        isLoadingProducts={isLoadingProducts}
                        openedFromModal={setVisible ? { setVisible } : undefined}
                        onSubmitProductsCallBack={onSubmitProductsCallBack}
                        localization={localization}
                        showAttributesModal={showAttributesModal}
                        showQuantities={showQuantities}
                        supplierId={supplierId}
                        productsFetched={productsFetched}
                    />
                );
            }}
            width="800px"
            onCancelCallback={onCancelCallback}
        />
    );
};

const mapStateToProps = (state: RootState) => ({
    products: productsSelectors.productsData(state),
    getProductsByFilter: (predicate: (p: product) => boolean) => productsSelectors.getProductsByFilter(state)(predicate),
    localization: localizationSelectors.products(state),
    isLoadingProducts: !!state.loading.effects.products.fetchProductsWithAttributes
});

const mapDispatchToProps = (dispatch: any) => ({
    fetchProducts: () => dispatch.products.fetchProductsWithAttributes()
});

export default connect(mapStateToProps, mapDispatchToProps)(ProductsModal);
