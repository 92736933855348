import BaseController from '../..';
import IProductHistory from './IProductHistory';

export default class ProductHistory extends BaseController implements IProductHistory {
    constructor(baseUrl: string) {
        super(baseUrl);
    }
    async fetchProductHistoryById(productId: string) {
        const response = await this.client.get(`/product/history/${productId}`);
        return response;
    }
}
