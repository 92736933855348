import React from 'react';
import { shipmentSubType, shipmentType } from '../../../../../models/entities/shipment/shipment';
import { shipmentContainer } from '../../../../../models/entities/shipment/shipmentContainer';
import { shipmentPackage } from '../../../../../models/entities/shipment/shipmentPackage';
import { styled } from '../../../../shared/Theme/theme';
import PackagesTable from '../../../singleShipment/sections/shipmentPackages/PackagesTable';

type Props = {
    packages: Array<shipmentPackage>;
    containers?: Array<shipmentContainer>;
    type: shipmentType;
    sub_type: shipmentSubType;
    localization: any;
};

const Packages = ({ packages, type, sub_type, localization, containers }: Props) => {
    return <PackagesTable localization={localization} type={type} sub_type={sub_type} packages={packages} containers={containers} />;
};

export default Packages;
