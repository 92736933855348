import React from 'react';
import styled from 'styled-components';
import theme from '../../Theme/theme';
import { TriggerProps } from '../Menu';

const triggersTheme = theme.colors.menu.triggers;

const CellTrigger = (props: TriggerProps) => {
    return (
        <MenuDisplay isOpen={props.isOpen} onClick={(e) => props.onClick(e)}>
            <span className="material-icons">{props.icon || 'more_vert'}</span>
        </MenuDisplay>
    );
};

interface MenuDisplayProps {
    isOpen: boolean;
}

const MenuDisplay = styled.div<MenuDisplayProps>`
    display: flex;
    justify-content: center;
    height: 100%;
    width: 100%;
    cursor: pointer;
    font-size: 11px;
    ${(props) => {
        if (props.isOpen) return `color: white;background: ${triggersTheme.cellTriggerBG};z-index:100;`;
        else return `color: ${triggersTheme.cellTriggerBG};background: inherit;`;
    }}
    position: relative;

    & span {
        user-select: none;
        display: block;
        line-height: inherit;
        padding-top: 9px;
    }
`;

export default CellTrigger;
