import React, { useEffect, useRef, useState } from 'react';
import { documentTypes } from '../../../../../models/entities/shipment/shipment';
import { styled } from '../../../../shared/Theme/theme';
import { Form, Formik } from 'formik';
import Button from '../../../../shared/SmallComponents/Button';
import { ModalFooter, ModalFormContainer } from '../../../../shared/Modal/Modal';
import Loader from '../../../../shared/SmallComponents/Loader';
import { document } from '../../../../../models/entities/common_subentities/document';
import { FormDatePicker, FormDropDown, FormTextInput, FormUploadInput } from '../../../../shared/inputs/form';
import FileExtIcon from '../../../../shared/Grid/specialColumns/FileExtIcon';

export enum formMode {
    NEW,
    EDIT
}

interface formFields {
    type?: string | string[];
    comment?: string;
    file?: File;
    fileType?: string;
    fileName?: string;
    expDate?: Date;
}
interface formFieldsErrors {
    type?: string | string[];
    comment?: string;
    file?: string;
}
type Props<entityType, settingsType> = {
    entity?: entityType;
    mode: formMode;
    localizations?: any;
    settings?: settingsType;
    openedFromModal?: {
        setVisible: React.Dispatch<React.SetStateAction<boolean>>;
    };
    confirmation?: { setConfirm: React.Dispatch<React.SetStateAction<boolean>> };
    createMultipleDocument: (entityId: string, documents: Array<document>) => void;
    showLoader?: boolean;
    typeMode?: 'multiple' | 'tags';
    optionalFields?: {
        type: boolean;
        comment: boolean;
    };
};

function MultipleDocumentFormConnected<entityType extends { id: string }, settingsType extends { documentTypes?: documentTypes } = any>({
    entity,
    localizations,
    mode,
    settings,
    openedFromModal,
    confirmation,
    createMultipleDocument,
    showLoader,
    typeMode,
    optionalFields
}: Props<entityType, settingsType>) {
    const [submitLoading] = useState(false);
    const [openFileInput, setOpenFileInput] = useState(true);
    const fileInputRef = React.createRef<HTMLInputElement>();
    useEffect(() => {
        if (openFileInput) {
            fileInputRef.current?.click();
            setOpenFileInput(false);
        }
    }, [openFileInput]);
    const documentTypesOptions = settings?.documentTypes?.map((item) => ({
        text: item.name,
        value: item.code
    }));
    const emptyDocument: () => formFields = () => {
        const obj: formFields = {
            file: undefined,
            expDate: undefined,
            fileType: '',
            fileName: ''
        } as formFields;
        if (optionalFields?.type) obj.type = '';
        if (optionalFields?.comment) obj.comment = '';
        return obj;
    };
    const setInitialValues = () => {
        const documents: Array<formFields> = [];
        return {
            documents,
            temp_file_upload: undefined
        };
    };
    const validate = (values: any) => {
        if (confirmation) {
            if (JSON.stringify(values) === JSON.stringify(setInitialValues())) {
                confirmation.setConfirm(false);
            } else {
                confirmation.setConfirm(true);
            }
        }

        const errors: any = {};
        if (values.documents.length === 0) errors.temp_file_upload = localizations.documents.form.required_error;
        values.documents.map((item: formFieldsErrors, index: number) => {
            if (optionalFields?.type && !item.type) {
                createEmptyErrors(errors, index);
                errors.documents[index].type = localizations.documents.form.required_error;
            }
            if (!item.file) {
                createEmptyErrors(errors, index);
                errors.documents[index].file = localizations.documents.form.required_error;
            }
        });
        return errors;
    };
    const submit = (values: any) => {
        if (entity) {
            if (mode === formMode.NEW) {
                createMultipleDocument(entity.id, values.documents);
                openedFromModal && openedFromModal.setVisible(false);
            }
        }
    };
    return (
        <Formik initialValues={setInitialValues()} validate={validate} onSubmit={submit}>
            {({ values, setFieldValue }) => {
                return (
                    <Form>
                        <Container>
                            {values.documents.map((item, index) => {
                                return (
                                    <DocumentContainer key={index}>
                                        <FileExtIcon filename={item.file?.name || ''} width="auto" />
                                        {optionalFields?.type && (
                                            <FieldContainer>
                                                <FormDropDown
                                                    minWidth="160px"
                                                    name={`documents[${index}][type]`}
                                                    placeHolder={localizations.documents.form.documenttype_placeholder}
                                                    options={documentTypesOptions || []}
                                                    mode={typeMode}
                                                    showSearch
                                                />
                                            </FieldContainer>
                                        )}
                                        {optionalFields?.comment && (
                                            <FieldContainer>
                                                <FormTextInput
                                                    name={`documents[${index}][comment]`}
                                                    placeHolder={localizations.documents.form.comment_placeholder}
                                                />
                                            </FieldContainer>
                                        )}
                                        <FieldContainer>
                                            <FormDatePicker name={`documents[${index}][expDate]`} placeHolder={'exp'} showTime />
                                        </FieldContainer>
                                        <span
                                            onClick={() => {
                                                const documents = [...values.documents];
                                                documents.splice(index, 1);
                                                setFieldValue('documents', documents);
                                            }}
                                            style={{ flexBasis: '0', cursor: 'pointer' }}
                                            className="material-icons"
                                        >
                                            delete_outline
                                        </span>
                                    </DocumentContainer>
                                );
                            })}
                            <FieldContainer style={{ marginTop: '15px' }}>
                                <FormUploadInput
                                    asButton={true}
                                    name="temp_file_upload"
                                    placeHolder={localizations.documents.form.addfile_placeholder}
                                    id="file-upload"
                                    multiple
                                    onChange={(files: FileList) => {
                                        Array.from(files).forEach((file) => {
                                            const documents = values.documents;
                                            documents.push({ ...emptyDocument(), file, fileType: file.type, fileName: file.name });
                                            setFieldValue('documents', documents);
                                        });
                                    }}
                                    ref={fileInputRef}
                                />
                            </FieldContainer>
                        </Container>
                        {openedFromModal && (
                            <FormFooter>
                                <Button minWidth="55px" buttonType="filled" type="submit">
                                    {submitLoading ? (
                                        <Loader width="20px" marginTop="0px" showText={false} />
                                    ) : (
                                        <span>{localizations.documents.modal.submit_btn}</span>
                                    )}
                                </Button>
                            </FormFooter>
                        )}
                    </Form>
                );
            }}
        </Formik>
    );
}

function createEmptyErrors(errors: any, index: number) {
    if (!errors.documents) errors.documents = [];
    if (!errors.documents[index]) errors.documents[index] = {};
}
const FieldContainer = styled.div`
    margin-bottom: 12px;
`;

const Container = styled(ModalFormContainer)``;

const FormFooter = styled(ModalFooter)`
    position: absolute;
    bottom: 0;
    width: 100%;
    left: 0;
    right: 0;
    background: white;
    justify-content: flex-end;
`;
const DocumentContainer = styled.div`
    display: flex;
    border: 1px solid #e2e2e2;
    padding: 15px;
    justify-content: space-between;
    align-items: center;
    margin: 10px 0;
    border-radius: 5px;
    > * {
        margin: 0 10px;
        flex-basis: 30%;
    }
`;
MultipleDocumentFormConnected.defaultProps = {
    optionalFields: {
        type: true,
        comment: true
    }
};
export default MultipleDocumentFormConnected;
