import IOrder from './IOrder';
import { order } from '../../../models/entities/order/order';
import BaseController from '..';
import {
    createOrderPayload,
    updateOrderPayload,
    bulkUpdateOrdersStatePayload,
    updateOrderStatePayload,
    bulkDeleteOrdersPayload
} from './../../../models/entities/order/payloads';
import OrderItems from './items/orderItems';
import OrderEvents from './events/orderEvents';
import OrderAlerts from './alerts/orderAlerts';
import OrderDocuments from './documents/orderDocuments';
import OrderDiscussions from './discussions/orderDiscussions';
import OrderFollowers from './followers/orderFollowers';
import OrderHistory from './history/orderHistory';

export default class Order extends BaseController implements IOrder {
    private orderItems: OrderItems;
    private orderEvents: OrderEvents;
    private orderAlerts: OrderAlerts;
    private orderDocuments: OrderDocuments;
    private orderDiscussions: OrderDiscussions;
    private orderFollowers: OrderFollowers;
    private orderHistory: OrderHistory;

    constructor(baseUrl: string) {
        super(baseUrl);
        this.orderItems = new OrderItems(baseUrl);
        this.orderEvents = new OrderEvents(baseUrl);
        this.orderAlerts = new OrderAlerts(baseUrl);
        this.orderDocuments = new OrderDocuments(baseUrl);
        this.orderDiscussions = new OrderDiscussions(baseUrl);
        this.orderFollowers = new OrderFollowers(baseUrl);
        this.orderHistory = new OrderHistory(baseUrl);
    }

    async fetchOrders(): Promise<Array<order>> {
        const response = await this.client.get('/orders');
        return response;
    }

    async fetchOrderById(orderId: string): Promise<order> {
        const response = await this.client.get(`/orders/${orderId}`);
        return response;
    }

    async createOrder(payload: createOrderPayload): Promise<order> {
        const response = await this.client.post('/orders', JSON.stringify(payload));
        return response;
    }

    async updateOrder(payload: updateOrderPayload): Promise<order> {
        const response = await this.client.put('/orders', JSON.stringify(payload));
        return response;
    }

    async bulkUpdateOrdersState(payload: bulkUpdateOrdersStatePayload): Promise<order[]> {
        const response = await this.client.put('/orders/bulkUpdateState', JSON.stringify(payload));
        return response;
    }

    async updateOrderState(payload: updateOrderStatePayload): Promise<order> {
        const response = await this.client.put('/orders/state', JSON.stringify(payload));
        return response;
    }

    async bulkDeleteOrders(payload: bulkDeleteOrdersPayload): Promise<number> {
        const response = await this.client.delete('/orders/bulkDelete', JSON.stringify(payload));
        return response;
    }

    items() {
        return this.orderItems;
    }

    events() {
        return this.orderEvents;
    }

    alerts() {
        return this.orderAlerts;
    }

    documents() {
        return this.orderDocuments;
    }

    discussions() {
        return this.orderDiscussions;
    }

    followers() {
        return this.orderFollowers;
    }

    history() {
        return this.orderHistory;
    }
}
