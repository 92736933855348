import React, { useState } from 'react';
import { styled } from '../../../shared/Theme/theme';
import Loader from '../../../shared/SmallComponents/Loader';
import { ColumnType, specialColumnType } from '../../../shared/Grid/types/Column';
import Grid from '../../../shared/Grid/Grid';
import Button from '../../../shared/SmallComponents/Button';
import CustomModal, { ModalTriggerProps } from '../../../shared/Modal/Modal';
import clock from '../../../../static/icons/clock.svg';
import { settings, shipment } from '../../../../models/entities/shipment/shipment';
import { formMode } from './AccountingForm/AccountingFormConnected';
import { featureFlagsModel } from '../../../../models/entities/businessPartner/settings';
import { billingLine } from '../../../../models/entities/common_subentities/billingLine';
import { shipment_po } from '../../../../models/entities/shipment_po';
import mockData from '../../../../sideEffects/shipments/settings/mockData';
import { DropDown } from '../../../shared/inputs/base';
import { render } from 'react-dom';
import renderStateOption from '../../../shared/SmallComponents/StateOption';

type Props<entityType, settingsType> = {
    entity: entityType;
    updateShipmentAccountingState: (shipmentId: string, shipmentAccountingState: string) => Promise<void>;
    billingLines?: Array<billingLine>;
    localization: any;
    settings?: settingsType;
    menuType: specialColumnType;
    AccountingFormConnected: any;
    printMode?: boolean;
    featureFlags?: featureFlagsModel;
};

type AccountingStateOption = {
    text: string;
    value: string;
    color: string;
};

function BillingLines<entityType extends shipment, settingsType extends settings>({
    entity,
    updateShipmentAccountingState,
    billingLines,
    localization,
    menuType,
    AccountingFormConnected,
    settings,
    printMode,
    featureFlags
}: Props<entityType, settingsType>) {
    const serviceTypeName = (serviceType: string) => {
        const serviceName = mockData.en.billingLineServiceType.find((service) => service.code === serviceType)?.name;
        return serviceName || serviceType;
    };

    const calculateTotalProfitByCurrency = () => {
        const totals: { [key: string]: number } = {};

        billingLines?.forEach((line) => {
            if (totals[line.currency]) {
                totals[line.currency] += Number(line.profit) || 0;
            } else {
                totals[line.currency] = Number(line.profit) || 0;
            }
        });

        return totals;
    };
    const [selectedState, setSelectedState] = useState<string | undefined>(entity.accountingState);
    const [loading, setLoading] = useState<boolean>(false);

    const handleAccountingStateChange = async (selectedValue: string) => {
        try {
            setLoading(true);
            await updateShipmentAccountingState(entity.id, selectedValue);
            setSelectedState(selectedValue);
        } catch (error) {
            console.error('Failed to update accounting state:', error);
        } finally {
            setLoading(false);
        }
    };

    const mockColumns: Array<ColumnType<billingLine>> = [
        {
            field: 'id',
            sortable: false,
            isDisplayed: false
        },
        {
            field: 'serviceType',
            sortable: false,
            isDisplayed: true,
            renderer: (cellValue, bindedData) => {
                return <div>{serviceTypeName(cellValue)}</div>;
            }
        },
        {
            field: 'currency',
            sortable: true,
            isDisplayed: true
        },
        {
            field: 'income',
            sortable: true,
            isDisplayed: true
        },
        {
            field: 'expense',
            sortable: true,
            isDisplayed: true
        },
        {
            field: 'totalCost',
            sortable: true,
            isDisplayed: true
        },
        {
            field: 'relativeCost',
            sortable: false,
            isDisplayed: true,
            renderer: (cellValue: any, bindData?: any[] | null | undefined) => {
                return <div>{cellValue}%</div>;
            }
        },
        {
            field: 'date',
            sortable: false,
            isDisplayed: true,
            renderer: (cellValue: any, bindData?: any[] | null | undefined) => {
                const date = new Date(cellValue);
                const formattedDate =
                    date.toLocaleDateString('en-US', {
                        year: 'numeric',
                        month: 'long',
                        day: 'numeric'
                    }) +
                    ' at ' +
                    date.toLocaleTimeString('en-US', {
                        hour: '2-digit',
                        minute: '2-digit',
                        hour12: false
                    });

                return <div>{formattedDate}</div>;
            }
        },
        {
            field: 'remarks',
            sortable: false,
            isDisplayed: true
        },
        {
            field: 'profit',
            sortable: false,
            isDisplayed: true,
            renderer: (cellValue: number, bindData) => {
                let color = 'black';
                cellValue < 0 ? (color = 'red') : cellValue > 0 ? (color = 'green') : (color = 'black');
                return <div style={{ color: color }}>{cellValue}</div>;
            }
        }
    ];
    const [modalVisible, setVisible] = useState(false);
    const [billingLine, setBillingLine] = useState({} as billingLine);
    return (
        <BillingLinesWrapper>
            {billingLines ? (
                <>
                    {!featureFlags?.UNDER_FORWARDER && !printMode ? (
                        <CustomModal
                            title={{ text: localization.billing_lines.modal.add_title }}
                            Trigger={(props: ModalTriggerProps) => (
                                <Container>
                                    {billingLines.length > 0 ? (
                                        <CenteredDropdownWrapper>
                                            <DropDown
                                                options={
                                                    settings?.accountingState
                                                        ?.sort((a, b) => a.displayOrder - b.displayOrder)
                                                        .map((item) => ({
                                                            key: item.code,
                                                            text: item.name,
                                                            value: item.code,
                                                            color: item.color
                                                        })) || []
                                                }
                                                width="90%"
                                                placeHolder="Accounting State"
                                                optionRenderer={renderStateOption}
                                                onChange={handleAccountingStateChange}
                                                value={selectedState}
                                            />
                                        </CenteredDropdownWrapper>
                                    ) : (
                                        <></>
                                    )}
                                    <Button onClick={props.onClick} width="auto" buttonType="hollow" padding="4px 12px">
                                        {localization.billing_lines.add_billingline}
                                    </Button>
                                </Container>
                            )}
                            Content={(
                                setVisible?: React.Dispatch<React.SetStateAction<boolean>>,
                                setConfirm?: React.Dispatch<React.SetStateAction<boolean>>
                            ) => {
                                return (
                                    <AccountingFormConnected
                                        entity={entity}
                                        mode={formMode.NEW}
                                        openedFromModal={setVisible ? { setVisible } : undefined}
                                        confirmation={setConfirm ? { setConfirm } : undefined}
                                        settings={settings}
                                        localization={localization}
                                    />
                                );
                            }}
                            width="393px"
                        />
                    ) : (
                        <h2>Billing Lines</h2>
                    )}

                    {loading ? (
                        <Loader />
                    ) : billingLines.length > 0 ? (
                        <GridWrapper>
                            <Grid<billingLine>
                                columns={mockColumns}
                                data={billingLines}
                                localization={localization.events.grid}
                                onSettingsClick={() => console.log('settings')}
                                onRowMoreOptionsClick={(row) => {
                                    setBillingLine(row);
                                    setVisible(true);
                                }}
                                menuType={menuType}
                                settings={settings}
                            />

                            {modalVisible && (
                                <CustomModal
                                    title={{ text: localization.billing_lines.modal.edit_title }}
                                    isOpen={true}
                                    Content={(
                                        setModalVisible?: React.Dispatch<React.SetStateAction<boolean>>,
                                        setConfirm?: React.Dispatch<React.SetStateAction<boolean>>
                                    ) => {
                                        return (
                                            <AccountingFormConnected
                                                entity={entity}
                                                mode={formMode.EDIT}
                                                billingLine={billingLine}
                                                openedFromModal={{ setVisible }}
                                                confirmation={setConfirm ? { setConfirm } : undefined}
                                                settings={settings}
                                                localization={localization}
                                            />
                                        );
                                    }}
                                    onCancelCallback={() => {
                                        setVisible(false);
                                        setBillingLine({} as billingLine);
                                    }}
                                    width="393px"
                                />
                            )}
                            {billingLines && billingLines.length > 0 && (
                                <TotalCostWrapper>
                                    {Object.entries(calculateTotalProfitByCurrency()).map(([currency, total]) => (
                                        <span style={{ color: total > 0 ? 'green' : total < 0 ? 'red' : 'black', marginRight: '10px' }}>
                                            <strong>
                                                Total {currency}: {total}
                                            </strong>
                                        </span>
                                    ))}
                                </TotalCostWrapper>
                            )}
                        </GridWrapper>
                    ) : (
                        <EmaptyStateText>{localization.billing_lines.empty_state}</EmaptyStateText>
                    )}
                </>
            ) : (
                <Loader />
            )}
        </BillingLinesWrapper>
    );
}

const GridWrapper = styled.div`
    flex: 0 0 100%;
    max-width: 100%;
    width: 100%;
    box-sizing: border-box;
    overflow-y: overlay;
    height: 100%;
    width: 100%;
`;

const BillingLinesWrapper = styled.div`
    display: flex;
    flex-direction: column;
    width: 100%;
`;

const EmaptyStateText = styled.span`
    color: ${(props) => props.theme.colors.emptyStateText};
`;
const Container = styled.div`
    position: relative; /* Required for absolute positioning */
    display: flex;
    align-items: center;
    justify-content: flex-end; /* Keep the button on the right */
    padding-top: 8px;
    width: 100%;
`;

const CenteredDropdownWrapper = styled.div`
    position: absolute;
    left: 55%; /* Move to the center */
    transform: translateX(-50%); /* Center the dropdown horizontally */
    width: 50%;
    display: flex;
    justify-content: center;
`;

const SmallLoader = styled(Loader)`
    width: 24px; /* Set a smaller width */
    height: 24px; /* Set a smaller height */
    margin: 0; /* Remove any margin */
`;

const TotalCostWrapper = styled.div`
    padding: 10px;
    text-align: left;
`;

export default BillingLines;
