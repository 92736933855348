import { createSelector } from 'reselect';
import { tariff } from '../../../models/entities/tariff/tariff';
import { RootState } from '../../store/store';

const tariffsData = (state: RootState) => state.tariffs.tariffs;
const isFetchingTariffs = (state: RootState) => !!state.loading.effects.tariffs.fetchTariffs;
const pagination = (state: RootState) => state.tariffs.pagination;
const gridFilter = (state: RootState) => state.tariffs.gridFilter;
const filterFields = (state: RootState) => state.tariffs.filterFields;
const isCreatingShipmentTariffQuotations = (state: RootState) => !!state.loading.effects.tariffs.createShipmentTariffQuotations;

const gridData = createSelector(tariffsData, gridFilter, filterFields, (tariffsData: Array<tariff>, gridFilter, filterFields) => {
    if (gridFilter && gridFilter !== '' && gridFilter.length > 2) {
        tariffsData = tariffsData.filter((s) => JSON.stringify(s).toLocaleLowerCase().includes(gridFilter.toLocaleLowerCase()));
    }
    return tariffsData;
});

const activeFilter = createSelector(filterFields, (filterFields) => {
    return filterFields?.find((f: { field: string; value: string }) => f.field === 'isActive');
});

const activeFiltersCount = createSelector(filterFields, (filterFields) => {
    if (!filterFields) return 0;
    return filterFields.length;
});

const activeTariffsCount = createSelector(tariffsData, (tariffsData: Array<tariff>) => {
    return tariffsData.length;
});

const filteredTariffsCount = createSelector(gridData, (gridData) => {
    return gridData?.length || 0;
});

const currentGridDataPage = createSelector(pagination, gridData, (pagination, gridData) => {
    const { currentPage, rowsPerPage } = pagination;

    return gridData.slice(currentPage * rowsPerPage, currentPage * rowsPerPage + rowsPerPage);
});

export { default as tariffsSelectors } from './selectors';

const selectors = {
    gridData,
    activeFilter,
    activeFiltersCount,
    activeTariffsCount,
    filteredTariffsCount,
    currentGridDataPage,
    isFetchingTariffs,
    pagination,
    tariffsData,
    isCreatingShipmentTariffQuotations
};

export default selectors;
