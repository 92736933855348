import React from 'react';
import styled from 'styled-components';
import { deletedRecord } from '../../../../models/entities/admin/deletedRecord';
import DeletedRecordsPageHeader from './components/DeletedRecordsPageHeader';
import DeletedRecordsGrid from './components/DeletedRecordsGrid';

type Props = {
    fetchDeletedRecords: () => Promise<void>;
    restoreEntity: (entityId: string, entityType: string) => Promise<void>;
    deleteEntity: (entityId: string, entityType: string) => Promise<void>;
    deletedRecords: deletedRecord[];
    localization: any;
    showLoader: boolean;
    setGridFilter: (text: string) => void;
    gridFilter?: string;
};
const DeletedRecordsPage = ({
    fetchDeletedRecords,
    deletedRecords,
    localization,
    showLoader,
    restoreEntity,
    setGridFilter,
    gridFilter,
    deleteEntity
}: Props) => {
    return (
        <PageWrapper>
            <HeaderWrapper>
                <DeletedRecordsPageHeader
                    fetchDeletedRecords={fetchDeletedRecords}
                    localization={localization}
                    setGridFilter={setGridFilter}
                    gridFilter={gridFilter}
                />
            </HeaderWrapper>
            <ContainersGridWrapper>
                <DeletedRecordsGrid
                    data={deletedRecords}
                    showLoader={showLoader}
                    localization={localization}
                    restoreEntity={restoreEntity}
                    deleteEntity={deleteEntity}
                />
            </ContainersGridWrapper>
        </PageWrapper>
    );
};

const PageWrapper = styled.div`
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
`;
const HeaderWrapper = styled.div`
    width: 100%;
    padding: 20px 30px;
`;
const ContainersGridWrapper = styled.div`
    flex: 1;
`;

export default DeletedRecordsPage;
