import React from 'react';
import { FormikProps } from 'formik';
import { enumToDropdownOptions, Option } from '../../../../shared/inputs/base/DropDown';
import FormInputWrapper from '../../../../shared/Form/FormInputWrapper';
import { FormAutoComplete, FormCheckbox, FormDatePicker, FormDropDown, FormTextInput, NumberUnitInput } from '../../../../shared/inputs/form';
import { eIncoterms } from '../../../../../models/entities/shipment/incoterms';
import carriers from '../../../../../static/data/carriers.json';
import { ePaymentTerms } from '../../../../../models/entities/businessPartner/paymentTerms';
import { orderPartyType } from '../../../../../models/entities/order/orderParty';
import { businessPartner } from '../../../../../models/entities/businessPartner/businessPartner';
import { OrderFormValues, PartyFormValues } from '../OrderForm';

type Props = {
    formikProps: FormikProps<OrderFormValues>;
    allowedCompaniesDropDownOptions: Option[];
    getBusinessPartnerById: (id: string) => businessPartner | undefined;
    getBusinessPartnersDropDownOptionsByTypes: (types: string[]) => Option[];
    localization: any;
};

const General = ({
    formikProps,
    allowedCompaniesDropDownOptions,
    localization,
    getBusinessPartnerById,
    getBusinessPartnersDropDownOptionsByTypes
}: Props) => {
    const fieldsLocalization = localization.fields;
    const { values, setValues, setFieldValue } = formikProps;

    const incotermsDropDownOptions = enumToDropdownOptions(eIncoterms);
    const paymentTermsDropDownOptions = enumToDropdownOptions(ePaymentTerms);

    const customersOptions = getBusinessPartnersDropDownOptionsByTypes(['CUSTOMER']);
    const pickupOptions = getBusinessPartnersDropDownOptionsByTypes(['WAREHOUSE']);
    const payerOptions = getBusinessPartnersDropDownOptionsByTypes(['CUSTOMER']);
    const carrierOptions = getBusinessPartnersDropDownOptionsByTypes(['FORWARDER_BROKER']);

    const onChangeBusinessPartner = (id: string, type: orderPartyType, additionalValues?: Partial<OrderFormValues>) => {
        const businessPartner = getBusinessPartnerById(id);
        const newValues = { ...values, [`${type.toLowerCase()}BusinessPartnerId`]: id, ...additionalValues };

        if (businessPartner) {
            const { addresses, contacts } = businessPartner;
            const defaultAddress = addresses?.find((address) => !!address.isDefault) || addresses?.[0];
            const defaultContact = contacts?.[0];

            const newParty: PartyFormValues = {
                type,
                businessPartnerId: id,
                name: businessPartner.name,
                addresses,
                addressId: defaultAddress?.id?.toString(),
                country: defaultAddress?.country,
                city: defaultAddress?.city,
                address: defaultAddress?.address || '',
                contacts: contacts,
                contactId: defaultContact?.id,
                contactName: defaultContact?.name,
                phoneNumber: defaultContact?.phoneNumber,
                email: defaultContact?.email
            };

            if (defaultAddress) {
                if (type === 'CUSTOMER') {
                    newValues.customerCountry = defaultAddress.country;
                    newValues.customerCity = defaultAddress.city;
                    newValues.customerAddress = defaultAddress.address || '';
                } else if (type === 'PICKUP') {
                    newValues.pickupCountry = defaultAddress.country;
                    newValues.pickupCity = defaultAddress.city;
                    newValues.pickupAddress = defaultAddress.address;
                }
            }
            (newValues.parties[type] as PartyFormValues) = newParty;

            setValues(newValues);
        }
    };
    return (
        <>
            <FormInputWrapper fieldName="companyId" localization={fieldsLocalization} isMandatory>
                <FormDropDown
                    name="companyId"
                    style={{ width: '200px', marginRight: '15px' }}
                    placeHolder={fieldsLocalization.companyId.placeholder}
                    options={allowedCompaniesDropDownOptions}
                    onChange={(companyId: string) => {
                        const company = allowedCompaniesDropDownOptions.find((c) => c.value === companyId)!;
                        setFieldValue('companyName', company.text);
                    }}
                />
            </FormInputWrapper>
            <FormInputWrapper fieldName="orderDate" localization={fieldsLocalization} isMandatory>
                <FormDatePicker name="orderDate" style={{ width: '200px' }} placeHolder={fieldsLocalization.orderDate.placeholder} showTime />
            </FormInputWrapper>
            <FormInputWrapper fieldName="customerId" localization={fieldsLocalization} isMandatory>
                <FormDropDown
                    name="customerBusinessPartnerId"
                    style={{ width: '200px' }}
                    placeHolder={fieldsLocalization.customerId.placeholder}
                    options={customersOptions}
                    showSearch
                    onChange={(customerBusinessPartnerId: string) => {
                        onChangeBusinessPartner(customerBusinessPartnerId, 'CUSTOMER');
                    }}
                />
            </FormInputWrapper>
            <FormInputWrapper fieldName="pickupId" localization={fieldsLocalization} isMandatory>
                <FormDropDown
                    name="pickupBusinessPartnerId"
                    style={{ width: '200px' }}
                    placeHolder={fieldsLocalization.pickupId.placeholder}
                    options={pickupOptions}
                    showSearch
                    onChange={(pickupBusinessPartnerId: string) => {
                        onChangeBusinessPartner(pickupBusinessPartnerId, 'PICKUP');
                    }}
                />
            </FormInputWrapper>
            <FormInputWrapper fieldName="payerId" localization={fieldsLocalization}>
                {!values.payerSameAsCustomer && (
                    <FormDropDown
                        name="payerBusinessPartnerId"
                        style={{ width: '200px' }}
                        placeHolder={fieldsLocalization.payerId.placeholder}
                        options={payerOptions}
                        showSearch
                        onChange={(payerBusinessPartnerId: string) => {
                            onChangeBusinessPartner(payerBusinessPartnerId, 'PAYER');
                        }}
                        allowClear
                    />
                )}
                <FormCheckbox
                    style={{ marginTop: 3 }}
                    name="payerSameAsCustomer"
                    onChange={(e) => {
                        const { checked } = e.target;
                        setValues({
                            ...values,
                            payerSameAsCustomer: checked,
                            payerBusinessPartnerId: undefined,
                            parties: { ...values.parties, PAYER: undefined }
                        });
                    }}
                >
                    {fieldsLocalization.payerSameAsCustomer.title}
                </FormCheckbox>
            </FormInputWrapper>
            <FormInputWrapper fieldName="dueDate" localization={fieldsLocalization}>
                <FormDatePicker name="dueDate" style={{ width: '200px' }} placeHolder={fieldsLocalization.dueDate.placeholder} showTime />
            </FormInputWrapper>
            <FormInputWrapper fieldName="poDate" localization={fieldsLocalization}>
                <FormDatePicker name="poDate" style={{ width: '200px' }} placeHolder={fieldsLocalization.poDate.placeholder} showTime />
            </FormInputWrapper>
            <FormInputWrapper fieldName="eta" localization={fieldsLocalization}>
                <FormDatePicker name="eta" style={{ width: '200px' }} placeHolder={fieldsLocalization.eta.placeholder} showTime />
            </FormInputWrapper>
            <FormInputWrapper fieldName="invoiceNumber" localization={fieldsLocalization}>
                <FormTextInput name="invoiceNumber" style={{ width: '200px' }} placeHolder={fieldsLocalization.invoiceNumber.placeholder} />
            </FormInputWrapper>
            <FormInputWrapper fieldName="incoterms" localization={fieldsLocalization}>
                <FormDropDown
                    name="incoterms"
                    style={{ width: '200px', marginRight: '15px' }}
                    placeHolder={fieldsLocalization.incoterms.placeholder}
                    options={incotermsDropDownOptions}
                    allowClear
                />
            </FormInputWrapper>
            <FormInputWrapper fieldName="trackingNumber" localization={fieldsLocalization}>
                <FormTextInput name="trackingNumber" style={{ width: '200px' }} placeHolder={fieldsLocalization.trackingNumber.placeholder} />
            </FormInputWrapper>
            <FormInputWrapper fieldName="carrier" localization={fieldsLocalization}>
                <FormDropDown
                    name="carrierBusinessPartnerId"
                    style={{ width: '200px', marginRight: '15px' }}
                    placeHolder={fieldsLocalization.carrier.placeholder}
                    options={carrierOptions}
                    allowClear
                />
            </FormInputWrapper>
            <FormInputWrapper fieldName="weight" localization={fieldsLocalization}>
                <NumberUnitInput
                    numberInputName="weight"
                    numberPlaceholder={fieldsLocalization.weight.title}
                    numberInputWidth="120px"
                    unitInputWidth="80px"
                    min={0}
                    step={0.001}
                    unitInputName="weightUnit"
                    unitPlaceholder={fieldsLocalization.weightUnit.title}
                    unitOptions={[
                        { value: 'KG', text: 'KG' },
                        { value: 'LB', text: 'LB' }
                    ]}
                />
            </FormInputWrapper>
            <FormInputWrapper fieldName="volume" localization={fieldsLocalization}>
                <NumberUnitInput
                    numberInputName="volume"
                    numberPlaceholder={fieldsLocalization.volume.title}
                    numberInputWidth="120px"
                    unitInputWidth="80px"
                    min={0}
                    step={0.001}
                    unitInputName="volumeUnit"
                    unitPlaceholder={fieldsLocalization.volumeUnit.title}
                    unitOptions={[
                        { value: 'CBM', text: 'CBM' },
                        { value: 'CFT', text: 'CFT' }
                    ]}
                />
            </FormInputWrapper>
            <FormInputWrapper fieldName="volume" localization={fieldsLocalization}>
                <FormAutoComplete
                    name="paymentTerms"
                    style={{ width: '200px' }}
                    options={paymentTermsDropDownOptions}
                    placeHolder={fieldsLocalization.paymentTerms.title}
                    showSearch
                    allowClear
                />
            </FormInputWrapper>
        </>
    );
};

export default General;
