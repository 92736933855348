import React from 'react';
import styled from 'styled-components';
import CustomModal, { ModalTriggerProps } from '../../../../shared/Modal/Modal';
import ProductView from '../ProductView';

type Props = {
    productId: string;
    onCancelCallback?: () => void;
    Trigger: (props: ModalTriggerProps) => JSX.Element;
};

const ProductModal = ({ productId, onCancelCallback, Trigger }: Props) => {
    return (
        <CustomModal
            Trigger={Trigger}
            Content={(setVisible?: React.Dispatch<React.SetStateAction<boolean>>, setConfirm?: React.Dispatch<React.SetStateAction<boolean>>) => {
                return (
                    <ContentContainer>
                        <ProductView params={{ productId }} padding="20px 0 20px 0" />
                    </ContentContainer>
                );
            }}
            contentPadding="0px"
            width="700px"
            onCancelCallback={onCancelCallback}
        />
    );
};

const ContentContainer = styled.div`
    max-height: 600px;
    height: 600px;
`;

export default ProductModal;
