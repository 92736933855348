import React from 'react';
import { purchaseOrderFollower } from '../../../../../models/entities/purchaseOrder/purchaseOrderFollower';
import { styled } from '../../../../shared/Theme/theme';
import UserBadge from '../../../../shared/UserBadge/UserBadge';

type Props = {
    follower: purchaseOrderFollower;
};

const PurchaseOrderFollower = ({ follower }: Props) => {
    return (
        <Follower>
            <UserBadge userName={follower.userName} hideBadgeName={true} borderColor="#f7f7f7" width={30} userId={follower.userId} />
        </Follower>
    );
};
const Follower = styled.div`
    margin-left: 2px;
`;
export default PurchaseOrderFollower;
