import React, { useState } from 'react';
import { styled } from '../../../shared/Theme/theme';
import { paymentDate, poOverview, purchaseOrder } from '../../../../models/entities/purchaseOrder/purchaseOrder';
import countries from '../../../../utils/countries-flags/countries';
import Button from '../../../shared/SmallComponents/Button';
import { Formik } from 'formik';
import { Form } from 'formik-antd';
import Loader from '../../../shared/SmallComponents/Loader';
import EditIcon from '../../../../static/icons/edit.svg';
import EntityDetails from '../../../shared/EntityDetails/EntityDetails';
import { RootState } from '../../../../state/store/store';
import { connect } from 'react-redux';
import { detailsData } from './overviewUtils';
import { isEmpty } from 'lodash';
import PoOverviewHeader from './PoOverviewHeader';
import { supplierUpdatesUpdatePoPayload } from '../../../../models/entities/purchaseOrder/payloads';
import { purchaseOrdersSelectors } from '../../../../state/ducks/purchaseOrders/selectors';

type Props = {
    supplierUpdatesUpdatePo: (payload: supplierUpdatesUpdatePoPayload) => Promise<void>;
    updatePurchaseOrderOverview: (id: string, poOverview: poOverview) => void;
    updatePurchaseOrderPaymentDates: (id: string, paymentDates: Array<paymentDate>) => void;
    updatePaymentDatesByPaymentTermPrecentage: (purchaseOrderId: string) => Promise<void>;
    supplierUpdatesConfirmPo: (purchaseOrderId: string) => Promise<void>;
    isPoStateAfterOrEqual: (purchaseOrderId: string, checkState: string) => boolean;
    showLoader: boolean;
    printMode?: boolean;
    purchaseOrder: purchaseOrder;
    localization: any;
    settings?: any;
    isSupplierUpdates?: boolean;
};

const PurchaseOrderOverview = ({
    supplierUpdatesUpdatePo,
    updatePurchaseOrderOverview,
    updatePurchaseOrderPaymentDates,
    updatePaymentDatesByPaymentTermPrecentage,
    supplierUpdatesConfirmPo,
    isPoStateAfterOrEqual,
    showLoader,
    purchaseOrder,
    localization,
    settings,
    printMode,
    isSupplierUpdates
}: Props) => {
    const vendor = purchaseOrder.parties?.find((p) => p.businessPartnerId === purchaseOrder.vendorId);
    const consignee = purchaseOrder.parties?.find(
        (p) => p.businessPartnerId === purchaseOrder.consigneeId || (purchaseOrder.consigneeSameAsCompany && p.companyId === purchaseOrder.companyId)
    );

    const originCountryName = countries.find((item) => item.code === purchaseOrder.originCountry)?.name;
    const destinationCountryName = countries.find((item) => item.code === purchaseOrder.destinationCountry)?.name;

    const [detailsEdit, setDetailsEdit] = useState(false);

    let initialFormValues: any = {
        requestedGoodsReady: purchaseOrder.requestedGoodsReady,
        expectedReadyDate: purchaseOrder.expectedReadyDate,
        actualGoodsReady: purchaseOrder.actualGoodsReady,
        taxesRate: purchaseOrder.taxesRate,
        paymentTerms: isSupplierUpdates ? undefined : purchaseOrder.paymentTerms ? [purchaseOrder.paymentTerms] : undefined,
        paymentDates: isSupplierUpdates ? undefined : purchaseOrder.paymentDates,
        isAsap: purchaseOrder.isAsap,
        note: isSupplierUpdates ? undefined : purchaseOrder.note,
        supplierNote: isSupplierUpdates ? purchaseOrder.supplierNote : undefined
    };

    const submit = async (values: any) => {
        const poOverviewValues = {
            ...values,
            paymentTerms: values.paymentTerms && values.paymentTerms?.length > 0 ? values?.paymentTerms[0] : null
        };

        if (isSupplierUpdates) {
            await supplierUpdatesUpdatePo(poOverviewValues);
        } else {
            await updatePurchaseOrderOverview(purchaseOrder.id, poOverviewValues);
        }

        setDetailsEdit(false);
    };

    const validate = (values: any) => {
        const errors: any = {};
        if (!errors.paymentDates) errors.paymentDates = [];
        values.paymentDates?.forEach(() => {
            errors.paymentDates.push({});
        });
        values.paymentDates?.forEach((item: paymentDate, index: number) => {
            const { date, amount, currency } = item;

            if (!date) {
                errors.paymentDates[index].date = 'Required';
            }
        });
        if ((errors.paymentDates as Array<any>)?.every((p) => isEmpty(p))) delete errors.paymentDates;
        return errors;
    };

    const renderDetails = (obj: object) =>
        Object.entries(obj)
            ?.filter(([key, value]) => !!value && !['id', 'type', 'address'].some((p) => key.toLowerCase().includes(p)))
            .map(([key, value], index) => {
                if (key === 'logoUrl' && value)
                    return (
                        <DetailWrapper>
                            <ImgLogo src={value} alt="Supplier logo" width={40} />
                        </DetailWrapper>
                    );
                return (
                    <DetailWrapper>
                        {key !== 'name' && <DetailHeader>{localization[key] || key}: </DetailHeader>}
                        {value}
                    </DetailWrapper>
                );
            });

    return (
        <OverviewWrapper>
            <PoOverviewHeader
                purchaseOrder={purchaseOrder}
                localization={localization}
                isSupplierUpdates={isSupplierUpdates}
                supplierUpdatesConfirmPo={supplierUpdatesConfirmPo}
                isPoStateAfterOrEqual={isPoStateAfterOrEqual}
            />
            {!isSupplierUpdates && (
                <OverviewHeaderWrapper>
                    <CompanySectionWrapper>
                        <SectionHeader>Supplier Details</SectionHeader>
                        {!!vendor && renderDetails(vendor)}
                        <DetailWrapper>
                            {[purchaseOrder.originPickupAddress, purchaseOrder.originZipCode, originCountryName].filter((c) => !!c).join(', ')}
                        </DetailWrapper>
                    </CompanySectionWrapper>
                    <SectionsSeparator />
                    <CompanySectionWrapper>
                        <SectionHeader>Ship to</SectionHeader>
                        {!!consignee && renderDetails(consignee)}
                        <DetailWrapper>
                            {[purchaseOrder.destinationPickupAddress, purchaseOrder.destinationZipCode, destinationCountryName]
                                .filter((c) => !!c)
                                .join(', ')}
                        </DetailWrapper>
                    </CompanySectionWrapper>
                </OverviewHeaderWrapper>
            )}

            <DetailsWrapperContainer>
                {!printMode && (
                    <DetailsEditButton onClick={() => setDetailsEdit(!detailsEdit)}>
                        <Button margin="5px" buttonType={detailsEdit ? 'filled' : 'hollow'}>
                            <img src={EditIcon} alt={localization.edit} />
                        </Button>
                    </DetailsEditButton>
                )}

                <Formik initialValues={initialFormValues} onSubmit={submit} validate={validate} enableReinitialize>
                    {({ values, setFieldValue, errors }) => {
                        return (
                            <Form>
                                <DetailsWrapper>
                                    <EntityDetails
                                        detailsData={detailsData(
                                            purchaseOrder,
                                            values,
                                            setFieldValue,
                                            localization,
                                            detailsEdit,
                                            errors,
                                            updatePurchaseOrderPaymentDates,
                                            updatePurchaseOrderOverview,
                                            updatePaymentDatesByPaymentTermPrecentage,
                                            undefined,
                                            isSupplierUpdates
                                        )}
                                        detailsEdit={detailsEdit}
                                        values={values}
                                    />
                                    {detailsEdit && (
                                        <div>
                                            <Button buttonType="filled" type="submit">
                                                {!!showLoader ? (
                                                    <Loader width="20px" marginTop="0px" showText={false} />
                                                ) : (
                                                    <span>{localization.save_btn || 'Save'}</span>
                                                )}
                                            </Button>
                                        </div>
                                    )}
                                </DetailsWrapper>
                            </Form>
                        );
                    }}
                </Formik>
            </DetailsWrapperContainer>
        </OverviewWrapper>
    );
};

const OverviewWrapper = styled.div`
    display: flex;
    flex-direction: column;
    width: 100%;
`;

const OverviewHeaderWrapper = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    width: 100%;
    border-bottom: solid 1px #e0e0e0;
    padding: 24px 18px 18px 48px;
`;

const CompanySectionWrapper = styled.div`
    flex: 1;
    max-width: 320px;
`;

const DetailWrapper = styled.div`
    color: #394372;

    margin: 4px 0;
    &:first-child {
        margin-top: 0;
    }
`;

const DetailsWrapperContainer = styled.div`
    position: relative;
`;
const DetailsEditButton = styled.div`
    display: inline-block;
    position: absolute;
    right: 0;
`;

const DetailHeader = styled.span`
    font-weight: bolder;
`;

const SectionHeader = styled.div`
    color: #394372;
    font-weight: bolder;
    border-bottom: 1px solid #394372;
    width: fit-content;
    padding: 0 5px;
`;

const SectionsSeparator = styled.div`
    width: 1px;
    border-right: solid 1px #e0e0e0;
    margin: 0 18px;
`;

const DetailsWrapper = styled.div`
    display: flex;
    flex-direction: column;
    padding: 24px 18px 18px 48px;
`;

const ImgLogo = styled.img`
    max-width: 200px;
`;

const mapStateToProps = (state: RootState) => ({
    showLoader: !!state.loading.effects.purchaseOrders.updatePurchaseOrderOverview || !!state.loading.effects.purchaseOrders.supplierUpdatesUpdatePo,
    isPoStateAfterOrEqual: (purchaseOrderId: string, checkState: string) =>
        purchaseOrdersSelectors.isPoStateAfterOrEqual(state)(purchaseOrderId, checkState)
});

const mapDispatchToProps = (dispatch: any) => ({
    updatePurchaseOrderOverview: (id: string, poOverview: poOverview) => dispatch.purchaseOrders.updatePurchaseOrderOverview({ id, poOverview }),
    updatePurchaseOrderPaymentDates: (id: string, paymentDates: Array<paymentDate>) =>
        dispatch.purchaseOrders.updatePurchaseOrderPaymentDates({ id, paymentDates }),
    updatePaymentDatesByPaymentTermPrecentage: (id: string) => dispatch.purchaseOrders.updatePaymentDatesByPaymentTermPrecentage(id),
    supplierUpdatesUpdatePo: (payload: supplierUpdatesUpdatePoPayload) => dispatch.purchaseOrders.supplierUpdatesUpdatePo(payload),
    supplierUpdatesConfirmPo: (purchaseOrderId: string) => dispatch.purchaseOrders.supplierUpdatesConfirmPo(purchaseOrderId)
});

export default connect(mapStateToProps, mapDispatchToProps)(PurchaseOrderOverview);
