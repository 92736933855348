import React from 'react';
import { styled } from '../../shared/Theme/theme';
import Grid, { GridProps } from '../../shared/Grid/Grid';
import { ColumnType } from '../../shared/Grid/types/Column';
import CZTooltip, { QuestionToolTip } from '../../shared/CZTooltip/CZTooltip';

type Props<T> = GridProps<T> & {
    columns: Array<ColumnType<T>>;
    data?: Array<T>;
    localization: any;
    width?: string;
};

function GridSection<T>({ data, localization, columns, width, ...restProps }: Props<T>) {
    return (
        <>
            {data?.length ? (
                <GridWrapper width={width}>
                    <Header>
                        <h3>
                            {localization.title} {`${data?.length > 0 ? ` (${data?.length})` : ''}`}
                        </h3>
                        <CZTooltip text={localization.tool_tip}>{QuestionToolTip}</CZTooltip>
                    </Header>

                    <Grid<T>
                        {...restProps}
                        columns={columns}
                        data={data}
                        localization={localization.grid}
                        emptyStateText={localization.grid.emptyState}
                    />
                </GridWrapper>
            ) : (
                <> </>
            )}
        </>
    );
}
interface WrapperProps {
    width?: string;
}

const Header = styled.div`
    display: flex;

    @media screen and (max-width: 768px) {
        margin: 0 auto;
        width: fit-content;
    }
`;
const GridWrapper = styled.div<WrapperProps>`
    border-right: 1px solid #e0e0e0;
    padding: 0 30px 30px 30px;
    width: ${(props) => props.width ?? 'auto'};
    flex: 1;
    max-height: 500px;
    overflow: auto;
    h3 {
        color: #415a8a;
    }
    @media screen and (max-width: 1200px) {
        width: 100%;
        max-width: 100%;
        max-height: 100%;
        border: none;
    }
    @media screen and (max-width: 768px) {
        width: 100%;
        max-height: 100%;
        border: none;
        padding: 0 0 50px 0;
    }
`;
export default GridSection;
