import React from 'react';
import { EntryComponentProps } from '@draft-js-plugins/mention/lib/MentionSuggestions/Entry/Entry';
import styled from 'styled-components';
import ShipmentTypeWithTooltip from '../../SmallComponents/ShipmentTypeWithTooltip';
import ShipmentRoute from '../../SmallComponents/ShipmentRoute';
import { shipment } from '../../../../models/entities/shipment/shipment';
import { entityMentionType } from './utils';

const EntityMentionEntryComponent = (props: EntryComponentProps) => {
    const { mention, isFocused, id, theme, ...parentProps } = props;

    const poContent = (
        <>
            <span className="detail">#{mention.name}</span>
        </>
    );
    const pdContent = (
        <>
            <span className="detail">#{mention.name}</span>
        </>
    );
    const shipmentContent = (
        <>
            <span className="type-icon">
                <ShipmentTypeWithTooltip iconWidth={24} typeCode={mention.typeCode} subTypeCode={mention.subTypeCode} />
            </span>

            <span className="detail">#{mention.name}</span>
            <span className="detail">
                <ShipmentRoute
                    entity={mention as unknown as shipment}
                    flagSize="18px"
                    fontSize="14px"
                    fontWeight="300px"
                    arrowStyle={{ margin: '9px' }}
                />
            </span>
            {mention.clientRef && (
                <span className="detail text-details">
                    {' '}
                    | {mention.clientRef} {mention.commodity ? ` | ${mention.commodity}` : ''}
                </span>
            )}
        </>
    );

    const getMentionContentByType = (() => {
        switch (mention.entityType as entityMentionType) {
            case 'shipment':
                return shipmentContent;
            case 'po':
                return poContent;
            case 'pd':
                return pdContent;

            default:
                return <div></div>;
        }
    })();
    return (
        <MentionContainer className={theme?.mentionSuggestionsEntryContainer} {...parentProps}>
            {getMentionContentByType}
        </MentionContainer>
    );
};

export default EntityMentionEntryComponent;

const MentionContainer = styled.div`
    display: flex;
    align-items: center;
    padding: 7px 10px !important;
    .detail {
        margin-left: 10px;
    }
    .text-details {
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
    }
`;
