import React, { useEffect } from 'react';
import styled from 'styled-components';
import { SectionTitle } from '../Shared';
import { task } from '../../../../models/entities/task';
import Task from './Task';
import Loader from '../../../shared/SmallComponents/Loader';
import AddTaskForm from './AddTaskForm';
import CustomModal, { ModalTriggerProps } from '../../../shared/Modal/Modal';
import taskIcon from '../../../../static/icons/task.svg';
import { drawers } from '../../../../state/ducks/header/header';

type Props = {
    tasks: Array<task> | null;
    isFetching: boolean;
    updateTask: (task: task) => void;
    deleteTask: (taskId: string) => void;
    localization: any;
    highlightedTaskId?: string;
    setDrawerEmptyState: (drawer: drawers, isEmpty: boolean) => void;
};

const TasksStrip = ({ tasks, updateTask, deleteTask, isFetching, localization, setDrawerEmptyState, highlightedTaskId }: Props) => {
    useEffect(() => {
        if (!isFetching && !tasks?.length) {
            setDrawerEmptyState('TASK', true);
        } else {
            setDrawerEmptyState('TASK', false);
        }
    }, [tasks, isFetching, setDrawerEmptyState]);
    const renderLoader = () => {
        return <Loader />;
    };

    const renderTasks = () => {
        if (tasks?.length) {
            return tasks?.map((task) => (
                <Task
                    updateTask={updateTask}
                    deleteTask={deleteTask}
                    localization={localization}
                    key={task.id}
                    task={task}
                    isHighlighted={task.id === highlightedTaskId}
                    isFetching={isFetching}
                />
            ));
        }

        return renderEmptyState();
    };

    const render = () => {
        if (isFetching) {
            return renderLoader();
        }

        return renderTasks();
    };

    const renderEmptyState = () => {
        return (
            <EmptyStateContainer>
                <EmptyStateTitle>{localization.empty_state.title}</EmptyStateTitle>
                <EmptyStateSubTitle>{localization.empty_state.sub_title}</EmptyStateSubTitle>
            </EmptyStateContainer>
        );
    };
    return (
        <Conatiner>
            <StyledSectionTitle>
                <Title>
                    <TaskIcon>
                        <img width="21" src={taskIcon} alt="task" />
                    </TaskIcon>
                    {localization.title}
                </Title>
                <CustomModal
                    title={{ text: localization.modal.add_title }}
                    Trigger={(props: ModalTriggerProps) => (
                        <AddTaskButton onClick={props.onClick}>
                            <span className="material-icons">add</span>
                            {localization.add_task}
                        </AddTaskButton>
                    )}
                    Content={(
                        setVisible?: React.Dispatch<React.SetStateAction<boolean>>,
                        setConfirm?: React.Dispatch<React.SetStateAction<boolean>>
                    ) => {
                        return (
                            <AddTaskForm
                                openedFromModal={setVisible ? { setVisible } : undefined}
                                confirmation={setConfirm ? { setConfirm } : undefined}
                            />
                        );
                    }}
                    width="393px"
                />
            </StyledSectionTitle>
            <TasksContainer>{render()}</TasksContainer>
        </Conatiner>
    );
};

const StyledSectionTitle = styled(SectionTitle)`
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0 32px;
    height: 30px;
`;
const TasksContainer = styled.div`
    padding-bottom: 15px;
    height: calc(100% - 30px);
    overflow-y: overlay;
`;
const Title = styled.span`
    display: flex;
    align-items: center;
`;

const Conatiner = styled.div`
    flex: 0 380px;
    overflow-y: overlay;
    height: 100%;
    padding-top: 48px;
`;

const TaskIcon = styled.span`
    margin-right: 5px;
`;

const AddTaskButton = styled.button`
    width: auto;
    height: 100%;
    padding: 4px 12px;

    color: ${(props) => props.theme.colors.primaryBlue};
    cursor: pointer;
    background-color: #fff;
    border: none;

    font-size: 12px;
    text-align: center;
    line-height: 0;
    min-height: 30px;
    display: flex;
    justify-content: center;
    align-items: center;

    &:focus {
        outline: none;
    }

    @media screen and (max-width: 768px) {
        & span {
            display: none;
        }
    }

    span.material-icons {
        font-size: 12px;
    }
`;

const EmptyStateContainer = styled.div`
    margin-top: 24px;
`;
const EmptyStateTitle = styled.div`
    font-size: 16px;
    color: ${(props) => props.theme.colors.primaryBlue};
    word-break: break-word;
`;
const EmptyStateSubTitle = styled.div`
    margin-top: 18px;
    font-size: 15px;
`;
export default TasksStrip;
