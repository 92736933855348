import React, { useState } from 'react';
import { meanBy, range } from 'lodash';
import moment from 'moment';
import { AreaChart, Area, XAxis, YAxis, CartesianGrid, Tooltip, ReferenceLine, ResponsiveContainer } from 'recharts';
import styled from 'styled-components';
import { orderFilterFields } from '../../../../../state/ducks/orders/gridFilters/types';
import { useHistory } from 'react-router-dom';
import { PagesRoutes } from '../../../../../routing/PagesRoutes';

type Props = {
    localization: any;
    ordersAmountByMonth: { [month: string]: number };
    setOrdersFilters: (filter: orderFilterFields) => void;
};

const OrdersAmountByMonth = ({ localization, ordersAmountByMonth, setOrdersFilters }: Props) => {
    const history = useHistory();

    const [[year, half], setYearHalf] = useState<[number, 0 | 1]>([moment().year(), 0]);

    const months = range(!half ? 0 : 6, !half ? 6 : 12).map((m) => moment([year, m]).format('MM/YY'));
    const ordersAmountByMonthArr = months.map((month) => ({ month, amount: ordersAmountByMonth[month] || 0 }));
    const ordersByMonthAvg = meanBy(ordersAmountByMonthArr, (o) => o.amount);

    const longestLabelLength = ordersAmountByMonthArr
        .map((c: any) => `${c.amount}`)
        .reduce((acc: number, cur: string) => (cur.length > acc ? cur.length : acc), 0);
    return (
        <ResponsiveContainer width="100%" height="100%">
            <AreaChart
                style={{ cursor: 'pointer' }}
                data={ordersAmountByMonthArr}
                margin={{ top: 20, left: 10, right: 25, bottom: 20 }}
                onClick={(data: any) => {
                    if (!data?.activeLabel) return;
                    const month = moment(data.activeLabel, 'MM/YY');
                    setOrdersFilters([
                        { field: 'orderDateFrom', value: month.toDate() },
                        { field: 'orderDateTo', value: month.endOf('month').toDate() }
                    ]);
                    history.push(PagesRoutes.Orders);
                }}
            >
                <CartesianGrid vertical={false} opacity={0.5} strokeDasharray="3 3" />
                <XAxis dataKey="month" stroke="#8b8b8b" fontSize={12} axisLine={false} tickLine={false} dx={5} dy={5} />
                <YAxis width={longestLabelLength * 10 + 10} tickCount={4} stroke="#8b8b8b" fontSize={12} axisLine={false} tickLine={false} dx={-5} />
                <Tooltip />
                <ReferenceLine
                    y={ordersByMonthAvg}
                    label={{ position: 'insideBottomLeft', value: `Avg: ${ordersByMonthAvg.toFixed(2)}`, fill: '#6d6c72', fontSize: 12 }}
                    stroke="#757575"
                    opacity={0.7}
                />

                <defs>
                    <linearGradient id="fillColor" x1="0" y1="0" x2="0" y2="1">
                        <stop offset="10%" stopColor="#008ac9" stopOpacity={0.3} />
                        <stop offset="90%" stopColor="#008ac9" stopOpacity={0} />
                    </linearGradient>
                </defs>

                <Area
                    type="monotone"
                    dataKey="amount"
                    fill="url(#fillColor)"
                    stroke="#008ac9"
                    strokeWidth={1.5}
                    isAnimationActive={false}
                    dot={{ stroke: '#008ac9', strokeWidth: 1.5, r: 3 }}
                />
            </AreaChart>
        </ResponsiveContainer>
    );
};

export default OrdersAmountByMonth;
