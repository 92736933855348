import React from 'react';
import { Modal, AutoComplete as AntdAutoComplete } from 'antd';
import styled, { CSSProperties, FlattenSimpleInterpolation } from 'styled-components';
import { getPopupContainerPopoverBug } from '.';

export type Option = {
    value: string;
    text: string;
    color?: string;
};

export const enumToDropdownOptions = (object: any): Array<Option> => {
    return Object.keys(object).map((key) => {
        return { value: key, text: object[key] as string };
    });
};
export type ExtendedProps = BaseProps & {
    options: Array<Option>;
};
export type BaseProps = {
    onChange?: any;
    onBlur?: any;
    placeHolder?: string;
    style?: CSSProperties;
    selectorStyle?: FlattenSimpleInterpolation;
    value?: string;
    disabled?: boolean;
    showSearch?: boolean;
    optionRenderer?: (option: Option & { color?: string }) => Promise<JSX.Element> | JSX.Element;
    hasError?: boolean;
    width?: string;
    minWidth?: string;
    height?: string;
    preventPopoverBug?: boolean;
    allowClear?: boolean;
    verifyChange?: {
        title?: (changeValue: any) => string;
    };
};

const AutoComplete = ({
    value,
    options,
    placeHolder,
    style,
    selectorStyle,
    disabled,
    optionRenderer,
    onChange,
    onBlur,
    showSearch,
    hasError,
    width,
    height,
    preventPopoverBug,
    allowClear,
    verifyChange
}: ExtendedProps) => {
    const { Option } = AntdAutoComplete;
    return (
        <Container hasError={hasError} width={width} height={height} selectorStyle={selectorStyle}>
            <AntdAutoComplete
                showSearch={showSearch}
                optionFilterProp="children"
                filterOption={(input, option) => {
                    const value = option?.value.toLowerCase();
                    const text = option?.text ? option?.text.toLowerCase() : value;
                    return value.indexOf(input.toLowerCase()) >= 0 || text.indexOf(input?.toLowerCase()) >= 0;
                }}
                getPopupContainer={(trigger: any) => getPopupContainerPopoverBug(trigger, preventPopoverBug)}
                disabled={disabled}
                style={{ width: 'inherit', height, ...style }}
                placeholder={placeHolder}
                value={value}
                onChange={(value) => {
                    if (verifyChange) {
                        Modal.confirm({
                            title: verifyChange.title ? verifyChange.title(value) : 'Are you sure?',
                            async onOk() {
                                await onChange(value);
                            },
                            onCancel() {}
                        });
                    } else {
                        onChange(value);
                    }
                }}
                onBlur={onBlur}
                dropdownAlign={{ offset: [0, 0] }}
                allowClear={allowClear}
            >
                {options.map((option, index) => {
                    return (
                        <Option key={index} value={option.text} label={option.text}>
                            {optionRenderer ? optionRenderer(option) : option.text}
                        </Option>
                    );
                })}
            </AntdAutoComplete>
        </Container>
    );
};
AutoComplete.defaultProps = {
    width: '100%',
    height: 'auto',
    style: ''
};
interface ContainerProps {
    hasError?: boolean;
    width?: string;
    height?: string;
    selectorStyle?: FlattenSimpleInterpolation;
}
const Container = styled.div<ContainerProps>`
    position: relative;
    width: ${(props) => props.width};
    & .ant-select-arrow {
        color: ${(props) => props.theme.colors.primaryBlue};
    }

    & .ant-select-selector {
        border-radius: 2vh !important;
        border-color: ${(props) => (props.hasError ? 'red' : props.theme.colors.primaryBlue)} !important;
        ${(props) => props.selectorStyle}
    }
    & .ant-select-focused.ant-select-multiple .ant-select-selector {
        border-radius: 2vh !important;
        box-shadow: ${(props) => (props.hasError ? '0 0 0 2px rgb(255 24 24 / 20%)' : '0 0 0 2px rgba(24, 144, 255, 0.2)')} !important;
    }
`;

export default AutoComplete;
