import React, { useRef } from 'react';
import styled from 'styled-components';
import { userInfo, userMenu } from '../../../models/entities/user';
import { userDetails } from '../../../state/ducks/users/users';
import { MenuSlideDown } from '../../animations/Animations';
import { UserImageAvatar } from '../Layout/Header/AvatarHeader';
import theme from '../Theme/theme';
import MenuItem, { MenuItemProps } from './components/MenuItem';
import MenuItemDropDown, { MenuItemDropDownProps } from './components/MenuItemDropDown';
import ProfileHeader from './ProfileHeader';
import { menuItemsEventsProps } from './UserMenuConnected';
const menuTheme = theme.colors.userMenu;

type Props = {
    setMenuOpen: () => void;
    userMenu: userMenu;
    isOpen: boolean;
    userInfo: userInfo;
    avatarColor?: string;
    localizations?: any;
    menuItemsEvents: menuItemsEventsProps;
};

function UserMenu({ userInfo, userMenu, setMenuOpen, localizations, menuItemsEvents, isOpen, avatarColor }: Props) {
    const outSideRef = useRef(null);
    return isOpen ? (
        <>
            <OptionsOverlay onClick={setMenuOpen}></OptionsOverlay>
            <MenuContainer ref={outSideRef}>
                <ProfileHeader
                    firstName={userInfo.firstName}
                    lastName={userInfo.lastName}
                    profileImg={UserImageAvatar({
                        firstName: userInfo.firstName,
                        lastName: userInfo.lastName,
                        userName: userInfo.username,
                        profileImgUrl: userInfo.profileImgUrl,
                        avatarColor
                    } as userDetails)}
                    status={userInfo.status}
                    localizations={localizations}
                    setMenuOpen={setMenuOpen}
                />
                <Divider />
                {userMenu.configuration?.items.map((item: any, index) => {
                    switch (item.type) {
                        case 'DROPDOWN':
                            return (
                                <MenuItemDropDown
                                    key={index}
                                    {...(item as MenuItemDropDownProps)}
                                    localizations={localizations}
                                    outSideRef={outSideRef}
                                    userInfo={userInfo}
                                    ddlKey={item.key}
                                />
                            );

                        default:
                            return (
                                <MenuItem
                                    key={index}
                                    {...(item as MenuItemProps)}
                                    localizations={localizations}
                                    onClick={menuItemsEvents[item.key]}
                                />
                            );
                    }
                })}
            </MenuContainer>
        </>
    ) : (
        <></>
    );
}
const MenuContainer = styled.div`
    background: white;
    animation: ${MenuSlideDown} ease-out 0.3s;
    z-index: 1002;
    min-height: 200px;
    width: 200px;
    margin-left: auto;
    border-bottom-left-radius: 3px;
    animation: dJIygU ease-out 0.3s;
    position: fixed;
    right: 0;
    top: 64px;
    z-index: 1004;
    padding: 0 0;
    padding-bottom: 15px;
    user-select: none;
    box-shadow: -1px 1px 2px 1px #00000026;
`;

const OptionsOverlay = styled.div`
    position: absolute;
    width: 100%;
    z-index: 1001;
    height: 100%;
    background: ${theme.colors.overlayBG};
`;
const Divider = styled.div`
    width: 80%;
    margin: auto;
    height: 1px;
    background: ${menuTheme.dividerColor};
`;
export default UserMenu;
