import BaseController from '..';
import IInventoryLogs from './IInventoryLogs';
import { inventoryLog } from '../../../models/entities/product/inventoryLog';

export default class InventoryLogs extends BaseController implements IInventoryLogs {
    constructor(baseUrl: string) {
        super(baseUrl);
    }
    async fetchInventory() {
        const response = await this.client.get('/catalog/inventory/view');
        return response;
    }
    async getInternalFlowInventoryLogs() {
        const response = await this.client.get('/catalog/inventory/internalFlow');
        return response;
    }
    async create(inv: inventoryLog) {
        const response = await this.client.post('/catalog/inventory', JSON.stringify({ data: inv }));
        return response;
    }
}
