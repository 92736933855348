import React from 'react';
import { Upload, message, Modal } from 'antd';
import { RcFile, UploadChangeParam, UploadFile } from 'antd/lib/upload/interface';
import { UploadRequestOption } from 'rc-upload/lib/interface';
import { LoadingOutlined, PlusOutlined } from '@ant-design/icons';
import ImgCrop from 'antd-img-crop';
import { useState } from 'react';
import styled from 'styled-components';
import { useEffect } from 'react';

type Props = {
    imgUrl?: string;
    filesList?: UploadFile<any>[];
    isLoading?: boolean;
    customRequest?: (options: UploadRequestOption<any>) => void;
    onRemove?: (file: UploadFile<any>) => Promise<boolean | void>;
    localization?: any;
    mode?: 'single' | 'multiple';
};

function getBase64(file: any) {
    return new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = () => resolve(reader.result);
        reader.onerror = (error) => reject(error);
    });
}

const UploadImgButton = ({ imgUrl, isLoading, customRequest, localization, mode, filesList, onRemove }: Props) => {
    const [fileList, setFileList] = useState<UploadFile<any>[]>(filesList || []);
    const [previewVisible, setPreviewVisible] = useState(false);
    const [previewImage, setPreviewImage] = useState('');
    const [previewTitle, setPreviewTitle] = useState('');

    useEffect(() => {
        setFileList(filesList || []);
    }, [filesList]);
    const isMultipleMode = mode === 'multiple';

    const uploadButton = (
        <div>
            {isLoading ? <LoadingOutlined /> : <PlusOutlined />}
            <div style={{ marginTop: 8 }}>{localization?.upload_img || 'Upload'}</div>
        </div>
    );

    const multipleUploadButton = (
        <div>
            <PlusOutlined />
            <div style={{ marginTop: 8 }}>{localization?.upload_img || 'Upload'}</div>
        </div>
    );

    const beforeUpload = (file: RcFile, _FileList: RcFile[]): boolean => {
        const isJpgOrPng = file.type === 'image/jpeg' || file.type === 'image/png';
        if (!isJpgOrPng) {
            message.error(localization?.you_can_only_upload_jpg_png_file || 'You can only upload JPG/PNG file!');
        }
        const isLt2M = file.size / 1024 / 1024 < 2;
        if (!isLt2M) {
            message.error(localization?.image_must_smaller_than_2_mb || 'Image must smaller than 2MB!');
        }
        return isJpgOrPng && isLt2M;
    };

    const onPreview = async (file: any) => {
        let src = file.url;
        if (!src && file) {
            src = await new Promise((resolve) => {
                const reader = new FileReader();
                reader.readAsDataURL(file.originFileObj);
                reader.onload = () => {
                    resolve(reader.result);
                };
            });
        }
        const image = new Image();

        image.src = src;
        const imgWindow = window.open(src);
        imgWindow?.document.write(image.outerHTML);
    };

    const handlePreview = async (file: any) => {
        if (!file.url && !file.preview) {
            file.preview = await getBase64(file.originFileObj);
        }

        setPreviewImage(file.url || file.preview);
        setPreviewVisible(true);
        setPreviewTitle(file.name || file.url.substring(file.url.lastIndexOf('/') + 1) || '');
    };

    const handleCancel = () => setPreviewVisible(false);

    const handleChange = ({ fileList }: UploadChangeParam<UploadFile<any>>) => setFileList(fileList);

    return isMultipleMode ? (
        <MultipleContainer>
            <Upload
                showUploadList={true}
                listType="picture-card"
                customRequest={customRequest}
                beforeUpload={beforeUpload}
                onPreview={handlePreview}
                onChange={handleChange}
                onRemove={onRemove}
                fileList={fileList}
                maxCount={5}
                multiple
            >
                {fileList.length >= 5 ? null : multipleUploadButton}
            </Upload>
            <Modal zIndex={1010} visible={previewVisible} title={previewTitle} footer={null} onCancel={handleCancel}>
                <img alt="example" style={{ width: '100%' }} src={previewImage} />
            </Modal>
        </MultipleContainer>
    ) : (
        <ImgCrop rotate quality={1}>
            <Upload showUploadList={false} listType="picture-card" customRequest={customRequest} beforeUpload={beforeUpload} onPreview={onPreview}>
                {isLoading || !imgUrl ? uploadButton : <img src={imgUrl} alt="uploaded_img" style={{ width: '100%' }} />}
            </Upload>
        </ImgCrop>
    );
};

const MultipleContainer = styled.div`
    .ant-upload-list-picture-card-container,
    .ant-upload-list-item,
    .ant-upload,
    .ant-upload-select,
    .ant-upload-select-picture-card {
        width: 80px;
        height: 80px;
    }
    .ant-upload-list-item {
        padding: 5px;
    }
    .ant-upload-list-item-progress {
        bottom: 20px;
        padding-left: 0;
        width: calc(100% - 10px);
        display: none;
    }
`;

export default UploadImgButton;
