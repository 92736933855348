import React from 'react';
import { styled } from '../../Theme/theme';
import calendar from '../../../../static/icons/calendar.svg';
import orders from '../../../../static/icons/orders.svg';
import tariffs from '../../../../static/icons/tariff.svg';
import dashboard from '../../../../static/icons/dashboard.svg';
import shipments from '../../../../static/icons/shipments.svg';
import users from '../../../../static/icons/customers.svg';
import businessPartners from '../../../../static/icons/business_partners.svg';
import companies from '../../../../static/icons/companies.svg';
import containers from '../../../../static/icons/container-white.svg';
import catalog from '../../../../static/icons/catalog.svg';
import products from '../../../../static/icons/products.svg';
import inventory from '../../../../static/icons/inventory.svg';
import grid from '../../../../static/icons/grid.svg';
import admin from '../../../../static/icons/management.svg';
import board from '../../../../static/icons/board.svg';
import bids from '../../../../static/icons/bids.svg';
import messages from '../../../../static/icons/messages.svg';
import partnersRelations from '../../../../static/icons/partners_relations.svg';
import deletedRecords from '../../../../static/icons/trash.svg';
import purchaseOrders from '../../../../static/icons/purchaseOrder.svg';
import purchaseDemands from '../../../../static/icons/purchaseDemand.svg';
import procurement from '../../../../static/icons/procurement.svg';
import { isItemSelected, linkItem } from './SideBar';
import { RouteComponentProps, withRouter } from 'react-router-dom';
import { isEqual } from 'lodash';
import { isBreakStatement } from 'typescript';

//This is the props api for this component
export type SideBarItemProps = RouteComponentProps & {
    text: string;
    icon: availableIcons;
    isSelected: boolean;
    onClick?: () => void;
    iconSize: number;
    subItems?: Array<linkItem>;
};

export type availableIcons =
    | 'dashboard'
    | 'calendar'
    | 'shipments'
    | 'companies'
    | 'purchaseOrders'
    | 'purchaseDemands'
    | 'procurement'
    | 'grid'
    | 'board'
    | 'bids'
    | 'messages'
    | 'containers'
    | 'businessPartners'
    | 'users'
    | 'admin'
    | 'catalog'
    | 'products'
    | 'inventory'
    | 'partners_relations'
    | 'deleted_records'
    | 'orders'
    | 'tariffs';

const SideBarItem = (props: SideBarItemProps) => {
    return (
        <Wrapper isSelected={props.isSelected} hasSubItems={!!props.subItems}>
            <Body onClick={props.onClick} isSelected={props.isSelected} hasSubItems={!!props.subItems}>
                <IconContainer>
                    <IconGenerator icon={props.icon} size={28 / props.iconSize} />
                </IconContainer>

                <span>{props.text}</span>
            </Body>
            <SubItems>
                {!!props.subItems &&
                    props.isSelected &&
                    props.subItems.map((item) => (
                        <SideBarItem
                            history={props.history}
                            match={props.match}
                            location={props.location}
                            key={item.route}
                            onClick={() => props.history.push(item.route)}
                            icon={item.icon}
                            isSelected={isItemSelected(props.history.location.pathname, item.route, item.subItems)}
                            text={item.text}
                            iconSize={item.iconSize}
                        />
                    ))}
            </SubItems>
        </Wrapper>
    );
};

/*
 * Helper components *
 * Wrapper - overall style and size + controlling the text style
 * IconGenerator - gets the icon text and generates an icon
 */
type BodyProps = {
    isSelected: boolean;
    hasSubItems: boolean;
};

const Wrapper = styled.div<BodyProps>`
    width: 240px;
    background-color: ${(props) => (props.isSelected && props.hasSubItems ? '#00375c' : 'transparent')};
    cursor: pointer;
    color: #fff;
    box-sizing: border-box;
    display: flex;
    flex-direction: column;

    @media screen and (max-width: 768px) {
        height: 45px;

        border-bottom: 3px solid ${(props) => (props.isSelected ? props.theme.colors.primary : props.theme.colors.secondary)};
        border-left: none;
    }
`;

const Body = styled.div<BodyProps>`
    font-size: ${(props) => props.theme.fontSizes.menuItem};
    display: flex;
    align-items: center;
    justify-content: space-between;

    @media screen and (min-width: 769px) {
        padding: 10px 0;
        font-size: ${(props) => props.theme.fontSizes.menuItem};
        display: flex;
        align-items: center;
        justify-content: space-between;
        min-height: 45px;
        border-left: 4px solid ${(props) => (props.isSelected && !props.hasSubItems ? props.theme.colors.primary : 'transparent')};
        background-color: ${(props) => (props.isSelected && !props.hasSubItems ? '#00375c' : 'transparent')};
    }

    & span {
        flex: 1;
        font-size: 14px;
    }
    @media screen and (max-width: 768px) {
        & span {
            display: none;
        }
    }
`;
const SubItems = styled.div`
    display: flex;
    flex-direction: column;
`;

const IconContainer = styled.div`
    width: 56px;
    text-align: center;
`;

//TODO: Consider moving it to more  generic place - and use in other components
type IconGeneratorProps = {
    icon: availableIcons;
    size: number;
};
const IconGenerator = ({ icon, size }: IconGeneratorProps) => {
    let selected = '';

    switch (icon) {
        case 'dashboard':
            selected = dashboard;
            break;
        case 'calendar':
            selected = calendar;
            break;
        case 'companies':
            selected = companies;
            break;
        case 'purchaseOrders':
            selected = purchaseOrders;
            break;
        case 'purchaseDemands':
            selected = purchaseDemands;
            break;
        case 'procurement':
            selected = procurement;
            break;
        case 'grid':
            selected = grid;
            break;
        case 'businessPartners':
            selected = businessPartners;
            break;
        case 'board':
            selected = board;
            break;
        case 'bids':
            selected = bids;
            break;
        case 'messages':
            selected = messages;
            break;
        case 'containers':
            selected = containers;
            break;
        case 'users':
            selected = users;
            break;
        case 'admin':
            selected = admin;
            break;
        case 'catalog':
            selected = catalog;
            break;
        case 'products':
            selected = products;
            break;
        case 'inventory':
            selected = inventory;
            break;
        case 'partners_relations':
            selected = partnersRelations;
            break;
        case 'deleted_records':
            selected = deletedRecords;
            break;
        case 'orders':
            selected = orders;
            break;
        case 'tariffs':
            selected = tariffs;
            break;
        default:
            selected = shipments;
    }

    return <StyledIcon src={selected} alt="" width={size} />;
};

const StyledIcon = styled.img`
    align-self: center;
`;

export default React.memo(withRouter(SideBarItem), (prevProps, nextProps) => {
    return isEqual(prevProps, nextProps);
});
