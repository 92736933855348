// eslint-disable-next-line import/no-anonymous-default-export
export default {
    en: {
        partyTypes: [
            {
                code: 'FORWARDER_BROKER',
                name: 'Forwarder/Broker',
                description: 'Forwarder/Broker',
                subTypes: [
                    {
                        code: 'FORWARDER',
                        name: 'Forwarder',
                        description: 'Forwarder description'
                    },
                    {
                        code: 'BROKER',
                        name: 'Broker',
                        description: 'Broker description'
                    }
                ]
            },
            {
                code: 'CARGO_OWNER',
                name: 'Cargo Owner',
                description: 'Shipper/Consignee',
                subTypes: [
                    {
                        code: 'SHIPPER',
                        name: 'Shipper',
                        description: 'Shipper description'
                    },
                    {
                        code: 'CONSIGNEE',
                        name: 'Consignee',
                        description: 'Consignee description'
                    },
                    {
                        code: 'BILL_TO',
                        name: 'Bill to',
                        description: 'Bill to description'
                    }
                ]
            },
            {
                code: 'NOTIFY',
                name: 'Notify',
                description: 'Notify description'
            },
            {
                code: 'AGENT',
                name: 'Agent',
                description: 'Agent description'
            },
            {
                code: 'CUSTOMER',
                name: 'Customer',
                description: 'Customer description'
            },
            {
                code: 'WAREHOUSE',
                name: 'Warehouse',
                description: 'Warehouse description'
            },
            {
                code: 'TRUCKER',
                name: 'Trucker',
                description: 'Trucker description'
            },
            {
                code: 'INSURER',
                name: 'Insurer',
                description: 'Insurer description'
            },
            {
                code: 'CUSTOM_BROKER',
                name: 'Custom broker',
                description: 'Custom broker description'
            },
            {
                code: 'BANK',
                name: 'Bank',
                description: 'Bank description'
            },
            {
                code: 'CHARATER_AGENT',
                name: 'Charter agent',
                description: 'Charter agent description'
            }
        ],
        documentTypes: [
            { code: 'BL', name: 'Original Bill of landing', description: 'Original Bill of landing description' },
            { code: 'WTS', name: 'Waybill/Telex/Surrendered', description: 'Original Bill of landing description' },
            { code: 'INV', name: 'Invoice', description: 'Invoice description' },
            { code: 'CE', name: 'Customs entry', description: 'Customs entry description' },
            { code: 'MO', name: 'Money Order', description: 'Money Order description' },
            { code: 'PROINV', name: 'Pro-forma invoice', description: 'Pro-forma invoice description' },
            { code: 'PL', name: 'Packing list', description: 'Packing list description' },
            { code: 'QUOTE', name: 'Quotation', description: 'Quotation description' },
            { code: 'COF', name: 'Certificate of origin', description: 'Certificate of origin description' },
            { code: 'EURO', name: 'Euro1/Euro-Med', description: 'Euro1/Euro-Med description' },
            { code: 'MSDS', name: 'MSDS', description: 'MSDS description' },
            { code: 'CBTEST', name: 'CB Test', description: 'CB Test description' },
            { code: 'IEPERMIT', name: 'Import/Export permit', description: 'Import/Export permit description' },
            { code: 'CATALOG', name: 'Catalog', description: 'Catalog description' },
            { code: 'IELINSE', name: 'Import/Export License', description: 'Import/Export License description' },
            { code: 'PO', name: 'PO License', description: 'PO License description' },
            { code: 'LOC', name: 'Letter of credit License', description: 'Letter of credit License description' },
            { code: 'COA', name: 'Certificate of Analysis', description: 'Certificate of Analysis description' },
            { code: 'OTHER', name: 'Other', description: 'Other file description' }
        ],
        eventTypes: [
            { code: 'OPENED', name: 'Opened', description: 'Opened description' },
            { code: 'BOOKING', name: 'Booking', description: 'Booking description' },
            { code: 'PICK_UP', name: 'Pick up', description: 'Pick up description' },
            { code: 'ON_HAND', name: 'On hand at POL', description: 'On hand at POL description' },
            { code: 'GOODS_ON_BOARD', name: 'Goods on board', description: 'Goods on board description' },
            { code: 'ABD', name: 'Actual booking details', description: 'Actual booking details description' },
            { code: 'PRE_ALERT', name: 'Pre alert', description: 'Pre alert description' },
            { code: 'ARRIVAL', name: 'Arrival', description: 'Arrival description' },
            { code: 'CUSTOMS_PAID', name: 'Paid to customs', description: 'Paid to customs description' },
            { code: 'CUSTOMS_CHECK', name: 'Customs check', description: 'Customs check description' },
            { code: 'CUSTOMS_RELEASED', name: 'Custom realesed', description: 'Custom realesed description' },
            { code: 'DELIVERY', name: 'Delivery', description: 'Delivery description' },
            { code: 'POD', name: 'POD - Proof of delivery', description: 'POD description' }
        ],
        state: [
            { code: 'NEW', name: 'New', description: 'New', displayOrder: 0, color: '#b2b2b2' },
            // { code: 'APPROVAL', name: 'Sent to approval', description: 'Sent to approval', displayOrder: 1, color: '#b2b2b2' },
            // { code: 'APPROVED', name: 'Approved', description: 'Approved', displayOrder: 2, color: '#007acc' },
            { code: 'VENDOR', name: 'Sent to supplier', description: 'Sent to supplier', displayOrder: 3, color: '#007acc' },
            { code: 'CONFIRM', name: 'Confirm by supplier', description: 'Confirm by supplier', displayOrder: 4, color: '#007acc' },
            { code: 'PRODUCTION', name: 'On production', description: 'On production', displayOrder: 5, color: '#007acc' },
            { code: 'READY_SHIP', name: 'Ready to ship', description: 'Ready to ship', displayOrder: 6, color: '#339933' },
            {
                code: 'FINAL_PAYMENT',
                name: 'Waiting for Final Payment',
                description: 'Waiting for Final Payment',
                displayOrder: 7,
                color: '#E50000'
            },
            { code: 'CLOSED', name: 'Closed', description: 'Closed', displayOrder: 8, color: '#b2b2b2' }
        ]
    }
};
