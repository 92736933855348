import { chat, message, editMessagePayload, messageAttachment, createChatPayload, participant, chatType } from '../../../models/entities/chat/chat';
import { ModelConfig } from '@rematch/core';
import { localSDK as client } from '../../../sdk';
import { isEqual } from 'lodash';
import { chatRequest } from '../../../models/entities/chat/chatRequest';
import moment from 'moment';
import { RootState } from '../../store/store';
import { randDarkColor } from '../../../components/shared/Theme/util';
import { ContentState } from 'draft-js';

export type chatsStateType = {
    chats: Array<chat>;
    error: string;
    isFetching: boolean;
    network: {
        chat_requests?: chatRequests;
    };
    currentPaneChatId: string;
};
export type chatRequests = {
    recieved: Array<chatRequest>;
    sent: Array<chatRequest>;
};

export type openChatDrawerArgs = { chatType: chatType; chatId?: string; participantsIds?: string[] };

export const chats: ModelConfig<chatsStateType> = {
    state: {
        isFetching: false,
        chats: [],
        error: '',
        network: {},
        currentPaneChatId: ''
    },
    reducers: {
        setCurrentChatId(state: chatsStateType, chatId: string): chatsStateType {
            return { ...state, currentPaneChatId: chatId };
        },
        addChat(state: chatsStateType, chat: chat): chatsStateType {
            const currentChats = [...state.chats];
            const currentChatIndex = state.chats.findIndex((c: chat) => c.id === chat.id);

            if (currentChatIndex !== -1) {
                currentChats[currentChatIndex] = chat;
            } else {
                currentChats.push(chat);
            }

            return { ...state, chats: currentChats };
        },
        removeChat(state: chatsStateType, chatId: string): chatsStateType {
            const currentChats = [...state.chats];
            const currentChatIndex = state.chats.findIndex((c: chat) => c.id === chatId);

            if (currentChatIndex !== -1) {
                currentChats.splice(currentChatIndex, 1);
            }
            return { ...state, chats: currentChats };
        },
        saveChatEditorState(state: chatsStateType, chatId: string, newState: ContentState): chatsStateType {
            const currentChats = [...state.chats];
            const currentChatIndex = state.chats.findIndex((c: chat) => c.id === chatId);

            if (currentChatIndex !== -1) {
                const currentChat = currentChats[currentChatIndex];
                const currentEditorState = currentChat.editorState;
                currentChat.editorState = { ...currentEditorState, contentState: newState };
            }
            return { ...state, chats: currentChats };
        },
        saveChatAttachmentsEditorState(state: chatsStateType, chatId: string, attachments: messageAttachment[]): chatsStateType {
            const currentChats = [...state.chats];
            const currentChatIndex = state.chats.findIndex((c: chat) => c.id === chatId);

            if (currentChatIndex !== -1) {
                const currentChat = currentChats[currentChatIndex];
                const currentEditorState = currentChat.editorState;
                currentChat.editorState = { ...currentEditorState, attachments };
            }
            return { ...state, chats: currentChats };
        },
        addMessageToChat(state: chatsStateType, chatId: string, message: message): chatsStateType {
            const newChats = [...state.chats];
            const chatIndex = newChats.findIndex((c) => c.id === chatId);
            if (chatIndex !== -1) {
                const newMessages = newChats[chatIndex].messages;

                const lastMessage = newMessages[newMessages.length - 1];

                if (lastMessage) {
                    const isNewMessageSameAsLast =
                        message.content === lastMessage.content && moment(message.sendDate).isSame(moment(lastMessage.sendDate));

                    if (!isNewMessageSameAsLast) newMessages.push(message);
                } else {
                    newMessages.push(message);
                }
            }
            return { ...state, chats: newChats };
        },
        addMessagesToChat(state: chatsStateType, chatId: string, messages: message[]): chatsStateType {
            const newChats = [...state.chats];
            const chatIndex = newChats.findIndex((c) => c.id === chatId);
            if (chatIndex !== -1) {
                const newMessages = newChats[chatIndex].messages;

                newChats[chatIndex].messages = [...newMessages, ...messages];
            }
            return { ...state, chats: newChats };
        },
        setMessagesHasNext(state: chatsStateType, chatId: string, hasNext: boolean): chatsStateType {
            const newChats = [...state.chats];
            const chatIndex = newChats.findIndex((c) => c.id === chatId);
            if (chatIndex !== -1) {
                newChats[chatIndex].messagesHasNext = hasNext;
            }
            return { ...state, chats: newChats };
        },
        updateMessage(state: chatsStateType, { id, chatId, message, attachments, replyMessageId }: editMessagePayload): chatsStateType {
            const newChats = [...state.chats];
            const chatIndex = newChats.findIndex((c) => c.id === chatId);
            if (chatIndex !== -1) {
                const newMessages = newChats[chatIndex].messages;
                const messageIndex = newMessages.findIndex((m) => m.id === id);
                if (messageIndex !== -1) {
                    if (message) newMessages[messageIndex].content = message;
                    if (attachments) newMessages[messageIndex].attachments = attachments;
                    if (replyMessageId) newMessages[messageIndex].replyMessageId = replyMessageId;
                    newMessages[messageIndex].isEdited = true;
                }
            }
            return { ...state, chats: newChats };
        },
        removeMessage(state: chatsStateType, { id, chatId }: { id: string; chatId: string }): chatsStateType {
            const newChats = [...state.chats];
            const chatIndex = newChats.findIndex((c) => c.id === chatId);
            if (chatIndex !== -1) {
                const newMessages = newChats[chatIndex].messages;
                const messageIndex = newMessages.findIndex((m) => m.id === id);
                if (messageIndex !== -1) {
                    newChats[chatIndex].messages.splice(messageIndex, 1);
                }
            }
            return { ...state, chats: newChats };
        },
        setMessagesRead(state: chatsStateType, chatId: string, messagesIds: string[]): chatsStateType {
            const newChats = [...state.chats];
            const chatIndex = newChats.findIndex((c) => c.id === chatId);
            if (chatIndex !== -1) {
                const newMessages = [...newChats[chatIndex].messages];

                newMessages
                    .filter((m) => messagesIds?.includes(m.id))
                    .forEach((message, i) => {
                        message.isRead = true;
                    });

                newChats[chatIndex].messages = newMessages;
            }
            return { ...state, chats: newChats };
        },
        setMessagesReadBy(
            state: chatsStateType,
            { chatId, messagesIds, readUserId }: { chatId: string; messagesIds: string[]; readUserId: string }
        ): chatsStateType {
            const newChats = [...state.chats];
            const chatIndex = newChats.findIndex((c) => c.id === chatId);
            if (chatIndex !== -1) {
                const newMessages = [...newChats[chatIndex].messages];

                newMessages
                    .filter((m) => messagesIds?.includes(m.id))
                    .forEach((message, i) => {
                        if (!message.readBy?.includes(readUserId)) {
                            message.readBy = message.readBy?.concat([readUserId]) || [readUserId];
                        }
                    });

                newChats[chatIndex].messages = newMessages;
            }
            return { ...state, chats: newChats };
        },
        setMessage(
            state: chatsStateType,
            { chatId, message, messageId, attachments }: { chatId: string; message: message; messageId: string; attachments: messageAttachment[] }
        ): chatsStateType {
            const newChats = [...state.chats];
            const chatIndex = newChats.findIndex((c) => c.id === chatId);
            if (chatIndex !== -1) {
                const newMessages = [...newChats[chatIndex].messages];

                const messageToUpdateIndex = newMessages.findIndex((m) => {
                    return isEqual(m, message);
                });

                if (messageToUpdateIndex !== -1) {
                    const newMessage: message = { ...message, id: messageId, attachments };
                    newMessages[messageToUpdateIndex] = newMessage;
                }

                newChats[chatIndex].messages = newMessages;
            }
            return { ...state, chats: newChats };
        },
        setChatTyping(state: chatsStateType, { chatId, userId, isTyping }: { chatId: string; userId: string; isTyping: boolean }): chatsStateType {
            const newChats = [...state.chats];
            let newTypingUsers;
            const chatIndex = newChats.findIndex((c) => c.id === chatId);
            if (chatIndex !== -1) {
                const typingUsers = newChats[chatIndex].typingUsers;
                if (typingUsers) {
                    newTypingUsers = [...typingUsers];
                    const typingUserIndex = newTypingUsers.findIndex((id) => id === userId);
                    if (typingUserIndex !== -1) {
                        if (!isTyping) {
                            newTypingUsers.splice(typingUserIndex, 1);
                        }
                    } else if (isTyping) {
                        newTypingUsers.push(userId);
                    }
                } else if (isTyping) {
                    newTypingUsers = [userId];
                }
                newChats[chatIndex].typingUsers = newTypingUsers;
            }
            return { ...state, chats: newChats };
        },
        setChatRequests(state: chatsStateType, chatRequests: chatRequests): chatsStateType {
            const network = { ...state.network };
            return { ...state, network: { ...network, chat_requests: chatRequests } };
        },
        removeGroupParticipant(state: chatsStateType, chatId: string, userId: string) {
            const newChats = [...state.chats];
            const chatIndex = newChats.findIndex((c) => c.id === chatId && c.type === 'group');
            if (chatIndex !== -1) {
                const newParticipantsIds = [...newChats[chatIndex].participants].filter((p) => p.userId !== userId);
                newChats[chatIndex].participants = newParticipantsIds;
            }
            return { ...state, chats: newChats };
        },
        setGroupParticipants(state: chatsStateType, chatId: string, usersIds: string[]) {
            const newChats = [...state.chats];
            const chatIndex = newChats.findIndex((c) => c.id === chatId && c.type === 'group');
            if (chatIndex !== -1) {
                const participants: Array<participant> = usersIds.map((id) => {
                    const participant: participant = {
                        chatId,
                        userId: id,
                        isAdmin: false,
                        createdAt: new Date()
                    };

                    return participant;
                });
                const newParticipants = [...newChats[chatIndex].participants, ...participants];
                newChats[chatIndex].participants = newParticipants;
            }
            return { ...state, chats: newChats };
        },
        setGroupName(state: chatsStateType, chatId: string, name: string) {
            const newChats = [...state.chats];
            const chatIndex = newChats.findIndex((c) => c.id === chatId && c.type === 'group');
            if (chatIndex !== -1) {
                newChats[chatIndex].name = name;
            }
            return { ...state, chats: newChats };
        }
    },
    effects: (dispatch: any) => ({
        openChatDrawer({ chatType, chatId, participantsIds }: openChatDrawerArgs) {
            dispatch.header.setDrawerOpenParams({
                open: `CHAT-${chatType}-${JSON.stringify(chatId || participantsIds)}`,
                params: {
                    participantsIds,
                    chatType,
                    chatId
                }
            });
        },
        async createChat(payload: createChatPayload) {
            const createdChat = await client.chat().createChat(payload);
            const getDrawerOpen = await dispatch.header.getDrawerOpenState();
            dispatch.chats.setCurrentChatId(createdChat.id);
            if (getDrawerOpen?.startsWith('CHAT')) {
                dispatch.chats.openChatDrawer({
                    participantsIds: createdChat.participants.map((p) => p.userId),
                    chatType: createdChat.type,
                    chatId: createdChat.id
                });
            }
            return createdChat.id;
        },
        async fetchNextMessages({ chatId, messagesToId }: { chatId: string; messagesToId: string }, state: RootState) {
            const chat = state.chats.chats.find((c) => c.id === chatId);

            if (chat) {
                if (chat.messagesHasNext === undefined || chat.messagesHasNext) {
                    const nextChat: chat = await client.chat().getChatById(chatId, messagesToId);

                    if (!nextChat || nextChat.messages.length < 20) {
                        dispatch.chats.setMessagesHasNext(chatId, false);
                        return;
                    }

                    dispatch.chats.addMessagesToChat(chatId, nextChat.messages);

                    return nextChat;
                }
            }
        },
        async fetchContactChat(contactId: string) {
            const chat: chat | null = await client.chat().getContactChat(contactId);

            if (chat) dispatch.chats.addChat(chat);
        },
        async sendMessage({ chatId, message }: { chatId: string; message: message }) {
            dispatch.chats.addMessageToChat(chatId, message);
            const { id, attachments } = await client
                .chat()
                .sendMessage(chatId, message.content, message.sendDate, message.attachments, message.replyMessageId);

            dispatch.chats.setMessage({ chatId, message, messageId: id, attachments });
        },
        async editMessage(payload: editMessagePayload) {
            dispatch.chats.updateMessage({ ...payload });

            const messageAttachments = await client.chat().editMessage(payload);
            dispatch.chats.updateMessage({ id: payload.id, chatId: payload.chatId, attachments: messageAttachments });
        },
        async deleteMessage({ id, chatId }: { id: string; chatId: string }) {
            dispatch.chats.removeMessage({ id, chatId });

            await client.chat().deleteMessage(id, chatId);
        },
        async deleteGroupParticipant({ chatId, userId, userName }: { chatId: string; userId: string; userName: string }) {
            dispatch.chats.removeGroupParticipant(chatId, userId);

            await client.chat().deleteGroupParticipant(chatId, userId, userName);
        },
        async addGroupParticipants({ chatId, users }: { chatId: string; users: { id: string; name: string }[] }) {
            dispatch.chats.setGroupParticipants(
                chatId,
                users.map((u) => u.id)
            );

            await client.chat().addGroupUser(chatId, users);
        },
        async readMessages({ chatId, messagesIds, userId }: { chatId: string; messagesIds: string[]; userId: string }) {
            await client.chat().readMessages(chatId, messagesIds);
        },
        async getMyNetwork() {
            const response = await client.chat().network().getMyNetwork();
            const values = Object.values(response);
            if (!values) return;
            values.forEach((item) => {
                item.name = item.firstName + ' ' + item.lastName;
                item.profileImgUrl = item.profileImg ? `${process.env.REACT_APP_API_URL}/api/user/image/${item.profileImg}` : '';
                if (!item.avatarColor) item.avatarColor = randDarkColor();
            });
            dispatch.users.appendUsers(response);
        },
        async editGroupName({ chatId, name }: { chatId: string; name: string }) {
            dispatch.chats.setGroupName(chatId, name);
            await client.chat().editGroupName(chatId, name);
            //bbb
        },
        async getChatRequests() {
            const response = await client.chat().network().getChatRequests();
            dispatch.chats.setChatRequests(response);
        },
        async inviteUser(email: string | string[]) {
            await client.chat().network().inviteUser(email);
            dispatch.chats.getChatRequests();
            return true;
        },
        async stopNetwork({ userId, networkId }: { userId: string; networkId?: string }) {
            await client.chat().network().stopNetwork(networkId);
            dispatch.users.setUserIsActive({ userId, isActive: false, networkId });
        },
        async approveRequest(requestId: string) {
            await client.chat().network().approveRequest(requestId);
            dispatch.chats.getChatRequests();
            dispatch.chats.getMyNetwork();
        },
        async denyRequest(requestId: string) {
            await client.chat().network().denyRequest(requestId);
            dispatch.chats.getChatRequests();
        }
    })
};
