import moment from 'moment';
import { purchaseOrder } from '../../../models/entities/purchaseOrder/purchaseOrder';
import { filterFieldsType } from '../allBusinessPartnersPage/allBusinessPartnersPage';

const filterPurchaseOrders = (purchaseOrders: purchaseOrder[], filterFields: filterFieldsType) => {
    let filteredPurchaseOrders = [...purchaseOrders];

    filterFields.forEach((filter) => {
        switch (filter.field) {
            case 'fromDate': {
                filteredPurchaseOrders = filteredPurchaseOrders.filter((purchaseOrder) =>
                    moment(purchaseOrder.createdAt).isSameOrAfter(moment(filter.value))
                );
                break;
            }
            case 'toDate': {
                filteredPurchaseOrders = filteredPurchaseOrders.filter((purchaseOrder) =>
                    moment(purchaseOrder.createdAt).isSameOrBefore(moment(filter.value))
                );
                break;
            }
            case 'incoterms':
                if (filter.value.length > 0) {
                    filteredPurchaseOrders = filteredPurchaseOrders.filter((purchaseOrder) =>
                        (filter.value as string[]).includes(purchaseOrder.incoterms)
                    );
                }
                break;
            case 'state':
                filteredPurchaseOrders = filteredPurchaseOrders.filter(
                    (po) => !!po.state && (filter.value as string[]).some((v) => v.toLowerCase() === po.state?.toLowerCase())
                );
                break;
            default:
                filteredPurchaseOrders = filteredPurchaseOrders.filter((purchaseOrder) =>
                    (purchaseOrder as any)[filter.field] ? (purchaseOrder as any)[filter.field].includes(filter.value as string) : purchaseOrder
                );
                break;
        }
    });
    return filteredPurchaseOrders;
};

export default filterPurchaseOrders;
