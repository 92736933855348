import React from 'react';
import styled from 'styled-components';
import { businessPartner } from '../../../../models/entities/businessPartner/businessPartner';
import { companiesRelationsResponseType, companiesRelationType } from '../../../../models/entities/company/companiesRelation';
import { companiesRelationRequestsResponseType } from '../../../../models/entities/company/companiesRelationRequest';
import RelationsSection from './components/RelationsSection';
import RequestsSection from './components/RequestsSection';

type Props = {
    localization: any;
    relations: companiesRelationsResponseType;
    relationRequests: companiesRelationRequestsResponseType;
    businessPartners: Array<businessPartner>;
    businessPartnerCargoOwners: Array<businessPartner>;
    relationToken?: string;
    isGeneratingToken: boolean;
    approveCompaniesRelationRequest: (
        requestId: string,
        businessPartnerId: string,
        recievedBusinessPartnerId?: string,
        recievedRelationType?: companiesRelationType
    ) => Promise<void>;
    sendCompaniesRelationRequest: (
        relationToken: string,
        relationType: companiesRelationType,
        recieverBusinessPartnerId?: string,
        recieverRelationType?: companiesRelationType
    ) => Promise<void>;
    denyCompaniesRelationRequest: (requestId: string) => Promise<void>;
    deleteCompaniesRelation: (relationId: string) => Promise<void>;
    generateRelationToken: () => Promise<void>;
};

const CompaniesRelationsPage = ({
    localization,
    relations,
    relationRequests,
    businessPartners,
    businessPartnerCargoOwners,
    relationToken,
    isGeneratingToken,
    approveCompaniesRelationRequest,
    sendCompaniesRelationRequest,
    denyCompaniesRelationRequest,
    deleteCompaniesRelation,
    generateRelationToken
}: Props) => {
    return (
        <PageContainer>
            <SectionContainer>
                <RelationsSection relations={relations} localization={localization} deleteCompaniesRelation={deleteCompaniesRelation} />
            </SectionContainer>
            <SectionContainer>
                <RequestsSection
                    localization={localization}
                    relationRequests={relationRequests}
                    businessPartners={businessPartners}
                    businessPartnerCargoOwners={businessPartnerCargoOwners}
                    relationToken={relationToken}
                    isGeneratingToken={isGeneratingToken}
                    denyCompaniesRelationRequest={denyCompaniesRelationRequest}
                    approveCompaniesRelationRequest={approveCompaniesRelationRequest}
                    sendCompaniesRelationRequest={sendCompaniesRelationRequest}
                    generateRelationToken={generateRelationToken}
                />
            </SectionContainer>
        </PageContainer>
    );
};

const PageContainer = styled.div`
    background-color: #f8f8f8;
    display: flex;
    width: 100%;
    height: 100%;
`;

const SectionContainer = styled.div`
    flex: 1;
    width: 100%;
    height: 100%;
`;

export default CompaniesRelationsPage;
