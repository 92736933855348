import { companyAdminUpdateUserPayload, companyAdminCreateUserPayload, userInfo } from '../../../../models/entities/user';
import BaseController from '../..';
import IUsers from './IUsers';

export default class Users extends BaseController implements IUsers {
    constructor(baseUrl: string) {
        super(baseUrl);
    }
    async fetchUsers() {
        const response: userInfo[] = await this.client.get('/companyAdmin/user/getUsersInSameCompany');
        return response;
    }
    async updateUserDetails(details: companyAdminUpdateUserPayload, userId: string) {
        const response = await this.client.post(`/companyAdmin/user/updateDetails/${userId}`, JSON.stringify(details));
        return response;
    }
    async resetPassword(userId: string, email: string) {
        const response = await this.client.post(`/companyAdmin/user/resetPassword/${userId}`, JSON.stringify({ email }));
        return response;
    }
    async deleteUser(userId: string) {
        const response = await this.client.delete(`/companyAdmin/user/${userId}`);
        return response;
    }
    async restoreUser(userId: string) {
        const response = await this.client.put(`/companyAdmin/user/restore`, JSON.stringify({ userId }));
        return response;
    }
    async createUser(payload: companyAdminCreateUserPayload) {
        const response = await this.client.post(`/companyAdmin/user`, JSON.stringify(payload));
        return response;
    }

    async checkEmailExist(email: string): Promise<boolean> {
        const exist = await this.client.get(`/companyAdmin/user/emailExist/${email}`);
        return exist;
    }
}
