import { ModelConfig } from '@rematch/core';

export type singleBusinessPartnerStateType = {
    businessPartnerId: string | null;
};

export const singleBusinessPartner: ModelConfig<singleBusinessPartnerStateType> = {
    state: {
        businessPartnerId: null
    },
    reducers: {
        setCurrentBusinessPartnerId(state: singleBusinessPartnerStateType, businessPartnerId: string): singleBusinessPartnerStateType {
            return { ...state, businessPartnerId };
        }
    }
};
