import { notification, pushNotificationModel } from '../../../models/entities/notification/notification';

import React from 'react';
import { notification as notify } from 'antd';
import Button from '../SmallComponents/Button';
import parse from 'html-react-parser';
import { rmHtmlTags } from '../../../utils/patterns';
import { mobileCheck } from '../../../utils/loadScript';
export function antdPushNotification(notification: pushNotificationModel, localization: any, icon?: string | JSX.Element) {
    notify.open({
        placement: 'topRight',
        message: notification.title,
        description: notification.message && parse(notification.message),
        top: 100,
        icon: typeof icon === 'string' ? <img src={icon} width="40px" /> : icon,
        btn: notification.click ? (
            <Button
                buttonType="filled"
                onClick={() => {
                    notification.click && notification.click.onClick();
                    notify.close(notification.id);
                }}
            >
                {notification.click.text || localization.click}
            </Button>
        ) : null,
        key: notification.id
    });
}
export async function pushNotification(notification: pushNotificationModel, localization: any, icon?: string | JSX.Element) {
    const title = notification.title;
    const options = { body: notification.message?.replace(rmHtmlTags, ''), tag: notification.id };
    if (!('Notification' in window) || mobileCheck()) {
        // This browser does not support desktop notification
        antdPushNotification(notification, localization, icon);
    } else if (Notification.permission === 'granted') {
        // If it's okay let's create a notification
        const not = new Notification(title, options);
        if (notification.click) not.onclick = notification.click.onClick;
    } else {
        const permission = await Notification.requestPermission();
        // If the user accepts, let's create a notification
        if (permission === 'granted') {
            const not = new Notification(title, options);
            if (notification.click) not.onclick = notification.click.onClick;
        } else {
            antdPushNotification(notification, localization, icon);
        }
    }
}
export function prepareNotification(notification: notification, localization: any, click?: { onClick: () => void; text?: string }) {
    const pushNotification: pushNotificationModel = {
        id: notification.id,
        title: notification.content.action.title || localization.default_title,
        message: notification.content.action.message,
        click
    };
    return pushNotification;
}
