import React, { useEffect } from 'react';
import { RootState } from '../../../state/store/store';
import { connect } from 'react-redux';
import { localizationSelectors } from '../../../state/ducks/localization/selectors';
import { userSelectors } from '../../../state/ducks/user/selectors';
import { shipment } from '../../../models/entities/shipment/shipment';
import FollowingShipments from './FollowingShipments';
import { headerSelectors } from '../../../state/ducks/header/selectors';
import { drawers } from '../../../state/ducks/header/header';
import { ColumnType } from '../../shared/Grid/types/Column';
import { userSettings } from '../../../models/entities/user';
import { allShipmentsPageSelectors } from '../../../state/ducks/allShipmentsPage/selectors';
import { filterColsByUserSettings } from '../../shared/Grid/util';

type Props = {
    localization: any;
    fetchShipmentsByIds: (shipmentsIds: string[]) => void;
    setDrawerEmptyState: (drawer: drawers, isEmpty: boolean) => void;
    setDrawerOpen: (open: drawers) => void;
    fetchGridColumns: () => void;
    followingShipments?: Array<string>;
    followingShipmentsData?: Array<shipment>;
    isLoadingShipments: boolean;
    isEmptyStateDrawer: boolean;
    gridColumns: Array<ColumnType<shipment>>;
    shipmentsGridSettings?: userSettings;
};

const FollowingShipmentsConnected = ({
    localization,
    fetchShipmentsByIds,
    setDrawerEmptyState,
    setDrawerOpen,
    followingShipments,
    followingShipmentsData,
    isLoadingShipments,
    isEmptyStateDrawer,
    fetchGridColumns,
    gridColumns,
    shipmentsGridSettings
}: Props) => {
    useEffect(() => {
        followingShipments && fetchShipmentsByIds(followingShipments);
        // if (!followingShipments?.every((shipmentId) => followingShipmentsData?.some((shipment) => shipment.id === shipmentId))) {
        //     followingShipments && fetchShipmentsByIds(followingShipments);
        // }
        if (!gridColumns || !gridColumns?.length) fetchGridColumns();
    }, [fetchShipmentsByIds, fetchGridColumns]);

    return (
        <FollowingShipments
            localization={localization}
            setDrawerEmptyState={setDrawerEmptyState}
            setDrawerOpen={setDrawerOpen}
            followingShipmentsData={followingShipmentsData}
            isLoadingShipments={isLoadingShipments}
            isEmptyStateDrawer={isEmptyStateDrawer}
            gridColumns={filterColsByUserSettings(gridColumns, shipmentsGridSettings)}
        />
    );
};

const mapStateToProps = (state: RootState) => ({
    localization: localizationSelectors.followingShipments(state),
    followingShipments: userSelectors.followingShipments(state),
    followingShipmentsData: userSelectors.followingShipmentsData(state),
    isLoadingShipments: state.loading.effects.shipments.fetchShipmentsByIds || state.loading.effects.allShipmentsPage.fetchGridColumns,
    isEmptyStateDrawer: headerSelectors.isEmptyStateDrawer(state),
    gridColumns: allShipmentsPageSelectors.gridColumns(state),
    shipmentsGridSettings: userSelectors.shipmentsGridSettings(state)
});

const mapDispatchToProps = (dispatch: any) => ({
    fetchGridColumns: () => dispatch.allShipmentsPage.fetchGridColumns(),
    fetchShipmentsByIds: async (shipmentsIds: string[]) => dispatch.shipments.fetchShipmentsByIds(shipmentsIds),
    setDrawerEmptyState: (drawer: drawers, isEmpty: boolean) => dispatch.header.setDrawerEmptyState({ drawer, isEmpty }),
    setDrawerOpen: (open: drawers) => dispatch.header.setDrawerOpen(open)
});

export default connect(mapStateToProps, mapDispatchToProps)(FollowingShipmentsConnected);
