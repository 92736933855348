import React from 'react';
import { DropDownItem } from './DropDown';
import styled from 'styled-components';

type Props = {
    option: DropDownItem;
    onSelect: (selectedOption: DropDownItem) => void;
    height: string;
    selectedIconColor?: string;
};

function DropDownOption<T>(props: Props) {
    return (
        <Container onClick={() => props.onSelect(props.option)} height={props.height} data-value={props.option.value}>
            {props.option.icon()} {props.option.text}
            {props.option.isSelected && (
                <SelectedIcon color={props.selectedIconColor}>
                    <span className="material-icons">check</span>
                </SelectedIcon>
            )}
        </Container>
    );
}

interface ContainerProps {
    readonly height: string;
}
interface SelectedIconProps {
    color?: string;
}
const SelectedIcon = styled.div<SelectedIconProps>`
    display: inline-block;
    margin-left: 5px;
    margin-bottom: 2px;
    & span {
        line-height: 40px;
        font-size: 14px;
        color: ${(props) => props.color || '#bdbdbd'};
        width: 12px;
    }
`;

const Container = styled.div<ContainerProps>`
    height: ${(props) => props.height};
    width: 100%;
    cursor: pointer;
    font-size: 11px;
    color: #3d4e7e;
    background-color: white;
    border-bottom: 1px solid #e0e0e0;
    position: relative;
    z-index: 1;
    position: relative;
    display: flex;
    align-items: center;
    justify-content: start;
    padding: 7px;
    :hover {
        background-color: #e1e3e2;
    }
`;

export default DropDownOption;
