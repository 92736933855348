import React, { useEffect, useState } from 'react';
import styled, { css } from 'styled-components';
import { chat, chatType, createChatPayload, editMessagePayload, message, messageAttachment } from '../../../models/entities/chat/chat';
import { userDropDown, userInfo } from '../../../models/entities/user';
import { drawers } from '../../../state/ducks/header/header';
import { userDetails, usersObject } from '../../../state/ducks/users/users';
import messagesIcon from '../../../static/icons/messages.svg';
import backIcon from '../../../static/icons/back.svg';
import { useHistory } from 'react-router-dom';
import ChatItem from './components/ChatItem';
import ContactItem from './components/ContactItem';
import ChatView from './ChatView/ChatView';
import { ColumnType } from '../../shared/Grid/types/Column';
import { shipment } from '../../../models/entities/shipment/shipment';
import { chatRequest } from '../../../models/entities/chat/chatRequest';
import { Badge } from '../../shared/Layout/Header/Header';
import { match, Route, Switch } from 'react-router-dom';
import { withRouter, RouteComponentProps } from 'react-router-dom';
import { ContentState, EditorState } from 'draft-js';
import { Input } from 'antd';
import { TextInput } from '../../shared/inputs/base';
import { openChatDrawerArgs } from '../../../state/ducks/chats/chats';

type Props = RouteComponentProps & {
    currentChatId: string;
    activeChats: Array<chat>;
    userInfo: userInfo;
    isMobile: boolean;
    usersInSameCompany?: Array<userDropDown>;
    openChatDrawer: (args: openChatDrawerArgs) => void;
    getChatByParticipantsIds: (participantsIds: string[], type: chatType) => chat | undefined;
    notReadMessagesCount: (chatId: string) => number | undefined;
    getUserPicture: (userId: string) => string;
    getUserById: (userId: string) => userDetails | undefined;
    setDrawerOpen: (open: drawers, params?: any) => void;
    typingUsers: (chatId: string) => string[] | undefined;
    setCurrentChatId: (chatId: string) => void;
    getContactsWithoutChat?: userDetails[];
    drawerOpen: drawers;
    isFetchingNextMessages: boolean;
    myNetwork: usersObject;
    gridColumns: Array<ColumnType<shipment>>;
    shipments: Array<shipment>;
    sentRequests?: Array<chatRequest>;
    getShipmentById: (id: string) => shipment | undefined;
    inviteUser: (email: string) => void;
    getMessagesByChatId: (chatId: string) => { [key: string]: message[] } | undefined;
    getMessageById: (chatId: string, messageId: string) => message | undefined;
    createChat: (payload: createChatPayload) => void;
    sendMessage: (chatId: string, message: message) => void;
    editMessage: (payload: editMessagePayload) => void;
    deleteMessage: (id: string, chatId: string) => void;
    readMessages: (chatId: string, messagesIds: string[], userId: string) => void;
    deleteGroupParticipant: (chatId: string, userId: string, userName: string) => Promise<void>;
    addGroupParticipants: (chatId: string, users: { id: string; name: string }[]) => Promise<void>;
    fetchNextMessages: (chatId: string, messagesToId: string) => Promise<chat | undefined>;
    fetchContactChat: (contactId: string) => void;
    saveChatEditorState: (chatId: string, editorContentState: ContentState) => void;
    saveChatAttachmentsEditorState: (chatId: string, attachments: messageAttachment[]) => void;
    openRequestsCount?: number;
    localization: any;
};

const ChatsPage = ({
    match,
    currentChatId,
    userInfo,
    isMobile,
    getContactsWithoutChat,
    getUserById,
    setDrawerOpen,
    drawerOpen,
    getMessageById,
    notReadMessagesCount,
    setCurrentChatId,
    activeChats,
    typingUsers,
    getMessagesByChatId,
    createChat,
    sendMessage,
    editMessage,
    deleteMessage,
    readMessages,
    deleteGroupParticipant,
    addGroupParticipants,
    fetchNextMessages,
    getShipmentById,
    inviteUser,
    saveChatEditorState,
    saveChatAttachmentsEditorState,
    openChatDrawer,
    isFetchingNextMessages,
    myNetwork,
    gridColumns,
    sentRequests,
    shipments,
    openRequestsCount,
    fetchContactChat,
    localization
}: Props) => {
    const [selectedParticipants, setSelectedParticipants] = useState<string[] | undefined>(undefined);
    const [selectedChat, setSelectedChat] = useState<chat | undefined>(undefined);
    const [currentMessages, setCurrentMessages] = useState<{ [key: string]: message[] } | undefined>(undefined);
    const [searchValue, setSearchValue] = useState<string>('');

    useEffect(() => {
        if (activeChats[0] && !currentChatId && !selectedParticipants?.length && !selectedChat) {
            setCurrentChatId(activeChats[0].id);
        }
    }, [activeChats, selectedParticipants, selectedChat]);

    useEffect(() => {
        setCurrentMessages(getMessagesByChatId(currentChatId));
    }, [currentChatId, getMessagesByChatId]);
    useEffect(() => {
        const currentChat = activeChats.find((c) => c.id === currentChatId);
        if (currentChat) {
            setSelectedParticipants(currentChat.participants.map((p) => p.userId));
            setSelectedChat(currentChat);
        }
    }, [currentChatId]);
    const history = useHistory();

    const renderSelectedChat = () => {
        if (selectedParticipants) {
            return (
                <ChatContainer>
                    <ChatView
                        participantsIds={selectedParticipants}
                        chatType={selectedChat?.type || 'user'}
                        userInfo={userInfo}
                        chat={selectedChat}
                        isFetchingNextMessages={isFetchingNextMessages}
                        myNetwork={myNetwork}
                        gridColumns={gridColumns}
                        sentRequests={sentRequests}
                        shipments={shipments}
                        typingUsers={selectedChat ? typingUsers(selectedChat.id) : undefined}
                        messages={currentMessages}
                        sendMessage={sendMessage}
                        createChat={createChat}
                        editMessage={editMessage}
                        deleteMessage={deleteMessage}
                        getUserById={getUserById}
                        readMessages={readMessages}
                        fetchNextMessages={fetchNextMessages}
                        getMessageById={getMessageById}
                        deleteGroupParticipant={deleteGroupParticipant}
                        addGroupParticipants={addGroupParticipants}
                        getShipmentById={getShipmentById}
                        inviteUser={inviteUser}
                        saveChatEditorState={saveChatEditorState}
                        saveChatAttachmentsEditorState={saveChatAttachmentsEditorState}
                        localization={localization.chat_view}
                    />
                </ChatContainer>
            );
        }
    };

    const renderContacts = getContactsWithoutChat
        ?.filter((u) => u.name.toLowerCase().includes(searchValue.toLowerCase()))
        .map((user, index) => {
            return (
                <ContactItem
                    key={index}
                    user={user}
                    userInfo={userInfo}
                    onClick={() => {
                        const participants = [userInfo.id, user.id];
                        setCurrentChatId('');
                        setSelectedParticipants(participants);
                        if (isMobile) {
                            openChatDrawer({ participantsIds: participants, chatType: 'user' });
                        }
                        history.push(match.path);
                        setSelectedChat(undefined);
                        fetchContactChat(user.id);
                    }}
                />
            );
        });
    const renderActiveUserChats = activeChats
        .filter((chat) => {
            if (chat.type === 'user') {
                const userId = chat.participants.map((p) => p.userId).find((id) => id !== userInfo.id) as string;
                const user = getUserById(userId);

                return user?.name.toLowerCase().includes(searchValue.toLowerCase());
            }
            return chat.name?.toLowerCase().includes(searchValue.toLowerCase());
        })
        .map((chat: chat, index) => {
            const { participants, id, type } = chat;
            const participantsIds = participants.map((p) => p.userId);
            return (
                <ChatItem
                    key={index}
                    chat={chat}
                    userInfo={userInfo}
                    isOpened={currentChatId === chat.id}
                    notReadMessagesCount={notReadMessagesCount(id)}
                    onClick={() => {
                        setCurrentChatId(chat.id);
                        if (isMobile) {
                            openChatDrawer({
                                participantsIds,
                                chatType: type,
                                chatId: chat.id
                            });
                        }
                    }}
                    typingUsers={typingUsers(id)}
                    getUserById={getUserById}
                />
            );
        });

    return (
        <PageContainer isMobile={isMobile}>
            <ContactsWrapper isMobile={isMobile}>
                <SearchInputWrapper>
                    <TextInput
                        value={searchValue}
                        placeHolder={localization.search_users}
                        suffix={
                            <span
                                className="material-icons"
                                style={{ color: '#008ac9', cursor: 'pointer' }}
                                onClick={() => !!searchValue && setSearchValue('')}
                            >
                                {!!searchValue ? 'close' : 'search'}
                            </span>
                        }
                        onChange={setSearchValue}
                    />
                </SearchInputWrapper>
                <ContactsContainer>
                    <Switch>
                        <Route exact path={match.path}>
                            {renderActiveUserChats}
                            <FloatButtonContainer>
                                <FloatButton
                                    onClick={() => {
                                        history.push('/messages/contacts');
                                    }}
                                >
                                    <img className="messages-icon" src={messagesIcon} alt="" />
                                </FloatButton>
                            </FloatButtonContainer>
                        </Route>
                        <Route exact path={`${match.path}/contacts`}>
                            <ItemContainer onClick={() => setDrawerOpen('CHAT_REQUESTS')}>
                                {openRequestsCount ? <Badge>{openRequestsCount}</Badge> : null}
                                <span style={{ color: 'rgb(0, 138, 201)' }} className="material-icons">
                                    person_add
                                </span>
                            </ItemContainer>
                            {renderContacts}
                            <FloatButtonContainer>
                                <FloatButton
                                    onClick={() => {
                                        history.push(match.path);
                                    }}
                                >
                                    <img className="messages-icon" src={backIcon} alt="" />
                                </FloatButton>
                            </FloatButtonContainer>
                        </Route>
                    </Switch>
                </ContactsContainer>
            </ContactsWrapper>
            {!isMobile && renderSelectedChat()}
        </PageContainer>
    );
};
interface PageContainerProps {
    isMobile: boolean;
}
const PageContainer = styled.div<PageContainerProps>`
    height: 100%;
    display: flex;
    position: relative;
`;
const ContactsWrapper = styled.div<{ isMobile: boolean }>`
    position: relative;
    ${(props) =>
        props.isMobile
            ? css`
                  width: 100%;
              `
            : css`
                  flex: 30%;
                  width: 0;
              `};
    height: 100%;
    border-right: 1px solid #e1e1e1;
    max-width: 420px;
    overflow-y: overlay;
`;
interface ItemContainerProps {
    isOpenedChat?: boolean;
}
const ContactsContainer = styled.div<ItemContainerProps>`
    margin-bottom: 5px;
    padding-bottom: 5px;
    width: 100%;
    height: 100%;
    z-index: 100;
`;
const SearchInputWrapper = styled.div`
    padding: 15px;
    position: sticky;
    top: 0;
    z-index: 10;
    background-color: #fff;
`;
const FloatButtonContainer = styled.div`
    width: 100%;
    height: 76px;
    padding: 0 20px 0 0;
    bottom: 0;
    left: 0;
    background-color: transparent;
    display: flex;
    align-items: center;
    justify-content: flex-end;
    z-index: 100;
    position: sticky;
`;
const FloatButton = styled.div`
    width: 56px;
    height: 56px;
    background-color: #008ac9;
    -webkit-backface-visibility: hidden;
    bottom: 20px;
    border-radius: 50%;
    box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.18), 0 2px 2px 0 rgba(0, 0, 0, 0.12);
    display: flex;
    align-items: center;
    justify-content: center;
    .messages-icon {
        width: 34px;
        height: 34px;
    }
    cursor: pointer;
`;

const ItemContainer = styled.div`
    height: 72px;
    padding: 11px 15px;
    display: flex;
    align-items: center;
    cursor: pointer;
    position: relative;
    border-bottom: 1px solid #e1e1e1;
    &:hover {
        background-color: #f1f1f1;
    }
`;

const ChatContainer = styled.div`
    border-left: 1px solid #e1e1e1;
    flex: 70%;
    width: 0;
`;

export default ChatsPage;
