import React, { useState } from 'react';
import { connect } from 'react-redux';
import styled from 'styled-components';
import { userDropDown, userInfo } from '../../../../models/entities/user';
import { userSelectors } from '../../../../state/ducks/user/selectors';
import { RootState } from '../../../../state/store/store';
import { usersSelectors } from '../../../../state/ducks/users/selectors';
import { drawers } from '../../../../state/ducks/header/header';
import { headerSelectors } from '../../../../state/ducks/header/selectors';
import { chatsSelectors } from '../../../../state/ducks/chats/selectors';
import { chat, chatType } from '../../../../models/entities/chat/chat';
import { userDetails } from '../../../../state/ducks/users/users';
import { getUserStatusTheme } from '../../Theme/util';
import UserBadge from '../../UserBadge/UserBadge';
import { Badge } from '../Header/Header';
import CreateGroupModal from '../../../pages/Chat/components/CreateGroupModal';
import { ModalTriggerProps } from '../../Modal/Modal';
import Menu, { TriggerProps } from '../../Menu/Menu';
import MenuItem from '../../Menu/MenuItem';
import personAddIcon from '../../../../static/icons/person_add.svg';
import groupAddIcon from '../../../../static/icons/group_add.svg';
import HexagonAvatar from '../../SmallComponents/HexagonAvatar';
import GroupBadge from '../../GroupBadge/GroupBadge';
import { localizationSelectors } from '../../../../state/ducks/localization/selectors';
import { openChatDrawerArgs } from '../../../../state/ducks/chats/chats';

type Props = {
    openChatDrawer: (args: openChatDrawerArgs) => void;
    activeChats: Array<chat>;
    userInfo: userInfo;
    usersInSameCompany?: Array<userDropDown>;
    getChatByParticipantsIds: (participantsIds: string[], type: chatType) => chat | undefined;
    setCurrentChatId: (chatId: string) => void;
    getContactsWithoutChat?: userDetails[];
    notReadMessagesCount: (chatId: string) => number | undefined;
    getUserPicture: (userId: string) => string;
    getUserById: (userId: string) => userDetails;
    setDrawerOpen: (open: drawers, params?: any) => void;
    fetchContactChat: (contactId: string) => void;
    drawerOpen: drawers;
    openRequestsCount?: number;
    localization: any;
};

const ContactsSideBar = ({
    openChatDrawer,
    userInfo,
    usersInSameCompany,
    setCurrentChatId,
    getUserPicture,
    getUserById,
    setDrawerOpen,
    drawerOpen,
    getChatByParticipantsIds,
    getContactsWithoutChat,
    notReadMessagesCount,
    activeChats,
    fetchContactChat,
    openRequestsCount,
    localization
}: Props) => {
    const [isModalOpened, setIsModalOpened] = useState(false);
    const checkIsOpenedChat = (participants: string[], chatId?: string) => {
        const chatData = drawerOpen?.split(/-(.+)/)[1];
        const chatType = chatData?.split(/-(.+)/)[0];
        const id = chatData?.split(/-(.+)/)[1];

        if (id === JSON.stringify(chatId || participants)) return true;

        return false;
    };
    const checkIsActiveChat = (participants: string[]) => {
        if (getChatByParticipantsIds(participants, 'user')) return true;

        return false;
    };
    const renderActiveUserChats = activeChats.map((chat: chat, index) => {
        const { participants, id, type } = chat;
        const participantsIds = participants.map((p) => p.userId);

        const itemContent = () => {
            if (type === 'user') {
                const userId = participantsIds.find((id) => id !== userInfo.id);
                if (userId) {
                    const user = getUserById(userId);
                    return (
                        <UserBadge
                            width={40}
                            userId={userId}
                            userName={user?.name || ''}
                            hideBadgeName={true}
                            borderColor={getUserStatusTheme(user?.status || 'OOTO').color}
                            popoverLeft={true}
                        />
                    );
                }
            } else {
                return <GroupBadge chat={chat} popoverLeft={true} />;
            }
        };
        return (
            <ItemContainer
                key={index}
                onClick={() => {
                    openChatDrawer({
                        chatType: type,
                        chatId: chat.id,
                        participantsIds
                    });
                    setCurrentChatId(chat.id);
                }}
                isOpened={checkIsOpenedChat(participantsIds, chat.id)}
                className={`active`}
            >
                {itemContent()}
                {!checkIsOpenedChat(participantsIds, chat.id) && !!Number(notReadMessagesCount(id)) && (
                    <NotReadBadge>{notReadMessagesCount(id)}</NotReadBadge>
                )}
            </ItemContainer>
        );
    });

    const renderContacts = getContactsWithoutChat?.map((user, index) => {
        const { name, id } = user;
        const participants = [id, userInfo.id];
        return (
            <ItemContainer
                key={index}
                onClick={() => {
                    openChatDrawer({ participantsIds: participants, chatType: 'user' });
                    setCurrentChatId('');

                    fetchContactChat(id);
                }}
                isOpened={checkIsOpenedChat(participants)}
                className={`${checkIsActiveChat(participants) ? 'active' : ''}`}
            >
                <UserBadge
                    width={40}
                    userId={id}
                    userName={name}
                    hideBadgeName={true}
                    borderColor={getUserStatusTheme(user?.status || 'OOTO').color}
                    popoverLeft={true}
                />
            </ItemContainer>
        );
    });
    return (
        <SideBarContainer>
            <ContactsContainer>
                <ActiveChatsContainer>{renderActiveUserChats}</ActiveChatsContainer>
                {renderContacts}
            </ContactsContainer>
            {/* {openRequestsCount ? <Badge>{openRequestsCount}</Badge> : null} */}
            <Menu
                menuWidth={165}
                position="left-top"
                arrowOnTrigger={false}
                isModalOpened={isModalOpened}
                options={[
                    <MenuItem
                        onClick={() => setDrawerOpen('CHAT_REQUESTS')}
                        icon={personAddIcon}
                        text={localization.contacts_side_bar.invite_users}
                    />,
                    <CreateGroupModal
                        onCancelCallback={() => {
                            setIsModalOpened(false);
                        }}
                        Trigger={(props: ModalTriggerProps) => (
                            <MenuItem
                                onClick={(e) => {
                                    e.stopPropagation();
                                    setIsModalOpened(true);
                                    props.onClick();
                                }}
                                icon={groupAddIcon}
                                text={localization.contacts_side_bar.new_group}
                            />
                        )}
                        localization={localization}
                    />
                ]}
                MenuTrigger={(props: TriggerProps) => (
                    <BottomContainer>
                        <ItemContainer onClick={props.onClick}>
                            <span style={{ color: 'rgb(0, 138, 201)' }} className="material-icons">
                                add
                            </span>
                        </ItemContainer>
                    </BottomContainer>
                )}
            />
        </SideBarContainer>
    );
};

interface ItemContainerProps {
    isOpened?: boolean;
}
const ActiveChatsContainer = styled.div<ItemContainerProps>`
    margin-bottom: 5px;
    padding-bottom: 5px;
    border-bottom: 1px solid #e1e1e1;
`;
const BottomContainer = styled.div<ItemContainerProps>`
    border-top: 1px solid #e1e1e1;
`;

const NotReadBadge = styled.div`
    top: 3px;
    right: 7px;
    font-weight: bold;
    background-color: #d3445b;

    height: 0px;
    overflow: hidden;
    position: absolute;
    width: 18px;
    height: 18px;
    line-height: 18px;
    font-size: 10px;
    border-radius: 50%;
    color: white;
    text-align: center;
    z-index: 2;
`;
const ItemContainer = styled.div<ItemContainerProps>`
    height: 50px;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    border-left: ${(props) => (props.isOpened ? '5px solid #008ac9;' : 'none')};
    padding-right: ${(props) => (props.isOpened ? '5px' : 0)};
    position: relative;

    transition: background-color 0.2s;
    &:hover {
        background-color: #f8f8f8;
    }
`;
const SideBarContainer = styled.div`
    position: absolute;
    z-index: 1002;
    right: 0;
    bottom: 0;
    height: calc(100% - 64px);
    background-color: white;
    width: 70px;
    border-left: 2px solid #efefef8c;
    box-shadow: 0 4px 6px -1px rgb(0 0 0 / 10%), 0 2px 4px -1px rgb(0 0 0 / 6%);
    padding-top: 5px;
    display: flex;
    flex-direction: column;
`;
const ContactsContainer = styled.div`
    flex: 1;
    overflow-y: overlay;
`;
const mapStateToProps = (state: RootState) => ({
    usersInSameCompany: userSelectors.usersInSameCompany(state),
    getUserPicture: (id: string) => usersSelectors.userPicture(state, id),
    getUserById: (id: string) => usersSelectors.getUserById(state)(id),
    drawerOpen: headerSelectors.drawerOpen(state),
    userInfo: userSelectors.userInfo(state),
    getChatByParticipantsIds: (participantsIds: string[], type: chatType) => chatsSelectors.getChatByParticipantsIds(state)(participantsIds, type),
    getContactsWithoutChat: chatsSelectors.getContactsWithoutChat(state),
    notReadMessagesCount: (chatId: string) => chatsSelectors.notReadMessagesCount(state)(chatId),
    activeChats: chatsSelectors.activeChats(state),
    myNetwork: usersSelectors.users(state),
    openRequestsCount: chatsSelectors.recievedRequests(state)?.filter((item) => item.status === 'PENDING').length,
    localization: localizationSelectors.chat(state)
});

const mapDispatchToProps = (dispatch: any) => ({
    openChatDrawer: (args: openChatDrawerArgs) => dispatch.chats.openChatDrawer(args),
    setCurrentChatId: (chatId: string) => dispatch.chats.setCurrentChatId(chatId),
    setDrawerOpen: (open: drawers, params?: any) => dispatch.header.setDrawerOpenParams({ open, params }),
    fetchContactChat: (contactId: string) => dispatch.chats.fetchContactChat(contactId)
});

export default React.memo(connect(mapStateToProps, mapDispatchToProps)(ContactsSideBar));
