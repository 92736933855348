import React from 'react';
import { connect } from 'react-redux';
import { shipment } from '../../../../../models/entities/shipment/shipment';
import { refCompanyType, shipmentReference } from '../../../../../models/entities/shipment/shipmentReference';
import { localizationSelectors } from '../../../../../state/ducks/localization/selectors';
import { RootState } from '../../../../../state/store/store';
import CustomModal, { ModalTriggerProps } from '../../../../shared/Modal/Modal';
import { styled } from '../../../../shared/Theme/theme';
import ReferencesItems from '../../../commonSubEntities/references/ReferencesItems';
import ReferencesItem from './ReferenceItem';
import RefFormConnected, { formMode } from './RefFormConnected';

type Props = {
    shipmentId: string;
    references: Array<shipmentReference>;
    type: refCompanyType;
    localization: any;
    printMode?: boolean;
    createShipmentReference: (shipmentNumber: string, reference: shipmentReference) => Promise<void>;
    updateShipmentReference: (shipmentNumber: string, reference: shipmentReference) => Promise<void>;
    deleteShipmentReference: (shipmentNumber: string, referenceId: string) => Promise<void>;
    showLoader?: boolean;
    isForwarder?: boolean;
};

const ShipmentReferencesItems = ({
    shipmentId,
    type,
    localization,
    references,
    printMode,
    createShipmentReference,
    updateShipmentReference,
    deleteShipmentReference,
    showLoader,
    isForwarder
}: Props) => {
    return (
        <ReferencesItems
            entityId={shipmentId}
            references={references}
            type={type}
            localization={localization}
            printMode={printMode}
            createReference={createShipmentReference}
            updateReference={updateShipmentReference}
            deleteReference={deleteShipmentReference}
            showLoader={showLoader}
            isForwarder={isForwarder}
        />
    );
};
const Container = styled.div`
    display: flex;
`;
const RefsContainer = styled.div`
    display: flex;
    max-width: 200px;
    overflow-y: overlay;
`;
const AddButton = styled.div`
    cursor: pointer;
    display: flex;
    align-items: center;
    span {
        font-size: 13px;
        margin-left: 5px;
    }
`;

const mapStateToProps = (state: RootState) => ({
    showLoader:
        state.loading.effects.shipments.createShipmentReference ||
        state.loading.effects.shipments.updateShipmentReference ||
        state.loading.effects.shipments.deleteShipmentReference,
    localization: localizationSelectors.singleShipmentPage(state).references
});

const mapDispatchToProps = (dispatch: any) => ({
    createShipmentReference: (shipmentNumber: string, reference: shipmentReference) =>
        dispatch.shipments.createShipmentReference({ shipmentNumber, reference }),
    updateShipmentReference: (shipmentNumber: string, reference: shipmentReference) =>
        dispatch.shipments.updateShipmentReference({ shipmentNumber, reference }),
    deleteShipmentReference: (shipmentNumber: string, referenceId: string) =>
        dispatch.shipments.deleteShipmentReference({ shipmentNumber, referenceId })
});

export default connect(mapStateToProps, mapDispatchToProps)(ShipmentReferencesItems);
