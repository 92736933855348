import React from 'react';
import { connect } from 'react-redux';
import styled from 'styled-components';
import { RootState } from '../../../../../state/store/store';
import { parseFilterFields, removeFilterItem, parsedFilterFieldType } from '../../utils';
import { allowedCompany } from '../../../../../models/entities/user';
import { userSelectors } from '../../../../../state/ducks/user/selectors';
import { product } from '../../../../../models/entities/product/product';
import { productsSelectors } from '../../../../../state/ducks/products/selectors';
import { filterFieldsType } from '../../../../../state/ducks/products/products';
import { partiesToDropdownOptions } from '../../../businessPartners/components/BusinessPartnerForm/BusinessPartnerFormConnected';
import { businessPartnersSelectors } from '../../../../../state/ducks/businessPartner/selectors';
import { businessPartner } from '../../../../../models/entities/businessPartner/businessPartner';

type Props = {
    filterFields: filterFieldsType | null;
    setProductFilters: (filter: filterFieldsType) => void;
    fetchProductsByFilter: (filter: filterFieldsType) => Array<product>;
    allowedCompanies: allowedCompany[];
    localization: any;
    companySupplier: businessPartner[];
    businessPartnerManufacturers: businessPartner[];
};

const FiltersStripe = ({
    filterFields,
    allowedCompanies,
    setProductFilters,
    fetchProductsByFilter,
    localization,
    companySupplier,
    businessPartnerManufacturers
}: Props) => {
    const suppliers = companySupplier?.concat(businessPartnerManufacturers);
    const renderFiltersStrip = () => {
        return parseFilterFields(filterFields || [], allowedCompanies, suppliers, localization)?.map((filter: parsedFilterFieldType, i) => {
            const isInactiveFilter = filter.field === 'isActive' && filter.originalValue === 'false';
            return (
                <FilterBadge key={i} isInactiveFilter={isInactiveFilter}>
                    {filter.parsedValue}
                    <FilterRemove
                        isInactiveFilter={isInactiveFilter}
                        className="material-icons"
                        onClick={() => {
                            if (filter.field === 'isActive') fetchProductsByFilter([]);
                            setProductFilters(removeFilterItem(filter, filterFields || []));
                        }}
                    >
                        close
                    </FilterRemove>
                </FilterBadge>
            );
        });
    };

    return <FiltersStripeContainer>{renderFiltersStrip()}</FiltersStripeContainer>;
};

interface FilterProps {
    isInactiveFilter: boolean;
}
const FiltersStripeContainer = styled.div`
    flex: 1;
    margin: 0 25px;
    font-size: 12px;
    display: flex;
    overflow-x: hidden;

    @media screen and (max-width: 768px) {
        display: none;
    }

    &:hover {
        overflow-x: overlay;
    }

    &::-webkit-scrollbar {
        height: 2px;
    }

    &::-webkit-scrollbar-track {
        background-color: white;
        border-radius: 10px;
    }

    &::-webkit-scrollbar-thumb {
        border-radius: 10px;
    }
`;

const FilterBadge = styled.span<FilterProps>`
    border-radius: 2vh;
    border: ${(props) => `1px solid ${props.isInactiveFilter ? '#ff7676' : '#008ac9'}`};
    color: ${(props) => (props.isInactiveFilter ? '#ff7676' : 'rgba(0, 0, 0, 0.65)')};
    font-size: 14px;
    height: 24px;
    padding: 3px 8px;
    margin-left: 10px;
    display: inline-flex;
    align-items: center;
    line-height: 1px;
    min-width: max-content;
`;

const FilterRemove = styled.span<FilterProps>`
    font-size: 12px;
    color: ${(props) => (props.isInactiveFilter ? '#ff7676' : '#008ac9')};
    margin-left: 5px;
    cursor: pointer;
`;

const mapStateToProps = (state: RootState) => ({
    filterFields: productsSelectors.filterFields(state),
    allowedCompanies: userSelectors.allowedCompanies(state),
    companySupplier: businessPartnersSelectors.businessPartnerSuppliers(state),
    businessPartnerManufacturers: businessPartnersSelectors.businessPartnerManufacturers(state)
});

const mapDispatchToProps = (dispatch: any) => ({
    setProductFilters: (filter: filterFieldsType) => dispatch.products.setProductFilters(filter),
    fetchProductsByFilter: (filter: filterFieldsType) => dispatch.products.fetchProductsByFilter(filter)
});

export default connect(mapStateToProps, mapDispatchToProps)(FiltersStripe);
