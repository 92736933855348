import React from 'react';
import { GridCellParams, GridColDef, GridValueFormatterParams } from '@material-ui/data-grid';
import styled from 'styled-components';
import { purchaseDemandItem } from '../../../../../../models/entities/purchaseDemand/purchaseDemandItem';
import CZTooltip from '../../../../../shared/CZTooltip/CZTooltip';
import NumberInput from '../../../../../shared/inputs/base/NumberInput';
import { currencyFormatter } from '../../../../../shared/inputs/form/CurrencyInput/CurrencyInput';
import MaterialPopover from '../../../../../shared/Popover/MaterialPopover';

export const ItemsGridColumns: (
    localization: any,
    changePdItemQuantity: (pdItemId: string, quantity: number) => void,
    items: purchaseDemandItem[],
    supplierPdItems: purchaseDemandItem[]
) => GridColDef[] = (
    localization: any,
    changePdItemQuantity: (pdItemId: string, quantity: number) => void,
    items: purchaseDemandItem[],
    supplierPdItems: purchaseDemandItem[]
) => [
    { field: 'productSku', headerName: localization.sku, flex: 1 },
    { field: 'name', headerName: localization.product_name, flex: 1 },
    {
        field: 'attributes',
        headerName: localization.attributes,
        flex: 1,
        renderCell: (params: GridCellParams) => {
            const value = (params.value as { [key: string]: string }) || undefined;
            if (!value) return <GridCell>-</GridCell>;

            return (
                <div style={{ width: '100%' }}>
                    <MaterialPopover
                        anchor={
                            <GridCell>
                                {Object.entries(value)
                                    .map(([key, name]) => `${key}: ${name}`)
                                    .join(', ')}
                            </GridCell>
                        }
                    >
                        <GridCell>
                            {Object.entries(value).map(([key, name], i) => (
                                <Detail key={`${params.row.id}-att-option-${i}`}>
                                    <DetailName>{key}:</DetailName>
                                    <DetailValue>{name}</DetailValue>
                                </Detail>
                            ))}
                        </GridCell>
                    </MaterialPopover>
                </div>
            );
        }
    },
    {
        field: 'quantity',
        headerName: localization.quantity,
        renderCell: (params: GridCellParams) => {
            const minOrderQuantity = Number(params.getValue(params.id, 'minOrderQuantity')) || 1;
            const quantity = Number(params.row.quantity) || minOrderQuantity;
            const isMinOrderValueExceeded = quantity < minOrderQuantity;

            const initPdItemIndex = supplierPdItems.findIndex((item) => item.id === params.row.id);
            const initLeftQuantity = Number(supplierPdItems[initPdItemIndex]?.leftQuantity) || 0;

            return (
                <CZTooltip text={`Minimum order value is ${minOrderQuantity}`} hidden={!isMinOrderValueExceeded}>
                    <div onClick={(e) => e.stopPropagation()}>
                        <NumberInput
                            onChange={(value: any) => {
                                changePdItemQuantity(params.row.id as string, Number(value) || minOrderQuantity);
                            }}
                            value={Number(items?.find((item) => item.id === params.row.id)?.leftQuantity) || minOrderQuantity}
                            placeHolder={localization.quantity}
                            style={{ width: '90px', color: isMinOrderValueExceeded ? 'red' : 'inherit' }}
                            min={1}
                            max={initLeftQuantity}
                        />
                    </div>
                </CZTooltip>
            );
        },
        width: 110
    },
    {
        field: 'price',
        flex: 1,
        headerName: localization.price,
        valueFormatter: (params: GridValueFormatterParams) =>
            params.value ? currencyFormatter(params.getValue(params.id, 'currency'))(params.value) : '-'
    },
    {
        field: 'currency',
        flex: 1,
        headerName: localization.total_cost,
        valueFormatter: (params: GridValueFormatterParams) =>
            params.getValue(params.id, 'price')
                ? currencyFormatter(params.value)(
                      (Number(params.getValue(params.id, 'price')) || 0) * (Number(params.getValue(params.id, 'quantity')) || 1) || 0
                  )
                : '-'
    }
];

const GridCell = styled.div`
    width: 100%;
    display: block;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
`;

const Detail = styled.div`
    display: flex;
    flex-wrap: wrap;
    margin: 10px 0;
`;

const DetailName = styled.div`
    color: #394372;
    font-weight: bold;
    margin-right: 5px;
`;

const DetailValue = styled.div`
    color: #757575;
`;
