import { RootState } from '../../store/store';
import { createSelector } from 'reselect';
import orderBy from 'lodash/orderBy';
import { purchaseOrdersSelectors } from '../purchaseOrders/selectors';
import { purchaseOrder } from '../../../models/entities/purchaseOrder/purchaseOrder';
import filterPurchaseOrders from './purchaseOrdersGridFilter';
import { numbersPattern } from '../../../utils/patterns';
import { gridSortCallback } from '../../../utils/sortArray';

const gridColumns = (state: RootState) => state.allPurchaseOrdersPage.gridColumns;
const fetchGridColumnsError = (state: RootState) => state.allPurchaseOrdersPage.fetchGridColumnsError;
const gridSort = (state: RootState) => state.allPurchaseOrdersPage.gridSort;
const gridFilter = (state: RootState) => state.allPurchaseOrdersPage.gridFilter;
const filterFields = (state: RootState) => state.allPurchaseOrdersPage.filterFields;
const pagination = (state: RootState) => state.allPurchaseOrdersPage.pagination;

const activeFiltersCount = createSelector(filterFields, (filterFields) => {
    if (!filterFields) return 0;
    return filterFields.length;
});

const activePurchaseOrdersCount = createSelector(purchaseOrdersSelectors.purchaseOrdersData, (purchaseOrdersData: Array<purchaseOrder>) => {
    return purchaseOrdersData.length;
});

const gridData = createSelector(
    purchaseOrdersSelectors.purchaseOrdersData,
    gridSort,
    gridFilter,
    filterFields,
    (purchaseOrdersData: Array<purchaseOrder>, gridSort, gridFilter, filterFields) => {
        if (gridFilter && gridFilter !== '' && gridFilter.length > 2) {
            purchaseOrdersData = purchaseOrdersData.filter((s) => JSON.stringify(s).toLocaleLowerCase().includes(gridFilter.toLocaleLowerCase()));
        }

        if (filterFields) {
            purchaseOrdersData = filterPurchaseOrders(purchaseOrdersData, filterFields);
        }

        if (gridSort) {
            switch (gridSort.column) {
                default:
                    purchaseOrdersData = orderBy(purchaseOrdersData, gridSortCallback(gridSort.column), [gridSort.direction]);
            }
        }

        return purchaseOrdersData;
    }
);

const filteredPOsCount = createSelector(gridData, (gridData) => {
    return gridData?.length || 0;
});

const currentGridDataPage = createSelector(pagination, gridData, (pagination, gridData) => {
    const { currentPage, rowsPerPage } = pagination;

    return gridData.slice(currentPage * rowsPerPage, currentPage * rowsPerPage + rowsPerPage);
});

export { default as allPurchaseOrdersPageSelectors } from './selectors';

export default {
    gridColumns,
    fetchGridColumnsError,
    gridSort,
    gridFilter,
    filterFields,
    pagination,
    filteredPOsCount,
    currentGridDataPage,
    gridData,
    activeFiltersCount,
    activePurchaseOrdersCount
};
