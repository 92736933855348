import React, { useEffect } from 'react';
import styled from 'styled-components';
import { Formik, Form } from 'formik';
import { notification } from 'antd';
import { SmileOutlined, FrownOutlined } from '@ant-design/icons';
import { RcFile } from 'antd/lib/upload/interface';
import { UploadRequestOption } from 'rc-upload/lib/interface';
import { userInfo } from '../../../models/entities/user';
import Button from '../../shared/SmallComponents/Button';
import FieldLabel from '../../shared/SmallComponents/FieldLabel';
import { FormTextInput } from '../../shared/inputs/form';
import Loader from '../../shared/SmallComponents/Loader';
import UploadImgButton from '../../shared/SmallComponents/UploadImgButton';

const PASSWORD_REGEX = '^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.{8,})';

type Props = {
    userInfo: userInfo;
    isLoadUserInfo: boolean;
    showPictureLoader: boolean;
    showDetailsLoader: boolean;
    showPasswordLoader: boolean;
    detailsError: string;
    passwordError: string;
    updateUserPicture: (file: RcFile) => void;
    updateUserDetails: (details: any) => boolean;
    updateUserPassword: (currentPassword: string, password: string, passwordConfirm: string) => boolean;
    localization: any;
};

interface formFieldsErrors {
    username?: string;
    firstName?: string;
    lastName?: string;
    phoneNumber?: string;
    role?: string;
}

interface passwordFieldsErrors {
    currentPassword?: string;
    password?: string;
    passwordConfirm?: string;
}

const EditProfilePage = ({
    userInfo,
    isLoadUserInfo,
    showPictureLoader,
    showDetailsLoader,
    showPasswordLoader,
    detailsError,
    passwordError,
    updateUserPicture,
    updateUserDetails,
    updateUserPassword,
    localization
}: Props) => {
    useEffect(() => {
        if (detailsError || passwordError) {
            openNotification('Error', true);
        }
    }, [detailsError, passwordError]);

    const setInitialValues = () => {
        return {
            username: userInfo.username,
            firstName: userInfo.firstName,
            lastName: userInfo.lastName,
            phoneNumber: userInfo.phoneNumber,
            role: userInfo.role
        };
    };
    const validate = (values: any) => {
        const errors: formFieldsErrors = {};

        if (values.username === '') errors.username = localization.errors.empty_field;
        if (values.firstName === '') errors.firstName = localization.errors.empty_field;
        if (values.lastName === '') errors.lastName = localization.errors.empty_field;
        if (values.phoneNumber === '') errors.phoneNumber = localization.errors.empty_field;
        if (values.role === '') errors.role = localization.errors.empty_field;
        return errors;
    };

    const submit = async (values: any) => {
        const response = await updateUserDetails({
            username: values.username,
            firstName: values.firstName,
            lastName: values.lastName,
            phoneNumber: values.phoneNumber,
            role: values.role
        });
        if (response) openNotification(localization.notification.details_has_been_updated);
    };

    const submitPasswordUpdate = async (values: any) => {
        const response = await updateUserPassword(values.currentPassword, values.password, values.passwordConfirm);

        if (response) openNotification(localization.notification.password_has_been_updated);
    };

    const validatePasswords = (values: any) => {
        const errors: passwordFieldsErrors = {};

        if (values.currentPassword === '') errors.currentPassword = localization.errors.empty_field;
        if (values.password === '') errors.password = localization.errors.empty_field;
        if (values.passwordConfirm === '') errors.passwordConfirm = localization.errors.empty_field;
        if (!values.password.match(PASSWORD_REGEX)) errors.password = localization.errors.pswd_invalid;
        if (values.password !== values.passwordConfirm) errors.passwordConfirm = localization.errors.pswd_match;
        return errors;
    };

    const openNotification = (title: string, fail: boolean = false) => {
        notification.open({
            placement: 'topLeft',
            message: title,
            style: {
                left: '250px'
            },
            top: 100,
            icon: fail ? <FrownOutlined style={{ color: '#108ee9' }} /> : <SmileOutlined style={{ color: '#108ee9' }} />
        });
    };

    return (
        <div>
            {!isLoadUserInfo && userInfo.username !== '' ? (
                <Container>
                    <UploadImgButton
                        imgUrl={userInfo.profileImgUrl || undefined}
                        isLoading={showPictureLoader}
                        customRequest={(options: UploadRequestOption<any>) => updateUserPicture(options.file as RcFile)}
                        localization={{ ...localization, ...localization.errors }}
                    />
                    <FormsContainer>
                        <DetailsFormContainer>
                            <Formik initialValues={setInitialValues()} validate={validate} onSubmit={submit}>
                                <Form>
                                    <FieldLabel>{localization.details_form.username}</FieldLabel>
                                    <FieldContainer>
                                        <FormTextInput name="username" placeHolder={localization.details_form.username} />
                                    </FieldContainer>
                                    <FieldLabel>{localization.details_form.first_name}</FieldLabel>
                                    <FieldContainer>
                                        <FormTextInput name="firstName" placeHolder={localization.details_form.first_name} />
                                    </FieldContainer>
                                    <FieldLabel>{localization.details_form.last_name}</FieldLabel>
                                    <FieldContainer>
                                        <FormTextInput name="lastName" placeHolder={localization.details_form.last_name} />
                                    </FieldContainer>
                                    <FieldLabel>{localization.details_form.phone_number}</FieldLabel>
                                    <FieldContainer>
                                        <FormTextInput name="phoneNumber" placeHolder={localization.details_form.phone_number} />
                                    </FieldContainer>
                                    <FieldLabel>{localization.details_form.role}</FieldLabel>
                                    <FieldContainer>
                                        <FormTextInput name="role" placeHolder={localization.details_form.role} />
                                    </FieldContainer>

                                    <Button minWidth="155px" height="auto" buttonType="filled" type="submit" margin="20px 0 0 0">
                                        {showDetailsLoader ? (
                                            <Loader width="20px" marginTop="0px" showText={false} />
                                        ) : (
                                            <span>{localization.details_form.submit_btn}</span>
                                        )}
                                    </Button>
                                </Form>
                            </Formik>
                            <ErrorMsg>{detailsError}</ErrorMsg>
                        </DetailsFormContainer>
                        <PasswordFormContainer>
                            <Formik
                                initialValues={{
                                    password: '',
                                    passwordConfirm: ''
                                }}
                                validate={validatePasswords}
                                onSubmit={submitPasswordUpdate}
                            >
                                <Form>
                                    <FieldLabel>{localization.password_form.current_pswd}</FieldLabel>
                                    <FieldContainer>
                                        <FormTextInput
                                            name="currentPassword"
                                            placeHolder={localization.password_form.current_pswd}
                                            isPassword={true}
                                        />
                                    </FieldContainer>
                                    <FieldLabel>{localization.password_form.new_pswd}</FieldLabel>
                                    <FieldContainer>
                                        <FormTextInput name="password" placeHolder={localization.password_form.new_pswd} isPassword={true} />
                                    </FieldContainer>
                                    <FieldLabel>{localization.password_form.confirm_pswd}</FieldLabel>
                                    <FieldContainer>
                                        <FormTextInput
                                            name="passwordConfirm"
                                            placeHolder={localization.password_form.confirm_pswd}
                                            isPassword={true}
                                        />
                                    </FieldContainer>
                                    <Button minWidth="155px" height="auto" buttonType="filled" type="submit" margin="20px 0 0 0">
                                        {showPasswordLoader ? (
                                            <Loader width="20px" marginTop="0px" showText={false} />
                                        ) : (
                                            <span>{localization.password_form.submit_btn}</span>
                                        )}
                                    </Button>
                                </Form>
                            </Formik>
                            <ErrorMsg>{passwordError}</ErrorMsg>
                        </PasswordFormContainer>
                    </FormsContainer>
                </Container>
            ) : (
                <Loader></Loader>
            )}
        </div>
    );
};

const Container = styled.div`
    width: 100%;
    padding: 30px;
    @media screen and (max-width: 768px) {
        padding: 30px 30px 70px 30px;
        width: 100%;
    }
`;
const FormsContainer = styled.div`
    display: flex;
    @media screen and (max-width: 768px) {
        flex-direction: column;
    }
`;
const PasswordFormContainer = styled.div`
    padding-left: 25px;
    flex-basis: 20%;
    @media screen and (max-width: 768px) {
        padding-left: 0px;
        padding-top: 30px;
        flex-basis: 100%;
    }
`;
const DetailsFormContainer = styled.div`
    flex-basis: 20%;
    padding-right: 25px;
    border-right: 1px solid #e4e4e4;
    @media screen and (max-width: 768px) {
        padding-right: 0;
        border: none;
        flex-basis: 100%;
    }
`;
const FieldContainer = styled.div`
    padding-bottom: 12px;
`;

const ErrorMsg = styled.div`
    color: red;
`;

export default EditProfilePage;
