import BaseController from '../..';
import { party } from '../../../../models/entities/common_subentities/party';
import IShipmentParties from './IShipmentParties';

export default class ShipmentParties extends BaseController implements IShipmentParties {
    constructor(baseUrl: string) {
        super(baseUrl);
    }
    async fetchShipmentParties(shipmentNumber: string) {
        const response = await this.client.get(`/shipment/parties/${shipmentNumber}`);
        return response;
    }
    async createShipmentParty(shipmentId: string, data: party) {
        const response = await this.client.post(`/shipment/parties`, JSON.stringify({ shipmentId, data }));
        return response;
    }
    async updateShipmentParty(shipmentId: string, data: party) {
        const response = await this.client.put(`/shipment/parties`, JSON.stringify({ shipmentId, data }));
        return response;
    }
    async deleteShipmentParty(shipmentId: string, partyId: number) {
        const response = await this.client.delete(`/shipment/parties`, JSON.stringify({ shipmentId, id: partyId }));
        return response;
    }
}
