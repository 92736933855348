import { FormikProps } from 'formik';
import React from 'react';
import { OrderFormValues } from '../OrderForm';

type Props = {
    formikProps: FormikProps<OrderFormValues>;
    localization: any;
};

const Items = (props: Props) => {
    return <div>Items</div>;
};

export default Items;
