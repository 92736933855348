import BaseController from '../..';
import { purchaseOrderReference } from '../../../../models/entities/purchaseOrder/purchaseOrderReference';
import IPurchaseOrderReferences from './IPurchaseOrderReferences';

export default class PurchaseOrderReferences extends BaseController implements IPurchaseOrderReferences {
    constructor(baseUrl: string) {
        super(baseUrl);
    }
    async createPurchaseOrderReference(purchaseOrderId: string, data: purchaseOrderReference) {
        const response = await this.client.post(`/purchaseOrder/references`, JSON.stringify({ purchaseOrderId, data }));
        return response;
    }
    async updatePurchaseOrderReference(purchaseOrderId: string, data: purchaseOrderReference) {
        const response = await this.client.put(`/purchaseOrder/references`, JSON.stringify({ purchaseOrderId, data }));
        return response;
    }
    async deletePurchaseOrderReference(purchaseOrderId: string, referenceId: number) {
        const response = await this.client.delete(`/purchaseOrder/references`, JSON.stringify({ purchaseOrderId, id: referenceId }));
        return response;
    }
}
