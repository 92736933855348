import React, { useState } from 'react';
import Menu, { TriggerProps } from '../../../shared/Menu/Menu';
import MenuItem from '../../../shared/Menu/MenuItem';
import exportIcon from '../../../../static/icons/download.svg';
import pdfIcon from '../../../../static/icons/documentsType/pdf.svg';
import excelIcon from '../../../../static/icons/documentsType/excel.svg';
import Button from '../../../shared/SmallComponents/Button';
import { exportTypes } from '../../../../state/ducks/allPurchaseDemandsPage/allPurchaseDemandsPage';
import styled from 'styled-components';
import { purchaseDemand } from '../../../../models/entities/purchaseDemand/purchaseDemand';
import { ColumnType } from '../../../shared/Grid/types/Column';
import ArrayToExcelModal from '../../../shared/ArrayToExcel/ArrayToExcelModal';
import { pickBy } from 'lodash';
import { ModalTriggerProps } from '../../../shared/Modal/Modal';
import Tooltip from '@material-ui/core/Tooltip';

type Props = {
    purchaseDemands: Array<purchaseDemand>;
    gridColumns: Array<ColumnType<purchaseDemand>>;
    localization: any;
    exportDataTable: (type: exportTypes) => void;
};
const ExportMenu = ({ localization, exportDataTable, purchaseDemands, gridColumns }: Props) => {
    const [isModalOpened, setIsModalOpened] = useState(false);

    const menuItems = [
        <ArrayToExcelModal<purchaseDemand>
            localization={{ ...localization.grid.columns, ...localization.exportOptions.export_excel_modal }}
            data={
                purchaseDemands.map((p) =>
                    pickBy(p, (value, key) => gridColumns.map((c) => c.field).includes(key as keyof purchaseDemand))
                ) as Array<purchaseDemand>
            }
            title={localization.exportOptions.export_excel_modal.title}
            fileName={localization.exportOptions.export_file_name}
            onCancelCallback={() => {
                setIsModalOpened(false);
            }}
            Trigger={(props: ModalTriggerProps) => (
                <MenuItem
                    onClick={(e) => {
                        e.stopPropagation();
                        setIsModalOpened(true);
                        props.onClick();
                    }}
                    icon={excelIcon}
                    text={localization.exportOptions.excel}
                />
            )}
        />
    ];
    return (
        <Menu
            position="bottom-center"
            arrowOnTrigger={false}
            options={menuItems}
            isModalOpened={isModalOpened}
            MenuTrigger={(props: TriggerProps) => (
                <Button width="32px" height="32px" borderRadius="50%" margin="7px" buttonType="hollow" onClick={props.onClick}>
                    <Tooltip title={localization.export_btn} aria-label="export">
                        <span className="material-icons-outlined">file_download</span>
                    </Tooltip>
                </Button>
            )}
        />
    );
};

const ExportLabel = styled.span`
    @media screen and (max-width: 768px) {
        display: none;
    }
`;
export default ExportMenu;
