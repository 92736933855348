import React from 'react';
import styled from 'styled-components';
import MaterialPopover from '../Popover/MaterialPopover';
import infoIcon from '../../../static/icons/info.svg';
import ObjectDetails, { Props as ObjectDetailsProps } from './ObjectDetails';

type Props = ObjectDetailsProps & {
    anchor?: React.ReactNode;
};
const ObjectDetailsPopover = ({ anchor, ...restProps }: Props) => {
    return (
        <MaterialPopover
            popoverLeft
            anchor={
                anchor ?? (
                    <InfoIconWrapper>
                        <InfoIcon src={infoIcon} />
                    </InfoIconWrapper>
                )
            }
        >
            <ObjectDetails {...restProps} />
        </MaterialPopover>
    );
};
const InfoIcon = styled.img`
    width: 20px;
    height: 20px;
`;
const InfoIconWrapper = styled.div`
    display: flex;
    position: relative;
    width: 25px;
    justify-content: center;
`;

export default ObjectDetailsPopover;
