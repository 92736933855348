import { GridCellParams, GridColDef, GridRowParams, GridValueFormatterParams } from '@material-ui/data-grid';
import React, { useEffect, useState } from 'react';
import { Link, useHistory } from 'react-router-dom';
import styled from 'styled-components';
import { purchaseOrder } from '../../../../../../models/entities/purchaseOrder/purchaseOrder';
import { shipment } from '../../../../../../models/entities/shipment/shipment';
import { eShipmentTypes } from '../../../../../../models/entities/shipment/shipmentTypes';
import { PagesRoutes } from '../../../../../../routing/PagesRoutes';
import { filterFieldsType } from '../../../../../../state/ducks/allShipmentsPage/allShipmentsPage';
import CZTooltip from '../../../../../shared/CZTooltip/CZTooltip';
import CompanyColor from '../../../../../shared/Grid/specialColumns/CompanyColor';
import ShipmentType from '../../../../../shared/Grid/specialColumns/ShipmentType';
import MuiDataGrid from '../../../../../shared/MuiDataGrid/MuiDataGrid';
import Loader from '../../../../../shared/SmallComponents/Loader';
import ShipmentRoute from '../../../../../shared/SmallComponents/ShipmentRoute';
import Button from '../../../../../shared/SmallComponents/Button';
import { purchaseOrderItem } from '../../../../../../models/entities/purchaseOrder/purchaseOrderItem';
import shipmentItem from '../../../../../../models/entities/shipment/shipmentItem';
import { poItemsToShipmentItems } from '../../../../singleShipment/ShipmentForm/sections/utils';
import CustomModal, { ModalProps } from '../../../../../shared/Modal/Modal';
import browserHistory from 'react-router';

type Props = {
    localization: any;
    selectedItems: purchaseOrderItem[];
    purchaseOrder: purchaseOrder;
    fetchShipmentsByFilter: (filter: filterFieldsType) => void;
    createShipmentItems: (shipmentId: string, items: shipmentItem[]) => void;
    shipments: shipment[];
    isLoading: boolean;
    fetchSinglePurchaseOrder: (purchaseOrderId: string) => Promise<purchaseOrder>;
    setModalVisible?: React.Dispatch<React.SetStateAction<boolean>>;
};

const MergePoItemsToShipmentModalContent = ({
    localization,
    createShipmentItems,
    selectedItems,
    purchaseOrder,
    fetchShipmentsByFilter,
    shipments,
    isLoading,
    setModalVisible,
    fetchSinglePurchaseOrder
}: Props) => {
    const [selectedShipmentId, setSelectedShipmentId] = useState<string | undefined>(undefined);
    const [isMerging, setIsMerging] = useState<boolean>(false);
    const [showGoBackModal, setShowGoBackModal] = useState<boolean>(false);

    const history = useHistory();

    const renderGoBackModal = () => {
        return (
            <CustomModal
                title={{ text: localization.dialog.title }}
                isOpen={true}
                Content={(setVisible?: React.Dispatch<React.SetStateAction<boolean>>) => {
                    return (
                        <div style={{ display: 'flex', justifyContent: 'space-around' }}>
                            <Button
                                onClick={() => {
                                    setModalVisible?.(false);
                                    fetchSinglePurchaseOrder(purchaseOrder.id);
                                }}
                            >
                                {localization.dialog.po}
                            </Button>
                            <Button
                                onClick={() => {
                                    history.push(PagesRoutes.Shipments + '/' + selectedShipmentId);
                                }}
                            >
                                {localization.dialog.shipment}
                            </Button>
                        </div>
                    );
                }}
                onCancelCallback={() => {
                    setShowGoBackModal(false);
                }}
                width="393px"
            />
        );
    };

    useEffect(() => {
        fetchShipmentsByFilter([
            { field: 'isActive', value: 'true' },
            { field: 'originCountry', value: purchaseOrder.originCountry },
            { field: 'destinationCountry', value: purchaseOrder.destinationCountry }
        ]);
    }, [purchaseOrder]);

    const ShipmentsGridColumns: GridColDef[] = [
        {
            field: 'CargoZoneNumber',
            headerName: '#',
            flex: 1,
            renderCell: (params: GridCellParams) => (
                <Link to={{ pathname: PagesRoutes.Shipments + '/' + params.row.id }} target="_blank">
                    {params.value}
                </Link>
            )
        },
        {
            field: 'typeCode',
            headerName: localization.fields.type,
            flex: 1,
            renderCell: (params: GridCellParams) => {
                const { typeCode, subTypeCode } = params.row;
                return (
                    <CZTooltip text={`${eShipmentTypes[typeCode] || typeCode}${subTypeCode.trim() !== '' ? ' - ' + subTypeCode : ''}`}>
                        <div>
                            <ShipmentType value={typeCode} />
                        </div>
                    </CZTooltip>
                );
            }
        },
        {
            field: 'companyId',
            headerName: localization.fields.company,
            flex: 1,
            renderCell: (params: GridCellParams) => {
                const { companyId, companyName } = params.row;
                return !companyId || !companyName ? '-' : <CompanyColor companyId={companyId} companyName={companyName} />;
            }
        },
        {
            field: 'shipperName',
            headerName: localization.fields.shipper,
            flex: 1
        },
        {
            field: 'forwarderName',
            headerName: localization.fields.forwarder,
            flex: 1
        },
        {
            field: 'lastEvent',
            headerName: localization.fields.last_event,
            flex: 1
        },
        {
            field: 'quantity',
            headerName: localization.fields.quantity,
            flex: 1
        },
        {
            field: 'volume',
            headerName: localization.fields.volume,
            flex: 1,
            valueFormatter: (params: GridValueFormatterParams) =>
                params.row.volume && params.row.volumeUnit ? `${Number(Number(params.row.volume).toFixed(3))} ${params.row.volumeUnit}` : '-'
        },
        {
            field: 'weight',
            headerName: localization.fields.weight,
            flex: 1,
            valueFormatter: (params: GridValueFormatterParams) =>
                params.row.weight && params.row.weightUnit ? `${Number(Number(params.row.weight).toFixed(3))} ${params.row.weightUnit}` : '-'
        }
    ];
    return (
        <>
            <MuiDataGrid
                columns={ShipmentsGridColumns.map((col) => ({ valueFormatter: (params: GridValueFormatterParams) => params.value || '-', ...col }))}
                rows={shipments.filter((s) => !['DELIVERY', 'DELIVERED'].includes(s.state))}
                //rows={shipments.filter(s => ['DRAFT', 'BID', 'ORDER'].includes(s.state))}
                autoHeight
                disableColumnFilter
                disableColumnMenu
                disableColumnReorder
                onSelectionModelChange={(newSelection) => {
                    if (newSelection.selectionModel?.[0]) {
                        setSelectedShipmentId(newSelection.selectionModel[0] as string);
                    }
                }}
                pageSize={10}
                pagination
                showFooter
                disableMultipleSelection
                loading={isLoading}
            />
            <ButtonsContainer>
                <Button
                    width="auto"
                    type="button"
                    buttonType="filled"
                    disabled={isMerging || !selectedShipmentId}
                    onClick={async () => {
                        if (selectedShipmentId) {
                            setIsMerging(true);
                            const shipmentItems: shipmentItem[] = poItemsToShipmentItems(
                                selectedItems.map((item) => ({ ...item, quantity: Number(item.quantity || 0) - Number(item.openedQuantity || 0) })),
                                purchaseOrder.id,
                                purchaseOrder.CargoZoneNumber
                            );
                            await createShipmentItems(selectedShipmentId, shipmentItems);
                            setIsMerging(false);
                            setShowGoBackModal(true);
                        }
                    }}
                >
                    {isMerging ? <Loader width="20px" marginTop="0px" showText={false} /> : <span>{localization.merge_btn}</span>}
                </Button>
            </ButtonsContainer>

            {showGoBackModal && renderGoBackModal()}
        </>
    );
};

const ButtonsContainer = styled.div`
    display: flex;
    justify-content: flex-end;
    margin-top: 20px;
`;

export default MergePoItemsToShipmentModalContent;
