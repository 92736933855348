import { IBoardItem, BoardColumnMap } from '../types/BoardColumn';

type State<T extends IBoardItem> = {
    columnMap: BoardColumnMap<T>;
    columnKeys: string[];
};

export function getColumnKeys<T extends IBoardItem>(columnMap: BoardColumnMap<T>): string[] {
    return Object.keys(columnMap);
}

type Action<T extends IBoardItem> = {
    type: 'REORDER';
    payload: BoardColumnMap<T>;
};

export type ReducerType<T extends IBoardItem> = (state: State<T>, action: Action<T>) => State<T>;

export function reducer<T extends IBoardItem>(state: State<T>, action: Action<T>): State<T> {
    if (action.type === 'REORDER') {
        return {
            columnMap: action.payload,
            columnKeys: getColumnKeys(action.payload)
        };
    }

    return state;
}
