import React from 'react';
import styled from 'styled-components';
import { orderParty } from '../../../../../models/entities/order/orderParty';
import countries from '../../../../../utils/countries-flags/countries';
import CountryOption from '../../../../shared/SmallComponents/CountryOption';

type Props = {
    party: orderParty;
    localization: any;
};
const PartySection = ({ party, localization }: Props) => {
    const partiesLocalization = localization.overview.parties;
    const countryName = countries.find((item) => item.code === party.country)?.name;
    const { country, city, address, contactName, email, phoneNumber } = party;

    const showAddress = country || city || address;
    const showContact = contactName || email || phoneNumber;

    return (
        <Container>
            <Header>{partiesLocalization[party.type]}</Header>
            <Detail>{party.name}</Detail>

            {showAddress && (
                <>
                    <DetailHeader>Address:</DetailHeader>
                    <Detail>
                        {party.country && <CountryOption country={countryName || party.country} code={party.country} removeBorder color="#008ac9" />}
                    </Detail>
                    <Detail>{party.city}</Detail>
                    <Detail>{party.address}</Detail>
                </>
            )}

            {showContact && (
                <>
                    <DetailHeader>Contact:</DetailHeader>
                    <Detail>{party.contactName}</Detail>
                    <Detail>{party.email}</Detail>
                    <Detail>{party.phoneNumber}</Detail>
                </>
            )}
        </Container>
    );
};

const Container = styled.div`
    color: #394372;
    margin-right: 40px;
`;

const Header = styled.div`
    font-weight: bolder;
    border-bottom: 1px solid #394372;
    width: fit-content;
    padding: 0 5px;
`;

const Detail = styled.div`
    margin: 4px 0;
    &:first-child {
        margin-top: 0;
    }
`;

const DetailHeader = styled.div`
    font-weight: bolder;
    margin: 15px 0 0 0;
`;

export default PartySection;
