import React, { PropsWithChildren } from 'react';
import styled from 'styled-components';
import TouchRipple from '@material-ui/core/ButtonBase/TouchRipple';

type Props = {
    style?: React.CSSProperties;
    color?: string;
};
const RippleContainer = ({ style, color, children }: PropsWithChildren<Props>) => {
    const rippleRef = React.useRef(null);
    const onRippleStart = (e: any) => {
        (rippleRef?.current as any)?.start(e);
    };
    const onRippleStop = (e: any) => {
        (rippleRef?.current as any)?.stop(e);
    };

    return (
        <Container style={style} onMouseDown={onRippleStart} onMouseUp={onRippleStop} onMouseLeave={onRippleStop} color={color}>
            {children}
            <TouchRipple ref={rippleRef} center={false} />
        </Container>
    );
};

const Container = styled.div<{ color?: string }>`
    display: inline-block;
    position: relative;
    height: fit-content;
    .MuiTouchRipple-root {
        color: ${(props) => props.color ?? '#000000d9'} !important;
    }
`;

export default RippleContainer;
