import React, { useEffect } from 'react';
import VirtualizedBoard from '../../shared/Board/VirtualizedBoard/VirtualizedBoard';
import { order, OrderStateType, updateOrderStatePayload } from '../../../models/entities/order';
import { RootState } from '../../../state/store/store';
import { connect } from 'react-redux';
import { ordersSelectors } from '../../../state/ducks/orders/selectors';
import { BoardColumnMap } from '../../shared/Board/types/BoardColumn';
import OrderItem from './components/OrderItem';
import { localizationSelectors } from '../../../state/ducks/localization/selectors';
import { userSelectors } from '../../../state/ducks/user/selectors';
import Loader from '../../shared/SmallComponents/Loader';
import styled from 'styled-components';
import GridPageHeader from '../../shared/PageTemplates/GridPage/components/GridPageHeader/GridPageHeader';

import ordersGridColumns from './ordersGridColumns';
import { paginationType } from '../../shared/PageTemplates/GridPage/components/GridPageHeader/models';
import { PagesRoutes } from '../../../routing/PagesRoutes';
import { orderFilters, orderFilterFields } from '../../../state/ducks/orders/gridFilters/types';
import FilterContent from './components/Filter/FilterContent';
import { parseFilterFields, removeFilterItem } from './components/Filter/utils';
import { allowedCompany } from '../../../models/entities/user';

type Props = {
    boardColumnMap: BoardColumnMap<order>;
    isFetchingOrders: boolean;
    fetchOrders: () => void;

    orders: order[];
    localization: any;
    activeOrdersCount: number;
    filteredOrdersCount: number;
    pagination: paginationType;
    filterFields: orderFilterFields | null;
    activeFiltersCount: number;
    allowedCompanies: allowedCompany[];
    setGridFilter: (text: string) => void;
    setCurrentPage: (page: number) => void;
    setRowsPerPage: (rowsPerPage: number) => void;
    setOrdersFilters: (filter: orderFilterFields) => void;
    updateOrderState: (payload: updateOrderStatePayload) => Promise<void>;
};
const OrdersBoardPageConnected = ({
    localization,
    boardColumnMap,
    isFetchingOrders,
    fetchOrders,

    orders,
    activeOrdersCount,
    filteredOrdersCount,
    filterFields,
    pagination,
    activeFiltersCount,
    allowedCompanies,
    setGridFilter,
    setCurrentPage,
    setRowsPerPage,
    setOrdersFilters,
    updateOrderState
}: Props) => {
    useEffect(() => {
        fetchOrders();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    if (isFetchingOrders) return <Loader />;

    return (
        <PageContainer>
            <GridPageHeader<order, orderFilters>
                data={orders}
                activeDataCount={activeOrdersCount}
                filteredDataCount={filteredOrdersCount}
                pagination={pagination}
                gridColumns={ordersGridColumns(localization)}
                currentGridFilter={null}
                localization={localization}
                isMobile={false}
                setGridFilter={setGridFilter}
                fetchData={fetchOrders}
                addBtn={{
                    title: localization.newOrder,
                    pathname: PagesRoutes.NewOrder
                }}
                filterStripeProps={{
                    parsedFilterFields: filterFields ? parseFilterFields(filterFields, allowedCompanies, localization) : undefined,
                    filterFields,
                    removeFilterItem: removeFilterItem,
                    setFilters: setOrdersFilters
                }}
                filterPopoverProps={{
                    activeFiltersCount,
                    FilterContent: FilterContent,
                    localization
                }}
                hidePagination
            />
            <BoardContainer>
                <VirtualizedBoard<order>
                    columnMap={boardColumnMap}
                    ItemContent={OrderItem}
                    onItemColumnChange={async (itemId, _sourceColumn, destColumn) => {
                        updateOrderState({
                            orderId: itemId,
                            state: destColumn as OrderStateType
                        });
                    }}
                />
            </BoardContainer>
        </PageContainer>
    );
};

const PageContainer = styled.div`
    height: 100%;
    width: 100%;
    display: flex;
    flex-direction: column;
`;

const BoardContainer = styled.div`
    flex: 1;
    width: 100%;
    overflow: auto;
`;

const mapStateToProps = (state: RootState) => ({
    localization: localizationSelectors.ordersPage(state),
    boardColumnMap: ordersSelectors.ordersBoardData(state),
    isFetchingOrders: ordersSelectors.isFetchingOrders(state),

    orders: ordersSelectors.currentGridDataPage(state),
    activeOrdersCount: ordersSelectors.activeOrdersCount(state),
    filteredOrdersCount: ordersSelectors.filteredOrdersCount(state),
    filterFields: ordersSelectors.filterFields(state),
    activeFiltersCount: ordersSelectors.activeFiltersCount(state),
    pagination: ordersSelectors.pagination(state),
    allowedCompanies: userSelectors.allowedCompanies(state)
});

const mapDispatchToProps = (dispatch: any) => ({
    fetchOrders: () => dispatch.orders.fetchOrders(),

    setGridFilter: (text: string) => dispatch.orders.setGridFilter(text),
    setCurrentPage: (page: number) => dispatch.orders.setCurrentPage(page),
    setRowsPerPage: (rowsPerPage: number) => dispatch.orders.setRowsPerPage(rowsPerPage),
    setOrdersFilters: (filters: orderFilterFields) => dispatch.orders.setOrdersFilters(filters),
    updateOrderState: (payload: updateOrderStatePayload) => dispatch.orders.updateOrderState(payload)
});

export default connect(mapStateToProps, mapDispatchToProps)(OrdersBoardPageConnected);
