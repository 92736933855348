import React from 'react';
import { filterFieldsType } from '../../../state/ducks/products/products';
import { allowedCompany } from '../../../models/entities/user';
import { GroupedOptions, Option } from '../../shared/inputs/base/DropDown';
import {
    FormTextArea,
    FormTextInput,
    FormDatePicker,
    FormDropDown,
    CurrencyInput,
    NumberUnitInput,
    DimensionInput,
    FormNumberInput,
    UserDropDownForm,
    FormCheckbox,
    FormTextEditor
} from '../../shared/inputs/form';
import { currencyFormatter } from '../../shared/inputs/form/CurrencyInput/CurrencyInput';
import { styled } from '../../shared/Theme/theme';
import { featureFlagsModel } from '../../../models/entities/businessPartner/settings';
import HsCodes from '../../shared/HsCodes/input';
import { hsCode } from '../../../models/entities/common_subentities/hsCode';
import { camalize, isErrorsEmpty } from '../../../utils/utils-functions';
import ViewHsCodes from '../../shared/HsCodes/view';
import { Link } from 'react-router-dom';
import { businessPartner } from '../../../models/entities/businessPartner/businessPartner';
import { renderCountryOption } from '../../shared/SmallComponents/CountryOption';
import countries from '../../../static/data/countries.json';
import Country from '../../shared/Grid/specialColumns/Country';
import SuppliersInput from './components/Form/SuppliersInput';
import { supplier } from '../../../models/entities/product/supplier';
import SuppliersGrid from './components/SuppliersGrid/SuppliersGrid';
import AsinInput from './components/Form/AsinInput';
import AsinView from './components/AsinsView/AsinView';
import { marketplaceAsin } from '../../../models/entities/product/product';
import { EditorState } from 'draft-js';

export type parsedFilterFieldType = {
    field: string;
    parsedValue: string;
    originalValue: any;
};

export const parseFilterFields = (
    filterFields: filterFieldsType,
    allowedCompanies: allowedCompany[],
    suppliers: businessPartner[],
    localization: any
) => {
    let parsedFilterFields: parsedFilterFieldType[] = [];
    filterFields.forEach(({ field, value }: { field: string; value: any }) => {
        switch (field) {
            case 'supplierId':
                value.forEach((type: string) => {
                    parsedFilterFields.push({
                        field,
                        parsedValue: suppliers.find((s) => s.id === type)?.name || '',
                        originalValue: type
                    });
                });
                break;
            case 'companyId':
                value.forEach((type: string) => {
                    parsedFilterFields.push({
                        field,
                        parsedValue: allowedCompanies.find((c) => c.companyId === type)?.companyName || '',
                        originalValue: type
                    });
                });
                break;
            case 'isActive':
                const parsedValue = (() => {
                    switch (value) {
                        case 'true':
                            return localization.active;
                        case 'false':
                            return localization.inactive;
                        default:
                            return localization.all;
                    }
                })();
                parsedFilterFields.push({ field, parsedValue, originalValue: value });
                break;
            default:
                parsedFilterFields.push({ field, parsedValue: value, originalValue: value });

                break;
        }
    });

    return parsedFilterFields;
};

export const removeFilterItem = (removedFilter: parsedFilterFieldType, filterFields: filterFieldsType) => {
    let newFilterFields: filterFieldsType = [...filterFields];
    const { field, originalValue } = removedFilter;
    switch (field) {
        case 'companyId':
        case 'supplierId':
            let arrayFilterIndex = newFilterFields.findIndex((filter) => filter.field === field);
            if (arrayFilterIndex > -1) {
                const newArray = (newFilterFields[arrayFilterIndex]?.value as string[]).filter((type) => type !== originalValue);
                newFilterFields[arrayFilterIndex] = {
                    field: field,
                    value: newArray
                };
                if (!newArray.length) {
                    newFilterFields.splice(arrayFilterIndex, 1);
                }
            }
            break;
        default:
            let filterIndex = newFilterFields.findIndex((filter) => filter.field === field && filter.value === originalValue);
            if (filterIndex > -1) {
                newFilterFields.splice(filterIndex, 1);
            }
            break;
    }

    return newFilterFields;
};
export const validateHsCodes = (hsCodes: Array<hsCode>, errors: any, localizations: any) => {
    const requiredFieldMessage = localizations.required_field_message;
    if (!errors.hsCodes) errors.hsCodes = [];
    hsCodes.forEach(() => {
        errors.hsCodes.push({});
    });

    hsCodes.forEach((item, index) => {
        const { code, percent } = item;

        if (percent && !code) errors.hsCodes[index].code = requiredFieldMessage;
    });
    if (isErrorsEmpty(errors.hsCodes)) delete errors.hsCodes;
};

export const validateAsin = (asin: Array<marketplaceAsin>, errors: any, localizations: any) => {
    const requiredFieldMessage = localizations.required_field_message;
    const asinMaxLengthMessage = localizations.max_length_message(20);

    if (!errors.asin) errors.asin = [];
    asin.forEach(() => {
        errors.asin.push({});
    });

    asin.forEach((item, index) => {
        const { marketplaceId, asin: asinValue } = item;

        if (!marketplaceId) errors.asin[index].marketplaceId = requiredFieldMessage;
        if (!asinValue) errors.asin[index].asin = requiredFieldMessage;

        if (asinValue?.length > 20) errors.asin[index].asin = asinMaxLengthMessage;
    });
    if (isErrorsEmpty(errors.asin)) delete errors.asin;
};

export const validateSuppliers = (suppliers: Array<Partial<supplier>>, isMultipleSuppliers: boolean, errors: any, localizations: any) => {
    if (!isMultipleSuppliers) return delete errors.suppliers;

    const requiredFieldMessage = localizations.required_field_message;
    if (!errors.suppliers) errors.suppliers = [];
    suppliers.forEach(() => {
        errors.suppliers.push({});
    });

    suppliers.forEach((supplier, index) => {
        const { sku, supplierId, cost, costCurrency } = supplier;

        if (!sku) errors.suppliers[index].sku = requiredFieldMessage;
        if (!supplierId) errors.suppliers[index].supplierId = requiredFieldMessage;
        if (!cost) errors.suppliers[index].cost = requiredFieldMessage;
        if (!costCurrency) errors.suppliers[index].costCurrency = '';
    });
    if (isErrorsEmpty(errors.suppliers)) delete errors.suppliers;
};

export type editableDetail = {
    name: string;
    label?: string;
    type?: editTypes;
    options?: Option[] | GroupedOptions;
    placeholder?: string;
    onChange?: any;
    onDelete?: (id: string) => void;
    suffix?: JSX.Element;
    referenceName?: string;
    min?: number;
    step?: number;
    max?: number;
    localizations?: any;
    hidden?: boolean;
};
export type editTypes =
    | 'dropdown'
    | 'date'
    | 'currency'
    | 'unit'
    | 'checkbox'
    | 'textarea'
    | 'texteditor'
    | 'dimension'
    | 'number'
    | 'users'
    | 'link'
    | 'hsCodes'
    | 'countries'
    | 'suppliers'
    | 'asin';

export const renderEditable = (
    editable: editableDetail,
    values: any,
    renderFieldLabel: (...params: any) => JSX.Element,
    viewMode?: boolean,
    setFieldValue?: (field: string, value: any) => void
) => {
    const { type, name, options, placeholder, label, onChange, onDelete, referenceName, min, step, max, suffix, localizations, hidden } = editable;
    const _placeholder = placeholder || label || name;
    let comp;
    if (viewMode) {
        let value = values[referenceName || name];
        if (type === 'currency' && value) value = currencyFormatter(values[`${name}Currency`])(values[name]);
        if (type === 'unit' && value) value = `${values[`${name}`]} ${values[`${name}Unit`]}`;
        if (type === 'asin') value = <AsinView asin={value} />;
        if (
            type === 'dimension' &&
            values[camalize(`${name}Length`)] &&
            values[camalize(`${name}Width`)] &&
            values[camalize(`${name}Height`)] &&
            values[camalize(`${name}DimensionUnit`)]
        )
            value = `${values[camalize(`${name}Length`)]}*${values[camalize(`${name}Width`)]}*${values[camalize(`${name}Height`)]} ${
                values[camalize(`${name}DimensionUnit`)]
            }`;
        comp = <Value className="value">{value || '-'}</Value>;
        if (value && type === 'link')
            comp = (
                <Link to={{ pathname: value }} target="_blank">
                    {value}
                </Link>
            );
        if (type === 'hsCodes') comp = <ViewHsCodes hsCodes={value} localizations={localizations} />;
        if (type === 'suppliers') comp = <SuppliersGrid suppliers={value} localization={localizations} />;
        if (type === 'countries') {
            if (value) {
                const country = value;
                const countryName = countries.find((item) => item.code === country)?.name;
                comp = <Country value={country} text={countryName} />;
            }
        }
    } else comp = <FormTextInput name={name} placeHolder={_placeholder} style={{ width: '100%' }} />;
    if (!viewMode)
        switch (type) {
            case 'dropdown':
                comp = (
                    <FormDropDown
                        name={name}
                        style={{ width: '100%' }}
                        placeHolder={_placeholder}
                        options={options || []}
                        showSearch={true}
                        onChange={onChange}
                    />
                );
                break;
            case 'countries':
                comp = (
                    <FormDropDown
                        name={name}
                        style={{ width: '100%' }}
                        placeHolder={_placeholder}
                        options={options || []}
                        showSearch={true}
                        onChange={onChange}
                        optionRenderer={renderCountryOption}
                    />
                );
                break;
            case 'date':
                comp = <FormDatePicker name={name} style={{ width: '100%' }} placeHolder={_placeholder} showTime />;
                break;
            case 'checkbox':
                comp = (
                    <FormCheckbox name={name} style={{ width: '100%' }}>
                        {''}
                    </FormCheckbox>
                );
                break;
            case 'currency':
                comp = (
                    <CurrencyInput
                        currencyValue={values[`${name}Currency`] ?? 'USD'}
                        numberInputName={name}
                        numberPlaceholder={_placeholder}
                        unitInputName={`${name}Currency`}
                        numberInputWidth="100%"
                    />
                );
                break;
            case 'unit':
                comp = (
                    <NumberUnitInput
                        numberInputName={name}
                        numberInputWidth="100%"
                        min={0}
                        step={0.001}
                        unitInputName={`${name}Unit`}
                        numberPlaceholder={_placeholder}
                        unitOptions={options || []}
                        onUnitChange={onChange}
                    />
                );
                break;
            case 'dimension':
                comp = (
                    <DimensionInput
                        lengthInputName={camalize(`${name}Length`)}
                        widthInputName={camalize(`${name}Width`)}
                        heightInputName={camalize(`${name}Height`)}
                        unitInputName={camalize(`${name}DimensionUnit`)}
                        unitPlaceHolder={_placeholder}
                        options={options || []}
                        onUnitChange={onChange}
                    />
                );
                break;
            case 'textarea':
                comp = <FormTextArea name={name} placeHolder={_placeholder} style={{ width: '100%' }} />;
                break;
            case 'texteditor':
                comp = (
                    <FormTextEditor
                        id={`${name}-text-editor`}
                        name={name}
                        editorState={values[name]}
                        placeholder={_placeholder}
                        style={{ width: '100%' }}
                        onChange={(state: EditorState) => {
                            console.log(state);

                            setFieldValue?.(name, state);
                        }}
                    />
                );
                break;
            case 'number':
                comp = (
                    <FormNumberInput name={name} placeHolder={_placeholder} min={min} step={step} max={max} width="100%" style={{ width: '100%' }} />
                );
                break;
            case 'users':
                comp = <UserDropDownForm name={name} placeHolder={_placeholder} users={undefined} showSearch />;
                break;
            case 'hsCodes':
                comp = <HsCodes name={name} onChange={onChange} values={values} codePlaceHolder={_placeholder} localizations={localizations} />;
                break;
            case 'suppliers':
                comp = (
                    <SuppliersInput
                        name={name}
                        onChange={onChange}
                        onDelete={onDelete}
                        values={values}
                        setFieldValue={setFieldValue}
                        codePlaceHolder={_placeholder}
                        localizations={localizations}
                        suppliersOptions={(options || []) as Option[]}
                    />
                );
                break;
            case 'asin':
                comp = (
                    <AsinInput
                        name={name}
                        onChange={onChange}
                        values={values}
                        setFieldValue={setFieldValue}
                        localizations={localizations}
                        placeholder={_placeholder}
                    />
                );
                break;
        }
    return (
        <>
            <div>{renderFieldLabel(label, false)}</div>
            {suffix ? (
                <div style={{ display: 'flex' }}>
                    <span style={{ flex: 1 }}>{comp}</span>
                    {suffix}
                </div>
            ) : (
                comp
            )}
        </>
    );
};
const Value = styled.div`
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
`;

type onChangeType = { [key: string]: (value: any) => void };
type onDeleteType = { [key: string]: (id: string) => void };

type suffixType = { [key: string]: JSX.Element };
type fieldsArrProps = {
    values?: any;
    localizations: any;
    options?: {
        supplier: Option[];
        category: Option[];
        allowedCompanies: Option[];
        countries: Option[];
    };
    onChange?: onChangeType;
    onDelete?: onDeleteType;
    suffix?: suffixType;
    featureFlags?: featureFlagsModel;
};
export type sectionType = { title?: string; items: Array<editableDetail>; hide?: boolean };
type fieldsArrType = Array<sectionType>;
export const formFieldsArr: (props: fieldsArrProps) => fieldsArrType = ({
    localizations,
    options,
    onChange,
    onDelete,
    featureFlags,
    suffix,
    values
}) => [
    {
        hide: options?.allowedCompanies.length === 0,
        items: [
            {
                name: 'companyId',
                label: localizations.company,
                type: 'dropdown',
                options: options?.allowedCompanies,
                onChange: onChange?.companyId
            }
        ]
    },
    {
        items: [
            {
                name: 'name',
                label: localizations.name
            }
        ]
    },
    {
        items: [
            {
                name: 'userId',
                label: localizations.user,
                type: 'users'
            },
            {
                name: 'sku',
                label: localizations.sku
            }
        ]
    },
    {
        items: [
            {
                name: 'description',
                label: localizations.description,
                type: 'texteditor'
            }
        ]
    },
    {
        items: [
            {
                hidden: values?.isMultipleSuppliers,
                name: 'supplierId',
                label: localizations.supplier,
                type: 'dropdown',
                options: options?.supplier,
                onChange: onChange?.supplierId,
                suffix: suffix?.supplierId
            },
            {
                name: 'isMultipleSuppliers',
                label: 'Multiple Suppliers',
                type: 'checkbox'
            }
        ]
    },
    {
        hide: !values?.isMultipleSuppliers,
        items: [
            {
                name: 'suppliers',
                label: 'Suppliers',
                placeholder: 'Sku',
                type: 'suppliers',
                options: options?.supplier,
                onChange: onChange?.suppliers,
                onDelete: onDelete?.suppliers,
                localizations: localizations
            }
        ]
    },
    {
        items: [
            {
                name: 'categoryId',
                label: localizations.category,
                type: 'dropdown',
                options: options?.category,
                suffix: suffix?.categoryId
            }
        ]
    },
    {
        items: [
            {
                name: 'brand',
                label: localizations.brand
            },
            {
                name: 'madeIn',
                label: localizations.madeIn,
                type: 'countries',
                options: options?.countries
            }
        ]
    },
    {
        items: [
            {
                hidden: values?.isMultipleSuppliers,
                name: 'cost',
                label: localizations.cost,
                type: 'currency'
            },
            {
                name: 'retailPrice',
                label: localizations.retailPrice,
                type: 'currency'
            },
            {
                name: 'salePrice',
                label: localizations.salePrice,
                type: 'currency'
            }
        ]
    },
    {
        items: [
            {
                name: 'weight',
                label: localizations.weight,
                type: 'unit',
                options: [
                    { value: 'KG', text: 'KG' },
                    { value: 'G', text: 'G' },
                    { value: 'LB', text: 'LB' }
                ],
                onChange: onChange?.weightUnit
            },
            {
                name: '',
                placeholder: localizations.CM,
                label: localizations.dimension,
                type: 'dimension',
                options: [
                    { value: 'CM', text: localizations.CM },
                    { value: 'IN', text: localizations.IN }
                ]
            }
        ]
    },
    {
        items: [
            {
                name: 'masterCartonWeight',
                label: localizations.masterCartonWeight,
                type: 'unit',
                options: [
                    { value: 'KG', text: 'KG' },
                    { value: 'LB', text: 'LB' }
                ],
                onChange: onChange?.masterCartonWeightUnit
            },
            {
                name: 'masterCarton',
                placeholder: localizations.CM,
                label: localizations.masterCartonDimension,
                type: 'dimension',
                options: [
                    { value: 'CM', text: localizations.CM },
                    { value: 'IN', text: localizations.IN }
                ]
            },
            {
                name: 'unitsPerMasterCarton',
                type: 'number',
                label: localizations.unitsPerMasterCarton
            }
        ]
    },
    {
        items: [
            {
                hidden: values?.isMultipleSuppliers,
                name: 'productionDays',
                type: 'number',
                label: localizations.productionDays
            },
            {
                name: 'hsCodes',
                label: localizations.hs_codes,
                placeholder: localizations.code,
                type: 'hsCodes',
                onChange: onChange?.hsCodes,
                localizations: localizations
            }
        ]
    },
    {
        items: [
            {
                hidden: values?.isMultipleSuppliers,
                name: 'minOrderQuantity',
                type: 'number',
                label: localizations.minOrderQuantity,
                min: 1
            },
            {
                name: 'minOrderQuantityAlert',
                type: 'number',
                label: localizations.minOrderQuantityAlert
            }
        ]
    },
    {
        title: localizations.amazon_title,
        hide: !featureFlags?.AMAZON,
        items: [
            {
                name: 'asin',
                label: 'Asin',
                placeholder: 'Asin',
                type: 'asin',
                onChange: onChange?.asin,
                onDelete: onDelete?.asin,
                localizations: localizations
            },
            {
                name: 'fnsku',
                label: localizations.fnsku
            }
        ]
    },
    {
        hide: !featureFlags?.AMAZON,
        items: [
            {
                name: 'rank',
                label: localizations.rank,
                type: 'number',
                min: 0.0,
                step: 0.1,
                max: 5.0
            },
            {
                name: 'numberOfRatings',
                label: localizations.numberOfRatings,
                type: 'number'
            }
        ]
    },
    {
        hide: !featureFlags?.AMAZON,
        items: [
            {
                name: 'fbaFee',
                label: localizations.fbaFee,
                type: 'currency'
            },
            {
                name: 'amazonPercentFee',
                label: localizations.amazonPercentFee,
                type: 'number',
                min: 0.0,
                step: 0.1
            }
        ]
    },
    {
        items: [
            {
                name: 'note',
                viewMode: true,
                label: localizations.note,
                type: 'textarea'
            }
        ]
    }
];
export const overviewFieldsArr: (props: fieldsArrProps) => fieldsArrType = ({ localizations, featureFlags, values }) => [
    {
        items: [
            {
                name: 'companyName',
                label: localizations.company
            }
        ]
    },
    {
        items: [
            {
                name: 'sku',
                label: localizations.sku
            }
        ]
    },
    {
        items: [
            {
                name: 'description',
                label: localizations.description
            },
            {
                name: 'madeIn',
                label: localizations.madeIn,
                type: 'countries'
            }
        ]
    },
    {
        items: [
            {
                hidden: values?.isMultipleSuppliers,
                name: 'supplierId',
                label: localizations.supplier,
                referenceName: 'supplierName'
            },
            {
                name: 'categoryName',
                label: localizations.category
            },
            {
                name: 'brand',
                label: localizations.brand
            }
        ]
    },
    {
        hide: !values?.isMultipleSuppliers,
        items: [
            {
                name: 'suppliers',
                label: 'Suppliers',
                type: 'suppliers',
                localizations: localizations
            }
        ]
    },
    {
        items: [
            {
                hidden: values?.isMultipleSuppliers,
                name: 'cost',
                label: localizations.cost,
                type: 'currency'
            },
            {
                name: 'retailPrice',
                label: localizations.retailPrice,
                type: 'currency'
            },
            {
                name: 'salePrice',
                label: localizations.salePrice,
                type: 'currency'
            }
        ]
    },
    {
        items: [
            {
                name: 'weight',
                label: localizations.weight,
                type: 'unit'
            },
            {
                name: '',
                label: localizations.dimension,
                type: 'dimension'
            }
        ]
    },
    {
        items: [
            {
                name: 'masterCartonWeight',
                label: localizations.masterCartonWeight,
                type: 'unit'
            },
            {
                name: 'masterCarton',
                label: localizations.masterCartonDimension,
                type: 'dimension'
            },
            {
                name: 'unitsPerMasterCarton',
                label: localizations.unitsPerMasterCarton,
                type: 'number'
            }
        ]
    },
    {
        items: [
            {
                hidden: values?.isMultipleSuppliers,
                name: 'minOrderQuantity',
                type: 'number',
                label: localizations.minOrderQuantity
            },
            {
                name: 'minOrderQuantityAlert',
                type: 'number',
                label: localizations.minOrderQuantityAlert
            },
            {
                hidden: values?.isMultipleSuppliers,
                name: 'productionDays',
                type: 'number',
                label: localizations.productionDays
            },
            {
                name: 'hsCodes',
                label: localizations.hs_codes,
                type: 'hsCodes',
                localizations: localizations
            }
        ]
    },
    {
        title: localizations.amazon_title,
        hide: !featureFlags?.AMAZON,
        items: [
            {
                name: 'asin',
                label: localizations.asin,
                type: 'asin'
            },
            {
                name: 'fnsku',
                label: localizations.fnsku
            }
        ]
    },
    {
        hide: !featureFlags?.AMAZON,
        items: [
            {
                name: 'rank',
                label: localizations.rank
            },
            {
                name: 'numberOfRatings',
                label: localizations.numberOfRatings
            }
        ]
    },
    {
        hide: !featureFlags?.AMAZON,
        items: [
            {
                name: 'fbaFee',
                label: localizations.fbaFee,
                type: 'currency'
            },
            {
                name: 'amazonPercentFee',
                label: localizations.amazonPercentFee
            }
        ]
    },
    {
        items: [
            {
                name: 'note',
                label: localizations.note
            }
        ]
    }
];
