import React from 'react';
import { event } from '../../../../models/entities/common_subentities/event';
import { settings, shipment, shipmentSettingsFunc } from '../../../../models/entities/shipment/shipment';
import { localizationSelectors } from '../../../../state/ducks/localization/selectors';
import { shipmentsSelectors } from '../../../../state/ducks/shipments/selectors';
import { RootState } from '../../../../state/store/store';
import { connect } from 'react-redux';
import { singleShipmentSelectors } from '../../../../state/ducks/singleShipment/selectors';
import { userSelectors } from '../../../../state/ducks/user/selectors';
import EventFormConnected, { formMode } from '../../commonSubEntities/events/EventForm/EventFormConnected';

type Props = {
    shipment?: shipment;
    event?: event;
    mode: formMode;
    localizations?: any;
    settings: shipmentSettingsFunc;
    openedFromModal?: {
        setVisible: React.Dispatch<React.SetStateAction<boolean>>;
    };
    confirmation?: { setConfirm: React.Dispatch<React.SetStateAction<boolean>> };
    createShipmentEvent: (shipmentNumber: string, event: event) => void;
    updateShipmentEvent: (shipmentNumber: string, event: event) => void;
    showLoader?: boolean;
    UserCompanyID?:string;
};

const ShipmentEventFormConnected = ({
    shipment,
    event,
    localizations,
    mode,
    settings,
    openedFromModal,
    confirmation,
    createShipmentEvent,
    updateShipmentEvent,
    showLoader,
    UserCompanyID
}: Props) => {
    return (
        <EventFormConnected<shipment, settings>
            entity={shipment}
            event={event}
            localizations={localizations}
            mode={mode}
            settings={settings(shipment)}
            openedFromModal={openedFromModal}
            confirmation={confirmation}
            createEvent={createShipmentEvent}
            updateEvent={updateShipmentEvent}
            showLoader={showLoader}
            UserCompanyID={UserCompanyID}
        />
    );
};

const mapStateToProps = (state: RootState) => ({
    localizations: localizationSelectors.singleShipmentPage(state),
    settings: shipmentsSelectors.settingsByLanguage(state),
    shipment: singleShipmentSelectors.shipment(state),
    showLoader: state.loading.effects.shipments.createShipmentEvent || state.loading.effects.shipments.updateShipmentEvent,
    userInfo: userSelectors.userInfo(state),
    UserCompanyID:userSelectors.usersCompanyId(state)

});

const mapDispatchToProps = (dispatch: any) => ({
    createShipmentEvent: (shipmentNumber: string, event: event) => dispatch.shipments.createShipmentEvent({ shipmentNumber, event }),
    updateShipmentEvent: (shipmentNumber: string, event: event) => dispatch.shipments.updateShipmentEvent({ shipmentNumber, event })
});

export default connect(mapStateToProps, mapDispatchToProps)(ShipmentEventFormConnected);
