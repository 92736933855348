import React from 'react';
import { FormikProps } from 'formik';
import FormInputWrapper from '../../../../shared/Form/FormInputWrapper';
import { FormDropDown, FormTextArea, FormTextInput } from '../../../../shared/inputs/form';
import { Option, enumToDropdownOptions } from '../../../../shared/inputs/base/DropDown';
import { eShipmentTypes } from '../../../../../models/entities/shipment/shipmentTypes';
import { eIncoterms } from '../../../../../models/entities/shipment/incoterms';
import { eContainerCapacity, eCustomsSubTypes, eInlandSubTypes } from '../../../../../models/entities/shipment/containerCapacity';
import { carriersDropDownOptionsByShipmentType, getAllowedTypes } from '../../../singleShipment/ShipmentForm/sections/utils';
import { renderShipmentTypeOption } from '../../../../shared/SmallComponents/ShipmentTypeOption';
import { shipmentType } from '../../../../../models/entities/shipment/shipment';
import { createTariffPayload } from '../../../../../models/entities/tariff';

type Props = {
    formikProps: FormikProps<createTariffPayload>;
    allowedCompaniesDropDownOptions: Option[];
    businessPartnersDropDownOptions: Option[];
    allowedShipmentTypes: Array<string>;
    localization: any;
};

const shipmentTypesDropDownOptions = enumToDropdownOptions(eShipmentTypes);
const containerCapacityDropDownOptions = enumToDropdownOptions(eContainerCapacity);
const inlandSubTypesDropDownOptions = enumToDropdownOptions(eInlandSubTypes);
const customsSubTypesDropDownOptions = enumToDropdownOptions(eCustomsSubTypes);
const incotermsDropDownOptions = enumToDropdownOptions(eIncoterms);

const General = ({ formikProps, allowedCompaniesDropDownOptions, businessPartnersDropDownOptions, allowedShipmentTypes, localization }: Props) => {
    const { values, setFieldValue } = formikProps;

    const fieldsLocalization = localization.fields;
    const allowedShipmentTypesOptions: Option[] = getAllowedTypes(shipmentTypesDropDownOptions, allowedShipmentTypes);
    const shipmentTypeCodeOptions: Option[] = ['OI', 'OEX', 'OE', 'DROP_OCEAN'].includes(values.shipmentTypeCode)
        ? containerCapacityDropDownOptions
        : ['INLAND'].includes(values.shipmentTypeCode)
        ? inlandSubTypesDropDownOptions
        : ['CUSTOMS'].includes(values.shipmentTypeCode)
        ? customsSubTypesDropDownOptions
        : [];

    return (
        <>
            <FormInputWrapper fieldName="companyId" localization={fieldsLocalization} isMandatory>
                <FormDropDown
                    name="companyId"
                    style={{ width: '200px', marginRight: '15px' }}
                    placeHolder={fieldsLocalization.companyId.placeholder}
                    options={allowedCompaniesDropDownOptions}
                />
            </FormInputWrapper>
            <FormInputWrapper fieldName="businessPartnerId" localization={fieldsLocalization}>
                <FormDropDown
                    name="businessPartnerId"
                    style={{ width: '200px', marginRight: '15px' }}
                    placeHolder={fieldsLocalization.businessPartnerId.placeholder}
                    options={businessPartnersDropDownOptions}
                />
            </FormInputWrapper>
            <FormInputWrapper fieldName="shipmentTypeCode" localization={fieldsLocalization} isMandatory>
                <div style={{ display: 'flex' }}>
                    <FormDropDown
                        name="shipmentTypeCode"
                        style={{ width: '200px', marginRight: '15px' }}
                        placeHolder={fieldsLocalization.shipmentTypeCode.placeholder}
                        options={allowedShipmentTypesOptions}
                        optionRenderer={renderShipmentTypeOption}
                        onChange={(value: any) => {
                            setFieldValue('shipmentSubTypeCode', '');
                            if (value === 'RAIL') {
                                setFieldValue('shipmentSubTypeCode', 'FCL');
                            }
                            if (value === 'EXPRESS') {
                                setFieldValue('incoterms', eIncoterms.DAP);
                            }
                        }}
                    />
                    {!!shipmentTypeCodeOptions?.length && (
                        <FormDropDown
                            name="shipmentSubTypeCode"
                            style={{ width: '120px' }}
                            placeHolder={fieldsLocalization.shipmentSubTypeCode.placeholder}
                            options={shipmentTypeCodeOptions}
                        />
                    )}
                </div>
            </FormInputWrapper>
            <FormInputWrapper fieldName="incoterms" localization={fieldsLocalization} isMandatory>
                <FormDropDown
                    name="incoterms"
                    style={{ width: '200px', marginRight: '15px' }}
                    placeHolder={fieldsLocalization.incoterms.placeholder}
                    options={incotermsDropDownOptions}
                    allowClear
                    mode="tags"
                />
            </FormInputWrapper>
            <FormInputWrapper fieldName="carrier" localization={fieldsLocalization}>
                <FormDropDown
                    name="carrier"
                    style={{ width: '200px', marginRight: '15px' }}
                    placeHolder={fieldsLocalization.carrier.placeholder}
                    options={carriersDropDownOptionsByShipmentType(values.shipmentTypeCode as shipmentType)}
                    allowClear
                    mode="tags"
                />
            </FormInputWrapper>
            <FormInputWrapper fieldName="transitTime" localization={fieldsLocalization}>
                <FormTextInput
                    type="number"
                    name="transitTime"
                    style={{ width: '200px', marginRight: '15px' }}
                    placeHolder={fieldsLocalization.transitTime.placeholder}
                />
            </FormInputWrapper>
            <FormInputWrapper fieldName="forwarderTariffNumber" localization={fieldsLocalization}>
                <FormTextInput
                    name="forwarderTariffNumber"
                    style={{ width: '200px', marginRight: '15px' }}
                    placeHolder={fieldsLocalization.forwarderTariffNumber.placeholder}
                />
            </FormInputWrapper>
            <FormInputWrapper fieldName="note" localization={fieldsLocalization}>
                <FormTextArea name="note" style={{ width: '200px', marginRight: '15px' }} placeHolder={fieldsLocalization.note.placeholder} />
            </FormInputWrapper>
        </>
    );
};

export default General;
