import React from 'react';
import styled from 'styled-components';
import { ordersStatistics } from '../../../models/entities/order/ordersStatistics';
import { orderFilterFields } from '../../../state/ducks/orders/gridFilters/types';
import OrdersOrientedSummarySection from './Summary/OrdersOrientedSummarySection';

type Props = {
    localization: any;
    ordersLocalization: any;
    userName: string;
    ordersStatistics: ordersStatistics;
    setOrdersFilters: (filter: orderFilterFields) => void;
};
const OrdersOrientedDashboardPage = ({ localization, ordersLocalization, userName, ordersStatistics, setOrdersFilters }: Props) => {
    return (
        <PageContainer>
            <TitleWrapper>
                <div className="title">
                    {localization.title}, {userName}
                </div>
                <div className="sub-title">{localization.sub_title}</div>
            </TitleWrapper>
            <OrdersOrientedSummarySection
                localization={localization.orderOrientedSummary}
                ordersLocalization={ordersLocalization}
                ordersStatistics={ordersStatistics}
                setOrdersFilters={setOrdersFilters}
                // data={{
                //     activeOrders: 16,
                //     exceptions: 3,
                //     warnings: 2,
                //     activeOrdersValue: [
                //         { sum: 10521, currency: 'USD' },
                //         { sum: 4220, currency: 'EUR' }
                //     ],
                //     ordersAmountByMonth: {
                //         '01/21': 15,
                //         '02/21': 25,
                //         '03/21': 20,
                //         '04/21': 35,
                //         '05/21': 25,
                //         '06/21': 40
                //     },
                //     exceptionsByMonth: {
                //         '01/21': {
                //             DHL: 3,
                //             UPS: 3
                //         },
                //         '02/21': {
                //             DHL: 2,
                //             UPS: 4
                //         },
                //         '03/21': {
                //             DHL: 8,
                //             UPS: 5
                //         },
                //         '04/21': {
                //             DHL: 9,
                //             UPS: 2
                //         },
                //         '05/21': {
                //             DHL: 7,
                //             UPS: 7
                //         },
                //         '06/21': {
                //             DHL: 6,
                //             UPS: 9
                //         }
                //     },
                //     pieCharts: {
                //         ordersByCountry: {
                //             data: [
                //                 {
                //                     name: 'Israel',
                //                     value: 80
                //                 },
                //                 {
                //                     name: 'United States',
                //                     value: 100
                //                 }
                //             ]
                //         },
                //         ordersByCarrier: {
                //             data: [
                //                 {
                //                     name: 'FEDEX',
                //                     value: 90
                //                 },
                //                 {
                //                     name: 'UPS',
                //                     value: 90
                //                 }
                //             ]
                //         },
                //         ordersByState: {
                //             data: [
                //                 { name: 'NEW', value: 10 },
                //                 { name: 'READY_TO_SHIP', value: 10 },
                //                 { name: 'PICKED_UP', value: 10 },
                //                 { name: 'IN_TRANSIT', value: 10 },
                //                 { name: 'DELIVERED', value: 10 },
                //                 { name: 'EXCEPTION', value: 10 },
                //                 { name: 'EXCEPTION_HANDLING', value: 10 },
                //                 { name: 'CANCELED', value: 10 }
                //             ]
                //         }
                //     }
                // }}
            />
        </PageContainer>
    );
};

const PageContainer = styled.div``;

const TitleWrapper = styled.div`
    color: ${(props) => props.theme.colors.sectionTitle};
    padding: 30px 30px 15px 30px;
    width: 50%;

    @media screen and (max-width: 1490px) {
        width: 100%;
    }

    .title {
        font-size: 25px;
        font-weight: 500;
    }

    .sub-title {
        font-size: 13px;
        white-space: pre-line;
    }
`;

export default OrdersOrientedDashboardPage;
