import React from 'react';
import styled from 'styled-components';
import { tariff, createTariffPayload } from '../../../models/entities/tariff';
import { PagesRoutes } from '../../../routing/PagesRoutes';
import { Option } from '../../shared/inputs/base/DropDown';
import BackButton from '../../shared/SmallComponents/BackButton';
import TariffOverview from './components/TariffOverview';

type Props = {
    tariff: tariff;
    allowedCompaniesDropDownOptions: Option[];
    businessPartnersDropDownOptions: Option[];
    allowedShipmentTypes: Array<string>;
    localization: any;
    updateTariff: (tariffId: string, tariff: createTariffPayload) => Promise<void>;
};
const SingleTariffPage = ({
    tariff,
    allowedCompaniesDropDownOptions,
    businessPartnersDropDownOptions,
    allowedShipmentTypes,
    localization,
    updateTariff
}: Props) => {
    return (
        <PageWrapper>
            <SectionWrapper>
                <div style={{ paddingLeft: 30 }}>
                    <BackButton style={{ marginLeft: '-30px' }} toPath={PagesRoutes.Tariffs} text={'Back'} />
                </div>
            </SectionWrapper>
            <TariffOverview
                tariff={tariff}
                allowedCompaniesDropDownOptions={allowedCompaniesDropDownOptions}
                businessPartnersDropDownOptions={businessPartnersDropDownOptions}
                allowedShipmentTypes={allowedShipmentTypes}
                localization={localization}
                updateTariff={updateTariff}
            />
        </PageWrapper>
    );
};

const PageWrapper = styled.div`
    width: 100%;
`;

const SectionWrapper = styled.div`
    display: flex;
    flex-direction: column;
    padding: 24px 18px 18px 18px;
    border-bottom: solid 1px ${(props) => props.theme.colors.grayDefaultBorder};
    justify-content: space-between;
`;

export default SingleTariffPage;
