import React from 'react';
import { GridColumns, GridValueFormatterParams } from '@material-ui/data-grid';
import MuiDataGrid from '../../../../shared/MuiDataGrid/MuiDataGrid';
import styled from 'styled-components';
import { shipment } from '../../../../../models/entities/shipment/shipment';
import { drawers } from '../../../../../state/ducks/header/header';
import Button from '../../../../shared/SmallComponents/Button';
import { connect } from 'react-redux';
import { RootState } from '../../../../../state/store/store';
import { tariffsSelectors } from '../../../../../state/ducks/tariffs/selectors';
import { createShipmentTariffQuotationsPayload } from '../../../../../models/entities/tariff/payloads/createShipmentTariffQuotationsPayload';
import Loader from '../../../../shared/SmallComponents/Loader';
import { currencyFormatter } from '../../../../shared/inputs/form/CurrencyInput/CurrencyInput';
import moment from 'moment';
import { calcShipmentItemsTotalVolumeWeight } from '../ShipmentItems/utils';

type Props = {
    shipment: shipment;
    isCreatingShipmentTariffQuotations: boolean;
    setDrawerOpen: (open: drawers, params?: any) => void;
    createShipmentTariffQuotations: (payload: createShipmentTariffQuotationsPayload) => Promise<void>;
};
const TariffQuotationsGrid = ({ isCreatingShipmentTariffQuotations, setDrawerOpen, shipment, createShipmentTariffQuotations }: Props) => {
    const {
        volume: itemsTotalVolume,
        weight: itemsTotalWeight,
        quantity: itemsTotalQuantity
    } = calcShipmentItemsTotalVolumeWeight(shipment.items || []);

    const tariffQuotationsGridColumns: GridColumns = [
        {
            field: 'validTill',
            headerName: 'Valid Till',
            flex: 1,
            valueFormatter: (params: GridValueFormatterParams) =>
                params.value ? moment(new Date(params.value as string)).format('DD/MM/YY hh:mm') : '-'
        },
        {
            field: 'shipmentType',
            headerName: 'Shipment Type',
            flex: 1
        },
        {
            field: 'freightPrice',
            headerName: 'Freight Price',
            flex: 1,
            valueFormatter: (params: GridValueFormatterParams) => currencyFormatter(params.row.currency)(params.value)
        },
        {
            field: 'freeDays',
            headerName: 'Free Days',
            flex: 1,
            valueFormatter: (params: GridValueFormatterParams) => params.value || '-'
        },
        {
            field: 'note',
            headerName: 'Note',
            flex: 1,
            valueFormatter: (params: GridValueFormatterParams) => params.value || '-'
        },
        {
            field: 'state',
            headerName: 'State',
            flex: 1
        },
        {
            hide: true,
            field: 'createdAt',
            headerName: 'Created At',
            flex: 1
        }
    ];
    return (
        <div>
            <Button
                buttonType="filled"
                onClick={async () => {
                    const payload: createShipmentTariffQuotationsPayload = {
                        shipmentId: shipment.id,
                        shipmentTypeCode: shipment.typeCode,
                        shipmentSubTypeCode: shipment.subTypeCode,
                        incoterms: shipment.incoterms,
                        originCountry: shipment.originCountry,
                        originStation: shipment.originStation,
                        destinationCountry: shipment.destinationCountry,
                        destinationStation: shipment.destinationStation,
                        totalWeight: Number(shipment.weight) || Number(itemsTotalWeight.weight) || 0,
                        totalVolume: Number(shipment.volume) || Number(itemsTotalVolume.volume) || 0,
                        amoutOfContainers: shipment.containers?.length || 0
                    };

                    await createShipmentTariffQuotations(payload);
                }}
            >
                {isCreatingShipmentTariffQuotations ? <Loader width="20px" marginTop="0px" showText={false} /> : 'Find instant quotations'}
            </Button>
            <MuiDataGrid
                sortModel={[{ field: 'createdAt', sort: 'desc' }]}
                columns={tariffQuotationsGridColumns}
                rows={shipment.tariffQuotations || []}
                autoHeight
                disableColumnFilter
                disableColumnMenu
                disableColumnReorder
                onRowClick={(params) => setDrawerOpen('TARIFF_QUOTATION', { tariffQuotation: params.row, shipmentId: shipment.id })}
            />
        </div>
    );
};

const GridCell = styled.div`
    width: 100%;
    display: block;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
`;

const mapStateToProps = (state: RootState) => ({
    isCreatingShipmentTariffQuotations: tariffsSelectors.isCreatingShipmentTariffQuotations(state)
});

const mapDispatchToProps = (dispatch: any) => ({
    createShipmentTariffQuotations: (payload: createShipmentTariffQuotationsPayload) => dispatch.tariffs.createShipmentTariffQuotations(payload)
});

export default connect(mapStateToProps, mapDispatchToProps)(TariffQuotationsGrid);
