import BaseController from '../..';
import IShipmentHistory from './IShipmentHistory';

export default class ShipmentHistory extends BaseController implements IShipmentHistory {
    constructor(baseUrl: string) {
        super(baseUrl);
    }
    async fetchShipmentHistoryById(shipmentNumber: string) {
        const response = await this.client.get(`/shipment/history/${shipmentNumber}`);
        return response;
    }
}
