import React, { useEffect, useState } from 'react';
import { Form, Formik } from 'formik';
import { RootState } from '../../../../../state/store/store';
import { connect } from 'react-redux';
import { styled } from '../../../../shared/Theme/theme';
import { ePurchaseOrderMode } from '../../../../../models/entities/purchaseOrder/purchaseOrderMode';
import { eIncoterms } from '../../../../../models/entities/purchaseOrder/incoterms';
import { eOpenDates } from '../../../../../models/entities/purchaseOrder/open_dates';
import countries from '../../../../../static/data/countries.json';
import airports from '../../../../../static/data/airports.json';
import Button from '../../../../shared/SmallComponents/Button';
import { localizationSelectors } from '../../../../../state/ducks/localization/selectors';
import { getDatesRange } from '../../../../../utils/date';
import { filterFieldsType } from '../../../../../state/ducks/allPurchaseOrdersPage/allPurchaseOrdersPage';
import { FormDatePicker, FormDropDown, FormTextInput } from '../../../../shared/inputs/form';
import { enumToDropdownOptions, Option } from '../../../../shared/inputs/base/DropDown';
import { renderCountryOption } from '../../../../shared/SmallComponents/CountryOption';
import FieldLabel from '../../../../shared/SmallComponents/FieldLabel';
import { Flex } from '../../../singlePurchaseOrder/PurchaseOrderForm/PurchaseOrderForm';
import { allPurchaseOrdersPageSelectors } from '../../../../../state/ducks/allPurchaseOrdersPage/selectors';
import { purchaseDemandsSelectors } from '../../../../../state/ducks/purchaseDemands/selectors';
import { purchaseOrdersSelectors } from '../../../../../state/ducks/purchaseOrders/selectors';

type Props = {
    localizations: any;
    filterFields: filterFieldsType | null;
    onCancel: () => void;
    setPurchaseOrderFilters: (filter: filterFieldsType) => void;
    purchaseOrderStateOptions: { text: string; value: string; color: string }[];
};

type formFilterFields = {
    state?: string[];
    mode?: string[];
    incoterms?: string[];
    forwarderName?: string;
    fromDate?: Date;
    toDate?: Date;
    originCountry?: string;
    originStation?: string;
    destinationCountry?: string;
    destinationStation?: string;
};

const FilterContent = ({ localizations, filterFields, onCancel, setPurchaseOrderFilters, purchaseOrderStateOptions }: Props) => {
    const [formFieldsValues, setFormFieldsValues] = useState<formFilterFields>({});

    useEffect(() => {
        const filterFieldsValues: formFilterFields = {};
        filterFields?.forEach(({ field, value }: { field: string; value: any }) => {
            (filterFieldsValues as any)[field] = value;
        });
        setFormFieldsValues(filterFieldsValues);
    }, [filterFields]);

    const purchaseOrderTypesDropDownOptions = enumToDropdownOptions(ePurchaseOrderMode);
    const incotermsDropDownOptions = enumToDropdownOptions(eIncoterms);
    const openDatesDropDownOptions = enumToDropdownOptions(eOpenDates);

    const countriesDropDownOptions = countries.map((country) => ({
        text: country.name,
        value: country.code
    }));

    const setInitialValues = () => {
        return formFieldsValues;
    };
    const validate = (values: any) => {
        const errors: any = {};
        return errors;
    };
    const submit = (values: any) => {
        const filter: filterFieldsType = [];
        Object.keys(formFieldsValues).forEach((item) => {
            if (item !== 'calcDate' && (formFieldsValues as any)[item]) filter.push({ field: item, value: (formFieldsValues as any)[item] });
        });

        setPurchaseOrderFilters(filter);
        onCancel();
    };

    const resetForm = () => {
        setFormFieldsValues({});
        setPurchaseOrderFilters([]);
    };

    return (
        <Formik initialValues={setInitialValues()} validate={validate} onSubmit={submit}>
            {({ values, setFieldValue }: any) => {
                let airportsDestOptions: Array<Option> = [];
                let airportsOrigOptions: Array<Option> = [];
                if (values.destinationCountry)
                    airportsDestOptions = airports
                        .filter((airport) => airport.iso === values.destinationCountry)
                        .map((airport) => ({
                            text: airport.name ? airport.name : airport.iata,
                            value: airport.iata
                        }));
                if (values.originCountry)
                    airportsOrigOptions = airports
                        .filter((airport) => airport.iso === values.originCountry)
                        .map((airport) => ({
                            text: airport.name ? airport.name : airport.iata,
                            value: airport.iata
                        }));
                return (
                    <FormContainer>
                        <Container>
                            <FieldContainer data-popover-bug>
                                <FieldLabel>{localizations.incoterms}</FieldLabel>
                                <FormDropDown
                                    preventPopoverBug={true}
                                    name="incoterms"
                                    placeHolder={localizations.incoterms}
                                    options={incotermsDropDownOptions}
                                    allowClear
                                    mode="multiple"
                                    onChange={(value: string[]) => setFormFieldsValues({ ...formFieldsValues, incoterms: value })}
                                    value={formFieldsValues.incoterms}
                                />
                            </FieldContainer>
                            <FieldContainer data-popover-bug>
                                <FieldLabel>{'State'}</FieldLabel>
                                <FormDropDown
                                    preventPopoverBug={true}
                                    name="state"
                                    placeHolder={'State'}
                                    options={purchaseOrderStateOptions}
                                    allowClear
                                    mode="multiple"
                                    onChange={(value: string[]) => setFormFieldsValues({ ...formFieldsValues, state: value })}
                                    value={formFieldsValues.state}
                                />
                            </FieldContainer>
                            <FieldContainer>
                                <FieldLabel>Forwarder</FieldLabel>
                                <FormTextInput
                                    name="forwarderName"
                                    placeHolder={localizations.forwarder}
                                    onChange={(value: string) => {
                                        setFormFieldsValues({ ...formFieldsValues, forwarderName: value });
                                    }}
                                    value={formFieldsValues.forwarderName || ''}
                                    allowClear
                                />
                            </FieldContainer>
                            <FieldContainer data-popover-bug>
                                <FieldLabel>{localizations.open_date}</FieldLabel>
                                <FormDropDown
                                    preventPopoverBug={true}
                                    name="calcDate"
                                    placeHolder={localizations.open_date}
                                    options={openDatesDropDownOptions}
                                    onChange={(value: string) => {
                                        const range = getDatesRange(value);
                                        setFieldValue('fromDate', range.from);
                                        setFieldValue('toDate', range.to);
                                        setFormFieldsValues({ ...formFieldsValues, fromDate: range.from, toDate: range.to });
                                    }}
                                    allowClear
                                />
                            </FieldContainer>
                            {values.calcDate === 'CUSTOM' && (
                                <>
                                    <FieldContainer data-popover-bug>
                                        <FieldLabel>{localizations.from_date}</FieldLabel>
                                        <FormDatePicker
                                            name="fromDate"
                                            placeHolder={localizations.from_date}
                                            onChange={(value: Date) => setFormFieldsValues({ ...formFieldsValues, fromDate: value })}
                                            value={formFieldsValues.fromDate}
                                            preventPopoverBug={true}
                                        />
                                    </FieldContainer>
                                    <FieldContainer data-popover-bug>
                                        <FieldLabel>{localizations.to_date}</FieldLabel>
                                        <FormDatePicker
                                            name="toDate"
                                            placeHolder={localizations.to_date}
                                            onChange={(value: Date) => setFormFieldsValues({ ...formFieldsValues, toDate: value })}
                                            value={formFieldsValues.toDate}
                                            preventPopoverBug={true}
                                        />
                                    </FieldContainer>
                                </>
                            )}
                            <FieldContainer data-popover-bug>
                                <FieldLabel>{localizations.origin_country}</FieldLabel>
                                <FormDropDown
                                    preventPopoverBug={true}
                                    name="originCountry"
                                    placeHolder={localizations.origin_country}
                                    options={countriesDropDownOptions}
                                    optionRenderer={renderCountryOption}
                                    showSearch={true}
                                    onChange={(value: string) => setFormFieldsValues({ ...formFieldsValues, originCountry: value })}
                                    value={formFieldsValues.originCountry}
                                    allowClear
                                />
                            </FieldContainer>
                            <FieldContainer data-popover-bug>
                                <FieldLabel>{localizations.origin_station}</FieldLabel>
                                <FormDropDown
                                    showSearch
                                    preventPopoverBug={true}
                                    name="originStation"
                                    placeHolder={localizations.origin_station}
                                    options={airportsOrigOptions}
                                    onChange={(value: string) => setFormFieldsValues({ ...formFieldsValues, originStation: value })}
                                    value={formFieldsValues.originStation}
                                    allowClear
                                />
                            </FieldContainer>
                            <Divider />
                            <FieldContainer data-popover-bug>
                                <FieldLabel>{localizations.destination_country}</FieldLabel>
                                <FormDropDown
                                    preventPopoverBug={true}
                                    name="destinationCountry"
                                    placeHolder={localizations.destination_country}
                                    options={countriesDropDownOptions}
                                    optionRenderer={renderCountryOption}
                                    showSearch={true}
                                    onChange={(value: string) => setFormFieldsValues({ ...formFieldsValues, destinationCountry: value })}
                                    value={formFieldsValues.destinationCountry}
                                    allowClear
                                />
                            </FieldContainer>
                            <FieldContainer data-popover-bug>
                                <FieldLabel>{localizations.destination_station}</FieldLabel>
                                <FormDropDown
                                    showSearch
                                    preventPopoverBug={true}
                                    name="destinationStation"
                                    placeHolder={localizations.destination_station}
                                    options={airportsDestOptions}
                                    onChange={(value: string) => setFormFieldsValues({ ...formFieldsValues, destinationStation: value })}
                                    value={formFieldsValues.destinationStation}
                                    allowClear
                                />
                            </FieldContainer>
                        </Container>
                        <FormFooter>
                            <Button minWidth="55px" type="button" onClick={onCancel}>
                                <span>{localizations.cancel_btn}</span>
                            </Button>
                            <Flex>
                                <Button minWidth="55px" type="button" onClick={resetForm}>
                                    <span>{localizations.reset_btn}</span>
                                </Button>
                                <Button minWidth="55px" buttonType="filled" type="submit">
                                    <span>{localizations.filter_btn}</span>
                                </Button>
                            </Flex>
                        </FormFooter>
                    </FormContainer>
                );
            }}
        </Formik>
    );
};
const FormContainer = styled(Form)`
    min-width: 500px;
    @media screen and (max-width: 768px) {
        min-width: 100%;
    }
`;
const FieldContainer = styled.div`
    margin-bottom: 12px;
    flex-basis: 45%;
    width: 0;
    padding: 0 10px;
    @media screen and (max-width: 768px) {
        flex-basis: 100%;
    }
`;
const Divider = styled.div`
    width: 100%;
    margin-bottom: 12px;
    border-top: 1px solid rgba(0, 0, 0, 0.06);
    @media screen and (max-width: 768px) {
        display: none;
    }
`;
const Container = styled.div`
    width: 100%;
    max-height: 420px;
    overflow: auto;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-around;
    @media screen and (max-width: 768px) {
        height: calc(100vh - 70px);
        padding: 4px 12px;
        max-height: 100%;
    }
`;

const FormFooter = styled.div`
    position: absolute;
    width: 100%;
    display: flex;
    justify-content: space-between;
    height: 70px;
    padding: 17px 30px;
    border-top: 1px solid #eaeaea;
    left: 0;
    right: 0;
    bottom: -70px;
    background: white;
    box-shadow: 0 9px 12px 1px rgb(0 0 0 / 9%);
    button {
        padding: 15px;
    }
    @media screen and (max-width: 768px) {
        bottom: 0;
        button {
            span {
                display: -webkit-box;
            }
        }
    }
`;

const mapStateToProps = (state: RootState) => ({
    localizations: localizationSelectors.allPurchaseOrdersPage(state).filter,
    filterFields: allPurchaseOrdersPageSelectors.filterFields(state),
    purchaseOrderStateOptions: purchaseOrdersSelectors.purchaseOrderStateOptions(state)
});

const mapDispatchToProps = (dispatch: any) => ({
    setPurchaseOrderFilters: (filter: filterFieldsType) => dispatch.allPurchaseOrdersPage.setPurchaseOrderFilters(filter)
});

export default connect(mapStateToProps, mapDispatchToProps)(FilterContent);
