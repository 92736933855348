import React, { FunctionComponent, useEffect, useReducer } from 'react';
import 'react-virtualized/styles.css';
import styled from '@emotion/styled';

import { DragDropContext, DropResult, DraggableLocation } from 'react-beautiful-dnd';

import { reorderColumnMap } from './reorder';
import BoardColumn from './BoardColumn';
import { IBoardItem, BoardColumnMap } from '../types/BoardColumn';
import { ReducerType, reducer, getColumnKeys } from './state';

export type ItemContentProps<T extends IBoardItem> = {
    item: T;
    isItemOpen: boolean;
    toggleItemOpen: () => void;
    measure?: () => void;
};

type Props<T extends IBoardItem> = {
    columnMap: BoardColumnMap<T>;
    ItemContent: FunctionComponent<ItemContentProps<T>>;
    onItemColumnChange?: (itemId: string, sourceGroupId: string, destinationGroupId: string) => Promise<void>;
};
function VirtualizedBoard<T extends IBoardItem>({ columnMap, ItemContent, onItemColumnChange }: Props<T>) {
    const [state, dispatch] = useReducer<ReducerType<T>>(reducer, { columnMap, columnKeys: getColumnKeys(columnMap) });

    useEffect(() => dispatch({ type: 'REORDER', payload: columnMap }), [columnMap]);
    async function onDragEnd(result: DropResult) {
        if (!result.destination) {
            return;
        }
        const source: DraggableLocation = result.source;
        const destination: DraggableLocation = result.destination;

        // Item dropped at the same location - do nothing.
        if (source.droppableId === destination.droppableId && source.index === destination.index) {
            return;
        }

        if (source.droppableId !== destination.droppableId && onItemColumnChange) {
            onItemColumnChange(result.draggableId, source.droppableId, destination.droppableId);
            return;
        }

        const updated = reorderColumnMap({
            columnMap: state.columnMap,
            source,
            destination
        });

        dispatch({ type: 'REORDER', payload: updated.columnMap });
    }

    return (
        <DragDropContext onDragEnd={onDragEnd}>
            <Container>
                {state.columnKeys.map((key: string) => (
                    <BoardColumn key={key} columnId={key} ItemContent={ItemContent} columnData={state.columnMap[key]} />
                ))}
            </Container>
        </DragDropContext>
    );
}

const Container = styled.div`
    display: flex;
    height: 100%;
`;

export default VirtualizedBoard;
