import { GridCellParams, GridColDef } from '@material-ui/data-grid';
import React from 'react';
import { pdItemPoRef } from '../../../../models/entities/purchaseDemand/purchaseDemandItem';
import CustomModal, { ModalTriggerProps } from '../../../shared/Modal/Modal';
import MuiDataGrid from '../../../shared/MuiDataGrid/MuiDataGrid';
import { Link } from 'react-router-dom';
import { PagesRoutes } from '../../../../routing/PagesRoutes';

type Props = {
    pdItemPoRefs: Array<pdItemPoRef>;
    onCancelCallback?: () => void;
    Trigger: (props: ModalTriggerProps) => JSX.Element;
    localization: any;
};

const PdItemPoRefsModal = ({ pdItemPoRefs, onCancelCallback, Trigger, localization }: Props) => {
    const columns: GridColDef[] = [
        {
            field: 'czNumber',
            headerName: '#',
            flex: 1,
            renderCell: (params: GridCellParams) => (
                <Link to={{ pathname: PagesRoutes.PurchaseOrders + '/' + params.getValue(params.id, 'id') }}>{params.value}</Link>
            )
        },
        {
            field: 'quantity',
            headerName: localization.quantity,
            flex: 1
        }
    ];
    return (
        <CustomModal
            title={{ text: localization.pos_refs }}
            Trigger={Trigger}
            Content={(setVisible?: React.Dispatch<React.SetStateAction<boolean>>, setConfirm?: React.Dispatch<React.SetStateAction<boolean>>) => {
                return (
                    <MuiDataGrid
                        columns={columns}
                        rows={pdItemPoRefs}
                        disableSelectionOnClick
                        disableColumnFilter
                        disableColumnSelector
                        disableColumnMenu
                        autoHeight
                    />
                );
            }}
            width="700px"
            onCancelCallback={onCancelCallback}
        />
    );
};

export default PdItemPoRefsModal;
