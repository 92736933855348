import BaseController from '../..';
import IShipmentFollow from './IShipmentFollow';

export default class ShipmentFollow extends BaseController implements IShipmentFollow {
    constructor(baseUrl: string) {
        super(baseUrl);
    }
    async followShipment(shipmentId: string, userPicture: string) {
        const response = await this.client.post(`/shipment/followers`, JSON.stringify({ shipmentId, userPicture }));
        return response;
    }
    async unFollowShipment(shipmentId: string) {
        const response = await this.client.delete(`/shipment/followers`, JSON.stringify({ shipmentId }));
        return response;
    }
    async getMyFollowingInvitations() {
        const response = await this.client.get(`/shipment/followers/invitations`);
        return response;
    }
}
