import React from 'react';
import { purchaseOrderFollower } from '../../../../../models/entities/purchaseOrder/purchaseOrderFollower';
import { styled } from '../../../../shared/Theme/theme';
import PurchaseOrderFollower from './PurchaseOrderFollower';

type Props = {
    followers?: Array<purchaseOrderFollower>;
};

const PurchaseOrderFollowers = ({ followers }: Props) => {
    if (!followers) return null;
    return (
        <FollowersContainer>
            {followers.map((follower) => (
                <PurchaseOrderFollower key={follower.id} follower={follower} />
            ))}
        </FollowersContainer>
    );
};

const FollowersContainer = styled.div`
    display: flex;
    align-items: center;
    margin-right: 20px;
`;

export default PurchaseOrderFollowers;
