import React from 'react';
import { InputNumber } from 'antd';
import styled, { CSSProperties } from 'styled-components';
import { FieldInputProps } from 'formik';
import useDebounceOnChange from '../../../../hooks/debounceOnChange';

export type BaseProps = {
    placeHolder?: string;
    value?: number;
    min?: number;
    max?: number;
    step?: number;
    style?: CSSProperties;
    name?: string;
    field?: FieldInputProps<any>;
    hasError?: boolean;
    onChange?: any;
    formatter?: (value: any) => string;
    parser?: (value: any) => any;
    width?: string;
    disabled?: boolean;
};

const NumberInput = ({
    hasError,
    value,
    min,
    max,
    step,
    name,
    placeHolder,
    style,
    field,
    onChange,
    formatter,
    parser,
    width,
    disabled
}: BaseProps) => {
    const [debounceValue, debounceOnChange] = useDebounceOnChange<number>(value || 0, (value: number) => onChange && onChange(value));
    return (
        <Container hasError={hasError} width={width}>
            <InputNumber
                value={debounceValue}
                min={min}
                max={max}
                step={step}
                name={name}
                {...field}
                style={style}
                placeholder={placeHolder}
                formatter={formatter}
                parser={parser}
                onChange={debounceOnChange}
                disabled={disabled}
            />
        </Container>
    );
};

interface ContainerProps {
    hasError?: boolean;
    width?: string;
}
const Container = styled.div<ContainerProps>`
    position: relative;
    width: ${(props) => props.width};
    & .ant-input,
    .ant-input-number {
        border-radius: 70px;
        border-color: ${(props) => (props.hasError ? 'red' : props.theme.colors.primaryBlue)} !important;
    }

    & .ant-input:focus,
    .ant-input-number:focus,
    .ant-input-number-focused {
        box-shadow: ${(props) => (props.hasError ? '0 0 0 2px rgb(255 24 24 / 20%)' : '0 0 0 2px rgba(24, 144, 255, 0.2)')};
    }

    & .ant-input-number-handler-wrap {
        right: 10px;
    }
`;
export default NumberInput;
