import { ModelConfig } from '@rematch/core';

export type singleTariffStateType = {
    tariffId: string | null;
};

export const singleTariff: ModelConfig<singleTariffStateType> = {
    state: {
        tariffId: null
    },
    reducers: {
        setCurrentTariffId(state: singleTariffStateType, tariffId: string): singleTariffStateType {
            return { ...state, tariffId };
        }
    }
};
