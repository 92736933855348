import React from 'react';
import styled from 'styled-components';
import doneIcon from '../../../../static/icons/status/done.svg';
import newIcon from '../../../../static/icons/status/new-releases.svg';
import onGoingIcon from '../../../../static/icons/status/on-going.svg';
import pendingIcon from '../../../../static/icons/status/pending.svg';
import stuckIcon from '../../../../static/icons/status/stuck.svg';

const renderNewIcon = () => {
    return (
        <IconWrapper>
            {/* <Circle>
                <span className="material-icons new">new_releases</span>
            </Circle> */}
            <img src={newIcon} alt="New" className="new-icon" />
        </IconWrapper>
    );
};
const renderPendingIcon = () => {
    return (
        <IconWrapper>
            <img src={pendingIcon} alt="Pending" />
        </IconWrapper>
    );
};
const renderStuckIcon = () => {
    return (
        <IconWrapper>
            <img src={stuckIcon} alt="Stuck" />
        </IconWrapper>
    );
};
const renderOnWorkIcon = () => {
    return (
        <IconWrapper>
            <img src={onGoingIcon} alt="On Work" />
        </IconWrapper>
    );
};
const renderDoneIcon = () => {
    return (
        <IconWrapper>
            <img src={doneIcon} alt="Done" />
        </IconWrapper>
    );
};

type CircleProps = {
    bgColor?: string;
    hasCircle?: boolean;
};

const IconWrapper = styled.div`
    & img {
        height: 14px;
        margin: 0px 7px 1px 0px;
    }

    .new-icon {
        height: 16px;
        color: rgba(0, 0, 0, 0.54);
        margin-bottom: 0.5px;
    }
`;

export default {
    renderNewIcon,
    renderPendingIcon,
    renderStuckIcon,
    renderOnWorkIcon,
    renderDoneIcon
};
