import React from 'react';
import styled from 'styled-components';
import { Option } from '../inputs/base/DropDown';

type Props = {
    text: string;
    value?: string;
    color: string;
};

export const CircleComp = ({ text, color }: Props) => {
    return (
        <>
            <Circle color={color} /> <Text> {text} </Text>
        </>
    );
};

const StateOption = ({ text, value, color }: Props) => {
    if (!value) return null;
    return (
        <Wrapper>
            <CircleComp text={text} color={color} />
        </Wrapper>
    );
};

export const renderStateOption = (option: Option & { color?: string }) => {
    return <StateOption text={option.text} value={option.value} color={option.color ?? '#b2b2b2'} />;
};

const Wrapper = styled.div`
    display: flex;
    justify-content: left;
    align-items: center;
    border-bottom: 1px solid #dadada;
    padding-bottom: 3px;
`;

type CircleProps = {
    color: string;
};

export const Circle = styled.div<CircleProps>`
    min-width: 11px;
    min-height: 11px;
    border-radius: 50%;
    background-color: ${(props) => props.color};
    border: 1px solid ${(props) => props.color};
    margin-right: 8px;
`;

const Text = styled.span`
    color: ${(props) => props.theme.colors.gridCell};
    font-size: ${(props) => props.theme.fontSizes.gridCell};
    font-weight: 400;
`;

export default renderStateOption;
