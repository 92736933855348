import React from 'react';
import CustomModal, { ModalTriggerProps } from '../Modal/Modal';
import ArrayToExcel from './ArrayToExcel';

type Props<T> = {
    data: Array<T>;
    title: string;
    fileName: string;
    localization: any;
    Trigger?: (props: ModalTriggerProps) => JSX.Element;
    onCancelCallback?: () => void;
};
const ArrayToExcelModal = function <T>({ data, title, Trigger, localization, fileName }: Props<T>) {
    return (
        <CustomModal
            title={{ text: title }}
            Trigger={Trigger}
            Content={(setVisible?: React.Dispatch<React.SetStateAction<boolean>>, setConfirm?: React.Dispatch<React.SetStateAction<boolean>>) => {
                return (
                    <ArrayToExcel<T>
                        data={data}
                        fileName={fileName}
                        localization={localization}
                        setModalVisible={(visible: boolean) => {
                            setVisible && setVisible(visible);
                        }}
                        confirmation={setConfirm ? { setConfirm } : undefined}
                    />
                );
            }}
            width="auto"
            maxWidth="auto"
        />
    );
};

export default ArrayToExcelModal;
