import { RootState } from '../../store/store';
import { createSelector } from 'reselect';
import { orderBy, sum, sumBy } from 'lodash';
import {
    internalFlowInventoryLogs,
    internalFlowInventoryLogsGridDataType,
    amountByEntityType,
    inventoryLog,
    amountByMarketplace
} from '../../../models/entities/product/inventoryLog';
import { gridSortCallback } from '../../../utils/sortArray';
import { eInternalFlowGridMode, gridModeType } from './internalFlowInventoryLogs';
import { calcVolume, sumVolumes } from '../../../utils/measurementUtils';

const internalFlowLogsData = (state: RootState) => state.internalFlowInventoryLogs.internalFlowInventoryLogs;
const gridColumns = (state: RootState) => state.internalFlowInventoryLogs.gridColumns;
const gridSort = (state: RootState) => state.internalFlowInventoryLogs.gridSort;
const gridFilter = (state: RootState) => state.internalFlowInventoryLogs.gridFilter;
const filterFields = (state: RootState) => state.internalFlowInventoryLogs.filterFields;
const gridMode = (state: RootState) => state.internalFlowInventoryLogs.gridMode;
const pagination = (state: RootState) => state.internalFlowInventoryLogs.pagination;

interface IAmountByMode {
    amount: number;
    currency?: string;
    volumeUnit?: string;
}

const internalFlowInventoryLogsGridData = createSelector(
    gridMode,
    internalFlowLogsData,
    (gridMode: gridModeType, internalFlowLogs?: internalFlowInventoryLogs) => {
        if (!internalFlowLogs) return [];

        const data: internalFlowInventoryLogsGridDataType[] = Object.entries(internalFlowLogs).map(([productId, logsData]) => {
            const { product, internalFlow, amazon, manual } = logsData;
            const { name, sku, asin, costCurrency, latestProductImgUrl, fnsku } = product;

            const getAmountByLogs = (logs: inventoryLog[]): amountByEntityType[] =>
                logs.reduce((acc: amountByEntityType[], log: inventoryLog) => {
                    const { id, quantity, cost, costCurrency, width, height, length, dimensionUnit, entityId, entityCzNumber, entityType } = log;

                    if (!(entityId && entityCzNumber && entityType)) return acc;

                    const valueAmount = quantity * Number(cost);
                    const { volume, unit: volumeUnit } = calcVolume({
                        width: Number(width),
                        height: Number(height),
                        length: Number(length),
                        dimensionsUnit: dimensionUnit,
                        quantity
                    });

                    const valueByEntity: amountByEntityType = {
                        id,
                        entityId,
                        entityCzNumber,
                        entityType,
                        amount:
                            gridMode === eInternalFlowGridMode.VALUE ? valueAmount : gridMode === eInternalFlowGridMode.VOLUME ? volume : quantity,
                        currency: gridMode === eInternalFlowGridMode.VALUE ? costCurrency : undefined,
                        volumeUnit: gridMode === eInternalFlowGridMode.VOLUME ? volumeUnit : undefined
                    };

                    return [...acc, valueByEntity];
                }, []);

            const pdReservedLogs = internalFlow.RESERVED?.PD;
            const poInboundLogs = internalFlow.INBOUND?.PO;
            const shipmentInboundLogs = internalFlow.INBOUND?.SHIPMENT;

            const reservedAmountByPDs: amountByEntityType[] = pdReservedLogs ? getAmountByLogs(pdReservedLogs) : [];
            const inboundAmountByPOs: amountByEntityType[] = poInboundLogs ? getAmountByLogs(poInboundLogs) : [];
            const inboundAmountByShipments: amountByEntityType[] = shipmentInboundLogs ? getAmountByLogs(shipmentInboundLogs) : [];

            const sumAmountsByMode = (amountsByMode: IAmountByMode[]): { total: number; unit?: string } => {
                if (gridMode === eInternalFlowGridMode.VOLUME) {
                    const volumes: { volume: number; unit: string }[] = amountsByMode.reduce(
                        (acc: { volume: number; unit: string }[], amountByEntity: IAmountByMode) => {
                            const { amount, volumeUnit } = amountByEntity;
                            if (!(volumeUnit && amount)) return acc;
                            return [...acc, { volume: amount, unit: volumeUnit }];
                        },
                        []
                    );

                    const { volume, unit } = sumVolumes(volumes);

                    return { total: volume, unit };
                }

                const total = sum(amountsByMode.map((amountByMode: IAmountByMode) => amountByMode.amount));

                if (gridMode === eInternalFlowGridMode.VALUE) {
                    return { total, unit: costCurrency };
                }
                return { total };
            };

            const { total: pdReservedTotal, unit: pdReservedTotalUnit } = sumAmountsByMode(reservedAmountByPDs);
            const { total: poInboundTotal, unit: poInboundTotalUnit } = sumAmountsByMode(inboundAmountByPOs);
            const { total: shipmentInboundTotal, unit: shipmentInboundTotalUnit } = sumAmountsByMode(inboundAmountByShipments);

            const getProductAmountByGridMode = (quantity: number): IAmountByMode => {
                const { volume, unit: volumeUnit } = calcVolume({ ...product, quantity });
                const valueAmount = quantity * Number(product.cost);
                return {
                    amount: gridMode === eInternalFlowGridMode.VALUE ? valueAmount : gridMode === eInternalFlowGridMode.VOLUME ? volume : quantity,
                    currency: gridMode === eInternalFlowGridMode.VALUE ? costCurrency : undefined,
                    volumeUnit: gridMode === eInternalFlowGridMode.VOLUME ? volumeUnit : undefined
                };
            };

            const amazonOnHand =
                amazon?.map((amazonInventory) => ({
                    ...getProductAmountByGridMode(
                        (amazonInventory.fulfillableQuantity || 0) + (amazonInventory.researchingQuantityTotalResearchingQuantity || 0)
                    ),
                    marketplaceId: amazonInventory.marketplaceId
                })) || [];
            if (amazonOnHand.length) console.log(amazonOnHand);
            const { total: amazonOnHandTotal, unit: amazonOnHandTotalUnit } = sumAmountsByMode(amazonOnHand);

            const amazonInbound =
                amazon?.map((amazonInventory) => ({
                    ...getProductAmountByGridMode(
                        (amazonInventory?.inboundWorkingQuantity || 0) +
                            (amazonInventory?.inboundShippedQuantity || 0) +
                            (amazonInventory?.inboundReceivingQuantity || 0)
                    ),
                    marketplaceId: amazonInventory.marketplaceId
                })) || [];
            const { total: amazonInboundTotal, unit: amazonInboundTotalUnit } = sumAmountsByMode(amazonInbound);

            const amazonReserved =
                amazon?.map((amazonInventory) => ({
                    ...getProductAmountByGridMode(amazonInventory.reservedQuantityTotalReservedQuantity || 0),
                    marketplaceId: amazonInventory.marketplaceId
                })) || [];
            const { total: amazonReservedTotal, unit: amazonReservedTotalUnit } = sumAmountsByMode(amazonReserved);

            const fixedNum = (n: number) => Number(n.toFixed(3));

            const { total: manualOnHand, unit: manualOnHandUnit } = sumAmountsByMode([
                getProductAmountByGridMode(sumBy(manual.ON_HAND, 'quantity') || 0)
            ]);
            const { total: manualInbound, unit: manualInboundUnit } = sumAmountsByMode([
                getProductAmountByGridMode(sumBy(manual.INBOUND, 'quantity') || 0)
            ]);
            const { total: manualReserved, unit: manualReservedUnit } = sumAmountsByMode([
                getProductAmountByGridMode(sumBy(manual.RESERVED, 'quantity') || 0)
            ]);

            const data: internalFlowInventoryLogsGridDataType = {
                productId: product.id,
                name,
                sku,
                asin,
                fnsku,
                productImgUrl: latestProductImgUrl,
                pdReservedTotal: fixedNum(pdReservedTotal),
                poInboundTotal: fixedNum(poInboundTotal),
                shipmentInboundTotal: fixedNum(shipmentInboundTotal),
                reservedAmountByPDs,
                inboundAmountByPOs,
                inboundAmountByShipments,
                pdReservedTotalUnit,
                poInboundTotalUnit,
                shipmentInboundTotalUnit,
                gridMode,
                amazonOnHand,
                amazonOnHandTotal: fixedNum(amazonOnHandTotal),
                amazonOnHandTotalUnit,
                amazonInbound,
                amazonInboundTotal: fixedNum(amazonInboundTotal),
                amazonInboundTotalUnit,
                amazonReserved,
                amazonReservedTotal: fixedNum(amazonReservedTotal),
                amazonReservedTotalUnit,
                manualOnHand: fixedNum(manualOnHand),
                manualOnHandUnit,
                manualInbound: fixedNum(manualInbound),
                manualInboundUnit,
                manualReserved: fixedNum(manualReserved),
                manualReservedUnit
            };

            return data;
        });
        return data;
    }
);

const gridData = createSelector(
    internalFlowInventoryLogsGridData,
    gridSort,
    gridFilter,
    filterFields,
    (internalFlowInventoryLogsGridData: Array<internalFlowInventoryLogsGridDataType>, gridSort, gridFilter, filterFields) => {
        // const settings: settings = settingsByLanguage();

        if (gridFilter && gridFilter !== '' && gridFilter.length > 2) {
            internalFlowInventoryLogsGridData = internalFlowInventoryLogsGridData.filter((s) =>
                JSON.stringify(s).toLocaleLowerCase().includes(gridFilter.toLocaleLowerCase())
            );
        }

        // if (filterFields) {
        //     inventoryData = filterProducts(inventoryData, filterFields);
        // }

        if (gridSort) {
            switch (gridSort.column) {
                default:
                    internalFlowInventoryLogsGridData = orderBy(internalFlowInventoryLogsGridData, gridSortCallback(gridSort.column), [
                        gridSort.direction
                    ]);
            }
        }

        return internalFlowInventoryLogsGridData;
    }
);

const activeInternalFlowInventoryLogsGridDataCount = createSelector(
    internalFlowInventoryLogsGridData,
    (internalFlowInventoryLogsGridData: internalFlowInventoryLogsGridDataType[]) => {
        return internalFlowInventoryLogsGridData.length;
    }
);

const filteredInventoryCount = createSelector(gridData, (gridData) => {
    return gridData?.length || 0;
});

const currentGridDataPage = createSelector(pagination, gridData, (pagination, gridData) => {
    const { currentPage, rowsPerPage } = pagination;

    return gridData.slice(currentPage * rowsPerPage, currentPage * rowsPerPage + rowsPerPage);
});
export { default as internalFlowInventoryLogsSelectors } from './selectors';

const exportObj = {
    internalFlowInventoryLogsGridData,
    gridColumns,
    gridData,
    filterFields,
    gridSort,
    gridFilter,
    filteredInventoryCount,
    pagination,
    activeInternalFlowInventoryLogsGridDataCount,
    currentGridDataPage,
    gridMode
};

export default exportObj;
