import BaseController from '..';
import { downloadFile } from '../../../utils/files';
import IFiles from './IFiles';

export default class FilesApi extends BaseController implements IFiles {
    constructor(baseUrl: string) {
        super(baseUrl);
    }
    async get(fileId: string) {
        const response = await this.client.get(`/files/get/${fileId}`);
        return response;
    }
    async getAll(filesIds: string[]) {
        const response = await this.client.post('/files/getAll', JSON.stringify({ filesIds }));
        return response;
    }
    async download(fileId: string, fileName: string) {
        const response = await this.client.post('/files/download', JSON.stringify({ fileId }));
        const blob = await response.blob();
        downloadFile(blob, fileName);
    }
    async getFileUrl(fileId: string) {
        const response = await this.client.get(`/files/preview/${fileId}`);
        if (!response || !response.data) return;
        const arrayBufferView = new Uint8Array(response.data.data);
        const blobUrl = URL.createObjectURL(new Blob([arrayBufferView], { type: response.type }));
        return { blobUrl, fileUrl: response.fileUrl ? response.fileUrl : blobUrl };
    }
}
