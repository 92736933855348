import React from 'react';
import Menu from '../../Menu/Menu';
import MenuItem from '../../Menu/MenuItem';
import edit from '../../../../static/icons/edit.svg';
import trash from '../../../../static/icons/trash.svg';
import CellTrigger from '../../Menu/MenuTriggers/CellTrigger';
import { RootState } from '../../../../state/store/store';
import { connect } from 'react-redux';
import { singlePurchaseDemandSelectors } from '../../../../state/ducks/singlePurchaseDemand/selectors';
import { purchaseDemand } from '../../../../models/entities/purchaseDemand/purchaseDemand';
type onRowMoreOptionClickType = () => void;
type Props = {
    entityId: string;
    localization: any;
    onRowMoreOptionsClick?: onRowMoreOptionClickType;
    purchaseDemand?: purchaseDemand;
    deletePurchaseDemandItem: (purchaseDemandId: string, itemId: string) => void;
};

const PurchaseDemandItemMenu = ({ entityId, purchaseDemand, deletePurchaseDemandItem, localization, onRowMoreOptionsClick }: Props) => {
    const menuItems = [
        <MenuItem
            onClick={() => {
                if (purchaseDemand) deletePurchaseDemandItem(purchaseDemand.id, entityId);
            }}
            icon={trash}
            text={localization.delete}
        />
    ];

    return <Menu options={menuItems} MenuTrigger={CellTrigger} strStyle="display: flex;align-items: center;width: 100%;height: 100%;" />;
};

const mapStateToProps = (state: RootState) => ({
    purchaseDemand: singlePurchaseDemandSelectors.purchaseDemand(state)
});

const mapDispatchToProps = (dispatch: any) => ({
    deletePurchaseDemandItem: (purchaseDemandId: string, itemId: string) =>
        dispatch.purchaseDemands.deletePurchaseDemandItem({ purchaseDemandId, itemId })
});

export default connect(mapStateToProps, mapDispatchToProps)(PurchaseDemandItemMenu);
