import React, { useState } from 'react';
import { useHistory } from 'react-router';
import styled from 'styled-components';
import { company, integrationKeys } from '../../../../../models/entities/company/company';
import { companyOverview } from '../../../../../models/entities/company/companyOverview';
import { PagesRoutes } from '../../../../../routing/PagesRoutes';
import EntityDetails, { detail } from '../../../../shared/EntityDetails/EntityDetails';
import BackButton from '../../../../shared/SmallComponents/BackButton';
import Button from '../../../../shared/SmallComponents/Button';
import CompanyAddresses from './sections/Addresses/AddressesTab';
import EditIcon from '../../../../../static/icons/edit.svg';
import { Formik } from 'formik';
import { Form } from 'formik-antd';
import { SketchPicker } from 'react-color';
import Loader from '../../../../shared/SmallComponents/Loader';
import UploadImgButton from '../../../../shared/SmallComponents/UploadImgButton';
import { RcFile } from 'antd/lib/upload/interface';
import { UploadRequestOption } from 'rc-upload/lib/interface';
import CopyToClipboard from '../../../../shared/CopyToClipboard';
import ApiKeysResponseModal from '../components/ApiKeysResponseModal';
import { marketplacesDropDownOptions, flatMarketplaces } from './utils';
import { Option } from '../../../../shared/inputs/base/DropDown';
import CountryOption from '../../../../shared/SmallComponents/CountryOption';
import { featureFlagsModel } from '../../../../../models/entities/businessPartner/settings';
import { Link } from 'react-router-dom';

type marketplace = {
    country: string;
    countryCode: string;
    marketplaceId: string;
};
type Props = {
    company: company;
    localization: any;
    updateCompanyOverview: (companyId: string, companyOverview: companyOverview) => Promise<void>;
    updateCompanyLogo: (companyId: string, file: RcFile) => Promise<void>;
    showLoader: boolean;
    showLogoLoader: boolean;
    createIntegrationApiKeys: (companyId: string) => Promise<Required<integrationKeys>>;
    isCreatingApiKeys: boolean;
    featureFlags?: featureFlagsModel;
};

const SingleCompanyPage = ({
    company,
    localization,
    updateCompanyOverview,
    updateCompanyLogo,
    showLoader,
    showLogoLoader,
    createIntegrationApiKeys,
    isCreatingApiKeys,
    featureFlags
}: Props) => {
    const history = useHistory();
    const [generatedApiKeys, setGeneratedApiKeys] = useState<Required<integrationKeys> | null>(null);
    const [detailsEdit, setDetailsEdit] = useState(false);
    const [showColorPicker, setShowColorPicker] = useState(false);

    const initialFormValues: companyOverview = {
        name: company.name,
        vatNumber: company.vatNumber,
        address: company.address,
        phoneNumber: company.phoneNumber,
        color: company.color || '#394372',
        marketplaceIds: company.marketplaceIds
    };

    const submit = async (values: companyOverview) => {
        await updateCompanyOverview(company.id, values);
        setDetailsEdit(false);
    };

    const detailsData = (values: any, setFieldValue: any): Array<Array<detail>> => [
        [
            {
                name: 'name',
                showCondition: true,
                editable: {},
                title: localization.name,
                value: company.name
            },
            {
                name: 'vatNumber',
                showCondition: true,
                editable: {},
                title: localization.vatNumber,
                value: company.vatNumber || '-'
            },
            {
                name: 'marketplaceIds',
                showCondition: !!featureFlags?.AMAZON,
                editable: {
                    type: 'dropdown',
                    value: company.marketplaceIds,
                    groupedOptions: marketplacesDropDownOptions,
                    dropDownMode: 'multiple',
                    placeholder: localization.marketplaces,
                    dropDownOptionRenderer: (option: Option) => {
                        const marketplace = flatMarketplaces.find((m) => m.marketplaceId === option.value);
                        return <CountryOption country={marketplace?.country || ''} code={marketplace?.countryCode || ''} />;
                    }
                },
                title: localization.marketplaces,
                value: (
                    <div style={{ maxHeight: 105, paddingTop: 5, overflowY: 'auto' }}>
                        {company.marketplaceIds?.map((id: string) => {
                            const marketplace = flatMarketplaces.find((m) => m.marketplaceId === id);
                            return (
                                <CountryOption
                                    country={marketplace?.country || ''}
                                    code={marketplace?.countryCode || ''}
                                    removeBorder
                                    paddingBottom={10}
                                />
                            );
                        }) || '-'}
                    </div>
                )
            }
        ],
        [
            {
                name: 'address',
                showCondition: !!company.address || detailsEdit,
                editable: {},
                title: localization.address,
                value: company.address
            },
            {
                name: 'phoneNumber',
                showCondition: !!company.phoneNumber || detailsEdit,
                editable: {},
                title: localization.phoneNumber,
                value: company.phoneNumber
            },
            {
                name: 'color',
                showCondition: !!company.color || detailsEdit,
                editable: (
                    <>
                        <ColorButtonWrapper
                            onClick={() => {
                                setShowColorPicker(true);
                            }}
                        >
                            <ColorBlock color={values.color} />
                        </ColorButtonWrapper>
                        {showColorPicker && (
                            <>
                                <PickerWrapper>
                                    <SketchPicker
                                        color={values.color}
                                        onChange={(color) => {
                                            setFieldValue('color', color.hex);
                                        }}
                                    />
                                </PickerWrapper>
                                <PickerBack
                                    onClick={() => {
                                        setShowColorPicker(false);
                                    }}
                                />
                            </>
                        )}
                    </>
                ),
                title: localization.color,
                value: <ColorBlock color={company.color} />
            }
        ]
    ];
    return (
        <PageWrapper>
            <SectionWrapper>
                <div style={{ paddingLeft: 30 }}>
                    <BackButton style={{ marginLeft: '-30px' }} toPath={PagesRoutes.CompaniesMgmt} text={localization.backLink} />
                </div>
            </SectionWrapper>
            <SectionWrapper>
                <div>
                    <TitleWrapper>{company.name}</TitleWrapper>
                    <UploadImgButton
                        imgUrl={company.logoUrl}
                        isLoading={showLogoLoader}
                        customRequest={(options: UploadRequestOption<any>) => updateCompanyLogo(company.id, options.file as RcFile)}
                        localization={{}}
                    />
                </div>
            </SectionWrapper>
            <OverviewSectionWrapper>
                <DetailsEditButton onClick={() => setDetailsEdit(!detailsEdit)}>
                    <Button margin="5px" buttonType={detailsEdit ? 'filled' : 'hollow'}>
                        <img src={EditIcon} alt={localization.edit} />
                    </Button>
                </DetailsEditButton>
                <Formik initialValues={initialFormValues} onSubmit={submit} enableReinitialize>
                    {({ values, setFieldValue }) => {
                        return (
                            <Form>
                                <EntityDetails detailsData={detailsData(values, setFieldValue)} values={company} detailsEdit={detailsEdit} />
                                {detailsEdit && (
                                    <div>
                                        <Button buttonType="filled" type="submit">
                                            {showLoader ? (
                                                <Loader width="20px" marginTop="0px" showText={false} />
                                            ) : (
                                                <span>{localization.save_btn}</span>
                                            )}
                                        </Button>
                                    </div>
                                )}
                            </Form>
                        );
                    }}
                </Formik>
            </OverviewSectionWrapper>
            <SectionWrapper>
                <CompanyAddresses localization={localization} addresses={company.addresses} />
            </SectionWrapper>
            <SectionWrapper>
                <TitleWrapper>{localization.api_keys}</TitleWrapper>

                <ApiKeysContainer>
                    {company.secretKey && company.accessKey && (
                        <div>
                            <KeyContainer>
                                <span>
                                    {localization.access_key}: {company.accessKey}
                                </span>
                                <CopyToClipboard value={company.accessKey} />
                            </KeyContainer>
                            <KeyContainer>
                                <span>
                                    {localization.company_id}: {company.id}
                                </span>
                                <CopyToClipboard value={company.id} />
                            </KeyContainer>
                        </div>
                    )}
                    {isCreatingApiKeys ? (
                        <span style={{ marginLeft: 0, width: 14, alignSelf: 'left' }}>
                            <Loader marginTop="0" showText={false} width="14px" wrapperWidth="fit-content" />
                        </span>
                    ) : (
                        <span
                            className="generate-btn"
                            onClick={async () => {
                                const keys = await createIntegrationApiKeys(company.id);
                                setGeneratedApiKeys(keys);
                            }}
                        >
                            {localization.create_keys}
                        </span>
                    )}
                </ApiKeysContainer>
                <IntegrationApiLinkContainer>
                    {localization.documentation}:
                    <Link to={{ pathname: process.env.REACT_APP_INTEGRATION_API_URL || '' }} target="_blank">
                        {' '}
                        {process.env.REACT_APP_INTEGRATION_API_URL}
                    </Link>
                </IntegrationApiLinkContainer>
            </SectionWrapper>
            {generatedApiKeys && (
                <ApiKeysResponseModal
                    keys={generatedApiKeys}
                    visible={!!generatedApiKeys}
                    localization={localization.keys_modal}
                    onOk={() => {
                        setGeneratedApiKeys(null);
                    }}
                />
            )}
        </PageWrapper>
    );
};

const PageWrapper = styled.div`
    width: 100%;
`;

const OverviewSectionWrapper = styled.div`
    position: relative;
    padding: 24px 18px 18px 18px;
    border-bottom: solid 1px ${(props) => props.theme.colors.grayDefaultBorder};
`;

const DetailsEditButton = styled.div`
    display: inline-block;
    position: absolute;
    right: 0;
    top: 0;
`;

const SectionWrapper = styled.div`
    display: flex;
    flex-direction: column;
    padding: 24px 18px 18px 18px;
    border-bottom: solid 1px ${(props) => props.theme.colors.grayDefaultBorder};
    justify-content: space-between;
`;

const TitleWrapper = styled.div`
    color: ${(props) => props.theme.colors.pageTitle};
    font-size: ${(props) => props.theme.fontSizes.pageTitle};
    font-weight: bold;
    height: 24px;
    margin-left: 10px;
    margin-bottom: 10px;
    white-space: nowrap;

    & span {
        @media screen and (max-width: 768px) {
            display: none;
        }
    }
`;

const ColorBlock = styled.div<{ color?: string }>`
    background: ${(props) => props.color ?? 'black'};
    width: 36px;
    height: 14px;
    border-radius: 2px;
`;

const ColorButtonWrapper = styled.div`
    padding: 5px;
    background: #fff;
    border-radius: 1px;
    box-shadow: 0 0 0 1px rgba(0, 0, 0, 0.1);
    display: inline-block;
    cursor: pointer;
`;

const PickerWrapper = styled.div`
    position: absolute;
    z-index: 2;
`;

const PickerBack = styled.div`
    z-index: 1;
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
`;

const ApiKeysContainer = styled.div`
    flex: 1;
    padding-left: 10px;
    .generate-btn {
        color: #1890ff;
        text-decoration: none;
        background-color: transparent;
        outline: none;
        cursor: pointer;
        transition: color 0.3s;

        &:hover {
            color: #40a9ff;
        }
    }
`;

const IntegrationApiLinkContainer = styled.div`
    padding-top: 10px;
    padding-left: 10px;
`;

const KeyContainer = styled.div`
    display: flex;
    align-items: center;
`;
export default SingleCompanyPage;
