import { Link } from 'react-router-dom';
import { styled } from '../Theme/theme';

const StyledLink = styled(Link)`
    font-size: ${(props) => props.theme.fontSizes.gridCell};
    color: ${(props) => props.theme.colors.link};
    display: block;
    display: -webkit-box;
    max-width: 100%;
    line-height: 1;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    overflow: hidden;
    text-overflow: ellipsis;
`;

export default StyledLink;
