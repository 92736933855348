import React, { useState } from 'react';
import { styled } from '../../../shared/Theme/theme';
import { purchaseDemand } from '../../../../models/entities/purchaseDemand/purchaseDemand';

import UserBadge from '../../../shared/UserBadge/UserBadge';
import moment from 'moment';
import { Dispatch, RootState } from '../../../../state/store/store';
import { connect } from 'react-redux';
import { isEmpty } from 'lodash';

type Props = {
    purchaseDemand: purchaseDemand;
    localization: any;
    settings: any;
};

const PurchaseDemandOverview = ({ purchaseDemand, localization, settings }: Props) => {
    return (
        <OverviewWrapper>
            <OverviewHeaderWrapper>
                <ReferencesSectionWrapper>
                    <PurchaseDemandNumberWrapper>
                        <PurchaseDemandNumber>
                            <span>{localization.purchaseDemand}</span> #{purchaseDemand.CargoZoneNumber}
                        </PurchaseDemandNumber>
                    </PurchaseDemandNumberWrapper>
                    <DetailWrapper>
                        <DetailHeader>{'Open Date: '}</DetailHeader>
                        {moment(purchaseDemand.createdAt).format('DD/MM/YYYY')}
                    </DetailWrapper>
                    <DetailWrapper>
                        <DetailHeader>{localization.requested_goods_ready}: </DetailHeader>
                        {purchaseDemand.isAsap
                            ? localization.asap
                            : purchaseDemand.requestedGoodsReady
                            ? moment(purchaseDemand.requestedGoodsReady).format('DD/MM/YYYY')
                            : '-'}
                    </DetailWrapper>
                </ReferencesSectionWrapper>
                <SectionsSeparator />
                <SectionWrapper>
                    <DetailWrapper>
                        <div style={{ display: `${purchaseDemand.companyLogoUrl ? 'inline-block' : 'block'}` }}>{localization.company}:</div>{' '}
                        <div
                            style={{
                                fontWeight: 'bolder',
                                fontSize: `${purchaseDemand.companyLogoUrl ? '12px' : '20px'}`,
                                display: `${purchaseDemand.companyLogoUrl ? 'inline-block' : 'block'}`
                            }}
                        >
                            {purchaseDemand.companyName}
                        </div>
                    </DetailWrapper>
                    {purchaseDemand.companyLogoUrl && <ImgLogo src={purchaseDemand.companyLogoUrl} alt={purchaseDemand.companyName} width={80} />}
                </SectionWrapper>
                <SectionsSeparator />
                <SectionWrapper>
                    <RowWrapper>
                        <UserBadge width={40} userId={purchaseDemand.userId} userName={purchaseDemand.userName} />
                    </RowWrapper>
                </SectionWrapper>
            </OverviewHeaderWrapper>
        </OverviewWrapper>
    );
};

const Row = styled.div`
    display: flex;
    flex-direction: row;
    line-height: 20px;
`;

const ImgLogo = styled.img`
    max-width: 200px;
`;

const OverviewWrapper = styled.div`
    display: flex;
    flex-direction: column;
    width: 100%;
`;

const OverviewHeaderWrapper = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    width: 100%;
    border-bottom: solid 1px #e0e0e0;
    padding: 24px 18px 18px 48px;
`;

const PurchaseDemandNumberWrapper = styled.div`
    display: flex;
    flex-direction: row;
    width: 100%;
`;

const SectionWrapper = styled.div`
    flex: 1;
    max-width: 200px;
`;
const ReferencesSectionWrapper = styled.div`
    flex: 1;
    max-width: fit-content;
`;
const CompanySectionWrapper = styled.div`
    flex: 1;
    max-width: 320px;
`;

const RowWrapper = styled.div`
    display: flex;
    white-space: nowrap;
    flex-direction: row;
    padding: 6px 0;
    color: #394372;
`;

const DetailWrapper = styled.div`
    color: #394372;

    margin: 4px 0;
    &:first-child {
        margin-top: 0;
    }
`;

const DetailsWrapperContainer = styled.div`
    position: relative;
`;
const DetailsEditButton = styled.div`
    display: inline-block;
    position: absolute;
    right: 0;
`;

const DetailHeader = styled.span`
    font-weight: bolder;
`;

const SectionHeader = styled.div`
    color: #394372;
    font-weight: bolder;
    border-bottom: 1px solid #394372;
    width: fit-content;
    padding: 0 5px;
`;

const SectionsSeparator = styled.div`
    width: 1px;
    border-right: solid 1px #e0e0e0;
    margin: 0 18px;
`;

const PurchaseDemandNumber = styled.div`
    color: ${(props) => props.theme.colors.pageTitle};
    font-size: ${(props) => props.theme.fontSizes.pageTitle};
    font-weight: bold;
    height: 24px;
    white-space: nowrap;

    & span {
        @media screen and (max-width: 768px) {
            display: none;
        }
    }
`;

const DetailsWrapper = styled.div`
    display: flex;
    flex-direction: column;
    padding: 24px 18px 18px 48px;
`;

const DetailsRow = styled.div`
    display: flex;
    flex-direction: row;
    max-width: 1000px;
`;

const DetailBox = styled.div`
    padding: 0 60px 26px 0;
    min-width: 20%;
    white-space: nowrap;
`;

const DetailTitle = styled.div`
    color: #008ac9;
`;

const DetailValue = styled.div`
    color: #394372;
    text-overflow: ellipsis;
    white-space: normal;
    -webkit-line-clamp: 2;
    display: -webkit-box;
    -webkit-box-orient: vertical;
`;
const ItemSeeMore = styled.div`
    font-size: 12px;
    margin-left: 5px;
    color: #1890ff;
    cursor: pointer;
    :hover {
        text-decoration: underline;
    }
`;
const ReferencesSection = styled.div`
    color: ${(props) => props.theme.colors.pageTitle};
    font-size: ${(props) => props.theme.fontSizes.subTitlte};
    margin-top: 6px;
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;

    & ::-webkit-scrollbar-thumb {
        background-color: rgba(0, 0, 0, 0.3) !important;
        border-radius: 5.5px;
    }
`;

const SingleRefWrapper = styled.div`
    display: flex;
    align-items: center;
    margin-right: 12px;
    flex-wrap: wrap;
    padding: 6px 0;
`;

const RefText = styled.span`
    font-weight: bold;
    margin-right: 4px;
`;

const mapStateToProps = (state: RootState) => ({});

const mapDispatchToProps = (dispatch: any) => ({});

export default connect(mapStateToProps, mapDispatchToProps)(PurchaseDemandOverview);
