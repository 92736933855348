import BaseController from '../..';
import { companiesRelation, companiesRelationsResponseType, companiesRelationType } from '../../../../models/entities/company/companiesRelation';
import { companiesRelationRequest, companiesRelationRequestsResponseType } from '../../../../models/entities/company/companiesRelationRequest';
import ICompaniesRelations from './ICompaniesRelations';

export default class CompaniesRelations extends BaseController implements ICompaniesRelations {
    constructor(baseUrl: string) {
        super(baseUrl);
    }
    async getCompaniesRelations(): Promise<companiesRelationsResponseType> {
        const response = await this.client.get(`/companyAdmin/company/relations`);
        return response;
    }
    async getCompaniesRelationRequests(): Promise<companiesRelationRequestsResponseType> {
        const response = await this.client.get(`/companyAdmin/company/relations/requests`);
        return response;
    }
    async requestCompaniesRelation(
        relationToken: string,
        relationType: companiesRelationType,
        recieverBusinessPartnerId?: string,
        recieverRelationType?: companiesRelationType
    ): Promise<companiesRelationRequest> {
        const response = await this.client.post(
            `/companyAdmin/company/relations/request`,
            JSON.stringify({ relationToken, relationType, recieverBusinessPartnerId, recieverRelationType })
        );
        return response;
    }
    async approveCompaniesRelation(
        requestId: string,
        businessPartnerId: string,
        recievedBusinessPartnerId?: string,
        recievedRelationType?: companiesRelationType
    ): Promise<companiesRelation> {
        const response = await this.client.post(
            `/companyAdmin/company/relations/approve`,
            JSON.stringify({ requestId, businessPartnerId, recievedBusinessPartnerId, recievedRelationType })
        );
        return response;
    }
    async denyCompaniesRelation(requestId: string): Promise<any> {
        const response = await this.client.post(`/companyAdmin/company/relations/deny`, JSON.stringify({ requestId }));
        return response;
    }
    async deleteCompaniesRelation(relationId: string): Promise<any> {
        const response = await this.client.delete(`/companyAdmin/company/relations`, JSON.stringify({ relationId }));
        return response;
    }
}
