import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { settings as shipmentSettingsType, shipment } from '../../../models/entities/shipment/shipment';
import { shipmentsSelectors } from '../../../state/ducks/shipments/selectors';
import { RootState } from '../../../state/store/store';
import { Formik, Form, FieldArray } from 'formik';
import { UserSettingsFormDropDown, FormTextArea } from '../../shared/inputs/form';
import Button from '../../shared/SmallComponents/Button';
import Loader from '../../shared/SmallComponents/Loader';
import filesIcon from '../../../static/icons/light-blue.svg';
import ShipmentItem from '../SmallComponents/ShipmentItem';
import { Checkbox } from '../inputs/base';
import { styled } from '../../shared/Theme/theme';
import * as Yup from 'yup';
import { isEqual } from 'lodash';
import { document } from '../../../models/entities/common_subentities/document';
import { Empty } from 'antd';
import { localizationSelectors } from '../../../state/ducks/localization/selectors';
import { allShipmentsPageSelectors } from '../../../state/ducks/allShipmentsPage/selectors';
import { userSelectors } from '../../../state/ducks/user/selectors';
import { ColumnType } from '../Grid/types/Column';
import { userSettings } from '../../../models/entities/user';
import { filterColsByUserSettings } from '../Grid/util';
import { getShipmentPdfFile, loadShipmentSvgIcons } from '../../pages/singleShipment/ShipmentPdfDocument';
import { featureFlagsModel } from '../../../models/entities/businessPartner/settings';
import { settings } from '../../../models/entities/businessPartner/businessPartner';
import { pdf } from '@react-pdf/renderer';

type Props = {
    shipmentId: string;
    setModalVisible?: (visible: boolean) => void;
    confirmation?: { setConfirm: React.Dispatch<React.SetStateAction<boolean>> };
    getShipmentById: (id: string) => shipment | undefined;
    fetchDocuments: (id: string) => void;
    shareShipment: (emails: string[], content: string, shipmentNumber: string, files: string[], shipmentPdfFile: File) => Promise<boolean>;
    isSharingShipment?: boolean;
    isFetchingDocuments?: boolean;
    gridColumns: Array<ColumnType<shipment>>;
    localization: any;
    singleShipmentLocalization: any;
    shipmentsGridSettings?: userSettings;
    shipmentSettings: shipmentSettingsType;
    settings: settings;
    featureFlags?: featureFlagsModel;
    setEmailAutoCompleteSettings: (emails: string[]) => void;
};
const ShareShipmentForm = ({
    shipmentId,
    setModalVisible,
    getShipmentById,
    shareShipment,
    fetchDocuments,
    confirmation,
    isSharingShipment,
    isFetchingDocuments,
    localization,
    singleShipmentLocalization,
    gridColumns,
    shipmentsGridSettings,
    setEmailAutoCompleteSettings,
    shipmentSettings,
    settings,
    featureFlags
}: Props) => {
    const shipment: shipment | undefined = getShipmentById(shipmentId);

    useEffect(() => {
        if (shipment) fetchDocuments(shipmentId);
    }, [shipmentId, fetchDocuments]);

    const shipmentDocuments: document[] | undefined = shipment?.documents?.documents;

    const renderCheckBoxes = (files: string[], setFieldValue: any) => (
        <SelectBoxContainer>
            <SelectBoxHeader>
                <Checkbox
                    checked={isEqual(files.sort(), shipmentDocuments?.map((document) => document.fileId).sort())}
                    onChange={() => {
                        isEqual(files.sort(), shipmentDocuments?.map((document) => document.fileId).sort())
                            ? setFieldValue('files', [])
                            : setFieldValue(
                                  'files',
                                  shipmentDocuments?.map((document) => document.fileId)
                              );
                    }}
                >
                    All
                </Checkbox>
            </SelectBoxHeader>
            <SelectBox>
                <FieldArray
                    name="files"
                    render={(arrayHelpers) => (
                        <div>
                            {shipmentDocuments?.map((document, index) => (
                                <Checkbox
                                    name="files"
                                    key={index}
                                    value={document.fileId}
                                    checked={files.includes(document.fileId as never)}
                                    onChange={(e) => {
                                        if (e.target.checked) arrayHelpers.push(document.fileId);
                                        else {
                                            const idx = files.indexOf(document.fileId as never);
                                            arrayHelpers.remove(idx);
                                        }
                                    }}
                                >
                                    {document.fileName}
                                </Checkbox>
                            ))}
                        </div>
                    )}
                />
            </SelectBox>
        </SelectBoxContainer>
    );

    const initialValues = { content: '', emails: [], files: [] };
    return (
        <>
            <ShipmentItemContainer>
                {shipment && <ShipmentItem shipment={shipment} gridColumns={filterColsByUserSettings(gridColumns, shipmentsGridSettings)} />}
            </ShipmentItemContainer>
            <Formik
                initialValues={initialValues}
                onSubmit={async ({ content, emails, files }) => {
                    if (shipment) {
                        const file = await getShipmentPdfFile({
                            shipment,
                            localization: singleShipmentLocalization,
                            settings,
                            featureFlags,
                            shipmentSettings
                        });
                        await shareShipment(emails, content, shipment.id, files, file).then((success) => {
                            if (success) {
                                setModalVisible && setModalVisible(false);
                                setEmailAutoCompleteSettings(emails);
                            }
                        });
                    }
                }}
                validate={(values: any) => {
                    if (confirmation) {
                        if (JSON.stringify(values) === JSON.stringify(initialValues)) {
                            confirmation.setConfirm(false);
                        } else {
                            confirmation.setConfirm(true);
                        }
                    }
                }}
                validationSchema={Yup.object({
                    emails: Yup.array()
                        .transform(function (value, originalValue) {
                            if (this.isType(value) && value !== null) {
                                return value;
                            }
                            return originalValue ? originalValue.split(/[\s,]+/) : [];
                        })
                        .of(Yup.string().email(({ value }) => `${value} ${localization.errors.email} `))
                        .min(1, localization.errors.required)
                })}
            >
                {(formik) => {
                    const { errors, isValid, dirty, values, touched, setFieldValue } = formik;
                    return (
                        <FormContainer>
                            <Form>
                                <FieldContainer emailsHasError={!!errors.emails && !!touched.emails}>
                                    <div id="emails-input">
                                        <UserSettingsFormDropDown
                                            name="emails"
                                            placeHolder={localization.placeholders.emails}
                                        ></UserSettingsFormDropDown>
                                    </div>
                                </FieldContainer>
                                <FieldContainer>
                                    <div id="content-input">
                                        <FormTextArea name="content" placeHolder={localization.placeholders.content} height="106px" />
                                    </div>
                                </FieldContainer>
                                <FilesContainer>
                                    {isFetchingDocuments ? (
                                        <LoaderContainer>
                                            <Loader marginTop="0px" showText={false} width="18px" />
                                        </LoaderContainer>
                                    ) : (
                                        !!shipment?.documents?.documents?.length && (
                                            <>
                                                <FilesHeader>
                                                    <img style={{ marginRight: '5px' }} src={filesIcon} alt="icon" />
                                                    <span>{localization.files}</span>
                                                </FilesHeader>
                                                {renderCheckBoxes(values.files, setFieldValue)}
                                            </>
                                        )
                                    )}
                                </FilesContainer>
                                <ButtonContainer>
                                    <Button height="32px" width="74px" buttonType="filled" type="submit" disabled={!(dirty && isValid)}>
                                        {isSharingShipment ? (
                                            <Loader width="20px" marginTop="0px" showText={false} />
                                        ) : (
                                            <span>{localization.share}</span>
                                        )}
                                    </Button>
                                </ButtonContainer>
                            </Form>
                        </FormContainer>
                    );
                }}
            </Formik>
        </>
    );
};

const FormContainer = styled.div`
    width: 100%;
`;

interface fieldContainerProps {
    emailsHasError?: boolean;
    contentHasError?: boolean;
}
const FieldContainer = styled.div<fieldContainerProps>`
    .err-msg {
        position: relative;
    }

    #emails-input {
        margin-bottom: 12px;

        .err-msg {
            display: ${(props) => (!props.emailsHasError ? 'none' : 'initial')};
        }
    }
    #content-input {
        .err-msg {
            display: ${(props) => (!props.contentHasError ? 'none' : 'initial')};
        }
    }
`;
const LoaderContainer = styled.div`
    position: absolute;
    top: 33px;
    right: 32px;
`;
const FilesContainer = styled.div`
    padding-bottom: 51px;
`;
const FilesHeader = styled.div`
    color: #0097d1;
    height: 24px;
    font-size: 21px;
    display: flex;
    align-items: center;
    font-weight: bold;
    margin-top: 21px;
`;
const SelectBoxContainer = styled.div`
    color: #263255;
`;
const ButtonContainer = styled.div`
    color: #263255;
    position: absolute;
    bottom: 32px;
    background-color: #fff;
    width: calc(100% - 64px);
`;
const ShipmentItemContainer = styled.div`
    padding: 20px 0 15px 0;
`;
const SelectBoxHeader = styled.div`
    margin-top: 8px;
    margin-bottom: 4px;
`;
const SelectBox = styled.div`
    max-height: 134px;
    padding: 0px 0;
    overflow-y: overlay;
    border-bottom: 1px solid #e0e0e0;
    border-top: 1px solid #e0e0e0;

    label {
        display: block;
        margin-left: 0 !important;
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;
    }
`;

const mapStateToProps = (state: RootState) => ({
    getShipmentById: (id: string) => shipmentsSelectors.getShipmentById(state, id),
    isSharingShipment: state.loading.effects.shipments.shareShipment,
    isFetchingDocuments: state.loading.effects.shipments.fetchShipmentDocumentsById,
    localization: localizationSelectors.shareShipmentModal(state),
    gridColumns: allShipmentsPageSelectors.gridColumns(state),
    shipmentsGridSettings: userSelectors.shipmentsGridSettings(state),
    singleShipmentLocalization: localizationSelectors.singleShipmentPage(state),
    shipmentSettings: shipmentsSelectors.settingsByLanguage(state)()
});

const mapDispatchToProps = (dispatch: any) => ({
    shareShipment: (emails: string[], content: string, shipmentNumber: string, files: string[], shipmentPdfFile: File) =>
        dispatch.shipments.shareShipment({ emails, content, shipmentNumber, files, shipmentPdfFile }),
    fetchDocuments: (id: string) => dispatch.shipments.fetchShipmentDocumentsById(id),
    setEmailAutoCompleteSettings: (emails: string[]) => dispatch.user.setEmailAutoCompleteSettings(emails)
});

export default connect(mapStateToProps, mapDispatchToProps)(ShareShipmentForm);
