import React, { useState } from 'react';
import Grid from '../../shared/Grid/Grid';
import { businessPartner, settings } from '../../../models/entities/businessPartner/businessPartner';
import { ColumnType } from '../../shared/Grid/types/Column';
import { styled } from '../../shared/Theme/theme';
import Input from '../../shared/SmallComponents/Input';
import Button from '../../shared/SmallComponents/Button';
import ExportMenu from './components/ExportMenu';
import FilterPopover from './components/Filter/FilterPopover';
import { exportTypes, paginationType } from '../../../state/ducks/allBusinessPartnersPage/allBusinessPartnersPage';
import CustomModal, { ModalTriggerProps } from '../../shared/Modal/Modal';
import BusinessPartnerFormConnected, { formMode } from './components/BusinessPartnerForm/BusinessPartnerFormConnected';
import TextInput from '../../shared/inputs/base/TextInput';
import { UploadBusinessPartnersModal } from './components/UploadBusinessPartners';
import Tooltip from '@material-ui/core/Tooltip';
import IconButton from '@material-ui/core/IconButton';
import Loader from '../../shared/SmallComponents/Loader';

type Props = {
    companies: Array<businessPartner>;
    companiesPage: Array<businessPartner>;
    gridColumns: Array<ColumnType<businessPartner>>;
    onGridColumnClick?: (column: keyof businessPartner) => void;
    fetchCompanies: () => void;
    onFilter?: (text: string) => void;
    filterText?: string;
    gridSortedBy?: keyof businessPartner;
    gridSortDirection?: 'asc' | 'desc';
    exportDataTable: (type: exportTypes) => void;
    setCurrentPage: (page: number) => void;
    setRowsPerPage: (rowsPerPage: number) => void;
    filteredBPsCount: number;
    activeBusinessPartnersCount: number;
    pagination: paginationType;
    localization: any;
    showLoader?: boolean;
    settings?: settings;
};

const BusinessPartnersPage = ({
    companies,
    companiesPage,
    gridColumns,
    onGridColumnClick,
    fetchCompanies,
    onFilter,
    filterText,
    gridSortedBy,
    gridSortDirection,
    localization,
    exportDataTable,
    showLoader,
    settings,
    setCurrentPage,
    setRowsPerPage,
    filteredBPsCount,
    pagination,
    activeBusinessPartnersCount
}: Props) => {
    const { currentPage, rowsPerPage } = pagination;

    const posFrom = currentPage * rowsPerPage + 1;
    const posTo = (currentPage + 1) * rowsPerPage;

    const [modalVisible, setVisible] = useState(false);
    const [businessPartner, setBusinessPartner] = useState({} as businessPartner);

    const filterHandler = (text: string) => {
        if (onFilter) onFilter(text);
    };

    const showTopLoader = activeBusinessPartnersCount > 1 && !!gridColumns.length;

    return (
        <PageWrapper>
            {!!showLoader && showTopLoader && (
                <LoadingOverlay>
                    <Loader width="20px" marginTop="0" inline />
                </LoadingOverlay>
            )}
            <PageHeader>
                <Title>{localization.title}</Title>
                <HeaderRightSide>
                    <Button
                        width="32px"
                        height="32px"
                        borderRadius="50%"
                        margin="7px"
                        buttonType="hollow"
                        onClick={() => {
                            fetchCompanies();
                        }}
                    >
                        <Tooltip title={'Refresh'} aria-label="refresh">
                            <span className="material-icons">refresh</span>
                        </Tooltip>
                    </Button>
                    <TextInput
                        style={{
                            height: '30px',
                            margin: '4px',
                            width: 'auto'
                        }}
                        allowClear={true}
                        onChange={(value: any) => filterHandler(value)}
                        placeHolder={localization.filterPlaceHolder}
                        value={filterText}
                    />
                    <FilterPopover settings={settings} localization={localization.filter} />
                    <ExportMenu localization={localization} exportDataTable={exportDataTable} companies={companies} gridColumns={gridColumns} />
                    <UploadBusinessPartnersModal localization={localization.upload_form} />
                    <CustomModal
                        title={{ text: localization.modal.add_title }}
                        Trigger={(props: ModalTriggerProps) => (
                            <Button onClick={props.onClick} width="32px" height="32px" borderRadius="50%" margin="7px" buttonType="filled">
                                <Tooltip title={localization.newCompany} aria-label="add_bp">
                                    <span className="material-icons">add</span>
                                </Tooltip>
                            </Button>
                        )}
                        Content={(
                            setVisible?: React.Dispatch<React.SetStateAction<boolean>>,
                            setConfirm?: React.Dispatch<React.SetStateAction<boolean>>
                        ) => {
                            return (
                                <BusinessPartnerFormConnected
                                    mode={formMode.NEW}
                                    openedFromModal={setVisible ? { setVisible } : undefined}
                                    confirmation={setConfirm ? { setConfirm } : undefined}
                                />
                            );
                        }}
                        width="393px"
                    />
                    {(!showLoader || showTopLoader) && (
                        <NavButtonsContainer>
                            {filteredBPsCount > 0
                                ? `${posFrom} – ${filteredBPsCount <= posTo ? filteredBPsCount : posTo}${companiesPage.length <= filteredBPsCount ? ' of ' + filteredBPsCount : ''
                                }`
                                : ''}
                            <IconButton
                                onClick={() => setCurrentPage(currentPage - 1)}
                                disabled={showLoader || posFrom <= 1}
                                className="material-icons nav-btn"
                            >
                                navigate_before
                            </IconButton>
                            <IconButton
                                onClick={() => setCurrentPage(currentPage + 1)}
                                disabled={showLoader || filteredBPsCount <= posTo}
                                className="material-icons nav-btn"
                            >
                                navigate_next
                            </IconButton>
                        </NavButtonsContainer>
                    )}
                </HeaderRightSide>
            </PageHeader>
            <GridWrapper>
                <Grid<businessPartner>
                    sortedByColumn={gridSortedBy}
                    sortDirection={gridSortDirection}
                    onSettingsClick={() => console.log('settings')}
                    onRowMoreOptionsClick={(row) => {
                        setBusinessPartner(row);
                        setVisible(true);
                    }}
                    menuType="businessPartnerMenu"
                    columns={gridColumns}
                    data={companiesPage}
                    onColumnClick={onGridColumnClick}
                    localization={localization.grid}
                    emptyStateText={localization.grid.emptyState}
                    showLoader={showLoader && !showTopLoader}
                    settings={settings}
                />
                {modalVisible && (
                    <CustomModal
                        title={{ text: localization.modal.edit_title }}
                        isOpen={true}
                        Content={(
                            setModalVisible?: React.Dispatch<React.SetStateAction<boolean>>,
                            setConfirm?: React.Dispatch<React.SetStateAction<boolean>>
                        ) => {
                            return (
                                <BusinessPartnerFormConnected
                                    mode={formMode.EDIT}
                                    party={businessPartner}
                                    openedFromModal={{ setVisible }}
                                    confirmation={setConfirm ? { setConfirm } : undefined}
                                />
                            );
                        }}
                        onCancelCallback={() => {
                            setVisible(false);
                            setBusinessPartner({} as businessPartner);
                        }}
                        width="393px"
                    />
                )}
            </GridWrapper>
        </PageWrapper>
    );
};

const PageWrapper = styled.div`
    height: 100%;
    display: flex;
    flex-direction: column;
`;

const GridWrapper = styled.div`
    flex: 1;
    max-width: 100%;
    width: 100%;
    box-sizing: border-box;
    height: 100%;
    width: 100%;
    z-index: 1;
    overflow: overlay;

    @media screen and (min-width: 768px) {
        height: calc(100% - 127px);
    }

    @media screen and (max-width: 768px) {
        height: calc(100% - 135px - 40px);
    }

    @media screen and (max-height: 400px) {
        height: calc(100% - 135px);
    }
`;

const PageHeader = styled.div`
    display: flex;
    padding: 20px 12px 0 12px;

    justify-content: space-between;
    align-items: center;

    @media screen and (max-width: 768px) {
        justify-content: center;
    }

    position: sticky;
    top: 0px;
    z-index: 1000;
    background-color: #fff;
`;

const Title = styled.div`
    display: flex;
    color: ${(props) => props.theme.colors.pageTitle};
    font-size: ${(props) => props.theme.fontSizes.pageTitle};
    font-weight: bold;

    @media screen and (max-width: 768px) {
        display: none;
    }
`;

const HeaderRightSide = styled.div`
    display: flex;
    width: 100%;
    max-width: fit-content;
    align-items: center;

    @media screen and (max-width: 768px) {
        height: 40px;
        justify-content: center;
    }

    .add-icon {
        display: none;
        @media screen and (max-width: 768px) {
            display: initial;
        }
    }

    .new-label {
        @media screen and (max-width: 768px) {
            display: none;
        }
    }
`;

const NavButtonsContainer = styled.div`
    display: flex;
    align-items: center;
    margin: 0 15px;
    color: ${(props) => props.theme.colors.primaryBlue};
    font-weight: bold;
    .nav-btn {
        font-size: 1.4rem;
        color: ${(props) => props.theme.colors.primaryBlue};
    }
`;

const LoadingOverlay = styled.div`
    background-color: #edf8f9;
    padding: 6px 10px;
    border-radius: 7px;
    position: absolute;
    z-index: 1012;
    top: 10px;
    left: 50%;
    transform: translateX(-50%);
`;
export default BusinessPartnersPage;
