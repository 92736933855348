import React from 'react';
import { styled } from '../../../shared/Theme/theme';
import { Formik, Form } from 'formik';
import { chat } from '../../../../models/entities/chat/chat';
import { ModalFooter, ModalFormContainer } from '../../../shared/Modal/Modal';
import FieldLabel from '../../../shared/SmallComponents/FieldLabel';
import Button from '../../../shared/SmallComponents/Button';
import { FormTextInput } from '../../../shared/inputs/form';
import { connect } from 'react-redux';
import Loader from '../../../shared/SmallComponents/Loader';
import { RootState } from '../../../../state/store/store';

type Props = {
    group: chat;
    openedFromModal?: {
        setVisible: React.Dispatch<React.SetStateAction<boolean>>;
    };
    editGroupName: (chatId: string, name: string) => Promise<void>;
    showLoader: boolean;
    localization: any;
};
const EditGroupNameForm = ({ group, openedFromModal, editGroupName, showLoader, localization }: Props) => {
    return (
        <Formik
            initialValues={{ name: group.name || '' }}
            validate={(values: any) => {
                const errors: any = {};
                if (!values.name) errors.name = localization.cannot_be_empty;
                return errors;
            }}
            onSubmit={async (values: { name: string }) => {
                await editGroupName(group.id, values.name);
                if (openedFromModal) openedFromModal.setVisible(false);
            }}
        >
            <Form>
                <Container>
                    <FieldLabel>Name</FieldLabel>
                    <FieldContainer>
                        <FormTextInput name="name" placeHolder={localization.value} />
                    </FieldContainer>
                </Container>
                {openedFromModal && (
                    <FormFooter>
                        <Button minWidth="55px" onClick={() => openedFromModal.setVisible(false)}>
                            <span>Cancel</span>
                        </Button>
                        <Button minWidth="55px" buttonType="filled" type="submit">
                            {showLoader ? <Loader width="20px" marginTop="0px" showText={false} /> : <span>{localization.save}</span>}
                        </Button>
                    </FormFooter>
                )}
            </Form>
        </Formik>
    );
};

const FieldContainer = styled.div`
    margin-bottom: 12px;
`;

const Container = styled(ModalFormContainer)``;

const FormFooter = styled(ModalFooter)`
    position: absolute;
    bottom: 0;
    width: 100%;
    left: 0;
    right: 0;
    background: white;
`;

const mapStateToProps = (state: RootState) => ({
    showLoader: state.loading.effects.chats.editGroupName
});

const mapDispatchToProps = (dispatch: any) => ({
    editGroupName: (chatId: string, name: string) => dispatch.chats.editGroupName({ chatId, name })
});

export default connect(mapStateToProps, mapDispatchToProps)(EditGroupNameForm);
