import React, { useState } from 'react';
import { BoardColumnMap, IBoardItem } from './types/BoardColumn';
import { DragDropContext, Draggable, DraggableLocation, DraggableProvided, Droppable, DropResult } from 'react-beautiful-dnd';
import styled from 'styled-components';
import CZTooltip, { QuestionToolTip } from '../CZTooltip/CZTooltip';
import Button from '../SmallComponents/Button';
import { boardCalcDataLength } from '../../pages/shipments/board/utils';
import BoardColumnList from './BoardColumnList';

type Props<T extends IBoardItem> = {
    columns: BoardColumnMap<T>;
    renderItem: (item: T, isDragging: boolean, provided: DraggableProvided) => JSX.Element;
    onDragItemEndFn: (source: DraggableLocation, destination: DraggableLocation) => Promise<void>;
    resetFilterFunc?: () => void;
    localization: any;
    isDragDisabled?: boolean;
};

function Board<T extends IBoardItem>(props: Props<T>) {
    const { columns, onDragItemEndFn, renderItem, resetFilterFunc, isDragDisabled, localization } = props;

    const [columnsState, setColumnsState] = useState<BoardColumnMap<T>>(columns);

    const setColumnOpen = (columnId: string, isOpen?: boolean) => {
        const currentColumnState = { ...columnsState };
        const currentColumn = currentColumnState[columnId];

        if (currentColumn) {
            currentColumn.isOpen = isOpen === undefined ? !currentColumn.isOpen : isOpen;
            setColumnsState(currentColumnState);
        }
    };

    const isColumnOpen = (columnId: string): boolean => {
        return columnsState[columnId].isOpen;
    };
    const onDragEnd = async (result: DropResult) => {
        if (!result.destination) return;
        const { source, destination } = result;
        if (source.droppableId !== destination.droppableId) {
            setColumnOpen(destination.droppableId, true);
            if (columns[source.droppableId].items.length === 1) {
                setColumnOpen(source.droppableId, false);
            }
        }
        await onDragItemEndFn(source, destination);
    };

    return boardCalcDataLength(columns) > 0 ? (
        <DragDropContextContainer>
            <DragDropContext onDragEnd={(result) => onDragEnd(result)}>
                {Object.entries(columns).map(([columnId, column], index) => {
                    return (
                        <ColumnContainer key={columnId}>
                            <ColumnTitle>
                                <span style={{ display: 'flex', alignItems: 'center' }}>
                                    {`${isColumnOpen(columnId) ? `${column.title} ` : ''}(${column.items.length})`}
                                    {isColumnOpen(columnId) && <CZTooltip text={column.toolTipText}>{QuestionToolTip}</CZTooltip>}
                                </span>
                                <span className="material-icons details-toggle" onClick={() => setColumnOpen(columnId)}>
                                    {isColumnOpen(columnId) ? 'keyboard_arrow_left' : 'keyboard_arrow_right'}
                                </span>
                            </ColumnTitle>
                            <div style={{ flex: 1 }}>
                                <Droppable droppableId={columnId} key={columnId}>
                                    {(provided, columnSnapshot) => {
                                        return (
                                            <ItemsContainer
                                                {...provided.droppableProps}
                                                ref={provided.innerRef}
                                                isDraggingOver={columnSnapshot.isDraggingOver}
                                                isOpen={isColumnOpen(columnId)}
                                            >
                                                <BoardColumnList column={column} renderItem={renderItem} />

                                                {!(isColumnOpen(columnId) || columnSnapshot.isDraggingOver) && (
                                                    <VerticalTitle>
                                                        {column.title}
                                                        <CZTooltip text={column.toolTipText}>{QuestionToolTip}</CZTooltip>
                                                    </VerticalTitle>
                                                )}
                                                {provided.placeholder}
                                            </ItemsContainer>
                                        );
                                    }}
                                </Droppable>
                            </div>
                        </ColumnContainer>
                    );
                })}
            </DragDropContext>
            <div style={{ flex: 1 }}>
                <ColumnTitle></ColumnTitle>
            </div>
        </DragDropContextContainer>
    ) : (
        <EmptyState>
            {resetFilterFunc ? (
                <>
                    <span>{localization.filter.noResults}</span>
                    <Button margin="15px 0 0 0" onClick={resetFilterFunc} buttonType="filled" minWidth="55px">
                        {localization.filter.resetBtn}
                    </Button>
                </>
            ) : (
                <span>{localization.filter.noData}</span>
            )}
        </EmptyState>
    );
}

const DragDropContextContainer = styled.div`
    display: flex;
    width: 100%;
    min-height: 100%;
`;
const ColumnContainer = styled.div`
    display: flex;
    flex-direction: column;
`;

const ColumnTitle = styled.div`
    padding: 0 10px 4px 12px;
    border-bottom: 3px solid #008ac9;
    color: #394372;
    z-index: 1000;
    background-color: #fff;
    font-weight: 500;
    position: sticky;
    top: 58px;
    padding-top: 20px;
    left: 0;
    display: flex;
    align-items: center;
    justify-content: space-between;
    .material-icons {
        font-size: 20px;
        cursor: pointer;
        color: #008ac9;
    }
    height: 52px;
`;

const VerticalTitle = styled.div`
    writing-mode: vertical-rl;
    text-orientation: mixed;
    color: #394372;
    display: flex;
    align-items: center;
    position: absolute;
    top: 10px;
    left: 8px;
`;

interface ItemsContainerProps {
    isDraggingOver: boolean;
    isOpen: boolean;
}
const Item = styled.div<ItemsContainerProps>`
    visibility: ${(props) => (props.isOpen || props.isDraggingOver ? 'visible' : 'hidden')};
`;

const ItemsContainer = styled.div<ItemsContainerProps>`
    background: ${(props) => (props.isDraggingOver ? 'lightblue' : 'white')};
    border-right: 1px solid #e4e4e4;
    padding: 8px;
    height: 100%;
    width: ${(props) => (props.isOpen || props.isDraggingOver ? '280px' : '60px')};
    position: relative;
`;

const EmptyState = styled.div`
    height: auto;
    background-image: linear-gradient(to bottom, #e9e9e9db, #ffffff);
    padding-top: 51px;
    padding-left: 56px;
    color: ${(props) => props.theme.colors.primaryBlue};
`;

export default Board;
