import { ModelConfig } from '@rematch/core';

export type singleBidStateType = {
    bidId: string | null;
};

export const singleBid: ModelConfig<singleBidStateType> = {
    state: {
        bidId: null
    },
    reducers: {
        setCurrentBidId(state: singleBidStateType, bidId: string): singleBidStateType {
            return { ...state, bidId };
        }
    }
};
