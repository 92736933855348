import { bid } from '../../../models/entities/bid/bid';
import { ModelConfig } from '@rematch/core';
import { party } from '../../../models/entities/bid/party';
import { setEntities, addEntity, editEntity, removeEntity } from '../../util/common_subentities/generic';
import { localSDK as client } from '../../../sdk';
import { shipment } from '../../../models/entities/shipment/shipment';
import { document } from '../../../models/entities/common_subentities/document';
import { getShipmentPdfFile } from '../../../components/pages/singleShipment/ShipmentPdfDocument';
import { localizationSelectors } from '../localization/selectors';
import { RootState } from '../../store/store';
import { businessPartnersSelectors } from '../businessPartner/selectors';
import { userSelectors } from '../user/selectors';
import { shipmentsSelectors } from '../shipments/selectors';

export type bidsStateType = {
    bids: Array<bid>;
    myRFQ: Array<bid>;
    error?: string;
    errorFetchSingleBid?: string;
    shipment?: shipment;
};

type createBidPayload = {
    shipment: shipment;
    bid: bid;
    publish: boolean;
};

type updateBidPayload = {
    id: string;
    bid: bid;
    publish: boolean;
    shipment: shipment;
};
type updateBidOverview = {
    id: string;
    bid: bid;
    shipmentId: string;
};
export const bids: ModelConfig<bidsStateType> = {
    state: {
        bids: [],
        myRFQ: []
    },
    reducers: {
        setBids(state: bidsStateType, bids: Array<bid>): bidsStateType {
            return { ...state, bids };
        },
        setMyRFQ(state: bidsStateType, myRFQ: Array<bid>): bidsStateType {
            return { ...state, myRFQ };
        },
        setFetchBidsError(state: bidsStateType, error: string): bidsStateType {
            return { ...state, error };
        },
        setSingleBid(state: bidsStateType, newBid: bid): bidsStateType {
            const newBids = [...state.bids];
            const bidIndex = newBids.findIndex((bid) => bid.id === newBid.id);
            if (bidIndex !== -1) {
                newBids[bidIndex] = newBid;
            } else {
                newBids.push(newBid);
            }
            return { ...state, bids: newBids };
        },
        setBidState(state: bidsStateType, bidId: string, bidState: string): bidsStateType {
            const newBids = [...state.bids];
            const bidIndex = newBids.findIndex((bid) => bid.id === bidId);
            if (bidIndex !== -1) {
                newBids[bidIndex].state = bidState;
            }
            return { ...state, bids: newBids };
        },
        setBidDocuments(state: bidsStateType, bidId: string, bidDocuments: Array<document>): bidsStateType {
            return { ...state, bids: setEntities<document>(state, bidId, 'bids', 'documents', bidDocuments) };
        },
        addBidDocument(state: bidsStateType, bidNumber: string, document: document): bidsStateType {
            return { ...state, bids: addEntity<document>(state, bidNumber, 'bids', 'documents', document) };
        },
        editBidDocument(state: bidsStateType, bidNumber: string, document: document): bidsStateType {
            return { ...state, bids: editEntity<document>(state, bidNumber, 'bids', 'documents', document) };
        },
        removeBidDocument(state: bidsStateType, bidNumber: string, documentId: number): bidsStateType {
            return { ...state, bids: removeEntity<document>(state, bidNumber, 'bids', 'documents', documentId) };
        },
        setBidParties(state: bidsStateType, bidId: string, bidParties: Array<party>): bidsStateType {
            return { ...state, bids: setEntities<party>(state, bidId, 'bids', 'parties', bidParties) };
        },
        addBidParty(state: bidsStateType, bidNumber: string, party: party): bidsStateType {
            return { ...state, bids: addEntity<party>(state, bidNumber, 'bids', 'parties', party) };
        },
        editBidParty(state: bidsStateType, bidNumber: string, party: party): bidsStateType {
            return { ...state, bids: editEntity<party>(state, bidNumber, 'bids', 'parties', party) };
        },
        removeBidParty(state: bidsStateType, bidNumber: string, partyId: number): bidsStateType {
            return { ...state, bids: removeEntity<party>(state, bidNumber, 'bids', 'parties', partyId) };
        },
        setTempShipment(state: bidsStateType, shipment: shipment): bidsStateType {
            return { ...state, shipment };
        },
        setFetchSingleBidError(state: bidsStateType, error: string) {
            return { ...state, errorFetchSingleBid: error };
        },
        setError(state: bidsStateType, error: string): bidsStateType {
            return { ...state, error };
        }
    },
    effects: (dispatch: any) => ({
        async fetchBids() {
            const bids = await client.bids().fetchBids();
            dispatch.bids.setBids(bids);
        },
        async fetchBidById(bidId: string) {
            const bidData = await client.bids().fetchById(bidId);
            dispatch.bids.setSingleBid(bidData);

            return bidData;
        },
        async fetchMyRFQ() {
            const rfq = await client.bids().getMyRFQ();
            dispatch.bids.setMyRFQ(rfq);

            return rfq;
        },
        async getShipmentPdfDocument(shipment: shipment, state: RootState) {
            const shipmentPdfFile = await getShipmentPdfFile({
                shipment,
                localization: localizationSelectors.singleShipmentPage(state),
                shipmentSettings: shipmentsSelectors.settingsByLanguage(state)(),
                settings: businessPartnersSelectors.settingsByLanguage(state),
                featureFlags: userSelectors.featureFlags(state)
            });

            return shipmentPdfFile;
        },
        async createBid({ bid, publish, shipment }: createBidPayload, state: RootState) {
            const shipmentPdfFile = await dispatch.bids.getShipmentPdfDocument(shipment);
            const createdBid = await client.bids().createBid(bid, shipmentPdfFile, publish);
            dispatch.shipments.setShipmentBid(bid.shipmentId, { ...createdBid, state: publish ? 'IN_PROGRESS' : 'NEW' });
            dispatch.shipments.setShipmentState(bid.shipmentId, 'BID');
            return createdBid.id;
        },
        async updateBid({ id, bid, publish, shipment }: updateBidPayload) {
            const shipmentPdfFile = await dispatch.bids.getShipmentPdfDocument(shipment);
            const updatedBid = await client.bids().updateBid(id, bid, shipmentPdfFile, publish);
            dispatch.shipments.setShipmentBid(bid.shipmentId, { ...updatedBid, state: publish ? 'IN_PROGRESS' : 'NEW' });
            dispatch.shipments.setShipmentState(bid.shipmentId, 'BID');
            return true;
        },
        async startBid({ id, shipmentId, shipment }: { id: string; shipmentId: string; shipment: shipment }) {
            const shipmentPdfFile = await dispatch.bids.getShipmentPdfDocument(shipment);
            await client.bids().startBid(id, shipmentPdfFile);
            dispatch.shipments.setShipmentBid(shipmentId, { state: 'IN_PROGRESS' });
            dispatch.shipments.setShipmentState(shipmentId, 'BID');
            dispatch.shipments.fetchShipmentHistoryById(shipmentId);
            return true;
        },
        async updateBidOverview({ id, bid, shipmentId }: updateBidOverview) {
            await client.bids().updateBidOverview(id, bid);
            dispatch.shipments.setShipmentBid(shipmentId, bid);
            return true;
        },
        async reSendEmail({ bidId, partyId, shipment }: { bidId: string; partyId: string; shipment: shipment }) {
            const shipmentPdfFile = await dispatch.bids.getShipmentPdfDocument(shipment);
            await client.bids().reSendEmail(bidId, partyId, shipmentPdfFile);
            return true;
        },
        async addBidParties({
            shipmentId,
            bidId,
            parties,
            shipment
        }: {
            shipmentId: string;
            bidId: string;
            parties: Array<party>;
            shipment?: shipment;
        }) {
            let shipmentPdfFile = undefined;
            if (shipment) {
                shipmentPdfFile = await dispatch.bids.getShipmentPdfDocument(shipment);
            }

            const createdParties = await client.bids().addBidParties(bidId, parties, shipmentPdfFile);
            dispatch.shipments.setShipmentBidParties(shipmentId, createdParties);
            return true;
        },
        async fetchShipmentById(shipmentId: string) {
            const shipmentData = await client.shipments().fetchById(shipmentId);
            dispatch.bids.setTempShipment(shipmentData);
        },
        async fetchShipments() {
            const shipments = await client.shipments().fetchShipmentsByFilter([]);
            // dispatch.bids.setShipments(shipments);
        },

        //quotation
        async approveQuotation({
            quotationId,
            shipmentId,
            transportationPrice,
            transportationPriceCurrency,
            sendNotApprovedEmails,
            ccRecipients
        }: {
            quotationId: number;
            shipmentId: string;
            transportationPrice: number;
            transportationPriceCurrency: string;
            sendNotApprovedEmails?: boolean;
            ccRecipients?: string[];
        }) {
            const shipment: shipment = await client.bids().approveQuotation(quotationId, sendNotApprovedEmails, ccRecipients);
            dispatch.shipments.editShipment(shipmentId, shipment);

            if (!Number(shipment.transportationPrice)) {
                dispatch.shipments.updateShipmentOverview({
                    id: shipmentId,
                    shipmentOverview: {
                        transportationPrice,
                        transportationPriceCurrency,
                        incoterms: shipment.incoterms
                    }
                });
            }
            return true;
        },

        //public quotation
        async fetchByToken(token: string) {
            const bidData = await client.bids().fetchByToken(token);
            return bidData;
        },
        async createQuotation({ formData, isPublic }: { formData: FormData; isPublic?: boolean }) {
            await client.bids().createQuotation(formData, isPublic);
            return true;
        },
        async quotationGetFile({ fileId, isPublic, bidId, partyId }: { fileId: string; bidId: string; partyId: string; isPublic?: boolean }) {
            return await client.bids().quotationGetFile(fileId, bidId, partyId, isPublic);
        }
    })
};
