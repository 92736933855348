import React, { useEffect, useState } from 'react';
import { Route, Redirect, useHistory } from 'react-router-dom';
import { PagesRoutes } from './PagesRoutes';
import Authentication from '../authentication/authentication';
import Layout from '../components/shared/Layout/Layout';
import { connect } from 'react-redux';
import { dispatch, RootState } from '../state/store/store';
import { authenticationSelectors } from '../state/ducks/authentication/selectors';
import { userSelectors } from '../state/ducks/user/selectors';
import Loader from '../components/shared/SmallComponents/Loader';
import { headerSelectors } from '../state/ducks/header/selectors';
import { drawers } from '../state/ducks/header/header';
import { mobileCheck } from '../utils/loadScript';

const AuthRoute = ({
    token,
    refreshToken,
    refreshTokenError,
    component,
    companySettings,
    printMode,
    setPrintMode,
    featureFlags,
    setDrawerOpen,
    isDrawerOpen,
    ...rest
}: any) => {
    let loginRefer = PagesRoutes.Login;
    if (rest.location.pathname !== '/') {
        loginRefer += `?refer=${encodeURIComponent(rest.location.pathname + rest.location.search)}`;
    }
    useEffect(() => {
        if (!token) refreshToken();
    }, [token, refreshTokenError]);
    const history = useHistory();
    const [lastHistoryLocation, setLastHistory] = useState('');
    useEffect(() => {
        history.listen(({ pathname }) => {
            setLastHistory(pathname);
        });
        history.block(({ pathname }) => {
            const pathnameDiffers = lastHistoryLocation !== pathname;
            if (pathnameDiffers && mobileCheck() && isDrawerOpen !== null) {
                setDrawerOpen(null);
                return false;
            } else return;
        });
    }, [isDrawerOpen, setDrawerOpen]);
    const queryString = window.location.search;
    const urlParams = new URLSearchParams(queryString);
    if (urlParams.getAll('printMode').length > 0) {
        setPrintMode();
    }
    const Component = component;
    return (
        <>
            {!token && refreshTokenError && <Redirect to={loginRefer} />}
            <Route
                {...rest}
                render={(props) => {
                    if (token) {
                        Authentication.SetToken(token);
                        return (
                            <Layout printMode={printMode} featureFlags={featureFlags}>
                                {!companySettings ? <Loader /> : <Component {...props} />}
                            </Layout>
                        );
                    } else return null;
                }}
            />
        </>
    );
};

const mapStateToProps = (state: RootState) => ({
    token: authenticationSelectors.token(state),
    refreshTokenError: !!state.error.effects.authentication.refreshToken,
    companySettings: userSelectors.userInfo(state).companySettings,
    printMode: headerSelectors.printMode(state),
    featureFlags: userSelectors.featureFlags(state),
    isDrawerOpen: headerSelectors.drawerOpen(state)
});
const mapDispatchToProps = (dispatch: any) => ({
    refreshToken: dispatch.authentication.refreshToken,
    setPrintMode: () => dispatch.header.setPrintMode(true),
    setDrawerOpen: (open: drawers, params?: any) => dispatch.header.setDrawerOpenParams({ open, params })
});

export default connect(mapStateToProps, mapDispatchToProps)(AuthRoute);
