import React, { useState, useRef, useImperativeHandle, PropsWithChildren } from 'react';
import { Menu, MenuItemProps } from '@mui/material';
import ArrowRight from '@mui/icons-material/ChevronRightRounded';
import { IconMenuItem, StyledMenu } from '.';

type Props = {
    parentMenuOpen: boolean;
    label: string;
    ref?: React.Ref<unknown>;
    rightIcon?: JSX.Element;
    leftIcon?: JSX.Element;
    className?: string;
    tabIndex?: number;
    disabled?: boolean;
} & Omit<MenuItemProps, 'sx'>;

const NestedMenuItem = (props: PropsWithChildren<Props>) => {
    const { ref, parentMenuOpen, label, rightIcon = <ArrowRight />, leftIcon, children, className, tabIndex: tabIndexProp, ...menuItemProps } = props;

    const menuItemRef = useRef(null);

    useImperativeHandle(ref, () => menuItemRef.current);

    const containerRef = useRef<HTMLDivElement>(null);

    const menuContainerRef = useRef<HTMLDivElement>(null);

    const [isSubMenuOpen, setIsSubMenuOpen] = useState(false);

    const handleMouseEnter = () => {
        setIsSubMenuOpen(true);
    };
    const handleMouseLeave = () => {
        setIsSubMenuOpen(false);
    };

    const isSubmenuFocused = () => {
        if (!containerRef?.current || !menuContainerRef?.current) return;

        const active = containerRef.current.ownerDocument.activeElement;
        for (const child of menuContainerRef.current.children as any) {
            if (child === active) {
                return true;
            }
        }
        return false;
    };

    const handleFocus: React.FocusEventHandler<HTMLDivElement> = (event) => {
        if (event.target === containerRef.current) {
            setIsSubMenuOpen(true);
        }
    };

    const handleKeyDown: React.KeyboardEventHandler<HTMLDivElement> = (event) => {
        if (event.key === 'Escape') {
            return;
        }

        if (isSubmenuFocused()) {
            event.stopPropagation();
        }

        if (!containerRef?.current) return;

        const active = containerRef.current.ownerDocument.activeElement;

        if (event.key === 'ArrowLeft' && isSubmenuFocused()) {
            containerRef.current.focus();
        }

        if (!menuContainerRef?.current) return;

        if (event.key === 'ArrowRight' && event.target === containerRef.current && event.target === active) {
            const firstChild = menuContainerRef.current.children[0];
            (firstChild as HTMLElement)?.focus();
        }
    };

    const open = isSubMenuOpen && parentMenuOpen;

    let tabIndex;
    if (!props.disabled) {
        tabIndex = tabIndexProp !== undefined ? tabIndexProp : -1;
    }

    return (
        <div
            ref={containerRef}
            onFocus={handleFocus}
            tabIndex={tabIndex}
            onMouseEnter={handleMouseEnter}
            onMouseLeave={handleMouseLeave}
            onKeyDown={handleKeyDown}
        >
            <div ref={menuItemRef}>
                <IconMenuItem menuItemProps={menuItemProps} className={className} leftIcon={leftIcon} rightIcon={rightIcon} label={label} />
            </div>

            <StyledMenu
                style={{ pointerEvents: 'none' }}
                anchorEl={menuItemRef.current}
                anchorOrigin={{
                    vertical: 'top',
                    horizontal: 'right'
                }}
                transformOrigin={{
                    vertical: 'top',
                    horizontal: 'left'
                }}
                open={open}
                autoFocus={false}
                disableAutoFocus
                disableEnforceFocus
                onClose={() => {
                    setIsSubMenuOpen(false);
                }}
            >
                <div ref={menuContainerRef} style={{ pointerEvents: 'auto' }}>
                    {children}
                </div>
            </StyledMenu>
        </div>
    );
};

export default NestedMenuItem;
