import React, { useEffect, useState } from 'react';
import moment from 'moment';
import { bid } from '../../../../models/entities/bid/bid';
import BackButton from '../../../shared/SmallComponents/BackButton';
import RouteLeavingGuard from '../../../shared/SmallComponents/RouteLeavingGuard';
import { PagesRoutes } from '../../../../routing/PagesRoutes';
import theme, { styled } from '../../../shared/Theme/theme';
import CZTooltip, { QuestionToolTip } from '../../../shared/CZTooltip/CZTooltip';
import { Formik, Form } from 'formik';
import Button from '../../../shared/SmallComponents/Button';
import { validationSchema } from '../../../../validationSchemas/bidForm';
import { useHistory } from 'react-router-dom';
import { BaseProps, eBidFormMode } from './BidFormConnected';
import Loader from '../../../shared/SmallComponents/Loader';
import CustomModal, { ModalTriggerProps } from '../../../shared/Modal/Modal';
import { FormDatePicker, FormDropDown, FormTextArea } from '../../../shared/inputs/form';
import Parties, { emptyPartyObj } from './sections/Parties';
import { partiesPrepareSubmit } from './sections/Parties/utils';
import Documents from './sections/Documents';
import OverviewHeader from '../../bids/singleBid/sections/OverviewHeader';
import { enumToDropdownOptions } from '../../../shared/inputs/base/DropDown';
import { eShipmentTypes } from '../../../../models/entities/shipment/shipmentTypes';
import { renderShipmentTypeOption } from '../../../shared/SmallComponents/ShipmentTypeOption';

type Props = BaseProps & {
    mode: string;
};

const shipmentTypesDropDownOptions = enumToDropdownOptions(eShipmentTypes);

export const renderFieldLabel = (text: string, isMandatory: boolean, tooltipText?: string) => {
    return (
        <LabelContainer>
            <Label>
                {isMandatory && <MandatoryIndication>*</MandatoryIndication>}
                {text}
            </Label>
            {tooltipText && <CZTooltip text={tooltipText}>{QuestionToolTip}</CZTooltip>}
        </LabelContainer>
    );
};
const SingleBidForm = ({ localizations, createBid, updateBid, bid, mode, businessPartners, shipment }: Props) => {
    const history = useHistory();
    const [submitClicked, setSubmitClicked] = useState(false);
    const [currentBidId, setCurrentBidId] = useState<string | null>(null);
    const [showGoBackModal, setShowGoBackModal] = useState(false);

    const onCancelClick = () => {
        history.push(PagesRoutes.Bids);
    };

    useEffect(() => {
        if ([eBidFormMode.EDIT, eBidFormMode.DUPLICATE].includes(mode) && bid) {
            setCurrentBidId(bid.id);
        } else {
            setCurrentBidId(null);
        }
    }, [mode, bid]);

    const renderGoBackModal = () => {
        return (
            <CustomModal
                title={{ text: 'Where do you want to go?' }}
                Trigger={(props: ModalTriggerProps) => <>{props.onClick()}</>}
                Content={(setVisible?: React.Dispatch<React.SetStateAction<boolean>>) => {
                    return (
                        <div style={{ display: 'flex', justifyContent: 'space-around' }}>
                            <Button
                                onClick={() => {
                                    history.push(PagesRoutes.Bids);
                                }}
                            >
                                {localizations.form.back_modal.bids_page}
                            </Button>
                            <Button
                                onClick={() => {
                                    history.push(PagesRoutes.Bids + '/' + currentBidId);
                                }}
                            >
                                {localizations.form.back_modal.bid_details}
                            </Button>
                        </div>
                    );
                }}
                onCancelCallback={() => {
                    setShowGoBackModal(false);
                }}
                width="393px"
            />
        );
    };

    const setInitialValues = () => {
        const initValues = {
            companyId: shipment.companyId,
            shipmentId: shipment.id,
            shipmentTypes: [],
            note: null,
            expirationDate: moment(new Date()).add('7', 'days'),
            parties: [emptyPartyObj],
            documents: []
        };
        if ([eBidFormMode.EDIT, eBidFormMode.DUPLICATE].includes(mode) && bid) {
            return {
                companyId: bid.companyId,
                shipmentId: bid.shipmentId,
                shipmentTypes: bid.shipmentTypes,
                expirationDate: bid.expirationDate,
                note: bid.note,
                parties: bid.parties && bid.parties.length > 0 ? bid.parties : [emptyPartyObj],
                documents: bid.documents
            };
        }
        return initValues;
    };
    const formValidationSchema = validationSchema(localizations);

    const validate = (values: any) => {
        const errors: any = {};
        if (values.parties.length === 0 || values.parties[0].id === '') errors.parties = '';
        return errors;
    };
    return (
        <>
            <Formik
                initialValues={setInitialValues()}
                validationSchema={formValidationSchema}
                validate={validate}
                onSubmit={async (values) => {
                    setSubmitClicked(true);

                    const bidObject = {
                        companyId: shipment.companyId,
                        shipmentId: shipment.id,
                        shipmentTypes: values.shipmentTypes,
                        expirationDate: values.expirationDate,
                        note: values.note,
                        parties: partiesPrepareSubmit(values, businessPartners, bid?.id),
                        documents: values.documents
                    } as bid;
                    if ([eBidFormMode.NEW, eBidFormMode.DUPLICATE].includes(mode)) {
                        await createBid(shipment, bidObject).then((response: string) => {
                            if (response) {
                                setCurrentBidId(response);
                                setShowGoBackModal(true);
                            }
                        });
                    } else if (bid) {
                        await updateBid(bid.id, bidObject, shipment).then((response: any) => {
                            if (response) history.push(PagesRoutes.Bids + '/' + currentBidId);
                        });
                    }
                }}
            >
                {({ values, errors, setFieldValue, isSubmitting, isValidating }) => {
                    return (
                        <PageContainer>
                            {isSubmitting && !isValidating && (
                                <LoaderOverlay>
                                    <Loader></Loader>
                                </LoaderOverlay>
                            )}
                            {showGoBackModal && currentBidId && renderGoBackModal()}
                            <Form>
                                <Container>
                                    <FormHeader>
                                        <BackButton
                                            style={{}}
                                            toPath={currentBidId ? PagesRoutes.Bids + '/' + currentBidId : PagesRoutes.Bids}
                                            text="Back to bids page"
                                        />
                                    </FormHeader>
                                    <OverviewHeader shipment={shipment} bid={bid} localization={localizations} padding="24px 18px 18px 0" />
                                    <br />

                                    <StyledFieldContainer>
                                        <div>{renderFieldLabel('Shipment Types', false)}</div>
                                        <FormDropDown
                                            name="shipmentTypes"
                                            placeHolder="Pick type"
                                            style={{ width: '200px' }}
                                            options={shipmentTypesDropDownOptions}
                                            optionRenderer={renderShipmentTypeOption}
                                            mode="multiple"
                                        />
                                    </StyledFieldContainer>
                                    <Flex>
                                        <StyledFieldContainer>
                                            <div>{renderFieldLabel(localizations.form.expirationdate, true)}</div>
                                            <div>
                                                <FormDatePicker
                                                    name="expirationDate"
                                                    style={{ width: '200px' }}
                                                    placeHolder={localizations.form.expirationdate}
                                                    showTime
                                                />
                                            </div>
                                        </StyledFieldContainer>
                                    </Flex>
                                    <StyledFieldContainer>
                                        <div>{renderFieldLabel('Note', false)}</div>
                                        <FormTextArea style={{ width: '200px' }} name="note" placeHolder="Note" />
                                    </StyledFieldContainer>
                                    <div>
                                        <Parties
                                            values={values}
                                            setFieldValue={setFieldValue}
                                            businessPartners={businessPartners}
                                            disabled={false}
                                            localization={localizations.form}
                                        />
                                    </div>
                                    <br />
                                    <div>
                                        <Documents values={values} setFieldValue={setFieldValue} localization={localizations.form} />
                                    </div>
                                </Container>
                                <Buttons>
                                    <Button disabled={bid && bid.state !== 'NEW'} buttonType="filled" width="100px" height="30px" type="submit">
                                        Save
                                    </Button>
                                    <Button width="100px" height="30px" type="button" onClick={onCancelClick}>
                                        Cancel
                                    </Button>
                                </Buttons>
                            </Form>
                        </PageContainer>
                    );
                }}
            </Formik>
            <RouteLeavingGuard
                title={localizations.form.route_guard}
                when={!submitClicked}
                navigate={(path) => history.push(path)}
                shouldBlockNavigation={(location) => {
                    return true;
                }}
            />
        </>
    );
};

const Buttons = styled.div`
    width: 100%;
    border-top: 1px solid #e0e0e0;
    margin-top: -15px;
    padding-top: 25px;
    padding-bottom: 25px;
    padding-left: 70px;
    display: flex;

    button {
        margin-right: 20px;
    }
`;

const LoaderOverlay = styled.div`
    position: absolute;
    width: inherit;
    z-index: 1001;
    height: 100%;
    background: ${theme.colors.overlayBG};
`;
const FieldContainer = styled.div`
    margin-bottom: 12px;
`;
const StyledFieldContainer = styled.div`
    margin-bottom: 12px;
    padding-right: 30px;
`;
const PageContainer = styled.div`
    position: relative;
    width: 100%;
`;

const Label = styled.label`
    color: ${(props) => props.theme.colors.pageTitle};
`;

const MandatoryIndication = styled.span`
    color: red;
    margin-right: 7px;
`;
const FormHeader = styled.div`
    flex-direction: row;
    justify-content: space-between;
    margin-bottom: 30px;

    & a {
        position: relative;
        left: -30px;
    }
`;

const Container = styled.div`
    position: relative;
    width: 100%;
    padding: 25px;
    padding-left: 40px;
    padding-right: 40px;
`;

const LabelContainer = styled.div`
    display: flex;
    margin-bottom: 5px;

    & img {
        margin-top: -2px;
        margin-left: 5px;
    }
`;
export const Flex = styled.div`
    display: flex;
`;

export const AddContainer = styled.div`
    cursor: pointer;
    display: inline-flex;
    align-items: center;
    font-size: 13px;
    line-height: 0;
    color: ${(props) => props.theme.colors.primaryBlue};
    span.material-icons {
        font-size: inherit;
        margin-right: 5px;
        user-select: none;
    }
`;

export default SingleBidForm;
