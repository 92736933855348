import React from 'react';
import { connect } from 'react-redux';
import { createTariffPayload } from '../../../../models/entities/tariff';
import { businessPartnersSelectors } from '../../../../state/ducks/businessPartner/selectors';
import { localizationSelectors } from '../../../../state/ducks/localization/selectors';
import { userSelectors } from '../../../../state/ducks/user/selectors';
import { RootState } from '../../../../state/store/store';
import { Option } from '../../../shared/inputs/base/DropDown';
import TariffForm from './TariffForm';

type Props = {
    allowedCompaniesDropDownOptions: Option[];
    businessPartnersDropDownOptions: Option[];
    allowedShipmentTypes: Array<string>;
    localization: any;
    createTariff: (tariff: createTariffPayload) => Promise<void>;
};

const TariffFormConnected = ({
    allowedCompaniesDropDownOptions,
    businessPartnersDropDownOptions,
    allowedShipmentTypes,
    localization,
    createTariff
}: Props) => {
    return (
        <TariffForm
            allowedCompaniesDropDownOptions={allowedCompaniesDropDownOptions}
            businessPartnersDropDownOptions={businessPartnersDropDownOptions}
            allowedShipmentTypes={allowedShipmentTypes}
            localization={localization}
            createTariff={createTariff}
        />
    );
};

const mapStateToProps = (state: RootState) => ({
    localization: localizationSelectors.singleTariff(state).form,
    allowedCompaniesDropDownOptions: userSelectors.allowedCompaniesDropDownOptions(state),
    businessPartnersDropDownOptions: businessPartnersSelectors.businessPartnersDropDownOptions(state),
    allowedShipmentTypes: userSelectors.allowedTypes(state)
});

const mapDispatchToProps = (dispatch: any) => ({
    createTariff: (tariff: createTariffPayload) => dispatch.tariffs.createTariff(tariff)
});

export default connect(mapStateToProps, mapDispatchToProps)(TariffFormConnected);
