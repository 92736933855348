import IDimensionsWeight from '../interfaces/dimensionsWeight';

export const calcQuantity = (items: Array<IDimensionsWeight>) => {
    return items.reduce((total: number, item: IDimensionsWeight) => {
        return total + (Number(item.quantity) || 0);
    }, 0);
};

export const calcVolume = (item: IDimensionsWeight, oneItem?: boolean) => {
    let volume = 0;

    const { length, width, height, dimensionsUnit, dimensionUnit, quantity } = item;

    const unit = dimensionsUnit || dimensionUnit;
    if (width && height && length && unit && quantity) {
        const divission = unit === 'IN' ? 1728 : 1000000;
        volume = (oneItem ? 1 : Number(quantity) || 0) * (((Number(width) || 0) * (Number(height) || 0) * (Number(length) || 0)) / divission);
    }
    return { volume, unit: unit === 'IN' ? 'CFT' : 'CBM' };
};

export const calcTotalVolume = (items: Array<IDimensionsWeight>) => {
    const volumeUnit = items.every((item) => item.dimensionsUnit === 'CM' || item.dimensionUnit === 'CM') ? 'CBM' : 'CFT';

    const volume = items.reduce((totalVolume: number, item: IDimensionsWeight) => {
        const { volume, unit } = calcVolume(item);

        let divission = 1;
        if (volumeUnit === 'CBM' && unit === 'CFT') divission = 35.3147;

        return totalVolume + volume / divission;
    }, 0);
    return { volume, unit: volumeUnit };
};

export const calcTotalWeight = (items: Array<IDimensionsWeight>) => {
    let weightUnit = 'KG';
    const totalWeight = items.reduce((total: number, item: IDimensionsWeight) => {
        if (!item.weight || !item.weightUnit) return total;

        let converaionRate = 1;
        if (item.weightUnit === 'LB') converaionRate = 0.45359237;
        if (item.weightUnit === 'G') converaionRate = 0.001;

        return total + (item.quantity || 0) * item.weight * converaionRate;
    }, 0);
    return { weight: totalWeight, unit: weightUnit };
};

export const calcWeightVolume = (items?: Array<IDimensionsWeight>) => {
    let weight = 0;
    let weightUnit = '';
    let volume = 0;
    let volumeUnit = '';
    let chargeableWeight = 0;
    if (items && items.length > 0) {
        const volumeCalc = calcTotalVolume(items);
        const weightCalc = calcTotalWeight(items);
        weight = weightCalc.weight;
        weightUnit = weightCalc.unit;
        volume = volumeCalc.volume;
        volumeUnit = volumeCalc.unit;
    }
    return { weight, weightUnit, volume, volumeUnit };
};

export const sumVolumes = (volumes: Array<{ volume: number; unit: string }>) => {
    const finalUnit = volumes.every((volume) => volume.unit === 'CFT') ? 'CFT' : 'CBM';

    const volume = volumes.reduce((totalVolume: number, item: { volume: number; unit: string }) => {
        const { volume, unit } = item;

        let divission = 1;
        if (finalUnit === 'CBM' && unit === 'CFT') divission = 35.3147;

        return Number((totalVolume + volume / divission).toFixed(2));
    }, 0);

    return { volume, unit: finalUnit };
};
