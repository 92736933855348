import React from 'react';
import { PagesRoutes } from '../../../../routing/PagesRoutes';
import StyledLink from '../../SmallComponents/StyledLink';

type Props = {
    bidId: string;
    bidNumber: string;
};

const BidLink = ({ bidId, bidNumber }: Props) => {
    return <StyledLink to={`${PagesRoutes.Bids}/${bidId}`}>{bidNumber || '-'}</StyledLink>;
};

export default BidLink;
