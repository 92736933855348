import { createShipmentTariffQuotationsPayload } from './../../../models/entities/tariff/payloads/createShipmentTariffQuotationsPayload';
import { tariffCharge, createTariffChargePayload } from './../../../models/entities/tariff/charge';
import { ModelConfig } from '@rematch/core';
import { filterFieldsType, paginationType } from '../../../components/shared/PageTemplates/GridPage/components/GridPageHeader/models';
import { tariff, createTariffPayload } from '../../../models/entities/tariff';
import { localSDK as client } from '../../../sdk';

export type tariffsStateType = {
    tariffs: tariff[];
    gridFilter: string | null;
    filterFields: filterFieldsType | null;
    pagination: paginationType;
};

export const tariffs: ModelConfig<tariffsStateType> = {
    state: {
        tariffs: [],
        gridFilter: null,
        filterFields: [],
        pagination: {
            currentPage: 0,
            rowsPerPage: 30
        }
    },
    reducers: {
        setSingleTariff(state: tariffsStateType, newTariff: tariff): tariffsStateType {
            const newTariffs = [...state.tariffs];
            const tariffIndex = newTariffs.findIndex((tariff) => tariff.id === newTariff.id);
            if (tariffIndex !== -1) {
                newTariffs[tariffIndex] = newTariff;
            } else {
                newTariffs.push(newTariff);
            }
            return { ...state, tariffs: newTariffs };
        },
        setTariffs(state: tariffsStateType, tariffs: tariff[]): tariffsStateType {
            return { ...state, tariffs };
        },
        addTariff(state: tariffsStateType, tariff: tariff): tariffsStateType {
            const newTariffs = [...state.tariffs, tariff];
            return { ...state, tariffs: newTariffs };
        },
        setTariffCharges(state: tariffsStateType, { tariffId, charges }: { tariffId: string; charges: tariffCharge[] }) {
            const newTariffs = [...state.tariffs];
            const tariffIndex = newTariffs.findIndex((tariff) => tariff.id === tariffId);

            if (tariffIndex === -1) return state;

            newTariffs[tariffIndex].charges = charges;

            return { ...state, tariffs: newTariffs };
        },
        addTariffCharge(state: tariffsStateType, { tariffId, charge }: { tariffId: string; charge: tariffCharge }) {
            const newTariffs = [...state.tariffs];
            const tariffIndex = newTariffs.findIndex((tariff) => tariff.id === tariffId);

            if (tariffIndex === -1) return state;

            const tariff = { ...newTariffs[tariffIndex] };
            const newCharges = [...(tariff.charges || []), charge];
            tariff.charges = newCharges;
            newTariffs[tariffIndex] = tariff;

            return { ...state, tariffs: newTariffs };
        },
        setGridFilter(state: tariffsStateType, filterText: string): tariffsStateType {
            return { ...state, gridFilter: filterText };
        },
        setCurrentPage(state: tariffsStateType, currentPage: number): tariffsStateType {
            return { ...state, pagination: { ...state.pagination, currentPage } };
        },
        setRowsPerPage(state: tariffsStateType, rowsPerPage: number): tariffsStateType {
            return { ...state, pagination: { ...state.pagination, rowsPerPage } };
        },
        setFilterFields(state: tariffsStateType, filterFields: filterFieldsType): tariffsStateType {
            return { ...state, filterFields };
        }
    },
    effects: (dispatch: any) => ({
        async fetchTariffs() {
            const tariffs = await client.tariffs().fetchTariffs();
            dispatch.tariffs.setTariffs(tariffs);
            dispatch.allShipmentsPage.setCurrentPage(0);
        },
        async fetchTariffById(tariffId: string) {
            const tariff = await client.tariffs().fetchTariffById(tariffId);
            dispatch.tariffs.setSingleTariff(tariff);

            return tariff;
        },
        async setTariffsFilters(filter: filterFieldsType) {
            dispatch.tariffs.setFilterFields(filter);
            dispatch.tariffs.setCurrentPage(0);
        },
        async createTariff(tariff: createTariffPayload) {
            const createdTariff = await client.tariffs().createTariff(tariff);
            dispatch.tariffs.addTariff(createdTariff);
        },
        async updateTariff({ tariffId, tariff }: { tariffId: string; tariff: createTariffPayload }) {
            const updatedTariff = await client.tariffs().updateTariff(tariffId, tariff);
            dispatch.tariffs.setSingleTariff(updatedTariff);
        },
        async fetchChargesByTariffId(tariffId: string) {
            const charges = await client.tariffs().charges().fetchChargesByTariffId(tariffId);
            dispatch.tariffs.setTariffCharges({ tariffId, charges });
        },
        async createTariffCharge(payload: createTariffChargePayload) {
            const charge = await client.tariffs().charges().createTariffCharge(payload);
            dispatch.tariffs.addTariffCharge({ tariffId: payload.tariffId, charge });
        },
        async createShipmentTariffQuotations(payload: createShipmentTariffQuotationsPayload) {
            const tariffQuotations = await client.tariffs().createShipmentTariffQuotationsPayload(payload);
            dispatch.shipments.setShipmentTariffQuotations(payload.shipmentId, tariffQuotations);
        }
    })
};
