import { ColumnType } from '../../../components/shared/Grid/types/Column';
import { purchaseDemand } from '../../../models/entities/purchaseDemand/purchaseDemand';
import SideEffectJS from 'side-effect-js';
import { ModelConfig } from '@rematch/core';
import { RootState } from '../../store/store';
import { localSDK as client } from '../../../sdk';

export type filterFieldsType = Array<{ field: string; value: any }>;
export type paginationType = { currentPage: number; rowsPerPage: number };

export type purchaseDemandsPageStateType = {
    gridColumns: Array<ColumnType<purchaseDemand>>;
    fetchGridColumnsError: string | null;
    gridSort: gridSortState | null;
    gridFilter: string | null;
    error: string | null;
    filterFields: filterFieldsType | null;
    pagination: paginationType;
};

export type gridSortState = {
    direction: 'asc' | 'desc';
    column: keyof purchaseDemand;
};

export type exportTypes = 'PDF' | 'EXCEL';

const defaultGridSort: gridSortState = {
    direction: 'desc',
    column: 'CargoZoneNumber'
};

export const allPurchaseDemandsPage: ModelConfig<purchaseDemandsPageStateType> = {
    state: {
        gridColumns: [],
        fetchGridColumnsError: null,
        gridSort: defaultGridSort,
        gridFilter: null,
        error: null,
        filterFields: null,
        pagination: {
            currentPage: 0,
            rowsPerPage: 30
        }
    },
    reducers: {
        setGridColumns(state: purchaseDemandsPageStateType, columns: Array<ColumnType<purchaseDemand>>): purchaseDemandsPageStateType {
            return { ...state, gridColumns: columns, fetchGridColumnsError: null };
        },
        setFetchColumnsError(state: purchaseDemandsPageStateType, error: string): purchaseDemandsPageStateType {
            return { ...state, fetchGridColumnsError: error };
        },
        setGridSort(state: purchaseDemandsPageStateType, sortData: gridSortState): purchaseDemandsPageStateType {
            return { ...state, gridSort: sortData };
        },
        setGridFilter(state: purchaseDemandsPageStateType, filterText: string): purchaseDemandsPageStateType {
            return { ...state, gridFilter: filterText };
        },
        setCurrentPage(state: purchaseDemandsPageStateType, currentPage: number): purchaseDemandsPageStateType {
            return { ...state, pagination: { ...state.pagination, currentPage } };
        },
        setRowsPerPage(state: purchaseDemandsPageStateType, rowsPerPage: number): purchaseDemandsPageStateType {
            return { ...state, pagination: { ...state.pagination, rowsPerPage } };
        },
        setFilterFields(state: purchaseDemandsPageStateType, filterFields: filterFieldsType): purchaseDemandsPageStateType {
            return { ...state, filterFields };
        },
        setError(state: purchaseDemandsPageStateType, error: string): purchaseDemandsPageStateType {
            return { ...state, error: error };
        }
    },
    effects: (dispatch: any) => ({
        async fetchGridColumns() {
            try {
                const fetchGridColumns = SideEffectJS.Get('gridColumnsPurchaseDemandsPage');
                const gridColumns = await fetchGridColumns();
                dispatch.allPurchaseDemandsPage.setGridColumns(gridColumns);
            } catch (e) {
                dispatch.allPurchaseDemandsPage.setFetchColumnsError(e);
            }
        },
        async exportDataTable(type: exportTypes, state: RootState) {},
        async exportDataRow({ purchaseDemandId, type }: { purchaseDemandId: string; type: exportTypes }) {},
        async setPurchaseDemandFilters(filter: filterFieldsType) {
            dispatch.allPurchaseDemandsPage.setFilterFields(filter);
            dispatch.allPurchaseDemandsPage.setCurrentPage(0);
        },
        async fetchPurchaseDemandsByFilter(filter: filterFieldsType) {
            try {
                dispatch.allPurchaseDemandsPage.setFilterFields(filter);
                const purchaseDemands = await client.purchaseDemands().fetchPurchaseDemandsByFilter(filter);
                dispatch.purchaseDemands.setPurchaseDemands(purchaseDemands);
                dispatch.allPurchaseDemandsPage.setCurrentPage(0);
            } catch (e) {
                dispatch.allPurchaseDemandsPage.setError(e);
            }
        },
        async deletePurchaseDemand(purchaseDemandId: string) {
            await client.purchaseDemands().deletePurchaseDemand(purchaseDemandId);
            dispatch.purchaseDemands.removePurchaseDemand(purchaseDemandId);
        }
    })
};
