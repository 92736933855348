import { shipmentPackage } from '../../../../../../models/entities/shipment/shipmentPackage';
import { shipmentContainer } from '../../../../../../models/entities/shipment/shipmentContainer';

import { isEmpty } from 'lodash';
import { shipment, shipmentType } from '../../../../../../models/entities/shipment/shipment';
import { lclPackageEmptyObj, fclPackageEmptyObj } from './emptyObjects';
import { diff } from '../../../../../../utils/json-diff';
import { isErrorsEmpty } from '../../../../../../utils/utils-functions';
import { calcTotalVolume, calcTotalWeight } from '../../../../../../utils/measurementUtils';
import shipmentItem from '../../../../../../models/entities/shipment/shipmentItem';
import { calcShipmentItemsTotalVolumeWeight } from '../../../sections/ShipmentItems/utils';

export const packagesPrepareSubmit = (values: any, shipmentId?: string) => {
    let packages: Array<shipmentPackage> = [];
    if (values.packages) {
        packages = values.packages
            .filter((item: shipmentPackage) => {
                return !isEmpty(diff(item, lclPackageEmptyObj, false));
            })
            .map((item: shipmentPackage) => {
                return { ...item, shipmentId };
            });
    }
    return packages;
};
export const containersPrepareSubmit = (values: any, shipmentId?: string) => {
    let containers: Array<shipmentContainer> = [];
    if (values.containers) {
        containers = values.containers
            .filter((item: shipmentContainer) => {
                return !isEmpty(diff(item, fclPackageEmptyObj, false));
            })
            .map((item: shipmentContainer) => {
                return { ...item, shipmentId };
            });
    }
    return containers;
};
export const packagesPrepareEdit = (shipment_packages?: Array<shipmentPackage>) => {
    let packages = shipment_packages;
    if (packages) {
        if (!packages.length) packages.push(lclPackageEmptyObj);
    } else {
        packages = defaultPackages;
    }

    return packages;
};

export const containersPrepareEdit = (shipment_containers?: Array<shipmentContainer>) => {
    let containers = shipment_containers;
    if (containers) {
        if (!containers.length) containers.push(fclPackageEmptyObj);
    } else {
        containers = defaultContainers;
    }

    return containers;
};
export const validatePackages = (packages: Array<shipmentPackage>, errors: any, localizations: any) => {
    const requiredFieldMessage = localizations.form.requiredFieldMessage;
    if (!errors.packages) errors.packages = [];
    packages.forEach(() => {
        errors.packages.push({});
    });

    packages.forEach((item, index) => {
        const { description, hsCode, length, width, height, dimensionsUnit, weight, weightUnit } = item;
        const lclHasDimantions = length && width && height && dimensionsUnit;
        const lclHasWeight = weight && weightUnit;

        if (length || width || height || weight || description || hsCode) {
            if (!lclHasDimantions) {
                errors.packages[index].length = requiredFieldMessage;
                errors.packages[index].width = '';
                errors.packages[index].height = '';
                errors.packages[index].dimensionsUnit = '';
            }
            if (!lclHasWeight) {
                errors.packages[index].weight = requiredFieldMessage;
                errors.packages[index].weightUnit = '';
            }
        }
    });
    if (isErrorsEmpty(errors.packages)) delete errors.packages;
};
export const validateContainers = (containers: Array<shipmentContainer>, errors: any, localizations: any) => {
    const requiredFieldMessage = localizations.form.requiredFieldMessage;
    if (!errors.containers) errors.containers = [];
    containers.forEach(() => {
        errors.containers.push({});
    });
    containers.forEach((item, index) => {
        const { description: fcl_description } = item;
        const { type, number } = item;

        if (number || fcl_description) {
            if (!type) errors.containers[index].type = requiredFieldMessage;
        }
    });
    if (isErrorsEmpty(errors.containers)) delete errors.containers;
};

// calculations

export const shipmentCalcWeightVolume = (shipment: shipment) =>
    calcWeightVolume(shipment.typeCode, shipment.packages, shipment.items, shipment.isTotals, {
        weight: Number(shipment.weight) || 0,
        weightUnit: shipment.weightUnit,
        volume: Number(shipment.volume) || 0,
        volumeUnit: shipment.volumeUnit
    });

export function calcWeightVolume(
    shipment_type: shipmentType,
    packages?: Array<shipmentPackage>,
    items?: Array<shipmentItem>,
    isTotals?: boolean,
    totalsData?: {
        weight?: number;
        weightUnit?: string;
        volume?: number;
        volumeUnit?: string;
    }
) {
    let itemsVolume, itemsWeight;
    if (items?.length) {
        const { volume: itemsTotalVolume, weight: itemsTotalWeight } = calcShipmentItemsTotalVolumeWeight(items);
        itemsVolume = itemsTotalVolume;
        itemsWeight = itemsTotalWeight;
    }

    let packagesVolume, packagesWeight;
    if (packages?.length) {
        packagesVolume = calcTotalVolume(packages);
        packagesWeight = calcTotalWeight(packages);
    }

    const weight = (isTotals ? totalsData?.weight : packagesWeight?.weight) || itemsWeight?.weight || 0;
    const weightUnit = (isTotals ? totalsData?.weightUnit : packagesWeight?.unit) || itemsWeight?.unit || '';
    const volume = (isTotals ? totalsData?.volume : packagesVolume?.volume) || itemsVolume?.volume || 0;
    const volumeUnit = (isTotals ? totalsData?.volumeUnit : packagesVolume?.unit) || itemsVolume?.unit || '';

    let division = 1; // default value for sea
    if (['AE', 'AI', 'DROP_AIR'].includes(shipment_type)) division = 6;
    if (['EXPRESS'].includes(shipment_type)) division = 5;

    const volumeInCBM = volumeUnit === 'CFT' ? volume * 0.0283168 : volume;
    const weightInKG = weightUnit === 'LB' ? weight * 0.453592 : weight;
    const convertionRate = weightUnit === 'LB' ? 2.20462 : 1;
    const volumeChargeableValue = (volumeInCBM * 1000) / division;
    const weightChargeableValue = weightInKG;
    let chargeableWeight = Math.max(volumeChargeableValue, weightChargeableValue) * convertionRate;

    if (['OI', 'OEX', 'OE', 'DROP_OCEAN'].includes(shipment_type) && volumeChargeableValue > weightChargeableValue)
        chargeableWeight = (volumeChargeableValue / 1000) * convertionRate;

    return { weight, weightUnit, volume, volumeUnit, chargeableWeight };
}

export const defaultPackages = [lclPackageEmptyObj];
export const defaultContainers = [fclPackageEmptyObj];
