import React from 'react';
import { Checkbox as AntCheckbox } from 'antd';
import { CSSProperties } from 'styled-components';

export type BaseProps = {
    checked?: boolean;
    value?: any;
    name?: string;
    style?: CSSProperties;
    disabled?: boolean;
    children: JSX.Element[] | JSX.Element | string | undefined;
    onChange?: (event: any) => void;
};

const Checkbox = ({ value, checked, name, style, onChange, children, disabled }: BaseProps) => {
    return (
        <AntCheckbox onChange={onChange} style={style} checked={checked} value={value} name={name} disabled={disabled}>
            {children}
        </AntCheckbox>
    );
};
Checkbox.defaultProps = {
    children: undefined
};
export default Checkbox;
