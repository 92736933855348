import React from 'react';
import styled from 'styled-components';
import { featureFlagsModel } from '../../../../../models/entities/businessPartner/settings';
import { company } from '../../../../../models/entities/company/company';
import { createCompanyPayload } from '../../../../../models/entities/company/createCompanyPayload';
import AddCompanyForm from './AddCompanyForm';
import EditCompanyForm from './EditCompanyForm';

type Props = {
    company?: company;
    createCompany: (payload: createCompanyPayload) => Promise<void>;
    localization: any;
    featureFlags?: featureFlagsModel;
};
const AddEditCompany = ({ company, createCompany, localization, featureFlags }: Props) => {
    return (
        <Wrapper>
            {company ? (
                <EditCompanyForm company={company} />
            ) : (
                <AddCompanyForm
                    createCompany={createCompany}
                    localization={localization.add_edit_company.add_company_form}
                    featureFlags={featureFlags}
                />
            )}
        </Wrapper>
    );
};

const Wrapper = styled.div`
    width: 100%;
    height: 100%;
    padding: 48px 11px;
`;

export default AddEditCompany;
