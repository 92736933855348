import React from 'react';
import { ColumnType } from '../types/Column';
import { styled } from '../../Theme/theme';
import { DragDropContext, Draggable, DraggableProvided, Droppable } from 'react-beautiful-dnd';
import PortalAwareDraggableColumnItem from './PortalAwareDraggableColumnItem';
type Props = {
    currGridColumns: ColumnType<any>[];
    setCurrGridColumns: React.Dispatch<React.SetStateAction<ColumnType<any>[]>>;
};

const reorder = (arr: Array<ColumnType<any>>, startIndex: number, endIndex: number) => {
    const result = Array.from(arr);
    const [removed] = result.splice(startIndex, 1);
    result.splice(endIndex, 0, removed);

    return result;
};

const CustomizeColumnsContent = ({ currGridColumns, setCurrGridColumns }: Props) => {
    return (
        <Container>
            <div>
                <DragDropContext
                    onDragEnd={(result) => {
                        if (!result.destination) {
                            return;
                        }
                        const items = reorder(currGridColumns, result.source.index, result.destination.index);
                        setCurrGridColumns(items);
                    }}
                >
                    <Droppable droppableId="droppable-1">
                        {(provided, _snapshot) => (
                            <Columns ref={provided.innerRef} {...provided.droppableProps}>
                                {currGridColumns.map((item, index) => {
                                    return (
                                        <Draggable key={index} draggableId={'' + index} index={index}>
                                            {(provided: DraggableProvided, snapshot) => {
                                                return (
                                                    <PortalAwareDraggableColumnItem
                                                        provided={provided}
                                                        snapshot={snapshot}
                                                        index={index}
                                                        item={item}
                                                        currGridColumns={currGridColumns}
                                                        setCurrGridColumns={setCurrGridColumns}
                                                    />
                                                );
                                            }}
                                        </Draggable>
                                    );
                                })}
                                {provided.placeholder}
                            </Columns>
                        )}
                    </Droppable>
                </DragDropContext>
            </div>
        </Container>
    );
};

export default CustomizeColumnsContent;

const Container = styled.div`
    border-bottom: 1px solid #eaeaea;
`;

const Columns = styled.div`
    max-height: 400px;
    overflow-y: auto;
    padding: 10px 30px;
`;
