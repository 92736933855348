import BaseController from '..';
import { purchaseOrder, poOverview, paymentDate } from '../../../models/entities/purchaseOrder/purchaseOrder';
import {
    sendSupplierUpdatesEmailPayload,
    supplierUpdatesUpdatePoPayload,
    supplierUpdatesUpdatePoItemsReadyDatePayload
} from '../../../models/entities/purchaseOrder/payloads';
import { exportTypes, filterFieldsType } from '../../../state/ducks/allPurchaseOrdersPage/allPurchaseOrdersPage';
import PurchaseOrderEvents from './events/purchaseOrderEvents';
import PurchaseOrderItems from './items/purchaseOrderItems';
import IPurchaseOrders from './IPurchaseOrders';
import PurchaseOrderParties from './parties/purchaseOrderParties';
import PurchaseOrderDiscussions from './discussions/purchaseOrderDiscussions';
import PurchaseOrderDocuments from './documents/purchaseOrderDocuments';
import PurchaseOrderReferences from './references/purchaseOrderReferences';
import PurchaseOrderHistory from './history/purchaseOrderHistory';
import { downloadFile } from '../../../utils/files';
import PurchaseOrderFollow from './follow/purchaseOrderFollow';
import PurchaseOrderSupplierUpdatesDocuments from './supplierUpdatesDocuments/purchaseOrderSupplierUpdatesDocuments';

export default class PurchaseOrders extends BaseController implements IPurchaseOrders {
    private purchaseOrderParties: PurchaseOrderParties;
    private purchaseOrderEvents: PurchaseOrderEvents;
    private purchaseOrderItems: PurchaseOrderItems;
    private purchaseOrderDiscussions: PurchaseOrderDiscussions;
    private purchaseOrderDocuments: PurchaseOrderDocuments;
    private purchaseOrderSupplierUpdatesDocuments: PurchaseOrderSupplierUpdatesDocuments;
    private purchaseOrderReferences: PurchaseOrderReferences;
    private purchaseOrderHistory: PurchaseOrderHistory;
    private purchaseOrderFollow: PurchaseOrderFollow;
    constructor(baseUrl: string) {
        super(baseUrl);
        this.purchaseOrderParties = new PurchaseOrderParties(baseUrl);
        this.purchaseOrderEvents = new PurchaseOrderEvents(baseUrl);
        this.purchaseOrderItems = new PurchaseOrderItems(baseUrl);
        this.purchaseOrderDiscussions = new PurchaseOrderDiscussions(baseUrl);
        this.purchaseOrderDocuments = new PurchaseOrderDocuments(baseUrl);
        this.purchaseOrderReferences = new PurchaseOrderReferences(baseUrl);
        this.purchaseOrderHistory = new PurchaseOrderHistory(baseUrl);
        this.purchaseOrderFollow = new PurchaseOrderFollow(baseUrl);
        this.purchaseOrderSupplierUpdatesDocuments = new PurchaseOrderSupplierUpdatesDocuments(baseUrl);
    }
    async fetchPurchaseOrders() {
        const response = await this.client.get('/purchaseOrders/allActive');
        return response;
    }
    async fetchById(purchaseOrderNumber: string) {
        const response = await this.client.get(`/purchaseOrders/id/${purchaseOrderNumber}`);
        return response;
    }
    async fetchByIds(purchaseOrdersIds: string[]) {
        const response = await this.client.post(`/purchaseOrders/getByIds`, JSON.stringify({ purchaseOrdersIds }));
        return response;
    }
    async fetchPurchaseOrdersByFilter(filter: filterFieldsType) {
        const response = await this.client.post('/purchaseOrders/filtered', JSON.stringify({ filters: filter }));
        return response;
    }
    async fetchByCZNumber(czNumber: string) {
        const response = await this.client.get(`/purchaseOrders/search/${czNumber}`);
        return response;
    }
    async createPurchaseOrder(purchaseOrder: purchaseOrder) {
        const response = await this.client.post('/purchaseOrders', JSON.stringify({ data: purchaseOrder }));
        return response;
    }

    async inviteUsersToFollow(users: Array<string>, purchaseOrderId: string, purchaseOrderNumber: string, message: string) {
        const response = await this.client.post(
            '/purchaseOrders/inviteToFollow',
            JSON.stringify({ users, purchaseOrderId, purchaseOrderNumber, message })
        );
        return response;
    }

    async updatePurchaseOrder(id: string, purchaseOrder: purchaseOrder) {
        const response = await this.client.put('/purchaseOrders', JSON.stringify({ purchaseOrderId: id, data: purchaseOrder }));
        return response;
    }
    async updatePurchaseOrderOverview(id: string, poOverview: poOverview) {
        const response = await this.client.put('/purchaseOrders/overview', JSON.stringify({ purchaseOrderId: id, data: poOverview }));
        return response;
    }
    async restorePurchaseOrder(purchaseOrderNumber: string) {
        const response = await this.client.put(`/purchaseOrders/restore`, JSON.stringify({ entityId: purchaseOrderNumber }));
        return response;
    }
    async deletePurchaseOrder(purchaseOrderNumber: string) {
        await this.client.delete(`/purchaseOrders/id/${purchaseOrderNumber}`);
    }
    async exportPurchaseOrder(purchaseOrderId: string, type: exportTypes, fileName: string) {
        const response = await this.client.get(`/purchaseOrders/${purchaseOrderId}/export/${type.toLowerCase()}`, true);
        const blob = await response.blob();
        downloadFile(blob, fileName);
    }
    parties() {
        return this.purchaseOrderParties;
    }
    events() {
        return this.purchaseOrderEvents;
    }
    items() {
        return this.purchaseOrderItems;
    }
    discussions() {
        return this.purchaseOrderDiscussions;
    }
    documents() {
        return this.purchaseOrderDocuments;
    }
    supplierUpdatesDocuments() {
        return this.purchaseOrderSupplierUpdatesDocuments;
    }
    references() {
        return this.purchaseOrderReferences;
    }
    history() {
        return this.purchaseOrderHistory;
    }
    follow() {
        return this.purchaseOrderFollow;
    }

    async fetchPurchaseOrderItems(purchaseOrderNumber: string) {
        const response = await this.client.get(`/purchaseOrder/packages/${purchaseOrderNumber}`);
        return response;
    }

    async fetchPurchaseOrderReferences(purchaseOrderNumber: string) {
        const response = await this.client.get(`/purchaseOrder/references/${purchaseOrderNumber}`);
        return response;
    }

    async updatePurchaseOrderState(purchaseOrderId: string, state: string) {
        const response = await this.client.put(`/purchaseOrders/state`, JSON.stringify({ purchaseOrderId, data: { state } }));
        return response;
    }

    async updatePaymentDatesByPaymentTermPrecentage(purchaseOrderId: string): Promise<Array<paymentDate>> {
        const response = await this.client.post(`/purchaseOrders/updatePaymentDatesByPaymentTermPrecentage`, JSON.stringify({ purchaseOrderId }));
        return response;
    }

    async sendSupplierUpdatesEmail(sendSupplierUpdatesEmailPayload: sendSupplierUpdatesEmailPayload): Promise<void> {
        await this.client.post(`/purchaseOrders/sendSupplierUpdatesEmail`, JSON.stringify(sendSupplierUpdatesEmailPayload));
    }

    //#region po supplier updates
    async getPoSupplierUpdates(token: string): Promise<purchaseOrder> {
        this.client.headers.Authorization = 'Bearer ' + token;
        const response = await this.client.get('/public/poSupplierUpdates');
        return response;
    }

    async supplierUpdatesConfirmPo(): Promise<void> {
        await this.client.post('/public/poSupplierUpdates/confirmPo');
    }
    async supplierUpdatesUpdatePo(payload: supplierUpdatesUpdatePoPayload): Promise<purchaseOrder> {
        const po = await this.client.put('/public/poSupplierUpdates', JSON.stringify(payload));
        return po;
    }
    async supplierUpdatesUpdatePoItemsReadyDate(payload: supplierUpdatesUpdatePoItemsReadyDatePayload): Promise<purchaseOrder> {
        const po = await this.client.put('/public/poSupplierUpdates/itemsReadyDate', JSON.stringify(payload));
        return po;
    }
    async getFileUrl(fileId: string) {
        const response = await this.client.get(`/public/poSupplierUpdates/files/preview/${fileId}`);
        if (!response || !response.data) return;
        const arrayBufferView = new Uint8Array(response.data.data);
        const blobUrl = URL.createObjectURL(new Blob([arrayBufferView], { type: response.type }));
        return { blobUrl, fileUrl: response.fileUrl ? response.fileUrl : blobUrl };
    }
    //#endregion
}
