import React from 'react';
import Menu from '../../Menu/Menu';
import MenuItem from '../../Menu/MenuItem';
import edit from '../../../../static/icons/edit.svg';
import trash from '../../../../static/icons/trash.svg';
import CellTrigger from '../../Menu/MenuTriggers/CellTrigger';
import { RootState } from '../../../../state/store/store';
import { connect } from 'react-redux';
import { singlePurchaseOrderSelectors } from '../../../../state/ducks/singlePurchaseOrder/selectors';
import { purchaseOrder } from '../../../../models/entities/purchaseOrder/purchaseOrder';
type onRowMoreOptionClickType = () => void;
type deletePurchaseOrderEventType = (purchaseOrderNumber: string, eventId: number) => void;
type Props = {
    entityId: number;
    localization: any;
    onRowMoreOptionsClick?: onRowMoreOptionClickType;
    deletePurchaseOrderEvent: deletePurchaseOrderEventType;
    purchaseOrder?: purchaseOrder;
};

const PurchaseOrderEventMenu = ({ entityId, purchaseOrder, localization, onRowMoreOptionsClick, deletePurchaseOrderEvent }: Props) => {
    const menuItems = [
        <MenuItem onClick={() => onRowMoreOptionsClick && onRowMoreOptionsClick()} icon={edit} text={localization.edit} />,
        <MenuItem onClick={() => purchaseOrder && deletePurchaseOrderEvent(purchaseOrder.id, entityId)} icon={trash} text={localization.delete} />
    ];

    return <Menu options={menuItems} MenuTrigger={CellTrigger} strStyle="display: flex;align-items: center;width: 100%;height: 100%;" />;
};

const mapStateToProps = (state: RootState) => ({
    purchaseOrder: singlePurchaseOrderSelectors.purchaseOrder(state)
});

const mapDispatchToProps = (dispatch: any) => ({
    deletePurchaseOrderEvent: (purchaseOrderNumber: string, eventId: number) =>
        dispatch.purchaseOrders.deletePurchaseOrderEvent({ purchaseOrderNumber, eventId })
});

export default connect(mapStateToProps, mapDispatchToProps)(PurchaseOrderEventMenu);
