import BaseController from '../..';
import { billingLine } from '../../../../models/entities/common_subentities/billingLine';
import IShipmentBillingLines from './IShipmentBillingLines';

export default class ShipmentBillingLines extends BaseController implements IShipmentBillingLines {
    constructor(baseUrl: string) {
        super(baseUrl);
    }
    async fetchShipmentBillingLines(shipmentNumber: string) {
        const response = await this.client.get(`/shipment/accounting/${shipmentNumber}`);
        return response;
    }
    async createShipmentBillingLine(shipmentId: string, data: billingLine) {
        const response = await this.client.post(`/shipment/accounting`, JSON.stringify({ shipmentId, data }));
        return response;
    }
    async updateShipmentBillingLine(shipmentId: string, data: billingLine) {
        const response = await this.client.put(`/shipment/accounting`, JSON.stringify({ shipmentId, data }));
        return response;
    }
    async deleteShipmentBillingLine(shipmentId: string, billingLineId: number) {
        const response = await this.client.delete(`/shipment/accounting`, JSON.stringify({ shipmentId, id: billingLineId }));
        return response;
    }
}
