import React, { useRef, useEffect } from 'react';
import { EntryComponentProps } from '@draft-js-plugins/mention/lib/MentionSuggestions/Entry/Entry';
import styled from 'styled-components';
import HexagonAvatar from '../../SmallComponents/HexagonAvatar';
import { getUserStatusTheme } from '../../Theme/util';
import { userInfo } from '../../../../models/entities/user';
import { UserImageAvatar } from '../../Layout/Header/AvatarHeader';
import { userDetails } from '../../../../state/ducks/users/users';

const UserMentionEntryComponent = (props: EntryComponentProps) => {
    const { mention, isFocused, id, theme, ...parentProps } = props;

    return (
        <UserMentionContainer className={theme?.mentionSuggestionsEntryContainer} {...parentProps}>
            <span className={theme?.mentionSuggestionsEntryContainerLeft}>
                {mention.id && (
                    <HexagonAvatar
                        img={mention?.picture?.url || UserImageAvatar(mention as userDetails, 20)}
                        width={20}
                        borderColor={getUserStatusTheme(mention?.status || 'OOTO').color ?? '#00b5b9'}
                    />
                )}
            </span>

            <div className={theme?.mentionSuggestionsEntryContainerRight}>
                <div className={theme?.mentionSuggestionsEntryText}>{mention.name}</div>

                <div className={theme?.mentionSuggestionsEntryTitle}>{mention.title}</div>
            </div>
        </UserMentionContainer>
    );
};

export default UserMentionEntryComponent;

const UserMentionContainer = styled.div`
    display: flex;
`;
