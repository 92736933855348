import React, { useState } from 'react';
import { connect } from 'react-redux';
import CustomModal, { ModalTriggerProps } from '../../../shared/Modal/Modal';
import { sendSupplierUpdatesEmailPayload } from '../../../../models/entities/purchaseOrder/payloads';
import { RootState } from '../../../../state/store/store';
import { Formik } from 'formik';
import { Form } from 'formik-antd';
import styled from 'styled-components';
import { FormAutoComplete, FormTextArea } from '../../../shared/inputs/form';
import { emailPattern } from '../../../../utils/patterns';
import { localizationSelectors } from '../../../../state/ducks/localization/selectors';
import { Modal } from 'antd';

type Props = {
    localization: any;
    purchaseOrderId: string;
    Trigger: (props: ModalTriggerProps) => JSX.Element;
    sendSupplierUpdatesEmail: (sendSupplierUpdatesEmailPayload: sendSupplierUpdatesEmailPayload) => Promise<void>;
    isSendSupplierUpdatesEmail: boolean;
};

type FormValues = {
    email: string;
    note?: string;
};
const SendSupplierUpdatesEmailModal = ({ localization, purchaseOrderId, Trigger, isSendSupplierUpdatesEmail, sendSupplierUpdatesEmail }: Props) => {
    const initialValues: FormValues = {
        email: '',
        note: ''
    };

    const onSubmit = async (values: FormValues) => {
        await sendSupplierUpdatesEmail({
            ...values,
            purchaseOrderId
        });

        Modal.success({
            zIndex: 1100,
            content: <div>{localization.sent_message}</div>,
            cancelButtonProps: {
                hidden: true
            }
        });
    };

    const validate = (values: FormValues) => {
        const errors: any = {};
        if (!values.email) errors.email = localization.form.required;
        if (values.email && !values.email.match(emailPattern)) errors.email = localization.form.invalid_email;
        return errors;
    };

    return (
        <Formik initialValues={initialValues} validate={validate} onSubmit={onSubmit}>
            {(formik) => {
                const { isValid, dirty, submitForm } = formik;
                return (
                    <Form>
                        <CustomModal
                            title={{ text: localization.title }}
                            contentPadding="10px 30px 10px 30px"
                            Trigger={Trigger}
                            Content={(
                                setVisible?: React.Dispatch<React.SetStateAction<boolean>>,
                                setConfirm?: React.Dispatch<React.SetStateAction<boolean>>
                            ) => {
                                return (
                                    <ContentContainer>
                                        <FieldContainer>
                                            <FormAutoComplete name="email" placeHolder={localization.form.email} options={[]} />
                                        </FieldContainer>
                                        <FieldContainer>
                                            <FormTextArea name="note" placeHolder={localization.form.note} height="106px" />
                                        </FieldContainer>
                                    </ContentContainer>
                                );
                            }}
                            confirmBtn={{
                                handler: async () => await submitForm(),
                                text: localization.form.send_btn,
                                closeModal: true,
                                disabled: !isValid || !dirty || isSendSupplierUpdatesEmail,
                                isLoading: isSendSupplierUpdatesEmail
                            }}
                            cancelBtn={{
                                text: 'Cancel'
                            }}
                            width="400px"
                        />
                    </Form>
                );
            }}
        </Formik>
    );
};

const mapStateToProps = (state: RootState) => ({
    localization: localizationSelectors.singlePurchaseOrderPage(state).send_supplier_updates_email_modal,
    isSendSupplierUpdatesEmail: !!state.loading.effects.purchaseOrders.sendSupplierUpdatesEmail
});

const mapDispatchToProps = (dispatch: any) => ({
    sendSupplierUpdatesEmail: (sendSupplierUpdatesEmailPayload: sendSupplierUpdatesEmailPayload) =>
        dispatch.purchaseOrders.sendSupplierUpdatesEmail(sendSupplierUpdatesEmailPayload)
});

export default connect(mapStateToProps, mapDispatchToProps)(SendSupplierUpdatesEmailModal);

const ContentContainer = styled.div``;

const FieldContainer = styled.div`
    .err-msg {
        position: relative;
    }
`;
