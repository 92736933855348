import { createSelector } from 'reselect';
import { RootState } from '../../store/store';
import { userDetails, usersObject } from './users';

const users = (state: RootState) => state.users.users;
const connectedUsers = (state: RootState) => state.users.connectedUsers;

const userPicture = (state: RootState, id: string): string => {
    return state.users.users[id] ? state.users.users[id].profileImgUrl : '';
};

const getUserById = createSelector(users, (users: usersObject) => (id: string) => {
    return users[id];
});

const getUsersByCompanyId = createSelector(users, (users: usersObject) => (companyId: string) => {
    return Object.values(users).filter((u) => u.companyId === companyId);
});

const getUsersByCompaniesIds = createSelector(users, (users: usersObject) => (companiesIds: string[]) => {
    return Object.values(users).filter((u) => companiesIds.includes(u.companyId));
});

export { default as usersSelectors } from './selectors';
export default {
    getUserById,
    userPicture,
    users,
    connectedUsers,
    getUsersByCompanyId,
    getUsersByCompaniesIds
};
