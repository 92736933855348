import React, { useState } from 'react';
import styled, { css } from 'styled-components';
import moment from 'moment';
import { order, orderStates, OrderStateType, updateOrderPayload, updateOrderStatePayload } from '../../../models/entities/order';
import { PagesRoutes } from '../../../routing/PagesRoutes';
import DropDown, { Option } from '../../shared/inputs/base/DropDown';
import BackButton from '../../shared/SmallComponents/BackButton';
import renderStateOption from '../../shared/SmallComponents/StateOption';
import OrderOverview from './components/OrderOverview';
import OrderFollowers from './components/OrderFollowers/OrderFollowers';
import OrderMenu from './components/OrderMenu/OrderMenu';
import MenuIcon from '../../../static/icons/menu.svg';
import { TriggerProps } from '../../shared/Menu/Menu';
import OrderAlertPopover from '../Orders/OrderAlertPopover';
import { orderEvent } from '../../../models/entities/order/orderEvent/orderEvent';
import OrderDiscussionsSection from './components/OrderDiscussions/OrderDiscussionsSection';
import ChevronRightIcon from '@material-ui/icons/ChevronRight';
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft';
import { orderAlert, orderAlertImg, orderAlertLevelEnum } from '../../../models/entities/order/orderAlert/orderAlert';
import CZTooltip from '../../shared/CZTooltip/CZTooltip';
import { TimeLineItem } from '../singleShipment/sections/Events/express/TimeLine';
import TimeLine from '../../shared/TimeLine/TimeLine';
import Button from '../../shared/SmallComponents/Button';
import { Link } from 'react-router-dom';
import EditIcon from '../../../static/icons/edit.svg';

type Props = {
    order: order;
    allowedCompaniesDropDownOptions: Option[];
    businessPartnersDropDownOptions: Option[];
    allowedShipmentTypes: Array<string>;
    localization: any;
    isUpdateOrder: boolean;
    orderLastEvent: orderEvent | null;
    eventsTabData: TimeLineItem[];
    isDrawerOpen: boolean;
    refreshOrder: () => Promise<void>;
    updateOrder: (payload: updateOrderPayload) => Promise<void>;
    updateOrderState: (payload: updateOrderStatePayload) => Promise<void>;
    getAlertStatusByOrderId: (orderId: string) => number;
    dismissOrderAlert: (orderId: string, alert: orderAlert) => Promise<void>;
};
const SingleOrderPage = ({
    order,
    allowedCompaniesDropDownOptions,
    businessPartnersDropDownOptions,
    allowedShipmentTypes,
    localization,
    isUpdateOrder,
    orderLastEvent,
    eventsTabData,
    isDrawerOpen,
    refreshOrder,
    updateOrder,
    updateOrderState,
    getAlertStatusByOrderId,
    dismissOrderAlert
}: Props) => {
    const [timeLineOpen, setTimeLineOpen] = useState<boolean>((order.events || []).length > 2);

    const toggleTimeLine = () => {
        setTimeLineOpen(!timeLineOpen);
    };

    const renderOrderAlerts = () => {
        return order.alerts
            ?.sort((a, b) => a.level - b.level)
            .map((orderAlert: orderAlert, i: number) => {
                if (orderAlert.isDismissed) return <div></div>;
                return (
                    <AlertItem key={i}>
                        <AlertImage src={orderAlertImg[orderAlert.level]} alt={localization.alerts.status[orderAlertLevelEnum[orderAlert.level]]} />
                        <AlertMessage>{orderAlert.message}</AlertMessage>
                        <CZTooltip text={'Dismiss'}>
                            <span className="material-icons" onClick={() => dismissOrderAlert(order.id, orderAlert)}>
                                close
                            </span>
                        </CZTooltip>
                    </AlertItem>
                );
            });
    };

    return (
        <PageWrapper>
            <PageContentContainer isTimeLineOpen={timeLineOpen} isDrawerOpen={isDrawerOpen}>
                <SectionWrapper>
                    <div style={{ paddingLeft: 30 }}>
                        <BackButton style={{ marginLeft: '-30px' }} toPath={PagesRoutes.Orders} text={'Back'} />
                    </div>
                    <ActionsContainer>
                        <LastUpdateText>
                            {'Last update'} {moment(order.updatedAt).format('DD/MM/YYYY HH:mm')}
                        </LastUpdateText>

                        <StateDropDownContainer>
                            <DropDown
                                placeHolder={localization.fields.state.title}
                                style={{ width: '100%' }}
                                options={Object.entries(orderStates)
                                    .sort((a, b) => a[1].displayOrder - b[1].displayOrder)
                                    .map(([state, data]) => ({
                                        text: localization.state?.[state] || state,
                                        value: state,
                                        color: data.color
                                    }))}
                                optionRenderer={renderStateOption}
                                value={order.state}
                                onChange={async (value: OrderStateType) => {
                                    await updateOrderState({ orderId: order.id, state: value });
                                }}
                            />
                        </StateDropDownContainer>
                        <WarningContainer>
                            <OrderAlertPopover orderId={order.id} alertStatus={getAlertStatusByOrderId(order.id)} />
                        </WarningContainer>
                        <div style={{ flex: 1 }}></div>
                        <OrderFollowers followers={order.followers} />
                        <Link
                            to={{
                                pathname: `${PagesRoutes.EditOrder}/${order.id}`
                            }}
                        >
                            <Button margin="0 12px 0 0" height="30px">
                                <img src={EditIcon} alt={'Edit'} />
                                <span>{'Edit'}</span>
                            </Button>
                        </Link>
                        <Button
                            width="auto"
                            height="auto"
                            buttonType="hollow"
                            padding="0 8px"
                            margin="0 10px 0 0"
                            borderRadiusMobile="50%"
                            minHeightMobile="32px"
                            paddingMobile="0 4px"
                            widthMobile="auto"
                            onClick={() => refreshOrder()}
                        >
                            <span className="material-icons">refresh</span>
                        </Button>
                        <OrderMenu
                            order={order}
                            trigger={(props: TriggerProps) => (
                                <img style={{ cursor: 'pointer' }} onClick={(e) => props.onClick(e)} src={MenuIcon} alt="Menu" />
                            )}
                        />
                    </ActionsContainer>
                </SectionWrapper>
                <OrderOverview
                    order={order}
                    orderLastEvent={orderLastEvent}
                    allowedCompaniesDropDownOptions={allowedCompaniesDropDownOptions}
                    businessPartnersDropDownOptions={businessPartnersDropDownOptions}
                    allowedShipmentTypes={allowedShipmentTypes}
                    localization={localization}
                    isUpdateOrder={isUpdateOrder}
                    updateOrder={updateOrder}
                />
                <SectionWrapper>
                    <OrderDiscussionsSection localization={localization} />
                </SectionWrapper>
            </PageContentContainer>
            <TimeLinePaneContainer isTimeLineOpen={timeLineOpen}>
                <ToggleBtn onClick={toggleTimeLine}>{!timeLineOpen ? <ChevronLeftIcon /> : <ChevronRightIcon />}</ToggleBtn>

                <DrawerContent>
                    <AlertsListContainer>{renderOrderAlerts()}</AlertsListContainer>
                    <TimeLineContainer>
                        <TimeLine data={eventsTabData || []} />
                    </TimeLineContainer>
                </DrawerContent>
            </TimeLinePaneContainer>
        </PageWrapper>
    );
};

const PageWrapper = styled.div`
    display: flex;
    flex-direction: row;
    height: 100%;

    @media screen and (max-width: 768px) {
        height: calc(100vh - 65px - 40px);
    }
`;

const PageContentContainer = styled.div<{ isTimeLineOpen: boolean; isDrawerOpen: boolean }>`
    transition: 225ms ease-in-out;
    width: ${(props) => (props.isDrawerOpen || !props.isTimeLineOpen ? '100%' : 'calc(100% - 380px)')};
    max-width: ${(props) => (props.isDrawerOpen || !props.isTimeLineOpen ? '100%' : 'calc(100% - 380px)')};
    border-right: 1px solid ${(props) => props.theme.colors.grayDefaultBorder};
    overflow-y: overlay;

    @media screen and (max-width: 1300px) {
        width: 100%;
        max-width: 100%;
    }
`;

const TimeLinePaneContainer = styled.div<{ isTimeLineOpen: boolean }>`
    position: fixed;
    z-index: 0;
    top: 64px;
    right: 70px;
    transition: 225ms ease-in-out;
    ${(props) =>
        props.isTimeLineOpen
            ? css`
                  width: 380px;
                  max-width: 380px;
              `
            : css`
                  width: 0px;
                  max-width: 0px;
              `}
    height: calc(100vh - 64px);
    overflow: visible;
    @media screen and (max-width: 1300px) {
        display: none;
    }
`;

const ToggleBtn = styled.div`
    position: absolute;
    top: 9px;
    left: -24px;
    width: 24px;
    color: #1e98cf;
    border: 1px solid #1e98cf;
    background-color: #eff8fc;
    border-right: none;
    height: 32px;
    border-radius: 10px 0px 0px 10px;
    padding: 3px 0;
    transition: 100ms ease-in-out;
    cursor: pointer;
    &:hover {
        box-shadow: 0 3px 5px 0 rgba(0, 0, 0, 0.2);
        width: 34px;
        left: -34px;
    }
`;

const SectionWrapper = styled.div`
    display: flex;
    flex-direction: column;
    padding: 24px 18px 18px 18px;
    border-bottom: solid 1px ${(props) => props.theme.colors.grayDefaultBorder};
    justify-content: space-between;
`;

const ActionsContainer = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    padding-left: 30px;
`;

const LastUpdateText = styled.div`
    font-size: 12px;
    font-style: italic;
    color: #757575;
`;

const StateDropDownContainer = styled.div`
    align-self: flex-end;
    width: 25%;
    margin-left: 20px;
`;

const WarningContainer = styled.div`
    margin-left: 10px;
    height: 25px;
`;

const AlertsListContainer = styled.div`
    min-width: 380px;
    flex: 1;
    width: 100%;
    display: flex;
    flex-direction: column;
    border-bottom: solid 1px #e0e0e0;

    & :last-child {
        margin-bottom: 0;
    }
`;

const AlertItem = styled.div`
    display: flex;
    flex-direction: row;
    padding: 10px 36px 10px 34px;
    align-items: center;
    transition: 0.1s;
    &:hover {
        background-color: #f1f1f1;
    }
    .material-icons {
        margin-left: auto;
        font-size: 12px;
        text-align: right;
        color: #a2a2a2;
        cursor: pointer;
        transition: 0.1s;

        &:hover {
            color: black;
        }
    }
`;

const AlertMessage = styled.div`
    color: #394372;
`;

const AlertImage = styled.img`
    margin-right: 8px;
    height: 18px;
`;

const TimeLineContainer = styled.div`
    min-width: 380px;
    padding: 20px 36px 10px 7px;
`;

const DrawerContent = styled.div`
    min-width: 380px;
    overflow-y: overlay;
    height: 100%;
`;

export default SingleOrderPage;
