import React from 'react';
import CustomModal, { ModalTriggerProps } from '../../../shared/Modal/Modal';
import { styled } from '../../../shared/Theme/theme';
import ReferencesItem from './ReferenceItem';
import RefFormConnected, { formMode } from './RefFormConnected';
import { refCompanyType, reference } from '../../../../models/entities/common_subentities/reference';

type Props = {
    entityId: string;
    references?: Array<reference>;
    type: refCompanyType;
    localization: any;
    printMode?: boolean;
    createReference: (entityId: string, reference: reference) => Promise<void>;
    updateReference: (entityId: string, reference: reference) => Promise<void>;
    deleteReference: (entityId: string, referenceId: string) => Promise<void>;
    showLoader?: boolean;
    isForwarder?: boolean;
};

const ReferencesItems = ({
    entityId,
    references,
    type,
    localization,
    printMode,
    createReference,
    updateReference,
    deleteReference,
    showLoader,
    isForwarder
}: Props) => {
    if (!references) return null;
    const filterRefs = references.filter((item) => item.companyType === type);
    return (
        <Container>
            <RefsContainer>
                {filterRefs.map((item, index) => {
                    return [
                        <ReferencesItem
                            key={item.id}
                            reference={item}
                            entityId={entityId}
                            createReference={createReference}
                            updateReference={updateReference}
                            deleteReference={deleteReference}
                            localization={localization}
                            isEditable={!item.referenceType && !printMode && !isForwarder}
                            showLoader={showLoader}
                        />,
                        index !== filterRefs.length - 1 && (
                            <span key={index} style={{ display: 'flex', alignItems: 'center' }}>
                                |
                            </span>
                        )
                    ];
                })}
            </RefsContainer>
            {!printMode && !isForwarder && (
                <CustomModal
                    title={{ text: `${localization.modal.add} ${localization.types[type]}` }}
                    Trigger={(props: ModalTriggerProps) => (
                        <AddButton onClick={props.onClick}>
                            <span className="material-icons">add_circle</span>
                        </AddButton>
                    )}
                    Content={(setVisible?: React.Dispatch<React.SetStateAction<boolean>>) => {
                        return (
                            <RefFormConnected
                                mode={formMode.NEW}
                                openedFromModal={setVisible ? { setVisible } : undefined}
                                entityId={entityId}
                                createReference={createReference}
                                updateReference={updateReference}
                                deleteReference={deleteReference}
                                localization={localization}
                                reference={{ companyType: type } as reference}
                            />
                        );
                    }}
                />
            )}
        </Container>
    );
};
const Container = styled.div`
    display: flex;
`;
const RefsContainer = styled.div`
    display: flex;
    max-width: 200px;
    overflow-y: overlay;
`;
const AddButton = styled.div`
    cursor: pointer;
    display: flex;
    align-items: center;
    span {
        font-size: 13px;
        margin-left: 5px;
    }
`;

export default ReferencesItems;
