import React, { useState } from 'react';
import { connect } from 'react-redux';
import Menu, { TriggerProps } from '../../../../shared/Menu/Menu';
import MenuItem from '../../../../shared/Menu/MenuItem';
import shareIcon from '../../../../../static/icons/share.svg';
import followIcon from '../../../../../static/icons/follow.svg';
import trashIcon from '../../../../../static/icons/trash.svg';
import exportIcon from '../../../../../static/icons/download.svg';
import CellTrigger from '../../../../shared/Menu/MenuTriggers/CellTrigger';
import { RootState } from '../../../../../state/store/store';
import { createOrderFollowerPayload, deleteOrderFollowerPayload } from '../../../../../models/entities/order/orderFollower/payloads';
import { singleOrderSelectors } from '../../../../../state/ducks/singleOrder/selectors';
import { order } from '../../../../../models/entities/order';
import { userSelectors } from '../../../../../state/ducks/user/selectors';
import { userInfo } from '../../../../../models/entities/user';

type Props = {
    order: order;
    isFollowingCurrentOrder: boolean;
    userInfo: userInfo;
    trigger?: (props: TriggerProps) => JSX.Element;
    createOrderFollower: (payload: createOrderFollowerPayload) => Promise<void>;
    deleteOrderFollower: (payload: deleteOrderFollowerPayload) => Promise<void>;
};
type MenuItemsProps = Props & {};

const menuItems = ({ order, isFollowingCurrentOrder, userInfo, createOrderFollower, deleteOrderFollower }: MenuItemsProps) => {
    const menuItemsArr = [
        <MenuItem
            icon={followIcon}
            text={!isFollowingCurrentOrder ? 'Follow' : 'Unfollow'}
            onClick={() => {
                !isFollowingCurrentOrder
                    ? createOrderFollower({ orderId: order.id })
                    : deleteOrderFollower({ orderId: order.id, userId: userInfo.id });
            }}
        />,
        <MenuItem icon={shareIcon} text={'Share'} />,
        <MenuItem icon={followIcon} text={'Invite to follow'} />,
        <MenuItem icon={exportIcon} text={'Export PDF'} />,
        <MenuItem icon={trashIcon} text={'Delete'} requiredConfirm />
    ];

    return menuItemsArr;
};

const OrderMenu = (props: Props) => {
    const [isModalOpened, setIsModalOpened] = useState(false);
    return (
        <Menu
            menuWidth={165}
            options={menuItems(props)}
            isModalOpened={isModalOpened}
            MenuTrigger={props.trigger || CellTrigger}
            containerStyle={{ display: 'flex', alignItems: 'center', height: '100%' }}
        />
    );
};

const mapStateToProps = (state: RootState) => ({
    userInfo: userSelectors.userInfo(state),
    isFollowingCurrentOrder: singleOrderSelectors.isFollowingCurrentOrder(state)
});

const mapDispatchToProps = (dispatch: any) => ({
    createOrderFollower: (payload: createOrderFollowerPayload) => dispatch.orders.createOrderFollower(payload),
    deleteOrderFollower: (payload: deleteOrderFollowerPayload) => dispatch.orders.deleteOrderFollower(payload)
});

export default connect(mapStateToProps, mapDispatchToProps)(OrderMenu);
