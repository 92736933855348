import { ModelConfig } from '@rematch/core';

export type singlePurchaseOrderStateType = {
    purchaseOrderId: string | null;
};

export const singlePurchaseOrder: ModelConfig<singlePurchaseOrderStateType> = {
    state: {
        purchaseOrderId: null
    },
    reducers: {
        setCurrentPurchaseOrderId(state: singlePurchaseOrderStateType, purchaseOrderId: string): singlePurchaseOrderStateType {
            return { ...state, purchaseOrderId };
        }
    }
};
