import { tariffQuotation } from './../../../models/entities/shipment/tariffQuotation/tariffQuotation';
import { createShipmentTariffQuotationsPayload } from './../../../models/entities/tariff/payloads/createShipmentTariffQuotationsPayload';
import { tariff, createTariffPayload } from '../../../models/entities/tariff';
import BaseController from '..';
import ITariff from './ITariff';
import TariffCharges from './charges/tariffCharges';

export default class Tariff extends BaseController implements ITariff {
    private tariffCharges: TariffCharges;

    constructor(baseUrl: string) {
        super(baseUrl);
        this.tariffCharges = new TariffCharges(baseUrl);
    }

    async fetchTariffs(): Promise<Array<tariff>> {
        const response = await this.client.get('/tariffs');
        return response;
    }
    async fetchTariffById(tariffId: string): Promise<tariff> {
        const response = await this.client.get(`/tariffs/${tariffId}`);
        return response;
    }
    async createTariff(tariff: createTariffPayload): Promise<tariff> {
        const response = await this.client.post('/tariffs', JSON.stringify(tariff));
        return response;
    }
    async updateTariff(tariffId: string, tariff: createTariffPayload): Promise<tariff> {
        const response = await this.client.put(`/tariffs/${tariffId}`, JSON.stringify(tariff));
        return response;
    }
    async createShipmentTariffQuotationsPayload(payload: createShipmentTariffQuotationsPayload): Promise<Array<tariffQuotation>> {
        const response = await this.client.post(`/tariffs/createShipmentTariffQuotations`, JSON.stringify(payload));
        return response;
    }

    charges() {
        return this.tariffCharges;
    }
}
