import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import InventoryPage from './InventoryPage';
import { inventoryTotalView } from '../../../models/entities/product/inventoryLog';
import { RootState } from '../../../state/store/store';
import { filterFieldsType, gridSortState } from '../../../state/ducks/inventory/inventory';
import { inventorySelectors } from '../../../state/ducks/inventory/selectors';
import { localizationSelectors } from '../../../state/ducks/localization/selectors';
import { ColumnType } from '../../shared/Grid/types/Column';
import ExceptionPage from '../ExceptionPage/ExceptionPage';
import { filterColsByUserSettings } from '../../shared/Grid/util';
import { userSelectors } from '../../../state/ducks/user/selectors';
import { userSettings } from '../../../models/entities/user';
import { drawers } from '../../../state/ducks/header/header';
import { featureFlagsModel } from '../../../models/entities/businessPartner/settings';

type Props = {
    inventoryLogs: Array<inventoryTotalView>;
    fetchInventory: () => void;
    fetchGridColumns: () => void;
    gridColumns: Array<ColumnType<inventoryTotalView>>;
    currentGridSort: gridSortState | null;
    setGridSort: (sortData: gridSortState) => void;
    localizations: any;
    showLoader?: boolean;
    inventoryGridSettings?: userSettings;
    fetchInventoryByFilter: (filter: filterFieldsType) => Array<inventoryTotalView>;
    filterFields: filterFieldsType | null;
    resetInventoryFilter: () => void;
    setDrawerOpen: (open: drawers, params?: any) => void;
    activeInventoryCount: number;
    featureFlags?: featureFlagsModel;
};

const InventoryLogsPageConnected = ({
    inventoryLogs,
    fetchInventory,
    fetchGridColumns,
    gridColumns,
    currentGridSort,
    setGridSort,
    localizations,
    showLoader,
    inventoryGridSettings,
    filterFields,
    resetInventoryFilter,
    fetchInventoryByFilter,
    setDrawerOpen,
    activeInventoryCount,
    featureFlags
}: Props) => {
    useEffect(() => {
        fetchInventory();
        fetchGridColumns();
    }, [fetchInventory, fetchGridColumns]);

    const DEFAULT_SORT_DIRECTION = 'asc';
    const SECOND_SORT_DIRECTION = 'desc';

    const onGridColumnClick = (newColumn: keyof inventoryTotalView) => {
        if (currentGridSort) {
            const { direction, column } = currentGridSort;
            if (column === newColumn)
                if (direction === DEFAULT_SORT_DIRECTION)
                    setGridSort({
                        column: newColumn,
                        direction: SECOND_SORT_DIRECTION
                    });
                else
                    setGridSort({
                        column: newColumn,
                        direction: DEFAULT_SORT_DIRECTION
                    });
            else {
                setGridSort({
                    column: newColumn,
                    direction: DEFAULT_SORT_DIRECTION
                });
            }
        } else {
            setGridSort({
                column: newColumn,
                direction: DEFAULT_SORT_DIRECTION
            });
        }
    };
    const userSettingKey = 'InventoryGrid';

    const amazonFields = ['productFnsku', 'productAsin'];
    const filteredColumns = filterColsByUserSettings(gridColumns, inventoryGridSettings).filter((c: ColumnType<inventoryTotalView>) => {
        return !featureFlags?.AMAZON ? !amazonFields.includes(c.field) : true;
    });

    return (
        <InventoryPage
            inventoryLogs={inventoryLogs}
            fetchInventory={fetchInventory}
            gridColumns={filteredColumns}
            onGridColumnClick={onGridColumnClick}
            gridSortDirection={currentGridSort?.direction}
            gridSortedBy={currentGridSort?.column}
            localization={localizations}
            showLoader={showLoader}
            userSettingKey={userSettingKey}
            resetFilterFunc={filterFields && filterFields.length > 0 && inventoryLogs.length === 0 ? resetInventoryFilter : undefined}
            setDrawerOpen={setDrawerOpen}
            activeInventoryCount={activeInventoryCount}
        />
    );
};

const mapStateToProps = (state: RootState) => ({
    inventoryLogs: inventorySelectors.gridData(state),
    gridColumns: inventorySelectors.gridColumns(state),
    currentGridSort: inventorySelectors.gridSort(state),
    localizations: localizationSelectors.inventory(state),
    showLoader: state.loading.effects.inventory.fetchGridColumns || state.loading.effects.inventory.fetchInventory,
    inventoryGridSettings: userSelectors.inventoryGridSettings(state),
    filterFields: inventorySelectors.filterFields(state),
    activeInventoryCount: inventorySelectors.activeInventoryCount(state),
    featureFlags: userSelectors.featureFlags(state)
});

const mapDispatchToProps = (dispatch: any) => ({
    fetchInventory: () => dispatch.inventory.fetchInventory(),
    fetchGridColumns: () => dispatch.inventory.fetchGridColumns(),
    setGridSort: (sortData: gridSortState) => dispatch.inventory.setGridSort(sortData),
    resetInventoryFilter: () => dispatch.inventory.setInventoryFilters([]),
    fetchInventoryByFilter: (filter: filterFieldsType) => dispatch.inventory.fetchInventoryByFilter(filter),
    setDrawerOpen: (open: drawers, params?: any) => dispatch.header.setDrawerOpenParams({ open, params })
});

export default connect(mapStateToProps, mapDispatchToProps)(InventoryLogsPageConnected);
