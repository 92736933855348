import React from 'react';
import { Input } from 'antd';
import styled from 'styled-components';
import { FieldInputProps } from 'formik';

export type BaseProps = {
    placeHolder?: string;
    value?: string;
    onChange?: (value: string) => void;
    name?: string;
    field?: FieldInputProps<any>;
    width?: string;
    height?: string;
    hasError?: boolean;
    style?: any;
    autoSize?:
        | boolean
        | {
              minRows?: number;
              maxRows?: number;
          };
    suffix?: React.ReactNode;
    disabled?: boolean;
};

const TextArea = ({ onChange, hasError, value, name, placeHolder, width, height, field, style, autoSize, suffix, disabled }: BaseProps) => {
    const { TextArea } = Input;
    return (
        <Container hasError={hasError} hasSuffix={!!suffix}>
            <TextArea
                value={value}
                name={name}
                {...field}
                onChange={(e) => {
                    field?.onChange(e);
                    onChange && onChange(e.currentTarget.value);
                }}
                style={style || { resize: 'none', width, height }}
                placeholder={placeHolder}
                autoSize={autoSize}
                disabled={disabled}
            />
            {!!suffix && <SuffixContainer>{suffix}</SuffixContainer>}
        </Container>
    );
};

TextArea.defaultProps = {
    width: '100%',
    height: 'auto'
};

interface ContainerProps {
    hasError?: boolean;
    hasSuffix?: boolean;
}
const SuffixContainer = styled.div`
    position: absolute;
    right: 0;
    bottom: 0;
    height: 30px;
    overflow: hidden;
    width: 100%;
    padding: 0 1px 1px 1px;
    border-radius: 0 0 19px 19px;
`;
export const Container = styled.div<ContainerProps>`
    position: relative;
    & .ant-input {
        border-radius: 15px;
        border-color: ${(props) => (props.hasError ? 'red' : props.theme.colors.primaryBlue)} !important;
        padding: ${(props) => (props.hasSuffix ? '12px 12px 40px 12px' : '12px')};
    }

    & .ant-input:focus {
        box-shadow: ${(props) => (props.hasError ? '0 0 0 2px rgb(255 24 24 / 20%)' : '0 0 0 2px rgba(24, 144, 255, 0.2)')};
    }

    textarea {
        resize: none;
    }
`;

export default TextArea;
