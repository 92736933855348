import React from 'react';
import { Link } from 'react-router-dom';
import styled from 'styled-components';
import HexagonAvatar from '../SmallComponents/HexagonAvatar';
import { getUserStatusTheme } from '../Theme/util';

type Props = {
    firstName: string;
    lastName: string;
    profileImg: string | JSX.Element;
    status: string;
    localizations?: any;
    setMenuOpen: () => void;
};

function ProfileHeader({ firstName, lastName, profileImg, status, setMenuOpen, localizations }: Props) {
    const statusTheme = getUserStatusTheme(status);
    return (
        <Container>
            <ProfileDetails>
                <HexagonAvatar width={50} img={profileImg} borderColor={statusTheme.color} />
                <div style={{ marginLeft: '5px', lineHeight: '16px' }}>
                    <NameSpan>
                        {firstName} {lastName}
                    </NameSpan>
                    <EditProfileLink onClick={setMenuOpen} to={`/edit_profile`}>
                        {localizations.edit_profile}
                    </EditProfileLink>
                </div>
            </ProfileDetails>
        </Container>
    );
}
const Container = styled.div`
    height: 100px;
    padding: 20px;
`;
const ProfileDetails = styled.div`
    display: flex;
    align-items: center;
`;
const NameSpan = styled.span`
    color: black;
    display: block;
`;
const EditProfileLink = styled(Link)`
color: grey;
    font-size: 12px;
}
`;
export default ProfileHeader;
