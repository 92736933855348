import React, { useState, useEffect } from 'react';
import SinglePurchaseDemandPage from './SinglePurchaseDemandPage';
import { RouteComponentProps, withRouter } from 'react-router-dom';
import { settings, purchaseDemand } from '../../../models/entities/purchaseDemand/purchaseDemand';
import { Dispatch, RootState } from '../../../state/store/store';
import Loader from '../../shared/SmallComponents/Loader';
import { singlePurchaseDemandSelectors } from '../../../state/ducks/singlePurchaseDemand/selectors';
import { localizationSelectors } from '../../../state/ducks/localization/selectors';
import { connect } from 'react-redux';
import { purchaseDemandsSelectors } from '../../../state/ducks/purchaseDemands/selectors';
import ExceptionPage from '../ExceptionPage/ExceptionPage';
import { settings as companySettings } from '../../../models/entities/businessPartner/businessPartner';
import { businessPartnersSelectors } from '../../../state/ducks/businessPartner/selectors';
import { purchaseDemandApprover } from '../../../models/entities/purchaseDemand/purchaseDemandApprover';
import { userSelectors } from '../../../state/ducks/user/selectors';
import { userDropDown, userInfo } from '../../../models/entities/user';
import { headerSelectors } from '../../../state/ducks/header/selectors';
import claimsType from '../../../models/entities/claimsType';
import { claimsDomainsType } from '../../../userClaims';

type PathParams = {
    purchaseDemandId: string;
};

type Props = RouteComponentProps<PathParams> & {
    purchaseDemand: purchaseDemand | undefined;
    setCurrentPurchaseDemandId: (currentPurchaseDemandId: string) => void;
    fetchSinglePurchaseDemand: (purchaseDemandId: string) => Promise<purchaseDemand>;
    fetchPurchaseDemandError: any;
    updatePurchaseDemandState: (purchaseDemandId: string, purchaseDemandState: string) => void;
    purchaseDemandState?: string;
    localizations?: any;
    settings?: settings;
    printMode?: boolean;
    companySettings?: companySettings;
    updatePurchaseDemandApproverState: (purchaseDemandId: string, approver: purchaseDemandApprover) => Promise<void>;
    usersInSameCompany: userDropDown[] | undefined;
    createPurchaseDemandApprover: (purchaseDemandId: string, approver: purchaseDemandApprover) => Promise<void>;
    deletePurchaseDemandApprover: (purchaseDemandId: string, approverId: string) => Promise<void>;
    userInfo: userInfo;
    getUserClaims: (domain: claimsDomainsType) => claimsType;
};

const SinglePurchaseDemandPageConnected = ({
    purchaseDemand,
    setCurrentPurchaseDemandId,
    fetchSinglePurchaseDemand,
    updatePurchaseDemandState,
    purchaseDemandState,
    fetchPurchaseDemandError,
    localizations,
    printMode,
    match,
    settings,
    companySettings,
    updatePurchaseDemandApproverState,
    createPurchaseDemandApprover,
    usersInSameCompany,
    userInfo,
    deletePurchaseDemandApprover,
    getUserClaims
}: Props) => {
    const urlPurchaseDemandId = match.params.purchaseDemandId;

    const parsedError = fetchPurchaseDemandError ? JSON.parse(fetchPurchaseDemandError) : null;

    useEffect(() => {
        setCurrentPurchaseDemandId(urlPurchaseDemandId);
        fetchSinglePurchaseDemand(urlPurchaseDemandId);
    }, [urlPurchaseDemandId, setCurrentPurchaseDemandId, fetchSinglePurchaseDemand]);

    return (
        <>
            {!fetchPurchaseDemandError ? (
                purchaseDemand ? (
                    <SinglePurchaseDemandPage
                        purchaseDemand={purchaseDemand}
                        purchaseDemandState={purchaseDemandState}
                        updatePurchaseDemandState={updatePurchaseDemandState}
                        localization={localizations}
                        settings={settings}
                        printMode={printMode}
                        companySettings={companySettings}
                        updatePurchaseDemandApproverState={updatePurchaseDemandApproverState}
                        createPurchaseDemandApprover={createPurchaseDemandApprover}
                        usersInSameCompany={usersInSameCompany}
                        userInfo={userInfo}
                        deletePurchaseDemandApprover={deletePurchaseDemandApprover}
                        getUserClaims={getUserClaims}
                    />
                ) : (
                    <Loader />
                )
            ) : (
                <ExceptionPage
                    status={parsedError.status}
                    subTitle={parsedError.message}
                    localization={localizations}
                    goBackPath="/purchaseDemands"
                />
            )}
        </>
    );
};

const mapStateToProps = (state: RootState) => ({
    purchaseDemand: singlePurchaseDemandSelectors.purchaseDemand(state),
    purchaseDemandState: singlePurchaseDemandSelectors.purchaseDemandState(state),
    history: singlePurchaseDemandSelectors.history(state),
    approvers: singlePurchaseDemandSelectors.approvers(state),
    items: singlePurchaseDemandSelectors.items(state),
    discussions: singlePurchaseDemandSelectors.discussions(state),
    documents: singlePurchaseDemandSelectors.documents(state),
    fetchPurchaseDemandError: singlePurchaseDemandSelectors.fetchPurchaseDemandError(state),
    localizations: localizationSelectors.singlePurchaseDemandPage(state),
    settings: purchaseDemandsSelectors.settingsByLanguage(state),
    companySettings: businessPartnersSelectors.settingsByLanguage(state),
    usersInSameCompany: userSelectors.usersInSameCompany(state),
    userInfo: userSelectors.userInfo(state),
    printMode: headerSelectors.printMode(state),
    getUserClaims: (domain: claimsDomainsType) => userSelectors.getUserClaims(state)(domain)
});

const mapDispatchToProps = (dispatch: Dispatch) => ({
    setCurrentPurchaseDemandId: (currentPurchaseDemandId: string) =>
        dispatch.singlePurchaseDemand.setCurrentPurchaseDemandId(currentPurchaseDemandId),
    fetchSinglePurchaseDemand: (purchaseDemandId: string) => dispatch.purchaseDemands.fetchPurchaseDemandById(purchaseDemandId),
    updatePurchaseDemandState: (purchaseDemandId: string, purchaseDemandState: string) =>
        dispatch.purchaseDemands.updatePurchaseDemandState({ purchaseDemandId, purchaseDemandState }),
    updatePurchaseDemandApproverState: (purchaseDemandId: string, approver: purchaseDemandApprover) =>
        dispatch.purchaseDemands.updatePurchaseDemandApproverState({ purchaseDemandNumber: purchaseDemandId, approver }),
    createPurchaseDemandApprover: (purchaseDemandId: string, approver: purchaseDemandApprover) =>
        dispatch.purchaseDemands.createPurchaseDemandApprover({ purchaseDemandNumber: purchaseDemandId, approver }),
    deletePurchaseDemandApprover: (purchaseDemandId: string, approverId: string) =>
        dispatch.purchaseDemands.deletePurchaseDemandApprover({ purchaseDemandNumber: purchaseDemandId, approverId })
});

export default withRouter(connect(mapStateToProps, mapDispatchToProps as any)(SinglePurchaseDemandPageConnected));
