import React from 'react';
import { Accordion, AccordionDetails, AccordionSummary } from '../../../../../shared/MuiAccordion/MuiAccordion';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import { Checkbox } from 'antd';
import { Field } from 'formik-antd';
import { FieldProps } from 'formik';
import { CheckboxOptionType } from 'antd/lib/checkbox';
import { isEqual } from 'lodash';
import { CheckboxValueType } from 'antd/lib/checkbox/Group';

type Props = {
    fieldName: string;
    title: string;
    options: CheckboxOptionType[];
    onChange?: (values: CheckboxValueType[]) => void;
};
const AccordionCheckBoxsesField = ({ fieldName, title, options, onChange }: Props) => {
    return (
        <Field name={fieldName}>
            {({ field: { value: fieldValue }, form: { setFieldValue, values } }: FieldProps) => {
                return (
                    <Accordion>
                        <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                            <Checkbox
                                style={{ padding: '0 8px 0 4px' }}
                                checked={isEqual(
                                    options.map((o) => o.value),
                                    fieldValue
                                )}
                                onClick={(e) => e.stopPropagation()}
                                onChange={(e) => {
                                    setFieldValue(fieldName, e.target.checked ? options.map((o) => o.value) : []);
                                }}
                            />
                            {title}
                        </AccordionSummary>
                        <AccordionDetails>
                            <Checkbox.Group
                                style={{
                                    display: 'flex',
                                    flexDirection: 'column',
                                    padding: 20
                                }}
                                value={fieldValue as Array<string>}
                                onChange={(values) => {
                                    setFieldValue(fieldName, values);
                                    onChange?.(values);
                                }}
                                options={options}
                            />
                        </AccordionDetails>
                    </Accordion>
                );
            }}
        </Field>
    );
};

export default AccordionCheckBoxsesField;
