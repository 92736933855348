import { product } from '../../../models/entities/product/product';
import { ModelConfig } from '@rematch/core';
import { localSDK as client } from '../../../sdk';
import { ColumnType } from '../../../components/shared/Grid/types/Column';
import SideEffectJS from 'side-effect-js';
import {
    internalFlowInventoryLogs as internalFlowInventoryLogsType,
    internalFlowInventoryLogsGridDataType
} from '../../../models/entities/product/inventoryLog';

export type paginationType = { currentPage: number; rowsPerPage: number };

export type gridModeType = 'quantity' | 'value' | 'volume';

export const eInternalFlowGridMode: { [key: string]: gridModeType } = {
    QUANTITY: 'quantity',
    VALUE: 'value',
    VOLUME: 'volume'
};

export type internalFlowInventoryLogsStateType = {
    internalFlowInventoryLogs?: internalFlowInventoryLogsType;
    gridColumns: Array<ColumnType<internalFlowInventoryLogsGridDataType>>;
    gridSort: gridSortState | null;
    gridFilter: string | null;
    filterFields: filterFieldsType | null;
    gridMode: gridModeType;
    pagination: paginationType;
};

export type filterFieldsType = Array<{ field: keyof internalFlowInventoryLogsGridDataType; value: any }>;
export type gridSortState = {
    direction: 'asc' | 'desc';
    column: keyof internalFlowInventoryLogsGridDataType;
};

export type exportTypes = 'PDF' | 'EXCEL';

const defaultGridSort: gridSortState = {
    direction: 'desc',
    column: 'name'
};

export const internalFlowInventoryLogs: ModelConfig<internalFlowInventoryLogsStateType> = {
    state: {
        gridColumns: [],
        gridSort: defaultGridSort,
        gridFilter: null,
        filterFields: null,
        gridMode: eInternalFlowGridMode.QUANTITY,
        pagination: {
            currentPage: 0,
            rowsPerPage: 30
        }
    },
    reducers: {
        setInternalFlowInventoryLogs(
            state: internalFlowInventoryLogsStateType,
            internalFlowInventoryLogs: internalFlowInventoryLogsType
        ): internalFlowInventoryLogsStateType {
            return { ...state, internalFlowInventoryLogs };
        },
        setGridSort(state: internalFlowInventoryLogsStateType, sortData: gridSortState): internalFlowInventoryLogsStateType {
            return { ...state, gridSort: sortData };
        },
        setGridFilter(state: internalFlowInventoryLogsStateType, filterText: string): internalFlowInventoryLogsStateType {
            return { ...state, gridFilter: filterText };
        },
        setGridColumns(
            state: internalFlowInventoryLogsStateType,
            columns: Array<ColumnType<internalFlowInventoryLogsGridDataType>>
        ): internalFlowInventoryLogsStateType {
            return { ...state, gridColumns: columns };
        },
        setGridMode(state: internalFlowInventoryLogsStateType, mode: gridModeType): internalFlowInventoryLogsStateType {
            return { ...state, gridMode: mode };
        },
        setCurrentPage(state: internalFlowInventoryLogsStateType, currentPage: number): internalFlowInventoryLogsStateType {
            return { ...state, pagination: { ...state.pagination, currentPage } };
        },
        setRowsPerPage(state: internalFlowInventoryLogsStateType, rowsPerPage: number): internalFlowInventoryLogsStateType {
            return { ...state, pagination: { ...state.pagination, rowsPerPage } };
        }
    },
    effects: (dispatch: any) => ({
        async fetchGridColumns() {
            const fetchGridColumns = SideEffectJS.Get('gridColumnsInternalFlowInventoryLogs');
            const gridColumns = await fetchGridColumns();
            dispatch.internalFlowInventoryLogs.setGridColumns(gridColumns);
        },
        async fetchInternalFlowInventoryLogs() {
            const logs = await client.inventory().getInternalFlowInventoryLogs();
            dispatch.internalFlowInventoryLogs.setInternalFlowInventoryLogs(logs);
            return true;
        }
    })
};
