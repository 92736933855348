import React, { useEffect } from 'react';
import { userSettingDefinition } from '../../../../models/entities/user';
import { styled } from '../../../shared/Theme/theme';
import UserSettingsItem from './UserSettingsItem';

type Props = {
    localization: any;
    userSettingsFromDefinitions: userSettingDefinition[];
    setUserSettings: (key: string, value: any, id?: number) => Promise<boolean>;
};

const UserSettingsPage = ({ userSettingsFromDefinitions, localization, setUserSettings }: Props) => {
    return (
        <Container>
            <Title>{localization.user_settings.title}</Title>
            <Items>
                <FirstItem>
                    <div>{localization.user_settings.key}</div>
                    <div>{localization.user_settings.value}</div>
                    <div></div>
                </FirstItem>
                {userSettingsFromDefinitions.map((item) => (
                    <UserSettingsItem item={item} setUserSettings={setUserSettings} localization={localization} />
                ))}
            </Items>
        </Container>
    );
};

const Container = styled.div`
    padding: 10px;
`;
const Title = styled.div`
    color: ${(props) => props.theme.colors.pageTitle};
    font-size: 25px;
    margin: 15px 0;
`;
const FirstItem = styled.div`
    font-weight: bold;
    display: flex;
    align-items: center;
    padding: 10px 2px;
    justify-content: space-between;
`;
const Items = styled.div``;
export default UserSettingsPage;
