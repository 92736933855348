import React, { useState } from 'react';
import { Checkbox } from 'antd';
import { styled } from '../Theme/theme';
import { ColumnType, specialColumnType } from './types/Column';
import CellRenderer from './CellRenderer/CellRenderer';
import CZTooltip, { QuestionToolTip } from '../CZTooltip/CZTooltip';
import Loader from '../SmallComponents/Loader';
import SettingsColumns from './specialColumns/SettingsColumn';
import Button from '../SmallComponents/Button';
import { useEffect } from 'react';
import { orderBy, remove, union, xor } from 'lodash';
import { css } from 'styled-components';
import { camelToSentence, groupElType, groupColumns, groupHeadersType, bindData } from './util';
import { useGroupedHeaders } from './hooks';
import ContextMenu from '../ContextMenu/ContextMenu';
import { ContextMenuItemType } from '../ContextMenu/types';

export type orderType = 'asc' | 'desc';

export type gridSortType<T> = {
    [key in keyof T]?: orderType;
};

export function orderGridDataFn<T>(data: T[], gridSort: gridSortType<T>) {
    return orderBy(data, (o) => o[Object.keys(gridSort)[0] as keyof T] || '', Object.values(gridSort) as orderType[]);
}

export type ContextMenuType = {
    entityId: string;
    mouseX: number;
    mouseY: number;
} | null;

export type GridProps<T> = {
    columns: Array<ColumnType<T>>;
    data: Array<T>;
    gridSort?: gridSortType<T>;
    sortedByColumn?: keyof T;
    sortDirection?: 'asc' | 'desc';
    localization?: any;
    settings?: any;
    emptyStateText?: string;
    showLoader?: boolean;

    allData?: Array<T>;
    showCheckboxSelection?: boolean;
    selectedRows?: string[];
    selectedColor?: string;

    contextMenuData?: (entity: T) => ContextMenuItemType[];

    userSettingKey?: string;
    lastVisitId?: string;
    menuType?: specialColumnType;
    menuBindedFields?: Array<keyof T>;
    offsetTop?: string;
    idKey?: keyof T;
    userIdKey?: keyof T;
    parentEntityId?: string;
    groupHeaders?: groupHeadersType<T>;
    printMode?: boolean;

    onSelectedRowsChange?: (selectedRows: string[]) => void;
    onSettingsClick?: () => void;
    onRowMoreOptionsClick?: (row: T) => void;
    onRowClick?: (row: T) => void;
    onColumnClick?: (field: keyof T) => void;
    setGridSort?: (sort: gridSortType<T>) => void;
    resetFilterFunc?: () => void;
    colorRowsBy?: (row: T) => string | undefined;
};

const SELECTION_COL_WIDTH = '40px';
const SETTINGS_COL_WIDTH = '40px';
const SETTINGS_COL_HEIGHT = 56;

function Grid<T>(props: GridProps<T>) {
    let {
        columns,
        data,
        sortedByColumn,
        sortDirection,
        localization,
        settings,
        menuType,
        menuBindedFields,
        idKey,
        userIdKey,
        emptyStateText,
        showLoader,
        userSettingKey,
        printMode,
        lastVisitId,
        parentEntityId,
        groupHeaders,
        gridSort,
        showCheckboxSelection,
        selectedRows,
        selectedColor,
        allData,
        contextMenuData,
        onSelectedRowsChange,
        onSettingsClick,
        onRowMoreOptionsClick,
        onColumnClick,
        resetFilterFunc,
        onRowClick,
        colorRowsBy,
        setGridSort
    } = props;

    const [currColumns, setCurrColumns] = useState(columns);

    const [currentContextMenuRow, setCurrentContextMenuRow] = useState<string | null>(null);

    useEffect(() => {
        setCurrColumns(columns);
    }, [columns]);

    const [groupedHeadersWidths, firstColumnContainerRef, innerColumnsContainerRef] = useGroupedHeaders<T>(columns, groupHeaders);

    const visibleColumns = currColumns.filter((col) => col.isDisplayed !== false);
    const firstColumn = visibleColumns.shift();

    const gridSortEntries = Object.entries(gridSort || []) as [keyof T, orderType][];

    const defaultOnColumnClick = (newColumn: keyof T) => {
        const currentSortColumnOrder = gridSortEntries.find?.(([key, _sort]) => key === newColumn)?.[1];
        const newOrder = !currentSortColumnOrder ? 'asc' : currentSortColumnOrder === 'asc' ? 'desc' : undefined;

        const newGridSort: gridSortType<T> = {};

        if (newOrder) {
            newGridSort[newColumn] = newOrder;
        }

        setGridSort?.(newGridSort);
    };

    const columnClickHandler = (col: ColumnType<T>) => {
        if (col.sortable) {
            if (onColumnClick) {
                onColumnClick?.(col.field);
                return;
            }

            defaultOnColumnClick?.(col.field);
        }
    };

    if (showLoader || !firstColumn) return <Loader />;

    const groupedColumns = groupColumns<T>(visibleColumns, groupHeaders);

    const allRowsIds = (allData || data).map((record) => (idKey ? `${record[idKey]}` : ''));

    const isAllRowsSelected = !!idKey ? xor(allRowsIds, selectedRows || []).length === 0 : false;

    const isSelected = (record: T) => !!(idKey && record?.[idKey] && selectedRows?.includes(`${record[idKey]}`));

    const GridColumn = React.memo(({ column, height }: { column: ColumnType<T>; height?: string }) => (
        <Column
            key={`grid_column_${column.field}`}
            width={column.width || '100%'}
            height={height}
            minWidth={column.minWidth}
            pointer={column.sortable || false}
            sortable={column.sortable}
            textAlign={!!groupHeaders?.length ? 'center' : column.textAlign}
            isGroupedGrid={!!groupHeaders?.length}
            isSorted={sortedByColumn ? column.field === sortedByColumn : gridSort ? Object.keys(gridSort).includes(column.field as string) : false}
            onClick={() => columnClickHandler(column)}
            sortDirection={sortDirection ? sortDirection : gridSort ? gridSortEntries.find?.(([key, _sort]) => key === column.field)?.[1] : 'asc'}
        >
            {!column.hideTitle && (
                <ColumnText>
                    {localization?.columns?.[column.field]
                        ? localization.columns?.[column.field]
                        : column.title
                        ? column.title
                        : camelToSentence(`${column.field}`)}
                </ColumnText>
            )}
            {column.toolTipText && <CZTooltip text={column.toolTipText}>{QuestionToolTip}</CZTooltip>}
        </Column>
    ));

    return (
        <ContentContainer>
            <TableCard>
                <ColumnsShadow />
                <FlexWrapper>
                    {showCheckboxSelection && (
                        <StickyFirstColumn width={SELECTION_COL_WIDTH} removeShadow>
                            <FlexWrapper>
                                <Column key={`selection_grid_column`} width={'100%'} pointer={false} minWidth={'0px'}>
                                    <SelectionWrapper removeBorder>
                                        <Checkbox
                                            checked={isAllRowsSelected}
                                            onChange={(e) => {
                                                if (!idKey || !onSelectedRowsChange) return;

                                                const { checked } = e.target;

                                                onSelectedRowsChange(checked ? allRowsIds : []);
                                            }}
                                        />
                                    </SelectionWrapper>
                                </Column>
                                {data.map((record, rowNumber) => {
                                    return (
                                        <Row key={`grid_selection_row_${rowNumber}`}>
                                            <SelectionWrapper
                                                backgroundColor={
                                                    (currentContextMenuRow && idKey && currentContextMenuRow === `${record[idKey]}`) ||
                                                    isSelected(record)
                                                        ? selectedColor || 'rgb(0 138 201 / 8%)'
                                                        : colorRowsBy?.(record)
                                                }
                                            >
                                                <Checkbox
                                                    checked={isSelected(record)}
                                                    onChange={(e) => {
                                                        if (!idKey || !onSelectedRowsChange) return;

                                                        const { checked } = e.target;
                                                        const id = `${record[idKey]}`;
                                                        const selection = selectedRows || [];

                                                        if (!id) return;

                                                        const newSelection = checked
                                                            ? union(selection, [id])
                                                            : remove(selection, (el: string) => el !== id);
                                                        onSelectedRowsChange(newSelection);
                                                    }}
                                                />
                                            </SelectionWrapper>
                                        </Row>
                                    );
                                })}
                            </FlexWrapper>
                        </StickyFirstColumn>
                    )}
                    <StickyFirstColumn width={firstColumn.width === '100%' ? '100px' : firstColumn.width} ref={firstColumnContainerRef}>
                        <FlexWrapper>
                            <Column
                                key={`grid_column_0`}
                                width={'100%'}
                                pointer={firstColumn.sortable || false}
                                sortable={firstColumn.sortable}
                                textAlign={firstColumn.textAlign}
                                isGroupedGrid={!!groupHeaders?.length}
                                isSorted={
                                    sortedByColumn
                                        ? firstColumn.field === sortedByColumn
                                        : gridSort
                                        ? Object.keys(gridSort).includes(firstColumn.field as string)
                                        : false
                                }
                                onClick={() => columnClickHandler(firstColumn)}
                                sortDirection={
                                    sortDirection
                                        ? sortDirection
                                        : gridSort
                                        ? gridSortEntries.find?.(([key, _sort]) => key === firstColumn.field)?.[1]
                                        : 'asc'
                                }
                                paddingLeft={'12px'}
                            >
                                {firstColumn.toolTipText && <CZTooltip text={firstColumn.toolTipText} />}
                                <ColumnText>
                                    {localization?.columns?.[firstColumn.field]
                                        ? localization.columns?.[firstColumn.field]
                                        : firstColumn.title
                                        ? firstColumn.title
                                        : camelToSentence(`${firstColumn.field}`)}
                                </ColumnText>
                            </Column>

                            {data.map((record, rowNumber) => {
                                return (
                                    <div style={{ width: '100%' }} key={`grid_row_${rowNumber}`}>
                                        <Row key={`grid_row_${rowNumber}`}>
                                            <CellRenderer<T>
                                                record={record}
                                                key={`grid_cell_0_${firstColumn}`}
                                                cellValue={record[firstColumn.field] || '-'}
                                                hideTitleAttribute={firstColumn.hideTitleAttribute}
                                                cellType={firstColumn.columnType}
                                                width={'100%'}
                                                textAlign={firstColumn.textAlign}
                                                bindedData={bindData(record, firstColumn.bindedFields)}
                                                localization={localization}
                                                settings={typeof settings === 'function' ? settings(record) : settings}
                                                entityId={idKey ? record[idKey] : null}
                                                entityUserId={userIdKey ? record[userIdKey] : null}
                                                colNumber={-1}
                                                isLastVisited={lastVisitId === (idKey && record[idKey])}
                                                valueFormatter={firstColumn.valueFormatter}
                                                renderer={firstColumn.renderer}
                                                onClick={onRowClick ? onRowClick.bind(undefined, record) : undefined}
                                                pointer={!!onRowClick}
                                                parentEntityId={parentEntityId}
                                                color={
                                                    (currentContextMenuRow && idKey && currentContextMenuRow === `${record[idKey]}`) ||
                                                    isSelected(record)
                                                        ? selectedColor || 'rgb(0 138 201 / 8%)'
                                                        : colorRowsBy?.(record)
                                                }
                                            />
                                        </Row>
                                    </div>
                                );
                            })}
                        </FlexWrapper>
                    </StickyFirstColumn>
                    <TableColumns
                        hasSettingColumn={onRowMoreOptionsClick !== undefined}
                        firstColumnWidth={firstColumn.width === '100%' ? '100px' : firstColumn.width}
                        selectionEnabled={showCheckboxSelection}
                        ref={innerColumnsContainerRef}
                    >
                        <FlexWrapper>
                            <OpacitySideHeader />
                            <div style={{ width: '100%', display: 'flex' }}>
                                {groupedColumns
                                    ? groupedColumns.map((group: groupElType<T>, i: number) => {
                                          const [groupName, columns] = group;
                                          if (!groupName) {
                                              return columns.map((col, index) => {
                                                  return <GridColumn column={col} key={`${col.field}-${index}`} />;
                                              });
                                          }
                                          return (
                                              <GroupHeadersWrapper key={`group-header-wrapper-${i}`}>
                                                  <GroupHeaderName>{groupName}</GroupHeaderName>
                                                  <div
                                                      style={{
                                                          width: groupedHeadersWidths.find((g) => g.name === groupName)?.width || '100%',
                                                          display: 'flex',
                                                          height: '100%'
                                                      }}
                                                  >
                                                      {columns.map((col, index) => {
                                                          return <GridColumn column={col} height={'auto'} key={`${col.field}-${index}`} />;
                                                      })}
                                                  </div>
                                              </GroupHeadersWrapper>
                                          );
                                      })
                                    : visibleColumns.map((col, index) => {
                                          return <GridColumn column={col} key={`${col.field}-${index}`} />;
                                      })}
                            </div>

                            {data.length > 0 &&
                                data.map((record, rowNumber) => {
                                    const row = (
                                        <div style={{ width: '100%' }} key={`grid_row_${rowNumber}`}>
                                            <Row key={`grid_row_${rowNumber}`}>
                                                <OpacitySideRow />
                                                {visibleColumns.map((col, colNumber) => {
                                                    return (
                                                        <CellRenderer<T>
                                                            record={record}
                                                            key={`grid_cell_${rowNumber}_${colNumber}`}
                                                            cellValue={record[col.field] || '-'}
                                                            cellType={col.columnType}
                                                            width={col.width || '100%'}
                                                            minWidth={col.minWidth}
                                                            textAlign={col.textAlign}
                                                            hideTitleAttribute={col.hideTitleAttribute}
                                                            bindedData={bindData(record, col.bindedFields)}
                                                            colNumber={colNumber}
                                                            settings={typeof settings === 'function' ? settings(record) : settings}
                                                            entityId={idKey ? record[idKey] : null}
                                                            entityUserId={userIdKey ? record[userIdKey] : null}
                                                            localization={localization}
                                                            isLastVisited={lastVisitId === (idKey && record[idKey])}
                                                            valueFormatter={col.valueFormatter}
                                                            renderer={col.renderer}
                                                            onClick={onRowClick ? onRowClick.bind(undefined, record) : undefined}
                                                            pointer={!!onRowClick}
                                                            parentEntityId={parentEntityId}
                                                            color={
                                                                (currentContextMenuRow && idKey && currentContextMenuRow === `${record[idKey]}`) ||
                                                                isSelected(record)
                                                                    ? selectedColor || 'rgb(0 138 201 / 8%)'
                                                                    : colorRowsBy?.(record)
                                                            }
                                                        />
                                                    );
                                                })}
                                            </Row>
                                        </div>
                                    );
                                    return contextMenuData?.length ? (
                                        <ContextMenu
                                            menuItemsData={contextMenuData(record)}
                                            onOpen={() => {
                                                if (idKey) setCurrentContextMenuRow(`${record[idKey]}`);
                                            }}
                                            onClose={() => {
                                                setCurrentContextMenuRow(null);
                                            }}
                                        >
                                            {row}
                                        </ContextMenu>
                                    ) : (
                                        row
                                    );
                                })}
                        </FlexWrapper>
                    </TableColumns>
                    {onRowMoreOptionsClick && !printMode ? (
                        <StickySettingsColumn>
                            <FlexWrapper>
                                {onSettingsClick ? (
                                    <SettingsColumns
                                        colWidth={SETTINGS_COL_WIDTH}
                                        columns={currColumns}
                                        userSettingKey={userSettingKey}
                                        onSettingsClick={onSettingsClick}
                                        setColumns={setCurrColumns}
                                    />
                                ) : (
                                    <Column key="settings" width={SETTINGS_COL_WIDTH} pointer={false} sortable={false}></Column>
                                )}
                                {data.map((record, rowNumber) => {
                                    return (
                                        <div style={{ width: '100%' }} key={`grid_row_${rowNumber}`}>
                                            <Row key={`grid_row_${rowNumber}`}>
                                                <CellRenderer<T>
                                                    record={record}
                                                    key={`grid_more_options_row${rowNumber}`}
                                                    pointer
                                                    cellType={menuType}
                                                    bindedData={bindData(record, menuBindedFields)}
                                                    width={SETTINGS_COL_WIDTH}
                                                    height={SETTINGS_COL_HEIGHT}
                                                    entityId={idKey ? record[idKey] : null}
                                                    entityUserId={userIdKey ? record[userIdKey] : null}
                                                    localization={localization?.menu}
                                                    settings={typeof settings === 'function' ? settings(record) : settings}
                                                    onRowMoreOptionsClick={() => onRowMoreOptionsClick?.(record)}
                                                    isLastVisited={lastVisitId === (idKey && record[idKey])}
                                                    parentEntityId={parentEntityId}
                                                    color={isSelected(record) ? selectedColor || 'rgb(0 138 201 / 8%)' : colorRowsBy?.(record)}
                                                />
                                            </Row>
                                        </div>
                                    );
                                })}
                            </FlexWrapper>
                        </StickySettingsColumn>
                    ) : (
                        <div></div>
                    )}
                </FlexWrapper>
                {data.length === 0 && (
                    <EmptyState>
                        {resetFilterFunc ? (
                            <>
                                <span>{localization.filter.noResults}</span>

                                <Button margin="15px 0 0 0" onClick={resetFilterFunc} buttonType="filled" minWidth="55px">
                                    {localization.filter.resetBtn}
                                </Button>
                            </>
                        ) : (
                            <span>{emptyStateText || localization.filter.noData}</span>
                        )}
                    </EmptyState>
                )}
            </TableCard>
        </ContentContainer>
    );
}

const ContentContainer = styled.div`
    display: flex;
    flex-direction: column;
    height: 100%;
    width: 100%;
`;

const TableCard = styled.div`
    flex: 1;
    max-width: 100%;
    position: relative;
    width: 100%;
    box-sizing: border-box;
    width: 100%;
`;

const FlexWrapper = styled.div`
    display: flex;
    flex-wrap: wrap;
    box-sizing: border-box;
    width: 100%;
`;

type FirstColumnProps = {
    width?: string;
    removeShadow?: boolean;
};

const StickyFirstColumn = styled.div<FirstColumnProps>`
    flex: 0 0 ${(props) => props.width ?? '25%'};
    max-width: ${(props) => props.width ?? '25%'};
    position: relative;
    box-sizing: border-box;

    box-shadow: ${(props) => (props.removeShadow ? 'none' : '7px 0 4px -4px rgba(0, 0, 0, 0.1)')};
    background-color: rgba(255, 255, 255, 0.9);

    @media screen and (min-width: 768px) {
        flex: 0 0 ${(props) => props.width ?? '16.6666666667%'};
        max-width: ${(props) => props.width ?? '16.6666666667%'};
    }

    @media screen and (min-width: 992px) {
        flex: 0 0 ${(props) => props.width ?? '8.3333333334%'};
        max-width: ${(props) => props.width ?? '8.3333333334%'};
    }
`;

type TableColumnsProps = {
    hasSettingColumn?: boolean;
    firstColumnWidth?: string;
    selectionEnabled?: boolean;
};

const getTableColumnsWidth = (props: TableColumnsProps) =>
    `calc(100% - ${props.firstColumnWidth ?? '25%'} - ${props.hasSettingColumn ? SETTINGS_COL_WIDTH : '0px'} - ${
        props.selectionEnabled ? SELECTION_COL_WIDTH : '0px'
    })`;
const getTableColumnsWidthMid = (props: TableColumnsProps) =>
    `calc(100% - ${props.firstColumnWidth ?? '16.6666666667%'} - ${props.hasSettingColumn ? SETTINGS_COL_WIDTH : '0px'} - ${
        props.selectionEnabled ? SELECTION_COL_WIDTH : '0px'
    })`;

const getTableColumnsWidthLarge = (props: TableColumnsProps) =>
    `calc(100% - ${props.firstColumnWidth ?? '8.3333333334%'} - ${props.hasSettingColumn ? SETTINGS_COL_WIDTH : '0px'} - ${
        props.selectionEnabled ? SELECTION_COL_WIDTH : '0px'
    })`;

const TableColumns = styled.div<TableColumnsProps>`
    width: ${(props) => getTableColumnsWidth(props)};
    max-width: ${(props) => getTableColumnsWidth(props)};
    position: relative;
    padding: 0;
    white-space: nowrap;
    overflow-x: auto;

    @media screen and (min-width: 768px) {
        width: ${(props) => getTableColumnsWidthMid(props)};
        max-width: ${(props) => getTableColumnsWidthMid(props)};
    }

    @media screen and (min-width: 992px) {
        width: ${(props) => getTableColumnsWidthLarge(props)};
        max-width: ${(props) => getTableColumnsWidthLarge(props)};
    }
`;

const OpacitySideHeader = styled.div`
    position: absolute;
    height: 73px;
    background-color: #fff;
    width: 20px;
`;

const OpacitySideRow = styled.div`
    position: absolute;
    height: 55px;
    background-color: #fff;
    width: 20px;
`;

const StickySettingsColumn = styled.div`
    flex: 0 0 ${SETTINGS_COL_WIDTH};
    max-width: ${SETTINGS_COL_WIDTH};
    position: relative;
    width: 100%;
    box-sizing: border-box;

    box-shadow: -7px 0 4px -4px rgba(0, 0, 0, 0.1);
    background-color: rgba(255, 255, 255, 0.9);

    @media screen and (min-width: 1200px) {
        box-shadow: none;
    }
`;

type StyledColumnProps = {
    width: string;
    height?: string;
    minWidth?: string;
    paddingLeft?: string;
    pointer: boolean;
    sortable?: boolean;
    sortDirection?: 'asc' | 'desc';
    isSorted?: boolean;
    textAlign?: string;
    isGroupedGrid?: boolean;
};

const ColumnsShadow = styled.div`
    display: flex;
    position: absolute;
    width: 100%;
    height: 6px;
    background-image: linear-gradient(#c3c3c3, #fff);
    z-index: 100;
    margin-top: 76px;

    @media screen and (max-width: 1200px) {
        display: none;
    }
`;
const ColumnText = styled.div`
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: normal;
    width: 100%;
    max-height: 40px;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
`;
export const Column = styled.div<StyledColumnProps>`
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    box-sizing: border-box;
    padding: 6px;
    padding-left: ${(props) => props.paddingLeft ?? ''};
    min-width: ${(props) => (props.width !== '100%' ? props.width : props.minWidth ?? '80px')};
    width: ${(props) => props.width};
    height: ${(props) => props.height ?? '76px'};
    cursor: ${(props) => (props.pointer ? 'pointer' : 'default')};
    user-select: none;

    ${(props) =>
        props.isGroupedGrid &&
        css`
            border: 1px solid #048ac9;
            border-left: none;
        `}
    border-bottom: 3px solid ${(props) => props.theme.colors.primaryBlue};
    position: relative;
    transition: border-bottom 0.2s;
    display: flex;
    align-items: center;
    text-align: ${(props) => (props.textAlign ? props.textAlign : 'left')};
    z-index: 999;
    color: ${(props) => props.theme.colors.secondary};
    font-weight: bold;
    font-size: ${(props) => props.theme.fontSizes.gridCell};

    :not(:hover) {
        ${({ isSorted, sortDirection, theme }) =>
            isSorted &&
            sortDirection === 'asc' &&
            css`
                &::before {
                    content: '▲';
                    bottom: -4px;
                    left: calc(50% - 5px);
                    position: absolute;
                    color: ${theme.colors.primaryBlue};
                    font-size: 14px;
                }
            `}

        ${({ isSorted, sortDirection, theme }) =>
            isSorted &&
            sortDirection === 'desc' &&
            css`
                &::before {
                    content: '▼';
                    bottom: -21px;
                    left: calc(50% - 5px);
                    position: absolute;
                    color: ${theme.colors.primaryBlue};
                    font-size: 14px;
                }
            `}
    }

    :hover {
        ${({ sortable, theme, pointer }) =>
            sortable &&
            css`
                border-bottom: 3px solid ${pointer ? theme.colors.grey : theme.colors.primaryBlue};
                &::before {
                    content: '▲';
                    bottom: -4px;
                    left: calc(50% - 5px);
                    position: absolute;
                    color: ${theme.colors.grey};
                    font-size: 14px;
                }
                &::after {
                    content: '▼';
                    bottom: -21px;
                    left: calc(50% - 5px);
                    position: absolute;
                    color: ${theme.colors.grey};
                    font-size: 14px;
                }
            `}
    }
`;

const Row = styled.div`
    display: flex;
    width: 100%;
    transition: all 0.2s;
    height: ${SETTINGS_COL_HEIGHT}px;
    max-height: ${SETTINGS_COL_HEIGHT}px;
`;
const EmptyState = styled.div`
    height: auto;
    background-image: linear-gradient(to bottom, #e9e9e9db, #ffffff);
    padding-top: 51px;
    padding-left: 56px;
    color: ${(props) => props.theme.colors.primaryBlue};
`;

const GroupHeadersWrapper = styled.div`
    display: flex;
    flex-direction: column;
    height: 76px;
    align-items: center;
`;

const GroupHeaderName = styled.div`
    border: 1px solid #048ac9;
    border-left: none;
    border-bottom: none;
    width: 100%;
    height: 42px;
    display: flex;
    align-items: center;
    justify-content: center;
    font-weight: bold;
`;

const SelectionWrapper = styled.div<{ removeBorder?: boolean; backgroundColor?: string }>`
    width: 50px;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    border-bottom: ${(props) => (props.removeBorder ? 'none' : 'solid 1px #e0e0e0')};
    background-color: ${(props) => props.backgroundColor ?? 'none'};
`;

Grid.defaultProps = {
    idKey: 'id'
};

const genericMemo: <T>(component: T) => T = React.memo;

export default genericMemo(Grid);
