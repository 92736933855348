import React, { useState, useEffect, useRef } from 'react';
import styled, { css } from 'styled-components';
import { task } from '../../../../models/entities/task';
import DropDown, { DropDownItem } from '../../../shared/ColoredDropDown/DropDown';
import { DatePicker } from 'antd';
import priorityDropDown from './PriorityDropDown';
import statusDropDown from './StatusDropDown';
import { TaskStatusEnum, TaskPriorityEnum } from '../../../../models/entities/task';
import moment, { Moment } from 'moment';
import trashIcon from '../../../../static/icons/trash.svg';
import editIcon from '../../../../static/icons/edit.svg';
import SetReminderDropDown from './SetReminderDropDown';
import { TriggerProps } from '../../../shared/Menu/Menu';
import { getReminderDate, getReminderValue } from '../../../../utils/date';
import { scroller, Events } from 'react-scroll';
import { Modal } from 'antd';

type Props = {
    task: task;
    updateTask: (task: task) => void;
    deleteTask: (taskId: string) => void;
    isHighlighted: boolean;
    localization: any;
    isFetching: boolean;
};

const Task = ({ task, updateTask, deleteTask, localization, isHighlighted, isFetching }: Props) => {
    const [isPriorityOpen, setIsPriorityOpen] = useState(false);
    const [isStatusOpen, setIsStatusOpen] = useState(false);
    const [isContentEditable, setContentEditable] = useState(false);
    const [textAreaValue, setValue] = useState(task.content);
    const [datePickerValue, setDatePickerValue] = useState<any>(moment(task.dueDate));
    const [reminderValue, setReminderValue] = useState<string | undefined>(getReminderValue(task.dueDate, task.reminder));
    const [isHighlightedTask, setIsHighlightedTask] = useState<boolean>(isHighlighted);

    const highlightedTaskRef = useRef<HTMLDivElement>(null);

    useEffect(() => {
        if (isHighlightedTask) {
            if (highlightedTaskRef && highlightedTaskRef.current) {
                highlightedTaskRef.current.scrollIntoView({
                    behavior: 'smooth'
                });
            }
            setTimeout(() => {
                setIsHighlightedTask(false);
            }, 2500);
        }
    }, [isHighlightedTask]);
    const statusDropDownOptions = [
        {
            text: localization.status.new,
            value: TaskStatusEnum.New.toString(),
            isSelected: task.status.toString() === TaskStatusEnum.New.toString(),
            icon: statusDropDown.renderNewIcon
        },
        {
            text: localization.status.pending,
            value: TaskStatusEnum.Pending.toString(),
            isSelected: task.status.toString() === TaskStatusEnum.Pending.toString(),
            icon: statusDropDown.renderPendingIcon
        },
        {
            text: localization.status.stuck,
            value: TaskStatusEnum.Stuck.toString(),
            isSelected: task.status.toString() === TaskStatusEnum.Stuck.toString(),
            icon: statusDropDown.renderStuckIcon
        },
        {
            text: localization.status.onWork,
            value: TaskStatusEnum.OnWork.toString(),
            isSelected: task.status.toString() === TaskStatusEnum.OnWork.toString(),
            icon: statusDropDown.renderOnWorkIcon
        },
        {
            text: localization.status.done,
            value: TaskStatusEnum.Done.toString(),
            isSelected: task.status.toString() === TaskStatusEnum.Done.toString(),
            icon: statusDropDown.renderDoneIcon
        }
    ];
    const priorityDropDownOptions = [
        {
            text: localization.priority.low,
            value: TaskPriorityEnum.Low.toString(),
            isSelected: task.priority.toString() === TaskPriorityEnum.Low.toString(),
            icon: priorityDropDown.renderLowIcon
        },
        {
            text: localization.priority.medium,
            value: TaskPriorityEnum.Medium.toString(),
            isSelected: task.priority.toString() === TaskPriorityEnum.Medium.toString(),
            icon: priorityDropDown.renderMediumIcon
        },
        {
            text: localization.priority.high,
            value: TaskPriorityEnum.High.toString(),
            isSelected: task.priority.toString() === TaskPriorityEnum.High.toString(),
            icon: priorityDropDown.renderHighIcon
        }
    ];

    const togglePriorityDropDown = () => {
        setIsPriorityOpen(!isPriorityOpen);
    };
    const closePriorityDropDown = () => {
        setIsPriorityOpen(false);
    };
    const toggleStatusDropDown = () => {
        setIsStatusOpen(!isStatusOpen);
    };
    const closeStatusDropDown = () => {
        setIsStatusOpen(false);
    };
    const onPriorityChange = (selectedOption: DropDownItem) => {
        updateTask({ ...task, priority: selectedOption.value });
        closePriorityDropDown();
    };
    const onStatusChange = (selectedOption: DropDownItem) => {
        updateTask({ ...task, status: selectedOption.value });
        closeStatusDropDown();
    };
    const onDueDateChange = (value: Moment | null, dateString: string) => {
        if (value) {
            setDatePickerValue(moment(value));
            const dueDate = new Date(dateString);
            updateTask({ ...task, dueDate, reminder: reminderValue ? getReminderDate(reminderValue, dueDate) : null });
        } else {
            setDatePickerValue(null);
        }
    };
    const onReminderChange = (value: string) => {
        setReminderValue(value);
        updateTask({ ...task, reminder: getReminderDate(value, task.dueDate) });
    };
    const onArchivingTask = () => {
        updateTask({ ...task, status: TaskStatusEnum.Archived });
    };
    const onDeleteTask = () => {
        Modal.confirm({
            zIndex: 1100,
            content: <div>Are you sure?</div>,
            async onOk() {
                await deleteTask(task.id);
            },
            onCancel() {}
        });
    };
    const onContentBlur = () => {
        updateTask({ ...task, content: textAreaValue });
        setContentEditable(false);
    };

    return (
        <>
            <Container isHighlighted={isHighlightedTask}>
                <Info>
                    <ContentContainer>
                        {isContentEditable && (
                            <Textarea
                                value={textAreaValue}
                                onChange={(e) => {
                                    if (e.currentTarget.value.length <= 500) {
                                        setValue(e.currentTarget.value);
                                    }
                                }}
                                onBlur={() => onContentBlur()}
                            />
                        )}
                        {!isContentEditable && (
                            <>
                                <Content onClick={() => setContentEditable(true)}>
                                    {task.content}
                                    <img className="edit-trigger" src={editIcon} alt="Archive task" />
                                </Content>
                            </>
                        )}
                    </ContentContainer>
                    <CreationDate>{moment(task.createdAt).format('MM/DD/YY, h:mm A').toString()}</CreationDate>
                    <RightActions>
                        <ArchiveIcon onClick={onDeleteTask}>
                            <img src={trashIcon} alt="Archive task" />
                        </ArchiveIcon>
                        <SetReminderDropDown
                            task={task}
                            onReminderChange={onReminderChange}
                            MenuTrigger={(props: TriggerProps) => (
                                <ReminderIcon onClick={props.onClick} hasReminder={!!task.reminder}>
                                    <span className="material-icons">schedule</span>
                                </ReminderIcon>
                            )}
                        />
                    </RightActions>
                </Info>
                <Actions>
                    <Status>
                        <DropDown
                            isOpen={isStatusOpen}
                            width="100%"
                            height={40}
                            options={statusDropDownOptions}
                            selectedOption={statusDropDownOptions.find((option) => option.isSelected)}
                            onToggle={toggleStatusDropDown}
                            onClickOutside={closeStatusDropDown}
                            onSelect={onStatusChange}
                            backgroundColor="transparent"
                        />
                    </Status>
                    <Priority>
                        <DropDown
                            isOpen={isPriorityOpen}
                            width="100%"
                            height={40}
                            options={priorityDropDownOptions}
                            selectedOption={priorityDropDownOptions.find((option) => option.isSelected)}
                            onToggle={togglePriorityDropDown}
                            onClickOutside={closePriorityDropDown}
                            onSelect={onPriorityChange}
                            backgroundColor="transparent"
                        />
                    </Priority>
                    <DueDate>
                        <DatePicker
                            showTime
                            onChange={onDueDateChange}
                            value={datePickerValue}
                            disabledDate={(current) => moment(current).isBefore(moment())}
                            style={{
                                height: '40px',
                                marginTop: '-1px',
                                marginLeft: '-1px',
                                marginRight: '-1px',
                                fontSize: '10px',
                                backgroundColor: 'transparent'
                            }}
                        />
                    </DueDate>
                </Actions>
            </Container>
            <div ref={isHighlightedTask ? highlightedTaskRef : undefined}></div>
        </>
    );
};

interface TaskContainerProps {
    isHighlighted: boolean;
}
const Container = styled.div<TaskContainerProps>`
    position: relative;
    height: fit-content;
    width: 100%;
    padding: 8px 32px;
    transition: background-color 0.3s;

    ${(props) =>
        props.isHighlighted
            ? css`
                  background-color: #fff06880;
              `
            : css`
                  background-color: transparent;
              `}
`;

const ContentContainer = styled.div`
    word-break: break-word;
`;

const Info = styled.div`
    padding: 15px;
    padding-right: 40px;
    cursor: pointer;
    border-top: 1px solid #e0e0e0;
    border-left: 1px solid #e0e0e0;
    border-right: 1px solid #e0e0e0;
    .edit-trigger {
        visibility: hidden;
    }

    :hover {
        .edit-trigger {
            visibility: visible;
        }
    }
`;

const Content = styled.div`
    font-size: 14px;
    color: #3d4e7e;
`;

const CreationDate = styled.div`
    margin-top: 5px;
    color: #757575;
    font-size: 11px;
`;

const Actions = styled.div`
    height: 40px;
    display: flex;
`;

const Action = styled.div`
    flex: 1;
    border: 1px solid #e0e0e0;
`;

const Status = styled(Action)`
    border-right: none;
`;

const Priority = styled(Action)`
    border-right: none;
`;

const DueDate = styled(Action)`
    border-right: 1px solid #e0e0e0;
`;
const RightActions = styled.div`
    position: absolute;
    top: 15px;
    right: 42px;
    display: flex;
    flex-direction: column;
`;
const ArchiveIcon = styled.div`
    margin-bottom: 5px;
    & img {
        height: 22px;
        width: 22px;
    }
`;
const ReminderIcon = styled.div<{ hasReminder?: boolean }>`
    color: ${(props) => (props.hasReminder ? props.theme.colors.primaryBlue : props.theme.colors.grey)};
    & span {
        font-size: 21px;
    }
`;

const Textarea = styled.textarea`
    border: none;
    outline: none;
    width: 100%;
    resize: vertical;
    min-height: 50px;
`;

export default Task;
