import { Modal } from 'antd';
import React, { useEffect, useState, useRef } from 'react';
import { chat, message } from '../../../../../models/entities/chat/chat';
import { shipment } from '../../../../../models/entities/shipment/shipment';
import { userInfo } from '../../../../../models/entities/user';
import { userDetails } from '../../../../../state/ducks/users/users';
import DotsWaveAnimation from '../../../../shared/Animations/DotsWaveAnimation';
import { ColumnType } from '../../../../shared/Grid/types/Column';
import { styled } from '../../../../shared/Theme/theme';
import { css } from 'styled-components';
import MessagesList from '../../components/MessagesList';
import { Events } from 'react-scroll';
type Props = {
    loader: React.MutableRefObject<any>;
    chatContentRef: React.MutableRefObject<any>;
    topMessageRef: React.MutableRefObject<any>;
    messagesEndRef: React.MutableRefObject<any>;
    scrollToBottom: (smooth?: boolean) => void;
    currentTopMessageId: string;
    userInfo: userInfo;
    chat?: chat;
    messages?: { [key: string]: message[] };
    deleteMessage: (id: string, chatId: string) => void;
    getUserById: (userId: string) => userDetails | undefined;
    isFetchingNextMessages: boolean;
    gridColumns: Array<ColumnType<shipment>>;
    getShipmentById: (id: string) => shipment | undefined;
    isEditMessage: message | null;
    setIsEditMessage: React.Dispatch<React.SetStateAction<message | null>>;
    setReplyMessage: React.Dispatch<React.SetStateAction<message | undefined>>;
    getMessageById: (chatId: string, messageId: string) => message | undefined;
    localization: any;
};

const Content = ({
    chat,
    messages,
    isEditMessage,
    getShipmentById,
    getUserById,
    gridColumns,
    userInfo,
    setIsEditMessage,
    deleteMessage,
    setReplyMessage,
    getMessageById,
    scrollToBottom,
    isFetchingNextMessages,
    chatContentRef,
    messagesEndRef,
    currentTopMessageId,
    topMessageRef,
    loader,
    localization
}: Props) => {
    const [highlightedMessage, setHighlightedMessage] = useState<string | undefined>(undefined);
    const [isBottom, setIsBottom] = useState(true);
    const [unreadMessages, setUnreadMessages] = useState(0);
    const handleScroll = (e: any) => {
        const { scrollHeight, scrollTop, clientHeight } = e.target;
        const bottom = scrollHeight - scrollTop - clientHeight < 3;

        if (bottom) {
            setIsBottom(true);
            setUnreadMessages(0);
        } else {
            setIsBottom(false);
        }
    };

    useEffect(() => {
        if (chatContentRef?.current) {
            chatContentRef.current.scrollTop = chatContentRef.current.scrollHeight;
        }
    }, []);

    useEffect(() => {
        scrollToBottom();
    }, [chat]);

    useEffect(() => {
        if (isBottom) {
            scrollToBottom();
        }
    }, [messages]);

    const lastMessage: message | undefined = chat?.messages[(chat?.messages.length || 0) - 1];
    useEffect(() => {
        if (!isBottom && chat?.messages.length != null && lastMessage?.userId !== userInfo.id) {
            setUnreadMessages((prev) => prev + 1);
        }
    }, [lastMessage?.id]);

    useEffect(() => {
        Events.scrollEvent.register('begin', function (to, element) {
            if (to?.includes('message-container-')) {
                const messageId = to.replace('message-container-', '');

                highlightMessage(messageId);
            }
        });
        return () => Events.scrollEvent.remove('begin');
    }, []);

    const highlightMessage = (id: string) => {
        setHighlightedMessage(id);
        setTimeout(() => {
            setHighlightedMessage(undefined);
        }, 1500);
    };
    const RenderMessages = () =>
        chat &&
        messages && (
            <MessagesList
                chatMessages={messages}
                chatId={chat.id}
                topMessageRef={topMessageRef}
                currentTopMessageId={currentTopMessageId}
                chatType={chat.type}
                isEditMessage={isEditMessage}
                getShipmentById={getShipmentById}
                getUserById={getUserById}
                gridColumns={gridColumns}
                userInfo={userInfo}
                highlightMessage={highlightMessage}
                highlightedMessage={highlightedMessage}
                getMessageById={getMessageById}
                onMenuEditClick={(e: any, m: message) => {
                    e.stopPropagation();
                    setIsEditMessage(m);
                    // setEditEditorState(moveSelectionToEnd(convertHtmlToEditorState(m.content, getUserById, getShipmentById)));
                    if (isEditMessage && isEditMessage.id === m.id) {
                        setIsEditMessage(null);
                    }
                }}
                onMenuDeleteClick={(e: any, m: message) => {
                    Modal.confirm({
                        zIndex: 1100,
                        content: <div>Are you sure?</div>,
                        async onOk() {
                            deleteMessage(m.id, m.chatId);
                        },
                        onCancel() {}
                    });
                }}
                setReplyMessage={(message: message) => {
                    setReplyMessage(message);
                    // setEditorState(moveSelectionToEnd(editorState));
                }}
                localization={localization}
            />
        );
    return (
        <div style={{ position: 'relative', flex: 1, overflow: 'hidden' }}>
            <ChatContent id={`chat-content-${chat?.id}`} onScroll={handleScroll} ref={chatContentRef}>
                <div className="dots-container">{isFetchingNextMessages && <DotsWaveAnimation dotSize={3} />}</div>
                <div className="loading" ref={loader}></div>
                {RenderMessages()}
                <div ref={messagesEndRef} />
            </ChatContent>
            <ScrollBottomBtn isBottom={isBottom} onClick={() => scrollToBottom(true)}>
                <span className="material-icons">keyboard_arrow_down</span>
                {!!unreadMessages && <div className="unread-count">{unreadMessages}</div>}
            </ScrollBottomBtn>
        </div>
    );
};

interface ChatContentProps {
    isBottom: boolean;
}

const ChatContent = styled.div`
    flex: 1;
    position: relative;
    overflow-y: overlay;
    display: block;
    flex-direction: column;
    padding-top: 18px;
    position: relative;
    height: 100%;

    .dots-container {
        text-align: center;
        position: absolute;
        top: 7px;
        width: 100%;
        height: 20px;
        background-color: transparent;
    }
    .loading {
        text-align: center;
        position: absolute;
        top: 7px;
        width: 100%;
        height: 200px;
        z-index: -1;
        background-color: transparent;
    }
`;
const ScrollBottomBtn = styled.div<ChatContentProps>`
    cursor: pointer;
    transition: 0.3s;
    height: 35px;
    width: 35px;
    border-radius: 50%;
    background-color: #00b5b9ad;
    position: absolute;
    bottom: 3px;
    right: 18px;
    transform-origin: 50% 50%;
    box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.18), 0 2px 2px 0 rgba(0, 0, 0, 0.12);
    display: flex;
    align-items: center;
    justify-content: center;
    color: white;

    .material-icons {
        font-size: 29px;
    }

    .unread-count {
        position: absolute;
        z-index: 10;
        bottom: 26px;
        right: -4px;
        height: 18px;
        width: 18px;
        font-size: 11px;
        background-color: #00375c;
        text-align: center;
        border-radius: 50%;
        display: flex;
        align-items: center;
        justify-content: center;
    }
    ${(props) =>
        !props.isBottom
            ? css`
                  opacity: 1;
                  transform: scale(1);
              `
            : css`
                  opacity: 0;
                  transform: scale(0);
              `}
`;

export default React.memo(Content);
