import React from 'react';
import { settings, shipment, shipmentSettingsFunc } from '../../../../models/entities/shipment/shipment';
import { localizationSelectors } from '../../../../state/ducks/localization/selectors';
import { shipmentsSelectors } from '../../../../state/ducks/shipments/selectors';
import { RootState } from '../../../../state/store/store';
import { connect } from 'react-redux';
import { singleShipmentSelectors } from '../../../../state/ducks/singleShipment/selectors';
import { document } from '../../../../models/entities/common_subentities/document';
import DocumentFormConnected, { formMode } from '../../commonSubEntities/documents/DocumentForm/DocumentFormConnected';

type Props = {
    shipment?: shipment;
    document?: document;
    mode: formMode;
    localizations?: any;
    settings: shipmentSettingsFunc;
    openedFromModal?: {
        setVisible: React.Dispatch<React.SetStateAction<boolean>>;
    };
    confirmation?: { setConfirm: React.Dispatch<React.SetStateAction<boolean>> };
    createShipmentDocument: (shipmentNumber: string, document: document) => void;
    updateShipmentDocument: (shipmentNumber: string, document: document) => void;
    showLoader?: boolean;
    defaultValues?: document;
};

const ShipmentDocumentFormConnected = ({
    shipment,
    document,
    localizations,
    mode,
    settings,
    openedFromModal,
    confirmation,
    createShipmentDocument,
    updateShipmentDocument,
    showLoader,
    defaultValues
}: Props) => {
    return (
        <DocumentFormConnected<shipment, settings>
            entity={shipment}
            document={document}
            localizations={localizations}
            mode={mode}
            settings={settings(shipment)}
            openedFromModal={openedFromModal}
            confirmation={confirmation}
            createDocument={createShipmentDocument}
            updateDocument={updateShipmentDocument}
            showLoader={showLoader}
            defaultValues={defaultValues}
            typeMode="tags"
        />
    );
};
const mapStateToProps = (state: RootState) => ({
    localizations: localizationSelectors.singleShipmentPage(state),
    settings: shipmentsSelectors.settingsByLanguage(state),
    shipment: singleShipmentSelectors.shipment(state),
    showLoader: state.loading.effects.shipments.createShipmentDocument || state.loading.effects.shipments.updateShipmentDocument
});

const mapDispatchToProps = (dispatch: any) => ({
    createShipmentDocument: (shipmentNumber: string, document: document) => dispatch.shipments.createShipmentDocument({ shipmentNumber, document }),
    updateShipmentDocument: (shipmentNumber: string, document: document) => dispatch.shipments.updateShipmentDocument({ shipmentNumber, document })
});

export default connect(mapStateToProps, mapDispatchToProps)(ShipmentDocumentFormConnected);
