import React, { useState } from 'react';
import Grid from '../../shared/Grid/Grid';
import { ColumnType } from '../../shared/Grid/types/Column';
import { styled } from '../../shared/Theme/theme';
import Button from '../../shared/SmallComponents/Button';
import CustomModal, { ModalTriggerProps } from '../../shared/Modal/Modal';
import TextInput from '../../shared/inputs/base/TextInput';
import { bid } from '../../../models/entities/bid/bid';

type Props = {
    bids: Array<bid>;
    gridColumns: Array<ColumnType<bid>>;
    localization: any;
    showLoader?: boolean;
    userSettingKey?: string;
};

const BidsPage = ({ bids, gridColumns, localization, showLoader, userSettingKey }: Props) => {
    return (
        <PageWrapper>
            <GridWrapper>
                <Grid<bid>
                    onSettingsClick={() => console.log('settings')}
                    onRowMoreOptionsClick={(row) => {}}
                    columns={gridColumns}
                    data={bids}
                    localization={localization.grid}
                    emptyStateText={localization.grid.emptyState}
                    showLoader={showLoader}
                    userSettingKey={userSettingKey}
                />
            </GridWrapper>
        </PageWrapper>
    );
};

const PageWrapper = styled.div`
    height: 100%;
    display: flex;
    flex-direction: column;
`;

const GridWrapper = styled.div`
    flex: 1;
    max-width: 100%;
    width: 100%;
    box-sizing: border-box;
    height: 100%;
    width: 100%;
    z-index: 1;
    overflow: overlay;

    @media screen and (min-width: 768px) {
        height: calc(100% - 127px);
    }

    @media screen and (max-width: 768px) {
        height: calc(100% - 135px - 40px);
    }

    @media screen and (max-height: 400px) {
        height: calc(100% - 135px);
    }
`;

export default BidsPage;
