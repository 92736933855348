import React from 'react';
import { event } from '../../../../models/entities/common_subentities/event';
import { settings, purchaseOrder } from '../../../../models/entities/purchaseOrder/purchaseOrder';
import { localizationSelectors } from '../../../../state/ducks/localization/selectors';
import { purchaseOrdersSelectors } from '../../../../state/ducks/purchaseOrders/selectors';
import { RootState } from '../../../../state/store/store';
import { connect } from 'react-redux';
import { singlePurchaseOrderSelectors } from '../../../../state/ducks/singlePurchaseOrder/selectors';
import { userSelectors } from '../../../../state/ducks/user/selectors';
import EventFormConnected, { formMode } from '../../commonSubEntities/events/EventForm/EventFormConnected';

type Props = {
    purchaseOrder?: purchaseOrder;
    event?: event;
    mode: formMode;
    localizations?: any;
    settings?: settings;
    openedFromModal?: {
        setVisible: React.Dispatch<React.SetStateAction<boolean>>;
    };
    confirmation?: { setConfirm: React.Dispatch<React.SetStateAction<boolean>> };
    createPurchaseOrderEvent: (purchaseOrderNumber: string, event: event) => void;
    updatePurchaseOrderEvent: (purchaseOrderNumber: string, event: event) => void;
    showLoader?: boolean;
};

const PurchaseOrderEventFormConnected = ({
    purchaseOrder,
    event,
    localizations,
    mode,
    settings,
    openedFromModal,
    confirmation,
    createPurchaseOrderEvent,
    updatePurchaseOrderEvent,
    showLoader
}: Props) => {
    return (
        <EventFormConnected<purchaseOrder, settings>
            entity={purchaseOrder}
            event={event}
            localizations={localizations}
            mode={mode}
            settings={settings}
            openedFromModal={openedFromModal}
            confirmation={confirmation}
            createEvent={createPurchaseOrderEvent}
            updateEvent={updatePurchaseOrderEvent}
            showLoader={showLoader}
        />
    );
};

const mapStateToProps = (state: RootState) => ({
    localizations: localizationSelectors.singlePurchaseOrderPage(state),
    settings: purchaseOrdersSelectors.settingsByLanguage(state),
    purchaseOrder: singlePurchaseOrderSelectors.purchaseOrder(state),
    showLoader: state.loading.effects.purchaseOrders.createPurchaseOrderEvent || state.loading.effects.purchaseOrders.updatePurchaseOrderEvent,
    userInfo: userSelectors.userInfo(state)
});

const mapDispatchToProps = (dispatch: any) => ({
    createPurchaseOrderEvent: (purchaseOrderNumber: string, event: event) =>
        dispatch.purchaseOrders.createPurchaseOrderEvent({ purchaseOrderNumber, event }),
    updatePurchaseOrderEvent: (purchaseOrderNumber: string, event: event) =>
        dispatch.purchaseOrders.updatePurchaseOrderEvent({ purchaseOrderNumber, event })
});

export default connect(mapStateToProps, mapDispatchToProps)(PurchaseOrderEventFormConnected);
