import BaseController from '../..';
import shipmentItem from '../../../../models/entities/shipment/shipmentItem';
import IShipmentContainers from './IShipmentContainers';

export default class ShipmentContainers extends BaseController implements IShipmentContainers {
    constructor(baseUrl: string) {
        super(baseUrl);
    }

    async fetchShipmentContainers(shipmentNumber: string) {
        const response = await this.client.get(`/shipment/containers/${shipmentNumber}`);
        return response;
    }

    async updateShipmentContainerState(shipmentId: string, packageId: string, state: string) {
        const response = await this.client.put(`/shipment/containers`, JSON.stringify({ shipmentId, id: packageId, state }));
        return response;
    }

    async deleteShipmentContainer(shipmentId: string, containerId: string): Promise<void> {
        await this.client.delete(`/shipment/containers`, JSON.stringify({ shipmentId, id: containerId }));
    }
}
