import { address } from '../../../models/entities/businessPartner/address';
import { ModelConfig } from '@rematch/core';
import { businessPartner } from '../../../models/entities/businessPartner/businessPartner';
import { document, documents } from '../../../models/entities/businessPartner/document';
import { contact } from '../../../models/entities/businessPartner/contact';
import { localSDK as client } from '../../../sdk';
import { discussion } from '../../../models/entities/common_subentities/discussion';
import { addDiscussion, editDiscussion, removeDiscussion } from '../../util/common_subentities/discussions';
import { RcFile } from 'antd/lib/upload';
export type businessPartnerStateType = {
    parties: Array<businessPartner>;
    errorFetchBusinessPartners?: string;
};

export const businessPartners: ModelConfig<businessPartnerStateType> = {
    state: {
        parties: []
    },
    reducers: {
        setBusinessPartners(state: businessPartnerStateType, parties: Array<businessPartner>): businessPartnerStateType {
            if (parties.length > 0) {
                let newParties = [...parties];
                newParties = newParties.map((item) => {
                    const stateParty = state.parties.find((p) => p.id === item.id);
                    if (!item.documents && stateParty?.documents) return { ...item, documents: stateParty.documents };
                    return item;
                });
                return { ...state, parties: newParties };
            }
            return state;
        },
        addBusinessPartner(state: businessPartnerStateType, party: businessPartner): businessPartnerStateType {
            if (state.parties) return { ...state, parties: [...state.parties, party] };
            return state;
        },
        editBusinessPartner(state: businessPartnerStateType, party: businessPartner): businessPartnerStateType {
            if (!state.parties) return state;
            let currentBusinessPartners = [...state.parties];
            const businessPartnerIndex = currentBusinessPartners.findIndex((currentBusinessPartner) => currentBusinessPartner.id === party.id);
            currentBusinessPartners[businessPartnerIndex] = { ...currentBusinessPartners[businessPartnerIndex], ...party };
            return { ...state, parties: currentBusinessPartners };
        },
        editBusinessPartnerIsActive(state: businessPartnerStateType, id: string, isActive: boolean): businessPartnerStateType {
            if (!state.parties) return state;
            let currentBusinessPartners = [...state.parties];
            const businessPartnerIndex = currentBusinessPartners.findIndex((currentBusinessPartner) => currentBusinessPartner.id === id);
            currentBusinessPartners[businessPartnerIndex] = { ...currentBusinessPartners[businessPartnerIndex], isActive };
            return { ...state, parties: currentBusinessPartners };
        },
        removeBusinessPartner(state: businessPartnerStateType, businessPartnerId: string): businessPartnerStateType {
            if (!state.parties) return state;
            let currentBusinessPartners = [...state.parties];
            return { ...state, parties: currentBusinessPartners.filter((item) => item.id !== businessPartnerId) };
        },
        setFetchBusinessPartnersError(state: businessPartnerStateType, error: string) {
            return { ...state, errorFetchBusinessPartners: error };
        },
        setSingleBusinessPartner(state: businessPartnerStateType, newBusinessPartner: businessPartner): businessPartnerStateType {
            if (!state.parties) return state;
            const newParties = [...state.parties];
            const businessPartnerIndex = newParties.findIndex((businessPartner) => businessPartner.id === newBusinessPartner.id);
            if (businessPartnerIndex !== -1) {
                newParties[businessPartnerIndex] = newBusinessPartner;
            } else {
                newParties.push(newBusinessPartner);
            }
            return { ...state, parties: newParties };
        },
        setBusinessPartnerDocuments(
            state: businessPartnerStateType,
            businessPartnerId: string,
            businessPartnerDocuments: documents
        ): businessPartnerStateType {
            if (!state.parties) return state;
            const newBusinessPartners = [...state.parties];
            const businessPartnerIndex = newBusinessPartners.findIndex((businessPartner) => businessPartner.id === businessPartnerId);
            if (businessPartnerIndex !== -1) {
                if (!newBusinessPartners[businessPartnerIndex].documents)
                    newBusinessPartners[businessPartnerIndex].documents = { ...businessPartnerDocuments };
            }
            return { ...state, parties: newBusinessPartners };
        },
        addBusinessPartnerDocument(state: businessPartnerStateType, businessPartnerId: string, document: document): businessPartnerStateType {
            if (!state.parties) return state;
            const newBusinessPartners = [...state.parties];
            const businessPartnerIndex = newBusinessPartners.findIndex((businessPartner) => businessPartner.id === businessPartnerId);
            if (businessPartnerIndex !== -1) {
                let documents = [document];
                const oldDocuments = newBusinessPartners[businessPartnerIndex].documents?.documents;
                if (oldDocuments && oldDocuments.length > 0) documents = [...oldDocuments, ...documents];
                newBusinessPartners[businessPartnerIndex].documents = { ...newBusinessPartners[businessPartnerIndex].documents, documents };
            }
            return { ...state, parties: newBusinessPartners };
        },
        editBusinessPartnerDocument(state: businessPartnerStateType, businessPartnerId: string, document: document): businessPartnerStateType {
            if (!state.parties) return state;
            const newBusinessPartners = [...state.parties];
            const businessPartnerIndex = newBusinessPartners.findIndex((businessPartner) => businessPartner.id === businessPartnerId);
            if (businessPartnerIndex !== -1) {
                const documents = newBusinessPartners[businessPartnerIndex].documents?.documents.map((item) => {
                    if (item.id === document.id) return { ...document };
                    else return { ...item };
                });
                if (documents)
                    newBusinessPartners[businessPartnerIndex].documents = { ...newBusinessPartners[businessPartnerIndex].documents, documents };
            }
            return { ...state, parties: newBusinessPartners };
        },
        removeBusinessPartnerDocument(state: businessPartnerStateType, businessPartnerId: string, documentId: number): businessPartnerStateType {
            if (!state.parties) return state;
            const newBusinessPartners = [...state.parties];
            const businessPartnerIndex = newBusinessPartners.findIndex((businessPartner) => businessPartner.id === businessPartnerId);
            if (businessPartnerIndex !== -1) {
                const oldDocuments = newBusinessPartners[businessPartnerIndex].documents?.documents.filter((item) => item.id !== documentId);
                if (oldDocuments)
                    newBusinessPartners[businessPartnerIndex].documents = {
                        ...newBusinessPartners[businessPartnerIndex].documents,
                        documents: oldDocuments
                    };
            }
            return { ...state, parties: newBusinessPartners };
        },
        setBusinessPartnerContacts(
            state: businessPartnerStateType,
            businessPartnerId: string,
            businessPartnerContacts: Array<contact>
        ): businessPartnerStateType {
            if (!state.parties) return state;
            const newBusinessPartners = [...state.parties];
            const businessPartnerIndex = newBusinessPartners.findIndex((businessPartner) => businessPartner.id === businessPartnerId);
            if (businessPartnerIndex !== -1) {
                newBusinessPartners[businessPartnerIndex].contacts = businessPartnerContacts;
            }
            return { ...state, parties: newBusinessPartners };
        },
        addBusinessPartnerContact(state: businessPartnerStateType, businessPartnerId: string, contact: contact): businessPartnerStateType {
            if (!state.parties) return state;
            const newBusinessPartners = [...state.parties];
            const businessPartnerIndex = newBusinessPartners.findIndex((businessPartner) => businessPartner.id === businessPartnerId);
            if (businessPartnerIndex !== -1) {
                let contacts = [contact];
                const oldContacts = newBusinessPartners[businessPartnerIndex].contacts;
                if (oldContacts && oldContacts.length > 0) contacts = [...oldContacts, ...contacts];
                newBusinessPartners[businessPartnerIndex].contacts = contacts;
            }
            return { ...state, parties: newBusinessPartners };
        },
        editBusinessPartnerContact(state: businessPartnerStateType, businessPartnerId: string, contact: contact): businessPartnerStateType {
            if (!state.parties) return state;
            const newBusinessPartners = [...state.parties];
            const businessPartnerIndex = newBusinessPartners.findIndex((businessPartner) => businessPartner.id === businessPartnerId);
            if (businessPartnerIndex !== -1) {
                const contacts = newBusinessPartners[businessPartnerIndex].contacts?.map((item) => {
                    if (item.id === contact.id) return { ...contact };
                    else return { ...item };
                });
                newBusinessPartners[businessPartnerIndex].contacts = contacts;
            }
            return { ...state, parties: newBusinessPartners };
        },
        removeBusinessPartnerContact(state: businessPartnerStateType, businessPartnerId: string, contactId: number): businessPartnerStateType {
            if (!state.parties) return state;
            const newBusinessPartners = [...state.parties];
            const businessPartnerIndex = newBusinessPartners.findIndex((businessPartner) => businessPartner.id === businessPartnerId);
            if (businessPartnerIndex !== -1) {
                const oldContacts = newBusinessPartners[businessPartnerIndex].contacts?.filter((item) => item.id !== contactId);
                newBusinessPartners[businessPartnerIndex].contacts = oldContacts;
            }
            return { ...state, parties: newBusinessPartners };
        },
        setBusinessPartnerAddresses(
            state: businessPartnerStateType,
            businessPartnerId: string,
            businessPartnerAddresses: Array<address>
        ): businessPartnerStateType {
            if (!state.parties) return state;
            const newBusinessPartners = [...state.parties];
            const businessPartnerIndex = newBusinessPartners.findIndex((businessPartner) => businessPartner.id === businessPartnerId);
            if (businessPartnerIndex !== -1) {
                newBusinessPartners[businessPartnerIndex].addresses = businessPartnerAddresses;
            }
            return { ...state, parties: newBusinessPartners };
        },
        addBusinessPartnerAddress(state: businessPartnerStateType, businessPartnerId: string, address: address): businessPartnerStateType {
            if (!state.parties) return state;
            const newBusinessPartners = [...state.parties];
            const businessPartnerIndex = newBusinessPartners.findIndex((businessPartner) => businessPartner.id === businessPartnerId);
            if (businessPartnerIndex !== -1) {
                let addresses = [address];
                const oldAddresses = newBusinessPartners[businessPartnerIndex].addresses;
                if (oldAddresses && oldAddresses.length > 0) addresses = [...oldAddresses, ...addresses];
                newBusinessPartners[businessPartnerIndex].addresses = addresses;
            }
            return { ...state, parties: newBusinessPartners };
        },
        editBusinessPartnerAddress(state: businessPartnerStateType, businessPartnerId: string, address: address): businessPartnerStateType {
            if (!state.parties) return state;
            const newBusinessPartners = [...state.parties];
            const businessPartnerIndex = newBusinessPartners.findIndex((businessPartner) => businessPartner.id === businessPartnerId);
            if (businessPartnerIndex !== -1) {
                const addresses = newBusinessPartners[businessPartnerIndex].addresses?.map((item) => {
                    if (item.id === address.id) return { ...address };
                    else return { ...item };
                });
                newBusinessPartners[businessPartnerIndex].addresses = addresses;
            }
            return { ...state, parties: newBusinessPartners };
        },
        removeBusinessPartnerAddress(state: businessPartnerStateType, businessPartnerId: string, addressId: number): businessPartnerStateType {
            if (!state.parties) return state;
            const newBusinessPartners = [...state.parties];
            const businessPartnerIndex = newBusinessPartners.findIndex((businessPartner) => businessPartner.id === businessPartnerId);
            if (businessPartnerIndex !== -1) {
                const oldAddresses = newBusinessPartners[businessPartnerIndex].addresses?.filter((item) => item.id !== addressId);
                newBusinessPartners[businessPartnerIndex].addresses = oldAddresses;
            }
            return { ...state, parties: newBusinessPartners };
        },
        setBusinessPartnerLogo(state: businessPartnerStateType, businessPartnerId: string, logoUrl: string): businessPartnerStateType {
            if (!state.parties.length) return state;
            const newBusinessPartners = [...state.parties];
            const businessPartnerIndex = newBusinessPartners.findIndex((businessPartner) => businessPartner.id === businessPartnerId);
            if (businessPartnerIndex !== -1) {
                const newBusinessPartner = { ...newBusinessPartners[businessPartnerIndex], logoUrl };
                newBusinessPartners[businessPartnerIndex] = newBusinessPartner;
            }
            return { ...state, parties: newBusinessPartners };
        },
        addBusinessPartnerDiscussion(state: businessPartnerStateType, businessPartnerId: string, discussion: discussion): businessPartnerStateType {
            return { ...state, parties: addDiscussion(state, businessPartnerId, 'parties', discussion) };
        },
        editBusinessPartnerDiscussion(state: businessPartnerStateType, businessPartnerId: string, discussion: discussion): businessPartnerStateType {
            return { ...state, parties: editDiscussion(state, businessPartnerId, 'parties', discussion) };
        },
        removeBusinessPartnerDiscussion(state: businessPartnerStateType, businessPartnerId: string, discussionId: string): businessPartnerStateType {
            return { ...state, parties: removeDiscussion(state, businessPartnerId, 'parties', discussionId) };
        }
    },
    effects: (dispatch: any) => ({
        async fetchBusinessPartners() {
            const parties = await client.businessPartners().fetchBusinessPartners();
            dispatch.businessPartners.setBusinessPartners(parties);
            dispatch.allBusinessPartnersPage.setCurrentPage(0);
        },
        async uploadBusinessPartnersByExcel({ file, businessPartnerId }: { file: File; businessPartnerId?: string }) {
            await client.businessPartners().uploadBusinessPartnersByExcel(file, businessPartnerId);
            dispatch.businessPartners.fetchBusinessPartners();
            return true;
        },
        async createBusinessPartner(party: businessPartner) {
            const newParty = await client.businessPartners().createBusinessPartner(party);
            dispatch.businessPartners.addBusinessPartner(newParty);
            return newParty;
        },
        async updateBusinessPartner({ businessPartnerId, party }: { businessPartnerId: string; party: businessPartner }) {
            const updated = await client.businessPartners().updateBusinessPartner(businessPartnerId, party);
            dispatch.businessPartners.editBusinessPartner({ ...party, id: businessPartnerId });
            return updated;
        },
        async updateBusinessPartnerIsActive({ businessPartnerId, isActive }: { businessPartnerId: string; isActive: boolean }) {
            await client.businessPartners().updateBusinessPartnerIsActive(businessPartnerId, isActive);
            dispatch.businessPartners.editBusinessPartnerIsActive(businessPartnerId, isActive);
        },
        async fetchBusinessPartnerById(businessPartnerId: number) {
            const businessPartner = await client.businessPartners().fetchById(businessPartnerId);
            dispatch.businessPartners.setSingleBusinessPartner(businessPartner);
        },
        async createMultipleBusinessPartnerDocument({ businessPartnerId, documents }: { businessPartnerId: string; documents: Array<document> }) {
            dispatch.loadingNotify.setLoadingState({ visible: true, text: 'Uploading files...' });
            const createdDocuments = await client.businessPartners().documents().createMultipleBusinessPartnerDocument(businessPartnerId, documents);
            createdDocuments.forEach((document: document) => {
                dispatch.businessPartners.addBusinessPartnerDocument(businessPartnerId, document);
            });
            dispatch.loadingNotify.setLoadingState({ visible: false });
        },
        async createBusinessPartnerDocument({ businessPartnerId, document }: { businessPartnerId: string; document: document }) {
            const createdDocument = await client.businessPartners().documents().createBusinessPartnerDocument(businessPartnerId, document);
            dispatch.businessPartners.addBusinessPartnerDocument(businessPartnerId, createdDocument);
        },
        async updateBusinessPartnerDocument({ businessPartnerId, document }: { businessPartnerId: string; document: document }) {
            await client.businessPartners().documents().updateBusinessPartnerDocument(businessPartnerId, document);
            dispatch.businessPartners.editBusinessPartnerDocument(businessPartnerId, document);
        },
        async deleteBusinessPartnerDocument({ businessPartnerId, documentId }: { businessPartnerId: string; documentId: number }) {
            await client.businessPartners().documents().deleteBusinessPartnerDocument(businessPartnerId, documentId);
            dispatch.businessPartners.removeBusinessPartnerDocument(businessPartnerId, documentId);
        },
        async createBusinessPartnerContact({ businessPartnerId, contact }: { businessPartnerId: string; contact: contact }) {
            const createdContact = await client.businessPartners().contacts().createBusinessPartnerContact(businessPartnerId, contact);
            dispatch.businessPartners.addBusinessPartnerContact(businessPartnerId, createdContact);
            return createdContact;
        },
        async updateBusinessPartnerContact({ businessPartnerId, contact }: { businessPartnerId: string; contact: contact }) {
            const updated = await client.businessPartners().contacts().updateBusinessPartnerContact(businessPartnerId, contact);
            dispatch.businessPartners.editBusinessPartnerContact(businessPartnerId, contact);
            return updated;
        },
        async deleteBusinessPartnerContact({ businessPartnerId, contactId }: { businessPartnerId: string; contactId: number }) {
            await client.businessPartners().contacts().deleteBusinessPartnerContact(businessPartnerId, contactId);
            dispatch.businessPartners.removeBusinessPartnerContact(businessPartnerId, contactId);
        },
        async createBusinessPartnerAddress({ businessPartnerId, address }: { businessPartnerId: string; address: address }) {
            const createdAddress = await client.businessPartners().addresses().createBusinessPartnerAddress(businessPartnerId, address);
            dispatch.businessPartners.addBusinessPartnerAddress(businessPartnerId, createdAddress);
        },
        async updateBusinessPartnerAddress({ businessPartnerId, address }: { businessPartnerId: string; address: address }) {
            const updatedAddress = await client.businessPartners().addresses().updateBusinessPartnerAddress(businessPartnerId, address);
            dispatch.businessPartners.editBusinessPartnerAddress(businessPartnerId, updatedAddress);
        },
        async deleteBusinessPartnerAddress({ businessPartnerId, addressId }: { businessPartnerId: string; addressId: number }) {
            await client.businessPartners().addresses().deleteBusinessPartnerAddress(businessPartnerId, addressId);
            dispatch.businessPartners.removeBusinessPartnerAddress(businessPartnerId, addressId);
        },

        async createBusinessPartnerDiscussion({
            businessPartnerId,
            discussion,
            businessPartnerNumber
        }: {
            businessPartnerId: string;
            discussion: discussion;
            businessPartnerNumber?: string;
        }) {
            const createdDiscussion = await client
                .businessPartners()
                .discussions()
                .createBusinessPartnerDiscussion(businessPartnerId, discussion, businessPartnerNumber);
            dispatch.businessPartners.addBusinessPartnerDiscussion(businessPartnerId, createdDiscussion);
            dispatch.businessPartners.fetchBusinessPartnerHistoryById(businessPartnerId);
        },
        async updateBusinessPartnerDiscussion({
            businessPartnerId,
            discussion,
            businessPartnerNumber
        }: {
            businessPartnerId: string;
            discussion: discussion;
            businessPartnerNumber?: string;
        }) {
            await client.businessPartners().discussions().updateBusinessPartnerDiscussion(businessPartnerId, discussion, businessPartnerNumber);
            dispatch.businessPartners.editBusinessPartnerDiscussion(businessPartnerId, discussion);
            dispatch.businessPartners.fetchBusinessPartnerHistoryById(businessPartnerId);
        },
        async deleteBusinessPartnerDiscussion({ businessPartnerId, discussionId }: { businessPartnerId: string; discussionId: number }) {
            await client.businessPartners().discussions().deleteBusinessPartnerDiscussion(businessPartnerId, discussionId);
            dispatch.businessPartners.removeBusinessPartnerDiscussion(businessPartnerId, discussionId);
            dispatch.businessPartners.fetchBusinessPartnerHistoryById(businessPartnerId);
        },
        async updateBusinessPartnerLogo({ businessPartnerId, file }: { businessPartnerId: string; file: RcFile }) {
            const logoUrl = await client.businessPartners().updateBusinessPartnerLogo(businessPartnerId, file);
            if (logoUrl) dispatch.businessPartners.setBusinessPartnerLogo(businessPartnerId, logoUrl);
        },
        async sendGetStartedInventationEmail({ businessPartnerId, email }: { businessPartnerId: string; email: string }) {
            await client.businessPartners().sendGetStartedInventationEmail(businessPartnerId, email);
        }
    })
};
