export const PagesRoutes = {
    Login: '/login',
    Home: '/',
    Calendar: '/calendar',
    Shipments: '/shipments',
    ShipmentsBoard: '/shipmentBoard',
    EditShipment: '/shipment/edit',
    DuplicateShipment: '/shipment/duplicate',
    NewShipment: '/shipment/new',
    BusinessPartners: '/businessPartners',
    EditCompany: '/company/edit',
    NewCompany: '/company/new',
    Notifications: '/notifications',
    EditProfile: '/edit_profile',
    UserSettings: '/user_settings',
    Error: '/NotFound',
    PurchaseOrders: '/purchaseOrders',
    EditPurchaseOrder: '/purchaseOrder/edit',
    DuplicatePurchaseOrder: '/purchaseOrder/duplicate',
    NewPurchaseOrder: '/purchaseOrder/new',
    PurchaseDemands: '/purchaseDemands',
    Bids: '/bids',
    EditBid: '/bid/edit',
    DuplicateBid: '/bid/duplicate',
    NewBid: '/bid/new',
    QuotationReply: '/quotationReply',
    Quotation: '/quotation',
    Messages: '/messages',
    Contacts: '/messages/contacts',
    Containers: '/containers',
    UsersMgmt: '/admin/users',
    CompaniesMgmt: '/admin/companies',
    CompaniesRelations: '/admin/partnersRelations',
    DeletedRecords: '/admin/trash',
    Catalog: '/catalog',
    Products: '/catalog/products',
    Inventory: '/catalog/inventory',
    InternalFlowInventoryLogs: '/catalog/internalFlowInventoryLogs',
    GetStarted: '/getStarted',
    OrderConfirmation: '/orderConfirmation',
    PoSupplierUpdates: '/poSupplierUpdates',
    Orders: '/orders',
    OrdersBoard: '/orderBoard',
    Tariffs: '/tariffs',
    NewTariff: '/tariff/new',
    NewOrder: '/order/new',
    EditOrder: '/order/edit'
};
