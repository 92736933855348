import React from 'react';
import { connect } from 'react-redux';
import { refCompanyType } from '../../../../../models/entities/common_subentities/reference';
import { purchaseOrder } from '../../../../../models/entities/purchaseOrder/purchaseOrder';
import { purchaseOrderReference } from '../../../../../models/entities/purchaseOrder/purchaseOrderReference';
import { localizationSelectors } from '../../../../../state/ducks/localization/selectors';
import { singlePurchaseOrderSelectors } from '../../../../../state/ducks/singlePurchaseOrder/selectors';
import { RootState } from '../../../../../state/store/store';
import CustomModal, { ModalTriggerProps } from '../../../../shared/Modal/Modal';
import { styled } from '../../../../shared/Theme/theme';
import ReferencesItems from '../../../commonSubEntities/references/ReferencesItems';

type Props = {
    purchaseOrderId: string;
    references?: Array<purchaseOrderReference>;
    type: refCompanyType;
    localization: any;
    printMode?: boolean;
    createPurchaseOrderReference: (purchaseOrderNumber: string, reference: purchaseOrderReference) => Promise<void>;
    updatePurchaseOrderReference: (purchaseOrderNumber: string, reference: purchaseOrderReference) => Promise<void>;
    deletePurchaseOrderReference: (purchaseOrderNumber: string, referenceId: string) => Promise<void>;
    showLoader?: boolean;
};

const PurchaseOrderReferencesItems = ({
    purchaseOrderId,
    type,
    localization,
    references,
    printMode,
    createPurchaseOrderReference,
    updatePurchaseOrderReference,
    deletePurchaseOrderReference,
    showLoader
}: Props) => {
    return (
        <ReferencesItems
            entityId={purchaseOrderId}
            references={references}
            type={type}
            localization={localization}
            printMode={printMode}
            createReference={createPurchaseOrderReference}
            updateReference={updatePurchaseOrderReference}
            deleteReference={deletePurchaseOrderReference}
            showLoader={showLoader}
        />
    );
};

const mapStateToProps = (state: RootState) => ({
    showLoader:
        state.loading.effects.purchaseOrders.createPurchaseOrderReference ||
        state.loading.effects.purchaseOrders.updatePurchaseOrderReference ||
        state.loading.effects.purchaseOrders.deletePurchaseOrderReference,
    references: singlePurchaseOrderSelectors.purchaseOrder(state)?.references,
    localization: localizationSelectors.singlePurchaseOrderPage(state).references
});

const mapDispatchToProps = (dispatch: any) => ({
    createPurchaseOrderReference: (purchaseOrderNumber: string, reference: purchaseOrderReference) =>
        dispatch.purchaseOrders.createPurchaseOrderReference({ purchaseOrderNumber, reference }),
    updatePurchaseOrderReference: (purchaseOrderNumber: string, reference: purchaseOrderReference) =>
        dispatch.purchaseOrders.updatePurchaseOrderReference({ purchaseOrderNumber, reference }),
    deletePurchaseOrderReference: (purchaseOrderNumber: string, referenceId: string) =>
        dispatch.purchaseOrders.deletePurchaseOrderReference({ purchaseOrderNumber, referenceId })
});

export default connect(mapStateToProps, mapDispatchToProps)(PurchaseOrderReferencesItems);
