import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import claimsType from '../../../../models/entities/claimsType';
import { bid } from '../../../../models/entities/bid/bid';
import { shipment, shipmentSettingsFunc } from '../../../../models/entities/shipment/shipment';
import { userSettings } from '../../../../models/entities/user';
import { filterFieldsType } from '../../../../state/ducks/allShipmentsPage/allShipmentsPage';
import { allShipmentsPageSelectors } from '../../../../state/ducks/allShipmentsPage/selectors';
import { drawers } from '../../../../state/ducks/header/header';
import { headerSelectors } from '../../../../state/ducks/header/selectors';
import { localizationSelectors } from '../../../../state/ducks/localization/selectors';
import { shipmentsSelectors } from '../../../../state/ducks/shipments/selectors';
import { singleShipmentSelectors } from '../../../../state/ducks/singleShipment/selectors';
import { userSelectors } from '../../../../state/ducks/user/selectors';
import { RootState } from '../../../../state/store/store';
import { BoardColumnMap } from '../../../shared/Board/types/BoardColumn';
import { ColumnType } from '../../../shared/Grid/types/Column';
import { filterColsByUserSettings } from '../../../shared/Grid/util';
import Loader from '../../../shared/SmallComponents/Loader';
import ShipmentsBoardPage from './ShipmentsBoardPage';
import { boardCalcDataLength } from './utils';
import { claimsDomainsType } from '../../../../userClaims';

type Props = {
    shipmentsBoardData: BoardColumnMap<shipment>;
    gridColumns: Array<ColumnType<shipment>>;
    fetchShipments: () => void;
    fetchGridColumns: () => void;
    updateShipmentState: (shipmentId: string, shipmentState: string, forwarderId?: string) => Promise<void>;
    showLoader: boolean;
    isSideBarOpen: boolean;
    setSideBarOpen: (open: boolean) => void;
    setDrawerOpen: (open: drawers, params?: any) => void;
    settings?: shipmentSettingsFunc;
    shipmentsGridSettings?: userSettings;
    lastVisitShipmentId?: string;
    filterFields: filterFieldsType | null;
    resetShipmentFilter: () => void;
    getUserClaims: (domain: claimsDomainsType) => claimsType;
    fetchShipmentsByFilter: (filter: filterFieldsType) => Array<shipment>;
    localization: any;
    createBid: (shipment: shipment, bid: bid, publish: boolean) => Promise<string>;
    updateShipmentForwarder: (id: string, forwarderId: string | null) => Promise<boolean>;
    isFreelancerOrForwarder?: boolean;
};

const ShipmentsBoardPageConnected = ({
    shipmentsBoardData,
    updateShipmentState,
    fetchShipments,
    showLoader,
    isSideBarOpen,
    fetchGridColumns,
    setSideBarOpen,
    setDrawerOpen,
    settings,
    gridColumns,
    shipmentsGridSettings,
    lastVisitShipmentId,
    filterFields,
    resetShipmentFilter,
    fetchShipmentsByFilter,
    localization,
    getUserClaims,
    createBid,
    updateShipmentForwarder,
    isFreelancerOrForwarder
}: Props) => {
    useEffect(() => {
        if (!filterFields?.find((f: { field: string; value: string }) => f.field === 'isActive')) {
            fetchShipmentsByFilter([]);
        }
        setDrawerOpen(null);
        setSideBarOpen(false);
        fetchGridColumns();
    }, [fetchShipments, fetchGridColumns, setSideBarOpen, setDrawerOpen]);

    useEffect(() => {
        const activeFilter = filterFields?.find((f: { field: string; value: string }) => f.field === 'isActive');
        if (activeFilter) {
            fetchShipmentsByFilter([activeFilter]);
        }
    }, [filterFields]);
    return showLoader ? (
        <Loader showText={true} />
    ) : (
        <ShipmentsBoardPage
            fetchShipments={fetchShipments}
            gridColumns={filterColsByUserSettings(gridColumns, shipmentsGridSettings)}
            shipmentsBoardData={shipmentsBoardData}
            updateShipmentState={updateShipmentState}
            settings={settings}
            getUserClaims={getUserClaims}
            setDrawerOpen={setDrawerOpen}
            lastVisitShipmentId={lastVisitShipmentId}
            resetFilterFunc={
                filterFields && filterFields.length > 0 && boardCalcDataLength(shipmentsBoardData) === 0 ? resetShipmentFilter : undefined
            }
            localization={localization}
            createBid={createBid}
            updateShipmentForwarder={updateShipmentForwarder}
            isFreelancerOrForwarder={isFreelancerOrForwarder}
        />
    );
};

const mapStateToProps = (state: RootState) => ({
    shipmentsBoardData: allShipmentsPageSelectors.shipmentsBoardData(state),
    settings: shipmentsSelectors.settingsByLanguage(state),
    isSideBarOpen: headerSelectors.sideBarOpen(state),
    showLoader:
        state.loading.effects.allShipmentsPage.fetchGridColumns ||
        state.loading.effects.shipments.fetchShipments ||
        state.loading.effects.allShipmentsPage.fetchShipmentsByFilter,
    gridColumns: allShipmentsPageSelectors.gridColumns(state),
    shipmentsGridSettings: userSelectors.shipmentsGridSettings(state),
    lastVisitShipmentId: singleShipmentSelectors.currentShipmentId(state) || undefined,
    filterFields: allShipmentsPageSelectors.filterFields(state),
    localization: localizationSelectors.singleShipmentPage(state).board,
    getUserClaims: (domain: claimsDomainsType) => userSelectors.getUserClaims(state)(domain),
    isFreelancerOrForwarder: userSelectors.isFreelancerOrForwarder(state)
});

const mapDispatchToProps = (dispatch: any) => ({
    fetchShipments: () => dispatch.shipments.fetchShipments(),
    fetchGridColumns: () => dispatch.allShipmentsPage.fetchGridColumns(),
    updateShipmentState: (shipmentId: string, shipmentState: string, forwarderId?: string) =>
        dispatch.shipments.updateShipmentState({ shipmentId, shipmentState, forwarderId }),
    setSideBarOpen: (open: boolean) => dispatch.header.setSideBarOpen(open),
    setDrawerOpen: (open: drawers, params?: any) => dispatch.header.setDrawerOpenParams({ open, params }),
    resetShipmentFilter: () => dispatch.allShipmentsPage.setShipmentFilters({ filter: [] }),
    fetchShipmentsByFilter: (filter: filterFieldsType) => dispatch.allShipmentsPage.fetchShipmentsByFilter(filter),
    createBid: (shipment: shipment, bid: bid, publish: boolean) => dispatch.bids.createBid({ shipment, bid, publish }),
    updateShipmentForwarder: (id: string, forwarderId: string | null) => dispatch.shipments.updateShipmentForwarder({ id, forwarderId })
});

export default connect(mapStateToProps, mapDispatchToProps)(ShipmentsBoardPageConnected);
