import * as Yup from 'yup';
import moment from 'moment';
import { AnyObject } from 'yup/lib/types';

export const tariffFormValidationSchema = (localization: any) => {
    const requiredFieldMessage = localization.errors.required;

    const yupStringRequired = Yup.string().required(requiredFieldMessage);
    return Yup.object({
        companyId: yupStringRequired,
        shipmentTypeCode: yupStringRequired,
        shipmentSubTypeCode: Yup.string().when('shipmentTypeCode', {
            is: (value: string) => ['OI', 'OEX', 'OE', 'DROP_OCEAN', 'INLAND'].includes(value),
            then: Yup.string().required(requiredFieldMessage).nullable(),
            otherwise: Yup.string().nullable()
        }),
        incoterms: Yup.array().min(1, requiredFieldMessage).required(requiredFieldMessage),
        originCountry: yupStringRequired,
        originStation: yupStringRequired,
        destinationCountry: yupStringRequired,
        destinationStation: yupStringRequired,
        activationDate: yupStringRequired.nullable(),
        expirationDate: yupStringRequired.nullable()
    });
};

const maxLengthValidation = (maxLength: number) => {
    return (val: any) => (val ? val.toString().length <= maxLength : true);
};
