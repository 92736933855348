import React from 'react';
import edit from '../../../../static/icons/edit.svg';
import trash from '../../../../static/icons/trash.svg';
import CellTrigger from '../../Menu/MenuTriggers/CellTrigger';
import { RootState } from '../../../../state/store/store';
import { connect } from 'react-redux';
import Menu from '../../Menu/Menu';
import MenuItem from '../../Menu/MenuItem';
import { productsSelectors } from '../../../../state/ducks/products/selectors';
type stopRelationType = (firstProductId: string, secondProductId: string) => void;
type Props = {
    entityId: string;
    localization: any;
    stopRelation: stopRelationType;
    currentProductId: string | null;
};

const RelatedProductMenu = ({ entityId, localization, stopRelation, currentProductId }: Props) => {
    const menuItems = [
        <MenuItem onClick={() => currentProductId && stopRelation(currentProductId, entityId)} icon={trash} text={localization.delete} />
    ];

    return <Menu options={menuItems} MenuTrigger={CellTrigger} strStyle="display: flex;align-items: center;width: 100%;height: 100%;" />;
};

const mapStateToProps = (state: RootState) => ({
    currentProductId: productsSelectors.currentProductId(state)
});

const mapDispatchToProps = (dispatch: any) => ({
    stopRelation: (firstProductId: string, secondProductId: string) => dispatch.products.stopRelation({ firstProductId, secondProductId })
});

export default connect(mapStateToProps, mapDispatchToProps)(RelatedProductMenu);
