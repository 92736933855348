import React from 'react';
import { document } from '../../../../../models/entities/common_subentities/document';
import DocumentFormConnected from '../../DocumentForm/DocumentFormConnected';
import { settings } from '../../../../../models/entities/purchaseOrder/purchaseOrder';
import MultipleDocumentFormConnected from '../../DocumentForm/MultipleDocumentFormConnected';
import Documents from '../../../commonSubEntities/documents/Documents';
import { getUrlFuncType } from '../../../../shared/FilesPreview/Preview';

type Props = {
    documents?: Array<document>;
    requiredDocs?: Array<string>;
    localization: any;
    settings?: settings;
    supplierName?: string;
    getFileUrl?: getUrlFuncType;
};

const PurchaseOrderDocuments = ({ documents, localization, settings, requiredDocs, supplierName, getFileUrl }: Props) => {
    return (
        <Documents<settings>
            documents={documents}
            localization={localization}
            settings={settings}
            requiredDocs={requiredDocs}
            menuType={supplierName ? 'purchaseOrderDocumentMenuSupplierUpdates' : 'purchaseOrderDocumentMenu'}
            MultipleDocumentFormConnected={MultipleDocumentFormConnected}
            DocumentFormConnected={DocumentFormConnected}
            additionalData={{ supplierName }}
            getFileUrl={getFileUrl}
        />
    );
};

export default PurchaseOrderDocuments;
