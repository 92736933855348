import React from 'react';
import Menu, { TriggerProps } from '../../Menu/Menu';
import MenuItem from '../../Menu/MenuItem';
import shareIcon from '../../../../static/icons/share.svg';
import followIcon from '../../../../static/icons/follow.svg';
import trashIcon from '../../../../static/icons/trash.svg';
import exportIcon from '../../../../static/icons/download.svg';
import pdfIcon from '../../../../static/icons/documentsType/pdf.svg';
import excelIcon from '../../../../static/icons/documentsType/excel.svg';
import CellTrigger from '../../Menu/MenuTriggers/CellTrigger';
import { connect } from 'react-redux';
import { RootState } from '../../../../state/store/store';
import { exportTypes } from '../../../../state/ducks/allPurchaseDemandsPage/allPurchaseDemandsPage';
import { localizationSelectors } from '../../../../state/ducks/localization/selectors';
import { userSelectors } from '../../../../state/ducks/user/selectors';
import claimsType from '../../../../models/entities/claimsType';

type exportDataRowProps = (purchaseDemandId: string, type: exportTypes) => void;
type Props = {
    entityId: string;
    localization: any;
    exportDataRow: exportDataRowProps;
    trigger?: (props: TriggerProps) => JSX.Element;
    deletePurchaseDemand: (purchaseDemandId: string) => Promise<any>;
    onDelete?: () => void;
    pdUserClaims: claimsType;
};
type MenuItemsProps = Props & {};

const menuItems = ({ localization, entityId, exportDataRow, deletePurchaseDemand, onDelete, pdUserClaims }: MenuItemsProps) => [
    <Menu
        position="left-center"
        options={[
            <MenuItem
                onClick={() => {
                    exportDataRow(entityId, 'PDF');
                }}
                icon={pdfIcon}
                text={localization.pdf}
            />,
            <MenuItem
                onClick={() => {
                    exportDataRow(entityId, 'EXCEL');
                }}
                icon={excelIcon}
                text={localization.excel}
            />
        ]}
        MenuTrigger={(props) => (
            <MenuItem
                onClick={(e) => {
                    props.onClick(e);
                }}
                icon={exportIcon}
                text={localization.export}
            />
        )}
    />,
    ...(pdUserClaims.delete_pd
        ? [
              <MenuItem
                  onClick={() => {
                      deletePurchaseDemand(entityId).then(() => {
                          if (onDelete) onDelete();
                      });
                  }}
                  icon={trashIcon}
                  text={localization.delete}
                  requiredConfirm
              />
          ]
        : [])
];

const PurchaseDemandMenu = ({ entityId, localization, exportDataRow, deletePurchaseDemand, trigger, onDelete, pdUserClaims }: Props) => {
    return (
        <Menu
            menuWidth={165}
            options={menuItems({
                localization: localization.grid.menu,
                entityId,
                exportDataRow,
                deletePurchaseDemand,
                onDelete,
                pdUserClaims
            })}
            MenuTrigger={trigger || CellTrigger}
            strStyle="display: flex;align-items: center;height: 100%;"
        />
    );
};

const mapStateToProps = (state: RootState) => ({
    localization: localizationSelectors.allPurchaseDemandsPage(state),
    pdUserClaims: userSelectors.getUserClaims(state)('pd')
});

const mapDispatchToProps = (dispatch: any) => ({
    exportDataRow: (purchaseDemandId: string, type: exportTypes) => dispatch.allPurchaseDemandsPage.exportDataRow({ purchaseDemandId, type }),
    deletePurchaseDemand: async (purchaseDemandId: string) => dispatch.allPurchaseDemandsPage.deletePurchaseDemand(purchaseDemandId)
});

export default connect(mapStateToProps, mapDispatchToProps)(PurchaseDemandMenu);
