import BaseController from '..';
import IDashboard from './IDashboard';
import { ordersStatistics } from './../../../models/entities/order/ordersStatistics';

export default class Dashboard extends BaseController implements IDashboard {
    async fetchShipmentsStatistics() {
        const response = await this.client.get('/shipments/statistics');
        return response;
    }

    async fetchOrdersStatistics(): Promise<ordersStatistics> {
        const response = await this.client.get('/orders/statistics');
        return response;
    }
}
