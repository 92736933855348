import React from 'react';
import { category } from '../../../../../models/entities/category/category';
import CustomModal, { ModalTriggerProps } from '../../../../shared/Modal/Modal';
import { styled } from '../../../../shared/Theme/theme';
import CategoryFormConnected, { formMode } from './CategoryFormConnected';

type Props = {
    companyId?: string;
    category?: category;
    modalTitle: string;
    submitCallback?: (category: category) => void;
};
const CategoryFormModal = ({ category, companyId, submitCallback, modalTitle }: Props) => {
    return (
        <CustomModal
            title={{ text: modalTitle }}
            Trigger={(props: ModalTriggerProps) => (
                <AddButton className="material-icons" onClick={props.onClick}>
                    add
                </AddButton>
            )}
            Content={(setVisible?: React.Dispatch<React.SetStateAction<boolean>>, setConfirm?: React.Dispatch<React.SetStateAction<boolean>>) => {
                return (
                    <CategoryFormConnected
                        companyId={companyId}
                        category={category}
                        mode={category ? formMode.EDIT : formMode.NEW}
                        openedFromModal={setVisible ? { setVisible } : undefined}
                        submitCallback={submitCallback}
                    />
                );
            }}
            width="393px"
        />
    );
};
const AddButton = styled.span`
    color: ${(props) => props.theme.colors.primaryBlue};
    cursor: pointer;
    display: flex;
    align-items: center;
`;
export default CategoryFormModal;
