import React, { useState } from 'react';
import { purchaseDemand, settings } from '../../../../../../models/entities/purchaseDemand/purchaseDemand';
import { purchaseDemandApprover } from '../../../../../../models/entities/purchaseDemand/purchaseDemandApprover';
import { userDropDown, userInfo } from '../../../../../../models/entities/user';
import { userDetails } from '../../../../../../state/ducks/users/users';
import { DropDown, UserDropDown } from '../../../../../shared/inputs/base';
import Loader from '../../../../../shared/SmallComponents/Loader';
import renderStateOption from '../../../../../shared/SmallComponents/StateOption';
import { styled } from '../../../../../shared/Theme/theme';
import UserBadge from '../../../../../shared/UserBadge/UserBadge';
import claimsType from '../../../../../../models/entities/claimsType';
import { claimsDomainsType } from '../../../../../../userClaims';

type Props = {
    purchaseDemand: purchaseDemand;
    localization?: any;
    settings?: settings;
    updatePurchaseDemandApproverState: (purchaseDemandId: string, approver: purchaseDemandApprover) => Promise<void>;
    createPurchaseDemandApprover: (purchaseDemandId: string, approver: purchaseDemandApprover) => Promise<void>;
    deletePurchaseDemandApprover: (purchaseDemandId: string, approverId: string) => Promise<void>;
    usersInSameCompany: userDropDown[] | undefined;
    userInfo: userInfo;
    getUserClaims: (domain: claimsDomainsType) => claimsType;
};

const Approvers = ({
    purchaseDemand,
    localization,
    settings,
    updatePurchaseDemandApproverState,
    createPurchaseDemandApprover,
    usersInSameCompany,
    userInfo,
    deletePurchaseDemandApprover,
    getUserClaims
}: Props) => {
    const [approverStateLoader, setApproverStateLoader] = useState<{ [key: string]: boolean }>({});
    const [addUser, setAddUser] = useState<undefined | string | string[]>();
    return (
        <Container>
            <Header>{localization.approvers.header}</Header>
            {purchaseDemand.approvers && purchaseDemand.approvers.length > 0 ? (
                purchaseDemand.approvers.map((approver) => {
                    return (
                        <Approver>
                            <UserContainer>
                                <UserBadge flexRow width={25} userId={approver.userId} />
                            </UserContainer>
                            <VerticalDivider />
                            <DropDown
                                disabled={purchaseDemand.state === 'DRAFT' || userInfo.id !== approver.userId}
                                width="200px"
                                value={approver.state}
                                onChange={(value: string) => {
                                    if (value === 'WAITING') return;
                                    setApproverStateLoader({ ...approverStateLoader, [approver.id]: true });
                                    updatePurchaseDemandApproverState(purchaseDemand.id, {
                                        ...approver,
                                        state: value
                                    } as purchaseDemandApprover).finally(() => {
                                        setApproverStateLoader({ ...approverStateLoader, [approver.id]: false });
                                    });
                                }}
                                options={
                                    settings?.approverState
                                        ?.sort((a, b) => a.displayOrder - b.displayOrder)
                                        .map((item) => ({
                                            text: item.name,
                                            value: item.code,
                                            color: item.color
                                        })) || []
                                }
                                optionRenderer={renderStateOption}
                            />
                            {(purchaseDemand.userId === userInfo.id || getUserClaims('pd').delete_approver) && (
                                <DeleteContainer
                                    onClick={() => {
                                        setApproverStateLoader({ ...approverStateLoader, [approver.id]: true });
                                        deletePurchaseDemandApprover(purchaseDemand.id, approver.id).finally(() => {
                                            setApproverStateLoader({ ...approverStateLoader, [approver.id]: false });
                                        });
                                    }}
                                >
                                    <span className="material-icons">delete</span>
                                </DeleteContainer>
                            )}
                            {approverStateLoader[approver.id] && (
                                <LoaderWrapper>
                                    <Loader marginTop="0px" showText={false} width="20px" />
                                </LoaderWrapper>
                            )}
                        </Approver>
                    );
                })
            ) : (
                <EmptyApproversText>{localization.approvers.empty_approvers}</EmptyApproversText>
            )}
            {getUserClaims('pd').add_approver && (
                <div style={{ width: '170px', display: 'flex' }}>
                    <UserDropDown
                        value={addUser}
                        onChange={(value: any) => {
                            setAddUser(value);
                            createPurchaseDemandApprover(purchaseDemand.id, { userId: value } as purchaseDemandApprover).finally(() => {
                                setAddUser(undefined);
                            });
                        }}
                        placeHolder={localization.approvers.select_user}
                        users={((usersInSameCompany as userDetails[]) || []).filter(
                            (user) => !purchaseDemand.approvers?.map((app) => app.userId)?.includes(user.id)
                        )}
                        showSearch
                    />
                    {addUser && (
                        <LoaderWrapper>
                            <Loader marginTop="0px" showText={false} width="20px" />
                        </LoaderWrapper>
                    )}
                </div>
            )}
        </Container>
    );
};

const Container = styled.div`
    padding: 24px 18px 18px 48px;
    width: 100%;
`;
const Header = styled.div`
    color: ${(props) => props.theme.colors.pageTitle};
    font-size: 20px;
    font-weight: 500;
`;
const Approver = styled.div`
    display: flex;
    margin: 10px 0;
    align-items: center;
`;
const UserContainer = styled.div`
    display: flex;
    max-width: 150px;
    min-width: 150px;
`;
const VerticalDivider = styled.div`
    height: 25px;
    width: 1px;
    background: rgb(0 0 0 / 14%);
    margin: 0 20px;
`;
const EmptyApproversText = styled.div`
    color: ${(props) => props.theme.colors.primaryBlue};
    margin: 10px 0;
`;
const LoaderWrapper = styled.div`
    margin-left: 10px;
`;
const DeleteContainer = styled.div`
    display: flex;
    margin-left: 10px;
    color: ${(props) => props.theme.colors.primaryBlue};
    span.material-icons {
        cursor: pointer;
    }
`;
export default Approvers;
