import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import theme from '../Theme/theme';
import Modal from 'react-modal';
import Button from '../SmallComponents/Button';
import Loader from '../SmallComponents/Loader';
import { SlideUpModal, SlideUpModalMobile } from '../../animations/Animations';
import { Modal as AntModal } from 'antd';
import { RootState } from '../../../state/store/store';
import { localizationSelectors } from '../../../state/ducks/localization/selectors';
import { connect } from 'react-redux';

const modalTheme = theme.colors.modal;
export type ModalTriggerProps = {
    onClick: () => void;
};
const HEADER_HEIGHT = 60;
const FOOTER_HEIGHT = 70;
export type ModalProps = {
    title?: {
        icon?: string;
        text: string;
    };
    confirmBtn?: {
        handler: () => void;
        closeModal?: boolean;
        isLoading?: boolean;
        disabled?: boolean;
        text: string;
    };
    cancelBtn?: {
        handler?: () => void;
        text: string;
    };
    onCancelCallback?: () => void;
    Trigger?: (props: ModalTriggerProps) => JSX.Element;
    Content: (setVisible?: React.Dispatch<React.SetStateAction<boolean>>, setConfirm?: React.Dispatch<React.SetStateAction<boolean>>) => JSX.Element;
    HeaderContent?: (
        setVisible?: React.Dispatch<React.SetStateAction<boolean>>,
        setConfirm?: React.Dispatch<React.SetStateAction<boolean>>
    ) => JSX.Element;
    width: string;
    maxWidth?: string;
    height?: number;
    isLoading?: boolean;
    overFlowY?: string;
    headerPadding?: string;
    contentPadding?: string;
    containerPadding?: string;
    headerHeight?: number;
    headerFontSize?: string;
    footerHeight?: number;
    isOpen: boolean;
    fullScreen?: boolean;
    localization: any;
};

function CustomModal({
    Trigger,
    Content,
    title,
    confirmBtn,
    cancelBtn,
    width,
    maxWidth,
    height,
    isLoading,
    overFlowY,
    isOpen,
    onCancelCallback,
    headerPadding,
    contentPadding,
    containerPadding,
    headerHeight,
    headerFontSize,
    footerHeight,
    fullScreen,
    localization,
    HeaderContent
}: ModalProps) {
    const [visible, setVisible] = useState(isOpen);
    const [confirm, setConfirm] = useState(false);

    useEffect(() => {
        setVisible(isOpen);
    }, [isOpen]);

    const onCancel = () => {
        const cancel = () => {
            setVisible(false);
            if (onCancelCallback) onCancelCallback();
            cancelBtn?.handler?.();
        };

        if (confirm) {
            AntModal.confirm({
                title: localization.confirm_text,
                onOk() {
                    cancel();
                },
                zIndex: 1006
            });
        } else {
            cancel();
        }
    };

    return (
        <>
            {Trigger && (
                <Trigger
                    onClick={() => {
                        setVisible(true);
                    }}
                />
            )}
            <ModalContainer
                width={width}
                maxWidth={maxWidth}
                padding={containerPadding}
                isOpen={visible}
                style={{ overlay: { backgroundColor: 'rgb(0 0 0 / 75%)', zIndex: 1005 } }}
                fullScreen={fullScreen}
            >
                <ModalHeader padding={!title ? '0' : headerPadding} headerHeight={!title ? 0 : headerHeight} headerFontSize={headerFontSize}>
                    <CloseButton onClick={onCancel}>
                        <span className="material-icons">close</span>
                    </CloseButton>
                    {title && (
                        <>
                            {title.icon && <img style={{ marginRight: '5px' }} src={title.icon} alt="icon" />}
                            <span>{title.text}</span>
                            {HeaderContent && <HeaderContentContainer>{HeaderContent(setVisible, setConfirm)}</HeaderContentContainer>}
                        </>
                    )}
                </ModalHeader>

                <ModalContent
                    height={height}
                    overFlowY={overFlowY || 'auto'}
                    padding={contentPadding}
                    headerHeight={headerHeight}
                    footerHeight={footerHeight}
                    fullScreen={fullScreen}
                >
                    {isLoading ? <Loader marginTop="0px" /> : Content(setVisible, setConfirm)}
                </ModalContent>
                {(cancelBtn || confirmBtn) && (
                    <ModalFooter footerHeight={footerHeight}>
                        {cancelBtn && (
                            <Button minWidth="55px" onClick={onCancel} type="button">
                                <span>{cancelBtn.text}</span>
                            </Button>
                        )}
                        {confirmBtn && (
                            <Button
                                minWidth="55px"
                                buttonType="filled"
                                onClick={async () => {
                                    await confirmBtn.handler();

                                    if (confirmBtn.closeModal) {
                                        setVisible(false);
                                    }
                                }}
                                disabled={confirmBtn.disabled}
                            >
                                {confirmBtn.isLoading ? <Loader width="20px" marginTop="0px" showText={false} /> : confirmBtn.text}
                            </Button>
                        )}
                    </ModalFooter>
                )}
            </ModalContainer>
        </>
    );
}
CustomModal.defaultProps = {
    isOpen: false,
    width: 'auto'
};
interface ModalContainerProps {
    width?: string;
    maxWidth?: string;
    height?: number;
    overFlowY?: string;
    padding?: string;
    headerHeight?: number;
    footerHeight?: number;
    fullScreen?: boolean;
}
const ModalContainer = styled(Modal)<ModalContainerProps>`
    max-height: 100%;
    ${(props) => (props.fullScreen ? 'height: 95%;' : '')}
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    background: white;
    min-width: ${(props) => props.width};
    max-width: ${(props) => props.maxWidth ?? 'auto'};
    box-shadow: 0 0 18px 15px #00000047;
    animation: ${SlideUpModal} ease-out 0.3s;
    padding: ${(props) => props.padding ?? '0'};
    border-radius: 12px;

    @media screen and (max-width: 768px) {
        top: 0;
        height: 100%;
        left: 0;
        right: 0;
        width: 100%;
        min-width: 100%;
        transform: inherit;
        animation: ${SlideUpModalMobile} ease-out 0.3s;
    }
`;

interface ModalHeaderProps {
    padding?: string;
    headerHeight?: number;
    headerFontSize?: string;
}
const ModalHeader = styled.div<ModalHeaderProps>`
    color: ${theme.colors.pageTitle};
    height: ${(props) => `${props.headerHeight ?? HEADER_HEIGHT}px`};
    font-size: ${(props) => props.headerFontSize ?? '24px'};
    display: flex;
    align-items: center;
    padding: ${(props) => props.padding ?? '20px 30px 10px 30px'};
    font-weight: bold;
`;
const CloseButton = styled.div`
    position: absolute;
    right: -30px;
    top: -3px;
    display: flex;
    color: white;
    cursor: pointer;
    @media screen and (max-width: 768px) {
        top: 5px;
        right: 5px;
        color: ${modalTheme.headerColor};
    }
`;
const ModalContent = styled.div<ModalContainerProps>`
    padding: ${(props) => props.padding ?? '10px 30px 20px 30px'};

    ${(props) => {
        let css = '';
        if (props.height && !props.fullScreen)
            css += `height: ${props.height - (props.headerHeight ?? HEADER_HEIGHT) - (props.footerHeight ?? FOOTER_HEIGHT)}px;`;
        else if (props.fullScreen) css += 'height: calc(100% - 130px);';
        css += `overflow-y: ${props.overFlowY};`;
        return css;
    }};
`;
interface ModalFooterProps {
    footerHeight?: number;
}
export const ModalFooter = styled.div<ModalFooterProps>`
    display: flex;
    justify-content: flex-end;
    height: ${(props) => `${props.footerHeight ?? FOOTER_HEIGHT}px`};
    padding: 17px 30px;
    border-bottom-left-radius: 12px;
    border-bottom-right-radius: 12px;
    button {
        margin-left: 10px;
        padding: 15px;
    }
    @media screen and (max-width: 768px) {
        position: absolute;
        width: 100%;
        bottom: 0;
        button {
            span {
                display: -webkit-box;
            }
        }
    }
`;
export const ModalFormContainer = styled.div`
    position: relative;
    width: 100%;
    padding: 15px 5px;
    max-height: calc(100vh - 185px);
    overflow: auto;
    padding-bottom: 70px;
`;

export const HeaderContentContainer = styled.div`
    flex: 1;
    display: flex;
    align-items: center;
    justify-content: flex-end;
`;

const mapStateToProps = (state: RootState) => ({
    localization: localizationSelectors.modal(state)
});

const mapDispatchToProps = (dispatch: any) => ({});

export default connect(mapStateToProps, mapDispatchToProps)(CustomModal);
