import React, { useEffect, useState } from 'react';
import { styled } from '../../../shared/Theme/theme';
import FiltersStripe from './Filter/FiltersStripe';
import Button from '../../../shared/SmallComponents/Button';
import FilterPopover from './Filter/FilterPopover';
import { Link } from 'react-router-dom';
import { PagesRoutes } from '../../../../routing/PagesRoutes';
import { connect } from 'react-redux';
import { product } from '../../../../models/entities/product/product';
import { localizationSelectors } from '../../../../state/ducks/localization/selectors';
import { RootState } from '../../../../state/store/store';
import { TextInput } from '../../../shared/inputs/base';
import Loader from '../../../shared/SmallComponents/Loader';
import { ColumnType } from '../../../shared/Grid/types/Column';
import { filterFieldsType, paginationType } from '../../../../state/ducks/products/products';
import { productsSelectors } from '../../../../state/ducks/products/selectors';
import { drawers } from '../../../../state/ducks/header/header';
import { UploadProductsModal } from './UploadProducts';
import Tooltip from '@material-ui/core/Tooltip';
import IconButton from '@material-ui/core/IconButton';

type Props = {
    products: Array<product>;
    activeProductsCount: number;
    setGridFilter: (text: string) => void;
    fetchProducts: () => void;
    productActiveFilter: { field: keyof product; value: any } | undefined;
    fetchProductsByFilter: (filter: filterFieldsType) => Array<product>;
    currentGridFilter: string | null;
    gridColumns: Array<ColumnType<product>>;
    localization: any;
    setDrawerOpen: (open: drawers, params?: any) => void;
    setCurrentPage: (page: number) => void;
    setRowsPerPage: (rowsPerPage: number) => void;
    filteredProductsCount: number;
    pagination: paginationType;
    isLoading?: boolean;
};
const AllProductsHeader = ({
    products,
    setGridFilter,
    fetchProducts,
    fetchProductsByFilter,
    setCurrentPage,
    setRowsPerPage,
    activeProductsCount,
    productActiveFilter,
    currentGridFilter,
    gridColumns,
    pagination,
    filteredProductsCount,
    localization,
    setDrawerOpen,
    isLoading
}: Props) => {
    const [textFilterValue, setTextFilterValue] = useState(currentGridFilter);
    const [isTypingTextFilter, setIsTypingTextFilter] = useState(false);

    const { currentPage, rowsPerPage } = pagination;

    useEffect(() => {
        setIsTypingTextFilter(true);
        const timeout = setTimeout(() => {
            setGridFilter(textFilterValue || '');
            setIsTypingTextFilter(false);
            setCurrentPage(0);
        }, 500);

        return () => clearTimeout(timeout);
    }, [textFilterValue]);

    const productsFrom = currentPage * rowsPerPage + 1;
    const productsTo = (currentPage + 1) * rowsPerPage;

    return (
        <PageHeader>
            <Title>{localization.title}</Title>

            <FiltersStripe localization={localization} />

            <HeaderRightSide>
                <Button
                    width="32px"
                    height="32px"
                    borderRadius="50%"
                    margin="7px"
                    buttonType="hollow"
                    onClick={() => {
                        fetchProductsByFilter(productActiveFilter ? [productActiveFilter] : []);
                    }}
                >
                    <Tooltip title={localization.refresh_btn} aria-label="refresh">
                        <span className="material-icons">refresh</span>
                    </Tooltip>
                </Button>
                <TextInput
                    style={{
                        height: '30px',
                        margin: '4px',
                        width: 'auto'
                    }}
                    allowClear={true}
                    onChange={(value: string) => setTextFilterValue(value)}
                    placeHolder={localization.filterPlaceHolder}
                    value={textFilterValue || ''}
                    suffix={isTypingTextFilter && <Loader wrapperWidth="auto" showText={false} marginTop="0" width="15px" />}
                />
                <FilterPopover localization={localization.filter} />
                <UploadProductsModal localization={localization.upload_form} />
                <NewButtonWrapper>
                    <Button
                        width="32px"
                        height="32px"
                        borderRadius="50%"
                        margin="7px"
                        buttonType="filled"
                        onClick={() => setDrawerOpen('PRODUCT', {})}
                    >
                        <Tooltip title={localization.new_product} aria-label="new-product">
                            <span className="material-icons">add</span>
                        </Tooltip>
                    </Button>
                </NewButtonWrapper>

                {!(isLoading && (activeProductsCount <= 1 || !gridColumns.length)) && (
                    <NavButtonsContainer>
                        {filteredProductsCount > 0
                            ? `${productsFrom} – ${filteredProductsCount <= productsTo ? filteredProductsCount : productsTo}${
                                  products.length <= filteredProductsCount ? ' of ' + filteredProductsCount : ''
                              }`
                            : ''}
                        <IconButton
                            onClick={() => setCurrentPage(currentPage - 1)}
                            disabled={isLoading || productsFrom <= 1}
                            className="material-icons nav-btn"
                        >
                            navigate_before
                        </IconButton>
                        <IconButton
                            onClick={() => setCurrentPage(currentPage + 1)}
                            disabled={isLoading || filteredProductsCount <= productsTo}
                            className="material-icons nav-btn"
                        >
                            navigate_next
                        </IconButton>
                    </NavButtonsContainer>
                )}
            </HeaderRightSide>
        </PageHeader>
    );
};

const PageHeader = styled.div`
    display: flex;
    padding: 20px 12px 0 12px;
    align-items: center;

    @media screen and (max-width: 768px) {
        justify-content: center;
        padding: 20px 12px 8px 8px;
    }

    position: sticky;
    top: 0px;
    left: 0px;
    z-index: 1000;
    background-color: #fff;
`;

const Title = styled.div`
    display: flex;
    color: ${(props) => props.theme.colors.pageTitle};
    font-size: ${(props) => props.theme.fontSizes.pageTitle};
    font-weight: bold;

    @media screen and (max-width: 768px) {
        display: none;
    }
`;

const HeaderRightSide = styled.div`
    display: flex;
    flex-direction: columm;
    width: 100%;
    max-width: fit-content;
    align-items: center;

    @media screen and (max-width: 768px) {
        height: 40px;
        justify-content: center;
    }

    .add-icon {
        display: none;
        @media screen and (max-width: 768px) {
            display: initial;
        }
    }

    .new-label {
        @media screen and (max-width: 768px) {
            display: none;
        }
    }
`;
const NewButtonWrapper = styled.div`
    @media screen and (max-width: 768px) {
        width: auto;
    }
    .add-icon {
        display: none;
        @media screen and (max-width: 768px) {
            display: initial;
        }
    }

    .new-label {
        @media screen and (max-width: 768px) {
            display: none;
        }
    }
`;

const NavButtonsContainer = styled.div`
    display: flex;
    align-items: center;
    margin: 0 15px;
    color: ${(props) => props.theme.colors.primaryBlue};
    font-weight: bold;
    .nav-btn {
        font-size: 1.4rem;
        color: ${(props) => props.theme.colors.primaryBlue};
    }
`;

const mapStateToProps = (state: RootState) => ({
    activeProductsCount: productsSelectors.activeProductsCount(state),
    currentGridFilter: productsSelectors.gridFilter(state),
    gridColumns: productsSelectors.gridColumns(state),
    products: productsSelectors.gridData(state),
    filteredProductsCount: productsSelectors.filteredProductsCount(state),
    pagination: productsSelectors.pagination(state),
    productActiveFilter: productsSelectors.productActiveFilter(state),
    localization: localizationSelectors.products(state)
});

const mapDispatchToProps = (dispatch: any) => ({
    setGridFilter: (text: string) => dispatch.products.setGridFilter(text),
    fetchProductsByFilter: (filter: filterFieldsType) => dispatch.products.fetchProductsByFilter(filter),
    setCurrentPage: (page: number) => dispatch.products.setCurrentPage(page),
    setRowsPerPage: (rowsPerPage: number) => dispatch.products.setRowsPerPage(rowsPerPage),
    setDrawerOpen: (open: drawers, params?: any) => dispatch.header.setDrawerOpenParams({ open, params })
});

export default connect(mapStateToProps, mapDispatchToProps)(AllProductsHeader);
