import React from 'react';
import { PagesRoutes } from '../../../../routing/PagesRoutes';
import { Option } from '../../../shared/inputs/base/DropDown';
import { FormSectionType } from '../../../shared/SectionsForm/models/formSectionType';
import SectionsForm from '../../../shared/SectionsForm/SectionsForm';
import { General, Geography, Dates } from './Sections';
import { tariffFormValidationSchema } from '../../../../validationSchemas/tariffForm';
import { useHistory } from 'react-router-dom';
import { createTariffPayload } from '../../../../models/entities/tariff';

type Props = {
    allowedCompaniesDropDownOptions: Option[];
    businessPartnersDropDownOptions: Option[];
    allowedShipmentTypes: Array<string>;
    localization: any;
    createTariff: (tariff: createTariffPayload) => Promise<void>;
};

const TariffForm = ({
    allowedCompaniesDropDownOptions,
    businessPartnersDropDownOptions,
    allowedShipmentTypes,
    localization,
    createTariff
}: Props) => {
    const history = useHistory();

    const initialValues: createTariffPayload = {
        companyId: '',
        shipmentTypeCode: '',
        incoterms: [],
        carrier: [],
        originCountry: '',
        originStation: '',
        destinationCountry: '',
        destinationStation: '',
        activationDate: null,
        expirationDate: null
    };

    const onSubmit = async (values: createTariffPayload) => {
        await createTariff(values);
        history.push(PagesRoutes.Tariffs);
    };

    const sections: FormSectionType<createTariffPayload>[] = [
        {
            name: 'General',
            component: (props) => (
                <General
                    formikProps={props}
                    allowedCompaniesDropDownOptions={allowedCompaniesDropDownOptions}
                    businessPartnersDropDownOptions={businessPartnersDropDownOptions}
                    allowedShipmentTypes={allowedShipmentTypes}
                    localization={localization}
                />
            ),
            errors: ['companyId', 'shipmentTypeCode', 'shipmentSubTypeCode', 'incoterms']
        },
        {
            name: 'Geography',
            component: (props) => <Geography formikProps={props} localization={localization} />,
            errors: ['originCountry', 'originStation', 'destinationCountry', 'destinationStation']
        },
        {
            name: 'Dates',
            component: (props) => <Dates formikProps={props} localization={localization} />,
            errors: ['activationDate', 'expirationDate']
        }
    ];

    return (
        <SectionsForm<createTariffPayload>
            formikProps={{
                initialValues,
                onSubmit,
                validationSchema: tariffFormValidationSchema(localization)
            }}
            sections={sections}
            previousPage={PagesRoutes.Tariffs}
            formHeader={{
                title: 'New Tariff',
                goBackBtnTitle: 'Go back to tariffs page'
            }}
        />
    );
};

export default TariffForm;
