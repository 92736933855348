import React, { useState } from 'react';
import { styled } from '../../../../../shared/Theme/theme';
import { attribute } from '../../../../../../models/entities/product/attribute';
import Loader from '../../../../../shared/SmallComponents/Loader';
import { ColumnType } from '../../../../../shared/Grid/types/Column';
import Grid from '../../../../../shared/Grid/Grid';
import CustomModal, { ModalTriggerProps } from '../../../../../shared/Modal/Modal';
import AttributeFormConnected, { formMode } from '../../AttributeForm/AttributeFormConnected';
import Button from '../../../../../shared/SmallComponents/Button';
import attachment from '../../../../../../static/icons/attachment.svg';
import { settings } from '../../../../../../models/entities/product/product';

type Props = {
    attributes?: Array<attribute>;
    localization: any;
    settings?: settings;
    productId: string;
};

const ProductsAttributes = ({ attributes, localization, settings, productId }: Props) => {
    const { name, options } = localization.attributes.grid.columns;
    const gridColumns: Array<ColumnType<attribute>> = [
        {
            title: name,
            field: 'name',
            sortable: false,
            isDisplayed: true,
            width: '300px'
        },
        {
            title: options,
            field: 'options',
            sortable: false,
            isDisplayed: true,
            columnType: 'attributeOption'
        }
    ];

    const [modalVisible, setVisible] = useState(false);
    const [attributeEntity, setAttribute] = useState({} as attribute);
    return (
        <AttributesWrapper>
            {attributes ? (
                <>
                    <CustomModal
                        title={{ text: localization.attributes.modal.add_title }}
                        Trigger={(props: ModalTriggerProps) => (
                            <ButtonContainer>
                                <Button onClick={props.onClick} width="auto" buttonType="hollow" padding="4px 12px">
                                    {localization.attributes.add_attribute}
                                </Button>
                            </ButtonContainer>
                        )}
                        Content={(
                            setVisible?: React.Dispatch<React.SetStateAction<boolean>>,
                            setConfirm?: React.Dispatch<React.SetStateAction<boolean>>
                        ) => {
                            return (
                                <AttributeFormConnected
                                    productId={productId}
                                    mode={formMode.NEW}
                                    openedFromModal={setVisible ? { setVisible } : undefined}
                                />
                            );
                        }}
                        width="500px"
                    />
                    {attributes.length > 0 ? (
                        <GridWrapper>
                            <Grid<attribute>
                                columns={gridColumns}
                                data={attributes}
                                localization={localization.attributes.grid}
                                settings={settings}
                                onSettingsClick={() => console.log('settings')}
                                onRowMoreOptionsClick={(row) => {
                                    setAttribute(row);
                                    setVisible(true);
                                }}
                                parentEntityId={productId}
                                menuType="productAttributeMenu"
                            />
                            {modalVisible && (
                                <CustomModal
                                    title={{ text: localization.attributes.modal.edit_title }}
                                    isOpen={true}
                                    Content={(
                                        setModalVisible?: React.Dispatch<React.SetStateAction<boolean>>,
                                        setConfirm?: React.Dispatch<React.SetStateAction<boolean>>
                                    ) => {
                                        return (
                                            <AttributeFormConnected
                                                productId={productId}
                                                mode={formMode.EDIT}
                                                attribute={attributeEntity}
                                                openedFromModal={{ setVisible }}
                                            />
                                        );
                                    }}
                                    onCancelCallback={() => {
                                        setVisible(false);
                                        setAttribute({} as attribute);
                                    }}
                                    width="393px"
                                />
                            )}
                        </GridWrapper>
                    ) : (
                        <EmaptyStateText>{localization.attributes.empty_state}</EmaptyStateText>
                    )}
                </>
            ) : (
                <Loader />
            )}
        </AttributesWrapper>
    );
};

const GridWrapper = styled.div`
    flex: 0 0 100%;
    max-width: 100%;
    width: 100%;
    box-sizing: border-box;
    overflow-y: overlay;
    height: 100%;
    width: 100%;
`;

const AttributesWrapper = styled.div`
    display: flex;
    flex-direction: column;
    width: 100%;
`;

const EmaptyStateText = styled.span`
    color: ${(props) => props.theme.colors.emptyStateText};
`;

const ButtonContainer = styled.div`
    display: flex;
    justify-content: flex-end;
    padding-top: 8px;
`;

export default ProductsAttributes;
