import { Input } from 'antd';
import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import Button from '../../../../shared/SmallComponents/Button';
import Loader from '../../../../shared/SmallComponents/Loader';

type Props = {
    fetchDeletedRecords: () => Promise<void>;
    localization?: any;
    setGridFilter: (text: string) => void;
    gridFilter?: string;
};
const DeletedRecordsPageHeader = ({ fetchDeletedRecords, localization, setGridFilter, gridFilter }: Props) => {
    return (
        <Wrapper>
            <Title>{localization.header.title}</Title>
            <DeleteEntitiesText>{localization.header.deleted_time_message}</DeleteEntitiesText>
            <ActionsWrapper></ActionsWrapper>
            <Button
                width="auto"
                height="auto"
                buttonType="hollow"
                padding="0 8px"
                margin="4px 4px 4px 8px"
                borderRadiusMobile="50%"
                minHeightMobile="32px"
                paddingMobile="0 4px"
                widthMobile="auto"
                onClick={() => {
                    fetchDeletedRecords();
                }}
            >
                <span className="material-icons">refresh</span>
            </Button>
            <Input
                style={{
                    height: '30px',
                    margin: '4px',
                    width: '250px'
                }}
                allowClear={true}
                onChange={(e) => setGridFilter(e.currentTarget.value)}
                placeholder={localization.header.text_filter.placeholder}
                value={gridFilter}
            />
        </Wrapper>
    );
};

const Wrapper = styled.div`
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
`;

const Title = styled.div`
    font-weight: bold;
    font-size: 24px;
    color: ${(props) => props.theme.colors.pageTitle};
`;

const ActionsWrapper = styled.div`
    font-weight: bold;
    font-size: 24px;
    width: 100%;
    flex: 1;
    display: flex;
    align-items: center;
    justify-content: center;
`;

const DeleteEntitiesText = styled.div`
    padding-top: 6px;
    color: #b1b1b1;
    margin-left: 10px;
`;
export default DeletedRecordsPageHeader;
