import { createSelector } from 'reselect';
import { RootState } from '../../store/store';

const menuOpen = (state: RootState) => state.header.menu.open;
const drawerOpen = (state: RootState) => state.header.drawer.open;
const drawerParams = (state: RootState) => state.header.drawer.params;
const sideBarOpen = (state: RootState) => state.header.sideBarOpen;
const emptyStateDrawers = (state: RootState) => state.header.drawer.emptyStateDrawers;
const currentPageTitle = (state: RootState) => state.header.currentPageTitle;
const hasGoBackBtn = (state: RootState) => state.header.hasGoBackBtn;
const printMode = (state: RootState) => state.header.printMode;

const isEmptyStateDrawer = createSelector(drawerOpen, emptyStateDrawers, (drawerOpen, emptyStateDrawers) => {
    return emptyStateDrawers.includes(drawerOpen);
});

export { default as headerSelectors } from './selectors';

export default {
    menuOpen,
    drawerOpen,
    drawerParams,
    sideBarOpen,
    emptyStateDrawers,
    currentPageTitle,
    hasGoBackBtn,
    printMode,
    isEmptyStateDrawer
};
