import { Form, Formik } from 'formik';
import React, { useState } from 'react';
import { quotation } from '../../../../../models/entities/bid/quotation';
import { FormDatePicker, FormTextInput, FormUploadInput, FormTextArea, CurrencyInput } from '../../../../shared/inputs/form';
import Button from '../../../../shared/SmallComponents/Button';
import Loader from '../../../../shared/SmallComponents/Loader';
import { styled } from '../../../../shared/Theme/theme';
import { makeStyles, MobileStepper } from '@material-ui/core';
import { eBidShipmentTypes } from '../../../singleShipment/BidForm/ShipmentBidForm';
import { isEmpty } from 'lodash';
import moment from 'moment';
import { bid } from '../../../../../models/entities/bid/bid';
import { QuotationPublicDetails } from '../../../../../sdk/controllers/bids/IBids';

export enum formMode {
    NEW,
    EDIT
}

interface formFields {
    file?: File;
    freightPrice?: number;
    freightPriceCurrency: string;
    transitTime?: number;
    freeDays?: number;
    validTill?: Date;
    shipmentType?: string;
}
type Props = {
    mode: formMode;
    createQuotation: (formData: FormData, isPublic?: boolean) => Promise<boolean>;
    isPublic?: boolean;
    shipmentTypes: Array<string>;
    localization: any;
    data?: QuotationPublicDetails;
};
const renderFieldLabel = (text: string, isMandatory: boolean, tooltipText?: string) => {
    return (
        <LabelContainer>
            <Label>
                {isMandatory && <MandatoryIndication>*</MandatoryIndication>}
                {text}
            </Label>
        </LabelContainer>
    );
};
const useStyles = makeStyles({
    root: {
        flexGrow: 1,
        background: 'transparent',
        color: 'white'
    }
});

function QuotationForm({ mode, createQuotation, shipmentTypes, localization, isPublic, data }: Props) {
    const [submitLoading, setLoading] = useState(false);
    const classes = useStyles();
    const [activeStep, setActiveStep] = useState(0);
    const [successMessage, showSuccessMessage] = useState(false);
    const maxSteps = shipmentTypes.length;
    const isLastStep = activeStep === maxSteps - 1;
    const requiredMessage = localization.required_message;
    let initFormValues: formFields = {
        file: undefined,
        freightPrice: undefined,
        freightPriceCurrency: 'USD',
        transitTime: undefined,
        freeDays: undefined,
        validTill: undefined
    };
    const setInitialValues = () => {
        return shipmentTypes.map((item) => {
            return { ...initFormValues, shipmentType: item };
        });
    };
    const validate = (values: any) => {
        const errors = shipmentTypes.map(() => {
            return {} as any;
        });
        //if (values[activeStep].shipmentType === 'OCEAN_FCL' && !values[activeStep].freeDays) errors[activeStep].freeDays = requiredMessage;
        if (!values[activeStep].freightPrice) errors[activeStep].freightPrice = requiredMessage;
        if (!values[activeStep].transitTime) errors[activeStep].transitTime = requiredMessage;
        if (!values[activeStep].validTill) errors[activeStep].validTill = requiredMessage;
        if (values[activeStep]?.note?.length > 512) errors[activeStep].note = localization.note_max_length;

        if (isErrorsEmpty(errors)) return {};

        return errors;
        function isErrorsEmpty(errors: any[]) {
            for (const item of errors) {
                if (!isEmpty(item)) {
                    return false;
                }
            }
            return true;
        }
    };
    const handleNext = (values: any) => {
        if (isLastStep) {
            submitForm(values);
        } else setActiveStep((prevActiveStep) => prevActiveStep + 1);
    };
    const submitForm = (quotations: Array<quotation>) => {
        if (mode === formMode.NEW) {
            //take only quotations with filled required fields!
            const filledQuotations = quotations.filter((item) => {
                let cond = !!(item.freightPrice && item.transitTime && item.validTill);
                //if (item.shipmentType === 'OCEAN_FCL') cond = cond && !!item.freeDays;
                return cond;
            });

            if (filledQuotations.length > 0) {
                setLoading(true);
                createQuotation(convertToArrayFormData(filledQuotations), isPublic)
                    .then((value) => {
                        if (value) {
                            showSuccessMessage(true);
                        }
                    })
                    .finally(() => {
                        setLoading(false);
                    });
            }
        }
    };
    function convertToArrayFormData(quotations: Array<quotation>): FormData {
        const formData = new FormData();
        if (!isPublic && data) {
            formData.append('bidId', data.bid.id);
            formData.append('partyId', `${data.party.id}`);
        }
        quotations.forEach((quotation) => {
            formData.append('shipmentType[]', quotation?.shipmentType);
            formData.append('freightPrice[]', quotation.freightPrice);
            formData.append('freightPriceCurrency[]', quotation?.freightPriceCurrency);
            formData.append('transitTime[]', '' + quotation.transitTime);
            if (typeof quotation.validTill === 'string') formData.append('validTill[]', quotation.validTill);
            if (quotation.note) formData.append('note[]', quotation.note);
            if (quotation.freeDays) formData.append('freeDays[]', '' + quotation.freeDays);
            if (quotation.file) {
                formData.append('file[]', quotation.file);
            }
        });
        return formData;
    }
    const handleBack = () => {
        if (activeStep === 0) return;
        setActiveStep((prevActiveStep) => prevActiveStep - 1);
    };

    return (
        <Formik initialValues={setInitialValues()} validate={validate} onSubmit={handleNext}>
            {({ values, errors, resetForm }) => {
                return successMessage ? (
                    <SuccessContainer>
                        <span style={{ fontSize: '48px', marginBottom: '10px' }} className="material-icons">
                            check_circle
                        </span>
                        <h2>{localization.sent_quotation}</h2>
                        <h3>{localization.we_will_update}</h3>
                        <Button
                            minWidth="100px"
                            width="auto"
                            height="30px"
                            type="submit"
                            buttonType="clean"
                            onClick={() => {
                                resetForm();
                                setActiveStep(0);
                                showSuccessMessage(false);
                            }}
                        >
                            {localization.send_again}
                        </Button>
                    </SuccessContainer>
                ) : (
                    <Form>
                        <Container>
                            <HeaderWrapper>
                                <div className="header-title">{localization.add_quotation}</div>
                            </HeaderWrapper>
                            <SectionWrapper>
                                <QuotationHeader>
                                    <ShipmentType>{eBidShipmentTypes[shipmentTypes[activeStep]]}</ShipmentType>
                                    <CurrentQuotation>
                                        {localization.quotation} {activeStep + 1} of {maxSteps}
                                    </CurrentQuotation>
                                </QuotationHeader>
                                <div>{renderFieldLabel(localization.total_freight_price, true)}</div>
                                <FieldContainer>
                                    <CurrencyInput
                                        currencyValue={values[activeStep]?.freightPriceCurrency}
                                        numberInputName={`[${activeStep}]freightPrice`}
                                        numberPlaceholder={localization.total_freight_price}
                                        unitInputName={`[${activeStep}]freightPriceCurrency`}
                                        numberInputWidth="100%"
                                    />
                                </FieldContainer>
                                <div>{renderFieldLabel(localization.transit_time, true)}</div>
                                <FieldContainer>
                                    <FormTextInput type="number" name={`[${activeStep}]transitTime`} placeHolder={localization.transit_time} />
                                </FieldContainer>
                                {['OCEAN_FCL', 'OEX'].includes(values[activeStep]?.shipmentType) && (
                                    <>
                                        <div>{renderFieldLabel(localization.demurrage_free_days, true)}</div>
                                        <FieldContainer>
                                            <FormTextInput
                                                type="number"
                                                name={`[${activeStep}]freeDays`}
                                                placeHolder={localization.demurrage_free_days}
                                            />
                                        </FieldContainer>
                                    </>
                                )}
                                <div>{renderFieldLabel(localization.valid_till, true)}</div>
                                <FieldContainer>
                                    <FormDatePicker
                                        name={`[${activeStep}]validTill`}
                                        placeHolder={localization.valid_till}
                                        disabledDate={(current) => moment(current).isBefore(moment().subtract(1, 'day'))}
                                    />
                                </FieldContainer>
                                <div>{renderFieldLabel(localization.note, false)}</div>
                                <FieldContainer>
                                    <FormTextArea name={`[${activeStep}]note`} placeHolder={localization.note} />
                                </FieldContainer>

                                <FieldContainer>
                                    <div style={{ paddingTop: 12 }}>
                                        <FormUploadInput name={`[${activeStep}]file`} placeHolder={localization.file_placeholder} id="file-upload" />
                                    </div>
                                </FieldContainer>
                            </SectionWrapper>
                        </Container>
                        <SectionWrapper>
                            <MobileStepper
                                variant="dots"
                                steps={maxSteps}
                                position="static"
                                activeStep={activeStep}
                                className={classes.root}
                                nextButton={
                                    <NextContainer>
                                        <SkipButton
                                            onClick={() => {
                                                handleNext(values);
                                            }}
                                        >
                                            <span>{localization.skip_btn}</span>
                                        </SkipButton>
                                        <Button minWidth="100px" width="auto" height="30px" type="submit" buttonType="filled">
                                            {isLastStep ? (
                                                <>
                                                    {submitLoading ? (
                                                        <Loader width="20px" marginTop="0px" showText={false} />
                                                    ) : (
                                                        <span>{localization.send_btn}</span>
                                                    )}
                                                </>
                                            ) : (
                                                <span>{localization.next_btn}</span>
                                            )}
                                        </Button>
                                    </NextContainer>
                                }
                                backButton={
                                    <Button
                                        textColor="white"
                                        width="auto"
                                        height="30px"
                                        type="button"
                                        buttonType="clean"
                                        onClick={() => handleBack()}
                                        visibility={activeStep === 0 ? 'hidden' : 'visible'}
                                    >
                                        <span style={{ fontSize: '16px', marginRight: '5px' }} className="material-icons">
                                            arrow_back
                                        </span>
                                        <span>{localization.back_btn}</span>
                                    </Button>
                                }
                            />
                        </SectionWrapper>
                    </Form>
                );
            }}
        </Formik>
    );
}

const Container = styled.div``;

const FieldContainer = styled.div`
    margin-bottom: 12px;
`;

const LabelContainer = styled.div`
    display: flex;
    margin-bottom: 5px;
    color: white;
    & img {
        margin-top: -2px;
        margin-left: 5px;
    }
`;

const SectionWrapper = styled.div`
    padding: 15px 40px;
`;
const HeaderWrapper = styled(SectionWrapper)`
    border-bottom: 1px solid white;
    .header-title {
        font-size: 18px;
        color: white;
    }
`;
const Label = styled.label`
    color: inherit;
`;
const MandatoryIndication = styled.span`
    color: inherit;
    margin-right: 7px;
`;
const QuotationHeader = styled.div`
    display: flex;
    justify-content: space-between;
    color: white;
    margin-bottom: 23px;
`;
const ShipmentType = styled.div`
    font-weight: 500;
    font-size: 18px;
`;
const CurrentQuotation = styled.div``;

const SuccessContainer = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    margin: auto;
    color: ${(props) => props.theme.colors.primaryBlue};
    h2,
    h3 {
        color: white;
        text-align: center;
    }
    h2 {
        margin-bottom: 0;
    }
`;

const NextContainer = styled.div`
    display: flex;
    align-items: center;
    position: relative;
`;
const SkipButton = styled.span`
    position: absolute;
    left: -35px;
    cursor: pointer;
`;
export default QuotationForm;
