import React from 'react';
import moment from 'moment';
import styled from 'styled-components';
import hexagonOpened from '../../../static/icons/hexagonCheck/opened.svg';
import hexagonClosed from '../../../static/icons/hexagonCheck/closed.svg';

export type TimeLineItem = {
    title: string;
    isCompleted: boolean;
    date?: Date;
    description?: string;
    content?: { [key: string]: string };
};

type Props = {
    data: Array<TimeLineItem>;
};

const TimeLine = ({ data }: Props) => {
    const renderItems = data.map((item: TimeLineItem, index) => {
        const { title, isCompleted, date, content, description } = item;

        return (
            <ItemContainer key={index}>
                <span className="material-icons">{isCompleted ? 'done' : ''}</span>
                <LineContainer isCompleted={isCompleted}>
                    <img src={isCompleted ? hexagonClosed : hexagonOpened} alt="hexagon" className="completed-mark" />
                    <div className="vertical-line"></div>
                </LineContainer>
                <ContentContainer>
                    <HeaderContainer>
                        <Title isCompleted={isCompleted}>{title}</Title>
                        {date && <span className="date">{moment(date).format('MM/DD/YYYY hh:mm').toString()}</span>}
                    </HeaderContainer>
                    {!!description && (
                        <ItemContent>
                            <div className="detail">
                                <span className="detail-value">{description}</span>
                            </div>
                        </ItemContent>
                    )}
                    {!!content && (
                        <ItemContent>
                            {Object.entries(content).map(([key, value], i) => (
                                <div className="detail" key={i}>
                                    {key !== 'header' && <span className="detail-header">{key}: </span>}
                                    <span className="detail-value">{value}</span>
                                </div>
                            ))}
                        </ItemContent>
                    )}
                </ContentContainer>
            </ItemContainer>
        );
    });
    return <TimeLineContainer>{renderItems}</TimeLineContainer>;
};

interface timelineItemProps {
    isCompleted: boolean;
}

const TimeLineContainer = styled.div`
    display: flex;
    flex-direction: column;
    width: 100%;
`;
const LineContainer = styled.div<timelineItemProps>`
    display: flex;
    flex-direction: column;
    align-items: center;

    .vertical-line {
        background-image: ${(props) =>
            props.isCompleted
                ? 'linear-gradient(#008ac9 33%, rgba(255, 255, 255, 0) 0%)'
                : 'linear-gradient(#e0e0e0 33%, rgba(255, 255, 255, 0) 0%)'};
        background-position: right;
        background-size: 2px 5px;
        width: 2px;
        flex: 1;
        background-repeat: repeat-y;
    }
`;
const ContentContainer = styled.div`
    display: flex;
    flex-direction: column;
    flex: 1;
    padding-left: 5px;
`;

const HeaderContainer = styled.div`
    display: flex;
    padding-bottom: 6px;
    margin-bottom: 10px;
    justify-content: space-between;
    align-items: baseline;

    background-image: linear-gradient(to right, #e0e0e0 33%, rgba(255, 255, 255, 0) 0%);
    background-position: bottom;
    background-size: 5px 2px;
    background-repeat: repeat-x;

    .date {
        font-size: 10px;
        text-align: right;
        color: #757575;
    }
`;
const ItemContent = styled.div`
    margin-bottom: 20px;

    .detail {
        .detail-header {
            color: #394372;
        }
        .detail-value {
            color: #757575;
        }
    }
`;
const Title = styled.div<timelineItemProps>`
    font-size: 16px;
    font-weight: 500;
    color: ${(props) => (props.isCompleted ? '#008ac9' : '#252b4a')};
    height: 24px;
    display: flex;
    align-items: center;
`;

const ItemContainer = styled.div`
    display: flex;
    width: 100%;

    .material-icons {
        width: 24px;
        height: 24px;
        font-size: 18px;
        color: #008ac9;
        display: flex;
        align-items: center;
        justify-content: center;
    }
`;

export default TimeLine;
