export const tariffChargeCalcTypes = ['FIX', 'FLAT', 'BREAK', 'PERCENT'] as const;

export type CalcType = typeof tariffChargeCalcTypes[number];

export type fixCalcData = { price: number };

export type flatCalcData = { price: number };

export type breakCalcData = { value: number; price: number };

export type percentCalcData = { percent: number; chargeCode: string };

export type ChargeCalcData<TCalcType extends CalcType> = TCalcType extends 'FIX'
    ? fixCalcData
    : TCalcType extends 'FLAT'
    ? flatCalcData
    : TCalcType extends 'BREAK'
    ? breakCalcData[]
    : TCalcType extends 'PRECENT'
    ? percentCalcData
    : any;
