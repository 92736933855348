import React from 'react';
import { orderFollower } from '../../../../../models/entities/order/orderFollower/orderFollower';
import { styled } from '../../../../shared/Theme/theme';
import UserBadge from '../../../../shared/UserBadge/UserBadge';

type Props = {
    follower: orderFollower;
};

const OrderFollower = ({ follower }: Props) => {
    return (
        <Follower>
            <UserBadge userName={follower.userName} hideBadgeName={true} borderColor="#f7f7f7" width={30} userId={follower.userId} />
        </Follower>
    );
};
const Follower = styled.div`
    margin-left: 2px;
`;
export default OrderFollower;
