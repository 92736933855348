import { sortBy } from 'lodash';
import React from 'react';
import styled from 'styled-components';
import { supplierUpdatesUpdatePoItemsReadyDatePayload, supplierUpdatesUpdatePoPayload } from '../../../../models/entities/purchaseOrder/payloads';
import { purchaseOrder, settings } from '../../../../models/entities/purchaseOrder/purchaseOrder';
import { getUrlFuncType } from '../../../shared/FilesPreview/Preview';
import LayoutLoadingNotify from '../../../shared/Layout/LayoutLoadingNotify';
import PoItemsGrid from '../../singlePurchaseOrder/PurchaseOrderForm/sections/Items/PoItemsGrid';
import Documents from '../../singlePurchaseOrder/sections/Documents/DocumentsTab';
import Overview from '../../singlePurchaseOrder/sections/Overview';

type Props = {
    purchaseOrder: purchaseOrder;
    localization: any;
    supplierUpdatesUpdatePoItemsReadyDate: (payload: supplierUpdatesUpdatePoItemsReadyDatePayload) => Promise<void>;
    supplierUpdatesUpdatePo: (payload: supplierUpdatesUpdatePoPayload) => Promise<void>;
    getPoSupplierUpdatesFileUrl?: getUrlFuncType;
    allPosPageLocalization: any;
    settings?: settings;
};
const PoSupplierUpdates = ({
    purchaseOrder,
    localization,
    allPosPageLocalization,
    supplierUpdatesUpdatePoItemsReadyDate,
    getPoSupplierUpdatesFileUrl,
    settings,
    supplierUpdatesUpdatePo
}: Props) => {
    const supplierName = purchaseOrder.parties?.find((p) => p.businessPartnerId === purchaseOrder.vendorId)?.name;
    const latestReadyDate = [...(purchaseOrder.items || [])]
        ?.sort((a, b) => {
            if (!a.readyDate) return 1;
            if (!b.readyDate) return -1;
            return new Date(b.readyDate).getTime() - new Date(a.readyDate).getTime();
        })
        ?.map((i) => i.readyDate)?.[0];

    return (
        <div>
            <Overview purchaseOrder={purchaseOrder} localization={localization} isSupplierUpdates />
            <BottomSection>
                <PoItemsGrid
                    values={purchaseOrder}
                    localization={allPosPageLocalization.form}
                    onUpdateItemsReadyDate={async (ids: string[], readyDate?: Date) => {
                        if (readyDate) {
                            const payload: supplierUpdatesUpdatePoItemsReadyDatePayload = {
                                purchaseOrderId: purchaseOrder.id,
                                itemsIds: ids,
                                readyDate
                            };
                            await supplierUpdatesUpdatePoItemsReadyDate(payload);

                            if (latestReadyDate) await supplierUpdatesUpdatePo({ expectedReadyDate: readyDate });
                        }
                    }}
                    isSupplierUpdates
                />
                <Documents
                    documents={purchaseOrder.documents?.documents}
                    requiredDocs={purchaseOrder.documents?.requiredDocs}
                    localization={localization}
                    supplierName={supplierName}
                    settings={settings}
                    getFileUrl={getPoSupplierUpdatesFileUrl}
                />
            </BottomSection>
            <LayoutLoadingNotify />
        </div>
    );
};

const BottomSection = styled.div`
    padding: 20px;
`;

export default PoSupplierUpdates;
