import React from 'react';
import edit from '../../../../static/icons/edit.svg';
import trash from '../../../../static/icons/trash.svg';
import CellTrigger from '../../Menu/MenuTriggers/CellTrigger';
import { RootState } from '../../../../state/store/store';
import { connect } from 'react-redux';
import { singlePurchaseDemandSelectors } from '../../../../state/ducks/singlePurchaseDemand/selectors';
import { purchaseDemand } from '../../../../models/entities/purchaseDemand/purchaseDemand';
import Menu from '../../Menu/Menu';
import MenuItem from '../../Menu/MenuItem';
type onRowMoreOptionClickType = () => void;
type deletePurchaseDemandDocumentType = (purchaseDemandNumber: string, documentId: string) => void;
type Props = {
    entityId: string;
    localization: any;
    onRowMoreOptionsClick?: onRowMoreOptionClickType;
    deletePurchaseDemandDocument: deletePurchaseDemandDocumentType;
    purchaseDemand?: purchaseDemand;
};

const PurchaseDemandDocumentMenu = ({ entityId, purchaseDemand, localization, onRowMoreOptionsClick, deletePurchaseDemandDocument }: Props) => {
    const menuItems = [
        <MenuItem onClick={() => onRowMoreOptionsClick && onRowMoreOptionsClick()} icon={edit} text={localization.edit} />,
        <MenuItem
            onClick={() => purchaseDemand && deletePurchaseDemandDocument(purchaseDemand.id, entityId)}
            icon={trash}
            text={localization.delete}
        />
    ];

    return <Menu options={menuItems} MenuTrigger={CellTrigger} strStyle="display: flex;align-items: center;width: 100%;height: 100%;" />;
};

const mapStateToProps = (state: RootState) => ({
    purchaseDemand: singlePurchaseDemandSelectors.purchaseDemand(state)
});

const mapDispatchToProps = (dispatch: any) => ({
    deletePurchaseDemandDocument: (purchaseDemandNumber: string, documentId: string) =>
        dispatch.purchaseDemands.deletePurchaseDemandDocument({ purchaseDemandNumber, documentId })
});

export default connect(mapStateToProps, mapDispatchToProps)(PurchaseDemandDocumentMenu);
