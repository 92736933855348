import React from 'react';
import { DataGrid, GridOverlay } from '@material-ui/data-grid';
import { makeStyles } from '@material-ui/core';
import { CreateCSSProperties, CSSProperties, PropsFunc } from '@material-ui/styles';
import styled from 'styled-components';
import Loader from '../SmallComponents/Loader';

type Props = React.ComponentProps<typeof DataGrid> & {
    showFooter?: boolean;
    rootStyles?: CSSProperties | CreateCSSProperties<{}> | PropsFunc<{}, CreateCSSProperties<{}>>;
};

const MuiDataGrid = ({ rootStyles, showFooter, ...rest }: Props) => {
    const defaultDataGridStyle = {
        '&.MuiDataGrid-root .MuiDataGrid-cell:focus-within': {
            outline: 'none'
        },
        '&.MuiDataGrid-root .MuiDataGrid-columnSeparator': {
            display: 'none'
        },
        '&.MuiDataGrid-root .MuiDataGrid-row': {
            cursor: 'pointer'
        },
        // '&.MuiDataGrid-root': {
        //     width: '100% !important'
        // },
        // '&.MuiDataGrid-root .MuiDataGrid-viewport': {
        //     minWidth: '100% !important',
        //     maxWidth: '100% !important'
        // },
        border: 'none'
    };

    const dataGridStyles = makeStyles((theme) => {
        return {
            root: {
                '& .MuiCheckbox-root svg': {
                    width: 16,
                    height: 16,
                    backgroundColor: 'transparent',
                    border: `1px solid ${theme.palette.type === 'light' ? '#d9d9d9' : 'rgb(67, 67, 67)'}`,
                    borderRadius: 2
                },
                '& .MuiCheckbox-root svg path': {
                    display: 'none'
                },
                '& .MuiCheckbox-root.Mui-checked:not(.MuiCheckbox-indeterminate) svg': {
                    backgroundColor: '#008ac9',
                    borderColor: '#008ac9'
                },
                '&.MuiDataGrid-root .MuiDataGrid-row.Mui-selected': {
                    backgroundColor: 'rgb(0 138 201 / 8%)'
                },
                '& .MuiCheckbox-root.Mui-checked .MuiIconButton-label:after': {
                    position: 'absolute',
                    display: 'table',
                    border: '2px solid #fff',
                    borderTop: 0,
                    borderLeft: 0,
                    transform: 'rotate(45deg) translate(-50%,-50%)',
                    opacity: 1,
                    transition: 'all .2s cubic-bezier(.12,.4,.29,1.46) .1s',
                    content: '""',
                    top: '50%',
                    left: '39%',
                    width: 5.71428571,
                    height: 9.14285714
                },
                '& .MuiCheckbox-root.MuiCheckbox-indeterminate .MuiIconButton-label:after': {
                    width: 8,
                    height: 8,
                    backgroundColor: '#008ac9',
                    transform: 'none',
                    top: '39%',
                    border: 0
                },
                ...defaultDataGridStyle,
                ...(rootStyles || {})
            }
        };
    });

    const classes = dataGridStyles();

    return (
        <DataGrid
            components={{
                LoadingOverlay: () => (
                    <GridOverlay>
                        <LoaderWrapper>
                            <Loader width="40px" marginTop="0"></Loader>
                        </LoaderWrapper>
                    </GridOverlay>
                )
            }}
            hideFooter={!showFooter}
            isCellEditable={(params) => false}
            {...rest}
            className={classes.root}
        />
    );
};

const LoaderWrapper = styled.div`
    position: absolute;
    background-color: #fff;
    z-index: 1000;
    width: 100%;
    height: 100%;

    display: flex;
    align-items: center;
    justify-content: center;
`;

export const MultiLineCell = styled.div`
    white-space: initial !important;
    line-height: 25px !important;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    overflow: hidden;
    text-overflow: ellipsis;
`;

export default MuiDataGrid;
