import moment from 'moment';
import { order } from '../../../../models/entities/order/order';
import { orderFilterFields } from './types';

const filterOrders = (orders: order[], filterFields: orderFilterFields) => {
    let filteredOrders = [...orders];

    filterFields.forEach((filter) => {
        switch (filter.field) {
            case 'orderDateFrom':
                filteredOrders = filteredOrders.filter((order) => moment(order.orderDate).isSameOrAfter(moment(filter.value)));
                break;
            case 'orderDateTo':
                filteredOrders = filteredOrders.filter((order) => moment(order.orderDate).isSameOrBefore(moment(filter.value)));
                break;

            case 'alertStatus':
                filteredOrders = filteredOrders.filter((order) => filter.value.includes(`${order.alertStatus || 3}`));
                break;
            case 'companyId':
                filteredOrders = filteredOrders.filter((order) => filter.value.includes(order.companyId));
                break;
            case 'state':
                filteredOrders = filteredOrders.filter((order) => filter.value.includes(order.state));
                break;
            default:
                filteredOrders = filteredOrders.filter((order) =>
                    order[filter.field] ? `${order[filter.field]}`.includes(`${filter.value}`) : order
                );
                break;
        }
    });
    return filteredOrders;
};

export default filterOrders;
