import { createSelector } from 'reselect';
import { company } from '../../../models/entities/company/company';
import { RootState } from '../../store/store';

const companies = (state: RootState) => state.companies.companies;
const gridFilter = (state: RootState) => state.admin.gridFilter;
const userCompanyId = (state: RootState) => state.user.userInfo.companyId;

const getCompanyById = createSelector(companies, (companies: company[]) => (companyId: string) => {
    return companies.find((c) => c.id === companyId);
});
const companiesData = createSelector(gridFilter, companies, (gridFilter, companies) => {
    if (gridFilter && gridFilter !== '' && gridFilter.length > 2) {
        companies = companies?.filter((s) => JSON.stringify(s).toLocaleLowerCase().includes(gridFilter.toLocaleLowerCase()));
    }
    return companies;
});

const getRelationToken = createSelector(companies, userCompanyId, (companies: company[], companyId: string) => {
    const company = companies.find((c) => c.id === companyId);
    return company?.relationToken;
});
export { default as companiesSelectors } from './selectors';

export default {
    companies,
    companiesData,
    getCompanyById,
    getRelationToken,
    userCompanyId
};
