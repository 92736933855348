import BaseController from '..';
import { product } from '../../../models/entities/product/product';
import { filterFieldsType } from '../../../state/ducks/products/products';
import ProductsDocuments from './documents/productsDocuments';
import ProductsAttributes from './attributes/productsAttributes';
import ProductsLinks from './links/productsLinks';
import ProductsHistory from './history/productHistory';
import IProducts from './IProducts';

export default class Products extends BaseController implements IProducts {
    private productsDocuments: ProductsDocuments;
    private productsAttributes: ProductsAttributes;
    private productsLinks: ProductsLinks;
    private productsHistory: ProductsHistory;
    constructor(baseUrl: string) {
        super(baseUrl);
        this.productsDocuments = new ProductsDocuments(baseUrl);
        this.productsAttributes = new ProductsAttributes(baseUrl);
        this.productsLinks = new ProductsLinks(baseUrl);
        this.productsHistory = new ProductsHistory(baseUrl);
    }
    async fetchProducts() {
        const response = await this.client.get('/catalog/products/allActive');
        return response;
    }
    async fetchProductsWithAttributes() {
        const response = await this.client.get('/catalog/products/attributes');
        return response;
    }
    async fetchById(productId: string) {
        const response = await this.client.get(`/catalog/products/id/${productId}`);
        return response;
    }
    async fetchByIds(productsIds: string[]): Promise<Array<product>> {
        const response = await this.client.post(`/catalog/products/getByIds`, JSON.stringify({ productsIds }));
        return response;
    }
    async fetchProductsByFilter(filter: filterFieldsType) {
        const response = await this.client.post('/catalog/products/filtered', JSON.stringify({ filters: filter }));
        return response;
    }
    async uploadProductsByExcel(file: File, companyId?: string, companyName?: string) {
        this.client.setBodyType('FORM-DATA');
        const formData = new FormData();
        formData.append('file', file);
        if (companyId) formData.append('companyId', companyId);
        if (companyName) formData.append('companyName', companyName);
        const response = await this.client.post('/catalog/products/excel-upload', formData);
        return response;
    }
    async createProduct(product: product) {
        const response = await this.client.post('/catalog/products', JSON.stringify({ data: product }));
        return response;
    }
    async duplicateProduct(product: product, origProductId: string, includeDocuments?: boolean, includeAttributes?: boolean) {
        const response = await this.client.post(
            '/catalog/products/duplicate',
            JSON.stringify({ origProductId, data: product, includeDocuments, includeAttributes })
        );
        return response;
    }
    async updateProduct(id: string, product: product, deletedSuppliers?: string[]) {
        const response = await this.client.put('/catalog/products', JSON.stringify({ data: product, productId: id, deletedSuppliers }));
        return response;
    }
    async updateProductIsActive(id: string, isActive: boolean) {
        const response = await this.client.put('/catalog/products/isActive', JSON.stringify({ productId: id, isActive }));
        return response;
    }
    async deleteProduct(productNumber: string) {
        await this.client.delete(`/catalog/products/id/${productNumber}`);
    }
    async createMultipleRelatedProducts(productId: string, relatedProducts: string[]) {
        const response = await this.client.post(`/product/related/multiple`, JSON.stringify({ productId, relations: relatedProducts }));
        return response;
    }
    async createRelatedProduct(firstProductId: string, secondProductId: string) {
        const response = await this.client.post(`/product/related`, JSON.stringify({ firstProductId, secondProductId }));
        return response;
    }
    async stopRelation(firstProductId: string, secondProductId: string) {
        const response = await this.client.delete(`/product/related`, JSON.stringify({ firstProductId, secondProductId }));
        return response;
    }
    documents() {
        return this.productsDocuments;
    }
    attributes() {
        return this.productsAttributes;
    }
    links() {
        return this.productsLinks;
    }
    history() {
        return this.productsHistory;
    }
}
