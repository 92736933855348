import React from 'react';
import { FormikProps } from 'formik';
import countries from '../../../../../static/data/countries.json';
import stations from '../../../../../static/data/stations.json';
import FormInputWrapper from '../../../../shared/Form/FormInputWrapper';
import { FormDropDown } from '../../../../shared/inputs/form';
import { renderCountryOption } from '../../../../shared/SmallComponents/CountryOption';
import styled from 'styled-components';
import { createTariffPayload } from '../../../../../models/entities/tariff';

type Props = {
    formikProps: FormikProps<createTariffPayload>;
    localization: any;
};

const countriesDropDownOptions = countries.map((country) => ({
    text: country.name,
    value: country.code
}));

const Geography = ({ formikProps, localization }: Props) => {
    const { values, setFieldValue } = formikProps;
    const fieldsLocalization = localization.fields;

    const seaPortShipmentStationOptions = (destination?: boolean) =>
        stations
            .filter((station) => station.country === (destination ? values.destinationCountry : values.originCountry) && station.type === 'seaport')
            .map((station) => ({
                text: station.name !== '' ? station.name : station.location,
                value: station.code
            }));

    return (
        <Container>
            <div style={{ paddingRight: '30px' }}>
                <FormInputWrapper fieldName="originCountry" localization={fieldsLocalization} isMandatory>
                    <FormDropDown
                        name="originCountry"
                        style={{ width: '200px' }}
                        placeHolder={fieldsLocalization.originCountry.placeholder}
                        options={countriesDropDownOptions}
                        optionRenderer={renderCountryOption}
                        onChange={() => {
                            setFieldValue('originStation', '');
                        }}
                        showSearch
                    />
                </FormInputWrapper>
                <FormInputWrapper fieldName="originStation" localization={fieldsLocalization} isMandatory>
                    <FormDropDown
                        disabled={!values.originCountry || !(values.originCountry.length > 0)}
                        name="originStation"
                        style={{ width: '200px' }}
                        placeHolder={fieldsLocalization.originStation.placeholder}
                        options={stations
                            .filter((station) => {
                                if (values.shipmentTypeCode === 'CUSTOMS') return station.country === values.originCountry;
                                else
                                    return (
                                        station.country === values.originCountry &&
                                        station.type ===
                                            (['OI', 'OEX', 'OE', 'DROP_OCEAN', 'RAIL'].includes(values.shipmentTypeCode) ? 'seaport' : 'airport')
                                    );
                            })
                            .map((station) => ({
                                text: station.name !== '' ? station.name : station.location,
                                value: station.code
                            }))}
                        showSearch
                    />
                </FormInputWrapper>
                {['OI', 'OEX', 'OE', 'DROP_OCEAN'].includes(values.shipmentTypeCode) && (
                    <FormInputWrapper fieldName="pol" localization={fieldsLocalization} isMandatory>
                        <FormDropDown
                            disabled={!values.originCountry || !(values.originCountry.length > 0)}
                            name="pol"
                            style={{ width: '200px' }}
                            placeHolder={fieldsLocalization.pol.placeholder}
                            options={seaPortShipmentStationOptions()}
                            showSearch
                        />
                    </FormInputWrapper>
                )}
            </div>
            <div style={{ borderRight: '1px solid #e0e0e0', paddingRight: '30px' }}>
                <FormInputWrapper fieldName="destinationCountry" localization={fieldsLocalization} isMandatory>
                    <FormDropDown
                        name="destinationCountry"
                        style={{ width: '200px' }}
                        placeHolder={fieldsLocalization.destinationCountry.placeholder}
                        options={countriesDropDownOptions}
                        optionRenderer={renderCountryOption}
                        onChange={() => {
                            setFieldValue('destinationStation', '');
                        }}
                        showSearch
                    />
                </FormInputWrapper>
                <FormInputWrapper fieldName="destinationStation" localization={fieldsLocalization} isMandatory>
                    <FormDropDown
                        disabled={!values.destinationCountry || !(values.destinationCountry.length > 0)}
                        name="destinationStation"
                        style={{ width: '200px' }}
                        placeHolder={fieldsLocalization.destinationStation.placeholder}
                        options={stations
                            .filter((station) => {
                                if (values.shipmentTypeCode === 'CUSTOMS') return station.country === values.destinationCountry;
                                else
                                    return (
                                        station.country === values.destinationCountry &&
                                        station.type ===
                                            (['OI', 'OEX', 'OE', 'DROP_OCEAN', 'RAIL'].includes(values.shipmentTypeCode) ? 'seaport' : 'airport')
                                    );
                            })
                            .map((station) => ({
                                text: station.name !== '' ? station.name : station.location,
                                value: station.code
                            }))}
                        showSearch
                    />
                </FormInputWrapper>
                {['OI', 'OEX', 'OE', 'DROP_OCEAN'].includes(values.shipmentTypeCode) && (
                    <FormInputWrapper fieldName="pod" localization={fieldsLocalization} isMandatory>
                        <FormDropDown
                            disabled={!values.originCountry || !(values.originCountry.length > 0)}
                            name="pod"
                            style={{ width: '200px' }}
                            placeHolder={fieldsLocalization.pod.placeholder}
                            options={seaPortShipmentStationOptions(true)}
                            showSearch
                        />
                    </FormInputWrapper>
                )}
            </div>
        </Container>
    );
};

const Container = styled.div`
    display: flex;
    flex-direction: row;
`;

export default Geography;
