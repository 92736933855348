import BaseController from '../..';
import { shipmentReference } from '../../../../models/entities/shipment/shipmentReference';
import IShipmentReferences from './IShipmentReferences';

export default class ShipmentReferences extends BaseController implements IShipmentReferences {
    constructor(baseUrl: string) {
        super(baseUrl);
    }
    async createShipmentReference(shipmentId: string, data: shipmentReference) {
        const response = await this.client.post(`/shipment/references`, JSON.stringify({ shipmentId, data }));
        return response;
    }
    async updateShipmentReference(shipmentId: string, data: shipmentReference) {
        const response = await this.client.put(`/shipment/references`, JSON.stringify({ shipmentId, data }));
        return response;
    }
    async deleteShipmentReference(shipmentId: string, referenceId: number) {
        const response = await this.client.delete(`/shipment/references`, JSON.stringify({ shipmentId, id: referenceId }));
        return response;
    }
}
