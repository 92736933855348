import { RootState } from '../../../state/store/store';
import { createSelector } from 'reselect';
import { task, TaskStatusEnum } from '../../../models/entities/task';
import { byDate } from '../../../utils/sortArray';
import { headerSelectors } from '../header/selectors';

const tasks = (state: RootState) => state.tasks.tasks;
const error = (state: RootState) => state.tasks.error;
const isFetching = (state: RootState) => state.tasks.isFetching;

const taskDrawerIsOpen = (state: RootState) => headerSelectors.drawerOpen(state) === 'TASK';

const followDrawerIsOpen = (state: RootState) => headerSelectors.drawerOpen(state) === 'FOLLOW';

const visibleTasks = createSelector(tasks, (tasks) => {
    const tasksItems = tasks?.filter((task) => task.status !== TaskStatusEnum.Archived);
    if (!tasksItems) return null;
    return tasksItems
        .sort(byDate(false, (task: task) => task.dueDate))
        .sort((a, b) => Number(a.status === TaskStatusEnum.Done) - Number(b.status === TaskStatusEnum.Done));
});

export { default as tasksSelectors } from './selectors';

export default {
    tasks,
    visibleTasks,
    error,
    isFetching,
    taskDrawerIsOpen,
    followDrawerIsOpen
};
