type stateType = { [key: string]: any };
function setEntities<T>(state: stateType, entityId: string, entityType: keyof stateType, subEntityType: string, entities: Array<T>) {
    const newArr = [...state[entityType]];
    const index = newArr.findIndex((item) => item.id === entityId);
    if (index !== -1) {
        newArr[index][subEntityType] = entities;
    }
    return newArr;
}
function addEntity<T>(state: stateType, entityId: string, entityType: keyof stateType, subEntityType: string, entity: T) {
    const newArr = [...state[entityType]];
    const index = newArr.findIndex((item) => item.id === entityId);
    if (index !== -1) {
        let arr = [entity];
        const oldArr = newArr[index][subEntityType];
        if (oldArr && oldArr.length > 0) arr = [...oldArr, ...arr];
        newArr[index][subEntityType] = arr;
    }
    return newArr;
}
function editEntity<T extends { id: string | number }>(
    state: stateType,
    entityId: string,
    entityType: keyof stateType,
    subEntityType: string,
    entity: T
) {
    const newArr = [...state[entityType]];
    const index = newArr.findIndex((item) => item.id === entityId);
    if (index !== -1) {
        const entities = newArr[index][subEntityType]?.map((item: T) => {
            if (item.id === entity.id) return { ...entity };
            else return { ...item };
        });
        newArr[index][subEntityType] = entities;
    }
    return newArr;
}
function removeEntity<T extends { id: string | number }>(
    state: stateType,
    entityId: string,
    entityType: keyof stateType,
    subEntityType: string,
    subEntityId: number | string
) {
    const newArr = [...state[entityType]];
    const index = newArr.findIndex((item) => item.id === entityId);
    if (index !== -1) {
        const oldEntities = newArr[index][subEntityType]?.filter((item: T) => item.id !== subEntityId);
        newArr[index][subEntityType] = oldEntities;
    }
    return newArr;
}

export { setEntities, addEntity, editEntity, removeEntity };
