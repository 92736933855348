import React, { useEffect, useState } from 'react';
import { order } from '../../../../../models/entities/order/order';
import { localizationSelectors } from '../../../../../state/ducks/localization/selectors';
import { ordersSelectors } from '../../../../../state/ducks/orders/selectors';
import { RootState } from '../../../../../state/store/store';
import { connect } from 'react-redux';
import { document } from '../../../../../models/entities/common_subentities/document';
import DocumentFormConnected, { formMode } from '../../../commonSubEntities/documents/DocumentForm/DocumentFormConnected';

type Props = {
    entityId?: string;
    document?: document;
    mode: formMode;
    localizations?: any;
    openedFromModal?: {
        setVisible: React.Dispatch<React.SetStateAction<boolean>>;
    };
    confirmation?: { setConfirm: React.Dispatch<React.SetStateAction<boolean>> };
    createOrderDocument: (orderId: string, document: document) => void;
    updateOrderDocument: (orderId: string, document: document) => void;
    showLoader?: boolean;
    defaultValues?: document;
};

const OrderDocumentFormConnected = ({
    entityId,
    document,
    localizations,
    mode,
    openedFromModal,
    confirmation,
    createOrderDocument,
    updateOrderDocument,
    showLoader,
    defaultValues
}: Props) => {
    return (
        <DocumentFormConnected<order>
            entity={{ id: entityId } as order}
            document={document}
            localizations={localizations}
            mode={mode}
            openedFromModal={openedFromModal}
            confirmation={confirmation}
            createDocument={createOrderDocument}
            updateDocument={updateOrderDocument}
            showLoader={showLoader}
            defaultValues={defaultValues}
            typeMode="tags"
        />
    );
};
const mapStateToProps = (state: RootState) => ({
    localizations: localizationSelectors.ordersPage(state),
    // settings: ordersSelectors.settingsByLanguage(state),
    showLoader: state.loading.effects.orders.createMultipleOrderDocument || state.loading.effects.orders.updateOrderDocument
});

const mapDispatchToProps = (dispatch: any) => ({
    createOrderDocument: (orderId: string, document: document) => dispatch.orders.createMultipleOrderDocument({ orderId, documents: [document] }),
    updateOrderDocument: (orderId: string, document: document) => dispatch.orders.updateOrderDocument({ orderId, document })
});

export default connect(mapStateToProps, mapDispatchToProps)(OrderDocumentFormConnected);
