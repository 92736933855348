import React, { useEffect } from 'react';
import { order } from '../../../../../models/entities/order';
import { GridColDef } from '@material-ui/data-grid';
import { OrderItemsGridColDef } from './OrderItemsGridColDef';
import MuiDataGrid from '../../../../shared/MuiDataGrid/MuiDataGrid';
import styled from 'styled-components';
import ProductsModal, { productGridRow } from '../../../products/components/ProductsModal/ProductsModal';
import { ModalTriggerProps } from '../../../../shared/Modal/Modal';
import Button from '../../../../shared/SmallComponents/Button';
import { connect } from 'react-redux';
import { RootState } from '../../../../../state/store/store';
import {
    createOrderItemInput,
    createOrderItemsPayload,
    updateOrderItemPayload,
    deleteOrderItemPayload
} from '../../../../../models/entities/order/orderItem/payloads';
import { localizationSelectors } from '../../../../../state/ducks/localization/selectors';
import { drawers } from '../../../../../state/ducks/header/header';
import { singleOrderSelectors } from '../../../../../state/ducks/singleOrder/selectors';
import { product } from '../../../../../models/entities/product/product';

type Props = {
    order: order;
    localization: any;
    orderItemsRelatedProducts: Array<product>;
    createOrderItems: (payload: createOrderItemsPayload) => Promise<void>;
    updateOrderItem: (payload: updateOrderItemPayload) => Promise<void>;
    deleteOrderItem: (payload: deleteOrderItemPayload) => Promise<void>;
    setDrawerOpen: (open: drawers, params?: any) => void;
    fetchProducts: (ids: string[]) => void;
};

const OverviewItems = ({
    order,
    localization,
    orderItemsRelatedProducts,
    createOrderItems,
    updateOrderItem,
    deleteOrderItem,
    setDrawerOpen,
    fetchProducts
}: Props) => {
    useEffect(() => {
        const productsIds = order.items?.map((item) => item.productId);
        if (productsIds && productsIds.length !== orderItemsRelatedProducts.length) fetchProducts(productsIds);
    }, [order.items, orderItemsRelatedProducts]);

    const ItemsGridColumns: GridColDef[] = OrderItemsGridColDef(
        order.items || [],
        updateOrderItem,
        deleteOrderItem,
        setDrawerOpen,
        localization,
        orderItemsRelatedProducts
    );
    return (
        <Container>
            <ProductsModal
                title={'Products'}
                showQuantities
                onSubmitProductsCallBack={async (products: productGridRow[]) => {
                    const itemsToCreate: Array<createOrderItemInput> = products.map((product) => ({
                        productId: product.id,
                        name: product.name,
                        productSku: product.sku,
                        // partNumber: product.partNumber,
                        quantity: product.quantity,
                        length: product.length,
                        height: product.height,
                        width: product.width,
                        dimensionsUnit: product.dimensionUnit,
                        weight: product.weight,
                        weightUnit: product.weightUnit,
                        price: product.retailPrice,
                        currency: product.retailPriceCurrency,
                        attributes: product.selectedAttributes,
                        hsCodes: product.hsCodes
                    }));

                    await createOrderItems({ orderId: order.id, items: itemsToCreate });
                }}
                showAttributesModal
                Trigger={(props: ModalTriggerProps) => {
                    return (
                        <AddContainer>
                            <Button onClick={props.onClick} width="auto" buttonType="hollow" padding="4px 12px" type="button">
                                <span className="material-icons">add</span>
                                <span>{'Add Item'}</span>
                            </Button>
                        </AddContainer>
                    );
                }}
            />
            <MuiDataGrid
                columns={ItemsGridColumns}
                rows={order.items || []}
                autoHeight
                disableColumnFilter
                disableColumnMenu
                disableColumnReorder
                disableSelectionOnClick
            />
        </Container>
    );
};

const Container = styled.div``;

const AddContainer = styled.div`
    cursor: pointer;
    display: inline-flex;
    align-items: center;
    margin-right: 10px;
    font-size: 13px;
    line-height: 0;
    color: ${(props) => props.theme.colors.primaryBlue};
    span.material-icons {
        font-size: inherit;
        margin-right: 5px;
        user-select: none;
    }
`;

const mapStateToProps = (state: RootState) => ({
    localization: localizationSelectors.ordersPage(state).fields.item,
    orderItemsRelatedProducts: singleOrderSelectors.orderItemsRelatedProducts(state)
});

const mapDispatchToProps = (dispatch: any) => ({
    createOrderItems: (payload: createOrderItemsPayload) => dispatch.orders.createOrderItems(payload),
    updateOrderItem: (payload: updateOrderItemPayload) => dispatch.orders.updateOrderItem(payload),
    deleteOrderItem: (payload: deleteOrderItemPayload) => dispatch.orders.deleteOrderItem(payload),
    setDrawerOpen: (open: drawers, params?: any) => dispatch.header.setDrawerOpenParams({ open, params }),
    fetchProducts: (ids: string[]) => dispatch.products.fetchProductsByIds(ids)
});

export default connect(mapStateToProps, mapDispatchToProps)(OverviewItems);
