import { task } from '../../../models/entities/task';
import { ModelConfig } from '@rematch/core';
import { localSDK as client } from '../../../sdk';

export type tasksStateType = {
    tasks: Array<task> | null;
    error: string;
    isFetching: boolean;
};

export const tasks: ModelConfig<tasksStateType> = {
    state: {
        isFetching: false,
        tasks: null,
        error: ''
    },
    reducers: {
        fetchTasksStarted(state: tasksStateType): tasksStateType {
            return { ...state, isFetching: true };
        },
        fetchTasksFinished(state: tasksStateType): tasksStateType {
            return { ...state, isFetching: false };
        },
        setTasks(state: tasksStateType, tasks: Array<task>): tasksStateType {
            return { ...state, tasks };
        },
        setError(state: tasksStateType, error: string): tasksStateType {
            return { ...state, error };
        },
        editTask(state: tasksStateType, task: task): tasksStateType {
            const currentTasks = state.tasks;
            if (currentTasks === null) return state;
            const currentTask = currentTasks.find((x) => x.id === task.id);
            if (!currentTask) return state;
            const currentTaskIndex = currentTasks.indexOf(currentTask);
            const newTasks = [...currentTasks];
            newTasks[currentTaskIndex] = task;
            return { ...state, tasks: newTasks };
        },
        addTask(state: tasksStateType, task: task): tasksStateType {
            const currentTasks = state.tasks;
            if (currentTasks === null) return state;
            const newTasks = [...currentTasks, task];
            return { ...state, tasks: newTasks };
        },
        removeTask(state: tasksStateType, taskId: string): tasksStateType {
            const currentTasks = state.tasks;

            if (currentTasks === null) return state;
            const newTasks = [...currentTasks];
            const taskIndex = newTasks.findIndex((t) => t.id === taskId);

            if (taskIndex === -1) return state;
            newTasks.splice(taskIndex, 1);

            return { ...state, tasks: newTasks };
        }
    },
    effects: (dispatch: any) => ({
        async fetchTasks() {
            dispatch.tasks.fetchTasksStarted();
            const tasks = await client.task().fetchTasks();
            dispatch.tasks.setTasks(tasks);
            dispatch.tasks.fetchTasksFinished();
        },
        async createTask(task: task) {
            const createdTask = await client.task().createTask(task);
            dispatch.tasks.addTask(createdTask);
        },
        async updateTask(task: task) {
            dispatch.tasks.editTask(task);
            await client.task().updateTask(task);
        },
        async deleteTask(taskId: string) {
            dispatch.tasks.removeTask(taskId);
            await client.task().deleteTask(taskId);
        }
    })
};
