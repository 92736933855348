import React, { useState } from 'react';
import { connect } from 'react-redux';
import { businessPartner } from '../../../../../models/entities/businessPartner/businessPartner';
import { RootState } from '../../../../../state/store/store';
import SendGetStartedInventationModalContent from './SendGetStartedInventationModalContent';
import CustomModal, { ModalTriggerProps } from '../../../../shared/Modal/Modal';
import { localizationSelectors } from '../../../../../state/ducks/localization/selectors';
import { emailPattern } from '../../../../../utils/patterns';

type Props = {
    businessPartner: businessPartner;
    sendGetStartedInventationEmail: (businessPartnerId: string, email: string) => Promise<void>;
    isSendingGetStartedInventation: boolean;
    Trigger: (props: ModalTriggerProps) => JSX.Element;
    localization: any;
};
const SendGetStartedInventationModal = ({
    businessPartner,
    sendGetStartedInventationEmail,
    isSendingGetStartedInventation,
    Trigger,
    localization
}: Props) => {
    const defaultEmail = businessPartner.email || businessPartner.contacts?.[0]?.email || undefined;

    const [email, setEmail] = useState<string | undefined>(defaultEmail);
    return (
        <CustomModal
            title={{ text: localization.invite_to_join_cargozone }}
            contentPadding="10px 30px 10px 30px"
            Trigger={Trigger}
            Content={(setVisible?: React.Dispatch<React.SetStateAction<boolean>>, setConfirm?: React.Dispatch<React.SetStateAction<boolean>>) => {
                return (
                    <SendGetStartedInventationModalContent
                        businessPartner={businessPartner}
                        email={email}
                        setEmail={(email: string) => setEmail(email)}
                        openedFromModal={setVisible ? { setVisible } : undefined}
                    />
                );
            }}
            confirmBtn={{
                handler: async () => {
                    if (email) {
                        await sendGetStartedInventationEmail(businessPartner.id, email);
                    }
                },
                text: 'Invite',
                closeModal: true,
                disabled: !email?.match(emailPattern),
                isLoading: isSendingGetStartedInventation
            }}
            width="400px"
        />
    );
};

const mapStateToProps = (state: RootState) => ({
    localization: localizationSelectors.SingleBusinessPartnerPage(state),
    isSendingGetStartedInventation: !!state.loading.effects.businessPartners.sendGetStartedInventationEmail
});

const mapDispatchToProps = (dispatch: any) => ({
    sendGetStartedInventationEmail: (businessPartnerId: string, email: string) =>
        dispatch.businessPartners.sendGetStartedInventationEmail({ businessPartnerId, email })
});

export default connect(mapStateToProps, mapDispatchToProps)(SendGetStartedInventationModal);
