import React from 'react';
import { connect } from 'react-redux';
import { userSettings } from '../../../../models/entities/user';
import { userSelectors } from '../../../../state/ducks/user/selectors';
import { RootState } from '../../../../state/store/store';
import { BaseProps } from '../base/DropDown';
import FormDropDown from './DropDown';

type Props = BaseProps & {
    name: string;
    emailsAutoCompleteSettings?: userSettings;
};

const UserSettingsDropDown = ({ name, emailsAutoCompleteSettings, ...restProps }: Props) => {
    return (
        <FormDropDown
            name="emails"
            mode="tags"
            {...restProps}
            options={
                emailsAutoCompleteSettings?.value?.map((item: string) => {
                    return { value: item, text: item };
                }) || []
            }
        ></FormDropDown>
    );
};
const mapStateToProps = (state: RootState) => ({
    emailsAutoCompleteSettings: userSelectors.emailsAutoCompleteSettings(state)
});

const mapDispatchToProps = (dispatch: any) => ({});

export default connect(mapStateToProps, mapDispatchToProps)(UserSettingsDropDown);
