import * as Yup from 'yup';
import moment from 'moment';

export const validationSchema = (localizations: any) => {
    const requiredFieldMessage = localizations.form.requiredFieldMessage;
    const dateAfterMessage = localizations.form.dateAfterMessage;
    return Yup.object({
        vendorId: Yup.string().required(requiredFieldMessage).nullable(),
        origin_country: Yup.string().required(requiredFieldMessage),
        origin_zipcode: Yup.string().required(requiredFieldMessage).nullable(),
        destination_pickup_address: Yup.string().test('len', 'Max 150 characters', maxLengthValidation(150)),
        origin_pickup_address: Yup.string().test('len', 'Max 150 characters', maxLengthValidation(150)),
        note: Yup.string().test('len', 'Max 150 characters', maxLengthValidation(150))
    });
};

const maxLengthValidation = (maxLength: number) => {
    return (val: any) => (val ? val.toString().length <= maxLength : true);
};
