import { ColumnType } from '../../shared/Grid/types/Column';
import { businessPartner } from '../../../models/entities/businessPartner/businessPartner';

export const gridColumns: Array<ColumnType<businessPartner>> = [
    {
        field: 'name',
        sortable: true,
        isDisplayed: true,
        width: '300px',
        columnType: 'companyLink',
        bindedFields: ['id']
    },
    {
        field: 'BusinessPartnerNumber',
        sortable: true,
        isDisplayed: true,
        width: '150px',
        columnType: 'companyLink',
        bindedFields: ['id']
    },
    {
        field: 'id',
        isDisplayed: true,
        sortable: false,
        width: '100px',
        columnType: 'copyCell'
    },
    {
        field: 'type',
        sortable: true,
        columnType: 'party_name',
        bindedFields: ['type']
    },
    {
        field: 'phoneNumber',
        sortable: true
    },
    {
        field: 'email',
        sortable: true
    },
    {
        field: 'vatNumber',
        sortable: true
    },
    {
        field: 'website',
        sortable: true
    },
    {
        field: 'paymentTerms',
        sortable: true
    },
    {
        field: 'paymentTermsRemarks',
        width: '200px',
        sortable: true
    },
    {
        field: 'taxesDuties',
        sortable: true
    },
    {
        field: 'monthlyIntrest',
        sortable: true
    },
    {
        field: 'creditLine',
        sortable: true,
        bindedFields: ['creditLineCurrency'],
        columnType: 'currency_input'
    }
];
