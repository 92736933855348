import React, { useState } from 'react';
import styled from 'styled-components';
import { event } from '../../../../models/entities/common_subentities/event';
import { purchaseDemand, settings } from '../../../../models/entities/purchaseDemand/purchaseDemand';
import { purchaseDemandItem } from '../../../../models/entities/purchaseDemand/purchaseDemandItem';
import CustomModal, { ModalTriggerProps } from '../../../shared/Modal/Modal';
import clock from '../../../../static/icons/clock.svg';
import Button from '../../../shared/SmallComponents/Button';
import ItemsTable from './purchaseDemandItems/ItemsTable';
import Loader from '../../../shared/SmallComponents/Loader';
import Grid from '../../../shared/Grid/Grid';
import columns from './purchaseDemandItems/gridColumns/columns';
import Items from '../PurchaseDemandForm/sections/Items';
import ItemsForm from '../ItemsForm/ItemsForm';
import { Formik } from 'formik';
import { Form } from 'formik-antd';
import { RootState } from '../../../../state/store/store';
import { connect } from 'react-redux';
import { Modal } from 'antd';
import { claimsDomainsType } from '../../../../userClaims';
import claimsType from '../../../../models/entities/claimsType';
import { userSelectors } from '../../../../state/ducks/user/selectors';

type Props = {
    purchaseDemand: purchaseDemand;
    items?: Array<purchaseDemandItem>;
    localization: any;
    printMode?: boolean;
    settings?: settings;
    updatePurchaseDemandItem: (item: purchaseDemandItem) => Promise<void>;
    createPurchaseDemandItems: (purchaseDemandId: string, items: Array<purchaseDemandItem>) => Promise<void>;
    deletePurchaseDemandItem: (purchaseDemandId: string, itemId: string) => void;
    getUserClaims: (domain: claimsDomainsType) => claimsType;
};

const ItemsTab = ({
    items,
    purchaseDemand,
    localization,
    settings,
    updatePurchaseDemandItem,
    createPurchaseDemandItems,
    deletePurchaseDemandItem,
    getUserClaims
}: Props) => {
    const [modalVisible, setVisible] = useState(false);
    const [updateItemQuantityTimeout, setUpdateItemQuantityTimeout] = useState(0);
    const [item, setItem] = useState({} as purchaseDemandItem);
    const isPdStateChanged =
        purchaseDemand &&
        (purchaseDemand.approvers?.some((app) => app.state !== 'WAITING') ||
            ['APPROVED', 'PARTIAL_APPROVED', 'REJECTED'].includes(purchaseDemand?.state));
    return (
        <ItemsWrapper>
            <Formik
                initialValues={{
                    companyId: purchaseDemand.companyId,
                    items: purchaseDemand?.items || []
                }}
                onSubmit={() => {}}
                enableReinitialize
            >
                {({ values, errors, setFieldValue, isSubmitting, isValidating }) => {
                    return (
                        <Form>
                            <Items
                                values={values}
                                setFieldValue={setFieldValue}
                                onAddItemsCallback={
                                    !getUserClaims('pd').add_pd_item
                                        ? undefined
                                        : async (items: purchaseDemandItem[]) => {
                                              await createPurchaseDemandItems(purchaseDemand.id, items);
                                          }
                                }
                                onUpdatePurchaseDemandItem={
                                    !getUserClaims('pd').edit_pd_item
                                        ? undefined
                                        : async (updatedItem: purchaseDemandItem, itemIndex?: string, cancelTimeoutInterval?: boolean) => {
                                              const updateItem = async () => {
                                                  if (isPdStateChanged) {
                                                      Modal.confirm({
                                                          zIndex: 1100,
                                                          title: <div>{localization.are_you_sure}</div>,
                                                          content: <div>{localization.reset_po_state_message}</div>,
                                                          async onOk() {
                                                              await updatePurchaseDemandItem({ ...updatedItem, purchaseDemandId: purchaseDemand.id });
                                                          },
                                                          onCancel() {
                                                              const originalItem = purchaseDemand?.items?.find((item) => item.id === updatedItem.id);
                                                              if (itemIndex && originalItem) setFieldValue(`items[${itemIndex}]`, originalItem);
                                                          }
                                                      });
                                                  } else {
                                                      await updatePurchaseDemandItem({ ...updatedItem, purchaseDemandId: purchaseDemand.id });
                                                  }
                                              };

                                              if (cancelTimeoutInterval) {
                                                  return await updateItem();
                                              }

                                              clearTimeout(updateItemQuantityTimeout);
                                              const timeout = setTimeout(async () => {
                                                  await updateItem();
                                              }, 500);
                                              setUpdateItemQuantityTimeout(timeout);
                                          }
                                }
                                onUpdateItemCost={
                                    !getUserClaims('pd').edit_pd_item_cost || !getUserClaims('pd').edit_pd_item
                                        ? undefined
                                        : async (updatedItem: purchaseDemandItem) => {
                                              await updatePurchaseDemandItem({ ...updatedItem, purchaseDemandId: purchaseDemand.id });
                                          }
                                }
                                onDeleteItem={(itemId: string) => {
                                    Modal.confirm({
                                        zIndex: 1100,
                                        content: <div>{localization.are_you_sure}</div>,
                                        onOk() {
                                            deletePurchaseDemandItem(purchaseDemand.id, itemId);
                                            const newItems = items?.filter((item) => item.id !== itemId);
                                            setFieldValue('items', newItems);
                                        },
                                        onCancel() {}
                                    });
                                }}
                                purchaseDemand={purchaseDemand}
                                disableAdd={!getUserClaims('pd').add_pd_item}
                                disableEdit={!getUserClaims('pd').edit_pd_item}
                                disableDelete={!getUserClaims('pd').delete_pd_item}
                            />
                        </Form>
                    );
                }}
            </Formik>
        </ItemsWrapper>
    );
};

const GridWrapper = styled.div`
    flex: 0 0 100%;
    max-width: 100%;
    width: 100%;
    box-sizing: border-box;
    overflow-y: overlay;
    height: 100%;
    width: 100%;
`;

const ItemsWrapper = styled.div`
    display: flex;
    flex-direction: column;
    width: 100%;
    padding-top: 8px;
`;

const EmaptyStateText = styled.span`
    color: ${(props) => props.theme.colors.emptyStateText};
`;
const ButtonContainer = styled.div`
    display: flex;
    justify-content: flex-end;
    padding-top: 8px;
`;
const mapStateToProps = (state: RootState) => ({
    getUserClaims: (domain: claimsDomainsType) => userSelectors.getUserClaims(state)(domain)
});

const mapDispatchToProps = (dispatch: any) => ({
    createPurchaseDemandItems: (purchaseDemandId: string, items: Array<purchaseDemandItem>) =>
        dispatch.purchaseDemands.createPurchaseDemandItems({ purchaseDemandId, items }),
    updatePurchaseDemandItem: (item: purchaseDemandItem) => dispatch.purchaseDemands.updatePurchaseDemandItem(item),
    deletePurchaseDemandItem: (purchaseDemandId: string, itemId: string) =>
        dispatch.purchaseDemands.deletePurchaseDemandItem({ purchaseDemandId, itemId })
});

export default connect(mapStateToProps, mapDispatchToProps)(ItemsTab);
