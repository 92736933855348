import React from 'react';
import { styled } from '../../Theme/theme';
import logo from '../../../../static/common/logo.svg';
import { connect } from 'react-redux';
import { RootState } from '../../../../state/store/store';
import { Language, languages } from '../../../../models/entities/language';

type Props = {
    onLanguageSelected: (language: Language) => void;
};

const PublicHeader = ({ onLanguageSelected }: Props) => {
    return (
        <HeaderContainer>
            <StyledLogo src={logo} alt="CARGOZONE" />
            <LanguageSelector>
                <span onClick={() => onLanguageSelected(languages.English)}>EN</span> |{' '}
                <span onClick={() => onLanguageSelected(languages.Spanish)}>ES</span> |{' '}
                <span onClick={() => onLanguageSelected(languages.Chinese)}>CN</span>
            </LanguageSelector>
        </HeaderContainer>
    );
};

const LanguageSelector = styled.div`
    font-size: ${(props) => props.theme.fontSizes.languageSelector};
    color: ${(props) => props.theme.colors.pageTitle};
    font-weight: bold;
    text-align: center;
    cursor: pointer;
`;

const HeaderContainer = styled.div`
    height: 100%;
    margin-left: 10px;
    position: relative;
    display: flex;
    align-items: center;
`;

const StyledLogo = styled.img`
    width: 175px;
    height: 30px;
    margin: 0 14px;
`;
const mapStateToProps = (state: RootState) => ({});

const mapDispatchToProps = (dispatch: any) => ({
    onLanguageSelected: (language: Language) => dispatch.localization.changeLanguage(language)
});

export default connect(mapStateToProps, mapDispatchToProps)(PublicHeader);
