import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { company } from '../../../../models/entities/company/company';
import { companiesSelectors } from '../../../../state/ducks/companies/selectors';
import { drawers } from '../../../../state/ducks/header/header';
import { localizationSelectors } from '../../../../state/ducks/localization/selectors';
import { RootState } from '../../../../state/store/store';
import CompaniesMgmt from './CompaniesMgmtPage';
import { adminSelectors } from '../../../../state/ducks/admin/selectors';
import { spApiLwaAuthorizationPayload } from '../../../../models/entities/company/spApiLwaAuthorizationPayload';
import { Modal } from 'antd';
import { userSelectors } from '../../../../state/ducks/user/selectors';
import { featureFlagsModel } from '../../../../models/entities/businessPartner/settings';
import { adsApiAuthorizationPayload } from '../../../../models/entities/company/adsApiAuthorizationPayload';

type Props = {
    companies: Array<company>;
    fetchMyCompanies: () => Promise<void>;
    showLoader: boolean;
    localization: any;
    setDrawerOpen: (open: drawers, params?: any) => void;
    setGridFilter: (text: string) => void;
    gridFilter: string | null;
    getLwaState: (companyId: string) => Promise<string>;
    spApiLwaAuthorization: (payload: spApiLwaAuthorizationPayload) => Promise<boolean>;
    adsApiAuthorization: (payload: adsApiAuthorizationPayload) => Promise<boolean>;
    createIntegrationApiKeys: (companyId: string) => Promise<void>;
    isCreatingApiKeys: boolean;
    featureFlags?: featureFlagsModel;
};
const CompaniesMgmtPageConnected = ({
    companies,
    fetchMyCompanies,
    showLoader,
    localization,
    setDrawerOpen,
    setGridFilter,
    gridFilter,
    getLwaState,
    spApiLwaAuthorization,
    adsApiAuthorization,
    createIntegrationApiKeys,
    isCreatingApiKeys,
    featureFlags
}: Props) => {
    const lwaAuthorization = async () => {
        const queryString = window.location.search;
        const urlParams = new URLSearchParams(queryString);

        const [lwaState, sellingPartnerId, spapiOAuthCode, code, scope] = [
            urlParams.get('state'),
            urlParams.get('selling_partner_id'),
            urlParams.get('spapi_oauth_code'),
            urlParams.get('code'),
            urlParams.get('scope')
        ];

        let response;

        if (lwaState && code && scope) {
            response = await adsApiAuthorization({
                code,
                state: lwaState
            });
        }
        if (lwaState && sellingPartnerId && spapiOAuthCode) {
            response = await spApiLwaAuthorization({
                state: lwaState,
                sellingPartnerId,
                spapiOAuthCode
            });
        }

        if (response) {
            Modal.success({
                zIndex: 1100,
                title: localization.api_connection.connection_successful,
                onOk() {}
            });
        }
    };

    useEffect(() => {
        (async () => {
            if (!!featureFlags?.AMAZON) await lwaAuthorization();
            await fetchMyCompanies();
        })();
        return function cleanup() {
            setGridFilter('');
        };
    }, []);

    return (
        <CompaniesMgmt
            fetchMyCompanies={fetchMyCompanies}
            companies={companies}
            localization={localization}
            showLoader={showLoader}
            setDrawerOpen={setDrawerOpen}
            setGridFilter={setGridFilter}
            gridFilter={gridFilter ?? ''}
            getLwaState={getLwaState}
            spApiLwaAuthorization={spApiLwaAuthorization}
            adsApiAuthorization={adsApiAuthorization}
            featureFlags={featureFlags}
        />
    );
};

const mapStateToProps = (state: RootState) => ({
    companies: companiesSelectors.companiesData(state),
    showLoader:
        !!state.loading.effects.companies.fetchMyCompanies ||
        !!state.loading.effects.companies.spApiLwaAuthorization ||
        !!state.loading.effects.companies.adsApiAuthorization,
    localization: localizationSelectors.companiesMgmtPage(state),
    gridFilter: adminSelectors.gridFilter(state),
    featureFlags: userSelectors.featureFlags(state)
});

const mapDispatchToProps = (dispatch: any) => ({
    fetchMyCompanies: () => dispatch.companies.fetchMyCompanies(),
    setDrawerOpen: (open: drawers, params?: any) => dispatch.header.setDrawerOpenParams({ open, params }),
    setGridFilter: (text: string) => dispatch.admin.setGridFilter(text),
    getLwaState: (companyId: string) => dispatch.companies.getLwaState(companyId),
    spApiLwaAuthorization: (payload: spApiLwaAuthorizationPayload) => dispatch.companies.spApiLwaAuthorization(payload),
    adsApiAuthorization: (payload: adsApiAuthorizationPayload) => dispatch.companies.adsApiAuthorization(payload)
});

export default connect(mapStateToProps, mapDispatchToProps)(CompaniesMgmtPageConnected);
