import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { RootState } from '../../../state/store/store';
import { shipment, shipmentSettingsFunc } from '../../../models/entities/shipment/shipment';
import { drawers } from '../../../state/ducks/header/header';
import Loader from '../SmallComponents/Loader';
import ShipmentPane from './ShipmentPane';
import { localizationSelectors } from '../../../state/ducks/localization/selectors';
import { singleShipmentSelectors } from '../../../state/ducks/singleShipment/selectors';
import { businessPartnersSelectors } from '../../../state/ducks/businessPartner/selectors';
import { settings } from '../../../models/entities/businessPartner/businessPartner';
import { settings as shipmentSettings } from '../../../models/entities/shipment/shipment';
import { shipmentsSelectors } from '../../../state/ducks/shipments/selectors';

type Props = {
    params?: any;
    fetchShipmentById: (shipmentId: string) => Promise<shipment>;
    localization: any;
    isLoadingShipment: boolean;
    settings?: settings;
    shipmentSettings: shipmentSettingsFunc;
    setDrawerOpen: (open: drawers) => void;
};

const ShipmentPaneConnected = ({ fetchShipmentById, localization, settings, params, isLoadingShipment, shipmentSettings, setDrawerOpen }: Props) => {
    const { shipmentId } = params;
    const [currShipment, setShipment] = useState<shipment | null>(null);
    useEffect(() => {
        setShipment(null);
        if (shipmentId) {
            fetchShipmentById(shipmentId).then((value) => {
                setShipment(value);
            });
        }
    }, [fetchShipmentById, shipmentId]);

    return !currShipment ? (
        <Loader showText={true} />
    ) : (
        <ShipmentPane
            shipment={currShipment}
            localization={localization.pane}
            settings={settings}
            shipmentSettings={shipmentSettings(currShipment)}
            setDrawerOpen={setDrawerOpen}
        />
    );
};

const mapStateToProps = (state: RootState) => ({
    localization: localizationSelectors.singleShipmentPage(state),
    settings: businessPartnersSelectors.settingsByLanguage(state),
    shipmentSettings: shipmentsSelectors.settingsByLanguage(state),
    isLoadingShipment: singleShipmentSelectors.isLoadingShipment(state)
});

const mapDispatchToProps = (dispatch: any) => ({
    fetchShipmentById: (shipmentId: string) => dispatch.shipments.fetchShipmentById(shipmentId),
    setDrawerOpen: (open: drawers) => dispatch.header.setDrawerOpen(open)
});

export default connect(mapStateToProps, mapDispatchToProps)(ShipmentPaneConnected);
