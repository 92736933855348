import { product } from '../../../models/entities/product/product';
import { ModelConfig } from '@rematch/core';
import { localSDK as client } from '../../../sdk';
import { ColumnType } from '../../../components/shared/Grid/types/Column';
import SideEffectJS from 'side-effect-js';
import { document } from '../../../models/entities/common_subentities/document';
import { addEntity, editEntity, removeEntity } from '../../util/common_subentities/generic';
import { attribute, attributeOption } from '../../../models/entities/product/attribute';
import { link } from '../../../models/entities/product/link';
import { history } from '../../../models/entities/common_subentities/history';
import { setHistory } from '../../util/common_subentities/history';
import moment from 'moment';

export type paginationType = { currentPage: number; rowsPerPage: number };

export type productsStateType = {
    products: Array<product>;
    gridColumns: Array<ColumnType<product>>;
    fetchGridColumnsError: string | null;
    gridSort: gridSortState | null;
    gridFilter: string | null;
    filterFields: filterFieldsType | null;
    singleProductId: string | null;
    pagination: paginationType;
};

export type filterFieldsType = Array<{ field: keyof product; value: any }>;
export type gridSortState = {
    direction: 'asc' | 'desc';
    column: keyof product;
};

export type exportTypes = 'PDF' | 'EXCEL';

const defaultGridSort: gridSortState = {
    direction: 'desc',
    column: 'updatedAt'
};
type createProductPayload = {
    product: product;
};

type updateProductPayload = {
    id: string;
    product: product;
    deletedSuppliers?: string[];
};
type updateProductIsActivePayload = {
    id: string;
    isActive: boolean;
};
export const products: ModelConfig<productsStateType> = {
    state: {
        products: [],
        gridColumns: [],
        fetchGridColumnsError: null,
        gridSort: defaultGridSort,
        gridFilter: null,
        filterFields: [],
        singleProductId: null,
        pagination: {
            currentPage: 0,
            rowsPerPage: 30
        }
    },
    reducers: {
        setProducts(state: productsStateType, products: Array<product>): productsStateType {
            return { ...state, products };
        },
        setMultipleProducts(state: productsStateType, products: Array<product>): productsStateType {
            const newProducts = [...state.products];
            products.forEach((product) => {
                const productIndex = newProducts.findIndex((p) => p.id === product.id);
                if (productIndex !== -1) {
                    newProducts[productIndex] = product;
                } else {
                    newProducts.push(product);
                }
            });
            return { ...state, products: newProducts };
        },
        setProductIsActive(state: productsStateType, id: string, isActive: boolean): productsStateType {
            const newProducts = [...state.products];
            const productIndex = newProducts.findIndex((product) => product.id === id);
            if (productIndex !== -1) {
                newProducts[productIndex] = { ...newProducts[productIndex], isActive };
            }
            return { ...state, products: newProducts };
        },
        setSingleProduct(state: productsStateType, newProduct: product): productsStateType {
            const newProducts = [...state.products];
            const productIndex = newProducts.findIndex((product) => product.id === newProduct.id);
            if (productIndex !== -1) {
                newProducts[productIndex] = newProduct;
            } else {
                newProducts.push(newProduct);
            }
            return { ...state, products: newProducts };
        },
        setSingleProductId(state: productsStateType, productId: string | null): productsStateType {
            return { ...state, singleProductId: productId };
        },
        removeProduct(state: productsStateType, productId: string): productsStateType {
            const newProducts = [...state.products];
            return { ...state, products: newProducts.filter((item) => item.id !== productId) };
        },
        setGridColumns(state: productsStateType, columns: Array<ColumnType<product>>): productsStateType {
            return { ...state, gridColumns: columns, fetchGridColumnsError: null };
        },
        addProductDocument(state: productsStateType, productNumber: string, document: document): productsStateType {
            return { ...state, products: addEntity<document>(state, productNumber, 'products', 'documents', document) };
        },
        editProductDocument(state: productsStateType, productNumber: string, document: document): productsStateType {
            return { ...state, products: editEntity<document>(state, productNumber, 'products', 'documents', document) };
        },
        removeProductDocument(state: productsStateType, productNumber: string, documentId: number): productsStateType {
            return { ...state, products: removeEntity<document>(state, productNumber, 'products', 'documents', documentId) };
        },
        addProductAttribute(state: productsStateType, productNumber: string, attribute: attribute): productsStateType {
            return { ...state, products: addEntity<attribute>(state, productNumber, 'products', 'attributes', attribute) };
        },
        editProductAttribute(state: productsStateType, productNumber: string, attribute: attribute): productsStateType {
            return { ...state, products: editEntity<attribute>(state, productNumber, 'products', 'attributes', attribute) };
        },
        removeProductAttribute(state: productsStateType, productNumber: string, attributeId: number): productsStateType {
            return { ...state, products: removeEntity<attribute>(state, productNumber, 'products', 'attributes', attributeId) };
        },
        addProductAttributeOption(
            state: productsStateType,
            { productId, attributeId, attributeOption }: { productId: string; attributeId: string; attributeOption: attributeOption }
        ): productsStateType {
            const newProducts = [...state.products];
            const index = newProducts.findIndex((item) => item.id === productId);
            if (index !== -1) {
                newProducts[index].attributes = addEntity<attributeOption>(newProducts[index], attributeId, 'attributes', 'options', attributeOption);
            }
            return { ...state, products: newProducts };
        },
        editProductAttributeOption(
            state: productsStateType,
            { productId, attributeId, attributeOption }: { productId: string; attributeId: string; attributeOption: attributeOption }
        ): productsStateType {
            const newProducts = [...state.products];
            const index = newProducts.findIndex((item) => item.id === productId);
            if (index !== -1) {
                newProducts[index].attributes = editEntity<attributeOption>(
                    newProducts[index],
                    attributeId,
                    'attributes',
                    'options',
                    attributeOption
                );
            }
            return { ...state, products: newProducts };
        },
        removeProductAttributeOption(
            state: productsStateType,
            { productId, attributeId, attributeOptionId }: { productId: string; attributeId: string; attributeOptionId: number }
        ): productsStateType {
            const newProducts = [...state.products];
            const index = newProducts.findIndex((item) => item.id === productId);
            if (index !== -1) {
                newProducts[index].attributes = removeEntity<attributeOption>(
                    newProducts[index],
                    attributeId,
                    'attributes',
                    'options',
                    attributeOptionId
                );
            }
            return { ...state, products: newProducts };
        },
        setLatestProductImgUrl(state: productsStateType, productId: string): productsStateType {
            const newProducts = [...state.products];
            const index = newProducts.findIndex((item) => item.id === productId);
            if (index !== -1) {
                const latestNewProductImageUrl = newProducts[index]?.documents
                    ?.filter((d: document) => d.type.includes('PRODUCT_IMG'))
                    ?.sort((a, b) => moment(b.createdAt).valueOf() - moment(a.createdAt).valueOf())[0]?.imgUrl;

                if (latestNewProductImageUrl) {
                    const newProduct: product = { ...newProducts[index], latestProductImgUrl: latestNewProductImageUrl };
                    newProducts[index] = newProduct;
                }
            }
            return { ...state, products: newProducts };
        },
        addProductLink(state: productsStateType, productNumber: string, link: link): productsStateType {
            return { ...state, products: addEntity<link>(state, productNumber, 'products', 'links', link) };
        },
        editProductLink(state: productsStateType, productNumber: string, link: link): productsStateType {
            return { ...state, products: editEntity<link>(state, productNumber, 'products', 'links', link) };
        },
        removeProductLink(state: productsStateType, productNumber: string, linkId: number): productsStateType {
            return { ...state, products: removeEntity<link>(state, productNumber, 'products', 'links', linkId) };
        },

        setProductHistory(state: productsStateType, productId: string, productHistory: Array<history>): productsStateType {
            return { ...state, products: setHistory(state, productId, 'products', productHistory) };
        },
        setFetchColumnsError(state: productsStateType, error: string): productsStateType {
            return { ...state, fetchGridColumnsError: error };
        },
        setGridSort(state: productsStateType, sortData: gridSortState): productsStateType {
            return { ...state, gridSort: sortData };
        },
        setGridFilter(state: productsStateType, filterText: string): productsStateType {
            return { ...state, gridFilter: filterText };
        },
        setCurrentPage(state: productsStateType, currentPage: number): productsStateType {
            return { ...state, pagination: { ...state.pagination, currentPage } };
        },
        setRowsPerPage(state: productsStateType, rowsPerPage: number): productsStateType {
            return { ...state, pagination: { ...state.pagination, rowsPerPage } };
        },
        setFilterFields(state: productsStateType, filterFields: filterFieldsType): productsStateType {
            return { ...state, filterFields };
        }
    },
    effects: (dispatch: any) => ({
        async fetchProducts() {
            const products = await client.products().fetchProducts();
            dispatch.products.setProducts(products);
            dispatch.products.setCurrentPage(0);
        },
        async fetchProductsWithAttributes() {
            const products = await client.products().fetchProductsWithAttributes();
            dispatch.products.setProducts(products);
        },
        async fetchProductById(productId: string) {
            const productData = await client.products().fetchById(productId);
            dispatch.products.setSingleProduct(productData);

            return productData;
        },
        async fetchProductsByIds(productsIds: string[]) {
            const products = await client.products().fetchByIds(productsIds);
            dispatch.products.setMultipleProducts(products);

            return products;
        },

        async fetchProductHistoryById(productId: string) {
            const productHistoryData = await client.products().history().fetchProductHistoryById(productId);
            dispatch.products.setProductHistory(productId, productHistoryData);
        },
        async createProduct(product: product) {
            const createdProduct = await client.products().createProduct(product);
            dispatch.products.setSingleProduct(createdProduct);
            return createdProduct.id;
        },
        async duplicateProduct({
            product,
            origProductId,
            includeDocuments,
            includeAttributes
        }: {
            product: product;
            origProductId: string;
            includeDocuments?: boolean;
            includeAttributes?: boolean;
        }) {
            const createdProduct = await client.products().duplicateProduct(product, origProductId, includeDocuments, includeAttributes);
            dispatch.products.setSingleProduct(createdProduct);
            return createdProduct.id;
        },
        async updateProductIsActive({ id, isActive }: updateProductIsActivePayload) {
            await client.products().updateProductIsActive(id, isActive);
            dispatch.products.setProductIsActive(id, isActive);
            return true;
        },
        async updateProduct({ id, product, deletedSuppliers }: updateProductPayload) {
            const updatedProduct = await client.products().updateProduct(id, product, deletedSuppliers);
            dispatch.products.setSingleProduct(updatedProduct);
            return true;
        },
        async uploadProductsByExcel({ file, companyId, companyName }: { file: File; companyId?: string; companyName?: string }) {
            await client.products().uploadProductsByExcel(file, companyId, companyName);
            dispatch.products.fetchProducts();
            return true;
        },
        async fetchGridColumns() {
            const fetchGridColumns = SideEffectJS.Get('gridColumnsProductsPage');
            const gridColumns = await fetchGridColumns();
            dispatch.products.setGridColumns(gridColumns);
        },
        async setProductFilters(filter: filterFieldsType) {
            dispatch.products.setFilterFields(filter);
            dispatch.products.setCurrentPage(0);
        },
        async fetchProductsBySupplierId(supplierId: string) {
            const products = await client.products().fetchProductsByFilter([
                {
                    field: 'supplierId',
                    value: supplierId
                }
            ]);

            dispatch.products.setMultipleProducts(products);
        },
        async fetchProductsByFilter(filter: filterFieldsType) {
            const products = await client.products().fetchProductsByFilter(filter);
            dispatch.products.setProducts(products);
            dispatch.products.setCurrentPage(0);
        },
        async deleteProduct(productId: string) {
            await client.products().deleteProduct(productId);
            dispatch.products.removeProduct(productId);
        },

        async createMultipleProductDocument({ productId, documents }: { productId: string; documents: Array<document> }) {
            dispatch.loadingNotify.setLoadingState({ visible: true, text: 'Uploading files...' });
            const createdDocuments: document[] = await client.products().documents().createMultipleProductDocument(productId, documents);
            createdDocuments.forEach((document: document) => {
                dispatch.products.addProductDocument(productId, document);
            });

            dispatch.products.setLatestProductImgUrl(productId);
            dispatch.products.fetchProductHistoryById(productId);
            dispatch.loadingNotify.setLoadingState({ visible: false });
        },
        async createProductDocument({ productId, document }: { productId: string; document: document }) {
            const createdDocument = await client.products().documents().createProductDocument(productId, document);
            dispatch.products.addProductDocument(productId, createdDocument);
            dispatch.products.fetchProductHistoryById(productId);
        },
        async updateProductDocument({ productId, document }: { productId: string; document: document }) {
            await client.products().documents().updateProductDocument(productId, document);
            dispatch.products.editProductDocument(productId, document);
            dispatch.products.fetchProductHistoryById(productId);
        },
        async deleteProductDocument({ productId, documentId }: { productId: string; documentId: number }) {
            await client.products().documents().deleteProductDocument(productId, documentId);
            dispatch.products.removeProductDocument(productId, documentId);
            dispatch.products.setLatestProductImgUrl(productId);
            dispatch.products.fetchProductHistoryById(productId);
        },
        async createProductAttribute({ productId, attribute }: { productId: string; attribute: attribute }) {
            const createdAttribute = await client.products().attributes().createProductAttribute(productId, attribute);
            dispatch.products.addProductAttribute(productId, createdAttribute);
            dispatch.products.fetchProductHistoryById(productId);
            return true;
        },
        async updateProductAttribute({ productId, attribute }: { productId: string; attribute: attribute }) {
            const updatedAttribute = await client.products().attributes().updateProductAttribute(productId, attribute);
            dispatch.products.editProductAttribute(productId, updatedAttribute);
            dispatch.products.fetchProductHistoryById(productId);
            return true;
        },
        async deleteProductAttribute({ productId, attributeId }: { productId: string; attributeId: number }) {
            await client.products().attributes().deleteProductAttribute(productId, attributeId);
            dispatch.products.removeProductAttribute(productId, attributeId);
            dispatch.products.fetchProductHistoryById(productId);
        },
        async createProductLink({ productId, link }: { productId: string; link: link }) {
            const createdLink = await client.products().links().createProductLink(productId, link);
            dispatch.products.addProductLink(productId, createdLink);
            dispatch.products.fetchProductHistoryById(productId);
            return true;
        },
        async updateProductLink({ productId, link }: { productId: string; link: link }) {
            const updatedLink = await client.products().links().updateProductLink(productId, link);
            dispatch.products.editProductLink(productId, updatedLink);
            dispatch.products.fetchProductHistoryById(productId);
            return true;
        },
        async deleteProductLink({ productId, linkId }: { productId: string; linkId: number }) {
            await client.products().links().deleteProductLink(productId, linkId);
            dispatch.products.removeProductLink(productId, linkId);
            dispatch.products.fetchProductHistoryById(productId);
        },
        async createProductAttributeOption({
            productId,
            attributeId,
            attributeOption
        }: {
            productId: string;
            attributeId: string;
            attributeOption: attributeOption;
        }) {
            const createdAttribute = await client.products().attributes().createProductAttributeOption(attributeId, attributeOption);
            dispatch.products.addProductAttributeOption({ productId, attributeId, attributeOption: createdAttribute });
            dispatch.products.fetchProductHistoryById(productId);
            return true;
        },
        async updateProductAttributeOption({
            productId,
            attributeId,
            attributeOption
        }: {
            productId: string;
            attributeId: string;
            attributeOption: attributeOption;
        }) {
            await client.products().attributes().updateProductAttributeOption(attributeId, attributeOption);
            dispatch.products.editProductAttributeOption({ productId, attributeId, attributeOption });
            dispatch.products.fetchProductHistoryById(productId);
            return true;
        },
        async deleteProductAttributeOption({
            productId,
            attributeId,
            attributeOptionId
        }: {
            productId: string;
            attributeId: string;
            attributeOptionId: number;
        }) {
            await client.products().attributes().deleteProductAttributeOption(attributeId, attributeOptionId);
            dispatch.products.removeProductAttributeOption({ productId, attributeId, attributeOptionId });
            dispatch.products.fetchProductHistoryById(productId);
        },
        async createMultipleRelatedProducts({ productId, relatedProducts }: { productId: string; relatedProducts: string[] }) {
            const product = await client.products().createMultipleRelatedProducts(productId, relatedProducts);
            dispatch.products.setSingleProduct(product);
            dispatch.products.fetchProductHistoryById(productId);
            return true;
        },
        async createRelatedProduct({ firstProductId, secondProductId }: { firstProductId: string; secondProductId: string }) {
            const product = await client.products().createRelatedProduct(firstProductId, secondProductId);
            dispatch.products.setSingleProduct(product);
            dispatch.products.fetchProductHistoryById(firstProductId);
            return true;
        },
        async stopRelation({ firstProductId, secondProductId }: { firstProductId: string; secondProductId: string }) {
            const product = await client.products().stopRelation(firstProductId, secondProductId);
            dispatch.products.setSingleProduct(product);
            dispatch.products.fetchProductHistoryById(firstProductId);
            return true;
        }
    })
};
