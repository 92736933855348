import React, { PropsWithChildren, useRef, useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { IconButton, Popover } from '@material-ui/core';
import { RootState } from '../../../state/store/store';
import { userDetails, usersObject } from '../../../state/ducks/users/users';
import HexagonAvatar from '../SmallComponents/HexagonAvatar';
import { styled } from '../../shared/Theme/theme';
import { makeStyles } from '@material-ui/core/styles';
import Loader from '../SmallComponents/Loader';
import { usersSelectors } from '../../../state/ducks/users/selectors';
import { UserImageAvatar } from '../Layout/Header/AvatarHeader';
import { userInfo, userMyNetwork } from '../../../models/entities/user';
import { getUserStatusTheme } from '../Theme/util';
import { chatsSelectors } from '../../../state/ducks/chats/selectors';
import CZTooltip from '../CZTooltip/CZTooltip';
import { userSelectors } from '../../../state/ducks/user/selectors';
import { drawers } from '../../../state/ducks/header/header';
import { chatRequest } from '../../../models/entities/chat/chatRequest';
import MaterialPopover from '../Popover/MaterialPopover';
import { showConfirm } from '../Menu/Menu';
import { guidPattern } from '../../../utils/patterns';
import { openChatDrawerArgs } from '../../../state/ducks/chats/chats';

type Props = {
    openChatDrawer: (args: openChatDrawerArgs) => void;
    addUser: (userId: string) => Promise<userDetails>;
    getUserById: (userId: string) => userDetails;
    isLoadingUser: boolean;
    userId: string;
    popoverLeft?: boolean;
    setDrawerOpen: (open: drawers, params?: any) => void;
    userInfo: userInfo;
    inviteUser: (email: string) => void;
    stopNetwork: (userId: string, networkId?: string) => void;
    sentRequests?: Array<chatRequest>;
    toggleOnClick?: boolean;
    userName?: string;
};

const useStyles = makeStyles((theme) => ({
    popover: {
        pointerEvents: 'none'
    },
    popoverContent: {
        pointerEvents: 'auto',
        minWidth: '90px',
        minHeight: '90px',
        display: 'flex',
        alignItems: 'center',
        backgroundColor: 'transparent',
        boxShadow: 'none'
    }
}));

const UserCard: React.FC<PropsWithChildren<Props>> = ({
    openChatDrawer,
    addUser,
    getUserById,
    isLoadingUser,
    userId,
    popoverLeft,
    userInfo,
    setDrawerOpen,
    children,
    inviteUser,
    stopNetwork,
    sentRequests,
    toggleOnClick,
    userName
}) => {
    const [user, setUser] = useState<userDetails | null | undefined>(null);

    useEffect(() => {
        setUser(getUserById(userId));
    }, [getUserById, userId]);
    return (
        <MaterialPopover
            anchor={children}
            popoverEnterFn={async () => {
                if (!isLoadingUser && (!user || !user?.name)) {
                    try {
                        if (!userId.toString().match(guidPattern)) {
                            if (userName) setUser({ userName, name: userName } as userDetails);
                            return;
                        }
                        const user = await addUser(userId);
                        setUser(user);
                    } catch (e) {
                        if (userName) setUser({ userName, name: userName } as userDetails);
                    }
                }
            }}
            popoverLeft={popoverLeft}
            toggleOnClick={toggleOnClick}
        >
            {user && user?.name ? (
                <UserDetailsContainer onClick={(e) => e.stopPropagation() /* stop propagation in order to disable card click */}>
                    <UserDetails>
                        <div className="avatar">
                            <HexagonAvatar
                                width={45}
                                img={user.profileImgUrl || UserImageAvatar(user)}
                                borderColor={getUserStatusTheme(user?.status || 'OOTO').color ?? '#00b5b9'}
                                hasShadow={false}
                            />
                        </div>
                        <span className="details">
                            <span className="username">{user.name}</span>
                            {user.companyName && <span className="role">{`${user.companyName} | ${user.role || ''}`}</span>}
                            <span className="number">{user.phoneNumber || ''}</span>
                            <span className="email">{user.email || ''}</span>
                        </span>
                        {user.id && user.id != userInfo.id && (
                            <Actions>
                                {user.isActive ? (
                                    <Action
                                        color="inherit"
                                        className="material-icons"
                                        onClick={() => {
                                            const participantsIds = [userInfo.id, user.id];
                                            openChatDrawer({ participantsIds, chatType: 'user' });
                                        }}
                                    >
                                        chat_bubble_outline
                                    </Action>
                                ) : (
                                    <Action
                                        disabled={!!sentRequests?.find((item) => item.recieverEmail === user.email)}
                                        color="inherit"
                                        className="material-icons"
                                        onClick={() => inviteUser(user.email)}
                                    >
                                        person_add
                                    </Action>
                                )}
                                {user.isActive && user.networkId && (
                                    <Action
                                        color="inherit"
                                        className="material-icons"
                                        onClick={() => showConfirm(stopNetwork.bind(undefined, user.id, user.networkId))}
                                    >
                                        person_add_disabled
                                    </Action>
                                )}
                            </Actions>
                        )}
                    </UserDetails>
                </UserDetailsContainer>
            ) : (
                <LoaderContainer>
                    <Loader width="30px" marginTop="0" showText={false}></Loader>
                </LoaderContainer>
            )}
        </MaterialPopover>
    );
};

const LoaderContainer = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 100%;
`;

const UserDetails = styled.div`
    display: flex;
    overflow: hidden;
    flex: 1;
    height: 100%;
    .avatar {
        width: 52px;
    }
    .details {
        display: flex;
        flex-direction: column;
        padding: 5px 5px 5px 10px;
        color: #252b4a;
        span {
            display: inline-block;
            font-weight: 300;
        }
        .username {
            font-size: 16px;
            font-weight: 500;
        }
    }
`;
const UserDetailsContainer = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
`;

const Actions = styled.div`
    margin-left: 10px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: start;
    padding: 10px;
    border-left: solid 1px #e0e0e0;
    color: black;
`;
const Action = styled(IconButton)``;
const mapStateToProps = (state: RootState) => ({
    isLoadingUser: state.loading.effects.users.addUser,
    getUserById: (id: string) => usersSelectors.getUserById(state)(id),
    sentRequests: chatsSelectors.sentRequests(state),
    userInfo: userSelectors.userInfo(state)
});

const mapDispatchToProps = (dispatch: any) => ({
    openChatDrawer: (args: openChatDrawerArgs) => dispatch.chats.openChatDrawer(args),
    addUser: (userId: string) => dispatch.users.addUser(userId),
    setDrawerOpen: (open: drawers, params?: any) => dispatch.header.setDrawerOpenParams({ open, params }),
    inviteUser: (email: string) => dispatch.chats.inviteUser(email),
    stopNetwork: (userId: string, networkId?: string) => dispatch.chats.stopNetwork({ userId, networkId })
});

export default connect(mapStateToProps, mapDispatchToProps)(UserCard);
