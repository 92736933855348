import React from 'react';
import { PagesRoutes } from '../../../../routing/PagesRoutes';
import StyledLink from '../../SmallComponents/StyledLink';

type Props = {
    companyId: string;
    value: string;
};

const CompanyLink = ({ companyId, value }: Props) => {
    return <StyledLink to={`${PagesRoutes.BusinessPartners}/${companyId}`}>{value}</StyledLink>;
};

export default CompanyLink;
