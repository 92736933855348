import React, { useEffect } from 'react';
import ChatsPage from './ChatsPage';
import { connect } from 'react-redux';
import { chatsSelectors } from '../../../state/ducks/chats/selectors';
import { headerSelectors } from '../../../state/ducks/header/selectors';
import { drawers } from '../../../state/ducks/header/header';
import { usersSelectors } from '../../../state/ducks/users/selectors';
import { RootState } from '../../../state/store/store';
import { userSelectors } from '../../../state/ducks/user/selectors';
import { chat, chatType, createChatPayload, editMessagePayload, message, messageAttachment } from '../../../models/entities/chat/chat';
import { userDropDown, userInfo, userMyNetwork } from '../../../models/entities/user';
import { userDetails, usersObject } from '../../../state/ducks/users/users';
import useWindowSize from '../../../hooks/windowSize';
import { ColumnType } from '../../shared/Grid/types/Column';
import { shipment } from '../../../models/entities/shipment/shipment';
import { allShipmentsPageSelectors } from '../../../state/ducks/allShipmentsPage/selectors';
import { chatRequest } from '../../../models/entities/chat/chatRequest';
import { shipmentsSelectors } from '../../../state/ducks/shipments/selectors';
import { Redirect, withRouter, RouteComponentProps } from 'react-router-dom';
import { localizationSelectors } from '../../../state/ducks/localization/selectors';
import { ContentState, EditorState } from 'draft-js';
import { openChatDrawerArgs } from '../../../state/ducks/chats/chats';

type Props = RouteComponentProps & {
    currentChatId: string;
    activeChats: Array<chat>;
    userInfo: userInfo;
    usersInSameCompany?: Array<userDropDown>;
    openChatDrawer: (args: openChatDrawerArgs) => void;
    getChatByParticipantsIds: (participantsIds: string[], type: chatType) => chat | undefined;
    notReadMessagesCount: (chatId: string) => number | undefined;
    getUserPicture: (userId: string) => string;
    getUserById: (userId: string) => userDetails;
    setDrawerOpen: (open: drawers, params?: any) => void;
    setCurrentChatId: (chatId: string) => void;
    typingUsers: (chatId: string) => string[] | undefined;
    getContactsWithoutChat?: userDetails[];
    drawerOpen: drawers;
    myNetwork: usersObject;
    gridColumns: Array<ColumnType<shipment>>;
    isFetchingNextMessages: boolean;
    shipments: Array<shipment>;
    sentRequests?: Array<chatRequest>;
    getShipmentById: (id: string) => shipment | undefined;
    inviteUser: (email: string) => void;
    getMessagesByChatId: (chatId: string) => { [key: string]: message[] } | undefined;
    getMessageById: (chatId: string, messageId: string) => message | undefined;
    createChat: (payload: createChatPayload) => void;
    sendMessage: (chatId: string, message: message) => void;
    editMessage: (payload: editMessagePayload) => void;
    deleteMessage: (id: string, chatId: string) => void;
    readMessages: (chatId: string, messagesIds: string[], userId: string) => void;
    deleteGroupParticipant: (chatId: string, userId: string, userName: string) => Promise<void>;
    addGroupParticipants: (chatId: string, users: { id: string; name: string }[]) => Promise<void>;
    fetchNextMessages: (chatId: string, messagesToId: string) => Promise<chat | undefined>;
    fetchContactChat: (contactId: string) => void;
    saveChatEditorState: (chatId: string, editorContentState: ContentState) => void;
    saveChatAttachmentsEditorState: (chatId: string, attachments: messageAttachment[]) => void;
    openRequestsCount?: number;
    localization: any;
};

const ChatsPageConnected = (props: Props) => {
    const [windowWidth] = useWindowSize();
    useEffect(() => {
        props.setDrawerOpen(null);
    }, []);
    return <ChatsPage isMobile={windowWidth < 768} {...props} />;
};

const mapStateToProps = (state: RootState) => ({
    currentChatId: state.chats.currentPaneChatId,
    usersInSameCompany: userSelectors.usersInSameCompany(state),
    getUserPicture: (id: string) => usersSelectors.userPicture(state, id),
    getUserById: (id: string) => usersSelectors.getUserById(state)(id),
    drawerOpen: headerSelectors.drawerOpen(state),
    userInfo: userSelectors.userInfo(state),
    getMessagesByChatId: (chatId: string) => chatsSelectors.getMessagesByChatId(state)(chatId),
    getChatByParticipantsIds: (participantsIds: string[], type: chatType) => chatsSelectors.getChatByParticipantsIds(state)(participantsIds, type),
    notReadMessagesCount: (chatId: string) => chatsSelectors.notReadMessagesCount(state)(chatId),
    getMessageById: (chatId: string, messageId: string) => chatsSelectors.getMessageById(state)(chatId, messageId),
    activeChats: chatsSelectors.activeChats(state),
    isFetchingNextMessages: !!state.loading.effects.chats.fetchNextMessages,
    gridColumns: allShipmentsPageSelectors.gridColumns(state),
    shipments: allShipmentsPageSelectors.gridData(state),
    getShipmentById: (id: string) => shipmentsSelectors.getShipmentById(state, id),
    sentRequests: chatsSelectors.sentRequests(state),
    typingUsers: (chatId: string) => chatsSelectors.typingUsers(state)(chatId),
    getContactsWithoutChat: chatsSelectors.getContactsWithoutChat(state),
    myNetwork: usersSelectors.users(state),
    openRequestsCount: chatsSelectors.recievedRequests(state)?.filter((item) => item.status === 'PENDING').length,
    localization: localizationSelectors.chat(state)
});

const mapDispatchToProps = (dispatch: any) => ({
    openChatDrawer: (args: openChatDrawerArgs) => dispatch.chats.openChatDrawer(args),
    setCurrentChatId: (chatId: string) => dispatch.chats.setCurrentChatId(chatId),
    setDrawerOpen: (open: drawers, params?: any) => dispatch.header.setDrawerOpenParams({ open, params }),
    createChat: (payload: createChatPayload) => dispatch.chats.createChat(payload),
    sendMessage: (chatId: string, message: message) => dispatch.chats.sendMessage({ chatId, message }),
    fetchNextMessages: (chatId: string, messagesToId: string) => dispatch.chats.fetchNextMessages({ chatId, messagesToId }),
    editMessage: (payload: editMessagePayload) => dispatch.chats.editMessage(payload),
    deleteMessage: (id: string, chatId: string) => dispatch.chats.deleteMessage({ id, chatId }),
    saveChatAttachmentsEditorState: (chatId: string, attachments: messageAttachment[]) =>
        dispatch.chats.saveChatAttachmentsEditorState(chatId, attachments),
    readMessages: (chatId: string, messagesIds: string[], userId: string) => dispatch.chats.readMessages({ chatId, messagesIds, userId }),
    deleteGroupParticipant: (chatId: string, userId: string, userName: string) => dispatch.chats.deleteGroupParticipant({ chatId, userId, userName }),
    addGroupParticipants: (chatId: string, users: { id: string; name: string }[]) => dispatch.chats.addGroupParticipants({ chatId, users }),
    saveChatEditorState: (chatId: string, editorContentState: ContentState) => dispatch.chats.saveChatEditorState(chatId, editorContentState),
    fetchContactChat: (contactId: string) => dispatch.chats.fetchContactChat(contactId),
    inviteUser: (email: string) => dispatch.chats.inviteUser(email)
});

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(ChatsPageConnected));
