import React from 'react';
import { RootState } from '../../../state/store/store';
import { connect } from 'react-redux';
import EditProfilePage from './EditProfilePage';
import { userSelectors } from '../../../state/ducks/user/selectors';
import { userInfo } from '../../../models/entities/user';
import { RcFile } from 'antd/lib/upload';
import { localizationSelectors } from '../../../state/ducks/localization/selectors';

type Props = {
    userInfo: userInfo;
    isLoadUserInfo: boolean;
    showPictureLoader: boolean;
    showDetailsLoader: boolean;
    showPasswordLoader: boolean;
    detailsError: string;
    passwordError: string;
    updateUserPicture: (file: RcFile) => void;
    updateUserDetails: (details: any) => boolean;
    updateUserPassword: (currentPassword: string, password: string, passwordConfirm: string) => boolean;
    localization: any;
};

const EditProfilePageConnected = ({
    userInfo,
    isLoadUserInfo,
    showPictureLoader,
    showDetailsLoader,
    showPasswordLoader,
    detailsError,
    passwordError,
    updateUserPicture,
    updateUserDetails,
    updateUserPassword,
    localization
}: Props) => {
    return (
        <EditProfilePage
            userInfo={userInfo}
            isLoadUserInfo={isLoadUserInfo}
            showPictureLoader={showPictureLoader}
            showDetailsLoader={showDetailsLoader}
            showPasswordLoader={showPasswordLoader}
            detailsError={detailsError}
            passwordError={passwordError}
            updateUserPicture={updateUserPicture}
            updateUserDetails={updateUserDetails}
            updateUserPassword={updateUserPassword}
            localization={localization}
        />
    );
};

const mapStateToProps = (state: RootState) => ({
    userInfo: userSelectors.userInfo(state),
    isLoadUserInfo: state.loading.effects.user.fetchUserInfo,
    showPictureLoader: state.loading.effects.user.updateUserPicture,
    showDetailsLoader: state.loading.effects.user.updateUserDetails,
    showPasswordLoader: state.loading.effects.user.updateUserPassword,
    detailsError: state.error.effects.user.updateUserDetails,
    passwordError: state.error.effects.user.updateUserPassword,
    localization: localizationSelectors.user(state).edit_profile
});

const mapDispatchToProps = (dispatch: any) => ({
    updateUserPicture: (file: RcFile) => dispatch.user.updateUserPicture(file),
    updateUserDetails: (details: any) => dispatch.user.updateUserDetails(details),
    updateUserPassword: (currentPassword: string, password: string, passwordConfirm: string) =>
        dispatch.user.updateUserPassword({ currentPassword, password, passwordConfirm })
});

export default connect(mapStateToProps, mapDispatchToProps)(EditProfilePageConnected);
