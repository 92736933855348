import React from 'react';
import { tariff } from '../../../models/entities/tariff/tariff';
import { PagesRoutes } from '../../../routing/PagesRoutes';
import { ColumnType } from '../../shared/Grid/types/Column';
import StyledLink from '../../shared/SmallComponents/StyledLink';

const fields: (keyof tariff)[] = [
    //'userId',
    //'companyId',
    //'businessPartnerId',
    'shipmentTypeCode',
    'shipmentSubTypeCode',
    //'state',
    'incoterms',
    'carrier',
    'transitTime',
    'originCountry',
    'originStation',
    'destinationCountry',
    'destinationStation',
    'pol',
    'pod',
    //'activationDate',
    //'expirationDate',
    'forwarderTariffNumber',
    'notes'
    //'createdAt',
    //'updatedAt',
    //'deletedAt'
];
const tariffsGridColumns: (localization: any) => Array<ColumnType<tariff>> = (localization: any) => [
    {
        title: 'Tariff Number',
        field: 'cargoZoneNumber',
        width: '200px',
        sortable: true,
        isDisplayed: true,
        bindedFields: ['id'],
        textAlign: 'center',
        renderer: (cellValue: any, bindedData?: any[] | null) => (
            <StyledLink to={`${PagesRoutes.Tariffs}/${bindedData?.[0]}`}>#{cellValue}</StyledLink>
        )
    },
    // {
    //     title: 'Company',
    //     field: 'companyName',
    //     bindedFields: ['companyId'],
    //     width: '100%',
    //     sortable: true,
    //     isDisplayed: true,
    //     columnType: 'company'
    // },
    // {
    //     title: 'Origin',
    //     field: 'originStation',
    //     width: '90px',
    //     sortable: true,
    //     //bindedFields: ['originCountry', 'originStationName'],
    //     columnType: 'origin',
    //     toolTipText: 'Port of Loading'
    // },
    // {
    //     title: 'Destination',
    //     field: 'destinationStation',
    //     width: '90px',
    //     sortable: true,
    //     //bindedFields: ['destinationCountry', 'destinationStationName'],
    //     columnType: 'destination',
    //     toolTipText: 'Port of Discharge'
    // },
    ...fields.map((f) => ({
        field: f,
        width: '100%',
        sortable: true
    })),
    {
        title: 'Activation Date',
        field: 'activationDate',
        width: '100%',
        sortable: true,
        columnType: 'date'
    },
    {
        title: 'Expiration Date',
        field: 'expirationDate',
        width: '100%',
        sortable: true,
        columnType: 'date'
    },
    {
        title: 'Last Update',
        field: 'updatedAt',
        width: '100%',
        sortable: true,
        toolTipText: 'Displays the date & time of the last update was received',
        columnType: 'date-time'
    },
    {
        title: 'Open Date',
        field: 'createdAt',
        width: '100px',
        sortable: true,
        isDisplayed: true,
        toolTipText: 'Displays the date and time the shipment was created ',
        columnType: 'date'
    }
];

export default tariffsGridColumns;
