import React, { useState } from 'react';
import { Formik, Form } from 'formik';
import Loader from '../../../../shared/SmallComponents/Loader';
import CZTooltip, { QuestionToolTip } from '../../../../shared/CZTooltip/CZTooltip';
import { BaseProps, eFormMode } from './InventoryFormConnected';
import { validationSchema } from '../../../../../validationSchemas/inventoryForm';
import { styled } from '../../../../shared/Theme/theme';
import Button from '../../../../shared/SmallComponents/Button';
import { editableDetail, formFieldsArr, renderEditable, sectionType } from '../../utils';
import { partiesToDropdownOptions } from '../../../businessPartners/components/BusinessPartnerForm/BusinessPartnerFormConnected';
import CreateBusinessPartner from '../../../singlePurchaseOrder/PurchaseOrderForm/sections/BusinessPartners/CreateBusinessPartner';
import { businessPartner } from '../../../../../models/entities/businessPartner/businessPartner';
import { inventoryLog, eInventoryActionType } from '../../../../../models/entities/product/inventoryLog';
import { enumToDropdownOptions } from '../../../../shared/inputs/base/DropDown';
import { drawers } from '../../../../../state/ducks/header/header';

type Props = BaseProps & {};
export const renderFieldLabel = (text: string, isMandatory: boolean, tooltipText?: string) => {
    return (
        <LabelContainer>
            <Label>
                {isMandatory && <MandatoryIndication>*</MandatoryIndication>}
                {text}
            </Label>
            {tooltipText && <CZTooltip text={tooltipText}>{QuestionToolTip}</CZTooltip>}
        </LabelContainer>
    );
};
const InventoryForm = ({
    localizations,
    mode,
    createInventory,
    onClose,
    businessPartnerWarehouses,
    featureFlags,
    products,
    setDrawerOpen
}: Props) => {
    const [loader, setLoader] = useState(false);
    const setInitialValues = () => {
        const initValues = {
            productId: null,
            cost: null,
            costCurrency: 'USD',
            warehouseId: null,
            location: null,
            actionType: null,
            quantity: null,
            reason: null
        };

        return initValues;
    };
    const formValidationSchema = validationSchema(localizations);
    const validate = (values: any) => {
        const errors: any = {};
        return errors;
    };
    const handleClose = (inventoryId?: string) => {
        if (onClose) onClose(inventoryId);
        setDrawerOpen('INVENTORY', {});
    };
    const submit = async (values: any) => {
        const stringToNumber = (value: string) => (value ? Number(value) : null);
        const verifyUnitItem = (cond: boolean, value: string) => (cond ? value : null);
        setLoader(true);
        const inventoryObject: inventoryLog = {
            ...values,
            cost: stringToNumber(values.cost),
            costCurrency: verifyUnitItem(values.cost, values.costCurrency),
            quantity: stringToNumber(values.quantity)
        } as inventoryLog;
        if (mode === 'NEW') {
            createInventory(inventoryObject)
                .then((value) => {
                    handleClose(value);
                })
                .finally(() => {
                    setLoader(false);
                });
        }
    };
    return (
        <>
            <Wrapper>
                <Formik initialValues={setInitialValues()} validationSchema={formValidationSchema} validate={validate} onSubmit={submit}>
                    {({ values, setFieldValue, handleSubmit }) => {
                        return (
                            <PageContainer>
                                <Form style={{ display: 'flex', flexDirection: 'column', height: '100%' }}>
                                    <Container>
                                        {formFieldsArr({
                                            localizations,
                                            options: {
                                                warehouse: partiesToDropdownOptions(businessPartnerWarehouses || []),
                                                product: products.map((item) => {
                                                    return { value: item.id, text: item.name };
                                                }),
                                                actionType: enumToDropdownOptions(eInventoryActionType)
                                            },
                                            onChange: {},
                                            suffix: {
                                                warehouseId: (
                                                    <CreateBusinessPartner
                                                        type="WAREHOUSE"
                                                        modalTitle={localizations.supplier}
                                                        submitCallback={(businessPartner: businessPartner) => {
                                                            setFieldValue('supplierId', businessPartner.id);
                                                            setFieldValue('supplierName', businessPartner?.name || null);
                                                        }}
                                                    />
                                                )
                                            },
                                            featureFlags
                                        }).map((outer: sectionType, index: number) => {
                                            return (
                                                !outer.hide && (
                                                    <Section key={index}>
                                                        {outer.title && <SectionTitle>{outer.title}</SectionTitle>}
                                                        <Flex>
                                                            {outer.items.map((item: editableDetail, index: number) => {
                                                                return (
                                                                    <StyledFieldContainer key={index}>
                                                                        {renderEditable(item, values, renderFieldLabel)}
                                                                    </StyledFieldContainer>
                                                                );
                                                            })}
                                                        </Flex>
                                                    </Section>
                                                )
                                            );
                                        })}
                                    </Container>
                                    <Buttons>
                                        <Button
                                            width="auto"
                                            height="30px"
                                            type="button"
                                            buttonType="clean"
                                            textColor="#394372"
                                            onClick={() => handleClose()}
                                        >
                                            <span>{localizations.cancel_btn}</span>
                                        </Button>
                                        <Button
                                            buttonType="filled"
                                            width="100px"
                                            height="30px"
                                            type="button"
                                            onClick={(e) => {
                                                handleSubmit();
                                            }}
                                        >
                                            {loader ? (
                                                <Loader width="20px" marginTop="0px" showText={false} />
                                            ) : (
                                                <span>{localizations.save_btn}</span>
                                            )}
                                        </Button>
                                    </Buttons>
                                </Form>
                            </PageContainer>
                        );
                    }}
                </Formik>
            </Wrapper>
        </>
    );
};

const Buttons = styled.div`
    width: 100%;
    padding-top: 25px;
    display: flex;
    justify-content: flex-end;
    height: 55px;

    button {
        margin-right: 20px;
    }
`;
const StyledFieldContainer = styled.div`
    margin-bottom: 12px;
    flex: 1;
    :not(:last-child) {
        padding-right: 30px;
    }
`;
const PageContainer = styled.div`
    position: relative;
    width: 100%;
    height: 100%;
`;

const Label = styled.label`
    color: ${(props) => props.theme.colors.pageTitle};
`;

const MandatoryIndication = styled.span`
    color: red;
    margin-right: 7px;
`;
const Container = styled.div`
    position: relative;
    width: 100%;
    padding: 25px;
    padding-top: 0;
    padding-bottom: 0;
    flex: 1;
    overflow: overlay;
`;

const LabelContainer = styled.div`
    display: flex;
    margin-bottom: 5px;

    & img {
        margin-top: -2px;
        margin-left: 5px;
    }
`;
const Flex = styled.div`
    display: flex;
`;
const Section = styled.div``;
const SectionTitle = styled.div`
    font-size: 16px;
    color: ${(props) => props.theme.colors.pageTitle};
    font-weight: 500;
    margin: 5px 0;
`;

const Wrapper = styled.div<{ padding?: string }>`
    padding: ${(props) => props.padding ?? '43px 0 20px 0'};
    height: 100%;
    width: 100%;
`;
export default InventoryForm;
