import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import styled, { css } from 'styled-components';
import Tooltip from '@material-ui/core/Tooltip';
import { makeStyles, createStyles } from '@material-ui/core/styles';
import { ColumnType } from '../../../../Grid/types/Column';
import { paginationType } from './models';
import Button from '../../../../SmallComponents/Button';
import { TextInput } from '../../../../inputs/base';
import Loader from '../../../../SmallComponents/Loader';
import { IconButton } from '@material-ui/core';
import { Pagination } from '@material-ui/lab';
import FiltersStripe, { FilterStripeProps } from './components/Filter/FiltersStripe';
import FilterPopover, { FilterPopoverProps } from './components/Filter/FilterPopover';

const useMobilePaginationStyles = makeStyles((theme) =>
    createStyles({
        root: {
            color: '#008ac9'
        }
    })
);

export type AddButtonProps = {
    title: string;
    pathname: string;
    pathState?: any;
};

export type GridPageHeaderProps<TEntity, TFilterFields = any> = {
    data: Array<TEntity>;
    activeDataCount: number;
    filteredDataCount: number;
    pagination: paginationType;
    gridColumns: Array<ColumnType<TEntity>>;
    currentGridFilter: string | null;
    localization: any;
    dataActiveFilter?: { field: string; value: any } | undefined;
    hidePagination?: boolean;
    isLoading?: boolean;
    isMobile: boolean;
    addBtn?: AddButtonProps;
    filterStripeProps?: FilterStripeProps<TEntity, TFilterFields>;
    filterPopoverProps?: FilterPopoverProps;
    setGridFilter: (text: string) => void;
    fetchData: () => void;
    setCurrentPage?: (page: number) => void;
    setRowsPerPage?: (rowsPerPage: number) => void;
};

function GridPageHeader<TEntity, TFilterFields = any>({
    data,
    activeDataCount,
    filteredDataCount,
    pagination,
    gridColumns,
    currentGridFilter,
    localization,
    dataActiveFilter,
    hidePagination,
    isLoading,
    isMobile,
    filterStripeProps,
    filterPopoverProps,
    addBtn,
    setGridFilter,
    fetchData,
    setCurrentPage,
    setRowsPerPage
}: GridPageHeaderProps<TEntity, TFilterFields>) {
    const [textFilterValue, setTextFilterValue] = useState(currentGridFilter);
    const [isTypingTextFilter, setIsTypingTextFilter] = useState(false);
    const { currentPage, rowsPerPage } = pagination;
    const classes = useMobilePaginationStyles();

    useEffect(() => {
        setIsTypingTextFilter(true);
        const timeout = setTimeout(() => {
            setGridFilter(textFilterValue || '');
            setIsTypingTextFilter(false);
            setCurrentPage?.(0);
        }, 500);

        return () => clearTimeout(timeout);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [textFilterValue]);

    const dataFrom = currentPage * rowsPerPage + 1;
    const dataTo = (currentPage + 1) * rowsPerPage;

    return (
        <>
            <PageHeader>
                <Title>{localization.title}</Title>

                <FiltersStripeContainer>
                    {filterStripeProps && <FiltersStripe<TEntity, TFilterFields> {...filterStripeProps} />}
                </FiltersStripeContainer>

                <HeaderRightSide>
                    <Button
                        width="32px"
                        height="32px"
                        borderRadius="50%"
                        margin="7px"
                        buttonType="hollow"
                        onClick={() => {
                            fetchData();
                        }}
                    >
                        <Tooltip title={localization.refresh_btn} aria-label="refresh">
                            <span className="material-icons">refresh</span>
                        </Tooltip>
                    </Button>
                    <TextInput
                        style={{
                            height: '30px',
                            margin: '4px',
                            width: 'auto'
                        }}
                        allowClear={true}
                        onChange={(value: string) => setTextFilterValue(value)}
                        placeHolder={localization.filterPlaceHolder}
                        value={textFilterValue || ''}
                        suffix={isTypingTextFilter && <Loader wrapperWidth="auto" showText={false} marginTop="0" width="15px" />}
                    />
                    {filterPopoverProps && <FilterPopover {...filterPopoverProps} />}
                    {addBtn && (
                        <Link to={{ pathname: addBtn.pathname, state: addBtn.pathState }}>
                            <NewButtonWrapper>
                                <Button width="32px" height="32px" borderRadius="50%" margin="7px" buttonType="filled">
                                    <Tooltip title={addBtn.title}>
                                        <span className="material-icons">add</span>
                                    </Tooltip>
                                </Button>
                            </NewButtonWrapper>
                        </Link>
                    )}
                    {!isMobile && !hidePagination && !(isLoading && (activeDataCount <= 1 || !gridColumns.length)) && (
                        <NavButtonsContainer isMobile={isMobile}>
                            <IconButton
                                onClick={() => setCurrentPage?.(currentPage - 1)}
                                disabled={isLoading || dataFrom <= 1}
                                className="material-icons nav-btn"
                            >
                                navigate_before
                            </IconButton>
                            {filteredDataCount > 0
                                ? `${dataFrom} – ${filteredDataCount <= dataTo ? filteredDataCount : dataTo}${
                                      data.length <= filteredDataCount ? ' of ' + filteredDataCount : ''
                                  }`
                                : ''}
                            <IconButton
                                onClick={() => setCurrentPage?.(currentPage + 1)}
                                disabled={isLoading || filteredDataCount <= dataTo}
                                className="material-icons nav-btn"
                            >
                                navigate_next
                            </IconButton>
                        </NavButtonsContainer>
                    )}
                </HeaderRightSide>

                {isMobile && !hidePagination && !(isLoading && (activeDataCount <= 1 || !gridColumns.length)) && (
                    <NavButtonsContainer isMobile={isMobile}>
                        <Pagination
                            className={classes.root}
                            count={Math.floor(filteredDataCount / rowsPerPage)}
                            page={currentPage + 1}
                            onChange={(event: React.ChangeEvent<unknown>, value: number) => {
                                setCurrentPage?.(value - 1);
                            }}
                        />
                    </NavButtonsContainer>
                )}
            </PageHeader>
        </>
    );
}

const PageHeader = styled.div`
    display: flex;
    padding: 20px 12px 0 12px;
    align-items: center;

    @media screen and (max-width: 768px) {
        justify-content: center;
        padding: 20px 8px 8px 8px;
    }

    position: sticky;
    top: 0px;
    left: 0px;
    z-index: 1000;
    background-color: #fff;
`;

const Title = styled.div`
    display: flex;
    color: ${(props) => props.theme.colors.pageTitle};
    font-size: ${(props) => props.theme.fontSizes.pageTitle};
    font-weight: bold;

    @media screen and (max-width: 768px) {
        display: none;
    }
`;

const HeaderRightSide = styled.div`
    display: flex;
    flex-direction: columm;
    width: 100%;
    max-width: fit-content;
    align-items: center;

    @media screen and (max-width: 768px) {
        height: 40px;
        justify-content: center;
    }

    .add-icon {
        display: none;
        @media screen and (max-width: 768px) {
            display: initial;
        }
    }

    .new-label {
        @media screen and (max-width: 768px) {
            display: none;
        }
    }
`;
const FiltersStripeContainer = styled.div`
    flex: 1;
    font-size: 12px;
    display: flex;
    overflow-x: hidden;

    @media screen and (max-width: 768px) {
        display: none;
    }

    &:hover {
        overflow-x: overlay;
    }

    &::-webkit-scrollbar {
        height: 2px;
    }

    &::-webkit-scrollbar-track {
        background-color: white;
        border-radius: 10px;
    }

    &::-webkit-scrollbar-thumb {
        border-radius: 10px;
    }
`;

const NewButtonWrapper = styled.div`
    @media screen and (max-width: 768px) {
        width: auto;
    }
    .add-icon {
        display: none;
        @media screen and (max-width: 768px) {
            display: initial;
        }
    }

    .new-label {
        @media screen and (max-width: 768px) {
            display: none;
        }
    }
`;

const NavButtonsContainer = styled.div<{ isMobile: boolean }>`
    display: flex;
    align-items: center;
    ${(props) =>
        props.isMobile
            ? css`
                  justify-content: center;
              `
            : css`
                  margin: 0 15px;
              `}
    color: ${(props) => props.theme.colors.primaryBlue};
    font-weight: bold;
    .nav-btn {
        font-size: 1.4rem;
        color: ${(props) => props.theme.colors.primaryBlue};
    }
    .MuiPaginationItem-page.Mui-selected {
        background-color: #008ac914;
    }
    .MuiPaginationItem-root {
        color: ${(props) => props.theme.colors.primaryBlue};
    }
`;

export default GridPageHeader;
