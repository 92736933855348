import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { companyAdminCreateUserPayload, userInfo } from '../../../../models/entities/user';
import { adminSelectors } from '../../../../state/ducks/admin/selectors';
import { drawers } from '../../../../state/ducks/header/header';
import { localizationSelectors } from '../../../../state/ducks/localization/selectors';
import { RootState } from '../../../../state/store/store';
import { gridSortType } from '../../../shared/Grid/Grid';
import UserMgmt from './UsersMgmtPage';

type Props = {
    fetchUsers: () => Promise<void>;
    users: userInfo[];
    localization: any;
    showLoader: boolean;
    setDrawerOpen: (open: drawers, params?: any) => void;
    setGridFilter: (text: string) => void;
    setUsersGridSort: (sort?: gridSortType<userInfo>) => void;
    usersGridSort?: gridSortType<userInfo>;
    gridFilter: string | null;
};
const UsersMgmtPageConnected = ({
    fetchUsers,
    users,
    localization,
    showLoader,
    setDrawerOpen,
    setGridFilter,
    setUsersGridSort,
    usersGridSort,
    gridFilter
}: Props) => {
    useEffect(() => {
        fetchUsers();
        return function cleanup() {
            setGridFilter('');
        };
    }, []);
    return (
        <UserMgmt
            fetchUsers={fetchUsers}
            users={users}
            localization={localization}
            showLoader={showLoader}
            setDrawerOpen={setDrawerOpen}
            setGridFilter={setGridFilter}
            setUsersGridSort={setUsersGridSort}
            usersGridSort={usersGridSort}
            gridFilter={gridFilter ?? ''}
        />
    );
};

const mapStateToProps = (state: RootState) => ({
    users: adminSelectors.usersData(state),
    usersGridSort: adminSelectors.usersGridSort(state),
    showLoader: !!state.loading.effects.admin.fetchUsers,
    localization: localizationSelectors.usersMgmtPage(state),
    gridFilter: adminSelectors.gridFilter(state)
});

const mapDispatchToProps = (dispatch: any) => ({
    fetchUsers: () => dispatch.admin.fetchUsers(),
    setDrawerOpen: (open: drawers, params?: any) => dispatch.header.setDrawerOpenParams({ open, params }),
    setGridFilter: (text: string) => dispatch.admin.setGridFilter(text),
    setUsersGridSort: (sort?: gridSortType<userInfo>) => dispatch.admin.setUsersGridSort(sort)
});

export default connect(mapStateToProps, mapDispatchToProps)(UsersMgmtPageConnected);
