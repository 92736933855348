import React from 'react';
import { settings } from '../../../../../models/entities/bid/bid';
import { document } from '../../../../../models/entities/common_subentities/document';
import Documents from '../../../commonSubEntities/documents/Documents';

type Props = {
    documents?: Array<document>;
    localization: any;
    settings?: settings;
    printMode?: boolean;
    bidId: string;
};

const BidDocuments = ({ documents, localization, settings, printMode, bidId }: Props) => {
    return <Documents<settings> documents={documents} localization={localization} settings={settings} printMode={printMode} entityId={bidId} />;
};

export default BidDocuments;
