import React from 'react';
import Grid from '../../shared/Grid/Grid';
import { purchaseDemand, settings } from '../../../models/entities/purchaseDemand/purchaseDemand';
import { ColumnType } from '../../shared/Grid/types/Column';
import { styled } from '../../shared/Theme/theme';
import Button from '../../shared/SmallComponents/Button';
import ExportMenu from './components/ExportMenu';
import FilterPopover from './components/Filter/FilterPopover';
import FiltersStripe from './components/Filter/FiltersStripe';
import { exportTypes, paginationType } from '../../../state/ducks/allPurchaseDemandsPage/allPurchaseDemandsPage';
import TextInput from '../../shared/inputs/base/TextInput';
import CustomModal, { ModalTriggerProps } from '../../shared/Modal/Modal';
import PurchaseDemandFormConnected from '../singlePurchaseDemand/PurchaseDemandForm/PurchaseDemandFormConnected';
import Tooltip from '@material-ui/core/Tooltip';
import IconButton from '@material-ui/core/IconButton';
import Loader from '../../shared/SmallComponents/Loader';
import claimsType from '../../../models/entities/claimsType';

type Props = {
    purchaseDemands: Array<purchaseDemand>;
    purchaseDemandsPage: Array<purchaseDemand>;
    activePurchaseDemandsCount: number;
    gridColumns: Array<ColumnType<purchaseDemand>>;
    onGridColumnClick?: (column: keyof purchaseDemand) => void;
    onFilter?: (text: string) => void;
    filterText?: string;
    gridSortedBy?: keyof purchaseDemand;
    gridSortDirection?: 'asc' | 'desc';
    exportDataTable: (type: exportTypes) => void;
    localization: any;
    showLoader?: boolean;
    settings?: settings;
    userSettingKey?: string;
    setCurrentPage: (page: number) => void;
    setRowsPerPage: (rowsPerPage: number) => void;
    filteredPDsCount: number;
    pagination: paginationType;
    fetchPurchaseDemands: () => void;
    pdUserClaims: claimsType;
};

const PurchaseDemandsPage = ({
    purchaseDemands,
    purchaseDemandsPage,
    activePurchaseDemandsCount,
    gridColumns,
    onGridColumnClick,
    onFilter,
    filterText,
    gridSortedBy,
    gridSortDirection,
    localization,
    exportDataTable,
    showLoader,
    settings,
    userSettingKey,
    setCurrentPage,
    setRowsPerPage,
    filteredPDsCount,
    fetchPurchaseDemands,
    pagination,
    pdUserClaims
}: Props) => {
    const { currentPage, rowsPerPage } = pagination;

    const pdsFrom = currentPage * rowsPerPage + 1;
    const pdsTo = (currentPage + 1) * rowsPerPage;

    const filterHandler = (text: string) => {
        if (onFilter) onFilter(text);
    };

    const showTopLoader = activePurchaseDemandsCount > 1 && !!gridColumns.length;

    return (
        <PageWrapper>
            {!!showLoader && showTopLoader && (
                <LoadingOverlay>
                    <Loader width="20px" marginTop="0" inline />
                </LoadingOverlay>
            )}

            <PageHeader>
                <Title>{localization.title}</Title>

                <FiltersStripe localization={localization} />

                <HeaderRightSide>
                    <Button
                        width="32px"
                        height="32px"
                        borderRadius="50%"
                        margin="7px"
                        buttonType="hollow"
                        onClick={() => {
                            fetchPurchaseDemands();
                        }}
                    >
                        <Tooltip title={'Refresh'} aria-label="refresh">
                            <span className="material-icons">refresh</span>
                        </Tooltip>
                    </Button>
                    <TextInput
                        style={{
                            height: '30px',
                            margin: '4px',
                            width: 'auto'
                        }}
                        allowClear={true}
                        onChange={(value: any) => filterHandler(value)}
                        placeHolder={localization.filterPlaceHolder}
                        value={filterText}
                    />
                    <FilterPopover localization={localization.filter} settings={settings} />
                    <ExportMenu
                        localization={localization}
                        exportDataTable={exportDataTable}
                        purchaseDemands={purchaseDemands}
                        gridColumns={gridColumns}
                    />

                    {pdUserClaims.add_pd && (
                        <CustomModal
                            title={{ text: localization.modal.add_title }}
                            Trigger={(props: ModalTriggerProps) => (
                                <Button width="32px" height="32px" borderRadius="50%" margin="7px" buttonType="filled" onClick={props.onClick}>
                                    <Tooltip title={localization.new_purchase_demand} aria-label="add_pd">
                                        <span className="material-icons">add</span>
                                    </Tooltip>
                                </Button>
                            )}
                            Content={(
                                setVisible?: React.Dispatch<React.SetStateAction<boolean>>,
                                setConfirm?: React.Dispatch<React.SetStateAction<boolean>>
                            ) => {
                                return <PurchaseDemandFormConnected openedFromModal={setVisible ? { setVisible } : undefined} />;
                            }}
                            height={700}
                            width="90vw"
                            maxWidth="1100px"
                        />
                    )}

                    {(!showLoader || showTopLoader) && (
                        <NavButtonsContainer>
                            {filteredPDsCount > 0
                                ? `${pdsFrom} – ${filteredPDsCount <= pdsTo ? filteredPDsCount : pdsTo}${purchaseDemandsPage.length <= filteredPDsCount ? ' of ' + filteredPDsCount : ''
                                }`
                                : ''}
                            <IconButton
                                onClick={() => setCurrentPage(currentPage - 1)}
                                disabled={showLoader || pdsFrom <= 1}
                                className="material-icons nav-btn"
                            >
                                navigate_before
                            </IconButton>
                            <IconButton
                                onClick={() => setCurrentPage(currentPage + 1)}
                                disabled={showLoader || filteredPDsCount <= pdsTo}
                                className="material-icons nav-btn"
                            >
                                navigate_next
                            </IconButton>
                        </NavButtonsContainer>
                    )}
                </HeaderRightSide>
            </PageHeader>
            <GridWrapper>
                <Grid<purchaseDemand>
                    sortedByColumn={gridSortedBy}
                    sortDirection={gridSortDirection}
                    onSettingsClick={() => console.log('settings')}
                    onRowMoreOptionsClick={(row) => console.log(row)}
                    menuType="purchaseDemandMenu"
                    columns={gridColumns}
                    data={purchaseDemandsPage}
                    onColumnClick={onGridColumnClick}
                    localization={localization.grid}
                    emptyStateText={localization.grid.emptyState}
                    showLoader={showLoader && !showTopLoader}
                    settings={settings}
                    userSettingKey={userSettingKey}
                />
            </GridWrapper>
        </PageWrapper>
    );
};

const PageWrapper = styled.div`
    height: 100%;
    display: flex;
    flex-direction: column;
`;

const GridWrapper = styled.div`
    flex: 1;
    max-width: 100%;
    width: 100%;
    box-sizing: border-box;
    height: 100%;
    width: 100%;
    z-index: 1;
    overflow: overlay;

    @media screen and (min-width: 768px) {
        height: calc(100% - 127px);
    }

    @media screen and (max-width: 768px) {
        height: calc(100% - 135px - 40px);
    }

    @media screen and (max-height: 400px) {
        height: calc(100% - 135px);
    }
`;

const PageHeader = styled.div`
    display: flex;
    padding: 20px 12px 0 12px;
    align-items: center;

    @media screen and (max-width: 768px) {
        justify-content: center;
    }

    position: sticky;
    top: 0px;
    z-index: 1000;
    background-color: #fff;
`;

const Title = styled.div`
    display: flex;
    color: ${(props) => props.theme.colors.pageTitle};
    font-size: ${(props) => props.theme.fontSizes.pageTitle};
    font-weight: bold;

    @media screen and (max-width: 768px) {
        display: none;
    }
`;

const HeaderRightSide = styled.div`
    display: flex;
    flex-direction: columm;
    width: 100%;
    max-width: fit-content;
    align-items: center;

    @media screen and (max-width: 768px) {
        height: 40px;
        justify-content: center;
    }

    .add-icon {
        display: none;
        @media screen and (max-width: 768px) {
            display: initial;
        }
    }

    .new-label {
        @media screen and (max-width: 768px) {
            display: none;
        }
    }
`;

const NavButtonsContainer = styled.div`
    display: flex;
    align-items: center;
    margin: 0 15px;
    color: ${(props) => props.theme.colors.primaryBlue};
    font-weight: bold;
    .nav-btn {
        font-size: 1.4rem;
        color: ${(props) => props.theme.colors.primaryBlue};
    }
`;

const LoadingOverlay = styled.div`
    background-color: #edf8f9;
    padding: 6px 10px;
    border-radius: 7px;
    position: absolute;
    z-index: 1012;
    top: 10px;
    left: 50%;
    transform: translateX(-50%);
`;

export default PurchaseDemandsPage;
