import React from 'react';
import { convertFromHTML, IConvertFromHTMLConfig } from 'draft-convert';
import { EditorState, Modifier, RichUtils } from 'draft-js';
import { stateToHTML } from 'draft-js-export-html';
import { shipment } from '../models/entities/shipment/shipment';
import { userDetails } from '../state/ducks/users/users';
import parse, { DOMNode, domToReact } from 'html-react-parser';
import UserCard from '../components/shared/UserCard/UserCard';
import ShipmentCard from '../components/shared/ShipmentCard/ShipmentCard';
import styled from 'styled-components';
import { convertToRaw } from 'draft-js';
import { SelectionState } from 'draft-js';
import YouTube from 'react-youtube';
import { Link } from 'react-router-dom';
import { PagesRoutes } from '../routing/PagesRoutes';

const removeSelectedBlocksStyle = (editorState: EditorState) => {
    const newContentState = RichUtils.tryToRemoveBlockStyle(editorState);
    if (newContentState) {
        return EditorState.push(editorState, newContentState, 'change-block-type');
    }
    return editorState;
};

export const getResetEditorState = (editorState: EditorState): EditorState => {
    const blocks = editorState.getCurrentContent().getBlockMap().toList();
    const updatedSelection = editorState.getSelection().merge({
        anchorKey: blocks.first().get('key'),
        anchorOffset: 0,
        focusKey: blocks.last().get('key'),
        focusOffset: blocks.last().getLength(),
        hasFocus: true
    });
    const newContentState = Modifier.removeRange(editorState.getCurrentContent(), updatedSelection, 'forward');

    const newState = EditorState.push(editorState, newContentState, 'remove-range');
    return removeSelectedBlocksStyle(newState);
};

export const insertTextToEditor = (editorState: EditorState, text: string): EditorState => {
    const selection = editorState.getSelection();
    const contentState = editorState.getCurrentContent();
    const ncs = Modifier.insertText(contentState, selection, text);
    const newState = EditorState.moveFocusToEnd(EditorState.push(editorState, ncs, 'insert-fragment'));

    return newState;
};

export const moveSelectionToEnd = (editorState: EditorState): EditorState => {
    const content = editorState.getCurrentContent();
    const blockMap = content.getBlockMap();

    const key = blockMap.last().getKey();
    const length = blockMap.last().getLength();

    const selection = new SelectionState({
        anchorKey: key,
        anchorOffset: length,
        focusKey: key,
        focusOffset: length
    });
    return EditorState.forceSelection(editorState, selection);
};

export const convertEditorStateToHtml = (editorState: EditorState) => {
    const html = stateToHTML(editorState.getCurrentContent(), {
        entityStyleFn: (entity: any) => {
            const entityType = entity.get('type').toLowerCase();
            if (entityType === 'mention') {
                const data = entity.getData();

                return {
                    element: 'span',
                    attributes: {
                        czid: data.mention.id,
                        entity: 'user'
                    },
                    style: {
                        color: '#008ac9',
                        cursor: 'pointer'
                    }
                };
            } else if (entityType === '#mention') {
                const data = entity.getData();

                return {
                    element: 'span',
                    attributes: {
                        czid: data.mention.id,
                        entity: data.mention.entityType
                    },
                    style: {
                        color: '#008ac9',
                        cursor: 'pointer'
                    }
                };
            }
        }
    });

    return html;
};

export const convertHtmlToEditorState = (
    html: string,
    getUserByIdFn: (id: string) => userDetails | undefined,
    getShipmentByIdFn: (id: string) => shipment | undefined,
    editorState?: EditorState
): EditorState => {
    const importerConfig: IConvertFromHTMLConfig = {
        htmlToEntity: (nodeName: any, node: any, createEntity: any) => {
            if (!!node?.getAttribute) {
                const czid = node.getAttribute('czid');
                const entityType = node.getAttribute('entity');
                if (czid) {
                    if (entityType === 'user') {
                        const user = getUserByIdFn(czid);
                        return createEntity('mention', 'MUTABLE', {
                            mention: user
                        });
                    } else if (entityType === 'shipment') {
                        const shipment = getShipmentByIdFn(czid);
                        if (shipment) {
                            return createEntity('#mention', 'MUTABLE', {
                                mention: { entityType: 'shipment', name: shipment.CargoZoneNumber, ...shipment }
                            });
                        }
                    } else if (entityType === 'po') {
                        // const purchaseOrder = getPurchaseOrderByIdFn(czid);
                        return createEntity('#mention', 'MUTABLE', {
                            mention: { entityType: 'po' }
                        });
                    }
                }
            }
        }
    };

    const fromHTML = (html: string) => convertFromHTML(importerConfig)(html);
    let htmlContentState = fromHTML(html || '');

    const newState = editorState
        ? EditorState.push(editorState, htmlContentState, 'change-block-data')
        : EditorState.createWithContent(htmlContentState);
    return newState;
};

export const parseHtmlToJsx = (html: string, popoverLeft?: boolean) => {
    const parsed = parse(html, {
        replace: ({ attribs, children, type, data }: any) => {
            if (attribs && attribs.czid) {
                switch (attribs.entity) {
                    case 'user':
                        return (
                            <UserCard userId={attribs.czid} popoverLeft={!!popoverLeft}>
                                <Mention>{domToReact(children)}</Mention>
                            </UserCard>
                        );
                    case 'shipment':
                        return (
                            <ShipmentCard shipmentId={attribs.czid} popoverLeft={!!popoverLeft}>
                                <Mention>{domToReact(children)}</Mention>
                            </ShipmentCard>
                        );
                    case 'po':
                        return (
                            <Link
                                to={{
                                    pathname: `${PagesRoutes.PurchaseOrders}/${attribs.czid}`
                                }}
                            >
                                <Mention>{domToReact(children)}</Mention>
                            </Link>
                        );
                    case 'pd':
                        return (
                            <Link
                                to={{
                                    pathname: `${PagesRoutes.PurchaseDemands}/${attribs.czid}`
                                }}
                            >
                                <Mention>{domToReact(children)}</Mention>
                            </Link>
                        );
                }
            } else if (attribs && !!attribs.href) {
                const videoId = checkYoutubeUrl(attribs.href);
                if (videoId) {
                    return (
                        <YouTube
                            videoId={videoId}
                            opts={{
                                width: '100%',
                                height: '100%'
                            }}
                            onReady={(event) => {
                                event.target.pauseVideo();
                            }}
                        />
                    );
                } else {
                    return (
                        <a href={attribs.href} title={attribs.href} rel="noreferrer" target="_blank">
                            {domToReact(children)}
                        </a>
                    );
                }
            } else if (type === 'text' && !!data?.trim()) {
                return <MessageText isRtl={new RegExp('^[\\u0591-\\u07FF\\uFB1D-\\uFDFD\\uFE70-\\uFEFC]', 'g').test(data)}>{data}</MessageText>;
            }
        }
    });

    return parsed;
};

const checkYoutubeUrl = (url: string) => {
    const regExp = /^.*((youtu.be\/)|(v\/)|(\/u\/\w\/)|(embed\/)|(watch\?))\??v?=?([^#&?]*).*/;
    const match = url.match(regExp);
    return match && match[7].length === 11 ? match[7] : false;
};

export const isEditorEmpty = (state: EditorState) => {
    return convertToRaw(state.getCurrentContent())?.blocks.every((b) => !b.text);
};

interface MessageTextProps {
    isRtl: boolean;
}

const MessageText = styled.span<MessageTextProps>`
    direction: ${(props) => (props.isRtl ? 'rtl' : 'ltr')};
    white-space: pre-line;
    display: inline-block;

    p {
        margin-bottom: 0;
    }
`;

const Mention = styled.span`
    color: #008ac9;
    cursor: pointer;
    &:hover {
        background-color: #ffff0075;
    }
`;
