import React, { useEffect, useState } from 'react';
import { partyTypes } from '../../../../../models/entities/shipment/shipment';
import { styled } from '../../../../shared/Theme/theme';
import { Formik, Form } from 'formik';
import Button from '../../../../shared/SmallComponents/Button';
import { ModalFooter, ModalFormContainer } from '../../../../shared/Modal/Modal';
import Loader from '../../../../shared/SmallComponents/Loader';
import { party } from '../../../../../models/entities/common_subentities/party';
import { businessPartner } from '../../../../../models/entities/businessPartner/businessPartner';
import { emailPattern, phonePattern } from '../../../../../utils/patterns';
import { FormDropDown, FormTextInput, LocationSearchInput } from '../../../../shared/inputs/form';
import { partiesToDropdownOptions } from '../../../businessPartners/components/BusinessPartnerForm/BusinessPartnerFormConnected';
import FieldLabel from '../../../../shared/SmallComponents/FieldLabel';
import { Option } from '../../../../shared/inputs/base/DropDown';
import { shipment_po } from '../../../../../models/entities/shipment_po';
import countries from '../../../../../utils/countries-flags/countries';
import { renderCountryOption } from '../../../../shared/SmallComponents/CountryOption';
import { address } from '../../../../../models/entities/businessPartner/address';
import { contact } from '../../../../../models/entities/businessPartner/contact';

export enum formMode {
    NEW,
    EDIT
}

interface formFields {
    type?: string;
    subType?: string;
    country?: string;
    city?: string;
    address?: string;
    phone_number?: string;
    email?: string;
    contact_id?: string;
    contact_name?: string;
    name?: string;
    businessPertner?: string;
}

const requiredFields = ['type', 'address', 'phone_number', 'email'];
type Props<entityType, settingsType> = {
    entity?: entityType;
    party?: party;
    mode: formMode;
    localizations?: any;
    settings?: settingsType;
    openedFromModal?: {
        setVisible: React.Dispatch<React.SetStateAction<boolean>>;
    };
    confirmation?: { setConfirm: React.Dispatch<React.SetStateAction<boolean>> };
    createParty: (entityId: string, party: party) => void;
    updateParty: (entityId: string, party: party) => void;
    showLoader?: boolean;
    businessPartners?: Array<businessPartner>;
    CreateBusinessPartner: any;
};

let AFTER_LOADING: boolean = false;
const countriesDropDownOptions = countries.map((country) => ({
    text: country.name,
    value: country.code
}));
function PartyFormConnected<entityType extends { id: string }, settingsType extends { partyTypes?: partyTypes }>({
    entity,
    party,
    localizations,
    mode,
    settings,
    openedFromModal,
    confirmation,
    createParty,
    updateParty,
    showLoader,
    businessPartners,
    CreateBusinessPartner
}: Props<entityType, settingsType>) {
    const [submitLoading, setLoading] = useState(false);
    const [contacts, setContacts] = useState<contact[]>([]);
    const [addresses, setAddresses] = useState<address[]>([]);
    const partyTypesOptions = settings?.partyTypes?.map((item) => ({
        text: item.name,
        value: item.code
    }));
    const initFormValues: formFields = {
        type: '',
        subType: '',
        country: '',
        city: '',
        address: '',
        phone_number: '',
        email: '',
        contact_name: '',
        name: ''
    };
    const setInitialValues = () => {
        if (!party || mode === formMode.NEW) {
            return initFormValues;
        } else {
            initFormValues.name = party.name;
            initFormValues.type = party.type;
            initFormValues.subType = party.subType;
            initFormValues.country = party.country;
            initFormValues.city = party.city;
            initFormValues.address = party.address;
            initFormValues.phone_number = party.phoneNumber;
            initFormValues.email = party.email;
            initFormValues.contact_id = party.contactId;
            initFormValues.contact_name = party.contactName;
            initFormValues.businessPertner = party.businessPartnerId;
            return initFormValues;
        }
    };
    const validate = (values: any) => {
        if (confirmation) {
            if (JSON.stringify(values) === JSON.stringify(initFormValues)) {
                confirmation.setConfirm(false);
            } else {
                confirmation.setConfirm(true);
            }
        }
        const errors: any = {};
        const subTypes = settings?.partyTypes?.find((item) => item.code === values.type)?.subTypes;
        requiredFields.forEach((field) => {
            if (!values[field]) errors[field] = localizations.parties.form.required_error;
        });

        if (!values.phone_number.match(phonePattern)) errors.phone_number = localizations.parties.form.phone_error;
        if (!values.email.match(emailPattern)) errors.email = localizations.parties.form.email_error;
        if (subTypes && subTypes.length > 0 && !values.subType) errors.subType = localizations.parties.form.required_error;

        const maxLengthValidation = (field: string, maxLength: number, message: string) => {
            if (values[field].length > maxLength) {
                errors[field] = message;
            }
        };

        maxLengthValidation('address', 250, localizations.parties.form.address_max_length_error);
        maxLengthValidation('phone_number', 20, localizations.parties.form.phone_max_length_error);
        maxLengthValidation('email', 100, localizations.parties.form.email_max_length_error);

        return errors;
    };

    const submit = (values: any) => {
        if (entity) {
            const payload = {
                name: values.name,
                type: values.type,
                subType: values.subType,
                country: values.country,
                city: values.city,
                address: values.address,
                phoneNumber: values.phone_number,
                email: values.email,
                contactId: values.contact_id,
                contactName: values.contact_name
            } as party;
            if (mode === formMode.NEW) createParty(entity.id, { ...payload, businessPartnerId: values.businessPertner });
            else if (party)
                updateParty(entity.id, {
                    ...payload,
                    id: party.id
                });
        }
    };
    const setPartyFieldValues = (setFieldValue: any, businessPartner?: businessPartner) => {
        setFieldValue('name', businessPartner?.name);
        setFieldValue('businessPertner', businessPartner?.id);
        setFieldValue('type', businessPartner?.type);
        if (businessPartner?.contacts) {
            setContacts(businessPartner.contacts);
        }
        if (businessPartner?.addresses) {
            setAddresses(businessPartner.addresses);
            const defaultAddress = businessPartner.addresses.find((item) => item.isDefault);
            if (defaultAddress) {
                setFieldValue('company_address', defaultAddress.id);
                onChangeBusinessPartnerAddress('' + defaultAddress.id, setFieldValue, defaultAddress);
            }
        }
    };
    const onChangeBusinessPartner = (value: string, setFieldValue: any) => {
        const businessPartner = businessPartners?.find((item) => item.id === value);
        setPartyFieldValues(setFieldValue, businessPartner);
    };
    const onChangeBusinessPartnerAddress = (value: string, setFieldValue: any, defaultAddress?: address) => {
        const address = defaultAddress || addresses.find((item) => '' + item.id === value);
        if (address) {
            setFieldValue('country', address.country);
            setFieldValue('city', address.city);
            setFieldValue('address', address.address);
        }
    };
    const onChangeBusinessPartnerContact = (value: string, setFieldValue: any, defaultContact?: contact) => {
        const contact = defaultContact || contacts.find((item) => '' + item.id === value);
        if (contact) {
            setFieldValue('contact_name', contact.name);
            setFieldValue('phone_number', contact.phoneNumber);
            setFieldValue('email', contact.email);
        }
    };
    useEffect(() => {
        if (showLoader) {
            setLoading(true);
            AFTER_LOADING = true;
        } else {
            setLoading(false);
            if (AFTER_LOADING && openedFromModal) {
                openedFromModal.setVisible(false);
                AFTER_LOADING = false;
            }
        }
    }, [showLoader, openedFromModal]);
    return (
        <Formik initialValues={setInitialValues()} validate={validate} onSubmit={submit}>
            {({ values, setFieldValue }) => {
                let subTypes;
                if (values.type) {
                    subTypes = settings?.partyTypes?.find((item) => item.code === values.type)?.subTypes;
                    if (subTypes)
                        subTypes = subTypes.map((item: any) => ({
                            text: item.name,
                            value: item.code
                        }));
                }
                return (
                    <Form>
                        <Container>
                            {mode === formMode.NEW && (
                                <>
                                    <FieldLabel>{localizations.parties.form.partyname_placeholder}</FieldLabel>
                                    <FieldContainer>
                                        <Flex>
                                            <FormDropDown
                                                name="name"
                                                placeHolder={localizations.parties.form.partyname_placeholder}
                                                options={partiesToDropdownOptions(businessPartners)}
                                                onChange={(value: string) => {
                                                    onChangeBusinessPartner(value, setFieldValue);
                                                }}
                                                showSearch
                                                width="100%"
                                            />
                                            <CreateBusinessPartner
                                                submitCallback={(businessPartner: businessPartner) => {
                                                    setPartyFieldValues(setFieldValue, businessPartner);
                                                }}
                                                modalTitle={localizations.parties.form.create_company_party}
                                            />
                                        </Flex>
                                    </FieldContainer>
                                    <FieldLabel>{localizations.parties.form.partytype_placeholder}</FieldLabel>
                                    <FieldContainer>
                                        <FormDropDown
                                            name="type"
                                            options={partyTypesOptions || []}
                                            placeHolder={localizations.parties.form.partytype_placeholder}
                                            disabled
                                        />
                                    </FieldContainer>
                                </>
                            )}
                            {subTypes && (
                                <>
                                    <FieldLabel>{localizations.parties.form.partysubtype_placeholder}</FieldLabel>
                                    <FieldContainer>
                                        <FormDropDown
                                            name="subType"
                                            options={subTypes}
                                            placeHolder={localizations.parties.form.partysubtype_placeholder}
                                        />
                                    </FieldContainer>
                                </>
                            )}
                            <FieldLabel>{localizations.parties.form.companyaddresses_placeholder}</FieldLabel>
                            <FieldContainer>
                                <FormDropDown
                                    name="company_address"
                                    options={addresses.map((item) => {
                                        return { text: item.name || item.city + ',' + item.country, value: item.id.toString() };
                                    })}
                                    placeHolder={localizations.parties.form.companyaddresses_placeholder}
                                    onChange={(value: string) => {
                                        onChangeBusinessPartnerAddress(value, setFieldValue);
                                    }}
                                />
                            </FieldContainer>
                            <FieldLabel>{localizations.parties.form.partycountry_placeholder}</FieldLabel>
                            <FieldContainer>
                                <FormDropDown
                                    name="country"
                                    placeHolder={localizations.parties.form.partycountry_placeholder}
                                    options={countriesDropDownOptions}
                                    optionRenderer={renderCountryOption}
                                    showSearch
                                />
                            </FieldContainer>
                            <FieldLabel>{localizations.parties.form.partycity_placeholder}</FieldLabel>
                            <FieldContainer>
                                <LocationSearchInput
                                    name="city"
                                    placeHolder={localizations.parties.form.partycity_placeholder}
                                    countryCode={values.country}
                                />
                            </FieldContainer>
                            <FieldLabel>{localizations.parties.form.partyaddress_placeholder}</FieldLabel>
                            <FieldContainer>
                                <FormTextInput name="address" placeHolder={localizations.parties.form.partyaddress_placeholder} />
                            </FieldContainer>
                            <FieldLabel>{localizations.parties.form.companycontacts_placeholder}</FieldLabel>
                            <FieldContainer>
                                <FormDropDown
                                    name="company_contact"
                                    options={contacts.map((item) => {
                                        return { text: item.name, value: item.id.toString() };
                                    })}
                                    placeHolder={localizations.parties.form.companycontacts_placeholder}
                                    onChange={(value: string) => {
                                        onChangeBusinessPartnerContact(value, setFieldValue);
                                    }}
                                />
                            </FieldContainer>
                            <FieldLabel>{localizations.parties.form.partycontact_name_placeholder}</FieldLabel>
                            <FieldContainer>
                                <FormTextInput name="contact_name" placeHolder={localizations.parties.form.partycontact_name_placeholder} />
                            </FieldContainer>
                            <FieldLabel>{localizations.parties.form.partyphone_placeholder}</FieldLabel>
                            <FieldContainer>
                                <FormTextInput name="phone_number" placeHolder={localizations.parties.form.partyphone_placeholder} />
                            </FieldContainer>
                            <FieldLabel>{localizations.parties.form.partyemail_placeholder}</FieldLabel>
                            <FieldContainer>
                                <FormTextInput name="email" placeHolder={localizations.parties.form.partyemail_placeholder} />
                            </FieldContainer>
                        </Container>
                        {openedFromModal && (
                            <FormFooter>
                                <Button minWidth="55px" onClick={() => openedFromModal.setVisible(false)}>
                                    <span>{localizations.parties.modal.cancel_btn}</span>
                                </Button>
                                <Button minWidth="55px" buttonType="filled" type="submit">
                                    {submitLoading ? (
                                        <Loader width="20px" marginTop="0px" showText={false} />
                                    ) : (
                                        <span>
                                            {mode === formMode.NEW ? localizations.parties.modal.add_btn : localizations.parties.modal.edit_btn}
                                        </span>
                                    )}
                                </Button>
                            </FormFooter>
                        )}
                    </Form>
                );
            }}
        </Formik>
    );
}
const FieldContainer = styled.div`
    margin-bottom: 12px;
`;

const Container = styled(ModalFormContainer)``;

const FormFooter = styled(ModalFooter)`
    position: absolute;
    bottom: 0;
    width: 100%;
    left: 0;
    right: 0;
    background: white;
`;
const Flex = styled.div`
    display: flex;
`;
export default PartyFormConnected;
